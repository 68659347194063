import { fromJS } from 'immutable';
import {
	EDITOR_CONTENT,
	RESET_EDITOR_STATE,
} from '@confluence-classic/confluence-fabric-editor/src/actions/legacy-editor-action-types';

import { OK } from '../../../constants/status-codes';

const defaultState = fromJS({
	contentId: null,
	hasFetched: null,
	result: null,
	errorData: null,
	statusCode: null,
	silent: null,
	spaceKey: null,
});

export default function editorContent(state = defaultState, action) {
	switch (action.type) {
		case `${EDITOR_CONTENT}_REQUEST`:
			return state.merge({
				contentId: action.contentId,
				silent: !!action.silent,
				hasFetched: false,
				result: null,
				errorData: null,
				statusCode: null,
				spaceKey: null,
			});

		case `${EDITOR_CONTENT}_SUCCESS`:
			if (state.get('contentId') !== action.contentId) {
				return state;
			}
			return state.merge({
				hasFetched: true,
				result: action.response && action.response._originalResponse,
				statusCode: OK,
				errorData: null,
				spaceKey: action.response.spaceKey,
			});

		case `${EDITOR_CONTENT}_FAILURE`:
			if (state.get('contentId') !== action.options.contentId) {
				return state;
			}

			return state.merge({
				hasFetched: true,
				statusCode: action.error && action.error.statusCode,
				errorData: action.message,
			});

		case RESET_EDITOR_STATE:
			return defaultState;

		default:
			return state;
	}
}
