import type StateCore from 'markdown-it/lib/rules_core/state_core';
import type Token from 'markdown-it/lib/token';

export function createToken(state: StateCore, type: string, level?: number): Token {
	let nesting: Token.Nesting;
	if (type.includes('_open')) {
		nesting = 1;
	} else if (type.includes('_close')) {
		nesting = -1;
	} else {
		nesting = 0;
	}
	const token = new state.Token(type, '', nesting);
	if (level) {
		token.level = level;
	}
	return token;
}
