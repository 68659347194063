import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

const ErrorType = Object.freeze({
	REMOVE_CHILD_ERROR: 'removeChildError',
	INSERT_BEFORE_ERROR: 'insertBeforeError',
});

export function handleDOMGoogleTranslateIssues() {
	if (typeof Node === 'function' && Node.prototype) {
		const originalRemoveChild = Node.prototype.removeChild;
		Node.prototype.removeChild = function (child) {
			if (child.parentNode !== this) {
				if (console) {
					console.error('Cannot remove a child from a different parent', child, this);

					sendRemoveChildErrorAnalyticsEvent(child);
				}
				return child;
			}
			return originalRemoveChild.apply(this, arguments);
		};

		const originalInsertBefore = Node.prototype.insertBefore;
		Node.prototype.insertBefore = function (newNode, referenceNode) {
			if (referenceNode && referenceNode.parentNode !== this) {
				if (console) {
					console.error(
						'Cannot insert before a reference node from a different parent',
						referenceNode,
						this,
					);

					sendInsertBeforeErrorAnalyticsEvent(referenceNode, newNode);
				}
				return newNode;
			}
			return originalInsertBefore.apply(this, arguments);
		};
	}
}

/**
 * Send an analytics event when a 'removeChild' error occurs. This function helps to track the occurrence of this suppressed error.
 * @param {Node} childNode - The node that is attempted to be removed.
 */
function sendRemoveChildErrorAnalyticsEvent(childNode) {
	const nodeData = {
		nodeToBeRemoved: extractNodeData(childNode),
	};
	sendAnalyticsEvent(ErrorType.REMOVE_CHILD_ERROR, nodeData);
}

/**
 * Send an analytics event when an 'insertBefore' error occurs.  This function helps to track the occurrence of this suppressed error.
 * @param {Node} referenceNode - The existing node in the DOM tree. The new node is intended to be inserted before this node.
 * @param {Node} newNode - The new node that is attempted to be inserted.
 */
function sendInsertBeforeErrorAnalyticsEvent(referenceNode, newNode) {
	const nodeData = {
		newNode: extractNodeData(newNode),
		referenceNode: extractNodeData(referenceNode),
	};
	sendAnalyticsEvent(ErrorType.INSERT_BEFORE_ERROR, nodeData);
}

function sendAnalyticsEvent(action, nodeData) {
	void getAnalyticsWebClient().then((client) => {
		client.sendOperationalEvent({
			source: 'confluenceFrontendServer',
			actionSubject: 'domManipulationError',
			action: 'error',
			attributes: {
				suppressedError: action,
				...nodeData,
			},
		});
	});
}

function extractNodeData(node) {
	if (!node) return {};

	return {
		nodeName: node.nodeName,
		parentElementNodeName: node.parentElement?.nodeName,
		isConnected: node.isConnected,
	};
}
