import type {
	ConvoAIResponseRovoAction,
	EditorPluginAIPromptResponseMeta,
	StreamParsed,
} from '../../../types';
import type { StreamingResponse } from '../../start-streaming-response';

function parsedHasExpectedKey<Key extends string>(
	key: Key,
	parsed: unknown,
): parsed is { [key in Key]: string } {
	if (typeof parsed !== 'object' || parsed === null || !(key in parsed)) {
		return false;
	}

	return true;
}

/**
 * This processes information from startStreamingResponse() and yields events
 * over to streamingService().
 */
export async function* streamResponseParser({
	streamingResponseGenerator,
	isConvoAI,
	streamingListener,
}: {
	streamingResponseGenerator: StreamingResponse;
	isConvoAI?: boolean;
	streamingListener?: (markdown: string, status: string) => void;
}): StreamParsed {
	let partialMeta: EditorPluginAIPromptResponseMeta = {
		inputOutputDiffRatio: '',
	};

	let partial = {
		type: 'markdown' as const,
		content: '',
		meta: partialMeta,
		rovoActions: [] as ConvoAIResponseRovoAction[],
	};

	for await (const yieldedResponse of streamingResponseGenerator) {
		if (yieldedResponse.state === 'loaded-stream') {
			return yield { state: 'loaded', data: partial };
		}

		if (yieldedResponse.state === 'loading' || yieldedResponse.state === 'loaded') {
			if (parsedHasExpectedKey('generatedContent', yieldedResponse.data)) {
				partial = {
					type: 'markdown',
					content: partial.content + yieldedResponse.data['generatedContent'],
					rovoActions: partial.rovoActions,
					meta:
						parsedHasExpectedKey('meta', yieldedResponse.data) &&
						parsedHasExpectedKey('inputOutputDiffRatio', yieldedResponse.data.meta)
							? {
									inputOutputDiffRatio: yieldedResponse.data['meta']['inputOutputDiffRatio'],
									loadingStatus: '',
								}
							: partial.meta,
				};

				if (streamingListener) {
					streamingListener(partial.content, yieldedResponse.state);
				}
				yield { state: yieldedResponse.state, data: partial };
			} else if (isConvoAI) {
				if (parsedHasExpectedKey('type', yieldedResponse.data)) {
					if (yieldedResponse.data.type === 'TRACE') {
						const loadingStatus =
							parsedHasExpectedKey('message', yieldedResponse.data) &&
							parsedHasExpectedKey('message_template', yieldedResponse.data.message)
								? yieldedResponse.data.message.message_template
								: '';

						partial = {
							...partial,
							type: 'markdown',
							content: partial.content,
							meta: {
								inputOutputDiffRatio: '',
								loadingStatus,
							},
						};
					}

					if (yieldedResponse.data.type === 'ANSWER_PART') {
						partial = {
							...partial,
							type: 'markdown',
							content: partial.content + (yieldedResponse.data as any).message.content,
							meta: {
								inputOutputDiffRatio: '',
								loadingStatus: '',
							},
						};
					}

					if (yieldedResponse.data.type === 'FINAL_RESPONSE') {
						const { message } = yieldedResponse.data as any;

						if (
							parsedHasExpectedKey('message_metadata', message.message) &&
							parsedHasExpectedKey('response_too_similar', message.message.message_metadata) &&
							message.message.message_metadata.response_too_similar
						) {
							return yield {
								state: 'failed',
								statusCode: message.status_code,
								reason: 'response-too-similar',
								data: {
									meta: {
										inputOutputDiffRatio: message.message?.message_metadata?.diff_ratio || '',
									},
								},
							};
						}

						const rovoActions: ConvoAIResponseRovoAction[] = message.message.actions || [];

						partial = {
							...partial,
							type: 'markdown',
							content: message.message.content,
							meta: {
								inputOutputDiffRatio: message.message?.message_metadata?.diff_ratio || '',
								loadingStatus: '',
							},
							rovoActions,
						};
					}

					if (yieldedResponse.data.type === 'ERROR') {
						let statusCode;
						let guard;
						let error;
						let retryAfter;

						if (parsedHasExpectedKey('message', yieldedResponse.data)) {
							// Get status code
							if (
								parsedHasExpectedKey('status_code', yieldedResponse.data.message) &&
								typeof yieldedResponse.data.message.status_code === 'number'
							) {
								statusCode = yieldedResponse.data.message.status_code;
							}

							// Get reason
							if (parsedHasExpectedKey('message_template', yieldedResponse.data.message)) {
								guard = yieldedResponse.data.message.message_template;
							}

							// Get error
							if (parsedHasExpectedKey('content', yieldedResponse.data.message)) {
								error = yieldedResponse.data.message.content;
							}

							// Get retryAfter
							if (
								parsedHasExpectedKey('headers', yieldedResponse.data) &&
								parsedHasExpectedKey('Retry-After', yieldedResponse.data.headers)
							) {
								retryAfter = Number(yieldedResponse.data.headers['Retry-After']);
							}
						}

						if (guard) {
							if (statusCode === 429) {
								return yield {
									state: 'failed',
									apiName: 'assistance-service',
									statusCode,
									guard,
									error,
									retryAfter,
								};
							}

							return yield {
								state: 'failed',
								apiName: 'assistance-service',
								statusCode,
								guard,
								error,
							};
						}
						return yield {
							state: 'failed',
							apiName: 'assistance-service',
							statusCode,
							guard: 'UNHANDLED_ERROR',
							error,
						};
					}
					yield { state: yieldedResponse.state, data: partial };
				} else {
					// If there are any unexpected loading responses,
					// We should return the current data
					yield { state: yieldedResponse.state, data: partial };
				}
			}
			// if not expected response from the API the we have an error
			else {
				return yield { state: 'failed', reason: 'parsing' };
			}
		} else {
			if (
				(yieldedResponse.state === 'failed' && yieldedResponse.reason !== 'response-too-similar') ||
				yieldedResponse.state === 'aup-violation'
			) {
				return yield yieldedResponse;
			} else {
				let responseTooSimilarData = {
					meta:
						parsedHasExpectedKey('meta', yieldedResponse.data) &&
						parsedHasExpectedKey('inputOutputDiffRatio', yieldedResponse.data.meta)
							? {
									inputOutputDiffRatio: yieldedResponse.data['meta']['inputOutputDiffRatio'],
								}
							: partialMeta,
				};

				return yield {
					state: 'failed',
					statusCode: 208,
					reason: 'response-too-similar',
					data: responseTooSimilarData,
				};
			}
		}
	}
}
