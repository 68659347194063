import { N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const panel = {
	display: 'block', // DEBUGGING
	borderWidth: '1px',
	borderRadius: '3px',
	borderStyle: 'solid',
	borderColor: token('color.border', 'rgb(193, 199, 208)'),
	borderImage: 'initial',
};

export const panelHeader = {
	display: 'block', // DEBUGGING
	backgroundColor: token('color.background.accent.gray.subtlest', 'rgb(244, 245, 247)'),
	minWidth: '128px',
	padding: token('space.100', '8px'),
	fontSize: '16px',
};

export const panelBody = {
	display: 'block', // DEBUGGING
	minWidth: '128px',
	borderWidth: '1px 0px 0px 0px',
	borderStyle: 'solid',
	borderColor: token('color.border', 'rgb(193, 199, 208)'),
	padding: token('space.100', '8px'),
};

export const panelHeaderExcerpt = {
	color: token('color.text.subtlest', N700),
	paddingLeft: token('space.050', '4px'),
};
