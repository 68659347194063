import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import AutoDismissFlag from '@atlaskit/flag/auto-dismiss-flag';

import type { FlagOptions } from '@atlassian/forge-ui/iframe';

import { FlagsStateContainer } from '@confluence/flags';

export const getFlagProvider = ({ showFlag, hideFlag }: FlagsStateContainer) => {
	const createFlag = (flagOptions: FlagOptions) => {
		const { isAutoDismiss, ...restOptions } = flagOptions;
		const { type, appearance, actions } = flagOptions;
		const shouldAutoDismiss = isAutoDismiss ?? (appearance === 'info' || appearance === 'success');

		void showFlag({
			...restOptions,
			type: appearance ?? type,
			actions: actions?.map(({ text: content, onClick }) => ({
				onClick: () => onClick(),
				content,
			})),
			customComponent: shouldAutoDismiss ? AutoDismissFlag : undefined,
		});
		return true;
	};

	const closeFlag = ({ id }: Pick<FlagOptions, 'id'>) => {
		void hideFlag(id);
		return true;
	};

	return { showFlag: createFlag, closeFlag };
};

export const FlagProvider = ({ children }) => (
	<Subscribe to={[FlagsStateContainer]}>{children}</Subscribe>
);
