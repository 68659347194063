export const getSmartLinksCount = (ssrSmartLinks: { [key: string]: any }) => {
	let total = 0;
	let jira = 0;
	let confluence = 0;

	if (ssrSmartLinks) {
		for (const key in ssrSmartLinks) {
			const link = ssrSmartLinks[key];

			if (link.status === 'resolved') {
				total++;
				const product = link.details?.meta?.product;
				if (product === 'jira') {
					jira++;
				} else if (product === 'confluence') {
					confluence++;
				}
			}
		}
	}

	return { total, jira, confluence };
};
