import {
	SHOW_EDITOR,
	HIDE_EDITOR,
	EDITOR_LOAD_COMPLETE,
	EDITOR_CONTENT_EDITABLE,
} from '@confluence-classic/confluence-fabric-editor/src/actions/legacy-editor-action-types';

const defaultState = {
	contentId: null,
	isEditorLoaded: false,
	isContentEditable: false,
};

export const editor = (state = defaultState, action) => {
	switch (action.type) {
		case SHOW_EDITOR:
			return { ...state, contentId: action.contentId };

		case HIDE_EDITOR:
			return { ...state, contentId: null };

		case EDITOR_LOAD_COMPLETE:
			return { ...state, isEditorLoaded: true };

		case EDITOR_CONTENT_EDITABLE:
			return { ...state, isContentEditable: true };
		default:
			return state;
	}
};
