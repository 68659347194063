import { LoadableAfterPaint } from '@confluence/loadable';

export const SpaceRoleManagementScreen = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceRoleManagementPage" */ '../src/page/SpaceRoleManagementPage'
			)
		).SpaceRoleManagementPage,
	name: 'SpaceRoleManagementPage',
});
