import React, { useContext, useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { Box, xcss } from '@atlaskit/primitives';

import { ChartSummaryBaseCard } from '@confluence/admin-center/entry-points/ChartSummaryBaseCard';
import {
	getMainStatProps,
	getGranularityTextForMainStat,
} from '@confluence/admin-center/entry-points/MainStat-utils';
import { MetricSettingsContext } from '@confluence/admin-center/entry-points/MetricSettingsContext';
import { useEventMetricData } from '@confluence/admin-center/entry-points/useEventMetricData';
import { getBrowserTimezone } from '@confluence/confluence-analytics/entry-points/getBrowserTimezone';
import {
	COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE,
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import type { HubAnalyticsChartQuery as HubAnalyticsChartQueryType } from './__types__/HubAnalyticsChartQuery';
import type { HubAnalyticsClicksChartQuery as HubAnalyticsClicksChartQueryType } from './__types__/HubAnalyticsClicksChartQuery';
import type { HubAnalyticsCTRChartQuery as HubAnalyticsCTRChartQueryType } from './__types__/HubAnalyticsCTRChartQuery';
import type { HubAnalyticsUniqueVisitorsChartQuery as HubAnalyticsUniqueVisitorsChartQueryType } from './__types__/HubAnalyticsUniqueVisitorsChartQuery';
import {
	HubAnalyticsChartQuery,
	HubAnalyticsClicksChartQuery,
	HubAnalyticsCTRChartQuery,
	HubAnalyticsUniqueVisitorsChartQuery,
} from './HubAnalyticsChartQuery.graphql';
import { getAdjustedStartDate } from './HubAnalyticsUtils';
import { Metric, useSelectedMetric } from './SelectedMetricContext';

const i18n = defineMessages({
	ctrLabel: {
		id: 'company-hub.analytics.hub-analytics-chart.hub-ctr-label',
		defaultMessage: 'Click-thru rate (average)',
		description: 'Hub line label for the time series hub ctr on the hub analytics chart',
	},
	hubClicksLabel: {
		id: 'company-hub.analytics.hub-analytics-chart.hub-clicks-label',
		defaultMessage: 'Clicks',
		description: 'Hub line label for the time series hub clicks on the hub analytics chart',
	},
	hubUniqueVisitorsLabel: {
		id: 'company-hub.analytics.hub-analytics-chart.hub-unique-visitors-label',
		defaultMessage: 'Unique visitors',
		description:
			'Hub line label for the time series hub unique visitors on the hub analytics chart',
	},
	hubViewsLabel: {
		id: 'company-hub.analytics.hub-analytics-chart.hub-views-label',
		defaultMessage: 'Views',
		description: 'Hub line label for the time series hub views on the hub analytics chart',
	},
	contentInteractionsMainStat: {
		id: 'company-hub.analytics.hub-analytics-chart.main-statistic',
		defaultMessage: '{value} {lastGranularityDescription}',
		description: 'Displays the total number of hub interactions for the hub analytics chart',
	},
});

const hubAnalyticsChartContainer = xcss({
	maxHeight: '100%',
});

type HubViewsChartProps = {
	spaceId: string;
	isSpaceCreatedDateOver1DayAgo: boolean | undefined;
};

export const HubAnalyticsChart = ({
	spaceId,
	isSpaceCreatedDateOver1DayAgo,
}: HubViewsChartProps) => {
	const { formatMessage, formatNumber } = useIntl();
	const metricSettingsValues = useContext(MetricSettingsContext);
	const { startDate, endDate, granularity } = metricSettingsValues;
	const [metricState] = useSelectedMetric();

	const chartTitle = useMemo(() => {
		switch (metricState.selectedMetric) {
			case Metric.VIEWS:
				return formatMessage(i18n.hubViewsLabel);
			case Metric.UNIQUE_VISITORS:
				return formatMessage(i18n.hubUniqueVisitorsLabel);
			case Metric.CLICKS:
				return formatMessage(i18n.hubClicksLabel);
			case Metric.CTR:
				return formatMessage(i18n.ctrLabel);
		}
	}, [formatMessage, metricState]);

	const adjustedStartDate = useMemo(() => {
		return getAdjustedStartDate({ startDate, endDate });
	}, [endDate, startDate]);

	const timezone = getBrowserTimezone();

	const {
		data: viewsData,
		loading: viewsLoading,
		error: viewsError,
	} = useQuery<HubAnalyticsChartQueryType>(HubAnalyticsChartQuery, {
		variables: {
			spaceId,
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			timezone,
			granularity,
		},
		skip: !isSpaceCreatedDateOver1DayAgo,
		context: {
			single: true,
		},
	});

	const [getClicksData, { data: clicksData, loading: clicksLoading, error: clicksError }] =
		useLazyQuery<HubAnalyticsClicksChartQueryType>(HubAnalyticsClicksChartQuery, {
			variables: {
				timezone,
				granularity,
				startDate: startDate.toISOString(),
			},
			context: {
				single: true,
			},
		});

	const [
		getUniqueVisitorsData,
		{ data: uniqueVisitorsData, loading: uniqueVisitorsLoading, error: uniqueVisitorsError },
	] = useLazyQuery<HubAnalyticsUniqueVisitorsChartQueryType>(HubAnalyticsUniqueVisitorsChartQuery, {
		variables: {
			spaceId,
			granularity,
			startDate: adjustedStartDate.toISOString(),
			endDate: endDate.toISOString(),
			timezone,
		},
		context: {
			single: true,
		},
	});

	const [getCTRData, { data: ctrData, loading: ctrLoading, error: ctrError }] =
		useLazyQuery<HubAnalyticsCTRChartQueryType>(HubAnalyticsCTRChartQuery, {
			variables: {
				granularity,
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				timezone,
			},
		});

	const lineDataToProcess = useMemo(() => {
		switch (metricState.selectedMetric) {
			case Metric.VIEWS:
				return [
					{
						description: formatMessage(i18n.hubViewsLabel),
						timeseriesData: viewsData?.hubViews?.nodes || [],
					},
				];
			case Metric.UNIQUE_VISITORS:
				if (isSpaceCreatedDateOver1DayAgo) {
					getUniqueVisitorsData();
				}
				return [
					{
						description: formatMessage(i18n.hubUniqueVisitorsLabel),
						timeseriesData: uniqueVisitorsData?.hubUniqueVisitors?.nodes || [],
					},
				];
			case Metric.CLICKS:
				if (isSpaceCreatedDateOver1DayAgo) {
					getClicksData();
				}
				return [
					{
						description: formatMessage(i18n.hubClicksLabel),
						timeseriesData: clicksData?.hubClicks?.nodes || [],
					},
				];
			case Metric.CTR:
				if (isSpaceCreatedDateOver1DayAgo) {
					getCTRData();
				}
				const ctrNodes = ctrData?.hubCTR?.nodes || [];
				const ctrTimeSeriesData = ctrNodes.map((value) => ({
					date: value.timestamp,
					count: value.ctr,
				}));
				return [
					{
						description: formatMessage(i18n.ctrLabel),
						timeseriesData: ctrTimeSeriesData,
					},
				];
		}
	}, [
		isSpaceCreatedDateOver1DayAgo,
		metricState.selectedMetric,
		formatMessage,
		viewsData?.hubViews?.nodes,
		getClicksData,
		clicksData?.hubClicks?.nodes,
		getCTRData,
		ctrData?.hubCTR?.nodes,
		getUniqueVisitorsData,
		uniqueVisitorsData?.hubUniqueVisitors?.nodes,
	]);

	const { lines, mainStatCount, subStat } = useEventMetricData(lineDataToProcess);

	const mainStat = getMainStatProps({
		count: mainStatCount,
		formatNumber,
		description: i18n.contentInteractionsMainStat,
		descriptionValues: {
			lastGranularityDescription: (
				<FormattedMessage {...getGranularityTextForMainStat(granularity)} />
			),
		},
	});

	const currentLoading = useMemo<boolean>(() => {
		switch (metricState.selectedMetric) {
			case Metric.VIEWS:
				return viewsLoading;
			case Metric.UNIQUE_VISITORS:
				return uniqueVisitorsLoading;
			case Metric.CLICKS:
				return clicksLoading;
			case Metric.CTR:
				return ctrLoading;
		}
	}, [viewsLoading, clicksLoading, uniqueVisitorsLoading, ctrLoading, metricState.selectedMetric]);

	const currentError = useMemo<ApolloError | undefined>(() => {
		switch (metricState.selectedMetric) {
			case Metric.VIEWS:
				return viewsError;
			case Metric.UNIQUE_VISITORS:
				return uniqueVisitorsError;
			case Metric.CLICKS:
				return clicksError;
			case Metric.CTR:
				return ctrError;
		}
	}, [viewsError, clicksError, uniqueVisitorsError, ctrError, metricState.selectedMetric]);

	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		experienceTracker.start({ name: COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE });
	}, [experienceTracker]);

	return (
		<>
			{currentError ? (
				<ExperienceFailure name={COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE} error={currentError} />
			) : (
				!currentLoading && <ExperienceSuccess name={COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE} />
			)}
			<Box xcss={hubAnalyticsChartContainer} testId="hub-analytics-chart">
				<ChartSummaryBaseCard
					title={chartTitle}
					lines={lines}
					isAreaChart
					loading={currentLoading}
					hasError={!!currentError}
					granularity={granularity}
					mainStat={mainStat}
					subStat={subStat}
				/>
			</Box>
		</>
	);
};
