import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class SingleModal extends PureComponent {
	render() {
		const { modalComponent: Modal, modalProps, closeActiveModal } = this.props;
		if (Modal) {
			return <Modal {...modalProps} closeModal={closeActiveModal} />;
		}
		return null;
	}
}

SingleModal.defaultProps = {
	modalProps: {},
};
SingleModal.propTypes = {
	closeActiveModal: PropTypes.func,
	modalComponent: PropTypes.func,
	modalProps: PropTypes.object,
};
