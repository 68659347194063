import gql from 'graphql-tag';

export const LegacyBridgeQuery = gql`
	query LegacyBridgeQuery($contentId: ID!, $isBlog: Boolean!) {
		# ptpage doesn't work for blog posts. It will return 404
		ptpage(id: $contentId, status: [CURRENT]) @skip(if: $isBlog) {
			id
			nearestAncestors(first: 1) {
				edges {
					node {
						id
						title
					}
				}
			}
		}
		content(id: $contentId) {
			nodes {
				id
				type
				title
				version {
					number
				}
				space {
					id
					key
					name
					type
				}
				operations {
					operation
					targetType
				}
			}
		}
	}
`;
