/***
 * BEWARE: THIS INSTANCE OF THE REST API INCLUDES ALL ENDPOINTS (IT ENDS UP ADDING ABOUT 300kb WHEREVER IT IS USED).
 * YOU MAY WANT TO CONSIDER IMPORTING THE SPECIFIC ENDPOINT AND CONFIGURING IT WITH THE DEFAULT CONFIGURATION:
 *
 *   import { webResources } from '@confluence-classic/confluence-rest-api';
 *   import configuration from './rest-api-configuration';
 *   webResources.configure(configuration());
 *
 */
import { configure } from '@confluence-classic/confluence-rest-api';

import configuration from './configuration';

configure(configuration());

export default function (newConfiguration = {}) {
	configure({ ...configuration(), ...newConfiguration });
}
