import React, { useCallback, useContext, useMemo } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import type { NumberFormatOptionsStyle } from '@formatjs/ecma402-abstract/types/number';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import InformationIcon from '@atlaskit/icon/core/information';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import type { PressableProps } from '@atlaskit/primitives';
import { Box, Stack, Inline, Pressable, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { MetricSettingsContext } from '@confluence/admin-center/entry-points/MetricSettingsContext';

import { Metric, useSelectedMetric } from './SelectedMetricContext';

const i18n = defineMessages({
	errorTitle: {
		id: 'company-hub.analytics.analytic-box-base.error.title',
		defaultMessage: 'Error fetching data',
		description: 'Title for the analytic box when an error occurs',
	},
	viewsInfoIconLabel: {
		id: 'company-hub.analytics.analytic-box-base.views-info-icon-label',
		defaultMessage: `The number of times the hub was accessed by a browser in the {timePeriod, select,
			DAY {{count, plural, one {past day} other {past {count} days}}}
			WEEK {{count, plural, one {past week} other {past {count} weeks}}}
			MONTH {{count, plural, one {past month} other {past {count} months}}}
			other {past}}.
			Views only represent content hosted on this instance.`,
		description: 'Label for the info icon in the views analytic box',
	},
	uniqueVisitorsInfoIconLabel: {
		id: 'company-hub.analytics.analytic-box-base.unique-visitors-info-icon-label',
		defaultMessage: `The number of distinct users that have visited the hub at least once in the {timePeriod, select,
			DAY {{count, plural, one {past day} other {past {count} days}}}
			WEEK {{count, plural, one {past week} other {past {count} weeks}}}
			MONTH {{count, plural, one {past month} other {past {count} months}}}
			other {past}}`,
		description: 'Label for the info icon in the unique visitors analytic box',
	},
	clicksInfoIconLabel: {
		id: 'company-hub.analytics.analytic-box-base.clicks-info-icon-label',
		defaultMessage: `The total number of clicks received across all linked content within the hub in the {timePeriod, select,
			DAY {{count, plural, one {past day} other {past {count} days}}}
			WEEK {{count, plural, one {past week} other {past {count} weeks}}}
			MONTH {{count, plural, one {past month} other {past {count} months}}}
			other {past}}`,
		description: 'Label for the info icon in the clicks analytic box',
	},
	ctrInfoIconLabel: {
		id: 'company-hub.analytics.analytic-box-base.ctr-info-icon-label',
		defaultMessage: 'The average percentage of clicks received compared to total hub impressions.',
		description: 'Label for the info icon in the CTR analytic box',
	},
});

const stackContainerStyles = xcss({
	minWidth: '25%',
});

const analyticBoxContainerStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.200',
	minWidth: '25%',
	minHeight: '146px',
	color: 'color.text.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
});

const activePressableStyles = xcss({
	borderWidth: '0',
	color: 'color.text.inverse',
	// @ts-ignore - chart brand color is allowed for data visualisation only, not listed as ts-allowed backgroundColor
	backgroundColor: token('color.chart.brand'),
	':hover': {
		// @ts-ignore - chart brand color is allowed for data visualisation only, not listed as ts-allowed backgroundColor
		backgroundColor: token('color.chart.brand'),
	},
});

const triangleStyles = xcss({
	width: '0',
	height: '0',
	borderLeft: '20px solid transparent',
	borderRight: '20px solid transparent',
	borderTop: `20px solid ${token('color.chart.brand')}`,
	alignSelf: 'center',
});

const displayNoneStyle = xcss({
	display: 'none',
});

const overflowTextStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const mainContentContainerStyles = xcss({
	padding: 'space.250',
	height: '100%',
});

const analyticValueContainerStyles = xcss({
	font: token('font.heading.xxlarge'),
	color: 'color.text.subtle',
	alignSelf: 'flex-start',
});

const analyticValueActiveContainerStyles = xcss({
	color: 'color.text.inverse',
});

const loadingErrorContainerStyles = xcss({
	marginTop: 'space.050',
});

type AnalyticBoxProps = {
	metric: (typeof Metric)[keyof typeof Metric];
	analyticName: MessageDescriptor;
	analyticValue: number | undefined;
	analyticStyle?: NumberFormatOptionsStyle;
	isLoading?: boolean;
	hasError?: boolean;
	testId?: string;
};

export const AnalyticBoxBase = ({
	metric,
	analyticName,
	analyticValue,
	analyticStyle,
	isLoading = false,
	hasError = false,
	testId,
}: AnalyticBoxProps) => {
	const { formatNumber, formatMessage } = useIntl();
	const [metricState, metricActions] = useSelectedMetric();
	const analyticValueIsSelected = metricState.selectedMetric === metric;
	const metricSettingsValues = useContext(MetricSettingsContext);
	const { granularity, lastXGranularities } = metricSettingsValues;
	const infoIconLabelText = useMemo(() => {
		switch (metric) {
			case Metric.VIEWS:
				return formatMessage(i18n.viewsInfoIconLabel, {
					timePeriod: granularity,
					count: lastXGranularities,
				});
			case Metric.UNIQUE_VISITORS:
				return formatMessage(i18n.uniqueVisitorsInfoIconLabel, {
					timePeriod: granularity,
					count: lastXGranularities,
				});
			case Metric.CLICKS:
				return formatMessage(i18n.clicksInfoIconLabel, {
					timePeriod: granularity,
					count: lastXGranularities,
				});
			case Metric.CTR:
				return formatMessage(i18n.ctrInfoIconLabel);
		}
	}, [formatMessage, granularity, lastXGranularities, metric]);
	let mainContent;

	if (isLoading) {
		mainContent = (
			<Box xcss={loadingErrorContainerStyles}>
				<Spinner size="medium" testId="spinner" />
			</Box>
		);
	} else if (hasError || analyticValue === undefined) {
		mainContent = (
			<Box xcss={loadingErrorContainerStyles}>
				<Inline>
					<ErrorIcon label="error icon" />
					<FormattedMessage {...i18n.errorTitle} />
				</Inline>
			</Box>
		);
	} else {
		mainContent = (
			<Box
				xcss={[
					analyticValueContainerStyles,
					analyticValueIsSelected ? analyticValueActiveContainerStyles : undefined,
				]}
			>
				{formatNumber(analyticValue, { notation: 'compact', style: analyticStyle })}
			</Box>
		);
	}

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick: NonNullable<PressableProps['onClick']> = useCallback(() => {
		metricActions.setSelectedMetric(metric);

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'analyticMetricSelected',
				source: 'companyHub',
				attributes: {
					metric: formatMessage(analyticName),
				},
			},
		}).fire();
	}, [analyticName, createAnalyticsEvent, formatMessage, metric, metricActions]);

	return (
		<Stack testId={testId} xcss={stackContainerStyles}>
			<Pressable
				xcss={[
					analyticBoxContainerStyles,
					analyticValueIsSelected ? activePressableStyles : undefined,
				]}
				onClick={onClick}
				padding="space.0"
			>
				<Stack
					xcss={mainContentContainerStyles}
					space="space.050"
					alignInline="stretch"
					alignBlock="start"
				>
					<Inline space="space.200" spread="space-between">
						<Box xcss={overflowTextStyles}>
							<Text
								size="large"
								weight="semibold"
								color={analyticValueIsSelected ? 'color.text.inverse' : 'color.text.subtlest'}
							>
								<FormattedMessage {...analyticName} />
							</Text>
						</Box>
						<Tooltip content={infoIconLabelText} ignoreTooltipPointerEvents>
							<InformationIcon
								label={formatMessage(analyticName)}
								color={
									analyticValueIsSelected
										? token('color.text.inverse')
										: token('color.text.subtlest')
								}
							/>
						</Tooltip>
					</Inline>
					{mainContent}
				</Stack>
			</Pressable>
			<Box xcss={[triangleStyles, analyticValueIsSelected ? undefined : displayNoneStyle]} />
		</Stack>
	);
};
