import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { Box } from '@atlaskit/primitives';

const i18n = defineMessages({
	refreshesDailyFooterText: {
		id: 'admin-center.group-summary-block.data-refreshes-daily',
		defaultMessage: 'Refreshes daily',
		description: 'Text stating that the data is refreshed daily',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ font?: string }>({
	display: 'flex',
	alignItems: 'center',
	color: token('color.text.subtle', '#44546F'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: ({ font }) => font ?? token('font.body', fontFallback.body.medium),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	transform: 'rotate(140deg) scale(-0.75, 0.75)',
	display: 'flex',
	alignItems: 'center',
});

export const DailyRefreshText = ({ font }: { font?: string }) => {
	return (
		<Container font={font}>
			<IconContainer>
				<Box paddingInlineStart="space.050">
					<RefreshIcon size="small" label="" />
				</Box>
			</IconContainer>
			<FormattedMessage {...i18n.refreshesDailyFooterText} />
		</Container>
	);
};
