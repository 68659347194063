import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { preloadAdminAnnouncementBanner } from '@confluence/admin-announcement-banner/entry-points/preloadAdminAnnouncementBanner';
import { preloadStorageEnforcementBanner } from '@confluence/storage-enforcement/entry-points/preloadStorageEnforcementBanner';

export const preloadBanners = async () => {
	const { edition } = await getPreloaderFnContext();

	const upperCaseEdition = edition?.toUpperCase() || '';
	if (upperCaseEdition === 'PREMIUM' || upperCaseEdition === 'STANDARD') {
		return preloadAdminAnnouncementBanner();
	} else if (upperCaseEdition === 'FREE') {
		return preloadStorageEnforcementBanner();
	}
};
