import memoizeOne from 'memoize-one';

import { liftPromiseState } from '@confluence/lifted-promise';
import { BadStatusError } from '@confluence/network';
import { getApolloClient } from '@confluence/graphql';

import { onScriptsAndCSSLoaded } from './onScriptsAndCSSLoaded';
import { overrideAJSMeta } from './overrideAjsMeta';
import { FrontendResourceRenderQuery } from './FrontendResourceRenderQuery.graphql';

type ResourceType = 'js' | 'css';

type Resource = {
	type: ResourceType;
	attributes: { [key: string]: string };
	url: string;
};
type ResourceByType = Record<ResourceType, Omit<Resource, 'type'>[]>;

export const getSuperBatchData = memoizeOne(
	async (client: ReturnType<typeof getApolloClient>): Promise<Resource[]> => {
		return client
			.query({
				query: FrontendResourceRenderQuery,
				variables: {},
				context: {
					allowOnExternalPage: true,
				},
			})
			.then(
				(response) =>
					(response?.data?.internalFrontendResource?.resourceList || []).map((resource: any) => {
						resource.attributes = resource.attributes || {};

						if (Array.isArray(resource.attributes)) {
							resource.attributes = resource.attributes.reduce((obj: any, { key, value }: any) => {
								obj[key] = value;
								return obj;
							}, {});
						}

						return resource;
					}) || [],
				(reason: any) => {
					if (reason instanceof BadStatusError) {
						return [];
					}

					throw reason;
				},
			);
	},
);

function createElement<K extends keyof HTMLElementTagNameMap>(
	type: K,
	attributes: Record<string, string>,
): HTMLElementTagNameMap[K] {
	const element = document.createElement(type);
	Object.keys(attributes).forEach((attributeKey) =>
		element.setAttribute(attributeKey, attributes[attributeKey]),
	);
	return element;
}

function createStyleElement(attributes: Record<string, string>): HTMLLinkElement {
	return createElement('link', { rel: 'stylesheet', ...attributes });
}

function createSyncScriptElement(attributes: Record<string, string>): HTMLScriptElement {
	attributes.crossorigin = 'anonymous';
	const element = createElement('script', attributes);
	element.async = false;
	return element;
}

export function getResourcesByType(resourceList: Resource[]): ResourceByType {
	return resourceList.reduce(
		(resources, { type, attributes, url }) => {
			if (!resources[type]) {
				resources[type] = [];
			}
			resources[type].push({ attributes, url });
			return resources;
		},
		{ js: [], css: [] } as ResourceByType,
	);
}

/**
 * Fix for HOT-88486 monolith is incorrectly returning hash. Change it to local.
 * TODO: Remove when BE fixed it.
 * export for test
 */
export function replaceHash(url: string) {
	return url.replace(/externals=[^&]+&/, 'externals=__local-default__&');
}

async function fetchAndInsertJsAndCssTagsToHead(): Promise<void | any[]> {
	const resources = await getSuperBatchData(getApolloClient());
	const resourceByType = getResourcesByType(resources);

	const fragment = window.document.createDocumentFragment();
	const tagsAdded: (HTMLScriptElement | HTMLLinkElement)[] = [];
	resourceByType.js.forEach(({ url, attributes }) => {
		// Jquery is always statically loaded in packages/confluence-frontend-server/templates/html/dashboard-spa-container.js
		if (attributes['data-wrm-key'] === 'com.atlassian.plugins.jquery:jquery') {
			return;
		}

		if (!window.document.querySelector(`script[data-wrm-key='${attributes['data-wrm-key']}']`)) {
			// use https when running locally as some links with the
			// non-secure http protocol throws 403 locally instead of redirecting to https
			const src =
				process.env.NODE_ENV === 'development' ? `https:${replaceHash(url)}` : replaceHash(url);
			const tag = createSyncScriptElement({
				// Note: url on BE pipeline is relative
				src,
				...attributes,
			});
			tagsAdded.push(tag);
			fragment.appendChild(tag);
		}
	});
	resourceByType.css.forEach(({ url, attributes }) => {
		if (!window.document.querySelector(`link[data-wrm-key='${attributes['data-wrm-key']}']`)) {
			// use https when running locally as some links with the
			// non-secure http protocol throws 403 locally instead of redirecting to https
			const src =
				process.env.NODE_ENV === 'development' ? `https:${replaceHash(url)}` : replaceHash(url);
			const tag = createStyleElement({
				// Note: url on BE pipeline is relative
				href: src,
				...attributes,
			});
			tagsAdded.push(tag);
			fragment.appendChild(tag);
		}
	});

	if (tagsAdded.length) {
		window.document.head.appendChild(fragment);
		return onScriptsAndCSSLoaded(tagsAdded);
	}

	return Promise.resolve();
}

export const requireSuperBatch = memoizeOne(() => {
	// NOTE: superbatch may be requested at the server side already, in which case
	// we don't need to refetch the superbatch resources again and can assume that
	// they are (or are being) loaded.
	const superbatchPromise = window.__SUPERBATCH_REQUESTED__
		? Promise.resolve()
		: fetchAndInsertJsAndCssTagsToHead();

	return liftPromiseState(superbatchPromise.then(() => overrideAJSMeta()));
});
