import type { Flags } from '@atlaskit/feature-flag-client/types';

import { getLogger } from '@confluence/logger';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

import type { FeatureFlagsType, SessionDataType } from './SessionDataTypes';

const logger = getLogger('session-data');

export const OVERRIDES_STORAGE_KEY = PERSISTED_KEYS_ON_SERVER?.PERSISTED_OVERRIDE_FEATURE_FLAGS;

// We must wait until @confluence/storage-manager is initialized before applying
// the persisted overrides. The flow in confluence-frontend-server and ssr-app
// looks like:
// 1. init session-data (which applies any FF overrides still stored in localstorage)
// 2. init storage-manager (which fetches FF overrides stored on the server)
// 3. call applyPersistedOverrides, which updates sessionData.featureFlags
//    and featureFlagClient with the server-supplied overrides.

/**
 * Determine whether there are any persisted FF overrides
 */
export const hasOverrides = () => {
	return (
		confluenceLocalStorageInstance &&
		confluenceLocalStorageInstance.doesContain(OVERRIDES_STORAGE_KEY)
	);
};

/**
 * Get persisted overrides from storage-manager.
 */
export const getOverrides = (): FeatureFlagsType => {
	if (!hasOverrides()) {
		return {};
	}

	const raw = confluenceLocalStorageInstance.getItem(OVERRIDES_STORAGE_KEY);

	try {
		return raw ? JSON.parse(raw) : {};
	} catch (_) {
		return {};
	}
};

/**
 * After storage-manager has been initialized, this function is called to
 * apply persisted overrides to both sessionData.featureFlags and featureFlagClient.
 * It returns an object with ALL feature flags.
 */
export const applyPersistedOverrides = (featureFlags: FeatureFlagsType): FeatureFlagsType => {
	const overrides = getOverrides();

	return Object.keys(featureFlags).reduce((acc: FeatureFlagsType, key) => {
		acc[key] = overrides.hasOwnProperty(key) ? overrides[key] : featureFlags[key];
		return acc;
	}, {});
};

/**
 * Mutate sessionData, adding overrides to featureFlags and featureFlagClient.
 */
export const updateSessionData = (
	sessionData: SessionDataType,
	featureFlagsWithOverrides: FeatureFlagsType,
) => {
	// FeatureFlagClient requires a slightly different format than featureFlags:
	// { <key>: { value: <val> } } instead of just { <key>: <val> }
	const normalizedFeatureFlagsWithOverrides = Object.keys(featureFlagsWithOverrides).reduce(
		(acc: Flags, key) => {
			// This is a dirty hack - accessing a private method `getFlagWrapper`
			// TODO need to figure out a way to properly pass and preserve `explanation` field of a featureFlag
			const previousFlagState = (sessionData.featureFlagClient as any).getFlagWrapper(key).flag;

			acc[key] = {
				...previousFlagState,
				value: featureFlagsWithOverrides[key],
			};
			return acc;
		},
		{},
	);

	sessionData.featureFlags = featureFlagsWithOverrides;
	sessionData.featureFlagClient.setFlags(normalizedFeatureFlagsWithOverrides);
};

const setOverrides = (func: (overrides: { [key: string]: any }) => { [key: string]: any }) => {
	if (!confluenceLocalStorageInstance) {
		logger.error`Unable to set persisted FF overrides. storage-manager not available.`;
		return;
	}

	const overrides = getOverrides();
	const result = func(overrides);

	try {
		// Persist the overrides to the server. Note: When result is an empty object,
		// we still persist it. If we used confluenceLocalStorageInstance.removeItem()
		// it wouldn't actually persist the data back to the server, as that method
		// only removes it from local storage.
		confluenceLocalStorageInstance.setItem(OVERRIDES_STORAGE_KEY, JSON.stringify(result));
	} catch (_) {
		logger.error`Unable to set persisted FF overrides. key = "${OVERRIDES_STORAGE_KEY}", value = "${JSON.stringify(
			result,
		)}"`;
	}

	return result;
};

export const setOverride = (key: string, value: any) =>
	setOverrides((overrides: { [key: string]: any }) => {
		overrides[key] = value;
		return overrides;
	});

export const removeOverride = (key: string) =>
	setOverrides((overrides: { [key: string]: any }) => {
		delete overrides[key];
		return overrides;
	});

export const removeAllOverrides = () =>
	setOverrides(() => {
		return {};
	});
