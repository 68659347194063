import { createStore, createHook, createContainer } from 'react-sweet-state';

const Store = createStore({
	initialState: {
		isContentReady: false,
		contentLoading: {},
	},
	actions: {
		onContentRendered:
			() =>
			({ setState }) => {
				setState({
					isContentReady: true,
				});
			},

		addContentToBeLoaded:
			(content, value) =>
			({ setState, getState }) => {
				const newContentLoading: any = getState().contentLoading;
				newContentLoading[content] = value;
				setState({
					contentLoading: newContentLoading,
				});
			},
		removeContentFromLoading:
			(content) =>
			({ setState, getState }) => {
				const existingContentLoading: any = getState().contentLoading;
				existingContentLoading[content] && delete existingContentLoading[content];

				setState({
					contentLoading: existingContentLoading,
				});
			},
	},
	name: 'isContentReady',
});

export const ContentStateContainer = createContainer(Store);
export const useContentState = createHook(Store);
