import gql from 'graphql-tag';

export const CompanyHubAnalyticsPageQuery = gql`
	query CompanyHubAnalyticsPageQuery($key: String!) {
		space(key: $key) {
			id
			history {
				createdDate
			}
			homepage {
				id
				operations {
					operation
				}
			}
		}
	}
`;
