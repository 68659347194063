import uuid from 'uuid/v4';

import { type Logger } from '../resiliencedb';
import getBatchableQueue, {
	type BatchableQueue,
	type BatchFlushCallback,
	type RetryQueueOptions,
} from '../resilienceQueue';
import getMetricsCollector, { type MetricsCollector } from '../resilienceQueue/Metrics';
import { type Context, type EventOverrides } from '../types';
import { attachXidToMultipleEvents, type XIDPromise } from '../xid';

import { DEFAULT_REQUEST_TIMEOUT } from './defaults';
import { sendEvents } from './sendEvents';
import {
	type BaseSegmentEvent,
	type LibraryMetadataDef,
	type PackagedEvent,
	type SegmentBatchDef,
	type SegmentEvent,
	SegmentEventTypes,
	type SegmentIdentifyEventDef,
	type SegmentIdentifyEventTraitsDef,
	type SegmentProperties,
	type SegmentScreenEventDef,
	type SegmentTrackEventDef,
	type SegmentTrackPropertiesDef,
} from './types';
import User from './user';
import { buildContext, prepareEventProperties } from './util';

export type Options = {
	apiHost: string;
	apiHostProtocol: string;
	retryQueueOptions?: RetryQueueOptions;
	retryQueuePrefix: string;
	product: string;
	requestTimeout?: number;
	xidPromiseGetter: () => ReturnType<typeof XIDPromise>;
	logger?: Logger;
	disableCookiePersistence?: boolean;
};

export default class EventProcessor {
	private user: User;
	private options: Required<Options>;

	private resilienceQueue: BatchableQueue<PackagedEvent>;

	private gasv3BatchUrl: string;
	private metrics: MetricsCollector;
	private xidPromiseCallback: ReturnType<typeof XIDPromise>;

	constructor(options: Options) {
		this.options = {
			...options,
			requestTimeout: options.requestTimeout || DEFAULT_REQUEST_TIMEOUT,
			retryQueueOptions: options.retryQueueOptions || {},
			logger: options.logger || console,
			disableCookiePersistence: options.disableCookiePersistence || false,
		};
		this.user = new User(this.options?.disableCookiePersistence);

		this.xidPromiseCallback = options.xidPromiseGetter();
		this.gasv3BatchUrl = `${options.apiHostProtocol}://${options.apiHost}/batch`;
		this.metrics = getMetricsCollector();

		this.resilienceQueue = getBatchableQueue(
			options.retryQueuePrefix,
			options.product,
			this.options.retryQueueOptions,
			this.options.logger,
		);
		this.resilienceQueue.start(this.sendEvents);
	}

	getUser(): User {
		return this.user;
	}

	async track(
		eventName: string,
		builtEvent: SegmentTrackPropertiesDef & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.TRACK, builtEvent);
		const eventWithoutMessageId: Omit<SegmentTrackEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.TRACK,
			properties: prepareEventProperties(builtEvent),
			event: eventName,
		};
		const event: SegmentTrackEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);

		await this.resilienceQueue.addItem(packagedEvent);
		if (callback) {
			callback();
		}
	}

	async page(
		eventName: string,
		builtEvent: SegmentProperties & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.PAGE, builtEvent);
		const eventWithoutMessageId: Omit<SegmentScreenEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.PAGE,
			properties: prepareEventProperties(builtEvent),
			name: eventName,
		};
		const event: SegmentScreenEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);

		await this.resilienceQueue.addItem(packagedEvent);
		if (callback) {
			callback();
		}
	}

	// Segment uses the identifier to update user id which we have already done in the analyticsWebClient.ts
	async identify(
		_identifier: string,
		builtEvent: SegmentIdentifyEventTraitsDef & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.IDENTIFY, builtEvent);
		const eventWithoutMessageId: Omit<SegmentIdentifyEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.IDENTIFY,
			traits: prepareEventProperties(builtEvent),
		};
		const event: SegmentIdentifyEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);
		await this.resilienceQueue.addItem(packagedEvent);
		if (callback) {
			callback();
		}
	}

	private buildBaseEvent(
		context: Context,
		type: SegmentEventTypes,
		overrides: EventOverrides,
	): Omit<BaseSegmentEvent, 'messageId'> {
		const clonedContext = prepareEventProperties(context);
		const segmentContext = buildContext(clonedContext);
		return {
			context: segmentContext,
			timestamp: new Date().toISOString(),
			type,
			userId: this.user.getUserId(),
			anonymousId: overrides.anonymousId || this.user.getAnonymousId(),
		};
	}

	private createMessageId(): string {
		return `ajs-${uuid()}`;
	}

	private packageEvent(event: SegmentEvent): PackagedEvent {
		const { apiHost, apiHostProtocol } = this.options;
		return {
			headers: {
				'Content-Type': 'text/plain',
			},
			msg: event,
			url: `${apiHostProtocol}://${apiHost}/${event.type.charAt(0)}`,
		};
	}

	// Using anonymous function so it can have the BatchFlushCallback type associated with it
	// And to allow it to not need bind when passing through to ResilieceQueue.
	public sendEvents: BatchFlushCallback<PackagedEvent> = async (items, callback) => {
		const httpRetryCount = this.resilienceQueue.getGlobalRetryCount();
		const metricsPayload = this.metrics.getMetricsPayload();
		const metadata: LibraryMetadataDef = {
			...metricsPayload,
			httpRetryCount,
		};
		for (let key in metadata) {
			// @ts-ignore Some keys maybe a string, but these will never equal 0
			if (metadata[key] === 0) {
				// @ts-ignore Save space in requests by removing metrics with no impact
				delete metadata[key];
			}
		}

		const eventsWithXID = await this.attachXIDs(items);

		// Calculating sentAt after the XID generation as this may take some time.
		const sentAt = new Date().toISOString();
		const unpackagedEvents = eventsWithXID.map((item) => {
			item.msg.sentAt = sentAt;
			return item.msg;
		});

		const batchBody: SegmentBatchDef = {
			batch: unpackagedEvents,
			sentAt,
			metadata,
		};

		try {
			const response = await sendEvents({
				url: this.gasv3BatchUrl,
				batch: batchBody,
				timeout: this.options.requestTimeout,
			});

			this.metrics.subtractFromMetrics(metricsPayload);
			callback(null, response);
		} catch (error) {
			callback(error, null);
		}
	};

	private async attachXIDs(items: PackagedEvent[]): Promise<PackagedEvent[]> {
		if (this.xidPromiseCallback) {
			return attachXidToMultipleEvents(items, this.xidPromiseCallback);
		}
		return Promise.resolve(items);
	}
}
