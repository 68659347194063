import { getLogger } from '@confluence/logger';
import { parseScriptTags } from '@confluence/dom-helpers';

const logger = getLogger('wrm');

export function loadResources(
	container: HTMLElement,
	tags: string | string[],
	// Process tags as an array of links when linksMode = true
	// We don't have tags info in CustomHeaderFooter.
	linksMode = false,
): Promise<any> {
	if (!tags.length) {
		return Promise.resolve();
	}

	const fragment = window.document.createDocumentFragment();
	const promises = parseScriptTags(tags, linksMode).map(
		({ src, ...attributes }) =>
			new Promise((resolve, reject) => {
				if (window.document.querySelector(`script[data-wrm-key='${attributes['data-wrm-key']}']`)) {
					// next/packages/content-renderer/src/TinyMCEServerFullRenderer.tsx will put script as defer to <head>
					// We don't want to re-initialize those scripts when rehydrate
					resolve(null);
				} else {
					const scriptElement = window.document.createElement('script');
					scriptElement.async = false;
					scriptElement.src = src;
					scriptElement.onload = resolve;
					scriptElement.onerror = reject;
					Object.keys(attributes).forEach((attributeKey) =>
						scriptElement.setAttribute(attributeKey, attributes[attributeKey]),
					);
					fragment.appendChild(scriptElement);
				}
			}),
	);
	container.appendChild(fragment);

	return Promise.all(promises).catch((...args) => {
		/* istanbul ignore next */
		if (process.env.NODE_ENV !== 'production') {
			logger.error`Error when loading resources ${args}`;
		}
	});
}
