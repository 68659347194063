import type { CustomValues } from '@atlassian/browser-metrics/types';
/**
 * Helper function to map nested objects into custom data that can be passed to
 * the BM library.
 *
 * Nested values are separated by `:`, and arrays have values joined with `,`.
 * For example:
 *
 * ```typescript
 * const foo = {
 *   bar: 1,
 *   buz: {
 *     bat: "test",
 *     bing: null,
 *   },
 *   barf: [1, 2, 3, 4]
 * };
 * ```
 *
 * will be converted to
 *
 * ```typescript
 * const transformedFoo = {
 *   "bar": 1,
 *   "buz:bat": "test",
 *   "buz:bing": null,
 *   "barf": "1,2,3,4",
 * };
 * ```
 * @param obj
 */
export const transformObjectToFlattenCustomValuesType = (obj: unknown): CustomValues => {
	if (typeof obj !== 'object' || !obj) {
		return {};
	}

	const recursivelyBuildCustomValues = <T extends {}>(obj: T): CustomValues => {
		const customValues: CustomValues = {};

		Object.entries(obj).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				customValues[key] = value.map((e) => JSON.stringify(e)).join(',');
			} else if (typeof value === 'object' && value) {
				const nestedValues = recursivelyBuildCustomValues(value);

				Object.entries(nestedValues).forEach(([nestedKey, nestedValue]) => {
					customValues[`${key}:${nestedKey}`] = nestedValue;
				});
			} else {
				if (typeof value === 'number') {
					customValues[key] = Math.round(value);
				} else if (typeof value === 'boolean') {
					customValues[key] = value;
				} else {
					customValues[key] = String(value);
				}
			}
		});

		return customValues;
	};

	return recursivelyBuildCustomValues(obj);
};
