import { query } from '@confluence/query-preloader-tools';

import {
	ONBOARDING_EXPERIENCES,
	TEMPLATES_COLLECTION,
} from '../../constants/onboarding-state/onboarding-constants';

import { getOnboardingStateGQL } from './OnboardingStatus.graphql';

export const preloadTemplatesCollectionOnboardingState = () => {
	return query({
		query: getOnboardingStateGQL,
		variables: {
			key: Object.values({
				...TEMPLATES_COLLECTION,
				...ONBOARDING_EXPERIENCES,
			}),
		},
	});
};
