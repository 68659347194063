import { namedRoutes } from '@confluence/named-routes';
import { Route } from '@confluence/route';
import { getContextAwareFullPath } from '@confluence/route-manager/entry-points/getContextAwareFullPath';

import mapRoutesToScreenName from './mapRoutesToScreenName';

const routes = Object.values(mapRoutesToScreenName(namedRoutes));

function replaceParams(token) {
	return token.replace(/^:\w+/g, '').replace(/\(.+?\)/g, '');
}

// Export for testing
export function pathDefinitionComparator(a, b) {
	const tokensA = a.definition.pattern.split('/');
	const tokensB = b.definition.pattern.split('/');

	for (let i = 0; i < tokensA.length || i < tokensB.length; i++) {
		if (i < tokensA.length && i < tokensB.length) {
			const lengthA = replaceParams(tokensA[i]).length;
			const lengthB = replaceParams(tokensB[i]).length;
			if (lengthA < lengthB) {
				return 1;
			} else if (lengthA > lengthB) {
				return -1;
			}
		} else if (i < tokensA.length) {
			return -1;
		} else {
			return 1;
		}
	}

	return 0;
}

export default (pathname) => {
	if (typeof pathname !== 'string') {
		if (process.env.NODE_ENV !== 'production') {
			console.error('pathname for matchPathFromRoutes must be a string');
		}
		return { match: null, routeName: '' };
	}

	const matches = [];

	for (const definition of routes) {
		if (!(definition instanceof Route)) {
			continue;
		}

		const match = definition.match(getContextAwareFullPath(pathname, true));

		if (match) {
			matches.push({ definition, match });
		}
	}

	if (matches.length >= 1) {
		let match = matches[0];

		if (matches.length > 1) {
			// Match as many literals as possible
			matches.sort(pathDefinitionComparator);
			match = matches[0];
		}

		return {
			match: match.match,
			routeName: match.definition.name,
			screenName: match.definition.screenName || '',
		};
	}

	return {
		match: null,
		routeName: '',
		screenName: '',
	};
};
