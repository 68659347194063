import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	propertyPanelVariableDisplayString: {
		id: 'template-variable.property.panel.variable.display.string',
		defaultMessage: 'Text',
		description:
			'The name of variable type `Text` for the button in the list of variable display of template variable dialog',
	},
	propertyPanelVariableDisplayStringTooltip: {
		id: 'template-variable.property.panel.variable.display.string.tooltip',
		defaultMessage: 'Single line text input field.',
		description:
			'The tooltip of variable type `Text` for the button in the list of variable display of template variable dialog',
	},
	propertyPanelVariableDisplayTextArea: {
		id: 'template-variable.property.panel.variable.display.textarea',
		defaultMessage: 'Multi-line Text',
		description:
			'The name of variable type `Multi-line Text` for the button in the list of variable display of template variable dialog',
	},
	propertyPanelVariableDisplayTextAreaTooltip: {
		id: 'template-variable.property.panel.variable.display.textarea.tooltip',
		defaultMessage: 'Multi-line text input field.',
		description:
			'The tooltip of variable type `Multi-line Text` for the button in the list of variable display of template variable dialog',
	},
	propertyPanelVariableDisplayList: {
		id: 'template-variable.property.panel.variable.display.list',
		defaultMessage: 'List',
		description:
			'The name of variable type `List` for the button in the list of variable display of template variable dialog',
	},
	propertyPanelVariableDisplayListTooltip: {
		id: 'template-variable.property.panel.variable.display.list.tooltip',
		defaultMessage: 'Drop down list.',
		description:
			'The tooltip of variable type `List` for the button in the list of variable display of template variable dialog',
	},
	templateEditorChangingVariableDialogTitle: {
		id: 'template-variable.template.editor.changing.variable.dialog.title',
		defaultMessage: 'Edit template variable',
		description: 'The title of edit template variable dialog',
	},
	templateEditorVariableNameField: {
		id: 'template-variable.template.editor.variable.name.field',
		defaultMessage: 'Variable name',
		description: 'The variable name field of edit template variable dialog',
	},
	propertyPanelVariableDisplayTextAreaRowsTooltip: {
		id: 'template-variable.property.panel.variable.textarea.rows.tooltip',
		defaultMessage: 'Number of rows to display for the field.',
		description: 'The tooltip of text area multiline rows of edit template variable dialog',
	},
	propertyPanelVariableDisplayTextAreaColumnsTooltip: {
		id: 'template-variable.property.panel.variable.textarea.columns.tooltip',
		defaultMessage: 'Number of columns to display for the field.',
		description: 'The tooltip of text area multi line columns of edit template variable dialog',
	},
	propertyPanelVariableListValuesTooltip: {
		id: 'template-variable.property.panel.variable.list.values.tooltip',
		defaultMessage: 'Enter each item for the list, separating each with a comma.',
		description: 'The tooltip of select list type of edit template variable dialog',
	},
	templateEditorVariableListFieldPlaceholder: {
		id: 'template-variable.template.editor.variable.list.field.placeholder',
		defaultMessage: 'option 1, option 2, option 3',
		description: 'The placeholder of select list type of edit template variable dialog',
	},
	templateEditorVariableSaveButtonLabel: {
		id: 'template-variable.template.editor.variable.save.button.label',
		defaultMessage: 'Save',
		description: 'The button label of save button in edit template variable dialog',
	},
	templateVariableDefault: {
		id: 'template-variable.template.editor.variable.name.default',
		defaultMessage: 'variable_name',
		description: 'The default name field of a template variable dialog',
	},
});
