import React, { type ReactElement, useState, useContext } from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import { WidthContext } from '../../../context';
import { buttonShouldFitContainer } from '../../UIKit1/button/breakpoints';
import Icon from '../../UIKit1/icon';

export const AkButton = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.button/custom-theme-button' */
			'@atlaskit/button/custom-theme-button'
		),
);

type PropsOf<T extends (props: any) => React.ReactNode> = Parameters<T>[0];

export function useNativeActionButtons({
	forgeDoc,
}: // actionButton is of type ReactElement because the @forge/react custom reconciler
//does not reconcile components in props into ForgeDoc
Omit<Parameters<RenderFn>[0], 'forgeDoc'> & { forgeDoc: ReactElement[] }): {
	akActionButtonProps: Array<PropsOf<typeof AkButton>>;
} {
	const [loading, setLoading] = useState(forgeDoc.map(() => false));
	const width = useContext(WidthContext);
	const shouldFitContainer = buttonShouldFitContainer(width);

	const actionButtons = forgeDoc.map((actionButton, index) => {
		const {
			props: {
				children,
				appearance = 'subtle',
				disabled,
				onClick,
				icon,
				iconPosition = 'before',
			} = {},
		} = actionButton;

		const handleClick = async () => {
			setLoading(
				loading.map((l, i) => {
					if (i === index) {
						return true;
					}
					return l;
				}),
			);
			try {
				await onClick();
			} finally {
				setLoading(
					loading.map((l, i) => {
						if (i === index) {
							return false;
						}
						return l;
					}),
				);
			}
		};

		let iconDom: React.ReactChild | undefined;
		if (icon) {
			iconDom = <Icon size="medium" glyph={icon} />;
		}

		const iconBefore = iconPosition === 'before' ? iconDom : undefined;
		const iconAfter = iconPosition === 'after' ? iconDom : undefined;

		return {
			shouldFitContainer,
			iconBefore,
			iconAfter,
			isLoading: loading[index],
			isDisabled: disabled,
			appearance,
			onClick: handleClick,
			children,
		};
	});

	return {
		akActionButtonProps: actionButtons,
	};
}
