import { defineMessages } from 'react-intl-next';
import type { IntlShape } from 'react-intl-next';
import memoize from 'memoize-one';

import { EDITOR_ONBOARDING_BLUEPRINT_KEY } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

import {
	FEATURED_CATEGORY_ID,
	MY_TEMPLATES_CATEGORY_ID,
	STARRED_CATEGORY_ID,
} from './categoryUtils';
import type { Category } from './categoryUtils';
import { SortOrders } from './SortByDropdown';

export type TemplateUtilsTemplate = {
	templateId: string | null;
	contentBlueprintId: string | null;
	blueprintModuleCompleteKey: string | null;
	itemModuleCompleteKey: string | null;
	templateType: string | null;
	name: string | null;
	description: string | null;
	author: string | null;
	isFavourite?: boolean | null;
	isPromoted?: boolean | null;
	hasGlobalBlueprintContent?: boolean;
	categoryIds: (string | null)[];
	keywords: (string | null)[] | null;
};

const i18n = defineMessages({
	featuredCategoryName: {
		id: 'template-utils.featured.category.name',
		defaultMessage: 'Featured',
		description: 'Text for the name of `Featured` category',
	},
	myTemplatesCategoryName: {
		id: 'template-utils.my-templates.category.name',
		defaultMessage: 'My Templates',
		description: 'Text for the name of `My Templates` category',
	},
	starredCategoryName: {
		id: 'template-utils.starred.category.name',
		defaultMessage: 'Starred',
		description: 'Text for the name of `Starred` category',
	},
	promotedCategoryName: {
		id: 'template-utils.promoted.category.name',
		defaultMessage: 'Promoted',
		description: 'Text for the name of `Promoted` category',
	},
});

export const BLOG_CONTENT_BLUEPRINT_ID = '00000000-0000-0000-0000-000000000001';
// blueprints/templates in uuid form are technically a new template
export const isBlueprint = (templateId: string) => !/^[0-9]+$/.test(templateId);
export const isNewTemplate = (templateId) => !templateId || templateId === '';

type GetTemplateKeyTemplate = Pick<
	TemplateUtilsTemplate,
	'blueprintModuleCompleteKey' | 'templateId' | 'itemModuleCompleteKey'
>;
export const getTemplateKey = (template: GetTemplateKeyTemplate): string => {
	return (
		template?.blueprintModuleCompleteKey ||
		template?.templateId ||
		template?.itemModuleCompleteKey ||
		''
	);
};

type GetTemplateIdTemplate = Pick<TemplateUtilsTemplate, 'contentBlueprintId' | 'templateId'>;
export const getTemplateId = (template: GetTemplateIdTemplate): string => {
	return template.contentBlueprintId || template.templateId || '';
};

type GetTemplateIdForUserPreferencesTemplate = GetTemplateIdTemplate &
	Pick<
		TemplateUtilsTemplate,
		| 'templateType'
		| 'hasGlobalBlueprintContent'
		| 'contentBlueprintId'
		| 'blueprintModuleCompleteKey'
	>;
export const getTemplateIdForUserPreferences = (
	template: GetTemplateIdForUserPreferencesTemplate | null,
): string | null => {
	if (!template) {
		return null;
	}
	if (
		template.templateType === 'blueprint' &&
		template.hasGlobalBlueprintContent &&
		template.contentBlueprintId !== BLOG_CONTENT_BLUEPRINT_ID
	) {
		return template.blueprintModuleCompleteKey;
	}
	return getTemplateId(template);
};

export const getReorderedTemplates = <T extends TemplateUtilsTemplate>(
	templates: T[],
	selectedSortOrder: string,
) => {
	const nonFeaturedTemplates: T[] = [];
	const promotedTemplates: T[] = [];
	const customTemplates: T[] = [];

	templates.forEach((template: T) => {
		if (
			template.categoryIds.includes('template.category.promoted') ||
			template.categoryIds.includes('template.category.custom')
		) {
			if (!template.categoryIds.includes(FEATURED_CATEGORY_ID)) {
				template.categoryIds.push(FEATURED_CATEGORY_ID);
			}

			// We want to show all promoted templates first, regardless of
			// whether or not the template is custom
			if (template.categoryIds.includes('template.category.promoted')) {
				promotedTemplates.push(template);
			} else {
				customTemplates.push(template);
			}
		} else {
			nonFeaturedTemplates.push(template);
		}
	});

	// If alphabetical sort is on, we don't care to show featured categories first
	if (selectedSortOrder !== SortOrders.ALPHABETICAL_SORT) {
		return [...promotedTemplates, ...customTemplates, ...nonFeaturedTemplates];
	} else {
		return templates;
	}
};

export const mapMyTemplates = <T extends TemplateUtilsTemplate>(
	templates: T[],
	currentUserId: string,
	additionalTemplateIdsList?: Set<string | null>,
) => {
	templates.forEach((t: T) => {
		const isStarredTemplateOrCreatedByCurrentUser =
			t.categoryIds.includes(STARRED_CATEGORY_ID) ||
			// checks for recently unstarred templates that we still want to display
			additionalTemplateIdsList?.has(getTemplateIdForUserPreferences(t)) ||
			t.author === currentUserId;

		if (
			isStarredTemplateOrCreatedByCurrentUser &&
			!t.categoryIds.includes(MY_TEMPLATES_CATEGORY_ID)
		) {
			t.categoryIds.push(MY_TEMPLATES_CATEGORY_ID);
		}
	});
};

export const getFeaturedCategoryCount = <T extends TemplateUtilsTemplate>(templates: T[]) =>
	templates?.filter(
		(template) =>
			template.categoryIds.includes('template.category.promoted') ||
			template.categoryIds.includes('template.category.custom'),
	).length;

export const getMyTemplatesCategoryCount = <T extends TemplateUtilsTemplate>(
	templates: T[],
	currentUserId: string,
) =>
	templates?.filter(
		(template) =>
			template.categoryIds.includes(STARRED_CATEGORY_ID) || template.author === currentUserId,
	).length;

export const getExistingCategoryIds = (
	availableCategories: Category[],
	lastSelectedCategoryIds: (string | null)[],
) =>
	availableCategories
		.filter((category) => lastSelectedCategoryIds.includes(category.id))
		.map((category) => category.id);

export const filterTemplatesByStarCategory = memoize(
	<T extends TemplateUtilsTemplate>(
		templates: T[],
		additionalTemplateIdsList: Set<string | null>,
	) => {
		return templates.filter(
			(t) =>
				t.categoryIds.includes(STARRED_CATEGORY_ID) ||
				additionalTemplateIdsList.has(getTemplateIdForUserPreferences(t)),
		);
	},
);

export const filterTemplatesByCategoryIds = <T extends TemplateUtilsTemplate>(
	templates: T[],
	categoryIds,
	selectedSortOrder: string,
	currentUserId: string,
	additionalTemplateIdsList?: Set<string | null>,
) => {
	if (!categoryIds.length) {
		return templates;
	}

	if (categoryIds?.includes(MY_TEMPLATES_CATEGORY_ID)) {
		mapMyTemplates(templates, currentUserId, additionalTemplateIdsList);
	}

	const isFeaturedCategorySelected = categoryIds?.includes(FEATURED_CATEGORY_ID);

	const templatesToBeFiltered = isFeaturedCategorySelected
		? getReorderedTemplates(templates, selectedSortOrder)
		: templates;

	return templatesToBeFiltered?.filter((template) =>
		template?.categoryIds?.some(
			(categoryId) =>
				categoryIds?.includes(categoryId) ||
				additionalTemplateIdsList?.has(getTemplateIdForUserPreferences(template)),
		),
	);
};

const getTemplateSearchQueryKeywordsFilter = (
	categoryIds,
	keywords,
	lowercaseQuery,
	intl,
	author,
	isFavourite,
	isPromoted,
	template,
	currentUserId,
	additionalUnstarredTemplateIdsList?,
): boolean => {
	const isRecentlyUnstarred =
		additionalUnstarredTemplateIdsList &&
		additionalUnstarredTemplateIdsList?.has(getTemplateIdForUserPreferences(template));
	if (
		(!keywords.includes(intl.formatMessage(i18n.starredCategoryName)) && isFavourite) ||
		isRecentlyUnstarred
	) {
		keywords.push(intl.formatMessage(i18n.starredCategoryName));
	} else if (!keywords.includes(intl.formatMessage(i18n.promotedCategoryName)) && isPromoted) {
		keywords.push(intl.formatMessage(i18n.promotedCategoryName));
	}

	const isFeaturedCategoryTemplate =
		categoryIds.includes('template.category.promoted') ||
		categoryIds.includes('template.category.custom');
	const isMyTemplatesCategoryTemplate =
		categoryIds.includes(STARRED_CATEGORY_ID) || author === currentUserId;

	if (
		!keywords.includes(intl.formatMessage(i18n.featuredCategoryName)) &&
		isFeaturedCategoryTemplate
	) {
		keywords.push(intl.formatMessage(i18n.featuredCategoryName));
	}
	if (
		!keywords.includes(intl.formatMessage(i18n.myTemplatesCategoryName)) &&
		isMyTemplatesCategoryTemplate
	) {
		keywords.push(intl.formatMessage(i18n.myTemplatesCategoryName));
	}

	const keywordsIncludesSearchQuery = (keyword) => keyword?.toLowerCase().includes(lowercaseQuery);
	return keywords.some(keywordsIncludesSearchQuery);
};

type SearchQueryFilterTemplate = Pick<
	TemplateUtilsTemplate,
	| 'name'
	| 'description'
	| 'author'
	| 'keywords'
	| 'categoryIds'
	| 'isFavourite'
	| 'isPromoted'
	| 'blueprintModuleCompleteKey'
>;
type SearchQueryFilter = <T extends SearchQueryFilterTemplate>(template: T) => boolean;
export const getTemplateSearchQueryFilter = (
	query: string,
	intl: IntlShape,
	currentUserId,
	additionalUnstarredTemplateIdsList?: Set<string | null>,
): SearchQueryFilter => {
	return (template) => {
		const lowercaseQuery = query.toLowerCase();

		const name = template.name || '';
		const description = template.description || '';
		const author = template.author || '';
		const keywords = template.keywords || [];
		const categoryIds = template.categoryIds || [];
		const isFavourite = template.isFavourite || false;
		const isPromoted = template.isPromoted || false;
		const blueprintAuthor = template.blueprintModuleCompleteKey ? author : '';

		const keywordsIncludeSearchQuery: boolean = getTemplateSearchQueryKeywordsFilter(
			categoryIds,
			keywords,
			lowercaseQuery,
			intl,
			author,
			isFavourite,
			isPromoted,
			template,
			currentUserId,
			additionalUnstarredTemplateIdsList,
		);

		return (
			name.toLowerCase().includes(lowercaseQuery) ||
			description.toLowerCase().includes(lowercaseQuery) ||
			blueprintAuthor.toLowerCase().includes(lowercaseQuery) ||
			keywordsIncludeSearchQuery
		);
	};
};

export const filterTemplatesBySearchQuery = memoize(
	<T extends SearchQueryFilterTemplate>(
		templates: T[],
		intl: IntlShape,
		currentUserId,
		searchQuery?: string | null,
		additionalUnstarredTemplateIdsList?: Set<string | null>,
	): T[] => {
		if (searchQuery) {
			const searchQueryFilter = getTemplateSearchQueryFilter(
				searchQuery,
				intl,
				currentUserId,
				additionalUnstarredTemplateIdsList,
			);
			templates = templates.filter(searchQueryFilter);
		}

		return templates;
	},
);

export const getSearchQueryMatches = <T extends SearchQueryFilterTemplate>(
	template: T,
	intl: IntlShape,
	currentUserId,
	searchQuery?: string,
) => {
	if (!searchQuery) {
		return {
			searchQueryMatchesTitle: false,
			searchQueryMatchesDescription: false,
			searchQueryMatchesAuthor: false,
			searchQueryMatchesKeywords: false,
		};
	}

	const lowercaseQuery = searchQuery.toLowerCase();

	const name = template.name || '';
	const description = template.description || '';
	const author = template.author || '';
	const keywords = template.keywords || [];
	const categoryIds = template.categoryIds || [];
	const isFavourite = template.isFavourite;
	const isPromoted = template.isPromoted;
	const keywordsIncludeSearchQuery: boolean = getTemplateSearchQueryKeywordsFilter(
		categoryIds,
		keywords,
		lowercaseQuery,
		intl,
		author,
		isFavourite,
		isPromoted,
		template,
		currentUserId,
	);

	return {
		searchQueryMatchesTitle: name.toLowerCase().includes(lowercaseQuery),
		searchQueryMatchesDescription: description.toLowerCase().includes(lowercaseQuery),
		searchQueryMatchesAuthor: author.toLowerCase().includes(lowercaseQuery),
		searchQueryMatchesKeywords: keywordsIncludeSearchQuery,
	};
};

export const filterEditorTutorialTemplate = memoize(
	<T extends TemplateUtilsTemplate>(
		templates: (T | null)[],
		includeEditorTutorialTemplate: boolean,
		selectedSortOrder: string,
	): T[] => {
		// filter out null templates
		const nonNullTemplates: T[] = templates.filter((template): template is T => !!template);
		const tutorialIndex = nonNullTemplates.findIndex(
			(element) =>
				// only one should appear at the same time
				element.blueprintModuleCompleteKey === EDITOR_ONBOARDING_BLUEPRINT_KEY,
		);

		if (tutorialIndex === -1) {
			return nonNullTemplates;
		}

		const tutorial = nonNullTemplates[tutorialIndex];

		// Remove the editor tutorial template if it shouldn't be included
		if (!includeEditorTutorialTemplate) {
			nonNullTemplates.splice(tutorialIndex, 1);
			return nonNullTemplates;
		}

		// If the sorting order is not alphabetical
		if (selectedSortOrder !== SortOrders.ALPHABETICAL_SORT) {
			// Remove the tutorial template, and then add it to the front.
			nonNullTemplates.splice(tutorialIndex, 1);
			nonNullTemplates.unshift(tutorial);
		}
		return nonNullTemplates;
	},
);

export const filterTemplatesByAllowList = memoize(
	<Type extends TemplateUtilsTemplate>(templates: Type[], allowList: string[]) => {
		if (!allowList.length) return templates;

		return templates.filter((template) => allowList.includes(getTemplateKey(template)));
	},
);

export const getTemplateIdAttributesForAnalytics = (template: GetTemplateKeyTemplate) => {
	if (template.blueprintModuleCompleteKey) {
		return {
			idType: 'blueprint',
			id: template.blueprintModuleCompleteKey,
		};
	}
	if (template.templateId) {
		return {
			idType: 'template',
			id: template.templateId,
		};
	}
	return {
		idType: 'other',
		id: template.itemModuleCompleteKey,
	};
};

type UpdateFavouriteTemplatesTemplate = GetTemplateIdForUserPreferencesTemplate &
	Pick<TemplateUtilsTemplate, 'categoryIds'>;
// Update the templatelist using userpref as the source of truth for starring
export const updateFavouriteTemplates = <T extends UpdateFavouriteTemplatesTemplate>(
	templates: (T | null)[],
	favouritedTemplatesList?: (string | null)[],
): T[] => {
	// filter out null templates
	const filteredTemplates = templates.filter((template): template is T => !!template);

	if (!favouritedTemplatesList) {
		return filteredTemplates;
	}

	return filteredTemplates.map((template) => {
		const isFavourite = favouritedTemplatesList.some(
			(favouritedTemplateId) => favouritedTemplateId === getTemplateIdForUserPreferences(template),
		);

		// avoid re-adding starred category to a template that has already been starred
		const categoryIds = template.categoryIds.filter((category) => category !== STARRED_CATEGORY_ID);
		if (isFavourite) categoryIds.push(STARRED_CATEGORY_ID);

		return {
			...template,
			isFavourite,
			categoryIds,
		};
	});
};

export const filterOutLiveMeetingNotesBlueprint = <T extends TemplateUtilsTemplate>(
	templates: (T | null)[],
): (T | null)[] => {
	return templates.filter(
		(template) =>
			template?.blueprintModuleCompleteKey !==
			'com.atlassian.confluence.plugins.confluence-business-blueprints:meeting-notes-live-page-blueprint',
	);
};
