import type { CSSProperties, ComponentType } from 'react';
import React from 'react';

import { getOverride } from '@confluence/meta';
import { PageTitle } from '@confluence/page-title';

/* eslint-disable import/no-default-export */
export default function withDocumentTitle(
	getTitle: (props: any) => string | null | undefined,
	wrapperStyle?: CSSProperties,
) {
	if (typeof wrapperStyle === 'undefined') {
		wrapperStyle = {};
	}

	return (BaseComponent: ComponentType<any>) => (props: any) => {
		const title = getTitle(props);
		const siteTitle = getOverride('site-title') || '';
		const titleDefinedOnWindow = window['__DOCUMENT_TITLE_TAG__'] || '';
		let titleProp;
		if (titleDefinedOnWindow) {
			titleProp = titleDefinedOnWindow;
		} else if (title && siteTitle) {
			titleProp = `${title} - ${siteTitle}`;
		} else {
			titleProp = title || siteTitle;
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div style={wrapperStyle} data-testid="with-document-title">
				{!window['__SSR_RENDERED__'] && <PageTitle title={titleProp} />}
				<BaseComponent {...props} />
			</div>
		);
	};
}
