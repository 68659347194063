import React from 'react';

import type { ExtensionHandlers, ExtensionParams } from '@atlaskit/editor-common/extensions';

import type { FeatureFlagsType } from '@confluence/session-data';

import { CUSTOM_SITES_CARDS_EXTENSION_FF } from '../link-cards/customSitesFeatureFlags';
import { linkCardsExtensionType } from '../link-cards/linkCardsExtensionType';
import { LinkCardsLoader } from '../link-cards/view/LinkCardsLoader';
import type { LinkCardsParameters } from '../link-cards/linkCardsTypes';
import { carouselExtensionType } from '../carousel/carouselConstants';
import { CarouselLoader } from '../carousel/view/CarouselLoader';

type getRendererCustomSitesExtensionHandlersProps = {
	featureFlags: FeatureFlagsType;
	contentId: string | undefined;
};

export const getRendererCustomSitesExtensionHandlers = ({
	featureFlags,
	contentId,
}: getRendererCustomSitesExtensionHandlersProps): ExtensionHandlers => {
	const extensionHandlers: ExtensionHandlers = {
		[linkCardsExtensionType]: () => <></>,
		[carouselExtensionType]: () => <></>,
	};
	const isEnabled = featureFlags[CUSTOM_SITES_CARDS_EXTENSION_FF];

	if (isEnabled && contentId) {
		extensionHandlers[linkCardsExtensionType] = (
			extension: ExtensionParams<LinkCardsParameters>,
		) => <LinkCardsLoader extensionNode={extension} contentId={contentId} />;
		extensionHandlers[carouselExtensionType] = (
			extension: ExtensionParams<LinkCardsParameters>,
		) => <CarouselLoader extensionNode={extension} contentId={contentId} />;
	}
	return extensionHandlers;
};
