import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { type ProductEnvironment } from '@atlassian/forge-ui-types';

import {
	captureAndReportError,
	type ErrorExtensionDetails,
	type TracingService,
} from '../error-reporting';
import { BridgeClientError } from './bridge';

import { blocklistedErrorMessagePatterns } from './blocklistedErrorMessagePatterns';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../analytics';
import { trackBridgeSucceed, trackBridgeFailed } from '../analytics/useForgeUiAnalyticsEvent';

type Feature = (payload: any) => Promise<any>;
type Features = { [key: string]: Feature };

type MetricsAndAnalyticsOptions<T> = {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	page: string;
	environment: ProductEnvironment;
	errorExtensionDetails: ErrorExtensionDetails;
	tracing?: TracingService;
} & T;

type WrapWithMetricsAndAnalytics<K extends keyof T, T> = (
	options: MetricsAndAnalyticsOptions<T>,
) => T[K];

const wrapFeatureWithMetricsAndAnalytics: WrapWithMetricsAndAnalytics<
	'feature',
	{ feature: Feature; bridgeMethod: string }
> =
	({
		feature,
		createAnalyticsEvent,
		page,
		bridgeMethod,
		environment,
		errorExtensionDetails,
		tracing,
	}) =>
	async (payload) => {
		try {
			tracing?.recordBridgeCall(`${bridgeMethod}() - start`);

			const result = await feature(payload);

			tracing?.recordBridgeCall(`${bridgeMethod}() - end`);

			if (bridgeMethod === 'on') {
				createAnalyticsEvent({
					eventType: OPERATIONAL_EVENT_TYPE,
					data: {
						action: 'subscribed',
						actionSubject: 'bridgeEvent',
						attributes: {
							// TODO: remove the condition (and related test) once Developer UGC spec is ready
							event: payload.data.event === 'JIRA_ISSUE_CHANGED' ? 'JIRA_ISSUE_CHANGED' : 'other',
						},
						source: page,
						tags: ['forge'],
					},
				}).fire(FORGE_UI_ANALYTICS_CHANNEL);
			}

			// this replaces the BRIDGE_REQUEST_RECEIVED metal client event
			trackBridgeSucceed(createAnalyticsEvent, { source: page });

			return result;
		} catch (err) {
			const error =
				err instanceof Error ? err : new Error(`Unknown Custom UI Bridge error: ${typeof err}`);

			if (!(error instanceof BridgeClientError)) {
				if (!blocklistedErrorMessagePatterns.some((pattern) => pattern.test(error.message))) {
					trackBridgeFailed(createAnalyticsEvent, { source: page, errorName: error.name });

					captureAndReportError({
						error: error,
						environment,
						errorExtensionDetails,
						page,
						tracing,
					});
				}
			}
			throw err;
		}
	};

export const withMetricsAndAnalytics: WrapWithMetricsAndAnalytics<
	'features',
	{ features: Features }
> = ({ features, createAnalyticsEvent, page, environment, errorExtensionDetails, tracing }) =>
	Object.entries(features).reduce<Features>(
		(acc, [key, feature]) => ({
			...acc,
			[key]: wrapFeatureWithMetricsAndAnalytics({
				feature,
				createAnalyticsEvent,
				page,
				bridgeMethod: key,
				environment,
				errorExtensionDetails,
				tracing,
			}),
		}),
		{},
	);
