import moment from 'moment-timezone';
import { eachHourOfInterval, subHours } from 'date-fns';

import type { AnalyticsDialogGraphQuery_TimeseriesCount_nodes } from '../queries/__types__/AnalyticsDialogQuery';

import { formatInTimeZone } from './utils';

const MAX_POINTS_HOUR_GRANULARITY = 24;

export const getBrowserTimezone = (): string => {
	// moment-timezone also uses Intl.DateTimeFormat().resolvedOptions().timeZone to retrieve
	// timezone from the browser like we were doing before but has equivalent fallbacks for
	// timezones that are in IANA TZDB but not in moment 0.5.28 which the cc-analytics service uses
	// e.g. "America/Ciudad_Juarez" gets "America/Denver" as its substitution
	// The need for this substitution could potentially change with the resolution of CCA-2576 https://hello.atlassian.net/browse/CCA-2576
	return moment.tz.guess();
};

const timeZone = getBrowserTimezone();

export const prependNoViewsData = (
	timeseriesNodes: AnalyticsDialogGraphQuery_TimeseriesCount_nodes[],
): AnalyticsDialogGraphQuery_TimeseriesCount_nodes[] => {
	if (timeseriesNodes.length == 0) {
		return timeseriesNodes;
	}

	// BE response may have up to a week's worth of timeseriesNodes despite the post being less than a day old
	// In this case, we won't need to create any emptyNodes because they already exist
	if (timeseriesNodes.length > MAX_POINTS_HOUR_GRANULARITY) {
		return timeseriesNodes.slice(-MAX_POINTS_HOUR_GRANULARITY);
	}

	const numMissingPoints = MAX_POINTS_HOUR_GRANULARITY - timeseriesNodes.length;
	const earliestAvailableTime = new Date(timeseriesNodes[0].date);

	const emptyNodes = eachHourOfInterval({
		start: subHours(earliestAvailableTime, numMissingPoints),
		end: subHours(earliestAvailableTime, 1),
	}).map((date) => ({
		date: formatInTimeZone(timeZone, date),
		count: 0,
	}));

	return [...emptyNodes, ...timeseriesNodes];
};
