import { ApolloError } from 'apollo-client';
import { normalize } from 'normalizr';
import window from 'window-or-global';

import { getApolloClient } from '@confluence/graphql';

import omitDeep from '../helpers/omitDeep';

const applyTransformers = (store, response, variables, actionName, transformer, schema) => {
	const actionNames = actionName instanceof Array ? actionName : [actionName];
	for (const actionType of actionNames) {
		try {
			const data =
				response.data &&
				transformGraphqlResponseToState(response.data, variables, transformer, schema);
			if (!data) {
				return;
			}

			store.dispatch({
				type: `${actionType}_SUCCESS`,
				response: omitDeep(data, '__typename'),
				...variables,
			});
		} catch (error) {
			window.console.error(error);
			store.dispatch({ type: `${actionType}_FAILED`, error });
		}
	}
};

const transformGraphqlResponseToState = (data, variables, transformer, schema) => {
	const transformed = transformer ? transformer(data, variables) : data;
	if (!transformed) {
		return null;
	}

	if (!schema) {
		return transformed;
	}

	const normalized = normalize(transformed, schema);
	return {
		...transformed,
		...normalized,
	};
};

export default (store) => (next) => (action) => {
	if (action.type === 'APOLLO_QUERY') {
		if (action.errorFilter && action.batch) {
			throw new Error('errorFilter is only supported when batch=false');
		}

		const actionNames =
			action.actionName instanceof Array
				? action.actionName
				: action.actionName
					? [action.actionName]
					: [];
		for (const actionName of actionNames) {
			store.dispatch({ type: `${actionName}_REQUEST`, ...action.variables });
		}

		const gqlClientQueryOptions = {
			query: action.query,
			variables: action.variables,
			context: {
				single: !action.batch,
			},
		};

		if (action.fetchPolicy) {
			gqlClientQueryOptions.fetchPolicy = action.fetchPolicy;
		}

		const queryPromise = getApolloClient().query(gqlClientQueryOptions);

		queryPromise
			.then((response) => {
				if (response instanceof Array) {
					let errors = response[0].errors;
					if (errors && action.errorsFilter) {
						errors = action.errorsFilter(errors);
					}
					if (errors) {
						throw new ApolloError({
							graphQLErrors: response[0].errors,
						});
					}
					return response[0];
				}
				return response;
			})
			.then((response) =>
				store.dispatch({
					type: 'APOLLO_QUERY_SUCCESS',
					response,
					actionName: action.actionName,
					transformer: action.transformer,
					schema: action.schema,
					variables: action.variables,
				}),
			)
			.catch((error) => {
				if (process.env.NODE_ENV !== 'production') {
					window.console.error(error);
				}
				store.dispatch({
					type: 'APOLLO_QUERY_FAILURE',
					error,
					actionName: action.actionName,
					variables: action.variables,
				});
			});
		next(action);
		return queryPromise;
	} else if (action.type === 'APOLLO_QUERY_SUCCESS') {
		if (
			action.response.data &&
			action.response.data.content &&
			action.response.data.content.nodes &&
			!action.response.data.content.nodes.length
		) {
			if (action.actionName === 'EDITOR_CONTENT_V2') {
				return store.dispatch({
					type: `${action.actionName}_FAILURE`,
					error: { statusCode: 404 },
					options: action.variables,
				});
			}
			// TODO: this should be removed in favour of generic failure action above
			return store.dispatch({
				type: 'CONTENT_FAILURE',
				error: { statusCode: 404 },
			});
		} else {
			applyTransformers(
				store,
				action.response,
				action.variables,
				action.actionName,
				action.transformer,
				action.schema,
			);
		}
	} else if (action.type === 'APOLLO_QUERY_FAILURE') {
		let error = action.error?.graphQLErrors?.length ? action.error?.graphQLErrors[0] : action.error;
		if (error?.response?.status) {
			error = { ...error, statusCode: error.response.status };
		}
		const message = error ? error.message : action.error.message;

		const actionNames =
			action.actionName instanceof Array ? action.actionName : [action.actionName];
		for (const actionName of actionNames) {
			store.dispatch({
				type: `${actionName}_FAILURE`,
				error,
				message,
				options: action.variables,
			});
		}
	}
	return next(action);
};
