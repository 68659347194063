import React from 'react';
import { styled, keyframes } from '@compiled/react';

import { N0, N30, N20A, N10A } from '@atlaskit/theme/colors';
import { Box, Stack, Inline, xcss } from '@atlaskit/primitives';

const embedReferenceContainerStyles = xcss({
	maxWidth: '100%',
	height: '600px',
	overflow: 'hidden',
	background: N0,
});

const previewContainerStyles = xcss({
	width: '100%',
	height: '100%',
	paddingRight: 'space.200',
	paddingBottom: 'space.100',
});

const placeholderContainerStyles = xcss({
	width: '100%',
	height: '100%',
});

const placeholderTextStyles = xcss({
	width: '100%',
});

const referenceOptionsContainerStyles = xcss({
	width: '320px',
	height: '600px',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	borderLeft: `2px solid ${N30}`,
});

const formHeaderContainerStyles = xcss({
	padding: 'space.300',
	paddingBottom: 'space.250',
	position: 'relative',
});

const referencePickerContainerStyles = xcss({
	minHeight: '0',
	paddingTop: 'space.025',
	paddingRight: 'space.300',
	paddingBottom: 'space.025',
	paddingLeft: 'space.300',
});

const optionGroupContainerStyles = xcss({
	marginBottom: 'space.300',
});

const shimmerAnimation = keyframes({
	from: {
		backgroundColor: N20A,
	},
	to: {
		backgroundColor: N10A,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomSkeleton = styled.div<{
	width: number;
	height: number;
	borderRadius: number | string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ borderRadius }) =>
		typeof borderRadius === 'string' ? borderRadius : `${borderRadius}px`,
	animationDirection: 'alternate',
	animationDuration: '1.5s',
	animationIterationCount: 'infinite',
	animationName: shimmerAnimation,
	animationTimingFunction: 'linear',
});

const OptionGroup = () => {
	return (
		<Stack alignBlock="start" space="space.050" xcss={optionGroupContainerStyles}>
			<CustomSkeleton width={56} height={14} borderRadius={18} />
			<CustomSkeleton width={272} height={40} borderRadius={3} />
		</Stack>
	);
};

export const DatabaseReferenceModalSkeleton = () => {
	return (
		<Inline xcss={embedReferenceContainerStyles} testId="reference-database-skeleton">
			<Box xcss={previewContainerStyles}>
				<Stack
					alignBlock="center"
					alignInline="center"
					space="space.250"
					xcss={placeholderContainerStyles}
				>
					<CustomSkeleton width={93} height={93} borderRadius="100%" />
					<Stack
						alignBlock="center"
						alignInline="center"
						space="space.150"
						xcss={placeholderTextStyles}
					>
						<CustomSkeleton width={216} height={14} borderRadius={18} />
						<CustomSkeleton width={132} height={14} borderRadius={18} />
					</Stack>
				</Stack>
			</Box>
			<Stack xcss={referenceOptionsContainerStyles}>
				<Inline alignBlock="center" spread="space-between" xcss={formHeaderContainerStyles}>
					<CustomSkeleton width={145} height={14} borderRadius={18} />
					<CustomSkeleton width={24} height={24} borderRadius={5} />
				</Inline>
				<Stack xcss={referencePickerContainerStyles}>
					<OptionGroup />
					<OptionGroup />
					<OptionGroup />
					<Inline alignInline="end" space="space.100">
						<CustomSkeleton width={61} height={32} borderRadius={3} />
						<CustomSkeleton width={61} height={32} borderRadius={3} />
					</Inline>
				</Stack>
			</Stack>
		</Inline>
	);
};
