import type {
	BaseMetricDataWithStartAndStop,
	PerformanceEventConfig,
	ShareableGlobalConfig,
} from '../../types';

export const eventProduct = (
	_config: PerformanceEventConfig,
	_data: BaseMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
) => {
	return {
		'event:product': `${globalConfig.product}`,
	};
};
