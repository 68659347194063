import type { ReadonlyTransaction, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { Decoration, DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { AISpellingGrammarPluginState } from '../states';

import { updateSpellingDecoration } from './spelling-decorations';
import {
	addSelectedSpellingDecoration,
	removeSelectedSpellingDecoration,
} from './spelling-selected-decoration';

export const SpellingAndGrammarDecorations = {
	SPELLING_ERROR: 'spellingError',
	SPELLING_ERROR_SELECTED: 'spellingErrorSelected',
	SUGGESTION: 'suggestion',
} as const;

export type SpellingAndGrammarDecorations =
	(typeof SpellingAndGrammarDecorations)[keyof typeof SpellingAndGrammarDecorations];

type DecorationTransformer = (params: DecorationTransformerParams) => DecorationSet;

export type DecorationTransformerParams = {
	decorationSet: DecorationSet;
	tr: Transaction | ReadonlyTransaction;
	pluginState: AISpellingGrammarPluginState;
};

type DecorationTransformers = Array<DecorationTransformer>;

const composeDecorations =
	(transformers: DecorationTransformers): DecorationTransformer =>
	({ decorationSet, tr, pluginState }: DecorationTransformerParams) =>
		transformers.reduce(
			(decorationSet, transform) => transform({ decorationSet, tr, pluginState }),
			decorationSet,
		);

export const filterDecorationByKey = (
	key: SpellingAndGrammarDecorations,
	decorationSet: DecorationSet,
): Decoration[] => decorationSet.find(undefined, undefined, (spec) => spec.key.indexOf(key) > -1);

export const regenerateSelectedSpellingDecorations = (
	params: DecorationTransformerParams,
): DecorationSet => {
	return composeDecorations([removeSelectedSpellingDecoration, addSelectedSpellingDecoration])(
		params,
	);
};

export const regenerateAllDecorations = (params: DecorationTransformerParams): DecorationSet => {
	return composeDecorations([
		removeSelectedSpellingDecoration,
		updateSpellingDecoration,
		addSelectedSpellingDecoration,
	])(params);
};
