import React from 'react';
import { LazyLoader as LazyEditPageLoader } from '@confluence-classic/confluence-fabric-editor/src/components/EditPage';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import type { RouteMatch } from '@confluence/route';
import {
	CONTAINER_HOME_ID,
	CONTAINER_HOME_LOADING_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { CreateContentRouteComponentLoader } from '@confluence/create-blank-fabric-page/entry-points/CreateContentRouteComponent';
import { useIsEmbeddedConfluence_DO_NOT_USE } from '@confluence/embedded-confluence-check';
import { DatabaseContainerLoader } from '@confluence/confluence-databases';
import { EmbedRouteLoader } from '@confluence/confluence-embeds';

import { WhiteboardRouteComponentLoader } from './WhiteboardRouteComponentLoader';

export const CreateContentRoute = () => (
	<CreateContentRouteComponentLoader
		preloadWhiteboardRouteComponent={WhiteboardRouteComponentLoader.preload}
		preloadDatabaseRouteComponent={DatabaseContainerLoader.preload}
		preloadPageAndBlogRouteComponent={LazyEditPageLoader.preload}
		preloadSmartLinkEmbedRouteComponent={EmbedRouteLoader.preload}
	/>
);

CreateContentRoute.NAVIGATION_PARAMS = ({
	name: routeName,
	params: { spaceKey, contentType: createRouteTypeParam },
	query,
}: RouteMatch) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isEmbeddedConfluence = useIsEmbeddedConfluence_DO_NOT_USE();
	const showPremiumOnboarding = !isEmbeddedConfluence;

	// spaceKey comes from query object while on creation route
	const currentSpaceKey = spaceKey || query?.spaceKey;
	const isPageLikeContent = createRouteTypeParam === 'page' || createRouteTypeParam === 'blog';
	return {
		Screen: {
			screenEvent: { name: 'createContentScreen' },
			pageState: {
				spaceKey: currentSpaceKey,
				routeName,
			},
			showPremiumOnboarding: isPageLikeContent ? showPremiumOnboarding : false,
		},
		MainLayout: {
			// CREATE_CONTENT route can return an undefined spaceKey during creation so we render a loading screen
			navView: query.spaceKey ? CONTAINER_HOME_ID : CONTAINER_HOME_LOADING_ID,
			spaceKey: currentSpaceKey,
			editorPreloader: isPageLikeContent ? getEditorPreloader : undefined,
			disableMinWidth: true,
			isViewPage: true,
			isEdit: true,
		},
	};
};
