interface IRendererProps {
	document: any;
	contentId: string;
}

export const convertRendererProps = (isLegacyRenderer: boolean, rendererProps: IRendererProps) => {
	if (!isLegacyRenderer) return rendererProps;

	return {
		adf: rendererProps.document,
		objectId: rendererProps.contentId,
	};
};

export const getMacroId = (node: any) => node?.parameters?.macroMetadata?.macroId?.value;
