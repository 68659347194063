/* eslint-disable no-restricted-syntax */
import React from 'react';

import { namedRoutes } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { RedirectLegacyCopyPageLoader } from '@confluence/redirect-legacy-copy-page';
import { RedirectLegacyViewPageLoader } from '@confluence/redirect-legacy-view-page';
import { processNavigationParams } from '@confluence/ssr-app/entry-points/routes';

import { RedirectOldCreatePage } from './routes/RedirectOldCreatePage';
import { AdminForgeAppsRoute } from './routes/AdminForgeAppsRoute';
import { AdvancedSearchRoute } from './routes/AdvancedSearchRoute';
import { BetaFeaturesRoute } from './routes/BetaFeaturesRoute';
import { ExternalShareRoute } from './routes/ExternalShareRoute';
import { GlobalPageForgeAppsRoute } from './routes/GlobalPageForgeAppsRoute';
import { GlobalPermissionRoute } from './routes/GlobalPermissionRoute';
import { AdminCompanyHubPermissionsRoute } from './routes/AdminCompanyHubPermissionsRoute';
import {
	CompanyHomeBuilderRoute,
	CustomizeNavigationRoute,
} from './routes/CustomizeNavigationRoute';
import { GlobalAutomationRoute } from './routes/GlobalAutomationRoute';
import { GlobalPermissionUserDetailsRoute } from './routes/GlobalPermissionUserDetailsRoute';
import { PdfExportPageRoute } from './routes/PdfExportPageRoute';
import { PdfExportSpaceRoute } from './routes/PdfExportSpaceRoute';
import { PeopleDirectoryRoute } from './routes/PeopleDirectoryRoute';
import { MarketplaceRoute } from './routes/MarketplaceRoute';
import { SpaceForgeAppsRoute } from './routes/SpaceForgeAppsRoute';
import { SpaceSettingsForgeAppsRoute } from './routes/SpaceSettingsForgeAppsRoute';
import { SpaceSettingsArchiveSpaceRoute } from './routes/SpaceSettingsArchiveSpaceRoute';
import { SpaceSettingsEditorConversionRoute } from './routes/SpaceSettingsEditorConversionRoute';
import { SpaceSettingsContentStateRoute } from './routes/SpaceSettingsContentStateRoute';
import { SpaceSettingsRelatedPagesRoute } from './routes/SpaceSettingsRelatedPagesRoute';
import { SpaceSettingsEditFeaturesRoute } from './routes/SpaceSettingsEditFeaturesRoute';
import { SpaceSettingsDetailsRoute } from './routes/SpaceSettingsDetailsRoute';
import { SpaceLookAndFeelSettingsRoute } from './routes/SpaceLookAndFeelSettingsRoute';
import { SpaceArchivedPagesRoute } from './routes/SpaceArchivedPagesRoute';
import { SpaceSettingsDataClassificationRoute } from './routes/SpaceSettingsDataClassificationRoute';
import { MyCalendarRoute } from './routes/MyCalendarRoute';
import { SpaceTeamCalendarsRoute } from './routes/SpaceTeamCalendarsRoute';
import { SpaceDirectoryRoute } from './routes/SpaceDirectoryRoute';
import { SpaceSettingsLandingPageRoute } from './routes/SpaceSettingsLandingPageRoute';
import { SpaceOverviewRoute } from './routes/SpaceOverviewRoute';
import { CompanyHubRoute } from './routes/CompanyHubRoute';
import { CompanyHubAnalyticsRoute } from './routes/CompanyHubAnalyticsRoute';
import { CompanyHubEditRoute } from './routes/CompanyHubEditRoute';
import { CompanyHubSettingsRoute } from './routes/CompanyHubSettingsRoute';
import { SpacePagesRoute } from './routes/SpacePagesRoute';
import { SpaceBlogsRoute } from './routes/SpaceBlogsRoute';
import { BlogHomeRoute } from './routes/BlogHomeRoute';
import { SpaceBulkToolsRoute } from './routes/SpaceBulkToolsRoute';
import { TemplateMigrationPreviewRoute } from './routes/TemplateMigrationPreviewRoute';
import { TemplateEditorRoute } from './routes/TemplateEditorRoute';
import { OnboardingRoute } from './routes/OnboardingRoute';
import { ContentHistoryRoute } from './routes/ContentHistoryRoute';
import { TemplateGalleryRoute } from './routes/TemplateGalleryRoute';
import { MobileTakeoverPageRoute } from './routes/MobileTakeoverPageRoute';
import { WikiRoute } from './routes/WikiRoute';
import { HomeRoute } from './routes/HomeRoute';
import { CreateBlogRoute } from './routes/CreateBlogRoute';
import { CreatePageRoute } from './routes/CreatePageRoute';
import { EditPageRoute } from './routes/EditPageRoute';
import { EditBlogRoute } from './routes/EditBlogRoute';
import { EditPageOrBlogV2Route } from './routes/EditPageOrBlogV2Route';
import { EditPageEmbedRoute } from './routes/EditPageEmbedRoute';
import { SpaceSettingsAutomationRoute } from './routes/SpaceSettingsAutomationRoute';
import { SpaceCustomContentListRoute } from './routes/SpaceCustomContentListRoute';
import { CustomContentOnPageRoute } from './routes/CustomContentOnPageRoute';
import { CustomContentViewRoute } from './routes/CustomContentViewRoute';
import { PublicLinksSettingsPageRoute } from './routes/PublicLinksSettingsPageRoute';
import { ContactAdministratorRoute } from './routes/ContactAdministratorRoute';
import { SpacePermissionsSettingsRoute } from './routes/SpacePermissionsSettingsRoute';
import { AdminAnnouncementBannerRendererRoute } from './routes/AdminAnnouncementBannerRendererRoute';
import { AdminCenterRoute } from './routes/AdminCenterRoute';
import { AdminCenterSpaceRoute } from './routes/AdminCenterSpaceRoute';
import { AdminSettingsRoute } from './routes/AdminSettingsRoute';
import { SpaceSettingsExportSpaceUnavailableRoute } from './routes/SpaceSettingsExportSpaceUnavailableRoute';
import { BulkSpacePermissionsRoute } from './routes/BulkSpacePermissionsRoute';
import { AdminTeamCalendarsRoute } from './routes/AdminTeamCalendarsRoute';
import { AdminConfigurationRoute } from './routes/AdminConfigurationRoute';
import { AdminDefaultSpaceLogoConfigRoute } from './routes/AdminDefaultSpaceLogoConfigRoute';
import { AdminHeaderAndFooterRoute } from './routes/AdminHeaderAndFooterRoute';
import { AdminTemplatesAndBlueprintsRoute } from './routes/AdminTemplatesAndBlueprintsRoute';
import { AdminNotificationsRoute } from './routes/AdminNotificationsRoute';
import { AdminSpaceReportsRoute } from './routes/AdminSpaceReportsRoute';
import { AdminCodeMacroConfigurationRoute } from './routes/AdminCodeMacroConfigurationRoute';
import { AdminColorSchemeRoute } from './routes/AdminColorSchemeRoute';
import { AdminSecurityConfigurationRoute } from './routes/AdminSecurityConfigurationRoute';
import { AdminApplicationNavigatorRoute } from './routes/AdminApplicationNavigatorRoute';
import { AdminMacroUsageRoute } from './routes/AdminMacroUsageRoute';
import { AdminTrashedSpacesRoute } from './routes/AdminTrashedSpacesRoute';
import { AdminImportSpacesRoute } from './routes/AdminImportSpacesRoute';
import { AdminJiraMacroRepairRoute } from './routes/AdminJiraMacroRepairRoute';
import { AdminThemesRoute } from './routes/AdminThemesRoute';
import { AdminPdfExportRoute } from './routes/AdminPdfExportRoute';
import { AdminWorkflowsRoute } from './routes/AdminWorkflowsRoute';
import { SpaceManagerRoute } from './routes/SpaceManagerRoute';
import { SpaceMembersRoute } from './routes/SpaceMembersRoute';
import { DefaultSpacePermissionsRoute } from './routes/DefaultSpacePermissionsRoute';
import { IndividualSpacesRoute } from './routes/IndividualSpacesRoute';
import { RedirectToSpaceOverview } from './routes/RedirectToSpaceOverview';
import { RedirectToHomeWithQuery } from './routes/RedirectToHomeWithQuery';
import { ActionRedirectLegacyRoute } from './routes/ActionRedirectLegacyRoute';
import { ResumeDraftLegacyRoute } from './routes/ResumeDraftLegacyRoute';
import { WhiteboardRoute } from './routes/WhiteboardRoute';
import { SpaceJiraProjectBoardRoute } from './routes/SpaceJiraProjectBoardRoute';
import { FolderViewRoute } from './routes/FolderViewRoute';
import { EmbedViewRoute } from './routes/EmbedViewRoute';
import { DatabaseViewRoute } from './routes/DatabaseViewRoute';
import { SpaceIndexRoute } from './routes/SpaceIndexRoute';
import { CreateContentRoute } from './routes/CreateContentRoute';
import { ViewPageOrBlogRoute } from './routes/ViewPageOrBlogRoute';
import { TopicsRoute } from './routes/TopicsRoute';
import { QuestionsRoute } from './routes/QuestionsRoute';
import { QuestionRoute } from './routes/QuestionRoute';
import { SiteAnalyticsRoute } from './routes/SiteAnalyticsRoute';
import { SiteAnalyticsPermissionsRoute } from './routes/SiteAnalyticsPermissionsRoute';
import { PageAnalyticsRoute } from './routes/PageAnalyticsRoute';
import { SpaceAnalyticsRoute } from './routes/SpaceAnalyticsRoute';
import { SpaceAnalyticsPermissionsRoute } from './routes/SpaceAnalyticsPermissionsRoute';
import { UserAccessRoute } from './routes/UserAccessRoute';
import { LiveEditSiteSettingsPageRoute } from './routes/LiveEditSiteSettingsPageRoute';
import { WorkflowsRoute } from './routes/WorkflowsRoute';

function redirect(name) {
	const redirectComponent = ({ params = {} }) => <Redirection name={name} params={params} />;
	redirectComponent.NAVIGATION_PARAMS = () => false;
	return redirectComponent;
}

/**
 * Please read patterns/routing.md
 */
export const routeComponents = processNavigationParams({
	[namedRoutes.BASE.name]: redirect(namedRoutes.WIKI.name),
	[namedRoutes.WIKI.name]: WikiRoute,
	[namedRoutes.HOME.name]: HomeRoute,
	[namedRoutes.MOBILE_TAKEOVER_PAGE.name]: MobileTakeoverPageRoute,
	[namedRoutes.TEMPLATE_GALLERY.name]: TemplateGalleryRoute,
	[namedRoutes.EDIT_PAGE.name]: EditPageRoute,
	[namedRoutes.EDIT_BLOG.name]: EditBlogRoute,
	[namedRoutes.EDIT_PAGE_V2.name]: EditPageOrBlogV2Route,
	[namedRoutes.EDIT_BLOG_V2.name]: EditPageOrBlogV2Route,
	[namedRoutes.EDIT_PAGE_EMBED.name]: EditPageEmbedRoute,
	[namedRoutes.CREATE_CONTENT.name]: CreateContentRoute,
	[namedRoutes.CREATE_PAGE.name]: CreatePageRoute,
	[namedRoutes.CREATE_BLOG.name]: CreateBlogRoute,
	[namedRoutes.CREATE_PAGE_ACTION_LEGACY.name]: RedirectOldCreatePage,
	[namedRoutes.CREATE_BLOG_ACTION_LEGACY.name]: RedirectOldCreatePage,
	[namedRoutes.CREATE_PAGE_VARIABLES_ACTION_LEGACY.name]: RedirectOldCreatePage,
	[namedRoutes.RESUME_DRAFT_LEGACY.name]: ResumeDraftLegacyRoute,
	[namedRoutes.COPY_PAGE_LEGACY.name]: RedirectLegacyCopyPageLoader,
	[namedRoutes.DASHBOARD_ACTION_LEGACY.name]: redirect(namedRoutes.HOME.name),
	[namedRoutes.SPA_ACTION_LEGACY.name]: redirect(namedRoutes.HOME.name),
	[namedRoutes.INDEX_ACTION_LEGACY.name]: redirect(namedRoutes.HOME.name),
	[namedRoutes.VIEW_PAGE_ACTION_LEGACY.name]: RedirectLegacyViewPageLoader,
	[namedRoutes.SPACE_OVERVIEW_INDEX_ACTION_LEGACY.name]: RedirectToSpaceOverview,
	[namedRoutes.SPACE_INDEX_ACTION_LEGACY.name]: RedirectToSpaceOverview,
	// it should be after "INDEX_ACTION_LEGACY", and can't be the last one, as it will brake navigation to space settings
	[namedRoutes.ACTION_REDIRECT_LEGACY.name]: ActionRedirectLegacyRoute,
	[namedRoutes.SPACE_DIRECTORY.name]: SpaceDirectoryRoute,
	[namedRoutes.SPACE_OVERVIEW_REDIRECT_LEGACY.name]: RedirectToSpaceOverview,
	[namedRoutes.SPACE_INDEX.name]: SpaceIndexRoute,
	[namedRoutes.SPACE_OVERVIEW.name]: SpaceOverviewRoute,
	[namedRoutes.COMPANY_HUB.name]: CompanyHubRoute,
	[namedRoutes.COMPANY_HUB_ANALYTICS.name]: CompanyHubAnalyticsRoute,
	[namedRoutes.COMPANY_HUB_EDIT.name]: CompanyHubEditRoute,
	[namedRoutes.COMPANY_HUB_SETTINGS.name]: CompanyHubSettingsRoute,
	[namedRoutes.GLOBAL_PAGE_FORGE.name]: GlobalPageForgeAppsRoute,
	[namedRoutes.SPACE_PAGES.name]: SpacePagesRoute,
	[namedRoutes.SPACE_BLOGS.name]: SpaceBlogsRoute,
	[namedRoutes.VIEW_PAGE.name]: ViewPageOrBlogRoute,
	[namedRoutes.BLOG_HOME.name]: BlogHomeRoute,
	[namedRoutes.VIEW_BLOG_DATE_LEGACY.name]: ViewPageOrBlogRoute,
	[namedRoutes.VIEW_BLOG.name]: ViewPageOrBlogRoute,
	[namedRoutes.WHITEBOARD_HOME.name]: redirect(namedRoutes.SPACE_PAGES.name),
	[namedRoutes.WHITEBOARD_BOARD.name]: WhiteboardRoute,
	[namedRoutes.DATABASE_VIEW.name]: DatabaseViewRoute,
	[namedRoutes.EMBED_VIEW.name]: EmbedViewRoute,
	[namedRoutes.FOLDER_VIEW.name]: FolderViewRoute,
	[namedRoutes.CONTACT_ADMINISTRATOR.name]: ContactAdministratorRoute,
	[namedRoutes.SPACE_CALENDARS.name]: SpaceTeamCalendarsRoute,
	[namedRoutes.SPACE_CALENDARS_SUBCALENDAR.name]: SpaceTeamCalendarsRoute,
	[namedRoutes.DISCOVER.name]: RedirectToHomeWithQuery({ feed: '' }),
	[namedRoutes.ALL_UPDATES.name]: RedirectToHomeWithQuery({ feed: 0 }),
	[namedRoutes.POPULAR.name]: RedirectToHomeWithQuery({ feed: 1, feedType: 'POPULAR' }),
	[namedRoutes.MY_WORK_HOME.name]: RedirectToHomeWithQuery({ recentTab: 1 }),
	[namedRoutes.MY_WORK_DRAFTS.name]: RedirectToHomeWithQuery({ recentTab: 2 }),
	[namedRoutes.RECENT_DRAFTS.name]: RedirectToHomeWithQuery({ recentTab: 2 }),
	[namedRoutes.MY_WORK_RECENT.name]: RedirectToHomeWithQuery({ recentTab: 1, filter: 'WORKED_ON' }),
	[namedRoutes.RECENT_PAGES.name]: RedirectToHomeWithQuery({ recentTab: 1 }),
	[namedRoutes.MY_RECENTLY_VISITED.name]: RedirectToHomeWithQuery({ recentTab: 0 }),
	[namedRoutes.RECENT_VISITED.name]: RedirectToHomeWithQuery({ recentTab: 0 }),
	[namedRoutes.MY_SAVED_FOR_LATER.name]: RedirectToHomeWithQuery({ recentTab: 3 }),
	[namedRoutes.RECENT_SAVED.name]: RedirectToHomeWithQuery({ recentTab: 3 }),
	[namedRoutes.TEMPLATE_MIGRATION_PREVIEW.name]: TemplateMigrationPreviewRoute,
	[namedRoutes.EDIT_TEMPLATE.name]: TemplateEditorRoute,
	[namedRoutes.EDIT_TEMPLATE_BLUEPRINT.name]: TemplateEditorRoute,
	[namedRoutes.EDIT_TEMPLATE_SPACE.name]: TemplateEditorRoute,
	[namedRoutes.CREATE_TEMPLATE.name]: TemplateEditorRoute,
	[namedRoutes.CREATE_TEMPLATE_SPACE.name]: TemplateEditorRoute,
	[namedRoutes.ADVANCED_SEARCH.name]: AdvancedSearchRoute,
	[namedRoutes.EXTERNAL_SHARE.name]: ExternalShareRoute,
	[namedRoutes.PEOPLE_DIRECTORY.name]: PeopleDirectoryRoute,
	[namedRoutes.MARKETPLACE_APP_REQUESTS.name]: MarketplaceRoute,
	[namedRoutes.MARKETPLACE_MANAGE_PROMOTIONS.name]: MarketplaceRoute,
	[namedRoutes.MARKETPLACE_FIND_APPS.name]: MarketplaceRoute,
	[namedRoutes.CONTENT_HISTORY.name]: ContentHistoryRoute,
	[namedRoutes.ONBOARDING.name]: OnboardingRoute,
	[namedRoutes.ADMIN_GLOBAL_AUTOMATION.name]: GlobalAutomationRoute,
	[namedRoutes.ADMIN_NEW_GLOBAL_PERMISSIONS.name]: GlobalPermissionRoute,
	[namedRoutes.ADMIN_COMPANY_HUB_PERMISSIONS.name]: AdminCompanyHubPermissionsRoute,
	[namedRoutes.ADMIN_COMPANY_HOME_BUILDER.name]: CompanyHomeBuilderRoute,
	[namedRoutes.ADMIN_CUSTOMIZE_NAVIGATION.name]: CustomizeNavigationRoute,
	[namedRoutes.ADMIN_NEW_GLOBAL_PERMISSIONS_USER_DETAILS.name]: GlobalPermissionUserDetailsRoute,
	[namedRoutes.ADMIN_EDIT_EXTERNAL_SHARING.name]: PublicLinksSettingsPageRoute,
	[namedRoutes.ADMIN_FORGE_APPS.name]: AdminForgeAppsRoute,
	[namedRoutes.ADMIN_BETA_FEATURES.name]: redirect(namedRoutes.ADMIN_NEW_FEATURES.name),
	[namedRoutes.ADMIN_NEW_FEATURES.name]: BetaFeaturesRoute,
	[namedRoutes.ADMIN_LIVE_EDIT.name]: LiveEditSiteSettingsPageRoute,
	[namedRoutes.ADMIN_NOTIFICATIONS.name]: AdminNotificationsRoute,
	[namedRoutes.SITE_ANALYTICS.name]: SiteAnalyticsRoute,
	[namedRoutes.SITE_ANALYTICS_PERMISSIONS.name]: SiteAnalyticsPermissionsRoute,
	[namedRoutes.SPACE_ANALYTICS.name]: SpaceAnalyticsRoute,
	[namedRoutes.SPACE_ANALYTICS_PERMISSIONS.name]: SpaceAnalyticsPermissionsRoute,
	[namedRoutes.PAGE_ANALYTICS.name]: PageAnalyticsRoute,
	[namedRoutes.SPACE_ARCHIVED_PAGES.name]: SpaceArchivedPagesRoute,
	[namedRoutes.SPACE_BULK_TOOLS.name]: SpaceBulkToolsRoute,
	[namedRoutes.SPACE_SETTINGS_LANDING_PAGE.name]: SpaceSettingsLandingPageRoute,
	[namedRoutes.SPACE_SETTINGS_LANDING_PAGE_ALT.name]: redirect(
		namedRoutes.SPACE_SETTINGS_LANDING_PAGE.name,
	),
	[namedRoutes.SPACE_FORGE_APPS.name]: SpaceForgeAppsRoute,
	[namedRoutes.SPACE_CUSTOM_CONTENT_LIST.name]: SpaceCustomContentListRoute,
	[namedRoutes.SPACE_SETTINGS_FORGE_APPS.name]: SpaceSettingsForgeAppsRoute,
	[namedRoutes.SPACE_SETTINGS_ARCHIVE_SPACE.name]: SpaceSettingsArchiveSpaceRoute,
	[namedRoutes.SPACE_SETTINGS_EDITOR_CONVERSION.name]: SpaceSettingsEditorConversionRoute,
	[namedRoutes.SPACE_SETTINGS_AUTOMATION.name]: SpaceSettingsAutomationRoute,
	[namedRoutes.SPACE_SETTINGS_CONTENT_STATE.name]: SpaceSettingsContentStateRoute,
	[namedRoutes.SPACE_SETTINGS_DATA_CLASSIFICATION.name]: SpaceSettingsDataClassificationRoute,
	[namedRoutes.SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE.name]:
		SpaceSettingsExportSpaceUnavailableRoute,
	[namedRoutes.SPACE_SETTINGS_RELATED_PAGES.name]: SpaceSettingsRelatedPagesRoute,
	[namedRoutes.SPACE_SETTINGS_EDIT_FEATURES.name]: SpaceSettingsEditFeaturesRoute,
	[namedRoutes.SPACE_SETTINGS_DETAILS.name]: SpaceSettingsDetailsRoute,
	[namedRoutes.SPACE_QUESTIONS_NATIVE.name]: QuestionsRoute,
	[namedRoutes.SPACE_QUESTION_NATIVE.name]: QuestionRoute,
	[namedRoutes.MY_CALENDAR.name]: MyCalendarRoute,
	[namedRoutes.PDF_EXPORT_PAGE.name]: PdfExportPageRoute,
	[namedRoutes.PDF_EXPORT_SPACE.name]: PdfExportSpaceRoute,
	[namedRoutes.CUSTOM_CONTENTS_ON_PAGE_LIST.name]: CustomContentOnPageRoute,
	[namedRoutes.CUSTOM_CONTENT_VIEW.name]: CustomContentViewRoute,
	[namedRoutes.SPACE_PERMISSIONS_SETTINGS.name]: SpacePermissionsSettingsRoute,
	[namedRoutes.SPACE_LOOK_AND_FEEL_SETTINGS.name]: SpaceLookAndFeelSettingsRoute,
	[namedRoutes.ADMIN_ANNOUNCEMENT_BANNER_RENDERER.name]: AdminAnnouncementBannerRendererRoute,
	[namedRoutes.SPACE_MANAGER.name]: SpaceManagerRoute,
	[namedRoutes.USER_ACCESS.name]: UserAccessRoute,
	[namedRoutes.ADMIN_CENTER.name]: AdminCenterRoute,
	[namedRoutes.ADMIN_CENTER_SPACE.name]: AdminCenterSpaceRoute,
	[namedRoutes.ADMIN_SETTINGS.name]: AdminSettingsRoute,
	[namedRoutes.ADMIN_BULK_PERMISSIONS.name]: BulkSpacePermissionsRoute,
	[namedRoutes.ADMIN_DEFAULT_SPACE_PERMISSIONS.name]: DefaultSpacePermissionsRoute,
	[namedRoutes.ADMIN_ALL_SPACES_SPACE_PERMISSIONS.name]: IndividualSpacesRoute,
	[namedRoutes.ADMIN_TEAM_CALENDARS.name]: AdminTeamCalendarsRoute,
	[namedRoutes.ADMIN_CONFIGURATION.name]: AdminConfigurationRoute,
	[namedRoutes.ADMIN_DEFAULT_SPACE_LOGO.name]: AdminDefaultSpaceLogoConfigRoute,
	[namedRoutes.ADMIN_HEADER_AND_FOOTER.name]: AdminHeaderAndFooterRoute,
	[namedRoutes.ADMIN_TEMPLATES_AND_BLUEPRINTS.name]: AdminTemplatesAndBlueprintsRoute,
	[namedRoutes.ADMIN_CODE_MACRO_CONFIGURATION.name]: AdminCodeMacroConfigurationRoute,
	[namedRoutes.ADMIN_COLOR_SCHEME.name]: AdminColorSchemeRoute,
	[namedRoutes.ADMIN_SECURITY_CONFIGURATION.name]: AdminSecurityConfigurationRoute,
	[namedRoutes.ADMIN_SPACE_REPORTS.name]: AdminSpaceReportsRoute,
	[namedRoutes.ADMIN_APPLICATION_NAVIGATOR.name]: AdminApplicationNavigatorRoute,
	[namedRoutes.ADMIN_TRASHED_SPACES.name]: AdminTrashedSpacesRoute,
	[namedRoutes.ADMIN_IMPORT_SPACES.name]: AdminImportSpacesRoute,
	[namedRoutes.ADMIN_JIRA_MACRO_REPAIR.name]: AdminJiraMacroRepairRoute,
	[namedRoutes.ADMIN_MACRO_USAGE.name]: AdminMacroUsageRoute,
	[namedRoutes.ADMIN_THEMES.name]: AdminThemesRoute,
	[namedRoutes.ADMIN_PDF_EXPORT.name]: AdminPdfExportRoute,
	[namedRoutes.ADMIN_WORKFLOWS.name]: AdminWorkflowsRoute,
	[namedRoutes.SPACE_JIRA_PROJECT_BOARD.name]: SpaceJiraProjectBoardRoute,
	[namedRoutes.SPACE_MEMBERS.name]: SpaceMembersRoute,
	[namedRoutes.TOPICS.name]: TopicsRoute,
	[namedRoutes.WORKFLOWS.name]: WorkflowsRoute,
});
