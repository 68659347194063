import { AutoIncrementingID } from '../auto-incrementing-id';

import type { Listener, Message } from './types';

/**
 * Event Index
 *
 * Storage for event listeners with auto-incrementing ID's.
 * Access and removal should remain O(1)
 */
export class EventIndex<M extends Message = Message> extends Map<string, Listener<M>> {
	private IDs = new AutoIncrementingID();
	/**
	 * Subscribe
	 *
	 * Adds a subscription to the index and returns a unique
	 * identifier
	 */
	public subscribe(listener: Listener<M>) {
		const ID = this.IDs.ID();
		this.set(ID, listener);
		return ID;
	}

	/**
	 * Unsubscribe
	 *
	 * Removes a subscription from the index by ID
	 */
	public unsubscribe(ID: string) {
		this.delete(ID);
	}

	/**
	 * Dispatch
	 *
	 * Dispatches events to their registered listeners
	 */
	public async dispatch(messages: Parameters<Listener<M>>) {
		for (const [_, listener] of this) {
			await Promise.resolve(listener(...messages));
		}
	}
}
