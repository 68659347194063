import React, { useCallback, useEffect, useMemo } from 'react';

import { CustomUIRenderer, ModalCustomUIRenderer } from './CustomUIRenderer';
import { UIKitRenderer } from './UIKitRenderer';

import {
	makeFrame,
	makeModalThreeLOPromptForCustomUI,
	makeThreeLOPromptForCustomUI,
} from '../../components';
import { ForgeUIExtensionAnalyticsContext } from '../../analytics';
import {
	type ForgeUIRendererProps,
	type ConfluenceCustomUIProps,
	type CloudOrWorkspaceId,
} from './types';
import { isCustomUI } from '../../utils/isCustomUI';

type Props = ForgeUIRendererProps & CloudOrWorkspaceId & ConfluenceCustomUIProps;

export const ForgeUIRenderer = (props: Props) => {
	const {
		accountId,
		bridge,
		client,
		components,
		consentMessage,
		contextIds,
		customBridgeMethods,
		dialogs,
		entryPoint,
		extension,
		extensionData,
		extensionPayload,
		extensionViewData,
		hasModalConsentPrompt,
		height,
		width,
		isIframeHidden,
		isIframeResizable,
		getContextToken,
		loadingComponent,
		locale,
		localId,
		mentionProvider,
		modalExtension,
		modalStyles,
		modalProvider,
		onConsentModalClose,
		onConsentSuccess,
		onError,
		onForgeDocUpdated,
		onIframeLoad,
		onInitialRender,
		product,
		timezone,
		getModalWidth,
		onModalExtensionClose,
	} = props;

	let cloudId: string | undefined;
	let workspaceId: string | undefined;

	if ('cloudId' in props) {
		cloudId = props.cloudId;
	} else if ('workspaceId' in props) {
		workspaceId = props.workspaceId;
	}

	const cloudOrWorkspaceId = cloudId ? { cloudId } : ({ workspaceId } as { workspaceId: string });

	useEffect(() => {
		onInitialRender?.();
	}, [onInitialRender]);

	const { id: extensionId, environmentId, environmentType } = extension;
	const coreData = useMemo(
		() => ({
			workspaceId,
			cloudId,
			localId: localId || extensionId,
			environmentId,
			environmentType,
		}),
		[cloudId, environmentId, environmentType, extensionId, localId, workspaceId],
	);

	const getThreeLOPrompt = useCallback(
		() => ({
			ThreeLOPrompt:
				modalExtension || hasModalConsentPrompt
					? makeModalThreeLOPromptForCustomUI({
							appName: extension.properties.title,
							isBlanketHidden: true,
							onClose: onConsentModalClose,
						})
					: makeThreeLOPromptForCustomUI({
							appName: extension.properties.title,
							onSuccess: onConsentSuccess,
							message: consentMessage,
						}),
		}),
		[
			extension.properties.title,
			consentMessage,
			hasModalConsentPrompt,
			modalExtension,
			onConsentSuccess,
			onConsentModalClose,
		],
	);

	const customUIProps = {
		accountId,
		bridge,
		client,
		consentMessage,
		contextIds,
		coreData,
		customBridgeMethods,
		dialogs,
		entryPoint,
		extension,
		extensionData,
		extensionPayload,
		extensionViewData,
		getContextToken,
		getThreeLOPrompt,
		height,
		loadingComponent,
		locale,
		localId,
		mentionProvider,
		modalExtension,
		modalStyles,
		modalProvider,
		onConsentModalClose,
		onConsentSuccess,
		onError,
		onForgeDocUpdated,
		onIframeLoad,
		product,
		timezone,
		isIframeHidden,
		isIframeResizable,
	};

	return (
		<ForgeUIExtensionAnalyticsContext extensionId={extension.id} localId={localId || extension.id}>
			{/* hidden span used for testing purposes and to identify that this renderer is being used when toggling the feature flag in products*/}
			<span hidden id="forge-ui-renderer" />
			{isCustomUI(extension, entryPoint) ? (
				modalExtension ? (
					// getModalWidth not applied here since all modal extensions are defaulted to medium width
					<ModalCustomUIRenderer {...customUIProps} onClose={onModalExtensionClose} />
				) : (
					<CustomUIRenderer {...customUIProps} getModalWidth={getModalWidth} width={width} />
				)
			) : (
				<UIKitRenderer
					coreData={coreData}
					getThreeLOPrompt={getThreeLOPrompt}
					accountId={accountId}
					client={client}
					contextIds={contextIds}
					extension={extension}
					extensionData={extensionData}
					extensionViewData={extensionViewData}
					product={product}
					bridge={bridge}
					customBridgeMethods={customBridgeMethods}
					components={{
						...components,
						Frame: makeFrame((inputProps) => (
							<CustomUIRenderer
								{...customUIProps}
								extension={{ ...extension, properties: { ...extension.properties, ...inputProps } }}
							/>
						)),
					}}
					consentMessage={consentMessage}
					entryPoint={entryPoint}
					getContextToken={getContextToken}
					locale={locale}
					localId={localId}
					mentionProvider={mentionProvider}
					modalExtension={modalExtension}
					onConsentSuccess={onConsentSuccess}
					onForgeDocUpdated={onForgeDocUpdated}
					timezone={timezone}
					onConsentModalClose={onConsentModalClose}
					onError={onError}
					loadingComponent={loadingComponent}
					extensionPayload={extensionPayload}
					{...cloudOrWorkspaceId}
				/>
			)}
		</ForgeUIExtensionAnalyticsContext>
	);
};
