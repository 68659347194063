import { parse, format } from 'url';

import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import type { StrictParsedUrlQuery } from './types';

export const CONTEXT_PATH = '/wiki';
const SPACE_ROOT = 'spaces';

export const generateSlug = (title: string | null | undefined): string | undefined | null => {
	if (!title) {
		return;
	}
	const slug = title
		// replace groups of reservered chars with a space
		// From https://www.ietf.org/rfc/rfc3986.txt
		// unreserved  = ALPHA / DIGIT / "-" / "." / "_" / "~"
		.replaceAll(/[^A-Za-z0-9\-._~]+/g, ' ')
		// trim the edges
		.trim()
		// replace spaces with '+'
		.replaceAll(' ', '+');

	// guard against relative url attacks
	if (slug === '.' || slug === '..') {
		return undefined;
	}

	return slug || undefined;
};

export const addQueryParams = (href: string, query: StrictParsedUrlQuery) => {
	const parsedUrl = { ...parse(href, true), search: undefined };

	parsedUrl.query = {
		...parsedUrl.query,
		...query,
	};
	parsedUrl.query = omitBy(parsedUrl.query, isNil);
	return format(parsedUrl);
};

export const removeQueryParamsFromUrl = (params: string[], href: string) => {
	const parsedUrl = { ...parse(href, true), search: undefined };
	params.forEach((paramName) => delete parsedUrl.query[paramName]);
	return format(parsedUrl);
};

const removeAllAnchorsFromUrl = (href: string): string => {
	return href.split('#')[0];
};

const removeAllQueryParamsFromUrl = (href: string): string => {
	return href.split('?')[0];
};

export const removeEverythingAfterContentSlug = (href: string) => {
	return removeAllAnchorsFromUrl(removeAllQueryParamsFromUrl(href));
};

/**
 * Build a space link. If path starts with contains context path then it will be removed
 *
 * @param spaceKey
 * @param path
 * @returns {string|*}
 */
export const getSpaceLink = (spaceKey: string, path: string | any = '') => {
	spaceKey = encodeURIComponent(spaceKey);
	let spacePath = (typeof path === 'string' ? path : path.pathname) || '';
	if (spacePath.startsWith(CONTEXT_PATH)) {
		spacePath = spacePath.substring(CONTEXT_PATH.length);
	}
	if (spacePath.startsWith('/')) {
		spacePath = spacePath.substring(1);
	}
	const link = `${CONTEXT_PATH}/${SPACE_ROOT}/${spaceKey}${spacePath ? `/${spacePath}` : ''}`;

	if (typeof path === 'string') {
		return link;
	}

	return {
		...path,
		pathname: link,
	};
};

const getBlogRoute = (spaceKey: string) => getSpaceLink(spaceKey, 'blog');
const getPagesRoute = (spaceKey: string) => getSpaceLink(spaceKey, 'pages');

/**
 * Build a url path to a page or blog post.
 * @param spaceKey The space key
 * @param id The page or blog post id
 * @param contentType The content type ('page' or 'blogpost')
 * @param createdDate The created date of the content, required for blog posts
 * @param slug The url encoded title
 * @returns {string}
 */
export const buildContentPath = (
	spaceKey: string,
	id: string,
	contentType: string | null,
	createdDate: string,
	slug: string | null | undefined,
): string => {
	const trailingSlug = slug ? `/${slug}` : '';
	switch (contentType) {
		case 'blogpost':
			const [yy, mm, dd] = createdDate.slice(0, 10).split('-');
			return `${getBlogRoute(spaceKey)}/${yy}/${mm}/${dd}/${id}${trailingSlug}`;
		case 'page':
			return `${getPagesRoute(spaceKey)}/${id}${trailingSlug}`;
		default:
			throw new Error(`Expected content type 'blogpost' or 'page', but received '${contentType}'`);
	}
};

/**
 * Expression to check if the string has a protocol.
 * @type {RegExp}
 */
export const HAS_PROTOCOL_REG = /^(http(s)?:)?\/\//;

/**
 * Only adds CONTEXT_PATH if it's not included already
 * @param {string} url The url.
 * @param {boolean} isContextAware If the url needs to be aware of the context.
 * @returns {string}
 */
export const getContextAwarePath = (url: string, isContextAware: boolean) =>
	isContextAware && url.toString().indexOf(CONTEXT_PATH) !== 0 ? CONTEXT_PATH : '';

/**
 * Generates a link to a full path.
 * If url starts with http:// or https:// don't add context path and make it skip spa transition
 * @param {string} url The url.
 * @param {boolean} isContextAware If the url needs to be aware of the context.
 * @returns {string}
 */
export const getContextAwareFullPath = (url: string, isContextAware: boolean) => {
	if (HAS_PROTOCOL_REG.test(url)) {
		return url;
	} else {
		return `${getContextAwarePath(url, isContextAware)}${url}`;
	}
};

// Helper to determine if a url path is just a hash
export const isHashedOnlyURL = (urn: string): boolean => urn.charAt(0) === '#';
