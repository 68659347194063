import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Tooltip from '@atlaskit/tooltip/Tooltip';

const i18n = defineMessages({
	follow: {
		id: 'profile.card.follow',
		defaultMessage: 'Follow',
		description: 'Text for the follow button on the profile card',
	},
	unfollow: {
		id: 'profile.card.unfollow',
		defaultMessage: 'Unfollow',
		description: 'Text for the unfollow button on the profile card',
	},
	followTooltip: {
		id: 'profile.card.follow.tooltip',
		defaultMessage: 'See activity in feed',
		description: 'Tooltip text for the follow button on the profile card',
	},
	unfollowTooltip: {
		id: 'profile.card.unfollow.tooltip',
		defaultMessage: 'Stop seeing activity in feed',
		description: 'Tooltip text for the unfollow button on the profile card',
	},
});

type FeedFollowType = {
	followed: boolean;
};

export const FeedFollow: FC<FeedFollowType> = ({ followed }) => {
	const intl = useIntl();
	return (
		<Tooltip
			content={intl.formatMessage(followed ? i18n.unfollowTooltip : i18n.followTooltip)}
			position="bottom"
			testId="feed-follow-tooltip"
		>
			<span>{intl.formatMessage(followed ? i18n.unfollow : i18n.follow)}</span>
		</Tooltip>
	);
};
