import { Fragment } from '@atlaskit/editor-prosemirror/model';
import type { Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import type { NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';

import { isFullStopOmittedInContainerType } from '../../pm-plugins/ai-spelling-grammar/utils';
import { DiffMatchPatch } from '../../utils/diff-match-patch/diff-match-patch';

import {
	concatConsecutiveReplacements,
	convertJoinedDeletesIntoReplacement,
	ignoreDirectReplacements,
	ignoreTrailingFullStops,
	ignoreTrailingSpaces,
	removeMarkdownCustomTagReplacements,
} from './post-processing';

export type IgnoredRange = { pos: number; size: number; type: 'mark' | 'inlineNode' };

export type DiffObject = {
	id: string;
	/**
	 * This represents the starting position in the original content for which the diff applies
	 */
	from: number;
	/**
	 * This represents the ending position in the original content for which the diff applies
	 */
	to: number;
	type: 'REPLACEMENT' | 'ADDITION' | 'DELETION';
	text: string;
	originalText: string;
	hasInlineNodes?: boolean;
	/**
	 * This is the replacement fragment of the original which is to be inserted at the from/to positions. Otherwise this is Fragment.empty if
	 * the target is to be deleted.
	 */
	replacement?: Fragment;

	/**
	 *	This flag will be set if the replacements only contains whitespace.
	 */
	isWhiteSpace?: boolean;
};

export type ParagraphChunk = {
	id: string;
	text: string;
	from: number;
	to: number;
	ignoredRanges: Array<IgnoredRange>;
	containerType: NodeType;
	parentTree?: string[];
};

export type Diff = {
	type: -1 | 0 | 1;
	text: string;
	length: number;
};

export const createDiffs = (
	a: string,
	b: string,
	shouldIgnoreTrailingFullStops?: boolean,
	isMarkdown?: boolean,
): Diff[] => {
	const diff = DiffMatchPatch.diff_linesToWords_(a, b);
	const diffs = DiffMatchPatch.diff_main(diff.chars1, diff.chars2, false);
	DiffMatchPatch.diff_charsToLines_(diffs, diff.lineArray);

	const normalized = diffs.map((diff: { [0]: -1 | 0 | 1; [1]: string }) => ({
		type: diff[0],
		text: diff[1],
		length: diff[1].length,
	}));

	if (fg('platform_editor_ai_proactive_sg_quality_filters')) {
		return [
			concatConsecutiveReplacements,
			...(shouldIgnoreTrailingFullStops ? [ignoreTrailingFullStops] : []),
			ignoreDirectReplacements,
			convertJoinedDeletesIntoReplacement,
			ignoreTrailingSpaces,
			...(isMarkdown ? [removeMarkdownCustomTagReplacements] : []),
		].reduce((diffs, proc) => proc(diffs), normalized);
	} else {
		return normalized;
	}
};

const normaliseType = (diff: Diff, nextDiff?: Diff) => {
	if (diff.type === -1 && nextDiff?.type === 1) {
		// Lookahead 1 diff to see if the next diff is an Addition
		return 'REPLACEMENT';
	}
	// delete
	else if (diff.type === -1) {
		return 'DELETION';
	}
	// add
	else if (diff.type === 1 && (!nextDiff || (nextDiff && nextDiff.type !== -1))) {
		return 'ADDITION';
	}
};

function isIntersectingWithIgnoredRanges(
	diffObject: DiffObject,
	ignoredRanges: Array<IgnoredRange>,
	originalFrom: number,
) {
	return ignoredRanges.some(({ pos, size }) => {
		if (diffObject.to < originalFrom + pos || diffObject.from > originalFrom + pos + size) {
			return false;
		}
		return true;
	});
}

export const getDiffObjectsForParagraph = ({
	originalParagraph,
	correctedParagraphText,
	isDiffHiddenInIgnoredRanges,
	editorView,
}: {
	originalParagraph: ParagraphChunk;
	correctedParagraphText: string;
	isDiffHiddenInIgnoredRanges: boolean;
	editorView?: EditorView;
}) => {
	const diffObjects: Array<DiffObject> = [];

	const originalText = originalParagraph.text;
	const diffs = createDiffs(
		originalText.trim(),
		correctedParagraphText.trim(),
		editorView
			? isFullStopOmittedInContainerType(editorView.state.schema, originalParagraph.containerType)
			: false,
	);

	const trimmingStartOffset = originalText.length - originalText.trimStart().length;
	let offset = originalParagraph.from + trimmingStartOffset;

	const n = diffs.length;
	for (let i = 0; i < n; i++) {
		const { type, text, length } = diffs[i];
		const finalType = normaliseType(diffs[i], diffs?.[i + 1]);

		if (finalType === 'DELETION') {
			diffObjects.push({
				id: `${originalParagraph.id}_${i}`,
				from: offset,
				to: offset,
				type: finalType,
				text: '',
				originalText: text,
			});
		} else if (finalType === 'REPLACEMENT' || finalType === 'ADDITION') {
			// Count how many inline nodes are overlapping with the word correction, and cater for them

			let inlineNodeOffsetSize = 0,
				inlineNodeOverlapSize = 0;
			originalParagraph.ignoredRanges.forEach(({ pos, size, type }) => {
				if (type === 'inlineNode') {
					/**
					 * Calculate offsets from inlineNodes that are before the current paragraph,
					 * and inline nodes within the diff.
					 * Only inline nodes should be considered, as marks don't contribute to node size.
					 */
					if (originalParagraph.from + pos < offset) {
						inlineNodeOffsetSize += size;
					}
					if (
						originalParagraph.from + pos < offset + length &&
						originalParagraph.from + pos > offset
					) {
						inlineNodeOverlapSize += size;
					}
				}
			});

			const from = offset + inlineNodeOffsetSize;

			const obj: DiffObject = {
				id: `${originalParagraph.id}_${i}`,
				from,
				to: offset + length + inlineNodeOffsetSize + inlineNodeOverlapSize,
				type: finalType,
				text,
				originalText: text,
			};

			if (finalType === 'REPLACEMENT') {
				const nextText = diffs?.[i + 1]?.text ?? text;
				const shouldTrimEndingSpace = text.endsWith(' ') && nextText.endsWith(' ');

				obj.to += shouldTrimEndingSpace ? -1 : 0;
				obj.text = shouldTrimEndingSpace ? nextText.slice(0, -1) : nextText;
				obj.originalText = shouldTrimEndingSpace ? text.slice(0, -1) : text;

				i++;
			}

			if (isDiffHiddenInIgnoredRanges) {
				if (
					!isIntersectingWithIgnoredRanges(
						obj,
						originalParagraph.ignoredRanges,
						originalParagraph.from,
					)
				) {
					diffObjects.push(obj);
				}
			} else {
				diffObjects.push(obj);
			}
		}

		// unchanged and removed words are part of original text
		if (type === 0 || type === -1) {
			offset += length;
		}
	}

	return diffObjects;
};

/**
 * This util can be used to generate a collection of diff object fragments using fragments as the comparator objects
 * @param original
 * @param corrected
 * @param schema
 * @param offset
 * @returns
 */
export const createDiffObjectsFromFragments = ({
	id,
	original,
	corrected,
	schema,
	offset = 0,
	containerType,
}: {
	original: Fragment;
	corrected: Fragment;
	id: string;
	schema: Schema;
	offset?: number;
	containerType: NodeType;
}) => {
	const diffObjects: Array<DiffObject> = [];

	// \uFFF9 & \uFFFB  are special unicode annotation markers representing the start/end of annotated content.
	// They're used here in this context to encode/decode inline nodes in a way which allows the text
	// diff process to still occur as normal.
	const leafNodes: PMNode[] = [];
	const { mention } = schema.nodes;
	const leafNodeComparator = (leafNode: PMNode) => {
		let i = leafNodes.findIndex((node) =>
			// Special case for mentions because mentions only retain the ID in transformations at the moment
			node.type === mention ? node.attrs.id === leafNode.attrs.id : node.eq(leafNode),
		);
		if (i < 0) {
			leafNodes.push(leafNode);
			i = leafNodes.length - 1;
		}
		return `\uFFF9${i}\uFFFB`;
	};

	// It's important we push the original nodes first, as we'll do a simple left>right scan to find a matching node later on
	// This means we will prefer the original nodes over replacing them with the corrected versions.
	const orgText = original.textBetween(0, original.size, '', leafNodeComparator);
	const correctedText = corrected.textBetween(0, corrected.size, '', leafNodeComparator);

	const diffs = createDiffs(
		orgText,
		correctedText,
		isFullStopOmittedInContainerType(schema, containerType),
		true,
	).map((diff) => {
		let hasInlineNodes = false;
		const fragment = diff.text.split('\uFFF9').reduce<Fragment>((frag, item) => {
			const parts = item.split('\uFFFB');
			// There should only be 1 or 2 items in the split.
			if (parts.length > 1) {
				hasInlineNodes = true;
				const nodeIndex = parts.shift()!;
				frag = frag.addToEnd(leafNodes[parseInt(nodeIndex)]);
			}

			const remainingText = parts.join('');
			if (remainingText.length > 0) {
				return frag.addToEnd(schema.text(remainingText));
			}

			return frag;
		}, Fragment.empty);

		return {
			type: diff.type,
			text: diff.text,
			length: diff.length,
			fragment,
			hasInlineNodes,
		};
	});

	const n = diffs.length;
	for (let i = 0; i < n; i++) {
		const currentDiff = diffs[i];
		const { type, text, fragment, hasInlineNodes } = currentDiff;
		const finalType = normaliseType(diffs[i], diffs?.[i + 1]);
		const isWhiteSpace = text.trim().length === 0;

		if (finalType === 'DELETION') {
			diffObjects.push({
				id: `${id}_${i}`,
				from: offset,
				to: offset + fragment.size,
				type: finalType,
				originalText: text,
				text: '',
				replacement: Fragment.empty,
				isWhiteSpace,
			});
		} else if (finalType === 'REPLACEMENT' || finalType === 'ADDITION') {
			const obj: DiffObject = {
				id: `${id}_${i}`,
				from: offset,
				to: offset,
				type: finalType,
				text: '',
				originalText: text,
				replacement: fragment,
				hasInlineNodes,
				isWhiteSpace,
			};

			if (finalType === 'REPLACEMENT') {
				const nextDiff = diffs?.[i + 1] ?? currentDiff;
				const shouldTrimEndingSpace = text.endsWith(' ') && nextDiff.text.endsWith(' ');

				obj.to += fragment.size + (shouldTrimEndingSpace ? -1 : 0);
				obj.replacement = shouldTrimEndingSpace
					? nextDiff.fragment.cut(0, nextDiff.fragment.size - 1)
					: nextDiff.fragment;
				obj.text = nextDiff.text;
				obj.isWhiteSpace = obj.isWhiteSpace || obj.replacement.size === 0;

				i++;
			}

			diffObjects.push(obj);
		}

		// unchanged and removed words are part of original text
		if (type === 0 || type === -1) {
			offset += fragment.size;
		}
	}

	return diffObjects;
};
