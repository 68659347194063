import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { CenteredSpinner } from '@confluence/confluence-shared-components';

import { panel, panelHeader, panelBody } from './styles';

type ExcerptIncludeComponentShadowProps = {
	shouldPanelBeDisplayed: boolean;
	targetContentTitle: string;
};

export class ExcerptIncludeComponentShadow extends PureComponent<ExcerptIncludeComponentShadowProps> {
	render() {
		const { shouldPanelBeDisplayed, targetContentTitle } = this.props;

		return shouldPanelBeDisplayed ? (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<span data-testid="excerpt-include-panel" style={panel}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
				<span style={panelHeader}>
					{targetContentTitle || (
						// eslint-disable-next-line check-i18n-id-prefix/i18n-id-prefix-with-package-name-FormattedMessage
						<FormattedMessage id="editor.excerpt.include.panel.title.loading" />
					)}
				</span>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
				<span style={panelBody}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
					<CenteredSpinner size="medium" style={{ minHeight: 100 }} />
				</span>
			</span>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<CenteredSpinner size="medium" style={{ minHeight: 100 }} />
		);
	}
}
