import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { LoadablePaint } from '@confluence/loadable';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';

const SpaceLookAndFeelSettingsRouteComponent = LoadablePaint({
	loader: async () =>
		import(
			/* webpackChunkName: "loadable-SpaceLookAndFeelSettingsRouteComponent" */ './SpaceLookAndFeelSettingsRouteComponent'
		),
});

export const SpaceLookAndFeelSettingsRoute = ({ params: { spaceKey, tab } }: RouteMatch) => {
	return <SpaceLookAndFeelSettingsRouteComponent spaceKey={spaceKey} tab={tab} />;
};

SpaceLookAndFeelSettingsRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }) => ({
	Screen: {
		screenEvent: {
			name: 'spaceSettingLookAndFeelPage',
			id: spaceKey,
		},
		pageState: {
			routeName: name,
			spaceKey,
		},
	},
	MainLayout: {
		navView: getIsNav4Enabled() ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
	},
});
