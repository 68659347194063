import { LoadableLazy } from '@confluence/loadable';

export {
	EditPageLoadingContext,
	EditPageLoadingActionsContext,
	EditPageLoadingContextProvider,
	useEditPageLoadingValues,
	useEditPageLoadingActions,
} from './EditPageLoadingContext';
export { NativeEditPageDataLoader } from './NativeEditPageDataLoader';
export {
	extractIsEditorVersionFabric,
	extractEditorContentType,
	fetchEditorTypeAndVersionPropertyQuery,
} from './editorTypeAndVersionPropertyHelpers';
export type {
	EditorTypeAndVersionPropertyQuery as EditorTypeAndVersionPropertyQueryType,
	EditorTypeAndVersionPropertyQueryVariables as EditorTypeAndVersionPropertyQueryVariablesType,
} from './preload/queries/__types__/EditorTypeAndVersionPropertyQuery';
export { EditorTypeAndVersionPropertyQuery } from './preload/queries/EditorTypeAndVersionPropertyQuery.graphql';
export { preloadEditorData } from './preload/preloadEditorData';
export { startPageLoadForEditor } from './startPageLoadForEditor';

export const EditPageLoadingListener = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditPageLoadingListener" */ './EditPageLoadingListener'
			)
		).EditPageLoadingListener,
});
