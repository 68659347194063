import React from 'react';

import type { MatchQuery, RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { HOME } from '@confluence/named-routes';

export function RedirectToHomeWithQuery(extra: MatchQuery) {
	const redirectComponent = ({ query }: RouteMatch) => (
		<Redirection name={HOME.name} params={{ query: { ...query, ...extra } }} />
	);
	redirectComponent.NAVIGATION_PARAMS = () => false;
	return redirectComponent;
}
