import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const PageContainerStyles = xcss({
	maxWidth: '1024px',
	minWidth: '504px',
	padding: 'space.600',
	paddingBottom: 'space.1000',
	margin: '0 auto',
	boxSizing: 'content-box',
});

export const PageContainer = ({ children }: React.PropsWithChildren<{}>) => (
	<Box xcss={PageContainerStyles} as="main">
		{children}
	</Box>
);
