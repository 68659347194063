import React from "react";

import { LegacyMacroRenderer } from "@confluence/fabric-extension-legacy-macro-renderer";
import { TableOfContentsLoader, LazyTableOfContentsLoader } from "@confluence/table-of-contents";

export const TableOfContentsHandler = ({adf, macroDefaultProps, cloudId, userId, shouldLoadAfterPaint }) => {
  if (!adf) {
    return <LegacyMacroRenderer {...macroDefaultProps} />;
  }

  return shouldLoadAfterPaint
  ? <LazyTableOfContentsLoader userId={userId} cloudId={cloudId} adf={adf} macroDefaultProps={macroDefaultProps}/>
  : <TableOfContentsLoader userId={userId} cloudId={cloudId} adf={adf} macroDefaultProps={macroDefaultProps}/>;
};
