import type { MentionNameDetails } from '@atlaskit/mention';
import { MentionNameStatus } from '@atlaskit/mention';

import { MENTIONS_LOOKUP_LIMIT, preloadMentionsUsers } from './preloadMentionsUsers';

export class MentionNameClient {
	getLookupLimit(): number {
		// HOT-108964 - sending too many mentions in the request header at once returns a 400
		// The API itself apparently has a hard limit of 90 (HOT-95106), but if we're not careful we can send too many
		// mentions and make the request header too large and the endpoint falls over
		return MENTIONS_LOOKUP_LIMIT;
	}

	async lookupMentionNames(ids: string[]): Promise<MentionNameDetails[]> {
		// Allows to rely upon the preloaded mentions data when resolving mentions in the editor
		// both on the client and server side
		return preloadMentionsUsers(ids).then((users) =>
			users.map((user) => ({ ...user, status: MentionNameStatus.OK }) as MentionNameDetails),
		);
	}
}
