import { useEffect, useRef, useState } from 'react';

import { getIframeObservable_DO_NOT_USE } from '@atlassian/embedded-confluence-common/ccfe-entry-points';
import type { IframePassThroughProps } from './IframeElementType';
import { type ObservableObject } from './ObservableObject';

/**
 * This hook would be used by Confluence FE (embedded view and edit component) to receive React props through iframe
 * Note: Please do not use it outside of embeddable page project. This is associated with Embeddable page iframe approach (temporary solution)
 *
 */
export function useReactProps_DO_NOT_USE<Props extends IframePassThroughProps>() {
	const unsubscribeRef = useRef<ReturnType<ObservableObject<Props>['subscribe']>>();

	const [embeddedConfluenceReactProps, setEmbeddedConfluenceReactProps] = useState<Props | null>(
		null,
	);

	if (!unsubscribeRef.current) {
		const observableObject = getIframeObservable_DO_NOT_USE<Props>();
		if (observableObject) {
			const updateProps = () => {
				setEmbeddedConfluenceReactProps(observableObject.object);
			};

			unsubscribeRef.current = observableObject.subscribe(updateProps);

			updateProps();
		}
	}

	useEffect(() => unsubscribeRef.current, []);

	return embeddedConfluenceReactProps;
}
