import gql from 'graphql-tag';

import { contentAppearancePublishedFragment } from '@confluence/content-appearance/entry-points/fragments';
import { byLineUserFragment } from '@confluence/content-unified-query/entry-points/fragments';

export const ContentHeaderUnifiedQuery = gql`
	query ContentHeaderUnifiedQuery(
		$contentId: ID!
		$spaceKey: String
		$versionOverride: Int
		$embeddedContentRender: String
		$limit: Int
		$useNewContentTopper: Boolean = false
	) {
		content(
			id: $contentId
			version: $versionOverride
			embeddedContentRender: $embeddedContentRender
		) {
			nodes {
				id
				type
				subType
				title
				...contentAppearancePublishedFragment
				history {
					createdDate
					createdBy {
						...byLineUserFragment
					}
					ownedBy {
						...byLineUserFragment
					}
					lastOwnedBy {
						...byLineUserFragment
					}
				}
				emojiTitlePublished: properties(key: "emoji-title-published") {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
				pageTitlePublished: properties(key: "page-title-property-published")
					@include(if: $useNewContentTopper) {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
				properties(key: "editor") {
					nodes {
						id
						key
						value
					}
				}
				space {
					id
					name
				}
				version {
					message
					by {
						...byLineUserFragment
					}
					friendlyWhen
					number
					contentTypeModified
				}
				metadata {
					sourceTemplateEntityId
					lastModifiedDate
					frontend {
						coverPictureWidth @include(if: $useNewContentTopper)
					}
				}
			}
		}
		contentContributors(id: $contentId, limit: $limit) {
			count
			isCurrentUserContributor
			nodes {
				...byLineUserFragment
			}
		}
		space(key: $spaceKey) {
			id
			theme {
				themeKey
			}
			lookAndFeel {
				headings {
					key
					value
				}
				content {
					header {
						background
						backgroundAttachment
						backgroundBlendMode
						backgroundClip
						backgroundColor
						backgroundImage
						backgroundOrigin
						backgroundPosition
						backgroundRepeat
						backgroundSize
						borderRadius
						padding
					}
				}
			}
		}
	}

	${byLineUserFragment}
	${contentAppearancePublishedFragment}
`;
