import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import {
	preloadSpaceBlogs,
	preloadSpaceBlogsAppearance,
} from '@confluence/space-blogs/entry-points/preloadSpaceBlogs';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadSpaceBlogsRoute = async (match: RouteMatch) => {
	const { isLoggedIn } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const spaceKey = match.params.spaceKey;

	tasks.push(
		preloadSpaceBlogs({ spaceKey }),
		preloadPageTree({
			spaceKey,
			statuses: PAGE_TREE_STATUSES,
			isSuperAdmin: false,
		}),
		preloadBlogTree({ spaceKey }),
		preloadSpaceBlogsAppearance({ spaceKey, isLoggedIn }),
	);

	return Promise.all(tasks);
};
