import React, { Fragment, useState } from 'react';
import {
	type FieldChildrenProps,
	type NativeRangeProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
// AFP-2532 TODO: Fix automatic suppressions below
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { FormStateChangeNotifier } from '../../UIKit1/form';
import { useIsInForm } from '../utils/useIsInForm';
import { Label } from '../label';
import { FieldContainer } from '../utils/FieldContainer';

const AKRange = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.range' */
			'@atlaskit/range'
		),
);
const AKField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.Field,
	})),
);

export const Range = (props: Parameters<RenderFn>[0]) => {
	const {
		defaultValue,
		min = 0,
		max = 100,
		step = 1,
		label,
		name,
		testId,
		onChange,
	} = props.forgeDoc.props as NativeRangeProps & { testId?: string };

	const initialValue = min >= (defaultValue || min) ? min : defaultValue;

	const [rangeValue, setRangeValue] = useState(initialValue);

	const isInForm = useIsInForm();
	if (!isInForm) {
		return (
			<FieldContainer>
				<Label name={name} label={label} />
				<AKRange
					min={min}
					max={max}
					step={step}
					testId={testId}
					value={rangeValue}
					onChange={(v: number) => {
						setRangeValue(v);
						onChange?.(v);
					}}
				/>
			</FieldContainer>
		);
	}

	return (
		<FieldContainer>
			<AKField name={name} label={label} defaultValue={initialValue}>
				{({ fieldProps }: FieldChildrenProps) => {
					const {
						onChange: fieldOnChange,
						id,
						isDisabled,
						onBlur,
						onFocus,
						value,
						'aria-invalid': ariaInvalid,
						'aria-labelledby': ariaLabelledBy,
					} = fieldProps;
					return (
						<Fragment>
							<FormStateChangeNotifier name={name} value={fieldProps.value} />
							<AKRange
								id={id}
								min={min}
								max={max}
								step={step}
								testId={testId}
								onChange={(value: number) => {
									fieldOnChange(value);
									onChange?.(value);
								}}
								isDisabled={isDisabled}
								onBlur={onBlur}
								onFocus={onFocus}
								value={value}
								aria-invalid={ariaInvalid}
								aria-labelledby={ariaLabelledBy}
							/>
						</Fragment>
					);
				}}
			</AKField>
		</FieldContainer>
	);
};
