import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { FlagsStateContainer } from '@confluence/flags';

const i18n = defineMessages({
	localStorageQuotaExceededTitle: {
		id: 'local-storage-quota-subscriber.flag.quota-exceeded.title',
		defaultMessage: 'We ran into a problem',
		description: 'Title for the flag notifying user that local storage quota has been exceeded',
	},
	localStorageQuotaExceededDescription: {
		id: 'local-storage-quota-subscriber.flag.quota-exceeded.description',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: `We’ve noticed an issue that may affect your experience on Confluence. Select "Refresh" below to fix it.`,
		description:
			'Description for the flag notifying user that local storage quota has been exceeded',
	},
	refreshLocalStorage: {
		id: 'local-storage-quota-subscriber.flag.refresh.title',
		defaultMessage: 'Refresh',
		description: 'Title for the flag action to refresh and clear local storage',
	},
});

export const LocalStorageQuotaSubscriber: FC<{}> = () => {
	const [isOverflown, setIsOverflown] = useState(false);
	const [wasShown, setWasShown] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const onQuotaExceeded = () => setIsOverflown(true);
		window.addEventListener('local-storage.quota.exceeded', onQuotaExceeded);

		return () => {
			setIsOverflown(false);
			window.removeEventListener('local-storage.quota.exceeded', onQuotaExceeded);
		};
	}, [setIsOverflown]);

	const clearLocalStorage = () => {
		window.localStorage.clear();
		window.location.reload();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'LocalStorageQuotaSubscriber',
				action: 'clicked',
				actionSubject: 'LocalStorageQuotaExceededFlag',
				actionSubjectId: 'localStorageQuotaCleared',
			},
		}).fire();
	};

	const showFlag = (flags: FlagsStateContainer) => {
		if (wasShown) return;

		void flags.showWarningFlag({
			title: <FormattedMessage {...i18n.localStorageQuotaExceededTitle} />,
			description: <FormattedMessage {...i18n.localStorageQuotaExceededDescription} />,
			actions: [
				{
					content: <FormattedMessage {...i18n.refreshLocalStorage} />,
					href: '',
					onClick: () => clearLocalStorage(),
				},
			],
		});
		setWasShown(true);
	};

	return (
		<Subscribe to={[FlagsStateContainer]}>
			{(flags: FlagsStateContainer) => {
				if (isOverflown) {
					showFlag(flags);
				}
				return null;
			}}
		</Subscribe>
	);
};
