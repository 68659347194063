import React from 'react';
import { redirectToLoginPage } from '@confluence-classic/confluence-authentication';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { START_TOUCH } from '@confluence/navdex';

const PeopleDirectory = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PeopleDirectory" */ '@confluence/people-directory/entry-points/PeopleDirectory'
			)
		).PeopleDirectory,
});

export const PeopleDirectoryRoute = () => (
	<PeopleDirectory statusCodeHandlers={{ 401: redirectToLoginPage }} />
);

PeopleDirectoryRoute.NAVIGATION_PARAMS = () => {
	return {
		Screen: {
			name: 'inProdPeopleDirectory',
			attributes: {
				navdexPointType: START_TOUCH,
			},
		},
		MainLayout: { navView: PRODUCT_HOME_ID },
	};
};
