import { HomeStarredPagesQuery } from './HomeStarredPagesQuery.graphql';

const PAGE_SIZE = 24;

export const getStarredQueryOptions = ({
	isSpaceAliasFFEnabled = false,
}: {
	isSpaceAliasFFEnabled: boolean;
}) => ({
	query: HomeStarredPagesQuery,
	variables: {
		limit: PAGE_SIZE,
		start: 0,
		includeAlias: isSpaceAliasFFEnabled,
	},
});
