import gql from 'graphql-tag';

export const GetCollabServiceWithMigrationQuery = gql`
	query getCollabServiceWithMigrationQuery($contentId: ID, $draftShareId: String) @SLA {
		content(id: $contentId, status: ["current", "draft"], draftShareId: $draftShareId) {
			nodes {
				id
				metadata {
					frontend {
						collabServiceWithMigration
					}
				}
			}
		}
	}
`;
