import { hashData } from '@confluence/hash';
import uuid from 'uuid';

let SESSION_SALT: string;

export const generateNewSalt = () => {
	SESSION_SALT = uuid();
};

const toStringArray = (arr: any[]): string => `[${arr.map((item) => toString(item)).join(',')}]`;

const toString = (o: any): string => {
	if (o === null) {
		return 'null';
	}
	if (Array.isArray(o)) {
		return toStringArray(o);
	}
	if (typeof o == 'object') {
		return objectHash(o);
	}
	if (typeof o == 'string') {
		return `"${o}"`;
	}
	return `${o.toString()}`;
};

export const objectHash = (o: object): string => {
	if (!SESSION_SALT) generateNewSalt();
	const strToHash = `${Object.entries(o)
		.filter(([_, value]) => typeof value !== 'undefined')
		.sort((a, b) => a[0].localeCompare(b[0]))
		.map(([key, value]) => `${key}:${toString(value)}`)
		.join()},${SESSION_SALT}`;
	return hashData(strToHash);
};
