import React, { useState, useContext } from 'react';
import { type LoadingButtonProps } from '@atlaskit/button/loading-button';
import Icon from '../../UIKit1/icon';
import { WidthContext } from '../../../context';
import { buttonShouldFitContainer } from '../../UIKit1/button/breakpoints';
import { type NativeButtonProps, type RenderFn } from '@atlassian/forge-ui-types';

export const AkButton = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.button/loading-button' */
			'@atlaskit/button/loading-button'
		),
);

// 'Native' can't be removed from the name as this hook is exported for products to use
export function useNativeButton({ forgeDoc }: Parameters<RenderFn>[0]): {
	akButtonProps: LoadingButtonProps;
} {
	const {
		appearance = 'default',
		disabled,
		onClick,
		icon,
		iconPosition = 'before',
	} = forgeDoc.props as NativeButtonProps;
	let buttonText = '';

	// These conditions only allows string children to be passed through.
	// Other elements are not permitted.
	if (typeof forgeDoc.children === 'string') {
		buttonText = forgeDoc.children;
	} else if (
		Array.isArray(forgeDoc.children) &&
		forgeDoc.children.length &&
		forgeDoc.children[0].type === 'String'
	) {
		buttonText = forgeDoc.children[0].props?.text;
	}

	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		try {
			await onClick();
		} finally {
			setLoading(false);
		}
	};

	const iconDom = icon ? <Icon size="medium" glyph={icon} /> : undefined;

	const iconBefore = iconPosition === 'before' ? iconDom : undefined;
	const iconAfter = iconPosition === 'after' ? iconDom : undefined;
	const width = useContext(WidthContext);
	const shouldFitContainer = buttonShouldFitContainer(width);

	return {
		akButtonProps: {
			shouldFitContainer,
			iconBefore,
			iconAfter,
			isLoading: loading,
			isDisabled: disabled,
			appearance,
			onClick: handleClick,
			children: buttonText,
		},
	};
}

export function Button(props: Parameters<RenderFn>[0]) {
	const { akButtonProps } = useNativeButton(props);
	const {
		shouldFitContainer,
		iconBefore,
		iconAfter,
		isLoading,
		isDisabled,
		appearance,
		onClick,
		children,
	} = akButtonProps;
	return (
		<AkButton
			shouldFitContainer={shouldFitContainer}
			iconBefore={iconBefore}
			iconAfter={iconAfter}
			isLoading={isLoading}
			isDisabled={isDisabled}
			appearance={appearance}
			onClick={onClick}
		>
			{children}
		</AkButton>
	);
}
