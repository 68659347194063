import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { NotFound } from '@confluence/not-found';

type Space404Props = {
	goBackPreviousPage?(): void;
	isFirstRoute?: boolean;
};

export const Space404 = (props: Space404Props) => {
	const { isFirstRoute, goBackPreviousPage } = props;

	const onClickGoPreviousLink = (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (goBackPreviousPage) {
			goBackPreviousPage();
		}
	};

	return (
		<NotFound
			secondaryButtonProps={{
				children: !isFirstRoute && goBackPreviousPage && (
					<FormattedMessage
						id="space-404.text.go.back.previous.page"
						defaultMessage="Go to previous page"
						description="link text to go the page user just came from"
					/>
				),
				onClick: onClickGoPreviousLink,
			}}
		/>
	);
};

Space404.displayName = 'Space404';
