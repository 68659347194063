import { type Store } from 'redux';

import { type Config } from '../../common/types/config';
import { type Message, type MessageMapping } from '../../common/types/message';
import { getMessages, type MessagesResponse } from '../../services/engage-targeting';
import { type CancellationToken } from '../../utils/createCancellationToken';
import { setMessageMapping } from '../engagement-actions';
import { type State as EngagementStoreState, store } from '../engagement-store';

/**
 * Returns a mapping of event to message. A message can have multiple events mapping to it.
 */
export const getEventToMessageMapping = (messages: Message[]): MessageMapping => {
	const mapping = new Map();
	messages.forEach((message) => {
		const components = message.components;
		components.forEach((component, index) => {
			component.triggers.forEach((trigger: string) => {
				mapping.set(trigger, {
					message,
					component: {
						componentId: index,
						isLast: index === components.length - 1,
						...component,
					},
				});
			});
		});
	});

	return mapping;
};

/**
 * We fetch messages from targeting and re-fetch based on interval from targeting.
 *
 * If the interval is set to 0, we don't process.
 *
 * If there's an active message, we don't update the store either as that will cause the current
 * message to be added back to the message mapping.
 */
export async function processMessages(
	engagementStore: Store<EngagementStoreState>,
	config: Config,
	cancellationToken: CancellationToken,
) {
	if (cancellationToken.isCancelled) {
		return;
	}

	const state: EngagementStoreState = store.getState();
	const { cloudId, stargateUrl, product, locale } = config;
	let messages: Message[], interval: number;
	try {
		const results: MessagesResponse = await getMessages(cloudId, stargateUrl, product, locale);
		if (cancellationToken.isCancelled) {
			return;
		}
		messages = results.messages;
		interval = results.interval;
	} catch (e) {
		// TODO: Configure metal to monitor errors here

		// Give up, don't try fetching again
		return;
	}
	// Don't overwrite the messageMapping if there's an active message
	if (state.current === null) {
		const messageMapping: MessageMapping = getEventToMessageMapping(messages);
		engagementStore.dispatch(setMessageMapping(messageMapping));
	}

	if (interval !== 0) {
		setTimeout(() => {
			processMessages(engagementStore, config, cancellationToken);
		}, interval * 1000);
	}
	// TODO: Configure metal to monitor success response here
}
