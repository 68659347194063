import { SSRMeasures } from '@confluence/action-measures';

// 500 ms timeout for each macro loading task, as this is the value the feature
// was rolled out with. On staging, we use 1000 ms to avoid test flakiness.
const TIMEOUT = process.env.CLOUD_ENV === 'staging' ? 1000 : 500;

export const macroPreloadGuard = async (
	name: string,
	task: () => Promise<any>,
): Promise<boolean> => {
	const timeoutPromise = new Promise<boolean>((resolve) =>
		setTimeout(() => {
			resolve(false);
		}, TIMEOUT),
	);

	const runTask = async () => {
		await SSRMeasures.run(`ssr-app/prepare/preloadQuery/macroPreloadGuard:${name}`, task);
		return true;
	};

	return Promise.race([timeoutPromise, runTask()]);
};
