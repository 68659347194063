import { useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { requireLegacyWRM } from '@confluence/wrm';
import { isViewPage as checkIsViewPage } from '@confluence/named-routes';

import { getConnectDataListener } from './connectDataListener';
import { getReceivedConnectRefDataEvent } from './analytics';

export const DATA_PROVIDER = 'dataProvider';

export const DataProviderModule = ({ connectHost }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const isViewPage = checkIsViewPage();

		setDataProviderModule({
			connectHost,
			createAnalyticsEvent,
			isViewPage,
		});
	}, [connectHost, createAnalyticsEvent]);

	return null;
};

export const checkIsDPModuleDefined = () => !!window['connectHost']?.isModuleDefined(DATA_PROVIDER);

export const setDataProviderModule = ({ connectHost, createAnalyticsEvent, isViewPage }) => {
	const isDPModuleDefined = checkIsDPModuleDefined();
	const dataProviderModule = {
		emitToDataProvider: async (...args) => {
			const data = args.length > 1 ? args[0] : null;
			const sendResponse = args.length > 1 ? args[args.length - 1] : args[0];
			const context = sendResponse?._context;
			const fragmentLocalId =
				context?.extension?.options?.productContext?.['macro.fragmentLocalId'];

			const extensionKey = context?.extension?.key;

			const event = getReceivedConnectRefDataEvent({
				isViewPage,
				extensionKey,
			});
			createAnalyticsEvent(event).fire();

			if (!extensionKey) {
				throw Error('Missing Connect app extensionKey while transferring data.');
			}

			const connectDataListener = getConnectDataListener();
			const status = await connectDataListener.transfer(
				extensionKey,
				fragmentLocalId,
				data,
				createAnalyticsEvent,
				isViewPage,
			);

			return sendResponse(status);
		},
	};

	if (!isDPModuleDefined) {
		connectHost.defineModule(DATA_PROVIDER, dataProviderModule);
	}
};

export const loadDataProviderModule = async ({ logger, isViewPage, createAnalyticsEvent }) => {
	await new Promise<void>((resolve) => {
		requireLegacyWRM(
			[
				'wr!confluence.web.resources:querystring',
				'wr!confluence.web.resources:navigator-context',
				'wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-resources-v5',
			],
			() => {
				const connectHost = window['connectHost'];

				setDataProviderModule({
					connectHost,
					createAnalyticsEvent,
					isViewPage,
				});

				resolve();
			},
			() => {
				logger.error`Error loading WRM for dataProvider!`;
				resolve();
			},
		);
	});
};
