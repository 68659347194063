import React from 'react';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { RendererExtensionHandlers } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';

export type RendererExtensionProviders = {
	getLegacyContentExtensions?: () => any;
	getExtensionHandlers?: (
		options: any,
		createAnalyticsEvent?: CreateUIAnalyticsEvent,
	) => RendererExtensionHandlers;
	populateExtensionProviders?: (dataProviders: ProviderFactory, options: any) => void;
	EditorLoaderLoader?: any;
};

export const RendererExtensionContext = React.createContext<RendererExtensionProviders>({});
