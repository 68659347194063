import { isApolloError } from 'apollo-client';

import { isGraphQLError } from './isGraphQLError';
import { NetworkError } from './NetworkError';

export class NoNetworkError extends NetworkError {
	/**
	 * If the call site of `cfetch` has indicated that `NoNetworkError`s are to be
	 * ignored by handlers, then `true`; otherwise, `false` or `undefined`.
	 */
	ignore?: boolean;
}

// TODO(review the code): have seen error like
/*
Error: GraphQL error: Exception querying the remote GraphQL service: io.atlassian.platform.micros.asynchttp.retry.RetryException
    at new ApolloError (bundle.esm.js:63)
    at ObservableQuery.push.../../node_modules/apollo-client/bundle.esm.js.ObservableQuery.getCurrentResult (bundle.esm.js:159)
    at QueryData.push.../../node_modules/@apollo/react-hooks/lib/react-hooks.esm.js.QueryData.getQueryResult (react-hooks.esm.js:265)
    at QueryData._this.getExecuteResult (react-hooks.esm.js:73)
    at QueryData.push.../../node_modules/@apollo/react-hooks/lib/react-hooks.esm.js.QueryData.execute (react-hooks.esm.js:106)
    at react-hooks.esm.js:380
    at useDeepMemo (react-hooks.esm.js:354)
    at useBaseQuery (react-hooks.esm.js:380)
    at useQuery (react-hooks.esm.js:397)
    at Query (react-components.esm.js:8)
 */
export function containsNoNetworkError(error: Error): boolean {
	if (error instanceof NoNetworkError) {
		return true;
	}

	if (isApolloError(error)) {
		const { graphQLErrors, networkError } = error;
		if (networkError) {
			// ApolloError describes a network-related error if its networkError
			// property value is truthy. These are errors that have occurred within a
			// NetworkLink of ApolloClient. Think non-experimental queries (and
			// mutations).
			return true;
		}

		return graphQLErrors.some(containsNoNetworkError);
	}

	if (isGraphQLError(error)) {
		const { originalError } = error;

		if (originalError) {
			return containsNoNetworkError(originalError);
		}
	}

	return false;
}

export function causedByAbortError(error: NoNetworkError): boolean {
	return error.cause instanceof Error && error.cause?.name === 'AbortError';
}
