import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';

const SUPPORT_LINK = 'https://support.atlassian.com/contact/';

const i18n = defineMessages({
	errorTitle: {
		id: 'admin-center.error.title',
		defaultMessage: 'Loading error',
		description: 'Error message that content could not be displayed',
	},
	errorTitleIconLabel: {
		id: 'admin-center.error.title.icon-label',
		defaultMessage: 'Error icon',
		description: 'Aria-label for error icon message that content could not be displayed',
	},
	errorDescription: {
		id: 'admin-center.error.description',
		defaultMessage: 'Refresh the page. <a>Contact support</a> if this persists.',
		description: 'Describes further actions a user can take if error occurs',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorTitle = styled.div({
	display: 'flex',
	alignItems: 'center',
	color: token('color.text.danger', '#DE350B'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorDescription = styled.div({
	display: 'flex',
	color: token('color.text.subtlest', '#626F86'),
	marginTop: token('space.050', '4px'),
});

export const ContainerErrorMessage = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<ErrorTitle>
				<ErrorIcon size="small" label={formatMessage(i18n.errorTitleIconLabel)} />
				<FormattedMessage {...i18n.errorTitle} />
			</ErrorTitle>
			<ErrorDescription>
				<FormattedMessage
					{...i18n.errorDescription}
					values={{
						a: (chunks: React.ReactNode[]) => <a href={SUPPORT_LINK}>{chunks}</a>,
					}}
				/>
			</ErrorDescription>
		</>
	);
};
