import React from 'react';

import Icon from '@atlaskit/icon/base';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { B50, B400, N30, N80 } from '@atlaskit/theme/colors';

const ExcerptMacroIconGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid="excerpt-macro-icon"
	>
		<rect
			x="5"
			y="8"
			width="22"
			height="16"
			rx="2"
			fill={token('color.background.accent.blue.subtlest.hovered', B50)}
		/>
		<rect x="8" y="15" width="16" height="2" rx="1" fill={token('color.link', B400)} />
		<rect x="8" y="11" width="16" height="2" rx="1" fill={token('color.link', B400)} />
		<rect
			x="8"
			y="4"
			width="19"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect
			x="8"
			y="30"
			width="19"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect
			x="8"
			width="16"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect
			x="8"
			y="26"
			width="16"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect x="8" y="19" width="10" height="2" rx="1" fill={token('color.link', B400)} />
	</svg>
);

export const ExcerptMacroIcon = (props: IconProps) => (
	<Icon
		glyph={ExcerptMacroIconGlyph}
		size="large"
		secondaryColor={token('color.background.accent.gray.subtler', N30)}
		{...props}
	/>
);
