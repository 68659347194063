import gql from 'graphql-tag';

export const SpaceIdentifiersQuery = gql`
	query SpaceIdentifiersQuery($spaceKey: String, $includeKey: Boolean = false) {
		space(key: $spaceKey) {
			id
			key @include(if: $includeKey)
		}
	}
`;
