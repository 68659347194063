import { type CreateUIAnalyticsEvent, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { type PlacementOnEvent } from '@atlassian/post-office-placement-shared';
import React, { type ErrorInfo, type FunctionComponent } from 'react';

interface ErrorBoundaryProps {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	children: React.ReactNode;
	placementId: string;
	analyticsChannel: string;
	onError: PlacementOnEvent;
	fallback?: FunctionComponent;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const analyticsEvent = this.props.createAnalyticsEvent({
			action: 'handled',
			actionSubject: 'error',
			actionSubjectId: 'postOfficePlacementErrorBoundary',
			attributes: {
				error: error?.message,
				errorInfo,
				placementId: this.props.placementId,
			},
		});
		analyticsEvent.fire(this.props.analyticsChannel);

		this.props.onError({ error, type: 'render-error' });

		this.setState({
			hasError: true,
		});
	}

	override render() {
		if (this.state.hasError) {
			return this.props.fallback ? <>{this.props.fallback}</> : null;
		}

		return this.props.children;
	}
}

export default withAnalyticsEvents()(ErrorBoundary);
