import { useRef, useEffect } from 'react';

import {
	createSingleQueryParamHook,
	useRouteActions,
} from '@confluence/route-manager/entry-points/RouteState';

type Prop = {
	spaceKey?: string;
	contentId?: string;
};

type SearchSessionType = {
	spaceKey?: string;
	contentId?: string;
	searchSessionId: string | null;
	additionalAnalyticsToParse: string | null;
};

const useSearchIdQueryParam = createSingleQueryParamHook('search_id');
const useAdditionalAnalyticsQueryParam = createSingleQueryParamHook('additional_analytics');

export const useSearchSessionIdFromQueryParam = ({ spaceKey, contentId }: Prop) => {
	const searchSessionCache = useRef<SearchSessionType | null>(null);
	const { setQueryParams } = useRouteActions();
	const search_id = useSearchIdQueryParam() || null;
	const additional_analytics = useAdditionalAnalyticsQueryParam() || null;

	const isCacheStale =
		searchSessionCache.current == null ||
		searchSessionCache.current.spaceKey != spaceKey ||
		searchSessionCache.current.contentId != contentId ||
		(searchSessionCache.current.additionalAnalyticsToParse != additional_analytics &&
			additional_analytics != null) ||
		(searchSessionCache.current.searchSessionId != search_id && search_id != null);

	// Cache the searchSessionId/additionalAnalytics because it will be stripped from the query string
	useEffect(() => {
		if (isCacheStale) {
			searchSessionCache.current = {
				spaceKey,
				contentId,
				searchSessionId: search_id,
				additionalAnalyticsToParse: additional_analytics,
			};
		}
	}, [isCacheStale, spaceKey, contentId, search_id, additional_analytics]);

	// Strip query param
	useEffect(() => {
		if (search_id) {
			setQueryParams(
				{
					search_id: null,
				},
				true,
			);
		}
		if (additional_analytics) {
			setQueryParams(
				{
					additional_analytics: null,
				},
				true,
			);
		}
	});

	let searchSessionId = search_id;
	let additionalAnalyticsToParse = additional_analytics;

	if (!search_id && !isCacheStale && searchSessionCache.current != null) {
		searchSessionId = searchSessionCache.current.searchSessionId;
	}
	if (!additional_analytics && !isCacheStale && searchSessionCache.current != null) {
		additionalAnalyticsToParse = searchSessionCache.current.additionalAnalyticsToParse;
	}

	return { searchSessionId, additionalAnalyticsToParse };
};
