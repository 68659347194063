import gql from 'graphql-tag';

import { SpaceOperationsFragment } from '@confluence/entity-operations/entry-points/EntityOperationsFragment.fragment';

import { DidContainUserContentFragment } from './DidContainUserContentFragment.fragment';

export const FooterCreateButtonsQuery = gql`
	query FooterCreateButtonsQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			key
			spaceTypeSettings {
				enabledContentTypes {
					isWhiteboardsEnabled
					isDatabasesEnabled
				}
			}
			...DidContainUserContentFragment
			...SpaceOperationsFragment
		}
	}
	${DidContainUserContentFragment}
	${SpaceOperationsFragment}
`;
