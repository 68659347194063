import { parse } from 'url';

import React from 'react';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { useReactProps_DO_NOT_USE } from '@atlassian/embedded-confluence/useReactProps';
import type { IframePassThroughProps } from '@atlassian/embedded-confluence/IframeElementType';
import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { AddAttributesWrapper } from './AddAttributesWrapper';
import { updateEmbeddedConfluenceSource } from './updateEmbeddedConfluenceSource';

export const EmbeddedConfluenceSource = ({
	channel,
	children,
}: {
	channel?: string;
	children: React.ReactNode;
}) => {
	let { embeddedConfluenceSource } = useReactProps_DO_NOT_USE<IframePassThroughProps>() || {};

	if (!embeddedConfluenceSource && isEmbeddedConfluence_DO_NOT_USE()) {
		embeddedConfluenceSource = parse(
			location.search,
			true,
		).query.embeddedConfluenceSource?.toString();
	}

	if (embeddedConfluenceSource) {
		const embeddedConfluenceSourceEventUpdater = (payload: AnalyticsEventPayload) =>
			updateEmbeddedConfluenceSource(payload, embeddedConfluenceSource!);

		return (
			<AddAttributesWrapper
				eventUpdater={embeddedConfluenceSourceEventUpdater}
				channel={channel || '*'}
			>
				{children}
			</AddAttributesWrapper>
		);
	}
	return <>{children}</>;
};
