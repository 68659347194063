import { useMessagePlacementHooks, usePostOfficeContext } from '@atlassian/post-office-context';
import { useMessageContext } from '@post-office/message-context';
import { usePlacementContext } from '@post-office/placement-context';
import { useRecommendationContext } from '@post-office/recommendation-context';
import { type SerializableRecord } from '@post-office/serializable-record';

import { MessageLifecycleClientError, MessageLifecycleContextError } from './error';
import { MessageEventClient } from './message-event-client';
import { InAppMessageEventType } from './types';

export type MessageEventHookScope = {
	markAllAsRead: (messages: SerializableRecord[]) => Promise<void>;
	messageRead: () => Promise<void>;
	messageUnread: () => Promise<void>;
	messageSnoozed: (snoozeDays: number) => Promise<void>;
	messageViewed: () => Promise<void>;
	messageClicked: () => Promise<void>;
	messageClickedSecondary: () => Promise<void>;
	messageAcknowledged: () => Promise<void>;
	messageDismissed: () => Promise<void>;
};

/**
 * Exposes functions for updating message lifecycle
 */
export const useMessageEvent = (): MessageEventHookScope => {
	if (!window.postOfficeMessageEventClient) {
		window.postOfficeMessageEventClient = new MessageEventClient();
	}
	const client: MessageEventClient = window.postOfficeMessageEventClient;

	const {
		dismissMessageFromPlacement,
		markReadFromPlacement,
		markClickedFromPlacement,
		markViewedFromPlacement,
		snoozeMessageFromPlacement,
	} = useMessagePlacementHooks();
	const { messageInstanceId, messageTemplateId, transactionAccountId, analyticsDetails } =
		useMessageContext();

	const { placementId } = usePlacementContext();

	const browserContext = usePostOfficeContext();
	const recommendationContext = useRecommendationContext();

	const context = {
		browser: browserContext,
		recommendation: recommendationContext,
	};

	return {
		/**
		 * Marks all messages as read. Calls markAllAsReadEvent() as the API call
		 */
		markAllAsRead: async (messages) => {
			const messagesEventPostPayload = messages.map((message) => {
				const { messageInstanceId, messageTemplateId } = message;

				if (!messageInstanceId || !messageTemplateId || !placementId) {
					throw new MessageLifecycleContextError();
				}
				return {
					messageInstanceId: message['messageInstanceId'] as string,
					messageTemplateId: message['messageTemplateId'] as string,
					type: InAppMessageEventType.Read,
					placement: placementId,
					createdAt: Date.now(),
					analyticsDetails,
					context,
				};
			});
			try {
				await client.markAllAsReadEvent(messagesEventPostPayload);
			} catch (error) {
				throw new MessageLifecycleClientError();
			}
		},
		/**
		 * Marks a message as read. Calls sendEvent() as the API call
		 * has to happen immediately.
		 */
		messageRead: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}

			markReadFromPlacement({ messageInstanceId });

			try {
				await client.sendEvent({
					messageInstanceId,
					messageTemplateId,
					type: InAppMessageEventType.Read,
					placement: placementId,
					createdAt: Date.now(),
					analyticsDetails,
					context,
				});
			} catch (error) {
				throw new MessageLifecycleClientError();
			}
		},
		/**
		 * Marks a message as unread. Calls sendEvent() as the API call
		 * has to happen immediately.
		 */
		messageUnread: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}
			try {
				await client.sendEvent({
					messageInstanceId,
					messageTemplateId,
					type: InAppMessageEventType.Unread,
					placement: placementId,
					createdAt: Date.now(),
					context,
					analyticsDetails,
				});
			} catch (error) {
				throw new MessageLifecycleClientError();
			}
		},
		/**
		 * Marks a message as viewed. This event is fired automatically and should not need to be fired manually in the message component.
		 */
		messageViewed: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}

			markViewedFromPlacement({ messageInstanceId });

			client.batchSendEvent({
				messageInstanceId,
				messageTemplateId,
				transactionAccountId,
				type: InAppMessageEventType.Viewed,
				placement: placementId,
				createdAt: Date.now(),
				analyticsDetails,
				context,
			});
		},
		/**
		 * Marks a message as clicked.
		 * This event is used as a positive reward to train the Post Office Defacto Bandit ML Model.
		 * Message Creators should use this event if you want to enable ML reranking for your placement.
		 */
		messageClicked: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}

			markClickedFromPlacement({ messageInstanceId });

			client.batchSendEvent({
				messageInstanceId,
				messageTemplateId,
				transactionAccountId,
				type: InAppMessageEventType.Clicked,
				placement: placementId,
				createdAt: Date.now(),
				analyticsDetails,
				context,
			});
		},
		/**
		 * Marks a message as clicked.
		 * This event is used as a positive reward to train the Post Office Defacto Bandit ML Model.
		 * Lower value than messageClicked (use for options like: "Learn More"), not to be used as a dismiss
		 * Message Creators should use this event if you want to enable ML reranking for your placement.
		 */
		messageClickedSecondary: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}
			client.batchSendEvent({
				messageInstanceId,
				messageTemplateId,
				transactionAccountId,
				type: InAppMessageEventType.ClickedSecondary,
				placement: placementId,
				createdAt: Date.now(),
				analyticsDetails,
				context,
			});
		},
		/**
		 * Marks a message as acknowledged
		 */
		messageAcknowledged: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}
			client.batchSendEvent({
				messageInstanceId,
				messageTemplateId,
				transactionAccountId,
				type: InAppMessageEventType.Acknowledged,
				placement: placementId,
				createdAt: Date.now(),
				analyticsDetails,
				context,
			});
		},
		/**
		 * Marks a message as dismissed.
		 * This event is used as a negative reward to train the Post Office Defacto Bandit ML Model.
		 * Message Creators should use this event if you want to enable ML reranking for your placement.
		 */
		messageDismissed: async () => {
			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}

			dismissMessageFromPlacement({ messageInstanceId });

			client.batchSendEvent({
				messageInstanceId,
				messageTemplateId,
				transactionAccountId,
				type: InAppMessageEventType.Dismissed,
				placement: placementId,
				createdAt: Date.now(),
				analyticsDetails,
				context,
			});
		},
		/**
		 * Marks a message as snoozed.
		 * This event is not used to train the Post Office Defacto Bandit ML Model.
		 */
		messageSnoozed: async (snoozeDays: number) => {
			const MILLIS_IN_A_DAY = 1000 * 60 * 60 * 24;

			if (!messageInstanceId || !messageTemplateId || !placementId) {
				throw new MessageLifecycleContextError();
			}

			snoozeMessageFromPlacement({ messageInstanceId });

			const statusExpiryTime = Date.now() + snoozeDays * MILLIS_IN_A_DAY;

			client.batchSendEvent({
				messageInstanceId,
				messageTemplateId,
				transactionAccountId,
				type: InAppMessageEventType.Snoozed,
				placement: placementId,
				createdAt: Date.now(),
				statusExpiryTime,
				analyticsDetails,
				context,
			});
		},
	};
};
