/**
 * Excluded props to simplify API:
 * - strategy
 * - hideTooltipOnClick
 * - hideTooltipOnMouseDown
 * - onHide
 * - onShow
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::f27354a013f7a6aabaae7d32fa4430a9>>
 * @codegenId #tooltip
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tooltip
 * @codegenParams { "props": { "children": true, "position": true, "mousePosition": true, "content": true, "truncate": true, "testId": true, "delay": true, "onShow": false, "onHide": false, "hideTooltipOnClick": false, "hideTooltipOnMouseDown": false, "strategy": false  }}
 * @codegenDependency ../../../../../../../design-system/tooltip/src/types.tsx <<SignedSource::b1cf997860a2df8ede33fcab512c80cf>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/internal/drag-manager.tsx <<SignedSource::97cf7628583168c02112ce79a8da1158>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/internal/tooltip-manager.ts <<SignedSource::73d46af527ca4da0412d601e9358d88c>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/internal/use-unique-id.ts <<SignedSource::0747aa3f6c36186f4795ba4e766c0a29>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/TooltipContainer.tsx <<SignedSource::425ad7177388295f649c0cd040ab62df>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/utilities.ts <<SignedSource::38d1ac8e8f116fcf46692966058b5330>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/Tooltip.tsx <<SignedSource::2463c7a6fe44531ef9daadee96bcffdc>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTooltip from '@atlaskit/tooltip';

type PlatformTooltipProps = React.ComponentProps<typeof PlatformTooltip>;

export type TooltipProps = Pick<
  PlatformTooltipProps,
  'children' | 'position' | 'mousePosition' | 'content' | 'truncate' | 'testId' | 'delay'
>;

export const Tooltip = (props: Parameters<RenderFn>[0]) => {
  const {
    position,
    mousePosition,
    content,
    truncate,
    testId,
    delay,
  } = props.forgeDoc.props as TooltipProps;
  return (
    <PlatformTooltip
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      position={position}
      mousePosition={mousePosition}
      content={content}
      truncate={truncate}
      testId={testId}
      delay={delay}
    />
  );
};
/**
 * @codegenEnd
 */
