import React from 'react';

import { FlagsProvider } from '@atlaskit/flag';

import type { RouteMatch } from '@confluence/route';
import { EmbedRouteLoader } from '@confluence/confluence-embeds';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import SpaceBase from '../../SpaceBase';
import { LazyDwellTime } from '../lazyComponents';

export const EmbedViewRoute = ({ params: { contentId, spaceKey } }: RouteMatch) => (
	<>
		<SpaceBase>
			<FlagsProvider>
				<EmbedRouteLoader contentId={contentId} spaceKey={spaceKey} />
			</FlagsProvider>
		</SpaceBase>
		<LazyDwellTime />
	</>
);

EmbedViewRoute.NAVIGATION_PARAMS = ({
	name: routeName,
	params: { contentId, spaceKey },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'smartLinkEmbedPageScreen',
			id: contentId,
			attributes: {
				contentId,
				contentType: 'embed',
			},
		},
		pageState: {
			contentId,
			spaceKey,
			contentType: 'embed',
			routeName,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		isViewPage: true,
	},
});
