import { useCallbackOne as useCallback } from 'use-memo-one';
import gql from 'graphql-tag';
import { useMutation, type MutationHookOptions } from '@apollo/react-hooks';
import { type ContextId, type ExtensionId } from '@atlassian/forge-ui-types';

import {
	type GQLInvokeExtensionEntrypoint,
	type GQLInvokeExtensionInput,
	type GQLInvokeExtensionPayload,
	type GQLInvokeExtensionResponse,
} from '../graphql/types';

export interface MutationVariables {
	input: GQLInvokeExtensionInput;
}

export interface MutationData {
	invokeExtension: GQLInvokeExtensionResponse;
}

export const invokeExtensionMutation = gql`
	mutation forge_ui_invokeExtension($input: InvokeExtensionInput!) {
		invokeExtension(input: $input) {
			success
			response {
				body
			}
			contextToken {
				jwt
				expiresAt
			}
			errors {
				message
				extensions {
					errorType
					statusCode
					... on InvokeExtensionPayloadErrorExtension {
						fields {
							authInfoUrl
						}
					}
				}
			}
		}
	}
`;

export const useInvokeExtension: Function = (
	contextIds: ContextId[],
	extensionId: ExtensionId,
	options?: MutationHookOptions<MutationData, MutationVariables>,
) => {
	const [mutationFunction] = useMutation<MutationData, MutationVariables>(
		invokeExtensionMutation,
		options,
	);
	return useCallback(
		(payload: GQLInvokeExtensionPayload, entryPoint: GQLInvokeExtensionEntrypoint = 'resolver') =>
			mutationFunction({
				variables: {
					input: {
						contextIds,
						extensionId,
						payload,
						entryPoint,
					},
				},
			}),
		[contextIds, extensionId, mutationFunction],
	);
};
