/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import { Fragment } from 'react';
import { InlineContext } from '../../../context/inline';

export const Text = (props: Parameters<RenderFn>[0]) => {
	const { forgeDoc, render } = props;

	return (
		<InlineContext.Provider value={{ inline: true }}>
			<p>
				{forgeDoc.children.map((child) => {
					// React expects a key on each child when rendering an array
					return <Fragment key={child.key}>{render(child)}</Fragment>;
				})}
			</p>
		</InlineContext.Provider>
	);
};
