import { PostOfficeContextProvider, useStableObject } from '@atlassian/post-office-context';

import { type ContextProviderProps } from './types';
import { DEFAULT_CONTEXT } from '../../constants';
import { type StableProvider } from '../../types';

export const StableContextProvider: StableProvider<ContextProviderProps> = ({
	context,
	children,
}) => {
	const stableContext = useStableObject({ ...DEFAULT_CONTEXT, ...context });

	return <PostOfficeContextProvider value={stableContext}>{children}</PostOfficeContextProvider>;
};
