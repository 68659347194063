import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';

import { Skeleton } from './Skeleton';

const SUB_STAT_HEIGHT = '24px';

export enum SubStatDirection {
	UP = 'up',
	DOWN = 'down',
	NONE = 'none',
}

export enum SubStatSentiment {
	GOOD = 'good',
	BAD = 'bad',
	NEUTRAL = 'neutral',
}

export type SubStatType = {
	text: string;
	arrowDirection: SubStatDirection;
	sentiment: SubStatSentiment;
};

const i18n = defineMessages({
	upArrowLabel: {
		id: 'admin-center.summary-card.icon-label.up-arrow',
		defaultMessage: 'Up arrow icon',
		description: 'Label for up arrow icon',
	},
	downArrowLabel: {
		id: 'admin-center.summary-card.icon-label.down-arrow',
		defaultMessage: 'Down arrow icon',
		description: 'Label for down arrow icon',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubStatContainer = styled.div({
	width: '100%',
	height: SUB_STAT_HEIGHT,
	color: token('color.text.subtlest', '#626F86'),
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubStatText = styled.div({
	margin: `0 ${token('space.100', '8px')} 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubStatArrowContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: SUB_STAT_HEIGHT,
	marginRight: token('space.025', '2px'),
});

const getIconColor = (sentiment: SubStatSentiment) => {
	switch (sentiment) {
		case SubStatSentiment.GOOD:
			return token('color.icon.accent.green', '#22A06B');
		case SubStatSentiment.BAD:
			return token('color.icon.accent.red', '#E34935');
		case SubStatSentiment.NEUTRAL:
		default:
			return token('color.text.subtlest', '#626F86');
	}
};

const SubStatArrow = ({ arrowDirection, sentiment }: Omit<SubStatType, 'text'>) => {
	const { formatMessage } = useIntl();
	const iconColor = getIconColor(sentiment);

	switch (arrowDirection) {
		case SubStatDirection.UP:
			return (
				<SubStatArrowContainer>
					<ArrowUpIcon
						primaryColor={iconColor}
						label={formatMessage(i18n.upArrowLabel)}
						size="small"
						testId="up-arrow"
					/>
				</SubStatArrowContainer>
			);
		case SubStatDirection.DOWN:
			return (
				<SubStatArrowContainer>
					<ArrowDownIcon
						primaryColor={iconColor}
						label={formatMessage(i18n.downArrowLabel)}
						size="small"
						testId="down-arrow"
					/>
				</SubStatArrowContainer>
			);
		case SubStatDirection.NONE:
		default:
			return null;
	}
};

type SubStatProps = {
	showSkeleton?: boolean;
	data: SubStatType | undefined;
};

export const SubStat = ({ showSkeleton = false, data }: SubStatProps) => {
	if (showSkeleton) {
		return <Skeleton width="150px" height="12px" />;
	} else if (!data) {
		// Preserves the card's height even if there is no sub stat to display
		return <SubStatContainer />;
	} else {
		return (
			<SubStatContainer>
				<SubStatArrow {...data} />
				<SubStatText>{data.text}</SubStatText>
			</SubStatContainer>
		);
	}
};
