import { ApolloLink } from 'apollo-link';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { getLogger } from '@confluence/logger';

const createGraphQLError = (
	operationName: string,
	message = `This is a test error from \"testFailuresLink\". To skip this query from triggering a test error, run this in console:\n  excludeOperationFromFailureGeneration(\"${operationName}\")`,
	status = 500,
) => {
	return new GraphQLError(message, undefined, undefined, undefined, undefined, undefined, {
		statusCode: status,
		http: { status },
	});
};

function devOnlyLinkImplementation() {
	type OperationControllerMode = 'include' | 'exclude' | 'off';

	class OperationController {
		private logger = getLogger('DevOnlyGraphqlErrorGenerator');
		private _mode: OperationControllerMode = 'off';
		private currentDebugReportingTimeoutId?: number;
		private requestedOperations = new Set<string>();

		constructor() {
			const configuredMode = sessionStorage.getItem('testFailuresLink.mode');

			if (configuredMode === 'include' || configuredMode === 'exclude') {
				this.mode = configuredMode;
			}
		}

		get mode(): OperationControllerMode {
			return this._mode;
		}

		set mode(value: OperationControllerMode) {
			this._mode = value;

			switch (value) {
				case 'include':
				case 'exclude': {
					sessionStorage.setItem('testFailuresLink.mode', value);
					break;
				}

				case 'off': {
					sessionStorage.removeItem('testFailuresLink.mode');
				}
			}
		}

		onOperationRequested(operationName: string): {
			error?: GraphQLError;
		} {
			if (this.mode === 'off') {
				return { error: undefined };
			}

			this.requestedOperations.add(operationName);
			this.scheduleDebugReporting();

			if (!this.shouldGenerateError(operationName)) {
				return { error: undefined };
			}

			switch (this.mode) {
				case 'include': {
					const operations = this.getOperations();
					return { error: operations.get(operationName) };
				}

				case 'exclude': {
					return { error: createGraphQLError(operationName) };
				}
			}
		}

		addOperation(operationName: string, message?: string, status?: number): void {
			const error = createGraphQLError(operationName, message, status);

			const operations = this.getOperations();
			operations.set(operationName, error);

			sessionStorage.setItem(this.operationsStorageKey, JSON.stringify(Array.from(operations)));
		}

		removeOperation(operationName: string): void {
			const operations = this.getOperations();
			operations.delete(operationName);
			sessionStorage.setItem(this.operationsStorageKey, JSON.stringify(Array.from(operations)));
		}

		getOperations(): Map<string, GraphQLError> {
			return new Map(JSON.parse(sessionStorage.getItem(this.operationsStorageKey) || '[]'));
		}

		reset(): void {
			const modes: OperationControllerMode[] = ['include', 'exclude', 'off'];
			modes.forEach((mode) => {
				this.mode = mode;
				sessionStorage.removeItem(this.operationsStorageKey);
			});
			sessionStorage.removeItem('testFailuresLink.mode');
			this._mode = 'off';
			this.requestedOperations.clear();
		}

		private scheduleDebugReporting(): void {
			if (this.mode === 'off') {
				return;
			}

			window.clearTimeout(this.currentDebugReportingTimeoutId);

			this.currentDebugReportingTimeoutId = window.setTimeout(() => {
				if (this.mode === 'include') {
					const configuredOperations = this.getOperations();

					const operationsThatWereFailedExplicitly = Array.from(this.requestedOperations).filter(
						(op) => configuredOperations.has(op),
					);

					const notYetRequestedOperations = Array.from(configuredOperations).filter(
						(op) => !this.requestedOperations.has(op[0]),
					);

					this.logger.log`OperationController mode: "include".`;
					this.logger.log`Operations which have been failed explicitly:`;
					this.logger.log`${operationsThatWereFailedExplicitly}`;
					this.logger
						.log`Operations which were configured to fail explicitly, but have not been requested yet:`;
					this.logger.log`${notYetRequestedOperations}`;
				} else if (this.mode === 'exclude') {
					const configuredOperations = this.getOperations();

					const operationsThatWereFailedExplicitly = Array.from(this.requestedOperations).filter(
						(op) => !configuredOperations.has(op),
					);

					const notYetRequestedOperations = Array.from(configuredOperations).filter(
						(op) => !this.requestedOperations.has(op[0]),
					);

					this.logger.log`OperationController mode: "exclude".`;
					this.logger.log`Operations which have been failed explicitly:`;
					this.logger.log`${operationsThatWereFailedExplicitly}`;
					this.logger
						.log`Operations which were excluded from failures, but have not been requested yet:`;
					this.logger.log`${notYetRequestedOperations}`;
				}
			}, 1000);
		}

		private shouldGenerateError(operationName: string): boolean {
			switch (this.mode) {
				case 'off': {
					return false;
				}

				case 'include': {
					return this.getOperations().has(operationName);
				}

				case 'exclude': {
					return !this.getOperations().has(operationName);
				}
			}
		}

		private get operationsStorageKey(): string {
			return `testFailuresLink.operations.${this.mode}`;
		}
	}

	const operationController = new OperationController();

	(window as any).graphqlOperationController = operationController;

	(window as any).enableArtificialGraphqlFailures = () => {
		operationController.mode = 'exclude';
	};

	(window as any).excludeOperationFromFailureGeneration = (operationName: string) => {
		operationController.mode = 'exclude';
		operationController.addOperation(operationName);
	};

	(window as any).includeOperationInFailureGeneration = (
		operationName: string,
		message?: string,
		status?: number,
	) => {
		operationController.mode = 'include';
		operationController.addOperation(operationName, message, status);
	};

	(window as any).unexcludeOperationFromFailureGeneration = (operationName: string) => {
		operationController.mode = 'exclude';
		operationController.removeOperation(operationName);
	};

	(window as any).unincludeOperationInFailureGeneration = (operationName: string) => {
		operationController.mode = 'include';
		operationController.removeOperation(operationName);
	};

	(window as any).disableArtificialGraphqlFailures = () => {
		operationController.reset();
	};

	(window as any).getArtificialGraphqlFailures = () => {
		return operationController.getOperations();
	};

	return new ApolloLink((operation, forward) => {
		return forward(operation).map((response) => {
			const { error } = operationController.onOperationRequested(operation.operationName);

			if (error) {
				delete response.data;
				response.errors = [error];
			}

			return response;
		});
	});
}

function noopLink() {
	return new ApolloLink((operation, forward) => forward(operation));
}

/**
 * The purpose of this link is to facilitate simulation of GraphQL errors.
 * In order to activate it, run this code in browser console:
 *
 *   ```js
 *   enableArtificialGraphqlFailures()
 *   ```
 *
 * This will activate the link for the duration of the browser session.
 *
 * Failure generator has two modes: "exclude" (default) and "include".
 *
 * ### "exclude" mode
 *
 * In this mode, an `error` is added to EVERY graphql operation.
 * If you want to let a certain operation proceed un-interrupted, you can exclude it from failure
 * generation by running this code:
 *
 *   ```js
 *   excludeOperationFromFailureGeneration("<OPERATION_NAME>")
 *   ```
 *
 * To undo an exclude:
 *
 *   ```js
 *   unexcludeOperationFromFailureGeneration("<OPERATION_NAME>")
 *   ```
 *
 * Calling this function will change failure generator mode to "exclude".
 *
 * ### "include" mode
 *
 * In this mode, an `error` is added to the list of GraphQL operations that you explicitly specify.
 * To ensure a failure is generated for an operation, run this code:
 *
 *   ```js
 *   includeOperationInFailureGeneration("<OPERATION_NAME>", "<MESSAGE>", <STATUS>)
 *   ```
 *
 * To undo an include:
 *
 *   ```js
 *   unincludeOperationInFailureGeneration("<OPERATION_NAME>")
 *   ```
 *
 * Calling this function will change failure generator mode to "include".
 *
 * ### "reset"
 *
 * To completely turn off failure generation and delete all includes and excludes from session storage,
 * run this code:
 *
 *   ```js
 *   disableArtificialGraphqlFailures()
 *   ```
 *
 * ### "list"
 *
 * To list all included and excluded errors:
 *
 *   ```js
 *   getArtificialGraphqlFailures()
 *   ```
 */
export const graphqlSyntheticErrorGenerator = () => {
	return (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing') ||
		process.env.CLOUD_ENV === 'branch'
		? devOnlyLinkImplementation()
		: noopLink();
};
