import { useEffect, useMemo } from 'react';
import { ENABLE_CHOREOGRAPHER_GATE } from '@atlassiansox/engagekit-ts';

import { choreographerFactory, ProductIds } from '@atlassian/ipm-choreographer';

import { useBooleanFeatureFlag } from '@confluence/session-data';

import { useAnalyticsEnvironment } from './useAnalyticsEnvironment';

export const useChoreographerServices = () => {
	const environment = useAnalyticsEnvironment();
	const enableChoreographer = useBooleanFeatureFlag(ENABLE_CHOREOGRAPHER_GATE.confluence);
	const choreographerServices = useMemo(() => {
		if (environment) {
			return choreographerFactory(
				ProductIds.CONFLUENCE,
				environment,
				{},
				{
					enableChoreographer,
				},
			);
		}
	}, [enableChoreographer, environment]);
	const { plugin } = choreographerServices ?? {};

	useEffect(() => {
		/**
		 * Cleanup function in case we unmount this component to remount another one.
		 */
		return () => {
			if (plugin) {
				plugin.destroy();
			}
		};
	}, [plugin]);

	return choreographerServices;
};
