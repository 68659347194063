import type { LineDefinition } from '../../base-components/ChartBase/ChartBase-types';
import type { SubStatType } from '../../base-components/SummaryBase/sub-components/SubStat';
import { sumPossiblyUndefinedNumbers, findNodeDataPointByDate } from '../../../admin-center-utils';
import type { TimeseriesDataPoint } from '../../../admin-center-utils';

export type TimeseriesData = TimeseriesDataPoint[];

export type UseMetricDataReturnType = {
	// Processed lines, intended to be passed to ChartSummaryBaseCard or ChartBaseCard.
	lines: LineDefinition[];
	// Main stat count to be used in ChartSummaryBaseCard or ChartSummaryCard main stat text.
	mainStatCount: number | undefined;
	// Sub stat config to be used in ChartSummaryBaseCard or ChartSummaryCard.
	subStat: SubStatType | undefined;
};

// Helper function that returns the sum of all data points in the provided TimeseriesData[] corresponding to the provided date.
// If any of these data points are undefined, or no data point for the provided date is found in any of the timeseries, return undefined.
export const sumTimeseriesDataPointsByDate = (lineData: TimeseriesData[], date: Date) =>
	sumPossiblyUndefinedNumbers(lineData.map((data) => findNodeDataPointByDate(data, date)?.count));
