/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { lazy, Fragment } from 'react';
import {
	type FieldChildrenProps,
	type RadioGroupProps,
	type Rendered,
	type RadioOption,
	type RadioProps,
} from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';

const styles = css({
	lineHeight: '20px',
});
const Field = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);
const AkRadioGroup = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.radio' */
			'@atlaskit/radio/RadioGroup'
		),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

interface AKRadioGroupProps {
	options: RadioOption[];
	defaultValue?: string;
}

const RadioGroup: React.FunctionComponent<
	Omit<Rendered<RadioGroupProps>, 'children'> & AKRadioGroupProps
> = ({ options, defaultValue, isRequired, label, description, name }) => {
	return (
		<Field defaultValue={defaultValue} isRequired={isRequired} label={label} name={name}>
			{({ fieldProps }: FieldChildrenProps) => {
				const {
					id,
					isRequired,
					isDisabled,
					isInvalid,
					onChange,
					value,
					'aria-labelledby': ariaLabelledBy,
				} = fieldProps;
				return (
					<Fragment>
						<FormStateChangeNotifier name={name} value={fieldProps.value} />
						{description && <AKHelperMessage>{description}</AKHelperMessage>}
						<div css={styles}>
							<AkRadioGroup
								test-id={id}
								isRequired={isRequired}
								isDisabled={isDisabled}
								isInvalid={isInvalid}
								onChange={onChange}
								value={value}
								aria-labelledby={ariaLabelledBy}
								options={options}
							/>
						</div>
					</Fragment>
				);
			}}
		</Field>
	);
};

const RadioGroupFn: React.FunctionComponent<Props> = ({ props, children }) => {
	const { label, name, isRequired, description } = props as RadioGroupProps;

	let defaultValue;
	const options: RadioOption[] = children
		.map((child) => {
			const { props, type } = child;
			if (type === 'Radio') {
				const { label, value, defaultChecked } = props as RadioProps;
				if (defaultChecked) {
					defaultValue = value;
				}

				return {
					name,
					label,
					value,
				};
			}
		})
		.filter((option): option is RadioOption => typeof option !== 'undefined');

	return (
		<RadioGroup
			label={label}
			name={name}
			options={options}
			defaultValue={defaultValue}
			description={description}
			isRequired={isRequired}
		/>
	);
};

export { RadioGroup, RadioGroupFn };
