export interface SsrBanner {
	name: string;
	height: number;
}

export class SSRBannerManager {
	readonly banners: SsrBanner[] = [];

	registerBanner({ name, height }: SsrBanner) {
		const bannerIndex = this.banners.findIndex((_banner) => name === _banner.name);

		if (bannerIndex < 0) {
			this.banners.push({ name, height });
		}
	}

	getBanners() {
		return this.banners;
	}
}
