// inspired by https://github.com/kentcdodds/use-deep-compare-effect/blob/master/src/index.js
/* eslint react-hooks/exhaustive-deps: 0 */
//Todo: this is flawed because callback is not included in the dependency array generated by useDeepCompareMemoize
// if the callback function (all use cases I found were inline functions and therfore not passed as a ref, were passes to the dep array they would trigger a re-evaluation every render defeating the purpose
// in order to properly work and handle the callback function being changed, the callback argument must be a useCallback or other function passed by ref and have it be included in the memoized depenedency array
import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export function useDeepCompareEffect(callback, dependencies) {
	useEffect(callback, useDeepCompareMemoize(dependencies));
}

//eslint-ignore react-hooks/exhaustive-deps
function useDeepCompareMemoize(value) {
	const ref = useRef();

	if (!isEqual(value, ref.current)) {
		ref.current = value;
	}
	return ref.current;
}
