import { booleanAttrs } from './booleanAttrs';
import { possibleStandardNames } from './possibleStandardNames';

type PropValue = string | number | boolean | Record<string, string> | undefined;

export function inlineStyleToObject(style: string) {
	return style.split(';').reduce(
		(styleObj, entity) => {
			const keyValuePair = entity.split(':', 2);
			const value = keyValuePair[1] || '';

			// -ms-style-property -> msStyleProperty
			// -moz-style-property -> MozStyleProperty
			// -webkit-style-property -> WebkitStyleProperty
			// style-property -> styleProperty
			let name = keyValuePair[0]
				.replace(/^-ms-/, 'ms-')
				.replace(/^-moz-/, 'Moz-')
				.replace(/^-webkit-/, 'Webkit-');
			if (!name.startsWith('--')) {
				name = name.replace(/-(.)/g, (_, ch) => ch.toUpperCase());
			}
			name = name.trim();

			if (name) {
				styleObj[name] = value.trim();
			}
			return styleObj;
		},
		{} as Record<string, string>,
	);
}

export function attrsToReactProps(attrs: NamedNodeMap) {
	const props: Record<string, PropValue> = {};
	for (let i = 0; i < attrs.length; i++) {
		const attr = attrs[i];
		const name = attr.name.toLowerCase();
		if (name === 'style') {
			props['style'] = inlineStyleToObject(attr.value);
		} else if (booleanAttrs.has(name)) {
			props[name] = true;
		} else {
			props[possibleStandardNames[name] || name] = attr.value;
		}
	}
	return props;
}
