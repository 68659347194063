import type { CustomField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export const calendar: FieldTransformer<any> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name !== 'id') {
				return field;
			}

			return {
				...field,
				type: 'custom',
				isMultiple: true,
				options: {
					resolver: {
						type: 'calendar',
					},
				},
			} as CustomField;
		});
	},
	// Custom field receives an array
	transformBefore(params) {
		if (params.id && typeof params.id === 'string') {
			params.id = params.id.split(',');
		}
		return params;
	},
	// Custom field returns an array, so converting it back to string to store
	transformAfter(params) {
		if (Array.isArray(params.id)) {
			params.id = params.id.join(',');
		}
		return params;
	},
};
