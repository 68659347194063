import type { ADNode } from '@atlaskit/editor-common/validator';

const replacer = (_: any, value: any) =>
	value !== null && typeof value === 'object' && !(value instanceof Array)
		? Object.keys(value)
				.sort()
				.reduce((sorted: any, key) => {
					sorted[key] = value[key];
					return sorted;
				}, {})
		: value;

/**
 * Serializes the ADF node to string using *stable* serialization, where order of attributes is
 * guaranteed (being sorted alphabetically).
 *
 * This is important to do, as stringified ADF is used as GraphQL query parameter and therefore
 * used to generate Apollo cache keys. Stable serialization is needed to produce the same string
 * for the same object or deeply equal objects.
 *
 * @param adf Extension node ADF
 * @returns JSON-serialized ADF node
 */
export const serializeExtensionADF = (adf: ADNode | ADNode[]) => JSON.stringify(adf, replacer);
