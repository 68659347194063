import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	contentQualityMayVary: {
		id: 'fabric.editor.ai.ai-panels.contentQualityMayVary',
		defaultMessage:
			'Content is based on your permissions, and its quality may vary. <link>Learn why</link>',
		description: 'Content quality may vary message',
	},
	contentQualityMayVaryLabel: {
		id: 'fabric.editor.ai.ai-panels.contentQualityMayVaryLabel',
		defaultMessage: 'Content is based on your permissions, and its quality may vary.',
		description: 'Content quality may vary label',
	},
});
