/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContentStateRestrictionLevel {
  NONE = "NONE",
  PAGE_OWNER = "PAGE_OWNER",
}

export enum GraphQLDateFormat {
  GLOBAL = "GLOBAL",
  MILLIS = "MILLIS",
  USER = "USER",
  USER_FRIENDLY = "USER_FRIENDLY",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RendererContentState
// ====================================================

export interface RendererContentState_singleContent_contentState {
  id: string | null;
  name: string;
  color: string;
  restrictionLevel: ContentStateRestrictionLevel;
}

export interface RendererContentState_singleContent_contentStateLastUpdated {
  value: string;
}

export interface RendererContentState_singleContent {
  id: string | null;
  contentState: RendererContentState_singleContent_contentState | null;
  contentStateLastUpdated: RendererContentState_singleContent_contentStateLastUpdated | null;
}

export interface RendererContentState {
  singleContent: RendererContentState_singleContent | null;
}

export interface RendererContentStateVariables {
  contentId: string;
  isDraft: boolean;
  format?: GraphQLDateFormat | null;
}
