import { type PreferenceCategory, type PrefetchedPreferenceState } from '../../types';

export type DeserializedConsents = ConsentRecordSaveRequest[];
export type ConsentToken = string;

export type ConsentRecord = {
	key: ConsentHubKey;
	granted: boolean;
	updatedTs: string; // Timestamp when consents updated. Format: "2023-08-03T14:46:25.822635Z"
};

export type ConsentRecordSaveRequest = {
	key: ConsentHubKey;
	granted: boolean;
	displayedText: string; // Localized/formatted text from the individual consent (banner text)
	gatheredTs?: string; // Timestamp when consents saved. Format: "2023-08-03T14:46:25.822635Z"
};

// If consents are present and populated, the user is authenticated and has recorded preferences.
// If consents are present but empty, the user is authenticated and has no preferences in the system.
// If consents are not present in the DB, the user is unauthenticated and has no preferences in the system.
// currentLocale will always be present if available ("UNKNOWN" if not), regardless of auth status
// localeWhenConsented will never be relevant for unauthenticated users.
// Typical locale format: <countryCode>-<subdivisionCode> e.g. 'FR-ARA' for France, or 'US-VA' for Virginia
// There are ~30m users who do not have the modern version of their locale stored in ConsentHub (inclusive of the subdivision)
// which means we need to account for instances where we ONLY have the country code of US.

export type ConsentHubResponse = {
	// Locale pulled from GeoIP sidecar when the consents were actually saved
	// Note: This is currently when _any_ consent is saved for the user
	localeWhenConsented?: string; // <countryCode>-<subdivisionCode>, or <countryCode> in some instances
	consents?: ConsentRecord[] | [];
	currentLocale?: string; // Locale pulled from GeoIP sidecar when the consents are requested, <countryCode>-<subdivisionCode>, or <countryCode> in some instances
};

// SSR teams can look at the cached cookie result and pre-load that rather than fetching from CH
export type PrefetchedConsentCookieData = {
	currentLocale?: string; // Not required anymore, but want to be backwards compatible
	consentToken: string;
};

export type EmptyObject = Record<string, never>;

// SSR teams can look at the cached cookie result and pre-load that rather than fetching from CH
export type PrefetchedConsentPreferences =
	| (ConsentHubResponse | PrefetchedConsentCookieData)
	| PrefetchedPreferenceState
	| EmptyObject;

export type ConsentHubRequestBody = {
	formUrl: string; // Required by Privacy to audit consents, can technically contain PII
	locale: 'UNKNOWN'; // ConsentHub will determine locale based on GeoIP sidecar
	site: 'atlassian';
	source: 'COOKIE_PREFERENCES';
	// If there are no consents, it's a first time fill, otherwise it's an update
	action: 'FORM_FILL' | 'UPDATE_PREFERENCES';
	consents: DeserializedConsents;
};

export enum ConsentHubKey {
	StrictlyNecessary = 'strictlyNecessaryCookies',
	Functional = 'functionalCookies',
	Analytics = 'performanceCookies',
	Marketing = 'targetingCookies',
	Unknown = 'unknownCookies',
}

export type ConsentDisplayedTextMap = {
	[key in PreferenceCategory]: string;
};

export type ConsentPreferenceData = {
	consentToken: string;
	consentTimestamp?: string;
	consentHubInaccessible?: boolean;
};
