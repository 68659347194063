import { type GasV3Payload } from './analytics-helpers/types';
import { type Options } from '@atlassiansox/cross-flow-api-internals';

export const enrichWithRequestOptions = (
	event: GasV3Payload,
	requestOptions?: Options,
): GasV3Payload => {
	if (!requestOptions) {
		return event;
	}

	const attributes = {
		// Spread the extra analytics attributes first, because we don't want consumers to override any event attributes below
		...(requestOptions.extraAnalyticsAttributes || {}),
		...(event.attributes || {}),
		sourceComponent: requestOptions.sourceComponent,
		sourceContext: requestOptions.sourceContext,
	};
	return { ...event, attributes };
};
