/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b75c5a5b9ecd93164fc8b9995b47157e>>
 * @codegenId #stack
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen stack
 * @codegenParams { "props": { "children": true, "alignBlock": true, "alignInline": true, "grow": true, "space": true, "spread": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/stack.tsx <<SignedSource::3c4893bb8d9df0a6e481c71cd6138a57>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::2f4848af544e0f64968f7c28012cf0f0>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::00aab072ec1c97fd176380fcd46ccf7e>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::a91bf7842593d9052c045e4a40d7ab3b>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/flex.tsx <<SignedSource::7148f07a56c498fe6d7f2eee9837f198>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Stack as PlatformStack } from '@atlaskit/primitives';

type PlatformStackProps = React.ComponentProps<typeof PlatformStack>;

export type StackProps = Pick<
  PlatformStackProps,
  'children' | 'alignBlock' | 'alignInline' | 'grow' | 'space' | 'spread' | 'testId'
>;

export const Stack = (props: Parameters<RenderFn>[0]) => {
  const {
    alignBlock,
    alignInline,
    grow,
    space,
    spread,
    testId,
  } = props.forgeDoc.props as StackProps;
  return (
    <PlatformStack
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      alignBlock={alignBlock}
      alignInline={alignInline}
      grow={grow}
      space={space}
      spread={spread}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
