import type { VFC } from 'react';
import { memo, useContext, useEffect } from 'react';

import { RoutesContext } from '@confluence/route-manager';
import { getTotalHydratedElements } from '@confluence/loadable/entry-points/getTotalHydratedElements';

import { getTimeToAppIdle } from './plugins/lighthouse-metrics/timeToAppIdle';
import { PerformanceObserverEntryTypes } from './plugins/lighthouse-metrics/const';
import { EntriesBuffer } from './plugins/lighthouse-metrics/utils/buffer';
import { TIME_TO_APP_IDLE_METRIC } from './metrics/time-to-idle/perf.config';
import { getTBT } from './plugins/lighthouse-metrics/tbt';

const IDLE_THRESHOLD = 3 * 1000;
const RETRY_INTERVAL = IDLE_THRESHOLD * 2;
const TIMEOUT_INTERVAL = 5 * 60 * 1000; // 5 minutes
const PERFORMANCE_OBSERVER_LATENCY_ADJUSTMENT = 1000;

const performance = window.performance;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const wainNextIdle = () => {
	const onIdleCallback = window.requestIdleCallback || window.requestAnimationFrame;

	const cancelCallback = (id: number) => {
		if (onIdleCallback === window.requestIdleCallback) {
			window.cancelIdleCallback(id);
		} else {
			window.cancelAnimationFrame(id);
		}
	};

	return new Promise<void>((resolve) => {
		const callbackId = onIdleCallback(() => {
			cancelCallback(callbackId);
			resolve();
		});
	});
};

// This is a temporary solution to measure time to app idle.
// It supposed to be replaced with TTAI measurement when UFO is adopted.
export const TimeToAppIdle: VFC<{}> = memo(({}) => {
	const routes = useContext(RoutesContext);
	const routeName = routes.match?.name || '';
	const contentId = routes.match?.params?.contentId || '';
	const transitionId = routes.transitionId || 0;

	useEffect(() => {
		const htmlTime = performance?.getEntriesByName?.('CFP-63.html').pop()?.startTime || 0;
		const isInitialLoad = transitionId === 0;
		const metric = TIME_TO_APP_IDLE_METRIC(`${transitionId}-${routeName}-${contentId}`);

		// we have two different 'startTime' variables in this context:
		// 'metricStartTime' - This is used for reporting to the metric and for calculating duration.
		// 'startTime' - This is used as a boundary for idle time calculation. There may be instances where the HTML is ready,
		// but the component is not yet rendered. In such cases, we use the component's first render time as the start boundary.
		const startTime = performance?.now();
		const metricStartTime = isInitialLoad ? htmlTime : startTime;

		let abortedTime = 0;

		metric.start({ startTime: metricStartTime });

		async function measureTimeToAppIdle() {
			while (true) {
				await delay(RETRY_INTERVAL);

				// wait for current long task to complete
				await wainNextIdle();

				const endTime = performance?.now();

				// wait to allow performance observer to collect recent entries
				// note that `endTime` somewhat behind after this line, but it's ok
				await delay(PERFORMANCE_OBSERVER_LATENCY_ADJUSTMENT);

				const idleTime = getTimeToAppIdle(
					startTime,
					endTime,
					IDLE_THRESHOLD,
					EntriesBuffer[PerformanceObserverEntryTypes.LongTask],
				);

				const aborted = abortedTime && (!idleTime || abortedTime < idleTime) ? 'transition' : false;
				const timedOut = endTime - metricStartTime > TIMEOUT_INTERVAL;
				if (idleTime || timedOut || aborted) {
					const stopTime = idleTime || endTime;
					const duration = stopTime - metricStartTime;
					const tbt = getTBT(
						startTime,
						stopTime,
						EntriesBuffer[PerformanceObserverEntryTypes.LongTask],
					);
					const customData = {
						duration,
						startTime: metricStartTime,
						stopTime,
						aborted,
						transitionId,
						routeName,
						contentId,
						timedOut,
						idleThreshold: IDLE_THRESHOLD,
						tbtObserved: Math.round(tbt.observed),
						tbtTotal: Math.round(tbt.total),
						totalElementsToHydrate: getTotalHydratedElements(),
					};

					metric.stop({
						stopTime,
						customData,
					});

					return;
				}
			}
		}

		void measureTimeToAppIdle();

		return () => {
			abortedTime = performance?.now();
		};
	}, [transitionId, routeName, contentId]);

	return null;
});
