import { useEffect } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

export type Props = {
	name: string;
};
const PageLoadMarkComponent = ({ name }: Props) => {
	const pageLoadMetric = browserMetrics.getPageLoadMetric();
	useEffect(() => {
		pageLoadMetric.mark(name);
	}, [pageLoadMetric, name]);

	return null;
};

export const PageLoadMark = withTransparentErrorBoundary({
	attribution: Attribution.BACKBONE,
})(PageLoadMarkComponent);
