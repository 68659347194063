import React from 'react';

import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import { ExtensionKeys } from '../../../../actions/types';
import Icon from '../../../../prebuilt/config-items-empty/generate-content/Icon';
import { AIActionItemsExtension } from '../../ai-action-items-panel/AIActionItemsExtension';
import { AIPanelRenderer } from '../../ai-panel-renderer/AIPanelRenderer';
import type { AIManifest, AIPanelParameters } from '../../types';
import {
	getFeedbackToolbarItem,
	getQualityToolbarItem,
	getRegenerateToolbarItem,
} from '../../utils';

import { messages } from './messages';

const buildActionItemsPanelManifest = ({
	api,
	intl,
	providerFactory,
}: AIManifest): ExtensionManifest<AIPanelParameters> => {
	return {
		title: intl.formatMessage(messages.actionItemsPageTitle),
		// TODO: rename to 'com.atlassian.ai-panels'
		// Requires a change in the confluence backend
		type: 'com.atlassian.ai-blocks',
		key: 'ai-action-items-block',
		description: intl.formatMessage(messages.actionItemsPageDescription),
		icons: {
			'48': async () => () => <Icon alt={intl.formatMessage(messages.actionItemsPageTitle)} />,
		},
		modules: {
			quickInsert: [
				{
					key: 'quick-insert',
					action: {
						type: 'node',
						key: 'aiActionItemsExtension',
						parameters: {
							content: '',
							macroMetadata: {
								title: intl.formatMessage(messages.actionItemsPageTitle),
							},
						},
					},
				},
			],
			nodes: {
				aiActionItemsBodiedExtension: {
					type: 'bodiedExtension',
					render:
						async () =>
						({ node }) => {
							return <AIPanelRenderer node={node} providerFactory={providerFactory} />;
						},
				},
				aiActionItemsExtension: {
					type: 'extension',
					render:
						async () =>
						({ node }) => {
							return <AIActionItemsExtension node={node} api={api} intl={intl} />;
						},
				},
			},
			contextualToolbars: [
				{
					context: {
						type: 'extension',
						nodeType: 'extension',
						extensionKey: 'ai-action-items-block:aiActionItemsExtension',
						// TODO: rename to 'com.atlassian.ai-panels'
						// Requires a change in the confluence backend
						extensionType: 'com.atlassian.ai-blocks',
					},
					toolbarItems: [
						getQualityToolbarItem(intl),
						getRegenerateToolbarItem(intl, api, ExtensionKeys.AIActionItemsExtension, messages),
						getFeedbackToolbarItem(intl, api, messages),
					],
				},
				{
					context: {
						type: 'extension',
						nodeType: 'bodiedExtension',
						extensionKey: 'ai-action-items-block:aiActionItemsBodiedExtension',
						// TODO: rename to 'com.atlassian.ai-panels'
						// Requires a change in the confluence backend
						extensionType: 'com.atlassian.ai-blocks',
					},
					toolbarItems: [
						getQualityToolbarItem(intl),
						getRegenerateToolbarItem(intl, api, ExtensionKeys.AIActionItemsExtension, messages),
						getFeedbackToolbarItem(intl, api, messages),
					],
				},
			],
		},
	};
};

export default buildActionItemsPanelManifest;
