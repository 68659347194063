import type { CustomField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export const pagetree: FieldTransformer<{ root: string | string[] }> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name !== 'root') {
				return field;
			}

			return {
				...field,
				isMultiple: false,
				type: 'custom',
				options: {
					resolver: {
						type: 'rootPage',
					},
				},
			} as CustomField;
		});
	},
};
