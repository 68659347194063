/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { UFOv1, UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';
import {
	InvalidMessageTemplateIdError,
	MessageRendererEmptyValidationResponseError,
} from '@post-office/errors';
import { MessageContextProvider } from '@post-office/message-context';
import { useMessageEvent } from '@post-office/message-lifecycle';
import { RecommendationProvider } from '@post-office/recommendation-context';
import {
	type ComponentProps,
	type FunctionComponent,
	type LazyExoticComponent,
	type ReactNode,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
} from 'react';

import { ChoreographerWrapper } from './choreographer-wrapper';
import { MessageAnalyticsContextProvider } from './message-analytics-context';
import { TrackMount } from './track-mount';
import { type ErrorBoundaryProps, withErrorBoundary } from './withErrorBoundary';
import { type MessageCategory } from '../types';

type RenderableMessageProps<MessageTemplateId extends string = string> = {
	messageTemplateId: MessageTemplateId;
	messageInstanceId: string;
	messageCategory: MessageCategory;
	transactionAccountId?: string;
	recommendationSession?: {
		sessionId: string;
		entityid: string;
	};
	product?: string;
	loadingFallback?: React.ReactNode;
	postOffice?: {
		analyticsDetails?: Record<string, string | number>;
	};
};

type PlacementPropsShape = Record<string, unknown> | undefined;

type RenderableMessageComponent<T extends Record<string, unknown> = Record<string, unknown>> =
	| LazyExoticComponent<React.FC<T>>
	| React.FC<T>;

export type MessageProps<MessageComponents extends Record<string, RenderableMessageComponent>> = {
	[K in keyof MessageComponents]: ComponentProps<MessageComponents[K]> &
		RenderableMessageProps<Extract<K, string>>;
}[keyof MessageComponents];

export const MessageViewed: FunctionComponent<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const { messageViewed } = useMessageEvent();

	// messageViewed returns a promise of void, which is incompatible with the TrackMount onMount prop
	const messageViewedVoidFunction = useCallback(() => {
		void messageViewed();
	}, [messageViewed]);

	return <TrackMount onMount={messageViewedVoidFunction}>{children}</TrackMount>;
};

export type MessageRendererType = (
	props: MessageProps<Record<string, RenderableMessageComponent>> &
		Record<string, unknown> &
		ErrorBoundaryProps,
) => ReactNode;

export type InitializeMessageRenderer = <
	PlacementProps extends Record<string, unknown> | undefined,
>() => (
	props: MessageProps<Record<string, RenderableMessageComponent>> &
		PlacementProps &
		ErrorBoundaryProps,
) => ReactNode;

// Any is here as we never care about the value, and it stops the types getting more complex
export const asMessageRenderer = <
	MessageComponents extends Record<string, RenderableMessageComponent>,
>(
	messageComponents: MessageComponents,
	messageValidator?: (props: unknown) => MessageProps<MessageComponents>,
): {
	initializeMessageRenderer: InitializeMessageRenderer;
	MessageRenderer: MessageRendererType;
} => {
	const initializeMessageRenderer = <PlacementProps extends PlacementPropsShape>() => {
		// Return renderer component
		return ({
			messageTemplateId,
			messageInstanceId,
			recommendationSession,
			fallbackRender,
			loadingFallback,
			onError,
			messageCategory,
			postOffice,
			...data
		}: MessageProps<MessageComponents> & PlacementProps & ErrorBoundaryProps) => {
			const isUFOEnabled = FeatureGates.checkGate('enable_ufo_tracking_in_post_office');

			const { markMessageFailed, markMessageUnmounted, messageSuccessComponent } = useMemo(() => {
				const messagePerformanceTracker = isUFOEnabled
					? new UFOv1.MessagePerformanceTracker(messageTemplateId)
					: undefined;

				const onMessageSuccess = () => {
					void messagePerformanceTracker?.markSuccess();
				};

				const markMessageFailed =
					(onError?: (error: Error, info: React.ErrorInfo) => void) =>
					(error: Error, info: React.ErrorInfo) => {
						void messagePerformanceTracker?.markFailure(error);
						onError && onError(error, info);
					};

				return {
					/** To be called when the message is unmounted  */
					markMessageUnmounted: messagePerformanceTracker?.abort,
					/** To be called when the message fails to render */
					markMessageFailed: markMessageFailed,
					/** To be rendered when the message is successfully loaded */
					messageSuccessComponent: <TrackMount onMount={onMessageSuccess} />,
				};
			}, [messageTemplateId]);

			useEffect(() => {
				return () => {
					void markMessageUnmounted?.();
				};
			}, [markMessageUnmounted]);

			const Core = () => {
				const Component = messageComponents?.[messageTemplateId];

				// This should not happen but things can happen at runtime;
				if (!Component) {
					// Throw to error boundary
					throw new InvalidMessageTemplateIdError(
						JSON.stringify({ messageTemplateId, messageInstanceId }),
					);
				}

				let validatedMessage;
				if (messageValidator) {
					// The messageValidator should also throw an error if the validation failed,
					// which will again be thrown here and should be caught by the ErrorBoundary
					validatedMessage = messageValidator(data);
					if (!validatedMessage) {
						throw new MessageRendererEmptyValidationResponseError();
					}
				}

				const loadingComponent = useMemo(
					() =>
						isUFOEnabled ? (
							<UFOv2.UFOLoadHold name={UFOv2.HoldNames.MESSAGE}>
								{loadingFallback ?? null}
							</UFOv2.UFOLoadHold>
						) : (
							<>{loadingFallback ?? null}</>
						),
					[loadingFallback, isUFOEnabled],
				);

				// This any should be factored out as part of validation work
				const componentProps: any = messageValidator ? validatedMessage : data;

				// messageTemplateId exists in the API but component is empty (should not happen)
				if (!Component) {
					return null;
				}

				return (
					<ChoreographerWrapper
						messageId={messageInstanceId}
						messageTemplateId={messageTemplateId}
						messageCategory={messageCategory}
					>
						<FeatureFlaggedUfoWrapper
							messageTemplateId={messageTemplateId}
							isEnabled={isUFOEnabled}
						>
							<MessageContextProvider
								value={{
									messageInstanceId,
									messageTemplateId,
									transactionAccountId: componentProps.transactionAccountId,
									analyticsDetails: postOffice?.analyticsDetails,
								}}
							>
								<RecommendationProvider value={recommendationSession ?? {}}>
									<Suspense fallback={loadingComponent}>
										<MessageAnalyticsContextProvider>
											{messageSuccessComponent}
											<Component {...componentProps} />
										</MessageAnalyticsContextProvider>
									</Suspense>
								</RecommendationProvider>
							</MessageContextProvider>
						</FeatureFlaggedUfoWrapper>
					</ChoreographerWrapper>
				);
			};

			return withErrorBoundary(Core)({
				errorBoundaryLocation: 'placement-message-renderer-component',
				fallbackRender,
				onError: markMessageFailed(onError),
			});
		};
	};

	return {
		initializeMessageRenderer,
		MessageRenderer: initializeMessageRenderer(),
	};
};

interface FeatureFlaggedUfoWrapperProps {
	isEnabled: boolean;
	messageTemplateId: string; // Adjust the type according to your actual context structure
	children: ReactNode;
}

const FeatureFlaggedUfoWrapper: FunctionComponent<FeatureFlaggedUfoWrapperProps> = ({
	isEnabled,
	messageTemplateId,
	children,
}) => {
	if (isEnabled) {
		return (
			<UFOv2.UFOSegment name={UFOv2.SegmentNames.MESSAGE}>
				<UFOv2.UFOSegment name={`${UFOv2.SegmentNames.MESSAGE}.${messageTemplateId}`}>
					{children}
				</UFOv2.UFOSegment>
			</UFOv2.UFOSegment>
		);
	}
	return <>{children}</>;
};
