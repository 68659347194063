const SCRIPT_REGEX = /<script([^>]*)\/*>/gi;
const STYLE_REGEX = /<link([^>]*)\/*>/gi;
const QUOTES_REGEX = /^(["']*)(?<value>.*?)\1$/;
const SPACE_REGEX = /\s+/;

export const SSR_ASYNC_ATTR = 'data-ssr-async-css';

export function parseScriptTags(
	tags: string | string[],
	linksMode = false,
	matcher = SCRIPT_REGEX,
): { [key: string]: string }[] {
	// Parse <script key="value">/script> into { key: "value" }
	if (linksMode) {
		return Array.isArray(tags) ? tags.map((t) => ({ src: t })) : [];
	}

	if (typeof tags !== 'string') return [];

	const result: { [key: string]: string }[] = [];
	for (const [, attributes] of tags.matchAll(matcher)) {
		result.push(
			Object.fromEntries(
				attributes
					.split(SPACE_REGEX)
					.filter((pair) => pair)
					.map((pair) => {
						const pos = pair.indexOf('=');
						if (pos !== -1) {
							const key = pair.substring(0, pos);
							const rawValue = pair.substring(pos + 1);
							const match = rawValue.match(QUOTES_REGEX);
							const value = match?.groups?.value ?? rawValue;
							return [key, value];
						} else {
							return [pair, pair];
						}
					}),
			),
		);
	}
	return result;
}

function parseStyleTags(tags: string): { [key: string]: string }[] {
	return parseScriptTags(tags, false, STYLE_REGEX);
}

export function objToAttrs(attributes: { [key: string]: string }): string {
	// Convert { key: value } to key="value"
	const result = Object.entries(attributes)
		.map(([key, value]) => (value && key !== value ? `${key}="${value}"` : key))
		.join(' ');
	return result ? ` ${result}` : '';
}

export function removeJquery(tags: string): string {
	// Jquery is always loaded in packages/confluence-frontend-server/templates/html/dashboard-spa-container.js
	// So we need to remove it from the superbatch tags
	return parseScriptTags(tags)
		.filter((attrs) => attrs['data-wrm-key'] !== 'com.atlassian.plugins.jquery:jquery')
		.map((attrs) => `<script${objToAttrs(attrs)}></script>`)
		.join('\n');
}

export function deferScript(tags: string): string {
	return parseScriptTags(tags)
		.map((attributes: { [key: string]: string }) => {
			attributes['defer'] = 'defer';
			return `<script${objToAttrs(attributes)}></script>`;
		})
		.join('\n');
}

const srcExtractor = /src=["'](.*?)["']/gi;
export function convertScriptTagsToPreloadTags(jsTags: string): string {
	if (!jsTags) return '';
	return [...jsTags.matchAll(srcExtractor)]
		.map((match) => match[1])
		.filter((src) => !!src)
		.map((src) => `<link rel="preload" href="${src}" as="script">`)
		.join('\n');
}

export function addSSRInlineStyleAttr(tags: string): string {
	return parseStyleTags(tags)
		.map((attributes: { [key: string]: string }) => {
			if (!attributes['media'] || attributes['media'] === 'all') {
				return `<link${objToAttrs(attributes)} data-ssr-inline-post-process>`;
			}
			return `<link${objToAttrs(attributes)}>`;
		})
		.join('\n');
}
