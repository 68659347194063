import { LoadableAfterPaint } from '@confluence/loadable';
import { performanceMarkInProd } from '@confluence-classic/confluence-performance';

const CONTENT_RENDERER_DOWNLOAD = `confluence.fabric.content.renderer.download`;

export default LoadableAfterPaint({
	name: 'ClassicContentRendererLoader',

	loader: async () => {
		performanceMarkInProd(`${CONTENT_RENDERER_DOWNLOAD}.start`);
		const module = await import(
			/* webpackChunkName: "loadable-ExternalShareContentRenderer" */ './ExternalShareContentRenderer'
		);
		performanceMarkInProd(`${CONTENT_RENDERER_DOWNLOAD}.end`);
		return module;
	},
});
