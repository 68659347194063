/**
 * Returns a promise that can be resolved by calling the resolve function.
 * The promise will be resolved automatically after timeout
 */
export function defer(timeout: number, timeoutCallback?: () => void) {
	let resolve: () => void;
	let timerId: ReturnType<typeof setTimeout>;
	const promise = new Promise<void>((_resolve) => {
		timerId = setTimeout(() => {
			_resolve();
			if (timeoutCallback) {
				timeoutCallback();
			}
		}, timeout);
		resolve = _resolve;
	});
	promise.finally(() => {
		clearTimeout(timerId);
	});
	return {
		promise,
		// @ts-ignore
		resolve,
	};
}
