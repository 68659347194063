export default class Queue<Item> {
	queue: Item[];
	maxSize: number;

	constructor(maxSize: number) {
		this.queue = [];
		this.maxSize = maxSize;
	}

	enqueue(item: Item): boolean {
		if (this.queue.length === this.maxSize) {
			return false;
		}

		this.queue.push(item);

		return true;
	}

	flush(callback: (item: Item) => void) {
		this.queue.forEach(callback);
		this.queue = [];
	}

	getSize(): number {
		return this.queue.length;
	}
}
