import type { Operation, FetchResult } from 'apollo-link';
import { ApolloLink, Observable, execute } from 'apollo-link';

export function ExperimentalLink() {
	return new ApolloLink((operation: Operation): Observable<FetchResult> | null => {
		return new Observable((observer) => {
			void import(/* webpackChunkName: "loadable-schemaLink" */ './schemaLink').then(
				({ getSchemaLink }) => {
					execute(getSchemaLink(), operation).subscribe({
						next: observer.next.bind(observer),
						error: observer.error.bind(observer),
						complete: observer.complete.bind(observer),
					});
				},
			);
		});
	});
}
