import { fg } from '@atlaskit/platform-feature-flags';

import { getAnalyticsClient, isSSR, Logger } from '../../../common/utils';
import { getBscGlobalState, initializeGlobalState } from '../../../common/utils/bsc-global-state';
import { WebStorageType } from '../../../services/web-storage-service';

import { createDocumentCookiePassthrough } from './create-document-cookie-passthrough';
import { createWebStoragePassthrough } from './create-web-storage-passthrough';

export interface InitializeControlsArgs {
	analyticsEnabled?: boolean;
	product?: string;
	flagEnabled?: boolean;
	webStorageEnabled?: boolean;
}

export const initializeControls = async (args: InitializeControlsArgs = {}) => {
	if (fg('platform_moonjelly_bsc_master_killswitch')) {
		// Exit if the master killswitch is enabled
		return;
	}

	try {
		initializeGlobalState(args);

		// Exit silently if in SSR context
		if (isSSR()) {
			return;
		}

		// Ensures that the AWC is constructed before overwriting any native methods.
		const analyticsWebClientInstance = getAnalyticsClient();

		// If AWC is not initialized, it will be undefined
		const isAnalyticsInitialized: boolean = !!analyticsWebClientInstance;

		if (!isAnalyticsInitialized) {
			throw new Error(
				`Failed to initialize AnalyticsWebClient. Analytics logging will not be set for native method overrides.`,
			);
		}

		await createDocumentCookiePassthrough();

		const isWebStorageControlsEnabled = getBscGlobalState().webStorageEnabled;
		if (!isWebStorageControlsEnabled) {
			return;
		}

		await createWebStoragePassthrough(WebStorageType.LocalStorage);
		await createWebStoragePassthrough(WebStorageType.SessionStorage);
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'initializeControlsError',
			message: `Failed to initialize browser storage controls. ${e.message || ''}`,
		});
	}
};

export { createDocumentCookiePassthrough, createWebStoragePassthrough };
