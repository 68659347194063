/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useMemo } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { Border } from '../../config-items-empty/border';

import { styleBorder, styleDiv, styleIconBackground } from './styles';
import type { IconShownAt } from './types';

interface Props {
	children: JSX.Element;
	shownAt: IconShownAt;
}
export const RainbowBorder = (props: Props) => {
	const { children, shownAt } = props;
	const id = useMemo(() => `aiIcon_radial_${new Date().getTime()}`, []);

	if (shownAt === 'suggestions') {
		return children;
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div css={[styleDiv, styleIconBackground]}>
			<svg
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={styleBorder}
				width="40"
				height="40"
				viewBox="0 0 40 40"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="0.5" y="1.5" width="37" height="37.5" rx="1.5" stroke={`url(#${id})`} />
				<defs>
					<radialGradient
						id={id}
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(1 1.5) rotate(44.5937) scale(49.8523)"
					>
						<Border />
					</radialGradient>
				</defs>
			</svg>

			{children}
		</div>
	);
};
