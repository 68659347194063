import { query } from '@confluence/query-preloader-tools';

import { PersistenceQuery } from './SpaceViewsPersistenceQueries.graphql';
import { SpaceRootQuery } from './SpaceViewsQueries.graphql';

export function preloadSpaceViews(spaceKey: string, isLicensed: boolean) {
	const queries = [
		query({
			query: SpaceRootQuery,
			variables: {
				spaceKey,
			},
		}),
	];
	if (isLicensed) {
		queries.push(
			query({
				query: PersistenceQuery,
				variables: {
					spaceKey,
				},
			}),
		);
	}
	return Promise.all(queries);
}
