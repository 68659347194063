import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';
import type Token from 'markdown-it/lib/token';

import type { IDMap } from '../../../convert-prosemirror-to-markdown/pm-markdown/serializer';
import { createToken } from '../utils/create-token';

// Some nodes will be inline but it's content will be empty.
// That's why, don't remove paragraphs wrapping it.
// For example, inlineExtension is inline node, so there should be no content inside it.
//  So it's custom element is represented by <custom data-type="inlineExtension" ... />
const emptyInlineCustomNodes = ['inlineExtension'];

export default function (
	md: MarkdownIt,
	idMap: IDMap,
	htmlConversionCounts: {
		htmlConvertedToNodes: number;
		htmlConvertedToFallbackNodes: number;
	},
) {
	md.core.ruler.after('inline', 'custom-block', function (state: StateCore) {
		state.tokens = state.tokens.reduce((acc: Token[], token: Token, index: number) => {
			const match = token.content.match(
				/**
				 * Matches the following:
				 * <custom data-type="smartlink" data-id="123" />
				 * <custom data-type="smartlink" />
				 */
				/<custom\s+data-type="(?<dataType>.*?)"\s*(data-id="(?<dataId>.*?)"\s*)?\/>/,
			);
			const dataType = match?.groups?.dataType;
			if (match && dataType && !emptyInlineCustomNodes.includes(dataType)) {
				const dataId = match.groups?.dataId;

				if (dataType) {
					htmlConversionCounts.htmlConvertedToNodes++;
					const customElementToken = createToken(state, dataType);
					if (dataId && idMap[dataId]) {
						// @ts-ignore
						customElementToken.pmAttrs = idMap[dataId].attributes;
					}
					/**
					 * When the custom tag goes through the tokenizer it is transformed into this structure:
					 * paragraph_open
					 * inline
					 * paragraph_close
					 *
					 * so the following section removes the wrapping paragraph tokens
					 */
					acc.splice(acc.length - 1, 1);
					state.tokens.splice(index + 1, 1);

					// We then push replace the inline token with our custom token
					acc.push(customElementToken);
				} else {
					htmlConversionCounts.htmlConvertedToFallbackNodes++;
					acc.push(token);
				}
			} else {
				acc.push(token);
			}
			return acc;
		}, []);
	});
}
