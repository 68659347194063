import { query } from '@confluence/query-preloader-tools';

import type { GetCurrentStorageUsageQuery as GetCurrentStorageUsageQueryType } from './__types__/GetCurrentStorageUsageQuery';
import { GetCurrentStorageUsageQuery } from './GetCurrentStorageUsage.graphql';

export const STORAGE_ENFORCEMENT_BANNER_QUERY = 'GetCurrentStorageUsageQuery';

export const preloadStorageEnforcementBanner = () =>
	query<GetCurrentStorageUsageQueryType, {}>({
		query: GetCurrentStorageUsageQuery,
	});
