import React from 'react';
import { type ImageProps } from '@atlassian/forge-ui-types';
import { type Props, EgressErrorMessage } from '../..';
import { useIsValidImageUrl } from '../../utils';

export const maxWidths = {
	xsmall: '25%',
	small: '37.5%',
	medium: '50%',
	large: '75%',
	xlarge: '100%',
} as const;

export default function Image({ src, alt, size = 'xlarge' }: ImageProps) {
	const maxWidth = maxWidths[size] || '100%';

	return (
		<img
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'block',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				margin: 'auto',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				maxWidth,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				textAlign: 'center',
			}}
			src={src}
			alt={alt}
		/>
	);
}

export const ImageFn: React.FunctionComponent<Props> = ({ props }) => {
	const { src, alt, size } = props as ImageProps;
	const isValidUrl = useIsValidImageUrl(src);

	if (isValidUrl) {
		return <Image src={src} alt={alt} size={size} />;
	}

	return <EgressErrorMessage url={src} />;
};
