/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { lazy, useState } from 'react';
import type { CheckboxFieldProps } from '@atlaskit/form';
import {
	type CheckboxGroupProps,
	type Rendered,
	type CheckboxOption,
	type CheckboxProps,
} from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';

const styles = css({
	lineHeight: '20px',
});
const AKCheckbox = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.checkbox' */
		'@atlaskit/checkbox'
	).then((module) => ({ default: module.Checkbox })),
);

const AKCheckboxField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.CheckboxField,
	})),
);
const Fieldset = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Fieldset })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

interface AKCheckboxGroupProps {
	options: CheckboxOption[];
}

const CheckboxGroup: React.FunctionComponent<
	Omit<Rendered<CheckboxGroupProps>, 'children'> & AKCheckboxGroupProps
> = ({ options, label, name, description }) => {
	const defaultValues: string[] = [];
	options.forEach((option: CheckboxOption) => {
		if (option.defaultChecked) {
			defaultValues.push(option.value);
		}
	});
	const [checkboxFieldValues, setCheckboxFieldValues] =
		useState<Array<string | undefined>>(defaultValues);
	return (
		<Fieldset legend={label}>
			{description && <AKHelperMessage>{description}</AKHelperMessage>}
			{options.map((option: CheckboxOption) => {
				const { key, label, value, defaultChecked, isRequired } = option;
				return (
					<AKCheckboxField key={key} value={value} defaultIsChecked={defaultChecked} name={name}>
						{({ fieldProps }: { fieldProps: CheckboxFieldProps }) => {
							const {
								id,
								isDisabled,
								isInvalid,
								onChange,
								onBlur,
								onFocus,
								value,
								name,
								'aria-invalid': ariaInvalid,
								'aria-labelledby': ariaLabelledBy,
								isChecked,
							} = fieldProps;

							return (
								<div css={styles}>
									<AKCheckbox
										id={id}
										isChecked={isChecked}
										isDisabled={isDisabled}
										isInvalid={isInvalid}
										isRequired={isRequired}
										onBlur={onBlur}
										onFocus={onFocus}
										value={value}
										name={name}
										label={label}
										onChange={(e) => {
											if (e.target.checked) {
												setCheckboxFieldValues((prevState) => [...prevState, value]);
											} else {
												setCheckboxFieldValues(checkboxFieldValues.filter((e) => e !== value));
											}
											return onChange(e);
										}}
										aria-invalid={ariaInvalid}
										aria-labelledby={ariaLabelledBy}
									/>
								</div>
							);
						}}
					</AKCheckboxField>
				);
			})}
			<FormStateChangeNotifier name={name} value={checkboxFieldValues} />
		</Fieldset>
	);
};

// NOTE: use `label` instead
interface DeprecatedProps {
	legend: string;
	name: string;
}

const CheckboxGroupFn: React.FunctionComponent<Props> = ({ props: propsAny, children }) => {
	const { name, description } = propsAny as CheckboxGroupProps;
	const props = propsAny as CheckboxGroupProps | DeprecatedProps;

	// NOTE: legend is deprecated
	const label = 'legend' in props ? props.legend : props.label;
	const options = children
		.filter(({ type }) => type === 'Checkbox')
		.map((child) => {
			const { key, props } = child;
			const { label, value, defaultChecked, isRequired } = props as CheckboxProps;

			return {
				key,
				label,
				value,
				defaultChecked,
				isRequired,
			} as CheckboxOption;
		});

	return <CheckboxGroup label={label} name={name} options={options} description={description} />;
};

export { CheckboxGroup, CheckboxGroupFn };
