import { PostOfficeAnalyticsContext } from '@atlaskit/analytics-namespaced-context';

import { type StableProvider } from '../../types';

const postOfficeAnalyticsContextData = {
	attributes: {
		build: {
			c: process.env['BITBUCKET_COMMIT'],
			b: process.env['BITBUCKET_BUILD_NUMBER'],
			t: process.env['BUILD_TIME'],
		},
	},
};

export const StableAnaltyicsProvider: StableProvider = ({ children }) => {
	return (
		<PostOfficeAnalyticsContext data={postOfficeAnalyticsContextData}>
			{children}
		</PostOfficeAnalyticsContext>
	);
};
