import window from 'window-or-global';

import { CONTEXT_PATH } from '@confluence/named-routes';

export function redirectToLoginPage() {
	window.location = `${CONTEXT_PATH}/login.action?os_destination=${window.location.pathname.replace(
		CONTEXT_PATH,
		'',
	)}`;
}
