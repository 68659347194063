import { useContext } from 'react';
import { FormContext } from '../form';

export const useIsInForm = () => {
	const formContext = useContext(FormContext);
	if (formContext === null) {
		return false;
	}
	return true;
};
