/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';
import { type NativeTagProps, type TagColor, type RenderFn } from '@atlassian/forge-ui-types';
import type { TagColor as AKTagColor } from '@atlaskit/tag';
import { getChildrenText } from '../utils/getChildrenText';

const AKTag = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.tag' */
		'@atlaskit/tag'
	).then((module) => ({
		default: module.SimpleTag,
	})),
);

type AKTagColorSansUndefined = Exclude<AKTagColor, undefined>;

const colorDict: Record<TagColor, AKTagColorSansUndefined> = {
	default: 'standard',
	green: 'green',
	blue: 'blue',
	red: 'red',
	purple: 'purple',
	grey: 'grey',
	teal: 'teal',
	yellow: 'yellow',
	'green-light': 'greenLight',
	'blue-light': 'blueLight',
	'red-light': 'redLight',
	'purple-light': 'purpleLight',
	'grey-light': 'greyLight',
	'teal-light': 'tealLight',
	'yellow-light': 'yellowLight',
};

const mapColorToAK = (color: TagColor): AKTagColor => {
	return colorDict[color];
};

export const Tag = ({ forgeDoc: { props, children } }: Parameters<RenderFn>[0]) => {
	const { color = 'default' } = props as NativeTagProps;
	const akColor = mapColorToAK(color);

	return <AKTag color={akColor} text={getChildrenText(children)} />;
};
