import gql from 'graphql-tag';
import { type GQLInvokeAuxEffectsInput, type GQLInvokeAuxEffectsResponse } from '../graphql/types';

export const invokeAuxEffectsMutation = gql`
	mutation forge_ui_invokeAuxEffects($input: InvokeAuxEffectsInput!) {
		invokeAuxEffects(input: $input) {
			success
			errors {
				message
				extensions {
					errorType
					statusCode
				}
			}
			result {
				effects
				contextToken {
					jwt
					expiresAt
				}
				metrics {
					appTimeMs
				}
			}
		}
	}
`;

export class APIError extends Error {
	constructor(message: string) {
		super(message);
		// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, APIError.prototype);
	}
}

export interface MutationVariables {
	input: GQLInvokeAuxEffectsInput;
}

export interface MutationData {
	invokeAuxEffects: GQLInvokeAuxEffectsResponse;
}
