import {
	ADMIN_HOME_ID,
	PRODUCT_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import {
	MARKETPLACE_APP_REQUESTS,
	MARKETPLACE_FIND_APPS,
	MARKETPLACE_MANAGE_PROMOTIONS,
} from '@confluence/named-routes';

export const MarketplaceRoute = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-Marketplace" */ '@confluence/marketplace/entry-points/Marketplace'
			)
		).Marketplace,
});

(MarketplaceRoute as any).NAVIGATION_PARAMS = ({ name }) => {
	let eventName: string = '';
	let navView: string = PRODUCT_HOME_ID;
	if (name === MARKETPLACE_FIND_APPS.name) {
		eventName = 'marketplaceFindApps';
		navView = PRODUCT_HOME_ID;
	} else if (name === MARKETPLACE_APP_REQUESTS.name) {
		eventName = 'marketplaceAppRequests';
		navView = ADMIN_HOME_ID;
	} else if (MARKETPLACE_MANAGE_PROMOTIONS.name) {
		eventName = 'marketplacePromotions';
		navView = ADMIN_HOME_ID;
	}
	return {
		Screen: {
			screenEvent: {
				name: eventName,
				id: eventName,
			},
			pageState: {
				routeName: name,
			},
		},
		MainLayout: {
			navView,
		},
	};
};
