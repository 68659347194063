import type { VFC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { N20A, N30 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexComment = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentContainer = styled.div({
	display: 'flex',
	flexBasis: '100%',
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div({
	backgroundColor: token('color.skeleton', N20A),
	width: '32px',
	height: '32px',
	borderRadius: '50%',
	marginTop: token('space.050', '4px'),
	marginRight: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BeforeStylesContainer = styled.div({
	content: ' ',
	display: 'block',
	marginBottom: token('space.200', '16px'),
	paddingTop: token('space.150', '12px'),
	borderBottom: `1px solid ${token('color.border', N30)}`,
	width: '100%',
});

const commentWrapperStyles = xcss({
	marginTop: 'space.250',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentAddPromptContainer = styled.div({
	width: '100%',
	border: 'none',
	borderRadius: '3px',
	height: '40px',
	color: token('color.text.subtlest', '#999'),
	paddingTop: token('space.0', '0px'),
	display: 'inline-block',
	verticalAlign: 'top',
	marginLeft: token('space.100', '8px'),
	flexGrow: 1,
	backgroundColor: token('color.skeleton', N20A),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContainer = styled.div({
	display: 'grid',
	gridTemplateColumns: 'auto auto auto auto',
	gridGap: '10px',
	backgroundColor: 'transparent',
	width: '500px',
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Author = styled.div({
	gridArea: '1 / 1 / span 1 / span 4',
	backgroundColor: token('color.skeleton', N20A),
	textAlign: 'center',
	padding: `${token('space.150', '12px')} 0`,
	border: 'none',
	borderRadius: '3px',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerComment = styled.div({
	gridArea: '2 / 1 / span 1 / span 10',
	backgroundColor: token('color.skeleton', N20A),
	textAlign: 'center',
	padding: `${token('space.075', '6px')} 0`,
	border: 'none',
	borderRadius: '3px',
});

type CommentPlaceholderProps = {
	showPlaceholder?: boolean;
};

export const CommentPlaceholder: VFC<CommentPlaceholderProps> = ({ showPlaceholder = false }) => {
	if (process.env.REACT_SSR || window.__SSR_RENDERED__ || showPlaceholder) {
		return (
			<Box xcss={commentWrapperStyles}>
				<CommentContainer>
					<Circle />
					<GridContainer>
						<Author />
						<InnerComment />
					</GridContainer>
				</CommentContainer>
				<BeforeStylesContainer />
				<FlexComment data-testid="comment-container">
					<CommentContainer>
						<Circle />
						<CommentAddPromptContainer />
					</CommentContainer>
				</FlexComment>
			</Box>
		);
	} else {
		return null;
	}
};
