import { LoadablePaint } from '@confluence/loadable';

export const PageContentRendererFabric = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageContentRendererFabric" */ './PageContentRendererFabric'
			)
		).PageContentRendererFabric,
	name: 'PageContentRendererFabric',
});

export const PageContentRendererTiny = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageContentRendererTiny" */ './PageContentRendererTiny'
			)
		).PageContentRendererTiny,
	name: 'PageContentRendererTiny',
});
