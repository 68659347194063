import React, { useContext } from 'react';

import { Attribution } from './Attribution';

export type AttributionContextValue = Attribution | string;

export const AttributionContext = React.createContext<AttributionContextValue>('unknown');

AttributionContext.displayName = 'AttributionContext';

export function useAttribution(attribution: AttributionContextValue) {
	const inheritedAttribution = useContext(AttributionContext);

	return attribution === Attribution.INHERIT ? inheritedAttribution : attribution;
}
