import gql from 'graphql-tag';

export const CoverPictureQuery = gql`
	query CoverPictureQuery($contentId: ID, $status: [String], $draftShareId: String) {
		content(id: $contentId, draftShareId: $draftShareId, status: $status) {
			nodes {
				id
				properties(keys: ["cover-picture-id-draft", "cover-picture-id-published"]) {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
			}
		}
	}
`;
