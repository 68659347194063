import { combineReducers } from 'redux';

import type { EditorContentV2Type } from './editorContentV2';
import editorContentV2 from './editorContentV2';
import { mediaPreviewer } from './mediaPreviewer';
import type { DraftStateType } from './draft';
import { default as draft } from './draft';

export type ViewState = {
	editorContentV2: EditorContentV2Type;
	mediaPreviewer: any;
	draft: DraftStateType;
};

export default combineReducers({
	editorContentV2,
	mediaPreviewer,
	draft,
});
