import { type FormData } from '@atlassian/forge-ui-types';

export interface UserPickerOption {
	id: string;
	name: string;
	avatarUrl: string;
	type: 'user';
}

interface OptionData {
	value: string;
}
type FormValue = Array<string> | UserPickerOption | OptionData | string;
type FormValueTransformer = (value: FormValue) => FormValue;

const isOptionData = (value: FormValue): value is OptionData =>
	typeof value === 'object' && value.hasOwnProperty('value');

const isUserData = (value: FormValue): value is UserPickerOption =>
	typeof value === 'object' &&
	value.hasOwnProperty('id') &&
	value.hasOwnProperty('name') &&
	value.hasOwnProperty('avatarUrl');

export const transformFormValue: FormValueTransformer = (value) => {
	if (isOptionData(value)) {
		return value.value;
	} else if (isUserData(value) && value.type === 'user') {
		return value.id;
	}
	return value;
};

export const transformFormData = (formData: FormData) =>
	Object.entries(formData).reduce((transformedData, [key, value]) => {
		return {
			...transformedData,
			[key]: Array.isArray(value) ? value.map(transformFormValue) : transformFormValue(value),
		};
	}, {});
