import type { ThemeState } from '@atlaskit/tokens';
import {
	getGlobalTheme,
	getThemeHtmlAttrs,
	getThemeStyles,
	ThemeMutationObserver,
} from '@atlaskit/tokens';

interface IframeThemeInfo {
	themeHtmlAttrs: Record<string, string>;
	styleElements: HTMLStyleElement[];
}

interface IframeThemeListener {
	onIframeClose: () => void;
}

const buildStyleElements = async (
	activeTheme: Partial<ThemeState>,
): Promise<HTMLStyleElement[]> => {
	return (await getThemeStyles(activeTheme)).map((theme) => {
		const styleElement: HTMLStyleElement = document.createElement('style');
		styleElement.innerHTML = theme.css;
		Object.entries(theme.attrs).forEach(([key, value]) => styleElement.setAttribute(key, value));
		return styleElement;
	});
};

// Can be invoked from iframes that have access to the parent window and allows for theme information to be passed into the iframe
const initializeIframeTheming = async (
	onThemeUpdated: (iframeThemeInfo: IframeThemeInfo) => void,
): Promise<IframeThemeInfo & IframeThemeListener> => {
	const activeTheme = getGlobalTheme();
	const observer = new ThemeMutationObserver(async (newTheme: Partial<ThemeState>) => {
		onThemeUpdated({
			themeHtmlAttrs: getThemeHtmlAttrs(newTheme),
			styleElements: await buildStyleElements(newTheme),
		});
	});

	observer.observe();

	const onIframeClose = () => observer.disconnect();

	return {
		themeHtmlAttrs: getThemeHtmlAttrs(activeTheme),
		styleElements: await buildStyleElements(activeTheme),
		onIframeClose,
	};
};

// Invoked from inside iframes to setup theming
export const setupThemeInsideIframe = (
	onThemeUpdated: (iframeThemeInfo: IframeThemeInfo) => void,
) => {
	if (window.parent.initializeIframeTheming) {
		void window.parent
			.initializeIframeTheming(onThemeUpdated)
			.then((iframeThemeInitInfo: IframeThemeInfo & IframeThemeListener) => {
				onThemeUpdated(iframeThemeInitInfo);
				// Remove listener to prevent memory leaks
				window.addEventListener('beforeunload', iframeThemeInitInfo.onIframeClose);
			});
	}
};

export const applyThemeInsideIframe = (iframeThemeInfo: IframeThemeInfo, doc: Document) => {
	// Add HTMl attributes which trigger styles to take effect
	Object.entries(iframeThemeInfo.themeHtmlAttrs).forEach(([key, value]) =>
		doc.documentElement.setAttribute(key, value),
	);

	// Add theming stylesheet(s) into page
	iframeThemeInfo.styleElements.forEach((styleElement: HTMLStyleElement) => {
		const existingStyleElement = doc.head.querySelector(
			`style[data-theme="${styleElement.getAttribute('data-theme')}"]`,
		);
		if (!existingStyleElement) {
			// Prevent re-inserting the same style element into the DOM repeatedly
			doc.head.appendChild(styleElement);
		}
	});
};

export const addIframeThemingSupport = () => {
	window.initializeIframeTheming = initializeIframeTheming;
};
