import gql from 'graphql-tag';

export const ExternalShareMediaDownloadTokenQuery = gql`
	query ExternalShareMediaDownloadTokenQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			body {
				atlas_doc_format {
					mediaToken {
						token
					}
				}
			}
		}
		mediaConfiguration {
			clientId
			fileStoreUrl
		}
	}
`;
