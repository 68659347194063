import { createSelector } from 'reselect';

export const selectorIsAnonymous = createSelector(
	(state) => state.meta,
	(meta) => {
		if (!meta) {
			return true;
		}
		return !meta.get('atlassian-account-id');
	},
);

export const getAllFeatures = (state) =>
	state.meta ? state.meta.get('enabled-dark-features', '').split(',') : [];
const getInputDarkFeature = (state, input) => input;

export const isDarkFeatureEnabledSelector = createSelector(
	getAllFeatures,
	getInputDarkFeature,

	(enabledDarkFeatures, input) =>
		enabledDarkFeatures.indexOf(input) > -1 &&
		enabledDarkFeatures.indexOf(`${input}.disable`) === -1,
);

export const isContentTypeOf = createSelector(
	(type) => type,
	(type, state) => state.meta.get('content-type', ''),
	(type, contentType) => contentType.toLowerCase() === type.toLowerCase(),
);
