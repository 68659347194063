/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::da22133c43d70ab0e872e32ea8f4fbfa>>
 * @codegenId #checkbox
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen checkbox
 * @codegenParams { "hasFieldProps": true, "props": { "testId": true, "defaultChecked": true, "isChecked": true, "isIndeterminate": true, "label": true, "size": false } }
 * @codegenDependency ../../../../../../../design-system/checkbox/src/types.tsx <<SignedSource::d1f2f3bdabc2e9a501690d5b8a7bc303>>
 * @codegenDependency ../../../../../../../design-system/checkbox/src/internal/index.tsx <<SignedSource::d49ae69a466602eb4cb562382f5be9ba>>
 * @codegenDependency ../../../../../../../design-system/checkbox/src/checkbox.tsx <<SignedSource::d13fac48792a9cd6068429c758048359>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformCheckbox from '@atlaskit/checkbox';
import { adaptEventHandler } from "../../../utils";
import type { EventHandlerProps } from "../../types";

type PlatformCheckboxProps = React.ComponentProps<typeof PlatformCheckbox>;

export type CheckboxProps = Pick<
  PlatformCheckboxProps,
  'testId' | 'defaultChecked' | 'isChecked' | 'isIndeterminate' | 'label'
 | 'id' | 'isRequired' | 'isDisabled' | 'isInvalid' | 'value' | 'aria-invalid' | 'aria-labelledby' | 'name'
> & Pick<EventHandlerProps, 'onChange' | 'onBlur' | 'onFocus'>;

export const Checkbox = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
    defaultChecked,
    isChecked,
    isIndeterminate,
    label,
    // Field props
    id,
    isRequired,
    isDisabled,
    isInvalid,
    onChange,
    onBlur,
    onFocus,
    value,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as CheckboxProps;
  return (
    <PlatformCheckbox
      testId={testId}
      defaultChecked={defaultChecked}
      isChecked={isChecked}
      isIndeterminate={isIndeterminate}
      label={label}
      // Field props
      id={id}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onChange={adaptEventHandler(onChange)}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      value={value}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */