import React from 'react';
import type { ReactNode } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N40 } from '@atlaskit/theme/colors';

export enum CardContainerMinHeight {
	CHART = '360px',
	CHART_SUMMARY = '360px',
	LEADERBOARD = '360px',
	SITE_ENABLED_STATE = '288px',
	SPACE_ENABLED_STATE = '254px',
	LOADING_AND_ERROR = '136px',
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	height?: string;
	minHeight?: CardContainerMinHeight;
	paddingLeft?: string;
}>({
	backgroundColor: token('elevation.surface.raised', N0),
	border: `1px solid ${token('color.border', N40)}`,
	borderRadius: '8px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ paddingLeft }) => paddingLeft ?? token('space.300', '24px'),
	paddingRight: token('space.300', '24px'),
	paddingTop: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
	// Defining boxSizing: "border-box" here makes it so the padding is counted as part of the
	// width of the element, rather than in addition to it. This is important when the card wraps
	// a chart because ParentSize (from @visx/responsive) gets confused otherwise and renders a chart
	// that gets bigger and bigger over time.
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => height ?? 'auto',
	// set min-height for card loading and error states (per designs)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ minHeight }) => minHeight ?? CardContainerMinHeight.LOADING_AND_ERROR,
});

type CardContainerProps = {
	children?: ReactNode;
	minHeight?: CardContainerMinHeight;
	height?: string;
	paddingLeft?: string;
};

export const CardContainer = ({ children, minHeight, height, paddingLeft }: CardContainerProps) => (
	<Container
		data-testid="card-container"
		height={height}
		minHeight={minHeight}
		paddingLeft={paddingLeft}
	>
		{children}
	</Container>
);
