import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';

import FeatureFlagClient, {
	type FlagValue,
	type CustomAttributes,
} from '@atlaskit/feature-flag-client';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

type AnalyticsHandler = NonNullable<Parameters<FeatureFlagClient['setAnalyticsHandler']>[0]>;
type ExposureEvent = Parameters<AnalyticsHandler['sendOperationalEvent']>[0];

const confluenceAnalyticsHandler: AnalyticsHandler = {
	sendOperationalEvent: (event: ExposureEvent) =>
		getAnalyticsWebClient().then((client) => {
			client.sendOperationalEvent(event);
		}),
};

// Use memoization here so we always get the same client instance
export const createFeatureFlagClient = memoize((flags = {}) => {
	const featureFlagClient = new FeatureFlagClient({
		flags,
		analyticsHandler: confluenceAnalyticsHandler,
	});
	const getRawValueFN = featureFlagClient.getRawValue.bind(featureFlagClient);
	const getBooleanValueFN = featureFlagClient.getBooleanValue.bind(featureFlagClient);
	const getJSONValueFN = featureFlagClient.getJSONValue.bind(featureFlagClient);
	const getVariantValueFN = featureFlagClient.getVariantValue.bind(featureFlagClient);

	const getRawValue = (
		key: string,
		options: {
			default: FlagValue;
			shouldTrackExposureEvent?: boolean | undefined;
			exposureData?: CustomAttributes | undefined;
		},
	) => {
		const value = getRawValueFN(key, options);
		return value;
	};
	const getBooleanValue = (
		key: string,
		options: {
			default: boolean;
			shouldTrackExposureEvent?: boolean | undefined;
			exposureData?: CustomAttributes | undefined;
		},
	) => {
		const value = getBooleanValueFN(key, options);
		return value;
	};

	const getJSONValue = (key: string) => {
		const value = getJSONValueFN(key);
		return value;
	};

	const getVariantValue = (
		key: string,
		options: {
			default: string;
			oneOf: string[];
			shouldTrackExposureEvent?: boolean | undefined;
			exposureData?: CustomAttributes | undefined;
		},
	) => {
		const value = getVariantValueFN(key, options);
		return value;
	};

	return Object.assign(featureFlagClient, {
		getRawValue,
		getBooleanValue,
		getJSONValue,
		getVariantValue,
	});
}, isEqual);
