import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { Screen } from '@confluence/screen';
import { VIEW_PAGE, VIEW_BLOG_DATE_LEGACY, VIEW_BLOG } from '@confluence/named-routes';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ViewPageLiteLoader, ViewPageLiteHeaderLoader } from '@confluence/view-page';

import SendTrackViewPageEvent from '../../SendTrackViewPageEvent';
import { ContentExtensionsProvider } from '../../ContentExtensionsProvider';
import SpaceBase from '../../SpaceBase';
import { LazyDwellTime } from '../lazyComponents';

export const ViewPageLiteRoute = ({
	name: routeName,
	params: { contentId, spaceKey, contentSlug },
}: RouteMatch) => {
	const isPage = routeName === VIEW_PAGE.name;
	const isBlog = routeName === VIEW_BLOG_DATE_LEGACY.name || routeName === VIEW_BLOG.name;
	let contentType;

	// any one of VIEW_BLOG_DATE_LEGACY, VIEW_BLOG or VIEW_PAGE route can end up here from ViewPageOrBlogRoute
	if (isBlog) {
		contentType = 'blogpost';
	} else if (isPage) {
		contentType = 'page';
	}

	return (
		<Screen
			showPremiumOnboarding={false}
			screenEvent={{
				name: 'viewPageScreen',
				id: contentId,
				attributes: {
					isPublicView: false,
					pageLoadInfo: getUniquePageLoadId(),
				},
				objectType: contentType,
				objectId: contentId,
			}}
			pageState={{
				contentId,
				spaceKey,
				routeName,
				contentSlug,
				isLite: true,
				contentType,
			}}
		>
			<ViewPageLiteHeaderLoader />
			<SpaceBase>
				<ContentExtensionsProvider>
					<ViewPageLiteLoader spaceKey={spaceKey} contentId={contentId}>
						<ErrorBoundary attribution={Attribution.BACKBONE}>
							<SendTrackViewPageEvent pageType={contentType} />
						</ErrorBoundary>
					</ViewPageLiteLoader>
				</ContentExtensionsProvider>
			</SpaceBase>
			<LazyDwellTime />
		</Screen>
	);
};
