import { getTokenValue } from '@atlaskit/tokens';

export type ChartColorTokens = keyof typeof chartColorTokens;

export const chartColorTokens = {
	// fallback value of the color tokens are only light themed colors
	'color.chart.categorical.1': getTokenValue('color.chart.categorical.1', '#2898BD'),
	'color.chart.categorical.1.hovered': getTokenValue(
		'color.chart.categorical.1.hovered',
		'#227D9B',
	),
	'color.chart.categorical.2': getTokenValue('color.chart.categorical.2', '#5E4DB2'),
	'color.chart.categorical.2.hovered': getTokenValue(
		'color.chart.categorical.2.hovered',
		'#352C63',
	),
	'color.chart.categorical.3': getTokenValue('color.chart.categorical.3', '#E56910'),
	'color.chart.categorical.3.hovered': getTokenValue(
		'color.chart.categorical.3.hovered',
		'#C25100',
	),
	'color.chart.categorical.4': getTokenValue('color.chart.categorical.4', '#943D73'),
	'color.chart.categorical.4.hovered': getTokenValue(
		'color.chart.categorical.4.hovered',
		'#50253F',
	),
	'color.chart.categorical.5': getTokenValue('color.chart.categorical.5', '#09326C'),
	'color.chart.categorical.5.hovered': getTokenValue(
		'color.chart.categorical.5.hovered',
		'#1C2B41',
	),
	'color.chart.categorical.6': getTokenValue('color.chart.categorical.6', '#8F7EE7'),
	'color.chart.categorical.6.hovered': getTokenValue(
		'color.chart.categorical.6.hovered',
		'#8270DB',
	),
	'color.chart.categorical.7': getTokenValue('color.chart.categorical.7', '#50253F'),
	'color.chart.categorical.7.hovered': getTokenValue(
		'color.chart.categorical.7.hovered',
		'#3D2232',
	),
	'color.chart.categorical.8': getTokenValue('color.chart.categorical.8', '#A54800'),
	'color.chart.categorical.8.hovered': getTokenValue(
		'color.chart.categorical.8.hovered',
		'#702E00',
	),
	'color.chart.lime.bold': getTokenValue('color.chart.lime.bold', '#6A9A23'),
	'color.chart.lime.bold.hovered': getTokenValue('color.chart.lime.bold.hovered', '#5B7F24'),
	'color.chart.lime.bolder': getTokenValue('color.chart.lime.bolder', '#5B7F24'),
	'color.chart.lime.bolder.hovered': getTokenValue('color.chart.lime.bolder.hovered', '#4C6B1F'),
	'color.chart.lime.boldest': getTokenValue('color.chart.lime.boldest', '#4C6B1F'),
	'color.chart.lime.boldest.hovered': getTokenValue('color.chart.lime.boldest.hovered', '#37471F'),
	'color.chart.neutral': getTokenValue('color.chart.neutral', '#8590A2'),
	'color.chart.neutral.hovered': getTokenValue('color.chart.neutral.hovered', '#758195'),
	'color.chart.red.bold': getTokenValue('color.chart.red.bold', '#F15B50'),
	'color.chart.red.bold.hovered': getTokenValue('color.chart.red.bold.hovered', '#E2483D'),
	'color.chart.red.bolder': getTokenValue('color.chart.red.bolder', '#E2483D'),
	'color.chart.red.bolder.hovered': getTokenValue('color.chart.red.bolder.hovered', '#C9372C'),
	'color.chart.red.boldest': getTokenValue('color.chart.red.boldest', '#AE2E24'),
	'color.chart.red.boldest.hovered': getTokenValue('color.chart.red.boldest.hovered', '#5D1F1A'),
	'color.chart.orange.bold': getTokenValue('color.chart.orange.bold', '#E56910'),
	'color.chart.orange.bold.hovered': getTokenValue('color.chart.orange.bold.hovered', '#C25100'),
	'color.chart.orange.bolder': getTokenValue('color.chart.orange.bolder', '#C25100'),
	'color.chart.orange.bolder.hovered': getTokenValue(
		'color.chart.orange.bolder.hovered',
		'#A54800',
	),
	'color.chart.orange.boldest': getTokenValue('color.chart.orange.boldest', '#A54800'),
	'color.chart.orange.boldest.hovered': getTokenValue(
		'color.chart.orange.boldest.hovered',
		'#702E00',
	),
	'color.chart.yellow.bold': getTokenValue('color.chart.yellow.bold', '#B38600'),
	'color.chart.yellow.bold.hovered': getTokenValue('color.chart.yellow.bold.hovered', '#946F00'),
	'color.chart.yellow.bolder': getTokenValue('color.chart.yellow.bolder', '#946F00'),
	'color.chart.yellow.bolder.hovered': getTokenValue(
		'color.chart.yellow.bolder.hovered',
		'#7F5F01',
	),
	'color.chart.yellow.boldest': getTokenValue('color.chart.yellow.boldest', '#7F5F01'),
	'color.chart.yellow.boldest.hovered': getTokenValue(
		'color.chart.yellow.boldest.hovered',
		'#533F04',
	),
	'color.chart.green.bold': getTokenValue('color.chart.green.bold', '#22A06B'),
	'color.chart.green.bold.hovered': getTokenValue('color.chart.green.bold.hovered', '#1F845A'),
	'color.chart.green.bolder': getTokenValue('color.chart.green.bolder', '#1F845A'),
	'color.chart.green.bolder.hovered': getTokenValue('color.chart.green.bolder.hovered', '#216E4E'),
	'color.chart.green.boldest': getTokenValue('color.chart.green.boldest', '#216E4E'),
	'color.chart.green.boldest.hovered': getTokenValue(
		'color.chart.green.boldest.hovered',
		'#164B35',
	),
	'color.chart.teal.bold': getTokenValue('color.chart.teal.bold', '#2898BD'),
	'color.chart.teal.bold.hovered': getTokenValue('color.chart.teal.bold.hovered', '#227D9B'),
	'color.chart.teal.bolder': getTokenValue('color.chart.teal.bolder', '#227D9B'),
	'color.chart.teal.bolder.hovered': getTokenValue('color.chart.teal.bolder.hovered', '#206A83'),
	'color.chart.teal.boldest': getTokenValue('color.chart.teal.boldest', '#206A83'),
	'color.chart.teal.boldest.hovered': getTokenValue('color.chart.teal.boldest.hovered', '#164555'),
	'color.chart.blue.bold': getTokenValue('color.chart.blue.bold', '#388BFF'),
	'color.chart.blue.bold.hovered': getTokenValue('color.chart.blue.bold.hovered', '#1D7AFC'),
	'color.chart.blue.bolder': getTokenValue('color.chart.blue.bolder', '#1D7AFC'),
	'color.chart.blue.bolder.hovered': getTokenValue('color.chart.blue.bolder.hovered', '#0C66E4'),
	'color.chart.blue.boldest': getTokenValue('color.chart.blue.boldest', '#0055CC'),
	'color.chart.blue.boldest.hovered': getTokenValue('color.chart.blue.boldest.hovered', '#09326C'),
	'color.chart.purple.bold': getTokenValue('color.chart.purple.bold', '#8F7EE7'),
	'color.chart.purple.bold.hovered': getTokenValue('color.chart.purple.bold.hovered', '#8270DB'),
	'color.chart.purple.bolder': getTokenValue('color.chart.purple.bolder', '#8270DB'),
	'color.chart.purple.bolder.hovered': getTokenValue(
		'color.chart.purple.bolder.hovered',
		'#6E5DC6',
	),
	'color.chart.purple.boldest': getTokenValue('color.chart.purple.boldest', '#5E4DB2'),
	'color.chart.purple.boldest.hovered': getTokenValue(
		'color.chart.purple.boldest.hovered',
		'#352C63',
	),
	'color.chart.magenta.bold': getTokenValue('color.chart.magenta.bold', '#DA62AC'),
	'color.chart.magenta.bold.hovered': getTokenValue('color.chart.magenta.bold.hovered', '#CD519D'),
	'color.chart.magenta.bolder': getTokenValue('color.chart.magenta.bolder', '#CD519D'),
	'color.chart.magenta.bolder.hovered': getTokenValue(
		'color.chart.magenta.bolder.hovered',
		'#AE4787',
	),
	'color.chart.magenta.boldest': getTokenValue('color.chart.magenta.boldest', '#943D73'),
	'color.chart.magenta.boldest.hovered': getTokenValue(
		'color.chart.magenta.boldest.hovered',
		'#50253F',
	),
	'color.chart.gray.bold': getTokenValue('color.chart.gray.bold', '#8590A2'),
	'color.chart.gray.bold.hovered': getTokenValue('color.chart.gray.bold.hovered', '#758195'),
	'color.chart.gray.bolder': getTokenValue('color.chart.gray.bolder', '#758195'),
	'color.chart.gray.bolder.hovered': getTokenValue('color.chart.gray.bolder.hovered', '#626F86'),
	'color.chart.gray.boldest': getTokenValue('color.chart.gray.boldest', '#44546F'),
	'color.chart.gray.boldest.hovered': getTokenValue('color.chart.gray.boldest.hovered', '#2C3E5D'),
	'color.chart.brand': getTokenValue('color.chart.brand', '#1D7AFC'),
	'color.chart.brand.hovered': getTokenValue('color.chart.brand.hovered', '#0C66E4'),
	'color.chart.danger': getTokenValue('color.chart.danger', '#F15B50'),
	'color.chart.danger.hovered': getTokenValue('color.chart.danger.hovered', '#E2483D'),
	'color.chart.danger.bold': getTokenValue('color.chart.danger.bold', '#AE2E24'),
	'color.chart.danger.bold.hovered': getTokenValue('color.chart.danger.bold.hovered', '#5D1F1A'),
	'color.chart.warning': getTokenValue('color.chart.warning', '#B38600'),
	'color.chart.warning.hovered': getTokenValue('color.chart.warning.hovered', '#946F00'),
	'color.chart.warning.bold': getTokenValue('color.chart.warning.bold', '#7F5F01'),
	'color.chart.warning.bold.hovered': getTokenValue('color.chart.warning.bold.hovered', '#533F04'),
	'color.chart.success': getTokenValue('color.chart.success', '#22A06B'),
	'color.chart.success.hovered': getTokenValue('color.chart.success.hovered', '#1F845A'),
	'color.chart.success.bold': getTokenValue('color.chart.success.bold', '#216E4E'),
	'color.chart.success.bold.hovered': getTokenValue('color.chart.success.bold.hovered', '#164B35'),
	'color.chart.discovery': getTokenValue('color.chart.discovery', '#8F7EE7'),
	'color.chart.discovery.hovered': getTokenValue('color.chart.discovery.hovered', '#8270DB'),
	'color.chart.discovery.bold': getTokenValue('color.chart.discovery.bold', '#5E4DB2'),
	'color.chart.discovery.bold.hovered': getTokenValue(
		'color.chart.discovery.bold.hovered',
		'#352C63',
	),
	'color.chart.information': getTokenValue('color.chart.information', '#388BFF'),
	'color.chart.information.hovered': getTokenValue('color.chart.information.hovered', '#1D7AFC'),
	'color.chart.information.bold': getTokenValue('color.chart.information.bold', '#0055CC'),
	'color.chart.information.bold.hovered': getTokenValue(
		'color.chart.information.bold.hovered',
		'#09326C',
	),
};
