import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SpotlightManager as AkSpotlightManager } from '@atlaskit/onboarding';

import { ChangeboardingSpotlightLazyLoader } from './ChangeboardingLazyLoaders';

export default class ChangeboardingManagerComponent extends Component {
	static propTypes = {
		children: PropTypes.any,
		spotlightId: PropTypes.string,
	};

	render() {
		const { spotlightId, children } = this.props;
		return (
			<AkSpotlightManager>
				{children}
				{!spotlightId ? null : <ChangeboardingSpotlightLazyLoader />}
			</AkSpotlightManager>
		);
	}
}
