import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

export const updateEmbeddedConfluenceSource = (
	payload: AnalyticsEventPayload,
	embeddedConfluenceSource: string,
): AnalyticsEventPayload => {
	const data = payload.data || payload; //this is needed because some events have their attributes property wrapped within data: { ... } , and some don't.
	data.attributes = {
		...data.attributes,
		embeddedConfluenceSource,
	};
	return payload;
};
