/* eslint-disable react/jsx-no-bind */
import React from 'react';

import type { ExtensionHandler } from '@atlaskit/editor-common/extensions';
import type FeatureFlagClient from '@atlaskit/feature-flag-client';
import { RendererActionsContext } from '@atlaskit/renderer/actions';

import type { FeatureFlagsType } from '@confluence/session-data';
import type {
	ContentUnifiedQueryMacroRenderedOutputType,
	ContentUnifiedQueryContentNodeType,
} from '@confluence/content-unified-query';
import {
	EXTENSION_TYPE,
	EXTENSION_KEY,
	FF_INSERT_EXCERPT_INLINE_PARAM,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import { createMacroExtensionHandler } from '@confluence/fabric-extension-lib/entry-points/extensions-common';
import type {
	ExtensionHandlerProps,
	RendererExtensionHandlers,
} from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { RENDERER } from '@confluence/macro-tracker';
import { ADFRenderer } from '@confluence/adf-renderer';
import {
	isProfileMacrosExperimentEnabled,
	ProfilePictureMacroHandler,
	UserProfileMacroHandler,
} from '@confluence/profile-macros';
import { LoadablePlaceholderServer } from '@confluence/loadable/entry-points/LoadablePlaceholderServer';
import {
	DetailsExtensionHandler,
	ExcerptExtensionHandler,
	ExpandExtensionHandler,
	AnchorExtensionHandler,
	ExcerptIncludeSSRExtensionHandler,
} from '@confluence/content-renderer-extension-handlers';

import { LiveSearchHandler } from './LiveSearchHandler';
import { TableOfContentsHandler } from './TableOfContentsHandler';
import { CreateFromTemplateHandler } from './CreateFromTemplateHandler';
import { FabricLegacyMacrosServerRenderer } from './FabricLegacyMacrosServerRenderer';

type Options = {
	adf?: string | null;
	spaceKey?: string | null;
	contentId: string;
	cloudId: string;
	userId: string | null;
	includeSuperbatch?: () => void;
	featureFlagClient?: FeatureFlagClient;
	macroRenderedOutput?:
		| (ContentUnifiedQueryMacroRenderedOutputType | ContentUnifiedQueryContentNodeType | null)[]
		| null;
};

const context = {
	insertCss: (styles) => styles._insertCss(),
};

export const getSsrExtensionHandlers = ({
	adf,
	contentId,
	spaceKey,
	cloudId,
	userId,
	macroRenderedOutput,
	includeSuperbatch,
	featureFlagClient,
}: Options): RendererExtensionHandlers => {
	return {
		[EXTENSION_TYPE.MACRO_CORE]: getMacroExtensionHandlerForRenderer({
			adf,
			contentId,
			spaceKey,
			cloudId,
			userId,
			macroRenderedOutput,
			includeSuperbatch,
			featureFlagClient,
		}),
		[EXTENSION_TYPE.MACRO_WITH_REFERENCES]: getEmptyHandlers(),
		[EXTENSION_TYPE.MIGRATION]: getEmptyHandlers(),
		[EXTENSION_TYPE.TEMPLATE]: getEmptyHandlers(),
	};
};

const getEmptyHandlers = (): ExtensionHandler<any> =>
	createMacroExtensionHandler(() => {
		return null;
	});

export const getExtensionHandlersMap = ({
	macroDefaultProps,
	extension,
	cloudId,
	contentId,
	userId,
	adf,
	featureFlagClient,
}) => {
	const featureFlags: FeatureFlagsType = {
		[FF_INSERT_EXCERPT_INLINE_PARAM]: !!featureFlagClient?.getBooleanValue(
			FF_INSERT_EXCERPT_INLINE_PARAM,
			{
				default: false,
			},
		),
	};

	function getSsrButtonLabel() {
		const { buttonLabel, createButtonLabel } = macroDefaultProps.node.parameters.macroParams;

		const labelText = buttonLabel?.value || createButtonLabel?.value;
		return labelText || undefined;
	}

	return {
		[EXTENSION_KEY.TOC]: () => (
			<TableOfContentsHandler
				macroDefaultProps={macroDefaultProps}
				adf={adf}
				cloudId={cloudId}
				userId={userId}
			/>
		),
		[EXTENSION_KEY.CREATE_FROM_TEMPLATE]: () => (
			<CreateFromTemplateHandler
				macroDefaultProps={macroDefaultProps}
				cloudId={cloudId}
				userId={userId}
				ssrButtonLabel={getSsrButtonLabel()}
			/>
		),
		[EXTENSION_KEY.LIVE_SEARCH]: () => <LiveSearchHandler macroDefaultProps={macroDefaultProps} />,
		[EXTENSION_KEY.DETAILS]: () => (
			<DetailsExtensionHandler
				{...macroDefaultProps}
				Renderer={ADFRenderer}
				isLegacyRenderer={false}
				getExtensionHandlers={getSsrExtensionHandlers}
			/>
		),
		[EXTENSION_KEY.EXPAND]: () => (
			<ExpandExtensionHandler
				{...macroDefaultProps}
				Renderer={ADFRenderer}
				isLegacyRenderer={false}
				getExtensionHandlers={getSsrExtensionHandlers}
			/>
		),
		[EXTENSION_KEY.EXCERPT]: () => (
			<ExcerptExtensionHandler
				{...macroDefaultProps}
				Renderer={ADFRenderer}
				isLegacyRenderer={false}
				getExtensionHandlers={getSsrExtensionHandlers}
			/>
		),
		[EXTENSION_KEY.ANCHOR]: () => <AnchorExtensionHandler {...macroDefaultProps} isSSR />,
		[EXTENSION_KEY.EXCERPT_INCLUDE]: () =>
			contentId ? (
				<ExcerptIncludeSSRExtensionHandler
					{...macroDefaultProps}
					Renderer={ADFRenderer}
					getExtensionHandlers={getSsrExtensionHandlers}
					featureFlags={featureFlags}
				/>
			) : null,
		[EXTENSION_KEY.PROFILE_PICTURE]: () =>
			isProfileMacrosExperimentEnabled() ? (
				<ProfilePictureMacroHandler
					macroDefaultProps={macroDefaultProps}
					macroParams={extension?.parameters?.macroParams}
				/>
			) : null,
		[EXTENSION_KEY.PROFILE]: () =>
			isProfileMacrosExperimentEnabled() ? (
				<UserProfileMacroHandler
					macroDefaultProps={macroDefaultProps}
					macroParams={extension?.parameters?.macroParams}
				/>
			) : null,

		/* These macros have React implementation on SPA */
		[EXTENSION_KEY.DETAILS_SUMMARY]: () => null,
		[EXTENSION_KEY.REDACTED_MACRO_PLACEHOLDER]: () => null,
		[EXTENSION_KEY.CONFLUENCE_DATABASES]: () => null,
	};
};

const getMacroExtensionHandlerForRenderer = ({
	adf,
	contentId,
	spaceKey,
	cloudId,
	userId,
	includeSuperbatch,
	macroRenderedOutput,
	featureFlagClient,
}): ExtensionHandler<any> =>
	createMacroExtensionHandler((extension) => {
		const { extensionKey } = extension;

		const macroDefaultProps: ExtensionHandlerProps = {
			node: extension,
			contentId,
			spaceKey,
			context,
			mode: RENDERER,
			extensionKey,
		};

		// This callback won't be available on a recursive call for handlers from bodied macros
		// but it only needs to be called on the top level of recursion.
		if (includeSuperbatch) {
			includeSuperbatch();
		}

		const serverExtensionHandlersMap = getExtensionHandlersMap({
			adf,
			userId,
			contentId,
			cloudId,
			extension,
			macroDefaultProps,
			featureFlagClient,
		});

		const handlerContent = serverExtensionHandlersMap[extensionKey];
		return (
			<RendererActionsContext>
				{typeof handlerContent === 'function' ? (
					/**
					 * This is a "detached" loadable placeholder.
					 * It renders a begin and end marker for stuff rendered on the server-side.
					 * When rendering SPA the loadable in
					 * confluence/node_modules/@atlaskit/editor-common/dist/es2019/extensions/extension-handlers.js
					 * will take this placeholder while loading
					 *
					 * We are specifying the tag to be div instead of normally a hidden input.
					 * This is because the renderer will try to fix the DOM structure placing the input tag to another <p> tag.
					 * This will break the collect() in confluence/next/packages/loadable/src/placeholders/placeholders.ts
					 */
					<LoadablePlaceholderServer
						placeholderId={`ssr-extension-handler-placeholder-${extensionKey}`}
						tag="div"
					>
						{handlerContent()}
					</LoadablePlaceholderServer>
				) : (
					/**
					 * There is no loadable marker for legacy macros
					 * This is because it has its own handling confluence/next/packages/content-renderer/src/LegacyMacroStyledElement.tsx
					 */
					<FabricLegacyMacrosServerRenderer
						macroRenderedOutput={macroRenderedOutput}
						macroDefaultProps={macroDefaultProps}
						macroOutput={extension?.parameters?.macroOutput}
					/>
				)}
			</RendererActionsContext>
		);
	});
