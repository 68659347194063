import gql from 'graphql-tag';

export const HomeLeftPanelAdministrationQuery = gql`
	query HomeLeftPanelAdministrationQuery {
		# Product admin check
		user(current: true) {
			id
			confluence {
				operations {
					operation
					targetType
				}
			}
		}
		# Site admin check
		isSiteAdmin
	}
`;
