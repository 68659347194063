import { query } from '@confluence/query-preloader-tools';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';

import { WhiteboardUnifiedQuery } from './graphql/WhiteboardUnifiedQuery.graphql';
import type {
	SpaceQueryWhiteboards,
	SpaceQueryWhiteboardsVariables,
} from './graphql/__types__/SpaceQuery';
import { SpaceQuery } from './graphql/SpaceQuery.graphql';
import type {
	WhiteboardUnifiedQueryVariables,
	WhiteboardUnifiedQuery as WhiteboardUnifiedQueryType,
} from './graphql/__types__/WhiteboardUnifiedQuery';
import { ContentIdByReferenceIdQuery } from './graphql/ContentIdByReferenceIdQuery.graphql';
import type {
	ContentIdByReferenceIdQueryWhiteboards,
	ContentIdByReferenceIdQueryWhiteboardsVariables,
} from './graphql/__types__/ContentIdByReferenceIdQuery';

type PreloadWhiteboardDataParams = {
	spaceKey: string;
	contentId: string;
	includeAlias: boolean;
	includeWhiteboardToken?: boolean;
};

export async function preloadWhiteboardData({
	spaceKey,
	contentId,
	includeAlias,
	includeWhiteboardToken = false,
}: PreloadWhiteboardDataParams): Promise<any> {
	const preloadPromises = [
		preloadContentData(contentId, spaceKey, includeWhiteboardToken, includeAlias),
		preloadSpace(spaceKey),
	];
	if (process.env.REACT_SSR) {
		preloadPromises.push(
			preloadPageTree({
				spaceKey,
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
				contentId,
			}),
		);
	}
	return Promise.all(preloadPromises);
}

async function preloadContentData(
	contentId: string,
	spaceKey: string,
	includeWhiteboardToken: boolean,
	includeAlias: boolean,
): Promise<void> {
	const UUIDRegex = /[-]/;
	const isUuidBasedId = UUIDRegex.test(contentId);
	if (isUuidBasedId) {
		await query<
			ContentIdByReferenceIdQueryWhiteboards,
			ContentIdByReferenceIdQueryWhiteboardsVariables
		>({
			query: ContentIdByReferenceIdQuery,
			variables: {
				id: contentId,
			},
		});
	} else {
		await query<WhiteboardUnifiedQueryType, WhiteboardUnifiedQueryVariables>({
			query: WhiteboardUnifiedQuery,
			variables: {
				contentId,
				spaceKey,
				includeWhiteboardToken,
				includeAlias,
				// The DSP query doesn't currently user the content version value, so using a fixed value
				contentVersion: 1,
			},
		});
	}
}

async function preloadSpace(spaceKey: string): Promise<void> {
	await query<SpaceQueryWhiteboards, SpaceQueryWhiteboardsVariables>({
		query: SpaceQuery,
		variables: {
			spaceKey,
		},
	});
}
