import React from 'react';
import { type TooltipProps, type Rendered } from '@atlassian/forge-ui-types';
import { type Props } from '../..';

const AKTooltip = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.tooltip' */
			'@atlaskit/tooltip'
		),
);

const Tooltip: React.FunctionComponent<Rendered<TooltipProps>> = ({ text, children }) => (
	<AKTooltip content={text}>{children}</AKTooltip>
);

export default Tooltip;

export const TooltipFn: React.FunctionComponent<Props> = ({
	props,
	children,
	Components,
	render,
	renderChildren,
	dispatch,
}) => {
	const { text } = props as TooltipProps;
	const renderedChildren = renderChildren({
		Components,
		children,
		render,
		dispatch,
	});
	return (
		<Tooltip text={text}>
			{Array.isArray(renderedChildren) ? renderedChildren[0] : renderedChildren}
		</Tooltip>
	);
};
