import { getBuildInfo } from '@confluence/build-info';
import { liftPromiseState } from '@confluence/lifted-promise';

type Context = {
	environment: string;
	synthetic: boolean;
};

let provideClient: (metalClient: any) => void;
const metalClientPromise = liftPromiseState(
	new Promise<any>((resolve) => {
		provideClient = resolve;
	}),
);

export const initMetalClient = ({ environment, synthetic }: Context): any => {
	Promise.all<any>([
		import(
			/* webpackChunkName: "loadable-atlassiansoxmetal-client" */ '@atlassiansox/metal-client'
		),
	])
		.then(([MetalClientModule]) => {
			const { default: MetalClient, envTypes } = MetalClientModule;

			const metalEnvironment =
				// In dev, proxying to a site will give that site's environment,
				// which is not what we want
				window.location.hostname === 'localhost'
					? envTypes.DEV
					: environment === 'STAGING'
						? envTypes.STAGING
						: environment === 'PRODUCTION'
							? envTypes.PROD
							: envTypes.DEV;

			const productInfo = {
				metalId: '88dcca98-e854-4063-954c-eabe3aeb25d2',
				version: getBuildInfo().FRONTEND_VERSION,
				env: metalEnvironment,
			};

			const settings = {
				meta: {
					synthetic,
				},
			};

			const metalClient = new MetalClient({ productInfo, settings });
			provideClient(metalClient);
		})
		.catch((error) => {
			throw new Error(`Fail to import metal-client: ${error}`);
		});
};

export const getMetalClient = () => metalClientPromise;
