import jwtDecode from 'jwt-decode';
import isEqual from 'lodash/isEqual';

type cacheWithExp<T> = {
	getValue: (arg?: any) => Promise<T>;
	forceGetValue: (arg?: any) => Promise<T>;
};

const BUFFER_MS = 60 * 1000; // refresh token 60 seconds before it expires
const EMPTY = [-Infinity, null, null];

/**
 * Memorize the result of fn in timeout milliseconds.
 */
export function cacheWithExp<T>(
	asyncFunction: (any?: any) => Promise<T>,
	tokenOrExpInMsAccessor: (json: T) => number | string,
	tokenExpBufferInMs = BUFFER_MS,
): cacheWithExp<T> {
	const cache = {};

	async function getValue(arg: any, force: boolean): Promise<T> {
		// It is ok to have clashing hash because we are going to check args anyway
		// Worse case is calling fn() more often than it should
		let hash = '';
		try {
			hash = JSON.stringify(arg);
		} catch {}

		const [exp, lastArg, lastValue] = cache[hash] || EMPTY;
		if (force || exp <= Date.now() || !isEqual(lastArg, arg)) {
			const value = await asyncFunction(arg);

			let tokenOrExp: string | number | null = null;
			try {
				tokenOrExp = tokenOrExpInMsAccessor(value);
			} catch (e) {
				if (process.env.NODE_ENV !== 'production') {
					throw e;
				}
			}

			let exp: number | null = null;
			if (tokenOrExp) {
				if (!isNaN(tokenOrExp as any)) {
					exp = Number(tokenOrExp);
				} else if (typeof tokenOrExp === 'string') {
					try {
						exp = jwtDecode<{ exp: number }>(tokenOrExp).exp * 1000 - tokenExpBufferInMs;
					} catch (e) {
						if (process.env.NODE_ENV !== 'production') {
							throw e;
						}
					}
				} else {
					if (process.env.NODE_ENV !== 'production') {
						throw new Error(
							`The return value can only be a string (JWT token) or number (expiry time in ms) but getting: ${tokenOrExp}`,
						);
					}
				}
			}
			cache[hash] = exp === null ? EMPTY : [exp, arg, value];

			return value;
		}

		return lastValue;
	}

	return {
		forceGetValue: (arg) => {
			return getValue(arg, true);
		},
		getValue: (arg) => {
			return getValue(arg, false);
		},
	};
}
