// Styles have been copied to packages/editor/generative-ai-modal/examples/utils/components/Modals.tsx
// Updates to this file should also be copied to there.
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const styleDiv = css({
	position: 'relative',
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const styleIconBackground = css({
	backgroundColor: token('elevation.surface.overlay', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const styleBorder = css({
	position: 'absolute',
	left: '0px',
	top: '0px',
	right: 0,
	bottom: 0,
});
