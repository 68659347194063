import { getSessionData } from '@confluence/session-data';
import type { SessionDataType } from '@confluence/session-data';
import { initializeClientFromValues } from '@confluence/statsig-client/entry-points/initializeClientFromValues';
import { getStatsigBootstrap } from '@confluence/statsig-client/entry-points/getStatsigBootstrap';

// Preload function context is a subset of session data
// It's a subset because we can't provide everything on the server-side.
export type PreloadFnContext = {
	isLoggedIn: SessionDataType['isLoggedIn'];
	/**
	 * @deprecated Use accessStatus
	 */
	isLicensed: SessionDataType['isLicensed'];
	accessStatus: SessionDataType['accessStatus'];
	userId: SessionDataType['userId'];
	cloudId: SessionDataType['cloudId'];
	orgId: SessionDataType['orgId'];
	/**
	 * @deprecated Use `fg()` from `@confluence/feature-gating`;
	 */
	featureFlags: SessionDataType['featureFlags'];
	edition: SessionDataType['edition'];
	activationId: SessionDataType['activationId'];
	environment: SessionDataType['environment'];
	locale: SessionDataType['locale'];
};

function sessionDataLikeToPreloadFnContext(data: Partial<SessionDataType>): PreloadFnContext {
	return {
		isLoggedIn: Boolean(data.isLoggedIn),
		isLicensed: Boolean(data.isLicensed),
		accessStatus: data.accessStatus || null,
		userId: data.userId || null,
		cloudId: data.cloudId || '',
		orgId: data.orgId,
		featureFlags: data.featureFlags || {},
		edition: data.edition || null,
		activationId: data.activationId || '',
		environment: data.environment || 'PRODUCTION',
		// Note: the locale from session data is not standard aa-AA format. It is aa_AA
		locale: data.locale?.replace('_', '-') || 'en-US',
	};
}

export type getPreloadFnContextType = () => Promise<PreloadFnContext>;

let resolveContext = (_: PreloadFnContext) => {};
const contextPromise: Promise<PreloadFnContext> = new Promise(
	(resolve) => (resolveContext = resolve),
);

export async function initializePreloaderFnContext(
	contextCreatedFromSSRApp: PreloadFnContext | null = null,
): Promise<void> {
	if (contextCreatedFromSSRApp) {
		return resolveContext(sessionDataLikeToPreloadFnContext(contextCreatedFromSSRApp));
	}

	const [sessionData, statsigBootstrapData] = await Promise.all([
		getSessionData(),
		getStatsigBootstrap(),
	]);

	const { userId, cloudId, environment } = sessionData;
	const { clientSdkKey, experimentValues, configCollectionValues } = statsigBootstrapData || {};

	await initializeClientFromValues({
		clientSdkKey,
		experimentValues,
		configCollectionValues,
		identifiers: {
			userId,
			tenantId: cloudId,
		},
		environment,
	});

	// Please do NOT add random field to the return result of context object.
	// Because when rendering on the server-side.
	// cc-frontend-ssr will provide these values so we can avoid a round trip before requesting the actual queries.
	// It is VERY critical that every value returned by the getContext callback can be derived from cc-frontend-ssr service without querying graphql.
	// Please consult developers in #cc-fe-performance when changing this function
	// Also, when updating this function you MUST update the preloadQueryBasedOnRoute call in @confluence/ssr-app
	resolveContext(sessionDataLikeToPreloadFnContext(sessionData));
}

export async function getPreloaderFnContext(): Promise<PreloadFnContext> {
	return contextPromise;
}
