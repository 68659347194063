import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import { WhiteboardRouteComponentLoader } from './WhiteboardRouteComponentLoader';

export const WhiteboardRoute = ({ params: { contentId, spaceKey } }: RouteMatch) => (
	<WhiteboardRouteComponentLoader spaceKey={spaceKey} contentId={contentId} />
);

WhiteboardRoute.NAVIGATION_PARAMS = ({
	name: routeName,
	params: { contentId, spaceKey },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'whiteboardScreen',
			id: contentId,
			attributes: {
				contentId,
			},
		},
		pageState: {
			contentId,
			spaceKey,
			contentType: 'whiteboard',
			routeName,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		isViewPage: true,
	},
});
