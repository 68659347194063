import window from "window-or-global";

import { WRM } from "@confluence-classic/confluence-wrm";

import type { MacroBrowser } from "@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types";

export const createFromTemplateMacroWRM = () => [
  "wr!com.atlassian.confluence.plugins.confluence-create-content-plugin:editor-resources",
  "wr!com.atlassian.confluence.plugins.confluence-create-content-plugin:create-from-template-macro-browser-resources",
  "wr!com.atlassian.confluence.plugins.confluence-create-content-plugin:create-from-template-resources"
];

export function loadMacroBrowserResources(): Promise<MacroBrowser> {
  return new Promise(resolve => {
    const resources = [
      // plugins contributing to macro browser will be loaded through this context.
      // `fabric-editor-macro-browser` context is found in core in case we want to add resources of other macro in future.
      "wrc!fabric-editor-macro-browser",

      // The inline task report plugin implements its own (legacy) way of loading JS overrides, hence we have to require
      // these overrides here.
      "wr!com.atlassian.confluence.plugins.confluence-inline-tasks:inline-tasks-macro-browser",

      // Jira gadgets
      "wr!com.atlassian.confluence.plugins.gadgets:macro-browser-for-gadgetsplugin",

      // these resources are necessary for create-from-template-macro to initialize and populate the template dropdown
      // copy from packages/confluence-editor/src/editor-plugins/create-from-template-macro/plugin.js
      // tinymce also did similar macro loading via packages/confluence-editor/src/editor-plugins/index.js
      ...createFromTemplateMacroWRM(),
    ];

    void WRM.require(
      resources,
      () => {
        const macroBrowser = window.require(
          "confluence-macro-browser/macro-browser"
        );

        void import(/* webpackChunkName: "loadable-atlassianconfluence-macro-browser-overrides" */
          "@confluence-classic/confluence-macro-browser-overrides"
        ).then((macroBrowserOverridesModule) => {
          macroBrowserOverridesModule.default()
        }).then(() => {
          /**
           * this is required for atlassian connect plugin to work correctly
           * e.g open custom dialog for DrawIO, Gliffy macro
           * check this repo for more info:
           * https://stash.atlassian.com/projects/AC/repos/atlassian-connect/browse
           */
          // @ts-ignore TODO FIXME we really don't need typings for "window-or-global", we need to get rid of it instead
          window.AJS.trigger("reset.rte");

          resolve(macroBrowser);
        });
      }
    );
  });
}
