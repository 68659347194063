/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b59b62050db568ec6528056d10120e35>>
 * @codegenId #modal-body
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-body
 * @codegenParams { "props": { "children": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-body.tsx <<SignedSource::ac7de9b89f785a66ed8575b768d78492>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::bdfd75a709534a0de60ff52436ad515a>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/components/scroll-container.tsx <<SignedSource::686bb707756f173802df509aee3d56e2>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/constants.tsx <<SignedSource::b046819e1c384364b93a2f5e229c2e92>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-scroll.tsx <<SignedSource::ea82eeb8f90148376b49dcaa51ef6498>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalBody as PlatformModalBody } from '@atlaskit/modal-dialog';

type PlatformModalBodyProps = React.ComponentProps<typeof PlatformModalBody>;

export type ModalBodyProps = Pick<
  PlatformModalBodyProps,
  'children' | 'testId'
>;

export const ModalBody = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ModalBodyProps;
  return (
    <PlatformModalBody
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
