import React from 'react';
import { type Extension } from '../web-client';

interface RendererContextProps {
	egress?: Extension['egress'];
	forgeEnvironment?: Extension['environmentType'];
	forgeReactMajorVersion?: number;
	appDomainName?: string;
	extensionType?: Extension['type'];
}

export const RendererContext = React.createContext<RendererContextProps>({});
