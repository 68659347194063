import type { ReactNode } from 'react';
import React, { Fragment, useCallback, useState, useEffect } from 'react';

import { Attribution } from './Attribution';
import type { ErrorComponentProps } from './GenericErrorBoundary';
import { GenericErrorBoundary } from './GenericErrorBoundary';

export type ErrorDisplayProps = {
	children?: ReactNode;
	error: ThrowErrorProps['error'];
};

export function ErrorDisplay({ children, error }: ErrorDisplayProps) {
	const [errorListenerIsReady, setErrorListenerIsReady] = useState(false);
	useEffect(() => {
		const listener = (event: ErrorEvent) => {
			if (event.error === error) {
				// this way we're preventing React from showing an ugly message in the console
				// because the developer is doing everything right
				event.preventDefault();
			}
		};
		window.addEventListener('error', listener);
		setErrorListenerIsReady(true);
		return () => {
			window.removeEventListener('error', listener);
			setErrorListenerIsReady(false);
		};
	}, [error, setErrorListenerIsReady]);

	const renderOnError = useCallback(
		(_: ErrorComponentProps) => <Fragment>{children}</Fragment>,
		[children],
	);

	return (
		<GenericErrorBoundary attribution={Attribution.INHERIT} renderOnError={renderOnError}>
			{errorListenerIsReady ? <ThrowError error={error} /> : null}
		</GenericErrorBoundary>
	);
}

type ThrowErrorProps = {
	error: Error;
};

export function ThrowError({ error }: ThrowErrorProps): never {
	throw error;
}
