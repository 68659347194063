import type { PerformanceEntryBuffer } from './utils/buffer';

const MAX_ACCEPTABLE_TASK_DURATION = 50;
export const getLongTaskTiming = (start: number, stop: number, buffer: PerformanceEntryBuffer) =>
	buffer
		.getAll()
		.filter(
			// filter entries that are long tasks (greater than MAX_ACCEPTABLE_TASK_DURATION)
			(entry) =>
				entry.duration > MAX_ACCEPTABLE_TASK_DURATION &&
				(entry.startTime >= start ||
					entry.startTime + entry.duration >= start ||
					(entry.startTime <= start && entry.startTime + entry.duration >= stop)),
		)
		.reduce((longTaskTiming, entry) => {
			longTaskTiming += entry.duration - MAX_ACCEPTABLE_TASK_DURATION;
			return longTaskTiming;
		}, 0);
