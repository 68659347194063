import memoize from 'memoize-one';

type Callback = (time: number) => void;

// export for testing
export class FreezeTracker {
	private callbacks: Map<string, Callback> = new Map();

	start() {
		let lastFreezeStart: number | undefined;

		if (typeof document !== 'undefined') {
			document.addEventListener('freeze', () => {
				lastFreezeStart = performance.now();
			});

			document.addEventListener('resume', () => {
				// probably zero chance of it happening, but still
				if (typeof lastFreezeStart !== 'number') return;

				const now = performance.now();
				for (const callback of this.callbacks.values()) {
					callback(now - lastFreezeStart);
				}
			});
		}
	}

	subscribe(name: string, callback: Callback) {
		// We are not subscribe and unsubscribe with callback's reference.
		// Because there are known cases that experiences are not properly stopped.
		// In order prevent from memory leak here we are using the name as the key since the variation is bounded.
		this.callbacks.set(name, callback);
	}

	unsubscribe(name: string) {
		this.callbacks.delete(name);
	}
}

export const getFreezeTracker = memoize(() => new FreezeTracker());
