import type { ApolloQueryResult } from 'apollo-client';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { TemplateMediaSessionDownloadQuery } from './TemplateMediaSessionDownloadQuery.graphql';
import type {
	TemplateMediaSessionDownloadQuery as TemplateMediaSessionDownloadQueryType,
	TemplateMediaSessionDownloadQueryVariables,
} from './__types__/TemplateMediaSessionDownloadQuery';
import { TemplateMediaSessionUploadQuery } from './TemplateMediaSessionUploadQuery.graphql';
import type {
	TemplateMediaSessionUploadQuery as TemplateMediaSessionUploadQueryType,
	TemplateMediaSessionUploadQueryVariables,
} from './__types__/TemplateMediaSessionUploadQuery';

type TokenQuery = {
	spaceKey?: string | null;
	templateIds: string[];
	collectionId?: string | null;
};

/**
 * spacekey - global vs space level
 * templateIds - string[] - for previews of multiple templates
 * want to count UUID as normal template
 * templateIds - string - to differntiate between preview of single template and copy pasting
 * want to count UUID as new template in the case of uploads
 * collectionId - passed since templates that don't have static identifiers until they are saved with an image
 **/
export const getTemplateDownloadToken = async ({
	spaceKey,
	templateIds,
	collectionId = null,
}: TokenQuery): Promise<ApolloQueryResult<TemplateMediaSessionDownloadQueryType> | undefined> => {
	return getApolloClient()
		.query<TemplateMediaSessionDownloadQueryType, TemplateMediaSessionDownloadQueryVariables>({
			query: TemplateMediaSessionDownloadQuery,
			variables: {
				spaceKey: spaceKey || null,
				templateIds,
				collectionId,
			},
			fetchPolicy: 'network-only',
		})
		.catch((error: Error) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.TAILORED_EXPERIENCES,
			});
			markErrorAsHandled(error);
			return undefined;
		});
};

export const getTemplateUploadToken = async ({
	spaceKey,
	templateIds,
	collectionId = null,
}: TokenQuery): Promise<ApolloQueryResult<TemplateMediaSessionUploadQueryType> | undefined> => {
	return getApolloClient()
		.query<TemplateMediaSessionUploadQueryType, TemplateMediaSessionUploadQueryVariables>({
			query: TemplateMediaSessionUploadQuery,
			variables: {
				spaceKey: spaceKey || null,
				templateIds,
				collectionId,
			},
			fetchPolicy: 'no-cache',
		})
		.catch((error: Error) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.TAILORED_EXPERIENCES,
			});
			markErrorAsHandled(error);
			return undefined;
		});
};
