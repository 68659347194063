import { type ProductIds } from './constants';
import {
	type DebugLoggerState,
	type DebugLoggingLevels,
	type DebugLogMessages,
	type DebugTraceMessages,
	type IDebugLogger,
	type WindowWithChoreographerLoggingLevel,
} from './types';

function getCurrentLoggingLevel() {
	return (window as WindowWithChoreographerLoggingLevel).choreographerLoggingLevel;
}

function logMessageIfValidLoggingLevel(
	loggingLevel: DebugLoggingLevels,
	data: Record<string, unknown>,
) {
	if (getCurrentLoggingLevel() === loggingLevel) {
		// eslint-disable-next-line no-console
		console.table(data);
	}
}

function traceMessageIfValidLoggingLevel(
	loggingLevel: DebugLoggingLevels,
	productId: ProductIds,
	messageId: string,
	message: string,
) {
	if (getCurrentLoggingLevel() === loggingLevel) {
		// eslint-disable-next-line no-console
		console.log(`CHOREOGRAPHER - ${productId} - ${messageId} - ${message}`);
	}
}

export class DebugLogger implements IDebugLogger {
	protected state: DebugLoggerState;

	constructor(productId: ProductIds, messageId: string) {
		this.state = {
			productId,
			messageId,
		};
	}

	/**
	 * A function that takes an object of properties for each logging level
	 * name, e.g. log.info and log.verbose, which are functions that take an
	 * object of any key/value pairs, which will be merged with the productId
	 * and the messageId values passed to the constructor, and output via
	 * console.table.
	 *
	 * Example: debugLogger.log({
	 *   info: { foo: true },
	 *   verbose: {
	 *     foo: true,
	 *     bar: 123,
	 *     baz 'abc',
	 *     timestamp: performance.now()
	 *   },
	 * });
	 *
	 * @param messages Record<LoggingLevels, Record<string, unknown>> An object
	 * keyed by the desired log-level, with each value being an object of any
	 * key/value pairs to be output to console.table.
	 */
	public log = (messages: Partial<DebugLogMessages>) => {
		[...Object.keys(messages)].forEach((loggingLevel) => {
			logMessageIfValidLoggingLevel(loggingLevel as DebugLoggingLevels, {
				productId: this.state.productId,
				messageId: this.state.messageId,
				...(messages[loggingLevel as DebugLoggingLevels] ?? {}),
				...(loggingLevel === 'verbose' ? { timestamp: performance.now() } : {}),
			});
		});
	};

	/**
	 * A function that takes an object of properties for each logging level
	 * name, e.g. trace.info and trace.verbose, which are functions that take
	 * a string to be used as the label for a stack trace via console.trace.
	 *
	 * Example: debugLogger.trace({
	 *   info: 'This is a stack trace',
	 *   verbose: `This is a stack trace with a much more verbose message which
	 * contains data about the ${productId} and ${messageId} within my function,
	 * invoked at ${performance.now()}ms`,
	 * });
	 *
	 * @param messages Record<LoggingLevels, string> An object keyed by the
	 * desired log-level, with each value being a string to be output as the
	 * label to console.trace.
	 */
	public trace = (messages: Partial<DebugTraceMessages>) => {
		[...Object.keys(messages)].forEach((loggingLevel) => {
			traceMessageIfValidLoggingLevel(
				loggingLevel as DebugLoggingLevels,
				this.state.productId,
				this.state.messageId,
				`${messages[loggingLevel as DebugLoggingLevels]!}${loggingLevel === 'verbose' ? ` (${performance.now()})` : ''}`,
			);
		});
	};
}
