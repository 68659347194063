import { format } from 'url';

import React, { useEffect } from 'react';
// eslint-disable-next-line check-cross-package-imports/dont-import-redux-in-next
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import window from 'window-or-global';
import { CONTEXT_PATH, getLocation } from '@confluence-classic/confluence-urls';

/**
 * Used to redirect /wiki/pages/createpage.action and /wiki/pages/createblogpost.action to the new create page URL.
 */

export const RedirectOldCreatePage = () => {
	const location = useSelector(getLocation);
	const dispatch = useDispatch();
	useEffect(() => {
		const { query, pathname } = location;

		let contentType;
		if (
			pathname.indexOf('/createpage.action') !== -1 ||
			pathname.indexOf('/createpage-entervariables.action') !== -1
		) {
			contentType = 'pages';
		} else if (pathname.indexOf('/createblogpost.action') !== -1) {
			contentType = 'blog';
		} else {
			if (process.env.NODE_ENV !== 'production') {
				window.console.error('CreatePage route cannot recognize contentType -> redirecting to /');
			}
			dispatch(replace({ pathname: CONTEXT_PATH }));
			return;
		}

		let spaceKey;
		if (typeof query.spaceKey !== 'undefined' && query.spaceKey) {
			spaceKey = query.spaceKey;
		} else if (typeof query.draftId !== 'undefined' && /^[0-9]+$/.test(query.draftId)) {
			spaceKey = 'byDraft';
		} else {
			if (process.env.NODE_ENV !== 'production') {
				window.console.error(
					'CreatePage route missing required params (one of "spaceKey" or "draftId") -> redirecting to /',
				);
			}
			dispatch(replace({ pathname: CONTEXT_PATH }));
			return;
		}

		const newQuery = {};
		Object.keys(query)
			.filter((param) => param !== 'spaceKey')
			.forEach((param) => (newQuery[param] = query[param]));

		const nextLocation = {
			...location,
			pathname: `${CONTEXT_PATH}/spaces/${spaceKey}/${contentType}/create`,
			query: newQuery,
			search: format({ query: newQuery }),
		};

		dispatch(replace(nextLocation));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

RedirectOldCreatePage.NAVIGATION_PARAMS = () => false;
