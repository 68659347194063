import React from 'react';

import type { GenericErrorBoundaryProps } from './GenericErrorBoundary';
import { GenericErrorBoundary } from './GenericErrorBoundary';

export type TransparentErrorBoundaryProps = Pick<
	GenericErrorBoundaryProps,
	'attribution' | 'children' | 'onError'
>;

const NullErrorComponent = () => null;

export const TransparentErrorBoundary = ({
	attribution,
	children,
	onError,
}: TransparentErrorBoundaryProps) => (
	<GenericErrorBoundary
		attribution={attribution}
		renderOnError={NullErrorComponent}
		onError={onError}
	>
		{children}
	</GenericErrorBoundary>
);
