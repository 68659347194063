import type { SyncStatusMapType } from './types';

export enum EDITOR_NAV_DESTINATION {
	NONE = 'none', // this mode means no redirect to anywhere, should stay in editor mode
	PREVIEW = 'preview', // preview mode will be removed in future when Fabric renderer is applied
	VIEWPAGE = 'viewpage',
	HOMEPAGE = 'homepage',
}

// Fragment limited to 255 in backend.  Moving this to frontend (here).
export const MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH = 255;

export const EDITOR_VERSIONS = {
	FABRIC: 'FABRIC',
	TINY_MCE: 'TINY_MCE',
};

export enum SYNC_STATUS_KEY {
	CONNECTED = 'connected',
	DISCONNECTED = 'disconnected',
	ERROR = 'error',
	COLLAB_LIMIT = 'collab-limit',
	PERMISSION_CHANGED = 'permission-changed',
}

export const SYNC_STATUS_MAP: SyncStatusMapType = {
	[SYNC_STATUS_KEY.CONNECTED]: {
		text: 'collab.status.connected',
		tooltip: 'collab.status.tooltip.success',
		shouldAutoHide: true,
		type: 'success',
	},
	[SYNC_STATUS_KEY.ERROR]: {
		text: 'collab.status.error',
		tooltip: 'collab.status.tooltip.error',
		shouldAutoHide: false,
		type: 'error',
	},
	[SYNC_STATUS_KEY.COLLAB_LIMIT]: {
		text: 'editor.collab.user.limit.title',
		tooltip: 'editor.collab.user.limit.body',
		shouldAutoHide: false,
		type: 'error',
	},
	[SYNC_STATUS_KEY.PERMISSION_CHANGED]: {
		text: 'fabric.editor.permission.removed.title',
		tooltip: 'fabric.editor.permission.removed.description',
		shouldAutoHide: false,
		type: 'error',
	},
};

/**
 * Synchrony has a max websocket per entity of 100;
 * Therefore, (MAX_NUMBER_SESSIONS_PER_PARTICIPANT) x (MAX_NUMBER_PARTICIPANTS)
 * should not exceed 100.
 */
export const MAX_NUMBER_SESSIONS_PER_PARTICIPANT = 8;
export const MAX_NUMBER_PARTICIPANTS = 12;

// This message is registered in: packages/confluence-frontend-server/src/i18n/source.json
export const PLACEHOLDER_TEXT_ID = 'editor.content.placeholder.easy.mentions';
export const ONBOARDING_EDITOR_PLACE_HOLDER_TEXT_ID = 'editor.onboarding.draft.placeholder';
export const AI_PLACEHOLDER_TEXT_ID = 'editor.content.placeholder.ai';
export const AI_PLACEHOLDER_DEFAULT_MESSAGE =
	"Type /ai for Atlassian Intelligence, / to add elements, or @ to mention someone (we'll let them know).";
