import { query } from '@confluence/query-preloader-tools';

import { getDraftContentAppearanceProperty } from './ContentAppearanceQuery.graphql';
import type {
	getDraftContentAppearanceProperty as getDraftContentAppearancePropertyType,
	getDraftContentAppearancePropertyVariables as getDraftContentAppearancePropertyVariablesType,
} from './__types__/getDraftContentAppearanceProperty';

export const preloadDraftContentAppearance = (
	contentId: getDraftContentAppearancePropertyVariablesType['contentId'],
	useCache: boolean = true,
) => {
	return query<
		getDraftContentAppearancePropertyType,
		getDraftContentAppearancePropertyVariablesType & { cacheBuster?: number }
	>({
		query: getDraftContentAppearanceProperty,
		variables: {
			contentId,
			status: ['current', 'draft'],
			...(!useCache ? { cacheBuster: Date.now() } : {}),
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	});
};
