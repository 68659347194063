import React, { useMemo } from 'react';

import { IntegratedChoreographerMessageTypesEnum } from '../constants';
import type {
	ChoreographedComponentProps,
	ChoreographedMessage,
	ChoreographedMessageTypes,
	ChoreographerOptions,
	MaybeChoreographedComponentProps,
	ToggleableWrapperComponentProps,
	WrapperComponentProps,
} from '../types';

export const shouldBeChoreographed = (
	props?: MaybeChoreographedComponentProps,
	coordinationOptions?: ChoreographerOptions,
): props is ChoreographedMessage => {
	return (
		Boolean(coordinationOptions?.enableChoreographer) &&
		props?.messageType !== undefined &&
		IntegratedChoreographerMessageTypesEnum.includes(props.messageType as ChoreographedMessageTypes)
	);
};

export const wrapComponentForChoreographer = <T extends { [K in keyof T]: T[K] }>(
	Component: React.ComponentType<T>,
) => {
	function WrapperComponent({
		shouldBeChoreographed: shouldBeChoreographedProp,
		withChoreographedRender,
		...props
	}: T & WrapperComponentProps) {
		const ChoreographedComponent = useMemo(
			() => withChoreographedRender<T>(Component),
			[withChoreographedRender],
		);

		if (shouldBeChoreographedProp(props)) {
			// Extract messageType since it is not a property of ChoreographedComponent
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { messageType, ...rest } = props;

			return <ChoreographedComponent {...(rest as T & ChoreographedComponentProps)} />;
		}

		// Extract messageType since it is not a property of the original Atlaskit component
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { messageType, messageId, onMessageDisposition, ...rest } = props as T &
			MaybeChoreographedComponentProps;
		return <Component {...(rest as T)} />;
	}

	WrapperComponent.displayName = `ChoreographerWrapped${(Component.displayName ?? Component.name) || 'Component'}`;
	return WrapperComponent;
};

export const wrapToggleableComponentForChoreographer = <T extends { [K in keyof T]: T[K] }>(
	Component: React.ComponentType<T>,
	toggleableProp: keyof T,
) => {
	function WrapperComponent({
		shouldBeChoreographed: shouldBeChoreographedProp,
		withChoreographedToggle,
		...props
	}: T & ToggleableWrapperComponentProps) {
		const ChoreographedComponent = useMemo(
			() => withChoreographedToggle<T>(Component, toggleableProp),
			[withChoreographedToggle],
		);

		if (shouldBeChoreographedProp(props)) {
			// Extract messageType since it is not a property of ChoreographedComponent
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { messageType, ...rest } = props;

			return <ChoreographedComponent {...(rest as T & ChoreographedComponentProps)} />;
		}

		// Extract messageType since it is not a property of the original Atlaskit component
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { messageType, messageId, onMessageDisposition, ...rest } = props as T &
			MaybeChoreographedComponentProps;
		return <Component {...(rest as T)} />;
	}

	WrapperComponent.displayName = `ChoreographerWrapped${(Component.displayName ?? Component.name) || 'Component'}`;
	return WrapperComponent;
};
