import type { LineDefinition, LineData } from './ChartBase-types';

// Custom hook that processes the given LineDefinition[] to be usable by CustomLineGroup.
// If isAreaChart = true, transforms data by stacking corresponding y-values to support stacked area chart.
// Otherwise, simply returns the given line definitions since line charts require no further data processing.
export const useChartBaseLineGroupData = ({
	lines,
	isAreaChart,
}: {
	lines: LineDefinition[];
	isAreaChart: boolean;
}) => {
	if (isAreaChart) {
		// Line data calculated from lines prop that offsets y-values to support stacked areas.
		// For each data point in each line, add the point's y-value to the sum of the previously
		// processed line's y-value (i.e. "stack" the y-values).
		const processedLines: LineDefinition[] = [];
		// Date to stacked y-value map, used to optimize getting the previously stacked line's stacked y-value
		// for a given date when processing the current line.
		const stackedYValuesByDate = new Map<number, number>();
		for (let currentLinesIndex = 0; currentLinesIndex < lines.length; currentLinesIndex++) {
			const { description: currentDescription, data: currentData } = lines[currentLinesIndex];
			const currentLineData: LineData = [];
			for (const dataPoint of currentData) {
				if (dataPoint.y === undefined) {
					// If dataPoint.y is undefined, then just push it to the current line data,
					// since we cannot add "undefined" points to the stacked y-values.
					currentLineData.push({ ...dataPoint });
				} else if (currentLinesIndex === 0) {
					// If we're currently processing the first line, then there is no previous lines to stack
					// dataPoint onto, so just push it to the current line data.
					currentLineData.push({ ...dataPoint });
					// Add a corresponding (date, y-value) pair to stackedYValuesByDate for future lines to reference
					stackedYValuesByDate.set(dataPoint.x.valueOf(), dataPoint.y);
				} else {
					// Otherwise, stack dataPoint onto the previously processed lines.
					// Since previous lines are already stacked (by virtue of this algorithm), search for
					// the current stacked y-value by date in stackedYValuesByDate.
					// If there is no entry for the date (ex: previous lines had undefined points for this date),
					// use 0 as the current stacked y-value.
					// Add the dataPoint.y to the stacked y-value.
					const stackedYValue =
						(stackedYValuesByDate.get(dataPoint.x.valueOf()) || 0) + dataPoint.y;
					// Push the updated line data point to the current line data.
					currentLineData.push({
						...dataPoint,
						y: stackedYValue,
					});
					// Update the corresponding entry in stackedYValuesByDate with the new stacked y-value.
					stackedYValuesByDate.set(dataPoint.x.valueOf(), stackedYValue);
				}
			}
			processedLines.push({
				description: currentDescription,
				data: currentLineData,
			});
		}
		return processedLines;
	} else {
		return lines;
	}
};
