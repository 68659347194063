import React, { Suspense } from 'react';
import type { LazyExoticComponent } from 'react';
import type { ActiveTokens } from '@atlaskit/tokens';
import type { Icon as Glyph, RenderFn } from '@atlassian/forge-ui-types';
import tokens from '@atlaskit/tokens/token-names';
import { IconGlyphs } from './__generated__';

export type IconColor = Extract<ActiveTokens, `color.icon.${string}`>;
export interface IconProps {
	size?: 'small' | 'medium' | 'large';
	label: string;
	glyph: Glyph;
	primaryColor?: IconColor;
	secondaryColor?: IconColor;
}

/**
 * The validateColor function and its helper functions could not be
 * moved to a seperate file because it could potentitally cause cyclic
 * dependencies since type IconColor must be defined here in order for
 * forge-react-types to recognise it.
 */
const iconTokenSet = new Set(Object.keys(tokens).filter((token) => token.startsWith('color.icon')));
const isInTokenSet = (color: IconColor) => iconTokenSet.has(color);

export const validateColor = (color?: IconColor) => {
	if (!color) {
		return undefined;
	}
	if (!isInTokenSet(color)) {
		// eslint-disable-next-line no-console
		console.warn(`${color} is not a valid icon color design token`);
		return undefined;
	}

	return `var(${tokens[color]})`;
};

export const IconComponent = ({ glyph, size, label, primaryColor, secondaryColor }: IconProps) => {
	const Component = IconGlyphs[glyph] as LazyExoticComponent<any>;
	if (!Component) {
		// eslint-disable-next-line no-console
		console.warn(`Icon component ${glyph} not found`);
		return <></>;
	}

	return (
		<Suspense fallback={null}>
			<Component
				// @ts-ignore: Jira only - TS2322: Type '{ label: string; size: "small" | "medium" | "large" | undefined; glyph: Icon; primaryColor: string | undefined; secondaryColor: string | undefined; }' is not assignable to type 'IntrinsicAttributes'.
				label={label}
				size={size}
				glyph={glyph}
				primaryColor={validateColor(primaryColor)}
				secondaryColor={validateColor(secondaryColor)}
			/>
		</Suspense>
	);
};

export const Icon = (props: Parameters<RenderFn>[0]) => {
	const { size, glyph, label, primaryColor, secondaryColor } = props.forgeDoc.props as IconProps;

	return (
		<IconComponent
			label={label}
			size={size}
			glyph={glyph}
			primaryColor={primaryColor}
			secondaryColor={secondaryColor}
		/>
	);
};
