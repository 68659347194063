import type { StringField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export interface OfficeConnectorMacroParams {
	page?: string;
}

export const officeConnector: FieldTransformer<OfficeConnectorMacroParams> = {
	transformFields: async (fields) => {
		return (
			fields
				// Removes the field altogether
				// https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/browse/packages/confluence-macro-browser-overrides/src/office-connector/overrides.js#139-153
				.filter((field) => !['date', 'space'].includes(field.name))
				// Adds a hidden field so it will keep existing values
				.map((field) => {
					if (field.name !== 'page') {
						return field;
					}

					return {
						...field,
						type: 'string',
						isHidden: true,
					} as StringField;
				})
		);
	},
	transformAfter(params) {
		if (!params.page) {
			delete params.page;
		}
		return params;
	},
};
