import { getExperienceTracker } from './getExperienceTracker';

// Returns true is parent experience is running and all relevant subexperiences are either not started or have already succeeded
// all experiences should have a contentId attribute - hasHealthyRunningExperience requires that to effectively operate
export const hasHealthyRunningExperience = ({
	parentExperience,
	subExperiences,
}: {
	parentExperience: string;
	subExperiences?: string[];
}) => {
	const tracker = getExperienceTracker();
	const parentExperienceState = tracker.getExperienceState(parentExperience);
	const isParentExperienceRunning = parentExperienceState && !parentExperienceState.hasStopped;

	if (!isParentExperienceRunning) return false;

	const parentExperienceContentId = String(parentExperienceState.attributes?.contentId);
	return areSubExperiencesHealthy({ parentExperienceContentId, subExperiences });
};

const areSubExperiencesHealthy = ({
	parentExperienceContentId,
	subExperiences = [],
}: {
	parentExperienceContentId?: string;
	subExperiences?: string[];
}) => {
	const tracker = getExperienceTracker();

	// Validate all subExperiences are healthy. If any experience is unhealthy, `every` will immediately return false, otherwise true
	return subExperiences.every((subExperience) => {
		const subExperienceState = tracker.getExperienceState(subExperience);
		// Subexperience has not run, so parent is still healthy
		if (!subExperienceState) return true;

		// Subexperience has not run for current parentExperience's contentId, so parentExperience is still healthy
		const isSubExperienceForDifferentContent =
			parentExperienceContentId !== subExperienceState.attributes?.contentId;

		if (isSubExperienceForDifferentContent) {
			return true;
		}

		const hasSubExperienceCompletedSuccessfully = subExperienceState.stopState === 'taskSuccess';

		if (hasSubExperienceCompletedSuccessfully) return true;

		// Subexperience has run and has not finished successfully, so parentExperience is unhealthy
		return false;
	});
};
