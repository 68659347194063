export const LocalStorageQuery = `
  query LocalStorageQuery($stringKeys: [String]!) {
    localStorage {
      stringValues(keys: $stringKeys) {
        key
        value
      }
    }
  }
`;
