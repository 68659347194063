import type { History, Location, LocationDescriptorObject } from 'history';

import { BridgeClientError } from '..';

export interface HistoryApiProvider {
	history?: History;
	extensionBasePath?: string;
}

export const createExtensionPathParsers = (extensionBasePath: string) => {
	return {
		extensionRouteToFullPath: (extensionRoute: string) =>
			`${extensionBasePath}${extensionRoute ?? ''}`,
		fullPathToExtensionRoute: (fullLocation: string) => {
			const extensionRoute = fullLocation.split(extensionBasePath).pop();
			return extensionRoute ? extensionRoute : '/';
		},
	};
};

export const createHistory = ({
	history,
	extensionBasePath,
}: HistoryApiProvider): History | undefined => {
	if (!history || !extensionBasePath) {
		throw new BridgeClientError('History API is not available within this module.');
	}

	const { extensionRouteToFullPath, fullPathToExtensionRoute } =
		createExtensionPathParsers(extensionBasePath);

	const parseLocation = (
		location: Location<any>,
		parser: typeof extensionRouteToFullPath | typeof fullPathToExtensionRoute,
	): Location<any> => ({
		...location,
		pathname: parser(location.pathname),
	});

	const setNewHistoryLocation = (
		type: 'replace' | 'push',
		pathOrLocation: string | LocationDescriptorObject<any>,
		state?: any,
	) => {
		const setHistory = type === 'push' ? history.push : history.replace;

		if (typeof pathOrLocation === 'string') {
			return setHistory(extensionRouteToFullPath(pathOrLocation), state);
		}

		return setHistory(
			parseLocation({ state, ...pathOrLocation } as Location<any>, extensionRouteToFullPath),
		);
	};

	return {
		...history,
		location: parseLocation(history.location, fullPathToExtensionRoute),
		push(pathOrLocation: string | LocationDescriptorObject<any>, state?: any) {
			setNewHistoryLocation('push', pathOrLocation, state);
		},
		replace(pathOrLocation: string | LocationDescriptorObject<any>, state?: any) {
			setNewHistoryLocation('replace', pathOrLocation, state);
		},
		listen(listener) {
			const unlisten = history.listen((location, action) => {
				listener(parseLocation(location, fullPathToExtensionRoute), action);
			});

			return unlisten;
		},
	};
};
