import React from 'react';

import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';

export const AddAttributesWrapper = ({
	eventUpdater,
	channel,
	children,
}: {
	eventUpdater: (payload: AnalyticsEventPayload) => AnalyticsEventPayload;
	channel?: string;
	children: React.ReactNode;
}) => {
	const onEvent = (event: UIAnalyticsEvent) => {
		event.update(eventUpdater);
	};

	return (
		<AnalyticsListener onEvent={onEvent} channel={channel}>
			{children}
		</AnalyticsListener>
	);
};
