import { preloadHomeWidgets } from './preloadHomeWidgets';

export const preloadSidePanel = ({
	isLicensed,
}: {
	isSpaceAliasFFEnabled?: boolean;
	isLicensed: boolean;
}) => {
	const preloadQueries = [preloadHomeWidgets({ isLicensed })];
	return Promise.all(preloadQueries);
};
