import type { IntlShape } from 'react-intl';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';
import {
	startOfDay,
	addDays,
	addWeeks,
	addMonths,
	compareAsc,
	endOfDay,
	endOfMonth,
	endOfWeek,
	isAfter,
	isBefore,
	isEqual,
	startOfWeek,
	startOfMonth,
	subDays,
	format,
} from 'date-fns';
import type { ApolloError } from 'apollo-client';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import type {
	MetricSettingsContextValueType,
	GranularityType,
} from './admin-center-core/common/MetricSettingsContext';
import type { LineData } from './admin-center-core/base-components/ChartBase/ChartBase-types';
import { numberIsFinite } from './admin-center-core/common/common-utils';
import type { ValidStartDateNames } from './admin-center-core/common/getValidStartDateByName';
import { getValidStartDateByName } from './admin-center-core/common/getValidStartDateByName';
import type { CurrentPagesChartQuery as SiteCurrentPagesChartQueryType } from './metrics/Content/__types__/CurrentPagesChartQuery';
import type { CurrentPagesChartQuery as SpaceCurrentPagesChartQueryType } from './admin-center-space/metrics/Content/__types__/CurrentPagesChartQuery';
import type { InactivePagesChartQuery as SiteInactivePagesChartQueryType } from './metrics/Content/__types__/InactivePagesChartQuery';
import type { InactivePagesChartQuery as SpaceInactivePagesChartQueryType } from './admin-center-space/metrics/Content/__types__/InactivePagesChartQuery';
import type { DeactivatedOwnerPagesChartQuery as SpaceDeactivatedOwnerPagesChartQueryType } from './admin-center-space/metrics/Content/__types__/DeactivatedOwnerPagesChartQuery';
import type { DeactivatedOwnerCountChartQuery as SiteDeactivatedOwnerCountChartQueryType } from './metrics/Content/__types__/DeactivatedOwnerCountChartQuery';
import type { StateMetricLineDataToProcess } from './admin-center-core/common/data-hooks/useStateMetricData';

const i18n = defineMessages({
	tooltipWeekXDescription: {
		id: 'admin-center.chart-tooltip.description.date-weekly',
		defaultMessage: 'Week starting {date}',
		description: 'The time frame for the statistic was last month',
	},
});

export type TimeseriesDataPoint = {
	date: string;
	count: number | undefined;
};

// To be used with state-based metric data (i.e. total content) in order to make them the same type
export type StateChartQueryData = {
	fullGranularityCount: TimeseriesDataPoint[] | null;
	dayBeforeEndDateCount: TimeseriesDataPoint[] | null;
};

type GetLineDataForChartArgs = {
	data: TimeseriesDataPoint[];
	metricSettingsValues: Pick<
		MetricSettingsContextValueType,
		'startDate' | 'endDate' | 'granularity'
	>;
	formatNumber: IntlShape['formatNumber'];
	formatNumberStyle?: 'decimal' | 'percent';
	formatMessage: IntlShape['formatMessage'];
};

// Returns the start date in the given granularity. For example, if the granularity is "WEEK",
// then this will return the start of the week containing the given startDate.
export const getStartDateInGranularity = (startDate: Date, granularity: GranularityType) => {
	if (granularity === 'DAY') {
		return startOfDay(startDate);
	} else if (granularity === 'WEEK') {
		return startOfWeek(startDate, { weekStartsOn: 1 });
	} else {
		// The granularity should be MONTH.
		// However, if it is an unexpected value, then we default to MONTH.
		return startOfMonth(startDate);
	}
};

// Returns whether the provided date is the start date of a granularity.
export const isStartOfGranularity = (date: Date, granularity: GranularityType) => {
	const startOfGranularity = getStartDateInGranularity(date, granularity);
	return isEqual(startOfGranularity, date);
};

// Returns the given date incremented by the given granularity.
export const addGranularity = (date: Date, granularity: GranularityType, increment: number = 1) => {
	if (granularity === 'DAY') {
		return addDays(date, increment);
	} else if (granularity === 'WEEK') {
		return addWeeks(date, increment);
	} else {
		// The granularity should be MONTH.
		// However, if it is an unexpected value, then we default to MONTH.
		return addMonths(date, increment);
	}
};

// Returns an accessor that can be used to iterate over the given data in sorted order.
// It contains helpful utility functions to simplify consuming code.
const getSortedDataAccessor = (data: { date: string; count: number | undefined }[]) => {
	// Sorts the input data into a new array in order to avoid O(n^2) time complexity.
	// In theory, the data is already pre-sorted, so this should be a no-op that runs in O(n) time.
	// In practice, this is a safeguard in case the data is not pre-sorted and may run in O(n*log(n)) time.
	const sortedData = data.slice().sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)));
	let i = 0;

	return {
		increment: () => i++,
		currentDate: () => new Date(sortedData[i].date),
		currentCount: () => sortedData[i].count,
		currentIsBefore: (date: Date) =>
			i < sortedData.length && isBefore(new Date(sortedData[i].date), date),
		currentIsEqual: (date: Date) =>
			i < sortedData.length && isEqual(new Date(sortedData[i].date), date),
	};
};

export const endOfGranularity = ({
	granularity,
	date,
}: {
	granularity: GranularityType;
	date: Date;
}) => {
	if (granularity === 'DAY') {
		return startOfDay(endOfDay(date));
	} else if (granularity === 'WEEK') {
		return startOfDay(
			endOfWeek(date, {
				weekStartsOn: 1,
			}),
		);
	} else {
		// The granularity should be MONTH.
		// However, if it is an unexpected value, then we default to MONTH.
		return startOfDay(endOfMonth(date));
	}
};

export const getXDescription = ({
	granularity,
	date,
	formatMessage,
}: {
	date: Date;
	granularity: GranularityType;
	formatMessage: IntlShape['formatMessage'];
}) => {
	switch (granularity) {
		case 'DAY':
			return format(date, 'EEE MMM dd, yyyy');
		case 'WEEK':
			// Show date without the day part
			return formatMessage(i18n.tooltipWeekXDescription, {
				date: format(date, 'MMM dd, yyyy'),
			});
		case 'MONTH':
		default:
			// The granularity should be MONTH.
			// However, if it is an unexpected value, then we default to MONTH.
			return format(date, 'LLL yyyy');
	}
};

const getYDescription = ({
	yValue,
	formatNumber,
	formatNumberStyle,
}: {
	yValue: number | undefined;
	formatNumber: IntlShape['formatNumber'];
	formatNumberStyle?: 'decimal' | 'percent';
}) => {
	return numberIsFinite(yValue)
		? formatNumber(formatNumberStyle === 'percent' ? yValue / 100 : yValue, {
				style: formatNumberStyle,
			})
		: undefined;
};

// This function returns the data in the format required by the ChartBase component. It does this
// based on the given data, start date, end date, and granularity. Importantly, it fills in any missing
// dates with a count of undefined.
const getLineDataForChart = ({
	data,
	metricSettingsValues: { startDate, endDate, granularity },
	formatNumber,
	formatNumberStyle,
	formatMessage,
}: GetLineDataForChartArgs): LineData => {
	if (startDate > endDate) {
		return [];
	}

	const sortedDataAccessor = getSortedDataAccessor(data);

	const lineData: LineData = [];
	const startDateInGranularity = getStartDateInGranularity(startDate, granularity);

	let currentExpectedDate: Date | undefined = startDateInGranularity;

	while (!!currentExpectedDate && currentExpectedDate < endDate) {
		// Increments the sortedDataAccessor until it no longer points to a value that is too small
		// or until the end of the array is reached. In theory, this is not necessary because the
		// actual data returned by the API should match expectations. However, making that assumption
		// is dangerous and could cause an infinite loop.
		//
		// This runs in O(n) time, but is independent of the outer loop, so the overall time complexity of the loop is still O(n).
		while (sortedDataAccessor.currentIsBefore(currentExpectedDate)) {
			if (!sortedDataAccessor.currentIsBefore(startDateInGranularity)) {
				// Adds an entry to lineData based on the given data if it is within the expected date range, but doesn't
				// match any of the expected dates. In theory, this should not happen. However, if it does, then adding an
				// entry here ensures we fail safely and don't omit the given data.
				lineData.push({
					x: sortedDataAccessor.currentDate(),
					y: sortedDataAccessor.currentCount(),
					// We don't know what a good description is, because the data point is unexpected. So, we just use the date.
					xDescription: `${sortedDataAccessor.currentDate().toDateString()}`,
					yDescription: getYDescription({
						yValue: sortedDataAccessor.currentCount(),
						formatNumber,
						formatNumberStyle,
					}),
					// We don't know if the data point is in progress, because the data point is unexpected. So, we just use undefined.
					isInProgress: undefined,
				});
			}

			// Increments the sortedDataAccessor.
			sortedDataAccessor.increment();
		}

		// Gets the current expected end granularity.
		const currentExpectedEndOfGranularity = endOfGranularity({
			granularity,
			date: currentExpectedDate,
		});

		// Gets if the current data point is in progress.
		const dayBeforeEndDate = subDays(endDate, 1);
		const isInProgress = isAfter(currentExpectedEndOfGranularity, dayBeforeEndDate);
		// Gets the xDescription for the current expected data point.
		const xDescription = getXDescription({
			granularity,
			date: currentExpectedDate,
			formatMessage,
		});

		if (sortedDataAccessor.currentIsEqual(currentExpectedDate)) {
			// Adds an entry to lineData with the matching count if found.
			lineData.push({
				x: currentExpectedDate,
				y: sortedDataAccessor.currentCount(),
				xDescription,
				yDescription: getYDescription({
					yValue: sortedDataAccessor.currentCount(),
					formatNumber,
					formatNumberStyle,
				}),
				isInProgress,
			});
			// Increments the sortedDataAccessor to avoid adding the same value again.
			sortedDataAccessor.increment();
		} else {
			// Adds an entry to lineData with an undefined count if no match was found.
			// This is the case for when isInProgress is true
			lineData.push({
				x: currentExpectedDate,
				y: undefined,
				xDescription,
				yDescription: undefined,
				isInProgress,
			});
		}

		// Increments the currentExpectedDate based on the granularity.
		currentExpectedDate = addGranularity(currentExpectedDate, granularity);
	}

	return lineData;
};

export type GetLineDataForChartEventMetricArgs = GetLineDataForChartArgs;

// Wrapper function for getLineDataForChart() specifically for event-based metrics
export const getLineDataForChartEventMetric = (args: GetLineDataForChartEventMetricArgs) =>
	getLineDataForChart(args);

export type GetLineDataForChartStateMetricArgs = {
	fullGranularityData: TimeseriesDataPoint[];
	dayBeforeEndDateData: TimeseriesDataPoint | undefined;
	metricSettingsValues: MetricSettingsContextValueType;
	formatNumber: IntlShape['formatNumber'];
	formatNumberStyle?: 'decimal' | 'percent';
	formatMessage: IntlShape['formatMessage'];
};

// Wrapper function for getLineDataForChart() specifically for state metrics.
// Returns processed LineData for combined full and partial granularities.
export const getLineDataForChartStateMetric = ({
	fullGranularityData,
	dayBeforeEndDateData,
	metricSettingsValues: {
		startDate,
		lastFullGranularityEndDate,
		dayBeforeEndDate,
		endDate,
		granularity,
	},
	formatNumber,
	formatNumberStyle,
	formatMessage,
}: GetLineDataForChartStateMetricArgs) => {
	// Uses getLineDataForChart() to get LineData for full granularity data.
	const fullGranularityLineData = getLineDataForChart({
		data: fullGranularityData,
		metricSettingsValues: {
			startDate,
			endDate: lastFullGranularityEndDate,
			granularity,
		},
		formatNumber,
		formatNumberStyle,
		formatMessage,
	});
	// If granularity = DAY, then there are no partial granularities, so we ignore dayBeforeEndDateData.
	// Else if endDate is the start of a granularity, then again, there are no partial granularities so we ignore dayBeforeEndDateData.
	if (granularity === 'DAY' || isStartOfGranularity(endDate, granularity)) {
		return fullGranularityLineData;
	}
	// We omit any data whose start date is equal to or after lastFullGranularityEndDate.
	const totalLineData = fullGranularityLineData.filter((dataPoint) =>
		isBefore(dataPoint.x, lastFullGranularityEndDate),
	);

	// Append dayBeforeEndDate's daily granularity count to the full granularity data.
	// Note: Even if dayBeforeEndDateData is undefined, we still append undefined data for dayBeforeEndDate to keep chart's x-axis accurate.
	totalLineData.push({
		// lastFullGranularityEndDate is used instead of dayBeforeEndDateData.date because this is where we want the data point to be placed on the chart.
		// The xDescription will be set to dayBeforeEndDateData.date, correctly representing the data point's actual date.
		x: lastFullGranularityEndDate,
		y: dayBeforeEndDateData?.count,
		xDescription: getXDescription({
			granularity,
			date: getStartDateInGranularity(dayBeforeEndDate, granularity),
			formatMessage,
		}),
		yDescription: getYDescription({
			yValue: dayBeforeEndDateData?.count,
			formatNumber,
			formatNumberStyle,
		}),
		isInProgress: true,
	});

	return totalLineData;
};

// Adds together the given list of numbers. Importantly, if any of the numbers are undefined, then the result is undefined.
export const sumPossiblyUndefinedNumbers = (numbers: (number | undefined)[]) =>
	numbers.reduce((a, b) => (numberIsFinite(a) && numberIsFinite(b) ? a + b : undefined), 0);

// Finds the data point in the provided TimeseriesDataPoint[] with the corresponding date; returns undefined if not found.
// Note: This is useful for finding data points by date rather than array index, in the event points get filtered/are missing.
export const findNodeDataPointByDate = (
	metricData: TimeseriesDataPoint[] | undefined,
	date: Date,
): TimeseriesDataPoint | undefined => {
	return (metricData || []).find((datapoint) => isEqual(new Date(datapoint.date), date));
};

// Filters out data points with corresponding dates prior to the given valid start date in the provided granularity.
export const filterInvalidDataPointsByValidStartDate = ({
	data,
	validStartDateName,
	granularity,
}: {
	data: TimeseriesDataPoint[];
	validStartDateName: ValidStartDateNames;
	granularity: GranularityType;
}) => {
	const validStartDate = getValidStartDateByName(validStartDateName);
	if (validStartDate && isStartOfGranularity(validStartDate, granularity)) {
		/**
		 * If validStartDate is the start of a granularity,
		 * then we include data points whose date is equal or after validStartDate.
		 *
		 * For example, suppose granularity = DAY (i.e. validStartDate is the start of a granularity).
		 * Since validStartDate is the first date where data is valid, all data points are valid from this date onward.
		 */
		return data.filter((item) => !isBefore(new Date(item.date), validStartDate));
	} else if (validStartDate) {
		/**
		 * Else if validStartDate is the middle/end of a granularity,
		 * then we include data points whose date is strictly after validStartDate.
		 *
		 * For example, suppose granularity = WEEK (validStartDate is a Thursday).
		 * It follows that Monday through Wednesday's data points are invalid, thus invalidating the full week's data point.
		 * The following week after validStartDate is the first valid full week data point.
		 */
		return data.filter((item) => isAfter(new Date(item.date), validStartDate));
	} else {
		// If validStartDate is not found, then return the unfiltered data.
		// Note: This case shouldn't happen, largely just a type check for getValidStartDateByName().
		return data;
	}
};

export const filterInvalidDatapointsByActivationDate = ({
	activationDate,
	data,
}: {
	activationDate?: Date;
	data: TimeseriesDataPoint[];
}) =>
	activationDate
		? data.filter(
				// !isBefore is used instead of isAfter because we don't want to remove the item if the dates are equal.
				(item) => !isBefore(new Date(item.date), activationDate),
			)
		: data;

// Divides two datasets together in order to obtain 1 dataset representing a percentage from both
// ex: divide inactive pages data by total pages to get % of how many pages are inactive
export const getPercentDatasetFromTwoDatasets = (
	numeratorData: TimeseriesDataPoint[],
	denominatorData: TimeseriesDataPoint[],
): Array<TimeseriesDataPoint> => {
	return (numeratorData || []).reduce(
		(accum: TimeseriesDataPoint[], numeratorItem: TimeseriesDataPoint) => {
			// filter out undefined values
			const denominatorMatchingItem = findNodeDataPointByDate(
				denominatorData,
				new Date(numeratorItem?.date),
			);

			// We check that dataset denominatorMatchingItem.count doesn't equal zero since
			// a zero denominator would result in a division by zero error.
			return typeof denominatorMatchingItem?.count === 'number' &&
				denominatorMatchingItem?.count > 0 &&
				typeof numeratorItem?.count === 'number'
				? [
						...accum,
						{
							date: numeratorItem?.date,
							count: (numeratorItem?.count / denominatorMatchingItem?.count) * 100,
						},
					]
				: accum;
		},
		[],
	);
};

/* This function is meant to be used with STATE-BASED data to divide 2 datasets
 * in order to obtain a single dataset representing a percentage
 */
export const getStateMetricDataByPercentage = ({
	denominatorData,
	numeratorData,
	formatMessage,
	countLabel,
	granularity,
	validStartDate,
}: {
	denominatorData: StateChartQueryData;
	numeratorData: StateChartQueryData;
	formatMessage: IntlShape['formatMessage'];
	countLabel: MessageDescriptor;
	granularity: GranularityType;
	validStartDate?: ValidStartDateNames;
}): StateMetricLineDataToProcess[] => {
	const combinedFullGranularityNodes = getPercentDatasetFromTwoDatasets(
		numeratorData?.fullGranularityCount || [],
		denominatorData?.fullGranularityCount || [],
	);

	const combinedDayBeforeEndDateNodes = getPercentDatasetFromTwoDatasets(
		numeratorData?.dayBeforeEndDateCount || [],
		denominatorData?.dayBeforeEndDateCount || [],
	);

	return [
		{
			description: formatMessage(countLabel),
			fullGranularityData:
				validStartDate === null || validStartDate === undefined
					? combinedFullGranularityNodes
					: filterInvalidDataPointsByValidStartDate({
							data: combinedFullGranularityNodes,
							granularity,
							validStartDateName: validStartDate,
						}),
			dayBeforeEndDateData:
				validStartDate === null || validStartDate === undefined
					? combinedDayBeforeEndDateNodes
					: filterInvalidDataPointsByValidStartDate({
							data: combinedDayBeforeEndDateNodes,
							granularity,
							validStartDateName: validStartDate,
						}),
		},
	];
};

export const mapCurrentPagesDataToGeneric = (
	currentPagesData: SiteCurrentPagesChartQueryType | SpaceCurrentPagesChartQueryType | undefined,
): StateChartQueryData => {
	return {
		fullGranularityCount: currentPagesData?.fullGranularityCurrentPagesCount?.nodes || [],
		dayBeforeEndDateCount: currentPagesData?.dayBeforeEndDateCurrentPagesCount?.nodes || [],
	};
};

export const mapInactivePagesDataToGeneric = (
	inactivePagesData: SiteInactivePagesChartQueryType | SpaceInactivePagesChartQueryType | undefined,
): StateChartQueryData => {
	return {
		fullGranularityCount: inactivePagesData?.fullGranularityInactivePagesCount?.nodes || [],
		dayBeforeEndDateCount: inactivePagesData?.dayBeforeEndDateInactivePagesCount?.nodes || [],
	};
};

export const mapDeactivatedOwnerPagesDataToGeneric = (
	deactivatedOwnerPagesData:
		| SpaceDeactivatedOwnerPagesChartQueryType
		| SiteDeactivatedOwnerCountChartQueryType
		| undefined,
): StateChartQueryData => {
	return {
		fullGranularityCount:
			deactivatedOwnerPagesData?.fullGranularityDeactivatedOwnerPagesCount?.nodes || [],
		dayBeforeEndDateCount:
			deactivatedOwnerPagesData?.dayBeforeEndDateDeactivatedOwnerPagesCount?.nodes || [],
	};
};

const filterExpectedErrors = (errors: ApolloError[]) => {
	const unexpectedErrors = (errors || []).reduce(
		(unexpectedErrorAccum: ApolloError[], error: ApolloError) => {
			if (
				isUnauthorizedError(error) ||
				error.graphQLErrors.some((err) =>
					err.message.includes('Not permitted to view external collaborators'),
				)
			) {
				markErrorAsHandled(error);
				return unexpectedErrorAccum;
			} else {
				return [...unexpectedErrorAccum, error];
			}
		},
		[],
	);

	return unexpectedErrors.length > 0 ? unexpectedErrors : undefined;
};

const isActualError = (item: ApolloError | undefined): item is ApolloError => {
	return !!item;
};

const filterUndefinedErrors = (errors: (ApolloError | undefined)[]): ApolloError[] =>
	errors.filter(isActualError);

// This function processes errors in three ways:
// 1. Takes possible error values and filters out undefined values (filterUndefinedErrors)
// 2. Turns any remaining errors into an array of ApolloError objects (filterUndefinedErrors)
// 3. Filters out expected errors since we only need to report unexpected errors (filterExpectedErrors)
export const processErrors = (errors: (ApolloError | undefined)[]): ApolloError[] | undefined => {
	const actualErrors = filterUndefinedErrors(errors);

	return filterExpectedErrors(actualErrors);
};
