import React from 'react';
import { EnvironmentContext, MetricsContextProvider } from '../context';
import { type UseExtensionQueryHookOptions } from '../web-client';
import { type AnalyticsWebClient, ForgeUIAnalyticsListener } from '../analytics';
import { type ProductEnvironment } from '@atlassian/forge-ui-types';

export type ForgeUIExtensionQueryOptions = UseExtensionQueryHookOptions;

export const ForgeUIExtensionPointProvider = ({
	analyticsWebClient,
	environment,
	product,
	page,
	children,
}: {
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	environment: ProductEnvironment;
	product: string;
	page: string;
	children: React.ReactNode;
}) => {
	return (
		<EnvironmentContext.Provider value={environment}>
			<MetricsContextProvider value={{ product, page }}>
				<ForgeUIAnalyticsListener
					client={analyticsWebClient}
					commonAttributes={{
						moduleType: page,
					}}
				>
					{children}
				</ForgeUIAnalyticsListener>
			</MetricsContextProvider>
		</EnvironmentContext.Provider>
	);
};
