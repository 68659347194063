export const StatsigBootstrapQueryRaw = `
  query StatsigBootstrapQueryRaw {
    frontendExperimentsBootstrap {
      value
    }
    frontendDynamicConfigsBootstrap {
      value
    }
  }
`;
