import { query } from '@confluence/query-preloader-tools';

import { LegacyBridgeQuery } from '../src/LegacyBridgeQuery.graphql';
import type {
	LegacyBridgeQuery as LegacyBridgeQueryType,
	LegacyBridgeQueryVariables,
} from '../src/__types__/LegacyBridgeQuery';

export async function preloadLegacyBridge({ contentId, isBlog }) {
	if (!contentId) {
		return;
	}

	return query<LegacyBridgeQueryType, LegacyBridgeQueryVariables>({
		query: LegacyBridgeQuery,
		variables: { contentId, isBlog },
		errorPolicy: 'all',
	});
}
