import uuid from 'uuid/v4';

import type { NodeType, Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import type {
	EditorState,
	ReadonlyTransaction,
	Transaction,
} from '@atlaskit/editor-prosemirror/state';
import { Mapping, ReplaceAroundStep, ReplaceStep } from '@atlaskit/editor-prosemirror/transform';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';
import { convertMarkdownToProsemirror } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';

import {
	createDiffObjectsFromFragments,
	getDiffObjectsForParagraph,
} from '../../utils/diff-match-patch/utils';
import type { DiffObject, IgnoredRange, ParagraphChunk } from '../../utils/diff-match-patch/utils';

import type { Suggestion } from './api';
import type { AISpellingGrammarPluginState, ProactiveAIBlock, ProactiveAISentence } from './states';

/**
 * This is a generalised collection of explicit included node types which proactive AI will operate on.
 * Types not listed here will be excluded by default.
 * If you want proactive ai to look inside a node then it's type must be listed here, otherwise the node will be skipped.
 * This is to avoid unneccessary searching through nodes which shouldn't have spelling + grammar run on them.
 */
export const getIncludedProactiveAINodeTypes = ({ schema: { nodes } }: EditorState) =>
	new Set<NodeType>([
		nodes.blockquote,
		nodes.bulletList,
		nodes.caption,
		nodes.decisionItem,
		nodes.decisionList,
		nodes.expand,
		nodes.heading,
		nodes.layoutColumn,
		nodes.layoutSection,
		nodes.listItem,
		nodes.nestedExpand,
		nodes.orderedList,
		nodes.panel,
		nodes.paragraph,
		nodes.table,
		nodes.tableRow,
		nodes.tableCell,
		nodes.tableHeader,
		nodes.taskItem,
		nodes.taskList,
	]);

/**
 * This is a refined collection of node types which will be used to determine whether proactive ai blocks will be created
 * to keep track of the state nodes which have S+G run over them.
 */
export const getProactiveAIBlockNodeTypes = ({ schema: { nodes } }: EditorState) =>
	new Set<NodeType>([nodes.decisionItem, nodes.heading, nodes.paragraph, nodes.taskItem]);

const getContextualNodeTypes = ({ nodes }: Schema) =>
	new Set<NodeType>([nodes.heading, nodes.taskItem, nodes.decisionItem]);

const getContextualParentNodeTypes = ({ nodes }: Schema) =>
	new Set<NodeType>([nodes.listItem, nodes.tableHeader, nodes.blockquote]);

export const isFullStopOmittedInContainerType = (schema: Schema, containerType: NodeType) => {
	if (
		getContextualNodeTypes(schema).has(containerType) ||
		getContextualParentNodeTypes(schema).has(containerType)
	) {
		return true;
	}
	return false;
};

export const getContainerType = (schema: Schema, node: PMNode, parent: PMNode | null) => {
	if (getContextualNodeTypes(schema).has(node.type)) {
		return node.type;
	}

	if (
		node.type === schema.nodes.paragraph &&
		parent &&
		getContextualParentNodeTypes(schema).has(parent.type)
	) {
		return parent.type;
	}

	return node.type;
};

/**
 * This is a collection of mark types which proactive AI will ignore suggestions for.
 */
const getIgnoredMarkRangesPredicate =
	({ schema: { marks } }: EditorState) =>
	(node: PMNode) => {
		if (node.isText) {
			if (node.marks.some((mark) => mark.type === marks.code)) {
				return true;
			}
			if (node.marks.some((mark) => mark.type === marks.link && node.text === mark.attrs?.href)) {
				return true;
			}
		}
		return false;
	};

export const getDiffObjectsForSuggestions = ({
	editorView,
	suggestions,
	paragraphChunkMap,
}: {
	editorView: EditorView;
	suggestions: Suggestion[];
	paragraphChunkMap: Map<string, ParagraphChunk>;
}) => {
	const chunksDiffObjectsById = new Map<string, DiffObject[]>();

	if (fg('platform_editor_ai_proactive_ai_md_plus')) {
		suggestions.forEach((suggestionData) => {
			const { chunkId, suggestion, chunkIdMap } = suggestionData;
			const chunk = paragraphChunkMap.get(chunkId);
			if (chunk) {
				const {
					state: { schema, doc },
				} = editorView;
				const { pmFragment } = convertMarkdownToProsemirror({
					markdown: suggestion,
					schema,
					idMap: chunkIdMap || {},
					featureToggles: {
						markdownPlus: true,
					},
				});

				const diffs = createDiffObjectsFromFragments({
					id: chunkId,
					original: Fragment.from(doc.cut(chunk.from, chunk.to)),
					corrected: pmFragment,
					schema,
					offset: chunk.from,
					containerType: chunk.containerType,
				});

				chunksDiffObjectsById.set(
					chunkId,
					diffs.filter((diff) => diff.type === 'REPLACEMENT' && !diff.isWhiteSpace),
				);
			}
		});
	} else {
		suggestions.forEach((suggestionData) => {
			const { chunkId, suggestion } = suggestionData;
			const chunk = paragraphChunkMap.get(chunkId);
			if (chunk) {
				chunksDiffObjectsById.set(
					chunkId,
					getDiffObjectsForParagraph({
						originalParagraph: chunk,
						correctedParagraphText: suggestion || chunk.text,
						isDiffHiddenInIgnoredRanges: true,
						editorView,
					}).filter((diff) => diff.type === 'REPLACEMENT' && !diff.isWhiteSpace),
				);
			}
		});
	}
	return chunksDiffObjectsById;
};

export function findProactiveAIBlockToUpdate(
	tr: Transaction | ReadonlyTransaction,
	pluginState: AISpellingGrammarPluginState,
	newEditorState: EditorState,
) {
	const { proactiveAIBlocks, isSpellingAndGrammarEnabled, splitParagraphIntoSentences } =
		pluginState;
	const shouldIgnoreChanges =
		!isSpellingAndGrammarEnabled ||
		tr.getMeta('isRemote') ||
		tr.getMeta('replaceDocument') ||
		tr.getMeta('suggestionAccepted') ||
		tr.getMeta('isAiContentTransformation');
	/**
	 * Don't register block with Document SG cheker when;
	 * - Realtime S+G check is enabled
	 * - AI generated content.
	 * In all other cases we want to register block with Document SG Checker.
	 */
	const needSGCheckForDocChecker =
		!isSpellingAndGrammarEnabled &&
		!tr.getMeta('suggestionAccepted') &&
		!tr.getMeta('isAiContentTransformation');

	const nodeTypes = getProactiveAIBlockNodeTypes(newEditorState);
	const includedNodeTypes = getIncludedProactiveAINodeTypes(newEditorState);
	const ignoredMarkRangesPredicate = getIgnoredMarkRangesPredicate(newEditorState);

	/**
	 * Transaction is collection of different steps.
	 * Each step can update different part of the doc.
	 * So we need to go through each step to find block updated/added by each step.
	 * For that, we need to look at doc after apply step.
	 *
	 * That's why here we are creating list of docs created after applying each step.
	 * tr.docs contain list that has doc created before each step.
	 * tr.doc is final version of doc created after applying last step.
	 *
	 * We need doc created after applying step.
	 * So for doc after applying first step (step 0) will be trDocsWithFinalDoc[1].
	 */
	const trDocsWithFinalDoc = tr.docs.concat([tr.doc]);
	const replaceStepsWithDocs: Array<{ step: ReplaceStep | ReplaceAroundStep; doc: PMNode }> = [];
	tr.steps.forEach((step, index) => {
		const stepDoc = trDocsWithFinalDoc[index + 1];
		if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
			replaceStepsWithDocs.push({ step, doc: stepDoc });
		}
	});

	if (proactiveAIBlocks && replaceStepsWithDocs.length > 0) {
		let updatedProactiveAIBlock: Array<ProactiveAIBlock> = Array.from(proactiveAIBlocks);

		/**
		 * Iterate through each step and step doc to find blocks to replace or add new blocks.
		 */
		replaceStepsWithDocs.forEach(({ step, doc }) => {
			const newProactiveAIBlocks: Array<ProactiveAIBlock> = [];
			const nodeMap: WeakMap<PMNode, ProactiveAIBlock> = new WeakMap();
			const mapping = new Mapping();
			const stepMap = step.getMap();
			mapping.appendMap(stepMap);

			/**
			 * First find new nodes that needs to be checked against
			 * proactive AI prompt.
			 */
			stepMap.forEach((oldStart, oldEnd, newStart, newEnd) => {
				doc.nodesBetween(newStart, Math.min(newEnd, doc.content.size), (node, pos, parent) => {
					if (
						nodeTypes.has(node.type) &&
						/**
						 * When new table is created, tableCell nodes created for first row (non header one)
						 * is reused for other rows.
						 * So only checking "newProactiveAIBlock.node === node" condition is not enough.
						 * We need to reuse same node to create block (as it has been reused in all rows)
						 * 	if position is different.
						 */
						!(nodeMap.get(node)?.from === pos + 1)
					) {
						const ignoredRanges = getIgnoredRanges({ node, ignoredMarkRangesPredicate });

						const from = pos + 1,
							to = pos + node.nodeSize - 1;

						const containerType = getContainerType(newEditorState.schema, node, parent);
						const newBlock = {
							id: uuid(),
							from,
							to,
							text: node.textContent,
							nodeTypeName: node.type.name,
							/**
							 * If it's remote transaction, then we don't want new blocks
							 * created from that transaction to be checked for S+G,
							 * because they must be checked where it's originated.
							 *
							 * Also when document is loaded in confluence, collab service
							 *  fetches document and fires transaction to replace whole document.
							 * In that case as well, we don't want to trigger S+G for whole document.
							 */
							needSpellingAndGrammarCheck: !shouldIgnoreChanges,
							needSGCheckForDocChecker,
							ignoredRanges,
							sentences: splitParagraphIntoSentences
								? getSentencesFromNode({
										node,
										paragraphStartPos: from,
										needSpellingAndGrammarCheck: !shouldIgnoreChanges,
										needSGCheckForDocChecker,
										paragraphIgnoredRanges: ignoredRanges,
										containerType,
									})
								: undefined,
							containerType,
						};
						newProactiveAIBlocks.push(newBlock);
						nodeMap.set(node, newBlock);
						return false;
					}
					return includedNodeTypes.has(node.type);
				});
			});

			/**
			 * Now find existing range of proactiveAIBlock that needs to be
			 * replaced with new blocks found.
			 *
			 * ASSUMPTIONS:
			 * 1. We have assumed here that new proactiveAIBlocks will be in
			 *    continous range.
			 *    For example: New 4 blocks will be from positions 350 to 550.
			 *                 Let's say there are 3 blocks before 350 and
			 *                 2 blocks after 550. And there were 2 blocks in 350 to 550 before.
			 *                 That means existing Array is
			 *                 [b1, b2, b3, -- b4, b5 --, b6, b7 )], affected blocks are b4, b5.
			 *                 So they will be replaced with new 3 blocks.
			 *                 Final array will look like
			 *                 [old_b1, old_b2, old_b3, -- new_b4, new_b5, new_b6 --, old_b6, old_b7 )]
			 */
			let startIndex = -1,
				endIndex = -1;
			for (let index = 0; index < updatedProactiveAIBlock.length; index++) {
				const block = updatedProactiveAIBlock[index];
				// If step range is before first block then it's new blocks at the beginning of the document.
				// We know that exact placement of new blocks, so breaking loop here.
				if (index === 0 && step.from < block.from && step.to < block.to) {
					startIndex = 0;
					endIndex = 0;
					break;
				}

				/**
				 * When step changes are across multiple nodes;
				 * we will find startIndex and endIndex here.
				 */
				if (
					(block.from <= step.from && block.to >= step.from) ||
					(block.from <= step.to && block.to >= step.to)
				) {
					if (startIndex === -1) {
						startIndex = index;
						endIndex = index + 1;
					} else if (startIndex > -1) {
						endIndex = index + 1;
					}
				}

				/**
				 * or if block is within step range.
				 * but when step changes are across multiple blocks, this condition will be
				 * 	true for all the middle blocks except first and last one.
				 * So can't break here.
				 */
				if (block.from >= step.from && block.to <= step.to) {
					if (startIndex === -1) {
						startIndex = index;
						endIndex = index + 1;
					} else if (startIndex > -1) {
						endIndex = index + 1;
					}
				}

				// if range is between current and next block,
				//	thus neigher current or next block should be replaced.
				// We know that exact placement of new blocks, so breaking loop here.
				if (index < updatedProactiveAIBlock.length - 1) {
					const nextBlock = updatedProactiveAIBlock[index + 1];
					if (step.from > block.to && step.to < nextBlock.from) {
						/**
						 * We want to add new block after current block.
						 * So startIndex has to be "index + 1".
						 * Also we don't want to delete any block.
						 * So expression "endIndex - startIndex" must be 0.
						 * So setting endIndex to "index + 1".
						 */
						startIndex = index + 1;
						endIndex = index + 1;
						break;
					}
				}

				// if step range is outside last block then it's new blocks
				// This is anyway last block, so no need to break;
				if (
					index === updatedProactiveAIBlock.length - 1 &&
					step.from > block.to &&
					step.to > block.to
				) {
					startIndex = index + 1;
					endIndex = index + 1;
				}
			}

			if (startIndex > -1 && endIndex > -1) {
				const deletedBlocks = updatedProactiveAIBlock.splice(
					startIndex,
					endIndex - startIndex,
					...newProactiveAIBlocks,
				);

				/**
				 * Here we will keep diffObjects of blocks.
				 *
				 * First remove all the diffObjects of deleted blocks that has been affected by step.
				 * Then map non affected diffObjects positions.
				 * Then traverse through all the new blocks and
				 * 	assign diffObjects block whose position is wihin block boundary.
				 */
				if (deletedBlocks.length > 0 && newProactiveAIBlocks.length > 0) {
					let deletedBlocksDiffObjects = getDiffObjectsFromBlocks(
						deletedBlocks,
						splitParagraphIntoSentences,
					);
					deletedBlocksDiffObjects = filterAndMapDiffObjectsByStep(
						deletedBlocksDiffObjects,
						step,
						mapping,
					);

					const blocksOrSentences = splitParagraphIntoSentences
						? getBlocksSentences(newProactiveAIBlocks)
						: newProactiveAIBlocks;
					setDiffObjectsInNewBlocksOrSentences(blocksOrSentences, deletedBlocksDiffObjects);
				}
			}

			updatedProactiveAIBlock = updatedProactiveAIBlock.map((block, i) => {
				// If there were new blocks and they were inserted, then we must ensure we skip over them and not attempt to
				// remap their positions.
				if (
					!!newProactiveAIBlocks.length &&
					i >= startIndex &&
					i < startIndex + newProactiveAIBlocks.length
				) {
					return block;
				}

				// Lastly update positions of blocks not affected by step with step's mapping.
				// So that when we apply next step we have updated positions.
				return updateBlockPositions(mapping, block);
			}) as ProactiveAIBlock[];
		});

		return {
			originProactiveAIBlock: proactiveAIBlocks,
			updatedProactiveAIBlock,
		};
	}

	return {
		originProactiveAIBlock: proactiveAIBlocks,
		updatedProactiveAIBlock: proactiveAIBlocks,
	};
}

function setDiffObjectsInNewBlocksOrSentences(
	blockOrSentences: ProactiveAIBlock[] | ProactiveAISentence[],
	deletedBlocksDiffObjects: DiffObject[],
) {
	blockOrSentences.map((blockOrSentence) => {
		blockOrSentence.diffObjects = deletedBlocksDiffObjects.filter(
			(diffObject) =>
				blockOrSentence.from <= diffObject.from && diffObject.to <= blockOrSentence.to,
		);
	});
}

function filterAndMapDiffObjectsByStep(
	diffObjects: DiffObject[],
	step: ReplaceStep | ReplaceAroundStep,
	mapping: Mapping,
) {
	return diffObjects
		?.filter((diffObject) => {
			const { from, to } = diffObject;
			// If diffObject is overlapping with changes then discard it.
			if ((from >= step.from && from <= step.to) || (to >= step.from && to <= step.to)) {
				return false;
			}
			return true;
		})
		.map((diffObject) => {
			diffObject.from = mapping.map(diffObject.from);
			diffObject.to = mapping.map(diffObject.to);
			return diffObject;
		});
}

export function updateBlockPositions(mapping: Mapping, block: ProactiveAIBlock): ProactiveAIBlock {
	return {
		id: block.id,
		text: block.text,
		ignoredRanges: block.ignoredRanges,
		nodeTypeName: block.nodeTypeName,
		needSpellingAndGrammarCheck: block.needSpellingAndGrammarCheck,
		needSGCheckForDocChecker: block.needSGCheckForDocChecker,
		metadata: block.metadata,
		from: mapping.map(block.from),
		to: mapping.map(block.to),
		diffObjects: updateDiffObjectsPositions(mapping, block.diffObjects),
		containerType: block.containerType,
		sentences: block.sentences?.map((sentence) => ({
			id: sentence.id,
			text: sentence.text,
			ignoredRanges: sentence.ignoredRanges,
			size: sentence.size,
			needSpellingAndGrammarCheck: sentence.needSpellingAndGrammarCheck,
			needSGCheckForDocChecker: sentence.needSGCheckForDocChecker,
			metadata: sentence.metadata,
			from: mapping.map(sentence.from),
			to: mapping.map(sentence.to),
			diffObjects: updateDiffObjectsPositions(mapping, sentence.diffObjects),
			containerType: sentence.containerType,
		})),
	};
}

export function updateDiffObjectsPositions(mapping: Mapping, diffObjects?: DiffObject[]) {
	return diffObjects?.map((diffObject) => ({
		id: diffObject.id,
		type: diffObject.type,
		text: diffObject.text,
		replacement: diffObject.replacement,
		originalText: diffObject.originalText,
		from: mapping.map(diffObject.from),
		to: mapping.map(diffObject.to),
	}));
}

const getIgnoredRanges = ({
	node,
	ignoredMarkRangesPredicate,
}: {
	node: PMNode;
	ignoredMarkRangesPredicate?: (node: PMNode) => boolean;
}) => {
	const ignoredRanges: Array<IgnoredRange> = [];
	node.descendants((node, pos) => {
		if (!node.isText && node.isInline) {
			ignoredRanges.push({ pos, size: node.nodeSize, type: 'inlineNode' });
		} else if (ignoredMarkRangesPredicate && ignoredMarkRangesPredicate(node)) {
			ignoredRanges.push({ pos, size: node.nodeSize, type: 'mark' });
		}
		return false;
	});
	return ignoredRanges;
};

export function getSentencesFromNode({
	node,
	paragraphStartPos,
	needSpellingAndGrammarCheck,
	needSGCheckForDocChecker,
	paragraphIgnoredRanges,
	locale,
	containerType,
}: {
	node: PMNode;
	paragraphStartPos: number;
	needSpellingAndGrammarCheck: boolean;
	needSGCheckForDocChecker: boolean;
	paragraphIgnoredRanges: IgnoredRange[];
	locale?: string;
	containerType: NodeType;
}) {
	const sentenceTexts = splitIntoSentencesBySegmenter(node.textContent, locale);

	let currentSentenceStartPos = 0;
	let sentences = sentenceTexts.map(({ text: sentenceText }) => {
		let currentSentenceSize = sentenceText.length;
		const ignoredRangesInSentences: ProactiveAIBlock['ignoredRanges'] = [];
		paragraphIgnoredRanges.forEach(({ pos, size, type }) => {
			if (pos >= currentSentenceStartPos && pos < currentSentenceStartPos + currentSentenceSize) {
				// pos should be relative to the sentence
				ignoredRangesInSentences.push({ pos: pos - currentSentenceStartPos, size, type });
				// only inline nodes should contribute to size
				if (type === 'inlineNode') {
					currentSentenceSize += size;
				}
			}
		});

		const sentence = {
			id: uuid(),
			from: currentSentenceStartPos,
			to: currentSentenceStartPos + currentSentenceSize,
			size: currentSentenceSize,
			text: sentenceText,
			ignoredRanges: ignoredRangesInSentences,
			needSpellingAndGrammarCheck,
			needSGCheckForDocChecker,
			containerType,
		};
		/**
		 * set startPos for next sentence.
		 */
		currentSentenceStartPos = currentSentenceStartPos + currentSentenceSize;
		return sentence;
	});

	sentences = sentences.map((sentence) => ({
		...sentence,
		from: paragraphStartPos + sentence.from,
		to: paragraphStartPos + sentence.to,
	}));

	return sentences;
}

function splitIntoSentencesBySegmenter(
	text: string,
	locale = 'en',
): Array<{ text: string; index: number }> {
	const segmenter = new Intl.Segmenter(locale, { granularity: 'sentence' });
	const sentences = segmenter.segment(text);
	return Array.from(sentences).map((segment) => {
		return {
			text: segment.segment,
			index: segment.index,
		};
	});
}

export function getAllProactiveAIBlocks(state: EditorState) {
	const nodeTypes = getProactiveAIBlockNodeTypes(state);
	const includedNodeTypes = getIncludedProactiveAINodeTypes(state);
	const proactiveAIBlocks: ProactiveAIBlock[] = [];
	const ignoredMarkRangesPredicate = getIgnoredMarkRangesPredicate(state);

	state.doc.descendants((node, pos, parent) => {
		if (nodeTypes.has(node.type)) {
			const ignoredRanges = getIgnoredRanges({ node, ignoredMarkRangesPredicate });
			const from = pos + 1,
				to = pos + node.nodeSize - 1;

			const containerType = getContainerType(state.schema, node, parent);
			proactiveAIBlocks.push({
				id: uuid(),
				text: node.textContent,
				from,
				to,
				nodeTypeName: node.type.name,
				ignoredRanges,
				needSpellingAndGrammarCheck: false,
				needSGCheckForDocChecker: true,
				sentences: canSplitParagraphIntoSentences()
					? getSentencesFromNode({
							node,
							paragraphStartPos: from,
							needSpellingAndGrammarCheck: false,
							needSGCheckForDocChecker: true,
							paragraphIgnoredRanges: ignoredRanges,
							containerType,
						})
					: undefined,
				containerType,
			});
			return false;
		}
		return includedNodeTypes.has(node.type);
	});

	return { proactiveAIBlocks };
}

export function canSplitParagraphIntoSentences() {
	return !!Intl.Segmenter;
}

export function getSelectedDiffObject(pluginState: AISpellingGrammarPluginState, pos: number) {
	const { proactiveAIBlocks, splitParagraphIntoSentences } = pluginState;
	let selectedDiffObject: DiffObject | undefined;

	if (proactiveAIBlocks?.length) {
		for (let i = 0; i < proactiveAIBlocks.length; i++) {
			const block = proactiveAIBlocks[i];
			if (pos >= block.from && pos <= block.to) {
				if (splitParagraphIntoSentences) {
					const blockSentences = block.sentences || [];
					for (let j = 0; j < blockSentences.length; j++) {
						const sentence = blockSentences[j];
						selectedDiffObject = sentence.diffObjects?.find(
							(diffObject) => pos >= diffObject.from && pos <= diffObject.to,
						);
						if (selectedDiffObject) {
							break;
						}
					}
				} else {
					selectedDiffObject = block.diffObjects?.find(
						(diffObject) => pos >= diffObject.from && pos <= diffObject.to,
					);
				}

				if (selectedDiffObject) {
					break;
				}
			}
		}
	}
	return selectedDiffObject;
}

export function removeSelectedDiffObjectFromBlock(
	pluginState: AISpellingGrammarPluginState,
	from: number,
	to: number,
) {
	const { proactiveAIBlocks, splitParagraphIntoSentences } = pluginState;

	return proactiveAIBlocks?.map((block) => {
		if (splitParagraphIntoSentences && block.sentences) {
			return {
				...block,
				sentences: block.sentences.map((sentence) => {
					return {
						...sentence,
						diffObjects: sentence.diffObjects?.filter(
							(diffObject) => from !== diffObject.from && to !== diffObject.to,
						),
					};
				}),
			};
		} else {
			return {
				...block,
				diffObjects: block.diffObjects?.filter(
					(diffObject) => from !== diffObject.from && to !== diffObject.to,
				),
			};
		}
	});
}

export function removeDiffObjectFromBlockMatchingText(
	pluginState: AISpellingGrammarPluginState,
	originalText: string,
) {
	const { proactiveAIBlocks, splitParagraphIntoSentences } = pluginState;

	return proactiveAIBlocks?.map((block) => {
		if (splitParagraphIntoSentences && block.sentences) {
			return {
				...block,
				sentences: block.sentences.map((sentence) => {
					return {
						...sentence,
						diffObjects: sentence.diffObjects?.filter(
							(diffObject) => originalText !== diffObject.originalText,
						),
					};
				}),
			};
		} else {
			return {
				...block,
				diffObjects: block.diffObjects?.filter(
					(diffObject) => originalText !== diffObject.originalText,
				),
			};
		}
	});
}

export function generateParagraphChunkMap(
	blocks: ProactiveAIBlock[],
	splitParagraphIntoSentences: boolean,
) {
	const chunks = splitParagraphIntoSentences ? getBlocksSentences(blocks) : blocks;
	return chunks.reduce(
		(accumulator, chunk) => {
			accumulator.set(chunk.id, chunk);
			return accumulator;
		},
		new Map() as Map<string, ParagraphChunk>,
	);
}

export function getBlocksSentences(blocks: ProactiveAIBlock[]) {
	return blocks.reduce((accumulator, block) => {
		if (block.sentences) {
			accumulator = accumulator.concat(block.sentences);
		}
		return accumulator;
	}, [] as ProactiveAISentence[]);
}

export function getBlocksSentencesNeedingSGCheck(blocks: ProactiveAIBlock[]) {
	return getBlocksSentences(blocks).filter((sentence) => sentence.needSpellingAndGrammarCheck);
}

export function getDiffObjectsFromBlocks(
	blocks: ProactiveAIBlock[],
	splitParagraphIntoSentences: boolean,
) {
	if (splitParagraphIntoSentences) {
		return (
			blocks.reduce((allDiffObjects, block) => {
				if (block.sentences) {
					return block.sentences?.reduce((accumulator, sentence) => {
						if (sentence.diffObjects) {
							accumulator = accumulator.concat(sentence.diffObjects);
						}
						return accumulator;
					}, allDiffObjects);
				}
				return allDiffObjects;
			}, [] as DiffObject[]) || []
		);
	} else {
		return (
			blocks.reduce((accumulator, block) => {
				if (block.diffObjects) {
					accumulator = accumulator.concat(block.diffObjects);
				}
				return accumulator;
			}, [] as DiffObject[]) || []
		);
	}
}

export function getAllDiffObjects(pluginState: AISpellingGrammarPluginState): DiffObject[] {
	const { proactiveAIBlocks, splitParagraphIntoSentences } = pluginState;

	return proactiveAIBlocks
		? getDiffObjectsFromBlocks(proactiveAIBlocks, splitParagraphIntoSentences)
		: [];
}

export function setBlocksDiffObjects(
	pluginState: AISpellingGrammarPluginState,
	chunksDiffObjectsById: Map<string, DiffObject[]>,
) {
	const { proactiveAIBlocks, splitParagraphIntoSentences, dismissedWords } = pluginState;

	return proactiveAIBlocks?.map((block) => {
		if (splitParagraphIntoSentences) {
			return {
				...block,
				sentences: block.sentences?.map((sentence) => {
					const newDiffObjects = chunksDiffObjectsById.get(sentence.id);
					return {
						...sentence,
						...(newDiffObjects && {
							diffObjects: newDiffObjects.filter(
								(diffObject) => !dismissedWords.has(diffObject.originalText),
							),
							needSpellingAndGrammarCheck: false,
							needSGCheckForDocChecker: false,
						}),
					};
				}),
			};
		} else {
			const newDiffObjects = chunksDiffObjectsById.get(block.id);
			return {
				...block,
				...(newDiffObjects && {
					diffObjects: newDiffObjects.filter(
						(diffObject) => !dismissedWords.has(diffObject.originalText),
					),
					needSpellingAndGrammarCheck: false,
					needSGCheckForDocChecker: false,
				}),
			};
		}
	});
}

export function getBlockOrSentenceFromDiffObject(
	pluginState: AISpellingGrammarPluginState,
	diffObject: DiffObject,
) {
	const { proactiveAIBlocks, splitParagraphIntoSentences } = pluginState;
	if (splitParagraphIntoSentences) {
		if (proactiveAIBlocks?.length) {
			for (let i = 0; i < proactiveAIBlocks.length; i++) {
				const block = proactiveAIBlocks[i];
				const sentence = block.sentences?.find((sentence) =>
					sentence.diffObjects?.includes(diffObject),
				);
				if (sentence) {
					return sentence;
				}
			}
		}
	} else {
		return proactiveAIBlocks?.find((block) => block.diffObjects?.includes(diffObject));
	}
}

export function isDiffObjectInPluginState(
	pluginState: AISpellingGrammarPluginState,
	diffObject: DiffObject,
) {
	const { proactiveAIBlocks, splitParagraphIntoSentences } = pluginState;
	if (splitParagraphIntoSentences) {
		return proactiveAIBlocks?.some((block) => {
			return block.sentences?.some((sentence) => {
				return sentence.diffObjects?.some(({ id }) => id === diffObject.id);
			});
		});
	} else {
		return proactiveAIBlocks?.some((block) => {
			return block.diffObjects?.some(({ id }) => id === diffObject.id);
		});
	}
}
