export const FeatureFlagsQueryRaw = /* GraphQL */ `
	query FeatureFlagsQuery {
		featureFlags(extraTargeting: true, first: 800) {
			nodes {
				id
				value
				explanation {
					ruleIndex
					ruleId
					kind
				}
			}
		}
		platformFeatureFlags: featureFlags(prefix: PLATFORM, extraTargeting: true, first: 500) {
			nodes {
				id
				value
				explanation {
					ruleIndex
					ruleId
					kind
				}
			}
		}
	}
`;
