import type { HeadingProps as PlatformHeadingProps } from '@atlaskit/heading';
type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Levels = 'h800' | 'h700' | 'h600' | 'h500' | 'h400' | 'h300';

export type HeadingProps = Pick<PlatformHeadingProps, 'children' | 'id' | 'testId'> & {
	as: HeadingTags;
	color?: 'default' | 'inverse';
};

const onlyAllowHeadingTag = (asProp: string | undefined): HeadingTags | undefined => {
	if (asProp && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(asProp)) {
		return asProp as HeadingTags;
	}
	throw Error(`${asProp} is an invalid heading tag. Please use one of h1, h2, h3, h4, h5, h6.`);
};

const getLevel = (asProp: HeadingTags) => {
	const levelMap: Record<string, Levels> = {
		h1: 'h800',
		h2: 'h700',
		h3: 'h600',
		h4: 'h500',
		h5: 'h400',
		h6: 'h300',
	};
	return levelMap[asProp];
};

// Note: the variant prop is excluded here as it's currently still in beta and requires the typography theme to be set up.

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::18bb26c40ecf8ed71742089644ff1550>>
 * @codegenId #heading
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen heading
 * @codegenParams { "defineOwnType": true, "props": { "level": { "excludeFromDeconstruct": true, "value": "getLevel(as)"}, "as": {"value": "onlyAllowHeadingTag(as)", "comment": "Other elements are not supported at this time"}, "children": true, "color": true, "id": true, "variant": false, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/heading/src/types.tsx <<SignedSource::00bfca2deb89f012dc316a40f839aa60>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading-context.tsx <<SignedSource::313cf29869afd2521ca18f89df225ed5>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading.partial.tsx <<SignedSource::351ea807b4352b2fd501faa2c465d04f>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading.tsx <<SignedSource::83a26c4cffa41fc7430f13792f533714>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformHeading from '@atlaskit/heading';

// Define the type for Heading as HeadingProps at the top of this file

export const Heading = (props: Parameters<RenderFn>[0]) => {
	const { as, color, id, testId } = props.forgeDoc.props as HeadingProps;
	// warning: level is deprecated;
	return (
		<PlatformHeading
			level={getLevel(as)}
			// Other elements are not supported at this time
			as={onlyAllowHeadingTag(as)}
			children={props.forgeDoc.children.map((c) => (
				<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
			))}
			color={color}
			id={id}
			testId={testId}
		/>
	);
};
/**
 * @codegenEnd
 */
