import type {
	AbortEvent,
	FailEvent,
	StartEvent,
	SuccessEvent,
} from '@atlassian/experience-tracker';

import type { NavigationPolicy } from '../navigation-policy';

export const CHANNEL_NAME = 'embedded-confluence-iframe';

export const PASSTHROUGH_PROPS_MODULE_NAME = 'passThroughProps';
export const PROPS_UPDATED = 'propsUpdated';
export type PassThroughPropsModule = {
	getProps: () => void;
	navigationPolicyNavigate: NavigationPolicy['navigate'];
	onPublish?: () => void;
};

export const EXPERIENCE_FORWARDING_MODULE_NAME = 'experienceForwarding';
export type ExperienceForwardingModule = {
	start: (options: Omit<StartEvent, 'action'>) => void;
	abort: (options: Omit<AbortEvent, 'action'>) => void;
	succeed: (options: Omit<SuccessEvent, 'action'>) => void;
	fail: (options: Omit<FailEvent, 'action'>) => void;
};
