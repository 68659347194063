import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const UserDetails = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-UserDetails" */ '@confluence/site-permissions/entry-points/UserDetails'
			)
		).UserDetails,
});

export const GlobalPermissionUserDetailsRoute = ({ params: { accountId } }: RouteMatch) => (
	<UserDetails accountId={accountId} />
);

GlobalPermissionUserDetailsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'sitePermissionsUserDetailsScreen',
		},
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
