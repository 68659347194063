import { type DependencyList, useMemo } from 'react';

export function toDependencyList<T extends Record<string, unknown>>(obj: T): DependencyList {
	return Object.entries(obj)
		.sort()
		.flatMap((v) => v);
}

/**
 * A custom hook that returns a memorized version of the input object to ensure stability across re-renders.
 *
 * The hook converts the object properties into a dependency list to determine if the object
 * has changed between renders. If the object's properties (values) haven't changed, the same memorized object is returned,
 * ensuring that reference equality checks (e.g., `===`) pass and preventing unnecessary re-renders or hook executions.
 *
 * @template T - A generic type extending `Record<string, unknown>` (Any Props) that describes the shape of the input object.
 * @param {T} obj - The input object that you want to stabilize across re-renders.
 * @returns {T} - Returns a memorized version of the input object that only changes if its properties (values) change.
 *
 * @example
 * const stableObject = useStableObject({ key: 'value', count: 5 });
 * // `stableObject` will remain the same across re-renders unless `key` or `count` values
 */
export function useStableObject<T extends Record<string, unknown>>(obj: T): T {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => obj, toDependencyList(obj));
}
