import React from 'react';
import type { FC, PropsWithChildren } from 'react';

import { LoadablePaint } from '@confluence/loadable';

export const PassThroughComponent: FC<PropsWithChildren<{}>> = ({ children }) => <>{children}</>;

export const TreeRootForServerPlaceholder = LoadablePaint({
	loader: async () => PassThroughComponent,
	_reactTreeRootForServerPlaceholderId: true,
});

export const TreeRootForServerPlaceholderWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
	return <TreeRootForServerPlaceholder>{children}</TreeRootForServerPlaceholder>;
};
