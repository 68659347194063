import { liftPromiseState } from '@confluence/lifted-promise';

export function onScriptsAndCSSLoaded(
	tags: (HTMLScriptElement | HTMLLinkElement)[],
): Promise<void> {
	return liftPromiseState(
		Promise.all(
			tags.map((script) => {
				return new Promise<void>((resolve) => {
					script.onload = () => {
						resolve();
						script.setAttribute('data-loaded', 'true');
					};
				});
			}),
		).then(() => {}),
	);
}
