import type { FC } from 'react';
import React, { Fragment, useEffect } from 'react';

import type { RendererAppearance } from '@atlaskit/renderer';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	PerformanceMetrics,
	LegacyPerformanceMetrics,
} from '@confluence/view-content-perf-metrics';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';

type RendererPerformanceMetricsProps = {
	isFabric: boolean;
	isRenderTTIMark?: boolean;
	contentId?: string;
	appearance?: RendererAppearance;
	revision?: string;
	isAutoConverted?: boolean;
	lastModifiedDate?: string;
	customPerfData?: Record<string, string | boolean>;
};

export const RendererPerformanceMetrics: FC<RendererPerformanceMetricsProps> = ({
	contentId,
	isFabric,
	appearance,
	revision,
	isRenderTTIMark = false,
	isAutoConverted = false,
	lastModifiedDate = '',
	customPerfData = {},
}) => {
	const [, actions] = useContentState();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		/* call onContentRendered in useContentState which uses react-sweet-state and sets isContentReady
    to true indicating content is rendered*/
		if (!isRenderTTIMark) {
			actions?.onContentRendered();
		}
	}, [actions, isRenderTTIMark]);

	useEffect(() => {
		// Send an event when the page have not been published in the past 15 seconds
		if (lastModifiedDate) {
			const timeSinceLastPublished = Date.now() - Date.parse(lastModifiedDate);
			const RECENTLY_PUBLISHED_MS = 15000;
			const shouldSendEvent = timeSinceLastPublished > RECENTLY_PUBLISHED_MS;
			if (shouldSendEvent) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'viewed',
						actionSubject: 'performanceTrigger',
						source: 'viewPage',
						attributes: {
							timeSinceLastPublished,
						},
					},
				}).fire();
			}
		}
	}, [createAnalyticsEvent, lastModifiedDate]);

	/*

    Logic to fire Performance Events in 4 cases:

    1. RendererTTIMarker - In this case we fire only PerformanceMetrics
    2. Fabric Rendered Page - In this case we fire only LegacyPerformanceMetrics
    3. Tiny Page - In this case we fire both LegacyPerformanceMetrics and PerformanceMetrics
    4. Fall back Page - In this case we fire both LegacyPerformanceMetrics and PerformanceMetrics
    */

	const renderPerformanceMetrics = !isFabric || isRenderTTIMark;
	const renderLegacyPerformanceMetrics = !isRenderTTIMark;

	return (
		<Fragment>
			{renderLegacyPerformanceMetrics && <LegacyPerformanceMetrics />}
			{renderPerformanceMetrics && (
				<PerformanceMetrics
					appearance={appearance as string}
					contentId={contentId!}
					fabric={isFabric}
					revision={revision}
					isAutoConverted={isAutoConverted}
					customPerfData={customPerfData}
				/>
			)}
		</Fragment>
	);
};
