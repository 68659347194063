import React, { useContext } from 'react';

import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { SPACE_OVERVIEW as SPACE_OVERVIEW_MARKER } from '@confluence/screen';
import { SpaceOverviewLoader } from '@confluence/space-overview';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { useIsCompanyHubEnabled } from '@confluence/company-hub-utils/entry-points/useIsCompanyHubEnabled';
import { fg } from '@confluence/feature-gating';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { HOME, COMPANY_HUB } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { SPAViewContext } from '@confluence/spa-view-context';
import { CompanyHubAnalyticsWrapper } from '@confluence/analytics/entry-points/CompanyHubAnalyticsWrapper';

export function getCompanyHubRoute(Component: React.ElementType) {
	const CompanyHubRoute = () => {
		const { isCompanyHubEntryPointEnabled, isCompanyHubPublished } = useIsCompanyHubEnabled();
		const { isSiteAdmin, loading } = useContext(SPAViewContext);
		const shouldShowPremiumUpsell = useCompanyHubPremiumGate();

		// As stated in its documentation below, the function NAVIGATION_PARAMS
		// cannot invoke the hook useIsCompanyHubEnabled.
		screenEventAttributes.publishedCompanyHub = isCompanyHubPublished;

		if (shouldShowPremiumUpsell && !loading) {
			if (isSiteAdmin) {
				// CompanyHomeBuilderUpsellPage was supposed to be rendered here, but it's
				// built on top of the underlying change-edition component
				// UpsellLooknFeel/SectionMessage which doesn't support SSR. Since the
				// SectionMessage is at the top of the content area of
				// CompanyHomeBuilderUpsellPage, even if the rest of the content area were
				// SSRed, it would shift vertically when the SPA loads. But the top nav is
				// still useful.
				return process.env.REACT_SSR ? null : (
					<CompanyHomeBuilderUpsellPage featureName="companyHub" />
				);
			} else {
				return <Redirection name={HOME.name} />;
			}
		}

		if (!loading && !isCompanyHubEntryPointEnabled) {
			return <Redirection name={HOME.name} />;
		}

		// The only different between SPA and SSR is we don't render space base in SSR

		const spaceKey = getCompanyHubSpaceKey() || '';

		let component = <Component spaceKey={spaceKey} contentId="" />;

		if (fg('confluence_frontend_company_hub_analytics')) {
			component = <CompanyHubAnalyticsWrapper>{component}</CompanyHubAnalyticsWrapper>;
		}

		return component;
	};

	const screenEventAttributes: Record<string, any> = {
		navdexPointType: START_TOUCH,
		// Company Hub hides its space from the user, doesn't refer to its page
		// as such, is rather presented to the user as "a new surface", and thus
		// doesn't constitute space usage from the perspective of the user.
		screenIsInSpace: false,
	};

	/**
	 * Warning: Though the function NAVIGATION_PARAMS is invoked during render,
	 * each route has its own implementation. Consequently, it cannot invoke
	 * hooks or, during route transitions between implementations that invoke
	 * different hooks, the rule of hooks to invoke a hook the same number of
	 * times during each render would be violated and an error would be thrown
	 * by React that would damage the DOM.
	 */
	CompanyHubRoute.NAVIGATION_PARAMS = () => {
		const spaceKey = getCompanyHubSpaceKey() || '';

		return {
			Screen: {
				screenEvent: {
					name: 'companyHub',
					id: spaceKey,
					// Warning: The value of the analytics event attribute
					// publishedCompanyHub that ScreenEvent sends will be
					// inaccurate on initial SPA load without SSR, because
					// ScreenEvent sends before SPAViewContext has loaded.
					attributes: screenEventAttributes,
				},
				pageState: {
					spaceKey,
					contentId: SPACE_OVERVIEW_MARKER,
					routeName: COMPANY_HUB.name,
				},
			},
			MainLayout: {
				navView: PRODUCT_HOME_ID,
				spaceKey,
				isViewPage: true,
			},
		};
	};

	return CompanyHubRoute;
}

export const CompanyHubRouteForSSR = getCompanyHubRoute(SpaceOverviewLoader);
