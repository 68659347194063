const PromiseStatus = Symbol.for('PromiseStatus');
const PromiseValue = Symbol.for('PromiseValue');

declare global {
	interface Promise<T> {
		[PromiseStatus]?: 'resolved' | 'rejected' | 'pending';
		[PromiseValue]?: T;
	}
}

/**
 * Provides synchronous access to a promise's current state.
 */
export const liftPromiseState = <T>(promise: Promise<T>) => {
	const newPromise = promise.then(
		(value) => {
			newPromise[PromiseStatus] = 'resolved';
			newPromise[PromiseValue] = value;
			return value;
		},
		(error) => {
			newPromise[PromiseStatus] = 'rejected';
			newPromise[PromiseValue] = error;
			throw error;
		},
	);

	newPromise[PromiseStatus] = 'pending';

	return newPromise;
};

export const isResolved = <T>(liftedPromise: Promise<T>) =>
	liftedPromise[PromiseStatus] === 'resolved';

export const getValue = <T>(liftedPromise: Promise<T>) => liftedPromise[PromiseValue];
