/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { type SerializedStyles, css, jsx } from '@emotion/react';
import {
	type NativeTableRootProps,
	type NativeTableCaptionProps,
	type NativeTableHeadProps,
	type NativeTableBodyProps,
	type NativeTableFootProps,
	type NativeTableRowProps,
	type NativeTableHeadCellProps,
	type NativeTableCellProps,
	type ForgeDoc,
	type Render,
	type RenderFn,
} from '@atlassian/forge-ui-types';

// This styling has been specifically added for the th to ensure that there is no left
// padding which will occur by default. These rely on attribute selectors matching the
// "row" scope value or any colspan value so only come into effect in specific instances
// and override the default styling provided by the CSS reset
const theadStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[scope="row"]': {
		paddingLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[colspan]': {
		paddingLeft: 0,
	},
});

// These are the names of the HTML element relating to table composition that are considered
// acceptable values to pass to the 'as' property of the RenderHtml function. This list should
// be comprehensive.
type HtmlTableElementNames = 'table' | 'caption' | 'thead' | 'tbody' | 'tfoot' | 'tr' | 'th' | 'td';

// Creating a union of all the different table element properties allows the RenderHtml type to be more concise
type NativeHtmlComponentProps =
	| NativeTableRootProps
	| NativeTableCaptionProps
	| NativeTableHeadProps
	| NativeTableBodyProps
	| NativeTableFootProps
	| NativeTableRowProps
	| NativeTableHeadCellProps
	| NativeTableCellProps;

// This is the type for the function that is commonly used by the components render a UI Kit component.
type RenderHtml = (args: {
	as: HtmlTableElementNames;
	forgeDoc: ForgeDoc;
	render: Render;
	props?: NativeHtmlComponentProps;
	style?: React.CSSProperties;
	css?: SerializedStyles;
}) => JSX.Element;

// This is the common function used by all the components. Behaviour is differentiated through the value
// passed as the 'as' prop. Not all components allow styling, but the structure of this function allow us
// to iteratively add styling capabilities in the future.
const renderHtml: RenderHtml = ({
	as: NativeHtmlComponent,
	forgeDoc,
	render,
	props,
	style,
	css,
}) => {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<NativeHtmlComponent {...props} style={style} css={css}>
			{forgeDoc.children?.map(render)}
		</NativeHtmlComponent>
	);
};

// The next section defines all the components for table composition...
// The main differences between each are:
// - the value passed to the "as" prop as this will determine the HTML element ultimately rendered on the page.
// - the casting of the received ForgeDoc props
// - whether or not styles are passed
//
// Styling is only applied to table and th and currently only supports "width"
// We may choose to iteratively add more styles as necessary to any of the tabular components.
const TableRoot = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { width, ...props } = forgeDoc.props as NativeTableRootProps;
	return renderHtml({ as: 'table', forgeDoc, render, props, style: { width } });
};

const TableCaption = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { ...props } = forgeDoc.props as NativeTableCaptionProps;
	return renderHtml({ as: 'caption', forgeDoc, render, props });
};

const TableHead = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { ...props } = forgeDoc.props as NativeTableHeadProps;
	return renderHtml({ as: 'thead', forgeDoc, render, props });
};

const TableBody = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { ...props } = forgeDoc.props as NativeTableBodyProps;
	return renderHtml({ as: 'tbody', forgeDoc, render, props });
};

const TableFoot = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { ...props } = forgeDoc.props as NativeTableFootProps;
	return renderHtml({ as: 'tfoot', forgeDoc, render, props });
};

const TableRow = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { ...props } = forgeDoc.props as NativeTableRowProps;
	return renderHtml({ as: 'tr', forgeDoc, render, props });
};

const TableCell = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { ...props } = forgeDoc.props as NativeTableCellProps;
	return renderHtml({ as: 'td', forgeDoc, render, props });
};

const TableHeadCell = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { width, ...props } = forgeDoc.props as NativeTableHeadCellProps;
	return renderHtml({
		as: 'th',
		forgeDoc,
		render,
		props,
		style: { width },
		css: theadStyles,
	});
};

export {
	TableRoot,
	TableCaption,
	TableHead,
	TableBody,
	TableFoot,
	TableRow,
	TableCell,
	TableHeadCell,
};
