import type { ReactElement } from 'react';

import type { ExperienceAttributes } from '@confluence/experience-tracker';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { getMacroExperienceTracker, MACRO_TRACKER_FF } from '../MacroExperienceTracker';
import type { Mode } from '../index';
import { useDeepCompareEffect } from '../useDeepCompareEffect';

export type MacroExperienceSuccessProps = {
	mode: Mode;
	extensionKey?: string;
	name: string;
	attributes?: ExperienceAttributes;
	contentId: string;
	children?: ReactElement | null;
};

export function MacroExperienceSuccess({
	attributes,
	children = null,
	contentId,
	extensionKey,
	mode,
	name,
}: MacroExperienceSuccessProps) {
	const isMacroTrackerEnabled = useBooleanFeatureFlag(MACRO_TRACKER_FF);

	useDeepCompareEffect(() => {
		if (isMacroTrackerEnabled) {
			getMacroExperienceTracker(mode, contentId).succeed({
				name,
				attributes,
			});
		}
	}, [attributes, contentId, extensionKey, isMacroTrackerEnabled, mode, name]);

	// https://stackoverflow.com/questions/54905376
	return children as ReactElement<any>;
}
