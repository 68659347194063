import { useContext } from 'react';

import { RoutesContext } from '@confluence/route-manager';

const RELEASE_UNUSED_DELAY_TIME = 10000; // 10 sec

const ssredMacros = new Map<string, Element>();
const usedMacros = new Set<string>();

/**
 * Looks throught the loaded document for DOM elements representing SSRed legacy macros,
 * and store references to them for later use (see `useSSRedLegacyMacroElement`).
 * Why is it needed: due to lack of normal hydration the elements are being recreated after the
 * server document is loaded. Some legacy macros have additional logic (ie fetching data and build markup based on it)
 * which is attached to DOM element. They are not aware about React lifecycle and when corresponding
 * DOM elements are recreated on re-mount these macros may execute their initialization logic multiple times.
 * By collecting and later reusing the exact same DOM nodes we are avoiding these effects.
 */
export function collectSSRedLegacyMacros() {
	document.querySelectorAll('[data-fabric-macro][data-skate-created]').forEach((e) => {
		const macroId = e.getAttribute('data-fabric-macro');
		if (macroId) {
			ssredMacros.set(macroId, e);
		}
	});
}

/**
 * Clean up the internal data structure that holds references to DOM elements picked up by `collectSSRedLegacyMacros`,
 * allowing garbage collector to collect those element.
 * Expected to be called on transition, sinse the store elements no longer supposed to be used.
 */
export function releaseSSRedLegacyMacros() {
	ssredMacros.clear();
}

/**
 * Release references for those elements that are not been used during the render cycle.
 */
export function releaseUnusedSSRedLegacyMacrosWithDelay() {
	setTimeout(releaseUnused, RELEASE_UNUSED_DELAY_TIME);
}

function releaseUnused() {
	for (const macroId of ssredMacros.keys()) {
		if (!usedMacros.has(macroId)) ssredMacros.delete(macroId);
	}
}

/**
 * The hook takes macroId as an input, and returns corresponding DOM element representing SSRed macro.
 * Will only return element for initial load, since after transition it makes no sense to use SSRed output.
 */
export function useSSRedLegacyMacroElement(macroId: string) {
	const { transitionId } = useContext(RoutesContext);
	usedMacros.add(macroId);
	const ssredElement = !transitionId ? ssredMacros.get(macroId) : undefined;
	return { ssredElement };
}
