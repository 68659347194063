/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::52bd92789b9897b3cdea30ddfb4195a6>>
 * @codegenId #valid-message
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen valid-message
 * @codegenParams { "props": { "children": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/form/src/messages.tsx <<SignedSource::1ef884f92ba652ff8a27fa6f20dcf11a>>
 * @codegenDependency ../../../../../../../design-system/form/src/field-id-context.tsx <<SignedSource::e7f0005d72bda85a5d2db3d5c67dc35f>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ValidMessage as PlatformValidMessage } from '@atlaskit/form';

type PlatformValidMessageProps = React.ComponentProps<typeof PlatformValidMessage>;

export type ValidMessageProps = Pick<
  PlatformValidMessageProps,
  'children' | 'testId'
>;

export const ValidMessage = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ValidMessageProps;
  return (
    <PlatformValidMessage
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
