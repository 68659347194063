/*
    This is a temporary solution for adding screenNames to named-routes.

    Rather than explicitly couple analytics to routing, we will end up moving screenName to a
    wrapper component that will take care of sending screen events, and will also have a decorator to
    add screenName to all events below it.
*/

export const screenNamesForRoutes = {
	EDIT_PAGE: 'editPageScreen',
	EDIT_BLOG: 'editPageScreen',
	EDIT_PAGE_V2: 'editPageScreen',
	EDIT_BLOG_V2: 'editPageScreen',
	EDIT_PAGE_EMBED: 'editPageEmbedScreen',
	CREATE_PAGE: 'editPageScreen',
	CREATE_BLOG: 'editPageScreen',
	SPACE_OVERVIEW: 'spaceOverviewScreen',
	VIEW_PAGE: 'viewPageScreen',
	VIEW_BLOG_DATE_LEGACY: 'viewPageScreen',
	VIEW_BLOG: 'viewPageScreen',
	SPACE_CALENDARS: 'spaceCalendarsScreen',
	ALL_UPDATES: 'allUpdatesScreen',
	POPULAR: 'popularScreen',
	MY_WORK_DRAFTS: 'draftsScreen',
	RECENT_DRAFTS: 'recentDraftsScreen',
	MY_WORK_RECENT: 'recentlyWorkedOnScreen',
	RECENT_PAGES: 'recentPagesScreen',
	MY_RECENTLY_VISITED: 'recentlyVisitedScreen',
	RECENT_VISITED: 'recentVisitedScreen',
	MY_SAVED_FOR_LATER: 'savedScreen',
	RECENT_SAVED_FOR_LATER: 'recentSavedScreen',
	HOME: 'homeScreen',
	PDF_EXPORT_PAGE: 'pdfExportScreen',
	PDF_EXPORT_SPACE: 'pdfExportScreen',
	TOPICS: 'topicsScreen',
};

const mapRoutesToScreenName = (routes, screenNames = screenNamesForRoutes) => {
	for (const name in routes) {
		if (typeof routes[name] === 'object') {
			routes[name].screenName = screenNames[name];
		}
	}
	return routes;
};

export default mapRoutesToScreenName;
