import gql from 'graphql-tag';

export const CollabDraftQuery = gql`
	query CollabDraftQuery($id: ID!) {
		collabDraft(id: $id) {
			document
			version
			metadata {
				title
			}
		}
	}
`;
