import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { Screen } from '@confluence/screen';
import { MainLayout } from '@confluence/main-layout';

import { LazyConfluenceNotFound } from './lazyComponents';

export function NotFound() {
	return (
		<Screen screenEvent={{ name: 'notFoundScreen' }}>
			<MainLayout navView={PRODUCT_HOME_ID}>
				<LazyConfluenceNotFound />
			</MainLayout>
		</Screen>
	);
}
