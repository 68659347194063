import { SSRMeasures } from '@confluence/action-measures';

export const guardExceptions: Promise<any>[] = [];

export const prepareGuardExceptionTask = async (
	queryName: string,
	task: () => Promise<any>,
): Promise<any> => {
	const wrappedTask = SSRMeasures.run(
		`ssr-app/prepare/preloadQuery/guardException:${queryName}`,
		task,
	);
	guardExceptions.push(wrappedTask);
	return wrappedTask;
};
