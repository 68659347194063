import React, { type FC } from 'react';
import { getExtensionHandlers } from '@confluence-classic/confluence-fabric-editor/src/lib/renderer-extensions';
import { populateExtensionProviders } from '@confluence-classic/confluence-fabric-editor/src/components/Renderer/populateExtensionProviders';

import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';

import EditorLoaderLoader from '../EditorLoader/EditorLoaderLoader';

const getLegacyContentExtensions = () => ({
	loadSortableTable: () =>
		import(
			/* webpackChunkName: "loadable-tech-debtsortable-tablessortable-table" */ '../../tech-debt/sortable-tables/sortable-table'
		),
});

const providers = {
	getExtensionHandlers,
	getLegacyContentExtensions,
	populateExtensionProviders,
	EditorLoaderLoader,
};

/**
 * A component to inject extension handlers/providers into the Content Renderer context.
 * WARN: this component must not be used directly but only via Loadable.
 */
export const ContentExtensionsProvider: FC<{}> = ({ children }) => (
	<RendererExtensionContext.Provider value={providers}>
		{children}
	</RendererExtensionContext.Provider>
);
