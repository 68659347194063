import { TESTING_TOKEN_OVERRIDE_COOKIE_KEY } from '../../../constants';
import { getCookie } from '../get-cookie';

/**
  TESTING UTILITY: Override resulting ConsentToken to simulate having never saved ConsentHub data
  Without doing this, it's neigh impossible to test scenarios that require missing ConsentHub data once
  you've saved consents in a live environment as we cannot delete ConsentHub data
*/
export const overrideConsentTokenForLiveTesting = (genuineConsentToken: string) => {
	// The existence of this cookie will make it look like fetched ConsentHub data is missing
	// You can set it to undefined yourself/empty string, or an actual default value.
	const tokenOverride = getCookie(TESTING_TOKEN_OVERRIDE_COOKIE_KEY);

	// Note: Clear this cookie out of your browser when you're done testing empty data sets
	// Or you'll be stuck seeing the banner and never actually consenting on the UI
	return tokenOverride || genuineConsentToken;
};
