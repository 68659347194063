import type { Resolver } from '../extensionTypes';

type Options = {
	resolver: Resolver;
	values: string[];
};

export const customValueResolver = ({ resolver, values }: Options) => {
	const notSpecial = (val) => values.indexOf(val) === -1;

	return async (searchTerm?: string, selectedValue?: string | string[]) => {
		if (Array.isArray(selectedValue)) {
			selectedValue = selectedValue.filter(notSpecial);
		} else if (!notSpecial(selectedValue)) {
			selectedValue = '';
		}

		const results = await resolver(searchTerm, selectedValue);
		results.push(...values.map((value) => ({ label: value, value })));

		return results;
	};
};
