// These exports are using the .default prop of the module because we're mixing ES6 and ES5. This is required for
// webpack to be able to correctly split bundles.
import { LoadablePaint, LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

export const LazySpaceBlogRedirect = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceBlogRedirect" */ '../SpaceBlogRedirect'))
			.SpaceBlogRedirect,
});

export const LazySpaceEditPage = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-containersEditPageContainer" */ '../../containers/EditPageContainer'
		),
});

export const LazySpaceCreatePage = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-containersCreatePageContainer" */ '../../containers/CreatePageContainer'
		),
});

export const LazyRouteToRightEditor = LoadablePaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-RouteToRightEditor" */ '../RouteToRightEditor'),
});

export const LazyMobileWebContainer = LoadablePaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-MobileWebContainer" */ '../MobileWebContainer'),
});

export const LazyDwellTime = LoadableLazy({
	loader: () => import(/* webpackChunkName: "loadable-DwellTime" */ '../DwellTime'),
});

export const LazyConfluenceNotFound = LoadableAfterPaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-containersConfluenceNotFoundContainer" */ '../../containers/ConfluenceNotFoundContainer'
		),
});

export const LazyTeamCalendars = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluenceteam-calendarentry-pointsTeamCalendars" */ '@confluence/team-calendar/entry-points/TeamCalendars'
			)
		).TeamCalendar,
});

export const LazyQuestions = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencequestionsentry-pointsQuestions" */ '@confluence/questions/entry-points/Questions'
			)
		).Questions,
});

export const LazyJiraProjectBoard = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-jira-project-board" */ '@confluence/experiment-jira-project-board/entry-points/JiraProjectBoard'
			)
		).JiraProjectBoard,
});
