/**
 * Official Atlassian javascript library for Confluence REST API calls.
 * @module confluence-rest-api
 */

import requestHelper from './request-helper';
const fetch = requestHelper.fetch;
export { fetch };

/**
 * Configures all API helpers.
 * @param {Object} configuration
 */
export function configure(configuration) {
	requestHelper.configure(configuration);
}

/**
 * Helper methods for fetching spaces.
 */
export { space } from './spaces';

/**
 * Helper methods for fetching content.
 */
export {
	editPageMetadata,
	editorPage,
	publishEditorPage,
	syncEditorDraft,
	publishEditorPageFromTemplate,
	createPage,
	draftMetadata,
	cancelScheduledPublish,
} from './content';

/**
 * Helper methods related to features.
 */
export { markDiscovered } from './feature-discovery';

/**
 * Helper methods related to page labels.
 */
export { suggest } from './labels';

/**
 * Helper methods related to the editor.
 */
export { getPluginCodeLanguages, getWikiConfluenceToXhtml, getWikiMarkdownToXhtml } from './editor';

/**
 * Helper methods related to content properties.
 */
export { updateContentProperty } from './contentProperties';

/**
 * Transformer functions.
 */
export {
	spaceResponseTransformer,
	contentsWithBodyResponseTransformer,
	editorContentsWithBodyResponseTransformerV2,
} from './transformers';

export { userTransformer } from './transformers/users';
export { isSpa } from './transformers/helpers';
