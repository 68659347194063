import type { GraphQLError } from 'graphql';
import type { ReactNode } from 'react';
import React from 'react';

import { isStatusCodeError } from '@confluence/error-types';
import { KnownErrorBoundary } from '@confluence/known-error-boundary';

type UnauthorizedErrorBoundaryProps = {
	children: ReactNode;
	onError?: (error: any) => void;
	renderOnError: (error: any) => ReactNode | null;
};

export const UnauthorizedErrorBoundary = (props: UnauthorizedErrorBoundaryProps) => (
	<KnownErrorBoundary isKnownError={isUnauthorizedError} {...props} />
);

/**
 * https://pug.jira-dev.com/wiki/spaces/CCB/pages/4709260932/GraphQL+Errors+Contract+for+Status+Code+401+and+403
 */

const isLegacyUnauthorizedGraphQLError = (error: Error) =>
	(error as GraphQLError)?.extensions?.data?.authorized === false;

export const isUnauthorizedError = (error: Parameters<typeof isStatusCodeError>[0]): boolean =>
	isStatusCodeError(error, '403', isLegacyUnauthorizedGraphQLError);
