import React from 'react';

import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager';
import { getSpaceOverviewRoute } from '@confluence/ssr-app/entry-points/routes';

/**
 * Keeping the redirection because refined themes only support space overview route.
 * https://atlassian.slack.com/archives/CFGSEJDFT/p1721136254384829
 */
export const SpaceIndexRoute = getSpaceOverviewRoute(({ spaceKey, query }) => (
	<Redirection name={SPACE_OVERVIEW.name} params={{ spaceKey, query }} />
));
