import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

import { POST_OFFICE_PLATFORM_NAME, UFOExperienceNames } from '../shared/constants';

const STEPS = {
	RML_FETCH: 'rml-fetch',
	RML_RENDER: 'rml-render',
	INITIAL_DATA: 'initial-data-load',
	COMPONENT_READY: 'component-ready',
};

type ConsumptionType = 'local' | 'remote';

const UFO_EXPERIENCE_CONFIG = {
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	type: ExperienceTypes.Load,
	platform: { component: POST_OFFICE_PLATFORM_NAME },
	timings: [
		{
			key: `${STEPS.RML_FETCH}-complete`,
			endMark: `${STEPS.RML_FETCH}-complete`,
		},
		{
			key: `${STEPS.RML_RENDER}-complete`,
			endMark: `${STEPS.RML_RENDER}-complete`,
		},
		{
			key: `${STEPS.INITIAL_DATA}-complete`,
			endMark: `${STEPS.INITIAL_DATA}-complete`,
		},
		{
			key: `${STEPS.INITIAL_DATA}-error`,
			endMark: `${STEPS.INITIAL_DATA}-error`,
		},
	],
};

export class PlacementPerformanceTracker {
	/** UFO experience for tracking the performance of all placements in aggregate.
	 * This supports setting up a single platform-level SLO for all placements.
	 */
	private readonly aggregatedExperience: UFOExperience;
	/** UFO experience for tracking the performance of a specific placement.
	 * This supports setting up a placement-specific SLOs.
	 */
	private readonly placementSpecificExperience: UFOExperience;
	private readonly placementExperiences: UFOExperience[] = [];
	private readonly metadata: Record<string, string> = {};

	constructor(placementId: string, consumptionType: ConsumptionType = 'remote') {
		this.aggregatedExperience = new UFOExperience(
			UFOExperienceNames.PLACEMENT,
			UFO_EXPERIENCE_CONFIG,
		);
		this.placementSpecificExperience = new UFOExperience(
			`${UFOExperienceNames.PLACEMENT}.${placementId}`,
			UFO_EXPERIENCE_CONFIG,
		);
		this.placementExperiences = [this.aggregatedExperience, this.placementSpecificExperience];
		for (const experience of this.placementExperiences) {
			void void experience.start();
		}
		this.metadata = { placementId, consumptionType };
	}

	public markFetchComplete = () => {
		for (const experience of this.placementExperiences) {
			void experience.mark(`${STEPS.RML_FETCH}-complete`);
		}
	};

	public markRenderComplete = () => {
		for (const experience of this.placementExperiences) {
			void experience.mark(`${STEPS.RML_RENDER}-complete`);
		}
	};

	public markInitialDataComplete = () => {
		for (const experience of this.placementExperiences) {
			void experience.mark(`${STEPS.INITIAL_DATA}-complete`);
		}
	};

	public markComponentReady = () => {
		for (const experience of this.placementExperiences) {
			void experience.success({
				metadata: this.metadata,
			});
		}
	};

	public markFetchFailure = (error: Error) => {
		for (const experience of this.placementExperiences) {
			void experience.failure({
				metadata: {
					...this.metadata,
					failedDuring: STEPS.RML_FETCH,
					error: JSON.stringify(error),
				},
			});
		}
	};

	public markRenderFailure = (error: Error) => {
		for (const experience of this.placementExperiences) {
			void experience.failure({
				metadata: {
					...this.metadata,
					failedDuring: STEPS.RML_RENDER,
					error: JSON.stringify(error),
				},
			});
		}
	};

	public markInitialDataFailure = (_: Error) => {
		for (const experience of this.placementExperiences) {
			void experience.mark(`${STEPS.INITIAL_DATA}-error`);
		}
	};

	public markComponentFailure = (error: Error) => {
		for (const experience of this.placementExperiences) {
			void experience.failure({
				metadata: {
					...this.metadata,
					failedDuring: STEPS.COMPONENT_READY,
					error: JSON.stringify(error),
				},
			});
		}
	};

	public markComponentAborted = () => {
		for (const experience of this.placementExperiences) {
			void experience.abort({
				metadata: this.metadata,
			});
		}
	};
}
