import React, { type ComponentType } from 'react';

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { type EngagementProviderProps } from '../../controllers/engagement-provider';

/*\
 *  @params: Component is the component that is being prevented from nesting instances.
\*/
export default function preventNestedInstances(
	Component: ComponentType<EngagementProviderProps>,
): ComponentType<EngagementProviderProps> {
	const PreventNestedInstancesContext = React.createContext(false);

	return function PreventNestedInstances(props: EngagementProviderProps) {
		return (
			// TODO: Replace usage of PreventNestedInstancesContext.Consumer with useContext(PreventNestedInstancesContext)
			<PreventNestedInstancesContext.Consumer>
				{(shouldPreventInstantiation) =>
					shouldPreventInstantiation ? (
						props.children
					) : (
						<PreventNestedInstancesContext.Provider value={true}>
							<Component {...props} />
						</PreventNestedInstancesContext.Provider>
					)
				}
			</PreventNestedInstancesContext.Consumer>
		);
	};
}
