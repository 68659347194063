/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { type RenderFn } from '@atlassian/forge-ui-types';

export const ButtonSet = ({ forgeDoc: { children }, render }: Parameters<RenderFn>[0]) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			display: 'flex',
			flexWrap: 'wrap',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'> *': {
				marginTop: token('space.100', '8px'),
				marginRight: token('space.100', '8px'),
			},
			marginTop: token('space.negative.100', '-8px'),
			marginRight: token('space.negative.100', '-8px'),
		})}
	>
		{children.map(render)}
	</div>
);
