import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import type FeatureFlagClient from '@atlaskit/feature-flag-client';

import { SpaceDetailQuery } from './SpaceDetailQuery.graphql';

export type SpaceDetailDataType = {
	spaceId: string;
	type: string;
	dataClassificationTags: string[];
};

interface CommonHookResult {
	loading: boolean;
	error?: ApolloError;
}

export interface SpaceDetailType extends CommonHookResult {
	data?: SpaceDetailDataType;
}

export const useSpaceDetail = (
	spaceKey: string | null | undefined,
	featureFlagClient?: FeatureFlagClient,
): SpaceDetailType => {
	const isSpaceAliasFFEnabled = !!featureFlagClient?.getBooleanValue(
		'confluence.frontend.space.alias',
		{ default: false, shouldTrackExposureEvent: true },
	);

	const { loading, data, error } = useQuery(SpaceDetailQuery, {
		variables: {
			includeKey: isSpaceAliasFFEnabled,
			spaceKey,
		},
	});

	return {
		loading,
		data: data && {
			spaceId: data.space?.id,
			type: data.space?.type,
			dataClassificationTags: data.space?.dataClassificationTags,
		},
		error,
	};
};
