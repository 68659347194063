/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivitiesObjectType {
  BLOGPOST = "BLOGPOST",
  DATABASE = "DATABASE",
  EMBED = "EMBED",
  ISSUE = "ISSUE",
  PAGE = "PAGE",
  WHITEBOARD = "WHITEBOARD",
}

export enum ActivityEventType {
  ASSIGNED = "ASSIGNED",
  COMMENTED = "COMMENTED",
  CREATED = "CREATED",
  EDITED = "EDITED",
  LIKED = "LIKED",
  PUBLISHED = "PUBLISHED",
  TRANSITIONED = "TRANSITIONED",
  UNASSIGNED = "UNASSIGNED",
  UPDATED = "UPDATED",
  VIEWED = "VIEWED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomeRecentActivitiesQuery
// ====================================================

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_events {
  eventType: ActivityEventType | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_links {
  webui: string | null;
  editui: string | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_favourited {
  isFavourite: boolean | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser {
  favourited: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_favourited | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata_comments {
  commentsCount: number | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata {
  currentuser: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser | null;
  comments: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata_comments | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_space {
  alias: string | null;
  id: string | null;
  key: string | null;
  name: string | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_likes {
  count: number | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_editorVersion_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_editorVersion {
  nodes: (HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_editorVersion_nodes | null)[] | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_emoji_nodes {
  id: string | null;
  value: string | null;
  key: string | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_emoji {
  nodes: (HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_emoji_nodes | null)[] | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content {
  id: string | null;
  title: string | null;
  type: string | null;
  subType: string | null;
  links: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_links | null;
  excerpt: string;
  metadata: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_metadata;
  space: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_space | null;
  likes: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_likes | null;
  status: string | null;
  blank: boolean;
  editorVersion: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_editorVersion | null;
  emoji: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content_emoji | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object {
  id: string;
  events: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_events[] | null;
  content: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object_content | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges_node {
  id: string;
  timestamp: string | null;
  object: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node_object | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_edges {
  cursor: string;
  node: HomeRecentActivitiesQuery_activities_myActivities_all_edges_node | null;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all_pageInfo {
  hasNextPage: boolean;
}

export interface HomeRecentActivitiesQuery_activities_myActivities_all {
  edges: (HomeRecentActivitiesQuery_activities_myActivities_all_edges | null)[] | null;
  pageInfo: HomeRecentActivitiesQuery_activities_myActivities_all_pageInfo;
}

export interface HomeRecentActivitiesQuery_activities_myActivities {
  all: HomeRecentActivitiesQuery_activities_myActivities_all | null;
}

export interface HomeRecentActivitiesQuery_activities {
  myActivities: HomeRecentActivitiesQuery_activities_myActivities | null;
}

export interface HomeRecentActivitiesQuery {
  activities: HomeRecentActivitiesQuery_activities | null;
}

export interface HomeRecentActivitiesQueryVariables {
  cloudId: string;
  first?: number | null;
  after?: string | null;
  eventTypes?: ActivityEventType[] | null;
  objectTypes?: ActivitiesObjectType[] | null;
  includeAlias?: boolean | null;
}
