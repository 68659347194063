import type Cookie from 'js-cookie';

export enum AWCCookiesKeys {
	AWC_TLD_TEST = 'AWC_TLD_TEST',
	AWC_ANALYTICS_DEBUG = 'AWC_ANALYTICS_DEBUG',
	AJS_ANONYMOUS_ID = 'AJS_ANONYMOUS_ID',
}

class CookieSchema {
	key: string;
	options: Cookie.CookieAttributes;

	constructor(key: string, options: Cookie.CookieAttributes) {
		this.key = key;
		this.options = options;
	}

	getAttributes() {
		return this.options;
	}

	getKey() {
		return this.key;
	}
}

const isSecure = () => {
	try {
		return window && window.location && window.location.protocol === 'https:';
	} catch (err) {
		return false;
	}
};

export const AllAnalyticsCookies: Record<AWCCookiesKeys, CookieSchema> = {
	[AWCCookiesKeys.AWC_TLD_TEST]: new CookieSchema('__awc_tld_test__', {
		expires: 365,
		secure: isSecure(),
		path: '/',
	}),
	[AWCCookiesKeys.AWC_ANALYTICS_DEBUG]: new CookieSchema('atlassian_analytics_debug', {
		expires: 365,
		secure: isSecure(),
		path: '/',
	}),
	[AWCCookiesKeys.AJS_ANONYMOUS_ID]: new CookieSchema('ajs_anonymous_id', {
		expires: 365,
		secure: isSecure(),
		path: '/',
	}),
};
