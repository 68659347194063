import { Record } from 'immutable';

const User = Record({
	isAnonymous: false,
	displayName: null,
	fullName: null,
	email: null,
	avatarUrl: null,
	isCurrentUser: null,
	currentUserCanFollow: null,
	currentUserIsFollowing: null,
	currentUserCanViewProfile: null,
	detailsPosition: null,
	detailsDepartment: null,
	detailsLocation: null,
	hasPersonalSpace: null,
	hasBlog: null,
	accountId: null,
	unknownUser: null,
});

export default User;
