import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { WorkflowsLoader } from '@confluence/advanced-workflows/entry-points/Workflows';

export const WorkflowsRoute = () => <WorkflowsLoader />;

WorkflowsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'workflowsScreen' },
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
	},
});
