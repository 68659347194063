export enum ValidStartDateNames {
	CONTENT_COUNT,
	DEACTIVATED_OWNER_PAGES,
	PUBLIC_LINK_VIEWS,
}

export const VALID_START_DATES = new Map<ValidStartDateNames, Date>([
	// Sat April 20, 2024
	[ValidStartDateNames.CONTENT_COUNT, new Date('2024-04-20 00:00:00')],
	// Thu Mar 27, 2024
	[ValidStartDateNames.DEACTIVATED_OWNER_PAGES, new Date('2024-03-28 00:00:00')],
	// Wed Nov 01, 2023
	[ValidStartDateNames.PUBLIC_LINK_VIEWS, new Date('2023-11-01 00:00:00')],
]);

export const getValidStartDateByName = (name: ValidStartDateNames) => VALID_START_DATES.get(name);
