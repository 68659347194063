import React from 'react';
import { useIntl, defineMessages, type IntlShape } from 'react-intl-next';
import { Box, xcss } from '@atlaskit/primitives';
import { useUsers, type UserData } from '../../../web-client/hooks/useUser';
import { type UserProps, type RenderFn } from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import type ApolloClient from 'apollo-client';

import { useInlineContext } from '../../../context/inline';

const AKAvatarGroup = React.lazy(() => import('@atlaskit/avatar-group'));
const AKBadge = React.lazy(() => import('@atlaskit/badge'));

type AKAGData = { key: string; name: string; src: string };
export function usersToAKAGData(
	users: Record<string, UserData>,
	accountIds: string[],
	intl: IntlShape,
): AKAGData[] {
	// order should match accountIds
	return accountIds.map((key) => {
		const { name = intl.formatMessage(messages.unknownUserMessage), picture = '' } =
			users[key] || {};
		return { key, name, src: picture };
	});
}

export function AvatarStack({ data }: { data: AKAGData[] }) {
	return <AKAvatarGroup appearance="stack" data={data} />;
}

function More({ data }: { data: AKAGData[] }) {
	return <AKBadge>{`+${data.length}`}</AKBadge>;
}

export function UserGroupInline({ data }: { data: AKAGData[] }) {
	const end = Math.max(Math.min(8, data.length), 0); // 4 would be the same as AvatarStack
	const shown = data.slice(0, end);
	const more = data.slice(end);
	const hasMore = more.length > 0;

	return (
		<span>
			{shown.map(({ name }: AKAGData, i) => {
				return (
					<>
						<AKBadge appearance="primary">{name}</AKBadge>
						{hasMore || i !== end - 1 ? ' ' : null}
					</>
				);
			})}
			{hasMore ? <More data={more} /> : null}
		</span>
	);
}

type Dependencies = {
	client: ApolloClient<any> | undefined;
};

// @ts-ignore setting z index to 0 so that the avatars do not overlap other components
const avatarStackWrapper = xcss({ zIndex: 0, position: 'relative' });

export default function UserGroup({ accountIds, client }: { accountIds: string[] } & Dependencies) {
	const users = useUsers(accountIds, client);
	const intl = useIntl();
	const data = usersToAKAGData(users, accountIds, intl);
	const { inline } = useInlineContext();

	if (inline) {
		return <UserGroupInline data={data} />;
	}

	return (
		// Each avatar in an avatar stack has a z index value applied.
		// Setting a container with a z index here to 0 so that the avatars do no overlap other components such as the Select menu
		<Box xcss={avatarStackWrapper}>
			<AvatarStack data={data} />
		</Box>
	);
}

export function UserGroupFn({ children }: Props) {
	const accountIds = children
		.map((child) => {
			if (child.type !== 'User' && child.type !== 'Avatar') {
				return '';
			}

			const { props } = child;
			const { accountId } = props as UserProps;
			return accountId;
		})
		.filter((x) => x);

	return <UserGroup accountIds={accountIds} client={undefined} />;
}

// New-style component requires ApolloClient as a prop to make a function
// Avatar doesn't use `dispatch` so no upgrading/downgrading required
export function makeUserGroup({ client }: Dependencies): RenderFn {
	return function avatarStackNext({ forgeDoc: { children } }) {
		const accountIds = children
			.map((child) => {
				if (child.type !== 'User' && child.type !== 'Avatar') {
					return '';
				}

				const { props } = child;
				const { accountId } = props as UserProps;
				return accountId;
			})
			.filter((x) => x);

		return <UserGroup accountIds={accountIds} client={client} />;
	};
}

const messages = defineMessages({
	unknownUserMessage: {
		id: 'confluence.user.group.unknown.user.message',
		defaultMessage: 'Unknown User',
		description: "This is a fallback message that is visible when a user's name is unknown.",
	},
});
