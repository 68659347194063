import { query } from '@confluence/query-preloader-tools';

import { HomeViewsUserPreferencesQuery } from './HomeViewsUserPreferencesQuery.graphql';

export const preloadOptions = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
	if (!isLoggedIn) {
		return Promise.resolve({});
	}
	return query({ query: HomeViewsUserPreferencesQuery });
};
