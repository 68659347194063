import { LoadableAfterPaint } from '@confluence/loadable';

export const RedirectLegacyCopyPageLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RedirectLegacyCopyPage" */ './RedirectLegacyCopyPage'
			)
		).RedirectLegacyCopyPage,
});

(RedirectLegacyCopyPageLoader as any).NAVIGATION_PARAMS = () => false;
