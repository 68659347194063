import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

import configure from './rest-api-client';

export const getTenantId = (state) => state.views.dashboard.get('tenantId', null);
const getAtlToken = (state) => state.meta.get('atl-token', null);

const getFields = createSelector(getTenantId, getAtlToken, (tenantId, atlToken) =>
	Map({
		tenantId,
		atlToken,
	}),
);

function hasChanged(previousState, state) {
	return previousState !== state;
}

let cache = Map({});

function configureRestApiClient(store) {
	const newState = getFields(store.getState());

	if (hasChanged(cache, newState)) {
		cache = newState;
		configure(newState.toJS());
	}
}

export const debouncedConfigureRestApiClient = debounce(configureRestApiClient, 500);
