import { isApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

import type { BadStatusError } from '@confluence/network';

export function isStatusCodeError(
	error: Error | null | undefined,
	statusCode: string,
	statusCodeAlternative?: (error: Error) => boolean,
): boolean {
	if (error) {
		if (isApolloError(error)) {
			return (
				error.graphQLErrors?.some((graphQLError) =>
					isStatusCodeNonApolloError(graphQLError, statusCode, statusCodeAlternative),
				) || isStatusCodeNonApolloError(error?.networkError, statusCode, statusCodeAlternative)
			);
		}

		return isStatusCodeNonApolloError(error, statusCode, statusCodeAlternative);
	}

	return false;
}

export function getStatusCodeError(error: Error | null | undefined): string | undefined {
	if (error) {
		if (isApolloError(error)) {
			for (const graphQLError of error?.graphQLErrors || []) {
				const result = getStatusCodeNonApolloError(graphQLError);
				if (result) {
					return result;
				}
			}
			const networkErrorStatusCode = getStatusCodeNonApolloError(error?.networkError);
			if (networkErrorStatusCode) {
				return networkErrorStatusCode;
			}
		}

		return getStatusCodeNonApolloError(error);
	}

	return undefined;
}

function isStatusCodeNonApolloError(
	error: Error | null | undefined,
	statusCode: string,
	statusCodeAlternative?: (error: Error) => boolean,
): boolean {
	if (error) {
		if (getStatusCode(error) === statusCode || statusCodeAlternative?.(error)) {
			return true;
		}

		const { originalError } = error as GraphQLError;
		if (originalError) {
			return isStatusCodeError(originalError, statusCode, statusCodeAlternative);
		}
	}

	return false;
}

function getStatusCodeNonApolloError(error: Error | null | undefined): string | undefined {
	if (error) {
		const result = getStatusCode(error);
		if (result) {
			return result;
		}

		const { originalError } = error as GraphQLError;
		if (originalError) {
			return getStatusCode(originalError);
		}
	}

	return undefined;
}

export function getStatusCode(error: Error): string | undefined {
	if (error) {
		let status: number | string = (error as BadStatusError)?.response?.status;

		if (status == null) {
			const { extensions } = error as GraphQLError;
			if (extensions) {
				status = extensions.statusCode || extensions.http?.status;
			}
		}

		// Coercing to string because backend is inconsistent in the response type,
		// both string and number have been seen in practice:
		return status?.toString();
	}

	return undefined;
}
