import gql from 'graphql-tag';

export const NativeCollabQuery = gql`
	query NativeCollabQuery($contentId: ID, $draftShareId: String, $status: [String]) @SLA {
		content(id: $contentId, draftShareId: $draftShareId, status: $status) {
			nodes {
				id
				type
				status
				version {
					number
					ncsStepVersion
					by {
						type
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
				history {
					createdBy {
						displayName
					}
					createdDate
				}
				space {
					id
					name
					homepageId
				}
				labels {
					nodes {
						id
						name
					}
				}
				subType
			}
		}
	}
`;
