import type { ThemeState } from '@atlaskit/tokens';
import { themeObjectToString, themeStringToObject } from '@atlaskit/tokens';

import { buildThemeFromState } from './buildTheme';
import { getDefaultTheme } from './defaultTheme';

// No preference...previously meant "Original" and will become to mean "Light"
export const API_DEFAULT_THEME_STATE = 'default';

// Explicitly chose "Original"...deprecated
export const API_ORIGINAL_THEME_STATE = 'original';

export const getEffectiveThemeState = (themeStateSerialized: string): Partial<ThemeState> => {
	if ([API_ORIGINAL_THEME_STATE, API_DEFAULT_THEME_STATE].includes(themeStateSerialized)) {
		return getDefaultTheme();
	}

	return buildThemeFromState(themeStringToObject(themeStateSerialized));
};

export const getEffectiveThemeStateSerialized = (
	themeStateOrDefault:
		| Partial<ThemeState>
		| typeof API_DEFAULT_THEME_STATE
		| typeof API_ORIGINAL_THEME_STATE,
): string => {
	if (
		themeStateOrDefault === API_DEFAULT_THEME_STATE ||
		themeStateOrDefault === API_ORIGINAL_THEME_STATE
	) {
		return themeStateOrDefault;
	}

	return themeObjectToString(themeStateOrDefault);
};
