import { Cache } from '../cache';

import type { Listener, Message, MessageMap } from './types';
import { EventIndex } from './EventIndex';

/**
 * Event Bus
 *
 * A basic pub sub interface for streaming any kind of
 * real time events around Confluence. Subscriptions
 * resolve in micro-tasks to reduce blocking time.
 */
export class EventBus<T extends MessageMap = MessageMap> extends Map<keyof T, EventIndex<Message>> {
	private enableCache = false;
	public Cache = new Cache<Record<keyof T, Message[]>>();
	constructor(cache?: boolean) {
		super();
		if (cache) {
			this.enableCache = true;
		}
	}

	/**
	 * Stream
	 *
	 * Adds a stream of messages to the bus and invokes active
	 * subscriptions to the corresponding message types
	 */
	public async stream(events: T[keyof T][]) {
		if (!events.length) {
			return;
		}
		const hash: Record<string, Message[]> = {};
		for (const event of events) {
			if (event.type in hash) {
				hash[event.type].push(event);
			} else {
				hash[event.type] = [event];
			}
			this.cache(event.type, event);
		}
		await Promise.all(
			Object.keys(hash).map((key) => {
				return this.dispatch(key, hash[key]);
			}),
		);
	}

	/**
	 * On
	 *
	 * Allows for registering listeners to incoming message types. Simply
	 * pass the message type you wish to receive notifications for and
	 * a callback to invoke
	 */
	public on<E extends keyof T>(event: E, callback: Listener<T[E]>) {
		const index = this.get(event) || new EventIndex();
		const ID = (index as unknown as EventIndex<T[E]>).subscribe(callback);
		this.set(event, index);
		return ID;
	}

	/**
	 * Off
	 *
	 * Removes an event listener by event-name and ID
	 */
	public off<E extends keyof T>(event: E, ID: string) {
		const index = this.get(event);
		if (index) {
			index.unsubscribe(ID);
			if (index.size === 0) {
				this.delete(event);
			}
		}
	}

	/**
	 * Dispatch
	 *
	 * Invokes all subscriptions for a given event type. Each listener
	 * receives its corresponding messages as an argument
	 */
	private async dispatch(event: string, ...params: Parameters<Listener<Message>>) {
		const index = this.get(event);
		if (index) {
			await index.dispatch(params);
		}
	}

	/**
	 * Cache
	 *
	 * When caching is enabled, the Event Bus can act as a
	 * database for historical message accumulation. To enable
	 * this feature, pass `enableCache: true` to the constructor
	 */
	private cache(key: string, value: Message) {
		if (!this.enableCache) {
			return;
		}
		const values = this.Cache.get(key) || [];
		values.push(value);
		this.Cache.set(key, values);
	}
}
