import { Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';
import { SSRMeasures } from '@confluence/action-measures';
import { cfetch } from '@confluence/network';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';
import { constructCompatibleRequestInit } from '@confluence/network/entry-points/constructCompatibleRequestInit';
import type { PrefetchedConsentPreferences } from '@atlassian/browser-storage-controls';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';

declare global {
	interface Window {
		__COOKIE_CONSENT_PREFERENCES__:
			| PrefetchedConsentPreferences
			| Promise<PrefetchedConsentPreferences | null>
			| null;
		__SSR_RENDER_PARAMS__: Record<string, any>;
	}
}

export const COOKIE_PREFERENCES_QUERY = 'CookiePreferencesQuery';

export const preloadCookiePreferences = async () => {
	if (window.__COOKIE_CONSENT_PREFERENCES__) {
		return window.__COOKIE_CONSENT_PREFERENCES__;
	}

	let hostEnv = 'prod';
	const { environment } = await getPreloaderFnContext();
	if (environment !== 'PRODUCTION') {
		hostEnv = 'staging';
	}

	const { cookiesConsentToken, cookiesFallbackToken, userId, perimeter } = getSSRRenderInputs();

	// If the consent token cookie exist (user has already set their preferences), there is no need to
	// fetch the preferences from ConsentHub
	if (cookiesConsentToken && cookiesFallbackToken) {
		window.__COOKIE_CONSENT_PREFERENCES__ = {
			consentToken: cookiesConsentToken,
			fallbackConsentToken: cookiesFallbackToken,
		} as PrefetchedConsentPreferences;
		return window.__COOKIE_CONSENT_PREFERENCES__;
	}

	// If we have no userId, or we are in FedRAMP, don't preload the consent preferences
	if (!userId || perimeter === 'fedramp-moderate') {
		window.__COOKIE_CONSENT_PREFERENCES__ = null;
		return window.__COOKIE_CONSENT_PREFERENCES__;
	}

	window.__COOKIE_CONSENT_PREFERENCES__ = SSRMeasures.run(
		'ssr-app/prepare/preloadQuery/fetch:CookieConsentPreferences',
		async () => {
			let result = null;
			try {
				const res = await cfetch(
					`https://atl-preferences-center-elb.${hostEnv}.atl-paas.net/rest/user/${userId}/consents/cookies`,
					constructCompatibleRequestInit({
						method: 'GET',
						credentials: 'include',
						headers: {
							'Content-Type': 'application/json',
						},
					}),
				);
				if (!res.ok) {
					throw new Error(`Cannot load Cookie Consent Preferences: ${res.statusText}`);
				}
				result = await res.json();
			} catch (e) {
				getMonitoringClient().submitError(e, {
					attribution: Attribution.BACKBONE,
				});
			}

			if (result && Object.keys(result).length) {
				window.__COOKIE_CONSENT_PREFERENCES__ = Object.assign({}, result, {
					fallbackConsentToken: cookiesFallbackToken,
				});
			} else {
				window.__COOKIE_CONSENT_PREFERENCES__ = null;
			}
			return window.__COOKIE_CONSENT_PREFERENCES__;
		},
	);
	return window.__COOKIE_CONSENT_PREFERENCES__;
};
