import { type ForgeDoc } from '@atlassian/forge-ui-types';
import uuid from 'uuid/v4';
import type React from 'react';

const Fragment = 'Fragment';

// Handles the different cases of React's fragment component
const isReactFragment = (type: unknown) =>
	type === 'Symbol(react.fragment)' || type === 'fragment' || type === undefined;

// Filter out any children that contain only whitespace
const filterEmptyReactChildren = (children: React.ReactElement | React.ReactElement[]) => {
	// Ensuring children is an array here as single elements will be an object
	// React.Children.toArray is not used as certain components could be passing JSX as an object to elementToForgeDoc
	const childrenAsArray = Array.isArray(children) ? children : [children];
	return childrenAsArray.filter(
		(child: React.ReactElement | string) =>
			!!child && (typeof child !== 'string' || child.trim() !== ''),
	) as React.ReactElement[];
};

type Element = string | number | React.ReactElement | React.ReactElement[];

// converts react element(s) to forgeDoc(s) recursively
// and additionally prune and cleanup unnecessary empty fragments
const elementToForgeDocConversionWithFragmentHandling = (
	elem: Element,
	isRoot: boolean = false,
): ForgeDoc | ForgeDoc[] => {
	// Handles string child for components
	if (typeof elem === 'string' || typeof elem === 'number') {
		return {
			type: 'String',
			props: {
				text: elem,
			},
			children: [],
		};
	} else if (Array.isArray(elem)) {
		return elem.flatMap((e) => elementToForgeDocConversionWithFragmentHandling(e));
	} else if (typeof elem === 'object') {
		let type = elem.type?.toString();
		const key = uuid();

		if (isReactFragment(type)) {
			type = Fragment;
			if (!isRoot) {
				const { children } = elem.props;
				return elementToForgeDocConversionWithFragmentHandling(filterEmptyReactChildren(children));
			}
		}

		// Remove children from props as we handle them separately
		const { children, ...rest } = elem.props;
		const props = rest;

		return {
			type,
			key,
			props,
			children: elementToForgeDocConversionWithFragmentHandling(
				filterEmptyReactChildren(children),
			) as ForgeDoc[],
		};
	}
	throw new Error(`Can\'t create ForgeDoc from ${elem}`);
};

// This function simulates the same behaviour of the ForgeReconciler from `@forge/react`
// to be used for converting elements to forgedoc within this package
export const elementToForgeDoc = (elem: Element): ForgeDoc => {
	const forgeDoc = elementToForgeDocConversionWithFragmentHandling(elem, true);
	if (Array.isArray(forgeDoc)) {
		throw new Error('Multiple root fragments are not supported');
	}
	return forgeDoc;
};
