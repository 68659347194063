import { query } from '@confluence/query-preloader-tools';

import { getEmojiTitleProperty } from './EmojiTitleQuery.graphql';
import type {
	getEmojiTitleProperty as getEmojiTitlePropertyType,
	getEmojiTitlePropertyVariables as getEmojiTitlePropertyVariablesType,
} from './__types__/getEmojiTitleProperty';

export const preloadEmojiTitle = (
	contentId: getEmojiTitlePropertyVariablesType['contentId'],
	draftShareId: getEmojiTitlePropertyVariablesType['draftShareId'],
	useCache: boolean,
) => {
	return query<getEmojiTitlePropertyType, getEmojiTitlePropertyVariablesType>({
		query: getEmojiTitleProperty,
		variables: {
			contentId,
			draftShareId,
			status: ['current', 'draft'],
			skipPublishedFragment: false,
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	});
};
