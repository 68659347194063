import { parse, format } from 'url';

import window from 'window-or-global';

import { Cookies } from '@confluence/cookies';

const CLASSIC_BRANCH = 'useFrontendBranch';
const BUILD_VERSION = 'useFrontendBuildVersion';
const REGEX = /[^A-Za-z0-9\-]/g;

async function setCookie(key, value) {
	const original = Cookies.get(key);
	if (value === 'off' || (key === BUILD_VERSION && !value)) {
		Cookies.remove(key);
	} else if (value) {
		await Cookies.set(key, decodeURIComponent(value).replace(REGEX, '-'));
	}

	return Cookies.get(key) !== original;
}

export async function setFrontendBranch() {
	const urlComponents = parse(window.location.href, true);

	let branchName = null;
	let buildVersion = null;
	if (
		urlComponents.query &&
		(urlComponents.query.useFrontendBranch || urlComponents.query.useFrontendBuildVersion)
	) {
		branchName = urlComponents.query.useFrontendBranch;
		buildVersion = urlComponents.query.useFrontendBuildVersion;
		const branchVersionChanged = await setCookie(CLASSIC_BRANCH, branchName);
		const buildVersionChanged = await setCookie(BUILD_VERSION, buildVersion);

		if (branchVersionChanged || buildVersionChanged) {
			window.location.assign(
				format({
					pathname: urlComponents.pathname,
					query: urlComponents.query,
					hash: urlComponents.hash,
				}),
			);
		}
	}
}
