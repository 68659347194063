import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ForgeGlobalPageExtensionPoint } from '@confluence/forge-ui/entry-points/GlobalPage';

export const GlobalPageForgeAppsRoute = ({
	params: { appId, envId, forgeManifestRoute },
}: RouteMatch) => (
	<ForgeGlobalPageExtensionPoint
		key={`${appId}-${envId}-${forgeManifestRoute}`}
		appId={appId}
		envId={envId}
		forgeManifestRoute={forgeManifestRoute}
	/>
);

GlobalPageForgeAppsRoute.NAVIGATION_PARAMS = ({
	params: { appId, envId, forgeManifestRoute },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'globalPageScreen',
			id: appId,
			attributes: { appId, envId, forgeManifestRoute },
		},
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
	},
});
