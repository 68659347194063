import type { EventMatcher } from './allowedEvents';
import { defaultEventMatchers } from './allowedEvents';
import { FILTER_EVENTS_FF, FILTER_EVENTS_EXEMPTIONS_FF } from './featureFlag';

export type EventFilterOptions = {
	shouldFilterEvents: boolean;
	eventMatchers: EventMatcher[];
};

/**
 * Gathers the status of any feature flags registered to block or allow certain events
 * from being sent via Analytics-Web-Client
 */
export const getEventFilterOptions = (
	featureFlags: Record<string, string | boolean>,
): EventFilterOptions => {
	const shouldFilterEvents = Boolean(featureFlags[FILTER_EVENTS_FF] ?? false);
	let extraMatchersFromFeatureFlag: EventMatcher[] = [];

	const exemptionsFlagValue = featureFlags[FILTER_EVENTS_EXEMPTIONS_FF];
	if (typeof exemptionsFlagValue === 'string') {
		try {
			const parsedMatchers = JSON.parse(exemptionsFlagValue);
			if (Array.isArray(parsedMatchers)) {
				extraMatchersFromFeatureFlag = parsedMatchers;
			}
		} catch (e) {
			// Noop, if an error occurs here, there's nowhere to submit it. The MonitoringClient
			// itself depends on analytics-web-client being initialized successfully.
			// eslint-disable-next-line no-console
			console.error(
				`Failed to parse feature flag "${FILTER_EVENTS_EXEMPTIONS_FF}" with error: ${e.message}`,
			);
		}
	}

	return {
		shouldFilterEvents,
		eventMatchers: [...defaultEventMatchers, ...extraMatchersFromFeatureFlag],
	};
};
