import gql from 'graphql-tag';

export const FavouriteButtonQuery = gql`
	query FavouriteButtonQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			type
			metadata {
				currentuser {
					favourited {
						isFavourite
					}
				}
			}
			operations {
				operation
				targetType
			}
		}
	}
`;
