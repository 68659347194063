import React from 'react';

import { isFedRamp } from '@atlassian/atl-context';
// import FeatureGates from '@atlaskit/feature-gate-js-client';
// import { Box } from '@atlaskit/primitives';

import { SITE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE } from '@confluence/confluence-analytics/entry-points/analyticsConstants';
import {
	SITE_ANALYTICS_PERMISSIONS_PLUGIN,
	FEDRAMP_SITE_ANALYTICS_PERMISSIONS_PLUGIN,
} from '@confluence/named-routes';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { Redirection } from '@confluence/route-manager';

export const SiteAnalyticsPermissionsRoute = () => {
	// //TODO - CCIR-11: Clean up this feature gate once the migration is complete
	// const isAnalyticsUiMigrationEnabled = FeatureGates.checkGate(
	// 	'confluence_frontend_analytics_ui_migration',
	// );
	// if (!isAnalyticsUiMigrationEnabled) {
	// 	const isFedrampEnv = isFedRamp();

	// 	const url = isFedrampEnv
	// 		? FEDRAMP_SITE_ANALYTICS_PERMISSIONS_PLUGIN.toUrl()
	// 		: SITE_ANALYTICS_PERMISSIONS_PLUGIN.toUrl();
	// 	return <Redirection href={url} />;
	// }

	// return <Box padding="space.1000">Site Analytics Permissions will go here</Box>;

	// The new SPA version of the Site Analytics Permissions page hasn't been implemented but we want
	// to turn on the other new Analytics routes, so hardcode this to the old plugin version for now
	const url = isFedRamp()
		? FEDRAMP_SITE_ANALYTICS_PERMISSIONS_PLUGIN.toUrl()
		: SITE_ANALYTICS_PERMISSIONS_PLUGIN.toUrl();
	return <Redirection href={url} />;
};

SiteAnalyticsPermissionsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: SITE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
