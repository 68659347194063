import { type NodeType } from '@atlaskit/editor-prosemirror/model';
import { type EditorState, type Selection } from '@atlaskit/editor-prosemirror/state';

export const getSupportedNodesForAIModal = ({ schema: { nodes } }: EditorState) =>
	new Set<NodeType>([
		nodes.expand,
		nodes.layoutSection,
		nodes.nestedExpand,
		nodes.panel,
		nodes.table,
	]);

export const isCellSelection = (state: EditorState, selection?: Selection) => {
	const { tableHeader, tableCell, tabeRow, table } = state.schema.nodes;
	const { $from } = selection || state.selection;
	const parent = $from.parent;
	return [tableHeader, tableCell, tabeRow, table].includes(parent.type);
};
