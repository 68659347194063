import { Record } from 'immutable';

const Version = Record({
	id: null,
	by: null,
	when: null,
	message: null,
	friendlyWhen: null,
	number: null,
	syncRev: null,
	confRev: null,
});

export default Version;
