import { Record } from 'immutable';

const Label = Record({
	id: null,
	name: null,
	prefix: null,
});

export default Label;

export const ErrorLabelRecord = Record({
	message: '',
	messageKey: '',
	args: [],
});
