import gql from 'graphql-tag';

import { PageCardContentFragment } from '@confluence/page-card/entry-points/PageCardContent.fragment';

const HomeActivitiesConnectionFragment = gql`
	fragment HomeActivitiesConnectionFragment on ActivitiesConnection {
		edges {
			cursor
			node {
				id
				timestamp
				object {
					id
					events {
						eventType
					}
					content {
						...PageCardContentFragment
						status
						blank
						editorVersion: properties(key: "editor") {
							nodes {
								id
								key
								value
							}
						}
						emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
							nodes {
								id
								value
								key
							}
						}
					}
				}
			}
		}
		pageInfo {
			hasNextPage
		}
	}
`;

export const HomeRecentUserPreferencesQuery = gql`
	query HomeRecentUserPreferencesQuery {
		userPreferences {
			recentFilter
		}
	}
`;

export const HomeRecentUserPreferencesMutation = gql`
	mutation HomeRecentUserPreferencesMutation($filter: RecentFilter) {
		updateUserPreferences(userPreferences: { recentFilter: $filter }) {
			recentFilter
		}
	}
`;

export const HomeRecentActivitiesQuery = gql`
	query HomeRecentActivitiesQuery(
		$cloudId: ID!
		$first: Int
		$after: String
		$eventTypes: [ActivityEventType!]
		$objectTypes: [ActivitiesObjectType!]
		$includeAlias: Boolean = false
	) {
		activities {
			myActivities {
				all(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								objectTypes: $objectTypes
								cloudIds: [$cloudId]
								eventTypes: $eventTypes
							}
						}
					]
					first: $first
					after: $after
				) {
					...HomeActivitiesConnectionFragment
				}
			}
		}
	}
	${PageCardContentFragment}
	${HomeActivitiesConnectionFragment}
`;

export const HomeRecentCreatedQuery = gql`
	query HomeRecentCreatedQuery(
		$cql: String!
		$cqlcontext: String!
		$first: Int
		$after: String
		$includeAlias: Boolean = false
	) {
		search(cql: $cql, cqlcontext: $cqlcontext, first: $first, after: $after) {
			nodes {
				content {
					...PageCardContentFragment
					status
					blank
					metadata {
						createdDate
					}
					editorVersion: properties(key: "editor") {
						nodes {
							id
							key
							value
						}
					}
					emoji: properties(keys: ["emoji-title-published"]) {
						nodes {
							id
							value
							key
						}
					}
					history {
						createdBy {
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
					}
					version {
						by {
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${PageCardContentFragment}
`;
