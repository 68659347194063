import { useContext, useEffect } from 'react';

import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';

type ApolloOperation = 'query' | 'subscription' | 'mutation';
type ApolloRenderProps = { loading: boolean; [x: string]: any };
type TChildrenArgs = [ApolloRenderProps, ...any] | [any, ApolloRenderProps, ...any];

export const withUFOHold = <TChildren extends ((...args: any[]) => any) | null | undefined>(
	operationType: ApolloOperation,
	operationName: string,
	children: TChildren,
) => {
	const UFOWrappedChildren = (...childrenArgs: TChildrenArgs) => {
		const { loading } = operationType === 'mutation' ? childrenArgs[1] : childrenArgs[0];
		const ufoContext = useContext(UFOInteractionContext);
		const isChildrenAvailable = children !== null && children !== undefined;

		useEffect(() => {
			if (loading || !isChildrenAvailable) {
				return ufoContext?.hold(operationName);
			}
		}, [loading, ufoContext, isChildrenAvailable]);

		if (!isChildrenAvailable) {
			return null;
		}

		return children(...childrenArgs);
	};

	return UFOWrappedChildren;
};
