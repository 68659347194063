import { AnalyticsContext } from '@atlaskit/analytics-next';
import { type FunctionComponent, createContext, useContext } from 'react';

export type RecommendationContext = {
	sessionId?: string;
	entityId?: string;
};

export const RecommendationContext = createContext<RecommendationContext>({
	sessionId: undefined,
	entityId: undefined,
});

export const RecommendationProvider: FunctionComponent<
	React.PropsWithChildren<{ value: RecommendationContext }>
> = ({ value, children }) => {
	return (
		<RecommendationContext.Provider value={value}>
			<AnalyticsContext data={value}>{children}</AnalyticsContext>
		</RecommendationContext.Provider>
	);
};

export const useRecommendationContext = () => useContext(RecommendationContext);
