import gql from 'graphql-tag';

export const HubInfoQuery = gql`
	query HubInfoQuery($key: String!) {
		space(key: $key) {
			id
			homepageV2 {
				version {
					number
					by {
						displayName
						profilePicture {
							path
						}
					}
					when
				}
			}
		}
	}
`;
