import { createContext } from 'react';

import type { DirectoryInvitePeopleSource } from '@atlassian/ptc-embeddable-directory';
import type { InviteeListOption } from '@atlassian/invite-people/types';

import type { UserRole } from './types';
import type { CoreInvitesSource } from './constants';

export type CoreInvitesCallbacks = {
	onModalClosed?: () => void;
	onInviteCompleted?: () => void;
	getContextualInviteNudgeProps?: () => {
		showSpotlight: boolean;
	};
};

export type OpenCoreInvitesFunction = (
	touchPoint?: CoreInvitesSource | DirectoryInvitePeopleSource,
	callbacks?: CoreInvitesCallbacks,
	invitees?: InviteeListOption[],
) => void;

export type CoreInvitesContextType = {
	openCoreInvites?: OpenCoreInvitesFunction;
	userRole?: UserRole;
};

export const CoreInvitesContext = createContext<CoreInvitesContextType>({});
