import idx from 'idx';
import uuid from 'uuid/v4';

import type { ExtensionParams } from '@atlaskit/editor-common/extensions';

import { objectHash } from '@confluence/object-hash';

import type { MacroAttributes } from './types';

export const JIRA_EXTENSION_KEY = 'jira';
export const JIRA_ISSUE_COUNT = 'issue-count';
export const JIRA_TABLE_VIEW = 'table-view';
export const JIRA_SINGLE_ISSUE = 'single-issue';

export const getMacroId = (parameters): string => {
	if (!parameters) return uuid();

	const idFromNode = idx(parameters, (_) => _.macroMetadata.macroId.value);

	if (idFromNode) {
		return idFromNode;
	}

	// for macro that does not have unique macro id, we want to generate some unique id
	return objectHash(parameters);
};

export const getExtensionKey = (attributes: MacroAttributes | ExtensionParams<any>): string => {
	if (!attributes) return '';

	const { extensionKey } = attributes;
	const macroParams = idx(attributes, (_) => _.parameters.macroParams);

	if (extensionKey === JIRA_EXTENSION_KEY)
		return `${extensionKey}${getJiraIssueMacroType(macroParams)}`;

	return extensionKey;
};

// jira issue macro has 4 different type: issue-count, table-view, single-issue and jirachart
// issue-count, table-view, single-issue has the same extensionKey 'jira'
// jirachart has the extensionKey 'jirachart'
export const getJiraIssueMacroType = (macroParams): string => {
	const prefix = '-';
	if (idx(macroParams, (_) => _.count.value) === 'true') {
		return prefix + JIRA_ISSUE_COUNT;
	} else if (idx(macroParams, (_) => _.columns) && idx(macroParams, (_) => _.maximumIssues)) {
		return prefix + JIRA_TABLE_VIEW;
	} else if (idx(macroParams, (_) => _.key.value)) {
		return prefix + JIRA_SINGLE_ISSUE;
	}

	return '';
};
