/** @jsxFrag jsx fragment pragma required */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { RenderFn } from '@atlassian/forge-ui-types';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

export const Fragment = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => (
	<>{forgeDoc.children.map(render)}</>
);

export const Root = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { children } = forgeDoc;
	return (
		<div
			/** Editor applies specific styles to components in .renderer-overrides that affects Forge apps so overrides are required here */
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'> *:not(.fabric-editor-block-mark)': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'h1, h2, h3, h4, h5, h6': {
						marginTop: 0,
					},
				},
			})}
		>
			{children.map(render)}
		</div>
	);
};
