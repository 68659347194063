import { query } from '@confluence/query-preloader-tools';

import { EndOfPageRecommendationQuery } from './EndOfPageRecommendationQuery.graphql';

export function preloadEndOfPageRecommendation({ contentId, spaceKey }) {
	return query({
		query: EndOfPageRecommendationQuery,
		variables: {
			type: 'page',
			id: contentId,
			exp: 'end_of_page',
			key: spaceKey,
		},
		// Must be preloaded with network only to get fresh data
		fetchPolicy: 'network-only',
	});
}
