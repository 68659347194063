/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { lazy, Fragment } from 'react';
import {
	type FieldChildrenProps,
	type NativeRadioGroupProps,
	type RadioOption,
	type RadioProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { FormStateChangeNotifier } from '../form';
import { useIsInForm } from '../utils/useIsInForm';
import { Label } from '../label';

const styles2 = css({
	lineHeight: '20px',
});
const styles = css({
	lineHeight: '20px',
});
const Field = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);
const AkRadioGroup = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.radio' */
			'@atlaskit/radio/RadioGroup'
		),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

interface AKRadioGroupProps {
	options: RadioOption[];
	defaultValue?: string;
}

const FormRadioGroup: React.FunctionComponent<
	Omit<NativeRadioGroupProps, 'children'> & AKRadioGroupProps
> = ({ options, defaultValue, isRequired, label, description, name, onChange }) => {
	return (
		<Field defaultValue={defaultValue} isRequired={isRequired} label={label} name={name}>
			{({ fieldProps }: FieldChildrenProps) => {
				const {
					isRequired,
					isDisabled,
					isInvalid,
					value,
					'aria-invalid': ariaInvalid,
					'aria-labelledby': ariaLabelledBy,
				} = fieldProps;

				return (
					<Fragment>
						<FormStateChangeNotifier name={name} value={fieldProps.value} />
						{description && <AKHelperMessage>{description}</AKHelperMessage>}
						<div css={styles}>
							<AkRadioGroup
								isRequired={isRequired}
								isDisabled={isDisabled}
								isInvalid={isInvalid}
								value={value}
								aria-invalid={ariaInvalid}
								aria-labelledby={ariaLabelledBy}
								options={options}
								onChange={(e) => {
									fieldProps.onChange(e);
									onChange?.(e.target.value);
								}}
							/>
						</div>
					</Fragment>
				);
			}}
		</Field>
	);
};

const RadioGroupInner: React.FunctionComponent<
	Omit<NativeRadioGroupProps, 'children'> & AKRadioGroupProps
> = ({ options, onChange, label, name, isRequired, description, defaultValue }) => {
	return (
		<div css={styles2}>
			<Label name={name} isRequired={isRequired} label={label} />
			{description && <AKHelperMessage>{description}</AKHelperMessage>}
			<AkRadioGroup
				defaultValue={defaultValue}
				options={options}
				onChange={(e) => onChange?.(e.target.value)}
			/>
		</div>
	);
};

export const RadioGroup = (props: Parameters<RenderFn>[0]) => {
	const { label, name, isRequired, description, onChange } = props.forgeDoc
		.props as NativeRadioGroupProps;

	const children = props.forgeDoc.children;

	let defaultValue;
	const options: RadioOption[] = children
		.map((child) => {
			const { props, type } = child;
			if (type === 'Radio') {
				const { label, value, defaultChecked } = props as RadioProps;
				if (defaultChecked) {
					defaultValue = value;
				}

				return {
					name,
					label,
					value,
				};
			}
		})
		.filter((option): option is RadioOption => typeof option !== 'undefined');

	const isInForm = useIsInForm();
	if (!isInForm) {
		return (
			<RadioGroupInner
				label={label}
				name={name}
				options={options}
				defaultValue={defaultValue}
				description={description}
				isRequired={isRequired}
				onChange={onChange}
			/>
		);
	}

	return (
		<FormRadioGroup
			label={label}
			name={name}
			options={options}
			defaultValue={defaultValue}
			description={description}
			isRequired={isRequired}
			onChange={onChange}
		/>
	);
};
