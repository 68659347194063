import React, { memo } from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import * as Components from '.';
import { forgePropsAreEqual } from '../utils/forgePropsAreEqual';

export const importMap: Record<string, (props: Parameters<RenderFn>[0]) => JSX.Element> = {
	BarChart: (args) => <Components.BarChart {...args} />,
	StackBarChart: (args) => <Components.StackBarChart {...args} />,
	HorizontalStackBarChart: (args) => <Components.HorizontalStackBarChart {...args} />,
	HorizontalBarChart: (args) => <Components.HorizontalBarChart {...args} />,
	LineChart: (args) => <Components.LineChart {...args} />,
	SingleValueChart: (args) => <Components.SingleValueChart {...args} />,
	PieChart: (args) => <Components.PieChart {...args} />,
};

export const importMapChartsMemo = Object.entries(importMap).reduce<typeof importMap>(
	(agg, [key, Component]) => {
		agg[key] = memo(Component, forgePropsAreEqual) as (typeof importMap)[keyof typeof importMap];
		return agg;
	},
	{},
);
