import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type { Positions } from '../../config-items/config-items';

type isMinLengthParams = {
	editorView: EditorView;
	minLength: number;
	positions?: Positions;
};

/**
 * Checks if the selection is equal to or longer than the minimum length.
 *
 * If no selection exists it will check if the document is equal too or longer than the minimum length.
 * Includes whitespace, like empty paragraphs, when calculating the length.
 */
// NOTE: To match the backend minimum length we should parse
// to markdown and check the length of the markdown string.
export function isMinLength({ editorView, minLength, positions }: isMinLengthParams): boolean {
	// If there is no selection
	// Or there is an empty selection and the FF is enabled
	if (!positions || (positions && positions[0] === positions[1])) {
		const doc = editorView.state.doc;
		// Size of the doc with a single empty paragraph is 4
		const positionDiff = doc.nodeSize - 4;
		return positionDiff >= minLength;
	}
	// If there is a selection
	else if (positions && positions[0] !== positions[1]) {
		const positionDiff = positions[1] - positions[0];
		return positionDiff >= minLength;
	}
	// If positions are equal or undefined
	else {
		return 0 >= minLength;
	}
}
