/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::316217c5b24ed9a9132596a333ceecd6>>
 * @codegenId #modal-title
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-title
 * @codegenParams { "props": { "appearance": true, "children": true, "isMultiline": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-title.tsx <<SignedSource::be9044ff36bbeadfcb3e9f6d81563bef>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::bdfd75a709534a0de60ff52436ad515a>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/constants.tsx <<SignedSource::b046819e1c384364b93a2f5e229c2e92>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/types.tsx <<SignedSource::69859d598b31939a4be80d243c9fef7e>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalTitle as PlatformModalTitle } from '@atlaskit/modal-dialog';

type PlatformModalTitleProps = React.ComponentProps<typeof PlatformModalTitle>;

export type ModalTitleProps = Pick<
  PlatformModalTitleProps,
  'appearance' | 'children' | 'isMultiline' | 'testId'
>;

export const ModalTitle = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    isMultiline,
    testId,
  } = props.forgeDoc.props as ModalTitleProps;
  return (
    <PlatformModalTitle
      appearance={appearance}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      isMultiline={isMultiline}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
