import {
	EDIT_PAGE_EMBED,
	EDIT_PAGE_V2,
	EDIT_BLOG_V2,
	EDIT_PAGE,
	EDIT_BLOG,
} from '@confluence/named-routes';
import { ExperienceActionMeasures } from '@confluence/action-measures';

import {
	EDIT_ROUTE_CHANGE_ACTION,
	EDIT_ROUTE_CHANGE_TO_TTI_ACTION,
	EDIT_ROUTE_CHANGE_TO_FMP_ACTION,
} from '../metric-keys';

export function markCustomTransitionExperienceForRoute(routeName: string) {
	if (!routeName) return;
	switch (routeName) {
		// edit route names taken from @confluence/browser-metrics/src/metricKeys.ts
		case EDIT_PAGE_EMBED.name:
		case EDIT_PAGE_V2.name:
		case EDIT_BLOG_V2.name:
		case EDIT_PAGE.name:
		case EDIT_BLOG.name:
			ExperienceActionMeasures.markMeasureEnd(EDIT_ROUTE_CHANGE_ACTION);
			ExperienceActionMeasures.markMeasureStart(EDIT_ROUTE_CHANGE_TO_FMP_ACTION);
			ExperienceActionMeasures.markMeasureStart(EDIT_ROUTE_CHANGE_TO_TTI_ACTION);
			return;
		default:
			return;
	}
}
