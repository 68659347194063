import { useQuery } from 'react-apollo';

import { useInlineComments } from '@confluence/inline-comments-hooks';
import { CommentsSectionQuery } from '@confluence/page-comments-queries/entry-points/CommentsSectionQuery.graphql';
import type {
	CommentsSectionQuery as CommentsSectionQueryType,
	CommentsSectionQueryVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CommentsSectionQuery';
import { markErrorAsHandled } from '@confluence/graphql';

export type UseCommentCountsData = {
	unresolvedInlineCommentsCount: number;
	footerCommentsCount: number;
};

export const useCommentCounts = (contentId: string): UseCommentCountsData => {
	const [{ unresolvedInlineComments }] = useInlineComments();

	// Instead of requesting 0 comments with the 'first' param here I am reusing the query that is preloaded and fetched
	// for page comments themselves. This allows me to take advantage of the caching and updates that occur in the
	// legacy classes. I've also just marked all errors as handled as we don't particularly care about the results here
	// and the page comments component has much more robust error handling than we'd want to add here.
	const { data: footerCommentsData, error } = useQuery<
		CommentsSectionQueryType,
		CommentsSectionQueryVariables
	>(CommentsSectionQuery, {
		variables: {
			contentId,
			offset: '',
			first: 100,
		},
	});

	if (error) {
		markErrorAsHandled(error);
	}

	return {
		unresolvedInlineCommentsCount:
			(unresolvedInlineComments && unresolvedInlineComments.length) ?? 0,
		footerCommentsCount: (footerCommentsData && footerCommentsData.comments?.totalCount) ?? 0,
	};
};
