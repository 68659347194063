import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

// @ts-ignore
import fragmentMatcherTypes from './types/fragmentMatcherTypes';

/*
This can be regenerated by running the following introspection query against /cgraphql:
{
    __schema {
        types {
            kind
            name
            possibleTypes {
                name
            }
        }
    }
}
*/

export const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData: {
		__schema: {
			types: fragmentMatcherTypes,
		},
	},
});
