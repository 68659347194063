import {
	ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY,
	TESTING_TOKEN_OVERRIDE_COOKIE_KEY,
} from '../../../constants';
import { getCookie } from '../get-cookie';
import { overrideConsentTokenForLiveTesting } from '../override-with-testing-cookies';
import {
	setConsentTokenCookie,
	setRequiredConsentCookies,
	setShouldShowBannerCookie,
} from '../set-required-consent-cookies';

import { getValidConsentData } from './utils';

export const getConsentToken = async () => {
	const fallbackConsentToken = getCookie(ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY);

	const consentData = await getValidConsentData(fallbackConsentToken);
	if (consentData) {
		const { consentToken, consentTimestamp } = consentData;

		/*** TESTING OVERRIDES ***/

		const potentiallyOverriddenConsentToken = overrideConsentTokenForLiveTesting(consentToken);

		const testingConsentOverride = getCookie(TESTING_TOKEN_OVERRIDE_COOKIE_KEY);
		const hasTestingConsents = !!(testingConsentOverride || testingConsentOverride === '');

		if (hasTestingConsents) {
			// If testing, set the cookies accordingly so we can test with any overridden token data.
			setShouldShowBannerCookie(potentiallyOverriddenConsentToken);
			setConsentTokenCookie(potentiallyOverriddenConsentToken);

			// Unfortunately, if you've previously saved to ConsentHub, that data will always resolve, so to simulate ConsentHub
			// being down, or the token data being bunk/invalid, we must set the testing token to an empty string. This will
			// return undefined here and skip any unpacking of the token. Otherwise, it will use the overridden token value.
			return potentiallyOverriddenConsentToken === ''
				? undefined
				: potentiallyOverriddenConsentToken;
		}

		// At this point, all data should be valid and overridden for testing as needed, so set those cookies!
		setRequiredConsentCookies(potentiallyOverriddenConsentToken, consentTimestamp);
		return consentToken;
	}
	return undefined;
};
