import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { TemplateMigrationPreviewLoader } from '@confluence/template-migration-preview';

export const TemplateMigrationPreviewRoute = ({ params: { contentId } }: RouteMatch) => (
	<TemplateMigrationPreviewLoader templateId={contentId} />
);

TemplateMigrationPreviewRoute.NAVIGATION_PARAMS = ({
	name,
	params: { contentId },
}: RouteMatch) => ({
	Screen: {
		screenEvent: { name: 'templateMigrationPreviewScreen' },
		pageState: {
			routeName: name,
			contentId,
		},
	},
	MainLayout: false,
});
