import type { GQLExtension } from '../web-client/graphql/types';

export const isCustomUI = (extension: Pick<GQLExtension, 'properties'>, entryPoint?: string) => {
	const { render, resource } = extension.properties;

	if (entryPoint && extension.properties[entryPoint]) {
		const { resource: entryPointResource, render: entryPointRender } =
			extension.properties[entryPoint];

		return entryPointResource != null && entryPointRender !== 'native';
	}

	return resource != null && render !== 'native';
};
