import React, { useCallback } from 'react';
import { element } from 'prop-types';

import { Attribution, GenericErrorBoundary } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';

import { EngagementProviderComponent } from './EngagementProviderComponent';

function EngagementProviderComponentWrapper({ children }) {
	const { isLicensed } = useSessionData();
	const renderOnError = useCallback(() => {
		return children;
	}, [children]);

	if (!isLicensed) {
		return children;
	}

	return (
		<GenericErrorBoundary attribution={Attribution.INHERIT} renderOnError={renderOnError}>
			<EngagementProviderComponent>{children}</EngagementProviderComponent>
		</GenericErrorBoundary>
	);
}

EngagementProviderComponentWrapper.propTypes = {
	children: element,
};

export const EngagementProvider = EngagementProviderComponentWrapper;
