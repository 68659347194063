import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { checkWebSocketConnectivity } from '@confluence/network';

let hasWebSocketLinkConnection: boolean | undefined;

function getWebsocketURI(): string {
	let WEBSOCKET_URI: string;

	if (process.env.NODE_ENV === 'testing') {
		WEBSOCKET_URI = `wss://pug.jira-dev.com/subscriptions/subscriptions`;
	} else if (process.env.NODE_ENV !== 'production') {
		// In dev mode this allows the local dev proxy to use the WebSocket host
		// that matches the backend origin host. The __backendOriginHost is only
		// available with local dev server.
		const host = (window as any).__backendOriginHost || window.location.host;

		WEBSOCKET_URI = `wss://${host}/subscriptions/subscriptions`;
	} else {
		WEBSOCKET_URI = `wss://${window.location.host}/subscriptions/subscriptions`;
	}

	return WEBSOCKET_URI;
}

let subscriptionClient: SubscriptionClient;
export function getSubscriptionClientInstance(): SubscriptionClient {
	const WEBSOCKET_URI = getWebsocketURI();
	// NOTE: current usage is geared towards quick reload, and subscription can be terminated by
	// next/packages/quick-reload/src/useWsSubscription.ts -> unsubscribeAll() call
	if (!subscriptionClient) {
		subscriptionClient = new SubscriptionClient(WEBSOCKET_URI, {
			reconnect: true,
			reconnectionAttempts: 2,
			// WS library closes a connection after a short timeout if the server was too slow to respond
			// https://github.com/apollographql/subscriptions-transport-ws/issues/377
			// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L548
			minTimeout: 5000,
			lazy: true,
		});
	}
	return subscriptionClient;
}

/**
 * Creates Apollo WebSocketLink to be used by Apollo Client
 * @returns WebSocketLink instance
 */
export const webSocketLink = () => new WebSocketLink(getSubscriptionClientInstance());

/**
 * Checks WS connectivity once and then relies on the detected WS connection status.
 * @returns true when WS enabled, false otherwise
 */
export const hasWebSocketLink = (): boolean => {
	const WEBSOCKET_URI = getWebsocketURI();
	if (hasWebSocketLinkConnection === undefined) {
		hasWebSocketLinkConnection = checkWebSocketConnectivity(WEBSOCKET_URI);
	}

	return hasWebSocketLinkConnection;
};
