/* eslint-disable no-console */
import {
	type OperationalEventAction,
	type OperationalEventAttributes,
	sendPackageOperationalEvent,
} from '../analytics-client';

const LOG_PREFIX = '[browser-storage-controls]';

// Feel free to add more types here, future dev
type LoggingMetadata = string | { [k: string]: string };

interface OperationalErrorLogConfig {
	action: OperationalEventAction;
	attributes?: OperationalEventAttributes;
	message: string;
	metadata?: LoggingMetadata;
}

export class Logger {
	static info(message: string, metadata?: LoggingMetadata) {
		console.log(`${LOG_PREFIX}: ${message}`, metadata ? JSON.stringify(metadata) : '');
	}

	static error(message: string, metadata?: LoggingMetadata) {
		console.error(`${LOG_PREFIX}: ${message}`, metadata ? JSON.stringify(metadata) : '');
	}

	static errorWithOperationalEvent({
		action,
		attributes = {},
		message,
		metadata,
	}: OperationalErrorLogConfig) {
		try {
			sendPackageOperationalEvent({
				action,
				attributes: { error: message, ...attributes },
			});
		} catch (e: any) {
			this.error(`Failed to send operational event on error: ${action}. ${e.message || ''}`);
		}

		this.error(message, metadata ? JSON.stringify(metadata) : '');
	}

	static warn(message: string, metadata?: LoggingMetadata) {
		console.warn(`${LOG_PREFIX}: ${message}`, metadata ? JSON.stringify(metadata) : '');
	}
}
