/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { lazy } from 'react';
import { type NativeCodeProps, type RenderFn } from '@atlassian/forge-ui-types';
import { useInlineContext } from '../../../context/inline';
import { getChildrenText } from '../utils/getChildrenText';

const styles = css({
	width: "'100%'",
});
const AKCodeBlock = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.code' */
		'@atlaskit/code'
	)
		.then((module) => ({
			AkCode: module.Code,
			AkCodeBlock: module.CodeBlock,
		}))
		.then((module) => ({
			default: module.AkCodeBlock,
		})),
);

const AKCodeInline = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.code' */
		'@atlaskit/code'
	)
		.then((module) => ({
			AkCode: module.Code,
			AkCodeBlock: module.CodeBlock,
		}))
		.then((module) => ({
			default: module.AkCode,
		})),
);

export const Code = ({ forgeDoc: { props, children } }: Parameters<RenderFn>[0]) => {
	const { language, showLineNumbers } = props as NativeCodeProps;

	const text = getChildrenText(children);

	const { inline } = useInlineContext();
	return inline ? (
		<AKCodeInline>{text}</AKCodeInline>
	) : (
		<div css={styles}>
			<AKCodeBlock text={text} language={language} showLineNumbers={showLineNumbers} />
		</div>
	);
};
