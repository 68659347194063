export const userTransformer = ({
	type,
	accountId,
	displayName,
	profilePicture: { path: avatarUrl } = {},
	permissionType,
} = {}) => {
	if (!avatarUrl) {
		return {};
	}
	if (type === 'anonymous') {
		return { isAnonymous: true, avatarUrl };
	}
	return {
		isAnonymous: false,
		displayName,
		avatarUrl,
		accountId,
		permissionType,
	};
};
