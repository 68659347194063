/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import React, { lazy, Fragment, useState } from 'react';
import {
	type FieldChildrenProps,
	type NativeTextFieldProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { makeValidate } from '../../UIKit1/textfield/validators';
import { FormStateChangeNotifier } from '../form';
import { useIsInForm } from '../utils/useIsInForm';
import { Label } from '../label';
import { FieldContainer } from '../utils/FieldContainer';

const AKTextField = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.textfield' */
			'@atlaskit/textfield'
		),
);

const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const AKFormField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const AKHelperMessage = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

const isValidType = (type: string) => {
	return ['text', 'number', 'email', 'tel', 'password'].includes(type);
};

export const TextField = (props: Parameters<RenderFn>[0]) => {
	const {
		type = 'text',
		name,
		label,
		description, // description about the field
		placeholder,
		isRequired = false,
		defaultValue,
		testId,
		autoComplete,
		onChange,
		value,
	} = props.forgeDoc.props as NativeTextFieldProps & { testId?: string };

	const validate = makeValidate(isRequired, type);

	// WARNING: for <input type=number> and React, native validation is strange, use type text
	const fieldType = !isValidType(type) || type === 'number' ? 'text' : type;

	// Implement validation of AKFormField when not in a form
	const [errorMessage, setErrorMessage] = useState('');

	const isInForm = useIsInForm();
	if (!isInForm) {
		return (
			<FieldContainer>
				<Label name={name} isRequired={isRequired} label={label} />
				<AKTextField
					name={name}
					type={fieldType}
					testId={testId}
					placeholder={placeholder}
					autoComplete={autoComplete}
					defaultValue={defaultValue}
					onChange={(event) => {
						setErrorMessage(
							validate((event as React.ChangeEvent<HTMLInputElement>).target.value) ?? '',
						);
						onChange?.(
							// AKTextField has the wrong type - remove this type cast if it ever gets fixed
							// to this correct type
							(event as React.ChangeEvent<HTMLInputElement>).target.value,
						);
					}}
					value={value}
					isInvalid={!!errorMessage}
					aria-invalid={errorMessage ? 'true' : 'false'}
					aria-labelledby={`${name}-label`}
				/>
				{errorMessage ? <AKErrorMessage>{errorMessage}</AKErrorMessage> : null}
				{description ? <AKHelperMessage>{description}</AKHelperMessage> : null}
			</FieldContainer>
		);
	}

	return (
		<FieldContainer>
			<AKFormField
				name={name}
				label={label}
				defaultValue={defaultValue}
				isRequired={isRequired}
				validate={validate}
			>
				{({ fieldProps }: FieldChildrenProps) => {
					const {
						id,
						isDisabled,
						isInvalid,
						onBlur,
						onFocus,
						value,
						onChange: fieldOnChange,
						'aria-labelledby': ariaLabelledBy,
					} = fieldProps;
					const error = isInvalid ? validate(value) : null;
					return (
						<Fragment>
							<FormStateChangeNotifier name={name} value={value} />
							<AKTextField
								type={fieldType}
								testId={testId}
								placeholder={placeholder}
								autoComplete={autoComplete}
								onChange={(event) => {
									fieldOnChange(event);
									onChange?.(
										// AKTextField has the wrong type - remove this type cast if it ever gets fixed
										// to this correct type
										(event as React.ChangeEvent<HTMLInputElement>).target.value,
									);
								}}
								onFocus={onFocus}
								onBlur={onBlur}
								isDisabled={isDisabled}
								id={id}
								isInvalid={isInvalid}
								aria-labelledby={ariaLabelledBy}
								defaultValue={defaultValue}
							/>
							{error ? <AKErrorMessage>{error}</AKErrorMessage> : null}
							{description ? <AKHelperMessage>{description}</AKHelperMessage> : null}
						</Fragment>
					);
				}}
			</AKFormField>
		</FieldContainer>
	);
};
