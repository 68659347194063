import type { Diff } from './utils';

type DiffPostProcessor = (diffs: Diff[]) => Diff[];

/**
 * This post process attempts to join consecutive replacement-only diffs which are seperated by whitespace. This way
 * they appear as a single suggestion replacement rather then seperate single ones.
 */
export const concatConsecutiveReplacements: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = [];
	for (let i = 0; i < diffs.length; i++) {
		// This is a very specific use case, were consequetive replacements are seperated only by unchanged white space.
		// This essentially converts them to a single replacement group.
		if (
			diffs[i].type === -1 &&
			diffs?.[i + 1]?.type === 1 &&
			!diffs[i + 1].text.includes('\uFFF9') &&
			!diffs[i + 1].text.includes('\uFFFB')
		) {
			// look ahead for groups of white space follwed by replacements, we will want to join all of them together.
			const grouped: Diff[] = [];
			for (let j = i + 2; j < diffs.length; j++) {
				if (
					diffs?.[j]?.type === 0 &&
					diffs?.[j]?.text.trim().length === 0 &&
					diffs?.[j + 1]?.type === -1 &&
					diffs?.[j + 2]?.type === 1 &&
					!diffs[j + 1].text.includes('\uFFF9') &&
					!diffs[j + 1].text.includes('\uFFFB')
				) {
					grouped.push(diffs?.[j], diffs?.[j + 1], diffs?.[j + 2]);
					j += 3;
				} else {
					break;
				}
			}

			if (grouped.length) {
				const ot = grouped.reduce((acc, d) => acc + (d.type <= 0 ? d.text : ''), diffs[i].text);
				result.push({
					type: -1,
					text: ot,
					length: ot.length,
				});

				const nt = grouped.reduce((acc, d) => acc + (d.type >= 0 ? d.text : ''), diffs[i + 1].text);
				result.push({
					type: 1,
					text: nt,
					length: nt.length,
				});

				i += grouped.length + 1;
			} else {
				result.push(diffs[i]);
			}
		} else {
			result.push(diffs[i]);
		}
	}
	return result;
};

/**
 * This post process attempts to use the context of where diffs are located within a document and decides whether or not
 * they should show/hide a full stop suggestion
 */
export const ignoreTrailingFullStops: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = diffs.concat();

	// use the original paragraph context to decide whether there should be a . at the end
	if (result.length > 1) {
		if (result[result.length - 2].type === -1 && result?.[result.length - 1]?.type === 1) {
			const removed = result[result.length - 2].text.trimEnd();
			const added = result[result.length - 1].text.trimEnd();

			const r1 = removed.charAt(removed.length - 1) === '.';
			const a1 = added.charAt(added.length - 1) === '.';
			const r2 = removed.substring(0, removed.length - 1);
			const a2 = added.substring(0, added.length - 1);

			if (removed !== added && (r1 || a1) && ((r1 && r2 === added) || (a1 && a2 === removed))) {
				result.splice(-2, 2, {
					type: 0,
					text: diffs[diffs.length - 2].text,
					length: diffs[diffs.length - 2].length,
				});
			}
		}
	}

	return result;
};

export const ignoreTrailingSpaces: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = diffs.concat();

	if (result.length > 1) {
		if (result[result.length - 2].type === -1 && result?.[result.length - 1]?.type === 1) {
			const removed = result[result.length - 2].text.trimEnd();
			const added = result[result.length - 1].text.trimEnd();

			if (removed === added) {
				result.splice(-2, 2, {
					type: 0,
					text: diffs[diffs.length - 2].text,
					length: diffs[diffs.length - 2].length,
				});
			}
		}
	}

	return result;
};

/**
 * This collection contains a one-way mapping identifying replacement direction and targets which should be ignored from
 * the diff check. If you want a diff to be ignored 2-ways then it needs to be listed twice with the direction reversed.
 *
 * This list is case-insensitive.
 */
export const ignoredDirectReplacements = new Map<string, string>([
	['&', 'and'],
	['and', '&'],
]);

export const ignoreDirectReplacements: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	// If the replacements is change & -> and then we should ignore it
	const result: Diff[] = [];
	for (let i = 0; i < diffs.length; i++) {
		// We only care about replacement diffs
		if (diffs[i].type === -1 && diffs?.[i + 1]?.type === 1) {
			if (
				ignoredDirectReplacements.get(diffs[i].text.trim().toLowerCase()) ===
				diffs[i + 1].text.trim().toLowerCase()
			) {
				result.push({
					type: 0,
					text: diffs[i].text,
					length: diffs[i].length,
				});

				i += 1;
			} else {
				result.push(diffs[i]);
			}
		} else {
			result.push(diffs[i]);
		}
	}
	return result;
};

/**
 * This will convert delete operations or blank replacements ops to a previous word replacement instead.
 */
export const convertJoinedDeletesIntoReplacement: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = [];

	for (let i = 0; i < diffs.length; i++) {
		// This is a very specific use case, were consequetive replacements are seperated only by unchanged white space.
		// This essentially converts them to a single replacement group.
		if (
			diffs[i].type === 0 &&
			diffs?.[i + 1]?.type === -1 &&
			diffs?.[i + 2]?.type !== 1 &&
			diffs?.[i]?.text.trim().length !== 0
		) {
			// Removing to the end of a diff
			const rightIndex = [' ', '\uFFFB', '\uFFF9'].reduce((v, c) => {
				const diffText = diffs[i].text;
				let x = diffText.lastIndexOf(c);
				if (x === diffText.trimEnd().length) {
					x = diffText.trimEnd().lastIndexOf(c);
				}
				return x !== -1 && x > v ? x : v;
			}, 0);

			const a = diffs[i].text.substring(0, rightIndex + 1);
			result.push({
				type: 0,
				text: a,
				length: a.length,
			});

			const b = diffs[i].text.substring(rightIndex + 1) + diffs[i + 1].text;
			result.push({
				type: -1,
				text: b,
				length: b.length,
			});

			const c = diffs[i].text.substring(rightIndex + 1);
			result.push({
				type: 1,
				text: c,
				length: c.length,
			});

			i += 1;
		} else {
			result.push(diffs[i]);
		}
	}

	return result;
};

export const removeMarkdownCustomTagReplacements: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	// If the replacements is change & -> and then we should ignore it
	const result: Diff[] = [];
	for (let i = 0; i < diffs.length; i++) {
		// We only care about replacement diffs
		if (diffs[i].type === -1 && diffs?.[i + 1]?.type === 1) {
			const o = diffs[i].text;
			const r = diffs[i + 1].text;
			const j = r.indexOf('</custom>');
			const k = o.indexOf('</custom>');
			// We should only manipulate and remove the custom tag if it wasn't in the original text and the BE
			// returned it with the replacement text.
			if (j !== -1 && k === -1) {
				// get the string value without the "</custom>" part.
				const v = r.substring(0, j) + r.substring(j + 9);
				// If the replacement string without the custom tag is empty then it's likely that the tag is a legitimate correction
				// so we will behave normally in this case
				if (v.trim().length !== 0) {
					// If the only difference was the custom tag, then we will ignore the change
					if (v === o) {
						result.push({
							type: 0,
							text: diffs[i].text,
							length: diffs[i].length,
						});

						i += 1;
					} else {
						//  If the text after removing the custom tag is still different then we
						// should try to generate a value suggestion based on the difference.
						result.push({
							type: -1,
							text: diffs[i].text,
							length: diffs[i].length,
						});

						result.push({
							type: 1,
							text: v,
							length: v.length,
						});

						i += 1;
					}
				} else {
					result.push(diffs[i]);
				}
			} else {
				result.push(diffs[i]);
			}
		} else {
			result.push(diffs[i]);
		}
	}
	return result;
};
