import type { CustomField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export const recentlyUpdatedDashboard: FieldTransformer<any> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name === 'spaces') {
				return {
					...field,
					isMultiple: true,
					type: 'custom',
					options: {
						resolver: {
							type: 'recentlyUpdatedDashboardSpaceKeyResolver',
						},
					},
				} as CustomField;
			}

			return field;
		});
	},
};
