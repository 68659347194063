import { query } from '@confluence/query-preloader-tools';

import { GrantAccessDialogLoaderQuery } from './GrantAccessDialogLoaderQuery.graphql';

export function preloadGrantAccessDialogData(contentId: string, accountId: string) {
	return query({
		query: GrantAccessDialogLoaderQuery,
		variables: { contentId, accountId },
	});
}
