import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.empty.summarizePage.title',
		defaultMessage: 'Summarize writing',
		description:
			'Title for "Summarize writing" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.empty.summarizePage.description',
		defaultMessage: "Creates a summary of the text you're working on, highlighting key points",
		description:
			'Describes "Summarize writing" Atlassian Intelligence feature shown in the editor typeahead and element browser.',
	},
});
