import React from 'react';

import {
	ADMIN_DEFAULT_SPACE_LOGO_CONFIG_ANALYTICS_SOURCE,
	LazyDefaultSpaceLogoConfigPage,
} from '@confluence/site-settings';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const AdminDefaultSpaceLogoConfigRoute = () => <LazyDefaultSpaceLogoConfigPage />;

AdminDefaultSpaceLogoConfigRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_DEFAULT_SPACE_LOGO_CONFIG_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
