import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	highlightedPageIncludeRenderedError: {
		id: 'fabric-extension-legacy-macro-renderer.legacy-macro-renderer-query.highlightedPageIncludeError',
		defaultMessage: 'Unable to render { include }',
		description: 'Highlighted Message to display if include page already rendered',
	},
	nonhighlightedPageIncludeRenderedError: {
		id: 'fabric-extension-legacy-macro-renderer.legacy-macro-renderer-query.nonhighlightedPageIncludeRenderedError',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: " Already included page ''{pageTitle}'', stopping.",
		description:
			'Message appears right after the highlighted message to indicate the page title that has already been rendered',
	},
});
