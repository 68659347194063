export function getQueryName(operationName: string | null | undefined, variables: any) {
	if (!operationName) return '';

	let name = operationName;
	if (operationName === 'WebItemLocationQuery' || operationName === 'WebPanelLocationQuery') {
		name = `${operationName}:${
			variables?.location || (variables?.locations || []).join(',') || ''
		}`;
	}
	return name.replace(/[^\-\.\w]/g, '_');
}
