import gql from 'graphql-tag';

export const CreateContentInlineMutation = gql`
	mutation CreateContentInlineMutation($createdInContentId: ID!, $spaceKey: String) {
		createContentInline(
			input: { createdInContentId: $createdInContentId, spaceKey: $spaceKey, type: "database" }
		) {
			id
		}
	}
`;
