import { LoadablePaint, LoadableAfterPaint } from '@confluence/loadable';
// eslint-disable-next-line no-restricted-imports
import type { LoadableType } from '@confluence/loadable/src/types';

import type { TableOfContentsInterface } from './TableOfContents';

// NOTE: this loader should have an AFTER_PAINT priority, however it had to be bumped to PAINT
// to avoid creating conditions for the bug https://product-fabric.atlassian.net/browse/CCP-1371

export const TableOfContentsLoader: LoadableType<TableOfContentsInterface> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TableOfContents" */ './TableOfContents'))
			.TableOfContents,
});

export const LazyTableOfContentsLoader: LoadableType<TableOfContentsInterface> = LoadableAfterPaint(
	{
		loader: async () =>
			(await import(/* webpackChunkName: "loadable-TableOfContents" */ './TableOfContents'))
				.TableOfContents,
	},
);
