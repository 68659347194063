import React from 'react';

import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { MainLayout } from '@confluence/main-layout';
import type { RouteMatch } from '@confluence/route';
import { Screen } from '@confluence/screen';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
import { EditPageLoaderServer } from '@confluence/edit-page-loader/entry-points/EditPageLoaderServer';
import { shouldShowMobileWeb } from '@confluence/mobile-detection';
import {
	CONTAINER_BLOG_ID,
	CONTAINER_HOME_ID,
	PRODUCT_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { EDIT_PAGE_V2 } from '@confluence/named-routes';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { BannerContextProvider } from '@confluence/banners';

import { parseDraftId } from '../helpers/editorUtilities';

export const EditPageRoute = ({ name, params: { contentId, spaceKey }, query }: RouteMatch) => {
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !isCompanyHub,
	});

	if (getSSRFeatureFlag('confluence.ssr.edit.page.disabled')) {
		return null;
	}

	const contentType = name === EDIT_PAGE_V2.name ? 'page' : 'blogpost';
	const draftShareId = parseDraftId(query);

	//
	// navView
	//
	let navView = CONTAINER_HOME_ID;
	if (isCompanyHub) {
		if (shouldShowPremiumUpsell) {
			return null;
		}

		navView = PRODUCT_HOME_ID;
	} else if (contentType === 'blogpost') {
		navView = CONTAINER_BLOG_ID;
	}

	return (
		<Screen
			showPremiumOnboarding={false}
			pageState={{ contentId, spaceKey, contentType }}
			screenEvent={{
				name: 'editPageScreen',
				id: contentId,
			}}
		>
			<MainLayout
				enableNavigation={!shouldShowMobileWeb()}
				spaceKey={spaceKey}
				navView={navView}
				isViewPage
			>
				<BannerContextProvider>
					<EditPageLoaderServer
						contentId={contentId}
						spaceKey={spaceKey}
						draftShareId={draftShareId}
						contentType={contentType}
					/>
				</BannerContextProvider>
			</MainLayout>
		</Screen>
	);
};
