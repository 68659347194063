/* eslint-disable no-restricted-properties */
export type LegacyMessage = {
	type: string;
	payload: any;
};

export type LegacyMessageQueuePushFcn = (message: LegacyMessage) => void;

type LegacyMessageQueue = {
	push: LegacyMessageQueuePushFcn;
};

declare global {
	interface Window {
		legacyMessageQueue?: LegacyMessageQueue;
	}
}

export function getLegacyMessageQueue(): Promise<LegacyMessageQueue> {
	return new Promise((resolve) => {
		const tryToResolve = (retry: number) => {
			if (retry <= 0) {
				return;
			}

			if (window?.legacyMessageQueue?.push) {
				resolve(window.legacyMessageQueue);
			} else {
				setTimeout(() => tryToResolve(retry - 1), 100);
			}
		};

		tryToResolve(50);
	});
}
