import { adaptEventHandler } from '../../../utils';

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::98c487322e6549bad072993646881588>>
 * @codegenId #loading-button
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen loading-button
 * @codegenParams { "props": { "children": true, "appearance": true, "autoFocus": true, "isDisabled": true, "isSelected": true, "onBlur": { "value": "adaptEventHandler(onBlur)" }, "onClick": { "value": "adaptEventHandler(onClick)" }, "onFocus": { "value": "adaptEventHandler(onFocus)" }, "spacing": true, "testId": true, "shouldFitContainer": true, "type": true, "isLoading": true } }
 * @codegenDependency ../../../../../../../design-system/button/src/old-button/types.tsx <<SignedSource::febdd22056428f6284ca217c3c3b64be>>
 * @codegenDependency ../../../../../../../design-system/button/src/old-button/loading-button.tsx <<SignedSource::54bb014c9023d6cacaffd0a43a12582b>>
 * @codegenDependency ../../../../../../../design-system/button/src/old-button/button.tsx <<SignedSource::acff4a29809ad045fac5870fcdc07ca6>>
 * @codegenDependency ../../../../../../../design-system/button/src/old-button/shared/loading-spinner.tsx <<SignedSource::961eda38183385a122fb5c152dfd8bcd>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { LoadingButton as PlatformLoadingButton } from '@atlaskit/button';

type PlatformLoadingButtonProps = React.ComponentProps<typeof PlatformLoadingButton>;

export type LoadingButtonProps = Pick<
  PlatformLoadingButtonProps,
  'children' | 'appearance' | 'autoFocus' | 'isDisabled' | 'isSelected' | 'onBlur' | 'onClick' | 'onFocus' | 'spacing' | 'testId' | 'shouldFitContainer' | 'type' | 'isLoading'
>;

export const LoadingButton = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    autoFocus,
    isDisabled,
    isSelected,
    onBlur,
    onClick,
    onFocus,
    spacing,
    testId,
    shouldFitContainer,
    type,
    isLoading,
  } = props.forgeDoc.props as LoadingButtonProps;
  return (
    <PlatformLoadingButton
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      appearance={appearance}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onBlur={adaptEventHandler(onBlur)}
      onClick={adaptEventHandler(onClick)}
      onFocus={adaptEventHandler(onFocus)}
      spacing={spacing}
      testId={testId}
      shouldFitContainer={shouldFitContainer}
      type={type}
      isLoading={isLoading}
    />
  );
};
/**
 * @codegenEnd
 */