import { Record } from 'immutable';

export default Record({
	id: null,
	webItemKey: null,
	webItemCompleteKey: null,
	title: null,
	url: null,
	position: null,
	icon: null,
	iconClass: null,
	styleClass: null,
	hidden: null,
	canHide: null,
	tooltip: null,
	urlWithoutContextPath: null,
});
