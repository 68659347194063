import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { GetUserStateQuery } from './graphql/GetUserStateQuery.graphql';
import type { GetUserState } from './graphql/__types__/GetUserStateQuery';

export async function preloadUserState() {
	const { cloudId } = await getPreloaderFnContext();

	return query<GetUserState, { cloudId: string }>({
		query: GetUserStateQuery,
		variables: { cloudId },
	});
}
