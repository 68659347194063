import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import type { GranularityType } from '../../common/MetricSettingsContext';

import type { SubStatType } from './sub-components/SubStat';
import { SubStatDirection, SubStatSentiment } from './sub-components/SubStat';

const i18n = defineMessages({
	zeroCountStartDateSubStatText: {
		id: 'admin-center.summary-card.zero-count-start-date',
		defaultMessage:
			'Up from 0 since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}',
		description:
			"Text displayed in substat when the count started at 0 and we can't calculate a percentage change",
	},
	zeroCountEndDateSubStatText: {
		id: 'admin-center.summary-card.zero-count-end-date',
		defaultMessage:
			'{startCount, plural, one {Down from 1 since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}} other {Down from {startCount} since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}}}',
		description:
			"Text displayed in substat when the count ended at 0 and we can't calculate a percentage change",
	},
	zeroCountBothDatesSubStatText: {
		id: 'admin-center.summary-card.zero-count-both-dates',
		defaultMessage:
			'No change since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}',
		description: 'Text displayed in substat when there is no change in the counts',
	},
	noChangeSubStatText: {
		id: 'admin-center.summary-card.no-change-sub-stat',
		defaultMessage:
			'No change since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}',
		description:
			'Text displayed in substat when there is no percentage change in the counts for a provided date range',
	},
	lessThanOnePercentSubStatText: {
		id: 'admin-center.summary-card.less-than-one-percent-sub-stat',
		defaultMessage:
			'<1% since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}',
		description:
			'Text displayed in substat when there is a percentage change less than one percent in the counts for a provided date range',
	},
	subStatText: {
		id: 'admin-center.summary-card.sub-stat',
		defaultMessage:
			'{percentChange, plural, one {1% since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}} other {{percentChange}% since {granularity, select, DAY {{interval, plural, =7 {last week} =30 {1 month ago} other {2 months ago}}} WEEK {{interval} weeks ago} MONTH {{interval} months ago} other {}}}}',
		description:
			'Text displayed in substat when there is a percentage change in the counts for a provided date range',
	},
});

const getSubStatText = ({
	currentCount,
	historicalCount,
	formatMessage,
	granularity,
	lastXGranularities,
}: {
	currentCount: number;
	historicalCount: number;
	formatMessage: IntlShape['formatMessage'];
	granularity: GranularityType;
	lastXGranularities: number;
}) => {
	const absPercentChange = (100 * Math.abs(currentCount - historicalCount)) / historicalCount;
	if (absPercentChange >= 1) {
		return formatMessage(i18n.subStatText, {
			percentChange: Math.round(absPercentChange),
			granularity,
			interval: lastXGranularities,
		});
	} else if (absPercentChange > 0) {
		return formatMessage(i18n.lessThanOnePercentSubStatText, {
			granularity,
			interval: lastXGranularities,
		});
	} else {
		return formatMessage(i18n.noChangeSubStatText, {
			granularity,
			interval: lastXGranularities,
		});
	}
};

const getSubStatTextFromPercent = ({
	granularity,
	currentPercent,
	historicalPercent,
	lastXGranularities,
	formatMessage,
}: {
	currentPercent: number;
	historicalPercent: number;
	lastXGranularities?: number;
	formatMessage: IntlShape['formatMessage'];
	granularity: GranularityType;
}) => {
	const absPercentChange = Math.abs(currentPercent - historicalPercent);
	if (absPercentChange > 0) {
		return formatMessage(i18n.subStatText, {
			percentChange: Math.round(absPercentChange),
			granularity,
			interval: lastXGranularities,
		});
	} else {
		return formatMessage(i18n.noChangeSubStatText, {
			granularity,
			interval: lastXGranularities,
		});
	}
};

const getSubStatDirection = ({
	currentCount,
	historicalCount,
}: {
	currentCount: number;
	historicalCount: number;
}) => {
	const countDiff = currentCount - historicalCount;
	if (countDiff > 0) {
		return SubStatDirection.UP;
	} else if (countDiff < 0) {
		return SubStatDirection.DOWN;
	} else {
		return SubStatDirection.NONE;
	}
};

type GetSubStatPropsArgs = {
	granularity: GranularityType;
	lastXGranularities: number;
	historicalCount: number | undefined;
	currentCount: number | undefined;
	isActivationDateOver1DayAgo?: boolean;
	formatMessage: IntlShape['formatMessage'];
};

export const getSubStatProps = ({
	granularity,
	historicalCount,
	currentCount,
	lastXGranularities,
	isActivationDateOver1DayAgo,
	formatMessage,
}: GetSubStatPropsArgs): SubStatType | undefined => {
	if (historicalCount === undefined || currentCount === undefined || !isActivationDateOver1DayAgo) {
		return undefined;
	} else if (historicalCount === 0 && currentCount === 0) {
		return {
			text: formatMessage(i18n.zeroCountBothDatesSubStatText, {
				granularity,
				interval: lastXGranularities,
			}),
			arrowDirection: SubStatDirection.NONE,
			sentiment: SubStatSentiment.NEUTRAL,
		};
	} else if (historicalCount === 0) {
		return {
			text: formatMessage(i18n.zeroCountStartDateSubStatText, {
				granularity,
				interval: lastXGranularities,
			}),
			arrowDirection: SubStatDirection.UP,
			sentiment: SubStatSentiment.NEUTRAL,
		};
	} else if (currentCount === 0) {
		return {
			text: formatMessage(i18n.zeroCountEndDateSubStatText, {
				startCount: historicalCount,
				granularity,
				interval: lastXGranularities,
			}),
			arrowDirection: SubStatDirection.DOWN,
			sentiment: SubStatSentiment.NEUTRAL,
		};
	} else {
		return {
			text: getSubStatText({
				lastXGranularities,
				currentCount,
				historicalCount,
				formatMessage,
				granularity,
			}),
			arrowDirection: getSubStatDirection({
				currentCount,
				historicalCount,
			}),
			sentiment: SubStatSentiment.NEUTRAL,
		};
	}
};

type GetSubStatPropsFromPercentArgs = {
	granularity: GranularityType;
	lastXGranularities: number;
	historicalPercent: number | undefined;
	currentPercent: number | undefined;
	isActivationDateOver1DayAgo?: boolean;
	formatMessage: IntlShape['formatMessage'];
};

export const getSubStatPropsFromPercent = ({
	granularity,
	historicalPercent,
	currentPercent,
	isActivationDateOver1DayAgo,
	formatMessage,
	lastXGranularities,
}: GetSubStatPropsFromPercentArgs): SubStatType | undefined => {
	if (
		historicalPercent === undefined ||
		currentPercent === undefined ||
		!isActivationDateOver1DayAgo
	) {
		return undefined;
	} else if (historicalPercent === 0 && currentPercent === 0) {
		return {
			text: formatMessage(i18n.zeroCountBothDatesSubStatText, {
				granularity,
				interval: lastXGranularities,
			}),
			arrowDirection: SubStatDirection.NONE,
			sentiment: SubStatSentiment.NEUTRAL,
		};
	} else {
		return {
			text: getSubStatTextFromPercent({
				granularity,
				lastXGranularities,
				currentPercent,
				historicalPercent,
				formatMessage,
			}),
			arrowDirection: getSubStatDirection({
				currentCount: currentPercent,
				historicalCount: historicalPercent,
			}),
			sentiment: SubStatSentiment.NEUTRAL,
		};
	}
};
