import gql from 'graphql-tag';

export const MediaDownloadTokenQuery = gql`
	query MediaDownloadTokenQuery(
		$contentId: ID
		$status: [String]
		$missingContentId: Boolean = false
	) {
		content(id: $contentId, status: $status) @skip(if: $missingContentId) {
			nodes {
				id
				body {
					atlas_doc_format {
						mediaToken {
							token
						}
					}
				}
			}
		}
		mediaConfiguration {
			clientId
			fileStoreUrl
		}
	}
`;
