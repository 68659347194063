import { type SyntheticEvent } from 'react';
import { type SerialisableEvent } from '../UIKit/types';

type TEventHandlerArgs = [event: SyntheticEvent, ...args: unknown[]];
type TEventHandlerArgsTransformer = (...args: TEventHandlerArgs) => unknown[];

/** The SyntheticEvent object is not serialisable and cannot be passed to the runtime/backend as is.
 * This function filters out all non serialisable arguments and maintains important properties of the event object.
 */
const getSerialisableEventObject = (syntheticEvent: SyntheticEvent) => {
	/** syntheticEvent.persist() is called to remove the synthetic event from React's event pool.
	 * Without this call, the properties of the event would be nullified after the event callback has been invoked,
	 * due to React's event pooling.
	 * By calling persist(), we ensure that the event properties remain available for the serialization process.
	 */
	syntheticEvent?.persist?.();

	const target = syntheticEvent.target as HTMLInputElement;

	const serialisableEvent: SerialisableEvent = {
		bubbles: syntheticEvent.bubbles,
		cancelable: syntheticEvent.cancelable,
		defaultPrevented: syntheticEvent.defaultPrevented,
		eventPhase: syntheticEvent.eventPhase,
		isTrusted: syntheticEvent.isTrusted,
		target: {
			selectionStart: target?.selectionStart,
			selectionEnd: target?.selectionEnd,
			value: target?.value,
			checked: target?.checked,
			name: target?.name,
			id: target?.id,
			tagName: target?.tagName,
			type: target?.type,
		},
		timeStamp: syntheticEvent.timeStamp,
		type: syntheticEvent.type,
	};

	return serialisableEvent;
};

/**
 * Default handler args transformer that extract react synthetic event from
 * the handler args and creates a serialisable object so that it can be passed into the runtime.
 */
const nativeEventHandlerArgsTransformer: TEventHandlerArgsTransformer = (...args) => {
	const [event, ...rest] = args;
	return [getSerialisableEventObject(event), ...rest];
};

/**
 * @param sourceEventHandler the source event handler defined by the app user
 * @returns the adapted event handler that can be safely invoked.
 */
export const adaptEventHandler = (sourceEventHandler: Function | undefined) => {
	if (!sourceEventHandler) {
		return sourceEventHandler;
	}
	return (...args: TEventHandlerArgs) => {
		sourceEventHandler(...nativeEventHandlerArgsTransformer(...args));
	};
};
