import type { ReactNode } from "react";
import React, { Fragment, Component } from "react";
import type { WithAnalyticsEventsProps } from "@atlaskit/analytics-next";
import { withAnalyticsEvents } from "@atlaskit/analytics-next";
import { getUniquePageLoadId } from "@confluence/unique-page-load-id";
import type { Extension } from "@confluence/macro-tracker";
import { getJiraHostingOption } from "@confluence/fabric-extension-lib";

export type MacroAnalyticsWrapperProps = {
  children: ReactNode;
  extensionKey: string;
  attributes?: { [key: string]: string | boolean | number };
  node: Extension;
};

/**
 * A wrapper component that fires analytics on initial view of a macro.
 */
export const MacroAnalyticsWrapper = withAnalyticsEvents()(
  class extends Component<
    MacroAnalyticsWrapperProps & WithAnalyticsEventsProps
  > {
    async componentDidMount() {
      const { createAnalyticsEvent, extensionKey, attributes, node } =
        this.props;
      if (createAnalyticsEvent) {
        const jiraHostingOption = await getJiraHostingOption(
          extensionKey,
          node?.parameters?.macroParams?.serverId?.value,
          true,
        );
        createAnalyticsEvent({
          type: "sendTrackEvent",
          data: {
            action: "viewed",
            actionSubject: "macro",
            actionSubjectId: extensionKey,
            source: "viewPageScreen",
            attributes: {
              ...attributes,
              pageLoadInfo: getUniquePageLoadId(),
              jiraHostingOption,
            },
          },
        }).fire();
      }
    }

    render() {
      const { children } = this.props;

      return <Fragment>{children}</Fragment>;
    }
  },
);
