import React, { useContext, createContext, useMemo } from 'react';
import type { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';

import { markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery } from '@confluence/query-preloader-tools';

import type {
	PageRestrictionsQuery as PageRestrictionsQueryType,
	PageRestrictionsQueryVariables,
} from './__types__/PageRestrictionsQuery';
import { PageRestrictionsQuery } from './PageRestrictionsQuery.graphql';
import { useContentId } from './useContentId';
import { ALLOWED_CONTENT_STATUSES } from './constants';

type AncestorType = {
	id: string | null;
	title: string | null;
	hasRestrictions: boolean;
	links: { webui: string | null } | null;
};

export type RestrictionsType = {
	hasInheritedRestrictions: boolean;
	hasViewRestrictions: boolean;
	hasRestrictions: boolean;
	ancestors: AncestorType[];
};

export type PageRestrictionsContextValue = {
	loading: boolean;
	error?: ApolloError;
	restrictions?: RestrictionsType;
	refetch: () => void;
};

export type PageRestrictionsContextProviderProps = {
	contentId?: string | Symbol;
	children?: React.ReactNode;
};

const defaultContext: PageRestrictionsContextValue = {
	loading: false,
	refetch: () => {},
};

export const PageRestrictionsContext = createContext<PageRestrictionsContextValue>(defaultContext);

export const PageRestrictionsContextConsumer = PageRestrictionsContext.Consumer;

export function PageRestrictionsContextProvider(props: PageRestrictionsContextProviderProps) {
	const contentId = useContentId(props.contentId);
	const { edition } = useSessionData() || {};
	const includeAncestors = ConfluenceEdition.FREE === edition;

	const { data, loading, error, refetch } = useQuery<
		PageRestrictionsQueryType,
		PageRestrictionsQueryVariables
	>(
		...getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery({
			query: PageRestrictionsQuery,
			variables: {
				contentId: contentId?.toString()!,
				status: ALLOWED_CONTENT_STATUSES,
				includeAncestors,
			},
			skip: !contentId || typeof contentId === 'symbol',
		}),
	);

	if (error) {
		if (!isUnauthorizedError(error)) {
			getMonitoringClient().submitError(error, { attribution: 'backbone' });
		}
		markErrorAsHandled(error);
	}

	const contextValue = useMemo(
		() =>
			contentId
				? {
						loading,
						error,
						restrictions: transformData(data),
						refetch,
					}
				: defaultContext,
		[loading, error, data, refetch, contentId],
	);

	return (
		<PageRestrictionsContext.Provider value={contextValue}>
			{props.children}
		</PageRestrictionsContext.Provider>
	);
}

const transformData = (data?: PageRestrictionsQueryType): RestrictionsType | undefined => {
	const content = data?.singleContent;
	if (content) {
		return {
			hasRestrictions: content.hasRestrictions,
			hasViewRestrictions: content.hasViewRestrictions,
			hasInheritedRestrictions: content.hasInheritedRestrictions,
			ancestors:
				content.ancestors?.map<AncestorType>((a) => ({
					id: a?.id || null,
					title: a?.title || null,
					hasRestrictions: Boolean(a?.hasRestrictions),
					links: { webui: a?.links?.webui || null },
				})) || [],
		};
	}
};

export const usePageRestrictionsContext = () => useContext(PageRestrictionsContext);
