import type { ADNode } from '@atlaskit/editor-common/validator';
import type { MacroAttributes, ExtensionType } from '@atlaskit/editor-common/provider-factory';

import type { Extension } from '@confluence/macro-tracker';
import { getApolloClient } from '@confluence/graphql';

import type { UnwrappedMacroParams, LegacyMacro } from '../extensions-common/types';
import {
	BODIED_EXTENSION_TYPE,
	INLINE_EXTENSION_TYPE,
	MULTI_BODIED_EXTENSION_TYPE,
	REGULAR_EXTENSION_TYPE,
} from '../extensionConstants';

import type { WrappedMacroParams } from './types';
import { ContentBodyConvertQuery } from './ConvertBody.experimentalgraphql';
import type { ContentBodyConvertQuery as ContentBodyConvertQueryResult } from './__types__/ContentBodyConvertQuery';

export function getDefaultParamValue(params: WrappedMacroParams): string {
	if (params.hasOwnProperty('')) {
		return getParamValue(params['']) || '';
	}
	return '';
}

// { a: { value: b }, c: d } -> { a : b, c: d }
export function unwrapMacroParams(
	macroParams: WrappedMacroParams | UnwrappedMacroParams = {},
): UnwrappedMacroParams {
	return Object.keys(macroParams).reduce((curr, key) => {
		const value = getParamValue(macroParams[key]);
		if (value !== '') {
			curr[key] = value;
		}
		return curr;
	}, {});
}

// { a : b, c: { value: d } } -> { a: { value: b }, c: { value: d } }
export function wrapMacroParams(
	macroParams: WrappedMacroParams | UnwrappedMacroParams = {},
): WrappedMacroParams {
	return Object.keys(macroParams).reduce((curr, key) => {
		const value = getParamValue(macroParams[key]);
		if (key !== '' || value !== '') {
			curr[key] = { value };
		}
		return curr;
	}, {});
}

// does every key have a value key inside?
function getParamValue(macroParam: any | { value: any }): any {
	if (macroParam?.hasOwnProperty('value')) {
		return macroParam.value;
	}
	return macroParam;
}

export function extensionToADF(node) {
	const { type, content, attrs, fragmentLocalId, ...rest } = node;
	// There is a discrepancy in docs and implementation of different parts of the code
	// https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/pull-requests/30727/overview?commentId=4917242
	const fieldWithMacroParameters = Boolean(attrs?.parameters) ? attrs : rest;
	const { macroParams } = fieldWithMacroParameters?.parameters || {};

	const adf: ADNode = {
		attrs: fieldWithMacroParameters,
		type,
	};

	if (fragmentLocalId) {
		adf.marks = [
			{
				attrs: {
					localId: fragmentLocalId,
					name: null,
				},
				type: 'fragment',
			},
		];
	}

	// confluence needs to be updated to get rid of bodyType as per
	// https://product-fabric.atlassian.net/wiki/spaces/E/pages/252351342/Splitting+Extension+node+into+three+nodes
	// but for now, the beackend requires it to be sent
	adf.attrs.bodyType = 'none';

	if (type === 'bodiedExtension') {
		adf.content = content && content.length > 0 ? content : [];
		adf.attrs.bodyType = 'rich';
	}

	const keys = Object.keys(adf.attrs);

	keys.forEach((key) => {
		if (adf.attrs[key] === undefined || adf.attrs[key] === null) {
			delete adf.attrs[key];
		}
	});

	adf.attrs.parameters.macroParams = wrapMacroParams(macroParams);

	return adf;
}

export function formatAsMacroAttributes(node: ADNode): MacroAttributes {
	const { attrs, content, type } = node;
	const { macroParams } = attrs.parameters;

	// MacroAttributes and ADNode are pretty much the same type, but
	// MacroAttributes requires either "extension", "bodiedExtension", "multiBodiedExtension" or
	// "inlineExtension". This check is so we can pass the ADNode as a
	// MacroAttributes, but will default to "extension" if given an unexpected
	// value.
	let extensionType: ExtensionType = REGULAR_EXTENSION_TYPE;

	if (
		[MULTI_BODIED_EXTENSION_TYPE, BODIED_EXTENSION_TYPE, INLINE_EXTENSION_TYPE].indexOf(type) >= 0
	) {
		extensionType = type as ExtensionType;
	}

	const macroAttributes: MacroAttributes = {
		type: extensionType,
		attrs,
	};

	if (!macroAttributes.attrs.parameters) {
		macroAttributes.attrs.parameters = {};
	}

	if (content) {
		macroAttributes.content = content;
	}

	macroAttributes.attrs.parameters.macroParams = wrapMacroParams(macroParams);

	return macroAttributes;
}

export async function convertBody(
	from: string,
	to: string,
	body: string,
	contentIdContext?: string,
): Promise<string | undefined | null> {
	const data: ContentBodyConvertQueryResult = (
		await getApolloClient().query({
			query: ContentBodyConvertQuery,
			variables: {
				from,
				to,
				body,
				contentIdContext,
			},
		})
	).data;

	return data.experimentalContentBodyConvert?.value;
}

export async function formatAsLegacyMacro(node: ADNode | LegacyMacro): Promise<LegacyMacro> {
	// just return the node if it's already in legacy format;
	if (node.hasOwnProperty('name') && !node.hasOwnProperty('attrs')) {
		return Promise.resolve(node as LegacyMacro);
	}

	const adNode = node as ADNode;
	const { attrs } = adNode;
	const { extensionKey, parameters } = attrs || {
		extensionKey: null,
		parameters: {
			macroParams: {},
			macroMetadata: {
				schemaVersion: null,
			},
		},
	};
	const { macroParams, macroMetadata } = parameters;

	const type = node.type;
	const name = extensionKey || '';
	const params = unwrapMacroParams(macroParams || {});
	const defaultParameterValue = getDefaultParamValue(macroParams || {});
	const schemaVersion = (macroMetadata && macroMetadata.schemaVersion) || '1';

	if (!adNode.content || adNode.content.length === 0) {
		return {
			type,
			name,
			params,
			defaultParameterValue,
			schemaVersion,
			body: '',
		};
	}

	const responseBody = await convertBody(
		'atlas_doc_format',
		'storage',
		JSON.stringify({
			type: 'doc',
			content: adNode.content,
			version: 1,
		}),
	);

	return {
		type,
		name,
		params,
		defaultParameterValue,
		schemaVersion,
		body: responseBody || '',
	};
}

/**
 * Parses the extension's ADF node for its parameters. It will stringify all
 * parameters except `__bodyContent` which is a special parameter for plain
 * text macros. SSR is also using the same elements to prevent duplication. Moved here coz
 * prevent circular dependency with fabric-extension-handler and content-renderer
 *
 * @param node The extension node to parse from
 */

export function getParametersString(node: Extension): string {
	const parameters = { ...(node?.parameters?.macroParams || {}) };

	if (parameters.__bodyContent) {
		delete parameters.__bodyContent;
	}

	// CEMS-300: To provide a consistent parameter to macro vendors for
	// both the Tiny and Fabric editors, we unwrap the parameter values
	return JSON.stringify(unwrapMacroParams(parameters));
}
