import React, { createContext, useContext } from 'react';

import type {
	MessageEventDismissPayload,
	MessageEventReadPayload,
	MessageEventSnoozePayload,
} from './types';

export type DismissCallback = (payload: MessageEventDismissPayload) => void;

export type SnoozeCallback = (payload: MessageEventSnoozePayload) => void;

export type ReadCallback = (payload: MessageEventReadPayload) => void;

export type ViewedCallback = (payload: MessageEventReadPayload) => void;

export type ClickedCallback = (payload: MessageEventReadPayload) => void;

type Callback = SnoozeCallback | ReadCallback | DismissCallback | ViewedCallback | ClickedCallback;

type Unsubscribe = () => void;

type Subscribe = (subscriber: Callback) => Unsubscribe;

type Publish = Callback;

/**
 * Represents values related to message placement.
 * @typedef {Object} MessagePlacementValues
 * @property {(callback: DismissCallback) => void} onMessageDismiss - Callback function for dismissing a message.
 * @property {Callback} dismissMessageFromPlacement - Callback for dismissing a message from placement.
 * @property {(callback: ReadCallback) => void} onMessageRead - Callback function for marking a message as read.
 * @property {Callback} markReadFromPlacement - Callback for marking a message as read.
 * @property {(callback: ViewedCallback) => void} onMessageViewed - Callback function for marking a message as viewed.
 * @property {Callback} markViewedFromPlacement - Callback for marking a message as viewed.
 * @property {(callback: ClickedCallback) => void} onMessageClicked - Callback function for marking a message as clicked.
 * @property {Callback} markClickedFromPlacement - Callback for marking a message as clicked.
 * @property {(callback: SnoozeCallback) => void} onMessageSnoozed - Callback function for marking a message as snoozed.
 * @property {Callback} markSnoozedFromPlacement - Callback for marking a message as snoozed.
 *
 */
export type MessagePlacementValues = {
	onMessageDismiss: (callback: DismissCallback) => Unsubscribe;
	dismissMessageFromPlacement: DismissCallback;
	onMessageSnooze: (callback: SnoozeCallback) => Unsubscribe;
	snoozeMessageFromPlacement: SnoozeCallback;
	onMessageRead: (callback: ReadCallback) => Unsubscribe;
	markReadFromPlacement: ReadCallback;
	onMessageViewed: (callback: ViewedCallback) => Unsubscribe;
	markViewedFromPlacement: ViewedCallback;
	onMessageClicked: (callback: ClickedCallback) => Unsubscribe;
	markClickedFromPlacement: ClickedCallback;
};

/**
 * Creates a Publish-Subscribe pattern for managing callbacks.
 * @returns {Array<Subscribe, Publish>} An array containing a Subscribe function and a Publish function.
 */
const createPubSub = (): [Subscribe, Publish] => {
	const subscribers: Map<Callback, Callback> = new Map();

	/**
	 * Subscribes a callback function to the pub-sub system.
	 * @param {Callback} subscriber - The callback function to subscribe.
	 * @returns A function to unsubscribe the callback.
	 */
	const subscribe: Subscribe = (subscriber) => {
		subscribers.set(subscriber, subscriber);

		return () => {
			subscribers.delete(subscriber);
		};
	};

	/**
	 * Publishes the payload to all subscribed callbacks.
	 * @param {Callback} payload - The payload to publish to the subscribers.
	 */
	const publish: Publish = (payload) => {
		subscribers.forEach((cb) => cb(payload));
	};

	return [subscribe, publish];
};

/**
 * Creates the message placement values including callbacks for message dismiss, snooze, and read.
 * @returns {MessagePlacementValues} The message placement values with callback functions.
 */
export const createMessagePlacementValues = (): MessagePlacementValues => {
	const [onMessageSnooze, snoozeMessageFromPlacement] = createPubSub();
	const [onMessageDismiss, dismissMessageFromPlacement] = createPubSub();
	const [onMessageRead, markReadFromPlacement] = createPubSub();
	const [onMessageViewed, markViewedFromPlacement] = createPubSub();
	const [onMessageClicked, markClickedFromPlacement] = createPubSub();

	return {
		onMessageSnooze,
		snoozeMessageFromPlacement,
		onMessageDismiss,
		dismissMessageFromPlacement,
		onMessageRead,
		markReadFromPlacement,
		onMessageViewed,
		markViewedFromPlacement,
		onMessageClicked,
		markClickedFromPlacement,
	};
};

/**
 * Context for message placement with callbacks for message dismiss and read.
 */
export const MessagePlacement = createContext(createMessagePlacementValues());

/**
 * Provider component for the message placement context.
 */
export const MessagePlacementProvider = MessagePlacement.Provider;

/**
 * Creates a MessagePlacementProvider component with the provided value.
 * @returns A functional component that wraps child components to pass down context.
 */
export const createMessagePlacementProvider =
	() =>
	({ children }: { children: React.ReactNode }) => (
		<MessagePlacementProvider value={createMessagePlacementValues()}>
			{children}
		</MessagePlacementProvider>
	);

/**
 * Custom hook that returns the context value for message placement.
 * @returns {MessagePlacementValues} -  context for message placement
 */
export const useMessagePlacementHooks = (): MessagePlacementValues => useContext(MessagePlacement);
