import React from 'react';
import PropTypes from 'prop-types';

import UFOSegment from '@atlaskit/react-ufo/segment';

import { PageSegmentLoadStart, PageSegmentLoadMark } from '@confluence/browser-metrics';
import { LoadableLazy } from '@confluence/loadable';
import { CommentPlaceholder } from '@confluence/comment-simple/entry-points/CommentPlaceholder';
import { PAGE_COMMENTS_METRIC } from '@confluence/comments-section/entry-points/pageSegmentLoadMetrics';
import { usePageCommentsSortingExperiment } from '@confluence/page-comments/entry-points/feature-flags';
import { useIsPageCommentsAutoReloadEnabled } from '@confluence/page-comments';
import { useCommentCounts } from '@confluence/comments-hooks';
import { CommentCountsProvider } from '@confluence/comment-context';

/* if handlePageCommentsRender is passed from ViewPageFooterComments we handle
displaying CommentPlaceholder in that component till comments are rendered*/
export const CommentsSectionFabric = LoadableLazy({
	// eslint-disable-next-line react/prop-types
	loading: ({ handlePageCommentsRender }) => {
		return !handlePageCommentsRender ? <CommentPlaceholder /> : null;
	},
	loader: () =>
		import(/* webpackChunkName: "loadable-CommentsSectionFabric" */ './CommentsSectionFabric'),
});

export const CommentsSectionLoader = ({ contentId, ...props }) => {
	const { isPageCommentsSortingExperimentEnabled, pageCommentsSortingExperimentCohort } =
		usePageCommentsSortingExperiment();
	const isAutoReloadPageCommentsEnabled = useIsPageCommentsAutoReloadEnabled();
	// This information is available in the CommentsCountContext, but it also needs to be available to the legacy class
	// component so passing in directly from the hook as well
	const { unresolvedInlineCommentsCount } = useCommentCounts(contentId);

	return (
		<UFOSegment name="page-comments">
			<CommentCountsProvider contentId={contentId}>
				<PageSegmentLoadStart key={props.contentId} metric={PAGE_COMMENTS_METRIC} isCustomStart />
				<PageSegmentLoadMark markName="pageCommentsRenderStart" metric={PAGE_COMMENTS_METRIC} />
				<CommentsSectionFabric
					{...props}
					contentId={contentId}
					isPageCommentsSortingExperimentEnabled={isPageCommentsSortingExperimentEnabled}
					pageCommentsSortingExperimentCohort={pageCommentsSortingExperimentCohort}
					isAutoReloadPageCommentsEnabled={isAutoReloadPageCommentsEnabled}
					inlineCommentsCount={unresolvedInlineCommentsCount}
				/>
			</CommentCountsProvider>
		</UFOSegment>
	);
};

CommentsSectionLoader.propTypes = {
	contentId: PropTypes.string.isRequired,
};
