/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type NativeImageProps, type RenderFn } from '@atlassian/forge-ui-types';
import { useIsValidImageUrl } from '../../utils';
import { EgressErrorMessage } from '../..';

export const maxWidths = {
	xsmall: '25%',
	small: '37.5%',
	medium: '50%',
	large: '75%',
	xlarge: '100%',
};

function ImageInner({ forgeDoc }: Parameters<RenderFn>[0]) {
	const { src, alt, size = 'xlarge' } = forgeDoc.props as NativeImageProps;
	const maxWidth = maxWidths[size] || '100%';

	return (
		<img
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'block',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				margin: 'auto',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				maxWidth,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				textAlign: 'center',
			}}
			src={src}
			alt={alt}
		/>
	);
}

export const Image = (props: Parameters<RenderFn>[0]) => {
	const { src } = props.forgeDoc.props as NativeImageProps;

	const isValidUrl = useIsValidImageUrl(src);

	if (isValidUrl) {
		return <ImageInner {...props} />;
	}

	return <EgressErrorMessage url={src} />;
};
