import { combineReducers } from 'redux';
import { fromJS } from 'immutable';

// reducers
import homePage from './spaceHome/homePage';

const DEFAULT_SPACE_INFO_STATE = fromJS({
	results: [],
	isRequesting: null,
	hasFetched: false,
});

export function currentSpace(state = DEFAULT_SPACE_INFO_STATE, action) {
	switch (action.type) {
		case 'SPACE_INFO_SUCCESS':
			const isFound = action.response.results.length > 0;
			return state.merge({
				isRequesting: false,
				hasFetched: true,
				spaceKey: action.spaceKey,
				isFound,
			});

		case 'SPACE_INFO_REQUEST':
			return state.merge({
				hasFetched: false,
				isRequesting: true,
				spaceKey: action.spaceKey,
			});

		case 'SPACE_INFO_FAILURE':
			return state.merge({
				hasFetched: false,
				isRequesting: false,
				spaceKey: action.spaceKey,
			});

		default:
			return state;
	}
}

export default combineReducers({
	currentSpace,
	homePage,
});
