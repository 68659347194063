import { fromJS } from 'immutable';
import extend from 'lodash/extend';

import { getAllAsMap } from '@confluence/meta';

import { exclude, map } from '../lib/obj';

/***
 * @type {string[]} The list of supported __INITIAL_STATE__ items
 */
export const WHITELIST = new Set([
	'i18n',
	'watches',
	'pagination',
	'session',
	'views',
	'hover',
	'meta',
	'isServerRenderedPage',
]);

// This blacklist is so we can gradually remove the usage of meta tags from the server-side.
const META_BLACKLIST = new Set(['discovered-plugin-features']);

/***
 * Constructs the initialState from windowGlobal.__INITIAL_STATE__ and the localStorage.
 *
 * @param {Object} [windowGlobal=window] the window global to use, defaults to window. Argument used for testing DI purposes.
 * @param {Object} [localStorage=window.localStorage] the localStorage global to use. Argument used for testing DI purposes.
 */
export default function (windowGlobal = global, extraState = {}) {
	const initialState = Object.assign(windowGlobal.__INITIAL_STATE__ || {}, extraState);
	initialState.meta = extend(initialState.meta || {}, exclude(getAllAsMap(), META_BLACKLIST));

	return map(initialState, (value, key) => {
		if (!WHITELIST.has(key)) {
			console.error(
				`Unsupported key \"${key}\" was found in the __INITIAL_STATE__.\nCheck if it works and add it to the whitelist of the initialStateFactory.`,
			);
		}
		return fromJS(value);
	});
}
