import gql from 'graphql-tag';

export const ExternalCollaboratorEntitlementFragment = gql`
	fragment ExternalCollaboratorEntitlementFragment on Query {
		entitlements {
			externalCollaborator {
				isEntitled
			}
		}
	}
`;
