import { type MutableRefObject, createContext, useContext, useMemo, useRef } from 'react';

type MessageAnalyticsContextValue = {
	fireMessageViewedInMessageComponent: MutableRefObject<boolean> | null;
	setFireMessageViewedInMessageComponent: (value: boolean) => void;
};

const defaultMessageAnalyticsContextValue: MessageAnalyticsContextValue = {
	fireMessageViewedInMessageComponent: null,
	setFireMessageViewedInMessageComponent: () => {},
};

export const MessageAnalyticsContext = createContext<MessageAnalyticsContextValue>(
	defaultMessageAnalyticsContextValue,
);

export const MessageAnalyticsContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const fireMessageViewedInMessageComponent = useRef(false);
	const contextValue = useMemo(
		() => ({
			fireMessageViewedInMessageComponent,
			setFireMessageViewedInMessageComponent: (value: boolean) => {
				fireMessageViewedInMessageComponent.current = value;
			},
		}),
		[fireMessageViewedInMessageComponent],
	);
	return (
		<MessageAnalyticsContext.Provider value={contextValue}>
			{children}
		</MessageAnalyticsContext.Provider>
	);
};
export const useMessageAnalyticsContext = (): MessageAnalyticsContextValue =>
	useContext(MessageAnalyticsContext);
