/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::41ed5648927b0201af124d118c20fda5>>
 * @codegenId #bleed
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen bleed
 * @codegenParams { "props": { "children": true, "all": true, "inline": true, "block": true, "testId": true, "data-testid": false, "xcss": false, "role": true } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/bleed.tsx <<SignedSource::15adb710ca9761627c48e41e1555dc16>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::2f4848af544e0f64968f7c28012cf0f0>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::a91bf7842593d9052c045e4a40d7ab3b>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Bleed as PlatformBleed } from '@atlaskit/primitives';

type PlatformBleedProps = React.ComponentProps<typeof PlatformBleed>;

export type BleedProps = Pick<
  PlatformBleedProps,
  'children' | 'all' | 'inline' | 'block' | 'testId' | 'role'
>;

export const Bleed = (props: Parameters<RenderFn>[0]) => {
  const {
    all,
    inline,
    block,
    testId,
    role,
  } = props.forgeDoc.props as BleedProps;
  return (
    <PlatformBleed
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      all={all}
      inline={inline}
      block={block}
      testId={testId}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
