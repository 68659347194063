export const CONNECT_APP_PREFIX_KEY = 'com.atlassian.plugins.atlassian-connect-plugin';
export const PAGE_FAVOURITE_KEY = 'confluence.sections.page.temp:pagefavourite';
export const EDIT_BUTTON_PAGE_KEY = 'confluence.sections.page.temp:edit-page';
export const EDIT_BUTTON_BLOGPOST_KEY = 'confluence.sections.page.temp:edit-blogpost';
export const WATCH_BUTTON_KEY = 'com.atlassian.confluence.plugins.watch-button:watch';
export const SHARE_BUTTON_KEY = 'com.atlassian.confluence.plugins.share-page:share';
export const INVITE_TO_EDIT_KEY =
	'com.atlassian.confluence.plugins.confluence-invite-to-edit:share';
export const PAGE_UNFAVOURITE_KEY = 'confluence.sections.page.temp:pageunfavourite';
export const ADMIN_THEME_KEY = 'confluence.sections.admin:theme';
export const ADMIN_COLOUR_SCHEME_KEY = 'confluence.sections.admin:colourscheme';
export const ADMIN_CUSTOM_PAGE_CONTENT_KEY = 'confluence.sections.admin:custompagecontent';
export const CREATE_PAGE_KEY =
	'com.atlassian.confluence.plugins.confluence-create-content-plugin:header-create-dialog';
export const CREATE_NEW_PAGE_KEY =
	'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blank-page';
export const CREATE_NEW_BLOGPOST_KEY =
	'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blog-post';
export const SPACE_TAB_OVERVIEW_KEY =
	'com.atlassian.confluence.plugins.confluence-frontend-support:overview-link';
export const SPACE_TAB_PAGES_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-pages';
export const SPACE_TAB_BLOG_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-blogs';
export const SPACE_TAB_QUESTIONS_KEY =
	'com.atlassian.confluence.plugins.confluence-questions:cq.space.sidebar.link';
export const SPACE_TAB_CALENDARS_KEY =
	'com.atlassian.confluence.extra.team-calendars:space-calendar-sidebar-link';
export const SPACE_TAB_CONNECT_ADDON_KEY = 'com.atlassian.plugins.atlassian-connect-plugin';
export const SPACE_SETTINGS_KEY =
	'com.atlassian.confluence.plugins.confluence-frontend-support:space-tools-link';
