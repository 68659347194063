import type { ComponentClass, FC } from 'react';
import React from 'react';
import { createStore, createHook, createContainer, createSubscriber } from 'react-sweet-state';

type Events = Record<string, string>;

export type SearchSessionStateType = {
	/**
	 * Search ID attached to to page viewed events
	 */
	searchSessionId?: string | null;
	additionalAnalytics: Events;
};
type SearchSessionPropType = {
	searchSessionId?: string | null;
	additionalAnalyticsToParse?: string | null;
};

type SearchSessionActions = {
	setSearchSessionId: (
		searchSessionId?: string | null,
	) => ({ setState }: { setState: any }) => void;
	setAdditionalAnalytics: (
		additionalAnalyticsToParse?: string | null,
	) => ({ setState }: { setState: any }) => void;
};

const parseAnalyticsFromQueryParams = (additionalAnalyticsToParse?: string | null) => {
	const formattedEvents = {} as Events;
	if (typeof additionalAnalyticsToParse !== 'string') {
		return formattedEvents;
	}

	const events = additionalAnalyticsToParse?.split('____');
	events.forEach((event) => {
		const formattedEvent = event?.split('---', 2);
		const hasProperlyFormattedEvent = formattedEvent.length === 2;

		if (hasProperlyFormattedEvent) {
			formattedEvents[formattedEvent[0]] = formattedEvent[1];
		}
	});
	return formattedEvents;
};

const actions: SearchSessionActions = {
	setSearchSessionId:
		(searchSessionId) =>
		({ setState }) => {
			setState({ searchSessionId });
		},
	setAdditionalAnalytics:
		(additionalAnalyticsToParse) =>
		({ setState }) => {
			const parsedAnalytics = parseAnalyticsFromQueryParams(additionalAnalyticsToParse);
			setState({ additionalAnalytics: parsedAnalytics });
		},
};

const SearchSessionStore = createStore<SearchSessionStateType, SearchSessionActions>({
	initialState: {
		searchSessionId: undefined,
		additionalAnalytics: {},
	},
	actions,
	name: 'SearchSessionStore',
});

export const SearchSessionStateContainer = createContainer(SearchSessionStore, {
	onInit:
		() =>
		({ setState }, { searchSessionId, additionalAnalyticsToParse }: SearchSessionPropType) => {
			const parsedAnalytics = parseAnalyticsFromQueryParams(additionalAnalyticsToParse);
			setState({ searchSessionId, additionalAnalytics: parsedAnalytics });
		},
	onUpdate:
		() =>
		({ setState }, { searchSessionId, additionalAnalyticsToParse }: SearchSessionPropType) => {
			const parsedAnalytics = parseAnalyticsFromQueryParams(additionalAnalyticsToParse);
			setState({ searchSessionId, additionalAnalytics: parsedAnalytics });
		},
});

export const useSearchSessionId = createHook(SearchSessionStore, {
	selector: (state) => ({
		searchSessionId: state.searchSessionId,
		additionalAnalytics: state.additionalAnalytics,
	}),
});

export const SearchSessionSubscriber = createSubscriber(SearchSessionStore);

export function withSearchSession<P, SearchSessionStateType>(WrappedComponent: ComponentClass<P>) {
	const SearchSessionComponent: FC<P & SearchSessionStateType> = (props: P) => (
		<SearchSessionSubscriber>
			{(searchSessionState) => (
				<WrappedComponent {...props} searchSessionState={searchSessionState} />
			)}
		</SearchSessionSubscriber>
	);
	SearchSessionComponent.displayName = `withSearchSession(${
		WrappedComponent.displayName || WrappedComponent.name
	})`;
	return SearchSessionComponent;
}
