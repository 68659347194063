import type {
	DynamicFieldDefinitions,
	FieldDefinition,
	TabGroupField,
} from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from '../types';
import { parseBoolean } from '../utils';

import { i18n } from './i18n';
import { childMacroFields, getBasicTabFields, validateDepthFields } from './fields';

export interface ChildrenMacroParams {
	page?: string;
	first?: number | string;
	all?: boolean | string;
	sort?: string;
	reverse?: boolean | string;
	style?: string;
	excerptType?: string;
	depth?: number | string;
	allChildren?: boolean | string;
	sortAndReverse?: string;
}

const basicTabFields = ['page', 'first', 'all'];
const displayTabFields = ['sort', 'style', 'excerptType'];

export const children: FieldTransformer<ChildrenMacroParams> = {
	transformFields: async (
		fields,
		_context,
		intl,
		_params,
		createAnalyticsEvent,
	): Promise<DynamicFieldDefinitions<{}>> => {
		const fireErrorAnalytics = (tab, error) => {
			if (createAnalyticsEvent) {
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						action: 'error',
						actionSubject: 'transformFields',
						actionSubjectId: tab,
						source: 'field-mappings/children',
						attributes: {
							error: error.message,
							stack: error.stack,
						},
					},
				}).fire();
			}
		};

		const getDynamicFields: DynamicFieldDefinitions<{}> = (currentParams?) => {
			const fieldsObj = fields.reduce((obj, field) => {
				obj[field.name] = field;
				return obj;
			}, {});

			return [
				{
					type: 'tab-group',
					name: 'tabGroup',
					label: 'Child Pages Tab Group',
					defaultTab: 'basic',
					fields: [
						{
							type: 'tab',
							label: intl.formatMessage(i18n.basicTabLabel),
							name: 'basic',
							fields: [
								...basicTabFields.flatMap((fieldName) => {
									try {
										return getBasicTabFields(fieldName, fieldsObj, intl, currentParams);
									} catch (error) {
										fireErrorAnalytics('basicTab', error);
										return [];
									}
								}),
							],
						},
						{
							type: 'tab',
							label: intl.formatMessage(i18n.displayTabLabel),
							name: 'display',
							fields: [
								...displayTabFields.map((fieldName) => {
									try {
										return childMacroFields(fieldName, fieldsObj, intl);
									} catch (error) {
										fireErrorAnalytics('displayTab', error);
										return [];
									}
								}),
							],
						},
					],
				} as TabGroupField,
			] as FieldDefinition[];
		};
		return getDynamicFields;
	},

	transformBefore(params) {
		// We need to update 'Show all levels' toggle here as well to align param state with UI
		params.all = validateDepthFields(
			parseBoolean(params.all),
			typeof params.depth === 'number' ? params.depth : parseInt(params.depth ?? ''),
		);

		// `sort` and `reverse` params have been combined into a single `sortAndReverse` field
		// so we need to combine their values into one to pass into the new enum field
		params.sortAndReverse = params.sort ?? '';
		if (parseBoolean(params.reverse)) {
			params.sortAndReverse += '.true';
		}
		return params;
	},

	transformAfter(params) {
		// 'Show all child pages' toggle field
		const allChildren = parseBoolean(params.allChildren);
		if (allChildren) {
			params.first = 0; // 'Max number in list' field
		}
		if (!allChildren && !params.first) {
			params.first = 10; // set some non-0 value
		}

		// 'Show all levels' depth toggle field
		const allLevels = parseBoolean(params.all);
		if (allLevels) {
			params.depth = 0;
		}
		if (!allLevels && params.depth === 0) {
			params.depth = 1;
		}

		// Convert sort/reverse field back to manifest definition for BE handling
		[params.sort = '', params.reverse] = (params.sortAndReverse ?? '').split('.');

		if (!params.sort) {
			delete params.sort;
		}

		return params;
	},
};
