import React, { Component } from 'react';

import { requireLegacyWRM } from '@confluence/wrm';

/**
 * ========================================================
 * DEPRECATED, DO NOT MOVE THIS FILE TO NEXT.
 * Refactor your component to use @confluence/wrm directly.
 * ========================================================
 *
 * withWRMDependencies is a decorator for loading WRM dependencies.
 *
 * It's a helper which besides loading dependencies will also hide the component
 * while the dependencies are not loaded avoiding FOUC and weird behaviours.
 *
 * usage:
 *
 * withWRMDependencies('wr!mypluginkey')(
 *  class MyComponent extends Component {}
 * )
 *
 * or
 *
 * withWRMDependencies(['wrc!myContext', 'wr!myWebResource'])(
 *  class MyComponent extends Component {}
 * )
 */

function withWRMDependencies(dependencies, options) {
	const configs = {
		isBlocking: true,
		...options,
	};

	return (BaseComponent) => {
		class WRMComponent extends Component {
			state = {
				dependenciesLoaded: false,
			};

			componentDidMount() {
				this.mounted = true;
				if (dependencies && dependencies.length > 0) {
					requireLegacyWRM(dependencies, this.markAsLoaded, () => {
						if (process.env.NODE_ENV !== 'production') {
							console.error(
								'with-wrm-dependencies cannot be initialized when WRM is disabled. WRM required: ',
								dependencies,
							);
						}
					});
				} else {
					this.markAsLoaded();
				}
			}

			componentWillUnmount() {
				this.mounted = false;
			}

			markAsLoaded = () => {
				if (this.mounted) {
					this.setState({
						dependenciesLoaded: true,
					});
				}
			};

			render() {
				const { dependenciesLoaded } = this.state;
				if (dependenciesLoaded || !configs.isBlocking) {
					return <BaseComponent {...this.props} wrmDependenciesLoaded={dependenciesLoaded} />;
				} else {
					return null;
				}
			}
		}

		WRMComponent.displayName = `WRMComponent(${BaseComponent.displayName || BaseComponent.name})`;

		return WRMComponent;
	};
}

export default withWRMDependencies;
