import type { ReactNode } from 'react';
import React, { Component } from 'react';

import { KnownErrorBoundaryContext } from './KnownErrorBoundaryContext';

export type KnownErrorBoundaryProps = {
	children: ReactNode;
	isKnownError: (error: any) => boolean;
	onError?: (error: any) => void;
	renderOnError: (error: any) => ReactNode | null;
};

type State = {
	error: Error | null;
};

export class KnownErrorBoundary extends Component<KnownErrorBoundaryProps, State> {
	private willParentCatch: ((error: Error) => boolean) | undefined;

	readonly state: Readonly<State> = {
		error: null,
	};

	componentDidCatch(error: Error) {
		if (this.props.isKnownError(error)) {
			this.props.onError?.(error);
			this.setState({ error });
		} else {
			throw error;
		}
	}

	willCatch = (error: Error) =>
		Boolean(this.props.isKnownError(error) || this.willParentCatch?.(error));

	render() {
		const { error } = this.state;

		return (
			<KnownErrorBoundaryContext.Consumer>
				{(willCatch) => {
					this.willParentCatch = willCatch;

					return (
						<KnownErrorBoundaryContext.Provider value={this.willCatch}>
							{error ? this.props.renderOnError(error) : this.props.children}
						</KnownErrorBoundaryContext.Provider>
					);
				}}
			</KnownErrorBoundaryContext.Consumer>
		);
	}
}
