// *** GENERATED FILE - DO NOT EDIT ***
// WARNING: import needs to be statically analysable to not be problematic [at runtime]
import { lazy, type ComponentType, type LazyExoticComponent } from 'react';
import { type Icon } from '@atlassian/forge-ui-types';

type LazyIcon = LazyExoticComponent<ComponentType<any>>;
export const IconPaths: Record<Icon, LazyIcon> = {
	activity: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/activity'),
	),
	add: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/add')),
	'add-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/add-circle'),
	),
	'add-item': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/add-item'),
	),
	addon: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/addon')),
	'app-access': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/app-access'),
	),
	'app-switcher': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/app-switcher'),
	),
	archive: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/archive'),
	),
	'arrow-down': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-down'),
	),
	'arrow-down-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-down-circle'),
	),
	'arrow-left': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-left'),
	),
	'arrow-left-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-left-circle'),
	),
	'arrow-right': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-right'),
	),
	'arrow-right-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-right-circle'),
	),
	'arrow-up': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-up'),
	),
	'arrow-up-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/arrow-up-circle'),
	),
	attachment: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/attachment'),
	),
	audio: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/audio')),
	'audio-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/audio-circle'),
	),
	backlog: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/backlog'),
	),
	billing: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/billing'),
	),
	'billing-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/billing-filled'),
	),
	'bitbucket-branches': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/branches'),
	),
	'bitbucket-builds': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/builds'),
	),
	'bitbucket-clone': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/clone'),
	),
	'bitbucket-commits': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/commits'),
	),
	'bitbucket-compare': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/compare'),
	),
	'bitbucket-forks': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/forks'),
	),
	'bitbucket-output': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/output'),
	),
	'bitbucket-pipelines': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/pipelines'),
	),
	'bitbucket-pullrequests': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/pullrequests'
			),
	),
	'bitbucket-repos': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/repos'),
	),
	'bitbucket-snippets': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/snippets'),
	),
	'bitbucket-source': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bitbucket/source'),
	),
	board: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/board')),
	book: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/book')),
	'bullet-list': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/bullet-list'),
	),
	calendar: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/calendar'),
	),
	'calendar-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/calendar-filled'),
	),
	camera: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/camera'),
	),
	'camera-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/camera-filled'),
	),
	'camera-rotate': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/camera-rotate'),
	),
	'camera-take-picture': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/camera-take-picture'),
	),
	canvas: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/canvas'),
	),
	check: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/check')),
	'check-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/check-circle'),
	),
	'check-circle-outline': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/check-circle-outline'),
	),
	checkbox: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/checkbox'),
	),
	'checkbox-indeterminate': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/checkbox-indeterminate'
			),
	),
	'chevron-down': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-down'),
	),
	'chevron-down-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-down-circle'),
	),
	'chevron-left': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-left'),
	),
	'chevron-left-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-left-circle'),
	),
	'chevron-left-large': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-left-large'),
	),
	'chevron-right': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-right'),
	),
	'chevron-right-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-right-circle'),
	),
	'chevron-right-large': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-right-large'),
	),
	'chevron-up': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-up'),
	),
	'chevron-up-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/chevron-up-circle'),
	),
	'child-issues': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/child-issues'),
	),
	code: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/code')),
	comment: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/comment'),
	),
	component: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/component'),
	),
	copy: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/copy')),
	creditcard: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/creditcard'),
	),
	'creditcard-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/creditcard-filled'),
	),
	cross: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/cross')),
	'cross-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/cross-circle'),
	),
	dashboard: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/dashboard'),
	),
	decision: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/decision'),
	),
	department: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/department'),
	),
	'detail-view': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/detail-view'),
	),
	discover: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/discover'),
	),
	'discover-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/discover-filled'),
	),
	document: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/document'),
	),
	'document-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/document-filled'),
	),
	documents: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/documents'),
	),
	download: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/download'),
	),
	'drag-handler': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/drag-handler'),
	),
	dropbox: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/dropbox'),
	),
	edit: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/edit')),
	'edit-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/edit-filled'),
	),
	'editor-add': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/add'),
	),
	'editor-addon': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/addon'),
	),
	'editor-advanced': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/advanced'),
	),
	'editor-align-center': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/align-center'),
	),
	'editor-align-image-center': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/align-image-center'
			),
	),
	'editor-align-image-left': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/align-image-left'
			),
	),
	'editor-align-image-right': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/align-image-right'
			),
	),
	'editor-align-left': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/align-left'),
	),
	'editor-align-right': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/align-right'),
	),
	'editor-attachment': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/attachment'),
	),
	'editor-background-color': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/background-color'
			),
	),
	'editor-bold': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/bold'),
	),
	'editor-bullet-list': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/bullet-list'),
	),
	'editor-close': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/close'),
	),
	'editor-code': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/code'),
	),
	'editor-collapse': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/collapse'),
	),
	'editor-date': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/date'),
	),
	'editor-decision': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/decision'),
	),
	'editor-divider': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/divider'),
	),
	'editor-done': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/done'),
	),
	'editor-edit': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/edit'),
	),
	'editor-emoji': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/emoji'),
	),
	'editor-error': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/error'),
	),
	'editor-expand': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/expand'),
	),
	'editor-feedback': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/feedback'),
	),
	'editor-file': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/file'),
	),
	'editor-file-preview': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/file-preview'),
	),
	'editor-help': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/help'),
	),
	'editor-hint': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/hint'),
	),
	'editor-horizontal-rule': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/horizontal-rule'
			),
	),
	'editor-image': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/image'),
	),
	'editor-image-border': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/image-border'),
	),
	'editor-image-resize': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/image-resize'),
	),
	'editor-indent': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/indent'),
	),
	'editor-info': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/info'),
	),
	'editor-italic': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/italic'),
	),
	'editor-layout-single': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/layout-single'),
	),
	'editor-layout-three-equal': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/layout-three-equal'
			),
	),
	'editor-layout-three-with-sidebars': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/layout-three-with-sidebars'
			),
	),
	'editor-layout-two-equal': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/layout-two-equal'
			),
	),
	'editor-layout-two-left-sidebar': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/layout-two-left-sidebar'
			),
	),
	'editor-layout-two-right-sidebar': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/layout-two-right-sidebar'
			),
	),
	'editor-link': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/link'),
	),
	'editor-media-center': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/media-center'),
	),
	'editor-media-full-width': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/media-full-width'
			),
	),
	'editor-media-wide': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/media-wide'),
	),
	'editor-media-wrap-left': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/media-wrap-left'
			),
	),
	'editor-media-wrap-right': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/media-wrap-right'
			),
	),
	'editor-mention': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/mention'),
	),
	'editor-more': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/more'),
	),
	'editor-note': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/note'),
	),
	'editor-number-list': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/number-list'),
	),
	'editor-open': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/open'),
	),
	'editor-outdent': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/outdent'),
	),
	'editor-panel': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/panel'),
	),
	'editor-photo': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/photo'),
	),
	'editor-quote': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/quote'),
	),
	'editor-recent': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/recent'),
	),
	'editor-redo': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/redo'),
	),
	'editor-remove': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/remove'),
	),
	'editor-remove-emoji': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/remove-emoji'),
	),
	'editor-search': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/search'),
	),
	'editor-settings': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/settings'),
	),
	'editor-strikethrough': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/strikethrough'),
	),
	'editor-success': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/success'),
	),
	'editor-table': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/table'),
	),
	'editor-table-display-options': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/table-display-options'
			),
	),
	'editor-task': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/task'),
	),
	'editor-text-color': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/text-color'),
	),
	'editor-text-style': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/text-style'),
	),
	'editor-underline': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/underline'),
	),
	'editor-undo': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/undo'),
	),
	'editor-unlink': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/unlink'),
	),
	'editor-warning': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/editor/warning'),
	),
	email: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/email')),
	emoji: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji')),
	'emoji-add': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji-add'),
	),
	'emoji-activity': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/activity'),
	),
	'emoji-atlassian': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/atlassian'),
	),
	'emoji-custom': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/custom'),
	),
	'emoji-emoji': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/emoji'),
	),
	'emoji-flags': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/flags'),
	),
	'emoji-food': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/food'),
	),
	'emoji-frequent': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/frequent'),
	),
	'emoji-keyboard': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/keyboard'),
	),
	'emoji-nature': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/nature'),
	),
	'emoji-objects': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/objects'),
	),
	'emoji-people': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/people'),
	),
	'emoji-productivity': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/productivity'),
	),
	'emoji-symbols': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/symbols'),
	),
	'emoji-travel': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/emoji/travel'),
	),
	error: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/error')),
	export: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/export'),
	),
	feedback: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/feedback'),
	),
	file: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/file')),
	filter: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/filter'),
	),
	'flag-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/flag-filled'),
	),
	folder: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/folder'),
	),
	'folder-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/folder-filled'),
	),
	followers: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/followers'),
	),
	following: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/following'),
	),
	googledrive: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/googledrive'),
	),
	'graph-bar': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/graph-bar'),
	),
	'graph-line': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/graph-line'),
	),
	gsuite: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/gsuite'),
	),
	highlights: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/highlights'),
	),
	'hipchat-audio-only': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/audio-only'),
	),
	'hipchat-chevron-double-down': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/chevron-double-down'
			),
	),
	'hipchat-chevron-double-up': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/chevron-double-up'
			),
	),
	'hipchat-chevron-down': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/chevron-down'),
	),
	'hipchat-chevron-up': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/chevron-up'),
	),
	'hipchat-dial-out': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/dial-out'),
	),
	'hipchat-lobby': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/lobby'),
	),
	'hipchat-media-attachment-count': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/media-attachment-count'
			),
	),
	'hipchat-outgoing-sound': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/outgoing-sound'
			),
	),
	'hipchat-sd-video': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/hipchat/sd-video'),
	),
	home: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/home')),
	'home-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/home-circle'),
	),
	image: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/image')),
	'image-border': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/image-border'),
	),
	'image-resize': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/image-resize'),
	),
	info: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/info')),
	'invite-team': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/invite-team'),
	),
	issue: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/issue')),
	'issue-raise': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/issue-raise'),
	),
	issues: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/issues'),
	),
	'jira-capture': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/jira/capture'),
	),
	'jira-failed-build-status': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/jira/failed-build-status'
			),
	),
	'jira-labs': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/jira/labs'),
	),
	'jira-test-session': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/jira/test-session'),
	),
	label: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/label')),
	lightbulb: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/lightbulb'),
	),
	'lightbulb-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/lightbulb-filled'),
	),
	like: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/like')),
	link: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/link')),
	'link-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/link-filled'),
	),
	list: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/list')),
	location: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/location'),
	),
	lock: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/lock')),
	'lock-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/lock-circle'),
	),
	'lock-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/lock-filled'),
	),
	marketplace: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/marketplace'),
	),
	'media-services-actual-size': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/actual-size'
			),
	),
	'media-services-add-comment': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/add-comment'
			),
	),
	'media-services-annotate': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/annotate'
			),
	),
	'media-services-arrow': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/arrow'),
	),
	'media-services-audio': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/audio'),
	),
	'media-services-blur': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/blur'),
	),
	'media-services-brush': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/brush'),
	),
	'media-services-button-option': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/button-option'
			),
	),
	'media-services-code': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/code'),
	),
	'media-services-document': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/document'
			),
	),
	'media-services-filter': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/filter'),
	),
	'media-services-fit-to-page': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/fit-to-page'
			),
	),
	'media-services-full-screen': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/full-screen'
			),
	),
	'media-services-grid': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/grid'),
	),
	'media-services-image': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/image'),
	),
	'media-services-line': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/line'),
	),
	'media-services-line-thickness': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/line-thickness'
			),
	),
	'media-services-no-image': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/no-image'
			),
	),
	'media-services-open-mediaviewer': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/open-mediaviewer'
			),
	),
	'media-services-oval': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/oval'),
	),
	'media-services-pdf': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/pdf'),
	),
	'media-services-preselected': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/preselected'
			),
	),
	'media-services-presentation': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/presentation'
			),
	),
	'media-services-rectangle': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/rectangle'
			),
	),
	'media-services-scale-large': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/scale-large'
			),
	),
	'media-services-scale-small': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/scale-small'
			),
	),
	'media-services-spreadsheet': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/spreadsheet'
			),
	),
	'media-services-text': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/text'),
	),
	'media-services-unknown': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/unknown'
			),
	),
	'media-services-video': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/video'),
	),
	'media-services-zip': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/zip'),
	),
	'media-services-zoom-in': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/zoom-in'
			),
	),
	'media-services-zoom-out': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/media-services/zoom-out'
			),
	),
	mention: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/mention'),
	),
	menu: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/menu')),
	'menu-expand': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/menu-expand'),
	),
	mobile: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/mobile'),
	),
	more: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/more')),
	'more-vertical': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/more-vertical'),
	),
	notification: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/notification'),
	),
	'notification-all': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/notification-all'),
	),
	'notification-direct': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/notification-direct'),
	),
	'office-building': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/office-building'),
	),
	'office-building-filled': lazy(
		() =>
			import(
				/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/office-building-filled'
			),
	),
	open: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/open')),
	overview: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/overview'),
	),
	page: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/page')),
	'page-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/page-filled'),
	),
	pdf: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/pdf')),
	people: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/people'),
	),
	'people-group': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/people-group'),
	),
	person: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/person'),
	),
	'person-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/person-circle'),
	),
	'person-with-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/person-with-circle'),
	),
	'person-with-cross': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/person-with-cross'),
	),
	'person-with-tick': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/person-with-tick'),
	),
	portfolio: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/portfolio'),
	),
	preferences: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/preferences'),
	),
	premium: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/premium'),
	),
	'presence-active': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/presence-active'),
	),
	'presence-busy': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/presence-busy'),
	),
	'presence-unavailable': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/presence-unavailable'),
	),
	question: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/question'),
	),
	'question-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/question-circle'),
	),
	questions: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/questions'),
	),
	queues: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/queues'),
	),
	quote: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/quote')),
	radio: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/radio')),
	recent: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/recent'),
	),
	redo: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/redo')),
	refresh: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/refresh'),
	),
	retry: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/retry')),
	roadmap: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/roadmap'),
	),
	'room-menu': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/room-menu'),
	),
	schedule: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/schedule'),
	),
	'schedule-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/schedule-filled'),
	),
	screen: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/screen'),
	),
	search: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/search'),
	),
	'select-clear': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/select-clear'),
	),
	send: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/send')),
	settings: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/settings'),
	),
	share: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/share')),
	ship: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/ship')),
	shortcut: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/shortcut'),
	),
	'sign-in': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/sign-in'),
	),
	'sign-out': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/sign-out'),
	),
	sprint: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/sprint'),
	),
	star: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/star')),
	'star-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/star-filled'),
	),
	'star-large': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/star-large'),
	),
	status: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/status'),
	),
	stopwatch: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/stopwatch'),
	),
	subtask: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/subtask'),
	),
	suitcase: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/suitcase'),
	),
	switcher: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/switcher'),
	),
	table: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/table')),
	task: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/task')),
	teams: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/teams')),
	trash: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/trash')),
	tray: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/tray')),
	undo: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/undo')),
	unlink: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/unlink'),
	),
	unlock: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/unlock'),
	),
	'unlock-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/unlock-circle'),
	),
	'unlock-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/unlock-filled'),
	),
	upload: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/upload'),
	),
	'user-avatar-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/user-avatar-circle'),
	),
	'vid-audio-muted': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-audio-muted'),
	),
	'vid-audio-on': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-audio-on'),
	),
	'vid-backward': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-backward'),
	),
	'vid-camera-off': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-camera-off'),
	),
	'vid-camera-on': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-camera-on'),
	),
	'vid-connection-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-connection-circle'),
	),
	'vid-forward': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-forward'),
	),
	'vid-full-screen-off': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-full-screen-off'),
	),
	'vid-full-screen-on': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-full-screen-on'),
	),
	'vid-hang-up': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-hang-up'),
	),
	'vid-hd-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-hd-circle'),
	),
	'vid-pause': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-pause'),
	),
	'vid-play': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-play'),
	),
	'vid-raised-hand': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-raised-hand'),
	),
	'vid-share-screen': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-share-screen'),
	),
	'vid-speaking-circle': lazy(
		() =>
			import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-speaking-circle'),
	),
	'vid-volume-full': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-volume-full'),
	),
	'vid-volume-half': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-volume-half'),
	),
	'vid-volume-muted': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/vid-volume-muted'),
	),
	'video-circle': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/video-circle'),
	),
	'video-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/video-filled'),
	),
	warning: lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/warning'),
	),
	watch: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/watch')),
	'watch-filled': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/watch-filled'),
	),
	world: lazy(() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/world')),
	'world-small': lazy(
		() => import(/* webpackChunkName: "forge-ui-icons" */ '@atlaskit/icon/glyph/world-small'),
	),
};
