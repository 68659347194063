import { requireLegacyWRM } from '@confluence/wrm';

/**
 * ========================================================
 * DEPRECATED, DO NOT MOVE THIS FILE TO NEXT.
 * Refactor your component to use @confluence/wrm directly.
 * ========================================================
 */
const WRM = {
	require(dependencies, callback) {
		return new Promise((resolve) => {
			requireLegacyWRM(
				dependencies,
				(modules) => {
					resolve(modules);
					if (callback) {
						callback(modules);
					}
				},
				() => {
					if (process.env.NODE_ENV !== 'production') {
						console.error(
							'WRM.require from @confluence-classic/confluence-wrm cannot be initialized when WRM is disabled. WRM required: ',
							dependencies,
						);
					}
				},
			);
		});
	},
};

export default WRM;
