import React from 'react';

import type { RouteMatch } from '@confluence/route';

import { LazySpaceCreatePage } from '../lazyComponents';
import { navdexEnd } from '../naxdev';

export const CreateBlogRoute = () => <LazySpaceCreatePage contentType="blogpost" />;

CreateBlogRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		showPremiumOnboarding: false,
		screenEvent: { name: 'editPageScreen', ...navdexEnd },
		pageState: { spaceKey },
	},
	MainLayout: false,
});
