import { type Fetcher, PlacementFetchingProvider } from '@atlassian/post-office-placement-data';
import { type EligibleMessage } from '@post-office/shared-contracts';
import React, { useMemo } from 'react';

import { type Payload, type PlacementPreviewProviderProps } from './types';

export const PLACEMENT_REQUEST_URL = `/gateway/api/post-office/api/v1/placements/`;
export const PREVIEW_REQUEST_URL = `/gateway/api/post-office/api/v1/placement-preview/`;

export const PlacementPreviewProvider: React.FC<PlacementPreviewProviderProps> = ({
	eligibleMessages,
	children,
}: PlacementPreviewProviderProps) => {
	const fetch = useMemo(() => createPreviewFetch({ eligibleMessages }), [eligibleMessages]);

	return <PlacementFetchingProvider fetch={fetch}>{children}</PlacementFetchingProvider>;
};

export const createPreviewFetch =
	({ eligibleMessages }: { eligibleMessages: Array<EligibleMessage> }): Fetcher['fetch'] =>
	(url, options) => {
		const finalUrl = url.replace(PLACEMENT_REQUEST_URL, PREVIEW_REQUEST_URL);

		const payload: Payload = {
			originalMethod: options?.method || 'GET',
			originalBody: parseBody(options?.body),
			eligibleMessages,
		};

		return globalThis.fetch(finalUrl, {
			...options,
			method: 'POST',
			headers: {
				...(options?.headers || {}),
			},
			body: stringifyPayload(payload),
		});
	};

const objectHasKeys = (input: NonNullable<unknown>): input is Record<string, unknown> =>
	!!Object.keys(input).length;

const parseBody = (body?: BodyInit | null): Record<string, unknown> | undefined => {
	if (typeof body === 'undefined' || body === null) return undefined;

	try {
		const parsedBody = JSON.parse(body as string) as NonNullable<unknown>;

		if (objectHasKeys(parsedBody)) {
			return parsedBody;
		}

		throw new Error();
	} catch {
		throw new Error('Unable to parse preview body. Is it a valid object?');
	}
};

const stringifyPayload = (payload: Payload): string => {
	try {
		return JSON.stringify(payload);
	} catch {
		throw new Error('Unable to parse preview body. Is it a valid object?');
	}
};
