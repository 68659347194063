export interface PermissionsTokenState {
	getToken: Promise<string | null>;
	isUsedForDraftFetch: boolean;
	isUsedForWSInitialization: boolean;
	timestamp?: number;
}

/**
 * Class to store the preloaded NCS permissions token, to be used ONLY for the first initialization of the editor via view page (ie clicking edit from the view page, "e" shorcut).
 *
 * The token is first fetched on edit button hover and/or click. If valid, it is then passed to the getDraft endpoint to fetch the initial NCS draft.
 * The same token is then used for the FIRST websocket initialization to complete the first editor initialization, after which point it is cleared so as to not be used again.
 * The token is NOT stored/used for other types of editing sessions like initial loads of the edit route, consecutive editing sessions (if the session disconnects and then reconnects), or scenarios where the token is null.
 *
 * tokenState consists of:
 * `getToken`: a promise that resolves either the permissions token string or null, fetched by getCollabToken
 * `isUsedForDraftFetch`: boolean to determine if the token has successfully been passed to the getDraft endpoint via the X-Token header
 * `isUsedForWSInitialization`: boolean to determine if the token has been used during the initial NCS websocket initialization
 * `timestamp`: optional timestamp when the token has been fetched. It can be used to determine if the token has has expired past a certain timeout (ie when fetched on hover)
 *
 * tokenState is stored by contentId. In principle, there should only ever be one contentId/token generated at a given time so mapping by
 * contentId is another precaution to ensure that tokens are regenerated/token state is cleared when contentIds change (ie transitions between pages).
 *
 * tokenState is cleared once the editor is first connected, as well as when the provider is destroyed.
 */
export class CollabPermissionsTokenState {
	private tokenState: Record<string, PermissionsTokenState> = {};

	public getPermissionsTokenState(contentId: string): PermissionsTokenState {
		return this.tokenState[contentId];
	}

	public setToken(contentId: string, tokenPromise: Promise<string | null> = Promise.resolve(null)) {
		// clear any existing tokens/bools in state
		this.tokenState[contentId] = {
			getToken: tokenPromise,
			isUsedForDraftFetch: false,
			isUsedForWSInitialization: false,
		};
	}

	public setIsUsedForDraftFetch(contentId: string, isUsedForDraftFetch: boolean) {
		if (!this.tokenState[contentId]) {
			return;
		}
		this.tokenState[contentId].isUsedForDraftFetch = isUsedForDraftFetch;
	}

	public setIsUsedForWSInitialization(contentId: string, isUsedForWSInitialization: boolean) {
		if (!this.tokenState[contentId]) {
			return;
		}
		this.tokenState[contentId].isUsedForWSInitialization = isUsedForWSInitialization;
	}

	public setTimestamp(contentId: string) {
		if (!this.tokenState[contentId]) {
			return;
		}
		this.tokenState[contentId].timestamp = performance.now();
	}

	public clearPermissionsTokenState(contentId: string) {
		if (this.tokenState[contentId]) {
			delete this.tokenState[contentId];
		}
	}
}

export const nativeCollabPermissionsTokenState = new CollabPermissionsTokenState();
