import { type CoreDataInner, type ExtensionData } from '@atlassian/forge-ui-types';

import type { ResolverContext } from '../types';

export const getResolverContext = (
	coreData: CoreDataInner,
	extensionData: ExtensionData,
): ResolverContext => ({
	...coreData,
	extension: extensionData,
});
