import gql from 'graphql-tag';

import { WatchDialogContentFragment } from './WatchDialogContent.fragment';

export const WatchDialogQuery = gql`
	query WatchDialogQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			...WatchDialogContentFragment
		}
	}

	${WatchDialogContentFragment}
`;
