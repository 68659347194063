/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-classes-per-file */

import { buildActionFields } from '../eventBuilder';
import { equals, omit } from '../objectUtils';

import { CompressionRule } from './compressionRule';

export default class EventCompressor {
	private compressionRules: CompressionRule[];

	constructor(compressionRules: CompressionRule[] = []) {
		if (!Array.isArray(compressionRules)) {
			throw new Error('Event compressors must be constructed with an array of CompressionRules');
		}

		if (!compressionRules.every((rule: any) => rule instanceof CompressionRule)) {
			throw new Error(
				'Event compressors can only be constructed with instances of CompressionRule',
			);
		}

		this.compressionRules = compressionRules;
	}

	canCompress = (event: any) =>
		this.compressionRules.some((compressionRule: any) => compressionRule.canCompress(event));

	compress = (events: any) => {
		const groups = this.createGroups(events);
		return groups.reduce((allCompressedEvents: any, group: any) => {
			const groupCompressedEvents = this.compressGroup(group);
			return allCompressedEvents.concat(groupCompressedEvents);
		}, []);
	};

	private createGroups = (events: any) =>
		// Group events based on contextual fields. These fields are anything that is added by the client itself,
		// rather than passed in by the caller (eg. product, org. and tenant info)
		events.reduce((groups: any[], event: any) => {
			const matchingCompressor = this.compressionRules.find((compressor) =>
				compressor.canCompress(event),
			);

			let contextFields: any;
			if (matchingCompressor) {
				const actionFields = buildActionFields(event, event.eventType);
				contextFields = omit(event, Object.keys(actionFields));
			}

			const matchingGroup = groups.find(
				(group: any) =>
					matchingCompressor === group.compressor && equals(contextFields, group.contextFields),
			);

			if (matchingGroup) {
				matchingGroup.events.push(event);
			} else {
				groups.push({
					contextFields,
					compressor: matchingCompressor,
					events: [event],
				});
			}

			return groups;
		}, []);

	private compressGroup = (group: any) => {
		// If this group doesn't have any compressor, then the event args are already in their final format
		if (!group.compressor) {
			return group.events;
		}

		try {
			// Run the compressor on the group to generate some new events.
			// The compression function is only expected to return the action fields for each
			// event that it generates, since all other fields are generated by the client.
			const compressedEventActionFields = group.compressor.compress(group.events);

			// Add the context fields to each of the resulting events to inflate them into a full action event
			return compressedEventActionFields.map((actionFields: any) => ({
				...actionFields,
				...group.contextFields,
			}));
		} catch (e) {
			// If we fail to compress the events, then just fall back the uncompressed events
			// so that no data is lost. This can happen if the compression function throws an error
			// or returns some invalid event payloads.
			// eslint-disable-next-line no-console
			console.warn(
				'Failed to compress some analytics events. ' +
					`Error: ${(e as Error).message}. Sending ${
						group.events.length
					} uncompressed events instead`,
			);
			return group.events;
		}
	};
}
