import type { ActionMeasure } from './types';
import { BaseMeasures } from './BaseMeasures';

class SSRMeasure extends BaseMeasures {
	private startTimeOffset: number = 0;
	private startTime: number = 0;
	private fetchCount: number = 0;

	public fetchCountIncr() {
		this.fetchCount++;
	}

	public getFetchCount() {
		return this.fetchCount;
	}

	public setStartTime(startTimeOffset: number = 0): void {
		this.startTimeOffset = startTimeOffset;
		if (!this.startTime) {
			this.startTime = Math.floor(performance.now());
		}
	}

	public getStartTime() {
		return this.startTime;
	}

	public getStartTimeOffset() {
		return this.startTimeOffset;
	}

	override markMeasureStart(name: string) {
		if (!this.measures[name]) {
			this.measures[name] = {
				startTime: Math.floor(performance.now()) - this.startTime,
				duration: 0,
			};
		}
	}

	override markMeasureEnd(name: string) {
		const entry = this.measures[name];
		if (entry) {
			entry.duration = Math.floor(performance.now()) - this.startTime - entry.startTime;
		}
	}

	override getMeasures(): Record<string, ActionMeasure> {
		for (const key in this.measures) {
			this.measures[key].startTime += this.startTimeOffset;
		}
		return this.measures;
	}

	override clearMeasures(): void {
		this.startTimeOffset = 0;
		this.startTime = 0;
		this.fetchCount = 0;
		this.measures = {};
	}
}

export const SSRMeasures = new SSRMeasure();
