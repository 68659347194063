import {
	type ContextId,
	type ExtensionId,
	type EnvironmentType,
	type ForgeContextToken,
	type ForgeMetrics,
	type LicenseDetails,
} from '@atlassian/forge-ui-types';
import { type GraphQLError } from 'graphql';

export interface GQLUser {
	accountId: string;
	name: string;
	picture: string;
	__typename?: 'GQLUser';
}

export interface GQLExtension {
	id: string; // ari:cloud:ecosystem::extension/<appId>/<environmentId>/static/<key>
	// Marking as optional to not introduce a breaking type change into Confluence
	appId?: string;
	key?: string;
	environmentId: string;
	environmentType: EnvironmentType;
	environmentKey: string;
	properties: any;
	// Marking as optional to not introduce a breaking type change into Confluence
	license?: LicenseDetails | null;
	type: string;
	installationId: string;
	appVersion?: string;
	appOwner?: GQLUser;
	consentUrl?: string;
	currentUserConsent?: {
		user: {
			aaid: GQLUser['accountId'];
		};
		appEnvironmentVersion: {
			id: string;
		};
		consentedAt: string;
	};
	requiresUserConsent?: boolean;
	migrationKey?: string;
	egress?: {
		type: string;
		addresses: string[];
	}[];
	dataClassificationPolicyDecision?: {
		status: string;
	};

	__typename?: 'GQLExtension';
}

export interface GQLExtensionContextExtensionsByTypeVariables {
	type: string;
}

export interface GQLExtensionContextsVariables {
	contextIds: ContextId[];
}

export enum GQLExtensionContextsFilterType {
	Container = 'CONTAINER',
	DataClassificationTag = 'DATA_CLASSIFICATION_TAG',
	ExtensionType = 'EXTENSION_TYPE',
	PrincipalType = 'PRINCIPAL_TYPE',
}
export interface GQLExtensionContextsFilter {
	type: GQLExtensionContextsFilterType;
	value: string[];
}

export interface GQLExtensionContextOld<TExtension = GQLExtension> {
	id: string;
	extensionsByType: TExtension[];
	__typename?: 'GQLExtensionContext';
}
export interface GQLExtensionContext<TExtension = GQLExtension> {
	id: string;
	extensions: TExtension[];
	__typename?: 'GQLExtensionContext';
}

export interface GQLCustomUITunnelDefinition {
	resourceKey: string;
	tunnelUrl: string;
}

export interface GQLAppTunnels {
	faasTunnelUrl: string;
	customUI: GQLCustomUITunnelDefinition[];
	__typename?: 'GQLAppTunnels';
}

export interface GQLMutationResponse {
	success: boolean;
	errors: GQLMutationError[] | null;
}

export interface GQLMutationError<Fields = unknown> {
	message: string;
	extensions: {
		statusCode: number;
		errorType: string;
		fields?: Fields;
	};
}

export interface GQLEffect {
	type: string;
	[key: string]: any;
}

export interface GQLAuxEffectsInvocationPayload {
	config?: object;
	context: object;
	contextToken?: string;
	effects: GQLEffect[];
	state: object;
}

export interface GQLInvokeAuxEffectsInput {
	contextIds: ContextId[];
	extensionId: ExtensionId;
	payload: GQLAuxEffectsInvocationPayload;
	function?: string;
}

export interface GQLAuxEffectsResult {
	effects: GQLEffect[];
	contextToken?: ForgeContextToken;
	metrics?: ForgeMetrics;
	__typename?: 'GQLAuxEffectsResult';
}

export interface GQLInvokeAuxEffectsResponse extends GQLMutationResponse {
	result?: GQLAuxEffectsResult;
}

export interface GQLInvokeExtensionPayload {
	call: {
		functionKey: string;
		payload: object;
	};
	context: {
		[key: string]: any;
	};
	contextToken?: string;
	extensionPayload?: {
		[key: string]: any;
	};
	exportType?: string;
}

export type GQLInvokeExtensionEntrypoint = 'resolver' | 'adfExport';

export interface GQLInvokeExtensionInput {
	contextIds: ContextId[];
	extensionId: ExtensionId;
	payload: GQLInvokeExtensionPayload;
	entryPoint: GQLInvokeExtensionEntrypoint;
}

export interface GQLInvokeExtensionResponse extends GQLMutationResponse {
	success: boolean;
	response: {
		body: any;
	};
	errors: Array<GQLMutationError<{ authInfoUrl?: string }>>;
	contextToken?: ForgeContextToken;
}

export interface GQLUserAuthTokenForExtensionInput {
	extensionId: ExtensionId;
	contextIds: ContextId[];
}

export interface GQLUserAuthTokenForExtensionResponse extends GQLMutationResponse {
	authToken?: {
		token: string;
		ttl: number;
	};
}

export interface GQLUnderlyingServiceError extends GraphQLError {
	extensions: {
		errorSource: 'UNDERLYING_SERVICE';
	};
}

export interface GQLGatewayError extends GraphQLError {
	extensions: {
		errorSource: 'GRAPHQL_GATEWAY';
	};
}

export const isGQLGatewayError = (err: GraphQLError): err is GQLGatewayError =>
	err.extensions && err.extensions.errorSource && err.extensions.errorSource === 'GRAPHQL_GATEWAY';

export const isGQLUnderlyingServiceError = (err: GraphQLError): err is GQLUnderlyingServiceError =>
	err.extensions &&
	err.extensions.errorSource &&
	err.extensions.errorSource === 'UNDERLYING_SERVICE';

export type { GQLExtension as Extension, GQLExtensionContext as ExtensionContext };
