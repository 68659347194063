class DialogKeyState {
	private _nextKey = 0;

	get nextKey(): number {
		return this._nextKey++;
	}

	set nextKey(newKey: number) {
		if (newKey > this._nextKey) {
			this._nextKey = newKey;
		}
	}
}

export const dialogKeyState = new DialogKeyState();
