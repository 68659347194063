import { LoadableAfterPaint } from '@confluence/loadable';

export const TemplateEditorLoader = LoadableAfterPaint({
	loader: async () => {
		const { TemplateEditor } = await import(
			/* webpackChunkName: "loadable-TemplateEditor" */ './TemplateEditor'
		);
		return TemplateEditor;
	},
});
