import { LoadableAfterPaint } from '@confluence/loadable';

export { isProfileMacrosExperimentEnabled } from '../src/profileMacrosUtils';

export const ProfilePictureMacroHandler = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ProfilePictureMacro" */ '../src/profile-picture/ProfilePictureMacroRenderer'
			)
		).ProfilePictureMacroRenderer,
});

export const UserProfileMacroHandler = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-UserProfileMacro" */ '../src/user-profile/UserProfileMacroRenderer'
			)
		).UserProfileMacroRenderer,
});
