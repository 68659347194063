import {
	startOfDay,
	startOfWeek,
	startOfMonth,
	subDays,
	subWeeks,
	subMonths,
	differenceInCalendarDays,
	differenceInCalendarWeeks,
	differenceInCalendarMonths,
	max,
	min,
} from 'date-fns';
import ceil from 'lodash/ceil';
import { useMemo } from 'react';

import type { GranularityType } from '../../common/MetricSettingsContext';

import type { LineDefinition } from './ChartBase-types';

const MAX_X_TICK_COUNT = 6;
const MIN_X_DECREMENT_AMOUNT = 1;

// Given a provided max value for the y-axis, round it up.
// Depending on the number of digits, round to the nearest 10, 100, 1100, 1200, 11000, 12000, etc.
const roundMaxYValue = (maxYValue: number) => {
	const digitCount = `${ceil(maxYValue)}`.length;
	if (maxYValue < 10) {
		// Round up to 10. For example,
		// 1              -> 10
		// 0              -> 10
		return 10;
	} else if (maxYValue < 1000) {
		// Round up from the first digit. For example,
		// 101            -> 200
		// 11             -> 20
		return ceil(maxYValue, -1 * (digitCount - 1));
	} else {
		// Round up from the second digit. For example,
		// 10001          -> 11000
		// 1001           -> 1100
		return ceil(maxYValue, -1 * (digitCount - 2));
	}
};

const getXGranularityDomain = ({
	granularity,
	maxXValue,
	minXValue,
}: {
	granularity: GranularityType;
	maxXValue: Date;
	minXValue: Date;
}) => {
	switch (granularity) {
		case 'DAY':
			return differenceInCalendarDays(maxXValue, minXValue);
		case 'WEEK':
			return differenceInCalendarWeeks(maxXValue, minXValue);
		case 'MONTH':
		default:
			// The granularity should be MONTH.
			// However, if it is an unexpected value, then we default to MONTH.
			return differenceInCalendarMonths(maxXValue, minXValue);
	}
};

const decrementXValue = ({
	granularity,
	decrementAmount,
	currXValue,
}: {
	granularity: GranularityType;
	decrementAmount: number;
	currXValue: Date;
}) => {
	switch (granularity) {
		case 'DAY':
			return startOfDay(subDays(currXValue, decrementAmount));
		case 'WEEK':
			return startOfWeek(subWeeks(currXValue, decrementAmount), {
				weekStartsOn: 1,
			});
		case 'MONTH':
		default:
			// The granularity should be MONTH.
			// However, if it is an unexpected value, then we default to MONTH.
			return startOfMonth(subMonths(currXValue, decrementAmount));
	}
};

// This function is used to determine the tick values for the x-axis.
// The tick values are determined based on the given domain of the axis.
const getColumnTickValues = ({
	granularity,
	minXValue,
	maxXValue,
}: {
	granularity: GranularityType;
	minXValue: Date;
	maxXValue: Date;
}) => {
	const granularityDomain = getXGranularityDomain({
		granularity,
		maxXValue,
		minXValue,
	});

	// Decrements the max x value by one granularity unit to avoid showing the max x value as a tick. This is done to avoid showing
	// a tick label that does not match the tooltip label for in progress state metrics.
	let currXValue = decrementXValue({
		granularity,
		decrementAmount: 1,
		currXValue: maxXValue,
	});

	const columnTickValues: Date[] = [];
	const decrementAmount = Math.max(
		Math.floor((granularityDomain + 1) / MAX_X_TICK_COUNT),
		MIN_X_DECREMENT_AMOUNT,
	);

	// Decrements the x value, adding ticks each time, until the x value is less than or equal to the min x value.
	while (currXValue > minXValue) {
		columnTickValues.push(currXValue);
		currXValue = decrementXValue({
			granularity,
			decrementAmount,
			currXValue,
		});
	}
	// Reverses the array so that the ticks are in ascending order. The order does not affect the chart visually,
	// but it does affect the order the ticks are processed by the screen reader.
	columnTickValues.reverse();

	return columnTickValues;
};

type UseCustomGridDataArgs = {
	lines: LineDefinition[];
	granularity: GranularityType;
	// If isPercentageChart = true, then fix the y-axis ticks where rowTickValues = [0, 50, 100].
	isPercentageChart?: boolean;
};

type UseCustomGridDataReturnType = {
	xScaleDomain: Date[];
	yScaleDomain: number[];
	columnTickValues: Date[];
	rowTickValues: number[];
};

export const useCustomGridData = ({
	lines,
	granularity,
	isPercentageChart,
}: UseCustomGridDataArgs): UseCustomGridDataReturnType => {
	const { minXValue, maxXValue, minYValue, maxYValue } = useMemo(() => {
		const allDataPoints = lines.flatMap((currLine) => currLine.data);
		const allXValues = allDataPoints.map((currDataPoint) => currDataPoint.x.valueOf());
		// For y-axis scale, if chart is displaying percentages, then we fix the tick values to 0, 50, and 100.
		// Thus, if isPercentageChart = true, then maxYValue = 100.
		// Otherwise, for a counts chart, calculate the max y-value based on allDataPoints.
		let maxYValue = 100;
		if (!isPercentageChart) {
			const allYValues = allDataPoints.map((currDataPoint) => currDataPoint.y || 0);
			maxYValue = roundMaxYValue(Math.max(...allYValues));
		}

		return {
			minXValue: min(allXValues),
			maxXValue: max(allXValues),
			minYValue: 0,
			maxYValue,
		};
	}, [lines, isPercentageChart]);

	return {
		xScaleDomain: [minXValue, maxXValue],
		yScaleDomain: [minYValue, maxYValue],
		columnTickValues: getColumnTickValues({
			granularity,
			minXValue,
			maxXValue,
		}),
		rowTickValues: [minYValue, (minYValue + maxYValue) / 2, maxYValue],
	};
};
