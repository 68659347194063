import { fg } from '@atlaskit/platform-feature-flags';

import { getBscGlobalState } from '../../../common/utils/bsc-global-state';
import { loadStorageControlsData } from '../../../common/utils/load-storage-controls-data-legacy';
import {
	type CategorizedStorageControlsCache,
	type CategorizedStorageControlsCacheLegacy,
	CategoryStorageType,
	PreferenceCategory,
} from '../../../types';
import { getPreferences } from '../../preferences-controls/get-preferences';

export const isThirdPartyAllowed = async (thirdParty: string): Promise<boolean> => {
	/*
    If no preferences for whatever reason, deny
    If prefs exist and are default, they will default to block all
    If third party has no category for whatever reason or the data doesn't resolve, deny
    If third party has a category, check preferences and allow/deny
  */
	const preferences = await getPreferences();
	if (!preferences) {
		return Promise.resolve(false);
	}

	let shouldDenyThirdParty = false;

	let storageControlsData:
		| CategorizedStorageControlsCacheLegacy
		| CategorizedStorageControlsCache
		| undefined;

	if (fg('platform_moonjelly_bsc_new_caches')) {
		const { loadCache } = getBscGlobalState();

		storageControlsData = await loadCache(CategoryStorageType.Cookies);
	} else {
		storageControlsData = await loadStorageControlsData();
	}

	if (storageControlsData) {
		const { thirdPartyKeys, categories } = storageControlsData;

		const thirdPartyCategoryNumber = thirdPartyKeys[thirdParty];
		const numToCategoryMap = {
			[categories.STRICTLY_NECESSARY]: PreferenceCategory.StrictlyNecessary,
			[categories.FUNCTIONAL]: PreferenceCategory.Functional,
			[categories.ANALYTICS]: PreferenceCategory.Analytics,
			[categories.MARKETING]: PreferenceCategory.Marketing,
			[categories.UNKNOWN]: PreferenceCategory.Unknown,
		};

		const thirdPartyCategory =
			thirdPartyCategoryNumber !== null ? numToCategoryMap[thirdPartyCategoryNumber] : null;

		let hasPreferencesForCategoryType = false;

		if (!!thirdPartyCategory) {
			hasPreferencesForCategoryType = preferences[thirdPartyCategory];
		}
		shouldDenyThirdParty = hasPreferencesForCategoryType;
	}

	return Promise.resolve(shouldDenyThirdParty);
};
