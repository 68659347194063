import { query } from '@confluence/query-preloader-tools';

import { CreateSpaceQuery } from './CreateSpaceQuery.graphql';

type PreloadCreateSpaceOptions = {
	isLicensed: boolean;
};

export const preloadCreateSpace = ({
	isLicensed,
}: PreloadCreateSpaceOptions): Promise<{ data: unknown }> => {
	if (!isLicensed) {
		return Promise.resolve({ data: null });
	}

	return query({ query: CreateSpaceQuery });
};
