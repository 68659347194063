import React from 'react';
import { Loader } from '../../../web-runtime/loader';
import { type IframeProps } from '../types';

export const getLoadingComponent = (loadingComponent?: IframeProps['loadingComponent']) => {
	if (loadingComponent) {
		return typeof loadingComponent === 'function' ? loadingComponent() : loadingComponent;
	}

	return <Loader />;
};
