enum PageMode {
	EDIT = 'edit',
	VIEW = 'view',
	LIVE = 'live',
}

export const findUnresolvedCommentIndex = (
	unresolvedAnnotationId: string,
	unresolvedInlineComments: string[],
	pageMode: string,
): string[] => {
	const isFullPageEditor = pageMode === PageMode.LIVE || pageMode === PageMode.EDIT;

	let activeAnnotationMarks: Element[];

	if (isFullPageEditor) {
		// In the full-page editor mode, collect parent spans with unique IDs for annotation boundaries
		const parentSpansSet = new Set<Element>();
		const annotations = document.querySelectorAll(
			'.ak-editor-annotation-focus, .ak-editor-annotation-blur',
		);
		annotations.forEach((element) => {
			const parentSpan = element.closest<HTMLElement>('span[id]');
			if (parentSpan) {
				parentSpansSet.add(parentSpan);
			}
		});
		activeAnnotationMarks = Array.from(parentSpansSet);
	} else {
		// For renderer or view mode, collect marks with active annotation state
		activeAnnotationMarks = Array.from(
			document.querySelectorAll("mark[data-mark-annotation-state='active']"),
		);
	}

	// Attempt to find the index of the new unresolved annotation by matching its element
	const newlyUnresolvedAnnotation = document.getElementById(unresolvedAnnotationId);
	const allUnresolvedAnnotations = Array.from(activeAnnotationMarks);
	const matchedIndex = newlyUnresolvedAnnotation
		? allUnresolvedAnnotations.indexOf(newlyUnresolvedAnnotation)
		: -1;

	// Construct the new array with unresolved comments
	const newUnresolvedInlineComments = [...unresolvedInlineComments];
	if (matchedIndex !== -1) {
		newUnresolvedInlineComments.splice(matchedIndex, 0, unresolvedAnnotationId);
	} else {
		newUnresolvedInlineComments.push(unresolvedAnnotationId);
	}

	return newUnresolvedInlineComments;
};
