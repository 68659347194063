import { LoadableAfterPaint } from '@confluence/loadable';

export const FeedbackCollectorLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LazyFeedbackCollector" */ './FeedbackCollector'))
			.FeedbackCollector,
});

export enum FeedbackType {
	CARDS = 'Cards',
	CAROUSEL = 'Carousel',
	COMPANY_HUB = 'Company hub',
	CUSTOMIZE_NAVIGATION = 'Customize Navigation',
}
