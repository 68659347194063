import React from 'react';

import type { RouteMatch } from '@confluence/route';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { shouldShowMobileWeb } from '@confluence/mobile-detection';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';

import { useBooleanFeatureFlag } from '@confluence/session-data';

import SpaceBase from '../../SpaceBase';

const ArchivedPagesListContainer = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ArchivedPagesListContainer" */ '@confluence/archived-pages-list/entry-points/ArchivedPagesListContainer'
			)
		).ArchivedPagesListContainer,
});

export const SpaceArchivedPagesRoute = ({ params: { spaceKey } }: RouteMatch) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	return isSpaceAliasFFEnabled ? (
		<SpaceBase>
			<ArchivedPagesListContainer spaceKey={spaceKey} />
		</SpaceBase>
	) : (
		<ArchivedPagesListContainer spaceKey={spaceKey} />
	);
};

SpaceArchivedPagesRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }: RouteMatch) => {
	const isMobile = shouldShowMobileWeb();
	return {
		Screen: {
			screenEvent: {
				name: 'spaceArchivedPages',
				id: spaceKey,
			},
			pageState: {
				routeName: name,
				spaceKey,
			},
		},
		MainLayout: {
			navView: getIsNav4Enabled() ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
			spaceKey,
			enableNavigation: !isMobile,
		},
	};
};
