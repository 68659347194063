import { LoadablePaint, MustPreloadLoadableNames } from '@confluence/loadable';

export const ViewPageLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ViewPage" */ './ViewPage')).ViewPage,
	_mustPreloadWhenRenderingSPA: [MustPreloadLoadableNames.ViewPageRouteComponentLoader],
});

export const ViewPageLiteHeaderLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ViewPageLiteHeader" */ './ViewPageLiteHeader'))
			.ViewPageLiteHeader,
});

export const ViewPageLiteLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ViewPageLite" */ './ViewPageLite')).ViewPageLite,
});
