/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::52aef07ad105bde87dc43516177fac51>>
 * @codegenId #range
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen range
 * @codegenParams { "hasFieldProps": {"isRequired": false, "isInvalid": false, "onChange": false}, "props": { "defaultValue": true, "max": true, "min": true, "step": true, "testId": true, "onChange": true }}
 * @codegenDependency ../../../../../../../design-system/range/src/range.tsx <<SignedSource::15eea32f3992862e9188336e9c8f0f27>>
 * @codegenDependency ../../../../../../../design-system/range/src/styled.tsx <<SignedSource::349ffc130205ef1d3261a8d6ead5626b>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformRange from '@atlaskit/range';
import { adaptEventHandler } from "../../../utils";
import type { EventHandlerProps } from "../../types";

type PlatformRangeProps = React.ComponentProps<typeof PlatformRange>;

export type RangeProps = Pick<
  PlatformRangeProps,
  'defaultValue' | 'max' | 'min' | 'step' | 'testId' | 'onChange'
 | 'id' | 'isDisabled' | 'value' | 'aria-invalid' | 'aria-labelledby' | 'name'
> & Pick<EventHandlerProps, 'onBlur' | 'onFocus'>;

export const Range = (props: Parameters<RenderFn>[0]) => {
  const {
    defaultValue,
    max,
    min,
    step,
    testId,
    onChange,
    // Field props
    id,
    isDisabled,
    onBlur,
    onFocus,
    value,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as RangeProps;
  return (
    <PlatformRange
      defaultValue={defaultValue}
      max={max}
      min={min}
      step={step}
      testId={testId}
      onChange={onChange}
      // Field props
      id={id}
      isDisabled={isDisabled}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      value={value}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */