/* eslint-disable @atlassian/tangerine/import/no-restricted-paths -- Preventing pre-commit error from being thrown when importing NO_CONTENT from controllers folder*/
import urlTemplate from 'url-template';

import { EngagekitChoreographerPlugin, type ProductIds } from '@atlassian/ipm-choreographer';

import { type Message } from '../../common/types/message';
import { NO_CONTENT } from '../../controllers/coordination/utils/status-codes';
import { errorIfNotOk, pullResponseTextIfNotOk, stargateFetch } from '../fetch-utils';

export type MessagesResponse = { messages: Message[]; interval: number };

export const TARGETING_SCHEMA_VERSION = '2';

const inProductMessagesUrlTemplate = urlTemplate.parse(
	'/engage-targeting/api/v2/user/{cloudId}/in-product-messages{?product,schema,locale}',
);

export async function getMessages(
	cloudId: string,
	stargateUrl: string,
	product: string,
	locale: string,
): Promise<MessagesResponse> {
	return stargateFetch(
		`${stargateUrl}${inProductMessagesUrlTemplate.expand({
			cloudId,
			product,
			schema: TARGETING_SCHEMA_VERSION,
			locale,
		})}`,
	)
		.then(errorIfNotOk)
		.then((r: Response) =>
			r.json().then((messages) => ({
				messages,
				interval: Number.parseFloat(r.headers.get('POLL-INTERVAL') || '0'),
			})),
		);
}

function handleStartStopMessageResponse(response: Response): boolean {
	return response.status === NO_CONTENT;
}

const startMessageUrlTemplate = urlTemplate.parse(
	'/engage-targeting/api/v2/user/messages/{messageId}/start',
);

const startMessageWithVariationIdUrlTemplate = urlTemplate.parse(
	'/engage-targeting/api/v2/user/messages/{messageId}/start/{variationId}',
);

const stopMessageUrlTemplate = urlTemplate.parse(
	'/engage-targeting/api/v2/user/messages/{messageId}/stop',
);

export type StartMessageConfig = {
	cloudId: string;
	stargateUrl: string;
	messageId: string;
	variationId?: string;
	workspaceId?: string;
	orgId?: string;
};

export type StopMessageConfig = {
	cloudId: string;
	stargateUrl: string;
	messageId: string;
	workspaceId?: string;
	orgId?: string;
};

//  Adding these types because we are not able to import them properly from @atlassiansox/analytics-web-client
export enum envType {
	LOCAL = 'local',
	DEV = 'dev',
	STAGING = 'staging',
	PROD = 'prod',
}

export type Attributes = Record<string, any> & { sourceProductId?: ProductIds };

export type EventOverrides = {
	anonymousId?: string;
};

export type CoordinationOptions = {
	additionalData?: Attributes;
	enableChoreographer?: boolean;
	envType?: envType;
};
// Initialize the choreographer plugin on module load
// const choreographerPlugin = new EngagekitChoreographerPlugin();
let choreographerPlugin: EngagekitChoreographerPlugin;

export async function startMessage(
	startMessageConfig: StartMessageConfig,
	options?: CoordinationOptions,
): Promise<Awaited<ReturnType<typeof startMessageWithCoordination>>> {
	if (options?.enableChoreographer) {
		return startMessageWithChoreographer(startMessageConfig, options);
	}

	return startMessageWithCoordination(startMessageConfig);
}

export async function stopMessage(
	stopMessageConfig: StopMessageConfig,
	options?: CoordinationOptions,
): Promise<Awaited<ReturnType<typeof stopMessageWithCoordination>>> {
	if (options?.enableChoreographer) {
		return stopMessageWithChoreographer(stopMessageConfig, options);
	}

	return stopMessageWithCoordination(stopMessageConfig);
}

async function startMessageWithChoreographer(
	startMessageConfig: StartMessageConfig,
	options: CoordinationOptions,
): Promise<Awaited<ReturnType<typeof startMessageWithCoordination>>> {
	if (!choreographerPlugin) {
		choreographerPlugin = new EngagekitChoreographerPlugin(options.envType ?? envType.LOCAL);
	}

	return choreographerPlugin.startMessageWithPlugin(
		startMessageConfig.messageId,
		() => startMessageWithCoordination(startMessageConfig, true),
		options.additionalData,
	);
}

async function stopMessageWithChoreographer(
	stopMessageConfig: StopMessageConfig,
	options: CoordinationOptions,
): Promise<Awaited<ReturnType<typeof stopMessageWithCoordination>>> {
	if (!choreographerPlugin) {
		choreographerPlugin = new EngagekitChoreographerPlugin(options.envType ?? envType.LOCAL);
	}

	return choreographerPlugin.stopMessageWithPlugin(
		stopMessageConfig.messageId,
		() => stopMessageWithCoordination(stopMessageConfig, true),
		options.additionalData,
	);
}

async function startMessageWithCoordination(
	startMessageConfig: StartMessageConfig,
	choreographerEnabled = false,
): Promise<boolean> {
	const requestBody = {
		cloudId: startMessageConfig.cloudId,
		orgId: startMessageConfig.orgId,
		workspaceId: startMessageConfig.workspaceId,
		choreographerEnabled,
	};

	return stargateFetch(
		startMessageConfig.variationId
			? `${startMessageConfig.stargateUrl}${startMessageWithVariationIdUrlTemplate.expand({
					messageId: startMessageConfig.messageId,
					variationId: startMessageConfig.variationId,
				})}`
			: `${startMessageConfig.stargateUrl}${startMessageUrlTemplate.expand({
					messageId: startMessageConfig.messageId,
				})}`,
		{
			method: 'post',
			body: JSON.stringify(requestBody),
			headers: { 'Content-Type': 'application/json' },
		},
	)
		.then(pullResponseTextIfNotOk)
		.then(handleStartStopMessageResponse);
}

async function stopMessageWithCoordination(
	stopMessageConfig: StopMessageConfig,
	choreographerEnabled = false,
): Promise<boolean> {
	const requestBody = {
		cloudId: stopMessageConfig.cloudId,
		orgId: stopMessageConfig.orgId,
		workspaceId: stopMessageConfig.workspaceId,
		choreographerEnabled,
	};

	return stargateFetch(
		`${stopMessageConfig.stargateUrl}${stopMessageUrlTemplate.expand({
			messageId: stopMessageConfig.messageId,
		})}`,
		{
			method: 'post',
			body: JSON.stringify(requestBody),
			headers: { 'Content-Type': 'application/json' },
		},
	)
		.then(pullResponseTextIfNotOk)
		.then(handleStartStopMessageResponse);
}

const userResponseUrlTemplate = urlTemplate.parse(
	'/engage-targeting/api/v2/user/{cloudId}/messages/{messageId}/response',
);

export async function sendUserResponse(
	cloudId: string,
	stargateUrl: string,
	messageId: string,
	payload: any,
): Promise<void> {
	return stargateFetch(`${stargateUrl}${userResponseUrlTemplate.expand({ cloudId, messageId })}`, {
		method: 'post',
		body: JSON.stringify(payload),
		headers: { 'Content-Type': 'application/json' },
	})
		.then(pullResponseTextIfNotOk)
		.then(() => Promise.resolve());
}

const markSeenAndFatigueUrlTemplate = urlTemplate.parse(
	'/engage-targeting/api/v2/user/messages/{messageId}/seen',
);

export type SeenMessageConfig = {
	cloudId: string;
	stargateUrl: string;
	messageId: string;
	workspaceId?: string;
	orgId?: string;
};

export async function markAsSeenAndFatigued(seenMessageConfig: SeenMessageConfig): Promise<void> {
	const requestBody = {
		cloudId: seenMessageConfig.cloudId,
		orgId: seenMessageConfig.orgId,
		workspaceId: seenMessageConfig.workspaceId,
	};

	return stargateFetch(
		`${seenMessageConfig.stargateUrl}${markSeenAndFatigueUrlTemplate.expand({
			messageId: seenMessageConfig.messageId,
		})}`,
		{
			method: 'post',
			body: JSON.stringify(requestBody),
			headers: { 'Content-Type': 'application/json' },
		},
	)
		.then(pullResponseTextIfNotOk)
		.then(() => Promise.resolve());
}
