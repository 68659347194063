import React from 'react';

import { LoadablePaint } from '@confluence/loadable';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

const AdminAnnouncementBannerRenderer = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AdminAnnouncementBannerRenderer" */ '@confluence/admin-announcement-banner/entry-points/AdminAnnouncementBannerRenderer'
			)
		).AdminAnnouncementBannerRenderer,
});

export const AdminAnnouncementBannerRendererRoute = () => {
	return <AdminAnnouncementBannerRenderer />;
};

AdminAnnouncementBannerRendererRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'adminAnnouncementBannerRenderer' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
