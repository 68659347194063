import { query } from '@confluence/query-preloader-tools';

import { HomeWidgetsQuery } from './HomeWidgetsQuery.graphql';

export function preloadHomeWidgets({ isLicensed }: { isLicensed: boolean }) {
	if (!isLicensed) {
		return Promise.resolve();
	}

	return query({
		query: HomeWidgetsQuery,
	});
}
