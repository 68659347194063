import { LoadablePaint } from '@confluence/loadable';

export const ConnectSpaceCustomContentListView = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcConnectSpaceCustomContentListView" */ '../src/ConnectSpaceCustomContentListView'
			)
		).ConnectSpaceCustomContentListView,
});
