import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager';

export function RedirectToSpaceOverview({ params: { key, spaceKey }, query }: RouteMatch) {
	return <Redirection name={SPACE_OVERVIEW.name} params={{ spaceKey: key || spaceKey, query }} />;
}

RedirectToSpaceOverview.NAVIGATION_PARAMS = () => false;
