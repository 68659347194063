import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { type PropsWithChildren, useEffect } from 'react';

/**
 * List of Placements we do not want to track placement (rendered) for, due to cost reasons, until we have a better solution.
 */
const TEMPORARY_NO_RENDER_TRACK_PLACEMENTS: string[] = ['screen-space-flags'];

export const RenderTrackerProvider = ({
	children,
	placementId,
}: PropsWithChildren<{ placementId: string }>) => {
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();

	useEffect(() => {
		if (TEMPORARY_NO_RENDER_TRACK_PLACEMENTS.includes(placementId)) {
			return;
		}

		fireAnalyticsEvent({
			eventType: 'track',
			action: 'rendered',
			actionSubject: 'placement',
		});
	}, [fireAnalyticsEvent, placementId]);

	return <>{children}</>;
};
