import {
	type EventDataMap,
	type EventHubEventUnionType,
	type FromEditorEventDataMap,
	type SubscribeParams,
	type ToEditorEventDataMap,
	type Unsubscribe,
} from './types';

class EventHub {
	private subscribers: Map<EventHubEventUnionType, Array<(data: any) => void>> = new Map();

	constructor() {
		this.subscribers = new Map();
	}

	/**
	 * Subscribes to a specific Editor AI event, registering a callback function that will be invoked whenever the event is published.
	 *
	 * @param {Object} params - The subscription parameters.
	 * @param {T} params.event - The name of the event to subscribe to.
	 * @param {(data: EventDataMap[T]) => void} params.callback - The callback function to invoke when the event is published.
	 * It receives the event data as its parameter.
	 * @returns {Object} An object containing the `unsubscribe` method to remove the subscription when it's no longer needed.
	 *
	 * @example
	 * // Subscribing to an event
	 * const subscription = eventHub.subscribe({
	 *   event: 'start prompt',
	 *   callback: (data) => {
	 *     console.log('Received start prompt event:', data);
	 *   },
	 * });
	 *
	 * // Later, to unsubscribe
	 * subscription.unsubscribe();
	 */
	subscribe<T extends keyof EventDataMap>({
		event,
		callback,
	}: SubscribeParams<T>): { unsubscribe: Unsubscribe } {
		const callbacks = this.subscribers.get(event) || [];
		this.subscribers.set(event, [...callbacks, callback]);

		return {
			unsubscribe: () => {
				const callbacks = this.subscribers.get(event)?.filter((sub) => sub !== callback) || [];
				if (callbacks.length > 0) {
					this.subscribers.set(event, callbacks);
				} else {
					this.subscribers.delete(event);
				}
			},
		};
	}

	/**
	 * Publishes an Editor AI event to a target editor. (This will usually be an event like 'start prompt').
	 *
	 * @param {Object} params - The event parameters.
	 * @param {T} params.event - The name of the event to publish.
	 * @param {ToEditorEventDataMap[T]} params.data - The data associated with the event, specific to the event type.
	 * @returns {void}
	 *
	 * @example
	 * // Publishing an event to the editor
	 * eventHub.publishToEditor({
	 *   event: 'start prompt',
	 *   data: {
	 *     prompt: 'issue reformatter',
	 *     targetEditorId,
	 *     analyticSourceId: 'jiraIssueReformatterExternalButton',
	 *   },
	 * });
	 */
	publishToEditor<T extends keyof ToEditorEventDataMap>({
		event,
		data,
	}: {
		event: T;
		data: ToEditorEventDataMap[T];
	}): void {
		const callbacks = this.subscribers.get(event) || [];
		callbacks.forEach((callback) => {
			(callback as (data: ToEditorEventDataMap[T]) => void)(data);
		});
	}

	/**
	 * Publishes an event originating from the editor, invoking all registered subscribers for that event.
	 *
	 * @param {Object} params - The event parameters.
	 * @param {T} params.event - The name of the event to publish.
	 * @param {FromEditorEventDataMap[T]} params.data - The data associated with the event, specific to the event type.
	 * @returns {void}
	 *
	 * @example
	 * // Publishing an event from the editor
	 * eventHub.publishFromEditor({
	 *   event: 'replace title in confluence',
	 *   data: {
	 *     analyticSourceId: 'confluenceTitleToolbar',
	 *     sourceEditorId,
	 *     title: textContent,
	 *   },
	 * });
	 */
	publishFromEditor<T extends keyof FromEditorEventDataMap>({
		event,
		data,
	}: {
		event: T;
		data: FromEditorEventDataMap[T];
	}): void {
		const callbacks = this.subscribers.get(event) || [];
		callbacks.forEach((callback) => {
			(callback as (data: FromEditorEventDataMap[T]) => void)(data);
		});
	}
}

const eventHub = new EventHub();

export { eventHub };
