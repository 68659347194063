import gql from 'graphql-tag';

const contentFragment = gql`
	fragment contentFragment on ConfluenceLegacyContent {
		id
		type
		space {
			id
		}
		operations {
			operation
			targetType
		}
	}
`;

const inlineCommentFragment = gql`
	fragment inlineCommentFragment on ConfluenceLegacyComment {
		id
		body(representation: ATLAS_DOC_FORMAT) {
			value
		}
		contentStatus
		links {
			webui
			editui
		}
		location {
			... on ConfluenceLegacyInlineComment {
				type
				inlineMarkerRef
				inlineResolveProperties {
					resolvedTime
					resolvedUser
				}
			}
		}
		parentId
		permissions {
			isEditable
			isRemovable
			isResolvable
		}
		version {
			when
			number
		}
		author {
			displayName
			profilePicture {
				path
			}
			permissionType
			... on ConfluenceLegacyKnownUser {
				accountId
			}
			... on ConfluenceLegacyUser {
				accountId
			}
		}
	}
`;

export const InlineCommentsQueryAgg = gql`
	query InlineCommentsQuery($pageId: ID!) {
		confluenceLegacy_content(id: $pageId) @optIn(to: "ConfluenceLegacyRN") {
			nodes {
				...contentFragment
			}
		}
		confluenceLegacy_comments(pageId: $pageId, type: UNRESOLVED, depth: ROOT, first: 1000)
			@optIn(to: "ConfluenceLegacyRN") {
			nodes {
				...inlineCommentFragment
				replies {
					...inlineCommentFragment
				}
			}
		}
	}
	${contentFragment}
	${inlineCommentFragment}
`;
