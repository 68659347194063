import type { ComponentProps } from 'react';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';

import { ChangeHubNameDialogLoadable } from './ChangeHubNameDialogLoadable';

type ChangeHubNameDialogLoadableProps = ComponentProps<typeof ChangeHubNameDialogLoadable>;

export function useChangeHubNameDialog(
	props: Omit<ChangeHubNameDialogLoadableProps, keyof DialogsContainerProps> = {},
) {
	const { showModal } = useDialogs();

	return () => showModal(ChangeHubNameDialogLoadable, props);
}
