import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ForgeGlobalSettingsExtensionPoint } from '@confluence/forge-ui/entry-points/GlobalSettings';

export const AdminForgeAppsRoute = ({ params: { id } }) => (
	<ForgeGlobalSettingsExtensionPoint key={id} id={id} />
);

AdminForgeAppsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'adminForgeApps',
		},
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
