import { getOrigin } from './getOrigin';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Filters out all parameters that may be used for malicious purposes such as attaching local files or adding bcc recipients
// Only subject and body are allowed
const filterMailtoParams = (mailtoLink: string) => {
	try {
		const url = new URL(mailtoLink);
		const params = url.searchParams;
		const email = url.pathname;
		let newParams = '';

		if (!emailRegex.test(email)) {
			// throw empty error for makeUrl to catch
			throw new Error();
		}

		if (params.has('subject')) {
			newParams += 'subject=' + encodeURIComponent(params.get('subject') || '');
		}

		if (params.has('body')) {
			newParams += newParams ? '&body=' : 'body=';
			newParams += encodeURIComponent(params.get('body') || '');
		}

		return `${url.protocol}${email}` + (newParams ? '?' + newParams : '');
	} catch (error) {
		// throw empty error for makeUrl to catch
		throw new Error();
	}
};

export function makeUrl(urlString: string): URL | undefined {
	try {
		if (urlString.startsWith('mailto:')) {
			return new URL(filterMailtoParams(urlString));
		}

		// We decode the URL string to handle cases where a malicious app might pass in a URL with encoded characters to bypass our egress checks
		const decodedUrlString = decodeURIComponent(urlString);
		const fullUrl = decodedUrlString.startsWith('/') ? `${getOrigin()}${urlString}` : urlString;

		// Checks if `fullUrl` has a protocol(eg. http://, https://, etc), if not, add the `https://` protocol
		const protocolRegex = /^(.*?:\/\/)/;
		const urlWithProtocol = protocolRegex.test(fullUrl) ? fullUrl : `https://${fullUrl}`;

		// We use the URL constructor to validate the URL
		const url = new URL(urlWithProtocol);

		return url;
	} catch {
		return undefined;
	}
}
