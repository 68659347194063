import type { CreateUIAnalyticsEvent } from "@atlaskit/analytics-next";

enum Styles {
  None = "none",
  Mixed = "mixed",
  Bullet = "bullet",
  Circle = "circle",
  Square = "square",
  Number = "number",
}

enum Separators {
  Brackets = "brackets",
  Braces = "braces",
  Pipe = "pipe"
}

interface StyleMacroParam {
  value: Styles.None | Styles.Mixed | Styles.Bullet | Styles.Circle | Styles.Square | Styles.Number
}

interface SeparatorMacroParam {
  value: Separators.Brackets | Separators.Braces | Separators.Pipe
}

interface MacroParam {
  value: string
}

interface TocMacroParam {
  minLevel: MacroParam;
  maxLevel: MacroParam;
  outline: MacroParam;
  type: MacroParam;
  printable: MacroParam;
  style: StyleMacroParam;
  separator: SeparatorMacroParam;
  class: MacroParam;
  include: MacroParam;
  exclude: MacroParam;
  indent: MacroParam;
}

export const sendTrackingEvent = (
  macroName: string,
  trackingData: TocMacroParam,
  createAnalyticsEvent: CreateUIAnalyticsEvent,
  contentId: string
) => {
  const {
    minLevel,
    maxLevel,
    outline,
    type,
    printable,
    style,
    separator,
    include,
    exclude,
    indent,
  } = trackingData

  const sensitiveData = {
    include: include?.value.length > 0 || false,
    exclude: exclude?.value.length > 0 || false,
    class: trackingData.class?.value.length > 0 || false,
    indent: indent?.value.length > 0 || false,
  }

  // in the unlikely event these dropdown fields become custom input text, ensure no PII is logged
  const separatorValue = Object.values<string>(Separators).includes(separator?.value) ? separator?.value : 'invalid'
  const styleValue = Object.values<string>(Styles).includes(style?.value) ? style?.value : 'invalid'

  const potentiallySensitiveData = {}
  if (type) {
    if (type.value === 'flat') {
      potentiallySensitiveData['separator'] = separatorValue
    } else if (type.value === 'list') {
      potentiallySensitiveData['style'] = styleValue
    }
  } else {
    potentiallySensitiveData['style'] = 'mixed'
  }

  const nonSensitiveData = {
    minLevel: parseInt(minLevel?.value) || 1,
    maxLevel: parseInt(maxLevel?.value) || 6,
    outline: (outline?.value === 'true') || false,
    printable: (printable?.value === 'false') || true,
    type: type?.value === "flat" ? "flat" : "list",
  }

  createAnalyticsEvent({
    type: "sendTrackEvent",
    data: {
      action: "viewed",
      actionSubject: "macro",
      actionSubjectId: macroName,
      source: "on page view",
      attributes: {
        pageID: contentId,
        ...sensitiveData,
        ...potentiallySensitiveData,
        ...nonSensitiveData,
      },
      eventType: "track",
    },
  }).fire();
};
