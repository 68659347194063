import type { ReactNode } from 'react';
import React from 'react';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';

// Same idea as RendererExtensionContext inside @confluence/content-renderer
// This context is used for the route component to pass some classic dependencies into
// the next components
export type ClassicEditorContextType = {
	// Functions to use the legacy macro browser
	openMacroBrowserForInitialConfiguration?: Function;
	openMacroBrowserToEditExistingMacro?: Function;
	loadMacroBrowserResources?: Function;
	// Redux functions to allow opening and closing modals (for opening and closing macro browser)
	setActiveModal?: (component: ReactNode, props: any) => void;
	closeActiveModal?: () => void;
	// Migration extension related
	getMigrationExtensionManifest?: Function;
	// Renderer related things for preview in editor
	getExtensionHandlers?: (options: any) => ExtensionHandlers;
	populateExtensionProviders?: (dataProviders: ProviderFactory, options: any) => void;
	Changeboarding?: (...props: any) => JSX.Element;
	isWithinEditor?: boolean;
};

export const ClassicEditorContext = React.createContext<ClassicEditorContextType>({});
