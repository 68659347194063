import type { NestedFieldDefinition } from '@atlaskit/editor-common/extensions';

export const cqlFieldMap = {
	label: {
		type: 'custom',
		label: 'Label',
		isMultiple: true,
		allowDuplicates: true,
		isRequired: true,
		options: {
			resolver: {
				type: 'label',
			},
		},
	},

	text: {
		type: 'string',
		op: '~',
		label: 'Including text',
	},

	contributor: {
		key: 'contributor',
		type: 'custom',
		label: 'Contributor',
		isMultiple: true,
		options: {
			resolver: {
				type: 'username',
			},
		},
	},

	creator: {
		key: 'creator',
		type: 'custom',
		label: 'Creator',
		isMultiple: true,
		options: {
			resolver: {
				type: 'username',
			},
		},
	},

	mention: {
		key: 'mention',
		type: 'custom',
		label: 'Mentioning',
		isMultiple: true,
		options: {
			resolver: {
				type: 'username',
			},
		},
	},

	parent: {
		type: 'custom',
		label: 'With parent',
		options: {
			resolver: {
				type: 'confluence-content-by-id',
			},
		},
	},

	ancestor: {
		type: 'custom',
		label: 'With ancestor',
		isMultiple: true,
		options: {
			resolver: {
				type: 'confluence-content-by-id',
			},
		},
	},

	space: {
		type: 'custom',
		label: 'In space',
		isMultiple: true,
		options: {
			resolver: {
				type: 'spacekey',
			},
		},
	},

	// TODO: https://product-fabric.atlassian.net/browse/ED-10532
	created: {
		type: 'string',
		op: '>=',
		label: 'Created after',
		description: 'Enter dates as YYYY-MM-DD, or use CQL functions like `now(-1d)` for yesterday.',
	},

	lastmodified: {
		type: 'string',
		op: '<=',
		label: 'Last modified',
		description: 'Enter dates as YYYY-MM-DD, or use CQL functions like `now(-1d)` for yesterday.',
	},

	title: {
		type: 'string',
		op: '~',
		label: 'With title',
	},

	type: {
		type: 'enum',
		label: 'Of type',
		isMultiple: false,
		style: 'select',
		items: [
			{ value: 'page', label: 'Page' },
			{ value: 'blogpost', label: 'Blog' },
			{ value: 'comment', label: 'Comment' },
			{ value: 'attachment', label: 'Attachment' },
		],
	},
};

// zip it into an array, adding the name of each entry with it
export const getCQLFields = (): NestedFieldDefinition[] =>
	Object.keys(cqlFieldMap).map((name) => ({
		name,
		...cqlFieldMap[name],
	}));
