import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import { Inline, Pressable, Text, xcss } from '@atlaskit/primitives';

import {
	FeedbackType,
	useFeedbackCollector,
} from '@confluence/company-hub-utils/entry-points/FeedbackCollectorLoader';

const i18n = defineMessages({
	label: {
		id: 'company-hub.hub-settings.general.share-feedback-button.label',
		defaultMessage: 'Give feedback on Company hub',
		description:
			'Label/text of the button that triggers a modal dialog to collect and submit the feedback of the user on the Company Hub feature',
	},
});

const pressableStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	borderRadius: '4px',
	paddingBlock: 'space.150',
	paddingInline: 'space.200',
});

export function GeneralPageShareFeedbackButton() {
	const openFeedbackCollector = useFeedbackCollector({
		source: 'generalHubSettings',
		type: FeedbackType.COMPANY_HUB,
	});

	return (
		<Pressable onClick={openFeedbackCollector} xcss={pressableStyles}>
			<Inline alignBlock="center" space="space.100">
				<MegaphoneIcon label="" spacing="spacious" />
				<FormattedMessage {...i18n.label} tagName={Text} />
			</Inline>
		</Pressable>
	);
}
