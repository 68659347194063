import { combineReducers } from 'redux';
import { fromJS } from 'immutable';
import {
	EDITOR_PRELOAD_START,
	EDITOR_PRELOAD_FINISH,
} from '@confluence-classic/confluence-fabric-editor/src/actions/legacy-editor-action-types';

import { labels, labelDialog } from '../../components/Labels/reducer';
import { editor } from '../../components/Comments/reducer';

import editorContent from './viewPage/editorContent';

const defaultState = fromJS({
	contentId: null,
	hasLoaded: false,
});

export function quickEditPreload(state = defaultState, action) {
	switch (action.type) {
		case EDITOR_PRELOAD_START:
			if (state.get('contentId') === action.contentId) {
				return state;
			}
			return state.merge({
				hasLoaded: false,
				contentId: action.contentId,
			});

		case EDITOR_PRELOAD_FINISH:
			if (state.get('contentId') !== action.contentId) {
				return state;
			}
			return state.merge({
				hasLoaded: true,
			});

		default:
			return state;
	}
}

export default combineReducers({
	editorContent,
	labels,
	labelDialog,
	editor,
	quickEditPreload,
});
