import { type DocumentNode } from 'graphql';

export default function getOperationName(gqlQueryNode: DocumentNode | null | undefined) {
	const operationDef = gqlQueryNode?.definitions?.find(
		(d) => d.kind === 'OperationDefinition' && d.name,
	);
	const operationName =
		(operationDef?.kind === 'OperationDefinition' ? operationDef?.name?.value : undefined) ??
		'unnamed';
	return operationName;
}
