import { LoadablePaint } from '@confluence/loadable';

export const LazySpaceReportsSiteSettings = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceReports" */ '../src/SpaceReports/SpaceReports'
			)
		).SpaceReports,
});

export { ADMIN_SPACE_REPORT_SCREEN } from '../src/SpaceReports/analytics';
