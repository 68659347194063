import type { ADNode } from '@atlaskit/editor-common/validator';
import type { MacroAttributes } from '@atlaskit/editor-common/provider-factory';

import type { MacroMetadata } from '../../extensions-common/types';
import { fetchMacroAttributes } from '../fetchMacroAttributes';
import { wrapMacroParams } from '../transformers';
/*
This script adds autoconvert handlers to the confluence editor.

It executes on editor initialisation, registering the handlers defined in the original add-on descriptors of whatever
add-ons are installed. Each one is associated with a particular macro (although a macro can be associated with many
handlers.

On pattern match, the each handler will be asked if they match and will be processed by the first matching handler.
The macro object is then created, using the matched uri and the information already existing in the JS context, and
the macro is inserted into the editor.
*/

const TRELLO_MACRO_NAME = 'trello-card';

type AutoconvertDef = {
	macroADFNode?: ADNode;
	macroName: string;
	matcher: string;
	urlParameter: string;
};

const escapePattern = (str: string): string => {
	return str.replace(/[\-\[\]\/\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
};

const replaceAll = (find: string, replace: string, str: string): string => {
	return str.replace(new RegExp(find, 'g'), replace);
};

const convertPatternToRegex = (pattern: string): string => {
	// Consolidate any double up wildcards
	while (pattern.indexOf('{}{}') !== -1) {
		pattern = pattern.replace('{}{}', '{}');
	}

	// build a regex from the defined autoconvert pattern
	pattern = escapePattern(pattern);
	pattern = replaceAll('{}', '[^/]*?', pattern);
	// eslint-disable-next-line prefer-template
	pattern = '^' + pattern + '$';

	return pattern;
};

const isPatternSafe = (pattern: string): boolean => {
	const patternBlockList: string[] = [
		'http://',
		'https://',
		'http://{}',
		'https://{}',
		'http://{}.{}',
		'https://{}.{}',
		'http://{}.{}.{}',
		'https://{}.{}.{}',
	];

	// check the url pattern is not banned
	return patternBlockList.every((bannedPattern) => bannedPattern !== pattern);
};

const hasAutoconvertData = (macro) => {
	return (
		macro.autoconvertData && macro.autoconvertData.urlParameter && macro.autoconvertData.patterns
	);
};

type MacroAttributesMap = {
	[macroName: string]: MacroAttributes;
};

const getADFNodeForMacros = async (
	macros: Set<string>,
	contentId?: string,
): Promise<MacroAttributesMap> => {
	const macroAttributesArray = await Promise.all(
		Array.from(macros).map((macroName) =>
			fetchMacroAttributes({
				macroDefinition: {
					name: macroName,
				},
				contentId,
			}),
		),
	);

	return macroAttributesArray.reduce((accu, macroAttributes) => {
		accu[macroAttributes.attrs.extensionKey] = macroAttributes;
		return accu;
	}, {});
};

const convertLink = (
	link: string,
	autoconvertDefs: AutoconvertDef[],
	macroAttributesMap: MacroAttributesMap,
): null | MacroAttributes => {
	const matchedAcd: undefined | AutoconvertDef = autoconvertDefs.find((acd) => {
		return !!link.match(acd.matcher);
	});
	if (matchedAcd) {
		const { macroName, urlParameter } = matchedAcd;
		const params = {};
		params[urlParameter] = link;
		const macroAttributes = macroAttributesMap[macroName];

		const convertedMacroAttributes: MacroAttributes = JSON.parse(JSON.stringify(macroAttributes));
		convertedMacroAttributes.attrs.text = `${urlParameter} = ${link}`;
		convertedMacroAttributes.attrs.extensionKey = macroName;
		if (!convertedMacroAttributes.attrs.parameters) {
			convertedMacroAttributes.attrs.parameters = {};
		}
		convertedMacroAttributes.attrs.parameters.macroParams = wrapMacroParams(params);

		return convertedMacroAttributes;
	} else {
		return null;
	}
};

export async function createConnectAddonConverter(
	loadMacroMetadata: () => Promise<MacroMetadata[]>,
	contentId?: string,
): Promise<(link: string) => MacroAttributes | null> {
	const autoconvertDefs: AutoconvertDef[] = [];

	const macros = await loadMacroMetadata();
	// this object contains name of macros which have correct autoconvertData
	const filteredMacros: Set<string> = new Set();

	macros.forEach((macro) => {
		if (
			// Don't convert any new Trello links to Trello macro due to new Trello smart links
			!hasAutoconvertData(macro) ||
			macro.macroName === TRELLO_MACRO_NAME
		) {
			return;
		}
		// only macro with autoconvertData can be converted
		macro.autoconvertData.patterns.forEach((pattern) => {
			if (isPatternSafe(pattern)) {
				const acd: AutoconvertDef = {
					macroName: macro.macroName,
					urlParameter: macro.autoconvertData.urlParameter,
					matcher: convertPatternToRegex(pattern),
				};
				filteredMacros.add(acd.macroName);
				autoconvertDefs.push(acd);
			}
		});
	});
	const macroAttributes = await getADFNodeForMacros(filteredMacros, contentId);

	return (link: string) => convertLink(link, autoconvertDefs, macroAttributes);
}
