type ValidationFailedEvent = {
	isViewPage: boolean;
	extensionKey: string;
	error: Error;
};

export const getValidationFailedEvent = ({
	isViewPage,
	extensionKey,
	error,
}: ValidationFailedEvent) => {
	return {
		type: 'sendTrackEvent',
		data: {
			containerType: 'page',
			objectType: 'connectAddon',
			source: isViewPage ? 'viewPageScreen' : 'editPageScreen',
			action: 'failed',
			actionSubject: 'connectRefEventDataValidation',
			attributes: {
				extensionKey,
				message: error.message,
			},
		},
	};
};

export const getReceivedConnectRefDataEvent = ({ isViewPage, extensionKey }) => {
	return {
		type: 'sendTrackEvent',
		data: {
			containerType: 'page',
			objectType: 'connectAddon',
			source: isViewPage ? 'viewPageScreen' : 'editPageScreen',
			action: 'received',
			actionSubject: 'connectRefEventData',
			attributes: {
				extensionKey,
			},
		},
	};
};
