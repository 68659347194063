function updateMetaTag(param: string, value: string) {
	let el: HTMLMetaElement | null = window.document.querySelector(`[name="ajs-${param}"]`);

	if (typeof value === 'undefined') {
		if (el) {
			window.document.head.removeChild(el);
		}
		return;
	}

	if (!el) {
		el = window.document.createElement('meta');
		el.name = `ajs-${param}`;
		window.document.head.appendChild(el);
	}

	el.content = value;
}

export function overrideAJSMeta() {
	// Only proxy window.AJS.Meta if it's already defined.
	if (
		window.AJS !== undefined &&
		window.AJS.Meta !== undefined &&
		window.AJS.Meta.set !== undefined
	) {
		const origMeta = window.AJS.Meta;
		const origSet = window.AJS.Meta.set;

		function proxiedSet(...args: [key: string, value: any]) {
			const param = args[0];
			const value = args[1];

			updateMetaTag(param, value);

			return origSet.apply(origMeta, args);
		}

		window.AJS.Meta.set = proxiedSet;
	}
}
