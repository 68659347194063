import React from 'react';

export const SSRInlineCommentsInit = ({}) => {
	// If there are no comments to SSR don't run scripts
	if (
		!window.__SSR_INLINE_COMMENT_MARKS__ ||
		(window.__SSR_INLINE_COMMENT_MARKS__ && window.__SSR_INLINE_COMMENT_MARKS__.length < 1)
	) {
		return null;
	}

	return (
		<div
			id="inline-comments-script"
			data-testid="inline-comments-script"
			data-marks={window.__SSR_INLINE_COMMENT_MARKS__}
			data-focused={window.__SSR_FOCUSED_INLINE_COMMENTS__}
		/>
	);
};
