import memoize from 'memoize-one';

export type Category = {
	id: string | null;
	name: string | null;
};

export type CountedCategory = {
	id: string;
	name: string;
	count: number;
};

export const FEATURED_CATEGORY_ID = 'template.category.featured';
export const MY_TEMPLATES_CATEGORY_ID = 'template.category.my-templates';
export const STARRED_CATEGORY_ID = 'template.category.starred';

type CategoryUtilsTemplate = {
	categoryIds: (string | null)[];
};
export const getCountedCategories = memoize(
	(
		templates: CategoryUtilsTemplate[],
		categories: (Category | null)[],
		mandatoryCategoryIdToShow = '',
	): CountedCategory[] => {
		const categoryIdToCountMap = {};
		for (const template of templates) {
			for (const categoryId of template.categoryIds) {
				if (!categoryId) {
					continue;
				}
				if (!categoryIdToCountMap[categoryId]) {
					categoryIdToCountMap[categoryId] = 0;
				}
				categoryIdToCountMap[categoryId]++;
			}
		}

		const countedCategories: CountedCategory[] = [];
		for (const category of categories) {
			if (!category?.id || !category?.name) {
				continue;
			}
			const count = categoryIdToCountMap[category.id];
			const shouldForceShowCategory = category.id === mandatoryCategoryIdToShow;

			if (count || shouldForceShowCategory) {
				countedCategories.push({
					id: category.id,
					name: category.name,
					count,
				});
			}
		}

		return countedCategories;
	},
);

export const isCategoryIdInCategories = (categoryId: string, categories: object): boolean => {
	return Object.values(categories).some((existingCategory) => existingCategory.id === categoryId);
};
