import { LoadablePaint } from '@confluence/loadable';

export const SiteHomepageRedirectLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SiteHomepageRedirect" */ './SiteHomepageRedirect'))
			.SiteHomepageRedirect,
});

export const GoToHomeLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GoToHome" */ './GoToHome')).GoToHome,
});
