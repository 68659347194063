import { query } from '@confluence/query-preloader-tools';

import { MediaDownloadTokenQuery } from './MediaDownloadTokenQuery.graphql';
import type {
	MediaDownloadTokenQuery as MediaDownloadTokenQueryType,
	MediaDownloadTokenQueryVariables as MediaDownloadTokenQueryVariablesType,
} from './__types__/MediaDownloadTokenQuery';

export const preloadMediaToken = async (contentId: string) => {
	const response = await query<MediaDownloadTokenQueryType, MediaDownloadTokenQueryVariablesType>({
		query: MediaDownloadTokenQuery,
		variables: {
			contentId,
			status: ['current', 'draft', 'archived'],
		},
	});

	if (process.env.REACT_SSR) {
		const { mediaConfigRecorder } = await import(
			/* webpackChunkName: "loadable-confluencessr-utilities" */ '@confluence/ssr-utilities'
		);
		mediaConfigRecorder.set({
			clientId: response?.data?.mediaConfiguration?.clientId || '',
			baseUrl: response?.data?.mediaConfiguration?.fileStoreUrl || '',
			token: response?.data?.content?.nodes?.[0]?.body?.atlas_doc_format?.mediaToken?.token || '',
		});
	}

	return response;
};
