import type { ComponentType } from 'react';
import React from 'react';

import type { NavigationPolicyType } from './useNavigationPolicy';
import { useNavigationPolicy } from './useNavigationPolicy';

export type WithNavigationPolicyProps = {
	navigationPolicy: NavigationPolicyType;
};
/**
 * XXX For function components, please use `useNavigationPolicy()` instead!
 *
 * XXX If you're chaining `withNavigationPolicy` with other higher-order components
 * and you're encoutering TypeScript errors:
 * 1. If you're wrapping a function component, you should rather be using
 *    `useNavigationPolicy()` instead!
 * 2. Or be explicit and tell `withNavigationPolicy` the type `P`.
 * 3. Or be explicit and tell other HOCs the types that they're inferring.
 *
 * Implements a higher-order component which provides `navigationPolicy` to a
 * specific `InnerComponent` as props.
 */
export function withNavigationPolicy<P>(
	InnerComponent: ComponentType<P & WithNavigationPolicyProps>,
) {
	function WithNavigationPolicy(props: P & Partial<Omit<P, keyof WithNavigationPolicyProps>>) {
		const navigationPolicy = useNavigationPolicy();
		return <InnerComponent {...(props as P)} navigationPolicy={navigationPolicy} />;
	}

	WithNavigationPolicy.displayName = `withNavigationPolicy(${
		InnerComponent.displayName || InnerComponent.name
	})`;

	return WithNavigationPolicy;
}
