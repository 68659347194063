import type { ChangeableValues, CreateDialogContextType } from './create-dialog-context-types';

export const getDefaultChangeableValues: ChangeableValues = {
	contentId: '',
	spaceKey: '',
	parentPageId: '',
	templateId: '',
	title: '',
	blueprint: {
		contentBlueprintId: '',
		itemModuleCompleteKey: '',
	},
	showTemplateVariableInputDrawer: false,
	showBlueprintVariableWizard: false,
	onContentUpdated: () => {},
	onError: () => {},
};

export const getDefaultContextState = (): CreateDialogContextType => {
	return {
		...getDefaultChangeableValues,
		onClose: () => {},
		openTemplateVariables: ({}) => {},
		openBlueprintWizard: ({}) => {},
	};
};
