import gql from 'graphql-tag';

export const WatchDialogContentFragment = gql`
	fragment WatchDialogContentFragment on Content {
		id
		currentUserIsWatching
		type
		space {
			id
			key
			currentUser {
				isWatched
				isWatchingBlogs
			}
			operations {
				operation
				targetType
			}
		}
	}
`;
