import type { ApolloError } from 'apollo-client';
import React from 'react';
import type { FC } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { ErrorDisplay } from '@confluence/error-boundary';
import { FlagsStateContainer } from '@confluence/flags';

export const i18n = defineMessages({
	followMutationErrorTitle: {
		id: 'profile.card.feed.follow.mutation.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble doing that",
		description:
			'Title of error flag shown when a user attempts to follow/unfollow a user but the action fails.',
	},
	followMutationErrorDescription: {
		id: 'profile.card.feed.follow.mutation.error.description',
		defaultMessage: 'Try following or unfollowing this user again ',
		description:
			'Body of error flag shown when a user attempts to unfollow/follow a user but the action fails. Shown below the error title',
	},
});

type FeedFollowErrorDisplayProps = {
	feedConfigError: ApolloError | undefined;
	mutationError: ApolloError | undefined;
	showErrorFlag?: boolean;
};

export const FeedFollowErrorDisplay: FC<FeedFollowErrorDisplayProps> = ({
	feedConfigError,
	mutationError,
	showErrorFlag,
}) => (
	<>
		{feedConfigError ? <ErrorDisplay error={feedConfigError} /> : null}
		{mutationError ? (
			<ErrorDisplay error={mutationError}>
				<Subscribe to={[FlagsStateContainer]}>
					{(flags: FlagsStateContainer) => {
						if (showErrorFlag) {
							void flags.showErrorFlag({
								title: <FormattedMessage {...i18n.followMutationErrorTitle} />,
								description: <FormattedMessage {...i18n.followMutationErrorDescription} />,
							});
						}
						return null;
					}}
				</Subscribe>
			</ErrorDisplay>
		) : null}
	</>
);
