import memoize from 'memoize-one';

import { getLogger } from '@confluence/logger';

export class TabInactivityTracker {
	/**
	 * Stores intervals in reverse order (that is, latest interval will be the first item in the array).
	 * The items in this array are tuples of [intervalStartTimestamp, intervalEndTimestamp].
	 * If `intervalEndTimestamp` is `null`, that means that tab is still inactive.
	 * Example value (number would actually be timestamps, but they are exemplified differently for ease of understanding):
	 * ```js
	 * // if the tab is currently active, but was hidden before
	 * [ [500, 600], [300, 400], [100, 200] ]
	 *
	 * // while the tab is still inactive
	 * [ [500, null], [100, 200] ]
	 *
	 * // if the tab has never been hidden
	 * [ ]
	 * ```
	 */
	private inactivityIntervals: Array<[number, number | null]> = [];
	private logger = getLogger('TabInactivityTracker');

	// in order to not increase memory footprint indefinitely, we'll cap the maximum amount of items
	private readonly maxIntervalsToStore: number;

	constructor({ maxIntervalsToStore }: { maxIntervalsToStore?: number } = {}) {
		const DEFAULT_MAX_INTERVALS_TO_STORE = 100;
		this.maxIntervalsToStore =
			typeof maxIntervalsToStore === 'number'
				? maxIntervalsToStore
				: DEFAULT_MAX_INTERVALS_TO_STORE;
	}

	initialize(): { teardown(): void } {
		if (typeof document === 'undefined') {
			return {
				teardown: () => {},
			};
		}

		if (document.hidden) {
			// if the tab is active, `onVisibilityChange` will result in invariant violation
			this.onVisibilityChange();
		}
		document.addEventListener('visibilitychange', this.onVisibilityChange);

		return {
			teardown: () => {
				document.removeEventListener('visibilitychange', this.onVisibilityChange);
			},
		};
	}

	getInactiveMillisecondsSince(timestamp: number): number {
		let inactivityDuration = 0;

		// for-loop will allow us to perform an early exit
		for (const [start, end] of this.inactivityIntervals) {
			// cases:
			// - timestamp < start     < end        : Relevant
			// - start     < timestamp < end        : Relevant
			// - start     < timestamp ; end=null   : Relevant
			// - start     < end       < timestamp  : Irrelevant
			const inactivityEnd = end || window.performance.now();
			if (timestamp > inactivityEnd) {
				break;
			}

			inactivityDuration += inactivityEnd - Math.max(start, timestamp);
		}

		return inactivityDuration;
	}

	private onVisibilityChange = () => {
		if (!document.hidden) {
			// we were inactive before, which means that there is an "unfinished" interval
			// we'll finish it after verifying invariants
			if (this.inactivityIntervals.length === 0 || this.inactivityIntervals[0][1] != null) {
				this.logger
					.error`Invariant violation: tab is currently active, but inactivity intervals are in inconsistent state`;
			} else {
				this.inactivityIntervals[0][1] = window.performance.now();
			}
		} else {
			if (this.inactivityIntervals.length >= this.maxIntervalsToStore) {
				// we're about to add a new interval; but we're also at max array capacity, so we'll
				// intentionally lose the most dated items in the array in the hope that they won't be needed anymore.
				this.inactivityIntervals.splice(this.maxIntervalsToStore - 1);
			}

			// tab just became inactive; we'll create a new "unfinished" interval
			this.inactivityIntervals.unshift([window.performance.now(), null]);
		}
	};
}

export const getTabInactivityTracker = memoize(() => {
	const tracker = new TabInactivityTracker();
	tracker.initialize();

	return tracker;
});
