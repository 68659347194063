import { query } from '@confluence/query-preloader-tools';
import { preloadCreateSpace } from '@confluence/create-space/entry-points/preloadCreateSpace';

import {
	StarredSpacesQuery,
	MyVisitedSpacesQuery,
	MyVisitedSpacesWithCurrentSpaceQuery,
	AnonymousSpacesQuery,
} from './SpacesListQueries.graphql';

type preloadSpacesListOptions = {
	isLicensed: boolean;
	isSpaceAliasFFEnabled?: boolean;
	selectedSpaceKey?: string;
};

export const preloadSpacesList = ({
	isSpaceAliasFFEnabled = false,
	isLicensed,
	selectedSpaceKey,
}: preloadSpacesListOptions): Promise<{ data: unknown }> => {
	const preloadQueries: Promise<{ data: unknown }>[] = [];

	if (isLicensed) {
		preloadQueries.push(
			query({
				query: StarredSpacesQuery,
				variables: { includeAlias: isSpaceAliasFFEnabled },
			}),
		);
		if (selectedSpaceKey) {
			preloadQueries.push(
				query({
					query: MyVisitedSpacesWithCurrentSpaceQuery,
					variables: { selectedSpaceKey, includeAlias: isSpaceAliasFFEnabled },
				}),
			);
		} else {
			preloadQueries.push(
				query({
					query: MyVisitedSpacesQuery,
					variables: { includeAlias: isSpaceAliasFFEnabled },
				}),
			);
		}
	} else {
		preloadQueries.push(
			query({
				query: AnonymousSpacesQuery,
				variables: { includeAlias: isSpaceAliasFFEnabled },
			}),
		);
	}

	preloadQueries.push(preloadCreateSpace({ isLicensed }));

	return Promise.all(preloadQueries).then(() => ({ data: {} }));
};
