import type { ComponentType } from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

export { NOT_FOUND_PAGE_LOAD } from './perf.config';

export const NotFound = LoadableAfterPaint({
	loader: async () =>
		// eslint-disable-next-line
		(await import(/* webpackChunkName: "loadable-NotFound" */ './NotFound'))
			.NotFound as ComponentType<any>,
});

export const OverviewNotFound = LoadableAfterPaint({
	loader: async () =>
		// eslint-disable-next-line
		(await import(/* webpackChunkName: "loadable-OverviewNotFound" */ './OverviewNotFound'))
			.OverviewNotFound as ComponentType<any>,
});

export { ErrorPageComponent } from './ErrorPageComponent';

export type { ButtonProps } from './ErrorPageComponent';
