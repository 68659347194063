import gql from 'graphql-tag';

export const FeatureDiscoveryQuery = gql`
	query FeatureDiscoveryQuery {
		featureDiscovery {
			pluginKey
			featureKey
		}
	}
`;
