import type {
	PreloadFailureReason,
	PreloadFailureResult,
} from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { MacrosQuery, getMultiMacroQueryBlocklistFF } from '@confluence/fabric-extension-queries';
import { query } from '@confluence/query-preloader-tools';

import { DEFAULT_PRELOAD_COUNT } from './preloader-constants';
import { macroPreloadGuard } from './macroPreloadGuard';

const failureIfNotSuccess = (reason: PreloadFailureReason) => (success: boolean) =>
	success ? undefined : { MacrosQuery_extensions: reason };

const preloadMacrosSSRAndReturnTopXFailed = async (
	contentId: string,
	featureFlags: { [key: string]: string | number | boolean },
) => {
	const blocklist = getMultiMacroQueryBlocklistFF(featureFlags);
	const preloadResult = await macroPreloadGuard('MacrosQuery_preload', () => {
		return query({
			variables: {
				first: DEFAULT_PRELOAD_COUNT,
				contentId,
				blocklist,
			},
			query: MacrosQuery,
			context: { single: true },
		});
	}).then(failureIfNotSuccess('timeout-rendered-macros'));

	return preloadResult ? Object.assign({}, ...[preloadResult]) : {};
};

export async function preloadMacrosSSRMultiple(
	contentId: string,
	featureFlags: { [key: string]: string | number | boolean },
): Promise<PreloadFailureResult | undefined> {
	// Function has early return so we extracted it out to avoid setting global in every return
	return (window['__SSR_MACRO_PRELOAD_TOPX_FAILS__'] = await preloadMacrosSSRAndReturnTopXFailed(
		contentId,
		featureFlags,
	));
}
