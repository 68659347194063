import React, { lazy } from 'react';
import { useState } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl-next';
import {
	type ThreeLOPromptProps as PublicThreeLOPromptProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { useAuth } from './useAuth';

const AKModalDialog = lazy(() => import('@atlaskit/modal-dialog'));
const AKModalTransition = lazy(() =>
	import('@atlaskit/modal-dialog').then((module) => ({
		default: module.ModalTransition,
	})),
);
const AKModalHeader = lazy(() => import('@atlaskit/modal-dialog/modal-header'));
const AKModalTitle = lazy(() => import('@atlaskit/modal-dialog/modal-title'));
const AKModalBody = lazy(() => import('@atlaskit/modal-dialog/modal-body'));
const AKModalFooter = lazy(() => import('@atlaskit/modal-dialog/modal-footer'));
const AKButton = lazy(() => import('@atlaskit/button/loading-button'));
const SectionMessage = lazy(() => import('@atlaskit/section-message'));

interface Dependencies {
	onClose?: () => void;
	appName?: string;
	isBlanketHidden?: boolean;
	metadata?: {
		moduleType?: string;
	};
}

type ModalThreeLOPromptProps = PublicThreeLOPromptProps &
	Dependencies & {
		onSuccess: () => Promise<void | (() => void)>;
	};

const ModalThreeLOPrompt = ({
	onClose = () => {},
	isBlanketHidden,
	...props
}: ModalThreeLOPromptProps) => {
	const { loading, startAuth, promptMessage, buttonText } = useAuth(props);
	const { authUrl } = props;
	const isAuthUrlEmpty = !authUrl || authUrl === '';
	const intl = useIntl();

	const { modalTitle, modalBody } = isAuthUrlEmpty
		? {
				modalTitle: intl.formatMessage(messages.modalErrorTitle),
				modalBody: (
					<SectionMessage appearance="error">
						<p>
							<FormattedMessage {...messages.noAuthURLErrorMessage} />
						</p>
					</SectionMessage>
				),
			}
		: {
				modalTitle: intl.formatMessage(messages.modalAccessRequiredTitle),
				modalBody: promptMessage,
			};

	const [isModalOpen, setIsModalOpen] = useState(true);

	return (
		<AKModalTransition>
			{isModalOpen && (
				<AKModalDialog
					onClose={() => {
						setIsModalOpen(false);
						onClose();
					}}
					isBlanketHidden={isBlanketHidden}
				>
					<AKModalHeader>
						<AKModalTitle>{modalTitle}</AKModalTitle>
					</AKModalHeader>
					<AKModalBody>{modalBody}</AKModalBody>
					<AKModalFooter>
						<AKButton
							appearance="subtle"
							onClick={() => {
								setIsModalOpen(false);
								onClose();
							}}
						>
							Cancel
						</AKButton>
						{!isAuthUrlEmpty && (
							<AKButton appearance="primary" autoFocus isLoading={loading} onClick={startAuth}>
								{buttonText}
							</AKButton>
						)}
					</AKModalFooter>
				</AKModalDialog>
			)}
		</AKModalTransition>
	);
};

export default ModalThreeLOPrompt;

export const makeModalThreeLOPrompt = ({ onClose, appName }: Dependencies): RenderFn => {
	return function modalThreeLOPrompt({ forgeDoc: { props }, dispatch }) {
		const { message, authUrl, promptText } = props as PublicThreeLOPromptProps;
		return (
			<ModalThreeLOPrompt
				message={message}
				authUrl={authUrl}
				promptText={promptText}
				onSuccess={() => dispatch({ type: 'render', extensionData: {} })}
				appName={appName}
				onClose={onClose}
			/>
		);
	};
};

export const makeModalThreeLOPromptForCustomUI = ({
	onClose,
	appName,
	isBlanketHidden = false,
}: Dependencies) => {
	return (props: ModalThreeLOPromptProps) => {
		return (
			<ModalThreeLOPrompt
				{...props}
				appName={appName}
				onClose={onClose}
				isBlanketHidden={isBlanketHidden}
			/>
		);
	};
};

const messages = defineMessages({
	noAuthURLErrorMessage: {
		id: 'confluence.threeLOPrompt.modal.no.authURL.error-message',
		defaultMessage: 'Consent URL not found. Please refresh the page and try again.',
		description:
			'This text is displayed when the URL that gives an app certain permissions is not present.',
	},
	modalErrorTitle: {
		id: 'confluence.threeLOPrompt.modal.error.title',
		defaultMessage: 'Error',
		description: 'This is the title assigned to a modal when there is an error with this component',
	},
	modalAccessRequiredTitle: {
		id: 'confluence.threeLOPrompt.modal.access.required.title',
		defaultMessage: 'Access required',
		description:
			"This is the title assigned to a modal when access to the user's account is required.",
	},
});
