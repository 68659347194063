import gql from 'graphql-tag';

export const CompanyHubPremiumGateQuery = gql`
	query CompanyHubPremiumGateQuery {
		entitlements {
			companyHub {
				isEntitled
			}
		}
	}
`;
