import { Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';
import { requireLegacyWRM, waitForRequiredModule, globalRequire } from '@confluence/wrm';

export const requireStyleClassResourcesForTemplates = (): void => {
	requireLegacyWRM(
		[
			'wrc!com.atlassian.plugins.atlassian-connect-plugin:blueprint-css-resources',
			'wrc!com.atlassian.plugins.atlassian-connect-plugin:dynamic-css-resources',
		],
		() => {
			// Although we've included 'wr!com.atlassian.plugins.atlassian-connect-plugin:dynamic-css-resources' here
			// The 'link-style-css' may have been executed somewhere else previously.
			// Requiring it again will not have effect. Here we are going to manually initialize it.
			waitForRequiredModule(
				{
					listener: 'TemplateList',
					module: 'ac/confluence/style/link-style-css',
				},
				() => {
					const linkStyleCSS = globalRequire('ac/confluence/style/link-style-css');
					if (linkStyleCSS && typeof linkStyleCSS.addLinkTags === 'function') {
						linkStyleCSS.addLinkTags();
					}
				},
			);
		},
		() => {
			getMonitoringClient().submitError(
				new Error('Style classes cannot be initialized when WRM is disabled.'),
				{
					attribution: Attribution.TAILORED_EXPERIENCES,
				},
			);
		},
	);
};
