export const getSrcUrl = (
	tunnels: { resourceKey: string; tunnelUrl: string }[],
	extensionResource: string,
	defaultUrl: string,
) => {
	const matchTunnel = tunnels.find((tunnel) => tunnel.resourceKey === extensionResource);
	if (matchTunnel) {
		return matchTunnel.tunnelUrl;
	}
	return defaultUrl;
};
