import { LoadableAfterPaint } from '@confluence/loadable';

export const ChangeboardingTargetLazyLoader = LoadableAfterPaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-ChangeboardingTarget" */ './ChangeboardingTarget'),
});

export const ChangeboardingSpotlightLazyLoader = LoadableAfterPaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-ChangeboardingSpotlight" */ './ChangeboardingSpotlight'),
});
