import React from 'react';
import { type ApolloClient } from 'apollo-client';
import memoize from 'memoize-one';
import { dequal } from 'dequal/lite';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import {
	type AnalyticsWebClient,
	type ForgeUIAnalyticsContext,
	sendEvent,
	extensionIdToAnalyticsAttributes,
} from '@atlassian/forge-ui';
import {
	type ContextId,
	type ProductEnvironment,
	type ForgeUIExtensionType,
} from '@atlassian/forge-ui-types';
import { type ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import ForgeEditorExtension, {
	type ConnectToForgeParameters,
	type ForgeExtension,
	type ForgeExtensionParameters,
} from './ForgeEditorExtension';

import { type ReferenceEntity } from '@atlaskit/editor-common/extensions';
import {
	CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE,
	CONNECT_ADDON_ACTION_SUBJECT,
} from '../utils/constants';
import { type FlagFunctions } from '../utils/getFlagProvider';
import type { CustomEditContext } from './ForgeEditorExtension';

interface GetExtensionParams {
	node: ForgeExtension;
	references?: Array<ReferenceEntity>;
}
interface RenderExtension {
	accountId?: string;
	apolloClient: ApolloClient<object>;
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	cloudId: string;
	contextIds: ContextId[];
	environment: ProductEnvironment;
	extension?: ForgeUIExtensionType;
	extensionData: Record<string, any>;
	extensionHandlerWithReference?: ExtensionHandlerWithReferenceFn<ForgeExtensionParameters>;
	FlagProvider: ({ children }: { children: (flags: FlagFunctions) => JSX.Element }) => JSX.Element;
	forgeUIAnalyticsContext: ForgeUIAnalyticsContext;
	isEditing: boolean;
	page: string;
	product: string;
	dataProviders: ProviderFactory;
	hideGlassPane?: boolean;
	customEditContext?: CustomEditContext;
}

export const renderExtension = ({
	accountId,
	apolloClient,
	analyticsWebClient,
	cloudId,
	contextIds,
	environment,
	extension,
	extensionData,
	extensionHandlerWithReference,
	forgeUIAnalyticsContext,
	isEditing,
	page,
	product,
	dataProviders,
	FlagProvider,
	hideGlassPane,
	customEditContext,
}: RenderExtension) =>
	memoize(({ node, references }: GetExtensionParams) => {
		const extensionWithParam = {
			...node,
			parameters: {
				// we always insert with parameters
				...node.parameters!,
				extensionTitle: extension?.properties.title,
				extension,
			},
		};
		const augmentedExtensionData: RenderExtension['extensionData'] = {
			...extensionData,
			isEditing,
		};
		const connectMetadata = getConnectMacroMetadata(node.parameters!);
		if (connectMetadata) {
			augmentedExtensionData.connectMetadata = connectMetadata;
		}

		const getForgeEditorExtensionComponent = ({ references }: GetExtensionParams) => (
			<FlagProvider>
				{(flags: FlagFunctions) => (
					<ForgeEditorExtension
						analyticsWebClient={analyticsWebClient}
						accountId={accountId || ''}
						apolloClient={apolloClient}
						cloudId={cloudId}
						contextIds={contextIds}
						environment={environment}
						extension={transformConnectToForgeExtension(extensionWithParam, extension, {
							analyticsWebClient,
							forgeUIAnalyticsContext,
							isEditing,
						})}
						extensionData={{
							...augmentedExtensionData,
							references,
						}}
						flags={flags}
						isEditing={isEditing}
						product={product}
						dataProviders={dataProviders}
						page={page}
						hideGlassPane={hideGlassPane}
						customEditContext={customEditContext}
					/>
				)}
			</FlagProvider>
		);

		if (extensionHandlerWithReference) {
			return extensionHandlerWithReference(node, ({ references: referencesFromHandler }) =>
				getForgeEditorExtensionComponent({
					node,
					references: referencesFromHandler,
				}),
			);
		}

		return getForgeEditorExtensionComponent({ node, references });
	}, dequal);

function getConnectMacroMetadata({ macroMetadata }: ConnectToForgeParameters):
	| {
			macroId: string;
			title?: string;
	  }
	| undefined {
	const macroId = macroMetadata?.macroId?.value;
	const title = macroMetadata?.title;
	if (!macroId) {
		// We don't want this function to return anything if it didn't have a Connect macro ID,
		// to avoid implying that pure Forge macros were Connect
		return undefined;
	}
	return {
		macroId,
		title,
	};
}

function transformConnectToForgeExtension(
	attrs: ForgeExtension,
	extension?: ForgeUIExtensionType,
	analyticsProps?: Pick<
		RenderExtension,
		'analyticsWebClient' | 'forgeUIAnalyticsContext' | 'isEditing'
	>,
): ForgeExtension {
	if (attrs.extensionType !== CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
		return attrs;
	}

	const { analyticsWebClient, forgeUIAnalyticsContext, isEditing } = analyticsProps || {};
	const eventSource = isEditing ? 'editPageScreen' : 'viewPageScreen';
	const { extensionKey } = attrs;

	try {
		if (!extension?.id) {
			throw new Error('No Forge extension for patching connect addon');
		}
		if (attrs.parameters && !attrs.parameters.extensionId) {
			attrs.parameters.extensionId = extension.id;
		}

		// Transform Connect macroParams to Forge guestParams
		if (attrs.parameters?.macroParams) {
			attrs.parameters.guestParams = attrs.parameters.guestParams || {};
			const connectParams = attrs.parameters.macroParams;
			Object.entries(connectParams).forEach(([key, obj]: [string, any]) => {
				if (typeof attrs.parameters!.guestParams![key] === 'undefined') {
					attrs.parameters!.guestParams![key] = obj.value;
				}
			});
		}
		if (analyticsWebClient) {
			sendEvent(analyticsWebClient)({
				source: eventSource,
				...forgeUIAnalyticsContext,
				eventType: OPERATIONAL_EVENT_TYPE,
				action: 'h11n_macro_render_success',
				actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
				actionSubjectId: extensionKey,
				attributes: {
					extensionKey,
					...extensionIdToAnalyticsAttributes(extension?.id),
					nodeLocalId: attrs.localId,
				},
			});
		}
	} catch (e) {
		if (analyticsWebClient) {
			sendEvent(analyticsWebClient)({
				source: eventSource,
				...forgeUIAnalyticsContext,
				eventType: OPERATIONAL_EVENT_TYPE,
				action: 'h11n_macro_render_failed',
				actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
				actionSubjectId: extensionKey,
				attributes: {
					extensionKey,
					...extensionIdToAnalyticsAttributes(extension?.id),
					errorMessage: e instanceof Error ? e.message : String(e),
					nodeLocalId: attrs.localId,
				},
			});
		}
		throw e;
	}

	return attrs;
}
