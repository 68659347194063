import type { VFC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';

type AnchorExtensionHandlerProps = ExtensionHandlerProps & {
	isSSR: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AnchorWrapper = styled.span({
	display: 'inline-block',
	height: '1em',
	width: 0,
});

const getAnchorName = (node: any) => {
	const rawValue = node?.parameters?.macroParams?.['']?.value;
	if (typeof rawValue !== 'string') return null;
	return rawValue.replaceAll(/[%"'\s]/g, '');
};

const getLegacyAnchorName = (node: any) => {
	return node?.parameters?.macroParams?.['legacyAnchorId']?.value;
};

export const AnchorExtensionHandler: VFC<AnchorExtensionHandlerProps> = ({ node, isSSR }) => {
	const isHidden = Boolean(node?.parameters?.macroParams?.hidden?.value);
	const anchorName = getAnchorName(node);
	const legacyAnchorName = getLegacyAnchorName(node);
	const hasName = !!anchorName || !!legacyAnchorName;

	// Note that we do not render `name` attribute on SSR stage for the following reason:
	// If `name` attribute would be rendered in SSR and there is a corresponding hash
	// in the browser url address field, the viewport will jump to an anchor as soon as HTML
	// is delivered to a client. However, right after that SPA rendering is happening which
	// involves replacing SSR-rendered DOM with SPA-rendered DOM. And this will cause the
	// viewport to jump back to the start of the page.
	const anchorAttr = isSSR ? {} : { name: anchorName };
	const legacyAnchorAttr = isSSR ? {} : { name: legacyAnchorName };

	return (
		<>
			{!isHidden && hasName ? (
				<AnchorWrapper>
					{/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
					<a data-group="deeplink" {...(anchorAttr as any)} />
					{/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
					<a data-group="deeplink" {...(legacyAnchorAttr as any)} />
				</AnchorWrapper>
			) : null}
		</>
	);
};
