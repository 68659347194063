/* eslint-disable no-console */

function getLoggerName(logSource: NonNullable<any>): string {
	return typeof logSource === 'string'
		? logSource
		: logSource.displayName || logSource.name || 'Unknown';
}

class Logger {
	name: string;

	constructor(logSource: NonNullable<any>) {
		this.name = getLoggerName(logSource);
	}

	log(strings: TemplateStringsArray, ...values: any[]): void {
		this.logAnyLevel(1, console.log, strings, values);
	}

	debug(strings: TemplateStringsArray, ...values: any[]): void {
		this.logAnyLevel(2, console.debug, strings, values);
	}

	info(strings: TemplateStringsArray, ...values: any[]): void {
		this.logAnyLevel(3, console.info, strings, values);
	}

	warn(strings: TemplateStringsArray, ...values: any[]): void {
		this.logAnyLevel(4, console.warn, strings, values);
	}

	error(strings: TemplateStringsArray, ...values: any[]): void {
		this.logAnyLevel(5, console.error, strings, values);
	}

	public logAnyLevel(
		logLevel: 1 | 2 | 3 | 4 | 5,
		consoleFunction: typeof console.log,
		strings: TemplateStringsArray,
		values: any[],
	): void {
		if (process.env.NODE_ENV === 'testing') {
			return;
		}

		try {
			if (
				// There's no sessionStorage in service workers.
				// We'll just enable logging from service workers for simplicity.
				typeof sessionStorage === 'undefined' ||
				sessionStorage.getItem('confluence.enable-logging') === 'true'
			) {
				const logLevelSetting =
					typeof sessionStorage !== 'undefined' && sessionStorage.getItem('confluence.log-level');
				const configuredLogLevel = parseInt(logLevelSetting || '', 10) || 1;

				if (configuredLogLevel > logLevel) {
					return;
				}

				const prefix = [`[@${Math.round(performance.now())} ${this.name}]`];
				const messages = strings.reduce((processed: any[], str, index) => {
					const value = values[index];
					const trimmedStr = str
						.trim()
						// Allow multiline strings to be indented in code but printed without indenting
						.replace(/\n\s+/g, '\n');

					if (trimmedStr) {
						processed.push(trimmedStr);
					}
					if (index !== strings.length - 1) {
						processed.push(typeof value === 'string' ? JSON.stringify(value) : value);
					}
					return processed;
				}, prefix);
				consoleFunction(...messages);
			}
		} catch (e) {
			// Catch and log.
			// We don't want to stop execution due to a logging error.
			console.error(e);
		}
	}
}

export const getLogger = (logSource: NonNullable<any>): Logger => new Logger(logSource);
