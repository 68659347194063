import type { UrlMatch } from './matchUrl';
import { matchUrl } from './matchUrl';
import { toUrl } from './toUrl';

export type RouteMatch = UrlMatch & {
	name: string;
};

export type RouteProps = {
	name: string;
	pattern: string;
	// An optional condition to check in match()
	condition?: (match: UrlMatch) => boolean;
	// Determine whether a route change is a transition
	// Default:
	// - true if no previous route
	// - true if pathname is not the same
	isTransition?: (previous: RouteMatch, current: RouteMatch) => boolean;
	ufoName?: string;
};

export class Route {
	static matchFirst(routes: Route[], url: string): RouteMatch | null {
		for (const route of routes) {
			if (!(route instanceof Route)) {
				continue;
			}
			const matchResult = route.match(url);
			if (matchResult) {
				return matchResult;
			}
		}
		return null;
	}

	readonly name: string;
	readonly ufoName?: string;
	readonly pattern: string;
	readonly condition: (match: UrlMatch) => boolean;
	readonly isTransition: (previous: RouteMatch | null, current: RouteMatch) => boolean;

	constructor(props: RouteProps) {
		this.name = props.name;
		this.ufoName = props.ufoName;
		this.pattern = props.pattern;
		this.condition = props.condition ? props.condition : () => true;
		this.isTransition = (previous, current) => {
			if (!previous) return true;
			if (props.isTransition) {
				return previous.name === current.name ? props.isTransition(previous, current) : true;
			}
			return previous.pathname !== current.pathname;
		};
	}

	match(url: string): RouteMatch | null {
		const urlMatch = matchUrl(this.pattern, url);

		if (urlMatch) {
			if (this.condition && !this.condition(urlMatch)) {
				return null;
			}

			return {
				...urlMatch,
				name: this.name,
			};
		}

		return null;
	}

	toUrl(params?: Parameters<typeof toUrl>[1], options?: Parameters<typeof toUrl>[2]): string {
		return toUrl(this.pattern, params, options);
	}
}
