import { isApolloError } from 'apollo-client';

import { getMonitoringClient } from '@confluence/monitoring';

declare global {
	interface Window {
		__GRAPHQL_ERROR_PROCESSOR__?: GraphqlErrorProcessor;
	}
}

function onGraphqlError(error: any): void {
	getMonitoringClient().submitError(error, { attribution: 'graphql' });
}

// NOTE: use `Symbol.for(...)` here because this module is bundled into two separate
// chunks - preloader and main app. Creating it via `Symbol(...)` will result in two
// different symbols, so errors that are handled in the preloader won't be identified
// as handled in the main app.
const HANDLED_ERROR_MARK_SYMBOL = Symbol.for('error marked as handled');

export class GraphqlErrorProcessor {
	// map of Error to timeoutId of when the generic error handling will kick-off
	private readonly errorsToHandle = new Map<any, number>();

	// eslint-disable-next-line no-useless-constructor
	constructor(
		private gracefulHandlingPeriodMs: number,
		private errorHandler: typeof onGraphqlError,
	) {}

	processError = (error: Error | undefined): void => {
		if (error) {
			this.scheduleErrorReporting(error);
		}
	};

	isErrorMarkedAsHandled = (error: any): boolean => {
		if (!error) {
			return false;
		}

		if (typeof error !== 'object') {
			return false;
		}

		return Boolean(error[HANDLED_ERROR_MARK_SYMBOL]);
	};

	markErrorAsHandled = (error: any): void => {
		if (!error) return;

		if (isApolloError(error)) {
			(error.graphQLErrors || []).forEach((e) => this.markErrorAsHandled(e));
			this.markErrorAsHandled(error.networkError);
		}

		const timeoutId = this.errorsToHandle.get(error);

		if (typeof error === 'object') {
			error[HANDLED_ERROR_MARK_SYMBOL] = true;
		}

		window.clearTimeout(timeoutId);
		this.errorsToHandle.delete(error);
	};

	private scheduleErrorReporting(error: any) {
		const timeoutId = window.setTimeout(() => {
			// NOTE: This is an extra measure to prevent over-reporting of an otherwise handled error.
			// When query is preloaded and errors out, the reporting is scheduled by onErrorLink from
			// both preloader and from the place where query data is processed, but `errorsToHandle`
			// would only contain the entry of the error mapped to the latest timeoutId.
			if (this.isErrorMarkedAsHandled(error)) {
				return;
			}

			if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing') {
				// eslint-disable-next-line no-console
				console.error(`%cSTOP!`, `color: #FF0000; font-size: 272px; font-weight: bold;`);
				// eslint-disable-next-line no-console
				console.error(
					`%cNow that we have your attention -- you haven't reported the below GraphQL error properly. Refer to error handling section in "next/packages/graphql/README.md" file for how to fix this.`,
					`color: #FF1493; font-weight: bold;`,
				);
				// eslint-disable-next-line no-console
				console.error(error);
			}

			this.errorHandler(error);
			markErrorAsHandled(error);
		}, this.gracefulHandlingPeriodMs);

		this.errorsToHandle.set(error, timeoutId);
	}
}

const GRACEFUL_ERROR_HANDLING_PERIOD_MS =
	// for testing environment the check for error handling needs to happen immediately (well, close to it, at least).
	// "Next tick" should be good enough, as this error processor handles GraphQL errors, which are async by nature.
	// Having this timeout be different might result in test flakiness, where an incorrectly handled GraphQL error
	// in one test might affect a subsequent test.
	process.env.NODE_ENV === 'testing' ? 0 : 100;

// GraphqlErrorProcessor is stateful and should be a singleton. Given that this module
// is bundled into two separate chunks - preloader and main app, we need to make sure
// that the same instance is used in both places.
let defaultGraphqlErrorProcessor = window.__GRAPHQL_ERROR_PROCESSOR__;

if (!defaultGraphqlErrorProcessor) {
	defaultGraphqlErrorProcessor = new GraphqlErrorProcessor(
		GRACEFUL_ERROR_HANDLING_PERIOD_MS,
		onGraphqlError,
	);

	window.__GRAPHQL_ERROR_PROCESSOR__ = defaultGraphqlErrorProcessor;
}

export const __test_only_GRACEFUL_ERROR_HANDLING_PERIOD_MS = GRACEFUL_ERROR_HANDLING_PERIOD_MS;

export const markErrorAsHandled = defaultGraphqlErrorProcessor.markErrorAsHandled;
export const isErrorMarkedAsHandled = defaultGraphqlErrorProcessor.isErrorMarkedAsHandled;
export const processError = defaultGraphqlErrorProcessor.processError;
