/* GeneratedContentHash: 4d32a4689c92500edf1c336e733018ff47b68304e95803187f464b342d5f6598 */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
import { lazy } from 'react';

export default {
	'message-template-template': lazy(
		() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--message-template-template/placements/in-app/screen-space-flags/render'
			),
	),
};
