import { type ConsentPreferences, ContextualConsentProperty, PreferenceCategory } from './types';

export const LEGACY_ATLASSIAN_GDPR_COOKIE_KEY = 'atl-gdpr-consent';
export const ATLASSIAN_CONSENT_COOKIE_KEY = 'atl-bsc-consent-token';
export const ATLASSIAN_SHOW_BANNER_COOKIE_KEY = 'atl-bsc-show-banner';
// TODO: DEPRECATED: Locale no longer supported, but to ensure backwards compatibility, we'll keep this exported const
export const ATLASSIAN_CURRENT_LOCALE_COOKIE_KEY = 'atl-bsc-current-locale';
export const ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY = 'atl-bsc-consent-token-fallback';
export const TESTING_TOKEN_OVERRIDE_COOKIE_KEY = 'atl-testing-consents';
export const ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY = 'atl-bsc-banner-dismissed-time';

export const ACCEPT_ALL_PREFERENCES: ConsentPreferences = {
	[PreferenceCategory.StrictlyNecessary]: true,
	[PreferenceCategory.Functional]: true,
	[PreferenceCategory.Marketing]: true,
	[PreferenceCategory.Analytics]: true,
	[PreferenceCategory.Unknown]: true, // Includes uncategorized cookies
};

export const ONLY_NECESSARY_PREFERENCES: ConsentPreferences = {
	[PreferenceCategory.StrictlyNecessary]: true,
	[PreferenceCategory.Functional]: false,
	[PreferenceCategory.Marketing]: false,
	[PreferenceCategory.Analytics]: false,
	[PreferenceCategory.Unknown]: false,
};

// Default preferences are now strict/"Only Necessary" by default for all users
// and for sake of ease, we'll classify them as "logged out"
export const DEFAULT_PREFERENCES: ConsentPreferences = {
	...ONLY_NECESSARY_PREFERENCES,
	[ContextualConsentProperty.ConsentsAreDefault]: true,
	[ContextualConsentProperty.UserIsAuthenticated]: false,
	[ContextualConsentProperty.ConsentDataUnavailable]: true,
};

export const V001_SCHEMA_KEY = '001';
export const V002_SCHEMA_KEY = '002';
export const V003_SCHEMA_KEY = '003';
