import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { CardAlignment } from '../../linkCardsTypes';
import { AlignmentField, FormFieldErrorMessage } from '../../../shared-components';
import type { UpdateMainFormFieldType } from '../../../shared-components/ContextPanel/configPanelTypes';
import { checkAlignment } from '../../LinkCardsContent';

// should be removed after the fg('company_hub_manual_cards_refinement') is removed
export const DEFAULT_CARDS_ALIGNMENT = 'center';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignmentFieldWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});

const i18n = defineMessages({
	alignmentParameterInvalidMessage: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.alignment.error.invalid-parameter',
		defaultMessage: 'Select alignment',
		description:
			'Message for error shown to user when a value for alignment is not provided or is invalid',
	},
});

type AlignmentFieldProps = {
	alignmentParameter: CardAlignment;
	updateMainFormField: UpdateMainFormFieldType;
};

export const CardConfigPanelAlignmentField: FC<AlignmentFieldProps> = ({
	alignmentParameter,
	updateMainFormField,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onAlignmentChange = (selectedValue: CardAlignment) => {
		updateMainFormField({ alignment: selectedValue });

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'dropdownItem',
				actionSubjectId: 'alignmentDropdown',
				source: 'cardsExtensionConfig',
				attributes: {
					size: selectedValue,
				},
			},
		}).fire();
	};

	return (
		<AlignmentFieldWrapper>
			<AlignmentField
				alignmentParameter={alignmentParameter}
				onAlignmentChange={onAlignmentChange}
			/>
			{!checkAlignment(alignmentParameter) && (
				<FormFieldErrorMessage>
					<FormattedMessage {...i18n.alignmentParameterInvalidMessage} />
				</FormFieldErrorMessage>
			)}
		</AlignmentFieldWrapper>
	);
};
