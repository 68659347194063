declare global {
	interface Window {
		testAnalytics?: any;
	}
}

import Cookie from 'js-cookie';

import { AllAnalyticsCookies } from './cookie';

const MAX_CACHE_SIZE = 100;
const COOKIE_NAME = AllAnalyticsCookies.AWC_ANALYTICS_DEBUG.getKey();
const DEBUG_TAG = 'synthetic';

const getDebugCookie: () => boolean = () => {
	try {
		return JSON.parse(Cookie.get(COOKIE_NAME) || 'false');
	} catch (error) {
		Cookie.remove(COOKIE_NAME);
	}
	return false;
};

class TestingCache {
	private readonly _isDebugCookieEnabled: boolean;

	constructor() {
		this._isDebugCookieEnabled = getDebugCookie();
		if (this._isDebugCookieEnabled) {
			this._bind();
		}
	}

	_bind = () => {
		if (typeof window === 'undefined' || window.testAnalytics) {
			return;
		}

		window.testAnalytics = {
			eventCache: [],
			events: () => window.testAnalytics.eventCache,
			clear: () => {
				window.testAnalytics.eventCache = [];
				try {
					localStorage.removeItem('awc.ui.viewed.last.sent');
					localStorage.removeItem('awc-staging.ui.viewed.last.sent');
					localStorage.removeItem('awc-dev.ui.viewed.last.sent');
					localStorage.removeItem('awc-local.ui.viewed.last.sent');
				} catch (_error) {
					// Ignore errors from localStorage.
				}
			},
		};
	};

	_cache = (event: any) => {
		if (typeof window === 'undefined') {
			return;
		}

		if (window.testAnalytics.eventCache.length >= MAX_CACHE_SIZE) {
			window.testAnalytics.eventCache = window.testAnalytics.eventCache.slice(
				1,
				window.testAnalytics.eventCache.length,
			);
		}
		window.testAnalytics.eventCache.push(event);
	};

	_shouldCacheEvent = () => this._isDebugCookieEnabled;

	saveEvent = (event?: any) => {
		if (event && this._shouldCacheEvent()) {
			if (!window.testAnalytics) {
				this._bind();
			}
			if (event.tags instanceof Array) {
				event.tags.push(DEBUG_TAG);
			} else {
				event.tags = [DEBUG_TAG];
			}
			this._cache(event);
		}
	};
}

export default TestingCache;
