/* eslint-disable react/prop-types */
import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { CALENDAR_CONTEXT } from '@confluence/team-calendar/entry-points/TeamCalendars';

const SpaceTeamCalendarsRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceTeamCalendarsRouteComponent" */ './SpaceTeamCalendarsRouteComponent'
		),
});

export const SpaceTeamCalendarsRoute = ({ params: { subCalendarId } }: RouteMatch) => {
	const calendarContext = subCalendarId
		? CALENDAR_CONTEXT.SPACE_SUBCALENDAR
		: CALENDAR_CONTEXT.SPACE_CALENDARS;

	return (
		<SpaceTeamCalendarsRouteComponent
			subCalendarId={subCalendarId}
			calendarContext={calendarContext}
		/>
	);
};

SpaceTeamCalendarsRoute.NAVIGATION_PARAMS = ({
	params: { spaceKey, subCalendarId },
}: RouteMatch) => {
	return {
		Screen: {
			screenEvent: {
				name: 'spaceTeamCalendarsScreen',
				id: spaceKey,
				attributes: {
					isSingleCalendar: !!subCalendarId,
				},
			},
			pageState: { spaceKey },
		},
		MainLayout: {
			navView: CONTAINER_HOME_ID,
			spaceKey,
		},
	};
};
