import React from 'react';

import Field from '@atlaskit/form/Field';

import { MULTI_LINES_TYPE } from './constants';
import {
	TemplateVariableInputWrapper,
	TemplateVariableInputFieldWrapper,
	TemplateVariableTextareaInputFieldWrapper,
} from './styles';
import type { TemplateVariableParams } from './types';
import { TemplateVariableInputComponent } from './TemplateVariableInputComponent';

export type TemplateVariableInputProps = {
	parameters?: TemplateVariableParams;
};

export const TemplateVariableInput = ({ parameters }: TemplateVariableInputProps) => {
	// https://github.com/final-form/react-final-form/issues/103
	// If users name variables using numbers it breaks the form
	// hacky fix added a $ character in front
	const inputName: string = `$${parameters?.name}`;
	const inputPlaceholder: string = `${parameters?.placeholder || parameters?.name}`;

	// . [ or ] in the variable name causes Final Form to throw an error
	const validInputName = inputName.replace(/[`.\[\]\/]/gi, '');

	return (
		<TemplateVariableInputWrapper>
			{parameters?.type === MULTI_LINES_TYPE ? (
				<TemplateVariableTextareaInputFieldWrapper>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
					<div style={{ display: 'inline-block', width: '100%' }}>
						<Field name={validInputName} defaultValue="">
							{({ fieldProps }) => (
								<TemplateVariableInputComponent
									{...fieldProps}
									parameters={parameters}
									inputName={validInputName}
									placeholder={inputPlaceholder}
								/>
							)}
						</Field>
					</div>
				</TemplateVariableTextareaInputFieldWrapper>
			) : (
				<TemplateVariableInputFieldWrapper>
					<div
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							display: 'inline-block',
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							width: '100%',
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							margin: '0px 0px',
						}}
					>
						<Field name={validInputName} defaultValue="">
							{({ fieldProps }) => (
								<TemplateVariableInputComponent
									{...fieldProps}
									parameters={parameters}
									inputName={validInputName}
									placeholder={inputPlaceholder}
								/>
							)}
						</Field>
					</div>
				</TemplateVariableInputFieldWrapper>
			)}
		</TemplateVariableInputWrapper>
	);
};
