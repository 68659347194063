import type { EditorPluginAIProvider } from '../types';

import { mapToConvoAIProductName } from './prompt-requests/utils/get-convoai-supported-product-name';

// Note -- the expectation is this type and the related EditorPluginAIPromptResponseMarkdown
// will be collapsed when we migrate to a single endpoint for all use cases.
type StreamingResponseEntry =
	| { state: 'loading'; data: unknown }
	| {
			state: 'failed';
			reason: 'backend-input-guard';
			guard: 'INPUT_EXCEEDS_TOKEN_LIMIT';
			statusCode: number;
	  }
	| {
			state: 'failed';
			reason: 'backend-input-guard';
			guard: 'INPUT_TOO_SHORT_TO_SUMMARIZE' | 'INPUT_TOO_SHORT_TO_PROCESS';
			statusCode: number;
	  }
	| { state: 'aup-violation'; statusCode: 451 }
	| {
			state: 'failed';
			reason: 'response-too-similar';
			statusCode: 208;
			data: unknown;
	  }
	| {
			state: 'failed';
			reason: 'rate-limited';
			retryAfter: number;
			statusCode: number;
	  }
	| {
			state: 'failed';
			reason: 'network' | 'backend' | 'aborted' | 'parsing';
			error: any;
			statusCode: number;
	  }
	| { state: 'loaded'; data: unknown }
	// When streaming content -- we don't receive a final stream event when the stream is finished reading
	| { state: 'loaded-stream' };
export type StreamingResponse = AsyncGenerator<StreamingResponseEntry>;

type XProductHeader = { 'X-Product': string } | {};
/**
 * Only send the X-Product header if the request is to the assistance-service
 * Exported for testing purposes
 */
export const getXProductHeader = (product: string): XProductHeader => {
	return {
		'X-Product': mapToConvoAIProductName(product),
	};
};

/**
 * Intended for use with streaming endpoints
 * https://hello.atlassian.net/wiki/spaces/CA3/pages/2485466879/Generative+AI+API+Specs
 *
 * This sets up the initial connection to the streaming endpoint, handles
 * socket/http connection issues and yields information to
 * streamResponseParser() as events stream in.
 */
export async function* startStreamingResponse({
	endpoint,
	payload,
	abortController,
	getFetchCustomHeaders,
	product,
	// These are for convoai/assistance-service
	experienceId = 'editor',
	channelId,
}: {
	endpoint: string;
	payload: string;
	abortController: AbortController;
	getFetchCustomHeaders?: EditorPluginAIProvider['getFetchCustomHeaders'];
	product: string;
	experienceId?: string;
	channelId?: string;
}): StreamingResponse {
	try {
		let actualEndpoint = endpoint;

		// Using a stateful endpoint
		if (channelId) {
			actualEndpoint = `${endpoint}/${channelId}/message/stream`;
		}

		const fetchInit: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				'X-Experience-Id': experienceId,
				...getXProductHeader(product),
			},
			// When we move to a single api endpoint we will be able to collapse
			// the current logic spread across;
			// - start-streaming-response
			// - generate, transform, summarise
			// - mapResponseForSingleKeyToMarkdown
			// - and various config items
			// In the meantime -- have opted into this stringify -> parse -> stringify
			// to keep the shapes of startStreamingResponse and startSingleResponse aligned.
			body: payload,
			credentials: 'include',
			signal: abortController.signal,
			mode: 'cors',
		};
		const fetchCustomHeaders = getFetchCustomHeaders?.(endpoint, fetchInit) || {};
		const response = await fetch(actualEndpoint, {
			...fetchInit,
			headers: { ...fetchInit.headers, ...fetchCustomHeaders },
		});

		if (!response.ok) {
			// For non 200 status codes
			if (response.status === 400) {
				try {
					// See
					// - https://hello.atlassian.net/wiki/spaces/CA3/pages/2485466879/Generative+AI+API+Specs#Error-codes
					// for details

					// Errors with special handling
					const knownErrors = [
						// We have soft alignment with the BE that for inputs that are not suitable
						// for prompts -- we want to rely on openai providing a suitable message for
						// the user. And not have manually maintained guards/user feedback messages.
						// https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3573548013/Introducing+AI+Dev+Documentation+TODO+AIFOLLOWUP+audit
						'INPUT_TOO_SHORT_TO_SUMMARIZE' as const,
						'INPUT_TOO_SHORT_TO_PROCESS' as const,
						'INPUT_EXCEEDS_TOKEN_LIMIT' as const,
					];
					const { error } = await response.json();
					if (error) {
						if (typeof error === 'object' && 'key' in error && knownErrors.includes(error.key)) {
							return yield {
								state: 'failed',
								reason: 'backend-input-guard',
								guard: error.key,
								statusCode: response.status,
							};
						}
						return yield {
							state: 'failed',
							reason: 'backend',
							error: 'Unhandled error response received',
							statusCode: response.status,
						};
					}
				} catch (backendError) {
					return yield {
						state: 'failed',
						reason: 'backend',
						error: backendError,
						statusCode: response.status,
					};
				}
			}

			if (response.status === 429) {
				// The BE are unable to add the header due to constraints with the service proxy
				// There is a ticket to add this via feature request
				// https://hello.jira.atlassian.cloud/browse/SPSVC-28
				// For now we are just hard coding the value
				let retryAfter = response.headers.get('Retry-After');
				if (!retryAfter) {
					retryAfter = '60';
				}

				return yield {
					state: 'failed',
					reason: 'rate-limited',
					retryAfter: Number(retryAfter),
					statusCode: response.status,
				};
			}

			if (response.status === 451) {
				// This status code is being used to indicate that the request was unable to be handled
				// due to the content not meeting the Acceptable Use Policy.
				return yield {
					state: 'aup-violation',
					statusCode: 451,
				};
			}

			return yield {
				state: 'failed',
				reason: 'backend',
				error: `unexpected response status: ${response.status}`,
				statusCode: response.status,
			};
		}

		if (!response.body) {
			yield {
				state: 'failed',
				reason: 'network',
				error: 'response.body missing',
				statusCode: response.status,
			};
			return;
		}

		try {
			const reader = response.body.getReader();
			const decoder = new TextDecoder('utf-8');
			let buffer = '';
			let done = false;

			while (!done) {
				const { value, done: doneReading } = await reader.read();
				done = doneReading;
				const chunkValue = decoder.decode(value);
				buffer = buffer + chunkValue;
				// Split the buffer by line breaks
				const lines = buffer.split('\n');
				// Process all complete lines, except for the last one (which might be incomplete)
				while (lines.length > 1) {
					const line = lines.shift()!;
					const parsedData = JSON.parse(line);

					if (parsedData.generatedContent === null) {
						if (parsedData.error?.statusCode === 429) {
							yield {
								state: 'failed',
								reason: 'rate-limited',
								retryAfter: 60,
								statusCode: 429,
							};
							return;
						}
						if (parsedData.error?.key === 'RESPONSE_TOO_SIMILAR') {
							yield {
								state: 'failed',
								reason: 'response-too-similar',
								statusCode: 208,
								data: parsedData,
							};
							return;
						}
					}
					yield { state: 'loading', data: parsedData };
				}
				// Keep the last (potentially incomplete) line in the buffer
				buffer = lines[0];
			}

			yield { state: 'loaded-stream' };
			return;
		} catch (parsingError) {
			yield {
				state: 'failed',
				reason: 'parsing',
				error: parsingError,
				statusCode: response.status,
			};
			return;
		}
	} catch (error: any) {
		if (error.name === 'AbortError') {
			yield {
				state: 'failed',
				reason: 'aborted',
				error,
				statusCode: error.status,
			};
		} else if (error.cause === 'ConvoAIChannelCreationError') {
			yield {
				state: 'failed',
				reason: 'backend',
				error,
				statusCode: error.status,
			};
		} else {
			// This can be due to code crashes too, but falls back to network errors
			// eslint-disable-next-line no-console
			console.error('Editor AI: startStreamingResponse()', error);

			yield {
				state: 'failed',
				reason: 'network',
				error,
				statusCode: error.status,
			};
		}
		return;
	}
}
