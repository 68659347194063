import gql from 'graphql-tag';

/*This query is to optimize the macro-loaders loading js and css via WRM.require when it can be fetched directly via this query*/
export const MacroContentRendererQueryWithTags = gql`
	query MacroContentRendererQueryWithTags(
		$adf: String!
		$containedRender: Boolean = false
		$contentId: ID!
		$mode: ContentRendererMode = RENDERER
	) {
		contentRenderer(
			adf: $adf
			containedRender: $containedRender
			contentId: $contentId
			mode: $mode
		) {
			html
			webResourceDependencies {
				contexts
				keys
				tags {
					js
					css
				}
			}
		}
	}
`;

export const MacroBodyRendererQueryWithTags = gql`
	query MacroBodyRendererQueryWithTags(
		$adf: String!
		$containedRender: Boolean = false
		$contentId: ID
		$mode: ContentRendererMode = RENDERER
	) {
		macroBodyRenderer(
			adf: $adf
			containedRender: $containedRender
			contentId: $contentId
			mode: $mode
		) {
			value
			representation
			mediaToken {
				token
			}
			webResourceDependencies {
				contexts
				keys
				tags {
					js
					css
				}
			}
		}
	}
`;
