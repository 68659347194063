import memoizeOne from 'memoize-one';

import type FeatureFlagClient from '@atlaskit/feature-flag-client';
import { ExposureTriggerReason } from '@atlaskit/feature-flag-client';
import type { ProviderProps } from '@atlaskit/link-provider';

import type { FeatureFlagsType } from '@confluence/session-data';

// Force FeatureFlagKey to string as FeatureFlagKey was defined as { [key: string]: boolean | string }
// but `keyof FeatureFlagsTyp` is string | number. This is because JavaScript object keys
// are always coerced to a string, so obj[0] is always the same as obj["0"].
type FeatureFlagKey = string;
type FeatureFlagValue = FeatureFlagsType[keyof FeatureFlagsType];
type FeatureFlagCollection =
	| Record<FeatureFlagKey, FeatureFlagValue>
	| Map<FeatureFlagKey, FeatureFlagValue>;

export type SmartCardProviderFeatureFlags = ProviderProps['featureFlags'];
export type SmartCardProviderLocation = 'editor' | 'renderer';

type LinkingPlatformFeatureFlagDetails = {
	featureName: string;
	key: FeatureFlagKey;
	isEditor: boolean;
	isRenderer: boolean;
	isTracking: boolean;
	value?: FeatureFlagValue;
};

/**
 * This object contains additional feature flags
 * that should be forward to SmartCardProvider but does not
 * have Linking Platform feature flag naming prefix.
 */
const ADDITIONAL_FEATURE_FLAGS: {
	[key: FeatureFlagKey]: LinkingPlatformFeatureFlagDetails;
} = {};

/**
 * Determines if the feature flag is Linking Platform feature flags
 * and extract additional details from flag name.
 * @param key
 * @param value
 */
const getLinkingPlatformFeatureFlagDetails = (
	key: FeatureFlagKey,
	value: FeatureFlagValue,
): LinkingPlatformFeatureFlagDetails | undefined => {
	if (key.startsWith('confluence.frontend.linkingPlatform.')) {
		const featureName = key.substring(key.lastIndexOf('.') + 1);
		if (featureName) {
			const fragments = key.split('.');
			const isTracking = fragments.includes('exposure');
			const isEditor = fragments.includes('editor');
			const isRenderer = fragments.includes('renderer');
			return { featureName, key, isEditor, isRenderer, isTracking, value };
		}
	}

	if (ADDITIONAL_FEATURE_FLAGS[key]) {
		return { ...ADDITIONAL_FEATURE_FLAGS[key], value };
	}
};

/**
 * Extract Linking Platform feature flags.
 * @param featureFlags
 */
export const getLinkingPlatformFeatureFlags = (
	featureFlags: FeatureFlagCollection,
): LinkingPlatformFeatureFlagDetails[] => {
	try {
		const obj = featureFlags instanceof Map ? Object.fromEntries(featureFlags) : featureFlags;

		const lpFlags = Object.entries(obj).reduce(
			(acc: LinkingPlatformFeatureFlagDetails[], [key, value]) => {
				const detail = getLinkingPlatformFeatureFlagDetails(key, value);
				return detail ? [...acc, detail] : acc;
			},
			[],
		);

		return lpFlags;
	} catch (e) {
		// We don't want to throw error on feature flags.
		return [];
	}
};

/**
 * Sends exposure event for linking platform feature flags.
 * @param featureFlagClient
 * @param flags
 */
export const trackLinkingPlatformFeatureFlags = (
	featureFlagClient: FeatureFlagClient,
	flags: LinkingPlatformFeatureFlagDetails[],
) => {
	try {
		flags
			.filter(({ isTracking }) => isTracking)
			.forEach(({ key }) => {
				featureFlagClient.trackFeatureFlag(key, {
					triggerReason: ExposureTriggerReason.Manual,
				});
			});
	} catch (e) {
		// We don't want to throw error on tracking request fails.
		return undefined;
	}
};

/**
 * Extract any feature flags with prefix `confluence.frontend.linkingPlatform.`
 * Identify flag additional filter by keyword e.g. `editor` or `renderer`.
 * Send exposure event on feature flag with keyword `exposure`.
 */
const processFeatureFlags = memoizeOne(
	(
		featureFlagClient: FeatureFlagClient,
		featureFlags: FeatureFlagCollection,
	): LinkingPlatformFeatureFlagDetails[] => {
		const lpFlags = getLinkingPlatformFeatureFlags(featureFlags);

		trackLinkingPlatformFeatureFlags(featureFlagClient, lpFlags);

		return lpFlags;
	},
);

/**
 * Convert feature flags detail into the object that accepted by SmartCardProvider.
 * @param flags
 */
export const toSmartCardProviderFeatureFlags = (
	flags: LinkingPlatformFeatureFlagDetails[],
): SmartCardProviderFeatureFlags =>
	flags.reduce((acc, { featureName, value }) => ({ ...acc, [featureName]: value }), {});

/**
 * Returns all feature flags that can be forward to SmartCardProvider.
 *
 * Feature flag name must be prefixed with `confluence.frontend.linkingPlatform.`
 * e.g. `confluence.frontend.linkingPlatform.showSmartCardActions`.
 *
 * The last section of the flag name is to be passed to `SmartCardProvider`,
 * e.g. `confluence.frontend.linkingPlatform.exposure.showSmartCardActions` become `showSmartCardActions`.
 *
 * Flag name can contain keywords:
 * - `exposure` sends tracking exposure event
 * - `editor` indicated flag to be used in Editor. @see getEditorSmartCardProviderFeatureFlags
 * - `renderer` indicates flag to be used in Renderer. @see getRendererSmartCardProviderFeatureFlags
 * Keywords are dot (.) separated.
 */
export const getSmartCardProviderFeatureFlags = memoizeOne(
	(
		featureFlagClient: FeatureFlagClient,
		featureFlags: FeatureFlagCollection,
		location?: SmartCardProviderLocation,
	): SmartCardProviderFeatureFlags => {
		const lpFlags = processFeatureFlags(featureFlagClient, featureFlags);

		if (location === 'editor') {
			return toSmartCardProviderFeatureFlags(lpFlags.filter(({ isEditor }) => isEditor));
		}

		if (location === 'renderer') {
			return toSmartCardProviderFeatureFlags(lpFlags.filter(({ isRenderer }) => isRenderer));
		}

		return toSmartCardProviderFeatureFlags(lpFlags);
	},
);
