import { parse } from 'url';

import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';

import matchPathFromRoutes from '../components/Routes/matchPathFromRoutes';

/**
 * Takes a LOCATION_CHANGE action from connected-react-router and augments it with a "query" object (which represents the
 * querystring parameters), and the route parameters extracted from Confluence's supported routes.
 *
 * This was written for backwards compatibility with the state provided by redux-router (which we moved away from).
 *
 * @param {object} state
 * @param {object} action
 * @returns {{ location: object, params: object }}
 */
export default (browserHistory) => {
	const routerReducer = browserHistory ? connectRouter(browserHistory) : (state = null) => state;

	return (state, action) => {
		const newState = routerReducer(state, action);

		if (action && action.type === LOCATION_CHANGE) {
			const { match, routeName, screenName } = matchPathFromRoutes(
				action.payload.location.pathname,
			);

			const query = newState.location.search ? parse(newState.location.search, true).query : {};

			return {
				action: newState.action,
				location: {
					...newState.location,
					query,
					routeName,
					screenName,
				},
				params: match ? match.params : {},
			};
		}

		return newState;
	};
};
