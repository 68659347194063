import React from 'react';
import { styled } from '@compiled/react';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';
import { R400, R500 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorText = styled.span({
	fontSize: '12px',
	color: token('color.text.accent.red', R500),
});

export const FormFieldErrorMessage = ({ children }) => (
	<ErrorMessageWrapper>
		<ErrorIcon label="" size="small" primaryColor={token('color.icon.accent.red', R400)} />
		<ErrorText>{children}</ErrorText>
	</ErrorMessageWrapper>
);
