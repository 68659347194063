import { setAttachment } from './graphql-helpers';

const noop = () => {};

type ConfigOptions = {
	contentId: string;
};

type EditorMediaProviderOptions = {
	contentType: string;
} & ConfigOptions;

//Moved attachUploadedMedia in media-helpers to aid in testing attachUploadedMedia is being called.
export const attachUploadedMedia = (options: EditorMediaProviderOptions) => {
	if (options.contentId && options.contentType) {
		return (file) => {
			const { id, name, size, mimeType } = file;
			const { contentId, contentType } = options;
			const requestBody = {
				size,
				contentType,
				mimeType,
				fileName: name,
				pageId: contentId,
				fileStoreId: id,
				minorEdit: true,
				renderEditorHTML: false,
				collectionName: `contentId-${contentId}`,
				uploadSource: 'fabric-editor',
			};
			void setAttachment(requestBody);
		};
	} else {
		return noop;
	}
};
