// TODO platform_moonjelly_bsc_new_caches

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { getPreferences } from '../../../controllers/preferences-controls/get-preferences';
import {
	CachedKeysDataPropertyLegacy,
	type CategorizedStorageControlsCacheLegacy,
	PreferenceCategory,
} from '../../../types';
import { loadStorageControlsData } from '../load-storage-controls-data-legacy';

const findStartsWithPatternMatch = (
	itemKey: string,
	{ startsWith }: CategorizedStorageControlsCacheLegacy['patterns'],
): number | null => {
	// Setup initial pattern step and position
	let currentStep = startsWith;
	let position = 0;

	// Iterate through item key symbol by symbol
	while (position < itemKey.length) {
		// The value of item character at the position
		const currentStepValue = currentStep[itemKey[position]];
		// If there is no match for item symbol in cache, this means there is no
		// pattern match for the item key
		if (currentStepValue === undefined) {
			return null;
		}
		// If current step value is a number, this means we reached the end of the pattern
		// and should return category number
		if (typeof currentStepValue === 'number') {
			return currentStepValue;
		}
		// Continue to going through pattern
		currentStep = currentStepValue;
		position++;
	}

	// No pattern match - all patterns longer or shorter than item key
	return null;
};

/**
 * Synchronously fetches a an item/cookie category from a CategorizedStorageControlsCache that has already been fetched
 *
 * This allows synchronous parts of the code to access cached versions of the cache but still maintain
 * a synchronous signature for clients
 *
 */
const getItemCategoryFromPassedCache = (
	itemKey: string,
	storageControlsData: CategorizedStorageControlsCacheLegacy,
	cachedKeysDataProperty:
		| CachedKeysDataPropertyLegacy.Cookies
		| CachedKeysDataPropertyLegacy.LocalStorage
		| CachedKeysDataPropertyLegacy.SessionStorage,
) => {
	let itemCategoryNumber: number | null = null;
	const { categories, patterns } = storageControlsData;
	let keys = storageControlsData['keys'];

	// Find direct match in keys
	if (itemKey in Object(keys)) {
		itemCategoryNumber = keys[itemKey];
	} else {
		// Find pattern match or null
		itemCategoryNumber = findStartsWithPatternMatch(itemKey, patterns);
	}

	const numToCategoryMap = {
		[categories.STRICTLY_NECESSARY]: PreferenceCategory.StrictlyNecessary,
		[categories.FUNCTIONAL]: PreferenceCategory.Functional,
		[categories.ANALYTICS]: PreferenceCategory.Analytics,
		[categories.MARKETING]: PreferenceCategory.Marketing,
		[categories.UNKNOWN]: PreferenceCategory.Unknown,
	};

	return itemCategoryNumber !== null ? numToCategoryMap[itemCategoryNumber] : null;
};

export const getItemCategoryLegacy = async (
	itemKey: string,
	cachedKeysDataProperty:
		| CachedKeysDataPropertyLegacy.Cookies
		| CachedKeysDataPropertyLegacy.LocalStorage
		| CachedKeysDataPropertyLegacy.SessionStorage,
): Promise<PreferenceCategory | null> => {
	const storageControlsData = await loadStorageControlsData();

	if (!storageControlsData) {
		return null;
	} else {
		return getItemCategoryFromPassedCache(itemKey, storageControlsData, cachedKeysDataProperty);
	}
};

/**
 * Given an item key (WebStorage key or cookie name), fetches preferences and categories and determines whether the key can be set
 *
 * Allows an optional allowedCallback to be passed which is invoked in the case the cookie is allowed to be set.
 * This allows synchronous parts of the code to check a item's status (e.g. the localStorage or document.cookie overrides)
 *
 */
export const isProcessingAllowedByPreferencesLegacy = async ({
	itemKey,
	allowedCallback = () => {},
	blockedCallback = () => {},
	cachedKeysDataProperty = CachedKeysDataPropertyLegacy.Cookies,
}: {
	itemKey: string;
	allowedCallback?: () => void;
	blockedCallback?: ({ itemHasCategory }: { itemHasCategory?: boolean }) => void;
	cachedKeysDataProperty?:
		| CachedKeysDataPropertyLegacy.Cookies
		| CachedKeysDataPropertyLegacy.LocalStorage
		| CachedKeysDataPropertyLegacy.SessionStorage;
}) => {
	let shouldProcessItem;

	// Skipping this because storageControlsData does not have actual web storage data
	// TODO: JELLY-808: Remove this skip once we have the cached LS/SS data in our categories.json
	const shouldSkip =
		cachedKeysDataProperty === CachedKeysDataPropertyLegacy.LocalStorage ||
		cachedKeysDataProperty === CachedKeysDataPropertyLegacy.SessionStorage;

	// TODO: Remove this check when categories.json returns the proper keys
	const itemCategory = shouldSkip
		? PreferenceCategory.StrictlyNecessary
		: await getItemCategoryLegacy(itemKey, cachedKeysDataProperty);

	if (itemCategory === PreferenceCategory.StrictlyNecessary) {
		shouldProcessItem = true;
	} else {
		const preferences = await getPreferences();
		let hasPreferencesForCategory = false;

		if (!!preferences) {
			if (!!itemCategory) {
				hasPreferencesForCategory = preferences[itemCategory];
			}
		}
		shouldProcessItem = hasPreferencesForCategory;
	}
	if (shouldProcessItem) {
		allowedCallback?.();
	} else {
		blockedCallback?.({ itemHasCategory: itemCategory != null });
	}
	return shouldProcessItem;
};
