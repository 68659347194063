/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::2c6d64008857e0b7114c32708b43483a>>
 * @codegenId #tabs
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tabs
 * @codegenParams { "props": { "id": true, "children": { "value": "props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))", "comment": "Filter out line spacing strings as Tabs should only have TabList & TabPanel children" }, "defaultSelected": true, "onChange": true, "selected": true, "shouldUnmountTabPanelOnChange": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::d14a7503f424a1271c00e3d090b07d73>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::727ed3a1bae137c6301da5445af46b85>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/context.tsx <<SignedSource::70ea6ece9135134cc7ab63208ad212f0>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/styles.tsx <<SignedSource::8945f585ee9624a8c31c58ee72ac05b6>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/utils.tsx <<SignedSource::9a6e8596f4f58e54324d784797ffbf6a>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tabs.tsx <<SignedSource::9486c1097402fbd037e6fbbfff4b21be>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTabs from '@atlaskit/tabs';

type PlatformTabsProps = React.ComponentProps<typeof PlatformTabs>;

export type TabsProps = Pick<
  PlatformTabsProps,
  'id' | 'children' | 'defaultSelected' | 'onChange' | 'selected' | 'shouldUnmountTabPanelOnChange' | 'testId'
>;

export const Tabs = (props: Parameters<RenderFn>[0]) => {
  const {
    id,
    defaultSelected,
    onChange,
    selected,
    shouldUnmountTabPanelOnChange,
    testId,
  } = props.forgeDoc.props as TabsProps;
  return (
    <PlatformTabs
      id={id}
      // Filter out line spacing strings as Tabs should only have TabList & TabPanel children
      children={props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))}
      defaultSelected={defaultSelected}
      onChange={onChange}
      selected={selected}
      shouldUnmountTabPanelOnChange={shouldUnmountTabPanelOnChange}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */