import React from 'react';

import { WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';

export function SSRForceLayout() {
	const script =
		`window.performance.mark("${WATERFALL_INITIAL_MEASURES.HTML_FORCE_LAYOUT}.start");` +
		// Accessing clientHeight causes the browser to perform a synchronous layout recalculation.
		// While this doesn't directly trigger a "paint" operation, it increases the likelihood of one
		// occurring shortly afterward. This means it is less likely that users will see a partially
		// painted screen while the browser processes imported JavaScript modules.
		`window.document.documentElement.clientHeight;` +
		`window.performance.mark("${WATERFALL_INITIAL_MEASURES.HTML_FORCE_LAYOUT}.end")`;

	const nonceValue = window.__SSR_NONCE_VALUE__;
	return (
		<script
			{...(nonceValue !== undefined ? { nonce: nonceValue } : {})}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: script,
			}}
		/>
	);
}
