import { PostOfficeAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import { usePostOfficeScreenPlacement } from '@atlassian/post-office-context';
import { type FunctionComponent, createContext, useContext, useMemo } from 'react';

export type PlacementContext = {
	placementId?: string;
};

export const PlacementContext = createContext<PlacementContext>({
	placementId: undefined,
});

export const PlacementProvider: FunctionComponent<
	React.PropsWithChildren<{ value: PlacementContext }>
> = ({ value, children }): JSX.Element => {
	const memoizedValue = useMemo(
		() => ({
			placementId: value.placementId,
		}),
		[value.placementId],
	);

	usePostOfficeScreenPlacement(memoizedValue);

	return (
		<PlacementContext.Provider value={memoizedValue}>
			<PostOfficeAnalyticsContext data={memoizedValue}>{children}</PostOfficeAnalyticsContext>
		</PlacementContext.Provider>
	);
};

export const usePlacementContext = () => useContext(PlacementContext);
