import { type Context, type UserInfo } from '../types';

import { type CompressionRule } from './compressionRule';
import { DEFAULT_DELAY_TIMEOUT, MAX_DELAY_TIMEOUT } from './defaults';
import EventDelayQueue from './eventDelayQueue';
import { type fireDelayedEventFn, StopLowPriorityEventDelayReason } from './types';

export default class EventDelayCoordinator {
	private delayQueue: EventDelayQueue;

	private delayTimeout?: ReturnType<typeof setTimeout>;

	private delayCallback?: (reason: StopLowPriorityEventDelayReason) => void;

	private isDelaying: boolean;

	constructor(fireDelayedEvent: fireDelayedEventFn, compressors: CompressionRule[]) {
		this.isDelaying = false;

		this.delayQueue = new EventDelayQueue(fireDelayedEvent, compressors);
	}

	push = (identifier: string, builtEvent: any, context: Context, userInfo: UserInfo) => {
		this.delayQueue.push(identifier, builtEvent, context, userInfo);
	};

	startLowPriorityEventDelay = (
		timeout: number = DEFAULT_DELAY_TIMEOUT,
		callback?: (reason: StopLowPriorityEventDelayReason) => void,
	) => {
		if (Number.isNaN(timeout) || timeout <= 0) {
			throw new Error(`Invalid timeout period: ${timeout}, must be a number greater than 0`);
		}

		if (this.delayTimeout) {
			clearTimeout(this.delayTimeout);
		}

		const delayTimeoutPeriod = timeout ? Math.min(timeout, MAX_DELAY_TIMEOUT) : MAX_DELAY_TIMEOUT;

		this.delayCallback = callback;

		this.delayTimeout = setTimeout(
			() => this.stopLowPriorityEventDelay(StopLowPriorityEventDelayReason.TIMEOUT),
			delayTimeoutPeriod,
		);

		// If the queue is still flushing from a previous delay period, then we should cancel that flush
		// to stop it from negatively impacting the performance of the new critical section
		this.delayQueue.cancelFlush();

		this.isDelaying = true;
	};

	stopLowPriorityEventDelay = (
		reason: StopLowPriorityEventDelayReason = StopLowPriorityEventDelayReason.MANUAL,
	) => {
		if (this.delayTimeout) {
			clearTimeout(this.delayTimeout);
			this.delayTimeout = undefined;
		}

		this.delayQueue.startFlush();
		this.isDelaying = false;

		this.delayCallback?.(reason);
		this.delayCallback = undefined;
	};

	isDelayingLowPriorityEvents = (): boolean => this.isDelaying;
}
