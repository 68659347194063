import type { IntlShape } from 'react-intl-next';

import type { FieldDefinition } from '@atlaskit/editor-common/extensions';

import { parseBoolean } from '../utils';

import { i18n, i18nDepth, i18nExcerptType, i18nSort, i18nStyle } from './i18n';

// Mapper for "old" children macro fields to "new" configuration UI design.
export const childMacroFields = (
	name: string,
	fields: { [key: string]: FieldDefinition },
	intl: IntlShape,
	defaultValue?: any,
) => {
	let field;
	switch (name) {
		case 'page':
			field = {
				...fields['page'],
				label: intl.formatMessage(i18n.pageLabel),
				placeholder: intl.formatMessage(i18n.pagePlaceholder),
				description: '',
			};
			break;
		case 'allChildren':
			field = {
				name: 'allChildren',
				type: 'boolean',
				style: 'toggle',
				label: intl.formatMessage(i18n.allChildren),
				description: '',
				defaultValue,
				isHidden: false,
				isMultiple: false,
				isRequired: false,
			};
			break;
		case 'first':
			field = {
				...fields['first'],
				label: intl.formatMessage(i18n.firstLabel),
				description: '',
			};
			break;
		case 'all':
			field = {
				...fields['all'],
				style: 'toggle',
				defaultValue: 'false',
				label: intl.formatMessage(i18n.allLabel),
			};
			break;
		case 'depth':
			field = {
				...fields['depth'],
				type: 'enum',
				style: 'select',
				defaultValue: '1',
				isMultiple: false,
				label: intl.formatMessage(i18n.depthLabel),
				items: [
					{
						label: intl.formatMessage(i18nDepth.one),
						value: '1',
					},
					{
						label: intl.formatMessage(i18nDepth.two),
						value: '2',
					},
					{
						label: intl.formatMessage(i18nDepth.three),
						value: '3',
					},
					{
						label: intl.formatMessage(i18nDepth.four),
						value: '4',
					},
					{
						label: intl.formatMessage(i18nDepth.five),
						value: '5',
					},
					{
						label: intl.formatMessage(i18nDepth.six),
						value: '6',
					},
					{
						label: intl.formatMessage(i18nDepth.seven),
						value: '7',
					},
					{
						label: intl.formatMessage(i18nDepth.eight),
						value: '8',
					},
					{
						label: intl.formatMessage(i18nDepth.nine),
						value: '9',
					},
				],
			};
			break;
		case 'sort':
			field = {
				...fields['sort'],
				name: 'sortAndReverse',
				type: 'enum',
				style: 'select',
				label: intl.formatMessage(i18n.sortLabel),
				defaultValue: '',
				items: [
					{
						label: intl.formatMessage(i18nSort.defaultGroup),
						options: [
							{
								label: intl.formatMessage(i18nSort.defaultItem),
								value: '',
							},
							{
								label: intl.formatMessage(i18nSort.defaultReverseItem),
								value: '.true',
							},
						],
					},
					{
						label: intl.formatMessage(i18nSort.titleGroup),
						options: [
							{
								label: intl.formatMessage(i18nSort.titleItem),
								value: 'title',
							},
							{
								label: intl.formatMessage(i18nSort.titleReverseItem),
								value: 'title.true',
							},
						],
					},
					{
						label: intl.formatMessage(i18nSort.creationGroup),
						options: [
							{
								label: intl.formatMessage(i18nSort.creationItem),
								value: 'creation.true',
							},
							{
								label: intl.formatMessage(i18nSort.creationReverseItem),
								value: 'creation',
							},
						],
					},
					{
						label: intl.formatMessage(i18nSort.modifiedGroup),
						options: [
							{
								label: intl.formatMessage(i18nSort.modifiedReverseItem),
								value: 'modified.true',
							},
							{
								label: intl.formatMessage(i18nSort.modifiedItem),
								value: 'modified',
							},
						],
					},
				],
			};
			break;
		case 'style':
			field = {
				...fields['style'],
				label: intl.formatMessage(i18n.styleLabel),
				defaultValue: '',
				items: [
					{
						label: intl.formatMessage(i18nStyle.default),
						value: '',
					},
					{
						label: intl.formatMessage(i18nStyle.h1),
						value: 'h1',
					},
					{
						label: intl.formatMessage(i18nStyle.h2),
						value: 'h2',
					},
					{
						label: intl.formatMessage(i18nStyle.h3),
						value: 'h3',
					},
					{
						label: intl.formatMessage(i18nStyle.h4),
						value: 'h4',
					},
					{
						label: intl.formatMessage(i18nStyle.h5),
						value: 'h5',
					},
					{
						label: intl.formatMessage(i18nStyle.h6),
						value: 'h6',
					},
				],
			};
			break;
		case 'excerptType':
			field = {
				...fields['excerptType'],
				label: intl.formatMessage(i18n.excerptTypeLabel),
				description: intl.formatMessage(i18n.excerptTypeDescription),
				items: [
					{
						label: intl.formatMessage(i18nExcerptType.none),
						value: 'none',
					},
					{
						label: intl.formatMessage(i18nExcerptType.richContent),
						value: 'rich content',
					},
					{
						label: intl.formatMessage(i18nExcerptType.simple),
						value: 'simple',
					},
				],
			};
			break;
	}

	return field as FieldDefinition;
};

/**
 * If an existing macro configuration had `all = true` and `depth > 0`
 * before the UI update, set `all = false` to align with new config UI.
 * Since `depth` overrides the `all` value this results in no change to the macro.
 */
export const validateDepthFields = (all: boolean, depth: number): boolean => {
	if (all && depth > 0) {
		return false;
	}
	return all;
};

export const getBasicTabFields = (
	name: String,
	fields: { [key: string]: FieldDefinition },
	intl: IntlShape,
	currentParams?,
): FieldDefinition | Array<FieldDefinition> => {
	if (name === 'page') {
		return childMacroFields('page', fields, intl);
	}

	const result: Array<FieldDefinition> = [];
	if (name === 'first') {
		// "Max number in list" field
		// Add an FE-only toggle field `allChildren` to conditionally display the "Max # in list" (`first`) field
		// Since it's an FE-only param, dynamically set its defaultValue depending on `first` param's current value
		// Toggle is TRUE if `first` is undefined or 0, i.e. no child page limit
		const defaultValue =
			!currentParams?.macroParams?.first?.value ||
			parseInt(currentParams.macroParams.first.value) === 0
				? 'true'
				: 'false';
		result.push(childMacroFields('allChildren', fields, intl, defaultValue));

		// If param is null (i.e. on initial load), manually update its state
		if (currentParams?.macroParams && !currentParams.macroParams.allChildren) {
			currentParams.macroParams.allChildren = {
				value: defaultValue,
			};
		}

		// Only render "Max number in list" field when toggle is FALSE
		if (!parseBoolean(currentParams?.macroParams?.allChildren?.value)) {
			result.push(childMacroFields('first', fields, intl));
		}
	}

	if (name === 'all') {
		// "Show all levels" toggle
		result.push(childMacroFields('all', fields, intl));

		// Only render "Max number of levels" field when `all` toggle is FALSE
		// (or edge case described in validateDepthFields)
		if (
			!validateDepthFields(
				parseBoolean(currentParams?.macroParams?.all?.value),
				parseInt(currentParams?.macroParams?.depth?.value ?? ''),
			)
		) {
			result.push(childMacroFields('depth', fields, intl));
		}
	}

	return result;
};
