import { createHistory } from '.';
import type { BridgeConfig } from './types';

export const createBridgeApi = (
	bridge: BridgeConfig | undefined,
	args: {
		userConsentRequired: boolean;
	},
) => {
	const { userConsentRequired } = args;

	return {
		refresh: createBridgeMethod(bridge?.refresh),
		submit: createBridgeMethod(bridge?.submit),
		close: createBridgeMethod(bridge?.close),
		openModal: createBridgeMethod(bridge?.openModal, {
			requiresUserConsent: userConsentRequired,
		}),
		showFlag: createBridgeMethod(bridge?.showFlag),
		closeFlag: createBridgeMethod(bridge?.closeFlag),
		reconcile: createBridgeMethod(bridge?.reconcile),
		onError: createBridgeMethod(bridge?.onError),
		createHistory: () =>
			createHistory({
				history: bridge?.history,
				extensionBasePath: bridge?.extensionBasePath,
			}),
		changeWindowTitle: createBridgeMethod(bridge?.changeWindowTitle),
	};
};

const createBridgeMethod = (method?: Function, ...args: any[]) => {
	return async (payload?: any) => {
		if (method) {
			return method(payload, ...args) ?? true;
		}
		return false;
	};
};
