export const ADMIN_USER_ACCESS_ANALYTICS_SOURCE = 'adminUserAccess';
export const SPACE_PERMS_REPORT_POLL_INTERVAL_MS = 500;
import { InspectPermissions } from './queries/__types__/UserPermissionsQuery';
export enum Permission {
	VIEW = InspectPermissions.VIEW,
	EDIT = InspectPermissions.EDIT,
	COMMENT = InspectPermissions.COMMENT,
}
export type PermissionResult = {
	count: number;
	type: PermissionResultType;
};
export enum PermissionResultType {
	ALLOWED = 'ALLOWED',
	DENIED = 'DENIED',
	INHERITED = 'INHERITED',
	IRRELEVANT = 'IRRELEVANT',
}
