import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CustomContentOnPageComponentLoader } from '@confluence/custom-content-page/entry-points/CustomContentOnPageComponentLoader';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const CustomContentOnPageRoute = ({ params: { contentId, spaceKey, type } }: RouteMatch) => {
	return (
		<CustomContentOnPageComponentLoader
			contentId={contentId}
			spaceKey={spaceKey}
			type={type === 'undefined' ? '' : type}
		/>
	);
};

CustomContentOnPageRoute.NAVIGATION_PARAMS = ({
	name,
	params: { contentId, spaceKey },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'customContentOnPage',
			id: contentId,
		},
		pageState: {
			routeName: name,
			spaceKey,
			contentId,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
