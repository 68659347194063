import gql from 'graphql-tag';

export const EditButtonWithSpaceKeyQuery = gql`
	query EditButtonWithSpaceKeyQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			key
		}
		spaceHomepage(spaceKey: $spaceKey) {
			id
			type
			operations {
				operation
				targetType
			}
			metadata {
				frontend {
					fabricEditorSupported
					embedded
					collabService
				}
			}
			subType
			type
		}
	}
`;
