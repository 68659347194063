import { UserProfileClient } from '@atlaskit/profilecard/client';
import type { ProfileCardClientData } from '@atlaskit/profilecard/types';

import type { LozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalUserLozengeProps';
import { ExternalUserLozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalUserLozengeProps';
import { getExternalCollaboratorEntitlementFromQuery } from '@confluence/external-collab-ui';
import { getApolloClient } from '@confluence/graphql';

import { SitePermissionType } from './__types__/UserProfileClientForConfluenceQuery';
import { UserProfileClientForConfluenceQuery } from './UserProfileClientForConfluenceQuery.graphql';
import { PROFILE_CARD_QUERY_METRIC } from './perf.config';

export type ProfileCardClientDataPromise = ReturnType<UserProfileClient['makeRequest']>;

type CustomLozengeItem = LozengeProps & { key: string };

// This is a customized version of the AtlasKit ProfileCard's UserProfileClient that is extended to
// add data specific to Confluence, such as whether the user is an External Collaborator (ExCo).
// For example, since the "pf-directory" service that the ProfileCard uses to get the user data does
// not currently have ExCo data but the Confluence BE does, we need to query both services to get
// the complete set of user information that we want.  Also, in this case since the ProfileCard
// does not know about ExCo data, we must process the ExCo data into a custom Lozenge that the
// ProfileCard (v15.5.0 or greater) will accept as a prop.
export class UserProfileClientForConfluence extends UserProfileClient {
	async makeRequest(cloudId: string, userId: string): Promise<ProfileCardClientData> {
		// Note because we are only subclassing "makeRequest()", we are still using UserProfileClient's
		// default "getProfile()" function - which means we automatically get the standard
		// UserProfileClient caching of the full user profile data, including our added properties

		PROFILE_CARD_QUERY_METRIC.start();

		const UserProfileClientForConfluencePromise = getApolloClient().query({
			query: UserProfileClientForConfluenceQuery,
			variables: { userId },
		});

		// If the standard user data query fails, we want to reject the returned promise because there
		// won't be any data for the caller to make a useful ProfileCard.  But if the standard user
		// data query succeeds but External Collaboration data query fails, we want to still display
		// the ProfileCard with the standard user data (that is, we don't want to reject).  In order to
		// do this, we can't use "Promise.all()" because it will return immediately when any of its
		// requests fail.  Instead we need to wait until all of the requests complete or fail, and that
		// means using "Promise.allSettled()", which has a much more complicated return object.
		const [userProfileClientForConfluenceQueryResult, standardUserDataResult] =
			await Promise.allSettled([
				UserProfileClientForConfluencePromise,
				super.makeRequest(cloudId, userId),
			]);

		if (standardUserDataResult.status === 'rejected') {
			PROFILE_CARD_QUERY_METRIC.stop({
				customData: {
					success: false,
				},
			});
			return Promise.reject(standardUserDataResult.reason);
		}

		// If the External Collaboration query was successful and External Collaboration is entitled
		// and this user is an External user, then add our custom Lozenge to the standard user profile
		// data
		const customLozenges: CustomLozengeItem[] = [];
		if (userProfileClientForConfluenceQueryResult.status === 'fulfilled') {
			const confluenceUserResponse = userProfileClientForConfluenceQueryResult.value.data;
			if (
				getExternalCollaboratorEntitlementFromQuery(confluenceUserResponse) &&
				confluenceUserResponse?.user?.confluence?.permissionType === SitePermissionType.EXTERNAL
			) {
				customLozenges.push({
					...ExternalUserLozengeProps,
					key: 'ExternalUserLozenge',
				});
			}
		}

		PROFILE_CARD_QUERY_METRIC.stop({
			customData: {
				success: true,
			},
		});
		return {
			...standardUserDataResult.value,
			customLozenges,
		};
	}
}
