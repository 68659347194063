import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { TemplateEditorLoader as NextTemplateEditor } from '@confluence/template-editor';

import { ClassicEditorContextProviderLoader } from '../../ClassicEditorContextProvider';

function getId(params: Record<string, string>) {
	const { contentId, pluginKey, moduleKey } = params;
	return !contentId && moduleKey ? `${pluginKey}:${moduleKey}` : contentId;
}

export const TemplateEditorRoute = ({ params }: RouteMatch) => {
	const id = getId(params);
	const { spaceKey } = params;

	return (
		<ClassicEditorContextProviderLoader>
			<NextTemplateEditor templateId={id} spaceKey={spaceKey} />
		</ClassicEditorContextProviderLoader>
	);
};

TemplateEditorRoute.NAVIGATION_PARAMS = ({ params }: RouteMatch) => {
	const id = getId(params);
	const { spaceKey } = params;

	return {
		Screen: {
			screenEvent: {
				name: 'editTemplatePageScreen',
				id: id || '',
			},
			pageState: {
				contentId: id,
				spaceKey,
			},
		},
		MainLayout: false,
		BannerContext: { includeTopNav: false },
	};
};
