import { HOME, ADVANCED_SEARCH, SPACE_OVERVIEW } from '@confluence/named-routes';

export const getIsEmbeddedConfluence = (match: any) =>
	!!(match?.query.embedded || match?.query.parentProduct);

export const hasEmbeddedAllowedFeatures = (feature: string, match: any) =>
	match?.query.initialAllowedFeatures?.indexOf(feature) ||
	match?.query.initialAllowedFeatures === 'all';

export const getUsername = (match: any) => {
	const username = match?.query.username;
	if (!username) {
		return '';
	}
	if (Array.isArray(username)) {
		return username[0];
	}
	return username;
};

export const shouldPreloadNavigationForTransition = (previousMatch: any) =>
	previousMatch?.name === HOME.name ||
	previousMatch?.name === ADVANCED_SEARCH.name ||
	previousMatch?.name === SPACE_OVERVIEW.name;
