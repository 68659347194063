/* tslint:disable */
/* eslint-disable */
import { globalMediaEventEmitter } from '@atlaskit/media-client';

const requests: Map<string, Promise<any>> = new Map();
const setCacheWithTimeout = (fileId, req): void =>
	requests.set(fileId, req) && void setTimeout(() => requests.delete(fileId), 3000);

const globalMediaViewedListener = (options) => {
	// To avoid case when user opens media-picker (and we don't want to call BE API after that)
	if (!options.isUserCollection) {
		// Sometimes globalEventMediaEmitter will invoke the globalMediaViewedListener callback
		// multiple times for a single `media-viewed` event. For this reason we return a cached
		// request promise if one exists
		const priorRequestForTheSameFileID = requests.get(options?.fileId);

		return (
			priorRequestForTheSameFileID ||
			(() => {
				const mediaViewedReq = fetch('/wiki/rest/internal/1.0/eventnotifier/notify', {
					method: 'POST',
					body: JSON.stringify({
						attachmentViewed: {
							fileStoreId: options.fileId,
						},
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				});

				setCacheWithTimeout(options.fileId, mediaViewedReq);

				return mediaViewedReq;
			})()
		);
	}
};

let mediaViewedListenerRegistered = false;

export const registerGlobalMediaViewedListener = () => {
	if (!mediaViewedListenerRegistered) {
		globalMediaEventEmitter.on('media-viewed', globalMediaViewedListener);
		mediaViewedListenerRegistered = true;
	}
};

export const removeGlobalMediaViewedListener = () => {
	globalMediaEventEmitter.off('media-viewed', globalMediaViewedListener);
	mediaViewedListenerRegistered = false;
};
