import { query } from '@confluence/query-preloader-tools';

import { preloadOptions } from '../Options/preloadOptions';

import { getDraftsQueryOptions } from './draftsQueryHelpers';

export const preloadDrafts = ({ isLoggedIn, isSpaceAliasFFEnabled }) => {
	return Promise.all([
		query(getDraftsQueryOptions({ isSpaceAliasFFEnabled })),
		preloadOptions({ isLoggedIn }),
	]);
};
