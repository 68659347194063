import gql from 'graphql-tag';

export const InlineImageQuery = gql`
	query InlineImageQuery($contentId: ID) {
		content(id: $contentId) {
			nodes {
				id
				body {
					atlas_doc_format {
						mediaToken {
							token
						}
					}
				}
			}
		}
		mediaConfiguration {
			clientId
			fileStoreUrl
		}
	}
`;
