export type ModalWidths = 'small' | 'medium' | 'large' | 'xlarge' | 'max';

export const getAkModalWidth = (modalSize: ModalWidths) => {
	switch (modalSize) {
		case 'xlarge':
			return 'x-large';
		case 'max':
			return '100%';
		default:
			return modalSize;
	}
};
