import { LoadableAfterPaint, LoadablePaint } from '@confluence/loadable';

export const LinkCardsLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LinkCards" */ './LinkCards')).LinkCards,
});

export const LazyLinkCardsLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LinkCards" */ './LinkCards')).LinkCards,
});
