import { useMemo, useCallback } from 'react';

import type { Modifiers, NavigationPolicy } from '@atlassian/embedded-confluence/navigationPolicy';
import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { isHashedOnlyURL } from '@confluence/route-manager-utils';

import type { RoutePolicy } from '../RoutePolicy';

import { useShimUrl } from './useShimUrl';

export function useRoutePolicy(navigationPolicy: NavigationPolicy | undefined) {
	const isEmbeddedConfluence = isEmbeddedConfluence_DO_NOT_USE();
	const { shimUrl } = useShimUrl(navigationPolicy);

	const getEmbeddedConfluenceRoutePolicy = useCallback(
		(): RoutePolicy => ({
			push({ match, matchSupportedRoute, open, push: defaultPush }, url, forceReload) {
				// When navigate to an url with anchor only e.g. `#test`, the navigation doesn't need to continue as SPA transition,
				// Hence, there is no need to continue the navigation using `push` and potentially execute parent product's navigate method.
				// In this case, we exit `routePolicy.push` earlier and use `open` (window.open) to load the url in the exisitng browsing context
				if (isHashedOnlyURL(url)) {
					open(url, '_self');
					return;
				}

				const _currRouteName = match?.name;
				const _currRouteContentId = match?.params.contentId;
				const _destinationRoute = matchSupportedRoute(url);
				const _dstRouteName = _destinationRoute?.name;
				const _dstRouteContentId = _destinationRoute?.params.contentId;
				const _dstRouteSpaceKey = _destinationRoute?.params.spaceKey;

				/**
				 * Transition within the same window if the target route is navigating to the same content
				 * This can ensure link to anchors in the page can still happen within the same window, instead of opening a new tab
				 **/
				const modifiers: Modifiers = {
					routeName: toEPRouteName(_dstRouteName),
					contentId: _dstRouteContentId,
					spaceKey: _dstRouteSpaceKey,
					target: this.willPushInSelf(
						_currRouteName,
						_currRouteContentId,
						_dstRouteName,
						_dstRouteContentId,
					)
						? '_self'
						: '_blank',
				};

				const defaultNavigate = (url: string, { target }: Modifiers) => {
					// We double check the url here in case parent product modify the `url` in `navigate` function and provided the hashed-only url to `defaultNavigate`
					if (isHashedOnlyURL(url)) {
						open(url, '_self');
						return;
					}

					const destRoute = matchSupportedRoute(url);
					const willPushInSelf = this.willPushInSelf(
						_currRouteName,
						_currRouteContentId,
						destRoute?.name,
						destRoute?.params.contentId,
					);

					// When navigate to an url with anchor and destination is under the same route for same content,
					// EP's default navigtation should simply follow browser's navigation instead of going to `defaultPush` and execute `history.push`.
					// In this case, we use `open` (window.open) to load in the exisitng browsing context
					if (willPushInSelf && Boolean(destRoute?.hash)) {
						open(destRoute?.hash, '_self');
						return;
					}

					if (target === '_self') {
						defaultPush(url, forceReload);
					} else {
						open(url, target);
					}
				};

				/**
				 * defaultNavigate can be invoked by the embedding product because it's the 3rd argument to
				 * navigationPolicy's navigate. If embedding product tells EP to navigate to a URL in a new
				 * tab, EP should not shim (rebase) it since the embedding product has already had a chance
				 * and they chose not to. On the other hand, if the embedding product does not tell EP to
				 * nagivate, EP can shim the url only when opening a new tab (some links might not have been
				 * shimmed, need to shim all links supposed to be shimmed as the last defense).
				 **/
				return navigationPolicy?.navigate
					? navigationPolicy?.navigate(url, modifiers, defaultNavigate)
					: defaultNavigate(modifiers.target === '_self' ? url : shimUrl(url), modifiers);
			},

			willPushInSelf(currRouteName, currRouteContentId, dstRouteName, dstRouteContentId) {
				const isEPRoute = !!toEPRouteName(dstRouteName);
				const isSameRoute =
					currRouteName === dstRouteName && currRouteContentId === dstRouteContentId;

				return isEPRoute && isSameRoute;
			},
		}),
		[shimUrl, navigationPolicy],
	);

	return useMemo(
		() => (isEmbeddedConfluence ? getEmbeddedConfluenceRoutePolicy() : undefined),
		[isEmbeddedConfluence, getEmbeddedConfluenceRoutePolicy],
	);
}

function toEPRouteName(routeName: string | undefined): Modifiers['routeName'] {
	switch (routeName) {
		case 'VIEW_PAGE':
		case 'EDIT_PAGE_EMBED':
			return routeName;
		default:
			return undefined;
	}
}
