import { getLogger } from '@confluence/logger';
import { SSRMeasures } from '@confluence/action-measures';

import { constructCompatibleRequestInit } from './constructCompatibleRequestInit';
import { BadStatusError } from './BadStatusError';
import { NoNetworkError } from './NoNetworkError';
import { RateLimitedError } from './RateLimitedError';
import { appendHeader } from './appendHeader';
import { getHeaderValue } from './getHeaderValue';
import { X_ATL_EXPERIENCE } from './atlExpHeader';

export type Fetch = typeof fetch;

type CfetchInit = Parameters<Fetch>[1] & {
	ignoreNoNetworkError?: boolean;
};

export type Cfetch = ((resource: Parameters<Fetch>[0], init?: CfetchInit) => ReturnType<Fetch>) & {
	subscribe: (subscriber: CfetchSubscriber) => () => void;
};

export type CfetchSubscriber = (response?: Response, error?: Error) => void;

/**
 * A [Fetch API](https://fetch.spec.whatwg.org) implementation which wraps
 * `fetch` to throw well-known Confluence Cloud `Error` types such as
 * `NoNetworkError` and `BadStatusError`.
 */
export const cfetch: Cfetch = async (resource: Parameters<Cfetch>[0], init: CfetchInit = {}) => {
	if (typeof window !== 'undefined' && window['GLOBAL_FALLBACK_ATL_EXP']) {
		if (resource instanceof Request && resource.url.startsWith('/cgraphql')) {
			resource.headers.append(
				X_ATL_EXPERIENCE,
				resource.headers.get(X_ATL_EXPERIENCE) || window['GLOBAL_FALLBACK_ATL_EXP'],
			);
		} else if (String(resource).startsWith('/cgraphql')) {
			init.headers = init?.headers || {};
			// For requests sending outside of exp tracker scope fallback to URL based exp name
			appendHeader(
				init.headers,
				X_ATL_EXPERIENCE,
				getHeaderValue(init.headers, X_ATL_EXPERIENCE) || window['GLOBAL_FALLBACK_ATL_EXP'],
			);
		}
	}

	// eslint-disable-next-line prefer-const
	let { ignoreNoNetworkError, requestInit } = decomposeInit(init);

	// XXX Default RequestInit's credentials to "include" but not always: if the
	// caller provided a Request as resource and no init, then us adding a
	// RequestInit can break the caller.
	if (!resource || typeof resource === 'string' || !(resource as Request).credentials) {
		requestInit = { credentials: 'include', ...requestInit };
	}

	let response: Response;

	try {
		if (process.env.REACT_SSR) {
			SSRMeasures.fetchCountIncr();
		}
		// eslint-disable-next-line check-credentials-option/has-credentials-option, no-restricted-syntax
		response = await fetch(resource, requestInit);
	} catch (e) {
		// TODO: Temporary fix for Tesseract runtime bug.
		// In React SSR mode. When React is finished rendering all the in-flight requests will be cancelled.
		// This causing runtime to crash.
		// See more: https://atlassian.slack.com/archives/CKVRLC9QU/p1589179628028100
		if (process.env.REACT_SSR) {
			if (e.name === 'AbortError') {
				return new Promise(() => {});
			}
		}

		// According to the fetch spec, a network failure results in a Promise
		// rejected with a TypeError. Any Response at all will resolve the Promise,
		// with the status property revealing the HTTP status code.
		const error = new NoNetworkError(`Network failure: ${e}`, { cause: e });
		error.ignore = ignoreNoNetworkError;

		error.ignore || emit(/* response */ undefined, error);
		throw error;
	}

	// If we don't get a 2xx Response, we'll consider that an error, and we'll
	// throw an exception. If we don't, we risk developers not checking the
	// Response status, running parsing logic such as parsing JSON, and failing
	// on non-2xx HTTP status code with weird errors like:
	// * Unexpected end of JSON input
	// * Unexpected token < in JSON at position 0
	// * JSON.parse: unexpected end of data at line 1 column 1 of the JSON data
	// * etc.
	//
	// By using our own Error subclass, we can prevent such unintended running of
	// parsing logic and we allow the product easily recognize the error and apply
	// product-wide processing.
	if (!response.ok) {
		let error;
		if (response.status === 429) {
			error = new RateLimitedError(resource.toString(), response);
		} else {
			error = new BadStatusError(`Received status ${response.status}`, response);
		}

		emit(response, error);
		throw error;
	}

	emit(response, /* error */ undefined);
	return response;
};

function decomposeInit(init: CfetchInit = {}) {
	const { ignoreNoNetworkError, ...requestInit } = init;

	return {
		ignoreNoNetworkError,
		requestInit: Object.getOwnPropertyNames(requestInit).length
			? constructCompatibleRequestInit(requestInit)
			: undefined,
	};
}

const logger = getLogger(cfetch);
let subscribers: CfetchSubscriber[] = [];

/**
 * Adds a specific `subscriber` to be notified whenever any `cfetch` call
 * resolves with a `Response` and/or an `Error`. If you're calling `cfetch` and
 * are interested in the outcome of that particular call only, please await the
 * resolution of the `Promise` returned by `cfetch`.
 */
cfetch.subscribe = (subscriber: CfetchSubscriber) => {
	if (typeof subscriber !== 'function') {
		throw new Error('Subscriber must be a function');
	}

	subscribers.push(subscriber);
	return () => {
		subscribers = subscribers.filter((x) => x !== subscriber);
	};
};

function emit(response?: Response, error?: Error) {
	subscribers.forEach((subscriber) => {
		try {
			subscriber(response, error);
		} catch (e) {
			logger.error`Error occurred in a cfetch subscriber: ${e}`;
		}
	});
}
