import { normalize } from 'normalizr';
import { CALL_API } from '@confluence-classic/confluence-legacy-redux';

// should implement action lifecycle management inside redux?
// this makes me super uncomfortable
const calling = {};

export default () => (next) => (action) => {
	const callAPI = action[CALL_API];

	if (typeof callAPI === 'undefined') {
		return next(action);
	}

	const { apiMethod, types, options, actionOptions } = callAPI;

	const callKey = JSON.stringify({
		types,
		options,
	});

	function actionWith(data) {
		const finalAction = Object.assign({}, action, data);
		delete finalAction[CALL_API];
		return finalAction;
	}

	const [requestType, successType, failureType] = types;

	if (callKey in calling) {
		return calling[callKey];
	}

	next(actionWith({ type: requestType, ...options }));

	let promiseResolve;
	let promiseReject;

	const deferredPromise = new Promise((resolve, reject) => {
		promiseResolve = resolve;
		promiseReject = reject;
	});

	calling[callKey] = deferredPromise;

	apiMethod(options, (err, response) => {
		delete calling[callKey];

		if (err) {
			const errorResponse =
				actionOptions &&
				actionOptions.errorHandler &&
				actionOptions.errorHandler(err, response, options);
			if (!errorResponse) {
				let errorMessage;
				try {
					errorMessage = JSON.parse(err.message);
				} catch (e) {
					if (err.message && err.message.indexOf('<!DOCTYPE html>') > -1) {
						console.error(
							'A rest call returned a HTML document that could not be parsed by the rest API middleware.',
							{ failureType, options, err, response },
						);
					} else {
						console.error(`Could not parse response from rest API: ${err.message}`, {
							failureType,
							options,
							err,
							response,
						});
					}
					errorMessage = err.message;
				}

				promiseReject(err);

				return next(
					actionWith({
						type: failureType,
						message: errorMessage,
						error: err,
						options,
					}),
				);
			} else {
				response = errorResponse;
			}
		}

		if (actionOptions && actionOptions.schema) {
			const normalized = normalize(response, actionOptions.schema);
			response = { ...response, ...normalized };
		}

		promiseResolve();

		return next(
			actionWith({
				type: successType,
				response,
				...options,
			}),
		);
	});

	return deferredPromise;
};
