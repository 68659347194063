/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { lazy, useState } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl, defineMessages } from 'react-intl-next';

import {
	type ErrorPanelProps as PublicErrorPanelProps,
	type Dispatch,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { Loader } from '../../../web-runtime/loader';

const AKSectionMessage = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.section-message' */
			'@atlaskit/section-message'
		),
);

const SectionMessageAction = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.section-message' */
			'@atlaskit/section-message/section-message-action'
		),
);

const textWrapCss = css({
	whiteSpace: 'pre-wrap',
});

type ErrorPanelProps = PublicErrorPanelProps & {
	dispatch?: Dispatch;
	isReloadPage?: boolean;
};

const ErrorPanel = ({ error, dispatch, isReloadPage }: ErrorPanelProps) => {
	const { message, errorDetails, errorMessage } = error;
	const [isLoading, setIsLoading] = useState(false);
	const intl = useIntl();

	const reinitializeApp = async () => {
		if (isReloadPage) {
			window.location.reload();
		}

		setIsLoading(true);
		try {
			if (dispatch) {
				await dispatch({ type: 'render', extensionData: {} });
			}
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}
	return (
		<div
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				width: '100%',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				ul: {
					paddingLeft: 0,
				},
			})}
		>
			<AKSectionMessage
				appearance="error"
				title={message}
				actions={[
					{
						key: 'refresh',
						text: intl.formatMessage(isReloadPage ? messages.reloadPage : messages.refreshApp),
						onClick: reinitializeApp,
					},
				].map(({ text, key, onClick }) => (
					<SectionMessageAction key={key} onClick={onClick}>
						{text}
					</SectionMessageAction>
				))}
			>
				{errorMessage && <p css={textWrapCss}>{errorMessage}</p>}
				{errorDetails && <pre css={textWrapCss}>{errorDetails}</pre>}
			</AKSectionMessage>
		</div>
	);
};

export default ErrorPanel;

export const ErrorPanelRenderFn: RenderFn = ({ forgeDoc, dispatch }) => {
	const { error } = forgeDoc.props as ErrorPanelProps;
	return <ErrorPanel key="error" error={error} dispatch={dispatch} />;
};

const messages = defineMessages({
	refreshApp: {
		id: 'confluence.error.panel.refresh.app',
		defaultMessage: 'Refresh app',
		description: 'This text is displayed on a button that lets the user refresh the app.',
	},
	reloadPage: {
		id: 'confluence.error.panel.reload.page',
		defaultMessage: 'Reload page',
		description: 'This text is displayed on a button that lets the user reload the page',
	},
});
