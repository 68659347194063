import {
	type EditorAnalyticsAPI,
	ACTION,
	ACTION_SUBJECT,
	EVENT_TYPE,
	type AnalyticsEventPayload,
	type ConnectedNodesAEP,
	type InitialiseFragmentMarksAEP,
	type DisconnectedSourceAEP,
	type DisconnectedTargetAEP,
	type GotConnectionsAEP,
	type UpdatedFragmentMarkNameAEP,
	type UpdatedSourceAEP,
	type UpdatedTargetAEP,
} from '@atlaskit/editor-common/analytics';
import { type Transaction } from '@atlaskit/editor-prosemirror/state';

type CommonCaptureParams = {
	tr: Transaction;
	editorAnalyticsAPI: EditorAnalyticsAPI | undefined;
};

const getPayload = (payload: any) => ({
	actionSubject: ACTION_SUBJECT.DOCUMENT,
	eventType: EVENT_TYPE.OPERATIONAL,
	tags: ['editor'],
	...payload,
});

export const captureInitialiseFragmentMarksEvent = ({
	docSize,
	count,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & InitialiseFragmentMarksAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.INITIALISED_FRAGMENT_MARK,
			attributes: {
				duration,
				docSize,
				count,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureConnectNodesEvent = ({
	docSize,
	sourceNodeType,
	targetNodeType,
	type,
	actionType,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & ConnectedNodesAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.CONNECTED_NODES,
			attributes: {
				duration,
				docSize,
				sourceNodeType,
				targetNodeType,
				type,
				actionType,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureUpdateSourceEvent = ({
	docSize,
	oldSourceNodeType,
	newSourceNodeType,
	type,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & UpdatedSourceAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.UPDATED_SOURCE,
			attributes: {
				duration,
				docSize,
				oldSourceNodeType,
				newSourceNodeType,
				type,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureDisconnectSourceEvent = ({
	docSize,
	targetNodeType,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & DisconnectedSourceAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.DISCONNECTED_SOURCE,
			attributes: {
				duration,
				docSize,
				targetNodeType,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureDisconnectTargetEvent = ({
	docSize,
	sourceNodeType,
	targetNodeType,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & DisconnectedTargetAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.DISCONNECTED_TARGET,
			attributes: {
				duration,
				docSize,
				sourceNodeType,
				targetNodeType,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureUpdateTargetEvent = ({
	docSize,
	newTargetNodeType,
	oldTargetNodeType,
	sourceNodeType,
	type,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & UpdatedTargetAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.UPDATED_TARGET,
			attributes: {
				duration,
				docSize,
				newTargetNodeType,
				oldTargetNodeType,
				sourceNodeType,
				type,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureUpdateFragmentMarkNameEvent = ({
	nodeType,
	tr,
	editorAnalyticsAPI,
}: Omit<CommonCaptureParams & UpdatedFragmentMarkNameAEP['attributes'], 'duration'>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.UPDATED_FRAGMENT_MARK_NAME,
			attributes: {
				duration,
				nodeType,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};

export const captureGetConnectionsEvent = ({
	count,
	docSize,
	tr,
	editorAnalyticsAPI,
}: Omit<
	CommonCaptureParams & CommonCaptureParams & GotConnectionsAEP['attributes'],
	'duration'
>) => {
	return (duration: number) => {
		const payload: AnalyticsEventPayload = getPayload({
			action: ACTION.GOT_CONNECTIONS,
			attributes: {
				docSize,
				duration,
				count,
			},
		});

		editorAnalyticsAPI?.attachAnalyticsEvent(payload)(tr);
	};
};
