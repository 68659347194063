import gql from 'graphql-tag';

export const GetProductsTeamAndSpaceQuery = gql`
	query GetProductsTeamAndSpaceQuery {
		tenantContext {
			licensedProducts {
				licenseStatus
				productKey
			}
		}
		user {
			id
			team
			confluence {
				hasPersonalSpace
				space {
					id
					key
				}
			}
		}
	}
`;
