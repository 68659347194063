import gql from 'graphql-tag';

import type { Option } from '@atlaskit/editor-common/extensions';

import { getApolloClient } from '@confluence/graphql';

import type { LegacyMacroManifest } from '../extensionTypes';

import { toArray } from './utils';

const spaceSearchQuery =
	// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming -- Read https://go/connie-relay-migration-fyi
	gql`
		query SpaceSearch($cql: String!) {
			search(cql: $cql, first: 25) {
				edges {
					node {
						entityType
						title
						space {
							key
							icon {
								path
							}
						}
					}
				}
			}
		}
	`;

const search = async (cql: string) => {
	const client = getApolloClient();

	const res = await client.query({
		query: spaceSearchQuery,
		variables: { cql },
	});

	return res.data.search.edges.map((edge) => ({
		label: edge.node.title,
		value: edge.node.space.key,
		icon: `/wiki${edge.node.space.icon.path}`,
	}));
};

const quoteString = (str: string) => `"${str}"`;

export const spacesInCqlClause = (spaces: string[]) =>
	`space.key IN (${spaces.map(quoteString).join(',')})`;

const searchByTitleOrSpaceKey = (searchTerm: string, selectedValues: string[] = []) =>
	search(
		`type = space AND (space.title ~ "${searchTerm}*" OR ${spacesInCqlClause(
			selectedValues.concat(searchTerm),
		)})`,
	);

export const buildResolver =
	(supportsCurrentSpace: boolean) =>
	async (searchTerm?: string, selectedValue?: string | string[]) => {
		const selectedValues = toArray(selectedValue);
		const searchers: Array<Promise<Option[]>> = [];

		if (searchTerm) {
			searchers.push(searchByTitleOrSpaceKey(searchTerm, selectedValues));
		} else {
			if (supportsCurrentSpace) {
				searchers.push(Promise.resolve([{ label: 'Current space', value: 'currentSpace()' }]));
			}

			const conditionalCql = selectedValues.length
				? ` OR ${spacesInCqlClause(selectedValues)}`
				: '';

			searchers.push(
				search(`type = space AND (space in recentlyViewedSpaces(4) ${conditionalCql})`),
			);
		}

		return (await Promise.all(searchers)).flat();
	};

export const spacekeyResolver = (macro: LegacyMacroManifest) => {
	// create-from-template doesn't support CQL-style
	// `currentSpace()` values, so don't show that menu option
	//
	// likewise for pagetree
	if (['create-from-template', 'pagetree'].indexOf(macro.macroName) > -1) {
		return buildResolver(false);
	} else {
		return buildResolver(true);
	}
};
