/* eslint react/prop-types:0 */
import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import {
	ADMIN_NOTIFICATIONS_ANALYTICS_SOURCE,
	LazyNotificationsSiteSettingsPageLoader,
} from '@confluence/notifications';

export const AdminNotificationsRoute = () => <LazyNotificationsSiteSettingsPageLoader />;

AdminNotificationsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_NOTIFICATIONS_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
