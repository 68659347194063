/* eslint-disable no-console */
import type { MultiBodiedExtensionActions } from '@atlaskit/editor-common/extensions';

import { requireLegacyWRM } from '@confluence/wrm';
import type { Logger } from '@confluence/logger';

type ConnectHost = Record<string, any>;
type ConnectContextArg = {
	_context: {
		extension?: { options?: { productContext?: { ['macro.id']: string } } };
	};
};
type ModuleFunction<INPUT extends any[], OUTPUT> = (
	...args: [...INPUT, ConnectContextArg]
) => Promise<OUTPUT> | void;
type ModuleFunctionWithExtraParams<INPUT extends any[], OUTPUT> = ModuleFunction<INPUT, OUTPUT> & {
	returnsPromise: true;
};

type MacroParams = Record<any, any>;
interface MacroACJSAPI {
	addBody: ModuleFunctionWithExtraParams<[], boolean>;
	removeBody: ModuleFunctionWithExtraParams<[number], boolean>;
	showBody: ModuleFunctionWithExtraParams<[{ frame: number; body: number }], boolean>;
	getBodyCount: ModuleFunctionWithExtraParams<[], number>;
	getParameters: ModuleFunctionWithExtraParams<[], MacroParams>;
	updateParameters: ModuleFunctionWithExtraParams<[MacroParams], boolean>;
}

export class ConnectMacroModule {
	private static macroName: string = 'macro';
	private static macroActions: MacroACJSAPI | null = null;
	private static macrosCache: Map<
		string,
		{
			actions: MultiBodiedExtensionActions;
			parameters: string;
			readonly: boolean;
		}
	> = new Map();

	static init(hostAPI: ConnectHost, logger: Logger): void {
		if (this.macroActions === null) {
			const getMacroId = (arg: ConnectContextArg) => {
				const macroId = arg?._context?.extension?.options?.productContext?.['macro.id'];

				if (!macroId) {
					throw new Error('No macro ID found in the call.');
				}

				return macroId;
			};

			const getEditorAction = <T extends keyof MultiBodiedExtensionActions>(
				methodName: T,
				macroId: string,
			) => {
				const cachedMacro = this.macrosCache.get(macroId);

				if (typeof cachedMacro?.actions?.[methodName] !== 'function') {
					throw new Error(
						'Failed to execute this method. Please ensure that you are calling this method from within a multi-body extension inside your Connect application.',
					);
				}

				return cachedMacro.actions[methodName];
			};

			const assertNotReadonly = (macroId: string): boolean => {
				const cachedMacro = this.macrosCache.get(macroId);

				if (cachedMacro && cachedMacro.readonly) {
					throw new Error('This method is not allowed in view mode');
				}

				return true;
			};

			/**
			 * Adding extra param to function ({returnPromise: true}) to let Connect know that
			 * it returns Promise
			 */
			const prepareModuleFunction = <INPUT extends any[], OUTPUT>(
				moduleFn: ModuleFunction<INPUT, OUTPUT>,
			): ModuleFunctionWithExtraParams<INPUT, OUTPUT> => {
				return Object.assign(moduleFn, {
					returnsPromise: true as const,
				});
			};

			this.macroActions = {
				addBody: prepareModuleFunction((...args) => {
					try {
						const context = args[args.length - 1];
						const macroId = getMacroId(context);
						assertNotReadonly(macroId);
						const addChild = getEditorAction('addChild', macroId);
						return Promise.resolve(addChild());
					} catch (e) {
						logger.warn(e.toString());
						return Promise.reject(e);
					}
				}),

				removeBody: prepareModuleFunction((...args) => {
					try {
						if (args.length !== 2 || typeof args[0] !== 'number') {
							throw new Error('The removeBody method expects an index number as its argument.');
						}

						const [index, context] = args;
						const macroId = getMacroId(context);
						assertNotReadonly(macroId);
						const removeChild = getEditorAction('removeChild', macroId);
						return Promise.resolve(removeChild(index));
					} catch (e) {
						logger.warn(e.toString());
						return Promise.reject(e);
					}
				}),

				showBody: prepareModuleFunction((...args) => {
					try {
						if (
							args.length !== 2 ||
							typeof args[0]?.body !== 'number' ||
							typeof args[0]?.frame !== 'number'
						) {
							throw new Error(
								'The showBody method expects an object with body and frame properties as its argument.',
							);
						}

						const [indexes, context] = args;
						const macroId = getMacroId(context);
						const changeActive = getEditorAction('changeActive', macroId);
						return Promise.resolve(changeActive(indexes.body));
					} catch (e) {
						logger.warn(e.toString());
						return Promise.reject(e);
					}
				}),

				getBodyCount: prepareModuleFunction((...args) => {
					try {
						const context = args[args.length - 1];
						const macroId = getMacroId(context);
						const getChildrenCount = getEditorAction('getChildrenCount', macroId);
						return Promise.resolve(getChildrenCount());
					} catch (e) {
						logger.warn(e.toString());
						return Promise.reject(e);
					}
				}),

				getParameters: prepareModuleFunction((...args) => {
					try {
						const context = args[args.length - 1];
						const macroId = getMacroId(context);
						const cachedMacro = this.macrosCache.get(macroId);

						if (!cachedMacro) {
							throw new Error(
								'Failed to execute this method. Please ensure that you are calling this method from within a multi-body extension inside your Connect application.',
							);
						}

						return Promise.resolve(JSON.parse(cachedMacro.parameters));
					} catch (e) {
						logger.warn(e.toString());
						return Promise.reject(e);
					}
				}),

				updateParameters: prepareModuleFunction((...args) => {
					try {
						if (args.length !== 2 || !args[0] || typeof args[0] !== 'object') {
							throw new Error(
								'The updateParameters method expects an object with macro parameters as its argument.',
							);
						}

						const [parameters, context] = args;
						const macroId = getMacroId(context);
						assertNotReadonly(macroId);
						const updateParameters = getEditorAction('updateParameters', macroId);
						return Promise.resolve(updateParameters(parameters));
					} catch (e) {
						logger.warn(e.toString());
						return Promise.reject(e);
					}
				}),
			};
		}

		if (!hostAPI.isModuleDefined(this.macroName)) {
			hostAPI.defineModule(this.macroName, this.macroActions);
		}
	}

	static register(
		macroId: string,
		macroParams: string,
		editorActions: MultiBodiedExtensionActions,
		readonly: boolean,
	): void {
		if (!this.macrosCache.has(macroId)) {
			this.macrosCache.set(macroId, {
				actions: editorActions,
				parameters: macroParams,
				readonly,
			});
		}
	}

	static deregister(macroId: string): void {
		if (this.macrosCache.has(macroId)) {
			this.macrosCache.delete(macroId);
		}
	}

	static cleanCache(): void {
		this.macrosCache.clear();
	}
}

export const defineMacroModule = async ({ logger }: { logger: Logger }) => {
	await new Promise<void>((resolve) => {
		requireLegacyWRM(
			[
				'wr!confluence.web.resources:querystring',
				'wr!confluence.web.resources:navigator-context',
				'wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-resources-v5',
			],
			() => {
				const connectHost = (window as any)['connectHost'];
				if (!connectHost) {
					logger.error`ConnectHost is not available to init macro module`;
					resolve();
					return;
				}

				ConnectMacroModule.init(connectHost, logger);
				resolve();
			},
			() => {
				logger.error`Error loading WRM for macro module!`;
				resolve();
			},
		);
	});
};
