import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import withAnalyticsEvents from '@atlaskit/analytics-next/withAnalyticsEvents';

const sendTrackEvent = (props) => {
	const { createAnalyticsEvent } = props;

	const analyticsData = {
		action: props.action,
		actionSubject: props.actionSubject,
		attributes: props.attributes,
		containerId: props.containerId,
		containerType: props.containerType,
		objectId: props.objectId,
		objectType: props.objectType,
		source: props.source,
	};

	const analyticsEvent = createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: analyticsData,
	});
	analyticsEvent.fire();
};

function SendTrackEventComponent(props) {
	const {
		action,
		actionSubject,
		attributes,
		containerId,
		containerType,
		objectId,
		objectType,
		source,
	} = props;
	useEffect(() => {
		sendTrackEvent(props);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, actionSubject, attributes, containerId, containerType, objectId, objectType, source]);

	return null;
}

SendTrackEventComponent.propTypes = {
	action: PropTypes.string.isRequired,
	actionSubject: PropTypes.string.isRequired,
	attributes: PropTypes.object,
	containerId: PropTypes.string.isRequired,
	containerType: PropTypes.string.isRequired,
	objectId: PropTypes.string.isRequired,
	objectType: PropTypes.string.isRequired,
	source: PropTypes.string.isRequired,
	createAnalyticsEvent: PropTypes.func.isRequired,
};

SendTrackEventComponent.defaultProps = {
	attributes: {},
};

export default withAnalyticsEvents()(SendTrackEventComponent);
