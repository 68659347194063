// stateful version of DynamicTable from @atlaskit
import { type ReactElement, Fragment } from 'react';
import { elementToForgeDoc } from '../../../../utils/elementToForgeDoc';
import type { Render } from '@atlassian/forge-ui-types';
import type { RowType, RowCellType, StatefulProps } from '@atlaskit/dynamic-table/types';

type NewRowType = Pick<RowType, 'cells' | 'key' | 'isHighlighted'>;

const renderRow = (row: RowType, render: Render): NewRowType => {
  // not supporting onClick and onKeyPress to simplify API
  const { cells, onClick, onKeyPress, ...rest } = row;
  return {
    ...rest,
    cells: cells
      .map((cell: RowCellType) => {
        const preRenderedContent = cell.content !== undefined ?
          <Fragment key={cell.key}>{render(elementToForgeDoc(cell.content as ReactElement))}</Fragment> : null;
        return {
          ...cell,
          content: preRenderedContent
        };
      }
    )
  }
}

export type DynamicTableProps = Pick<
StatefulProps,
  'defaultPage' | 'defaultSortKey' | 'defaultSortOrder' | 'emptyView' | 'head' | 'highlightedRowIndex' | 'isFixedSize' | 'isLoading' | 'isRankable' | 'label' | 'loadingSpinnerSize' | 'onRankEnd' | 'onRankStart' | 'onSetPage' | 'page' | 'paginationi18n' | 'rowsPerPage' | 'sortKey' | 'sortOrder' | 'testId'
  > & {
  rows?: NewRowType[];
  caption?: string;
};

/**
 * Non working props excluded:
 * isRankingDisabled
 * onPageRowsUpdate
 * onSort
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::2477e30d21685e5d16cd44321ce97e8f>>
 * @codegenId #dynamic-table
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen dynamic-table
 * @codegenParams { "defineOwnType": true, "props": { "createAnalyticsEvent": false, "caption": true, "defaultPage": true, "defaultSortKey": true, "defaultSortOrder": true, "emptyView": {"value": "emptyView ? props.render(elementToForgeDoc(emptyView)) as ReactElement : undefined"}, "head": {"value": "head ? renderRow(head, props.render): undefined", "comment": "Rendering each cell's content as one React element"}, "highlightedRowIndex": true, "isFixedSize": true, "isLoading": true, "isRankable": true, "label": true, "loadingSpinnerSize": true, "onRankEnd": true, "onRankStart": true, "onSetPage": true, "page": true, "paginationi18n": true, "rowsPerPage": true, "rows": {"value": "rows?.map((r) => renderRow(r, props.render))", "comment": "Rendering each cell's content as one React element"}, "sortKey": true, "sortOrder": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/dynamic-table/src/types.tsx <<SignedSource::51f56388f16379bf529081b9ae629325>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::776e3f3df8ff5c06e378bdcf4d407167>>
 * @codegenDependency ../../../../../../../design-system/dynamic-table/src/internal/helpers.tsx <<SignedSource::03e44bc26756d3fb58d2bbc29f93544e>>
 * @codegenDependency ../../../../../../../design-system/dynamic-table/src/components/stateless.tsx <<SignedSource::a5b6394cfe81f681551bf5a49d2186f0>>
 * @codegenDependency ../../../../../../../design-system/dynamic-table/src/components/stateful.tsx <<SignedSource::c6709531b0918cf11b8c7dce12f9325b>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformDynamicTable from '@atlaskit/dynamic-table';

// Define the type for DynamicTable as DynamicTableProps at the top of this file

export const DynamicTable = (props: Parameters<RenderFn>[0]) => {
  const {
    caption,
    defaultPage,
    defaultSortKey,
    defaultSortOrder,
    emptyView,
    head,
    highlightedRowIndex,
    isFixedSize,
    isLoading,
    isRankable,
    label,
    loadingSpinnerSize,
    onRankEnd,
    onRankStart,
    onSetPage,
    page,
    paginationi18n,
    rowsPerPage,
    rows,
    sortKey,
    sortOrder,
    testId,
  } = props.forgeDoc.props as DynamicTableProps;
  return (
    <PlatformDynamicTable
      caption={caption}
      defaultPage={defaultPage}
      defaultSortKey={defaultSortKey}
      defaultSortOrder={defaultSortOrder}
      emptyView={emptyView ? props.render(elementToForgeDoc(emptyView)) as ReactElement : undefined}
      // Rendering each cell's content as one React element
      head={head ? renderRow(head, props.render): undefined}
      highlightedRowIndex={highlightedRowIndex}
      isFixedSize={isFixedSize}
      isLoading={isLoading}
      isRankable={isRankable}
      label={label}
      loadingSpinnerSize={loadingSpinnerSize}
      onRankEnd={onRankEnd}
      onRankStart={onRankStart}
      onSetPage={onSetPage}
      page={page}
      paginationi18n={paginationi18n}
      rowsPerPage={rowsPerPage}
      // Rendering each cell's content as one React element
      rows={rows?.map((r) => renderRow(r, props.render))}
      sortKey={sortKey}
      sortOrder={sortOrder}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */