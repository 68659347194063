//Product View IDs
export const PRODUCT_HOME_ID = 'product/home';
export const PRODUCT_ADDONS_ID = 'product/addons';

//Container View IDs
export const CONTAINER_HOME_ID = 'container/home';
export const CONTAINER_HOME_LOADING_ID = 'container/home/loading';
export const CONTAINER_BLOG_ID = 'container/blog';

//Settings View IDs
export const CONTAINER_ADMIN_HOME_ID = 'container/admin/home';
export const ADMIN_HOME_ID = 'admin/home';

export const FLAG_MESSAGE_ID = 'conf-top-nav-announce';
