import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';
import { FabricChannel } from '@atlaskit/analytics-listeners';

import { useSessionData } from '@confluence/session-data';

import type { FeatureFlags } from './helpers';
import { extendPayloadAttributes } from './helpers';

interface RendererAnalyticsListenerProps {
	objectId?: string;
	objectType?: string;
	featureFlags: FeatureFlags;
	source?: string;
	children?: React.ReactNode;
}

export const RendererAnalyticsListener: FunctionComponent<RendererAnalyticsListenerProps> = (
	props,
) => {
	const { objectId, objectType, children, source } = props;
	// setting default to development so in tests it doesn't fail due to being undefined
	const { environment } = useSessionData() || {};
	// setting default to development so in tests it doesn't fail due to being undefined
	const safeEnvironment = environment || 'DEVELOPMENT';

	const handleEvent = useCallback(
		(event: UIAnalyticsEvent) => {
			const instanceName: string | undefined = safeEnvironment;

			event.update((payload) => {
				let newPayload = extendPayloadAttributes(payload, {
					objectId,
					objectType,
					rendererSource: source,
				});

				if (instanceName) {
					newPayload = extendPayloadAttributes(newPayload, {
						instance: instanceName,
					});
				}
				return newPayload;
			});
		},
		[safeEnvironment, objectId, objectType, source],
	);

	return (
		<AnalyticsListener onEvent={handleEvent} channel={FabricChannel.editor}>
			<AnalyticsListener onEvent={handleEvent} channel={FabricChannel.media}>
				{children}
			</AnalyticsListener>
		</AnalyticsListener>
	);
};
