import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	flagTitle: {
		id: 'fabric-media-support.tasklist.error.flag.title',
		description: 'Flag title to inform user that an attempt to toggle a tasklist checkbox failed',
		defaultMessage: 'Something went wrong',
	},
	flagDescription: {
		id: 'fabric-media-support.tasklist.error.flag.description',
		description:
			'Flag description to inform user that an attempt to toggle a tasklist checkbox failed',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"An error occurred while trying to change a task's status. Please try again later.",
	},
});
