import { useEffect, useState } from 'react';

import { isStatusCodeError } from '@confluence/error-boundary';

import {
	getDraftContentAppearanceProperty,
	getPublishedContentAppearanceProperty,
} from './ContentAppearanceQuery.graphql';
import type {
	ContentAppearance,
	GraphqlAppearanceContentProperty,
	GraphqlQueryContentWithDraftAppearance,
	GraphqlQueryContentWithPublishedAppearance,
} from './types';
import { ContentAppearancePropertyKey, ContentAppearanceType } from './types';

export const getContentAppearanceFromContentProperties = (
	properties: GraphqlAppearanceContentProperty['nodes'],
	key: ContentAppearancePropertyKey,
): ContentAppearance => {
	const contentAppearanceProperty = properties?.find(
		(property) => property?.key === key && property?.version,
	);

	let appearance = ContentAppearanceType.DEFAULT;
	let version = 0;
	if (contentAppearanceProperty) {
		appearance = contentAppearanceProperty.value
			? JSON.parse(contentAppearanceProperty.value)
			: appearance;
		version = contentAppearanceProperty.version?.number || version;
	}

	return { appearance, version };
};

export const getContentAppearanceDraft = (content: GraphqlQueryContentWithDraftAppearance) => {
	return getContentAppearanceFromContentProperties(
		content.appearanceDraft?.nodes ?? null,
		ContentAppearancePropertyKey.DRAFT,
	);
};

export const getContentAppearancePublished = (
	content: GraphqlQueryContentWithPublishedAppearance,
) => {
	return getContentAppearanceFromContentProperties(
		content.appearancePublished?.nodes ?? null,
		ContentAppearancePropertyKey.PUBLISHED,
	);
};

// if the apollo client is imported in this file instead of passed in as an argument,
// it causes an obscure webpack build error
export const updateContentAppearancePropertyWithRetry = async (
	updateContentAppearanceProperty,
	params,
	apolloClient,
	collabEditProvider?,
) => {
	try {
		await updateContentAppearanceProperty(params);
		updateAppearanceForCollab(collabEditProvider, params);
		return params;
	} catch (error) {
		// If it was an HTTP 409 conflict, retry once with the latest version number + 1
		if (isStatusCodeError(error, '409')) {
			const appearanceProperty = await fetchContentAppearanceProperty(
				params.contentId,
				params.propertyKey,
				apolloClient,
				'network-only',
			);
			if (appearanceProperty.version) {
				params = prepareNewParams(params, appearanceProperty);
				await updateContentAppearanceProperty(params);
				updateAppearanceForCollab(collabEditProvider, params);
				return params;
			}
		}
		throw error;
	}
};

export const prepareNewParams = (params, appearanceProperty) => {
	params.versionNumber = appearanceProperty.version + 1;
	if (params.propertyValue !== appearanceProperty.appearance) {
		params.propertyValue =
			appearanceProperty.appearance === ContentAppearanceType.DEFAULT
				? ContentAppearanceType.FULL_WIDTH
				: ContentAppearanceType.DEFAULT;
	}
	return params;
};

export const fetchContentAppearanceProperty = async (
	contentId,
	propertyKey,
	apolloClient,
	fetchPolicy = 'cache-first',
): Promise<ContentAppearance> => {
	const query =
		propertyKey === ContentAppearancePropertyKey.PUBLISHED
			? getPublishedContentAppearanceProperty
			: getDraftContentAppearanceProperty;

	// This is dummy variable is used to force "network-only" requests to only be run as network calls.
	// A bug in Apollo sometimes makes this query return data from the cache even if the policy is "network-only"
	// See for more details: https://pug.jira-dev.com/wiki/spaces/~683304838/pages/5971970200/Apollo+Client+-+weird+refetch+behavior
	const dummy = fetchPolicy === 'network-only' ? Date.now() : undefined;

	const contentAppearanceDraftProperty = await apolloClient.query({
		query,
		variables: {
			contentId,
			status: ['current', 'draft'],
			dummy,
		},
		fetchPolicy,
		context: { single: 'true' },
	});
	const content = contentAppearanceDraftProperty.data?.content?.nodes[0];

	if (content) {
		return propertyKey === ContentAppearancePropertyKey.PUBLISHED
			? getContentAppearancePublished(content)
			: getContentAppearanceDraft(content);
	}
	return getDefaultContentAppearance();
};

export const getDefaultContentAppearance = (): ContentAppearance => {
	return { appearance: ContentAppearanceType.DEFAULT, version: 0 };
};

export const UPDATE_APPEARANCE_VIA_COLLAB_PROVIDER_KEY = 'editorAppearance';

export const updateAppearanceForCollab = (collabEditProvider, params) => {
	if (!collabEditProvider) {
		return;
	}
	// Implies NCS provider
	if (typeof collabEditProvider.setMetadata === 'function') {
		collabEditProvider.setMetadata({
			...collabEditProvider.getMetadata(),
			metadataSource: 'client',
			editorWidth: {
				value: params.propertyValue,
				version: params.versionNumber,
			},
		});
		// Otherwise, Synchrony provider
	} else {
		collabEditProvider.sendMessage({
			type: UPDATE_APPEARANCE_VIA_COLLAB_PROVIDER_KEY,
			appearance: params.propertyValue,
			version: params.versionNumber,
		});
	}
};

export const updateContentAppearancePropertyAfterOfflineMode = async (
	appearanceProperty,
	params,
	updateContentAppearanceProperty,
	collabEditProvider,
) => {
	const newParams = {
		...params,
		propertyValue: appearanceProperty.appearance,
		versionNumber: appearanceProperty.version + 1,
	};

	if (appearanceProperty.version) {
		await updateContentAppearanceProperty(newParams);
		updateAppearanceForCollab(collabEditProvider, newParams);
	}
};

export const ContentAppearanceButton = ({
	children,
	userOffline,
	params,
	apolloClient,
	updateContentAppearanceProperty,
	collabEditProvider,
}) => {
	const [shouldFetchAppearance, setShouldFetchAppearance] = useState<boolean>(false);

	const fetchAndUpdateAfterOffline = async () => {
		const appearanceProperty = await fetchContentAppearanceProperty(
			params.contentId,
			params.propertyKey,
			apolloClient,
			'network-only',
		);
		await updateContentAppearancePropertyAfterOfflineMode(
			appearanceProperty,
			params,
			updateContentAppearanceProperty,
			collabEditProvider,
		);
	};

	useEffect(() => {
		// should refetch contentAppearanceProperty after user reconnected
		setShouldFetchAppearance(!!userOffline);
	}, [userOffline]);

	if (!userOffline && shouldFetchAppearance) {
		void fetchAndUpdateAfterOffline();
	}

	return children;
};
