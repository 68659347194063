import gql from 'graphql-tag';

export const FeedTypeSelectorRefreshQuery = gql`
	query FeedTypeSelectorRefreshQuery {
		userPreferences {
			feedTab
		}
	}
`;

export const FeedTypeSelectorRefreshMutation = gql`
	mutation FeedTypeSelectorRefreshMutation($feedTab: String!) {
		updateUserPreferences(userPreferences: { feedTab: $feedTab }) {
			feedTab
		}
	}
`;
