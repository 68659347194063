import { ApolloLink } from 'apollo-link';

import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';

export const UFOLoggerLink: ApolloLink = new ApolloLink((operation, forward) => {
	const networkStart = performance.now();
	return forward(operation).map((response) => {
		const networkComplete = performance.now();
		const operationName = operation.operationName ?? 'unnamed';
		addSpanToAll('graphql', operationName, [{ name: 'network' }], networkStart, networkComplete);
		return response;
	});
});
