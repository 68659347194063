import type {
	PostOfficeEnvironmentConfig,
	PostOfficeContextValue,
} from '@atlassian/post-office-context';
import { AccessStatus, type SessionDataType } from '@confluence/session-data';

const CONFLUENCE_PRODUCT = 'confluence';

const DEFAULT_POST_OFFICE_CONTEXT = {
	product: CONFLUENCE_PRODUCT,
	locale: 'en',
};

const getWorkspaceARI = ({ cloudId, activationId }: { cloudId: string; activationId: string }) => {
	return `ari:cloud:confluence:${cloudId}:workspace/${activationId}`;
};

export type ConfluenceSessionDataFields =
	| 'accessStatus'
	| 'activationId'
	| 'cloudId'
	| 'locale'
	| 'orgId'
	| 'edition';

export const sessionToPostOfficeContext = (
	sessionData: Pick<SessionDataType, ConfluenceSessionDataFields>,
): PostOfficeContextValue => {
	const { accessStatus, activationId, cloudId, locale, orgId, edition } = sessionData;

	const isAdmin = accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;
	const workspaceAri = getWorkspaceARI({ activationId, cloudId });

	return {
		...DEFAULT_POST_OFFICE_CONTEXT,
		locale,
		orgId,
		isSiteAdmin: isAdmin,
		tenantId: cloudId,
		workspaceAri,
		workspaceId: activationId,
		subproduct: undefined,
		productEdition: edition || undefined,
	};
};

export const transformConfluenceEnvToPostOfficeEnvironment = (
	environment: SessionDataType['environment'] | null,
): PostOfficeEnvironmentConfig['env'] => {
	switch (environment) {
		case 'STAGING':
			return 'staging';
		case 'PRODUCTION':
			return 'production';
		case 'DEVELOPMENT':
			return 'local';
		default:
			return 'local';
	}
};
