import gql from 'graphql-tag';

export const MediaUploadTokenQuery = gql`
	query MediaUploadTokenQuery($contentId: ID!) {
		contentMediaSession(contentId: $contentId) {
			token {
				value
			}
			configuration {
				clientId
				fileStoreUrl
			}
			collection
		}
	}
`;
