import {
	BASE,
	WIKI,
	HOME,
	SPACE_INDEX,
	SPACE_OVERVIEW,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	WHITEBOARD_BOARD,
	VIEW_PAGE,
	EDIT_PAGE_V2,
	EDIT_BLOG_V2,
	EDIT_PAGE_EMBED,
	EXTERNAL_SHARE,
	SPACE_PAGES,
	SPACE_BLOGS,
	CONTENT_HISTORY,
	CREATE_CONTENT,
	DATABASE_VIEW,
	EMBED_VIEW,
	COMPANY_HUB,
	COMPANY_HUB_EDIT,
} from '@confluence/named-routes';
import type { RouteMatch } from '@confluence/route';

export const matchHome: (url: string) => RouteMatch | null = (url: string) =>
	BASE.match(url) || WIKI.match(url) || HOME.match(url);

export const matchViewPage: (url: string) => RouteMatch | null = (url: string) =>
	VIEW_PAGE.match(url) || matchBlogPage(url);

export const matchBlogPage: (url: string) => RouteMatch | null = (url: string) =>
	VIEW_BLOG.match(url) || VIEW_BLOG_DATE_LEGACY.match(url);

export const matchWhiteboardPage: (url: string) => RouteMatch | null = (url: string) =>
	WHITEBOARD_BOARD.match(url);

export const matchDatabasePage: (url: string) => RouteMatch | null = (url: string) =>
	DATABASE_VIEW.match(url);

export const matchEmbedPage: (url: string) => RouteMatch | null = (url: string) =>
	EMBED_VIEW.match(url);

export const matchSpaceOverview: (url: string) => RouteMatch | null = (url: string) =>
	SPACE_OVERVIEW.match(url) || SPACE_INDEX.match(url);

export const matchCompanyHub: (url: string) => RouteMatch | null = (url: string) =>
	COMPANY_HUB.match(url);

export const matchFabricEditor: (url: string) => RouteMatch | null = (url: string) =>
	EDIT_PAGE_V2.match(url) || EDIT_BLOG_V2.match(url) || COMPANY_HUB_EDIT.match(url);

export const matchEditBlog: (url: string) => RouteMatch | null = (url: string) =>
	EDIT_BLOG_V2.match(url);

export const matchExternalShare: (url: string) => RouteMatch | null = (url: string) =>
	EXTERNAL_SHARE.match(url);

export const matchSpacePages: (url: string) => RouteMatch | null = (url: string) =>
	SPACE_PAGES.match(url);

export const matchSpaceBlogs: (url: string) => RouteMatch | null = (url: string) =>
	SPACE_BLOGS.match(url);

export const matchContentHistory: (url: string) => RouteMatch | null = (url: string) =>
	CONTENT_HISTORY.match(url);

export const matchEmbeddedEditor: (url: string) => RouteMatch | null = (url: string) =>
	EDIT_PAGE_EMBED.match(url);

export const matchCreateContent: (url: string) => RouteMatch | null = (url: string) =>
	CREATE_CONTENT.match(url);
