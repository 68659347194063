import { requireLegacyWRM, loadResources } from '@confluence/wrm';
import { cfetch, BadStatusError } from '@confluence/network';

import type { MacroMetadata, WebResources, MacroRenderedOutputForSSR } from './types';

const MACRO_DEFAULT_WRM_RESOURCES = [
	'wr!confluence.web.resources:view-content',
	'wr!confluence.macros.advanced:get-more', // Adding this resource to load the Macro css
];

export const teamCalendarWRM = () => [
	// transitional and implicit dependencies
	'wrc!com.atlassian.confluence.extra.team-calendars:amd',
	'wrc!com.atlassian.confluence.extra.team-calendars:user-timezone-setup',
	// editor context
	'wrc!com.atlassian.confluence.extra.team-calendars:calendar-init-editor',
	// macro-browser (not loaded by macro browser for some reason)
	'wrc!com.atlassian.confluence.extra.team-calendars:macro-browser-web-resources',
];

export function loadMacroWebResources(
	webresources: WebResources,
	macroRenderedOutputFromSSR?: MacroRenderedOutputForSSR[] | false,
	macroOutput?: string | null,
	callback?: () => void,
	scriptContainer?: HTMLDivElement | null,
	unavailableCallback?: () => void,
): Promise<void> {
	let deps: string[] = [],
		jsTags;
	if (macroRenderedOutputFromSSR || macroOutput) {
		deps = [...MACRO_DEFAULT_WRM_RESOURCES];
	} else {
		if (!webresources) {
			return Promise.resolve();
		}

		jsTags = webresources?.tags?.js ?? null;
		const contexts =
			!jsTags || !scriptContainer
				? (webresources.contexts && webresources.contexts.map((context) => `wrc!${context}`)) || []
				: [];
		const keys =
			!jsTags || !scriptContainer
				? (webresources.keys && webresources.keys.map((key) => `wr!${key}`)) || []
				: [];

		deps = [...MACRO_DEFAULT_WRM_RESOURCES, ...contexts, ...keys];
	}

	return new Promise((resolve) => {
		requireLegacyWRM(
			deps,
			() => {
				// Callback
				if (callback) {
					callback();
				}

				if (scriptContainer && jsTags) {
					void loadResources(scriptContainer, jsTags).then(resolve);
				} else {
					resolve();
				}
			},
			() => {
				// Unavailable Callback
				// eslint-disable-next-line no-console
				console.error(`Cannot loadMacroWebResources: ${deps}`);
				if (unavailableCallback) {
					unavailableCallback();
				}
			},
		);
	});
}
export function createMetadataLoader() {
	let macrosMetadataCache: Promise<MacroMetadata[]> | null = null;
	const macrosMetadataSpaceAwareCache: Record<string, Promise<MacroMetadata[]>> = {};

	return async function metadataLoader(): Promise<MacroMetadata[]> {
		const spaceKey = window.AJS?.Meta?.get('space-key');
		if (spaceKey) {
			if (!macrosMetadataSpaceAwareCache[spaceKey]) {
				macrosMetadataSpaceAwareCache[spaceKey] = fetchMacroMetadata(spaceKey);
			}
			return macrosMetadataSpaceAwareCache[spaceKey];
		}
		if (!macrosMetadataCache) {
			macrosMetadataCache = fetchMacroMetadata();
		}
		return macrosMetadataCache;
	};
}

function fetchMacroMetadata(spaceKey?: string): Promise<MacroMetadata[]> {
	// This same request will be made again by the macro browser when resources are loaded.
	// Running it standalone means we can show the list of macros before loading the resources,
	// mitigating the perf impact caused by the macro browser.
	const onResponse = (response) => response.json();
	return cfetch(
		`/wiki/plugins/macrobrowser/browse-macros.action?detailed=false&t=12${
			spaceKey ? `&spaceKey=${spaceKey}` : ''
		}`,
	)
		.then(
			/* onFulfilled */ onResponse,
			/* onRejected */ (reason) => {
				if (reason instanceof BadStatusError) {
					return onResponse(reason.response);
				}

				throw reason;
			},
		)
		.then(({ macros }) => {
			return macros;
		});
}
