import type { FieldTransformer } from '../types';

// This transformer runs for all macros
export const parseBoolean: FieldTransformer<{ [key: string]: any }> = {
	transformBefore(params, metadata) {
		Object.entries(metadata).forEach(([key, field]) => {
			if (field?.type?.name === 'boolean' && params[key] !== undefined) {
				params[key] = params[key] === 'false' ? false : !!params[key];
			}
		});
		return params;
	},
};
