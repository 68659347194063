import gql from 'graphql-tag';

export const AdminAnnouncementBannerQuery = gql`
	query AdminAnnouncementBannerQuery {
		adminAnnouncementBanner {
			id
			isDismissible
			content
			appearance
			title
			updatedAt
		}
	}
`;
