import { parse, format } from 'url';

import window from 'window-or-global';

import { ADMIN_TEMPLATES_AND_BLUEPRINTS, SPACE_SETTINGS_TEMPLATES } from '@confluence/named-routes';

import { CONTEXT_PATH, DISPLAY_ROOT, SPACE_ROOT, MY_WORK_ROOT, DISCOVER_ROOT } from './routes';

export const getParam = (param, url) => getParams(url)[param];

export const getParams = (url) => {
	/* istanbul ignore next */
	if (typeof url === 'undefined') {
		url = window.location.href;
	}
	const parsedUrl = parse(url, true);
	return parsedUrl.query || {};
};

/**
 * Converts a url to a route by removing the first "/" and context path
 *
 * @param {String} url - the url to convert into a route
 * @returns {String} the route
 */
export const urlToRoute = (url) => {
	if (CONTEXT_PATH) {
		url = url.replace(CONTEXT_PATH, '');
	}
	return url.replace(/^\/(.*)/, '$1');
};

const PAGE_CONTENT_SLUG_REGEX = new RegExp(
	`${CONTEXT_PATH}/spaces/([^\/]+)/pages/([0-9]+)/([^?/]+)`,
);
const BLOG_CONTENT_SLUG_REGEX = new RegExp(
	`${CONTEXT_PATH}/spaces/([^\/]+)/blog/(\\d{4})/(\\d{2})/(\\d{2})/([0-9]+)/([^?/]+)`,
);

/**
 * Strips content titles from URLs and replaces them with ":contentSlug"
 * @param url - A URL from which to strip the content title (excluding domain, protocol and port).
 * @return {String} url - The sanitized URL.
 */
export const stripContentTitlesFromURL = (url) => {
	if (!url) {
		return '';
	} else if (PAGE_CONTENT_SLUG_REGEX.test(url)) {
		return url.replace(PAGE_CONTENT_SLUG_REGEX, '/spaces/$1/pages/$2/:contentSlug');
	} else if (BLOG_CONTENT_SLUG_REGEX.test(url)) {
		return url.replace(BLOG_CONTENT_SLUG_REGEX, '/spaces/$1/blog/$2/$3/$4/$5/:contentSlug');
	} else {
		return url.replace(CONTEXT_PATH, '') || '/';
	}
};

/**
 * Adds one or more query parameters to the current url
 *
 * @object params
 * @string url
 * @returns {string}
 */
export const addQueryParamsToUrl = (params, url) => {
	const parsedUrl = parse(url, true);
	delete parsedUrl.search;
	parsedUrl.query = { ...parsedUrl.query, ...params };
	return format(parsedUrl);
};

/**
 * Removes one or more query parameters from the given url
 * @param {string[]} params - names of query parameters to remove.
 * @param {string} url - the url to remove parameters from.
 * @returns {string} the url without the removed parameters.
 */
export const removeQueryParamsFromUrl = (params, url) => {
	const parsedUrl = parse(url, true);
	delete parsedUrl.search;
	params.forEach((paramName) => delete parsedUrl.query[paramName]);
	return format(parsedUrl);
};

export const getPagesRoute = (spaceKey) => getSpaceLink(spaceKey, 'pages');
export const getBlogFullRoute = (spaceKey, year, month, day, contentId, slug) =>
	getSpaceLink(spaceKey, `blog/${year}/${month}/${day}/${contentId}${slug ? `/${slug}` : ''}`);
export const getOverviewRoute = (spaceKey) => getSpaceLink(spaceKey, 'overview');
export const getQuestionsBaseRoute = (spaceKey) => getSpaceLink(spaceKey, 'questions');
export const getCalendarsRoute = (spaceKey) => getSpaceLink(spaceKey, 'calendars');
export const getSpaceToolRoute = (spaceKey) =>
	`${CONTEXT_PATH}/${SPACE_ROOT}/viewspacesummary.action?key=${encodeURIComponent(
		spaceKey,
	)}&src=spacetools`;
export const getEditPage = (spaceKey, contentType, contentId) => {
	const contentTypeRoute = contentType === 'blogpost' ? 'blog' : 'pages';
	return getSpaceLink(spaceKey, `${contentTypeRoute}/edit/${contentId}`);
};
export const getEditPageV2 = (spaceKey, contentType, contentId) => {
	const contentTypeRoute = contentType === 'blogpost' ? 'blog' : 'pages';
	return getSpaceLink(spaceKey, `${contentTypeRoute}/edit-v2/${contentId}`);
};

export const getFabricTemplateEditPage = (contentId, spaceKey) => {
	if (spaceKey) {
		return getSpaceLink(spaceKey, `templates/edit/${contentId}`);
	}

	return `${CONTEXT_PATH}/templates/edit/${contentId}`;
};

export const getViewPageRoute = (spaceKey, contentId) => `${getPagesRoute(spaceKey)}/${contentId}`;

/**
 * Build a space link. If path starts with contains context path then it will be removed
 *
 * @param spaceKey
 * @param path
 * @returns {string|*}
 */
const getSpaceLink = (spaceKey, path = '') => {
	spaceKey = encodeURIComponent(spaceKey);
	let spacePath = (typeof path === 'string' ? path : path.pathname) || '';
	if (spacePath.startsWith(CONTEXT_PATH)) {
		spacePath = spacePath.substring(CONTEXT_PATH.length);
	}
	if (spacePath.startsWith('/')) {
		spacePath = spacePath.substring(1);
	}
	const link = `${CONTEXT_PATH}/${SPACE_ROOT}/${spaceKey}${spacePath ? `/${spacePath}` : ''}`;

	if (typeof path === 'string') {
		return link;
	}

	return {
		...path,
		pathname: link,
	};
};

export const getListPageTemplateRoute = (spaceKey) =>
	SPACE_SETTINGS_TEMPLATES.toUrl({
		spaceKey,
	});

export const getTemplateListPage = (spaceKey) => {
	if (spaceKey) {
		return SPACE_SETTINGS_TEMPLATES.toUrl({
			spaceKey,
		});
	}

	return ADMIN_TEMPLATES_AND_BLUEPRINTS.toUrl();
};

export const getDefaultAdminPage = () => `${CONTEXT_PATH}/admin/configuration`;

export const isDashboardRoute = (location) => isMyWorkRoute(location) || isDiscoverRoute(location);

const myWorkMatcher = new RegExp(`${CONTEXT_PATH}/${MY_WORK_ROOT}`);
export const isMyWorkRoute = (location) => myWorkMatcher.test(location.pathname);

const discoverMatcher = new RegExp(`${CONTEXT_PATH}/${DISCOVER_ROOT}`);
export const isDiscoverRoute = (location) => discoverMatcher.test(location.pathname);

const viewPageMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/pages/[0-9]+`);
export const isViewPageRoute = (location) => viewPageMatcher.test(location.pathname);

const legacyViewPageMatcher = new RegExp(`${CONTEXT_PATH}/pages/viewpage\.action`);
export const isLegacyViewPageRoute = (location) => legacyViewPageMatcher.test(location.pathname);

const blogpostMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/blog/[0-9]+`);
export const isBlogpostRoute = (location) => blogpostMatcher.test(location.pathname);

const legacySpaceMatcher = new RegExp(`${CONTEXT_PATH}/spaces/viewspace.action`);
export const isLegacySpaceRoute = (location) => legacySpaceMatcher.test(location.pathname);

const spaceOverviewMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/overview`);
export const isSpaceOverviewRoute = (location) => spaceOverviewMatcher.test(location.pathname);

const spaceShortcutsMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/shortcuts`);
export const isSpaceShortcutsRoute = (location) => spaceShortcutsMatcher.test(location.pathname);

const spaceBlogMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/blog`);
export const isSpaceBlogRoute = (location) => spaceBlogMatcher.test(location.pathname);

const spaceQuestionMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/questions`);
export const isSpaceQuestionRoute = (location) => spaceQuestionMatcher.test(location.pathname);

const spaceLegacyCalendarMatcher = new RegExp(`${CONTEXT_PATH}/${DISPLAY_ROOT}/[^\/]+/calendar`);

export const isSpaceLegacyCalendarRoute = (location) =>
	spaceLegacyCalendarMatcher.test(location.pathname);

const spaceToolMatcher = new RegExp(`${CONTEXT_PATH}/${SPACE_ROOT}/viewspacesummary.action`);

export const isSpaceToolRoute = (location) => spaceToolMatcher.test(location.pathname);

const spaceLegacyQuestionMatcher = new RegExp(
	`${CONTEXT_PATH}/${DISPLAY_ROOT}/[^\/]+/customcontent/list/ac%3Acom.atlassian.confluence.plugins.confluence-questions%3Aquestion`,
);

export const isSpaceLegacyQuestionRoute = (location) =>
	spaceLegacyQuestionMatcher.test(location.pathname);

const spaceCalendarMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/calendars`);
export const isSpaceCalendarRoute = (location) => spaceCalendarMatcher.test(location.pathname);

const spaceAddonMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/addon`);
export const isSpaceAddonRoute = (location) => spaceAddonMatcher.test(location.pathname);

const fabricEditorMatcher = new RegExp(`${CONTEXT_PATH}/spaces/[^\/]+/(pages|blog)/edit-v2/`);
export const isFabricEditorRoute = (location) => fabricEditorMatcher.test(location.pathname);
