import {
	type ChoreographerFactory,
	ProductIds,
	choreographerFactory,
} from '@atlassian/ipm-choreographer';
import type { Env } from '@atlassian/post-office-context';
import { envType } from '@atlassiansox/analytics-web-client';

const mapEnvToAnalyticsEnv = (env: Env) => {
	switch (env) {
		case 'test':
		case 'local':
			return envType.LOCAL;
		case 'dev':
			return envType.DEV;
		case 'staging':
		case 'stg-fedm-east':
			return envType.STAGING;
		case 'production':
		case 'prod-fedm-east':
			return envType.PROD;
		default:
			return envType.DEV;
	}
};

class ChoreographerRegistry {
	private readonly product: string | undefined;
	private readonly environment: Env;

	constructor(product: string | undefined, environment: Env) {
		this.product = product;
		this.environment = environment;
	}

	public getPostOfficeChoreographerFactory(): ChoreographerFactory {
		if (window.choreographerRegistry === undefined) {
			window.choreographerRegistry = new Map();
		}
		const maybeChoreographerFactory = window.choreographerRegistry.get(ProductIds.POST_OFFICE);
		if (maybeChoreographerFactory) {
			return maybeChoreographerFactory;
		}

		const factory = choreographerFactory(
			ProductIds.POST_OFFICE,
			mapEnvToAnalyticsEnv(this.environment),
			{
				sourceProductId: this.product,
			},
		);

		window.choreographerRegistry.set(ProductIds.POST_OFFICE, factory);

		return factory;
	}
}

export default ChoreographerRegistry;

declare global {
	interface Window {
		choreographerRegistry?: Map<ProductIds, ChoreographerFactory>;
	}
}
