import type { IntlShape } from 'react-intl-next';

import type {
	Handler,
	ObjectKey,
	RecentUpdateContext,
	RecentUpdatesId,
	TaskDecisionProvider,
	TaskState,
} from '@atlaskit/task-decision/types';

import { getApolloClient } from '@confluence/graphql';
import type { FlagsStateContainer } from '@confluence/flags';

import { SetTaskStatusMutation } from './SetTaskStatusMutation.graphql';
import { i18n } from './i18n';
import type { SetTaskStatusMutation as SetTaskStatusMutationType } from './__types__/SetTaskStatusMutation';

type ToggleTaskCallback = (objectKey: ObjectKey, state: TaskState) => void;
export class ConfluenceTaskDecisionProvider implements TaskDecisionProvider {
	_toggleTaskCallback: ToggleTaskCallback | undefined;

	/* eslint-disable no-useless-constructor */
	constructor(
		public intl: IntlShape,
		public flags: FlagsStateContainer,
		public contentId: string,
		public toggleTaskCallback?: ToggleTaskCallback,
	) {
		/**
		 * eslint mistakenly thinks this is a useless constructor. It's not,
		 * because TypeScript translates the 'public' arguments to actual code that
		 * assigns them to instance variables.
		 */
		this._toggleTaskCallback = toggleTaskCallback;
	}

	toggleTask(objectKey: ObjectKey, state: TaskState) {
		if (this._toggleTaskCallback) {
			this._toggleTaskCallback(objectKey, state);

			return Promise.resolve(state);
		} else {
			/**
			 * Using objectKey.objectAri may be a better alternative to passing in
			 * contentId to the constructor. If it's reliable.
			 */
			return getApolloClient()
				.mutate({
					mutation: SetTaskStatusMutation,
					variables: {
						contentId: this.contentId,
						taskId: objectKey.localId,
						status: state === 'DONE' ? 'CHECKED' : 'UNCHECKED',
					},
				})
				.then((response: { data?: SetTaskStatusMutationType }) => {
					const success: boolean = response?.data?.setTaskStatus?.success || false;
					if (!success) this.showErrorFlag();
					return state;
				})
				.catch(
					// this method is set to return `any` as a workaround for types.
					// Given that `TaskDecisionProvider.toggleTask` does not actually permit
					// `void` to be returned, what the `catch` block should probably be doing
					// is re-throwing the error that it got (at least that might match the
					// expectations). Since doing that will result in actual behavior changes
					// which I'm hesitant to perform, TS is simply silenced by `ny`-ing the
					// return type.
					(_: Error): any => {
						/**
						 * Note: there seems to be an issue whereby clicking a checkbox twice
						 * quickly causes an error. I'm guessing something's not happy with a
						 * mutation being issued while the previous one was still in-flight.
						 * KTLO: we should probably create a queue as a workaround.
						 * I'm (mdean2) not fixing this right now because I'm up against the
						 * clock and the previous classic implementation suffered the same
						 * problem.
						 */
						this.showErrorFlag();
					},
				);
		}
	}

	private showErrorFlag() {
		void this.flags.showErrorFlag({
			title: this.intl.formatMessage(i18n.flagTitle),
			description: this.intl.formatMessage(i18n.flagDescription),
		});
	}

	// Below are stubs, implemented to pacify TypeScript, and inherited from old
	// classic implementation.

	notifyRecentUpdates(_updateContext: RecentUpdateContext) {}

	subscribe(_objectKey: ObjectKey, _handler: Handler): void {}

	unsubscribe(_objectKey: ObjectKey, _handler: Handler): void {}

	unsubscribeRecentUpdates(_id: RecentUpdatesId) {}
}
