/**
 * Recursively omits a key from any javascript type.
 *
 * @param val
 * @param keyToOmit
 *
 */
const omitDeep = (val, keyToOmit) => {
	const type = typeof val;

	if (type !== 'object' || val === null || val === undefined) {
		return val;
	}

	if (Array.isArray(val)) {
		return val.map((arrItem) => omitDeep(arrItem, keyToOmit));
	}

	if (type === 'object') {
		return Object.keys(val).reduce((newObj, key) => {
			if (key !== keyToOmit) {
				newObj[key] = omitDeep(val[key], keyToOmit);
			}
			return newObj;
		}, {});
	}

	throw new Error(`Unsupported type "${type}" confronted in omitDeep function`);
};

export default omitDeep;
