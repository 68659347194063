import { LoadablePaint } from '@confluence/loadable';

export const CustomContentView = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcCustomContentView" */ '../src/CustomContentView'
			)
		).CustomContentView,
});
