import { EP_REACT_PROPS_OBSERVABLE_OBJECT, type ObservableObject } from '../observable-object';

import type { IframeElement, IframePassThroughProps } from './IframeElementType';

export const getIframeObservable_DO_NOT_USE = <Props extends IframePassThroughProps>():
	| ObservableObject<Props>
	| undefined =>
	((window.frameElement as IframeElement)?.[EP_REACT_PROPS_OBSERVABLE_OBJECT] ||
		// @ts-ignore https://hello.atlassian.net/browse/TYPEF-946
		window[EP_REACT_PROPS_OBSERVABLE_OBJECT]) as ObservableObject<Props> | undefined;
