// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { BannerStateInfo } from './BannerStateContainer';
import { findBanner } from './BannerStateContainer';

type FloatingBannerStateContainerState = {
	floatingBanners: BannerStateInfo[];
};

export class FloatingBannerStateContainer extends Container<FloatingBannerStateContainerState> {
	state: FloatingBannerStateContainerState = {
		floatingBanners: [],
	};

	showFloating = (name: string, height: number) => {
		const index = findBanner(name, this.state.floatingBanners);
		if (index === -1) {
			void this.setState((state) => {
				const newArray = [...state.floatingBanners];
				newArray.push({ name, height });
				return { ...state, floatingBanners: newArray };
			});
		} else if (this.state.floatingBanners[index].height !== height) {
			void this.setState((state) => {
				const newArray = [...state.floatingBanners];
				newArray[index].height = height;
				return { ...state, floatingBanners: newArray };
			});
		}
	};

	hideFloating = (name: string) => {
		const index = findBanner(name, this.state.floatingBanners);
		if (index > -1) {
			void this.setState((state) => {
				const newArray = [...state.floatingBanners];
				newArray.splice(index, 1);
				return { ...state, floatingBanners: newArray };
			});
		}
	};

	getFloatingHeightBefore = (name: string) => {
		const index = findBanner(name, this.state.floatingBanners);
		if (index === -1) {
			return 0;
		}

		return this.state.floatingBanners
			.slice(0, index)
			.reduce((height: number, banner: BannerStateInfo) => {
				return height + banner.height;
			}, 0);
	};

	getFloatingHeight = () =>
		this.state.floatingBanners.reduce(
			(height: number, banner: BannerStateInfo) => height + banner.height,
			0,
		);
}
