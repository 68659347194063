/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b82a0727cca5b80b1d3537ecad92b7f1>>
 * @codegenId #required-asterisk
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen required-asterisk
 * @codegenParams { "props": {} }
 * @codegenDependency ../../../../../../../design-system/form/src/required-asterisk.tsx <<SignedSource::94d7aa27dda37d509b833e51e67a51b4>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { RequiredAsterisk as PlatformRequiredAsterisk } from '@atlaskit/form';

export const RequiredAsterisk = (props: Parameters<RenderFn>[0]) => {
  return (
    <PlatformRequiredAsterisk
    />
  );
};
/**
 * @codegenEnd
 */
