import type { ParsedUrlQuery } from './types';

/**
 * For the given "queryParams" and "paramName", returns the first value from the query string.
 *
 * If no such param exists, the return value defaults to `undefined`.
 */
export function getSingleParam(queryParams: ParsedUrlQuery, paramName: string): string | undefined {
	if (!queryParams) {
		return undefined;
	}

	const value = queryParams[paramName];

	if (Array.isArray(value)) {
		return value[0];
	}

	return value;
}

/**
 * For the given "queryParams" and "paramName", tries to convert the first value of the param
 * to an integer.
 *
 * If no such param exists, or if it does not represent a valid integer number
 * in radix=10, the return value will be `defaultValue` (which is `undefined` by default).
 */
export function getSingleParamInt(
	queryParams: ParsedUrlQuery,
	paramName: string,
): number | undefined;
export function getSingleParamInt<T>(
	queryParams: ParsedUrlQuery,
	paramName: string,
	defaultValue: T,
): number | T;
export function getSingleParamInt<T>(
	queryParams: ParsedUrlQuery,
	paramName: string,
	defaultValue?: T,
): number | T | undefined {
	const value = getSingleParam(queryParams, paramName);

	const intValue = parseInt(value || '', 10);
	if (isNaN(intValue)) {
		return defaultValue;
	}

	return intValue;
}
