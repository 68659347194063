import mean from 'lodash/mean';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import type { EditorAIAnalyticEventPayload } from '../../analytics/types';
import { addAnalytics, createUnifiedAnalyticPayload, withAnalytics } from '../../analytics/utils';
import { DiffMatchPatch } from '../../utils/diff-match-patch/diff-match-patch';
import { type DiffObject, type ParagraphChunk } from '../../utils/diff-match-patch/utils';
import { median } from '../../utils/median';

import { aiSpellingGrammarPluginKey } from './ai-spelling-grammar-plugin-key';
import {
	disableNeedSpellingAndGrammar,
	insertSuggestion,
	removeSuggestion,
	startSpellingAndGrammar,
	toggleSpellingAndGrammar,
} from './commands';
import { END_PUNC_CHARS, MIDDLE_PUNC_CHARS, START_PUNC_CHARS } from './constants';
import type { ProactiveAIBlock, ProactiveAISentence } from './states';
import { isFullStopOmittedInContainerType } from './utils';

const getCommonAnalyticsAttributes = (): Record<string, string> => {
	const isEnabled = editorExperiment('editor_ai_-_proactive_ai_model_variations', 'skip_grammar');

	const getExperimentValue = FeatureGates.getExperimentValue;
	const variation = getExperimentValue(
		'editor_ai_-_proactive_ai_model_variations',
		'cohort',
		'not-enrolled',
	);

	if (!isEnabled) {
		return {
			variation,
		};
	}
	return {
		variation: 'default',
	};
};

const getSuggestionMetadata = ({
	blockOrSentenceFromDiffObject,
	schema,
}: {
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
	schema: Schema;
}) => {
	if (!blockOrSentenceFromDiffObject) {
		return;
	}

	const metadata = {
		...blockOrSentenceFromDiffObject?.metadata,
		inlineNodeCount: blockOrSentenceFromDiffObject?.ignoredRanges.filter(
			({ type }) => type === 'inlineNode',
		).length,
		contextualFormatting: isFullStopOmittedInContainerType(
			schema,
			blockOrSentenceFromDiffObject.containerType,
		),
	};

	return metadata;
};

export const getPunctuationAttributes = (
	diffObject: DiffObject,
): { isFullStopAdded: boolean; isPunctuationChanged: boolean } => {
	const { text, originalText } = diffObject;

	if (
		!originalText ||
		!text ||
		// if it's the same length or 1 character difference between the texts
		!(originalText.length === text.length || Math.abs(originalText.length - text.length) === 1)
	) {
		return {
			isFullStopAdded: false,
			isPunctuationChanged: false,
		};
	}

	const diffs: Array<{ [0]: -1 | 0 | 1; [1]: string }> = DiffMatchPatch.diff_main(
		originalText,
		text,
		false,
	);

	switch (diffs.length) {
		case 2: {
			const [diff1, diff2] = diffs;
			// If there's only 1 addition in the diff at the end
			if (diff1[0] === 0 && diff2[0] === 1) {
				return {
					isFullStopAdded: diff2[1] === '.',
					isPunctuationChanged: END_PUNC_CHARS.includes(diff2[1]),
				};
			}
			// If there's only 1 addition in the diff at the beginning
			if (diff1[0] === 1 && diff2[0] === 0) {
				return {
					isFullStopAdded: false,
					isPunctuationChanged: START_PUNC_CHARS.includes(diff1[1]),
				};
			}
			break;
		}
		case 3: {
			const [diff1, diff2, diff3] = diffs;
			// If punctuation was added or removed in the middle
			if (diff1[0] === 0 && diff2[0] !== 0 && diff3[0] === 0) {
				return {
					isFullStopAdded: false,
					isPunctuationChanged: MIDDLE_PUNC_CHARS.includes(diff2[1]),
				};
			}
			break;
		}
		case 4: {
			const [diff1, diff2, diff3, diff4] = diffs;
			// If there's 1 replacement (deletion + addition) in the middle
			if (diff1[0] === 0 && diff2[0] === -1 && diff3[0] === 1 && diff4[0] === 0) {
				return {
					isFullStopAdded: false,
					isPunctuationChanged:
						MIDDLE_PUNC_CHARS.includes(diff2[1]) && MIDDLE_PUNC_CHARS.includes(diff3[1]),
				};
			}
			break;
		}
		default: {
			break;
		}
	}

	return {
		isFullStopAdded: false,
		isPunctuationChanged: false,
	};
};

export const insertSuggestionWithAnalytics = ({
	aiInteractionID,
	selectedDiff,
	schema,
	blockOrSentenceFromDiffObject,
}: {
	aiInteractionID: string;
	schema: Schema;
	selectedDiff: DiffObject;
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
}) =>
	withAnalytics({
		payload: {
			action: 'inserted',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				aiInteractionID,
				...getPunctuationAttributes(selectedDiff),
				...getCommonAnalyticsAttributes(),
				...getSuggestionMetadata({
					blockOrSentenceFromDiffObject,
					schema,
				}),
			},
			eventType: EVENT_TYPE.TRACK,
		},
	})(insertSuggestion(selectedDiff));

export const removeSuggestionWithAnalytics = ({
	aiInteractionID,
	schema,
	selectedDiff,
	blockOrSentenceFromDiffObject,
}: {
	aiInteractionID: string;
	schema: Schema;
	selectedDiff: DiffObject;
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
}) =>
	withAnalytics({
		payload: {
			action: 'dismissed',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				aiInteractionID,
				...getPunctuationAttributes(selectedDiff),
				...getCommonAnalyticsAttributes(),
				...getSuggestionMetadata({
					blockOrSentenceFromDiffObject,
					schema,
				}),
			},
			eventType: EVENT_TYPE.TRACK,
		},
	})(removeSuggestion(selectedDiff.originalText));

export const initiateProactiveAIWithAnalytics = (aiInteractionID: string) =>
	withAnalytics({
		payload: createUnifiedAnalyticPayload(
			'initiated',
			aiInteractionID,
			'Proactive Spelling and Grammar',
			true,
		),
	});

export const handleSpellingAndGrammarWithAnalytics = ({
	proactiveToggleCount,
	totalSuggestionsOnPage,
	dismissedWords,
	insertionCount,
	initialToggleState,
	triggeredFrom,
	operationType,
}: {
	proactiveToggleCount: number;
	totalSuggestionsOnPage: number;
	dismissedWords: number;
	insertionCount: number;
	initialToggleState: boolean;
	triggeredFrom: string;
	operationType: 'start' | 'toggle';
}) => {
	const analyticsPayload: EditorAIAnalyticEventPayload = {
		action: 'toggled',
		actionSubject: 'editorPluginAI',
		actionSubjectId: 'spellingSuggestion',
		attributes: {
			...getCommonAnalyticsAttributes(),
			toggledCount: proactiveToggleCount,
			initialToggleState,
			totalSuggestions: totalSuggestionsOnPage,
			totalAcceptedSuggestions: insertionCount,
			totalDismissedSuggestions: dismissedWords,
			triggeredFrom,
		},
		eventType: EVENT_TYPE.TRACK,
	};
	const operation = operationType === 'start' ? startSpellingAndGrammar : toggleSpellingAndGrammar;
	const operationWithAnalytics = withAnalytics({ payload: analyticsPayload })(
		operation(proactiveToggleCount),
	);
	return operationWithAnalytics;
};

const getFireAPIReceivedAnalytics = () => {
	let apiReceivedCount = 0;
	const apiReceivedSamplingRate = 10;
	let durationArray: number[] = [];
	return ({
		view,
		duration,
		totalSuggestions,
		totalAcceptedSuggestions,
		totalDismissedSuggestions,
	}: {
		view: EditorView;
		duration: number;
		totalSuggestions: number;
		totalAcceptedSuggestions: number;
		totalDismissedSuggestions: number;
	}) => {
		apiReceivedCount++;
		durationArray.push(duration);
		if (apiReceivedCount >= apiReceivedSamplingRate) {
			/**
			 * min / max will not be undefined as this function samples
			 * durations after X amount of times, and this is only calculated
			 * at the end of the sampling
			 */
			const minDuration = Math.min(...durationArray)!;
			const maxDuration = Math.max(...durationArray)!;

			const meanDuration = mean(durationArray);
			const medianDuration = median(durationArray);

			const tr = addAnalytics({
				editorState: view.state,
				tr: view.state.tr,
				payload: {
					action: 'apiReceived',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'spellingSuggestion',
					attributes: {
						...getCommonAnalyticsAttributes(),
						meanDuration,
						medianDuration,
						minDuration,
						maxDuration,
						totalSuggestions,
						totalAcceptedSuggestions,
						totalDismissedSuggestions,
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
			view.dispatch(tr);

			// reset sampling
			apiReceivedCount = 0;
			durationArray = [];
		}
	};
};

export const fireAPIReceivedAnalytics = getFireAPIReceivedAnalytics();

export const disableCheckForFailedChunksWithAnalytics = ({
	view,
	failedChunkIds,
	errors,
	reason,
	statusCode,
}: {
	view: EditorView;
	failedChunkIds: Array<ParagraphChunk['id']>;
	errors: string[];
	reason: string;
	statusCode: number;
}) =>
	disableNeedSpellingAndGrammar(failedChunkIds, (tr) => {
		const uniqueInteractionID = tr.getMeta(aiSpellingGrammarPluginKey);
		let trWithAnalytics = tr;
		errors.forEach((error) => {
			trWithAnalytics = addAnalytics({
				editorState: view.state,
				tr: trWithAnalytics,
				payload: {
					action: 'apiError',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'spellingSuggestion',
					attributes: {
						...getCommonAnalyticsAttributes(),
						error,
						reason,
						statusCode,
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
		});

		trWithAnalytics = addAnalytics({
			editorState: view.state,
			tr: trWithAnalytics,
			payload: createUnifiedAnalyticPayload(
				'error',
				uniqueInteractionID,
				'Proactive Spelling and Grammar',
				true,
				{
					aiErrorMessage: reason,
					aiErrorCode: statusCode,
				},
			),
		});

		return trWithAnalytics;
	});

export const disableCheckForPurgedChunksWithAnalytics = ({
	purgedChunkIds,
	totalParts,
	totalPurgedParts,
}: {
	purgedChunkIds: Array<ParagraphChunk['id']>;
	totalParts: number;
	totalPurgedParts: number;
}) => {
	return withAnalytics({
		payload: {
			action: 'apiPurged',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				...getCommonAnalyticsAttributes(),
				totalParts,
				totalPurgedParts,
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		},
	})(disableNeedSpellingAndGrammar(purgedChunkIds));
};

export const viewSuggestionWithAnalytics = (aiInteractionId: string, editorState: EditorState) =>
	addAnalytics({
		editorState,
		tr: editorState.tr,
		payload: createUnifiedAnalyticPayload(
			'viewed',
			aiInteractionId,
			'Proactive Spelling and Grammar',
			true,
		),
	});

export const ignoreSuggestionWithAnalytics = ({
	aiInteractionID,
	editorState,
	selectedDiff,
	blockOrSentenceFromDiffObject,
	transaction,
}: {
	aiInteractionID: string;
	editorState: EditorState;
	selectedDiff: DiffObject;
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
	transaction?: Transaction;
}) =>
	addAnalytics({
		editorState,
		tr: transaction ?? editorState.tr,
		payload: {
			action: 'ignored',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				aiInteractionID,
				...getPunctuationAttributes(selectedDiff),
				...getCommonAnalyticsAttributes(),
				...getSuggestionMetadata({
					schema: editorState.schema,
					blockOrSentenceFromDiffObject,
				}),
			},
			eventType: EVENT_TYPE.TRACK,
		},
	});
