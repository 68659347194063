import { fromJS } from 'immutable';

const DEFAULT_STATE = fromJS({});

export default function homePage(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case 'SPACE_HOME_CONTENT_SUCCESS':
			return state.mergeIn([action.spaceKey], {
				isSpaceFound: action?.response?.result !== undefined,
			});
		default:
			return state;
	}
}
