import { Iterable, Record, List, Set, OrderedSet, fromJS } from 'immutable';

import { generateSlug } from '@confluence/route-manager';

const UserContext = Record({
	isFavourite: null,
	favouritedDate: null,
	lastSeen: null,
	lastModified: null,
	contentStatus: null,
});

const Link = Record({
	editui: null,
	webui: null,
	webuiIsSpa: null,
	viewChangesLink: null,
});

const CardCount = Record({
	cssClass: null,
	iconType: null,
	count: 0,
});

const Body = Record({
	html: null,
	editorHtml: null,
	cssTags: '',
	jsUris: List([]),
});

export default class Content extends Record({
	id: null,
	type: null,
	iconType: null,
	kind: null,
	title: null,
	slug: null,
	links: null,
	version: null,
	summary: null,
	iconCssClass: null,
	iconPath: null,
	createdBy: null,
	createdDate: null,
	friendlyDate: null,
	contentCssClass: null,
	userContext: null,
	numberOfLikes: null,
	numberOfComments: null,
	willbedeleted: null,
	cardCounts: fromJS([]),
	contributors: List([]),
	hasChildren: null,
	childNodes: List([]),
	body: null,
	position: null,
	webItems: Set([]),
	ancestors: OrderedSet([]),
	currentUserPermissions: Set([]),
	labels: Set([]),
	spaceKey: null,
	// `isUnauthorized` is set to be `true` explicitly in a content reducer.
	// `isUnauthorized` can not be `false`
	// because while merging old and new content entities in `entityReducerCommon,
	// we don't want the new content entity can override `isUnauthorized` prop and make it from `true` to `false`.
	isUnauthorized: null,
	hasInheritedRestrictions: null,
	lastFetchTime: null,
	viewers: List([]),
	editorVersion: null,
}) {
	constructor(recordData) {
		super(recordData);
		return this.withMutations((contentData) => {
			if (recordData.links) {
				contentData.set('links', new Link(recordData.links));
			}

			if (recordData.userContext) {
				contentData.set('userContext', new UserContext(recordData.userContext));
			}

			if (recordData.cardCounts) {
				contentData.set(
					'cardCounts',
					fromJS(recordData.cardCounts, (key, val) => {
						const isIndexed = Iterable.isIndexed(val);
						return isIndexed ? val.toList() : new CardCount(val);
					}),
				);
			}

			if (recordData.viewers) {
				contentData.set('viewers', List(recordData.viewers));
			}

			if (recordData.childNodes) {
				contentData.set('childNodes', List(recordData.childNodes));
			}

			if (recordData.contributors) {
				contentData.set('contributors', List(recordData.contributors));
			}

			if (recordData.body) {
				contentData.set('body', new Body(recordData.body));
			}

			if (recordData.ancestors) {
				contentData.set('ancestors', OrderedSet(recordData.ancestors));
			}

			if (recordData.currentUserPermissions) {
				contentData.set('currentUserPermissions', Set(recordData.currentUserPermissions));
			}

			if (recordData.title) {
				contentData.set('slug', generateSlug(recordData.title));
			}

			if (recordData.labels) {
				contentData.set('labels', Set(recordData.labels));
			}

			// schema of content entity `schemas/entities.js` has a `space` prop.
			// Before normalizr, `content.space` is a space object.
			// After normalizr, `content.space` is a space key value
			if (recordData.space) {
				contentData.set('spaceKey', recordData.space);
			}

			return contentData;
		});
	}
}
