/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b5d38e1d50fe427a3765fb07a5f5ebaf>>
 * @codegenId #tab
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tab
 * @codegenParams { "props": { "children": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::d14a7503f424a1271c00e3d090b07d73>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/hooks.tsx <<SignedSource::85aae58dfbe7e51fa5c11faea1be0adf>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tab.tsx <<SignedSource::49722274242ea0ce581b10ed6c496b01>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Tab as PlatformTab } from '@atlaskit/tabs';

type PlatformTabProps = React.ComponentProps<typeof PlatformTab>;

export type TabProps = Pick<
  PlatformTabProps,
  'children' | 'testId'
>;

export const Tab = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as TabProps;
  return (
    <PlatformTab
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */