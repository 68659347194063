/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

export const Loader = () => {
	return (
		<div
			// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				textAlign: 'center',
				paddingTop: token('space.150', '12px'),
				paddingBottom: token('space.150', '12px'),
				width: '100%',
				overflow: 'hidden',
			})}
		>
			<Spinner testId="forge-app-loading-spinner" size="large" />
		</div>
	);
};
