// TODO: remove once @confluence-classic/confluence-urls is added to next

import { CONTEXT_PATH } from '@confluence/named-routes';

export const getAtlassianPeopleProfileUrl = (
	accountId: string | null | undefined,
	src?: string | null,
	context: string = '',
) => {
	if (!accountId) {
		return '';
	}
	return `${context}/people/${accountId}?ref=confluence${
		src ? `&src=${encodeURIComponent(src)}` : ''
	}`;
};

export const getAtlassianPeopleProfileUrlAsync = async (accountId: string, src?: string) => {
	if (!accountId) {
		return '';
	}

	return `${CONTEXT_PATH}/people/${accountId}?ref=confluence${
		src ? `&src=${encodeURIComponent(src)}` : ''
	}`;
};

export const getTeamUrl = (teamId: string, src?: string, context: string = '') => {
	if (!teamId) {
		return '';
	}
	return `${context}/people/team/${teamId}?ref=confluence${
		src ? `&src=${encodeURIComponent(src)}` : ''
	}`;
};

export const getTeamUrlAsync = async (teamId: string, src?: string) => {
	if (!teamId) {
		return '';
	}

	return `${CONTEXT_PATH}/people/team/${teamId}?ref=confluence${
		src ? `&src=${encodeURIComponent(src)}` : ''
	}`;
};

// Replacement for the getAttributeNames DOM API since it's not supported by IE
const getAttributeNames = (element: Element) =>
	Array.from(element.attributes).map((attribute) => attribute.name);

// Return only DOM elements that have the data-account-id attribute
const withAccountId = (element: Element) => element.getAttribute('data-account-id');

// Returns React props that can be used to recreate a DOM element
const getReactPropsFromDOMElement = (element: Element) => {
	const attributeToProp: Record<string, string> = { class: 'className' };
	const props: Record<string, any> = {};
	getAttributeNames(element).forEach((attribute) => {
		props[attributeToProp[attribute] || attribute] = element.getAttribute(attribute);
	});

	props.dangerouslySetInnerHTML = {
		__html: element.innerHTML,
	};

	return props;
};

// Replaces elements with empty placeholders,
// returning an array containing the placeholder and React props to recreate each element
export function replaceUserElementsWithPlaceholders(userElements: Element[]) {
	return userElements.filter(withAccountId).map((element) => {
		const props = getReactPropsFromDOMElement(element);
		// Set this attribute to stop the old user profile hovers from binding
		props['data-user-hover-bound'] = 'true';
		// Ensure that links go to the same location as the profile card
		props.href = getAtlassianPeopleProfileUrl(props['data-account-id'], null);

		const placeholder = window.document.createElement('span');
		if (!element.parentNode) {
			return {};
		}
		element.parentNode.replaceChild(placeholder, element);

		return { props, placeholder };
	});
}
