import gql from 'graphql-tag';

export const MacrosQuery = gql`
	query MacrosQuery(
		$contentId: ID!
		$mode: MacroRendererMode = RENDERER
		$blocklist: [String!]
		$first: Int
		$after: String
	) {
		macros(contentId: $contentId, blocklist: $blocklist, first: $first, after: $after) {
			pageInfo {
				hasNextPage
				endCursor
			}

			nodes {
				macroId
				contentId
				renderedMacro(mode: $mode) {
					value
					macroRenderedRepresentation
					macroBodyStorage
					webResource {
						contexts
						keys
						superbatch {
							metatags
						}
						tags {
							css
							data
							js
						}
						uris {
							css
							data
							js
						}
					}
					mediaToken {
						token
					}
				}
			}
		}
	}
`;
