import type { FC, MutableRefObject } from 'react';
import React, { memo, useMemo, useRef, useState, useCallback, useLayoutEffect } from 'react';

export enum ReloadType {
	content = 'content',
	comment = 'comment',
}

export enum ReloadPriority {
	immediate = 0,
	normal = 1,
	background = 2,
}

export type ReloadFunc = (options?: any) => Promise<any>;

export type QuickReloadContextProps = {
	contentId?: string;
	children?: React.ReactNode;
};

export type QuickReloaderType = {
	types: Array<ReloadType>;
	name: string;
	priority?: ReloadPriority;
	reload: ReloadFunc;
};

export type QuickReloadContextType = {
	contentId?: string;
	registerReloader: (reloader: QuickReloaderType) => void;
	unregisterReloader: (reloader: QuickReloaderType) => void;
	reloaders: {
		[ReloadType.content]: ReloadFunc;
		[ReloadType.comment]: ReloadFunc;
	};
};

type ReloaderStateType = {
	[key: string]: QuickReloaderType;
};

const reloadAll = async (
	reloaders: ReloaderStateType,
	contentId: string | undefined,
	contentIdRef: MutableRefObject<string | undefined>,
	options?: any,
): Promise<void> => {
	// Arrange reloader by priority to ensure that lower priority reloaders are
	// called strictly after higher-priority ones
	const reloadersByPriority = Object.keys(reloaders).reduce(
		(res: Array<Array<QuickReloaderType>>, key: string) => {
			const reloader = reloaders[key];
			const priority = Number(reloader.priority || ReloadPriority.immediate);

			if (res[priority]) {
				res[priority].push(reloader);
			} else {
				res[priority] = [reloader];
			}

			return res;
		},
		[],
	);

	// Call reloaders according to their priorities
	for (const reloaders of reloadersByPriority) {
		// It is possible that by the time the execution flow reached this point,
		// user may have already transitioned to another `contentId`,
		// and in such case some reloaders may contain closures to stale/unmounted components.
		// Comparing `contentIdRef` (which contains up-to-date value)
		// with `contentId` (which is dated the same as `reloaders`) allows to detect such cases.
		const isOriginalContentId = contentIdRef.current === contentId;

		if (reloaders && isOriginalContentId) {
			const reloaderPromises = reloaders.map((reloader) => reloader.reload(options));
			await Promise.all(reloaderPromises);
		}
	}
};

/**
 * Quick reload context, allowing consumers to reload
 */
export const QuickReloadContext = React.createContext<QuickReloadContextType>({
	registerReloader: () => {},
	unregisterReloader: () => {},
	reloaders: {
		[ReloadType.content]: async () => {},
		[ReloadType.comment]: async () => {},
	},
});

export const QuickReloadContextProvider: FC<QuickReloadContextProps> = memo(
	({ contentId, children }) => {
		const contentIdRef = useRef(contentId);
		const isUnmountedRef = useRef(false);
		const [contentReloader, setContentReloader] = useState<ReloaderStateType>({});

		const [commentReloader, setCommentReloader] = useState<ReloaderStateType>({});

		useLayoutEffect(() => {
			// Due to having empty dependency array the `cleanup` function will be called
			// when component unmounts.
			return () => {
				isUnmountedRef.current = true;
			};
		}, []);

		useLayoutEffect(() => {
			contentIdRef.current = contentId;

			// When contentId changes all reloaders have to be cleaned up, so new page
			// can register their own reloaders.
			// NOTE that reloaders are cleaned up not in the effect but in a `cleanup`
			// function that effect returns.
			return () => {
				if (!isUnmountedRef.current) {
					setContentReloader({});
					setCommentReloader({});
				}
			};
		}, [contentId]);

		/**
		 * Handles common logic for registering and unregistering reloaders.
		 *
		 * @param {QuickReloaderType} reloader - The reloader object containing
		 * information about the reloader function to be registered or unregistered,
		 * including its name, types, and reloading logic.
		 * @param {(setReloader: (prevState: (prevState: ReloaderStateType) => ReloaderStateType) => void) => void} callback - A callback function that takes
		 * the setReloader function as an argument and performs the necessary logic for
		 * registering or unregistering the given reloader within the context provider.
		 * The setReloader function is used to update the state of either contentReloader
		 * or commentReloader, depending on the reloader's type.
		 */
		const handleReloader = (
			reloader: QuickReloaderType,
			callback: (
				setReloader: (prevState: (prevState: ReloaderStateType) => ReloaderStateType) => void,
			) => void,
		) => {
			reloader.types.forEach((type) => {
				const setReloader = type === ReloadType.comment ? setCommentReloader : setContentReloader;
				callback(setReloader);
			});
		};

		// Allows to register multiple reload functions for specific type of data to reload.
		// For example, to reload content you need to refetch Content and ViewPageSwitcher
		// queries and possibly run some other functions, if needed.
		const registerReloader = useCallback((reloader: QuickReloaderType) => {
			handleReloader(reloader, (setReloader) => {
				setReloader((reloaders) => ({
					...reloaders,
					[reloader.name]: reloader,
				}));
			});
		}, []);

		const unregisterReloader = useCallback((reloader: QuickReloaderType) => {
			handleReloader(reloader, (setReloader) => {
				setReloader((reloaders) => {
					const { [reloader.name]: _removedReloader, ...newReloaders } = reloaders;
					return newReloaders;
				});
			});
		}, []);

		// Create the context for all the reloaders
		const context = useMemo(
			() => ({
				contentId,
				registerReloader,
				unregisterReloader,
				reloaders: {
					[ReloadType.content]: (options: any) =>
						reloadAll(contentReloader, contentId, contentIdRef, options),
					[ReloadType.comment]: (options: any) =>
						reloadAll(commentReloader, contentId, contentIdRef, options),
				},
			}),
			[contentId, registerReloader, unregisterReloader, contentReloader, commentReloader],
		);
		return <QuickReloadContext.Provider value={context}>{children}</QuickReloadContext.Provider>;
	},
);
