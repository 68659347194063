import { format } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl-next';
import { AnimatedAxis } from '@visx/xychart';

import { token } from '@atlaskit/tokens';

import type { GranularityType } from '../../../common/MetricSettingsContext';

const AXIS_STROKE_COLOR = token('color.chart.gray.bolder', '#758195');
const AXIS_STROKE_WIDTH = 1;
const AXIS_TICK_STROKE_COLOR = token('color.chart.gray.bolder', '#758195');
const AXIS_TICK_LABEL_PROPS = {
	fontSize: 11,
	fontWeight: token('font.weight.regular', '400'),
	fill: token('color.text.subtlest', '#626F86'),
};

// AnimatedAxis tickFormat function. This function returns the label for each date tick on the axis.
// The label format is determined by the domain of the axis and is then applied to the given value.
const getXAxisTickFormat = ({
	granularity,
	value,
}: {
	granularity: GranularityType;
	value: Date;
}) => {
	if (granularity === 'MONTH') {
		// 3 character month of year. (JAN, FEB, MAR, etc...)
		return format(value, 'LLL');
	} else {
		// The granularity should be DAY or WEEK.
		// However, if it is an unexpected value, then we default to the same format.
		// 3 character month of year and day of month. (JAN 1, JAN 8, JAN 15, etc...)
		return format(value, 'LLL d');
	}
};

export const CustomXAxis = ({
	tickValues,
	granularity,
}: {
	tickValues: Date[];
	granularity: GranularityType;
}) => (
	<AnimatedAxis
		orientation="bottom"
		tickValues={tickValues}
		tickFormat={(value: Date) => getXAxisTickFormat({ granularity, value })}
		stroke={AXIS_STROKE_COLOR}
		strokeWidth={AXIS_STROKE_WIDTH}
		tickStroke={AXIS_TICK_STROKE_COLOR}
		tickLabelProps={AXIS_TICK_LABEL_PROPS}
	/>
);

export const CustomYAxis = ({ tickValues }: { tickValues: number[] }) => {
	const { formatNumber } = useIntl();
	return (
		<AnimatedAxis
			orientation="left"
			tickValues={tickValues}
			tickFormat={(value: number) => formatNumber(value, { notation: 'compact' })}
			stroke={AXIS_STROKE_COLOR}
			strokeWidth={AXIS_STROKE_WIDTH}
			hideTicks
			tickLabelProps={AXIS_TICK_LABEL_PROPS}
		/>
	);
};
