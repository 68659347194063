import { type LocalId } from './types';

/**
 * Occurs if there's a compatibility issue with the environment. For example; if the environment doesn't support
 * FragmentMarks
 */
export class CompatibilityError extends Error {
	constructor(public readonly reason: string) {
		super(`Unable to attach, Incorrect configuration: ${reason}`);
	}
}

/**
 * Occurs if the API hasn't been setup correctly.
 */
export class ConfigurationError extends Error {
	constructor(public readonly handlerName: string) {
		super(`Unable to execute ${handlerName}, API hasn't been initialized`);
	}
}

/**
 * Can occur during API Initialization.
 */
export class InitializeError extends Error {
	constructor() {
		super('An Error occured during Initialization');
	}
}

/**
 * Occurs when an API access point has been supplied but the Implementation doesn't exist for it.
 */
export class NotImplementedError extends Error {
	constructor() {
		super('API not implemented');
	}
}

/**
 * Occurs when a node type is referenced which the API doesn't support.
 */
export class UnsupportedTypeError extends Error {
	constructor(type: string) {
		super(`Unsupported type (${type})`);
	}
}

/**
 * Occurs when a localId is referenced but cannot be found in the document.
 */
export class NodeNotFoundError extends ReferenceError {
	constructor(public readonly localId: LocalId) {
		super(`Node (${localId}) could not be found`);
	}
}

/**
 * Similar to NodeNotFoundError but occurs when the target cannot be referenced in the document.
 */
export class TargetReferenceError extends NodeNotFoundError {
	constructor(public readonly localId: LocalId) {
		super(localId);
	}
}

/**
 * Similar to NodeNotFoundError but occurs when the source cannot be referenced in the document.
 */
export class SourceReferenceError extends NodeNotFoundError {
	constructor(public readonly localId: LocalId) {
		super(localId);
	}
}

/**
 * Occurs when the target and source reference result in a circular reference.
 */
export class CyclicReferenceError extends ReferenceError {
	constructor(
		public readonly targetLocalId: LocalId,
		public readonly sourceLocalId: LocalId,
	) {
		super(
			`Connecting Target (${targetLocalId}) to Source (${sourceLocalId}) will result in a Circular Reference`,
		);
	}
}

/**
 * Occurs if a node localId is non-unique.
 */
export class LocalIdCollisionError extends ReferenceError {
	constructor(public readonly localId: LocalId) {
		super(`LocalId (${localId}) Collision has occured`);
	}
}
