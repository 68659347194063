import type { ADNode } from "@atlaskit/editor-common/validator";

export function getType(node) {
  return node.type.name || node.type;
}

export function updateParams(node, newParams): ADNode {
  return {
    type: getType(node),
    attrs: {
      ...node.attrs,
      parameters: {
        ...node.attrs.parameters,
        ...newParams
      }
    }
  };
}

type CallBackType = (key: string, value: any) => void;
export function traverseADTree(root: ADNode, callbackFunc: CallBackType) {
  for (const nodeKey of Object.keys(root)) {
    const nodeValue: ADNode = root[nodeKey];
    callbackFunc(nodeKey, nodeValue);

    // go deeper
    if (nodeValue !== null && typeof nodeValue === "object") {
      traverseADTree(root[nodeKey], callbackFunc);
    }
  }
}

export function filterADNodesByType(root: ADNode, typeName: string): ADNode[] {
  const results: ADNode[] = [];

  traverseADTree(root, (_nodeKey, nodeValue) => {
    if (nodeValue && nodeValue.type === typeName) {
      results.push(nodeValue as ADNode);
    }
  });

  return results;
}
