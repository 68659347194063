import { LoadableAfterPaint } from '@confluence/loadable';

export const RedirectLegacyViewPageLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RedirectLegacyViewPage" */ './RedirectLegacyViewPage'
			)
		).RedirectLegacyViewPage,
});

(RedirectLegacyViewPageLoader as any).NAVIGATION_PARAMS = () => false;
