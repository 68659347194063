import React, { useState, useEffect } from 'react';
import { differenceInDays } from 'date-fns';

import { Stack } from '@atlaskit/primitives';

import type { MetricSettingsContextValueType } from '@confluence/admin-center/entry-points/MetricSettingsContext';
import {
	MetricSettingsContext,
	MetricSettingsContextDefaultValue,
} from '@confluence/admin-center/entry-points/MetricSettingsContext';
import { MetricSettingsController } from '@confluence/admin-center/entry-points/MetricSettingsController';

import { AnalyticBoxesRow } from './AnalyticBoxesRow';
import { HubAnalyticsChart } from './HubAnalyticsChart';
import { HubAnalyticsTable } from './HubAnalyticsTable';

type CompanyHubAnalyticsVisualizationsProps = {
	spaceId: string;
	spaceCreatedDate: Date | undefined;
	isSpaceCreatedDateOver1DayAgo: boolean | undefined;
};

export const CompanyHubAnalyticsVisualizations = ({
	spaceId,
	spaceCreatedDate,
	isSpaceCreatedDateOver1DayAgo,
}: CompanyHubAnalyticsVisualizationsProps) => {
	const [metricSettingsContext, setMetricSettingsContext] =
		useState<MetricSettingsContextValueType>(MetricSettingsContextDefaultValue);

	useEffect(() => {
		const hasNewActivationDate =
			spaceCreatedDate?.valueOf() !== metricSettingsContext.activationDate?.valueOf();
		const hasNewIsActivationDateOver1DayAgo =
			isSpaceCreatedDateOver1DayAgo !== metricSettingsContext.isActivationDateOver1DayAgo;

		if (hasNewActivationDate || hasNewIsActivationDateOver1DayAgo) {
			const isActivationDateOver1DayAgo: boolean = !!(
				spaceCreatedDate &&
				differenceInDays(metricSettingsContext.endDate, new Date(spaceCreatedDate)) >= 1
			);
			setMetricSettingsContext({
				...metricSettingsContext,
				isActivationDateOver1DayAgo,
				activationDate: spaceCreatedDate ? new Date(spaceCreatedDate) : undefined,
			});
		}
	}, [
		spaceCreatedDate,
		isSpaceCreatedDateOver1DayAgo,
		metricSettingsContext,
		setMetricSettingsContext,
	]);

	return (
		<MetricSettingsContext.Provider value={metricSettingsContext}>
			<Stack space="space.200">
				<MetricSettingsController
					setMetricSettingsContext={setMetricSettingsContext}
					analyticsSource="companyHubAnalytics"
				/>
				<AnalyticBoxesRow isSpaceCreatedDateOver1DayAgo={isSpaceCreatedDateOver1DayAgo} />
				<HubAnalyticsChart
					spaceId={spaceId}
					isSpaceCreatedDateOver1DayAgo={isSpaceCreatedDateOver1DayAgo}
				/>
				<HubAnalyticsTable isSpaceCreatedDateOver1DayAgo={isSpaceCreatedDateOver1DayAgo} />
			</Stack>
		</MetricSettingsContext.Provider>
	);
};
