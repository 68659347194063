import { css, cssMap } from '@compiled/react';
import React from 'react';

const baseOutlineStyles = css({
	position: 'relative',

	'&::after': {
		content: "''",
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		opacity: '0.2',
	},
});

const colorMap = cssMap({
	red: {
		outline: '2px dashed red',
		'&::after': {
			backgroundColor: 'red',
		},
	},
	yellow: {
		outline: '2px dashed yellow',
		'&::after': {
			backgroundColor: 'yellow',
		},
	},
	blue: {
		outline: '2px dashed blue',
		'&::after': {
			backgroundColor: 'blue',
		},
	},
	green: {
		outline: '2px dashed green',
		'&::after': {
			backgroundColor: 'green',
		},
	},
});

export const withOutline = (color: 'red' | 'yellow' | 'blue' | 'green', element: any) => (
	<div css={[baseOutlineStyles, colorMap[color]]}>{element}</div>
);
