/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::5749cc462ce99d99ba59a06039d4f509>>
 * @codegenId #radio
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen radio
 * @codegenParams { "hasFieldProps": true, "props": { "label": true, "testId": true, "isChecked": true, "testId": true, "ariaLabel": true, "onInvalid": true }}
 * @codegenDependency ../../../../../../../design-system/radio/src/types.tsx <<SignedSource::6a2f73d4dc252916d11e2658bb97efc2>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::776e3f3df8ff5c06e378bdcf4d407167>>
 * @codegenDependency ../../../../../../../design-system/radio/src/radio.tsx <<SignedSource::7a1a2cca2ff52fba1587affcd6c2c4c6>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Radio as PlatformRadio } from '@atlaskit/radio';
import { adaptEventHandler } from "../../../utils";
import type { EventHandlerProps } from "../../types";

type PlatformRadioProps = React.ComponentProps<typeof PlatformRadio>;

export type RadioProps = Pick<
  PlatformRadioProps,
  'label' | 'testId' | 'isChecked' | 'ariaLabel' | 'onInvalid'
 | 'id' | 'isRequired' | 'isDisabled' | 'isInvalid' | 'value' | 'aria-invalid' | 'aria-labelledby' | 'name'
> & Pick<EventHandlerProps, 'onChange' | 'onBlur' | 'onFocus'>;

export const Radio = (props: Parameters<RenderFn>[0]) => {
  const {
    label,
    testId,
    isChecked,
    ariaLabel,
    onInvalid,
    // Field props
    id,
    isRequired,
    isDisabled,
    isInvalid,
    onChange,
    onBlur,
    onFocus,
    value,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as RadioProps;
  return (
    <PlatformRadio
      label={label}
      testId={testId}
      isChecked={isChecked}
      ariaLabel={ariaLabel}
      onInvalid={onInvalid}
      // Field props
      id={id}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onChange={adaptEventHandler(onChange)}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      value={value}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
