import { sanitizeUrl } from '../../../../utils';
import { adaptEventHandler } from '../../../utils';

/**
 * Type for link button had to be defined here because the prop `href` should only take strings here
 * and not custom RouterLinkConfigs
 */
import type { LinkButtonProps as PlatformLinkButtonProps } from '@atlaskit/button/new';
export type LinkButtonProps = Pick<
	PlatformLinkButtonProps,
	| 'children'
	| 'target'
	| 'autoFocus'
	| 'isDisabled'
	| 'isSelected'
	| 'onBlur'
	| 'onClick'
	| 'onFocus'
	| 'testId'
	| 'shouldFitContainer'
> & {
	href?: string;
	ref?: React.Ref<HTMLAnchorElement>;
	appearance?: PlatformLinkButtonProps['appearance'] | 'link' | 'subtle-link';
	spacing?: PlatformLinkButtonProps['spacing'] | 'none';
};

/**
 * Mapping the deprecated 'link' and 'subtle-link' appearances to 'subtle'
 */
const mapDeprecatedAppearances = (appearance: LinkButtonProps['appearance']) =>
	appearance === 'link' || appearance === 'subtle-link' ? 'subtle' : appearance;

/**
 * Mapping the deprecated 'none' spacing to 'compact'
 */
const mapDeprecatedSpacing = (spacing: LinkButtonProps['spacing']) =>
	spacing === 'none' ? 'compact' : spacing;

/**
 * Excluded props:
 * - analyticsContext - not supported in Forge
 * - interactionName - not supported in Forge
 * - iconAfter - not supported until Icon is introduced in UI Kit 2
 * - iconBefore - not supported until Icon is introduced in UI Kit 2
 * - UNSAFE_iconAfter_size - unsafe; ADS will remove it in the future
 * - UNSAFE_iconBefore_size - unsafe; ADS will remove it in the future
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::c9648ec3d3028c0c9f13a0716c049067>>
 * @codegenId #link-button
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen link-button
 * @codegenParams { "defineOwnType": true, "props": { "target": true, "href": { "value": "sanitizeUrl(href)", "comment": "safety measure " }, "rel": { "value": "'noopener noreferrer'", "comment": "safety measure", "excludeFromDeconstruct": true }, "children": true, "autoFocus": true, "overlay": false, "isDisabled": true, "isSelected": true, "onBlur": {"value": "adaptEventHandler(onBlur)"}, "onClick": {"value": "adaptEventHandler(onClick)"}, "onFocus": {"value": "adaptEventHandler(onFocus)"}, "spacing": { "value": "mapDeprecatedSpacing(spacing)" }, "testId": true, "interactionName": false, "analyticsContext": false, "iconAfter": false, "UNSAFE_iconAfter_size": false, "iconBefore": false, "UNSAFE_iconBefore_size": false, "shouldFitContainer": true, "appearance": { "value": "mapDeprecatedAppearances(appearance)" } } }
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/default/types.tsx <<SignedSource::b9aec5245f14d39bd330fbc759b068dd>>
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/types.tsx <<SignedSource::e02baeacd709a2faae3cf14a0e45b694>>
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/default/link.tsx <<SignedSource::e860eb384b10a895d8761490d8468631>>
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/default/use-default-button.tsx <<SignedSource::6d7b8d1f729d119dcf1d3aef067dbc6e>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { LinkButton as PlatformLinkButton } from '@atlaskit/button/new';

// Define the type for LinkButton as LinkButtonProps at the top of this file

export const LinkButton = (props: Parameters<RenderFn>[0]) => {
  const {
    target,
    href,
    autoFocus,
    isDisabled,
    isSelected,
    onBlur,
    onClick,
    onFocus,
    spacing,
    testId,
    shouldFitContainer,
    appearance,
  } = props.forgeDoc.props as LinkButtonProps;
  return (
    <PlatformLinkButton
      target={target}
      // safety measure 
      href={sanitizeUrl(href)}
      // safety measure
      rel={'noopener noreferrer'}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onBlur={adaptEventHandler(onBlur)}
      onClick={adaptEventHandler(onClick)}
      onFocus={adaptEventHandler(onFocus)}
      spacing={mapDeprecatedSpacing(spacing)}
      testId={testId}
      shouldFitContainer={shouldFitContainer}
      appearance={mapDeprecatedAppearances(appearance)}
    />
  );
};
/**
 * @codegenEnd
 */
