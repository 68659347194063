import { LoadableAfterPaint } from '@confluence/loadable';

export { INACCESSIBLE_PAGE_LOAD } from '../src/perf.config';

export const RestrictedPage = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-RestrictedPage" */ './RestrictedPage'))
			.RestrictedPage,
});

export const RestrictedSpace = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-RestrictedSpace" */ './RestrictedSpace'))
			.RestrictedSpace,
});

export const RestrictedPageConfirmation = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RestrictedPageConfirmation" */ './RestrictedPageConfirmation'
			)
		).RestrictedPageConfirmation,
});
