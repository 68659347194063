import React from 'react';

import { ADMIN_CENTER, ADMIN_CONFIGURATION } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

export const AdminSettingsRoute = () => {
	const { edition } = useSessionData();

	if (edition === ConfluenceEdition.PREMIUM) {
		return <Redirection name={ADMIN_CENTER.name} />;
	}

	return <Redirection name={ADMIN_CONFIGURATION.name} />;
};

AdminSettingsRoute.NAVIGATION_PARAMS = () => false;
