import { LoadablePaint, LoadableLazy } from '@confluence/loadable';

export const NotificationsSiteSettingsChangeboardingSpotlight = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-NotificationsSiteSettingsChangeboardingSpotlight" */ './site-settings/NotificationsSiteSettingsChangeboardingSpotlight'
			)
		).NotificationsSiteSettingsChangeboardingSpotlight,
});

export const LazyNotificationsSiteSettingsPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-NotificationsSiteSettingsPage" */ './site-settings/NotificationsSiteSettingsPage'
			)
		).NotificationsSiteSettingsPage,
});

export { ADMIN_NOTIFICATIONS_ANALYTICS_SOURCE } from './site-settings/notificationsSiteSettingsAnalyticsSource';
