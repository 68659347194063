import type { BM3Marks } from '@atlaskit/react-ufo/custom-timings';
import { getInteractionId } from '@atlaskit/react-ufo/interaction-id-context';
import {
	addCustomTiming,
	getCurrentInteractionType,
} from '@atlaskit/react-ufo/interaction-metrics';

export function addAllBM3TimingsToUFO(marks: BM3Marks) {
	const interactionId = getInteractionId().current;
	if (!interactionId) {
		return;
	}

	const interactionType = getCurrentInteractionType(interactionId);
	if (interactionType === 'press') {
		return;
	}

	const bm3Timings: { [key: string]: { startTime: number; endTime: number } } = {};

	Object.keys(marks).forEach((mark) => {
		if (mark.startsWith('segment-start')) {
			const segmentName = mark.split(':')[1];
			const key = `wf/react/${segmentName}`;
			const endMark = `segment-end:${segmentName}`;
			const startTime = marks[mark];
			const endTime = marks[endMark];

			if (typeof startTime === 'number' && typeof endTime === 'number') {
				bm3Timings[key] = { startTime, endTime };
			}
		}

		if (mark.startsWith('wf') && mark.endsWith('.start')) {
			const key = mark.split('.start')[0];
			const startTime = marks[mark];
			const endMark = `${key}.end`;
			const endTime = marks[endMark];

			if (typeof startTime === 'number' && typeof endTime === 'number') {
				bm3Timings[key] = { startTime, endTime };
			}
		}
	});

	addCustomTiming(interactionId, [], bm3Timings);
}
