import React from 'react';
import type { ReactNode } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';
import { differenceInDays } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';

import Heading from '@atlaskit/heading';
import { Box, Text, Stack, xcss } from '@atlaskit/primitives';
import Link from '@atlaskit/link';

import { SpaceSettingsBreadcrumbs } from '@confluence/breadcrumbs/entry-points/SpaceSettingsBreadcrumbs';
import { DocumentContentTitle } from '@confluence/document-title/entry-points/DocumentContentTitle';
import { TransparentErrorBoundary, Attribution, ErrorDisplay } from '@confluence/error-boundary';
import {
	collectAll,
	COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE,
	COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE,
	COMPANY_HUB_ANALYTICS_EXPERIENCE,
	COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE,
	ExperienceAbort,
	ExperienceFailure,
	ExperienceStart,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import type { Experience, ExperienceEvent } from '@confluence/experience-tracker';
import { fg } from '@confluence/feature-gating';
import { NotFound } from '@confluence/not-found';
import { usePageState } from '@confluence/page-context';

import type { CompanyHubAnalyticsPageQuery as CompanyHubAnalyticsPageQueryType } from './__types__/CompanyHubAnalyticsPageQuery';
import { CompanyHubAnalyticsPageQuery } from './CompanyHubAnalyticsPageQuery.graphql';
import { CompanyHubAnalyticsVisualizations } from './CompanyHubAnalyticsVisualizations';

const i18n = defineMessages({
	pageTitle: {
		id: 'company-hub.analytics-page.title',
		defaultMessage: 'Company hub analytics',
		description: 'Page title for the company hub analytics page',
	},
	hubSettingsAnalyticsHeader: {
		id: 'company-hub.analytics-page.hub-settings.analytics.title',
		defaultMessage: 'Analytics',
		description: 'Header title for the analytics tab in company hub settings page',
	},
	hubSettingsAnalyticsDescription: {
		id: 'company-hub.analytics-page.hub-settings.description.non-final',
		defaultMessage:
			'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias. <link>Learn more</link>',
		description:
			'Description area below Analytics title where users can learn about the hub analytics and also access the Company hub documentation',
	},
	notFoundDocumentTitle: {
		id: 'company-hub.analytics-page.error-screen.not-found',
		description:
			'This text will appear in the browser tab if users try accessing the page when the feature is turned off',
		defaultMessage: 'Not found',
	},
});

const SUB_EXPERIENCES = [
	COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE,
	COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE,
	COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE,
];

const pageLayoutStyles = xcss({
	paddingTop: 'space.400',
	paddingInline: 'space.800',
});
const pageHeaderContainerStyles = xcss({
	marginBottom: 'space.400',
});
const pageDescriptionStyles = xcss({
	marginTop: 'space.200',
	marginBottom: 'space.400',
});
const backButtonContainerStyles = xcss({
	marginBottom: 'space.300',
});

type AnalyticsContainerProps = {
	children: ReactNode;
	spaceKey: string;
};

const AnalyticsHeader = ({ children, spaceKey }: AnalyticsContainerProps) => {
	const { formatMessage } = useIntl();

	if (fg('company_hub_consolidated_settings')) {
		return (
			<Stack grow="fill" xcss={pageHeaderContainerStyles}>
				<Heading size="medium">
					<FormattedMessage {...i18n.hubSettingsAnalyticsHeader} />
				</Heading>
				<Box xcss={pageDescriptionStyles}>
					<Text color="color.text.subtle">
						<FormattedMessage
							{...i18n.hubSettingsAnalyticsDescription}
							values={{
								link: (chunks: React.ReactNode[]) => (
									// eslint-disable-next-line jsx-a11y/anchor-is-valid
									<Link appearance="subtle" target="_blank" href="#">
										{chunks}
									</Link>
								),
							}}
						/>
					</Text>
				</Box>
				{children}
			</Stack>
		);
	}

	return (
		<Stack xcss={pageLayoutStyles}>
			<DocumentContentTitle titlePrefix={formatMessage(i18n.pageTitle)} />
			<TransparentErrorBoundary attribution={Attribution.COMPANY_HUB}>
				<Box xcss={backButtonContainerStyles}>
					<SpaceSettingsBreadcrumbs
						spaceKey={spaceKey}
						source="companyHubAnalytics"
						currentSection="companyHubAnalyticsBackButton"
					/>
				</Box>
			</TransparentErrorBoundary>
			<Stack grow="fill" xcss={pageHeaderContainerStyles}>
				<Heading size="xlarge">
					<FormattedMessage {...i18n.pageTitle} />
				</Heading>
			</Stack>
			{children}
		</Stack>
	);
};

type CompanyHubAnalyticsPageProps = {
	spaceKey: string;
};

export const CompanyHubAnalyticsPage = ({ spaceKey }: CompanyHubAnalyticsPageProps) => {
	const { formatMessage } = useIntl();
	const {
		data: queryData,
		loading: queryLoading,
		error: queryError,
	} = useQuery<CompanyHubAnalyticsPageQueryType>(CompanyHubAnalyticsPageQuery, {
		variables: {
			key: spaceKey,
		},
		skip: !spaceKey || !fg('confluence_frontend_company_hub_analytics'),
	});

	const [{ contentIdLoading }] = usePageState();

	const spaceId = queryData?.space?.id;
	const hasEditingPermissions = queryData?.space?.homepage?.operations?.some(
		(obj) => obj?.operation === 'update',
	);

	const shouldRenderErrorState = queryError || !spaceId || !hasEditingPermissions;

	if (queryLoading || contentIdLoading) {
		return null;
	}

	const spaceCreatedDate = queryData?.space?.history?.createdDate
		? new Date(queryData?.space?.history?.createdDate)
		: undefined;
	const isSpaceCreatedDateOver1DayAgo = spaceCreatedDate
		? differenceInDays(new Date(), spaceCreatedDate) >= 1
		: undefined;

	const collect = (events: ExperienceEvent[], experience: Experience) => {
		collectAll(SUB_EXPERIENCES)(events, experience);
	};

	return (
		<>
			<ExperienceStart
				name={COMPANY_HUB_ANALYTICS_EXPERIENCE}
				collect={collect}
				timeout={ExperienceTimeout.DEFAULT}
			/>
			{shouldRenderErrorState ? (
				<>
					{queryError && (
						<ErrorDisplay error={queryError}>
							<ExperienceFailure
								name={COMPANY_HUB_ANALYTICS_EXPERIENCE}
								error={new Error('cannot render company hub analytics page')}
							/>
						</ErrorDisplay>
					)}
					{!queryError && !hasEditingPermissions && (
						<ExperienceAbort
							name={COMPANY_HUB_ANALYTICS_EXPERIENCE}
							reason="user does not have editing permissions"
						/>
					)}
					{!queryError && !spaceId && (
						<ExperienceFailure
							name={COMPANY_HUB_ANALYTICS_EXPERIENCE}
							error={new Error('spaceId cannot be found')}
						/>
					)}
					<DocumentContentTitle titlePrefix={formatMessage(i18n.notFoundDocumentTitle)} />
					<NotFound />
				</>
			) : (
				<AnalyticsHeader spaceKey={spaceKey}>
					<CompanyHubAnalyticsVisualizations
						spaceId={spaceId}
						spaceCreatedDate={spaceCreatedDate}
						isSpaceCreatedDateOver1DayAgo={isSpaceCreatedDateOver1DayAgo}
					/>
				</AnalyticsHeader>
			)}
		</>
	);
};
