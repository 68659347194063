import React from 'react';

import { perfMark, WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';

import { SSRThemeInit } from './SSRThemeInit';

export const SSRAppTheme = () => (
	<>
		{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_THEME_INIT}.start`)}
		<SSRThemeInit />
		{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_THEME_INIT}.end`)}
	</>
);
