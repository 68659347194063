import gql from 'graphql-tag';

export const FrontendResourceRenderQuery = gql`
	query FrontendResourceRenderQuery {
		internalFrontendResource {
			resourceList {
				type
				url
				attributes {
					key
					value
				}
			}
		}
	}
`;
