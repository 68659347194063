/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type FieldChildrenProps, type RangeProps } from '@atlassian/forge-ui-types';
import { type Props } from '../..'; // AFP-2532 TODO: Fix automatic suppressions below
import { FormStateChangeNotifier } from '../form';
import { FieldContainer } from '../../UIKit-legacy/utils/FieldContainer';

const AKRange = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.range' */
			'@atlaskit/range'
		),
);
const AKField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.Field,
	})),
);

const Range = ({
	defaultValue,
	min = 0,
	max = 100,
	step = 1,
	label,
	name,
	testId,
}: RangeProps & { testId?: string }) => {
	return (
		<FieldContainer>
			<AKField name={name} label={label} defaultValue={defaultValue || min}>
				{({ fieldProps }: FieldChildrenProps) => {
					const {
						isDisabled,
						onChange,
						onBlur,
						onFocus,
						value,
						'aria-labelledby': ariaLabelledBy,
					} = fieldProps;
					return (
						<Fragment>
							<FormStateChangeNotifier name={name} value={fieldProps.value} />
							<AKRange
								min={min}
								max={max}
								step={step}
								testId={testId}
								isDisabled={isDisabled}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								onFocus={onFocus}
								aria-labelledby={ariaLabelledBy}
							/>
						</Fragment>
					);
				}}
			</AKField>
		</FieldContainer>
	);
};

export default Range;

export const RangeFn = ({ props }: Props) => {
	return <Range {...(props as RangeProps)} />;
};
