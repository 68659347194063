import type { VFC } from 'react';
import React, { useContext, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { PageTitle } from '@confluence/page-title';
import { SPAViewContext } from '@confluence/spa-view-context';

const i18n = defineMessages({
	notFound: {
		id: 'document-title.not.found',
		defaultMessage: 'Page Not Found',
		description: 'title of the 404 page not found page',
	},
});

type DocumentContentTitleProps = {
	contentTitle?: string;
	contentSpace?: string;
	siteTitleOnly?: boolean;
	titlePrefix?: string;
	notFound?: boolean;
};

export const DocumentContentTitle: VFC<DocumentContentTitleProps> = ({
	titlePrefix = '',
	contentTitle = '',
	contentSpace = '',
	siteTitleOnly = false,
	notFound = false,
}) => {
	const intl = useIntl();
	const { siteTitle } = useContext(SPAViewContext);
	useEffect(() => {
		return () => {
			window['__DOCUMENT_TITLE_TAG__'] = '';
		};
	}, []);

	// In the case when page is rendered by SSR, the __DOCUMENT_TITLE_TAG__ will be
	// populated and we can skip document title.
	if (window['__DOCUMENT_TITLE_TAG__'] && window['__SSR_RENDERED__'] && document.title) {
		return null;
	}

	const title = [
		titlePrefix || (notFound ? intl.formatMessage(i18n.notFound) : ''),
		siteTitleOnly ? undefined : contentTitle,
		siteTitleOnly ? undefined : contentSpace,
		siteTitle,
	]
		.filter((part) => part)
		.join(' - ');

	// Set for SSR document title
	window['__DOCUMENT_TITLE_TAG__'] = title;

	return <PageTitle title={title} />;
};
