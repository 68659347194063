import { LoadablePaint } from '@confluence/loadable';

export const CustomContentOnPageComponentLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcCustomContentOnPageComponent" */ '../src/CustomContentOnPageComponent'
			)
		).CustomContentOnPageComponent,
});
