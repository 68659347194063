import { LoadableAfterPaint, LoadablePaint } from '@confluence/loadable';

export const LazyHomepageCard = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-HomepageCard" */ './HomepageCard')).HomepageCard,
});

export const LazyTeamCalendarSiteSettingsPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TeamCalendarSiteSettingsPage" */ './site-settings/TeamCalendarSiteSettingsPage'
			)
		).TeamCalendarSiteSettingsPage,
});

export { ADMIN_TEAM_CALENDARS_ANALYTICS_SOURCE } from '../src/site-settings/teamCalendarSiteSettingsConstants';
