import { PageVisibleState } from '../../metric/base-metric';
import { visibilityChangeObserver } from '../../observer/visibility-change-observer';
import { webVitalsObserver } from '../../observer/web-vitals-observer';
import type {
	BaseMetricDataWithStartAndStop,
	PerformanceEventConfig,
	ShareableGlobalConfig,
} from '../../types';
import { pageVisibleValue } from '../page-visible/value';

export const webVitalsMetrics = async (
	config: PerformanceEventConfig,
	data: BaseMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
) => {
	let pageVisibleState = data.pageVisibleState;
	const setPageVisibleStateToMixed = () => {
		pageVisibleState = PageVisibleState.MIXED;
	};
	visibilityChangeObserver.subscribe(setPageVisibleStateToMixed);
	const ttciEnabled =
		globalConfig.webVitals?.ttciEnabled !== undefined ? globalConfig.webVitals?.ttciEnabled : true;
	const webVitalMetrics = await webVitalsMetricCustomTimeout(
		ttciEnabled,
		globalConfig.webVitals?.timeout || 30000,
	);
	visibilityChangeObserver.unsubscribe(setPageVisibleStateToMixed);
	return {
		...webVitalMetrics,
		'pageVisible:state': pageVisibleState,
		...pageVisibleValue(config, data, globalConfig),
	};
};

// exported to allow testing
export const webVitalsMetricCustomTimeout = async (ttciEnabled: boolean, timeout: number) => {
	// wait for webVitalsObserver.ttciDetermined if ttciEnabled is true
	await (ttciEnabled ? webVitalsObserver.ttciDetermined : Promise.resolve());
	// wait up to $timeout ms longer for web-vitals until returning
	const timeoutWindow = new Promise((resolve) => setTimeout(resolve, timeout));
	await Promise.race([timeoutWindow, webVitalsObserver.webVitalsDetermined]);
	return webVitalsObserver.data;
};
