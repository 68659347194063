import { useContext } from 'react';

import { LoadableAncestorsContext } from '../LoadableAncestors';

/**
 * Place holder id is different than the __loadable_id__
 *
 * It is used for uniquely identifying SSR rendered result for each loadable component.
 * When we collect the rendered result we assign index for each loadable component.
 * For example if we rendered component A in 5 places then we would assign index 0-4 for each HTML snippet. See ./placeholders.ts
 *
 * This has a problem because we don't always render all the occurrences of a loadable component on the server-side.
 * For example if we only render component A in the last 3 places and assign 0-2 for those snippets.
 * When we rehydrate SPA we would grab the wrong placeholder as when waiting for the first 2 places.
 *
 * This function solves this problem by hashing the ancestors id has part of the placeholder id.
 * This means placeholders that rendered by different code path can be identified uniquely.
 */
export function usePlaceholderId(id: string | undefined, idOverride?: string) {
	const { ancestors } = useContext(LoadableAncestorsContext);
	if (idOverride) {
		return idOverride;
	}

	const ids = [id];
	for (const ancestor of ancestors) {
		// Because SPA and SSR App roots from different components.
		// SPA's root is Loadable(ViewOrEditRoute)
		// SSR App's root is Loadable(MainLayout)
		// We need to stop at the common ancestor.
		if (ancestor.rootForServerPlaceholderId) {
			break;
		}
		// RouteComponent when set as loadables in classic package
		// do not have a similar mapping in SSR
		// causing hierarchy mismatch issues
		if (!ancestor.name?.includes('Route')) {
			ids.push(ancestor.id);
		}
	}
	return ids.join(':');
}
