import type { ThemeState } from '@atlaskit/tokens';

import { getApolloClient } from '@confluence/graphql';
import type { API_DEFAULT_THEME_STATE } from '@confluence/theming-utils';
import {
	getEffectiveThemeState,
	getEffectiveThemeStateSerialized,
} from '@confluence/theming-utils';

import { ErrorSubjects, ErrorSubjectIds, reportThemingError } from './errorHandling';
import type { ThemePreferenceSelectorQuery as ThemePreferenceSelectorQueryType } from './graphql/__types__/ThemePreferenceSelectorQuery';
import type {
	ThemePreferenceMutation as ThemePreferenceMutationType,
	ThemePreferenceMutationVariables,
} from './graphql/__types__/ThemePreferenceMutation';
import { ThemePreferenceSelectorQuery } from './graphql/ThemePreferenceQuery.graphql';
import { ThemePreferenceMutation } from './graphql/ThemePreferenceMutation.graphql';

export class RemoteThemeStorage {
	public static async getThemeRemote(): Promise<Partial<ThemeState>> {
		const { data, errors } = await getApolloClient().query<ThemePreferenceSelectorQueryType>({
			query: ThemePreferenceSelectorQuery,
		});

		if (errors) {
			throw errors;
		}

		return getEffectiveThemeState(data.userPreferences.theme);
	}

	public static async saveThemeRemote(
		theme: Partial<ThemeState> | typeof API_DEFAULT_THEME_STATE,
	): Promise<void> {
		try {
			const { errors } = await getApolloClient().mutate<
				ThemePreferenceMutationType,
				ThemePreferenceMutationVariables
			>({
				mutation: ThemePreferenceMutation,
				variables: { theme: getEffectiveThemeStateSerialized(theme) },
			});

			if (errors) {
				const attributes = {
					errors: errors.map((error) => ({
						message: error.message,
						stack: error.stack,
					})),
					theme,
				};
				await reportThemingError(
					ErrorSubjects.remoteThemeStorage,
					ErrorSubjectIds.apolloMutationErrors,
					attributes,
				);
			}
		} catch (e) {
			const attributes = {
				message: e.message,
				stack: e.stack,
				theme,
			};
			await reportThemingError(
				ErrorSubjects.remoteThemeStorage,
				ErrorSubjectIds.saveThemeRemote,
				attributes,
			);
		}
	}
}
