import { query } from '@confluence/query-preloader-tools';

import { FavouriteSpaceQuery } from './FavouriteSpaceQuery.graphql';

export function preloadSpaceStarTextButton(spaceKey: string) {
	return query({
		query: FavouriteSpaceQuery,
		variables: {
			spaceKey,
		},
	});
}
