import { LoadableAfterPaint } from '@confluence/loadable';

export const AnalyticsByLine = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-componentsAnalyticsByLine" */ './components/AnalyticsByLine'
			)
		).AnalyticsByLine,
});

export const SpaceAnalyticsPageLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-componentsSpaceAnalyticsPage" */ './components/SpaceAnalytics/SpaceAnalyticsPage'
			)
		).SpaceAnalyticsPage,
});

export const PageAnalyticsPageLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-componentsPageAnalyticsPage" */ './components/PageAnalytics/PageAnalyticsPage'
			)
		).PageAnalyticsPage,
});

export const SiteAnalyticsPageLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-componentsSiteAnalyticsPage" */ './components/SiteAnalytics/SiteAnalyticsPage'
			)
		).SiteAnalyticsPage,
});
