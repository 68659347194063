/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::dafc75eee1de4f1c30b8ca3212cef373>>
 * @codegenId #form-footer
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen form-footer
 * @codegenParams { "props": { "children": true, "align": true } }
 * @codegenDependency ../../../../../../../design-system/form/src/form-footer.tsx <<SignedSource::3b105b4ff989bfd7acc72a9571893353>>
 * @codegenDependency ../../../../../../../design-system/form/src/types.tsx <<SignedSource::ef36f00504d6991104143c9dae284802>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { FormFooter as PlatformFormFooter } from '@atlaskit/form';

type PlatformFormFooterProps = React.ComponentProps<typeof PlatformFormFooter>;

export type FormFooterProps = Pick<
  PlatformFormFooterProps,
  'children' | 'align'
>;

export const FormFooter = (props: Parameters<RenderFn>[0]) => {
  const {
    align,
  } = props.forgeDoc.props as FormFooterProps;
  return (
    <PlatformFormFooter
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      align={align}
    />
  );
};
/**
 * @codegenEnd
 */