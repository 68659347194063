import React from 'react';
import Placement from '@atlassian/post-office-placement/placement';
import { ScreenSpaceFlagsPlacementComponent } from '@post-office-placement/screen-space-flags/placement';
import { fg } from '@confluence/feature-gating';
import { ScreenSpaceFlagPlacementPreviewSwitcher } from '@atlassian/post-office-placement/preview';
export const ScreenSpaceFlagsPlacement = () => {
	return fg('post-office-screen-space-overlay-confluence') ? (
		<ScreenSpaceFlagPlacementPreviewSwitcher
			// https://switcheroo.atlassian.com/ui/gates/ad7460cd-a0fe-4594-8b6a-b2549d888133/key/post-office_enable_screen-space-preview_confluence
			isEnabled={fg('post-office_enable_screen-space-preview_confluence')}
		>
			<Placement
				placementId="screen-space-flags"
				PlacementComponent={ScreenSpaceFlagsPlacementComponent}
				disableInitialData
			/>
		</ScreenSpaceFlagPlacementPreviewSwitcher>
	) : null;
};
