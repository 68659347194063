import { combineReducers } from 'redux';
import { Map } from 'immutable';
import fabricEditor from '@confluence-classic/confluence-fabric-editor/src/reducers/index';
import editor from '@confluence-classic/confluence-fabric-editor/src/reducers/editor';
import { entities } from '@confluence-classic/confluence-legacy-redux'; // 20KB

import router from './router'; // 0 KB
import session from './session'; // 0KB
import views from './views'; // 110KB
import modal from './modal'; // 0KB0KB
import discoveredFeatures from './discovered-features'; // 0 KB

const isServerRenderedPage = (state = null) => state;
const meta = (state = Map()) => state;

export default function (extraReducers, browserHistory) {
	return combineReducers({
		router: router(browserHistory),
		session,
		views,
		entities,
		meta,
		modal,
		discoveredFeatures,
		editor,
		fabricEditor,
		isServerRenderedPage,
		...extraReducers,
	});
}
