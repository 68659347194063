import { type Fetcher, fetchPlacementData } from '@atlassian/post-office-placement-data';
import { type SerializableRecord } from '@post-office/serializable-record';
import { useEffect, useMemo, useRef } from 'react';

import { AlreadyRunningError, type DataFetcher, useData } from './useData';

const depsIsValid = (deps: React.DependencyList | undefined): deps is React.DependencyList =>
	Array.isArray(deps);

export const createUsePlacement = (
	placementId: string,
	fetcher: Fetcher = { fetch: (...args) => globalThis.fetch(...args) },
) => {
	if (!fetcher.fetch) {
		throw new Error(
			'No fetcher found. If you are using usePlacement outside or a browser you must supply a fetcher yourself',
		);
	}

	return <TData extends SerializableRecord, TContext extends SerializableRecord>(
		path: string,
		context: TContext,
		requestOptions?: RequestInit,
		config?: {
			deps?: React.DependencyList;
			isEnabled?: boolean;
			throttleTime?: number;
		},
	) => {
		const dependencyList = depsIsValid(config?.deps)
			? [...config.deps, config?.isEnabled]
			: [path, config?.isEnabled];

		const timerRef = useRef<NodeJS.Timeout>();
		const isRunningPromise = useRef<boolean>(false);

		useEffect(() => {
			return () => {
				if (timerRef.current) {
					clearTimeout(timerRef.current);
				}
			};
		}, []);

		const promiseToResolve = useMemo<DataFetcher | undefined>(
			() => {
				if (config?.isEnabled === false) {
					return undefined;
				}

				return ({ signal }) => {
					if (isRunningPromise.current) {
						return Promise.reject(new AlreadyRunningError());
					}

					isRunningPromise.current = true;

					timerRef.current = setTimeout(() => {
						isRunningPromise.current = false;
					}, config?.throttleTime ?? 0);

					return fetchPlacementData(placementId, path, context, fetcher, {
						...requestOptions,
						signal,
					});
				};
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			dependencyList,
		);

		return useData<TData>(promiseToResolve, {
			analytics: {
				name: placementId,
				path: path,
			},
		});
	};
};
