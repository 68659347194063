import { query } from '@confluence/query-preloader-tools';

import { preloadOptions } from '../Options/preloadOptions';

import { getStarredQueryOptions } from './starredQueryHelpers';

export const preloadStarred = ({ isLoggedIn, isSpaceAliasFFEnabled = false }) => {
	return Promise.all([
		query(getStarredQueryOptions({ isSpaceAliasFFEnabled })),
		preloadOptions({ isLoggedIn }),
	]);
};
