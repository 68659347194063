import type {
	BaseMetricDataWithStartAndStop,
	PerformanceEventConfig,
	ShareableGlobalConfig,
} from '../../types';

export const hostname = (
	_config: PerformanceEventConfig,
	_data: BaseMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
) => {
	return { hostname: globalConfig.hostname };
};
