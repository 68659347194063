// Clients can optionally provide the callbackTimeoutPeriod parameter
export type SchedulerOptions = {
	minRetryDelay: number;
	maxRetryDelay: number;
	backoffFactor: number;
	backoffJitterPercentage: number;
	waitInterval: number;
	callbackTimeoutPeriod: number;
};

export type OnDoneFn = {
	(state: DoneState): void;
};

export type OnTimeoutFn = {
	(): void;
};

export type CallbackFn = {
	(done: OnDoneFn): void;
};

export enum DoneState {
	NOOP,
	SUCCESS,
	ERROR,
}
