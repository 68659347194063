import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';

export function isoToCustomDate(date: string, dateFormat: string) {
	return format(parseISO(date), dateFormat);
}

export function customToIsoDate(date: string, dateFormat: string) {
	return formatISO(parse(date, dateFormat, new Date()), {
		representation: 'date',
	});
}

// TODO: PGXT-4540 Fix non-string parameters being passed around as strings
export const parseBoolean = (value?: boolean | string): boolean => {
	if (typeof value === 'boolean') {
		return value;
	}
	return value === 'true';
};
