import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';

import type { CTAProps } from '../../../common/useCTA';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapperWithDot = styled.span({
	marginTop: 0,
	'@media (min-width: 480px)': {
		'&::before': {
			content: '·',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	display: 'inline-block',
	margin: `${token('space.100', '8px')} 0 0 0`,
	'@media (min-width: 480px)': {
		margin: `0 0 0 ${token('space.100', '8px')}`,
	},
});

const CTAButtonComponent = ({ text, onClick }: CTAProps) => (
	<Button appearance="link" spacing="none" onClick={onClick}>
		{text}
	</Button>
);

type CTAButtonProps = {
	data: CTAProps;
	showWithDot?: boolean;
};

export const CTAButton = ({ data, showWithDot = false }: CTAButtonProps) => {
	if (showWithDot) {
		return (
			<ButtonWrapperWithDot>
				<ButtonWrapper>
					<CTAButtonComponent {...data} />
				</ButtonWrapper>
			</ButtonWrapperWithDot>
		);
	} else {
		return <CTAButtonComponent {...data} />;
	}
};
