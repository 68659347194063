import { getProfileClient } from '@confluence/profile';
import type { FeatureFlagsType } from '@confluence/session-data';

import type { ProfileCardProvider, ProfileCardGetActions } from './types';

export function getProfileCardProvider(
	cloudId: string,
	getActions: ProfileCardGetActions,
	featureFlags: FeatureFlagsType,
): Promise<ProfileCardProvider> {
	return Promise.resolve({
		cloudId,
		resourceClient: getProfileClient(cloudId, featureFlags),
		getActions,
	});
}
