import { GraphQLError } from 'graphql';

export function isGraphQLError(value: unknown): value is GraphQLError {
	if (process.env.NODE_ENV === 'testing') {
		// jest messes up globals badly, see https://github.com/facebook/jest/issues/2549
		// once that issue is fixed, the usages of this function can be inlined to `error instanceof GraphQLError`
		return (value as any)?.name === 'GraphQLError';
	}

	return value instanceof GraphQLError;
}
