import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { type ForgeDoc } from '@atlassian/forge-ui-types';

import { type ComponentMap, RendererNext } from '..';
import { makeAvatar, makeAvatarStack, makeNativeUserPicker } from '../../components';

import { WebRuntime } from '../../web-runtime';
import { type UIKitRendererProps } from './types';
import { uncurlyUuid } from './utils';

export const UIKitRenderer = (props: UIKitRendererProps) => {
	const {
		accountId,
		bridge,
		client,
		components,
		coreData,
		contextIds,
		entryPoint,
		extension,
		extensionData,
		extensionViewData,
		getContextToken,
		mentionProvider,
		product,
		locale,
		modalExtension,
		onForgeDocUpdated,
		getThreeLOPrompt,
		customBridgeMethods,
		loadingComponent,
		onError,
		extensionPayload,
	} = props;

	const { cloudId, workspaceId } = coreData;

	const [forgeDoc, setForgeDoc] = useState<ForgeDoc | undefined>(undefined);
	const [error, setError] = useState<Error | undefined>(undefined);

	const wiredComponents = useMemo(
		() => ({
			Avatar: makeAvatar({ client }),
			AvatarStack: makeAvatarStack({ client }),
			UserPicker: makeNativeUserPicker({
				client,
				mentionProvider,
				accountId,
				...(workspaceId ? { cloudId: 'bitbucket' } : { cloudId }),
				productAttributes: workspaceId ? { workspaceIds: [uncurlyUuid(workspaceId)] } : undefined,
				productKey: product,
			}),
		}),
		[accountId, client, cloudId, mentionProvider, product, workspaceId],
	);

	const getComponents = useCallback(
		(defaults: ComponentMap) => ({
			...defaults,
			...wiredComponents,
			...components,
		}),
		[components, wiredComponents],
	);

	const handleError = useCallback(
		(error: Error) => {
			if (onError) {
				onError(error.message);
			}
		},
		[onError],
	);

	useEffect(() => {
		if (onError && error) {
			onError(error.message);
		}
	}, [error, onError]);

	return (
		<>
			<RendererNext
				isNative
				forgeDoc={forgeDoc}
				extension={extension}
				error={error?.message}
				components={getComponents}
				modalExtension={modalExtension}
				extensionType={extensionData.type}
				loading
				loadingComponent={loadingComponent}
				onError={handleError}
			/>
			<WebRuntime
				accountId={accountId}
				setForgeDoc={(forgeDoc: ForgeDoc) => {
					if (forgeDoc.type !== 'MacroConfig') {
						setForgeDoc(forgeDoc);
					}
					onForgeDocUpdated?.(forgeDoc);
				}}
				setError={setError}
				apolloClient={client}
				components={getThreeLOPrompt}
				contextIds={contextIds}
				extension={extension}
				coreData={coreData}
				extensionData={extensionData}
				extensionPayload={extensionPayload}
				extensionViewData={extensionViewData}
				locale={locale}
				bridge={bridge}
				getContextToken={getContextToken}
				entryPoint={entryPoint}
				customBridgeMethods={customBridgeMethods}
			/>
		</>
	);
};
