/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContentAnalyticsViewersUnifiedQuery
// ====================================================

export interface ContentAnalyticsViewersUnifiedQuery_contentAnalyticsViewers {
  count: number;
}

export interface ContentAnalyticsViewersUnifiedQuery_user_confluence {
  permissionType: SitePermissionType | null;
}

export interface ContentAnalyticsViewersUnifiedQuery_user {
  id: string | null;
  confluence: ContentAnalyticsViewersUnifiedQuery_user_confluence | null;
}

export interface ContentAnalyticsViewersUnifiedQuery {
  contentAnalyticsViewers: ContentAnalyticsViewersUnifiedQuery_contentAnalyticsViewers | null;
  user: ContentAnalyticsViewersUnifiedQuery_user | null;
}

export interface ContentAnalyticsViewersUnifiedQueryVariables {
  contentId: string;
  fromDate?: string | null;
}
