/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserProfileClientForConfluenceQuery
// ====================================================

export interface UserProfileClientForConfluenceQuery_user_confluence {
  permissionType: SitePermissionType | null;
}

export interface UserProfileClientForConfluenceQuery_user {
  id: string | null;
  confluence: UserProfileClientForConfluenceQuery_user_confluence | null;
}

export interface UserProfileClientForConfluenceQuery_entitlements_externalCollaborator {
  isEntitled: boolean;
}

export interface UserProfileClientForConfluenceQuery_entitlements {
  externalCollaborator: UserProfileClientForConfluenceQuery_entitlements_externalCollaborator;
}

export interface UserProfileClientForConfluenceQuery {
  user: UserProfileClientForConfluenceQuery_user | null;
  entitlements: UserProfileClientForConfluenceQuery_entitlements;
}

export interface UserProfileClientForConfluenceQueryVariables {
  userId?: string | null;
}
