export const areCommonPropertiesEqual = (objectA: any, objectB: any): boolean => {
	for (const key in objectA) {
		if (key in objectB) {
			if (typeof objectA[key] === 'object' && typeof objectB[key] === 'object') {
				const isEqual = areCommonPropertiesEqual(objectA[key], objectB[key]);
				if (!isEqual) {
					return false;
				}
				continue;
			} else if (objectA[key] !== objectB[key]) {
				return false;
			}
		}
	}

	return true;
};
