import { EventBus } from '@confluence/generics';

import type { BMStream } from './types';

/**
 * BM Event Bus
 *
 * An event emitter for browser metrics related events.
 * From this bus you can stream and subscribe to events
 * that would otherwise require stacking new logic into
 * App.js' route handler
 *
 * ```typescript
 * const ID = BMEventBus.on("page-transition", (events) => {
 *  // React to page transitions from anywhere in confluence
 *  // code!
 * });
 *
 * BMEventBus.off("page-transition", ID);
 * ```
 */
export const BMEventBus = new EventBus<BMStream>();
