/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::48d27981b641db10be9a790436d25a22>>
 * @codegenId #flex
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen flex
 * @codegenParams { "props": { "children": true, "as": false, "justifyContent": true, "alignItems": true, "columnGap": true, "gap": true, "rowGap": true, "direction": true, "wrap": true, "testId": true, "data-testid": false, "role": true, "xcss": false } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/flex.tsx <<SignedSource::7148f07a56c498fe6d7f2eee9837f198>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::2f4848af544e0f64968f7c28012cf0f0>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::00aab072ec1c97fd176380fcd46ccf7e>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::a91bf7842593d9052c045e4a40d7ab3b>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Flex as PlatformFlex } from '@atlaskit/primitives';

type PlatformFlexProps = React.ComponentProps<typeof PlatformFlex>;

export type FlexProps = Pick<
  PlatformFlexProps,
  'children' | 'justifyContent' | 'alignItems' | 'columnGap' | 'gap' | 'rowGap' | 'direction' | 'wrap' | 'testId' | 'role'
>;

export const Flex = (props: Parameters<RenderFn>[0]) => {
  const {
    justifyContent,
    alignItems,
    columnGap,
    gap,
    rowGap,
    direction,
    wrap,
    testId,
    role,
  } = props.forgeDoc.props as FlexProps;
  return (
    <PlatformFlex
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      justifyContent={justifyContent}
      alignItems={alignItems}
      columnGap={columnGap}
      gap={gap}
      rowGap={rowGap}
      direction={direction}
      wrap={wrap}
      testId={testId}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
