import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { createContentsFromIdsSelector } from '@confluence-classic/confluence-legacy-redux';
import { getRouteParams, getLocation } from '@confluence-classic/confluence-urls';

import { OK } from '../../constants/status-codes';

import LegacyBridge from './LegacyBridge';

const selectContentsFromIds = createContentsFromIdsSelector();

const mapStateToProps = (state) => {
	let spaceKey;
	let contentId;
	let contentType;

	let createpageProps = {};
	// CreatePage passes draftId as contentId. Use it instead of the one coming from
	if (state.editor.get('draftId') && state.editor.get('statusCode') === OK) {
		contentId = state.editor.get('draftId');
		const createdPage = state.editor.get('result');

		let ancestors;
		let parentPage;
		if (typeof createdPage?.get === 'function') {
			contentType = createdPage.get('type', null);
			ancestors = createdPage.get('ancestors', []);
			parentPage = ancestors.last();
		} else {
			contentType = createdPage.type;
			ancestors = createdPage.ancestors || [];
			parentPage = ancestors[ancestors.length - 1];
		}

		createpageProps = {
			parentPageId: `${
				// So the page can either be a plain object or a record depending on timing...
				// TODO: Get rid of all the redux here.
				parentPage
					? typeof parentPage.get === 'function'
						? parentPage.get('id')
						: parentPage.id
					: 0
			}`, // cast to string
			pageVersion: 0, // enforce version 0, otherwise saving will fail with 409 Conflict
		};
	}

	const routeParams = getRouteParams(state);
	const location = getLocation(state);

	if (!spaceKey) {
		spaceKey = routeParams.spaceKey;
	}

	if (!contentId) {
		// on old viewPage we need `contentId` for request permissions component
		// we can get it as `pageId` from location query;
		contentId = routeParams.contentId || (location.query || {}).pageId;
	}

	if (!contentId && spaceKey) {
		// Fallback to space homepage if no content id found
		contentId = `${state.entities.getIn(['spaces', spaceKey, 'homepageId'])}`; // string
	}

	const currentPage = state.entities.getIn(['contents', `${contentId}`]);

	if (contentId && currentPage) {
		contentType = currentPage.type;
	}
	const editorForCommentsIsEnabled = state.views.viewPage.editor.contentId;
	if (editorForCommentsIsEnabled) {
		contentType = 'comment';
	}

	if (
		['CREATE_TEMPLATE_SPACE', 'EDIT_TEMPLATE_SPACE', 'EDIT_TEMPLATE', 'CREATE_TEMPLATE'].includes(
			location.routeName,
		)
	) {
		contentType = 'template';
	}

	let viewpageProps = {};
	const currentPageVersion = state.entities.getIn(['versions', `${contentId}`], {});
	if (currentPage) {
		const parentPage =
			currentPage.ancestors && currentPage.ancestors.size
				? selectContentsFromIds(fromJS([currentPage.ancestors.last()]), state).first()
				: null;
		viewpageProps = {
			pageTitle: currentPage.title,
			pageVersion: currentPageVersion.number,
			parentPageTitle: parentPage ? parentPage.title : '',
			parentPageId: parentPage ? parentPage.id : '0',
			canRemovePage:
				currentPage.currentUserPermissions && currentPage.currentUserPermissions.includes('delete'),
		};
	}

	const experimentProps = {
		experimentFeatures: state.views.dashboard.get('experimentFeatures'),
	};

	// TODO: there is no simple way to select the parent yet so this code will be commented out till we solve it
	// matches /spaces/{spaceKey}/pages or /spaces/{spaceKey}/pages/ only
	//const isPageTree = /\/spaces\/([~0-9A-z]*)\/pages(\/)?$/.test(location.pathname);
	//const contextualMenuOpenForPageId = state.views.spaceHome.pageTreeContextualMenu;
	//
	//if (isPageTree && !!contextualMenuOpenForPageId) {
	//    const selectedPage = state.entities.getIn(['contents', contextualMenuOpenForPageId]);
	//    if (selectedPage) {
	//        const parentPage = selectedPage.ancestors.size ? selectContentsFromIds(fromJS([selectedPage.ancestors.last()]), state).first() : null;
	//        viewpageProps = {
	//            contentId: selectedPage.id,
	//            pageTitle: selectedPage.title,
	//            parentPageTitle: parentPage ? parentPage.title : null,
	//            parentPageId: parentPage ? parentPage.id : '0',
	//            canRemovePage: selectedPage.currentUserPermissions.includes('delete')
	//        };
	//    }
	//}

	return {
		spaceKey,
		contentId,
		contentType,
		spaceName: state.entities.getIn(['spaces', spaceKey, 'name']),
		spaceType: state.entities.getIn(['spaces', spaceKey, 'type']),
		...viewpageProps,
		...createpageProps,
		...experimentProps,
	};
};

export default connect(mapStateToProps)(LegacyBridge);
export { mapStateToProps };
