import type { Option } from '@atlaskit/editor-common/extensions';

import { cfetch } from '@confluence/network';

const search = async (searchTerm: string) => {
	const res = await cfetch(
		`/wiki/rest/calendar-services/1.0/calendar/search/subcalendars.json?term=${searchTerm}&limit=25&showSubCalendarsInView=true`,
	);

	const resp = await res.json();
	if (!resp.success) {
		return [];
	}

	return resp.payload.map(({ subCalendar: { name, spaceName, id } }) => ({
		label: name,
		description: spaceName,
		value: id,
	}));
};

const fromIds = async (uuids: string[]) => {
	const res = await cfetch(
		`/wiki/rest/calendar-services/1.0/calendar/util/format/subcalendar/ids.json?subCalendarIds=${uuids.join(
			'%2C',
		)}`,
	);

	const resp = await res.json();

	return resp.map(({ name, id }) => ({
		label: name,
		value: id,
	}));
};

export const calendarResolver = async (searchTerm?: string, selectedValue?: string | string[]) => {
	const searchers: Array<Promise<Option[]>> = [];

	if (searchTerm) {
		searchers.push(search(searchTerm));
	}

	if (selectedValue) {
		if (Array.isArray(selectedValue)) {
			searchers.push(fromIds(selectedValue));
		} else {
			searchers.push(fromIds([selectedValue]));
		}
	}

	return (await Promise.all(searchers)).flat();
};
