// parses from the form .../wiki/spaces/{spaceKey}/pages/{contentId}...
const confluencePageContentIdParse =
	/\/wiki\/spaces\/(?<resourceContext>[^\/]+)\/pages\/(?<resourceId>[0-9]+)/;
// parses from the form .../wiki/spaces/{spaceKey}/blog/{YYYY}/{MM}/{DD}/{contentId}...
// parses from the new form .../wiki/spaces/{spaceKey}/blog/{contentId}...
const confluenceBlogContentIdParse =
	/\/wiki\/spaces\/(?<resourceContext>[^\/]+)\/blog\/(?:[0-9]+\/[0-9]+\/[0-9]+\/?)?(?<resourceId>[0-9]+)/;
// parses edit urls in the form .../wiki/spaces/spaceKey/edit|edit-v2/{contentId} and the form .../{contentId}?randomtextuntilthequestionmark
const confluenceBlogEditContentIdParse =
	/\/wiki\/spaces\/(?<resourceContext>[^\/]+)\/(?:blog)\/(?:edit|edit-v2)\/(?<resourceId>[0-9]+)/;
const confluencePageEditContentIdParse =
	/\/wiki\/spaces\/(?<resourceContext>[^\/]+)\/(?:pages)\/(?:edit|edit-v2)\/(?<resourceId>[0-9]+)/;
// parses in the form .../wiki/spaces/{spaceKey}/overview  where the overview is optional
const confluenceSpaceOverviewParse =
	/\/wiki\/spaces\/(?<resourceId>[^\/]+)(\/overview(\/)?)?(?:\?atlOrigin=.*|\?focusedCommentId=.*|\?homepageId=.*)?$/;
// only handle template edit mode links since templates in use are just pages
// .../wiki/spaces/.../templates/edit/{templateId}/ the last slash is optional
// regex uses * instead of + because global templates urls don't have a space name
const confluenceTemplateParse =
	/\/wiki(?:\/|\/spaces\/[^\/]*\/)templates\/edit\/(?<resourceId>.*)(\/)?$/;
const confluenceTinyParse = /\/wiki\/x\/(?<resourceId>[^\/(?)]+)$/;
// parses from the form .../wiki/pages/resumedraft.action?draftId={contentId}...
// used for draft documents copied from the home page of confluence
const confluenceDraftPageContentIdParse =
	/\/wiki\/pages\/resumedraft\.action.*[\?\&]draftId=(?<resourceId>[0-9]+)/;
// parses a page URL with an additional undefined link indicator.  Grabs the spaceId as well as the resourceId.
const confluenceUndefinedLinkParse =
	/\/wiki\/pages\/createpage\.action\?spaceKey=(?<resourceContext>[^&]+)&title=(?<resourceTitle>[^&]+)&linkCreation=true&fromPageId=(?<resourceId>[^&]+)$/;

export const confluencePatterns: RegExp[] = [
	confluencePageContentIdParse,
	confluenceBlogContentIdParse,
	confluenceBlogEditContentIdParse,
	confluencePageEditContentIdParse,
	confluenceSpaceOverviewParse,
	confluenceTemplateParse,
	confluenceTinyParse,
	confluenceDraftPageContentIdParse,
	confluenceUndefinedLinkParse,
];
