import React from 'react';

import { perfMark, WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';

export const SSRReactRootTiming: React.FC = ({ children }) => {
	return (
		<>
			{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_REACT_ROOT}.start`)}
			{children}
			{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_REACT_ROOT}.end`)}
		</>
	);
};
