import gql from 'graphql-tag';

import { ExternalCollaboratorEntitlementFragment } from '@confluence/external-collab-ui/entry-points/ExternalCollaboratorEntitlementFragment';

export const UserProfileClientForConfluenceQuery = gql`
	query UserProfileClientForConfluenceQuery($userId: ID) {
		user(current: false, id: $userId) {
			id
			confluence {
				permissionType
			}
		}
		...ExternalCollaboratorEntitlementFragment
	}

	${ExternalCollaboratorEntitlementFragment}
`;
