import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
/* eslint-disable check-cross-package-imports/dont-import-redux-in-next */
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { goBack, replace } from 'connected-react-router';
import {
	selectorIsAnonymous,
	createSpacesOnlyFromIdsSelector,
} from '@confluence-classic/confluence-legacy-redux';
import { getRouteParams, getLocation } from '@confluence-classic/confluence-urls';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { spaceForBaseSpaceQuery } from '@confluence/load-edit-page/entry-points/LoadEditPageQueries';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useSpaceKey } from '@confluence/space-utils';
import { SpaceBase as SpaceBaseWithoutRedux } from '@confluence/space-base';
import { fg } from '@confluence/feature-gating';

import { getSpaceByKey } from '../../actions/graphql';

import SpaceBaseComponent from './SpaceBaseComponent';

const SpaceBaseComponentSingleSpace = ({ spaceFromApollo, spaceFromRedux, ...props }) => (
	<SpaceBaseComponent
		{...props}
		space={props.isSpaceAliasFFEnabled ? spaceFromApollo : spaceFromRedux}
	/>
);

SpaceBaseComponentSingleSpace.propTypes = {
	spaceFromApollo: PropTypes.object,
	spaceFromRedux: PropTypes.object,
	isSpaceAliasFFEnabled: PropTypes.bool,
};

const selectSpacesFromIds = createSpacesOnlyFromIdsSelector();

const mapStateToProps = (state) => {
	const routeParams = getRouteParams(state);
	const { contentId, contentSlug, spaceKey } = routeParams;

	const viewData = state.views.spaceHome.currentSpace;
	const location = getLocation(state);
	const pathname = location.pathname;

	const spaceFromRedux = selectSpacesFromIds(fromJS([spaceKey]), state).first();
	const isFirstRoute = state.session.history && state.session.history.size === 1;
	const isViewDataFresh = !viewData.get('spaceKey') || viewData.get('spaceKey') === spaceKey;
	const isSpaceFound = !!(isViewDataFresh && viewData.get('isFound')
		? viewData.get('isFound')
		: state.views.spaceHome.homePage.get('isSpaceFound'));
	const isRequesting =
		isViewDataFresh && viewData.get('isRequesting') && viewData.get('spaceKey') === spaceKey;
	const hasFetched = isViewDataFresh && viewData.get('hasFetched');

	// get transition from where to where
	const isViewPage = !!contentId || !!contentSlug;

	return {
		spaceKey,
		spaceFromRedux,
		contentId,
		contentSlug,
		pathname,
		isRequesting,
		hasFetched,
		isFirstRoute,
		isAnonymous: selectorIsAnonymous(state),
		isViewPage,
		location,
		routeParams,
		isSpaceFound,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		getSpaceByKey: (variables) => dispatch(getSpaceByKey(variables)),
		goBackPreviousPage: () => dispatch(goBack()),
		replace: (location) => dispatch(replace(location)),
	};
}

const SpaceBaseConnected = connect(
	mapStateToProps,
	mapDispatchToProps,
)(SpaceBaseComponentSingleSpace);

const SpaceBaseWithRedux = (props) => {
	const spaceKey = useSpaceKey();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { matchRoute } = useContext(RoutesContext);
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const { data } = useQuery(spaceForBaseSpaceQuery, {
		variables: { spaceKey, includeAlias: isSpaceAliasFFEnabled },
		// The fetchPolicy is cache-only because this Apollo query is a replacement for reading from the Redux store.
		// The network fetching is done elsewhere, as it was before. The Apollo query must be used to get the space data
		// because the Redux store does lookup by the space key returned by the BE instead of the space key argument
		// passed to the query. When a space alias is being used, these keys are different and the Redux store does not
		// work as expected.
		fetchPolicy: 'cache-only',
		skip: !isSpaceAliasFFEnabled || !spaceKey,
	});
	const spaceFromApollo = data?.spaces?.nodes?.[0];

	return (
		<SpaceBaseConnected
			{...props}
			isSpaceAliasFFEnabled={isSpaceAliasFFEnabled}
			spaceFromApollo={spaceFromApollo}
			createAnalyticsEvent={createAnalyticsEvent}
			matchRoute={matchRoute}
		/>
	);
};
export default (props) => {
	const isSpaceBaseWithoutReduxEnabled = fg('confluence_frontend_use_space_base_without_redux');
	if (isSpaceBaseWithoutReduxEnabled) {
		return <SpaceBaseWithoutRedux {...props} />;
	} else {
		return <SpaceBaseWithRedux {...props} />;
	}
};
