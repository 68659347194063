import type { ForgeDoc } from '@atlassian/forge-ui-types';

// Convert child to number if possible
// Badge `max` prop will only work if the child is a number
const getBadgeChildren = (forgeDoc: ForgeDoc) => {
  const child = forgeDoc.children?.[0]?.props?.text
  if(child && !isNaN(Number(child))) {
    return Number(child);
  }
  return child;
}

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::1d95ef8748eea211a4fc001a18f27718>>
 * @codegenId #badge
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen badge
 * @codegenParams { "props": { "appearance": true, "children": { "value": "getBadgeChildren(props.forgeDoc)" }, "max": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/badge/src/types.tsx <<SignedSource::22d0f399402dd1010cb5590baa6eab60>>
 * @codegenDependency ../../../../../../../design-system/badge/src/internal/utils.tsx <<SignedSource::65073689541a3ebadc1b310b053042dc>>
 * @codegenDependency ../../../../../../../design-system/badge/src/badge.tsx <<SignedSource::6f9229d8f91d254274c1f2b7922dbae2>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformBadge from '@atlaskit/badge';

type PlatformBadgeProps = React.ComponentProps<typeof PlatformBadge>;

export type BadgeProps = Pick<
  PlatformBadgeProps,
  'appearance' | 'children' | 'max' | 'testId'
>;

export const Badge = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    max,
    testId,
  } = props.forgeDoc.props as BadgeProps;
  return (
    <PlatformBadge
      appearance={appearance}
      children={getBadgeChildren(props.forgeDoc)}
      max={max}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
