import AnalyticsWebClient, { envType } from '@atlassiansox/analytics-web-client';

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import type { WebStorageType } from '../../../services/web-storage-service/types';
import type { ConsentPreferences } from '../../../types';
import { getBscGlobalState } from '../bsc-global-state';
import { Logger } from '../logger';
import { packageName, packageVersion } from '../package-info';

import { withAnalyticsEventSampling } from './with-sampling';

const getEnvironment = () => {
	const { hostname } = window.location;

	if (hostname.includes('.dev.') || hostname.includes('.stg.')) {
		return envType.STAGING;
	}
	if (
		hostname.includes('localhost') ||
		hostname.includes('ngrok') ||
		hostname.includes('atlastunnel')
	) {
		return envType.DEV;
	}

	return envType.PROD;
};

// As many Atlassian products all have the same origin and we don't want the whole path for security reasons
// we'll extract the subpath for some additional context around our products if it exists
export const getProductSubPath = (path: string | null): string => {
	let subPath = '';

	if (!path) {
		return subPath;
	}
	const checkPath = (match: string) => {
		return new RegExp(`^${match}(/|$)`).test(String(path));
	};

	if (checkPath('/wiki')) {
		subPath = '/wiki';
	}
	if (checkPath('/jira')) {
		subPath = '/jira';
	}
	if (checkPath('/compass')) {
		subPath = '/compass';
	}
	if (checkPath('/canvas')) {
		subPath = '/canvas';
	}
	if (checkPath('/servicedesk') || checkPath('/jira/servicedesk')) {
		subPath = '/servicedesk';
	}

	return subPath;
};

const getProductFromDomain = (domain: string) => {
	if (domain === 'https://trello.com') {
		return 'trello';
	}

	return null;
};

export const getContextualPageDetails = () => {
	const { origin, pathname } = window.location;
	const productPath = getProductSubPath(pathname);
	const domain = `${origin}${productPath}`;

	const product = getProductFromDomain(domain) || productPath.slice(1);

	return {
		domain: domain,
		product: product,
	};
};

const decorateEventAttributes = (
	attributes?:
		| OperationalEventAttributes
		| UIEventAttributes
		| ScreenEventAttributes
		| TrackEventAttributes,
) => {
	const { domain, product } = getContextualPageDetails();

	return {
		packageName,
		packageVersion,
		domain,
		product: getBscGlobalState().product?.toLowerCase() ?? product,
		...(attributes && attributes),
	};
};

export const getAnalyticsClient = (() => {
	let instance: AnalyticsWebClient | undefined;
	return () => {
		try {
			if (!instance && getBscGlobalState().analyticsEnabled) {
				instance = new AnalyticsWebClient(
					{
						env: getEnvironment(),
						product: 'atlassianCookies',
					},
					{
						// useLegacyUrl tells the analytics client to make calls directly to Global Analytics Service.
						// When set to false, the client will use a stargate proxy for the current URL to send events
						// to GAS. Said another way:
						// true => events are sent to https://api-private.stg.atlassian.com/gasv3/api/v1/batch
						// false => events are sent to <the current domain>/gasv3/api/v1/batch
						//
						// By default, this should be set to false.  If you need to validate that analytics events
						// are being transmitted correctly in local dev, you can temporarily enable it since localhost
						// won't be able to proxy to GAS via stargate.
						useLegacyUrl: false,
						disableCookiePersistence: true,
					},
				);
			}
		} catch (e) {
			// Log  errors thrown in constructor initialization for the AnalyticsWebClient
			Logger.error(`Failed to initialize AnalyticsWebClient. ${e}`);
		}
		if (!getBscGlobalState().analyticsEnabled) {
			return undefined;
		}
		// Return the instance, even if it is undefined
		return instance;
	};
})();

export type UICheckboxChangeAttributes = {
	isSelected: boolean;
};

export type UIEventAttributes = UICheckboxChangeAttributes | {};

export type ScreenEventAttributes = {};
export type TrackEventAttributes = {};

export type OperationalEventAttributes =
	| OperationalThirdPartyAttributes
	| OperationalPreferencesAttributes
	| OperationalCookieAttributes
	| OperationalSetCookieAttributes
	| OperationalStorageItemAttributes
	| OperationalSetStorageItemAttributes
	| OperationalBannerAppearanceAttributes
	| {};

export interface OperationalThirdPartyAttributes {
	thirdParty: string;
	wasRejected: boolean;
}

export interface OperationalPreferencesAttributes {
	preferences: ConsentPreferences;
}

export interface OperationalCookieAttributes {
	cookieKey: string;
}

export interface OperationalSetCookieAttributes {
	cookieKey: string;
	wasRejected: boolean;
}
export interface OperationalStorageItemAttributes {
	itemKey: string;
}

export interface OperationalSetStorageItemAttributes {
	itemKey: string;
	wasRejected: boolean;
	storageType?: WebStorageType;
}

export interface OperationalBannerAppearanceAttributes {
	timeSinceDismissal: number;
}

export type OperationalEventAction =
	| 'bannerAppearanceAfterDismissal'
	| 'usedSetCookie'
	| 'usedSetCookieError'
	| 'usedGetCookie'
	| 'usedGetCookieError'
	| 'usedDocumentCookie'
	| 'usedDocumentCookieError'
	| 'usedSetStrictlyNecessaryCookie'
	| 'usedSetStrictlyNecessaryCookieError'
	| 'usedCheckThirdParty'
	| 'checkThirdPartyError'
	| 'usedDeleteCookie'
	| 'usedDeleteCookieError'
	| 'initializeControlsError'
	| 'loadStorageControlsError'
	| 'updatePreferencesError'
	| 'fetchConsentPreferencesError'
	| 'saveConsentPreferencesError'
	| 'usedAtlBrowserStorageGetItem'
	| 'usedAtlBrowserStorageGetItemError'
	| 'usedAtlBrowserStorageSetItem'
	| 'usedAtlBrowserStorageSetItemError'
	| 'usedAtlBrowserStorageSetStrictlyNecessaryItem'
	| 'usedAtlBrowserStorageSetStrictlyNecessaryItemError'
	| 'usedCheckIfSetByPackageError'
	| 'renderConsentBannerError';

export function sendPackageOperationalEvent(properties: {
	action: OperationalEventAction;
	attributes?: OperationalEventAttributes;
}) {
	withAnalyticsEventSampling(properties.action, () =>
		getAnalyticsClient()?.sendOperationalEvent({
			source: 'package',
			actionSubject: 'package',
			action: properties.action,
			attributes: decorateEventAttributes(properties.attributes),
		}),
	);
}

export function sendUIEvent(properties: {
	action: 'clicked';
	actionSubject: 'link' | 'button' | 'checkbox' | 'area';
	actionSubjectId: string;
	source: 'cookieConsentModal' | 'cookieConsentBanner';
	attributes?: UIEventAttributes;
}) {
	getAnalyticsClient()?.sendUIEvent({
		...properties,
		attributes: decorateEventAttributes(properties.attributes),
	});
}

export function sendScreenEvent(properties: {
	name: 'cookieConsentModal' | 'cookieConsentBanner';
	attributes?: {};
}) {
	getAnalyticsClient()?.sendScreenEvent({
		...properties,
		attributes: decorateEventAttributes(properties.attributes),
	});
}

export function sendTrackEvent(properties: {
	source: 'cookieConsentModal' | 'cookieConsentBanner';
	action: 'submitted';
	actionSubject: 'cookieConsentPreferences';
	attributes?: {};
}) {
	getAnalyticsClient()?.sendTrackEvent({
		...properties,
		attributes: decorateEventAttributes(properties.attributes),
	});
}
