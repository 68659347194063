import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';

import { EXTERNAL_SHARE } from '@confluence/named-routes';

import { ExternalShareContext } from './ExternalShareContext';

type ExternalShareContextProviderProps = {
	path: string;
	children?: ReactNode;
};

export const ExternalShareContextProvider: FC<ExternalShareContextProviderProps> = ({
	children,
	path,
}) => {
	const externalShareContextValue = useMemo(
		() => ({
			isExternalShareRequest: !!EXTERNAL_SHARE.match(path),
		}),
		[path],
	);

	return (
		<ExternalShareContext.Provider value={externalShareContextValue}>
			{children}
		</ExternalShareContext.Provider>
	);
};
