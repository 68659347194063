/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::8224ba9b1592919d0993fc441f32c80d>>
 * @codegenId #modal-footer
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-footer
 * @codegenParams { "props": { "children": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-footer.tsx <<SignedSource::f4739a8ea0895ce7f2b3e78e9c6b7510>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::bdfd75a709534a0de60ff52436ad515a>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/constants.tsx <<SignedSource::b046819e1c384364b93a2f5e229c2e92>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalFooter as PlatformModalFooter } from '@atlaskit/modal-dialog';

type PlatformModalFooterProps = React.ComponentProps<typeof PlatformModalFooter>;

export type ModalFooterProps = Pick<
  PlatformModalFooterProps,
  'children' | 'testId'
>;

export const ModalFooter = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ModalFooterProps;
  return (
    <PlatformModalFooter
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
