import { query } from '@confluence/query-preloader-tools';
import {
	MacroContentRendererQueryWithTags,
	MacroBodyRendererQueryWithTags,
} from '@confluence/fabric-extension-queries';

export function preloadMacrosSPA() {
	const macroParams: any[] = window['__LEGACY_MACRO_PARAMS__'] || [];
	window['__LEGACY_MACRO_PARAMS__'] = [];

	return Promise.all(
		macroParams.map(({ isMacroContentAndBody, ...rest }) => {
			const preloadMacroQuery = isMacroContentAndBody
				? MacroBodyRendererQueryWithTags
				: MacroContentRendererQueryWithTags;

			return query({
				query: preloadMacroQuery,
				variables: rest,
				context: { single: true },
			});
		}),
	);
}
