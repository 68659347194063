import Cookies, { type CookieAttributes } from 'js-cookie';

import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';

/**
 * Deletes a cookie.
 */
export const deleteCookie = (key: string, attributes?: CookieAttributes) => {
	if (fg('platform_moonjelly_bsc_master_killswitch')) {
		// Delete the cookie without package operational events
		// when master killswitch is enabled
		Cookies.remove(key, attributes);
	} else {
		try {
			sendPackageOperationalEvent({
				action: 'usedDeleteCookie',
				attributes: { cookieKey: key },
			});
			Cookies.remove(key, attributes);
		} catch (e: any) {
			Logger.errorWithOperationalEvent({
				action: 'usedDeleteCookieError',
				attributes: { cookieKey: key },
				message: `Failed to use delete cookie. ${e.message || ''}`,
			});
		}
	}
};
