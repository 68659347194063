import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_BLOG_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import SpaceBase from '../../SpaceBase';
import { LazySpaceBlogRedirect } from '../lazyComponents';

export const BlogHomeRoute = () => (
	<SpaceBase>
		<LazySpaceBlogRedirect />
	</SpaceBase>
);

BlogHomeRoute.NAVIGATION_PARAMS = ({ params: { contentId, spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: { name: 'blogHomeScreen' },
		pageState: { spaceKey, contentId },
	},
	MainLayout: {
		navView: CONTAINER_BLOG_ID,
		spaceKey,
	},
});
