import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';

import fragmentMatcherAGGTypes from './types/fragmentMatcherAGGTypes.json';
import { createOnErrorLink } from './links/OnErrorLink';
import { SSRTestLink } from './links/SSRTestLink';
import { SSRLink } from './links/SSRLink';
import { createWrappedFetch } from './createWrappedFetch';

let apolloClient: ApolloClient<Object> | null = null;
const wrappedFetch = createWrappedFetch(false);

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData: {
		__schema: {
			types: fragmentMatcherAGGTypes,
		},
	},
});

export const apolloAGGCache = new InMemoryCache({ fragmentMatcher });

// export for testing
export const clearAGGClient = (): void => {
	apolloClient = null;
};

export const getAGGClient = (): ApolloClient<object> => {
	// Use mock gateway Apollo client in tests
	if ((window as any).MOCK_GATEWAY_APOLLO_CLIENT) {
		return (window as any).MOCK_GATEWAY_APOLLO_CLIENT;
	}

	if (!apolloClient) {
		apolloClient = createApolloClient();
		(window as any).__APOLLO_AGG_CLIENT__ = apolloClient;
	}
	return apolloClient;
};

const createLinks = () => {
	const links = [createOnErrorLink()];

	if (process.env.REACT_SSR) {
		links.push(SSRLink());
		if (process.env.CLOUD_ENV === 'staging' || process.env.CLOUD_ENV === 'branch') {
			links.push(SSRTestLink());
		}
	} else {
		links.push(UFOLoggerLink);
	}

	links.push(
		new HttpLink({
			credentials: 'include',
			uri: '/gateway/api/graphql',
			fetch: wrappedFetch,
		}),
	);

	return from(links);
};

const createApolloClient = (): ApolloClient<object> => {
	if (typeof window['__APOLLO_AGG_STATE__'] === 'object') {
		apolloAGGCache.restore(window['__APOLLO_AGG_STATE__']);
	}
	const client = new ApolloClient({
		// Prevent cache and network query to refresh when they are already preloaded on server-side.
		// Refreshing data will cause component to fallback to loading state.
		ssrMode: Boolean(process.env.REACT_SSR),
		connectToDevTools: process.env.NODE_ENV !== 'production',
		link: createLinks(),
		cache: apolloAGGCache,
	});

	// This is not actual API from Apollo. This field is read in next/packages/ssr-app/src/module-replacements/apollo-client.js
	(client as any).__isAGGClient = true;
	return client;
};
