import type { ReactElement } from 'react';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import type { ExperienceAttributes } from '@confluence/experience-tracker';

import { useDeepCompareEffect } from '../useDeepCompareEffect';
import { getMacroExperienceTracker, MACRO_TRACKER_FF } from '../MacroExperienceTracker';

export type MacroExperienceFailureProps = {
	error: Error;
	mode: string;
	onFailure?: () => void;
	attributes: ExperienceAttributes;
	extensionKey?: string;
	name: string;
	children?: ReactElement | null;
	contentId: string;
	source?: string;
};

export function MacroExperienceFailure({
	attributes,
	children = null,
	contentId,
	extensionKey,
	error,
	mode,
	name,
	source,
}: MacroExperienceFailureProps) {
	const isMacroTrackerEnabled = useBooleanFeatureFlag(MACRO_TRACKER_FF);

	useDeepCompareEffect(() => {
		if (isMacroTrackerEnabled) {
			getMacroExperienceTracker(mode, contentId).fail({
				name,
				attributes: {
					...attributes,
					source,
				},
				error,
			});
		}
	}, [attributes, contentId, extensionKey, error, isMacroTrackerEnabled, mode, name, source]);

	return children;
}
