import { RetryLink } from 'apollo-link-retry';
import type { Operation } from 'apollo-link';

import { NoNetworkError } from '@confluence/network';

// In some circumstances, unmounting a <Query> won't cancel the underlying query,
// causing a retry-loop to continue long after the corresponding UI has
// disappeared (link-to-this-page-dialog was doing this for me -mdean2).
// Seems to need fetchPolicy="cache-first" (the default) and a not-single query
// to show the effect. While that's still an issue, be conservative with the
// max retries.
const NETWORK_ERROR_MAX_RETRIES = 2;
const NETWORK_ERROR_INITIAL_RETRY_DELAY_MS = 500;
const NETWORK_ERROR_MAX_RETRY_DELAY_MS = 5000;

// Query retries are disabled by default: reconsider after fetching feature flags
let retryEnabled = false;

const sendTrackEvent = (error: NoNetworkError, operation: Operation) => {
	void import(
		/* webpackChunkName: "loadable-confluenceanalytics-web-client" */ '@confluence/analytics-web-client'
	).then(({ getAnalyticsWebClient }) => {
		void getAnalyticsWebClient().then((analyticsWebClient) => {
			analyticsWebClient.sendOperationalEvent({
				source: 'ui',
				actionSubject: 'graphql',
				action: 'errored',
				attributes: {
					eventName: 'network.error.retry',
					queryName: operation.operationName,
					priorRetries: operation.getContext().networkRetries || 0,
					message: error.message,
				},
			});
		});
	});
};

export const networkErrorRetryLink = new RetryLink({
	delay: {
		initial: NETWORK_ERROR_INITIAL_RETRY_DELAY_MS,
		max: NETWORK_ERROR_MAX_RETRY_DELAY_MS,
		jitter: true,
	},
	attempts: {
		max: NETWORK_ERROR_MAX_RETRIES,
		retryIf: (error, operation) => {
			// Never retry if feature-flag not enabled
			if (!retryEnabled) return false;

			// Don't retry mutations, regardless of all other concerns
			if (
				operation.query.definitions.some(
					(definition) => 'operation' in definition && definition.operation === 'mutation',
				)
			)
				return false;

			// Do retry when connection failed at the network layer
			if (error instanceof NoNetworkError) {
				sendTrackEvent(error, operation);
				operation.setContext((context: any) => ({
					networkRetries: (context.networkRetries || 0) + 1,
				}));
				return true;
			}

			// All other errors, leave it to a higher link in the chain to retry if it wants to
			return false;
		},
	},
});

export function enableNetworkRetryLink() {
	retryEnabled = true;
}
