import React from 'react';
import { styled } from '@compiled/react';

import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import Heading from '@atlaskit/heading';

import type { CTAProps } from '../common/useCTA';

import type { YAxisSelectProps } from './YAxisSelect';
import { YAxisSelect } from './YAxisSelect';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	marginBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div[role="presentation"]': {
		' &:hover': {
			cursor: 'pointer',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> h3': {
		color: token('color.text.subtle', '#44546F'),
	},
});

export type HeaderBaseProps = {
	title: string;
	tooltip?: {
		text: string;
		iconLabel: string;
	};
	loading: boolean;
	cta?: CTAProps;
	yAxisSelectProps?: YAxisSelectProps;
};

export const HeaderBase = ({ title, tooltip, loading, cta, yAxisSelectProps }: HeaderBaseProps) => (
	<HeaderWrapper data-testid="header-container">
		<TitleWrapper>
			<Heading as="h3" size="small">
				{title}
			</Heading>
			{tooltip && (
				<Tooltip content={tooltip.text} position="bottom-start">
					<EditorPanelIcon label={tooltip.iconLabel} size="medium" testId="infoIcon" />
				</Tooltip>
			)}
		</TitleWrapper>
		{loading && <Spinner size="small" />}
		{typeof yAxisSelectProps !== 'undefined' && (
			<YAxisSelect
				onYAxisValueChange={yAxisSelectProps.onYAxisValueChange}
				yAxisValue={yAxisSelectProps.yAxisValue}
				yAxisActionSubjectPrefix={yAxisSelectProps.yAxisActionSubjectPrefix}
				analyticsSource={yAxisSelectProps.analyticsSource}
			/>
		)}
		{!!cta && !loading && (
			<Button appearance="link" spacing="none" href={cta.href} onClick={cta.onClick}>
				{cta.text}
			</Button>
		)}
	</HeaderWrapper>
);
