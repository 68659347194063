import React from 'react';

import {
	ADMIN_COLOR_SCHEME_ANALYTICS_SOURCE,
	LazyColorSchemePage,
} from '@confluence/site-settings';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const AdminColorSchemeRoute = () => <LazyColorSchemePage />;

AdminColorSchemeRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_COLOR_SCHEME_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
