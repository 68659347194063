import type { MarkdownConversionErrorCaughtAEP } from '../analytics/types';

/**
 * This Error exists so that we can specifically
 * capture and handle this particular error in the
 * ErrorBoundary
 */
export const markdownConversionErrorName = 'MarkdownConversionError';

export class MarkdownConversionError extends Error {
	type: MarkdownConversionErrorCaughtAEP['attributes']['errorSubType'];

	constructor({
		message,
		type,
	}: {
		message: string;
		type: MarkdownConversionErrorCaughtAEP['attributes']['errorSubType'];
	}) {
		super(message);
		this.name = markdownConversionErrorName;
		this.type = type;
	}
}
