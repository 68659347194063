import type { FieldTransformer } from '../types';

export interface AllMacroParams {
	[key: string]: any;
	defaultParameterValue?: string;
}

// This transformer runs for all macros
export const transformEmptyKey: FieldTransformer<AllMacroParams> = {
	// Transform all empty keys ([""]) to `defaultParameterValue`
	transformBefore(params, metadata) {
		if (params[''] != null) {
			params.defaultParameterValue = params[''];
			metadata.defaultParameterValue = metadata[''];
		}
		return params;
	},
	// Transforming them back to [""] here.
	transformAfter(params, metadata) {
		if (params.defaultParameterValue != null) {
			params[''] = params.defaultParameterValue;
			metadata[''] = metadata.defaultParameterValue;
			delete params.defaultParameterValue;
		}
		return params;
	},
};
