export function constructCompatibleRequestInit(requestInit: RequestInit) {
	// referrerPolicy is not supported by Edge pre Chromium:
	if (requestInit && 'referrerPolicy' in requestInit) {
		const { referrerPolicy, ...requestInitWithoutReferrerPolicy } = requestInit;

		try {
			new Request('/', { referrerPolicy });
			// The referrerPolicy seems to be supported. Leave it in requestInit.
		} catch (_) {
			// The referrerPolicy does not seem to be supported. Drop it from
			// requestInit; otherwise, the Request constructor and fetch will throw:
			requestInit = requestInitWithoutReferrerPolicy;
		}
	}

	return requestInit;
}
