import { LoadablePaint, LoadableBackground } from '@confluence/loadable';

export const SpaceManagerLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceManagerContainer" */ './SpaceManager'))
			.SpaceManager,
});

export const NewLozenge = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-NewLozenge" */ './NewLozenge')).NewLozenge,
});
