// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { fg } from '@atlaskit/platform-feature-flags';

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { getPreferences } from '../../../controllers/preferences-controls/get-preferences';
import {
	type CategorizedStorageControlsCache,
	type CategoryStorageType,
	PreferenceCategory,
} from '../../../types';
import { getBscGlobalState } from '../bsc-global-state';
import { isProcessingAllowedByPreferencesLegacy } from '../item-processing-legacy';

export const findStartsWithPatternMatch = (
	itemKey: string,
	startsWith: CategorizedStorageControlsCache['patterns']['startsWith'][string],
): number | null => {
	// Setup initial pattern step and position
	let currentStep = startsWith;
	let position = 0;

	// Iterate through item key symbol by symbol
	while (position < itemKey.length) {
		// The value of item character at the position
		const currentStepValue = currentStep[itemKey[position]];
		// If there is no match for item symbol in cache, this means there is no
		// pattern match for the item key
		if (currentStepValue === undefined) {
			return null;
		}
		// If current step value is a number, this means we reached the end of the pattern
		// and should return category number
		if (typeof currentStepValue === 'number') {
			return currentStepValue;
		}
		// Continue to going through pattern
		currentStep = currentStepValue;
		position++;
	}

	// No pattern match - all patterns longer or shorter than item key
	return null;
};

export const findEndsWithPatternMatch = (
	itemKey: string,
	endsWith: CategorizedStorageControlsCache['patterns']['endsWith'][string],
) => {
	// Return category number if str is found in the trie, otherwise return undefined
	const trieSearch = (str: string): number | undefined => {
		let currentStep: typeof endsWith | number = endsWith;
		let foundString = '';
		let terminalNumber: number | undefined;

		for (let i = 0; i < str.length; i++) {
			const char = str[i];

			currentStep = currentStep[char];
			if (currentStep === undefined) {
				break;
			}

			foundString += char;

			if (typeof currentStep === 'number') {
				terminalNumber = currentStep;
				break;
			}
		}

		if (foundString === str) {
			// Trie contained the complete string
			return terminalNumber;
		} else {
			// Trie did not contain the complete string
			return undefined;
		}
	};

	let position = 0;

	// Iterate through item key symbol by symbol
	while (position < itemKey.length) {
		const remainderMatch = trieSearch(itemKey.slice(position));
		if (remainderMatch !== undefined) {
			return remainderMatch;
		}
		position++;
	}

	return null;
};

/**
 * Synchronously fetches a an item/cookie category from a CategorizedStorageControlsCache that has already been fetched
 *
 * This allows synchronous parts of the code to access cached versions of the cache but still maintain
 * a synchronous signature for clients
 *
 */
export const getItemCategoryFromPassedCache = (
	itemKey: string,
	storageControlsData: CategorizedStorageControlsCache,
	productName?: string,
) => {
	let itemCategoryNumber: number | null = null;
	const { categories, patterns } = storageControlsData;

	let productKeys = {
		...storageControlsData['keys']['all'],
		...(productName ? storageControlsData['keys'][productName] : {}),
	};

	const productStartsWithPatterns = {
		...patterns.startsWith['all'],
		...(productName ? patterns.startsWith[productName] : {}),
	};
	const productEndsWithPatterns = {
		...patterns.endsWith['all'],
		...(productName ? patterns.endsWith[productName] : {}),
	};

	// Find direct match in keys
	if (Object.keys(productKeys).includes(itemKey)) {
		itemCategoryNumber = productKeys[itemKey];
	} else {
		// Find pattern match or null
		itemCategoryNumber = findStartsWithPatternMatch(itemKey, productStartsWithPatterns);

		if (itemCategoryNumber === null) {
			itemCategoryNumber = findEndsWithPatternMatch(itemKey, productEndsWithPatterns);
		}
	}

	const numToCategoryMap = {
		[categories.STRICTLY_NECESSARY]: PreferenceCategory.StrictlyNecessary,
		[categories.FUNCTIONAL]: PreferenceCategory.Functional,
		[categories.ANALYTICS]: PreferenceCategory.Analytics,
		[categories.MARKETING]: PreferenceCategory.Marketing,
		[categories.UNKNOWN]: PreferenceCategory.Unknown,
	};

	return itemCategoryNumber !== null ? numToCategoryMap[itemCategoryNumber] : null;
};

export const getItemCategory = async (
	itemKey: string,
	storageType: CategoryStorageType,
	productName?: string,
): Promise<PreferenceCategory | null> => {
	const storageControlsData = await getBscGlobalState().loadCache(storageType);

	if (!storageControlsData) {
		return null;
	} else {
		return getItemCategoryFromPassedCache(itemKey, storageControlsData, productName);
	}
};

/**
 * Given an item key (WebStorage key or cookie name), fetches preferences and categories and determines whether the key can be set
 *
 * Allows an optional allowedCallback to be passed which is invoked in the case the cookie is allowed to be set.
 * This allows synchronous parts of the code to check a item's status (e.g. the localStorage or document.cookie overrides)
 *
 */
export const isProcessingAllowedByPreferences = async ({
	itemKey,
	allowedCallback = () => {},
	blockedCallback = () => {},
	storageType,
	productName,
}: {
	itemKey: string;
	allowedCallback?: () => void;
	blockedCallback?: ({ itemHasCategory }: { itemHasCategory?: boolean }) => void;
	storageType: CategoryStorageType;
	productName?: string;
}) => {
	if (!fg('platform_moonjelly_bsc_new_caches')) {
		return await isProcessingAllowedByPreferencesLegacy({
			itemKey,
			allowedCallback,
			blockedCallback,
		});
	}

	let shouldProcessItem;

	const itemCategory = await getItemCategory(itemKey, storageType, productName);

	if (itemCategory === PreferenceCategory.StrictlyNecessary) {
		shouldProcessItem = true;
	} else {
		const preferences = await getPreferences();
		let hasPreferencesForCategory = false;

		if (!!preferences) {
			if (!!itemCategory) {
				hasPreferencesForCategory = preferences[itemCategory];
			}
		}
		shouldProcessItem = hasPreferencesForCategory;
	}
	if (shouldProcessItem) {
		allowedCallback?.();
	} else {
		blockedCallback?.({ itemHasCategory: itemCategory != null });
	}
	return shouldProcessItem;
};
