/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { type FieldProps, type NativeToggleProps, type RenderFn } from '@atlassian/forge-ui-types';
import { FormStateChangeNotifier } from '../form';
import { useIsInForm } from '../utils/useIsInForm';

const AKToggle = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.toggle' */
		'@atlaskit/toggle'
	).then((module) => ({
		default: module.default,
	})),
);

const AKField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const wrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
});

const labelStyles = css({
	cursor: 'pointer',
});

export const Toggle = ({ forgeDoc }: Parameters<RenderFn>[0]) => {
	const { defaultChecked, name, label, onChange } = forgeDoc.props as NativeToggleProps & {
		testId: string;
	};

	const [toggleVal, setToggleVal] = React.useState(false || defaultChecked);

	const isInForm = useIsInForm();
	if (!isInForm) {
		const handleToggle = () => {
			setToggleVal((prevVal) => {
				onChange?.(!prevVal);
				return !prevVal;
			});
		};

		return (
			<div css={wrapperStyles}>
				<AKToggle
					name={name}
					isChecked={toggleVal}
					size="large"
					label={label}
					onChange={handleToggle}
				/>
				<span css={labelStyles} onClick={handleToggle}>
					{label}
				</span>
			</div>
		);
	}

	return (
		<AKField defaultValue={defaultChecked} name={name}>
			{({ fieldProps }: { fieldProps: FieldProps }) => {
				const {
					onChange: fieldOnChange,
					value,
					id,
					isDisabled,
					onBlur,
					onFocus,
					name: toggleName,
				} = fieldProps;
				return (
					<div css={wrapperStyles}>
						<FormStateChangeNotifier name={toggleName} value={value} />
						<AKToggle
							onChange={() => {
								fieldOnChange(!value);
								onChange?.(!value);
							}}
							isChecked={value}
							size="large"
							label={label}
							id={id}
							isDisabled={isDisabled}
							onBlur={onBlur}
							onFocus={onFocus}
							name={toggleName}
						/>
						<span
							css={labelStyles}
							onClick={() => {
								fieldOnChange(!value);
								onChange?.(!value);
							}}
						>
							{label}
						</span>
					</div>
				);
			}}
		</AKField>
	);
};
