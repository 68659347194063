import { isEqual } from 'date-fns';
import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { AnimatedGlyphSeries, AreaSeries, LineSeries, TooltipContext } from '@visx/xychart';
import type { Path } from 'd3-path';

import type { LineData, LineDataElement, LineDefinition } from '../ChartBase-types';
import { accessors } from '../ChartBase-utils';

const i18n = defineMessages({
	dataPointAriaLabel: {
		id: 'admin-center.chart-base-subcomponents.data-point-aria-label',
		defaultMessage: '{lineDescription}: {yValue}, Date: {xValue}',
		description: 'The aria label to use on a charts data point.',
	},
	linePathAriaLabel: {
		id: 'admin-center.chart-base-subcomponents.line-path-aria-label',
		defaultMessage: '{lineDescription} line path',
		description: 'The aria label to use on a charts data point.',
	},
	areaPathAriaLabel: {
		id: 'admin-center.chart-base-subcomponents.area-path-aria-label',
		defaultMessage: '{lineDescription} area path',
		description: 'The aria label to use on a charts data point.',
	},
});

// Styles the circle SVG so that it is only visible when it is focused on.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisibleOnFocusCircle = styled.circle({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:focus)': {
		color: 'transparent',
	},
});

type PartialTooltipDatum = { key: string; datum: LineDataElement };
type FullTooltipDatum = PartialTooltipDatum & { index: number };

// This component creates a customized graphic component that is used for accessibility purposes.
// It renders a series of tabbable graphics with meaningful aria labels. On focus, the tooltip
// is displayed for all data points that correspond to the focused on date.
const AccessibleTabController = ({ lines }: { lines: LineDefinition[] }) => {
	const tooltipContext = useContext(TooltipContext);

	// Creates a map of date values to the data points that correspond to that date.
	// The data points are also formatted into the format expected by the tooltip.
	const uniqueDateMap = new Map<string, PartialTooltipDatum[]>();
	lines.forEach(({ data, description }) => {
		data.forEach((item) => {
			const dateKey = `${item.x.valueOf()}`;
			const currentEntries = uniqueDateMap.get(dateKey);
			const newEntry = { key: description, datum: item };
			if (!currentEntries) {
				// Creates a new entry with the new date point.
				uniqueDateMap.set(dateKey, [newEntry]);
			} else {
				// Updates the existing entry with the new data point.
				currentEntries.push(newEntry);
			}
		});
	});

	// Returns an invisible, but tabbable graphic for each unique date.
	return (
		<>
			{Array.from(uniqueDateMap.entries()).map(([uniqueDateKey, uniqueDateValue], index) => {
				// Constructs the datumByKey object that is expected by the tooltip. Sets the object to
				// all data points with the same date results in the tooltip being displayed for all points
				// on the current date when tabbing.
				const datumByKey: {
					[key in string]: FullTooltipDatum;
				} = uniqueDateValue.reduce(
					(result, currentValue) => ({
						...result,
						[currentValue.key]: {
							key: currentValue.key,
							datum: currentValue.datum,
							index,
						},
					}),
					{},
				);

				// Constructs the nearestDatum object that is expected by the tooltip. Sets the nearest datum to
				// the first data point results in the tooltip being displayed near the first line when tabbing.
				const nearestDatum = {
					...uniqueDateValue[0],
					index,
					distance: 0,
				};

				// Constructs the aria label for the data points associated with the current date. It contains the date,
				// which is the same for all related data points, as well as the y-value for each data point.
				const ariaLabel = uniqueDateValue.reduce(
					(result, currentValue) =>
						`${result} - ${
							currentValue.key
						}: ${accessors.yDescriptionAccessor(currentValue.datum)}`,
					`${accessors.xDescriptionAccessor(nearestDatum.datum)}`,
				);

				return (
					<g
						key={`AccessibleTabController-${uniqueDateKey}`}
						tabIndex={0}
						aria-label={ariaLabel}
						onBlur={() => {
							// Closes the tooltip and clears the datum data when the data point is no longer focused on.
							tooltipContext?.updateTooltip((currentState) => ({
								...currentState,
								tooltipData: {
									nearestDatum: undefined,
									datumByKey: {},
								},
								tooltipOpen: false,
							}));
						}}
						onFocus={() => {
							// Opens the tooltip and sets the datum data when the data point is focused on.
							tooltipContext?.updateTooltip((currentState) => ({
								...currentState,
								tooltipData: {
									nearestDatum,
									datumByKey,
								},
								tooltipOpen: true,
							}));
						}}
					/>
				);
			})}
		</>
	);
};

// This component creates a customized AnimatedGlyphSeries component that is used purely for accessibility purposes.
const AccessibleGlyphSeries = ({
	description,
	data,
	dataTestId,
}: {
	description: string;
	data: LineData;
	dataTestId: string;
}) => {
	const { formatMessage } = useIntl();

	return (
		<AnimatedGlyphSeries
			dataKey={description}
			data={data}
			{...accessors}
			renderGlyph={({
				key,
				color,
				x,
				y,
				size,
				onBlur,
				onFocus,
				onPointerMove,
				onPointerOut,
				onPointerUp,
				datum,
			}) => {
				const ariaLabel = formatMessage(i18n.dataPointAriaLabel, {
					lineDescription: description,
					xValue: accessors.xDescriptionAccessor(datum),
					yValue: accessors.yDescriptionAccessor(datum),
				});
				return (
					<VisibleOnFocusCircle
						// Default properties to make things work "as expected".
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="visx-circle-glyph"
						key={key}
						tabIndex={onBlur || onFocus ? 0 : undefined}
						fill={color}
						r={size / 2}
						cx={x}
						cy={y}
						onBlur={onBlur}
						onFocus={onFocus}
						onPointerMove={onPointerMove}
						onPointerOut={onPointerOut}
						onPointerUp={onPointerUp}
						// Custom properties to make things work differently.
						aria-label={ariaLabel}
						data-testid={dataTestId}
					/>
				);
			}}
		/>
	);
};

const getNewPositionDimension = ({
	currentPositionDimension,
	dimensionIncrement,
	maxPositionDimension,
}: {
	currentPositionDimension: number;
	dimensionIncrement: number;
	maxPositionDimension: number;
}) => {
	if (dimensionIncrement > 0) {
		return Math.min(currentPositionDimension + dimensionIncrement, maxPositionDimension);
	} else {
		return Math.max(currentPositionDimension + dimensionIncrement, maxPositionDimension);
	}
};

const DASH_LENGTH = 4;
const getIncrementLength = ({ addLine }: { addLine: boolean }) => {
	if (addLine) {
		return DASH_LENGTH;
	} else {
		// 4 is added here because the line is 2 pixels wide and occupies the start and end positions.
		return DASH_LENGTH + 4;
	}
};

const getDimensionIncrements = ({
	lastSegmentXLength,
	lastSegmentYLength,
	addLine,
}: {
	lastSegmentXLength: number;
	lastSegmentYLength: number;
	addLine: boolean;
}) => {
	// Gets the length of the line point to point using the pythagorean theorem so that we can calculate
	// the percentage of the line that each increment should occupy. This value will then be applied to the
	// x and y lengths to get the related values.
	const lastSegmentLength = Math.sqrt(
		Math.pow(lastSegmentXLength, 2) + Math.pow(lastSegmentYLength, 2),
	);

	const singleIncrementPercentage = getIncrementLength({ addLine }) / lastSegmentLength;
	return {
		xIncrement: singleIncrementPercentage * lastSegmentXLength,
		yIncrement: singleIncrementPercentage * lastSegmentYLength,
	};
};

// Creates a custom curve that draws the segment leading to the specified point as a series of dashes.
// The basis of this code is taken from the d3-shape library's linearCurveFactory function.
const CustomLinearCurveFactory = ({
	context,
	dashedPointIndex,
}: {
	context: Path;
	dashedPointIndex: number | undefined;
}) => {
	let penPosition = { x: 0, y: 0 };
	let pointIndex = 0;
	const lineTo = (x: number, y: number) => {
		// If this is the last data point, then draw the line as a series of dashes.
		if (pointIndex === dashedPointIndex) {
			const lastSegmentXLength = x - penPosition.x;
			const lastSegmentYLength = y - penPosition.y;

			let addLine = false;
			while (penPosition.x < x) {
				const { xIncrement, yIncrement } = getDimensionIncrements({
					lastSegmentXLength,
					lastSegmentYLength,
					addLine,
				});
				const newX = getNewPositionDimension({
					currentPositionDimension: penPosition.x,
					dimensionIncrement: xIncrement,
					maxPositionDimension: x,
				});
				const newY = getNewPositionDimension({
					currentPositionDimension: penPosition.y,
					dimensionIncrement: yIncrement,
					maxPositionDimension: y,
				});

				if (addLine) {
					context.lineTo(newX, newY);
				} else {
					context.moveTo(newX, newY);
				}
				addLine = !addLine;
				penPosition = { x: newX, y: newY };
			}
		} else {
			context.lineTo(x, y);
		}
	};

	let line: number;
	let point: number;
	return {
		areaStart: () => {
			line = 0;
		},
		areaEnd: () => {
			line = NaN;
		},
		lineStart: () => {
			point = 0;
		},
		lineEnd: () => {
			if (line || (line !== 0 && point === 1)) {
				context.closePath();
			}

			line = 1 - line;
		},
		point: (x, y) => {
			(x = +x), (y = +y);
			switch (point) {
				case 0:
					point = 1;
					line ? lineTo(x, y) : context.moveTo(x, y);
					break;
				case 1:
					point = 2; // falls through
				default:
					lineTo(x, y);
					break;
			}

			pointIndex++;
			penPosition = { x, y };
		},
	};
};

const getCurveFactory = (data: LineData) => (context: Path) => {
	const isLastDataPointInProgress = data[data.length - 1]?.isInProgress;
	const definedDataPointCount = data.filter((d) => d.y !== undefined && d.y !== null).length;

	// Draw a dashed line for the last data point if it is in progress.
	return CustomLinearCurveFactory({
		context,
		dashedPointIndex: isLastDataPointInProgress ? definedDataPointCount - 1 : undefined,
	});
};

// Finds the data point in the provided LineData with the corresponding date; returns undefined if not found.
// Note: This is useful for finding processed line data points by date rather than array index, in the event points get filtered/are missing.
const findDataPointByDate = (lineData: LineData, date: Date) =>
	lineData.find((dataPoint) => isEqual(dataPoint.x, date));

// Helper function that, starting for startIndex, searches the given lines in reverse order for
// the first data point corresponding to provided date that have a defined y-value.
const findDefinedDataPointByDateFromIndex = ({
	lines,
	startIndex,
	date,
}: {
	lines: LineDefinition[];
	startIndex: number;
	date: Date;
}) => {
	let previousDataPointIndex = startIndex;
	let previousDataPoint: LineDataElement | undefined = undefined;
	while (previousDataPoint?.y === undefined && previousDataPointIndex > -1) {
		previousDataPoint = findDataPointByDate(lines[previousDataPointIndex--].data, date);
	}
	return previousDataPoint;
};

// This component creates a customized group of lines with specialized behavior in order to support
// dashed lines and accessible glyph series.
// If isAreaChart = true, includes an area series displaying stacked data.
export const CustomLineGroup = ({
	lines,
	isAreaChart,
}: {
	lines: LineDefinition[];
	isAreaChart: boolean;
}) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<AccessibleTabController lines={lines} />
			{lines
				.flatMap(({ description, data }, index) => [
					<LineSeries
						key={`${description}-line`}
						dataKey={description}
						data={data}
						{...accessors}
						strokeLinecap="square"
						curve={getCurveFactory(data)}
						aria-label={formatMessage(i18n.linePathAriaLabel, {
							lineDescription: description,
						})}
						data-testid="CustomLineGroup-LineSeries"
					/>,
					<AccessibleGlyphSeries
						key={`${description}-glyph`}
						description={description}
						data={data}
						dataTestId="CustomLineGroup-AccessibleGlyphSeries"
					/>,
					// Note: AreaSeries renderLine prop is not used, instead favoring adding a LineSeries per area/line, since
					// AreaSeries line rendering (namely renderLine and curve props) does not support dashed line handling.
					isAreaChart ? (
						<AreaSeries
							key={`${description}-area`}
							dataKey={description}
							data={data}
							{...accessors}
							y0Accessor={(d: LineDataElement) => {
								const previousDataPoint = findDefinedDataPointByDateFromIndex({
									lines,
									startIndex: index - 1,
									date: d.x,
								});
								return previousDataPoint?.y || 0;
							}}
							opacity={0.25}
							renderLine={false}
							aria-label={formatMessage(i18n.areaPathAriaLabel, {
								lineDescription: description,
							})}
							data-testid="CustomLineGroup-AreaSeries"
						/>
					) : null,
				])
				// Reverses the order of the rendered items so that earlier lines render on top of later lines.
				.reverse()}
		</>
	);
};
