import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import { fg } from '@atlaskit/platform-feature-flags';

import { MessageDeliveryStatus } from './constants';
import type {
	Attributes,
	ILogger,
	LoggerState,
	MessageActions,
	MessageDeliveryStatuses,
} from './types';

export class Logger implements ILogger {
	protected state: LoggerState;

	/**
	 * Utility class to simplify and allow for wider use of sending operational event to the Analytics web client for logging/metrics.
	 *
	 * @param actionSubject A (MessageActions) string denoting either the start or stop phase of a message.
	 * @param actionSubjectId Unique messageId to be logged with this even.
	 * @param metricsData Additional data from plugin to be sent with event for metrics logging.
	 * @param analyticsClient An instance of AnalyticsWebClient, unique to each caller
	 */
	constructor(
		actionSubject: MessageActions,
		actionSubjectId: string,
		metricsData: Attributes,
		analyticsClient: AnalyticsWebClient,
	) {
		this.state = {
			actionSubject: actionSubject,
			actionSubjectId: actionSubjectId,
			metricsData,
			analyticsClient: analyticsClient,
		};
	}

	/**
	 * Send operational event to the Analytics web client for logging/metrics.
	 *
	 * @param action A (MessageDeliveryStatuses) string denoting the ultimate disposition of this message start or stop request.
	 */
	private sendOperationalEvent(action: MessageDeliveryStatuses, additionalAttributes?: Attributes) {
		if (fg('platform.post-office.enable-full-choreographer-logging')) {
			const {
				analyticsClient,
				actionSubject,
				actionSubjectId,
				metricsData: attributes,
			} = this.state;

			void analyticsClient.sendOperationalEvent({
				source: 'choreographer',
				action,
				actionSubject,
				actionSubjectId,
				attributes: {
					...(attributes ?? {}),
					...(additionalAttributes ?? {}),
				},
				tags: ['postOffice'],
			});
		}
	}

	blocked(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.BLOCKED, additionalAttributes);
	}

	error(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.ERROR, additionalAttributes);
	}

	unregistered(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.UNREGISTERED, additionalAttributes);
	}

	started(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.STARTED, additionalAttributes);
	}

	stopped(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.STOPPED, additionalAttributes);
	}
}
