import { useMemo } from 'react';

import { useStringFeatureFlag } from '@confluence/session-data';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

import { EXTENSION_OVERLAY_REMOVAL_LIST_FF } from './legacy-macro-constants';

export const useShouldHideOverlay = (extensionKey: string): boolean => {
	const isLivePage = useIsLivePage();
	const extensionsToHideOverlays = useStringFeatureFlag(EXTENSION_OVERLAY_REMOVAL_LIST_FF) ?? '';

	const shouldHide: boolean = useMemo(() => {
		if (!extensionKey || !extensionsToHideOverlays) {
			return false;
		}

		if (extensionsToHideOverlays === 'ALLOW_ALL' || isLivePage) {
			return true;
		}

		const extensionKeys = extensionsToHideOverlays
			.split(',')
			.map((ext) => ext.trim().toLowerCase());

		return extensionKeys.includes(extensionKey.toLowerCase());
	}, [extensionKey, extensionsToHideOverlays, isLivePage]);

	return shouldHide;
};
