import gql from 'graphql-tag';

export const PermissionsPageContentQuery = gql`
	query PermissionsPageContentQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			currentUser {
				isAdmin
			}
		}
	}
`;
