import type { PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { type NodeType } from '@atlaskit/editor-prosemirror/model';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type {
	InvokedFrom,
	InvokedFromTriggerMethod,
} from '../../analytics/analytics-flow/analyticsFlowTypes';
import type { EditorPluginAIConfigItemMarkdown } from '../../config-items/config-items';

export interface EndExperienceAction {
	type: 'END_EXPERIENCE';
	data: { modalDecorationSet?: DecorationSet };
}
export interface ShowModalAction {
	type: 'SHOW_MODAL';
	data: {
		modalDecorationSet: DecorationSet;
		configItem: EditorPluginAIConfigItemMarkdown;
		modalMountedTimeStamp: number;
		lastTriggeredFrom?: InvokedFrom;
		triggerMethod?: InvokedFromTriggerMethod;
		initialPrompt?: string;
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		triggeredFor?: NodeType;
	};
}

export interface AIDecorationExperiencePluginState {
	dispatchAnalyticsEvent: PMPluginFactoryParams['dispatchAnalyticsEvent'];
	modalDecorationSet: DecorationSet;
	configItem?: EditorPluginAIConfigItemMarkdown;
	modalMountedTimeStamp?: number;
	lastTriggeredFrom?: InvokedFrom;
	triggerMethod?: InvokedFromTriggerMethod;
	initialPrompt?: string;
	//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
	triggeredFor?: NodeType;
}

export function reducer(
	state: AIDecorationExperiencePluginState,
	action: EndExperienceAction | ShowModalAction,
): AIDecorationExperiencePluginState {
	switch (action.type) {
		case 'END_EXPERIENCE':
			// When the experience ends the plugin state needs to reset everything back to the initial plugin state
			return {
				modalDecorationSet: DecorationSet.empty,
				dispatchAnalyticsEvent: state.dispatchAnalyticsEvent,
				...action.data,
			};
		case 'SHOW_MODAL':
			return {
				...state,
				...action.data,
			};

		default:
			return state;
	}
}
