import reduce from 'lodash/reduce';
import union from 'lodash/union';
import keys from 'lodash/keys';
import window from 'window-or-global';

/**
 * returns the difference between two objects. To be used when debugging shouldComponentUpdate or componentDidUpdate
 *
 * i.e:
 *
 *      shouldComponentUpdate(nextProps, nextState) {
 *          console.info('props', differences(this.props, nextProps));
 *          console.info('state', differences(this.state, nextState));
 *          return this.props !== nextProps || this.state !== nextState;
 *      }
 *
 *
 * @param a
 * @param b
 */
export const differences = (a, b) => {
	// lodash reduce can reduce over objects, not just arrays
	/* istanbul ignore next */
	const results = reduce(
		a,
		(result, value, key) =>
			value === b[key]
				? result
				: result.concat([`Prop name: '${key}'`, '1:', value, '2:', b[key]], '=========='),
		[],
	);

	/* istanbul ignore next */
	if (!results || results.length === 0) {
		return 'No change!';
	}

	return results;
};

/**
 * Perform a quick shallow comparison between 2 objects. This can be used in shouldComponentUpdate.
 * @param obj1
 * @param obj2
 * @param {Array} omitProps - array of props which will be ingored to compare
 * @returns {boolean}
 */
export const isShallowEqual = (obj1, obj2, omitProps = []) => {
	if (obj1 === obj2) {
		return true;
	}

	const allKeys = union(keys(obj1), keys(obj2));

	for (const key of allKeys) {
		if (omitProps.indexOf(key) >= 0) {
			continue;
		}

		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
};

export const performanceMark = (name) => {
	if (process.env.NODE_ENV !== 'production' && window.performance && window.performance.mark) {
		window.performance.mark(name);
	}
};

export const clearPerformanceMarks = (names) => {
	if (process.env.NODE_ENV !== 'production' && window.performance && window.performance.mark) {
		names.forEach((name) => window.performance.clearMarks(name));
	}
};

export const getDurationBetweenMarks = (markA, markB) => {
	if (
		process.env.NODE_ENV !== 'production' &&
		window.performance &&
		window.performance.getEntriesByName
	) {
		const entriesA = window.performance.getEntriesByName(markA);
		const entriesB = window.performance.getEntriesByName(markB);
		if (entriesA.length && entriesB.length) {
			const [{ startTime: startA }] = entriesA;
			const [{ startTime: startB }] = entriesB;
			return startB - startA;
		}
	}

	return 'N/A';
};

export const measureBetweenMarks = (name, markA, markB) => {
	if (
		process.env.NODE_ENV !== 'production' &&
		window.performance &&
		window.performance.getEntriesByName &&
		window.performance.measure
	) {
		const entriesA = window.performance.getEntriesByName(markA);
		const entriesB = window.performance.getEntriesByName(markB);
		if (entriesA.length && entriesB.length) {
			window.performance.measure(name, markA, markB);
		}
	}
};

export const countPerformanceMarks = (mark) => {
	if (
		process.env.NODE_ENV !== 'production' &&
		window.performance &&
		window.performance.getEntriesByName
	) {
		const entries = window.performance.getEntriesByName(mark);
		return entries.length ? entries.length : 'N/A';
	}

	return 'N/A';
};

export const performanceMarkInProd = (mark) => {
	if (!window.performance) {
		return;
	}

	window.performance.mark(mark);
};
