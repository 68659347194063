import type {
	AllActionTypes,
	SynchronyConnectionStatusType,
	SynchronyBehindStateType,
} from './action-types';
import { ActionTypes } from './action-types';
import type { ResetEditorStateActionType } from '../../actions/types/common-editor-action-types';
import { ActionTypes as CommonEditorActionTypes } from '../../actions/types/common-editor-action-types';
import type { Provider as SynchronyProviderType } from '@atlassian/prosemirror-synchrony-plugin/build/collab-provider';

type ContentReconciliationStatusType = {
	outOfSync?: boolean;
	behind?: SynchronyBehindStateType;
	conflictingRev?: string | null;
	isRequesting: boolean;
	isRecovering?: boolean;
	isRecovered?: boolean;
	hasRequested?: boolean;
};

export type SynchronyStateType = {
	status: SynchronyConnectionStatusType | null;
	contentReconciliationStatus: ContentReconciliationStatusType;
	synchronyConfigMap: SynchronyConfigMap;
	synchronyProviderMap: SynchronyProviderMap;
};

export type SynchronyConfigType =
	| {
			jwt: string;
			url: string;
			appId: string;
			entityId: string | null;
			synchronyExpiry: string;
			worker?: Promise<Worker>;
			websocketWorker?: Promise<Worker>;
	  }
	| false
	| null;

export type SynchronyConfigMap = {
	[key: string]: SynchronyConfigType;
};

export type SynchronyProviderMap = {
	[key: string]: {
		provider?: SynchronyProviderType | null;
		isInitializingProvider: boolean;
		preInitSucceeded: boolean;
	};
};

export const defaultState: SynchronyStateType = {
	// default value is null to indicate a value of first page loading
	status: null,
	synchronyConfigMap: {},
	contentReconciliationStatus: {
		isRequesting: false,
	},
	synchronyProviderMap: {},
};

export function getEntityId(synchronyAppId, contentId) {
	return synchronyAppId === null || contentId === null
		? null
		: `/${synchronyAppId}/confluence-adf-${contentId}`;
}

export default function synchrony(
	state = defaultState,
	action: AllActionTypes | ResetEditorStateActionType,
): SynchronyStateType {
	switch (action.type) {
		case ActionTypes.CONNECTION_OFFLINE:
			return { ...state, status: 'disconnected' };

		case ActionTypes.CONNECTION_ONLINE:
			return { ...state, status: 'connected' };

		case ActionTypes.SYNCHRONY_UPDATE_STATUS:
			return { ...state, status: action.status };

		case CommonEditorActionTypes.RESET_EDITOR_STATE:
			return {
				...defaultState,
				synchronyConfigMap: state.synchronyConfigMap,
			};

		case ActionTypes.INIT_COLLAB_PROVIDER_REQUEST:
			return {
				...state,
				synchronyProviderMap: {
					...state.synchronyProviderMap,
					[action.contentId]: {
						...state.synchronyProviderMap[action.contentId],
						isInitializingProvider: true,
						preInitSucceeded: false,
					},
				},
			};

		case ActionTypes.INIT_COLLAB_PROVIDER_FAILURE:
			return {
				...state,
				synchronyProviderMap: {
					...state.synchronyProviderMap,
					[action.contentId]: {
						provider: null,
						isInitializingProvider: false,
						preInitSucceeded: false,
					},
				},
			};
		case ActionTypes.INIT_COLLAB_PROVIDER_SUCCESS:
			return {
				...state,
				synchronyProviderMap: {
					...state.synchronyProviderMap,
					[action.contentId]: {
						provider: action.provider,
						isInitializingProvider: false,
						preInitSucceeded: action.preInitSucceeded,
					},
				},
			};

		case ActionTypes.RESET_COLLAB_PROVIDER: {
			delete state.synchronyProviderMap[action.contentId];
			return {
				...state,
			};
		}

		default:
			return state;
	}
}
