import type {
	BaseMetricDataWithStartAndStop,
	BasePageLoadMetricDataWithStartAndStop,
	CustomValues,
	PageLoadPerformanceEventConfig,
	PerformanceEventConfig,
	ShareableGlobalConfig,
} from '../../types';

export const productCustomData = async (
	_config: PerformanceEventConfig | PageLoadPerformanceEventConfig,
	data: BaseMetricDataWithStartAndStop | BasePageLoadMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
): Promise<CustomValues | null> => {
	if (!globalConfig.custom && !globalConfig.plugins) {
		return null;
	}
	const result: CustomValues = {};
	if (globalConfig.custom) {
		Object.entries(globalConfig.custom).forEach(([key, value]) => {
			result[`${globalConfig.product}:${key}`] = value;
		});
	}

	if (globalConfig.plugins) {
		const pluginOutputs = await Promise.all(
			globalConfig.plugins.map((plugin) =>
				plugin({
					start: data.start,
					stop: data.stop,
					key: data.key,
				}),
			),
		);
		pluginOutputs.forEach((pluginOutput) => {
			if (pluginOutput === null) {
				return;
			}
			Object.entries(pluginOutput).forEach(([key, value]) => {
				result[`${globalConfig.product}:${key}`] = value;
			});
		});
	}

	return result;
};
