import { query } from '@confluence/query-preloader-tools';

import { WebPanelLocationQuery } from './WebPanelLocationQuery.graphql';
import type {
	WebPanelLocationQuery as WebPanelLocationQueryType,
	WebPanelLocationQueryVariables,
} from './__types__/WebPanelLocationQuery';

export function preloadWebPanelLocation(variables: WebPanelLocationQueryVariables) {
	return query<WebPanelLocationQueryType, WebPanelLocationQueryVariables>({
		query: WebPanelLocationQuery,
		variables,
	});
}
