/* eslint-disable import/dynamic-import-chunkname */
/**
 * The webpackChunkName "translations.foo" is important. Do not change without updating following files:
 * - packages/confluence-frontend-server/webpack.config.js
 * - packages/confluence-frontend-server/templates/generator.js
 */
function fetchLanguage(locale) {
	if (!process.env.REACT_SSR) {
		/**
		 * en-ZZ is a special locale used for locate translation strings. Real user won't see it.
		 * Split the condition statements so webpack can remove the whole thing when compiling for SSR
		 * SSR doesn't have splits this file will make the ssr-app significantly bigger
		 */
		if (locale === 'en-ZZ') {
			return () => import(/* webpackChunkName: "translations.en-ZZ" */ './en-ZZ.json');
		}
	}

	const languageHash = {
		'fr-FR': () => import(/* webpackChunkName: "translations.fr-FR" */ './fr-FR.json'),

		'de-DE': () => import(/* webpackChunkName: "translations.de-DE" */ './de-DE.json'),

		'ja-JP': () => import(/* webpackChunkName: "translations.ja-JP" */ './ja-JP.json'),

		'es-ES': () => import(/* webpackChunkName: "translations.es-ES" */ './es-ES.json'),

		'ko-KR': () => import(/* webpackChunkName: "translations.ko-KR" */ './ko-KR.json'),

		'pt-BR': () => import(/* webpackChunkName: "translations.pt-BR" */ './pt-BR.json'),

		'ru-RU': () => import(/* webpackChunkName: "translations.ru-RU" */ './ru-RU.json'),

		'en-GB': () => import(/* webpackChunkName: "translations.en-GB" */ './en-GB.json'),

		'da-DK': () => import(/* webpackChunkName: "translations.da-DK" */ './da-DK.json'),

		'et-EE': () => import(/* webpackChunkName: "translations.et-EE" */ './et-EE.json'),

		'fi-FI': () => import(/* webpackChunkName: "translations.fi-FI" */ './fi-FI.json'),

		'hu-HU': () => import(/* webpackChunkName: "translations.hu-HU" */ './hu-HU.json'),

		'is-IS': () => import(/* webpackChunkName: "translations.is-IS" */ './is-IS.json'),

		'it-IT': () => import(/* webpackChunkName: "translations.it-IT" */ './it-IT.json'),

		'nl-NL': () => import(/* webpackChunkName: "translations.nl-NL" */ './nl-NL.json'),

		'no-NO': () => import(/* webpackChunkName: "translations.no-NO" */ './no-NO.json'),

		'pl-PL': () => import(/* webpackChunkName: "translations.pl-PL" */ './pl-PL.json'),

		'ro-RO': () => import(/* webpackChunkName: "translations.ro-RO" */ './ro-RO.json'),

		'sk-SK': () => import(/* webpackChunkName: "translations.sk-SK" */ './sk-SK.json'),

		'cs-CZ': () => import(/* webpackChunkName: "translations.cs-CZ" */ './cs-CZ.json'),

		'sv-SE': () => import(/* webpackChunkName: "translations.sv-SE" */ './sv-SE.json'),

		'zh-CN': () => import(/* webpackChunkName: "translations.zh-CN" */ './zh-CN.json'),

		'zh-TW': () => import(/* webpackChunkName: "translations.zh-TW" */ './zh-TW.json'),
	};

	return locale in languageHash ? languageHash[locale] : undefined;
}

export function lazyLanguage(locale) {
	const promises = [import(/* webpackChunkName: "translations.en-US" */ './en-US.json')];
	const languageChunk = fetchLanguage(locale);
	if (languageChunk) {
		promises.push(languageChunk());
	}
	return promises;
}
