import type {
	BaseMetricDataWithStartAndStop,
	BasePageLoadMetricDataWithStartAndStop,
	PerformanceEventConfig,
} from '../../types';

export const pageVisibleState = (
	_config: PerformanceEventConfig,
	data: BaseMetricDataWithStartAndStop | BasePageLoadMetricDataWithStartAndStop,
) => {
	return {
		'pageVisible:state': data.pageVisibleState,
	};
};
