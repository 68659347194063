import type { ComponentProps, FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';

import { fg } from '@confluence/feature-gating';

import { useIsDisabledContext } from './IsDisabledContext';

type ToggleFieldProps = {
	Icon: React.ElementType;
	label: string;
} & ComponentProps<typeof Toggle>;

const toggleFieldContainerStyles = xcss({
	marginInline: 'space.negative.075',
});

const toggleFieldContainerDisabledStyles = xcss({
	color: 'color.text.disabled',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.label({
	flexGrow: 1,
});

export const ToggleField: FC<ToggleFieldProps> = ({ Icon, id, label, ...toggleProps }) => {
	const isDisabled = useIsDisabledContext();

	if (!id) id = `toggle-${label.replace(/\s+/g, '-').toLowerCase()}-property`;

	return (
		<Flex
			alignItems="center"
			xcss={[toggleFieldContainerStyles, isDisabled && toggleFieldContainerDisabledStyles]}
		>
			<Box paddingInlineEnd="space.100">
				{!fg('company_hub_manual_cards_refinement') && (
					<Icon testId="custom-sites-extensions-config-icon" />
				)}
			</Box>
			<Label htmlFor={id}>{label}</Label>
			<Toggle {...toggleProps} isDisabled={isDisabled} id={id} />
		</Flex>
	);
};
