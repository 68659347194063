import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';
import type { WebStorageType } from '../types';

/**
 * Gets a WebStorage item synchronously. This will NOT query user preferences before setting
 * the item, so it can be used to set strictly necessary
 */
export const getStorageItem = (
	itemKey: string,
	storageType: WebStorageType,
	browserStorage: Storage,
): string | null => {
	if (fg('platform_moonjelly_bsc_master_killswitch')) {
		// Get the item without package operational events
		// when master killswitch is enabled
		return browserStorage.getItem(itemKey);
	}

	const eventAttributes = {
		itemKey,
		storageType,
	};

	try {
		sendPackageOperationalEvent({
			action: 'usedAtlBrowserStorageGetItem',
			attributes: eventAttributes,
		});
		return browserStorage.getItem(itemKey);
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'usedAtlBrowserStorageGetItemError',
			attributes: eventAttributes,
			message: `Failed to get ${storageType} item. ${e.message || ''}`,
		});
		return null;
	}
};
