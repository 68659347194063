import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N30 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Skeleton = styled.div<{ width: string; height: string }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => width,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => height,
	borderRadius: '3px',
	background: token('color.skeleton', N30),
	'@media (min-width: 480px)': {
		margin: `0 ${token('space.100', '8px')} 0 0`,
	},
});
