import gql from 'graphql-tag';

export const OperationsQuery = gql`
	query OperationsQuery {
		siteOperations {
			userProfile
			application
		}
	}
`;
