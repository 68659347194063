import React, { createContext, useState, useRef, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

import type { CommentHighlight } from './commentContextTypes';

export type CreateInlineCommentHighlight = CommentHighlight;

export type HighlightOrigin = 'userHighlight' | 'nudgeHighlight';

export type ResolvedComment = {
	commentId: string;
	inlineMarkerRef: string;
};

type InlineCommentsContextValue = {
	newCommentHighlight?: CreateInlineCommentHighlight;
	createComment: (newHighlight: CreateInlineCommentHighlight, origin?: HighlightOrigin) => void;
	clearCreateHighlight: () => void;
	highlightOrigin: HighlightOrigin;
	activeHighlight: string | null;
	onHighlightClick: (markerRef: string | null) => void;
	firstHighlightClicked: boolean;
	shouldDisplaySidebar: boolean;
	toggleSidebar: (shouldDisplay: boolean) => void;
	resolvedComment: ResolvedComment | null;
	setResolvedComment: React.Dispatch<React.SetStateAction<ResolvedComment | null>>;
	setActiveHighlight: React.Dispatch<React.SetStateAction<string | null>>;
};

export const InlineCommentsContext = createContext<InlineCommentsContextValue>({
	newCommentHighlight: undefined,
	createComment: () => {},
	clearCreateHighlight: () => {},
	highlightOrigin: 'userHighlight',
	activeHighlight: null,
	onHighlightClick: () => {},
	firstHighlightClicked: false,
	shouldDisplaySidebar: false,
	toggleSidebar: () => {},
	resolvedComment: null,
	setResolvedComment: () => {},
	setActiveHighlight: () => {},
});
InlineCommentsContext.displayName = 'InlineCommentsContext';

type InlineCommentsProviderProps = {
	children: ReactNode;
};

export const InlineCommentsProvider: FC<InlineCommentsProviderProps> = ({ children }) => {
	const [newCommentHighlight, setNewCommentHighlight] = useState<CreateInlineCommentHighlight>();

	const [highlightOrigin, setHighlightOrigin] = useState<HighlightOrigin>('userHighlight');

	/**
	 * We eventually want this value to be the official state for loading/displaying
	 * the UI for inline comments. InlineCommentSidebar and some child components have created
	 * stateful logic (active highlight, isSidebarShown, etc) that assumes
	 * the code is always loaded which we will eventually adjust.
	 */
	const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState<boolean>(false);

	const firstHighlightClicked = useRef(false);

	const [activeHighlight, setActiveHighlight] = useState<string | null>(null);

	const [resolvedComment, setResolvedComment] = useState<ResolvedComment | null>(null);

	const inlineCommentsContextValue = useMemo(
		() => ({
			newCommentHighlight,
			highlightOrigin,
			createComment: (
				newHighlight: CreateInlineCommentHighlight,
				origin: HighlightOrigin = 'userHighlight',
			) => {
				/**
				 * Creating a comment is one of the three user explicit scenarios
				 * where we want to load inline comments code.
				 */
				setShouldDisplaySidebar(true);
				setNewCommentHighlight(newHighlight);
				setHighlightOrigin(origin);
				if (resolvedComment) {
					setResolvedComment(null);
				}
			},
			clearCreateHighlight: () => setNewCommentHighlight(undefined),
			activeHighlight,
			onHighlightClick: (markerRef: string | null) => {
				if (!firstHighlightClicked.current) {
					firstHighlightClicked.current = true;
				}

				/**
				 * Clicking on a highlight is one of the three user explicit scenarios
				 * where we want to load inline comments code.
				 */
				if (markerRef) {
					setShouldDisplaySidebar(true);
				} else {
					setShouldDisplaySidebar(false);
				}

				setActiveHighlight(markerRef);
			},
			resolvedComment,
			setResolvedComment,
			firstHighlightClicked: firstHighlightClicked.current,
			shouldDisplaySidebar,
			toggleSidebar: (shouldDisplay: boolean) => setShouldDisplaySidebar(shouldDisplay),
			setActiveHighlight,
		}),
		[activeHighlight, highlightOrigin, newCommentHighlight, resolvedComment, shouldDisplaySidebar],
	);

	return (
		<InlineCommentsContext.Provider value={inlineCommentsContextValue}>
			{children}
		</InlineCommentsContext.Provider>
	);
};
