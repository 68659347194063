import gql from 'graphql-tag';

export const AnalyticBoxesRowQuery = gql`
	query AnalyticBoxesRowQuery($contentId: ID!, $startDate: String!, $endDate: String!) {
		viewsForPage: contentAnalyticsTotalViewsByPage(
			contentIds: [$contentId]
			startTime: $startDate
			endTime: $endDate
		) {
			nodes {
				totalViews
			}
		}
		clicksForPage: countGroupByEventName(
			eventName: [companyHubLink_clicked]
			startTime: $startDate
			endTime: $endDate
		) {
			nodes {
				count
			}
		}
		uniqueVisitorsForPage: countUsersGroupByPage(
			pageId: [$contentId]
			startTime: $startDate
			endTime: $endDate
			eventName: [page_viewed]
		) {
			nodes {
				user
			}
		}
		ctr: eventCTR(
			endTime: $endDate
			startTime: $startDate
			clickEventName: companyHubLink_clicked
			discoverEventName: companyHubLink_viewed
		) {
			nodes {
				ctr
			}
		}
	}
`;
