import React from 'react';

import type { RouteMatch } from '@confluence/route';

import { LazyRouteToRightEditor } from '../lazyComponents';

export const EditBlogRoute = ({ params: { contentId, spaceKey } }: RouteMatch) => (
	<LazyRouteToRightEditor contentType="blog" contentId={contentId} spaceKey={spaceKey} />
);

EditBlogRoute.NAVIGATION_PARAMS = ({ params: { contentId, spaceKey } }: RouteMatch) => ({
	Screen: {
		showPremiumOnboarding: false,
		screenEvent: {
			name: 'editPageScreen',
			id: contentId,
		},
		pageState: { contentId, spaceKey, contentType: 'blogpost' },
	},
	MainLayout: false,
});
