import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import { getBrowserHistory } from '@confluence/browser-history';
// ^^^ This block contributes 4KB

// Middlewares
import api from '../middleware/api'; // 20KB
import graphql from '../middleware/graphql'; // 3KB
import trackAnalyticEvents from '../middleware/track-analytic-events'; // 22KB
// Reducer
import getRootReducer from '../reducers';

// Facades
import initialStateFactory from './initialStateFactory'; // 10KB

export default function configureStore(initialState = {}) {
	const devMiddleware = [
		// Uncomment this line to enable timing middleware.
		// It will provide some helpful information to tune the performance.
		// applyMiddleware(require('../middleware/timing').default),

		// if you want devtools, install the chrome extension
		// https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd

		typeof global.__REDUX_DEVTOOLS_EXTENSION__ === 'function'
			? global.__REDUX_DEVTOOLS_EXTENSION__()
			: (f) => f,
	];
	const browserHistory = getBrowserHistory();
	const finalCreateStore = compose(
		applyMiddleware(thunk, api, graphql, trackAnalyticEvents),
		applyMiddleware(routerMiddleware(browserHistory)),
		...(process.env.NODE_ENV === 'production' ? [] : devMiddleware),
	)(createStore);

	return finalCreateStore(
		getRootReducer({}, browserHistory),
		initialStateFactory(global, initialState),
	);
}
