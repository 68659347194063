/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::70fc2b79f074782b0eaaa527df6f46fe>>
 * @codegenId #form-header
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen form-header
 * @codegenParams { "props": { "title": true, "description": true, "children": true } }
 * @codegenDependency ../../../../../../../design-system/form/src/form-header.tsx <<SignedSource::b9fc3ec294d0f5f194636aa8e2a5ee05>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { FormHeader as PlatformFormHeader } from '@atlaskit/form';

type PlatformFormHeaderProps = React.ComponentProps<typeof PlatformFormHeader>;

export type FormHeaderProps = Pick<
  PlatformFormHeaderProps,
  'title' | 'description' | 'children'
>;

export const FormHeader = (props: Parameters<RenderFn>[0]) => {
  const {
    title,
    description,
  } = props.forgeDoc.props as FormHeaderProps;
  return (
    <PlatformFormHeader
      title={title}
      description={description}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
    />
  );
};
/**
 * @codegenEnd
 */