import gql from 'graphql-tag';

export const ScreenQuery = gql`
	query ScreenQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			homepage {
				id
			}
		}
	}
`;
