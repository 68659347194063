import { createSelector } from 'reselect';
import {
	hasFeatureBeenDiscoveredSelector,
	haveDiscoveredFeaturesBeenLoaded,
	isDarkFeatureEnabledSelector,
} from '@confluence-classic/confluence-legacy-redux';
import { getLocation } from '@confluence-classic/confluence-urls';

const A_SECOND_AGO = new Date(Date.now() - 1000);
const A_YEAR_FROM_NOW = new Date(Date.now() + 3.154e10);

const getSpotlightId = (props) => props.spotlightId;
const getStartDate = (props) => props.startDate || A_SECOND_AGO;
const getEndDate = (props) => props.endDate || A_YEAR_FROM_NOW;
const getDarkFeatureEnabled = (props) => props.darkFeatureEnabled;
const getDarkFeatureDisabled = (props) => props.darkFeatureDisabled;
const getIndex = (state) => state.get('index');
const getLoaded = (state) => state.get('loaded');
const getFlow = (state) => state.get('flow');
const getSpotlights = (state) => state.get('spotlights');

export const hasSpotlightBeenInvoked = createSelector(
	[getSpotlights, (state, props) => getSpotlightId(props)],
	(spotlights, spotlightId) => {
		for (let flowIndex = 0; flowIndex < spotlights.size; flowIndex++) {
			for (
				let spotlightIndex = 0;
				spotlightIndex < spotlights.get(flowIndex).size;
				spotlightIndex++
			) {
				if (spotlights.getIn([flowIndex, spotlightIndex, 'id']) === spotlightId) {
					return spotlights.getIn([flowIndex, spotlightIndex, 'requested']);
				}
			}
		}
		return false;
	},
);

export const getSpotlightStorageKey = (spotlightId) => `${spotlightId}.viewed`;

export const pluginKey = 'com.atlassian.confluence.plugins.confluence-frontend-support';

const getCurrentFlow = createSelector([getSpotlights, getFlow], (spotlights, flow) =>
	spotlights.get(flow),
);

const getCurrentSpotlight = createSelector([getCurrentFlow, getIndex], (currentFlow, index) =>
	currentFlow.get(index),
);

export const getCurrentSpotlightAttributes = createSelector([getCurrentSpotlight], (spotlight) =>
	spotlight ? spotlight.get('attributes').toJSON() : {},
);

export const getChangeboardingSpotlightIds = createSelector([getCurrentFlow], (currentFlow) =>
	currentFlow ? currentFlow.map((spotlight) => spotlight.get('id')).toArray() : [],
);

export const getTotalChangeboardingSpotlights = createSelector([getLoaded], (loaded) => loaded);

export const getCurrentSpotlightId = createSelector([getCurrentSpotlight], (currentSpotlight) =>
	currentSpotlight ? currentSpotlight.get('id') : null,
);

const isChangeboardingWithinDateRange = createSelector(
	[getStartDate, getEndDate],
	(startDate, endDate) => {
		const now = Date.now();
		return startDate.getTime() <= now && endDate.getTime() >= now;
	},
);

const hasBeenDiscovered = (state, ownProps) => {
	const featureKey = getSpotlightStorageKey(getSpotlightId(ownProps));
	return hasFeatureBeenDiscoveredSelector(state, { pluginKey, featureKey });
};

const getDarkFeatureFulfillment = createSelector(
	[
		(state, ownProps) => {
			const darkFeature = getDarkFeatureEnabled(ownProps);
			return !darkFeature || isDarkFeatureEnabledSelector(state, darkFeature);
		},
		(state, ownProps) => {
			const darkFeature = getDarkFeatureDisabled(ownProps);
			return !darkFeature || !isDarkFeatureEnabledSelector(state, darkFeature);
		},
	],
	(enabledOk, disabledOk) => enabledOk && disabledOk,
);

/*
CONFDEV-59125
We need to blacklist some of the URLs from displaying changeboarding
spotlights because they conflict with the target modals. Some of the URLs
found to be conflicting have these patterns:
- ...?focusedCommentId=<id>#comment-<id>
- ...?showComments=true&showCommentArea=true#addcomment
- ...?preview=%2F3348890201%2F3349054229%2F1%2F3348890348%2F26ea7p.jpg
but just to be safe, we have disabled it for any URL containing a "#" sign
or a query parameter called "preview".
 */
export const isChangeboardingUrlBlacklisted = (state) => {
	const { query = {}, hash = '' } = ({} = getLocation(state));
	return hash !== '' || query.preview;
};

export const shouldChangeboardingInstanceRender = createSelector(
	[
		haveDiscoveredFeaturesBeenLoaded,
		hasBeenDiscovered,
		getDarkFeatureFulfillment,
		(state, ownProps) => isChangeboardingWithinDateRange(ownProps),
	],
	(discoveredFeaturesLoaded, discovered, darkFeaturesFulfilled, withinRange) =>
		discoveredFeaturesLoaded && !discovered && darkFeaturesFulfilled && withinRange,
);

export const getChangeboardingActions = createSelector(
	[getTotalChangeboardingSpotlights, getIndex],
	(total, index) => {
		const prev = index > 0;
		const next = index + 1 < total;
		const done = !next;
		return { prev, next, done };
	},
);
