import { Decoration } from '@atlaskit/editor-prosemirror/view';

import { getAllDiffObjects } from '../utils';

import { type DecorationTransformerParams, SpellingAndGrammarDecorations } from './index';

const createDecoration = (from: number, to: number, id: string) =>
	Decoration.inline(
		from,
		to,
		{
			nodeName: 'span',
			class: `ai-suggestions`,
			id: `ai-sg-${id}`,
			'data-testid': `ai-suggestions`,
		},
		{
			key: SpellingAndGrammarDecorations.SPELLING_ERROR,
			diffObjectId: id,
		},
	);

export const updateSpellingDecoration = ({
	decorationSet,
	tr,
	pluginState,
}: DecorationTransformerParams) => {
	const allDiffObjects = getAllDiffObjects(pluginState);
	const allDiffObjectsIds = allDiffObjects.map((diffObject) => diffObject.id);
	const existingDecorationDiffObjectIds: string[] = [];

	// First step: remove decorations for diffObjects that does not exist any more.
	// For performance reasons, we will build existingDecorationDiffObjectIds here as well.
	const decorationsToRemove = decorationSet.find(undefined, undefined, (spec) => {
		if (spec.key !== SpellingAndGrammarDecorations.SPELLING_ERROR) {
			return false;
		}
		existingDecorationDiffObjectIds.push(spec.diffObjectId);
		return !allDiffObjectsIds.includes(spec.diffObjectId);
	});
	decorationSet = decorationSet.remove(decorationsToRemove);

	// Second step: map existing decorations set with tr.mapping
	decorationSet = decorationSet.map(tr.mapping, tr.doc);

	// Third step: create new decorations for new diffObjects.
	let decorations = allDiffObjects?.reduce<Decoration[]>((acc, { id, from, to }) => {
		if (!existingDecorationDiffObjectIds.includes(id)) {
			acc.push(createDecoration(from, to, id));
		}
		return acc;
	}, []);

	// Fourth Step: If there are new decorations then add it in decorationsSet
	if (!!decorations?.length) {
		return decorationSet.add(tr.doc, decorations);
	}

	return decorationSet;
};
