import React, { lazy, Fragment } from 'react';
import type { FieldProps } from '@atlaskit/form/Field';
import { type NativeTextAreaProps, type RenderFn } from '@atlassian/forge-ui-types';
import { FormStateChangeNotifier } from '../form';
import { useIsInForm } from '../utils/useIsInForm';
import { Label } from '../label';
import { FieldContainer } from '../utils/FieldContainer';

const AkFormField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const AKTextArea = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.textarea' */
			'@atlaskit/textarea'
		),
);

const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

const validate = (value: any) => (!value ? 'EMPTY' : undefined);

export const TextArea = (props: Parameters<RenderFn>[0]) => {
	const {
		name,
		label,
		defaultValue,
		isMonospaced,
		isRequired,
		description,
		placeholder,
		spellCheck,
		onChange,
		value,
	} = props.forgeDoc.props as NativeTextAreaProps;

	const isInForm = useIsInForm();

	if (!isInForm) {
		return (
			<FieldContainer>
				<Label name={name} isRequired={isRequired} label={label} />
				<AKTextArea
					name={name}
					appearance="standard"
					resize="smart"
					minimumRows={2}
					defaultValue={defaultValue}
					isMonospaced={isMonospaced}
					placeholder={placeholder}
					spellCheck={spellCheck}
					onChange={(event) => {
						onChange?.(event.target.value);
					}}
					value={value}
				/>
				{description && <AKHelperMessage>{description}</AKHelperMessage>}
			</FieldContainer>
		);
	}

	return (
		<FieldContainer>
			<AkFormField
				name={name}
				label={label}
				defaultValue={defaultValue}
				isRequired={isRequired}
				validate={isRequired ? validate : undefined}
			>
				{({ fieldProps, error }: { fieldProps: FieldProps<unknown>; error?: string }) => {
					const {
						id,
						isDisabled,
						isRequired,
						isInvalid,
						onBlur,
						onFocus,
						'aria-invalid': ariaInvalid,
						'aria-labelledby': ariaLabelledBy,
					} = fieldProps;

					return (
						<Fragment>
							<FormStateChangeNotifier name={name} value={fieldProps.value} />
							<AKTextArea
								id={id}
								isDisabled={isDisabled}
								isRequired={isRequired}
								isInvalid={isInvalid}
								onBlur={onBlur}
								onFocus={onFocus}
								value={fieldProps.value as string | undefined}
								name={fieldProps.name}
								onChange={(event) => {
									fieldProps.onChange(event);
									onChange && onChange(event.target.value);
								}}
								appearance="standard"
								resize="smart"
								minimumRows={2}
								isMonospaced={isMonospaced}
								placeholder={placeholder}
								spellCheck={spellCheck}
								aria-invalid={ariaInvalid}
								aria-labelledby={ariaLabelledBy}
							/>
							{error === 'EMPTY' && <AKErrorMessage>This field is required.</AKErrorMessage>}
							{description && <AKHelperMessage>{description}</AKHelperMessage>}
						</Fragment>
					);
				}}
			</AkFormField>
		</FieldContainer>
	);
};
