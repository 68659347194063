import memoizeOne from 'memoize-one';

import type { ContentAppearanceToAtlaskitMap, FullPageAppearances } from './types';
import { ContentAppearanceType } from './types';

const _contentAppearanceToAtlaskitMap: ContentAppearanceToAtlaskitMap = {
	[ContentAppearanceType.DEFAULT]: 'full-page',
	[ContentAppearanceType.FULL_WIDTH]: 'full-width',
};

export const getContentAppearanceForAtlaskit = memoizeOne(
	(contentAppearance: ContentAppearanceType): FullPageAppearances => {
		const appearanceForAtlaskit = _contentAppearanceToAtlaskitMap[contentAppearance];
		if (appearanceForAtlaskit) {
			return appearanceForAtlaskit;
		}
		return getDefaultContentAppearanceForAtlaskit();
	},
);

export const getDefaultContentAppearanceForAtlaskit = (): FullPageAppearances => {
	return _contentAppearanceToAtlaskitMap[ContentAppearanceType.DEFAULT];
};
