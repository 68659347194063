import { startOfDay, startOfWeek, startOfMonth, subDays, subWeeks, subMonths } from 'date-fns';
import React from 'react';

export type GranularityType = 'DAY' | 'WEEK' | 'MONTH';
export const GRANULARITIES: GranularityType[] = ['DAY', 'WEEK', 'MONTH'];

const DEFAULT_GRANULARITY: GranularityType = 'DAY';
const DEFAULT_LAST_X_GRANULARITIES = 30;

export type MetricSettingsContextValueType = {
	granularity: GranularityType;
	lastXGranularities: number;
	startDate: Date;
	firstFullGranularityStartDate: Date;
	firstFullGranularityEndDate: Date;
	lastFullGranularityStartDate: Date;
	lastFullGranularityEndDate: Date;
	dayBeforeEndDate: Date;
	endDate: Date;
	isActivationDateOver1DayAgo?: boolean;
	activationDate?: Date;
	hasPublicLinksStatusError?: boolean;
};

// Helper function for generateMetricSettingsContextValue().
// Gets the start date for the nth granularity relative to endDate (n = lastXGranularities).
const getStartOfGranularity = ({
	endDate,
	granularity,
	lastXGranularities,
}: {
	endDate: Date;
	granularity: GranularityType;
	lastXGranularities: number;
}) => {
	switch (granularity) {
		case 'DAY':
			return startOfDay(subDays(endDate, lastXGranularities));
		case 'WEEK':
			return startOfWeek(subWeeks(endDate, lastXGranularities), {
				weekStartsOn: 1,
			});
		case 'MONTH':
		default:
			// The granularity should be MONTH.
			// However, if it is an unexpected value, then we default to MONTH.
			return startOfMonth(subMonths(endDate, lastXGranularities));
	}
};

export const getMetricSettingsContextValue = ({
	granularity,
	lastXGranularities,
	isActivationDateOver1DayAgo,
	activationDate,
	hasPublicLinksStatusError,
}: {
	granularity: GranularityType;
	lastXGranularities: number;
	isActivationDateOver1DayAgo?: boolean;
	activationDate?: Date;
	hasPublicLinksStatusError?: boolean;
}) => {
	const endDate = startOfDay(new Date());
	return {
		granularity,
		lastXGranularities,
		startDate: getStartOfGranularity({
			endDate,
			granularity,
			lastXGranularities: lastXGranularities + 1,
		}),
		firstFullGranularityStartDate: getStartOfGranularity({
			endDate,
			granularity,
			lastXGranularities: lastXGranularities + 1,
		}),
		firstFullGranularityEndDate: getStartOfGranularity({
			endDate,
			granularity,
			lastXGranularities,
		}),
		lastFullGranularityStartDate: getStartOfGranularity({
			endDate,
			granularity,
			lastXGranularities: 1,
		}),
		lastFullGranularityEndDate: getStartOfGranularity({
			endDate,
			granularity,
			lastXGranularities: 0,
		}),
		dayBeforeEndDate: subDays(endDate, 1),
		endDate,
		isActivationDateOver1DayAgo,
		activationDate,
		hasPublicLinksStatusError,
	};
};

export const MetricSettingsContextDefaultValue = {
	...getMetricSettingsContextValue({
		granularity: DEFAULT_GRANULARITY,
		lastXGranularities: DEFAULT_LAST_X_GRANULARITIES,
	}),
};

export const MetricSettingsContext = React.createContext<MetricSettingsContextValueType>(
	MetricSettingsContextDefaultValue,
);
