/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment, useContext } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { type FormConditionProps, type Rendered } from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { transformFormValue } from '../form/transformFormData';
import { FormContext } from '../form';

const isEqual = (first: any, second: any) => {
	if (first === second) {
		return true;
	}
	if (Array.isArray(first) && Array.isArray(second)) {
		return first.sort().toString() === second.sort().toString();
	}
	return false;
};

const FormCondition: React.FunctionComponent<Rendered<FormConditionProps>> = ({
	when,
	is,
	areChildrenPersisted = false,
	children,
}: Rendered<FormConditionProps>) => {
	const formContext = useContext(FormContext);
	const shouldShowChildren = isEqual(transformFormValue(formContext?.formValues[when]), is);
	if (areChildrenPersisted) {
		return (
			<div
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					display: shouldShowChildren ? 'block' : 'none',
				})}
			>
				{children}
			</div>
		);
	} else {
		return <Fragment>{shouldShowChildren ? children : null}</Fragment>;
	}
};

export default FormCondition;

export const FormConditionFn = ({
	props,
	children,
	Components,
	dispatch,
	render,
	renderChildren,
}: Props) => {
	const { when, is, areChildrenPersisted } = props as Rendered<FormConditionProps>;
	return (
		<FormCondition when={when} is={is} areChildrenPersisted={areChildrenPersisted}>
			{renderChildren({ children, render, dispatch, Components })}
		</FormCondition>
	);
};
