import type { FieldTransformer } from './types';

export const excerpt: FieldTransformer<{ root: string | string[] }> = {
	transformFields: async (fields, _) => {
		return fields.map((field) => {
			// Mark this field as required in the FE to display the UI red asterisk, but we will not
			// enforce it in the backend as this breaks existing untitled excerpts
			if (field.name === 'name') {
				return {
					...field,
					isRequired: true,
				};
			}

			return field;
		});
	},
};
