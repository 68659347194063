export {
	productEnvToCdnEnv,
	createSrcFromExtension,
	shortenUuid,
	expandUuid,
} from './createSrcFromExtension';
export { getSrcUrl } from './getSrcUrl';
export { getExtensionEntryPoint } from './getExtensionEntryPoint';
export { getBrowserLocale } from './getBrowserLocale';
export { getResolverContext } from './getResolverContext';
export { getViewContext } from './getViewContext';

export const getBrowserTimezone = () => {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
