import { LoadableAfterPaint } from '@confluence/loadable';

export { ANALYTICS_SOURCE } from '../src/constants';

export const BulkSpacePermissionsPortal = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkSpacePermissionsPortal" */ '../src/BulkSpacePermissionsPortal'
			)
		).BulkSpacePermissionsPortal,
	name: 'BulkSpacePermissionsPortal',
});

export const BulkSpacePermissionsPage = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkSpacePermissionsPage" */ '../src/BulkSpacePermissionsPage'
			)
		).BulkSpacePermissionsPage,
	name: 'BulkSpacePermissionsPage',
});
