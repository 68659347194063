import type { FieldTransformer } from '../types';
import { isValueSet } from '../../utils';

/**
 * There is a transformation on data that is happening for tasks report macro that deletes the combined field of spaceAndPage
 * https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/browse/confluence/next/packages/fabric-extension-lib/src/providers/macros/field-mappings/tasks-report.ts#90
 * This code runs prior to the missingRequiredParameters check at causes the check to fail.
 * To properly check if the field is set we need to check the renamed fields.
 * This check may be used for any other renamed parameters.
 */
const COMPOUND_FIELD_MAP = {
	spaceAndPage: ['space', 'page'],
};
export interface AllMacroParams {
	[key: string]: any;
	isMissingRequiredParameters?: boolean;
}

function checkValueSet(key, params) {
	if (!COMPOUND_FIELD_MAP[key]) {
		return isValueSet(params[key]);
	}
	return (
		isValueSet(params[key]) ||
		COMPOUND_FIELD_MAP[key].some((subfield) => isValueSet(params[subfield]))
	);
}

// This transformer runs for all macros
export const missingRequiredParameters: FieldTransformer<AllMacroParams> = {
	transformAfter(params, metadata) {
		if (
			Object.entries(metadata).some(
				([key, field]) =>
					// NOTE: CQL has default value inside `field.options.defaultFields`
					// But it doesn't work now
					field &&
					field.required &&
					field.defaultValue == null &&
					!field.hidden &&
					!checkValueSet(key, params),
			)
		) {
			params.isMissingRequiredParameters = true;
		}

		return params;
	},
};
