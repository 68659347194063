import gql from 'graphql-tag';

export const PageStatusQuery = gql`
	query PageStatusQuery($contentId: ID!) {
		content(id: $contentId, status: ["archived", "current"]) {
			nodes {
				id
				status
			}
		}
	}
`;
