import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { ContainerErrorMessage } from '../common/ContainerErrorMessage';

import { CardContainer, CardContainerMinHeight } from './CardContainer';
import type { ChartBaseProps } from './ChartBase/ChartBase';
import { ChartBase } from './ChartBase/ChartBase';
import type { HeaderBaseProps } from './HeaderBase';
import { HeaderBase } from './HeaderBase';
import type { SummaryBaseProps } from './SummaryBase/SummaryBase';
import { SummaryBase } from './SummaryBase/SummaryBase';

const headerWrapperStyles = xcss({
	marginBottom: 'space.100',
});

export type ChartSummaryBaseCardProps = ChartBaseProps &
	SummaryBaseProps &
	HeaderBaseProps & {
		hasError: boolean | undefined;
	};

export const ChartSummaryBaseCard = ({
	title,
	tooltip,
	cta,
	loading,
	hasError,
	yAxisSelectProps,
	...props
}: ChartSummaryBaseCardProps) => (
	<CardContainer minHeight={CardContainerMinHeight.CHART_SUMMARY}>
		<Box xcss={headerWrapperStyles}>
			<HeaderBase
				title={title}
				tooltip={tooltip}
				cta={cta}
				loading={loading}
				yAxisSelectProps={yAxisSelectProps}
			/>
		</Box>
		{hasError && <ContainerErrorMessage />}
		{!loading && !hasError && (
			<>
				<SummaryBase {...props} />
				<ChartBase title={title} {...props} />
			</>
		)}
	</CardContainer>
);
