export function globalRequire(...args: [string]) {
	if (!window) {
		return;
	}
	if (!window.require) {
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line no-console
			console.error('AMD require not defined. Are you running standalone mode?');
		}

		return;
	}

	return window.require.apply(window, args);
}
