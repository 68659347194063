import { getTraceIdFromResponse } from './getTraceIdFromResponse';
import { NetworkError } from './NetworkError';

// HTTP status code wasn't an expected 2xx
export class BadStatusError extends NetworkError {
	public response: Response;
	name = 'BadStatusError';

	constructor(message: string, response: Response) {
		super(message);

		this.response = response;
	}

	get traceId(): string | null {
		return getTraceIdFromResponse(this.response);
	}

	static isBadStatusError(value: unknown): value is BadStatusError {
		if (process.env.NODE_ENV === 'testing') {
			// jest messes up globals badly, see https://github.com/facebook/jest/issues/2549
			// once that issue is fixed, the usages of this function can be inlined to `error instanceof BadStatusError`
			return (value as any)?.name === 'BadStatusError';
		}

		return value instanceof BadStatusError;
	}
}
