import React from 'react';

import type { SessionDataType } from './SessionDataTypes';

export const SessionDataContext = React.createContext<SessionDataType>(
	// The app should not be making use of `SessionDataContext` until it's loaded.
	// It *should* be safe to assert the presence of SessionData by this point.
	undefined!,
);

SessionDataContext.displayName = 'SessionDataContext';
