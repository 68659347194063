/* eslint-disable check-credentials-option/has-credentials-option */
import { parse } from 'url';

import { cfetch, BadStatusError, NoNetworkError } from '@confluence/network';
import { preloadedRestLink } from '@confluence/query-preloader-tools';

import { ConfluenceRestApiError } from '@confluence/network';

/**
 * Substitute tokens with values
 *
 * @param {String} url - the url with ':token' or ':token?' tokens to be replaced.
 * @param {Object} parameters - the substitutions.
 */
const replaceTokens = (url, parameters) => {
	return url.replace(/:([a-zA-Z]+)\?*/g, function (match, token) {
		let param = parameters[token];

		// blueprint urls need a ? following the contentId
		if (match === ':contentId?') {
			param = param + '?';
		}
		// Do not encode token like :query => key=value or :contentId? => 123188823?
		return typeof param === 'string' && param.indexOf('=') === -1 && match !== ':contentId?'
			? encodeURIComponent(decodeURIComponent(param))
			: param;
	});
};

function waitFor({ condition, maxTries = 10, interval = 250, callback }) {
	if (typeof condition !== 'function') {
		throw new Error('waitFor requires a condition wrapped in a function as first argument');
	}

	if (typeof callback !== 'function') {
		throw new Error('waitFor requires a function as callback');
	}

	// if condition passes, return immediately
	if (condition()) {
		return callback();
	}

	let currentTry = 0;

	const waitForTimeout = () =>
		setTimeout(() => {
			if (condition()) {
				return callback();
			}

			// count number of tries
			currentTry = currentTry + 1;

			// escape if it's over the maximum
			if (maxTries && maxTries > 0 && currentTry >= maxTries) {
				if (process.env.NODE_ENV !== 'production') {
					console.error(`waitFor attempted ${maxTries} without success. Stopping it now`);
				}

				return;
			}

			// check again
			waitForTimeout();
		}, interval);

	// start waiting
	waitForTimeout();
}

function requestErrorHandler(
	callback,
	httpCodeHandlers = {},
	method = '',
	urlPath = '',
	routeName = '',
) {
	return (error) => {
		if (error instanceof BadStatusError) {
			// XXX Translate BadStatusError to ConfluenceRestApiError because that's what
			// customHandler and callback (may) rely on (since they predate the
			// introduction of BadStatusError).
			const { response } = error;
			return response.text().then((text) =>
				requestErrorHandler(
					callback,
					httpCodeHandlers,
					method,
					urlPath,
					routeName,
				)(
					new ConfluenceRestApiError(
						{
							message: text,
							'status-code': response.status,
							method,
							url: urlPath,
						},
						{ route: routeName, traceId: error.traceId },
					),
				),
			);
		}

		if (error instanceof ConfluenceRestApiError) {
			const customHandler = httpCodeHandlers[error.statusCode];

			if (customHandler) {
				customHandler(error);
			}

			return callback(error);
		}

		// XXX TypeError was given to callback before the introduction of
		// NoNetworkError. Based on searching for TypeError in the source code base,
		// it appears that the TypeError was used as a signal of an offline network.
		// That's understandable given that fetch throws a TypeError when the
		// network is offline. However, requestErrorHandler runs in a catch of a
		// chain of Promises each of which may throw TypeError for reasons other
		// than the network being offline. So preserve the legacy behavior for
		// compatibility reasons.
		//
		// However, we now wrap fetch and throw NoNetworkError so take that new type
		// into account as well.
		if (error instanceof TypeError || error instanceof NoNetworkError) {
			return callback(error);
		}

		throw error;
	};
}

// test-only export
export const requestErrorHandler__test = requestErrorHandler;

function invokeResource(
	url,
	{
		body,
		callback,
		headers,
		method,
		extraConfig = {},
		responseTransformer,
		httpCodeHandlers,
		routeName,
	},
	config,
) {
	let bodyParam = body;
	const contentType = headers['Content-Type'];

	// remove Content-Type if 'null', which is required for multipart/form-data
	// so that the browser populates the mime boundary string correctly
	if (!contentType) {
		delete headers['Content-Type'];
	}

	if (contentType && contentType.indexOf('application/x-www-form-urlencoded') !== -1) {
		bodyParam = new URLSearchParams(body).toString();
	} else if (contentType === 'application/json') {
		bodyParam = JSON.stringify(body);
	}

	const urlPath = parse(url).path;

	const passThrough = () =>
		cfetch(url, {
			headers,
			method: method,
			body: bodyParam,
		});

	return preloadedRestLink(
		urlPath,
		{
			method,
			headers,
			body,
		},
		passThrough,
	)
		.then(function (response) {
			if (extraConfig.ignoreResponseJson) {
				return null;
			}
			if (response.status !== 204) {
				return response.json();
			} else {
				return response;
			}
		})
		.then(function (responseJson) {
			if (responseJson && responseJson['status-code']) {
				throw new ConfluenceRestApiError(responseJson, { route: routeName });
			}

			return callback(
				null,
				responseTransformer
					? responseTransformer(responseJson, url, config.contextPath, body)
					: responseJson,
			);
		})
		.catch(requestErrorHandler(callback, httpCodeHandlers, method, urlPath, routeName));
}

/**
 * Create a new instance of a requestHelper with custom configuration.
 *
 * @returns Object
 */
export function requestHelperFactory() {
	let config = {
		endpoint: null,
		contextPath: null,
		extraHeaders: {},
		atlToken: null,
		tenantId: null,
	};

	return {
		configure(configuration = {}) {
			config = { ...config, ...configuration };
		},

		getConfig() {
			return config;
		},

		getAtlToken() {
			if (!config.atlToken) {
				throw new Error('Tried to use atlToken before it was available');
			}
			return config.atlToken;
		},

		/**
		 * Wait for tenant id and resolves the promise
		 * @returns {Promise}
		 */
		withTenantId() {
			return new Promise((resolve, reject) => {
				waitFor({
					condition: () => config.tenantId !== null,
					callback: () => resolve(config.tenantId),
				});
			});
		},

		/**
		 * Returns the full PATH for a given path.
		 * @param {string} path
		 * @param {Object} [params]
		 */
		getFullPath(path, params) {
			const absoluteUrl = config.endpoint
				? `${config.endpoint}${config.contextPath}${path}`
				: `${config.contextPath}${path}`;

			return params ? replaceTokens(absoluteUrl, params) : absoluteUrl;
		},
		getExternalServicePath(path, params) {
			if (typeof path !== 'function') {
				throw new Error(
					'getExternalServicePath expects path to be a function so it can be lazy evaluated',
				);
			}
			const lazyPath = path();
			return replaceTokens(lazyPath, params);
		},
		get(
			url,
			{ callback, headers, responseTransformer, httpCodeHandlers, extraConfig = {}, routeName },
		) {
			const actualHeaders = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...config.extraHeaders,
				...headers,
			};
			return invokeResource(
				url,
				{
					callback,
					headers: actualHeaders,
					method: 'GET',
					responseTransformer,
					httpCodeHandlers,
					extraConfig,
					routeName,
				},
				config,
			);
		},
		post(
			url,
			{ body, callback, headers, extraConfig, responseTransformer, httpCodeHandlers, routeName },
		) {
			const actualHeaders = {
				'Content-Type': 'application/x-www-form-urlencoded',
				...config.extraHeaders,
				...headers,
			};
			return invokeResource(
				url,
				{
					body,
					callback,
					headers: actualHeaders,
					method: 'POST',
					extraConfig,
					responseTransformer,
					httpCodeHandlers,
					routeName,
				},
				config,
			);
		},
		put(
			url,
			{ body, callback, headers, extraConfig, responseTransformer, httpCodeHandlers, routeName },
		) {
			const actualHeaders = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...config.extraHeaders,
				...headers,
			};
			return invokeResource(
				url,
				{
					body,
					callback,
					headers: actualHeaders,
					method: 'PUT',
					extraConfig,
					responseTransformer,
					httpCodeHandlers,
					routeName,
				},
				config,
			);
		},
		delete(
			url,
			{ callback, headers, extraConfig, responseTransformer, httpCodeHandlers, routeName },
		) {
			const actualHeaders = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...config.extraHeaders,
				...headers,
			};
			return invokeResource(
				url,
				{
					callback,
					headers: actualHeaders,
					method: 'DELETE',
					extraConfig,
					responseTransformer,
					httpCodeHandlers,
					routeName,
				},
				config,
			);
		},
		postLegacyResource(
			url,
			{ body, callback, headers, method, responseTransformer, httpCodeHandlers, routeName },
		) {
			return cfetch(url, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					...config.extraHeaders,
					...headers,
				},
				method: 'POST',
				body: typeof body === 'string' ? body : new URLSearchParams(body).toString(),
			})
				.then(
					/* onFulfilled */ (response) => {
						const contentType = response.headers.get('content-type');
						return !contentType ||
							contentType.indexOf('text/html') !== -1 ||
							contentType.indexOf('text/plain') !== -1
							? response.text()
							: response.json();
					},
					/* onRejected */ (reason) => {
						if (reason instanceof BadStatusError) {
							return new Error('Request failed with ' + reason.response.status);
						}

						throw reason;
					},
				)
				.then((result) => {
					if (result instanceof Error) {
						return callback(result);
					} else {
						return callback(
							null,
							responseTransformer
								? responseTransformer(result, url, config.contextPath, body)
								: result,
						);
					}
				})
				.catch(requestErrorHandler(callback, httpCodeHandlers, method, url, routeName));
		},

		/**
		 * Clean wrapper around Native fetch - it returns the fetch promise so it
		 * can be used when we need to delegate workflow rules to the action creator
		 * instead of relying on the middleware.
		 *
		 * @param url
		 * @param options
		 * @returns Promise
		 */
		fetch(url, options) {
			// eslint-disable-next-line no-restricted-syntax
			return fetch(url, options);
		},
	};
}

/** Global request helper with shared configuration to avoid breaking API changes.
 *
 * @deprecated
 * @type Object
 */
const globalRequestHelper = requestHelperFactory();
globalRequestHelper.configure();
export default globalRequestHelper;
