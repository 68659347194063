import { query } from '@confluence/query-preloader-tools';

import type {
	PageRestrictionsQuery as Q,
	PageRestrictionsQueryVariables as V,
} from './__types__/PageRestrictionsQuery';
import { ALLOWED_CONTENT_STATUSES } from './constants';
import { PageRestrictionsQuery } from './PageRestrictionsQuery.graphql';

export function preloadPageRestrictionsQuery(contentId: V['contentId'], includeAncestors: boolean) {
	return query<Q, V>({
		query: PageRestrictionsQuery,
		variables: {
			contentId,
			status: ALLOWED_CONTENT_STATUSES,
			includeAncestors,
		},
		// Must be preloaded with network only to get fresh data
		fetchPolicy: 'network-only',
	});
}
