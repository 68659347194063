/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx, css } from '@emotion/react';
import { useState, lazy, Suspense, useRef } from 'react';
import { token } from '@atlaskit/tokens';
import { AkForm, FormContext, useForm } from '../form';
import { type RenderFn } from '@atlassian/forge-ui-types';
import type {
	ModalDialogProps,
	ModalBodyProps,
	ModalTitleProps,
} from '@atlaskit/modal-dialog/types';
import { type LoadingButtonProps } from '@atlaskit/button/loading-button';
import { useNativeActionButtons } from '../utils/useNativeActionButton';
import { useNativeButton } from '../button';

const AkModalDialog = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.modal-dialog' */
			'@atlaskit/modal-dialog'
		),
);
const AKModalHeader = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.modal-dialog/modal-header' */
			'@atlaskit/modal-dialog/modal-header'
		),
);
const AKModalTitle = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.modal-dialog/modal-title' */
			'@atlaskit/modal-dialog/modal-title'
		),
);
const AKModalBody = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.modal-dialog/modal-body' */
			'@atlaskit/modal-dialog/modal-body'
		),
);
const AKModalFooter = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.modal-dialog/modal-footer' */
			'@atlaskit/modal-dialog/modal-footer'
		),
);
const AKButton = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.button/loading-button' */
			'@atlaskit/button/loading-button'
		),
);

function useModalDialog({ forgeDoc, dispatch, render }: Parameters<RenderFn>[0]): {
	akModalDialogProps: ModalDialogProps;
	akModalTitleProps: ModalTitleProps;
	akModalBodyProps: ModalBodyProps;
	actions: LoadingButtonProps[];
} {
	const {
		appearance,
		header,
		onClose,
		closeButtonText = 'Cancel',
		width = 'medium',
		onSubmitSuccess,
	} = forgeDoc.props || {};

	const formForgeDoc =
		forgeDoc.children.length === 1 && forgeDoc.children[0].type === 'Form'
			? forgeDoc.children[0]
			: undefined;

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const [formValues, setFormValues] = useState({});
	const setFormValue = (name: string, value: any) => {
		setFormValues((prevState) => ({ ...prevState, [name]: value }));
	};

	const {
		children,
		akFormProps,
		htmlFormProps,
		akSubmitButtonProps: akFormSubmitButtonProps,
	} = useForm({
		// This is awkward because we only use values if formForgeDoc exists
		// I want to conditionally call this hook but I can't
		forgeDoc: formForgeDoc || { type: 'Form', children: [] },
		dispatch,
		render,
	});

	const akFormPropsWithOnSubmit: typeof akFormProps = {
		...akFormProps,
		onSubmit: async (...args) => {
			setIsSubmitting(true);
			try {
				await akFormProps.onSubmit(...args);
				if (onSubmitSuccess) {
					await onSubmitSuccess();
				}
			} finally {
				setIsSubmitting(false);
			}
		},
	};

	const formId = useRef(`modal-form-id-${Date.now()}`);
	const akSubmitButtonProps = {
		...akFormSubmitButtonProps,
		appearance: appearance || 'primary',
		isLoading: isSubmitting,
		form: formId.current,
	};

	const handleClose = async () => {
		setIsClosing(true);
		try {
			await onClose();
		} finally {
			setIsClosing(false);
		}
	};

	const actions = formForgeDoc?.props?.actionButtons || [];
	const { akActionButtonProps } = useNativeActionButtons({
		forgeDoc: actions,
		dispatch,
		render,
	});

	const { akButtonProps: closeButtonProps } = useNativeButton({
		forgeDoc: {
			type: 'Button',
			props: {
				appearance: 'subtle',
				onClick: onClose,
			},
			children: [
				{
					type: 'String',
					children: [],
					props: {
						text: closeButtonText,
					},
				},
			],
		},
		dispatch,
		render,
	});
	const akCloseButtonProps = {
		...closeButtonProps,
		isLoading: isClosing || closeButtonProps.isLoading,
	};

	const { key: akFormPropsWithOnSubmitKey, onSubmit: akFormPropsWithOnSubmitOnSubmit } =
		akFormPropsWithOnSubmit;

	return {
		akModalDialogProps: {
			onClose: handleClose,
			width,
		},
		akModalTitleProps: {
			appearance,
			children: header,
		},
		akModalBodyProps: {
			children: formForgeDoc ? (
				<AkForm key={akFormPropsWithOnSubmitKey} onSubmit={akFormPropsWithOnSubmitOnSubmit}>
					{({ formProps }) => {
						const { ref, onSubmit, onKeyDown } = formProps;
						// @ts-ignore
						const { 'data-testid': dataTestId, className } = htmlFormProps;

						return (
							<form
								ref={ref}
								onSubmit={onSubmit}
								onKeyDown={onKeyDown}
								data-testid={dataTestId}
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={className}
								id={formId.current}
							>
								<FormContext.Provider value={{ formValues, setFormValue }}>
									{children}
								</FormContext.Provider>
							</form>
						);
					}}
				</AkForm>
			) : (
				<div
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={css({
						display: 'inherit',
						width: 'inherit',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
						'> *:not(:first-child)': {
							marginTop: token('space.150', '12px'),
							width: 'inherit',
						},
					})}
				>
					{forgeDoc.children.map(render)}
				</div>
			),
		},
		actions: formForgeDoc
			? [...akActionButtonProps, akCloseButtonProps, akSubmitButtonProps]
			: [akCloseButtonProps],
	};
}

export function ModalDialog(props: Parameters<RenderFn>[0]) {
	const { akModalDialogProps, akModalTitleProps, akModalBodyProps, actions } =
		useModalDialog(props);

	const { onClose, width } = akModalDialogProps;

	const { appearance, children: akModalTitleChildren } = akModalTitleProps;

	const { children: akModalBodyChildren } = akModalBodyProps;

	return (
		<Suspense fallback={null}>
			<AkModalDialog onClose={onClose} width={width}>
				<AKModalHeader>
					<AKModalTitle appearance={appearance}>{akModalTitleChildren}</AKModalTitle>
				</AKModalHeader>
				<AKModalBody>{akModalBodyChildren}</AKModalBody>
				<AKModalFooter>
					{actions.map((akButtonProps) => {
						const {
							shouldFitContainer,
							iconBefore,
							iconAfter,
							isLoading,
							isDisabled,
							appearance,
							onClick,
							children,
							type,
							form,
						} = akButtonProps;

						return (
							<AKButton
								shouldFitContainer={shouldFitContainer}
								iconBefore={iconBefore}
								iconAfter={iconAfter}
								isLoading={isLoading}
								isDisabled={isDisabled}
								appearance={appearance}
								onClick={onClick}
								type={type}
								form={form}
							>
								{children}
							</AKButton>
						);
					})}
				</AKModalFooter>
			</AkModalDialog>
		</Suspense>
	);
}
