import React, { useRef, useEffect } from 'react';
import idx from 'idx';
import uuidv4 from 'uuid/v4';

import type { UIAnalyticsEvent, UIAnalyticsEventHandler } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';

import { AtlaskitShim } from './AtlaskitShim';
import type { NavdexPointType } from './NavdexPointType';
import { START, START_TOUCH, TOUCH, END } from './NavdexPointType';

export const NAVDEX_STORAGE_KEY = 'navigationSession';

const SESSION_EXPIRE_MILLIS = 10 * 60 * 1000;

type SessionId = string;

type NavdexSession = {
	id: SessionId;
	updated: Date;
	updateStorage: boolean;
};

type NavdexListenerProps = {
	children: React.ReactNode;
};

const loadSession = (): NavdexSession | null => {
	const sessionString = localStorage.getItem(NAVDEX_STORAGE_KEY);
	if (!sessionString) {
		return null;
	}
	const session = JSON.parse(sessionString);
	session.updated = new Date(session.updated);
	return session;
};

export const NavdexListener = ({ children }: NavdexListenerProps) => {
	const session = useRef(loadSession());

	const setSession = (newSession: NavdexSession | null) => {
		session.current = newSession;
		if (!session.current) {
			localStorage.removeItem(NAVDEX_STORAGE_KEY);
		} else if (session.current.updateStorage) {
			try {
				localStorage.setItem(NAVDEX_STORAGE_KEY, JSON.stringify(session.current));
			} catch (e) {
				localStorage.removeItem(NAVDEX_STORAGE_KEY);
			}
		}
	};

	useEffect(() => {
		const storageListener = (event: StorageEvent) => {
			if (event.key === NAVDEX_STORAGE_KEY) {
				const newSession = loadSession();
				if (newSession) {
					touchSession(newSession.id, false);
				} else {
					setSession(null);
				}
			}
		};

		window.addEventListener('storage', storageListener);
		return () => {
			window.removeEventListener('storage', storageListener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateEvent = (
		event: UIAnalyticsEvent,
		navigationSessionId: SessionId,
		pointTypeOverride?: NavdexPointType,
	) => {
		if (event.payload.data) {
			event.update((payload) => ({
				...payload,
				data: {
					...payload.data,
					attributes: {
						...payload.data.attributes,
						navigationSessionId,
						navdexPointType: pointTypeOverride || payload.data.attributes.navdexPointType,
					},
				},
			}));
		} else {
			// Atlaskit events lack the data object
			event.update((payload) => ({
				...payload,
				attributes: {
					...payload.attributes,
					navigationSessionId,
					navdexPointType: pointTypeOverride || payload.attributes.navdexPointType,
				},
			}));
		}
	};

	const touchSession = (sessionId: SessionId, updateStorage: boolean = true) => {
		setSession({
			id: sessionId,
			updated: new Date(),
			updateStorage,
		});
	};

	const handleTouchPoint: UIAnalyticsEventHandler = (event) => {
		const type: NavdexPointType =
			idx(event, (_) => _.payload.data.attributes.navdexPointType) ||
			// Atlaskit events lack the data object
			idx(event, (_) => _.payload.attributes.navdexPointType);

		let sessionId = session.current && session.current.id;

		if (session.current && Date.now() - session.current.updated.getTime() > SESSION_EXPIRE_MILLIS) {
			setSession(null);
			sessionId = null;
		}

		switch (type) {
			case START:
				const newSessionId = uuidv4();
				updateEvent(event, newSessionId);
				touchSession(newSessionId);
				break;
			case START_TOUCH:
				const actualPointType = sessionId ? TOUCH : START;
				sessionId = sessionId || uuidv4();
				updateEvent(event, sessionId, actualPointType);
				touchSession(sessionId);
				break;
			case TOUCH:
				if (sessionId) {
					updateEvent(event, sessionId);
					touchSession(sessionId);
				}
				break;
			case END:
				if (sessionId) {
					updateEvent(event, sessionId);
					setSession(null);
				}
				break;
		}
	};

	return (
		<AnalyticsListener onEvent={handleTouchPoint} channel="*">
			<AtlaskitShim>{children}</AtlaskitShim>
		</AnalyticsListener>
	);
};
