// (Normally these contants' names would end with "_ANALYTICS_SOURCE" but then the names would be
//  things like "SPACE_ANALYTICS_ANALYTICS_SOURCE" and that's not readable)
export const SITE_ANALYTICS_EVENTS_SOURCE = 'instanceAnalytics';
export const SITE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE = 'instanceAnalyticsPermissions';
export const SPACE_ANALYTICS_EVENTS_SOURCE = 'insights/spaceAnalytics';
export const SPACE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE = 'spaceAnalyticsPermissions';

// WebItem ID for the space analytics permissions settings menu item
export const SPACE_ANALYTICS_PERMISSIONS_WEBITEM_ID =
	'com.addonengine.analytics__analytics-permissions-space-tools-tab';
