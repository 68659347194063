import { liftPromiseState, isResolved } from '@confluence/lifted-promise';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';

declare global {
	interface Window {
		QUERY_PRELOADER_RESULTS?: QueryPreloaderStoreType;
	}
}

type QueryPreloaderStoreType = { [key: string]: Promise<any> };
type PreloadResultKeyType = {
	name: string;
	variables?: { [key: string]: any };
};
type DataAccessor = (result: any) => any;

export class PreloadResult {
	store: QueryPreloaderStoreType;

	constructor(store: QueryPreloaderStoreType = {}) {
		this.store = store;
	}

	serialize(value?: any): string {
		if (Array.isArray(value)) {
			return `[${value.map(this.serialize, this).join(',')}]`;
		} else if (typeof value === 'object') {
			const serializedObject = Object.entries(value ?? {})
				.filter((entry) => entry[1] !== undefined)
				.sort((a, b) => (a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0))
				.map(([key, child]) => `${key}:${this.serialize(child)}`)
				.join(',');
			return `{${serializedObject}}`;
		} else {
			return String(value);
		}
	}

	toHashKey({ name, variables }: PreloadResultKeyType) {
		const serializedVariables = this.serialize(variables ?? {});
		return `${name}:${serializedVariables}`;
	}

	load(
		key: PreloadResultKeyType,
		loader: () => Promise<any>,
		{ refetchIfNotClaimed = true } = {},
		saveToStore = true,
	) {
		const hash = this.toHashKey(key);

		const disablePreloadedQueryLinkSaveToStore =
			getSSRFeatureFlag('confluence.ssr.disable-preloadedquerylink-save-to-store') || false;

		// Ignore the request if there is a pending request with the exact same query name and variables.
		let query;
		if (!this.store[hash] || (refetchIfNotClaimed && isResolved(this.store[hash]))) {
			query = liftPromiseState(loader());

			if (saveToStore || !disablePreloadedQueryLinkSaveToStore) {
				this.store[hash] = query;
			}
		}

		if (disablePreloadedQueryLinkSaveToStore) {
			return this.store[hash] || query;
		}

		return this.store[hash];
	}

	claim(key: PreloadResultKeyType, dataAccessor?: DataAccessor): Promise<object | null> {
		const hash = this.toHashKey(key);
		const result = this.store[hash];
		return result
			? result
					.catch(() => {
						delete this.store[hash];
						return null;
					})
					.then((result) => {
						// Cypress uses Electron 61 which doesn't support finally
						delete this.store[hash];
						// Custom implementation for accessing preloaded data
						if (dataAccessor) {
							return dataAccessor(result);
						}
						// Ignore the preloaded data if there is no valid data field
						return result && result.data ? result : null;
					})
			: Promise.resolve(null);
	}
}

let preloadResult: PreloadResult | null = null;
export function getPreloadResult(): PreloadResult {
	if (preloadResult === null) {
		// Save data to a global variable so they can be shared between bundles.
		if (!window.QUERY_PRELOADER_RESULTS) {
			window.QUERY_PRELOADER_RESULTS = {};
		}
		preloadResult = new PreloadResult(window.QUERY_PRELOADER_RESULTS);
	}

	return preloadResult;
}
