import { LoadablePaint } from '@confluence/loadable';

export const EmbeddedViewPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-view-pageEmbeddedViewPage" */ './view-page/EmbeddedViewPage'
			)
		).EmbeddedViewPage,
});

export const EmbeddedEditPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-edit-pageEmbeddedEditPage" */ './edit-page/EmbeddedEditPage'
			)
		).EmbeddedEditPage,
});

export const EmbeddedWhiteboardLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-embeddedWhiteboard" */ './whiteboard/EmbeddedWhiteboard'
			)
		).EmbeddedWhiteboard,
});
