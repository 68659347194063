// eslint-disable-next-line import/no-extraneous-dependencies
import _isNumber from 'is-number';
import { defineMessages, createIntl, createIntlCache, type IntlShape } from 'react-intl-next';

const cache = createIntlCache();
const defaultIntl = createIntl({ locale: 'en' }, cache);

export function isNumber(value: unknown, propIntl?: IntlShape) {
	const intl = propIntl ? propIntl : defaultIntl;
	const { formatMessage } = intl;
	return _isNumber(value) ? undefined : formatMessage(messages.validNumber);
}

export function isEmail(value: unknown, propIntl?: IntlShape) {
	const intl = propIntl ? propIntl : defaultIntl;
	const { formatMessage } = intl;
	if (typeof value === 'string' && /@/.test(value)) {
		return;
	}
	// return 'Enter a valid email.';
	return formatMessage(messages.validEmail);
}

export function isTelephone(value: unknown, propIntl?: IntlShape) {
	const intl = propIntl ? propIntl : defaultIntl;
	const { formatMessage } = intl;
	if (typeof value === 'string' && /[0-9]/.test(value)) {
		return;
	}
	// return 'Enter a valid telephone.';
	return formatMessage(messages.validTelephone);
}

export type ValidateFn = (value: unknown, intl?: IntlShape) => string | undefined;

const Validators: { [key: string]: ValidateFn } = {
	email: isEmail,
	number: isNumber,
	tel: isTelephone,
};

export function makeValidate(
	isRequired: boolean,
	type: keyof typeof Validators,
	propIntl?: IntlShape,
) {
	return function Validate(value: unknown) {
		const intl = propIntl ? propIntl : defaultIntl;
		const { formatMessage } = intl;
		const isEmpty = typeof value === 'string' && value.length === 0;
		if (isRequired && isEmpty) {
			// return 'This field is required.';
			return formatMessage(messages.requiredFieldMessage);
		}
		if (isEmpty) {
			return;
		}

		const isType = type in Validators ? Validators[type] : undefined;
		return isType ? isType(value, propIntl) : undefined;
	};
}

const messages = defineMessages({
	validNumber: {
		id: 'confluence.textfield.validator.number',
		defaultMessage: 'Enter a valid number',
		description: 'Message prompting the user to enter a number in the proper format.',
	},
	validEmail: {
		id: 'confluence.textfield.validator.email',
		defaultMessage: 'Enter a valid email.',
		description: 'Message prompting the user to enter an email address in the proper format.',
	},
	validTelephone: {
		id: 'confluence.textfield.validator.telephone',
		defaultMessage: 'Enter a valid telephone.',
		description: 'Message prompting the user to enter a phone number in the proper format.',
	},
	requiredFieldMessage: {
		id: 'confluence.textfield.validator.required.field.message',
		defaultMessage: 'This field is required.',
		description: 'This message alerts the user that a field is required.',
	},
});
