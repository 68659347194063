import { adaptEventHandler } from '../../../utils';
import type { Icon as IconType } from '@atlassian/forge-ui-types';
import type { ButtonProps as PlatformButtonProps } from '@atlaskit/button/new';
import { IconComponent } from '../../icon';

const createIconComponent = (icon: IconType) => {
	const Component = () => <IconComponent glyph={icon} label={icon} />;
	return Component;
};

export type ButtonProps = Pick<
	PlatformButtonProps,
	| 'children'
	| 'autoFocus'
	| 'isDisabled'
	| 'isSelected'
	| 'onBlur'
	| 'onClick'
	| 'onFocus'
	| 'testId'
	| 'shouldFitContainer'
	| 'appearance'
	| 'type'
> & {
	iconBefore?: IconType;
	iconAfter?: IconType;
	spacing?: PlatformButtonProps['spacing'] | 'none';
};

/**
 * Mapping the deprecated 'none' spacing to 'compact'
 */
const mapDeprecatedSpacing = (spacing: ButtonProps['spacing']) =>
	spacing === 'none' ? 'compact' : spacing;

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::71806a24696290b9d686674f7d02d717>>
 * @codegenId #button
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen button
 * @codegenParams { "defineOwnType": true, "props": { "children": true, "autoFocus": true, "overlay": false, "isDisabled": true, "isSelected": true, "onBlur": { "value": "adaptEventHandler(onBlur)" }, "onClick": { "value": "adaptEventHandler(onClick)" }, "onFocus": { "value": "adaptEventHandler(onFocus)" }, "spacing": { "value": "mapDeprecatedSpacing(spacing)" }, "testId": true, "shouldFitContainer": true, "appearance": true, "type": true, "iconAfter": { "value": "iconAfter && createIconComponent(iconAfter)" }, "iconBefore": { "value": "iconBefore && createIconComponent(iconBefore)" } } }
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/default/types.tsx <<SignedSource::b9aec5245f14d39bd330fbc759b068dd>>
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/types.tsx <<SignedSource::e02baeacd709a2faae3cf14a0e45b694>>
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/default/use-default-button.tsx <<SignedSource::6d7b8d1f729d119dcf1d3aef067dbc6e>>
 * @codegenDependency ../../../../../../../design-system/button/src/new-button/variants/default/button.tsx <<SignedSource::14882a31566ae77d011a3ccd7907a4e3>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformButton from '@atlaskit/button/new';

// Define the type for Button as ButtonProps at the top of this file

export const Button = (props: Parameters<RenderFn>[0]) => {
  const {
    autoFocus,
    isDisabled,
    isSelected,
    onBlur,
    onClick,
    onFocus,
    spacing,
    testId,
    shouldFitContainer,
    appearance,
    type,
    iconAfter,
    iconBefore,
  } = props.forgeDoc.props as ButtonProps;
  return (
    <PlatformButton
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onBlur={adaptEventHandler(onBlur)}
      onClick={adaptEventHandler(onClick)}
      onFocus={adaptEventHandler(onFocus)}
      spacing={mapDeprecatedSpacing(spacing)}
      testId={testId}
      shouldFitContainer={shouldFitContainer}
      appearance={appearance}
      type={type}
      iconAfter={iconAfter && createIconComponent(iconAfter)}
      iconBefore={iconBefore && createIconComponent(iconBefore)}
    />
  );
};
/**
 * @codegenEnd
 */
