import { type Context, type UserInfo } from '../types';

export type CompressorPredicate = (event: any) => boolean;

export type CompressorFunction = (events: any[]) => any[];

export enum StopLowPriorityEventDelayReason {
	TIMEOUT = 'timeout',
	MANUAL = 'manual',
}

export type fireDelayedEventFn = (
	identifier: string,
	builtEvent: any,
	context: Context,
	userInfo: UserInfo,
) => void;
