import { LoadableLazy } from '@confluence/loadable';

export const ChangeHubNameDialogLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ChangeHubNameDialogLoadable" */ './ChangeHubNameDialog'
			)
		).ChangeHubNameDialog,
});
