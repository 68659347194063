import { chartColorTokens } from './colorTokens/chartColorTokens';

const getColorTokenValue = (
	colors: string[] | undefined,
	colorTokenMap: Record<string, string>,
	colorTokenDocLink?: string,
): string[] | undefined => {
	if (!colors || !Array.isArray(colors) || !colors.length) {
		return;
	}

	const { colorValues, invalidTokens } = colors.reduce(
		(acc, color) => {
			const value = colorTokenMap[color] ?? '';

			return {
				colorValues: [...acc.colorValues, value],
				invalidTokens: value ? acc.invalidTokens : [...acc.invalidTokens, `${color}`],
			};
		},
		{ colorValues: [] as string[], invalidTokens: [] as string[] },
	);

	if (invalidTokens.length) {
		// eslint-disable-next-line no-console
		console.error(
			`Unsupported color token used: ${invalidTokens.join(', ')}. ${colorTokenDocLink ? `For supported color tokens, see ${colorTokenDocLink}` : ''}`,
		);
	}
	return colorValues;
};

export const getChartColorTokenValues = (colors: string[] | undefined) => {
	const chartTokenLink = 'https://atlassian.design/components/tokens/all-tokens#color-chart';

	return getColorTokenValue(colors, chartColorTokens, chartTokenLink);
};
