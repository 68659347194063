import { ApolloLink, Observable } from 'apollo-link';

import { EXTERNAL_SHARE } from '@confluence/named-routes';

/**
 * This custom link terminate queries without allowOnExternalShare context property
 * if query was fired from external page
 */
export const externalShareLink = new ApolloLink((operation, forward) => {
	const { allowOnExternalPage = false } = operation.getContext();

	const isExternalShareRequest = !!EXTERNAL_SHARE.match(window.location.pathname);
	if (allowOnExternalPage || !isExternalShareRequest) {
		return forward(operation);
	}

	return new Observable((observer) => {
		observer.complete();
	});
});
