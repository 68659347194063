import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LiveEditSiteSettingsPageLoader } from '@confluence/live-pages-features/entry-points/LiveEditSiteSettingsPage';

export const LiveEditSiteSettingsPageRoute = () => <LiveEditSiteSettingsPageLoader />;

LiveEditSiteSettingsPageRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'liveEditSiteSettingsPage' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
