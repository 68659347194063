import { getBscGlobalState, isSSR } from '../../common/utils';
import { Logger } from '../../common/utils/logger';
import { Status } from '../../types';
import { BSCIndexedDB } from '../indexed-db-service';

import { WebStorageType } from './types';
import { getStorageItem, setStorageItem, setStrictlyNecessaryStorageItem } from './utils';

class AtlBrowserStorage {
	storage: Storage;
	storageType: WebStorageType;
	isAvailable = false;

	constructor(storage: Storage, storageType: WebStorageType) {
		this.storage = storage;
		this.storageType = storageType;
		this.isAvailable = this.isStorageAvailable();
	}

	get isWebStorageEnabled() {
		if (getBscGlobalState().webStorageEnabled) {
			return true;
		}
		Logger.warn('WebStorage config is disabled, cannot proceed.');
		return false;
	}

	// Core API
	getItem(key: string): string | null {
		if (!this.isWebStorageEnabled) {
			return null;
		}

		if (!this.isAvailable) {
			// If our storage hasn't been enabled, short-circuit because we can't use the native utility
			Logger.warn(`${this.storageType} is unavailable. Failed to get item.`);
			return null;
		}
		const retrievedItem = getStorageItem(key, this.storageType, this.storage);
		return retrievedItem;
	}

	async setItem(key: string, value: string): Promise<void> {
		if (!this.isWebStorageEnabled) {
			return;
		}

		if (!this.isAvailable) {
			// If our storage hasn't been enabled, short-circuit because we can't use the native utility
			Logger.warn(`${this.storageType} is unavailable. Failed to set item.`);
			return;
		}

		const globalStateProduct = getBscGlobalState().product;

		const itemSetStatus = await setStorageItem(
			key,
			value,
			this.storageType,
			globalStateProduct,
			this.storage,
		);
		Logger.warn(
			`${itemSetStatus === Status.SUCCESS ? 'Successfully' : 'Failed to'} set item ${key} with value ${value}`,
		);
	}

	setStrictlyNecessaryItem(key: string, value: string): void {
		if (!this.isWebStorageEnabled) {
			return;
		}

		if (!this.isAvailable) {
			// If our storage hasn't been enabled, short-circuit because we can't use the native utility
			Logger.warn(`${this.storageType} is unavailable. Failed to set strictly necessary item.`);
			return;
		}

		const itemSetStatus = setStrictlyNecessaryStorageItem(
			key,
			value,
			this.storageType,
			this.storage,
		);
		Logger.warn(
			`${itemSetStatus === Status.SUCCESS ? 'Successfully' : 'Failed to'} set strictly necessary item ${key} with value ${value}`,
		);
	}

	// Native Utilities
	removeItem(key: string): void {
		if (!this.isWebStorageEnabled) {
			return;
		}

		if (!this.isAvailable) {
			// If our storage hasn't been enabled, short-circuit because we can't use the native utility
			Logger.warn(`${this.storageType} is unavailable. Failed to remove item.`);
			return;
		}
		this.storage.removeItem(key);
	}

	key(index: number): string | null {
		if (!this.isWebStorageEnabled) {
			return null;
		}

		if (!this.isAvailable) {
			return null;
		}
		return this.storage.key(index);
	}

	length(): number {
		if (!this.isWebStorageEnabled) {
			return 0;
		}

		if (!this.isAvailable) {
			return 0;
		}
		return this.storage.length;
	}

	clear(): void {
		if (!this.isWebStorageEnabled) {
			return;
		}

		if (!this.isAvailable) {
			return;
		}
		this.storage.clear();
	}

	// Utilities
	isStorageAvailable(): boolean {
		if (isSSR()) {
			return false;
		}
		try {
			const x = '__storage_test__';
			this.storage.setItem(x, x);
			this.storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// everything except Firefox
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// test name field too, because code might not be present
					// everything except Firefox
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				this.storage &&
				this.storage.length !== 0
			);
		}
	}

	checkIfSetByPackage = async ({
		itemKey,
		isSetCallback = () => {},
		isNotSetCallback = () => {},
	}: {
		itemKey: string;
		isSetCallback?: () => void;
		isNotSetCallback?: () => void;
	}) => {
		// As IDB is asynchronous in nature, when this in invoked, we need to rely on the callbacks
		// to actually do something with the result.
		try {
			const record = await BSCIndexedDB.getRecord(itemKey);
			if (record) {
				isSetCallback();
				return true;
			} else {
				isNotSetCallback();
				return false;
			}
		} catch (e: any) {
			// If it fails to pull from IDB, we can't validate that it's been set by our package or not, so log the error
			// and assume it hasn't been set by our package. This will only impact our analytics correctness.
			Logger.errorWithOperationalEvent({
				action: 'usedCheckIfSetByPackageError',
				message: `IDB lookup failed to check if item was set by package. ${e.message || ''}`,
				attributes: {
					itemKey,
				},
			});
			isNotSetCallback();
			return false;
		}
	};
}

const AtlBrowserStorageLocal = new AtlBrowserStorage(
	window?.localStorage,
	WebStorageType.LocalStorage,
);

const AtlBrowserStorageSession = new AtlBrowserStorage(
	window?.sessionStorage,
	WebStorageType.SessionStorage,
);

export const findStorageTarget = (type: WebStorageType) => {
	return {
		[WebStorageType.LocalStorage]: AtlBrowserStorageLocal,
		[WebStorageType.SessionStorage]: AtlBrowserStorageSession,
	}[type];
};

export { AtlBrowserStorage, AtlBrowserStorageSession, AtlBrowserStorageLocal, WebStorageType };
