export const getHTTPProtocol = (resourceTimings: PerformanceEntry[]) => {
	for (const entry of resourceTimings as PerformanceResourceTiming[]) {
		if (entry.initiatorType !== 'script') continue;
		// As of Feb 2021, `nextHopProtocol` is only supported by 73% of browsers
		// https://caniuse.com/mdn-api_performanceresourcetiming_nexthopprotocol
		if ((entry as any)?.nextHopProtocol) {
			return (entry as any).nextHopProtocol;
		}
	}
	return 'unknown';
};
