import React from 'react';
import { type NativeStatusLozengeProps, type RenderFn } from '@atlassian/forge-ui-types';
import { getChildrenText } from '../utils/getChildrenText';

const AkStatusLozenge = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.lozenge' */
			'@atlaskit/lozenge'
		),
);

export const StatusLozenge = ({ forgeDoc: { props, children } }: Parameters<RenderFn>[0]) => {
	const { appearance } = props as NativeStatusLozengeProps;

	return <AkStatusLozenge appearance={appearance}>{getChildrenText(children)}</AkStatusLozenge>;
};
