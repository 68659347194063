import type { ApolloClient } from 'apollo-client';

import { createClient } from './createClient';

// Make sure there is only one client being created in the app
let apolloClient: ApolloClient<any> | null = null;
export function getApolloClient(): ApolloClient<any> {
	// Ensure tests are using the same apollo client.
	// Also allow query in @confluence/query-preloader to use apollo without direct dependency.
	if (window['GLOBAL_APOLLO_CLIENT']) {
		return window['GLOBAL_APOLLO_CLIENT'];
	}
	if (!apolloClient) {
		apolloClient = createClient();
	}
	window['GLOBAL_APOLLO_CLIENT'] = apolloClient!;
	return apolloClient!;
}
