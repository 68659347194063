import { AutoIncrementingID, Cache } from '@confluence/generics';

import { BMEventBus } from '../utils/BMEventBus';

import type { ComputedCP, CriticalPath, JSCache } from './types';

/**
 * JS Cache Rate
 *
 * An optimization on top of tracking our JS load's influence
 * on specific page/segment loads. Instead of recomputing cache
 * rates based upon a bottom-up iteration of loaded assets, the
 * `JSCacheRate` module will compute cache rates by iterating
 * only the assets that have not yet been used to compute cache
 * rates historically
 */
export class JSCacheRate {
	private static IDs = new AutoIncrementingID();
	private static Cache = new Cache<Record<string, JSCache>>();
	public static Listener = BMEventBus.on('page-transition', this.clearCache.bind(this));

	/**
	 * Clear Cache
	 *
	 * Clears all existing cache entries
	 */
	public static clearCache() {
		this.IDs.reset();
		this.Cache.clear();
	}

	/**
	 * Compute Cache Rate
	 *
	 * Iterates the cache looking for the LRU cache compute
	 * and creates a new compute based upon the results of
	 * it (if it exists). If not, it'll compute cache rate
	 * using a bottom-up iteration of loaded assets
	 */
	public static computeCacheRate(time: number) {
		let lastKey = '';
		const entries = this.Cache.keys;
		const maxIndex = entries.length - 1;
		for (let i = maxIndex; i > -1; i--) {
			const key = entries[i];
			const { time: stopTime } = this.Cache.get(key);
			if (time > stopTime) {
				lastKey = key;
				break;
			}
		}
		let newEntry: ComputedCP;
		if (lastKey) {
			newEntry = this.mergeFromLastKey(lastKey, time);
		} else {
			newEntry = this.iterateResources(time);
		}
		const ID = JSCacheRate.IDs.ID();
		JSCacheRate.Cache.set(ID, {
			time,
			...newEntry,
		});
		return JSCacheRate.Cache.get(ID);
	}

	/**
	 * Merge From Last Key
	 *
	 * Combines the LRU cache rate with a cache rate being computed
	 */
	private static mergeFromLastKey(key: string, time: number) {
		const { lastIndex, criticalPath } = JSCacheRate.Cache.get(key);
		const { lastIndex: newIndex, criticalPath: newCriticalPath } = this.iterateResources(
			time,
			lastIndex + 1,
		);
		return {
			lastIndex: newIndex,
			criticalPath: this.merge(criticalPath, newCriticalPath),
		};
	}

	/**
	 * Iterate Resources
	 *
	 * Iterates performance resource timings given a time and from (index)
	 * filter. While iterating, this method will accumulate the scripts
	 * that have loaded prior to the input time and capture the last index
	 * of the final script loaded within that scope
	 */
	private static iterateResources(time: number, from: number = 0): ComputedCP {
		const criticalPath = this.criticalPath;
		const resources = window.performance.getEntriesByType('resource');
		const { length } = resources;
		let lastIndex = from;
		for (let i = lastIndex; i < length; i++) {
			const entry = resources[i] as PerformanceResourceTiming;
			if (entry.initiatorType !== 'script' || entry.responseEnd > time) {
				continue;
			}
			lastIndex = i;
			if (entry.transferSize) {
				criticalPath.nonCachedSize += entry.encodedBodySize;
			} else {
				criticalPath.cachedCount += 1;
				criticalPath.cachedSize += entry.encodedBodySize;
			}
			criticalPath.totalSize += entry.encodedBodySize;
			criticalPath.scripts.push({
				name: entry.name.split('/').pop() || '',
				start: entry.startTime,
				end: entry.responseEnd,
				size: entry.encodedBodySize,
			});
		}
		return { criticalPath, lastIndex };
	}

	/**
	 * Critical Path
	 *
	 * Returns an initialized `CriticalPath` object
	 */
	private static get criticalPath(): CriticalPath {
		return {
			scripts: [],
			totalSize: 0,
			cachedCount: 0,
			cachedSize: 0,
			nonCachedSize: 0,
		};
	}

	/**
	 * Merge
	 *
	 * Merges the properties of two critical path objects
	 */
	private static merge(cachedCP: CriticalPath, newCP: CriticalPath): CriticalPath {
		return {
			scripts: cachedCP.scripts.concat(newCP.scripts),
			totalSize: cachedCP.totalSize + newCP.totalSize,
			cachedCount: cachedCP.cachedCount + newCP.cachedCount,
			cachedSize: cachedCP.cachedSize + newCP.cachedSize,
			nonCachedSize: cachedCP.nonCachedSize + newCP.nonCachedSize,
		};
	}
}
