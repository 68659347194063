import type { IntlShape } from 'react-intl-next';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import { i18n } from '../i18n';

import { DatabaseReferenceModalSkeleton } from './DatabaseReferenceModalSkeleton';

export type DatabaseReferenceModalProps = {
	onClose: (result: string | undefined) => void;
	iframeUrl: string;
	intl: IntlShape;
};

const isDatabaseUrl = (value: string): boolean => /database\/\d+/.test(value);

export const DatabaseReferenceModal = ({
	onClose,
	iframeUrl,
	intl,
}: DatabaseReferenceModalProps) => {
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const [isLoading, setIsLoading] = useState(true);

	const messageCallback = useCallback(
		(event: MessageEvent) => {
			const isFromExpectedIframe =
				iframeRef.current && event.source === iframeRef.current.contentWindow;

			if (isFromExpectedIframe) {
				try {
					const data = JSON.parse(event.data);
					if (data.finalDatabaseUrl && isDatabaseUrl(data.finalDatabaseUrl)) {
						onClose(data.finalDatabaseUrl);
					} else if (data.cancelDatabaseReference) {
						onClose(undefined);
					} else if (data.databaseReferencingComponentLoaded) {
						setIsLoading(false);
					}
				} catch (error) {}
			}
		},
		[onClose, iframeRef],
	);

	useEffect(() => {
		window.addEventListener('message', messageCallback);

		return () => {
			window.removeEventListener('message', messageCallback);
		};
	}, [messageCallback]);

	return (
		<ModalTransition>
			<Modal
				width={1131}
				height={600}
				onClose={() => onClose(undefined)}
				testId="reference-database-modal"
				label={intl.formatMessage(i18n.referenceDatabaseModalLabel)}
			>
				{isLoading && <DatabaseReferenceModalSkeleton />}
				<iframe
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						width: '100%',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						height: '600px',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						border: '0px',
						display: isLoading ? 'none' : 'block',
					}}
					src={iframeUrl}
					title="reference-database-frame"
					ref={iframeRef}
				/>
			</Modal>
		</ModalTransition>
	);
};
