import React, { useMemo } from 'react';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { useSessionData } from '@confluence/session-data';

import BaseCrossFlowProvider from '@atlassiansox/cross-flow-support/confluence';

import { getUsersPlugin, getContainersPlugin } from './utils';

export const CrossFlowProvider = ({ children }) => {
	const { cloudId, locale } = useSessionData();
	const analyticWebClient = useMemo(() => getAnalyticsWebClient(), []);
	const isMarketingOptOut =
		document.querySelector("meta[name='ajs-marketing-opt-out']")?.getAttribute('content') ===
		'true';

	if (isMarketingOptOut) {
		return children;
	}

	return (
		<BaseCrossFlowProvider
			cloudId={cloudId}
			analyticsClient={analyticWebClient}
			locale={locale}
			plugins={[getUsersPlugin, getContainersPlugin]}
		>
			{children}
		</BaseCrossFlowProvider>
	);
};
