import gql from 'graphql-tag';

import {
	contentAppearanceDraftFragment,
	contentAppearancePublishedFragment,
} from './fragments.fragment';

export const getDraftContentAppearanceProperty = gql`
	query getDraftContentAppearanceProperty($contentId: ID, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				id
				...contentAppearanceDraftFragment
			}
		}
	}

	${contentAppearanceDraftFragment}
`;

export const getPublishedContentAppearanceProperty = gql`
	query getPublishedContentAppearanceProperty($contentId: ID, $status: [String]) @SLA {
		content(id: $contentId, status: $status) {
			nodes {
				id
				...contentAppearancePublishedFragment
			}
		}
	}

	${contentAppearancePublishedFragment}
`;
