import { getUniquePageLoadId } from '@confluence/unique-page-load-id';

import type {
	ExperienceAttributes,
	ExperienceEvent,
	StartEvent,
	StopEvent,
} from './ExperienceEvent';
import { ExperienceTimeoutError } from './ExperienceTimeoutError';
import { getTabInactivityTracker } from './TabInactivityTracker';
import { getFreezeTracker } from './FreezeTracker';
import type { ExperienceStopStates } from './ExperienceStopStates';

type ConstructorProps = {
	name: string;
	id: string;
	timeout?: number;
	startTime?: number;
	attributes?: ExperienceAttributes;
	onStart?: (event: StartEvent) => (event: StopEvent) => void;
	onSuccess?: () => void;
	onFailure?: () => void;
	onAbort?: () => void;
};

type ExperienceStopStateKeys = keyof typeof ExperienceStopStates;
type ExperienceStopState = (typeof ExperienceStopStates)[ExperienceStopStateKeys];

export type ExperienceState = {
	name: string;
	timeout?: number;
	hasStopped: boolean;
	stopState: ExperienceStopState | null;
	startTime: number;
	attributes?: ExperienceAttributes;
};

export class Experience {
	name: string;
	id: string;
	timeout?: number;

	private freezeTime = 0;
	private stopState: ExperienceStopState | null = null;
	private startTime: number;
	private attributes?: ExperienceAttributes;
	private onStop?: (event: StopEvent) => void;
	private onSuccess?: () => void;
	private onFailure?: () => void;
	private onAbort?: () => void;

	constructor({
		name,
		id,
		timeout,
		startTime = window.performance.now(),
		attributes,
		onStart,
		onSuccess,
		onFailure,
		onAbort,
	}: ConstructorProps) {
		this.name = name;
		this.id = id;
		this.timeout = timeout;
		this.startTime = startTime;
		this.attributes = attributes;
		this.onSuccess = onSuccess;
		this.onFailure = onFailure;
		this.onAbort = onAbort;

		const startEvent: StartEvent = {
			action: 'taskStart',
			name,
			id,
			startTime,
			timeout,
			attributes: {
				...attributes,
				...getUniquePageLoadId(),
			},
		};
		if (onStart) {
			this.onStop = onStart(startEvent);
		}
		getFreezeTracker().subscribe(name, (time) => {
			this.freezeTime += time;
		});
	}

	succeed(attributes?: ExperienceAttributes) {
		if (this.hasStopped) return;

		this.onSuccess && this.onSuccess();

		this.stop({
			action: 'taskSuccess',
			name: this.name,
			id: this.id,
			startTime: this.startTime,
			duration: this.getAbsoluteDuration(),
			activeDuration: this.getDurationAdjustedForActive(),
			adjustedDuration: this.getDurationAdjustedForTabActivity(),
			attributes: {
				...this.attributes,
				...attributes,
				...getUniquePageLoadId(),
			},
		});
	}

	fail({ error, attributes }: { error: Error; attributes?: ExperienceAttributes }) {
		if (this.hasStopped) return;

		this.onFailure && this.onFailure();

		this.stop({
			action: 'taskFail',
			name: this.name,
			id: this.id,
			startTime: this.startTime,
			duration: this.getAbsoluteDuration(),
			activeDuration: this.getDurationAdjustedForActive(),
			adjustedDuration: this.getDurationAdjustedForTabActivity(),
			error,
			attributes: {
				...this.attributes,
				...attributes,
				...getUniquePageLoadId(),
			},
		});
	}

	abort({
		reason,
		attributes,
		checkForTimeout = true,
	}: {
		reason: string;
		attributes?: ExperienceAttributes;
		checkForTimeout?: boolean;
	}) {
		if (this.hasStopped) return;

		const adjustedDuration = this.getDurationAdjustedForTabActivity();
		const isTimeout = checkForTimeout && this.timeout != null && adjustedDuration >= this.timeout;

		// Check if the experience should have failed due to timeout
		if (isTimeout) {
			// We want to abort timeouts from view-page and create-page to improve SLO reliability accuracy as they're not guaranteed to be correlated to real user impact. For more info see MODES-4806.
			if (this.name !== 'view-page' && this.name !== 'create-page') {
				this.fail({
					attributes: {
						originalAbortReason: reason,
						...attributes,
					},
					error: new ExperienceTimeoutError(`${this.name} failed to complete in ${this.timeout}ms`),
				});
				if (process.env.NODE_ENV === 'development') {
					// eslint-disable-next-line no-console
					console.warn(
						`%cExperience Timeout: ${this.name} failed to complete in ${this.timeout}ms`,
						`color: #FF0000; font-size: 20px; font-weight: bold;`,
					);
					// eslint-disable-next-line no-console
					console.warn(
						`%cWhile timeout can happen due to genuine reasons, often this is an indication that experience is started and NOT stopped. If you see this error, please ensure that mentioned experience is stopped correctly.`,
						`color: #FF0000;`,
					);
				}

				return;
			}
		}

		this.onAbort && this.onAbort();

		this.stop({
			action: 'taskAbort',
			name: this.name,
			id: this.id,
			startTime: this.startTime,
			duration: this.getAbsoluteDuration(),
			activeDuration: this.getDurationAdjustedForActive(),
			adjustedDuration,
			reason: isTimeout
				? `ExperienceTimeout: ${this.name} failed to complete in ${this.timeout}ms`
				: reason,
			checkForTimeout,
			attributes: {
				originalAbortReason: isTimeout ? reason : undefined,
				...this.attributes,
				...attributes,
				...getUniquePageLoadId(),
			},
		});
	}

	/**
	 * Called on experience to stop it with the same action (success / failure / abort) and attributes as another event.
	 * For example, compound experience (e.g. edit-page) is failed if sub-experience (e.g. edit-page/publish) fails.
	 */
	stopOn(event?: ExperienceEvent, extraAttributes: ExperienceAttributes = {}) {
		if (!event) {
			return;
		}

		if (event.action === 'taskSuccess') {
			this.succeed({});
		} else if (event.action === 'taskAbort') {
			this.abort({
				reason: event.reason,
				attributes: {
					...extraAttributes,
					stoppedOn: event.name,
					stoppedOnPath: this.getStoppedOnPath(event),
				},
				checkForTimeout: event.checkForTimeout,
			});
		} else if (event.action === 'taskFail') {
			this.fail({
				error: event.error,
				attributes: {
					...extraAttributes,
					stoppedOn: event.name,
					stoppedOnPath: this.getStoppedOnPath(event),
				},
			});
		}
	}

	getState(): ExperienceState {
		return {
			timeout: this.timeout,
			hasStopped: this.hasStopped,
			stopState: this.stopState,
			startTime: this.startTime,
			name: this.name,
			attributes: this.attributes,
		};
	}

	get hasStopped() {
		return this.stopState !== null;
	}

	private stop(event: StopEvent) {
		this.stopState = event.action;
		if (this.onStop) {
			this.onStop(event);
		}
		getFreezeTracker().unsubscribe(this.name);
	}

	private getAbsoluteDuration() {
		return Math.round(window.performance.now() - this.startTime);
	}

	private getDurationAdjustedForTabActivity() {
		return (
			this.getAbsoluteDuration() -
			getTabInactivityTracker().getInactiveMillisecondsSince(this.startTime)
		);
	}

	private getDurationAdjustedForActive() {
		return this.getAbsoluteDuration() - this.freezeTime;
	}

	private getStoppedOnPath(event: ExperienceEvent) {
		if (!event.attributes?.stoppedOnPath) {
			return event.name;
		}
		return `${event.name},${event.attributes.stoppedOnPath}`;
	}
}
