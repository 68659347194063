import { combineReducers } from 'redux';
import type { SynchronyStateType } from '../support/synchrony-support/reducer';
import synchrony from '../support/synchrony-support/reducer';
import type { ViewState } from './views/index';
import views from './views/index';

export type RootState = {
	fabricEditor: {
		views: ViewState;
		synchrony: SynchronyStateType;
	};
	entities: any;
	router: any;
	views: {
		viewPage: {
			labels: any;
		};
	};
};

export default combineReducers({
	synchrony,
	views,
});
