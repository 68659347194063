import type { PropsWithChildren } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { Metric } from './SelectedMetricContext';

type HighlightContainerProps = PropsWithChildren<{
	metric: Metric | null;
}>;

export const HighlightContainer = ({ children, metric }: HighlightContainerProps) => {
	switch (metric) {
		case Metric.VIEWS:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
			const HighlightViews = styled.div({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr > td:nth-child(2)': {
					borderLeft: `2px solid ${token('color.border.selected')}`,
					borderRight: `2px solid ${token('color.border.selected')}`,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr:nth-last-child(-n + 1) > td:nth-child(2)': {
					borderBottom: `2px solid ${token('color.border.selected')}`,
				},
			});
			return <HighlightViews>{children}</HighlightViews>;
		case Metric.UNIQUE_VISITORS:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
			const HighlightUniqueVisitors = styled.div({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors, @atlaskit/ui-styling-standard/no-nested-selectors
				'table > tbody > tr > td:nth-child(3)': {
					borderLeft: `2px solid ${token('color.border.selected')}`,
					borderRight: `2px solid ${token('color.border.selected')}`,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr:nth-last-child(-n + 1) > td:nth-child(3)': {
					borderBottom: `2px solid ${token('color.border.selected')}`,
				},
			});
			return <HighlightUniqueVisitors>{children}</HighlightUniqueVisitors>;
		case Metric.CLICKS:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
			const HighlightClicks = styled.div({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr > td:nth-child(4)': {
					borderLeft: `2px solid ${token('color.border.selected')}`,
					borderRight: `2px solid ${token('color.border.selected')}`,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr:nth-last-child(-n + 1) > td:nth-child(4)': {
					borderBottom: `2px solid ${token('color.border.selected')}`,
				},
			});
			return <HighlightClicks>{children}</HighlightClicks>;
		case Metric.CTR:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
			const HighlightCTR = styled.div({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr > td:nth-child(5)': {
					borderLeft: `2px solid ${token('color.border.selected')}`,
					borderRight: `2px solid ${token('color.border.selected')}`,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
				'table > tbody > tr:nth-last-child(-n + 1) > td:nth-child(5)': {
					borderBottom: `2px solid ${token('color.border.selected')}`,
				},
			});
			return <HighlightCTR>{children}</HighlightCTR>;
		default:
			return <div>{children}</div>;
	}
};
