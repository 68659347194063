import type { IntlShape, MessageDescriptor } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import { numberIsFinite } from '../../common/common-utils';

import type { MainStatType } from './sub-components/MainStat';

const i18n = defineMessages({
	yesterday: {
		id: 'admin-center.main-stat.description.yesterday',
		defaultMessage: 'yesterday',
		description: 'The timeframe for the statistic was yesterday',
	},
	lastWeek: {
		id: 'admin-center.main-stat.description.last-week',
		defaultMessage: 'last week',
		description: 'The time frame for the statistic was last week',
	},
	lastMonth: {
		id: 'admin-center.main-stat.description.last-month',
		defaultMessage: 'last month',
		description: 'The time frame for the statistic was last month',
	},
});

export const getMainStatText = ({
	count,
	formatNumber,
}: {
	count: number | undefined;
	formatNumber: IntlShape['formatNumber'];
}) => (numberIsFinite(count) ? formatNumber(count) : undefined);

export const getMainStatTextFromPercent = ({
	count,
	formatNumber,
}: {
	count: number | undefined;
	formatNumber: IntlShape['formatNumber'];
}): string | undefined =>
	numberIsFinite(count)
		? formatNumber(count / 100, {
				style: 'percent',
			})
		: undefined;

// Function that checks whether mainStat property should be data or undefined
// Returns the value that gets evaluated by MainStat to display proper UI
export const getMainStatProps = ({
	count,
	formatNumber,
	description,
	descriptionValues,
}: {
	count: number | undefined;
	formatNumber: IntlShape['formatNumber'];
	description?: MessageDescriptor;
	descriptionValues?: { [key in string]: JSX.Element };
}): MainStatType | undefined => {
	if (
		typeof getMainStatText({
			count,
			formatNumber,
		}) === 'undefined'
	) {
		return undefined;
	}

	return {
		value: getMainStatText({
			count,
			formatNumber,
		}),
		description,
		descriptionValues,
	};
};
// Function that checks whether mainStat property should be data or undefined
// Returns the value that gets evaluated by MainStat to display proper UI
export const getMainStatPropsFromPercent = ({
	count,
	formatNumber,
	description,
	descriptionValues,
}: {
	count: number | undefined;
	formatNumber: IntlShape['formatNumber'];
	description?: MessageDescriptor;
	descriptionValues?: { [key in string]: JSX.Element };
}): MainStatType | undefined => {
	if (
		typeof getMainStatTextFromPercent({
			count,
			formatNumber,
		}) === 'undefined'
	) {
		return undefined;
	}

	return {
		value: getMainStatTextFromPercent({
			count,
			formatNumber,
		}),
		description,
		descriptionValues,
	};
};

export const getGranularityTextForMainStat = (granularity: string) => {
	if (granularity === 'DAY') {
		return i18n.yesterday;
	} else if (granularity === 'WEEK') {
		return i18n.lastWeek;
	} else {
		// The granularity should be MONTH.
		// However, if it is an unexpected value, then we default to MONTH.
		return i18n.lastMonth;
	}
};
