import { buildActionName } from '../eventBuilder';
import { equals, partition } from '../objectUtils';

import { type CompressionRule } from './compressionRule';
import EventCompressor from './eventCompressor';
import { type fireDelayedEventFn } from './types';

// We want to throttle the flush rate so that we don't have a huge upfront performance hit from starting the flush,
// and so that the underlying Segment client has some time to process some events in its queue before more are added.
const FLUSH_BATCH_SIZE = 7; // aligns with the default batch size of Segment's BatchableQueue
const FLUSH_BATCH_BACKOFF_PERIOD = 100;

export default class EventDelayQueue {
	private compressor: EventCompressor;

	private eventArgs: any[];

	private flushBatchTimeout: ReturnType<typeof setTimeout> | null;

	private processFn: fireDelayedEventFn;

	constructor(processFn: fireDelayedEventFn, compressionRules: CompressionRule[]) {
		this.processFn = processFn;
		this.flushBatchTimeout = null;
		this.eventArgs = [];
		this.compressor = new EventCompressor(compressionRules);
	}

	push = (identifier: any, builtEvent: any, context: any, userInfo: any) => {
		this.eventArgs.push({
			identifier,
			builtEvent,
			context,
			userInfo,
		});
	};

	size = () => this.eventArgs.length;

	startFlush = () => {
		try {
			this.eventArgs = this.compressEventArgs(this.eventArgs);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn(
				'Failed to perform compression on the delayed analytics events. ' +
					`Error: ${(e as Error).message}. Sending ${
						this.eventArgs.length
					} uncompressed events instead`,
			);
		}

		this.flushNextBatch();
	};

	cancelFlush = () => {
		if (this.flushBatchTimeout) {
			clearTimeout(this.flushBatchTimeout);
			this.flushBatchTimeout = null;
		}
	};

	private flushNextBatch = () => {
		const batch = this.eventArgs.splice(0, FLUSH_BATCH_SIZE);
		batch.forEach((item: any) =>
			this.processFn(item.identifier, item.builtEvent, item.context, item.userInfo),
		);
		if (this.eventArgs.length > 0) {
			this.flushBatchTimeout = setTimeout(() => this.flushNextBatch(), FLUSH_BATCH_BACKOFF_PERIOD);
		} else {
			this.flushBatchTimeout = null;
		}
	};

	private compressEventArgs = (eventArgs: any) => {
		const [compressibleEventArgs, incompressibleEventArgs] = partition(eventArgs, (args: any) =>
			this.compressor.canCompress(args.builtEvent),
		);

		// Events can only be compressed together if they share the same context and user info, since these are top-level
		// fields that need to exist on the fired event and can only be set to a single value.
		// We can achieve this by grouping our events by context prior to passing them to the compressor.
		const contextGroups = compressibleEventArgs.reduce((groups: any[], args: any) => {
			const matchingGroup = groups.find(
				(group) => equals(group.userInfo, args.userInfo) && equals(group.context, args.context),
			);

			if (matchingGroup) {
				matchingGroup.eventArgs.push(args);
			} else {
				groups.push({
					userInfo: args.userInfo,
					context: args.context,
					eventArgs: [args],
				});
			}
			return groups;
		}, []);

		// Run the compressor on each group
		const allCompressedEventArgs = contextGroups.reduce((acc: any, group: any) => {
			try {
				const events = group.eventArgs.map((args: any) => args.builtEvent);
				const compressedEvents = this.compressor.compress(events);
				const compressedEventArgs = compressedEvents.map((compressedEvent: any) => ({
					identifier: buildActionName(compressedEvent),
					builtEvent: compressedEvent,
					userInfo: group.userInfo,
					context: group.context,
				}));

				return acc.concat(compressedEventArgs);
			} catch (e) {
				// If anything goes wrong while compressing this group, then just fall back on the
				// uncompressed events instead. The event compressor already handles errors with invalid
				// generator functions or results, but this is an extra layer of defense to prevent data
				// loss in the event of an unexpected error.
				// eslint-disable-next-line no-console
				console.warn(
					'Failed to compress some analytics events. ' +
						`Error: ${(e as Error).message}. Sending ${
							group.eventArgs.length
						} uncompressed events instead`,
				);
				return group.eventArgs;
			}
		}, []);

		incompressibleEventArgs.forEach((args: any) => allCompressedEventArgs.push(args));
		return allCompressedEventArgs;
	};
}
