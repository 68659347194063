/* GeneratedContentHash: 3cb6cf61e4d1a4b8f604d8bf6e1ab4815270623ad61101f536107283c030de54 */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These imports are for client side use only
#
# Generate source: local/cli/src/commands/placements/generate-placement-files.ts
*/

import { asMessageRenderer } from '@post-office/placement-common';
import marketing from '@post-office/team-export--marketing/placements/in-app/screen-space-flags/render';
import postOfficeTemplate from '@post-office/team-export--post-office-template/placements/in-app/screen-space-flags/render';
import testMessages from '@post-office/team-export--test-messages/placements/in-app/screen-space-flags/render';

export const renderer = { ...marketing, ...postOfficeTemplate, ...testMessages };

//TODO: add validator as second arguement to asMessageRenderer when validators are ready
//import { validator } from './validate';

export const { initializeMessageRenderer, MessageRenderer } = asMessageRenderer(renderer);
