import { query, writeQuery } from '@confluence/query-preloader-tools';

import {
	InlineCommentsQuery,
	InlineCommentsWithSpaceKeyQuery,
} from './InlineCommentsQuery.graphql';
import type {
	InlineCommentsWithSpaceKeyQuery as InlineCommentsWithSpaceKeyQueryType,
	InlineCommentsWithSpaceKeyQueryVariables,
} from './__types__/InlineCommentsWithSpaceKeyQuery';
import type {
	InlineCommentsQuery as InlineCommentsQueryType,
	InlineCommentsQueryVariables,
} from './__types__/InlineCommentsQuery';

export type preloadInlineCommentsProps = {
	pageId?: string;
	spaceKey?: string;
};

export function preloadInlineComments({ pageId, spaceKey }: preloadInlineCommentsProps) {
	if (!pageId && spaceKey) {
		return query<InlineCommentsWithSpaceKeyQueryType, InlineCommentsWithSpaceKeyQueryVariables>({
			query: InlineCommentsWithSpaceKeyQuery,
			variables: { spaceKey },
			fetchPolicy: 'network-only',
		}).then(({ data }) => {
			if (data?.spaceHomepage?.id) {
				writeQuery<InlineCommentsQueryType, InlineCommentsQueryVariables>({
					query: InlineCommentsQuery,
					variables: {
						pageId: data?.spaceHomepage?.id,
					},
					data: {
						content: data?.spaceHomepage
							? {
									nodes: [data?.spaceHomepage],
									// @ts-ignore
									__typename: 'PaginatedContentListWithChild',
								}
							: null,
						comments: data?.space?.homepageComments || null,
					},
				});
			}
		});
	}
	return query({
		query: InlineCommentsQuery,
		variables: { pageId },
		fetchPolicy: 'network-only',
	});
}
