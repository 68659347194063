const getSSRDoneTime = () => {
	const ssrTTRMark = performance?.getEntriesByName('CFP-63.ssr-ttr');
	if (!ssrTTRMark || !ssrTTRMark[0]) {
		return undefined;
	}
	return ssrTTRMark[0].startTime;
};

const getSSRTimings = () => {
	if (!window.__SSR_MEASURES__) {
		return [];
	}
	const ssrTimings = Object.keys(window.__SSR_MEASURES__).map((key) => {
		return {
			label: key,
			data: window.__SSR_MEASURES__[key],
		};
	});
	return ssrTimings || [];
};

export { getSSRDoneTime, getSSRTimings };
