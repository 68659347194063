import { LoadableBackground, LoadableAfterPaint } from '@confluence/loadable';

export const SuperAdminBanner = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SuperAdminBanner" */ './SuperAdminBanner'))
			.SuperAdminBanner,
});

export const SuperAdminExpiration = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-super-admin-expirationSuperAdminExpiration" */ './super-admin-expiration/SuperAdminExpiration'
			)
		).SuperAdminExpiration,
});

export const SuperAdminEntryPoint = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SuperAdminEntryPoint" */ './SuperAdminEntryPoint'))
			.SuperAdminEntryPoint,
});

export const AdminKeyUpSell = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AdminKeyUpSell" */ './AdminKeyUpSell'))
			.AdminKeyUpSell,
});

export const SuperAdminSatisfactionSurvey = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SuperAdminSatisfactionSurvey" */ './SuperAdminSatisfactionSurvey'
			)
		).SuperAdminSatisfactionSurvey,
});

export const PremiumAdminEvent = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-analytics-eventsPremiumAdminEvent" */ './analytics-events/PremiumAdminEvent'
			)
		).PremiumAdminEvent,
});

export { AdminKeyEligibilityQuery } from './AdminKeyEligibilityQuery';
export { AdminKeyQuery, useIsSuperAdmin } from './AdminKeyQuery';
