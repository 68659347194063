import { parse } from 'url';

import window from 'window-or-global';

import { Cookies } from '@confluence/cookies';

const BM_UNIQUE_IDENTIFIER = 'bmUniqueIdentifier';

export function setBrowserMetricsUniqueIdentifier() {
	const urlComponents = parse(window.location.href, true);
	const identifier = urlComponents.query?.bmUniqueIdentifier;
	if (identifier === 'off') {
		Cookies.remove(BM_UNIQUE_IDENTIFIER);
	} else if (identifier) {
		Cookies.set(BM_UNIQUE_IDENTIFIER, identifier);
	}
}
