import React from 'react';

import { LoadableBackground } from '@confluence/loadable';
import { getTranslation, DEFAULT_LOCALE } from '@confluence/i18n';

export const IntlNextProvider = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-react-intl-next" */ 'react-intl-next'))
			.IntlProvider,
});

interface ErrorBoundaryProps {
	children?: React.ReactNode;
}

interface ErrorBoundaryState {
	missingIntlProviderInAncestry?: boolean;
}

const isMissingIntlProviderInAncestryError = (err: Error) =>
	err?.toString()?.includes('<IntlProvider> needs to exist in the component ancestry');

export class IntlNextErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	componentDidCatch(error: Error) {
		// if missing IntlProvider in ancestry, we setup a fallback IntlProvider ourselves
		if (process.env.CLOUD_ENV === 'branch' && isMissingIntlProviderInAncestryError(error)) {
			// eslint-disable-next-line no-console
			console.error('Had to load react-intl 5 in the error boundary');
			// eslint-disable-next-line no-console
			console.error('This is fine for branch deploys, but should probably not go to prod');
			this.setState({ missingIntlProviderInAncestry: true });
		} else {
			// else we re-propagate the non-react-intl-next error
			throw error;
		}
	}

	render() {
		if (this.state?.missingIntlProviderInAncestry) {
			const { locale, messages } = getTranslation();

			return (
				<IntlNextProvider
					key={locale}
					data-test-language={locale}
					locale={locale}
					defaultLocale={DEFAULT_LOCALE}
					messages={messages}
					textComponent="span"
				>
					{this.props.children}
				</IntlNextProvider>
			);
		}
		return this.props.children;
	}
}
