import { fg } from '@confluence/feature-gating';

/**
 * Returns if the current tenant has the ability to use Live Pages.
 * This takes into account multiple releases that contribute to the feature's availability.
 * @returns { boolean } Whether the Live Pages feature is enabled
 */
export const isLivePagesFeatureEnabled = (): boolean => {
	// This is allowed as this is the abstraction layer for other packages to use
	// fg('confluence_live_pages') checks if live pages are enabled
	// fg('confluence_live_pages_ab_test_opted_in') checks if live pages AB test opted in
	return fg('confluence_live_pages') || fg('confluence_live_pages_ab_test_opted_in');
};

export enum LivePageReleasePhase {
	NOT_ENABLED = 'not_enabled',
	CLOSED_BETA = 'closed_beta',
	AB_TEST_OPT_IN = 'ab_test_opt_in',
	UNKNOWN = 'unknown',
}

export const getLivePageReleasePhase = (): LivePageReleasePhase => {
	if (!isLivePagesFeatureEnabled()) {
		return LivePageReleasePhase.NOT_ENABLED;
	}

	if (fg('confluence_live_pages')) {
		return LivePageReleasePhase.CLOSED_BETA;
	}

	if (fg('confluence_live_pages_ab_test_opted_in')) {
		return LivePageReleasePhase.AB_TEST_OPT_IN;
	}

	return LivePageReleasePhase.UNKNOWN;
};
