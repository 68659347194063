import type { ConfigResult, ValuesPayload } from '@atlaskit/config-common-libs';
import { ConfigCollection } from '@atlaskit/config-common-libs';

declare global {
	interface Window {
		__STATSIGCONFIGS_JS__: StatsigConfigurations;
	}
}

class StatsigConfigurations {
	private static configCollection: ConfigCollection;

	static initializeFromValues(configCollectionValues: ValuesPayload) {
		this.configCollection = ConfigCollection.fromValues(configCollectionValues);
	}

	private static getValueOrDefault<T>(
		getFunction: (cname: string) => ConfigResult<T>,
		configName: string,
		defaultVal: T,
	): T {
		if (!this.configCollection) {
			return defaultVal;
		}

		const configResult = getFunction.call(this.configCollection, configName);
		if (!configResult || 'error' in configResult || !('value' in configResult)) {
			return defaultVal;
		}

		return configResult.value;
	}

	public static isKillSwitchOn(configName: string): boolean {
		return this.getValueOrDefault(this.configCollection?.getBoolean, configName, false);
	}

	public static getBoolean(configName: string, defaultVal: boolean): boolean {
		return this.getValueOrDefault(this.configCollection?.getBoolean, configName, defaultVal);
	}

	public static getString(configName: string, defaultVal: string): string {
		return this.getValueOrDefault(this.configCollection?.getString, configName, defaultVal);
	}

	public static getNumber(configName: string, defaultVal: number): number {
		return this.getValueOrDefault(this.configCollection?.getNumber, configName, defaultVal);
	}

	public static getStringList(configName: string, defaultVal: string[]): string[] {
		return this.getValueOrDefault(this.configCollection?.getStringList, configName, defaultVal);
	}

	public static getNumberList(configName: string, defaultVal: number[]): number[] {
		return this.getValueOrDefault(this.configCollection?.getNumberList, configName, defaultVal);
	}
}

/* eslint-disable import/no-default-export */
export default StatsigConfigurations;
