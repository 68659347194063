import type { Publisher, Subscriber, Unsubscribe } from './types';

export const createSimplePubSub = <T>(): [Subscriber<T>, Publisher<T>] => {
	const subscribers = new Map<Publisher<T>, Publisher<T>>();

	const subscribe = (subscriber: Publisher<T>): Unsubscribe => {
		subscribers.set(subscriber, subscriber);

		return () => {
			subscribers.delete(subscriber);
		};
	};

	const publish = (payload: T) => {
		subscribers.forEach((callback) => callback(payload));
	};

	return [subscribe, publish];
};
