import type { MediaProvider } from '@atlaskit/editor-common/provider-factory';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type { UploadRejectionData } from '@atlaskit/media-picker';

import { cacheWithExp } from '../cacheWithExp';
import {
	createAuthProvider,
	getAuthFromContext as getPageAuthFromContext,
} from '../MediaProvider/MediaProvider';
import type { AuthProvider, TokenData } from '../MediaProvider/MediaProvider';

import { getTemplateDownloadToken, getTemplateUploadToken } from './graphql-helpers';

type ConfigOptions = {
	spaceKey: string;
	templateIds: string[];
	collectionId: string | null;
};

type EditorConfigOptions = {
	spaceKey: string;
	templateId: string;
	collectionId: string | null;
	onUploadRejection?: (data: UploadRejectionData) => boolean;
};

type RendererConfigOptions = {
	spaceKey?: string;
	templateIds: string[];
};

// blueprints/templates in uuid form are technically a new template
const isBlueprint = (templateId: string) => !/^[0-9]+$/.test(templateId);
const isNewTemplate = (templateId) => !templateId || templateId === '';
const shouldUseTemplateId = (templateIds: string[]) => templateIds.length;
const formatTemplateId = (templateId: string, isFirstLoad: boolean, isUpload: boolean) => {
	if (isNewTemplate(templateId) || (isBlueprint(templateId) && isUpload) || !isFirstLoad) {
		return [];
	} else {
		return [templateId];
	}
};

const cachedGetTemplateUploadData = cacheWithExp<TokenData>(
	async (options: ConfigOptions) => {
		const response = await getTemplateUploadToken({
			spaceKey: options.spaceKey,
			templateIds: options.templateIds,
			collectionId: options.collectionId,
		});
		const session = response?.data?.templateMediaSession;
		const collectionId = session?.collections?.[0]?.value || '';

		return {
			token: session?.uploadToken?.value || '',
			config: session?.configuration || { fileStoreUrl: '', clientId: '' },
			collectionId,
		};
	},
	(json) => json.token,
);

const cachedGetTemplateDownloadData = cacheWithExp<TokenData>(
	async (options: ConfigOptions) => {
		const response = await getTemplateDownloadToken({
			spaceKey: options.spaceKey,
			templateIds: options.templateIds,
			collectionId: options.collectionId,
		});

		return {
			token: response?.data?.templateMediaSession?.downloadToken?.value || '',
			config: response?.data?.templateMediaSession?.configuration || {
				fileStoreUrl: '',
				clientId: '',
			},
		};
	},
	(json) => json.token,
);

export const getTemplateUploadCollectionId = async (editorOptions: EditorConfigOptions) => {
	const templateIds = formatTemplateId(editorOptions.templateId, !editorOptions.collectionId, true);
	const uploadData = await cachedGetTemplateUploadData.getValue({
		...editorOptions,
		templateIds,
		collectionId: shouldUseTemplateId(templateIds) ? null : editorOptions.collectionId,
	});

	return uploadData.collectionId;
};

export const getTemplateMediaContextValues = (
	contextId: string,
): {
	collectionId: string | null;
	templateId: string | null;
	spaceKey: string | null;
} => {
	let collectionId,
		templateId,
		spaceKey: null | string = null;
	try {
		({ collectionId, templateId, spaceKey } = JSON.parse(contextId));
	} catch (_) {}

	return {
		collectionId,
		templateId,
		spaceKey,
	};
};

export const getTemplateMediaAuth = async ({
	templateId,
	collectionId,
	spaceKey,
}): Promise<AuthProvider> => {
	// if given a templateId the source is the renderer, otherwise it's the editor
	const response = await getTemplateDownloadToken({
		templateIds: templateId ? [templateId] : [],
		collectionId: templateId ? null : collectionId,
		spaceKey,
	});

	const { clientId = '', fileStoreUrl = '' } =
		response?.data?.templateMediaSession?.configuration || {};

	return {
		clientId,
		token: response?.data?.templateMediaSession?.downloadToken?.value || '',
		baseUrl: fileStoreUrl,
	};
};

const getAuthFromContext = async (contextId: string): Promise<AuthProvider> => {
	const { collectionId, templateId, spaceKey } = getTemplateMediaContextValues(contextId);

	// templateID = null/undefined -> not template type
	// templateId = "" -> new template
	// templateId = "2394234324" -> user modified template
	// templateId = "23j4h-23jk4h-kj2h34-kj23h4" -> new template
	if (typeof collectionId !== 'string' && typeof templateId !== 'string') {
		// copy source is page contentType
		return getPageAuthFromContext(contextId);
	}

	return getTemplateMediaAuth({ collectionId, spaceKey, templateId });
};

export const getViewTemplateMediaClientConfig = (
	options: RendererConfigOptions,
): MediaClientConfig => {
	return {
		authProvider: createAuthProvider(async () => cachedGetTemplateDownloadData.getValue(options)),
		getAuthFromContext,
	};
};

export const createRendererTemplateMediaProvider = (
	options: RendererConfigOptions,
): Promise<MediaProvider> => {
	return Promise.resolve({
		viewMediaClientConfig: getViewTemplateMediaClientConfig(options),
	});
};

export const createTemplateEditorMediaProvider = async (
	options: EditorConfigOptions,
): Promise<MediaProvider> => {
	let isFirstDownload = true;
	let collectionId: string | null = options.collectionId;

	const getEditorViewTemplateMediaClientConfig = (
		editorOptions: EditorConfigOptions,
	): MediaClientConfig => {
		return {
			authProvider: createAuthProvider(async () => {
				const templateIds = formatTemplateId(editorOptions.templateId, isFirstDownload, false);
				isFirstDownload = false;
				return cachedGetTemplateDownloadData.getValue({
					...editorOptions,
					templateIds,
					collectionId: shouldUseTemplateId(templateIds) ? null : collectionId,
				});
			}),
			getAuthFromContext: (context) => {
				isFirstDownload = false;
				return getAuthFromContext(context);
			},
		};
	};

	const getTemplateMediaUploadMediaClientConfig = (
		editorOptions: EditorConfigOptions,
	): MediaClientConfig => {
		return {
			authProvider: createAuthProvider(() => {
				const templateIds = formatTemplateId(editorOptions.templateId, !collectionId, true);
				return cachedGetTemplateUploadData.getValue({
					...editorOptions,
					templateIds,
					collectionId: shouldUseTemplateId(templateIds) ? null : collectionId,
				});
			}),
			getAuthFromContext: (context) => {
				return getAuthFromContext(context);
			},
		};
	};

	const viewMediaClientConfig = getEditorViewTemplateMediaClientConfig(options);
	const uploadMediaClientConfig = getTemplateMediaUploadMediaClientConfig(options);
	const collection = await getTemplateUploadCollectionId(options);

	collectionId = collection || null;
	return {
		viewMediaClientConfig,
		uploadParams: {
			collection,
			onUploadRejection: options.onUploadRejection,
		},
		uploadMediaClientConfig,
	};
};
