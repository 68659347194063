import { styled } from '@compiled/react';

import { AVATAR_SIZES } from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';

import type { IconSize } from './types';
import { loadingGrey, loadingBorderRadius, iconStyles } from './TemplateIconConstants';

type DefaultIconProps = {
	backgroundColor?: string;
	size?: IconSize;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const DefaultIcon = styled.div<DefaultIconProps>(iconStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: `${(props) => AVATAR_SIZES[props.size || 'medium']}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: `${(props) => AVATAR_SIZES[props.size || 'medium']}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	paddingTop: `${token('space.050', '4px')} !important`,
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: loadingBorderRadius,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.backgroundColor ? props.backgroundColor : loadingGrey,
});
