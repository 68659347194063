import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { TopicHomepage } from '@confluence/topics/entry-points/TopicHomepage';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { HOME } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';

export const TopicsRoute = ({ params: { topicId } }: RouteMatch) => {
	const { isAdminHubAIEnabled } = useSessionData();

	if (!isAdminHubAIEnabled || !fg('confluence_enable_ai_topics_homepage')) {
		return <Redirection name={HOME.name} />;
	}
	return <TopicHomepage topicId={topicId} />;
};

TopicsRoute.NAVIGATION_PARAMS = ({ params: { topicId } }: RouteMatch) => ({
	Screen: {
		screenEvent: { name: 'topicsScreen' },
		pageState: {
			topicId,
		},
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
		topicId,
	},
});
