import React from 'react';

import { LoadablePaint } from '@confluence/loadable';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { useSessionData } from '@confluence/session-data';

const AdminCenterPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluenceadmin-center" */ '@confluence/admin-center'
			)
		).AdminCenterPage,
});

export const AdminCenterRoute = () => <AdminCenterPage />;

AdminCenterRoute.NAVIGATION_PARAMS = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { edition } = useSessionData();
	return {
		Screen: {
			screenEvent: { name: 'adminCenter', attributes: { edition } },
		},
		MainLayout: {
			navView: ADMIN_HOME_ID,
		},
	};
};
