import type { DraftActionTypes } from '../../actions/types/draft-types';
import { ActionTypes } from '../../actions/types/draft-types';
import type { ResetEditorStateActionType } from '../../actions/types/common-editor-action-types';
import { ActionTypes as CommonEditorActionTypes } from '../../actions/types/common-editor-action-types';

export type DraftStateType = {
	isSaving: boolean;
	isError: boolean;
	errorCode?: number | null;
};

export const defaultState: DraftStateType = {
	isSaving: false,
	isError: false,
	errorCode: null,
};

export default function Draft(
	state: DraftStateType = defaultState,
	action: DraftActionTypes | ResetEditorStateActionType,
): DraftStateType {
	switch (action.type) {
		case ActionTypes.EDITOR_DRAFT_SAVE_REQUEST:
			return {
				isSaving: true,
				isError: false,
				errorCode: null,
			};

		case ActionTypes.EDITOR_DRAFT_SAVE_FAILURE:
			return {
				isSaving: false,
				isError: true,
				errorCode: action.message && action.message.statusCode,
			};

		case ActionTypes.EDITOR_DRAFT_SAVE_SUCCESS:
			return {
				isSaving: false,
				isError: false,
				errorCode: null,
			};

		case ActionTypes.SET_SAVE_DRAFT:
			return {
				isSaving: action.isSaving === undefined ? false : action.isSaving,
				isError: false,
				errorCode: null,
			};

		case CommonEditorActionTypes.RESET_EDITOR_STATE:
			return defaultState;

		default:
			return state;
	}
}
