/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::86bc3b75b95cc3989eed6def3e13bbdd>>
 * @codegenId #spinner
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen spinner
 * @codegenParams { "props": { "appearance": true, "delay": true, "interactionName": false, "label": true, "size": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/spinner/src/types.tsx <<SignedSource::8174c980c33917a258c47472aca99c3d>>
 * @codegenDependency ../../../../../../../design-system/spinner/src/index.tsx <<SignedSource::3f3e357ea7e251d4254240a8f85f98f2>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformSpinner from '@atlaskit/spinner';

type PlatformSpinnerProps = React.ComponentProps<typeof PlatformSpinner>;

export type SpinnerProps = Pick<
  PlatformSpinnerProps,
  'appearance' | 'delay' | 'label' | 'size' | 'testId'
>;

export const Spinner = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    delay,
    label,
    size,
    testId,
  } = props.forgeDoc.props as SpinnerProps;
  return (
    <PlatformSpinner
      appearance={appearance}
      delay={delay}
      label={label}
      size={size}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */