import { query } from '@confluence/query-preloader-tools';

import { InlineHighlightsQuery } from './graphql/InlineHighlightsQuery.graphql';

export function preloadInlineHighlights(pageId: string) {
	return query({
		query: InlineHighlightsQuery,
		variables: {
			pageId,
		},
		fetchPolicy: 'network-only',
	});
}
