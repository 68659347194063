import createBrowserHistory from 'history/createBrowserHistory';
import memoizeOne from 'memoize-one';
import type { History, LocationListener } from 'history';

export type setBrowserHistoryListenerType = (
	newBrowserHistoryListener: LocationListener<undefined>,
) => () => void;

let browserHistoryListener: LocationListener<undefined> | null = null;

/**
 * Function that allows setting the browser history listener. The purpose is to prioritise Confluence to set its listener first,
 * before external components can set their listener.
 *
 * Specifically, we have logic to handle routing in <RouteManager> that needs to trigger before the listener defined by <ConnectedRouter>;
 * given that <RouteManager> is mounted after <ConnectedRouter>, we need to register the listener and then allow <RouteManager> to set the actual callback function once it mounts.
 *
 * The implementation only allows for a single listener to be registered, but this could be extended to allow for multiple listeners.
 */
export const setBrowserHistoryListener: setBrowserHistoryListenerType = (
	newBrowserHistoryListener,
) => {
	browserHistoryListener = newBrowserHistoryListener;

	return () => {
		browserHistoryListener = null;
	};
};

export const getBrowserHistory: () => History<undefined> = memoizeOne(() => {
	const browserHistory: History<undefined> = createBrowserHistory<undefined>();

	browserHistory.listen((location, action) => {
		browserHistoryListener?.(location, action);
	});

	return browserHistory;
});
