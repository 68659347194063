/**
 * Auto Incrementing ID
 *
 * Basic ID generation using a global integer
 */
export class AutoIncrementingID {
	private incrementor = -1;

	/**
	 * ID
	 *
	 * Returns a stringified auto-incrementing ID
	 */
	public ID() {
		return (++this.incrementor).toString();
	}

	/**
	 * Reset
	 *
	 * Returns the incrementor to its original state
	 */
	public reset() {
		this.incrementor = -1;
	}
}
