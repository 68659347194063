export const LEGACY_COOKIE_CACHE_URL_PROD =
	'https://atlassian-cookies--categories.us-east-1.prod.public.atl-paas.net/categories.json';

export const COOKIE_CACHE_URL_PROD =
	'https://atlassian-cookies--categories.us-east-1.prod.public.atl-paas.net/categories_COOKIE.json';

export const LOCAL_STORAGE_CACHE_URL_PROD =
	'https://atlassian-cookies--categories.us-east-1.prod.public.atl-paas.net/categories_LOCAL_STORAGE.json';

export const SESSION_STORAGE_CACHE_URL_PROD =
	'https://atlassian-cookies--categories.us-east-1.prod.public.atl-paas.net/categories_SESSION_STORAGE.json';
