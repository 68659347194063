import type { FieldTransformer } from '../types';

// This transformer runs for all macros
export const cql: FieldTransformer<{ [key: string]: any }> = {
	transformBefore(params, metadata) {
		Object.entries(metadata).forEach(([key, field]) => {
			const value = params[key];
			if (field.type.name === 'cql' && typeof value === 'string' && value.length) {
				// Required to migrate old macros inserted via Macro Browser since
				// new config doesn't support . [dot] in field name. For more context @see ED-12857
				params[key] = value.replace(/(contributor|creator|mention)\.accountid = /g, '$1 = ');
			}
		});
		return params;
	},
};
