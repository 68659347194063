/**
 * Cache
 *
 * A generic cache designed to house just about anything. You can implement
 * your own type-safe LRU or just use it as a hash table
 */
export class Cache<T extends Record<string, any>> {
	private _cache: T;
	constructor(cache?: T) {
		this._cache = (cache || {}) as T;
	}

	/**
	 * Data
	 *
	 * Returns the entirety of the cache
	 */
	public get data() {
		return this._cache;
	}

	/**
	 * Get
	 *
	 * Returns a cache value by key
	 */
	get<K extends keyof T>(key: K): T[K] {
		return this._cache[key];
	}

	/**
	 * Has
	 *
	 * Returns whether or not a given key exists in the cache
	 */
	has<K extends keyof T>(key: K): boolean {
		return this._cache.hasOwnProperty(key);
	}

	/**
	 * Set
	 *
	 * Overrides the value of a given key with the specified value
	 */
	set<K extends keyof T>(key: K, value: T[K]) {
		this._cache[key] = value;
	}

	/**
	 * Delete
	 *
	 * Deletes the specified cache key
	 */
	delete(key: keyof T) {
		delete this._cache[key];
	}

	/**
	 * Keys
	 *
	 * Returns all currently set cache keys
	 */
	get keys() {
		return Object.keys(this._cache) as (keyof T)[];
	}

	/**
	 * Values
	 *
	 * Returns all cached values
	 */
	public get values() {
		return Object.values(this._cache) as T[keyof T][];
	}

	/**
	 * Clear
	 *
	 * Clears all cached values
	 */
	public clear() {
		this._cache = {} as T;
	}
}
