import React from 'react';
import { ParentSize } from '@visx/responsive';
import { buildChartTheme, DataProvider, XYChart } from '@visx/xychart';

import { token } from '@atlaskit/tokens';
import { N20A } from '@atlaskit/theme/colors';

import type { GranularityType } from '../../common/MetricSettingsContext';

import { useChartBaseLineGroupData } from './useChartBaseLineGroupData';
import { useCustomGridData } from './useCustomGridData';
import { CustomLineGroup } from './sub-components/CustomLineGroup';
import { CustomGrid } from './sub-components/CustomGrid';
import { CustomXAxis, CustomYAxis } from './sub-components/CustomAxes';
import { CustomLegend } from './sub-components/CustomLegend';
import { CustomTooltip } from './sub-components/CustomTooltip';
import type { CustomTooltipProps } from './sub-components/CustomTooltip';
import type { LineDefinition } from './ChartBase-types';
import { chartColorPalette } from './chartColorPalette';

const getChartTheme = ({ lineCount }: { lineCount: number }) =>
	buildChartTheme({
		backgroundColor: token('color.background.neutral', N20A),
		colors: chartColorPalette
			// The colors are sliced and reversed here so that they are applied correctly
			// even though we render the lines in reverse order.
			.slice(0, lineCount)
			.reverse(),
		gridColor: token('color.text.subtlest', '#626F86'),
		gridColorDark: token('color.text.subtlest', '#626F86'),
		tickLength: 8,
	});

const INNER_CHART_HEIGHT = 154;
const CHART_TOP_MARGIN = 8;
const CHART_BOTTOM_MARGIN_OFFSET = 5; // The chart adds 5px of margin to the bottom of the chart. This is a workaround to remove it.
const CHART_BOTTOM_MARGIN = 30 - CHART_BOTTOM_MARGIN_OFFSET;
const CHART_RIGHT_MARGIN = 24;
const CHART_LEFT_MARGIN = 45;

export type ChartBaseProps = CustomTooltipProps & {
	title: string;
	lines: LineDefinition[];
	isAreaChart?: boolean;
	granularity: GranularityType;
	shouldRightAlignLegend?: boolean;
	isPercentageChart?: boolean;
};

export const ChartBase = ({
	title,
	lines,
	isAreaChart,
	granularity,
	shouldRightAlignLegend = false,
	tooltipOverride,
	isPercentageChart,
}: ChartBaseProps) => {
	const lineGroupData = useChartBaseLineGroupData({
		lines,
		isAreaChart: !!isAreaChart,
	});
	const { xScaleDomain, yScaleDomain, columnTickValues, rowTickValues } = useCustomGridData({
		lines: lineGroupData,
		granularity,
		isPercentageChart,
	});

	// Annoyingly, the linter replaces CHART_RIGHT_MARGIN with a tokenized value if it is set directly on XYChart, which causes
	// a bug because the tokenized value is not a number. The linter does not replace it if it is set here instead.
	const chartMargin = {
		top: CHART_LEFT_MARGIN,
		right: CHART_RIGHT_MARGIN,
		bottom: CHART_BOTTOM_MARGIN,
		left: CHART_LEFT_MARGIN,
	};

	return (
		<DataProvider
			xScale={{
				type: 'time',
				padding: 0,
				align: 0,
				domain: xScaleDomain,
			}}
			yScale={{ type: 'linear', domain: yScaleDomain }}
			theme={getChartTheme({ lineCount: lines.length })}
		>
			<ParentSize>
				{(parent) => (
					<XYChart
						accessibilityLabel={title}
						// Falls back to 400x300 if parent.width is undefined. This should only happen
						// if window.ResizeObserver is not supported, which is the case for unit tests.
						width={parent.width || 400}
						// The height of the chart includes the margin.
						height={INNER_CHART_HEIGHT + CHART_TOP_MARGIN + CHART_BOTTOM_MARGIN}
						margin={chartMargin}
					>
						<CustomGrid rowTickValues={rowTickValues} columnTickValues={columnTickValues} />
						<CustomXAxis tickValues={columnTickValues} granularity={granularity} />
						<CustomYAxis tickValues={rowTickValues} />
						<CustomLineGroup lines={lineGroupData} isAreaChart={!!isAreaChart} />
						<CustomTooltip tooltipOverride={tooltipOverride} />
					</XYChart>
				)}
			</ParentSize>
			{lines.length > 1 && <CustomLegend shouldRightAlign={shouldRightAlignLegend} />}
		</DataProvider>
	);
};
