import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { AdvancedSearch } from '@confluence/advanced-search';
import { fg } from '@confluence/feature-gating';

export const AdvancedSearchRoute = () => <AdvancedSearch />;

AdvancedSearchRoute.NAVIGATION_PARAMS = () => {
	return {
		Screen: fg('unified_search_in_confluence')
			? null
			: { screenEvent: { name: 'advancedSearchScreen' } },
		MainLayout: { navView: PRODUCT_HOME_ID },
	};
};
