import { restrictedTimingKeys } from '../constants';
import { logger } from '../logger';
import { visibilityChangeObserver } from '../observer/visibility-change-observer';
import type {
	BMEventsType,
	CustomValues,
	PerformanceEventConfigParam,
	UntilExperienceConfig,
} from '../types';

import { UntilExperience } from './until-experience';

export enum MetricState {
	NOT_STARTED = 'not-started',
	STARTED = 'started',
	FINISHED = 'finished',
	CANCELLED = 'cancelled',
}

export enum PageVisibleState {
	VISIBLE = 'visible',
	MIXED = 'mixed',
	HIDDEN = 'hidden',
}

export type OnStopCallback = (data?: BaseMetricStopArguments | null) => void;
export type OnCancelCallback = () => void;

function getPageVisibleState() {
	return typeof document !== 'undefined' && document.visibilityState === 'visible'
		? PageVisibleState.VISIBLE
		: PageVisibleState.HIDDEN;
}
export interface BaseMetricStopArguments {
	customData?: CustomValues | null;
	stopTime?: number;
}

export interface BaseMetricStartArguments {
	startTime?: number;
}

export interface BaseMetricData {
	key: string;
	state: MetricState;
	start: number | null;
	stop: number | null;
	marks: { [key: string]: number };
	custom: CustomValues | null;
	route: string | null;
	submetrics: Array<BaseMetricData>;
	config: PerformanceEventConfigParam;
	pageVisibleState: PageVisibleState;
	type: BMEventsType;
}

export interface BaseMetricMergeData extends BaseMetricData {
	onStopCallbacks: Array<[OnStopCallback, OnCancelCallback]>;
}

export class BaseMetric {
	protected state: MetricState = MetricState.NOT_STARTED;
	protected startTime: number | null = null;
	protected stopTime: number | null = null;
	protected marks: { [key: string]: number } = {};
	protected onStopCallbacks: Array<[OnStopCallback, OnCancelCallback]> = [];
	protected custom: CustomValues | null = null;
	protected config: PerformanceEventConfigParam;
	protected submetrics: BaseMetricData[] = [];
	protected route: string | null = null;
	protected untilOnStopCallback: OnStopCallback | null = null;
	protected pageVisibleState: PageVisibleState = getPageVisibleState();
	readonly key: string;
	readonly type: BMEventsType;
	static FMP = 'fmp';

	constructor(config: PerformanceEventConfigParam) {
		this.config = config;
		this.key = config.key.replace(/ /gi, '-');
		this.type = config.type;
		this.config.timings?.forEach((entry) => {
			if (restrictedTimingKeys.includes(entry.key)) {
				// eslint-disable-next-line no-console
				console.warn(
					`Entry ${entry.key} is restricted and has been renamed to custom__${entry.key}`,
				);
				entry.key = `custom__${entry.key}`;
			}
		});
	}
	protected get untilMetrics(): Array<UntilExperience> {
		const { until } = this.config;
		if (!until) {
			return [];
		} else if (Array.isArray(until)) {
			return this.getConfiguredUntilMetricArray(until);
		}
		return this.getConfiguredUntilMetricArray([until]);
	}

	start({ startTime = window.performance.now() }: BaseMetricStartArguments = {}) {
		this.clear();
		logger.logCond(!!this.config.debug, this.key, startTime);
		this.state = MetricState.STARTED;
		this.startTime = startTime;
		this.pageVisibleState = getPageVisibleState();
		visibilityChangeObserver.subscribe(this.setPageVisibleStateToMixed);
		this.watchUntil();
	}

	stop(stopArguments: BaseMetricStopArguments = {}): boolean {
		const { stopTime = window.performance.now() } = stopArguments;

		if (this.state !== MetricState.STARTED || this.startTime === null) {
			logger.log(
				`metric ${this.config.key} has been stopped while not being started before; current state: ${this.state}`,
			);
			return false;
		}

		if (stopTime < this.startTime) {
			logger.log(
				`metric ${this.config.key} has been stopped with stopTime lower than startTime; startTime: ${this.startTime}; stopTime: ${stopTime}`,
			);
			return false;
		}

		this.stopTime = stopTime;
		this.state = MetricState.FINISHED;

		this.handleStop(stopArguments);
		visibilityChangeObserver.unsubscribe(this.setPageVisibleStateToMixed);
		return true;
	}

	protected handleStop(stopArguments: BaseMetricStopArguments) {
		const { customData = null } = stopArguments;

		if (customData) {
			this.custom = { ...this.custom, ...customData };
		}

		this.config.include &&
			this.config.include.forEach((metric) => {
				const included = metric.getData();
				if (
					included.state === MetricState.FINISHED &&
					included.start !== null &&
					included.start >= (this.startTime || 0)
				) {
					this.addSubMetric(metric.getData());
				}
			});

		this.onStopCallbacks.forEach(([success]) => {
			success(stopArguments);
		});

		this.onStopCallbacks = [];
		logger.logCond(!!this.config.debug, this.key, this.config.debug && this.getData());
	}

	protected setPageVisibleStateToMixed = () => {
		this.pageVisibleState = PageVisibleState.MIXED;
	};

	mark(mark: string, timestamp: number = window.performance.now()) {
		this.marks[mark] = timestamp;
	}

	cancel() {
		if (this.state !== MetricState.STARTED) {
			return;
		}
		this.state = MetricState.CANCELLED;
		this.cancelUntil();

		this.onStopCallbacks.forEach(([, cancel]) => {
			cancel();
		});
		this.onStopCallbacks = [];
		visibilityChangeObserver.unsubscribe(this.setPageVisibleStateToMixed);
	}

	merge(mergeData: BaseMetricMergeData) {
		this.state = mergeData.state;
		this.pageVisibleState = mergeData.pageVisibleState;
		this.startTime = mergeData.start;
		this.stopTime = mergeData.stop;
		this.marks = { ...this.marks, ...mergeData.marks };
		this.submetrics = [...this.submetrics, ...mergeData.submetrics];
		this.onStopCallbacks = [...this.onStopCallbacks, ...mergeData.onStopCallbacks];
	}

	onStop(success: OnStopCallback, cancel: OnStopCallback) {
		this.onStopCallbacks.push([success, cancel]);
	}

	removeOnStopCallback(success: OnStopCallback) {
		const index = this.onStopCallbacks.findIndex(([successCallback]) => {
			return success === successCallback;
		});
		if (index > -1) {
			this.onStopCallbacks.splice(index, 1);
		}
	}

	getData(): BaseMetricData {
		return {
			key: this.key,
			state: this.state,
			start: this.startTime,
			stop: this.stopTime,
			//todo ranges
			// ranges: this.makeRanges()
			marks: this.marks,
			// todo make copy
			custom: this.custom,
			submetrics: this.submetrics,
			config: this.config,
			route: this.route,
			pageVisibleState: this.pageVisibleState,
			type: this.config.type,
		};
	}

	getDataToMerge(): BaseMetricMergeData {
		return {
			...this.getData(),
			onStopCallbacks: this.onStopCallbacks,
		};
	}

	protected addSubMetric(data: BaseMetricData) {
		this.submetrics.push(data);
	}

	/**
	 *
	 * @param metric
	 * The array of Until metrics can be of two types
	 * This function returns an UntilExperience
	 */
	protected getConfiguredUntilMetric(
		metricOrConfig: BaseMetric | UntilExperienceConfig,
	): UntilExperience {
		return metricOrConfig instanceof BaseMetric
			? UntilExperience.fromMetric(metricOrConfig)
			: UntilExperience.fromConfig(metricOrConfig);
	}

	protected getConfiguredUntilMetricArray(
		metricArray: Array<BaseMetric | UntilExperienceConfig>,
	): UntilExperience[] {
		if (!metricArray.length) {
			return [];
		}
		return metricArray.map((metric) => this.getConfiguredUntilMetric(metric));
	}

	protected isUntilFinished() {
		return this.untilMetrics.every(
			(metric) => metric.experience.getData().state === MetricState.FINISHED,
		);
	}

	protected handleAllUntilFinished() {
		// Order by ascending stop time
		const sorted = [...this.untilMetrics].sort((m1, m2) => {
			return m1.getDependencyStopTime()! - m2.getDependencyStopTime()!;
		});

		// Find the latest page segment to become fully interactive
		const latestFullyInteractiveSegment = [...this.untilMetrics].reduce(
			(currentLatestSegment: UntilExperience | undefined, metric) => {
				const currentLatestStopTime = currentLatestSegment?.getFullyInteractiveStopTime() ?? 0;
				const metricStopTime = metric.getFullyInteractiveStopTime() ?? 0;

				return metricStopTime > currentLatestStopTime ? metric : currentLatestSegment;
			},
			undefined,
		);

		// Accumulate the custom data from each metric, later metrics take priority
		const accumulatedCustomData = {};
		sorted.forEach((metric) => {
			Object.assign(accumulatedCustomData, metric.experience.getData().custom);
		});

		const latestSegment = sorted[sorted.length - 1];
		const latestFullyInteractiveStopTime =
			latestFullyInteractiveSegment?.getFullyInteractiveStopTime();
		this.stop({
			stopTime: latestSegment.getDependencyStopTime()!,
			customData: {
				...accumulatedCustomData,
				segment: latestSegment.getKey(),
				segmentOverrided: latestSegment.getIsSegmentLoadOverrided(),
				...(latestFullyInteractiveStopTime
					? {
							latestFullyInteractiveSegment: latestFullyInteractiveSegment?.getKey(),
							latestFullyInteractiveStopTime,
						}
					: {}),
			},
		});
	}

	protected watchUntil() {
		if (!this.untilMetrics.length) {
			return;
		}

		if (this.isUntilFinished()) {
			// Already finished, handle it immediately
			this.handleAllUntilFinished();
			return;
		}

		// If not currently finished, register stop callbacks to re-check
		this.untilOnStopCallback = () => {
			if (this.isUntilFinished()) {
				this.handleAllUntilFinished();
			}
		};
		this.untilMetrics.forEach((metric) => {
			if (metric.experience.getData().state !== MetricState.FINISHED) {
				metric.experience.onStop(this.untilOnStopCallback!, () => this.cancel());
			}
		});
	}

	protected cancelUntil() {
		this.untilMetrics.forEach((metric) => {
			if (metric.experience.getData().state !== MetricState.CANCELLED && this.untilOnStopCallback) {
				metric.experience.removeOnStopCallback(this.untilOnStopCallback);
			}
		});
	}

	protected clear() {
		// todo before clearing - handle cancelling existing onStop / unsubscribe submetrics
		this.cancelUntil();
		this.state = MetricState.NOT_STARTED;
		this.startTime = null;
		this.stopTime = null;
		this.marks = {};
		this.custom = null;
		this.submetrics = [];
		this.route = null;
		this.pageVisibleState = getPageVisibleState();
	}
}
