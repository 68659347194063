import { PERSISTED_KEYS_ON_SERVER } from './LocalStorageKeys';
import { LOCAL_STORAGE_MANAGER, STORAGE_MANAGER_PREFIX, STORAGE_MANAGER_ID } from './storageKeys';

const serverCacheKeyRegex = /[^A-Za-z0-9\-._\/]/g;

export const getKeysForLocalStorageQuery = (userId: string): string[] => {
	const keys: string[] = [];
	let prefix = `${LOCAL_STORAGE_MANAGER}/${STORAGE_MANAGER_PREFIX}.${userId}.${STORAGE_MANAGER_ID}`;
	prefix = prefix.replace(serverCacheKeyRegex, '_');

	(Object.keys(PERSISTED_KEYS_ON_SERVER) as Array<keyof typeof PERSISTED_KEYS_ON_SERVER>).forEach(
		(key) => keys.push(`${prefix}.${PERSISTED_KEYS_ON_SERVER[key]}`),
	);

	return keys;
};

export const getStorageKey = (key: string, cacheKey: string) => {
	if (Object.values(PERSISTED_KEYS_ON_SERVER).indexOf(key) > -1) {
		cacheKey = cacheKey.replace(serverCacheKeyRegex, '_');
	}
	return cacheKey;
};
