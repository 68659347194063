/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ClientNetworkStatus {
  IP_BLOCKED = "IP_BLOCKED",
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  RATE_LIMITED = "RATE_LIMITED",
  SESSION_EXPIRED = "SESSION_EXPIRED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NetworkStatusListenerQuery
// ====================================================

export interface NetworkStatusListenerQuery_network {
  status: ClientNetworkStatus | null;
}

export interface NetworkStatusListenerQuery {
  network: NetworkStatusListenerQuery_network | null;
}
