import { Record } from 'immutable';

import { NoNetworkError } from '@confluence/network';

import { INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE_ERROR } from '../../../constants/status-codes';

export const ErrorRecord = Record({
	error: false,
	type: undefined,
	title: undefined,
	body: undefined,
	bodyKey: undefined,
	close: undefined,
	isNetworkConnected: true,
});

export default function errors(state = new ErrorRecord(), action) {
	if (action.error && /_FAILURE$/.test(action.type)) {
		if (action.error instanceof TypeError || action.error instanceof NoNetworkError) {
			if (!state.isNetworkConnected) {
				// local network is disconnected, but fetch attempted
				return new ErrorRecord({
					error: true,
					type: 'error',
					title: 'error.connection.lost.title',
					bodyKey: 'error.connection.lost.body',
					close: 'manual',
					isNetworkConnected: false,
				});
			}
		} else if (
			action.error.statusCode === INTERNAL_SERVER_ERROR ||
			action.error.statusCode === SERVICE_UNAVAILABLE_ERROR
		) {
			return new ErrorRecord({
				error: true,
				type: 'error',
				title: 'error.server-error.title',
				bodyKey: 'error.server-error.body',
				close: 'auto',
			});
		}
	}

	switch (action.type) {
		case 'CONNECTION_OFFLINE':
			return new ErrorRecord({
				error: false,
				isNetworkConnected: false,
			});
		case 'CONNECTION_ONLINE':
			if (state.error) {
				// 'connection lost' message displayed, connection recovered
				return new ErrorRecord({
					error: true,
					type: 'success',
					title: 'error.connection.recovered.title',
					body: '<button onclick="window.location.reload()" class="aui-button aui-button-link btn-reload-content">i18nPlaceholder</button>',
					bodyKey: 'error.connection.recovered.action',
					close: 'manual',
					isNetworkConnected: true,
				});
			}
			return new ErrorRecord({ isNetworkConnected: true });
		case 'REMOVE_ERROR':
			return new ErrorRecord({
				error: false,
			}).set('isNetworkConnected', state.isNetworkConnected);
	}
	return state;
}
