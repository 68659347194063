/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnalyticsTimeseriesGranularity {
  DAY = "DAY",
  HOUR = "HOUR",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsDialogGraphQuery
// ====================================================

export interface AnalyticsDialogGraphQuery_TimeseriesCount_nodes {
  date: string;
  count: number;
}

export interface AnalyticsDialogGraphQuery_TimeseriesCount {
  nodes: AnalyticsDialogGraphQuery_TimeseriesCount_nodes[];
}

export interface AnalyticsDialogGraphQuery_UserCount {
  count: number;
}

export interface AnalyticsDialogGraphQuery_AllCount {
  count: number;
}

export interface AnalyticsDialogGraphQuery {
  TimeseriesCount: AnalyticsDialogGraphQuery_TimeseriesCount | null;
  UserCount: AnalyticsDialogGraphQuery_UserCount | null;
  AllCount: AnalyticsDialogGraphQuery_AllCount | null;
}

export interface AnalyticsDialogGraphQueryVariables {
  contentId: string;
  graphStartTime: string;
  graphGranularity: AnalyticsTimeseriesGranularity;
  countStartTime: string;
  timezone: string;
}
