import gql from 'graphql-tag';

export const CreateSpaceQuery = gql`
	query CreateSpaceQuery {
		user(current: true) {
			id
			confluence {
				operations {
					operation
				}
			}
		}
	}
`;
