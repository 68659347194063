import type {
  ProgressTrackerProps as PlatformProgressTrackerProps,
  Stage as PlatformStage,
} from '@atlaskit/progress-tracker';

type Stage = Pick<
  PlatformStage,
  'id' | 'label' | 'percentageComplete' | 'status' | 'onClick'
>;

export type ProgressTrackerProps = Pick<
  PlatformProgressTrackerProps,
  'label' | 'testId'
> &
  Partial<Pick<PlatformProgressTrackerProps, 'animated' | 'spacing'>> & {
    items: Array<Stage>;
  };

// Filters out href from items. @forge/bridge router must be used in onClick for in app navigation
const filterItems = (items: Array<Stage>) => {
  return (items as Array<PlatformStage>).map((item) => {
    // removes href and noLink props
    const { href, noLink, ...rest } = item;
    return {
      ...rest,
      noLink: !rest.onClick,
    };
  });
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::1156785b7b6e557e392c4ba430156125>>
 * @codegenId #progress-tracker
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen progress-tracker
 * @codegenParams { "defineOwnType": true, "props": { "animated":true, "items": {"value": "filterItems(items)"}, "label":true, "spacing":true, "testId":true } }
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/progress-tracker.tsx <<SignedSource::219114a0e3613cbcbd268db4791a477f>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/constants.tsx <<SignedSource::fa6b41f520d818db82141cdc08ee3adb>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/internal/constants.tsx <<SignedSource::a34078edd4601938936172e6f2317203>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/internal/link.tsx <<SignedSource::a047722470f68809457151bece316b9e>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/internal/stage.tsx <<SignedSource::e4804d58946be6abb058d2dd26788f36>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/types.tsx <<SignedSource::aa38f4d76d8182565167c3cb62212286>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ProgressTracker as PlatformProgressTracker } from '@atlaskit/progress-tracker';

// Define the type for ProgressTracker as ProgressTrackerProps at the top of this file

export const ProgressTracker = (props: Parameters<RenderFn>[0]) => {
  const {
    animated,
    items,
    label,
    spacing,
    testId,
  } = props.forgeDoc.props as ProgressTrackerProps;
  return (
    <PlatformProgressTracker
      animated={animated}
      items={filterItems(items)}
      label={label}
      spacing={spacing}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
