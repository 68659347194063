import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { DatabaseContainerLoader } from '@confluence/confluence-databases';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import { LazyDwellTime } from '../lazyComponents';

export const DatabaseViewRoute = ({ params: { contentId, spaceKey } }: RouteMatch) => (
	<>
		<DatabaseContainerLoader contentId={contentId} spaceKey={spaceKey} />
		<LazyDwellTime />
	</>
);

DatabaseViewRoute.NAVIGATION_PARAMS = ({
	name: routeName,
	params: { contentId, spaceKey },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'databaseScreen',
			id: contentId,
			attributes: {
				contentId,
			},
		},
		pageState: {
			contentId,
			spaceKey,
			contentType: 'database',
			routeName,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		isViewPage: true,
	},
});
