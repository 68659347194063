import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

export type FeatureFlags = { [flag: string]: any };

export function extendPayloadAttributes(
	payload: AnalyticsEventPayload,
	newAttributes: Record<string, any>,
) {
	return {
		...payload,
		attributes: {
			...payload.attributes,
			...newAttributes,
		},
	};
}

export const getProseMirrorFMPTimestamp = (startTime, duration) => {
	return Number.isFinite(startTime) && Number.isFinite(duration)
		? Math.round(startTime + duration)
		: undefined;
};
