import { CookieLevel, Cookies } from '@confluence/cookies';

const SUPER_ADMIN_COOKIE = 'confluence.asSuperAdmin';

/**
 * Determines if a frontend claim for Super Admin exists. This is NOT the
 * ultimate source of truth for Super Admin state.
 * @returns {boolean}
 */
export function getSuperAdminClaim(): boolean {
	return Boolean(Cookies.get(SUPER_ADMIN_COOKIE));
}

/**
 * Removes a previously established frontend claim for Super Admin. This action
 * should be executed after a successful mutation to disable Super Admin in the
 * server.
 */
export function removeSuperAdminClaim() {
	Cookies.remove(SUPER_ADMIN_COOKIE);
}

/**
 * Establishes a frontend claim for Super Admin. This action should be executed
 * after a successful mutation to enable Super Admin in the server.
 */
export async function setSuperAdminClaim() {
	await Cookies.set(SUPER_ADMIN_COOKIE, 'true', undefined, CookieLevel.NECESSARY);
}
