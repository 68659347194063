import {
	type AnalyticsWebClientType,
	type OperationalEventPayload,
	type ScreenEventPayload,
	type UIEventPayload,
} from '../../common/types/analytics';

export type GasV3Attributes = {
	messageId: string;
	variationId: string;
	componentIndex: number;
	locale: string;
};

export function sendScreenEvent(
	clientOrPromise: AnalyticsWebClientType | Promise<AnalyticsWebClientType>,
	payload: ScreenEventPayload,
) {
	Promise.resolve(clientOrPromise).then((client) => {
		client.sendScreenEvent(payload);
	});
}

export function sendUIEvent(
	clientOrPromise: AnalyticsWebClientType | Promise<AnalyticsWebClientType>,
	payload: UIEventPayload,
) {
	Promise.resolve(clientOrPromise).then((client) => {
		client.sendUIEvent(payload);
	});
}

export function sendOperationalEvent(
	clientOrPromise: AnalyticsWebClientType | Promise<AnalyticsWebClientType>,
	payload: OperationalEventPayload,
) {
	Promise.resolve(clientOrPromise).then((client) => {
		client.sendOperationalEvent(payload);
	});
}
