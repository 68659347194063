import type { Option, CustomField, EnumSelectField } from '@atlaskit/editor-common/extensions';

import { getTemplateResolver } from '../data-resolvers/template-resolver';

import type { FieldTransformer } from './types';

const isNumber = (contentId) => /^[0-9]+$/.test(contentId);

export const createFromTemplate: FieldTransformer<{
	templateName: string;
	spaceKey?: string;
	contentBlueprintId?: string;
	templateId?: string;
}> = {
	transformFields: async (fields, pageContext, _, params?: any) => {
		let templates: Option[] = [];
		const resolver = getTemplateResolver(params?.spaceKey ? params : pageContext);
		if (resolver) {
			templates = await resolver('', '');
		}

		const newFields = fields.map((field) => {
			if (field.name === 'templateName') {
				return {
					...field,
					type: 'enum',
					items: templates,
					isRequired: true,
				} as EnumSelectField;
			} else if (field.name === 'spaceKey') {
				return {
					...field,
					defaultValue: pageContext.spaceKey,
					isRequired: false,
				} as CustomField;
			}

			return field;
		});

		const spaceKeyIndex = newFields.findIndex(({ name }) => name === 'spaceKey');
		const templateIndex = newFields.findIndex(({ name }) => name === 'templateName');
		newFields.unshift(newFields.splice(templateIndex, 1)[0]);
		newFields.unshift(newFields.splice(spaceKeyIndex, 1)[0]);

		return newFields;
	},

	transformAfter(params) {
		const { templateName } = params;

		if (templateName) {
			if (isNumber(templateName)) {
				params.templateId = templateName;
				delete params.contentBlueprintId;
			} else {
				params.contentBlueprintId = templateName;
				delete params.templateId;
			}
		}

		return params;
	},
};
