import React from 'react';

import type { Extension } from '@confluence/macro-tracker';

export type ExtensionWithParentContentId = Extension & {
	parentContentId?: string;
};

type PageIncludeContextType = {
	listOfRenderedPageIncludeNodes: ExtensionWithParentContentId[];
};

export const PageIncludeContext = React.createContext<PageIncludeContextType>({
	listOfRenderedPageIncludeNodes: [],
});
