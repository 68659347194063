export type ConnectDataTransferResponse = {
	status: ConnectDataTransferStatus;
	message: string;
	module: string;
	errors?: Array<Object> | string;
};

export enum ConnectDataTransferStatus {
	Success = 'Success',
	Error = 'Error',
}
