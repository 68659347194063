import type { WatchQueryFetchPolicy, ErrorPolicy } from 'apollo-client';
import type { DocumentNode } from 'graphql';

import { getOperationName } from './getOperationName';
import { getQueryName } from './getQueryName';

const QueryPreloadedWithNetworkOnly = new Set<string>();

function getKey(name: string, variables: Record<string, any> | undefined): string {
	if (!variables) {
		return name;
	}
	const sortedVariables = Object.entries(variables)
		.sort(([a], [b]) => a.localeCompare(b))
		.reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
	return `${name}:${JSON.stringify(sortedVariables)}`;
}

export function flagQueryPreloadedByNetworkOnly(
	name: string,
	variables: any,
	fetchPolicy: WatchQueryFetchPolicy,
): void {
	const key = getKey(name, variables);
	if (fetchPolicy === 'network-only') {
		QueryPreloadedWithNetworkOnly.add(key);
	} else {
		QueryPreloadedWithNetworkOnly.delete(key);
	}
}

export function getQueryPropsAndSetFetchPolicyForNetworkOnlyQuery<
	V extends {
		query: DocumentNode;
		variables: any;
		fetchPolicy?: WatchQueryFetchPolicy;
	},
>(options: V): V {
	const [query, queryOptions] = getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery(options);
	return {
		query,
		...queryOptions,
	} as V;
}

/**
 * Return cache-first for query preloaded with network-only
 * Return network-only when super admin is on because there is no preloading
 */
export function getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery<
	V extends {
		query: DocumentNode;
		variables: any;
		fetchPolicy?: WatchQueryFetchPolicy;
		errorPolicy?: ErrorPolicy;
	},
>(options: V): [DocumentNode, Omit<V, 'query'>] {
	const { query, ...queryOptions } = options;
	if (!query) {
		// This shouldn't happen. Throw in development to catch it.
		if (process.env.NODE_ENV !== 'production') {
			throw new Error(
				`Please pass in query object to getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery`,
			);
		}
		return [query, queryOptions];
	}

	const { variables = {}, fetchPolicy } = queryOptions;
	const name = getOperationName(query);
	const queryName = getQueryName(name, variables);
	const shouldUseCacheFirst =
		// If SSRed then the query has just been preloaded on server-side the data is fresh
		window.__SSR_RENDERED__ ||
		// If we can ensure the query was preloaded with network-only then it is safe to read from cache
		QueryPreloadedWithNetworkOnly.has(getKey(queryName, variables));

	return [
		query,
		{
			...queryOptions,
			fetchPolicy: shouldUseCacheFirst
				? 'cache-first'
				: // Otherwise use whatever passed in
					fetchPolicy,
		},
	];
}
