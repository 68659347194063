import type { IntlShape } from 'react-intl-next';

import { i18nWidth } from '../field-mappings/recently-updated/i18n';

export const widthPercentageResolver =
	(intl: IntlShape) => async (searchTerm?: string, selectedValue?: string | string[]) => {
		const items = [
			{
				label: intl.formatMessage(i18nWidth.fullLabel),
				value: '100%',
			},
			{
				label: intl.formatMessage(i18nWidth.threeQuartersLabel),
				value: '75%',
			},
			{
				label: intl.formatMessage(i18nWidth.halfLabel),
				value: '50%',
			},
			{
				label: intl.formatMessage(i18nWidth.quarterLabel),
				value: '25%',
			},
		];
		// Add the current input value if it is not one of the predefined options
		// This is necessary to allow values beyond the predefined ones. (i.e. "90%")
		if (
			selectedValue &&
			typeof selectedValue === 'string' &&
			!Object.values(items)
				.map((item) => item.value)
				.includes(selectedValue)
		) {
			items.push({
				label: selectedValue,
				value: selectedValue,
			});
		}
		if (searchTerm) {
			return Promise.resolve(items.filter((item) => item.value.indexOf(searchTerm) !== -1));
		}
		return Promise.resolve(items);
	};
