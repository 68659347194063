import { LoadablePaint } from '@confluence/loadable';

export { preloadSpaceOverview } from './preloadSpaceOverview';
export { SpaceOverviewGroupedButtons } from './SpaceOverviewGroupedButtons';
export { StartSpaceOverviewPageLoad } from './perf.config';

export const SpaceOverviewLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-space-overview" */ './SpaceOverview'))
			.SpaceOverview,
});
