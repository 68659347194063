import { StorageClient } from '@atlaskit/frontend-utilities';

export const LOCAL_STORAGE_CLIENT_KEY = '@atlaskit/editor-plugin-ai';
export const LOCAL_STORAGE_PROACTIVE_AI_TOGGLED_KEY = 'proactiveAIToggled';

const storageClient = new StorageClient(LOCAL_STORAGE_CLIENT_KEY);

export const markLocalStorageProactiveAIToggled = (value: boolean, expiration?: number) => {
	storageClient.setItemWithExpiry(LOCAL_STORAGE_PROACTIVE_AI_TOGGLED_KEY, value, expiration);
};

export const isLocalStorageProactiveAIToggled = (defaultValue?: boolean) => {
	const localStorageValue =
		storageClient.getItem(LOCAL_STORAGE_PROACTIVE_AI_TOGGLED_KEY) ?? defaultValue;
	return !!localStorageValue;
};
