import { type NetworkStatusChangeCallback, NetworkStatusEnum } from './types';

export default class NetworkStatus {
	constructor(networkChangeCallback: NetworkStatusChangeCallback) {
		if (typeof window !== 'undefined') {
			window.addEventListener('offline', () => networkChangeCallback(NetworkStatusEnum.OFFLINE));
			window.addEventListener('online', () => networkChangeCallback(NetworkStatusEnum.ONLINE));
		}
	}

	getNetworkStatus(): NetworkStatusEnum {
		if (window.navigator.onLine) {
			return NetworkStatusEnum.ONLINE;
		}
		return NetworkStatusEnum.OFFLINE;
	}
}
