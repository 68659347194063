import gql from 'graphql-tag';

import { UserPermissionFragment } from '@confluence/external-collab-ui/entry-points/UserPermissionFragment';

export const AppNavigationUnifiedQuery = gql`
	query AppNavigationUnifiedQuery {
		lookAndFeel {
			custom {
				horizontalHeader {
					backgroundColor
					primaryNavigation {
						highlightColor
					}
				}
			}
		}
		siteConfiguration {
			customSiteLogo
			siteLogoUrl
			siteTitle
			showSiteTitle
		}
		user(current: true) {
			id
			displayName
			photos {
				value
				isPrimary
			}
		}
		# This data is a pre-requisite for rendering the People menu.
		# Including this fragment helps to ensure the People menu will be SSR'd properly.
		...UserPermissionFragment
	}

	${UserPermissionFragment}
`;
