import { query } from '@confluence/query-preloader-tools';

import { EditorContentStateQuery } from './EditorContentStateQuery.graphql';
import type {
	EditorContentStateQuery as EditorContentStateQueryType,
	EditorContentStateQueryVariables,
} from './__types__/EditorContentStateQuery';

export function preloadEditorContentState(contentId: any, useCache: boolean) {
	return query<EditorContentStateQueryType, EditorContentStateQueryVariables>({
		query: EditorContentStateQuery,
		variables: {
			contentId,
			isDraft: true,
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	});
}
