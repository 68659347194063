import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { themeSSRManager } from '@confluence/ssr-utilities';
import { getEffectiveThemeState } from '@confluence/theming-utils';
import { fg } from '@confluence/feature-gating';

import { UserPreferencesForSSRQuery } from './UserPreferencesForSSRQuery.graphql';
import type {
	UserPreferencesForSSRQuery as UserPreferencesForSSRQueryType,
	UserPreferencesForSSRQueryVariables as UserPreferencesForSSRQueryVariablesType,
} from './__types__/UserPreferencesForSSRQuery';
import { UserSettingsQuery } from './UserSettingsQuery.graph';
import type {
	UserSettingsQuery as UserSettingsQueryType,
	UserSettingsQueryVariables as UserSettingsQueryVariablesType,
} from './__types__/UserSettingsQuery';

export const API_DEFAULT_THEME_STATE = 'default';

export const preloadUserSettingsForSSR = async ({
	spaceKey = '',
	themeState,
	isEditPageEmbedExperience,
}: {
	spaceKey?: string;
	themeState?: string;
	isEditPageEmbedExperience?: boolean;
}) => {
	if (themeState) {
		themeSSRManager.setThemeStateBasedOnSavedThemeState(
			getEffectiveThemeState(decodeURIComponent(themeState)),
		);
	}

	const { isLicensed } = await getPreloaderFnContext();

	const isUserSettingsQueryMigrationEnabled = fg('confluence_user_settings_query_migration');

	if ((!isLicensed || isEditPageEmbedExperience) && !isUserSettingsQueryMigrationEnabled) {
		return Promise.resolve(null);
	}

	let response: { data: UserPreferencesForSSRQueryType | UserSettingsQueryType | null };

	if (fg('confluence_user_settings_query_migration')) {
		response = await query<UserSettingsQueryType, UserSettingsQueryVariablesType>({
			query: UserSettingsQuery,
			variables: {
				isNotLicensed: !isLicensed,
			},
		});
	} else {
		response = await query<UserPreferencesForSSRQueryType, UserPreferencesForSSRQueryVariablesType>(
			{
				query: UserPreferencesForSSRQuery,
				variables: { spaceKey, hasSpaceKey: Boolean(spaceKey) },
			},
		);
	}
	const theme = response?.data?.userPreferences?.theme;
	// Setting colorMode for user theme
	if (theme) {
		themeSSRManager.setThemeStateBasedOnSavedThemeState(getEffectiveThemeState(theme));
	}

	return response;
};
