import { Slice } from '@atlaskit/editor-prosemirror/model';
import { safeInsert } from '@atlaskit/editor-prosemirror/utils';

import type {
	ActionAppearance,
	EditorPluginAIConfigItemMarkdownAction,
} from '../../config-items/config-items';
import { eventHub } from '../../pm-plugins/ai-event-hub/event-hub';
import { findEditorIdFromView } from '../../pm-plugins/ai-event-hub/utils/isolated-utils';

import {
	beforeDispatchAIRovoAgentTransaction,
	beforeDispatchAITransaction,
	beforeDispatchingTransactionForBlocks,
	insertAIContentAtCurrentPosition,
	replaceWithAIContent,
	replaceWithAIContentForBlock,
} from './action-utils';
import { messages } from './messages';

export type CreateInsertProps = { appearance: ActionAppearance; isRovoAgentAction?: boolean };

export const createInsertAtTop = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insertAtTop,
	appearance,
	run({ editorView, pmFragment }) {
		const { state } = editorView;
		const transaction = state.tr;

		const slice = new Slice(pmFragment, 0, 0);
		transaction.replace(0, 0, slice);

		beforeDispatchAITransaction(transaction, state);
		editorView.dispatch(transaction);
	},
});

export const createInsertBelow = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insertBelow,
	appearance,
	run({ editorView, positions, pmFragment, triggeredFor }) {
		const { state } = editorView;
		const transaction = state.tr;
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		if (triggeredFor?.isBlock) {
			const insertPos = positions[1];
			safeInsert(pmFragment, insertPos)(transaction);
			beforeDispatchingTransactionForBlocks(pmFragment, transaction, editorView.state);
		} else {
			const insertPos = state.doc.resolve(positions[1]).after();
			safeInsert(pmFragment, insertPos)(transaction);
		}

		beforeDispatchAITransaction(transaction, state);
		editorView.dispatch(transaction);
	},
});

export const createInsertAtCurrentPosition = ({
	appearance,
	isRovoAgentAction,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insert,
	appearance,
	isRovoAgentAction,
	run({ editorView, positions, pmFragment }) {
		const tr = insertAIContentAtCurrentPosition({ editorView, positions, pmFragment });
		if (isRovoAgentAction) {
			beforeDispatchAIRovoAgentTransaction(tr);
		}
		editorView.dispatch(tr);
	},
});

export const createReplace = ({
	appearance,
	isRovoAgentAction,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.replace,
	appearance,
	isRovoAgentAction,
	run({ editorView, positions, pmFragment, triggeredFor }) {
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		const tr = triggeredFor?.isBlock
			? replaceWithAIContentForBlock({ editorView, positions, pmFragment, triggeredFor })
			: replaceWithAIContent({ editorView, positions, pmFragment });
		if (isRovoAgentAction) {
			beforeDispatchAIRovoAgentTransaction(tr);
		}
		editorView.dispatch(tr);
	},
});

/**
 * EXPERIMENTAL: Util for experimental flow where 'Suggest title' in
 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
 * This util allows replacing the title in Confluence.
 */
export const createReplaceExistingConfluenceTitle = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.replaceTitle,
	appearance,
	run({ editorView, pmFragment }) {
		let fragmentToInsert = pmFragment;
		let textContent = '';
		fragmentToInsert.forEach((node) => {
			textContent += node.textContent;
		});
		const confluenceTitle = document.querySelector(
			'textarea[data-ai-suggest-title-target="true"]',
		) as HTMLTextAreaElement;
		if (confluenceTitle) {
			const sourceEditorId = findEditorIdFromView(editorView);
			if (sourceEditorId) {
				eventHub.publishFromEditor({
					event: 'replace title in confluence',
					data: {
						analyticSourceId: 'confluenceTitleToolbar',
						sourceEditorId,
						title: textContent,
					},
				});
			}

			confluenceTitle.scrollIntoView();
		}
	},
});
