import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { ADMIN_CUSTOMIZE_NAVIGATION } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';

const CustomizeNavigation = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CustomizeNavigation" */ '@confluence/customize-navigation'
			)
		).CustomizeNavigation,
});

export const CustomizeNavigationRoute = () => <CustomizeNavigation />;

CustomizeNavigationRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'customizeNavigationScreen' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});

// Legacy
export const CompanyHomeBuilderRoute = () => <Redirection name={ADMIN_CUSTOMIZE_NAVIGATION.name} />;
CompanyHomeBuilderRoute.NAVIGATION_PARAMS = () => false;
