import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { SpacePermissionsSettingsRoute } from './SpacePermissionsSettingsRoute';
import {
	ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSideNav } from '@confluence/breadcrumbs/entry-points/getCompanyHubSideNav';
import {
	CompanyHubSettingsPage,
	CompanyHubSettingsPageTabsType,
} from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { fg } from '@confluence/feature-gating';

const DEFAULT_TAB = 'users';

export const AdminCompanyHubPermissionsRoute = ({ params, ...props }: RouteMatch) => {
	const tab = getPreConsolidatedSettingsTab(params);

	if (!tab) {
		return <CompanyHubSettingsPage tab={CompanyHubSettingsPageTabsType.USERS} />;
	}

	const spaceKey = getCompanyHubSpaceKey();
	return <SpacePermissionsSettingsRoute {...props} params={{ spaceKey, ...params, tab }} />;
};

AdminCompanyHubPermissionsRoute.NAVIGATION_PARAMS = (routeMatch: RouteMatch) => {
	const { name, params } = routeMatch;

	const tab = getPreConsolidatedSettingsTab(params);
	const spaceKey = getCompanyHubSpaceKey();
	const navView = getCompanyHubSideNav(routeMatch) ? ADMIN_HOME_ID : CONTAINER_HOME_ID;

	return {
		Screen: {
			screenEvent: {
				name: `${tab || 'consolidated'}HubPermissionsScreen`,
				id: spaceKey,
			},
			pageState: { spaceKey, routeName: name },
		},
		MainLayout: {
			navView,
			spaceKey,
		},
	};
};

function getPreConsolidatedSettingsTab({ tab }: RouteMatch['params']) {
	if (!tab && !fg('company_hub_consolidated_settings')) {
		tab = DEFAULT_TAB;
	}
	return tab;
}
