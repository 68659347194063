import { sanitizeUrl } from '../../../../utils';
import { adaptEventHandler } from '../../../utils';

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::70be90f611b1240cad10e7ab1142fa50>>
 * @codegenId #section-message-action
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen section-message-action
 * @codegenParams { "props": { "children": true, "linkComponent": false, "onClick": {"value": "adaptEventHandler(onClick)"}, "href": { "value": "href ? sanitizeUrl(href): undefined", "comment": "safety measure" }, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/section-message/src/types.tsx <<SignedSource::33ed9c413b530d397ed67dfc90f0d312>>
 * @codegenDependency ../../../../../../../design-system/section-message/src/section-message-action.tsx <<SignedSource::0ed180173fb922658c85aea03d03308a>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { SectionMessageAction as PlatformSectionMessageAction } from '@atlaskit/section-message';

type PlatformSectionMessageActionProps = React.ComponentProps<typeof PlatformSectionMessageAction>;

export type SectionMessageActionProps = Pick<
  PlatformSectionMessageActionProps,
  'children' | 'onClick' | 'href' | 'testId'
>;

export const SectionMessageAction = (props: Parameters<RenderFn>[0]) => {
  const {
    onClick,
    href,
    testId,
  } = props.forgeDoc.props as SectionMessageActionProps;
  return (
    <PlatformSectionMessageAction
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      onClick={adaptEventHandler(onClick)}
      // safety measure
      href={href ? sanitizeUrl(href): undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
