import React, { useContext } from 'react';

import {
	ADMIN_HOME_ID,
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { LoadablePaint } from '@confluence/loadable';
import type { RouteMatch } from '@confluence/route';
import { getCompanyHubSideNav } from '@confluence/breadcrumbs/entry-points/getCompanyHubSideNav';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { SPAViewContext } from '@confluence/spa-view-context';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { ADMIN_COMPANY_HUB_PERMISSIONS, SPACE_MEMBERS } from '@confluence/named-routes';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';

const SpacePermissionsSettingsRouteComponent = LoadablePaint({
	loader: async () =>
		import(
			/* webpackChunkName: "loadable-SpacePermissionSettingsRouteComponent" */ './SpacePermissionsSettingsRouteComponent'
		),
});

const DEFAULT_TAB = 'users';

export const SpacePermissionsSettingsRoute = ({
	name: routeName,
	params: { spaceKey, tab = DEFAULT_TAB },
}: RouteMatch) => {
	//
	// Warning:
	// "Hub permissions" is a customization of "Space permissions" predating RBAC so
	// "Hub permissions" is to be used in the Company Hub-type of space and
	// "Space permisisons" or RBAC are to be used in all other types of space!
	//

	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !isCompanyHub,
	});
	const { isSiteAdmin } = useContext(SPAViewContext);

	if (isCompanyHub) {
		if (
			routeName !== ADMIN_COMPANY_HUB_PERMISSIONS.name &&
			fg('confluence_frontend_company_hub_simplified_routes')
		) {
			return <Redirection name={ADMIN_COMPANY_HUB_PERMISSIONS.name} />;
		}
	} else {
		//RBAC Milestone 2 Redirect Logic in case users try to access via url
		const isSpacePermissionsTab = tab === 'users' || tab === 'groups' || tab === 'guests';
		if (isSpacePermissionsTab && fg('cc_perms_exp_rbac_fe_milestone_2')) {
			return <Redirection name={SPACE_MEMBERS.name} params={{ spaceKey }} />;
		}
	}

	return isSiteAdmin && shouldShowPremiumUpsell ? (
		<CompanyHomeBuilderUpsellPage featureName="companyHubPermissions" />
	) : (
		<SpacePermissionsSettingsRouteComponent spaceKey={spaceKey} tab={tab} />
	);
};

SpacePermissionsSettingsRoute.NAVIGATION_PARAMS = (routeMatch: RouteMatch) => {
	const {
		name,
		params: { spaceKey, tab = DEFAULT_TAB },
	} = routeMatch;

	return {
		Screen: {
			screenEvent: {
				name:
					tab === 'public-links'
						? 'spacePermissionsPublicLinksScreen'
						: `${tab}SpacePermissionsScreen`,
				id: spaceKey,
			},
			pageState: { spaceKey, routeName: name },
		},
		MainLayout: {
			navView: getSideNav(routeMatch),
			spaceKey,
		},
	};
};

const getSideNav = (routeMatch: RouteMatch) =>
	isCompanyHubSpaceKey(routeMatch.params.spaceKey) && getCompanyHubSideNav(routeMatch)
		? ADMIN_HOME_ID
		: getIsNav4Enabled()
			? CONTAINER_ADMIN_HOME_ID
			: CONTAINER_HOME_ID;
