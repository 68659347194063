import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { B50, N800, N70 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TemplateVariableInputWrapper = styled.span({
	marginTop: token('space.negative.150', '-12px'),
	marginBottom: token('space.200', '16px'),
	paddingBottom: token('space.025', '2px'),
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TemplateVariableNameWrapper = styled.span({
	padding: `${token('space.025', '2px')} 5px`,
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	borderRadius: '3px 0 0 3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TemplateVariableName = styled.span({
	wordBreak: 'break-all',
	color: token('color.text', N800),
	fontWeight: 'initial',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TemplateVariableType = styled.span({
	color: token('color.text.subtlest', N70),
	padding: `${token('space.025', '2px')} 5px`,
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	borderRadius: '0 3px 3px 0',
	wordBreak: 'break-all',
});

// TemplateVariableInput
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TemplateVariableInputFieldWrapper = styled.span({
	display: 'inline-block',
	maxWidth: '100%',
	whiteSpace: 'initial',
});
// textareas push the rest of the content down when inline
// so it needs extra styling
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TemplateVariableTextareaInputFieldWrapper = styled.span({
	display: 'inline-block',
	maxWidth: '100%',
	position: 'relative',
	verticalAlign: 'top',
});

// TemplateVariableInputComponent
// atlaskit buttons text overlaps the popper dropdown
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonStyle = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		position: 'unset',
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropDownInputWrapper = styled.span({
	marginLeft: token('space.025', '2px'),
});
