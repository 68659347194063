import React from 'react';
import type { ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Tooltip } from '@visx/xychart';
import { defaultStyles as tooltipDefaultStyles } from '@visx/tooltip';

import { Box } from '@atlaskit/primitives';
import { N0 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

import type { LineDataElement } from '../ChartBase-types';
import { accessors } from '../ChartBase-utils';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContainer = styled.div({
	backgroundColor: token('elevation.surface.raised', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipDate = styled.span({
	color: token('color.text', '#172B4D'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipDescription = styled.span<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => props.color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.semibold', '600'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipValue = styled.span({
	color: token('color.text', '#172B4D'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	marginLeft: token('space.100', '8px'),
	float: 'right',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverrideContentContainer = styled.div({
	color: token('color.text', '#172B4D'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.medium', '500'),
});

export type CustomTooltipProps = {
	// Optionally override the tooltip content for certain data points.
	tooltipOverride?: {
		// Filter function defining the condition for which data points to override tooltip content for.
		xFilter: (x: Date) => boolean;
		// Tooltip content to override the default content with.
		content: string | ReactNode;
	};
};

// This component creates a customized Tooltip component to get the value description right.
export const CustomTooltip = ({ tooltipOverride }: CustomTooltipProps) => (
	<Tooltip
		snapTooltipToDatumX
		snapTooltipToDatumY
		showVerticalCrosshair
		showSeriesGlyphs
		renderTooltip={({ tooltipData, colorScale }) => {
			const allDataDescriptions = Object.values(tooltipData?.datumByKey || {}).map((datum) => ({
				key: datum.key,
				x: datum ? accessors.xAccessor(datum.datum as LineDataElement) : undefined,
				xDescriptionValue: datum
					? accessors.xDescriptionAccessor(datum.datum as LineDataElement)
					: undefined,
				yDescriptionValue: datum
					? accessors.yDescriptionAccessor(datum.datum as LineDataElement)
					: undefined,
			}));
			const xValue = allDataDescriptions[0]?.x;

			const lineXDescription = (
				<TooltipDate>{allDataDescriptions[0]?.xDescriptionValue}</TooltipDate>
			);
			const getLineYDescriptions = () => (
				<>
					{allDataDescriptions.map((descriptions) => {
						// Otherwise, show a tooltip for the data points we're nearest to for each line.
						return (
							<div key={`CustomTooltip-LineDetail-${descriptions.key}`}>
								<TooltipDescription color={colorScale?.(descriptions.key || '') || ''}>
									{descriptions.key}
								</TooltipDescription>
								<TooltipValue>
									{descriptions.yDescriptionValue && ` ${descriptions.yDescriptionValue}`}
								</TooltipValue>
							</div>
						);
					})}
				</>
			);

			return (
				<TooltipContainer data-testid="CustomTooltip">
					{lineXDescription}
					{xValue && tooltipOverride?.xFilter(xValue) ? (
						<Box>
							<OverrideContentContainer>{tooltipOverride?.content}</OverrideContentContainer>
						</Box>
					) : (
						getLineYDescriptions()
					)}
				</TooltipContainer>
			);
		}}
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			...tooltipDefaultStyles,
			boxShadow: `0 1px 2px ${token('color.interaction.hovered', '#00000029')}`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			backgroundColor: token('elevation.surface.raised', N0),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			opacity: 1,
		}}
	/>
);
