import React, { useCallback } from 'react';

import type {
	// eslint-disable-line no-restricted-imports
	ExperienceAttributes,
} from '@confluence/experience-tracker';
import { getExperienceTracker } from '@confluence/experience-tracker';
import { containsSessionExpiredError } from '@confluence/network';
import { LoadableAfterPaint } from '@confluence/loadable';

import type { ErrorComponentModalProps } from './ErrorComponent';
import type { GenericErrorBoundaryProps, ErrorComponentProps } from './GenericErrorBoundary';
import { GenericErrorBoundary } from './GenericErrorBoundary';

// Error component brings in modal-dialog. It should only be loaded when there is actually an error.
export const ErrorComponent = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ErrorComponent" */ './ErrorComponent'))
			.ErrorComponent,
});

export type ErrorBoundaryProps = React.PropsWithChildren<
	{
		attributes?: ExperienceAttributes;
	} & ErrorComponentModalProps &
		Pick<GenericErrorBoundaryProps, 'attribution'>
>;

export const ErrorBoundary = ({ attribution, attributes, children, modal }: ErrorBoundaryProps) => {
	const renderOnError = useCallback(
		(props: ErrorComponentProps) => <ErrorComponent {...props} modal={modal} />,
		[modal],
	);

	const failExperiencesOnError = useCallback(
		(error: Error) => {
			if (error.name === 'ChunkLoadError')
				getExperienceTracker().abort({ reason: 'ChunkLoadErrorAbort' });
			// the error UI rendered by this component is scary enough for the user
			// to consider all experiences as failures (we prompt the user to contact support and stuff).
			// Session expiration check that you see here isn't excessive, as it is only
			// supposed to be temporary (while we are in the process of transitioning to better error
			// handling via ErrorDisplay components and the like).
			// Once that migration is complete, this failure will be __unconditional__.
			if (!containsSessionExpiredError(error)) {
				getExperienceTracker().fail({ error, attributes });
			}
		},
		[attributes],
	);

	return (
		<GenericErrorBoundary
			attribution={attribution}
			renderOnError={renderOnError}
			onError={failExperiencesOnError}
		>
			{children}
		</GenericErrorBoundary>
	);
};
