import type { Resolver } from '../extensionTypes';

export const dedupeResolver =
	(resolver: Resolver) => async (searchTerm?: string, selectedValue?: string | string[]) => {
		const options = await resolver(searchTerm, selectedValue);
		const memory = new Map<string, boolean>();
		return options.filter(({ value }) => {
			const isKnownValue = memory.has(value);
			memory.set(value, true);
			return !isKnownValue;
		});
	};
