import React from 'react';
import type { NativeInlineProps, RenderFn } from '@atlassian/forge-ui-types';
import { spaceMap } from '../utils/spaceMap';

const flexGrowMap = {
	hug: { flexGrow: 0 },
	fill: {
		width: '100%',
		flexGrow: 1,
	},
};

export function Inline({ forgeDoc, render }: Parameters<RenderFn>[0]) {
	const { alignBlock, alignInline, spread, space, grow, shouldWrap, rowSpace } =
		forgeDoc.props as NativeInlineProps;

	const justifyContent = spread || alignInline;
	const growProps = grow ? flexGrowMap[grow] : undefined;
	const gap = space && spaceMap[space];
	const rowGap = rowSpace && spaceMap[rowSpace];

	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'flex',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				flexDirection: 'row',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				gap,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				justifyContent: justifyContent,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				alignItems: alignBlock,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...growProps,
				flexWrap: shouldWrap ? 'wrap' : undefined,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				rowGap,
			}}
		>
			{forgeDoc.children.map(render)}
		</div>
	);
}
