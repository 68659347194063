import React from 'react';
import { type BadgeProps, type NativeBadgeProps, type RenderFn } from '@atlassian/forge-ui-types';
import { getChildrenText } from '../utils/getChildrenText';

const AKBadge = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.badge' */
			'@atlaskit/badge'
		),
);

export const Badge = ({ forgeDoc: { props, children } }: Parameters<RenderFn>[0]) => {
	// `text` prop should eventually be deprecated
	// It exists because it was included in Preview
	// Merging the two types here to avoid breaking changes
	const { appearance, text } = props as BadgeProps & NativeBadgeProps;
	return <AKBadge appearance={appearance}>{text ? text : getChildrenText(children)}</AKBadge>;
};
