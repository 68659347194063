import { query, writeQuery } from '@confluence/query-preloader-tools';

import {
	WebItemLocationQuery,
	WebItemLocationWithSpaceKeyQuery,
} from './WebItemLocationQuery.graphql';
import type {
	WebItemLocationQuery as WebItemLocationQueryType,
	WebItemLocationQueryVariables,
} from './__types__/WebItemLocationQuery';
import type {
	WebItemLocationWithSpaceKeyQuery as WebItemLocationWithSpaceKeyQueryType,
	WebItemLocationWithSpaceKeyQueryVariables,
} from './__types__/WebItemLocationWithSpaceKeyQuery';

export function preloadWebItemLocation({
	location = null,
	contentId = null,
	spaceKey = null,
	version = null,
}: {
	location?: string | string[] | null;
	contentId?: string | null;
	spaceKey?: string | null;
	version?: number | null;
} = {}) {
	if (!contentId && spaceKey) {
		return query<WebItemLocationWithSpaceKeyQueryType, WebItemLocationWithSpaceKeyQueryVariables>({
			query: WebItemLocationWithSpaceKeyQuery,
			variables: {
				location: Array.isArray(location) ? null : location,
				locations: Array.isArray(location) ? location : [],
				spaceKey,
				version,
			},
		}).then(({ data }) => {
			if (data?.space?.homepageId) {
				writeQuery<WebItemLocationQueryType, WebItemLocationQueryVariables>({
					query: WebItemLocationQuery,
					variables: {
						location: Array.isArray(location) ? null : location,
						locations: Array.isArray(location) ? location : [],
						contentId: data?.space?.homepageId,
						spaceKey: null,
						version,
					},
					data: {
						webItemSections: data?.space?.homepageWebSections || [],
					},
				});
			}
		});
	}
	const variables: WebItemLocationQueryVariables = {
		// GraphQL doesn't cache single location strings properly when passed to locations
		// unless we're using multiple locations we should be using the location variable
		location: Array.isArray(location) ? null : location,
		locations: Array.isArray(location) ? location : [],
		contentId,
		spaceKey,
		version,
	};

	return query({
		query: WebItemLocationQuery,
		variables,
	});
}
