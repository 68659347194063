import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	summarizePageTitle: {
		id: 'fabric.editor.ai.ai-panels.summarizePageTitle',
		defaultMessage: `Page summary panel (Beta)`,
		description: 'Title for AI summary panel',
	},
	summarisePageDescription: {
		id: 'fabric.editor.ai.ai-panels.summarizePageDescription',
		defaultMessage: `Use Atlassian Intelligence to summarize this page and display in a panel`,
		description: 'Description for AI summary panel',
	},
	regenerateButtonLabel: {
		id: 'fabric.editor.ai.ai-panels.summarizePageRegenerateButtonLabel',
		defaultMessage: `Summarize page (Beta)`,
		description: 'Regenerate button label',
	},
	regenerateButtonTooltip: {
		id: 'fabric.editor.ai.ai-panels.summarizePageRegenerateButtonTooltip',
		defaultMessage: `Use Atlassian Intelligence to summarize this page`,
		description: 'Regenerate button tooltip',
	},
	feedbackButtonLabelTooltip: {
		id: 'fabric.editor.ai.ai-panels.summarizePageFeedbackButtonLabel',
		defaultMessage: 'Give feedback',
		description: 'Feedback collector trigger button label and tooltip',
	},
});
