import { useRef } from 'react';
import gql from 'graphql-tag';
import { type ApolloClient, type ApolloError } from 'apollo-client';
import { useQuery, type QueryHookOptions } from '@apollo/react-hooks';
import { type GQLCustomUITunnelDefinition, type GQLAppTunnels } from '../graphql/types';

export const getActiveTunnelsQuery = () => {
	const query = `
  query forge_ui_getActiveTunnels($appId: ID!, $environmentId: ID!) {
    appActiveTunnels(appId: $appId, environmentId: $environmentId) {
      customUI {
        resourceKey
        tunnelUrl
      }
    }
  }
  `;

	return gql`
		${query}
	`;
};

export interface QueryVariables {
	appId: string;
	environmentId: string;
}

export interface QueryData {
	appActiveTunnels: GQLAppTunnels;
}

export type UseAppTunnelsQueryHookOptions = QueryHookOptions<QueryData, QueryVariables>;

export type UseAppTunnelsOptions = QueryVariables & {
	environmentType: string;
	client?: ApolloClient<object>;
	queryOptions?: UseAppTunnelsQueryHookOptions;
	expandAppOwner?: boolean;
};

interface CommonHookResult {
	loading: boolean;
	error?: ApolloError;
}

export interface UseAppTunnelsHookResult extends CommonHookResult {
	tunnels: GQLCustomUITunnelDefinition[];
}

export interface AppTunnelsGQLResult {
	data?: {
		appActiveTunnels?: {
			customUI?: Array<GQLCustomUITunnelDefinition>;
		};
	};
	loading: boolean;
}

export const createAppTunnelsQueryOptions = (
	client: ApolloClient<object> | undefined,
	appId: string,
	environmentId: string,
	queryOptions?: UseAppTunnelsQueryHookOptions,
): UseAppTunnelsQueryHookOptions => {
	return {
		variables: {
			appId,
			environmentId,
		},
		...queryOptions,
		query: getActiveTunnelsQuery(),
		client,
		/**
		 * We don't want to set 'ignore' because that discards errors and we need to inspect
		 * them for SLI reporting. We also need 'all' so we don't discard data on errors:http://go/j/AUX-317
		 * https://www.apollographql.com/docs/react/data/error-handling/
		 */
		errorPolicy: 'all',
	};
};

export const getCustomUITunnelsFromGQLResult = ({ loading, data }: AppTunnelsGQLResult) => {
	if (!loading) {
		return data?.appActiveTunnels?.customUI;
	}

	return undefined;
};

export const useCustomUITunnelsList = (options: UseAppTunnelsOptions): UseAppTunnelsHookResult => {
	const { appId, environmentType, environmentId, client, queryOptions } = options;

	const shouldQuery = useRef(true);

	if (environmentType !== 'DEVELOPMENT') {
		shouldQuery.current = false;
	}

	const requestOptions = createAppTunnelsQueryOptions(client, appId, environmentId, queryOptions);

	const { error, loading, data } = useQuery<QueryData, QueryVariables>(
		requestOptions.query || getActiveTunnelsQuery(),
		{
			...requestOptions,
			skip: !shouldQuery.current,
		},
	);
	const tunnels = getCustomUITunnelsFromGQLResult({ loading, data });

	return {
		error,
		tunnels: tunnels || [],
		loading,
	};
};
