import React, { createContext, useRef, useContext } from 'react';
import { type Breadcrumb } from '@sentry/browser';

export interface TracingService {
	getBreadcrumbs(): Breadcrumb[];
	recordBridgeCall(description: string): void;
	recordGqlCall(description: string): void;
	recordConsentFlow(description: string, result?: string): void;
}

export const TracingContext = createContext<TracingService | undefined>(undefined);

export const TracingProvider: React.FC<any> = (props) => {
	const breadCrumbs = useRef<Breadcrumb[]>([]);

	const tracingService = useRef({
		getBreadcrumbs: () => breadCrumbs.current,
		recordBridgeCall: (description: string) =>
			(breadCrumbs.current = [
				...breadCrumbs.current,
				{
					category: `forge.bridge`,
					message: description,
					timestamp: new Date().toISOString() as any,
				},
			]),
		recordGqlCall: (description: string) =>
			(breadCrumbs.current = [
				...breadCrumbs.current,
				{
					type: 'http',
					category: 'gql',
					timestamp: new Date().toISOString() as any,
					data: {
						url: description,
					},
				},
			]),
		recordConsentFlow: (description: string, result?: string) =>
			(breadCrumbs.current = [
				...breadCrumbs.current,
				{
					category: `user.consent`,
					message: description,
					timestamp: new Date().toISOString() as any,
					...(result
						? {
								data: {
									result,
								},
							}
						: {}),
				},
			]),
	});

	return (
		<TracingContext.Provider value={tracingService.current}>
			{props.children}
		</TracingContext.Provider>
	);
};

export const useTracingContext = () => {
	const tracingContext = useContext(TracingContext);

	return tracingContext;
};
