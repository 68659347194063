import { type CategorizedStorageControlsCache, CategoryStorageType } from '../../../types';
import { CacheLoader } from '../load-storage-controls-data';

let localAnalyticsEnabled: boolean | undefined;
let localProduct: string | undefined;
let webStorageControlsEnabled: boolean | undefined;
let localConsentModeEnabled: boolean | undefined;

let cookiesCacheLoader: CacheLoader | undefined;
let localStorageCacheLoader: CacheLoader | undefined;
let sessionStorageCacheLoader: CacheLoader | undefined;

let loadCache: (
	storageType: CategoryStorageType,
) => Promise<CategorizedStorageControlsCache | undefined> = async (
	storageType: CategoryStorageType,
) => {
	switch (storageType) {
		case CategoryStorageType.Cookies:
			return await cookiesCacheLoader?.loadStorageControlsData();
		case CategoryStorageType.LocalStorage:
			return await localStorageCacheLoader?.loadStorageControlsData();
		case CategoryStorageType.SessionStorage:
			return await sessionStorageCacheLoader?.loadStorageControlsData();
		default:
			return undefined;
	}
};

interface InitializeBscGlobalStateParams {
	analyticsEnabled?: boolean;
	product?: string;
	webStorageEnabled?: boolean;
	localConsentMode?: boolean;
}

interface OverrideBscGlobalStateParams {
	loadCache?: typeof loadCache;
}

export interface BscGlobalState {
	analyticsEnabled?: boolean;
	product?: string;
	webStorageEnabled?: boolean;
	localConsentMode?: boolean;

	cookiesCacheLoader?: CacheLoader;
	localStorageCacheLoader?: CacheLoader;
	sessionStorageCacheLoader?: CacheLoader;

	loadCache: (
		storageType: CategoryStorageType,
	) => Promise<CategorizedStorageControlsCache | undefined>;
}

export const getBscGlobalState: () => BscGlobalState = () => {
	if (localAnalyticsEnabled == null) {
		localAnalyticsEnabled = true;
	}

	// Unless specified, localConsentMode is turned off
	localConsentModeEnabled = localConsentModeEnabled ?? false;

	return {
		analyticsEnabled: localAnalyticsEnabled,
		product: localProduct,
		webStorageEnabled: webStorageControlsEnabled,
		localConsentMode: localConsentModeEnabled,
		cookiesCacheLoader,
		localStorageCacheLoader,
		sessionStorageCacheLoader,
		loadCache,
	};
};

export const initializeGlobalState = ({
	analyticsEnabled = true,
	product = 'NONE',
	webStorageEnabled = false,
	localConsentMode = false,
}: InitializeBscGlobalStateParams = {}) => {
	localAnalyticsEnabled = analyticsEnabled;
	localProduct = product;
	webStorageControlsEnabled = webStorageEnabled;
	localConsentModeEnabled = localConsentMode;

	cookiesCacheLoader = new CacheLoader(CategoryStorageType.Cookies);
	localStorageCacheLoader = new CacheLoader(CategoryStorageType.LocalStorage);
	sessionStorageCacheLoader = new CacheLoader(CategoryStorageType.SessionStorage);
};

export const testing_overrideGlobalState = ({
	loadCache: overrideLoadCache,
}: OverrideBscGlobalStateParams) => {
	if (overrideLoadCache) {
		loadCache = overrideLoadCache;
	}
};

export const clearGlobalState = () => {
	localAnalyticsEnabled = undefined;
	localProduct = undefined;

	cookiesCacheLoader = undefined;
	localStorageCacheLoader = undefined;
	sessionStorageCacheLoader = undefined;
};
