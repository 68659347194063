/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::9a249836a536fd8d2ab4be34749dabb1>>
 * @codegenId #tab-panel
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tab-panel
 * @codegenParams { "props": { "children": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::d14a7503f424a1271c00e3d090b07d73>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/hooks.tsx <<SignedSource::85aae58dfbe7e51fa5c11faea1be0adf>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tab-panel.tsx <<SignedSource::fa31622ee8fc05c6247d26031f6d36b1>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { TabPanel as PlatformTabPanel } from '@atlaskit/tabs';

type PlatformTabPanelProps = React.ComponentProps<typeof PlatformTabPanel>;

export type TabPanelProps = Pick<
  PlatformTabPanelProps,
  'children' | 'testId'
>;

export const TabPanel = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as TabPanelProps;
  return (
    <PlatformTabPanel
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */