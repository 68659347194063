import { LoadablePaint } from '@confluence/loadable';

export const CompanyHomeBuilderUpsellPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHomeBuilderUpsellPage" */ '../src/CompanyHub/CompanyHomeBuilderUpsellPage'
			)
		).CompanyHomeBuilderUpsellPage,
});
