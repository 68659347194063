import gql from 'graphql-tag';

export const DisplayConditionsSpaceAdminQuery = gql`
	query DisplayConditionsSpaceAdminQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			currentUser {
				isAdmin
			}
		}
	}
`;
