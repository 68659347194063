import type { ReactElement } from 'react';

import type { ExperienceAttributes } from '@confluence/experience-tracker';
import { containsNoNetworkError } from '@confluence/network';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { getMacroExperienceTracker, MACRO_TRACKER_FF } from '../MacroExperienceTracker';
import { useDeepCompareEffect } from '../useDeepCompareEffect';

type Base = {
	error?: Error;
	attributes?: ExperienceAttributes;
};

type MacroExperienceStopProps = Base & {
	mode: string;
	extensionKey?: string;
	attributes?: ExperienceAttributes;
	error?: Error;
	name: string;
	children?: ReactElement | null;
	contentId: string;
	source?: string;
};

export function stopExperienceOnError(
	name: string,
	error: Error,
	mode: string,
	contentId: string,
	attributes?: ExperienceAttributes,
	source?: string,
) {
	if (containsNoNetworkError(error)) {
		getMacroExperienceTracker(mode, contentId).abort({
			name,
			reason: `Aborted ${name} because of network error: ${error.toString()}`,
			checkForTimeout: false,
			attributes: {
				...attributes,
				source,
			},
		});
		return;
	}

	getMacroExperienceTracker(mode, contentId).fail({
		name,
		error,
		attributes: {
			...attributes,
			source,
		},
	});
}

export function MacroExperienceStop({
	attributes,
	children = null,
	error,
	contentId,
	mode,
	name,
	source,
}: MacroExperienceStopProps) {
	const isMacroTrackerEnabled = useBooleanFeatureFlag(MACRO_TRACKER_FF);

	useDeepCompareEffect(() => {
		if (isMacroTrackerEnabled) {
			if (error) {
				stopExperienceOnError(name, error, mode, contentId, attributes, source);
			} else {
				getMacroExperienceTracker(mode, contentId).succeed({
					name,
					attributes,
				});
			}
		}
	}, [attributes, contentId, error, isMacroTrackerEnabled, mode, name, source]);

	// https://stackoverflow.com/questions/54905376
	return children as ReactElement<any>;
}
