import { LoadablePaint, MustPreloadLoadableNames } from '@confluence/loadable';

export const ViewPageRouteComponentLoader = LoadablePaint({
	// Name is used in next/packages/view-page/src/index.ts must match
	name: MustPreloadLoadableNames.ViewPageRouteComponentLoader,
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ViewPageRouteComponent" */ './ViewPageRouteComponent'
			)
		).ViewPageRouteComponent,
});
