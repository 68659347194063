import { LoadablePaint } from '@confluence/loadable';

export const CreateContentRouteComponentLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcCreateContentRouteComponentCreateContentRouteComponentWrapper" */ '../src/CreateContentRouteComponent/CreateContentRouteComponentWrapper'
			)
		).CreateContentRouteComponentWrapper,
});
