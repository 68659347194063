import type { IntlShape } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import type { ToolbarItem } from '@atlaskit/editor-common/extensions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';

import { parseNumber } from './parseNumber';

type CreateChartsExtensionProps = {
	intl: IntlShape;
	editorActions?: EditorActions;
	extensionHandlers?: ExtensionHandlerWithReferenceFn<any>;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	externalToolbarItems?: ToolbarItem[];
};

export const createChartsExtension = async ({
	intl,
	editorActions,
	extensionHandlers,
	createAnalyticsEvent,
	externalToolbarItems,
}: CreateChartsExtensionProps) => {
	const { buildManifest } = await import(
		/* webpackChunkName: "loadable-atlassiancharts" */ '@atlassian/charts'
	);
	return buildManifest({
		intl: intl as any,
		editorActions,
		parseNumber,
		extensionHandlerWithReference: extensionHandlers,
		createAnalyticsEvent,
		externalToolbarItems: externalToolbarItems || [],
	});
};
