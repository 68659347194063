import type { WRMType } from './requireLegacyWRM';

let wrm: Promise<WRMType> | null = null;
export function waitForWRM(): Promise<WRMType> {
	if (wrm) {
		return wrm;
	}

	wrm = new Promise((resolve) => {
		const wrmInterval = window.setInterval(() => {
			if (window.WRM && typeof window.WRM.require === 'function') {
				window.clearInterval(wrmInterval);
				resolve(window.WRM);
			}
		}, 50);
	});

	return wrm;
}
