export class MessageLifecycleContextError extends Error {
	constructor() {
		super('useMessageLifecycle must be used inside a Message template render component.');
	}
}

export class MessageLifecycleClientError extends Error {
	constructor() {
		super('API call to /messages failed due to an error');
	}
}
