import { LoadableAfterPaint } from '@confluence/loadable';

export { CompanyHubSettingsPageTabsType } from '../src/hub-settings/CompanyHubSettingsPage';
export const CompanyHubSettingsPage = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkCompanyHubSettingsPage" */ '../src/hub-settings/CompanyHubSettingsPage'
			)
		).CompanyHubSettingsPage,
	name: 'CompanyHubSettingsPage',
});
