export const routerStateExists = (state) =>
	// TODO: https://product-fabric.atlassian.net/browse/BM-429
	// Note: It is not safe to checking the state.router anymore because it is always not null
	// This is a temporary fix to avoid fixing every usage of this function
	!state.isServerRenderedPage && Boolean(state.router);

export const getLocation = (state) =>
	!state.isServerRenderedPage && state.router ? state.router.location : {};

export const getQueryParams = (state) => {
	const stateLocation = getLocation(state);
	return stateLocation && stateLocation.query ? stateLocation.query : {};
};

export const getRouteParams = (state) =>
	!state.isServerRenderedPage && state.router ? state.router.params || {} : {};
