import gql from 'graphql-tag';

export const PageRestrictionsQuery = gql`
	query PageRestrictionsQuery($contentId: ID!, $status: [String], $includeAncestors: Boolean!) {
		singleContent(id: $contentId, status: $status) {
			id
			hasInheritedRestrictions
			hasViewRestrictions
			hasRestrictions
			ancestors @include(if: $includeAncestors) {
				id
				title
				hasRestrictions
				links {
					webui
				}
			}
		}
	}
`;
