import { query } from '@confluence/query-preloader-tools';

import { RendererContentStateQuery } from './RendererContentStateQuery.graphql';
import type {
	RendererContentState as RendererContentStateType,
	RendererContentStateVariables,
} from './__types__/RendererContentStateQuery';
import { GraphQLDateFormat } from './__types__/RendererContentStateQuery';

export async function preloadRendererContentState(contentId: string) {
	return query<RendererContentStateType, RendererContentStateVariables>({
		query: RendererContentStateQuery,
		variables: {
			contentId,
			isDraft: false,
			format: GraphQLDateFormat.USER_FRIENDLY,
		},
		fetchPolicy: 'network-only',
	});
}
