import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { SPACE_OVERVIEW as SPACE_OVERVIEW_MARKER } from '@confluence/screen';
import { SpaceOverviewLoader } from '@confluence/space-overview';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { COMPANY_HUB, SPACE_OVERVIEW } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';

export function getSpaceOverviewRoute(Component: React.ElementType) {
	const SpaceOverviewRoute = (match: RouteMatch) => {
		const {
			params: { spaceKey = '', contentId = '' },
			query,
		} = match;

		if (isCompanyHubSpaceKey(spaceKey)) {
			return <Redirection name={COMPANY_HUB.name} />;
		}
		// The only different between SPA and SSR is we don't render space base in SSR
		return <Component spaceKey={spaceKey} contentId={contentId} query={query} />;
	};

	SpaceOverviewRoute.NAVIGATION_PARAMS = ({
		params: { spaceKey = '' },
		query: { mode = '' },
	}: RouteMatch) => ({
		Screen: {
			screenEvent: {
				name: 'spaceOverviewScreen',
				id: spaceKey,
				attributes: {
					isGlobalMode: mode === 'global',
					navdexPointType: START_TOUCH,
				},
			},
			pageState: {
				spaceKey,
				contentId: SPACE_OVERVIEW_MARKER,
				routeName: SPACE_OVERVIEW.name,
			},
		},
		MainLayout: {
			navView: CONTAINER_HOME_ID,
			spaceKey,
			isViewPage: true,
		},
	});

	return SpaceOverviewRoute;
}

export const SpaceOverviewRouteForSSR = getSpaceOverviewRoute(SpaceOverviewLoader);
