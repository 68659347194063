import FeatureGates from '@atlaskit/feature-gate-js-client';
import { UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';

import type { PerformanceTrackingProviderProps } from './types';
import { TrackMount } from '../../../track-mount';
import { usePerformanceTracking } from '../../hooks/usePerformanceTracking';
import type { StableProvider } from '../../types';

export const PerformanceTracking: StableProvider<PerformanceTrackingProviderProps> = ({
	children,
	...props
}) => {
	const isUFOEnabled = FeatureGates.checkGate('enable_ufo_tracking_in_post_office');

	const { isLoading, onMount } = usePerformanceTracking(props);

	return (
		<UFOv2.UFOSegment name={UFOv2.SegmentNames.PLACEMENT}>
			{isUFOEnabled && isLoading && <UFOv2.UFOLoadHold name={UFOv2.HoldNames.PLACEMENT} />}
			<TrackMount onMount={onMount}>{children}</TrackMount>
		</UFOv2.UFOSegment>
	);
};
