import type { LabelProps as PlatformLabelProps } from '@atlaskit/form';
export type LabelProps = Pick<PlatformLabelProps, 'children' | 'testId'| 'id' > & {labelFor : string}
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::d77e8e63ebb8f2e0277dad14339c0a1e>>
 * @codegenId #label
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen label
 * @codegenParams { "defineOwnType": true, "customProps": ["labelFor"], "props": { "children": true,  "htmlFor": { "value" : "labelFor", "excludeFromDeconstruct": true, "comment":"labelFor as prop name to remove reference to HTML"},"id": true, "testId" : true } }
 * @codegenDependency ../../../../../../../design-system/form/src/label.tsx <<SignedSource::8f96207bee44f3631d60b6dde76b43d5>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Label as PlatformLabel } from '@atlaskit/form';

// Define the type for Label as LabelProps at the top of this file

export const Label = (props: Parameters<RenderFn>[0]) => {
  const {
    id,
    testId,
    labelFor,
  } = props.forgeDoc.props as LabelProps;
  return (
    <PlatformLabel
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      // labelFor as prop name to remove reference to HTML
      htmlFor={labelFor}
      id={id}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
