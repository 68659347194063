import type { ComponentProps } from 'react';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';

import { UnpublishHubDialogLoadable } from './UnpublishHubDialogLoadable';

type UnpublishHubDialogLoadableProps = ComponentProps<typeof UnpublishHubDialogLoadable>;

export function useDeactivateHubDialog(
	props: Omit<UnpublishHubDialogLoadableProps, keyof DialogsContainerProps> = {},
) {
	const { showModal } = useDialogs();

	return () => showModal(UnpublishHubDialogLoadable, props);
}
