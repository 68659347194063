import { isQuotaExceededError } from './StorageManager';

/**
 * Monkey patch for local storage to catch "Quota Exceeded" errors.
 * See https://jira.atlassian.com/browse/CONFDEV-67240.
 */
export const patchLocalStorage = () => {
	const realSetItem = window.localStorage.setItem;

	function newSetItem(this: any, ...args: [string, string]) {
		try {
			return realSetItem.apply(this, args);
		} catch (e) {
			if (isQuotaExceededError(e)) {
				window.dispatchEvent(new CustomEvent('local-storage.quota.exceeded'));
			}
			throw e;
		}
	}

	window.localStorage.setItem = newSetItem;
};
