// These transformers will run for all macros
import type { FieldTransformer } from '../types';

import { cql } from './cql';
import { parseBoolean } from './parse-boolean';
import { stripDefaultValues } from './strip-default-values';
import { transformEmptyKey } from './transform-empty-key';
import { transformMultiValuedFields } from './transform-multi-valued-fields';
import { missingRequiredParameters } from './missing-required-parameters';

export const composeTransformers = <T extends {}>(
	...transformers: FieldTransformer<T extends infer R ? R : T>[]
) => ({
	transformFields: (fields, pageContext, intl) =>
		transformers.reduce(
			async (fields, transformer) =>
				transformer.transformFields
					? transformer.transformFields(await fields, pageContext, intl)
					: fields,
			Promise.resolve(fields),
		),
	transformBefore: (params, metadata, pageContext) =>
		transformers.reduce(
			(params, transformer) =>
				transformer.transformBefore
					? transformer.transformBefore(params, metadata, pageContext)
					: params,
			params,
		),
	transformAfter: (params, metadata, pageContext) =>
		transformers.reduce(
			(params, transformer) =>
				transformer.transformAfter
					? transformer.transformAfter(params, metadata, pageContext)
					: params,
			params,
		),
});

/**
 * IMPORTANT: ORDER MATTERS HERE, Please don't sort it for style purpose
 */
export const always = composeTransformers(
	cql,
	transformEmptyKey,
	parseBoolean,
	transformMultiValuedFields,
	stripDefaultValues,
	missingRequiredParameters,
);
