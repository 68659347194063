import { JS_LOAD_START, JS_LOAD_END } from '@confluence/loadable/entry-points/instrumentation';

export const bundleEvalTimings = {
	mapPerformanceMark: (mark: string) => {
		if (mark.endsWith(JS_LOAD_START)) {
			return {
				name: mark.substring(0, mark.indexOf(JS_LOAD_START)),
				type: 'start',
			};
		}
		if (mark.endsWith(JS_LOAD_END)) {
			return {
				name: mark.substring(0, mark.indexOf(JS_LOAD_END)),
				type: 'end',
			};
		}
		return null;
	},
};
