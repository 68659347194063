import window from 'window-or-global';

const contextPathFromPathname = window.location && window.location.pathname.split('/')[1];

export const CONTEXT_PATH = contextPathFromPathname ? `/${contextPathFromPathname}` : '/wiki';

export const SPACE_ROOT = 'spaces';
export const DISPLAY_ROOT = 'display';
export const SPACE_QUESTIONS_ROOT = 'display';
export const DISCOVER_ROOT = 'discover';
export const MY_WORK_ROOT = 'my';
export const RECENT_ROOT = 'recent';
