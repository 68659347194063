/**
 * confluence/meta is used to access dynamic metadata passed from the
 * server to JavaScript via the page HTML.
 */

/**
 *  A backing map to use if the user sets data.
 */
export const overrides: Record<string, any> = {};

/**
 * Sets metadata with a key and value, for use when the state of the page changes after
 * loading from the server
 */
export const setOverride = (key: string, value: any) => {
	overrides[key] = value;
};

/**
 * Convert 'true' or 'false' like values into JavaScript booleans
 */
export const stringToBoolean = (value: any) => {
	const evaluatedValue = typeof value === 'string' ? value.toLowerCase() : '';
	const booleanEnum: Record<string, boolean> = {
		true: true,
		false: false,
	};
	return evaluatedValue in booleanEnum ? booleanEnum[evaluatedValue] : value;
};

/**
 * Returns a value given a key. If no entry exists with the key, undefined is returned.
 * If the string value is "true" or "false" the respective boolean value is returned.
 * If the type is defined in an override, the return value will respect/have the overridden type.
 */
export const getOverride = (key: string) => {
	// Check if we're server-side or client-side
	if (isClientSide()) {
		const metaEl = document.querySelector(`meta[name='ajs-${key}']`) as HTMLMetaElement;
		if (metaEl) {
			return stringToBoolean(metaEl.content);
		}
		return overrides[key];
	}
	// Always return undefined on the server-side
	return undefined;
};

/**
 * Mainly for use when debugging, returns all Data pairs in a map for eyeballing.
 */
export const getAllAsMap = () => {
	const map: Record<string, any> = {};
	if (!document?.querySelectorAll?.length) {
		return map;
	}
	const metas = document.querySelectorAll('meta[name^=ajs-]');
	if (metas) {
		for (let i = 0; i < metas.length; i++) {
			const { name, content } = metas[i] as HTMLMetaElement;
			map[name.substring(4)] = content;
		}
	}
	return Object.assign(map, overrides);
};

/**
 * Get a mapping of ajs properties
 */
export const getTagsAsMap = () => {
	const metaData: Record<string, any> = {};
	if (isClientSide()) {
		const metaTags = document.querySelectorAll('meta[name^=ajs-]');
		for (let i = 0; i < metaTags.length; i++) {
			const { name, content } = metaTags[i] as HTMLMetaElement;
			metaData[name.replace(/^ajs-/, '')] = content;
		}
	}
	return metaData;
};

/**
 * Check if the document exists. If it does, this code is running
 * on the client
 */
export const isClientSide = () => {
	return typeof document !== 'undefined';
};

export const resetOverrides = () => {
	Object.keys(overrides).forEach((key) => {
		delete overrides[key];
	});
};
