import type { Action } from 'react-sweet-state';
import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';

type CelebrationsState = {
	showAddContentCelebration: boolean;
	showInvitePeopleCelebration: boolean;
	showOrganizeSpaceCelebration: boolean;
};

const initialState: CelebrationsState = {
	showAddContentCelebration: false,
	showInvitePeopleCelebration: false,
	showOrganizeSpaceCelebration: false,
};

const actions = {
	setShowAddContentCelebration:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showAddContentCelebration: true,
			});
		},
	resetShowAddContentCelebration:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showAddContentCelebration: false,
			});
		},
	setShowInvitePeopleCelebration:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showInvitePeopleCelebration: true,
			});
		},
	resetShowInvitePeopleCelebration:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showInvitePeopleCelebration: false,
			});
		},
	setShowOrganizeSpaceCelebration:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showOrganizeSpaceCelebration: true,
			});
		},
	resetShowOrganizeSpaceCelebration:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showOrganizeSpaceCelebration: false,
			});
		},
	resetAllCelebrations:
		(): Action<CelebrationsState> =>
		({ setState }) => {
			setState({
				showOrganizeSpaceCelebration: false,
				showInvitePeopleCelebration: false,
				showAddContentCelebration: false,
			});
		},
};

const CelebrationsStore = createStore({
	initialState,
	actions,
	name: 'CelebrationsStore',
});

export const useTrackerCelebrationsState = createStateHook(CelebrationsStore);
export const useTrackerCelebrationsActions = createActionsHook(CelebrationsStore);
