import type { UntilExperienceConfig } from '../types';

import { type BaseMetric } from './base-metric';
import type { PageSegmentLoadMetric } from './page-segment-load';

export class UntilExperience {
	static FMP: string = 'fmp';

	static fromConfig(config: UntilExperienceConfig) {
		return new UntilExperience(config.experience, config.useFmpAsUntilStopTime);
	}

	static fromMetric(metric: BaseMetric) {
		return new UntilExperience(metric);
	}

	constructor(
		public experience: BaseMetric | PageSegmentLoadMetric,
		protected useFmpAsUntilStopTime?: boolean,
	) {}

	/**
	 * If FMP mark is present, return that
	 * otherwise return the eventual load
	 */
	getFmp() {
		const { marks, stop } = this.experience.getData();
		return marks[UntilExperience.FMP] ?? stop;
	}

	getDependencyStopTime() {
		if (this.useFmpAsUntilStopTime) {
			return this.getFmp();
		}
		return this.experience.getData().stop;
	}

	/**
	 * Get the time it takes for a segment to become fully interactable,
	 * regardless of whether it uses the FMP as the stop time. (Eventual load)
	 */
	getFullyInteractiveStopTime() {
		return this.experience.getData().stop;
	}

	getIsSegmentLoadOverrided() {
		if (this.useFmpAsUntilStopTime) {
			const { marks } = this.experience.getData();
			return marks[UntilExperience.FMP] ? true : false;
		}
		return false;
	}

	getKey() {
		return this.experience.getData().key;
	}
}
