import type { ForgeUIAnalyticsContext } from '@atlassian/xen-editor-provider';
import { ProductEnvironment } from '@atlassian/xen-editor-provider';

export const createForgeMacroExtensionData = ({
	contentId,
	spaceKey,
	spaceId,
}: {
	contentId: string;
	spaceKey: string;
	spaceId?: string;
}) => ({
	type: 'macro',
	content: {
		id: contentId,
	},
	space: {
		key: spaceKey,
		id: spaceId,
	},
});

export function createForgeUIAnalyticsContext({
	objectId,
	objectType,
	containerId,
	containerType,
}: {
	objectId: string;
	objectType: 'page' | 'blogpost' | null;
	containerId: string | null;
	containerType: 'space';
}): ForgeUIAnalyticsContext {
	return {
		objectId,
		objectType: objectType || undefined,
		containerId: containerId || undefined,
		containerType,
	};
}

export function environmentToForgeProductEnvironment(environment: string) {
	return (
		// In dev, proxying to a site will give that site's environment,
		// which is not what we want
		window.location.hostname === 'localhost'
			? ProductEnvironment.DEVELOPMENT
			: environment === 'STAGING'
				? ProductEnvironment.STAGING
				: environment === 'PRODUCTION'
					? ProductEnvironment.PRODUCTION
					: ProductEnvironment.DEVELOPMENT
	);
}
