import { applyOverrides } from './FeatureFlagLocalOverrides';
import type { FeatureFlagsQuery_featureFlags_nodes } from './__types__/FeatureFlagsQuery';

/**
 * NOTE:
 * This file is used in query preloader aggregator.
 * In order to keep the bundle size to the minimum.
 * This file should not depend on any other packages.
 * All exports should be pure function.
 */

export function unwrapValue(value: any): string | boolean {
	if (value === 'true') {
		return true;
	} else if (value === 'false') {
		return false;
	} else if (typeof value === 'object') {
		return JSON.stringify(value);
	}

	return value;
}

export function keyById<TObject extends { id: string }, TValue>(
	objectList: TObject[],
	valueGetter: (item: TObject) => TValue,
) {
	return objectList.reduce<{ [objectId: string]: TValue }>((keyedObject, item) => {
		keyedObject[item.id] = valueGetter(item);
		return keyedObject;
	}, {});
}

export const processFeatureFlagsPure = (flags: FeatureFlagsQuery_featureFlags_nodes[]) => {
	const original = keyById(flags, (item) => unwrapValue(item.value));
	const withOverrides = applyOverrides<string | boolean>(original, (key, overrides, original) =>
		overrides.hasOwnProperty(key) ? overrides[key] : original[key],
	);
	return {
		original,
		withOverrides,
	};
};
