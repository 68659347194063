export function getHeaderValue(headers: HeadersInit, headerName: string): string | null {
	if (Array.isArray(headers)) {
		const header = headers.find(([name]) => name === headerName);
		return header ? header[1] : null;
	}

	if (headers instanceof Headers) {
		return headers.get(headerName);
	}

	return headers[headerName] ?? null;
}
