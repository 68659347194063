/* eslint react/prop-types:0 */
import React from 'react';

import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';

const SpaceSettingsForgeAppsRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceSettingsForgeAppsRouteComponent" */ './SpaceSettingsForgeAppsRouteComponent'
		),
});

export const SpaceSettingsForgeAppsRoute = ({
	params: { appId, forgeManifestRoute, envId, spaceKey },
}) => (
	<SpaceSettingsForgeAppsRouteComponent
		spaceKey={spaceKey}
		appId={appId}
		envId={envId}
		forgeManifestRoute={forgeManifestRoute}
	/>
);

SpaceSettingsForgeAppsRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey, appId, envId } }) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingsForgeApps', id: spaceKey, attributes: { appId, envId } },
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: getIsNav4Enabled() ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
