import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	manifestDescription: {
		id: 'confluence-databases-extension.description',
		defaultMessage: 'Create an empty database',
		description: 'Description for database editor extension.',
	},
	manifestTitle: {
		id: 'confluence-databases-extension.title',
		defaultMessage: 'Database extension',
		description: 'Title for database editor extension.',
	},
	createDatabaseTitle: {
		id: 'confluence-databases-extension.create-database.title',
		defaultMessage: 'Create database',
		description: 'Title for create database quick insert action.',
	},
	createDatabaseDescription: {
		id: 'confluence-databases-extension.create-database.description',
		defaultMessage: 'Create an empty database',
		description: 'Description for create database quick insert action.',
	},
	referenceDatabaseTitle: {
		id: 'confluence-databases-extension.reference-database.title',
		defaultMessage: 'Embed database',
		description: 'Title for reference database quick insert action.',
	},
	referenceDatabaseDescription: {
		id: 'confluence-databases-extension.reference-database.description',
		defaultMessage: 'Display an existing database, entry, or value',
		description: 'Description for reference database quick insert action.',
	},
	referenceDatabaseModalLabel: {
		id: 'confluence-databases-extension.reference-database.modal-label',
		defaultMessage: 'Reference database modal',
		description: 'Label for reference database modal.',
	},
	createDatabaseIconAltText: {
		id: 'confluence-databases-extension.create-database.icon-alt-text',
		defaultMessage: 'Create database icon',
		description: 'Alt text for create database quick insert action icon.',
	},
	referenceDatabaseIconAltText: {
		id: 'confluence-databases-extension.reference-database.icon-alt-text',
		defaultMessage: 'Reference database icon',
		description: 'Alt text for reference database quick insert action icon.',
	},
	successFlagTitle: {
		id: 'confluence-databases-extension.success-flag.title',
		defaultMessage: 'Database added to content tree',
		description: 'Title for success flag when database is created inline.',
	},
	successFlagAction: {
		id: 'confluence-databases-extension.success-flag.action',
		defaultMessage: 'Go to database',
		description: 'Action for success flag when database is created inline.',
	},
	successFlagDescription: {
		id: 'confluence-databases-extension.success-flag.description',
		defaultMessage:
			'The source database has been created in the tree. Go to the database to move its location or change its permissions.',
		description: 'Description for success flag when database is created inline.',
	},
	defaultErrorFlagTitle: {
		id: 'confluence-databases-extension.error-flag.title',
		defaultMessage: 'We’re unable to create right now',
		description: 'Title for default flag when database is created inline.',
	},
	defaultErrorFlagDescription: {
		id: 'confluence-databases-extension.error-flag.description',
		defaultMessage:
			'Refresh or wait a few minutes and try again. If this keeps happening, contact support.',
		description: 'Description for success flag when database is created inline.',
	},
	parentPageMovedErrorTitle: {
		id: 'confluence-databases-extension.error.parent-page-moved-error-title',
		defaultMessage: 'The parent has moved',
		description:
			'Title for error flag when the page is moved when trying to create a database inline.',
	},
	parentPageMovedErrorDescription: {
		id: 'confluence-databases-extension.error.parent-page-moved-error-description',
		defaultMessage: 'Refresh to create in the new location.',
		description:
			'Description for error flag when the page is moved when trying to create a database inline.',
	},
	permissionRemovedErrorTitle: {
		id: 'confluence-databases-extension.error.permission-removed-error-title',
		defaultMessage: 'We’re unable to create this content',
		description:
			'Title for error flag when the user encounters a permissions issue while creating content or tries to create content in a nonexistent space',
	},
	permissionRemovedErrorDescription: {
		id: 'confluence-databases-extension.error.permission-removed-error-description',
		defaultMessage:
			'You don’t have permission to create here or the space has been recently deleted.',
		description:
			'Description for error flag when the user encounters a permissions issue while creating content or tries to create content in a nonexistent space.',
	},
	invalidParentErrorTitle: {
		id: 'confluence-databases-extension.error.invalid-parent-error-title',
		defaultMessage: 'We can’t create a database',
		description:
			'Title for error flag when the page is deleted or archived when trying to create a database inline.',
	},
	invalidParentErrorDescription: {
		id: 'confluence-databases-extension.error.invalid-parent-error-description',
		defaultMessage:
			'This page was deleted or archived. You can create a database on a different page.',
		description:
			'Description for error flag when when the page is deleted or archived when trying to create a database inline.',
	},
});
