/* eslint-disable max-lines */
import React, { Component, type ReactElement } from 'react';

import isMatch from 'lodash/isMatch';
import once from 'lodash/once';
import PropTypes from 'prop-types';
import { type Store, type Unsubscribe } from 'redux';
import window from 'window-or-global';

import { AnalyticsEvent, AnalyticsListener, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	type MaybeChoreographedComponentProps,
	MessageDeliveryStatus,
	shouldBeChoreographed,
} from '@atlassian/ipm-choreographer';

import {
	type AnalyticsWebClientType,
	type CreateAnalyticsEvent,
	type OperationalEventPayload,
	type ScreenEventPayload,
	type UIEventPayload,
} from '../../common/types/analytics';
import { type Config } from '../../common/types/config';
import {
	type ComponentType,
	type CurrentMessage,
	type EventMapping,
	type MessageMapping,
} from '../../common/types/message';
import {
	type CoordinationOptions,
	startMessage,
	stopMessage,
} from '../../services/engage-targeting';
import { sendOperationalEvent, sendScreenEvent, sendUIEvent } from '../../services/gasv3';
import { getBrowserInfo } from '../../utils/browser';
import createCustomEvent from '../../utils/createCustomEvent';
import { isMessagingEnabled } from '../../utils/messaging';
import { clearCurrent, setCurrent } from '../engagement-actions';
import { type State as EngagementStoreState } from '../engagement-store';
import Queue from '../queue';

const MAX_EVENT_QUEUE_SIZE = 100;
const EK_LOADED_EVENT = 'engagement.inproduct.loaded';
export const ANALYTIC_EVENT_DOM_EVENT_NAME = 'ep.analyticEvent';

function collapseEventToPayload(event: UIAnalyticsEvent) {
	const payload = {
		...event.payload,
		context: {},
	};

	Object.assign(payload.context, ...event.context);

	return payload;
}

function findMatchingTrigger(messageMapping: MessageMapping, payload: Record<string, any>) {
	return Array.from(messageMapping.keys()).find((trigger) => {
		return isMatch(payload, trigger);
	});
}

function fireExposureEvent(
	attributes: { flagKey: string; value: string },
	clientOrPromise: AnalyticsWebClientType | Promise<AnalyticsWebClientType>,
) {
	const analyticsEvent = new AnalyticsEvent({
		payload: {
			action: 'exposed',
			actionSubject: 'feature',
			actionSubjectId: attributes.flagKey,
			source: 'engagement-platform',
			attributes,
		},
	});
	Promise.resolve(clientOrPromise).then((client) => {
		client.sendTrackEvent(analyticsEvent.payload as any);
	});
}

function getExposureAttributes(
	messageId: string,
	cohort: string,
	additionalProps?: Record<string, any>,
) {
	return {
		reason: 'RULE_MATCH',
		ruleId: 'engagement.inproduct',
		flagKey: `engagement.inproduct.${messageId}`,
		value: cohort,
		...additionalProps,
	};
}

function getBaseAttributes(
	messageId: string,
	variationId: string,
	componentId: number,
	locale: string,
) {
	return {
		messageId: messageId,
		variationId: variationId,
		componentIndex: componentId,
		locale: locale,
	};
}

// TODO refactor this to be inside EngagementProvider
// Instead of calling out to fireAnalyticsEvent we can call this.fireEvent
export async function triggerShown(
	eventMapping: EventMapping,
	config: Config,
	store: Store<EngagementStoreState>,
	clientOrPromise: AnalyticsWebClientType | Promise<AnalyticsWebClientType>,
	coordinationOptions?: CoordinationOptions,
	componentConfig?: MaybeChoreographedComponentProps,
): Promise<void> {
	const { locale } = config;
	const { message, component } = eventMapping;

	if (message.holdout) {
		await startMessage(
			{
				cloudId: config.cloudId,
				stargateUrl: config.stargateUrl,
				messageId: message.messageId,
				variationId: message.variationId,
				orgId: config.orgId,
				workspaceId: config.workspaceId,
			},
			coordinationOptions,
		);

		await stopMessage(
			{
				cloudId: config.cloudId,
				stargateUrl: config.stargateUrl,
				messageId: message.messageId,
				orgId: config.orgId,
				workspaceId: config.workspaceId,
			},
			coordinationOptions,
		);

		fireExposureEvent(
			getExposureAttributes(message.messageId, 'holdout', { locale }),
			clientOrPromise,
		);

		return;
	}

	if (component.componentId === 0) {
		const success = await startMessage(
			{
				cloudId: config.cloudId,
				stargateUrl: config.stargateUrl,
				messageId: message.messageId,
				variationId: message.variationId,
				orgId: config.orgId,
				workspaceId: config.workspaceId,
			},
			coordinationOptions,
		);

		if (coordinationOptions?.enableChoreographer && componentConfig?.onMessageDisposition) {
			componentConfig.onMessageDisposition(
				success ? MessageDeliveryStatus.STARTED : MessageDeliveryStatus.BLOCKED,
			);
		}

		if (success) {
			fireExposureEvent(
				getExposureAttributes(message.messageId, message.variationId, {
					locale,
				}),
				clientOrPromise,
			);
			store.dispatch(setCurrent(eventMapping));
		}
	} else {
		fireExposureEvent(
			getExposureAttributes(message.messageId, message.variationId, { locale }),
			clientOrPromise,
		);
		store.dispatch(setCurrent(eventMapping));
	}
}

type Props = {
	eventTarget: EventTarget;
	store: Store<EngagementStoreState>;
	children: ReactElement;
	// TODO remove once confirmed no one is passing this prop
	analyticsEventHandler?: (event: AnalyticsEvent) => void;
	createAnalyticsEvent: CreateAnalyticsEvent;
	analyticsClientInstance: AnalyticsWebClientType | Promise<AnalyticsWebClientType>;
	coordinationOptions?: CoordinationOptions;
};

// eslint-disable-next-line @repo/internal/react/no-class-components
export class EngagementProvider extends Component<Props> {
	analyticsEventQueue: Queue<UIAnalyticsEvent>;
	unsubscribeFromStore: () => void;
	fireQueueOverflowEvent: () => void;
	sendUIEventWithClient: (event: UIEventPayload) => void;
	sendScreenEventWithClient: (event: ScreenEventPayload) => void;
	sendOperationalEventWithClient: (event: OperationalEventPayload) => void;

	static componentConfig: Map<string, MaybeChoreographedComponentProps> = new Map();

	static childContextTypes = {
		subscribeEngagementState: PropTypes.func,
		setComponentConfig: PropTypes.func,
	};

	static defaultProps = {
		eventTarget: window,
	};

	/*
	 * We use this flag for ensuring that we only fire the engagement.inproduct.loaded event once. This is an interim solution
	 * until we can more holistically rethink our code architecture to eliminate duplicate behaviour across EngagementProviders.
	 */
	static hasInitializedAtLeastOneEngagementProvider = false;

	constructor(props: Props) {
		super(props);

		const { analyticsClientInstance } = this.props;
		this.sendUIEventWithClient = sendUIEvent.bind(null, analyticsClientInstance);
		this.sendScreenEventWithClient = sendScreenEvent.bind(null, analyticsClientInstance);
		this.sendOperationalEventWithClient = sendOperationalEvent.bind(null, analyticsClientInstance);
		this.analyticsEventQueue = new Queue(MAX_EVENT_QUEUE_SIZE);
		this.unsubscribeFromStore = this.subscribeEngagementState(this.onStoreChange);
		this.fireQueueOverflowEvent = once(
			// TODO: create metric for this with Metal
			() => {},
		);
	}

	onStoreChange = (state: EngagementStoreState): void => {
		const { createAnalyticsEvent } = this.props;
		const config = this.getConfig();
		const { locale } = config;

		if (state.initialized) {
			if (!EngagementProvider.hasInitializedAtLeastOneEngagementProvider) {
				this.sendOperationalEventWithClient({
					action: 'loaded',
					actionSubject: 'messages',
					actionSubjectId: undefined,
					source: 'engagekit',
					attributes: {
						locale,
					},
				});

				const browserInfo = getBrowserInfo();
				this.onEvent(
					createAnalyticsEvent({
						event: EK_LOADED_EVENT,
						properties: {
							browser: browserInfo.name,
							browserVersion: browserInfo.version,
						},
					}),
				);

				EngagementProvider.hasInitializedAtLeastOneEngagementProvider = true;
			}

			this.analyticsEventQueue.flush(this.onEvent);
			if (this.unsubscribeFromStore) {
				this.unsubscribeFromStore();
			}
		}
	};

	getConfig(): Config {
		const { config } = this.getState();

		return config;
	}

	getChildContext() {
		return {
			subscribeEngagementState: this.subscribeEngagementState,
			setComponentConfig: this.setComponentConfig,
		};
	}

	onEvent = (event: UIAnalyticsEvent): void => {
		const state = this.getState();
		if (!state.initialized) {
			const success = this.analyticsEventQueue.enqueue(event);
			if (!success) {
				this.fireQueueOverflowEvent();
			}

			return;
		}

		const { eventTarget, store, analyticsClientInstance } = this.props;
		const config = this.getConfig();
		const { current, messageMapping } = state;

		const payload = collapseEventToPayload(event);

		eventTarget.dispatchEvent(
			createCustomEvent({
				eventName: ANALYTIC_EVENT_DOM_EVENT_NAME,
				canBubble: false,
				cancellable: false,
				detail: payload,
			}),
		);

		if (!isMessagingEnabled()) {
			return;
		}

		// existing message found
		if (current) {
			return;
		}

		const trigger = findMatchingTrigger(messageMapping, payload);

		// No messages found for trigger
		if (!trigger) {
			return;
		}

		const eventMapping = messageMapping.get(trigger)!;

		let componentConfig: MaybeChoreographedComponentProps | undefined;
		if (eventMapping.component?.componentType) {
			const componentConfigId = this.getConfigId(
				eventMapping.component.componentType,
				eventMapping.component.target,
			);
			componentConfig = EngagementProvider.componentConfig.get(componentConfigId);
		}

		const coordinationOptions = this.getComponentCoordinationOptions(componentConfig);

		triggerShown(
			eventMapping,
			config,
			store,
			analyticsClientInstance,
			coordinationOptions,
			componentConfig,
		);
	};

	onEngagementEvent = (event: UIAnalyticsEvent): void => {
		const {
			action,
			clickType,
			ekMessageId,
			ekVariationId,
			ekComponentId,
			ekComponentType,
			ekIsLast,
		} = event.payload;

		const config = this.getConfig();
		const { locale } = config;
		const attributes: Record<string, any> = getBaseAttributes(
			ekMessageId,
			ekVariationId,
			ekComponentId,
			locale,
		);

		if (action === 'click') {
			this.handleClickEvent(ekMessageId, ekVariationId, ekComponentId);
			attributes.buttonType = clickType;
			this.sendUIEventWithClient({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${clickType}Button`,
				source: `engagement_${ekComponentType}`,
				attributes,
			});
		} else {
			if (action === 'shown') {
				attributes.componentType = ekComponentType;
				this.sendScreenEventWithClient({
					name: `engagement_${ekComponentType}`,
					attributes,
				});
			}
			if (action === 'hidden') {
				attributes.isLast = ekIsLast;
				this.sendUIEventWithClient({
					action: 'hid',
					actionSubject: 'component',
					actionSubjectId: undefined,
					source: `engagement_${ekComponentType}`,
					attributes,
				});
			}
		}

		this.onEvent(event);
	};

	subscribeEngagementState = (callback: (state: EngagementStoreState) => void): Unsubscribe => {
		const { store } = this.props;
		callback(store.getState());

		return store.subscribe(() => {
			callback(store.getState());
		});
	};

	getState(): EngagementStoreState {
		return this.props.store.getState();
	}

	getActiveMessage(): CurrentMessage {
		return this.getState().current!;
	}

	getComponentCoordinationOptions = (
		componentConfig?: MaybeChoreographedComponentProps,
	): CoordinationOptions => {
		// Default enableChoreographer to the EngagementProvider's coordinationOptions
		const enableChoreographer = Boolean(this.props.coordinationOptions?.enableChoreographer);

		return {
			...this.props.coordinationOptions,
			enableChoreographer: componentConfig?.messageType
				? // Use the component's individual choreographer configuration, if defined,
					// regardless of the EngagementProvider's coordinationOptions
					shouldBeChoreographed(componentConfig, { enableChoreographer: true })
				: enableChoreographer,
		};
	};

	async completeMessage(
		messageId: string,
		componentConfig?: MaybeChoreographedComponentProps,
	): Promise<void> {
		const config = this.getConfig();
		const coordinationOptions = this.getComponentCoordinationOptions(componentConfig);

		const disposition = await stopMessage(
			{
				cloudId: config.cloudId,
				stargateUrl: config.stargateUrl,
				messageId,
				orgId: config.orgId,
				workspaceId: config.workspaceId,
			},
			coordinationOptions,
		);

		if (coordinationOptions.enableChoreographer && componentConfig?.onMessageDisposition) {
			componentConfig.onMessageDisposition(
				disposition ? MessageDeliveryStatus.STOPPED : MessageDeliveryStatus.CANCELED,
			);
		}
	}

	handleClickEvent(messageId: string, variationId: string, componentId: number): void {
		const activeMessage = this.getActiveMessage();

		// It is important to check that the event we're handling matches the
		// current active message because it is possible for an event to come
		// through for a past message. We don't want to mess up the current
		// message by calling clearCurrent just because the old message is done.
		if (
			!activeMessage ||
			activeMessage.messageId !== messageId ||
			activeMessage.variationId !== variationId ||
			activeMessage.componentId !== componentId
		) {
			return;
		}

		let componentConfig: MaybeChoreographedComponentProps | undefined;
		if (activeMessage.componentType) {
			const componentConfigId = this.getConfigId(activeMessage.componentType, activeMessage.target);
			componentConfig = EngagementProvider.componentConfig.get(componentConfigId);
		}

		if (activeMessage.isLast) {
			this.completeMessage(messageId, componentConfig);
		}

		this.props.store.dispatch(clearCurrent());
	}

	getConfigId = (componentType: ComponentType, engagementId?: string): string => {
		return engagementId ? `${componentType}-${engagementId}` : componentType;
	};

	setComponentConfig = (
		componentType: ComponentType,
		config: MaybeChoreographedComponentProps,
		engagementId?: string,
	): (() => void) => {
		const configId = this.getConfigId(componentType, engagementId);
		EngagementProvider.componentConfig.set(configId, config);

		return () => this.removeComponentConfig(configId);
	};

	removeComponentConfig = (configId: string): void => {
		if (EngagementProvider.componentConfig.has(configId)) {
			EngagementProvider.componentConfig.delete(configId);
		}
	};

	componentWillUnmount(): void {
		this.unsubscribeFromStore && this.unsubscribeFromStore();
	}

	render(): JSX.Element {
		return (
			<AnalyticsListener channel="*" onEvent={this.onEvent}>
				<AnalyticsListener channel="engagement" onEvent={this.onEngagementEvent}>
					{this.props.children}
				</AnalyticsListener>
			</AnalyticsListener>
		);
	}
}
