import { isPartOfElementStillInViewPort } from '@confluence/dom-helpers';

/**
 * Captures the media elements in the current viewport under the DOM element with id 'content'.
 * It pulls the source values for these captured media elements and saves it to the window object.
 * These source values will then be accessed and cleared in next/packages/media-analytics/src/MediaAnalytics.tsx
 */
export const captureViewportMediaForAnalytics = async () => {
	const imageElements = Array.prototype.slice.call(
		document.getElementById('content')
			? document.getElementById('content').querySelectorAll('img')
			: [],
	);

	window._contentMediaSources = imageElements
		.filter((element) => isPartOfElementStillInViewPort(element))
		.map((element) => element.src);
};
