import { styled } from '@compiled/react';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import type { TextFieldProps } from '@atlaskit/textfield';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import SearchIcon from '@atlaskit/icon/glyph/search';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';

const i18n = defineMessages({
	searchPlaceholder: {
		id: 'company-hub.analytics.search-filter.placeholder',
		defaultMessage: 'Search by title',
		description: 'Placeholder text for the search input in the links clicked table',
	},
	searchLabel: {
		id: 'company-hub.analytics.search-filter.label',
		defaultMessage: 'Top content search',
		description: 'Label for the search input in the links clicked table',
	},
	searchClear: {
		id: 'company-hub.analytics.search-filter.clear-label',
		defaultMessage: 'Clear search',
		description: 'Clear search button label',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchWrapper = styled.div({
	width: '240px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [data-ds--text-field--container]': {
		borderWidth: '1px',
		borderRadius: '4px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '20px',
		height: '20px',
		marginLeft: token('space.100', '8px'),
		marginRight: token('space.025', '2px'),
	},
});

type SearchFilterProps = {
	onChange(text: string): void;
	searchText: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-html-button -- To migrate as part of go/ui-styling-standard
const ClearSearchButton = styled.button({
	border: 'none',
	backgroundColor: 'transparent',
	marginRight: token('space.100', '8px'),
	height: '26px',
	width: '26px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '9px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
});

export const SearchFilter = ({ onChange, searchText }: SearchFilterProps) => {
	const intl = useIntl();

	const onSearch = useCallback<NonNullable<TextFieldProps['onChange']>>(
		(event) => {
			onChange(event.currentTarget.value || '');
		},
		[onChange],
	);

	const onClear = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
		onChange('');
	}, [onChange]);

	return (
		<SearchWrapper>
			<Textfield
				aria-label={intl.formatMessage(i18n.searchLabel)}
				placeholder={intl.formatMessage(i18n.searchPlaceholder)}
				onChange={onSearch}
				value={searchText}
				elemBeforeInput={<SearchIcon label="" primaryColor={token('color.icon.accent.gray')} />}
				{...(searchText && {
					elemAfterInput: (
						<ClearSearchButton onClick={onClear}>
							<SelectClearIcon
								label={intl.formatMessage(i18n.searchClear)}
								size="small"
								primaryColor={token('color.icon.subtle')}
							/>
						</ClearSearchButton>
					),
				})}
				isCompact
			/>
		</SearchWrapper>
	);
};
