import { getMacroMetaData } from '@confluence/fabric-extension-lib/entry-points/editor-extensions';

let connectMacroKeyRefOutputTypeMap: Map<string, string>;
let initialized = false;

async function createMacroKeyRefOutputTypeMap() {
	const macros = await getMacroMetaData();
	const result = macros
		.filter((macro) => macro.refDataSchema != null)
		.map((macro): [string, string] => [macro.macroName, macro.refDataSchema.outputType]);

	return new Map(result);
}

export async function getConnectMacroKeyRefOutputTypeMap() {
	// used this initialized variable because I was seeing that connectMacroKeyRefOutputTypeMap may
	// be null errors otherwise
	if (!initialized) {
		connectMacroKeyRefOutputTypeMap = await createMacroKeyRefOutputTypeMap();
		initialized = true;
	}

	return connectMacroKeyRefOutputTypeMap;
}
