import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	pageLabel: {
		id: 'fabric-extension-lib.children.field.page.label',
		defaultMessage: 'Parent page',
		description:
			'Label for search field to find and select the page for which its child pages will be displayed in the macro',
	},
	pagePlaceholder: {
		id: 'fabric-extension-lib.children.field.page.placeholder',
		defaultMessage: 'Current page',
		description:
			"Placeholder text for 'Parent page' field to indicate the current page is selected when this field is empty",
	},
	allChildren: {
		id: 'fabric-extension-lib.children.field.allChildren.label',
		defaultMessage: 'Show all child pages',
		description:
			'Label for toggle field to configure whether to show all child pages under the current parent page',
	},
	allLabel: {
		id: 'fabric-extension-lib.children.field.all.label',
		defaultMessage: 'Show all levels',
		description:
			'Label for toggle field to configure whether to show all nested child pages under each child page in the list',
	},
	depthLabel: {
		id: 'fabric-extension-lib.children.field.depth.label',
		defaultMessage: 'Max number of levels',
		description:
			'Label for dropdown number field to configure the depth of nested child pages to display',
	},
	excerptTypeLabel: {
		id: 'fabric-extension-lib.children.field.excerptType.label',
		defaultMessage: 'Display preview',
		description:
			'Label for dropdown field to configure how the list of child pages should show a preview of their content in the list',
	},
	excerptTypeDescription: {
		id: 'fabric-extension-lib.children.field.excerptType.description',
		defaultMessage:
			'Lists can display a preview of the top of a child page, or the content of its excerpt.',
		description:
			"Description for the 'Display preview' dropdown field which explains how the 'Page preview' and 'Excerpt preview' options work",
	},
	firstLabel: {
		id: 'fabric-extension-lib.children.field.first.label',
		defaultMessage: 'Max number in list',
		description: 'Label for a number input field to configure how many child pages to display',
	},
	sortLabel: {
		id: 'fabric-extension-lib.children.field.sort.label',
		defaultMessage: 'Sort order',
		description: 'Label for dropdown field to configure the sort order of the listed child pages',
	},
	styleLabel: {
		id: 'fabric-extension-lib.children.field.style.label',
		defaultMessage: 'List style',
		description: 'Label for dropdown field to configure the text style for the list of child pages',
	},
	basicTabLabel: {
		id: 'fabric-extension-lib.config.tab.basic.label',
		defaultMessage: 'Basic',
		description: 'Label for tab containing general configuration options',
	},
	displayTabLabel: {
		id: 'fabric-extension-lib.config.tab.display.label',
		defaultMessage: 'Display',
		description: 'Label for tab containing display configuration options',
	},
});

export const i18nDepth = defineMessages({
	one: {
		id: 'fabric-extension-lib.children.field.depth.item.one',
		defaultMessage: '1 level',
		description: "Dropdown option to set 'Max number of levels' field to 1",
	},
	two: {
		id: 'fabric-extension-lib.children.field.depth.item.two',
		defaultMessage: '2 levels',
		description: "Dropdown option to set 'Max number of levels' field to 2",
	},
	three: {
		id: 'fabric-extension-lib.children.field.depth.item.three',
		defaultMessage: '3 levels',
		description: "Dropdown option to set 'Max number of levels' field to 3",
	},
	four: {
		id: 'fabric-extension-lib.children.field.depth.item.four',
		defaultMessage: '4 levels',
		description: "Dropdown option to set 'Max number of levels' field to 4",
	},
	five: {
		id: 'fabric-extension-lib.children.field.depth.item.five',
		defaultMessage: '5 levels',
		description: "Dropdown option to set 'Max number of levels' field to 5",
	},
	six: {
		id: 'fabric-extension-lib.children.field.depth.item.six',
		defaultMessage: '6 levels',
		description: "Dropdown option to set 'Max number of levels' field to 6",
	},
	seven: {
		id: 'fabric-extension-lib.children.field.depth.item.seven',
		defaultMessage: '7 levels',
		description: "Dropdown option to set 'Max number of levels' field to 7",
	},
	eight: {
		id: 'fabric-extension-lib.children.field.depth.item.eight',
		defaultMessage: '8 levels',
		description: "Dropdown option to set 'Max number of levels' field to 8",
	},
	nine: {
		id: 'fabric-extension-lib.children.field.depth.item.nine',
		defaultMessage: '9 levels',
		description: "Dropdown option to set 'Max number of levels' field to 9",
	},
});

export const i18nSort = defineMessages({
	titleGroup: {
		id: 'fabric-extension-lib.children.field.sort.group.title',
		defaultMessage: 'Title',
		description: 'Label for category of sort options to sort the list alphabetically',
	},
	titleItem: {
		id: 'fabric-extension-lib.children.field.sort.item.title',
		defaultMessage: 'A → Z',
		description:
			"Label for 'Sort order' dropdown option (under 'Title' category) to sort a list alphabetically",
	},
	titleReverseItem: {
		id: 'fabric-extension-lib.children.field.sort.item.titlereverse',
		defaultMessage: 'Z → A',
		description:
			"Label for 'Sort order' dropdown option (under 'Title' category) to sort a list reverse alphabetically",
	},

	creationGroup: {
		id: 'fabric-extension-lib.children.field.sort.group.creation',
		defaultMessage: 'Created',
		description: 'Label for category of sort options to sort the list by creation date',
	},
	creationItem: {
		id: 'fabric-extension-lib.children.field.sort.item.creation',
		defaultMessage: 'Newest → Oldest',
		description:
			"Label for 'Sort order' dropdown option (under 'Created' category) to sort a list from newest to oldest",
	},
	creationReverseItem: {
		id: 'fabric-extension-lib.children.field.sort.item.creationreverse',
		defaultMessage: 'Oldest → Newest',
		description:
			"Label for 'Sort order' dropdown option (under 'Created' category) to sort a list from oldest to newest",
	},

	modifiedGroup: {
		id: 'fabric-extension-lib.children.field.sort.group.modified',
		defaultMessage: 'Modified',
		description: 'Label for category of sort options to sort the list by modified date',
	},
	modifiedItem: {
		id: 'fabric-extension-lib.children.field.sort.item.modified',
		defaultMessage: 'Old → Recent',
		description:
			"Label for 'Sort order' dropdown option (under 'Modified' category) to sort a list from oldest to most recently modified",
	},
	modifiedReverseItem: {
		id: 'fabric-extension-lib.children.field.sort.item.modifiednreverse',
		defaultMessage: 'Recent → Old',
		description:
			"Label for 'Sort order' dropdown option (under 'Modified' category) to sort a list from most recently to oldest modified",
	},

	defaultGroup: {
		id: 'fabric-extension-lib.children.field.sort.group.default',
		defaultMessage: 'Content Tree',
		description:
			'Label for category of sort options to sort the list in the default order of how they appear in the page tree',
	},
	defaultItem: {
		id: 'fabric-extension-lib.children.field.sort.item.default',
		defaultMessage: 'Current',
		description:
			"Label for 'Sort order' dropdown option (under 'Content Tree' category) to sort a list in the default order of how they appear in the page tree",
	},
	defaultReverseItem: {
		id: 'fabric-extension-lib.children.field.sort.item.defaultreverse',
		defaultMessage: 'Reverse',
		description:
			"Label for 'Sort order' dropdown option (under 'Content Tree' category) to sort a list in the reverse order of how they appear in the page tree",
	},
});

export const i18nStyle = defineMessages({
	default: {
		id: 'fabric-extension-lib.children.field.style.item.default',
		defaultMessage: 'Bulleted',
		description:
			"Option for 'List style' dropdown field to display the list in a bulleted list (default style)",
	},
	h1: {
		id: 'fabric-extension-lib.children.field.style.item.h1',
		defaultMessage: 'H1',
		description:
			"Option for 'List style' dropdown field to display the list text in an H1 heading style",
	},
	h2: {
		id: 'fabric-extension-lib.children.field.style.item.h2',
		defaultMessage: 'H2',
		description:
			"Option for 'List style' dropdown field to display the list text in an H2 heading style",
	},
	h3: {
		id: 'fabric-extension-lib.children.field.style.item.h3',
		defaultMessage: 'H3',
		description:
			"Option for 'List style' dropdown field to display the list text in an H3 heading style",
	},
	h4: {
		id: 'fabric-extension-lib.children.field.style.item.h4',
		defaultMessage: 'H4',
		description:
			"Option for 'List style' dropdown field to display the list text in an H4 heading style",
	},
	h5: {
		id: 'fabric-extension-lib.children.field.style.item.h5',
		defaultMessage: 'H5',
		description:
			"Option for 'List style' dropdown field to display the list text in an H5 heading style",
	},
	h6: {
		id: 'fabric-extension-lib.children.field.style.item.h6',
		defaultMessage: 'H6',
		description:
			"Option for 'List style' dropdown field to display the list text in an H6 heading style",
	},
});

export const i18nExcerptType = defineMessages({
	none: {
		id: 'fabric-extension-lib.children.field.excerptType.item.none',
		defaultMessage: 'None',
		description:
			"Option for 'Display preview' dropdown field to not display any page preview in the list (default option)",
	},
	richContent: {
		id: 'fabric-extension-lib.children.field.excerptType.item.richContent',
		defaultMessage: 'Page preview',
		description:
			"Option for 'Display preview' dropdown field to display the first line of the child page under each list item",
	},
	simple: {
		id: 'fabric-extension-lib.children.field.excerptType.item.simple',
		defaultMessage: 'Excerpt preview',
		description:
			"Option for 'Display preview' dropdown field to display the excerpt text within the child page under each list item",
	},
});
