import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	actionItemsPageTitle: {
		id: 'fabric.editor.ai.ai-panels.actionItemsPageTitle',
		defaultMessage: `Action items panel (Beta)`,
		description: 'Title for AI action items panel',
	},
	actionItemsPageDescription: {
		id: 'fabric.editor.ai.ai-panels.actionItemsPageDescription',
		defaultMessage: `Use Atlassian Intelligence to find action items and display in a panel`,
		description: 'Description for AI action items panel',
	},
	regenerateButtonLabel: {
		id: 'fabric.editor.ai.ai-panel.actionItemsregenerateButtonLabel',
		defaultMessage: `Find action items (Beta)`,
		description: 'Regenerate button label',
	},
	regenerateButtonTooltip: {
		id: 'fabric.editor.ai.ai-panels.actionItemsregenerateButtonTooltip',
		defaultMessage: `Use Atlassian Intelligence to find action items on this page`,
		description: 'Regenerate button tooltip',
	},
	feedbackButtonLabelTooltip: {
		id: 'fabric.editor.ai.ai-panels.actionItemsFeedbackButtonLabel',
		defaultMessage: 'Give feedback',
		description: 'Feedback collector trigger button label and tooltip',
	},
});
