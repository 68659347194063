import { useEffect, useState } from 'react';
import lodashIsEqual from 'lodash/isEqual';

export const useMemoizedStateObject = <T extends unknown>(
	data: T,
	equalityCheck: (a: unknown, b: unknown) => boolean = lodashIsEqual,
): T => {
	const [memoizedData, setMemoizedData] = useState(data);

	useEffect(() => {
		if (!equalityCheck(data, memoizedData)) {
			setMemoizedData(data);
		}
	}, [data, memoizedData, equalityCheck]);

	return memoizedData;
};
