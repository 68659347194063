import React from 'react';

import Icon from '@atlaskit/icon/base';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { B50, B400, N30, N80, N500 } from '@atlaskit/theme/colors';

const ExcerptIncludeMacroIconGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="34"
		height="32"
		viewBox="0 0 34 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid="excerpt-include-macro-icon"
	>
		<rect
			x="12"
			y="8"
			width="22"
			height="16"
			rx="2"
			fill={token('color.background.accent.blue.subtlest.hovered', B50)}
		/>
		<rect x="15" y="15" width="16" height="2" rx="1" fill={token('color.link', B400)} />
		<rect x="15" y="11" width="16" height="2" rx="1" fill={token('color.link', B400)} />
		<rect
			x="15"
			y="4"
			width="19"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect
			x="15"
			y="30"
			width="19"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect
			x="15"
			width="16"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect
			x="15"
			y="26"
			width="16"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N80)}
		/>
		<rect x="15" y="19" width="10" height="2" rx="1" fill={token('color.link', B400)} />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.8965 12.8966C5.85003 12.943 5.81316 12.9981 5.788 13.0588C5.76285 13.1195 5.7499 13.1845 5.7499 13.2501C5.7499 13.3158 5.76285 13.3808 5.788 13.4415C5.81316 13.5021 5.85003 13.5572 5.8965 13.6036L7.793 15.5001H3C2.86739 15.5001 2.74021 15.5528 2.64645 15.6466C2.55268 15.7404 2.5 15.8675 2.5 16.0001C2.5 16.1328 2.55268 16.2599 2.64645 16.3537C2.74021 16.4475 2.86739 16.5001 3 16.5001H7.793L5.8965 18.3966C5.85003 18.443 5.81316 18.4981 5.788 18.5588C5.76285 18.6195 5.7499 18.6845 5.7499 18.7501C5.7499 18.8158 5.76285 18.8808 5.788 18.9415C5.81316 19.0021 5.85003 19.0572 5.8965 19.1036C6.0915 19.2986 6.4085 19.2986 6.604 19.1036L9.3535 16.3536C9.44405 16.2634 9.49649 16.1419 9.5 16.0141V15.9856C9.49642 15.858 9.44398 15.7367 9.3535 15.6466L6.604 12.8966C6.55756 12.8502 6.50242 12.8133 6.44172 12.7881C6.38102 12.763 6.31596 12.75 6.25025 12.75C6.18454 12.75 6.11948 12.763 6.05878 12.7881C5.99808 12.8133 5.94294 12.8502 5.8965 12.8966V12.8966Z"
			fill={token('color.icon', N500)}
		/>
	</svg>
);

export const ExcerptIncludeMacroIcon = (props: IconProps) => (
	<Icon
		glyph={ExcerptIncludeMacroIconGlyph}
		size="large"
		secondaryColor={token('color.background.accent.gray.subtler', N30)}
		{...props}
	/>
);
