import type { Render } from "@atlassian/forge-ui-types";
import { elementToForgeDoc } from "../../../../utils/elementToForgeDoc";

const renderActions = (
  render: Render, actions: React.ReactElement | React.ReactElement[]
) => Array.isArray(actions)
    ? actions.map((action: React.ReactElement) => render(elementToForgeDoc(action)))
    : [render(elementToForgeDoc(actions))]

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::0991743f23a7cfeffa013ffb246b2ca9>>
 * @codegenId #section-message
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen section-message
 * @codegenParams { "props": { "children": true, "appearance": true, "title": true, "actions": { "value": "actions ? renderActions(props.render, actions) as React.ReactElement[] : undefined", "comment": "pre-render actions before inserting as props" }, "icon": false, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/section-message/src/types.tsx <<SignedSource::33ed9c413b530d397ed67dfc90f0d312>>
 * @codegenDependency ../../../../../../../design-system/section-message/src/internal/appearance-icon.tsx <<SignedSource::49e8e2faaf92a7e9c70b6660ad26ca6f>>
 * @codegenDependency ../../../../../../../design-system/section-message/src/section-message.tsx <<SignedSource::e76e81b1531828932cbde7f6fcb0f118>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformSectionMessage from '@atlaskit/section-message';

type PlatformSectionMessageProps = React.ComponentProps<typeof PlatformSectionMessage>;

export type SectionMessageProps = Pick<
  PlatformSectionMessageProps,
  'children' | 'appearance' | 'title' | 'actions' | 'testId'
>;

export const SectionMessage = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    title,
    actions,
    testId,
  } = props.forgeDoc.props as SectionMessageProps;
  return (
    <PlatformSectionMessage
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      appearance={appearance}
      title={title}
      // pre-render actions before inserting as props
      actions={actions ? renderActions(props.render, actions) as React.ReactElement[] : undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
