import React, { lazy } from 'react';
import { useUID } from 'react-uid';
import { useMentionResource } from './providers/useMentionResource';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import {
	type FieldChildrenProps,
	type UserPickerProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { PortalConsumer } from '../../../context';
import { gridSize } from '@atlaskit/theme/constants';
import { useUsers, type UserData } from '../../../web-client/hooks/useUser';
import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';

import { type MentionProvider } from '@atlaskit/mention';
import type ApolloClient from 'apollo-client';
import { type UserPickerOption } from '../form/transformFormData';

const BITBUCKET_FIELD_ID = 'BitbucketMentions';

const AKFormField = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.form' */
			'@atlaskit/form/Field'
		),
);
const AKUserPicker = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.user-picker' */
			'@atlaskit/user-picker'
		),
);
const AKSmartUserPicker = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.smart-user-picker' */
			'@atlaskit/smart-user-picker'
		),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);
const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

export type ProductKey = 'jira' | 'confluence' | 'people' | 'compass' | 'bitbucket';

export const isProductKey = (productKey: string): productKey is ProductKey => {
	return (
		productKey === 'confluence' ||
		productKey === 'jira' ||
		productKey === 'people' ||
		productKey === 'compass' ||
		productKey === 'bitbucket'
	);
};

interface Dependencies {
	// We use client to hydrate default values although options
	// are loaded and searches run by smart user picker
	client: ApolloClient<any>;
	mentionProvider?: Promise<MentionProvider>;
	accountId?: string;
	cloudId?: string;
	productKey?: ProductKey;
	baseUrl?: string;
}

const validate = (value: any) =>
	!value || (Array.isArray(value) && value.length === 0) ? 'EMPTY' : undefined;

// Format the values(UserData) returned from useUsers hook, to pass to defaultValue prop
const hydrateDefaultValue = (users: { [accountId: string]: UserData }, isMulti = false) => {
	const defaultValues: UserPickerOption[] = [];

	for (const userId in users) {
		const { name, picture } = users[userId];
		// Only grabbing the values where user name exist
		if (name) {
			defaultValues.push({
				name,
				avatarUrl: picture,
				id: userId,
				type: 'user',
			});
		}
	}
	return isMulti ? defaultValues : defaultValues[0];
};

const UserPicker: React.FunctionComponent<UserPickerProps & Dependencies> = ({
	name,
	label,
	description,
	placeholder,
	isRequired,
	isMulti,
	// Warning: Smart user picker ONLY supports default value hydration
	// of *users*, not groups!
	defaultValue: defaultUsersValue = '',
	includeUsers = true,
	includeGroups = false,
	client,
	mentionProvider,
	accountId: currentAccountId,
	cloudId: suppliedCloudId,
	productKey,
	baseUrl,
}) => {
	const query = useMentionResource(mentionProvider);
	const uid = useUID();

	// Hydrate the default value ourselves, since we are controlling value from <Field>
	// component value. It will get passed into the user picker as simply `value`.
	const accountIds = Array.isArray(defaultUsersValue) ? defaultUsersValue : [defaultUsersValue];
	const usersData = useUsers(accountIds, client);
	const hydratedDefaultValue = hydrateDefaultValue(usersData, isMulti);

	return (
		<PortalConsumer>
			{(portal) => (
				<AKFormField
					id={`${uid}-forge-${suppliedCloudId && productKey ? 'smart-user-picker' : 'user-picker'}`}
					name={name}
					label={label}
					defaultValue={hydratedDefaultValue}
					validate={isRequired ? validate : undefined}
					isRequired={isRequired}
				>
					{({ fieldProps, error }: FieldChildrenProps & { error?: string }) => {
						const userPickerProps = {
							...fieldProps,
							isMulti,
							menuPortalTarget: portal || undefined,
							noOptionsMessage: () => null,
							placeholder,
							width: `${gridSize() * 40}px`,
						};
						return (
							<>
								<FormStateChangeNotifier name={name} value={fieldProps.value} />
								{suppliedCloudId && productKey ? (
									<AKSmartUserPicker
										{...userPickerProps}
										fieldId={
											// Bitbucket User Recommendations does not support generic contextType
											productKey === 'bitbucket' ? BITBUCKET_FIELD_ID : uid
										}
										debounceTime={400}
										includeUsers={includeUsers}
										includeGroups={includeGroups}
										siteId={suppliedCloudId}
										// If principalId === 'Context', upstream attempts to use other context
										// to discover the principal ID
										principalId={currentAccountId || 'Context'}
										productKey={productKey}
										baseUrl={baseUrl}
										inputId={userPickerProps.id}
									/>
								) : (
									<AKUserPicker
										{...userPickerProps}
										loadOptions={query}
										fieldId={null}
										inputId={userPickerProps.id}
									/>
								)}
								{error === 'EMPTY' && (
									<AKErrorMessage>
										<FormattedMessage {...messages.emptyFieldErrorMessage} />
									</AKErrorMessage>
								)}
								{description && <AKHelperMessage>{description}</AKHelperMessage>}
							</>
						);
					}}
				</AKFormField>
			)}
		</PortalConsumer>
	);
};

export default UserPicker;

export const UserPickerFn: React.FunctionComponent<Pick<Props, 'props'> & Dependencies> = ({
	client,
	mentionProvider,
	accountId,
	cloudId,
	productKey,
	baseUrl,
	props,
}) => {
	const {
		name,
		label,
		placeholder,
		description,
		defaultValue,
		isRequired,
		isMulti,
		includeUsers,
		includeGroups,
	} = props as UserPickerProps;
	return (
		<UserPicker
			client={client}
			accountId={accountId}
			cloudId={cloudId}
			productKey={productKey}
			mentionProvider={mentionProvider}
			name={name}
			label={label}
			placeholder={placeholder}
			description={description}
			defaultValue={defaultValue}
			isRequired={isRequired}
			isMulti={isMulti}
			includeUsers={includeUsers}
			includeGroups={includeGroups}
			baseUrl={baseUrl}
		/>
	);
};

// ApolloClient, cloudId and productKey must be passed as props,
// and will not be fetched via context.
export const makeUserPicker = ({
	client,
	mentionProvider,
	accountId,
	cloudId,
	productKey,
	baseUrl,
}: Dependencies) => {
	return function UserPickerNext({ forgeDoc: { props } }: Parameters<RenderFn>[0]) {
		return (
			<UserPickerFn
				props={props}
				client={client}
				mentionProvider={mentionProvider}
				accountId={accountId}
				cloudId={cloudId}
				productKey={productKey}
				baseUrl={baseUrl}
			/>
		);
	};
};

const messages = defineMessages({
	emptyFieldErrorMessage: {
		id: 'confluence.user.picker.empty.field.error.message',
		defaultMessage: 'This field is required.',
		description: 'This message is displayed when a required text field is empty.',
	},
});
