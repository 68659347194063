import { query } from '@confluence/query-preloader-tools';

import { ViewSpaceExternalCollaboratorsDialogQuery } from './graphql/ViewSpaceExternalCollaboratorsDialogQuery.graphql';

export function preloadSpaceGuestList(spaceKey: string) {
	return query({
		query: ViewSpaceExternalCollaboratorsDialogQuery,
		variables: {
			spaceKey,
		},
	});
}
