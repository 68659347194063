import { differenceInMonths, addMonths, differenceInDays, addDays } from 'date-fns';

export const getAdjustedStartDate = ({
	startDate,
	endDate,
}: {
	startDate: Date;
	endDate: Date;
}) => {
	// Calculate the number of full months between startDate and endDate
	let newStartDate = startDate;
	const monthDifference = differenceInMonths(endDate, startDate);
	// Adjust startDate if it's more than 12 months before endDate
	if (monthDifference > 12) {
		const monthsBeyondLimit = monthDifference - 12;
		newStartDate = addMonths(startDate, monthsBeyondLimit);
	}
	// Calculate the day difference to ensure it's within 12 months
	const dayDifference = differenceInDays(endDate, newStartDate);
	// Adjust the startDate to ensure it's exactly 12 months or less to the day
	if (dayDifference > 365) {
		newStartDate = addDays(newStartDate, dayDifference - 365);
	}
	return newStartDate;
};
