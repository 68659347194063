import {
	type BaseMetricDataWithStartAndStop,
	type BasePageLoadMetricDataWithStartAndStop,
	PageVisibleState,
	PageVisibleValueOrigin,
	type PerformanceEventConfig,
	type ShareableGlobalConfig,
} from '../../types';

export const pageVisibleValue = (
	_config: PerformanceEventConfig,
	data: BaseMetricDataWithStartAndStop | BasePageLoadMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
) => {
	const value =
		globalConfig.pageVisibleValueOrigin === PageVisibleValueOrigin.documentHidden
			? !document.hidden || false
			: data.pageVisibleState === PageVisibleState.VISIBLE;
	return {
		'pageVisible:value': value,
	};
};
