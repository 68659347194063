import type { UserData } from '@atlassiansox/cross-flow-plugins';
import { createGetUsersPlugin, UserIdTypes } from '@atlassiansox/cross-flow-plugins';

import { cfetch } from '@confluence/network';

interface InstanceUser {
	displayName: string;
	id: string;
	userName: string;
}

type FetchInstanceUsersResponse = InstanceUser[];

const getUsers = async (containerId: string): Promise<UserData[]> => {
	const getUsersUrl = `/gateway/api/xflow/${containerId}/fetch-instance-users`;

	try {
		const response = await cfetch(getUsersUrl, {
			credentials: 'same-origin',
		});

		if (response.ok) {
			const data: FetchInstanceUsersResponse = await response.json();

			return data.map<UserData>((value) => ({
				idType: UserIdTypes.ATLASSIAN_ACCOUNT_ID,
				id: value.id,
				displayName: value.displayName,
			}));
		}

		throw new Error(
			`Unexpected response from ${getUsersUrl} ${response.status} ${response.statusText}`,
		);
	} catch (error) {
		throw new Error(`Unable to fetch ${getUsersUrl} ${error.message}`);
	}
};

export const getUsersPlugin = createGetUsersPlugin(getUsers);
