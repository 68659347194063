import type { VFC } from 'react';
import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';

import {
	getExperienceName,
	MacroExperienceSuccess,
	getMacroAttributesFromADFNode,
} from '@confluence/macro-tracker';
import { mustPreloadWhenRenderingSPA, MustPreloadLoadableNames } from '@confluence/loadable';

import type { RendererExtensionHandlerProps } from './ExcerptExtensionHandler';
import { convertRendererProps } from './utils';

export const ExpandComponentShadow = () => (
	<div
		style={{
			padding: `${token('space.100', '8px')} ${token('space.0', '0')} ${token(
				'space.0',
				'0',
			)} ${token('space.200', '16px')}`,
		}}
	>
		<span
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'inline-block',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				borderRadius: 3,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				backgroundColor: token('elevation.surface', '#F4F5F7'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: token('space.200', '16px'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: token('space.200', '16px'),
				margin: `${token('space.025', '2px')} ${token(
					'space.200',
					'16px',
				)} ${token('space.025', '2px')}
        ${token('space.0', '0')}`,
			}}
		/>
		<span
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'inline-block',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				borderRadius: 3,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				backgroundColor: token('elevation.surface', '#F4F5F7'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: 120,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: 20,
			}}
		/>
	</div>
);

const EXPANDED_STYLES: any = {
	transition: 'opacity 0.5s',
	height: 'auto',
	opacity: 1,
	paddingLeft: token('space.600', '48px'),
};

const COLLAPSED_STYLES: any = {
	transition: '0s',
	height: token('space.0', '0'),
	opacity: 0,
	overflow: 'hidden',
	paddingLeft: token('space.600', '48px'),
	pointerEvents: 'none',
};

export const ExpandExtensionHandler: VFC<RendererExtensionHandlerProps> = ({
	contentId,
	extensionKey,
	mode,
	node,
	appearance,
	showTemplateVariablesInPreview,
	showTemplateInputInPreview,
	Renderer,
	isLegacyRenderer,
	getExtensionHandlers,
	annotationProvider,
}) => {
	mustPreloadWhenRenderingSPA([MustPreloadLoadableNames.ClassicRendererLoader]);

	const [isExpanded, setExpanded] = useState(false);
	const toggleExpand = useCallback(() => {
		setExpanded((expanded) => !expanded);
	}, []);

	useEffect(() => {
		if (isExpanded) {
			window.dispatchEvent(new Event('resize'));
		}
	}, [isExpanded]);

	const attributes = getMacroAttributesFromADFNode(node);
	const document = { type: 'doc', version: 1, content: node?.content };
	const title = node?.parameters?.macroParams?.title;

	// ADFRenderer (next-based) requires extension handlers during rendering on server side,
	// for extensions like this as they use renderer internally. Handlers aren't needed with
	// legacy renderer because legacy component cooks them internally.
	const extensionHandlers =
		getExtensionHandlers && !isLegacyRenderer
			? getExtensionHandlers({
					adf: document,
					contentId,
					appearance,
					showTemplateInputInPreview,
					showTemplateVariablesInPreview,
				})
			: undefined;

	return (
		<Fragment>
			<Button
				appearance="link"
				iconBefore={
					<div
						style={{
							transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							transition: '0.2s',
						}}
					>
						<ChevronRightIcon label="" />
					</div>
				}
				onClick={toggleExpand}
			>
				{title !== undefined ? (
					title
				) : (
					<FormattedMessage
						id="content-renderer-extension-handlers.expand-macro.default-title"
						defaultMessage="Click here to expand..."
						description="Shows a link that will open the expander to show the rest of the content"
					/>
				)}
			</Button>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<div style={isExpanded ? EXPANDED_STYLES : COLLAPSED_STYLES}>
				<Renderer
					{...convertRendererProps(isLegacyRenderer, { contentId, document })}
					extensionHandlers={extensionHandlers}
					showTemplateInputInPreview={showTemplateInputInPreview}
					showTemplateVariablesInPreview={showTemplateVariablesInPreview}
					annotationProvider={annotationProvider}
					allowAnnotations={!!annotationProvider}
				/>
			</div>
			<MacroExperienceSuccess
				name={getExperienceName(mode, node)}
				contentId={contentId}
				extensionKey={extensionKey}
				mode={mode}
				attributes={attributes}
			/>
		</Fragment>
	);
};
