/* eslint-disable @atlaskit/ui-styling-standard/use-compiled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */

import { css } from '@emotion/react';

import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const aiPrismColor = {
	['prism.border.step.1']: {
		light: '#0065FF',
		dark: '#0065FF80',
	},
	['prism.border.step.2']: {
		light: '#0469FF',
		dark: '#0469FF80',
	},
	['prism.border.step.3']: {
		light: '#BF63F3',
		dark: '#BF63F380',
	},
	['prism.border.step.4']: {
		light: '#FFA900',
		dark: '#FFA90080',
	},
};

export const prismBackground = (colorMode?: 'light' | 'dark') =>
	css({
		background: `conic-gradient(
			from var(--panel-gradient-angle, 270deg),
			${aiPrismColor['prism.border.step.1'][colorMode ?? 'light']} 0%,
			${aiPrismColor['prism.border.step.2'][colorMode ?? 'light']} 20%,
			${aiPrismColor['prism.border.step.3'][colorMode ?? 'light']} 50%,
			${aiPrismColor['prism.border.step.4'][colorMode ?? 'light']} 56%,
			${aiPrismColor['prism.border.step.1'][colorMode ?? 'light']} 100%
		)`,
		borderRadius: `calc(${token('border.radius.100', '3px')} + ${token('border.width', '1px')})`,
		padding: token('border.width', '1px'),
	});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const aiPanel = css({
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius.100', '3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const aiPanelContainer = css({
	padding: token('space.200', '16px'),
});
