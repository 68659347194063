import Cookies, { type CookieAttributes } from 'js-cookie';

import { fg } from '@atlaskit/platform-feature-flags';

import { getBscGlobalState, sendPackageOperationalEvent } from '../../../common/utils';
import { isProcessingAllowedByPreferences } from '../../../common/utils/item-processing';
import { Logger } from '../../../common/utils/logger';
import { CategoryStorageType, Status } from '../../../types';
export type { CookieAttributes as JSCookieAttributes };

/**
 * Sets a cookie asynchronously. This queries user preferences before setting
 * the cookie, so it must be used for any cookies which are not strictly
 * necessary.
 */
export const setCookie = async (
	key: string,
	value: string,
	attributes?: CookieAttributes,
): Promise<string> => {
	if (fg('platform_moonjelly_bsc_master_killswitch')) {
		// Set the cookie without checking user preferences
		// when master killswitch is enabled
		Cookies.set(key, value, { ...attributes, 'atl-set-cookie': true });
		return Status.SUCCESS;
	}

	// user belongs to an org that opted into cookies management and we should respect their preferences

	const productName = getBscGlobalState().product;

	// Flag enabled, user belongs to an org that opted into cookies management and we should respect their preferences
	const shouldSetCookie = await isProcessingAllowedByPreferences({
		itemKey: key,
		storageType: CategoryStorageType.Cookies,
		productName,
	});

	const eventAttributes = {
		wasRejected: !shouldSetCookie,
		cookieKey: key,
	};
	try {
		sendPackageOperationalEvent({
			action: 'usedSetCookie',
			attributes: eventAttributes,
		});

		if (shouldSetCookie) {
			Cookies.set(key, value, { ...attributes, 'atl-set-cookie': true });
			return Status.SUCCESS;
		} else {
			return Status.BLOCKED;
		}
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'usedSetCookieError',
			attributes: eventAttributes,
			message: `Failed to use set cookie. ${e.message || ''}`,
		});
		return Status.BLOCKED;
	}
};
