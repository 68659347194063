import type { CSSProperties } from '@emotion/serialize';
import type * as CSS from 'csstype';
import type { MediaQuery } from '@atlaskit/primitives';
import { tokensMap } from '@atlaskit/primitives';

type TokensMap = typeof tokensMap;
type TokensMapPropKey = keyof TokensMap;

type TokenizedProps = {
	[K in TokensMapPropKey]?: keyof TokensMap[K];
};

// LiteralUnion technique
type RawCSSValue = string & {};

type RelaxedTokenizedProps = {
	[K in TokensMapPropKey]?: keyof TokensMap[K] | RawCSSValue;
};

type AllMedia =
	| MediaQuery
	| '@media screen and (forced-colors: active), screen and (-ms-high-contrast: active)'
	| '@media (prefers-color-scheme: dark)'
	| '@media (prefers-color-scheme: light)'
	| '@media (prefers-reduced-motion: reduce)';

type StandardCSSProps = Omit<CSSProperties, TokensMapPropKey>;

type RestrictedPropsSpec = RelaxedTokenizedProps & StandardCSSProps;

type SafeCSSObject<
	SupportedPropKeys extends keyof CSSProperties = keyof CSSProperties,
	RawCSSPropKeys extends SupportedPropKeys = SupportedPropKeys,
	RestrictedProps extends RestrictedPropsSpec = RestrictedPropsSpec,
> = {
	// media query without nested media query
	[MQ in AllMedia]?: Omit<
		SafeCSSObject<SupportedPropKeys, RawCSSPropKeys, RestrictedPropsSpec>,
		AllMedia
	>;
} & {
	// pseudo without nested pseudo or media query
	[Pseudo in CSS.Pseudos]?: Omit<
		SafeCSSObject<SupportedPropKeys, RawCSSPropKeys, RestrictedPropsSpec>,
		CSS.Pseudos | AllMedia
	>;
} & Pick<
		// set token values for all the tokenized props for `prop: true`
		TokenizedProps,
		Exclude<Extract<SupportedPropKeys, TokensMapPropKey>, RawCSSPropKeys | keyof RestrictedProps>
	> &
	Pick<
		// set standard css prop values for all the none-tokenized props for `prop: true`
		StandardCSSProps,
		Exclude<
			Extract<SupportedPropKeys, keyof StandardCSSProps>,
			RawCSSPropKeys | keyof RestrictedProps
		>
	> & // force standard css prop values for allowCSS: true
	Pick<CSSProperties, Extract<RawCSSPropKeys, keyof CSSProperties>> &
	RestrictedProps; // use configured prop value mapping for `prop: { supportedValues: [...] }`

const safeSelectors = /^@media .*$|^::?.*$|^@supports .*$/;

type PropValidator = <T extends string>(value: T) => boolean;

const REX_CSS_SIMPLE_STRING_PROP_VALUE = /^[a-zA-Z-]+$/;
const REX_CSS_WIDTH_PROP_VALUE = /^-?\d+(\.\d+)?(px|em|rem|%)?$/;

// Note: we currently support only free formed size related props (e.g. width).
//       the validation logic should be extended to support other props in the future.
const defaultPropValidator: PropValidator = (rawValue) => {
	const value = `${rawValue ?? ''}`.trim();
	return REX_CSS_SIMPLE_STRING_PROP_VALUE.test(value) || REX_CSS_WIDTH_PROP_VALUE.test(value);
};

const xcssValidateAndCleanup = <
	SupportedPropKeys extends keyof CSSProperties,
	RawCSSPropKeys extends SupportedPropKeys,
	RestrictedProps extends RestrictedPropsSpec,
>(
	styleObj: SafeCSSObject,
	supportedXCSSPropsSpec: Record<SupportedPropKeys, PropValidator>,
	isRoot: boolean = true,
): SafeCSSObject<SupportedPropKeys, RawCSSPropKeys, RestrictedProps> => {
	return Object.entries(styleObj).reduce(
		(targetStyleObj, [key, value]) => {
			if (typeof value === 'object' && safeSelectors.test(key) && isRoot) {
				// handle the case where the value is a media query or pseudo selector
				const cleanedValue = xcssValidateAndCleanup<
					SupportedPropKeys,
					RawCSSPropKeys,
					RestrictedProps
				>(value as SafeCSSObject, supportedXCSSPropsSpec, false);
				if (cleanedValue && Object.keys(cleanedValue).length > 0) {
					targetStyleObj[key] = cleanedValue;
				}
			} else if (value) {
				if (supportedXCSSPropsSpec[key as SupportedPropKeys]?.(value as string)) {
					// handle the case where the value is a design token value of a token prop
					// or a string value of a standard css prop that passes the validator function
					// if defined.
					targetStyleObj[key] = value;
				} else {
					// eslint-disable-next-line no-console
					console.warn(`Unexpected XCSS property provided: ${key}: ${value}`);
				}
			} else {
				// in case the prop is not supported.
				// eslint-disable-next-line no-console
				console.warn(`Unsupported XCSS property provided: ${key}: ${value}`);
			}

			return targetStyleObj;
		},
		{} as SafeCSSObject & Record<string, unknown>,
	) as SafeCSSObject<SupportedPropKeys, RawCSSPropKeys, RestrictedProps>;
};

type XCSSValidatorParam = {
	[key in keyof CSSProperties]:
		| true
		| {
				supportedValues: Array<RestrictedPropsSpec[key]>;
		  }
		| {
				allowCSS: true;
		  };
};

type XCSSValidatorConfig = NonNullable<XCSSValidatorParam[keyof CSSProperties]>;

const isSupportedValuesConfig = (
	config: XCSSValidatorConfig,
): config is { supportedValues: Array<CSSProperties[keyof CSSProperties]> } => {
	return config !== true && 'supportedValues' in config && Array.isArray(config.supportedValues);
};

/**
 *
 * @param supportedXCSSProps - the list of css props to be supported for the intended component.
 *    If not provided, all the props will be supported. The props could be either standard css props
 *    or design token based props. If the prop is a design token based prop, the value of the prop
 *    will be validated against the design tokens map to ensure the value is a valid design token string.
 * @returns a function that takes a style object and returns a style object with only the supported props
 *    as specified in the supportedXCSSProps list. The props that are not supported will be removed from the
 *    returned style object and a warning will be logged in the console.
 */
const makeXCSSValidator = <U extends XCSSValidatorParam>(supportedXCSSProps: U) => {
	type SupportedPropKeys = Extract<keyof U, keyof CSSProperties>;
	type RawCSSPropKeys = Extract<
		{
			[K in SupportedPropKeys]: U[K] extends { allowCSS: true } ? K : never;
		}[SupportedPropKeys],
		SupportedPropKeys
	>;
	type RestrictedPropKeys = Extract<
		{
			[K in SupportedPropKeys]: U[K] extends {
				supportedValues: Array<RestrictedPropsSpec[K]>;
			}
				? K
				: never;
		}[SupportedPropKeys],
		SupportedPropKeys
	>;
	type RestrictedProps = {
		[K in RestrictedPropKeys]?: U[K] extends { supportedValues: infer V }
			? Exclude<V[keyof V], number | Function>
			: never;
	};
	type ExpectedReturnXCSSPropType = SafeCSSObject<
		SupportedPropKeys,
		RawCSSPropKeys,
		RestrictedProps
	>;

	const supportedXCSSPropsSpec = Object.entries(supportedXCSSProps).reduce(
		(acc, [prop, config]) => {
			let validator: PropValidator | undefined;
			if (config === true) {
				const tokensMapStyle = tokensMap[prop as TokensMapPropKey];
				if (tokensMapStyle) {
					const tokenSet = new Set(Object.keys(tokensMapStyle));
					validator = (value: string) => tokenSet.has(value);
				} else {
					throw new Error('invalid validation config applied to token only props');
				}
			} else if (isSupportedValuesConfig(config)) {
				const supportedValues = new Set(config.supportedValues);
				validator = (value: string) => supportedValues.has(value);
			} else if (config?.allowCSS === true) {
				validator = defaultPropValidator;
			}
			if (validator) {
				acc[prop as SupportedPropKeys] = validator;
			}
			return acc;
		},
		{} as Record<SupportedPropKeys, PropValidator>,
	);

	return (styleObj: SafeCSSObject | ExpectedReturnXCSSPropType): ExpectedReturnXCSSPropType =>
		xcssValidateAndCleanup(styleObj, supportedXCSSPropsSpec);
};

export { makeXCSSValidator };

export type { SafeCSSObject };
