import React, { memo } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Flex } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar/Avatar';
import Image from '@atlaskit/image';
import FileIcon from '@atlaskit/icon/glyph/file';
import { N300, N0 } from '@atlaskit/theme/colors';

import type { IconSize } from './types';
import { TemplateLegacyIcon } from './TemplateLegacyIcon';
import { DefaultIcon as DefaultIconWrapper } from './TemplateIconStyles';
import { iconStyles } from './TemplateIconConstants';

export type TemplateIconTemplate = {
	iconURL: string | null;
	darkModeIconURL?: string | null;
	styleClass: string | null;
	itemModuleCompleteKey: string | null;
	blueprintModuleCompleteKey?: string | null;
	contentBlueprintId?: string | null;
};

type TemplateIconProps = {
	template: TemplateIconTemplate;
	isDisabled?: boolean;
	size?: IconSize;
};

type InjectedTemplateIconProps = TemplateIconProps;

// @todo CTE-166 finalize icons once they are implemented on the BE
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const StyledImageWrapper = styled(Flex)(iconStyles);

const i18n = defineMessages({
	label: {
		id: 'template-card.icon.label',
		defaultMessage: 'default template icon',
		description: 'String to use as the aria-label for the icon',
	},
});

const InjectedTemplateIcon = ({
	template,
	isDisabled = false,
	size = 'medium',
}: InjectedTemplateIconProps) => {
	const intl = useIntl();
	if (template.iconURL) {
		return (
			<IconWrapper data-testid="template-icon-avatar">
				<Avatar appearance="square" size={size} isDisabled={isDisabled}>
					{({ ref, children, ...props }) => (
						<StyledImageWrapper>
							<Image
								{...props}
								src={template.iconURL || ''}
								srcDark={template.darkModeIconURL || ''}
							/>
						</StyledImageWrapper>
					)}
				</Avatar>
			</IconWrapper>
		);
	} else if (template.styleClass) {
		return <TemplateLegacyIcon size={size} template={template} />;
	} else {
		// Ideally, this code will never be used. However, if the BE response is missing an iconURL for some reason,
		// this will return an icon with a grey background color that looks like the icon for 3rd party templates.
		return (
			<DefaultIconWrapper
				backgroundColor={token('color.background.accent.gray.bolder', N300)}
				size={size}
				data-testid="template-icon-default"
			>
				<FileIcon
					primaryColor={token('color.icon.inverse', N0)}
					label={intl.formatMessage(i18n.label)}
				/>
			</DefaultIconWrapper>
		);
	}
};

// The avatar-item component, which actually renders TemplateIcon, passes a backgroundColor
// prop to the TemplateIcon. The TemplateIcon and InjectedTemplateIcon disregard this backgroundColor
// prop. Therefore, we can ignore the backgroundColor prop for memoization.
const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.template === nextProps.template &&
		prevProps.isDisabled === nextProps.isDisabled &&
		prevProps.size === nextProps.size
	);
};

export const TemplateIcon = memo(InjectedTemplateIcon, areEqual);
