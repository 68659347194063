import { startBrowserMetricsPageLoad } from '@confluence/browser-metrics';
import { ExperienceActionMeasures } from '@confluence/action-measures';
import {
	EDIT_BLOG,
	EDIT_PAGE,
	EDIT_BLOG_V2,
	EDIT_PAGE_V2,
	EDIT_PAGE_EMBED,
} from '@confluence/named-routes';

export type RouteDetailsParams = {
	isBlogpost: boolean;
	isFabricSupported: boolean;
	isEmbeddedContent: boolean;
};

const getRouteType = ({ isBlogpost, isFabricSupported, isEmbeddedContent }: RouteDetailsParams) => {
	// Currently a temporary check for blogs is added here until Embedded Pages supports blogs.
	if (isEmbeddedContent && !isBlogpost) {
		return EDIT_PAGE_EMBED;
	}

	if (isFabricSupported) {
		return isBlogpost ? EDIT_BLOG_V2 : EDIT_PAGE_V2;
	} else {
		return isBlogpost ? EDIT_BLOG : EDIT_PAGE;
	}
};

export const startPageLoadForEditor = (
	routeDetails: RouteDetailsParams,
	transitionId: any,
	contentId?: string,
) => {
	const routeType = getRouteType(routeDetails);

	// Need to clear measures every time we start measuring. Otherwise initial measurements
	// will keep being used for all entry points.
	ExperienceActionMeasures.clearMeasures();

	if (routeType.name) {
		// transitionId cant be 0 since 0 is only for initial page load.
		startBrowserMetricsPageLoad(
			routeType.name,
			routeType.ufoName,
			(transitionId || 0) + 1,
			contentId,
		);
	}
};
