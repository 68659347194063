import { GuardPolicy, StoreType } from './constants';
import { AbandonWriteError } from './errors';
import { type BulkAddItemResult, type ItemWrapperType } from './types';

export type MemoryItemHandlers<T> = {
	addItems: (itemsToAdd: ItemWrapperType<T>[]) => void;
	getItemCount: () => Promise<number>;
	evictEventsIfNeeded: (eventLimit: number) => number;
};

export default class MemoryDbEventCountGuard<T> {
	private eventLimit: number;
	private memoryItemHandlers: MemoryItemHandlers<T>;

	constructor(eventLimit: number, memoryItemHandlers: MemoryItemHandlers<T>) {
		if (eventLimit > 0) {
			this.eventLimit = eventLimit;
		} else {
			throw Error('Event Limit has to be set higher than 1');
		}

		this.memoryItemHandlers = memoryItemHandlers;
	}

	/**
	 * Unlike IndexedDb, we cannot take advantage of any index. So we are,
	 * treating the memory store as a sorted set when adding items.
	 */
	insertItemsToMemoryStore = (
		itemsToAdd: ItemWrapperType<T>[],
		policy: GuardPolicy,
	): Promise<BulkAddItemResult<T>> => {
		switch (policy) {
			case GuardPolicy.ABANDON:
				return this.handleAbandonIfLimitWillExceedPolicy(itemsToAdd);
			case GuardPolicy.EVICT:
				return Promise.resolve(this.handleEvictOldestIfLimitExceededPolicy(itemsToAdd));
			case GuardPolicy.IGNORE:
				return this.handleAddAsManyAsPossible(itemsToAdd);
		}
	};

	private async handleAbandonIfLimitWillExceedPolicy(
		itemsToAdd: ItemWrapperType<T>[],
	): Promise<BulkAddItemResult<T>> {
		const itemCount = await this.memoryItemHandlers.getItemCount();
		if (itemCount + itemsToAdd.length > this.eventLimit) {
			throw new AbandonWriteError(StoreType.MEMORY);
		}
		this.memoryItemHandlers.addItems(itemsToAdd);
		return {
			items: itemsToAdd,
			numberOfEvictedItems: 0,
		};
	}

	private handleEvictOldestIfLimitExceededPolicy(
		itemsToAdd: ItemWrapperType<T>[],
	): BulkAddItemResult<T> {
		this.memoryItemHandlers.addItems(itemsToAdd);
		const numberOfEvictedItems = this.memoryItemHandlers.evictEventsIfNeeded(this.eventLimit);
		return {
			items: itemsToAdd,
			numberOfEvictedItems,
		};
	}

	private async handleAddAsManyAsPossible(
		itemsToAdd: ItemWrapperType<T>[],
	): Promise<BulkAddItemResult<T>> {
		const freeSpace = this.eventLimit - (await this.memoryItemHandlers.getItemCount());
		const partialEventsToAdd =
			freeSpace > itemsToAdd.length ? itemsToAdd : itemsToAdd.slice(0, freeSpace);
		this.memoryItemHandlers.addItems(partialEventsToAdd);
		return {
			items: partialEventsToAdd,
			numberOfEvictedItems: 0,
		};
	}
}
