/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

import { useAIThemeColor } from '@atlassian/generative-ai-modal/styles/theme';

export const Border = () => {
	const aiThemeColor = useAIThemeColor();
	return (
		<>
			<stop stopColor={aiThemeColor('prism.border.step.1')} />
			<stop offset="0.25" stopColor={aiThemeColor('prism.border.step.1')} />
			<stop offset="0.41" stopColor={aiThemeColor('prism.border.step.2')} />
			<stop offset="0.73" stopColor={aiThemeColor('prism.border.step.3')} />
			<stop offset="0.86" stopColor={aiThemeColor('prism.border.step.4')} />
			<stop offset="1" stopColor={aiThemeColor('prism.border.step.4')} />
		</>
	);
};
