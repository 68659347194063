import { type SerializableRecord } from '@post-office/serializable-record';

import type { DataState, ErrorState, LoadingState } from './types';

export const resStatusAsString = ({ status, statusText }: Response) =>
	JSON.stringify({ status, statusText });

export const assertResponseIsOk = (res: Response) => {
	if (res.ok) return res;

	throw new Error(resStatusAsString(res));
};

export const toLoading = (): LoadingState => ({
	isLoading: true,
	data: undefined,
	error: undefined,
});

export const toError = (error: unknown): ErrorState => ({
	isLoading: false,
	error: error instanceof Error ? error : Error(String(error)),
	data: undefined,
});

export const toData = <Data extends SerializableRecord>(data: Data): DataState<Data> => ({
	isLoading: false,
	error: undefined,
	data,
});
