import { type Logger } from '../resiliencedb';

import PullBatchableQueue from './PullBatchableQueue';
import {
	type BatchableQueue,
	type BatchFlushCallback,
	type Callback,
	ResilienceMechanism,
	type RetryQueueOptions,
} from './types';

export type { BatchableQueue, BatchFlushCallback, Callback, RetryQueueOptions };

// Enum isnt just a type, its also an object that needs to be
// exported as an object.
export { ResilienceMechanism };

export default <T extends object>(
	retryQueuePrefix: string,
	product: string,
	options?: RetryQueueOptions,
	logger?: Logger,
): BatchableQueue<T> => {
	return new PullBatchableQueue(retryQueuePrefix, product, options || {}, logger);
};
