import { createContext, useContext } from 'react';

import {
	API_ORIGIN_LOCAL,
	API_PATHNAME_LOCAL,
	API_PATHNAME_STG_PROD,
	REMOTE_MODULE_URI_LOCAL,
	REMOTE_MODULE_URI_PROD,
	REMOTE_MODULE_URI_STG,
} from './constants';
import type { PostOfficeEnvironmentValues } from './types';

export const defaultEnvironmentValues: PostOfficeEnvironmentValues = {
	currentEnv: 'local',
	envConfigs: {
		test: {
			remoteModule: {
				origin: REMOTE_MODULE_URI_LOCAL,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: API_ORIGIN_LOCAL,
				pathname: API_PATHNAME_LOCAL,
				searchParams: undefined,
			},
		},
		local: {
			remoteModule: {
				origin: REMOTE_MODULE_URI_LOCAL,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: API_ORIGIN_LOCAL,
				pathname: API_PATHNAME_LOCAL,
				searchParams: undefined,
			},
		},
		dev: {
			remoteModule: {
				origin: REMOTE_MODULE_URI_LOCAL,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: API_ORIGIN_LOCAL,
				pathname: API_PATHNAME_LOCAL,
				searchParams: undefined,
			},
		},
		staging: {
			remoteModule: {
				origin: REMOTE_MODULE_URI_STG,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: API_PATHNAME_STG_PROD,
				searchParams: undefined,
			},
		},
		'stg-fedm-east': {
			remoteModule: {
				origin: REMOTE_MODULE_URI_STG,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: API_PATHNAME_STG_PROD,
				searchParams: undefined,
			},
		},
		production: {
			remoteModule: {
				origin: REMOTE_MODULE_URI_PROD,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: API_PATHNAME_STG_PROD,
				searchParams: undefined,
			},
		},
		'prod-fedm-east': {
			remoteModule: {
				origin: REMOTE_MODULE_URI_PROD,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: API_PATHNAME_STG_PROD,
				searchParams: undefined,
			},
		},
	},
};

export const PostOfficeEnvironment =
	createContext<PostOfficeEnvironmentValues>(defaultEnvironmentValues);

export const PostOfficeEnvironmentProvider = PostOfficeEnvironment.Provider;

export const usePostOfficeEnvironment = () => useContext(PostOfficeEnvironment);
