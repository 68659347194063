import React from 'react';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import type { RouteMatch } from '@confluence/route';
import { VIEW_PAGE, VIEW_BLOG_DATE_LEGACY, VIEW_BLOG } from '@confluence/named-routes';
import { shouldShowMobileWeb } from '@confluence/mobile-detection';
import { useIsEmbeddedConfluence_DO_NOT_USE } from '@confluence/embedded-confluence-check';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import {
	CONTAINER_BLOG_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';

import { ViewPageRouteComponentLoader } from './ViewPageRouteComponentLoader';
import { ViewPageLiteRoute } from './ViewPageLiteRoute';

function isLite(query: any) {
	return Boolean(query?.lite);
}

export const ViewPageOrBlogRoute = (props: RouteMatch) => {
	const isMobile = shouldShowMobileWeb();
	const isEmbeddedConfluence = useIsEmbeddedConfluence_DO_NOT_USE();

	const { name, params, query } = props;
	const { contentId, spaceKey } = params;
	const isViewPage = name === VIEW_PAGE.name;

	if (isLite(query)) {
		return <ViewPageLiteRoute {...props} />;
	}

	return (
		<>
			<ViewPageRouteComponentLoader
				spaceKey={spaceKey}
				contentId={contentId}
				isMobile={isMobile}
				isPage={isViewPage}
				isEmbeddedConfluence={isEmbeddedConfluence}
			/>
		</>
	);
};

ViewPageOrBlogRoute.NAVIGATION_PARAMS = ({ name, params, query }: RouteMatch) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isEmbeddedConfluence = useIsEmbeddedConfluence_DO_NOT_USE();
	const showPremiumOnboarding = !isEmbeddedConfluence;

	const { contentId, spaceKey, contentSlug } = params;
	const isViewBlog = name === VIEW_BLOG_DATE_LEGACY.name || name === VIEW_BLOG.name;
	const contentType = isViewBlog ? 'blogpost' : 'page';

	if (isLite(query)) {
		return false;
	}

	return {
		Screen: {
			screenEvent: {
				name: 'viewPageScreen',
				id: contentId,
				attributes: {
					isPublicView: false,
					pageLoadInfo: getUniquePageLoadId(),
				},
				objectType: contentType,
				objectId: contentId,
			},
			pageState: {
				routeName: name,
				spaceKey,
				contentId,
				contentType,
				contentSlug,
			},
			showPremiumOnboarding,
		},
		MainLayout: {
			navView: isViewBlog ? CONTAINER_BLOG_ID : CONTAINER_HOME_ID,
			spaceKey,
			editorPreloader: getEditorPreloader,
			isViewPage: true,
		},
	};
};
