import { buildBasePath, usePostOfficeEnvironment } from '@atlassian/post-office-context';
import { useMemo } from 'react';

export const useBuildBasePath = () => {
	const postOfficeEnvironment = usePostOfficeEnvironment();

	return useMemo(
		() => buildBasePath(postOfficeEnvironment.currentEnv, postOfficeEnvironment.envConfigs),
		[postOfficeEnvironment.currentEnv, postOfficeEnvironment.envConfigs],
	);
};
