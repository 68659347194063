import { B500, N0, N20, N30, N50, N500, N60, N800, N90 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const bgColor = token('elevation.surface.overlay', N0);

export const headerBgColor = token('color.background.brand.bold', B500);

export const teamHeaderBgColor = token('color.background.neutral', N50);

export const headerBgColorDisabledUser = token('color.background.disabled', N30);

export const headerTextColor = token('color.text.inverse', N0);

export const headerTextColorInactive = token('color.text', N800);

export const appLabelBgColor = token('color.background.neutral', N20);

export const appLabelTextColor = token('color.text', N500);

export const labelTextColor = token('color.text', N800);

export const labelIconColor = token('color.text.subtlest', N60);

export const errorIconColor = token('color.text.disabled', N90);

export const errorTitleColor = token('color.text', N800);

export const errorTextColor = token('color.text.subtlest', N90);
