import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import {
	LazyTeamCalendarSiteSettingsPage,
	ADMIN_TEAM_CALENDARS_ANALYTICS_SOURCE,
} from '@confluence/team-calendar';

export const AdminTeamCalendarsRoute = () => {
	const { edition } = useSessionData();

	if (edition !== ConfluenceEdition.PREMIUM) {
		return null;
	}

	return <LazyTeamCalendarSiteSettingsPage />;
};

AdminTeamCalendarsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_TEAM_CALENDARS_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
