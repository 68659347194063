/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { type HeadingProps, type RenderFn } from '@atlassian/forge-ui-types';

// Set defaults based on https://atlassian.design/foundations/typography
const largeStyle = css({
	fontSize: '29px',
	fontWeight: 600,
	lineHeight: '32px',
});
const mediumStyle = css({
	fontSize: '20px',
	fontWeight: 500,
	lineHeight: '24px',
});
const smallStyle = css({
	fontSize: '14px',
	fontWeight: 600,
	lineHeight: '16px',
});

export const Heading = (props: Parameters<RenderFn>[0]) => {
	const { forgeDoc, render } = props;
	const { size } = forgeDoc.props as HeadingProps;

	if (size === 'large') {
		return <h1 css={largeStyle}>{forgeDoc.children.map(render)}</h1>;
	}
	if (size === 'small') {
		return <h3 css={smallStyle}>{forgeDoc.children.map(render)}</h3>;
	}
	// size === 'medium'
	return <h2 css={mediumStyle}>{forgeDoc.children.map(render)}</h2>;
};
