import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

export const dataIdFromObject = (object: any) => {
	if (object.__typename === 'WebItem') {
		return `${object.completeKey || object.id}; ${object.url}`;
	} else if (object.__typename === 'OnboardingState') {
		return object.key;
	} else if (object.__typename === 'WebSection') {
		return object.cacheKey || object.id;
	} else if (object.__typename === 'DetailsSummaryLines') {
		return `${object.macroId}_${object.currentPage}`;
	} else if (object.__typename === 'DetailLine') {
		return `${object.id}_${object.rowId}_${object.macroId}`;
	} else if (object.__typename === 'ConfluenceSystemTemplate') {
		return object.moduleCompleteKey;
	} else if (object.id === null) {
		return;
	}

	return defaultDataIdFromObject(object);
};
