// @ts-ignore
import crypto from 'crypto';

function hash(algorithm: 'sha256' | 'sha1', data: any, asBytes: boolean = false) {
	const hash = crypto.createHash(algorithm);
	hash.update(data);
	if (asBytes) {
		return Array.from(hash.digest());
	}
	return hash.digest('hex');
}

export function hashData(data: any) {
	return hash('sha256', data) as string;
}

export function hashDataAsByte(data: any) {
	return hash('sha256', data, true) as number[];
}

// @deprecated USE hashData
export function hashDataWithSha1_DO_NOT_USE(data: any) {
	return hash('sha1', data) as string;
}
