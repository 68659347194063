import gql from 'graphql-tag';

export const SpaceShortcutsSpaceNavigationQuery = gql`
	query SpaceShortcutsSpaceNavigationQuery($spaceKey: String) {
		spaceSidebarLinks(spaceKey: $spaceKey) {
			quick {
				id
				url
				position
				title
				styleClass
			}
		}
	}
`;
