import idx from 'idx';

// eslint-disable-next-line check-cross-package-imports/dont-import-redux-in-next
import { getSpaceInfoSelector } from '@confluence-classic/confluence-legacy-redux';

import {
	EDIT_PAGE_PUBLISH_EXPERIENCE,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
	stopExperienceOnError,
} from '@confluence/experience-tracker';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

const trackAnalyticEvents = (store) => (next) => (action) => {
	const state = store.getState();
	const { error } = action;
	switch (action.type) {
		case 'EDITOR_CONTENT_PUBLISH_SUCCESS':
		case 'EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_SUCCESS':
			triggerAnalyticsForPage(action, state);
			getExperienceTracker().succeed({
				name: EDIT_PAGE_PUBLISH_EXPERIENCE,
			});
			break;

		case 'EDITOR_CONTENT_PUBLISH_FAILURE':
		case 'EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_FAILURE':
			const statusCode = idx(error, (_) => _.statusCode) || 0;
			// does not let ExperienceTracker track user errors
			if (statusCode === 0 || (statusCode >= 500 && statusCode <= 599)) {
				// User (e.g. 0 status code) or non-user (e.g. 5xx status code) error.
				// Abort or fail, respectively, the experience.
				stopExperienceOnError(EDIT_PAGE_PUBLISH_EXPERIENCE, error);
			} else if (statusCode >= 400 && statusCode <= 499) {
				// User error. Don't fail the experience which includes don't let the
				// experience time out so abort the experience.
				getExperienceTracker().abort({
					name: EDIT_PAGE_PUBLISH_EXPERIENCE,
					reason: idx(error, (_) => _.message) || idx(action, (_) => _.message.message),
					checkForTimeout: false,
				});
			}
			break;
	}

	return next(action);
};

const callAnalyticsEvent = (analyticsObj) => {
	getAnalyticsWebClient().then((analyticWebClientResponse) => {
		analyticWebClientResponse.sendTrackEvent(analyticsObj);
	});
};

const triggerAnalyticsForPage = (action, state) => {
	let spaceId;
	let spaceType;
	let homepageId;
	const { id, type, version } = action.response;
	try {
		const spaceKey = state.router.params.spaceKey;
		const space = getSpaceInfoSelector(state, spaceKey);
		spaceId = space.id;
		spaceType = space.type;
		homepageId = space.homepageId;
	} catch (e) {}

	const analyticsObject = {
		containerType: 'space',
		containerId: spaceId,
		source: 'editPageScreen',
		objectType: 'page',
		objectId: id,
		action: version?.number === 1 ? 'created' : 'updated',
		actionSubject: 'page',
		actionSubjectId: id,
		attributes: {
			pageType: type,
			spaceType,
			versionNum: version?.number,
			createExperience: null,
			isOverviewPage: homepageId === id,
		},
	};

	analyticsObject.attributes.createExperience = 'createPageModal';
	callAnalyticsEvent(analyticsObject);
};

export default trackAnalyticEvents;
