import type { ContentAppearanceType } from '@confluence/content-appearance';

import type { EDITOR_NAV_DESTINATION } from '../constants/editor-constants';

// TODO: Remove when other reducers are typed
export const EDITOR_CONTENT_PREVIEW = 'EDITOR_CONTENT_PREVIEW';
export const CHANGE_EDITOR_MODE = 'CHANGE_EDITOR_MODE';

// TODO: Convert this to flow by changing it to a regular Object
// Had to use enum here in order to be able to re-use the action types
export enum ActionTypes {
	CHANGE_EDITOR_TITLE = 'CHANGE_EDITOR_TITLE',
	CLOSE_EDITOR_ERROR = 'CLOSE_EDITOR_ERROR',
	SHOW_EDITOR_ERROR = 'SHOW_EDITOR_ERROR',
	EDITOR_CONTENT_V2_REQUEST = 'EDITOR_CONTENT_V2_REQUEST',
	EDITOR_CONTENT_V2_SUCCESS = 'EDITOR_CONTENT_V2_SUCCESS',
	EDITOR_CONTENT_V2_FAILURE = 'EDITOR_CONTENT_V2_FAILURE',
	EDITOR_CONTENT_PUBLISH_REQUEST = 'EDITOR_CONTENT_PUBLISH_REQUEST',
	EDITOR_CONTENT_PUBLISH_SUCCESS = 'EDITOR_CONTENT_PUBLISH_SUCCESS',
	EDITOR_CONTENT_PUBLISH_FAILURE = 'EDITOR_CONTENT_PUBLISH_FAILURE',
	EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_SUCCESS = 'EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_SUCCESS',
	EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_FAILURE = 'EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_FAILURE',

	EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_REQUEST = 'EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_REQUEST',
	EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_SUCCESS = 'EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_SUCCESS',
	EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_FAILURE = 'EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_FAILURE',

	EDITOR_CONTENT_PREVIEW = 'EDITOR_CONTENT_PREVIEW',

	EDITOR_CONTENT_PREVIEW_REQUEST = 'EDITOR_CONTENT_PREVIEW_REQUEST',
	EDITOR_CONTENT_PREVIEW_SUCCESS = 'EDITOR_CONTENT_PREVIEW_SUCCESS',
	EDITOR_CONTENT_PREVIEW_FAILURE = 'EDITOR_CONTENT_PREVIEW_FAILURE',

	EDITOR_DRAFT_SAVE_SUCCESS = 'EDITOR_DRAFT_SAVE_SUCCESS',

	SET_IS_PUBLISHING_CONTENT = 'SET_IS_PUBLISHING_CONTENT',

	UPDATE_CONTENT_SPACE_KEY = 'UPDATE_CONTENT_SPACE_KEY',

	CHANGE_EDITOR_MODE = 'CHANGE_EDITOR_MODE',
	CONTENT_SUCCESS = 'CONTENT_SUCCESS',
	HANDLE_PUBLISH_EVENT = 'HANDLE_PUBLISH_EVENT',

	FEATURE_DISCOVERED_MARK_SUCCESS = 'FEATURE_DISCOVERED_MARK_SUCCESS',
	EDITOR_SHOW_SCHEDULED_PUBLISH_DIALOG = 'EDITOR_SHOW_SCHEDULED_PUBLISH_DIALOG',
	EDITOR_HIDE_SCHEDULED_PUBLISH_COMPONENT = 'EDITOR_HIDE_SCHEDULED_PUBLISH_COMPONENT',
	EDITOR_CURRENTLY_SCHEDULED_PUBLISH_DATE = 'EDITOR_CURRENTLY_SCHEDULED_PUBLISH_DATE',
	EDITOR_CANCEL_SCHEDULED_PUBLISH = 'EDITOR_CANCEL_SCHEDULED_PUBLISH',
	SET_IS_SCHEDULING_PUBLISH = 'SET_IS_SCHEDULING_PUBLISH',
}

export enum ContentTypeType {
	PAGE = 'page',
	BLOGPOST = 'blogpost',
}

export type ErrorActionDataType = {
	message: string;
	details?: string;
	data?: {
		errors?: {
			message: { key: string };
			details?: string;
		}[];
	};
	statusCode?: statusCode;
};

export type ChangeEditorModeType = {
	type: ActionTypes.CHANGE_EDITOR_MODE;
	mode: EDITOR_NAV_DESTINATION;
};

export type ChangeEditorTitleType = {
	type: ActionTypes.CHANGE_EDITOR_TITLE;
	contentId: string;
	title: string;
};

export type EditorContentV2RequestType = {
	type: ActionTypes.EDITOR_CONTENT_V2_REQUEST;
	contentId: string;
};

export type EditorContentV2SuccessType = {
	type: ActionTypes.EDITOR_CONTENT_V2_SUCCESS;
	contentId: string;
	response: any; // TODO: TYPE WITH GENERATED GRAPHQL
	docFormat: 'atlas_doc_format'; // TODO: Should we remove this?
};

export type EditorContentV2FailureType = {
	type: ActionTypes.EDITOR_CONTENT_V2_FAILURE;
	error: error;
	options: {
		contentId: string;
	};
	message: ErrorActionDataType;
};

export type SetIsPublishingContentType = {
	type: ActionTypes.SET_IS_PUBLISHING_CONTENT;
	isPublishingContent: boolean;
};

type error = any; // FIXME
export type statusCode = number | null;
type errorData = any;
type content = {
	id?: string;
	schedulePublishDate?: string | null;
};

export type EditorContentPublishRequestType = {
	type: ActionTypes.EDITOR_CONTENT_PUBLISH_REQUEST;
	content: content;
};

export type EditorContentPublishFailureType = {
	type:
		| ActionTypes.EDITOR_CONTENT_PUBLISH_FAILURE
		| ActionTypes.EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_FAILURE;
	options: {
		content: content;
		retryOnConflict: boolean;
		migratedRecently: boolean;
	};
	error: {
		statusCode: statusCode;
	};
	message: ErrorActionDataType;
};

export type EditorContentPublishSuccess = {
	migratedRecently: boolean | any;
	type:
		| ActionTypes.EDITOR_CONTENT_PUBLISH_SUCCESS
		| ActionTypes.EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_SUCCESS;
	editorMode: EDITOR_NAV_DESTINATION;
	content: content;
};

export type ShowEditorErrorType = {
	type: ActionTypes.SHOW_EDITOR_ERROR;
	statusCode: statusCode;
	errorData: errorData;
};

export type EditorContentPreviewFailureType = {
	type: ActionTypes.EDITOR_CONTENT_PREVIEW_FAILURE;
	error: error;
	message: ErrorActionDataType;
};

export type SetIsSchedulingPublishType = {
	type: ActionTypes.SET_IS_SCHEDULING_PUBLISH;
	isSchedulingPublish: boolean;
};

export type EditorCurrentlyScheduledPublishDateType = {
	type: ActionTypes.EDITOR_CURRENTLY_SCHEDULED_PUBLISH_DATE;
	currentlyScheduledPublishDate: Date | null;
};

export type EditorShowScheduledPublishDialogType = {
	type: ActionTypes.EDITOR_SHOW_SCHEDULED_PUBLISH_DIALOG;
	context: string;
};

export type NoActionBodyActionTypes = {
	type:
		| ActionTypes.CLOSE_EDITOR_ERROR
		| ActionTypes.CONTENT_SUCCESS
		| ActionTypes.EDITOR_HIDE_SCHEDULED_PUBLISH_COMPONENT
		| ActionTypes.EDITOR_CANCEL_SCHEDULED_PUBLISH;
};

export type HandlePublishEventType = {
	type: ActionTypes.HANDLE_PUBLISH_EVENT;
	contentId: string;
	lastPublisher: string; // FIXME
};

export type EditorDraftSaveSuccessType = {
	type: ActionTypes.EDITOR_DRAFT_SAVE_SUCCESS;
	content: content;
};

export type UpdateContentAppearancePropertyBody = {
	contentId: string;
	propertyKey: string;
	propertyValue: ContentAppearanceType;
	versionNumber: number;
};
export type UpdateContentAppearancePropertyRequest = {
	type: ActionTypes.EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_REQUEST;
} & UpdateContentAppearancePropertyBody;

export type UpdateContentAppearancePropertySuccess = {
	type: ActionTypes.EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_SUCCESS;
} & UpdateContentPropertySuccessResponse &
	UpdateContentAppearancePropertyBody;

export type UpdateContentPropertySuccessResponse = {
	contentId;
	response: {
		key: string;
		value: ContentAppearanceType;
		version: {
			number: number;
		};
	};
};

export type UpdateContentAppearancePropertyFailure = {
	type: ActionTypes.EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_FAILURE;
	message: ErrorActionDataType;
	error: error;
	options: UpdateContentAppearancePropertyBody;
};

export type EditorContentV2ActionTypes =
	| ChangeEditorModeType
	| ChangeEditorTitleType
	| EditorContentV2RequestType
	| EditorContentV2SuccessType
	| EditorContentV2FailureType
	| SetIsPublishingContentType
	| EditorContentPublishRequestType
	| EditorContentPublishFailureType
	| EditorContentPublishSuccess
	| ShowEditorErrorType
	| NoActionBodyActionTypes
	| EditorContentPreviewFailureType
	| HandlePublishEventType
	| EditorDraftSaveSuccessType
	| UpdateContentAppearancePropertyRequest
	| UpdateContentAppearancePropertySuccess
	| UpdateContentAppearancePropertyFailure
	| UpdateContentSpaceKeyType
	| SetIsSchedulingPublishType
	| EditorCurrentlyScheduledPublishDateType
	| EditorShowScheduledPublishDialogType;

export type UpdateContentSpaceKeyType = {
	type: ActionTypes.UPDATE_CONTENT_SPACE_KEY;
	spaceKey: string;
};
