import { type PackagedEvent, SegmentEventTypes } from '../integration/types';

import { type XIDItem } from './index';

export function attachXidToSingleEvent(
	item: PackagedEvent,
	xidCallback: () => XIDItem[],
): PackagedEvent {
	if (!(xidCallback && xidCallback instanceof Function)) {
		return item;
	}

	if (item.msg.type === SegmentEventTypes.PAGE || item.msg.type === SegmentEventTypes.TRACK) {
		const xid = xidCallback();

		if (xid && Array.isArray(xid)) {
			item.msg.properties = { ...item.msg.properties, xid };
		}
	} else if (item.msg.traits && item.msg.type === SegmentEventTypes.IDENTIFY) {
		const xid = xidCallback();

		if (xid && Array.isArray(xid)) {
			item.msg.traits = { ...item.msg.traits, xid };
		}
	}

	return item;
}

export async function attachXidToMultipleEvents(
	items: PackagedEvent[],
	xidItemPromiseCallback: Promise<() => XIDItem[]>,
): Promise<PackagedEvent[]> {
	try {
		const xidCallback = await xidItemPromiseCallback;
		return items.map((item) => attachXidToSingleEvent(item, xidCallback));
	} catch (error) {
		return items;
	}
}
