import React, { useLayoutEffect, useRef } from 'react';
import type { RefObject } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { withOutline } from '../tooling/outline';

import { attrsToReactProps } from './attrsToReactProps';
import { lookupOriginalElement, shouldUseOriginalElement } from './placeholders';

type NodeRemovalTask = () => void;

const nodeRemovalTasks: NodeRemovalTask[] = [];
let nodeRemovalTimerHandle: ReturnType<typeof setTimeout> | null = null;

// scheduled tasks will be executed in the next microtask tick,
// this allows preventing unnecessary/expensive DOM manipulations during react render phase
function scheduleNodeRemoval(fn: NodeRemovalTask) {
	nodeRemovalTasks.push(fn);
	if (!nodeRemovalTimerHandle) {
		nodeRemovalTimerHandle = setTimeout(() => {
			for (const task of nodeRemovalTasks) {
				task();
			}

			nodeRemovalTimerHandle = null;
			nodeRemovalTasks.length = 0;
		}, 0);
	}
}

export function InlineScript({
	attrs,
	innerHTML,
	nonce,
}: {
	attrs: NamedNodeMap;
	innerHTML: string;
	nonce: string | undefined;
}) {
	useLayoutEffect(() => {
		const scriptElement = document.createElement('script');
		scriptElement.innerHTML = innerHTML;
		for (let i = 0; i < attrs.length; i++) {
			scriptElement.setAttribute(attrs[i].name, attrs[i].value);
		}
		if (nonce) {
			scriptElement.nonce = nonce;
		}

		document.body.appendChild(scriptElement);
		return () =>
			scheduleNodeRemoval(() => {
				if (scriptElement.parentNode === document.body) {
					document.body.removeChild(scriptElement);
				}
			});
	}, [attrs, innerHTML, nonce]);

	return null;
}

function DOMElementFromSSRResult({ el }: { el: Element }) {
	const ref = useRef<HTMLInputElement>(null);
	useLayoutEffect(() => {
		const target = ref.current;
		const parent = target?.parentNode;
		if (parent) {
			const originalNode = lookupOriginalElement(el);
			if (originalNode) {
				parent.insertBefore(originalNode, target.nextSibling);
			}
			return () =>
				scheduleNodeRemoval(() => {
					if (originalNode && originalNode.parentNode === parent) {
						parent.removeChild(originalNode);
					}
				});
		}
	}, [el]);

	// There is no way around in React. We need a reference point to do the DOM manipulation.
	// We also can't operate on this node directly, because the siblings will reference to it.
	// Similar to https://github.com/facebook/react/issues/11538 it will cause exception.
	// data-loadable-ref here has no meaning. Just to add something to DOM so it can be trace back to here.
	return <input type="hidden" ref={ref} data-loadable-ref />;
}

function createElement(el: Element, elementRef?: RefObject<HTMLElement | null>) {
	/**
	 * The value of Element.tagName, that is the uppercase name of the element tag if an HTML element,
	 * or the lowercase element tag if an XML element (like a SVG or MATHML element).
	 * https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeName
	 */
	let tag = el.nodeName;
	if (tag === tag.toUpperCase()) {
		tag = tag.toLowerCase();
	}

	const attrs = el.attributes;
	const type = attrs.getNamedItem('type')?.value;
	const skipInlineScript = attrs.getNamedItem('data-skip-in-placeholder')?.value;
	if (shouldUseOriginalElement(el)) {
		return <DOMElementFromSSRResult el={el} />;
	} else if (
		tag === 'script' &&
		(!type || type === 'text/javascript' || type === 'module') &&
		!skipInlineScript
	) {
		return (
			<InlineScript nonce={(el as HTMLElement).nonce} attrs={attrs} innerHTML={el.innerHTML} />
		);
	}

	return React.createElement(
		tag,
		{ ...attrsToReactProps(attrs), ref: elementRef },
		// do not pass ref to children as ref for viewport is only needed for parent node
		...(el.childNodes.length ? convertToReactElements(el.childNodes) : []),
	);
}

function convertToReactElements(
	nodes: NodeListOf<Node> | Node[],
	elementRef?: RefObject<HTMLElement | null>,
): React.ReactNode[] {
	return Array.from(nodes).map((node: Node) => {
		switch (node.nodeType) {
			case Node.COMMENT_NODE:
				return null;
			case Node.TEXT_NODE:
				return node.textContent;
			case Node.ELEMENT_NODE:
				//pass ref to parent node only
				return createElement(node as Element, elementRef);
		}
		throw new Error(`Unsupported node type (${node.nodeType}): ${(node as any)?.outerHTML}`);
	});
}

export function ReactBasedPlaceholder({
	content,
	showDebugOutline,
	elementRef,
}: {
	content: null | Element[];
	showDebugOutline: boolean;
	elementRef?: RefObject<HTMLElement | null>;
}) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!content) {
		return null;
	}

	try {
		const placeholder = React.createElement(
			React.Fragment,
			{},
			...convertToReactElements(content, elementRef),
		);
		return showDebugOutline ? withOutline('blue', placeholder) : placeholder;
	} catch (e) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				source: 'loadable',
				action: 'failed',
				actionSubject: 'convertToReactElements',
				attributes: {
					message: e.message,
					stack: e.stack,
				},
			},
		}).fire();
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line no-console
			console.error(e);
		}
		return null;
	}
}
