import isMatch from 'lodash/isMatch';

export type EventMatcher = {
	action?: string;
	actionSubject?: string;
	source?: string;
	attributes?: Record<string, unknown>;
};

// NOTE: event matchers can *also* be defined in the variations of
// the "confluence.frontend.filter-events.exemptions" feature flag.
// https://app.launchdarkly.com/confluence/staging/features/confluence.frontend.filter-events.exemptions/variations
// Consult LaunchDarkly to determine what events are whitelisted for a particular
// tenant in addition to the default matchers defined below

export const defaultEventMatchers: EventMatcher[] = [
	{
		action: 'measured',
		actionSubject: 'performance',
		source: 'performance',
		attributes: {
			properties: {
				'event:type': 'page-load',
			},
		},
	},
	{
		action: 'measured',
		actionSubject: 'performance',
		source: 'performance',
		attributes: {
			properties: {
				'event:key': 'confluence.fe.page-segment-load.content-renderer',
			},
		},
	},
];

// inspects the event and returns if the event matches any allowed events
export const isAllowedEvent = (event: any, matchers: Array<EventMatcher>) => {
	if (typeof event !== 'object' || !event) {
		return false;
	}
	return matchers.some((matcher) => isMatch(event, matcher));
};
