import {
	createPageSegmentLoadMetric,
	PAGE_COMMENTS_SEGMENT_KEY,
} from '@confluence/browser-metrics';

export const PAGE_COMMENTS_METRIC = createPageSegmentLoadMetric({
	key: PAGE_COMMENTS_SEGMENT_KEY,
	timings: [
		{
			key: 'fmp',
			endMark: 'fmp',
		},
		{
			key: 'tti',
			endMark: 'tti',
		},
		{
			key: 'pageCommentsRender',
			startMark: 'pageCommentsRenderStart',
			endMark: 'pageCommentsRenderEnd',
		},
	],
});
