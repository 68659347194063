import React from 'react';

export type LegacyMacroRendererContextType = {
	shouldRefetchMacroQuery: boolean;
};

// Can be used anywhere in the tree where LegacyMacroRendererQuery is rendered to force re-fetch macro queries. This is
// useful when multiple PageContentRenderer or other Renderer instances are present in the DOM at once as it gets
// unique IDs in the macro's dangerously set HTML, allowing the macro content to be inserted properly in each renderer's
// macro instance via inline script execution.
// See https://hello.atlassian.net/wiki/spaces/CCMG/pages/1632470060/Presenter+Mode+Implementation+Technical+Details#Macro-Rendering
// for further information.
export const LegacyMacroRendererContext = React.createContext<LegacyMacroRendererContextType>({
	shouldRefetchMacroQuery: false,
});
