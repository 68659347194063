import type { CustomField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export interface AttachmentMacroParams {
	labels?: string | string[];
}

export const attachments: FieldTransformer<AttachmentMacroParams> = {
	// Converts the "labels" field to be a multi-select
	// which is not supported by the old macro browser
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name !== 'labels') {
				return field;
			}

			return {
				...field,
				type: 'custom',
				isMultiple: true,
				options: {
					resolver: {
						type: 'label',
					},
				},
			} as CustomField;
		});
	},
	// Custom field receives an array
	transformBefore(params) {
		if (params.labels && typeof params.labels === 'string') {
			params.labels = params.labels.split(',');
		}
		return params;
	},
	// Custom field returns an array, so converting it back to string to store
	transformAfter(params) {
		if (Array.isArray(params.labels)) {
			params.labels = params.labels.join(',');
		}
		return params;
	},
};
