import React, { useEffect, useState, createContext } from 'react';

type FrameCountValues = {
	frameCount: number;
	setFrameCount: React.Dispatch<React.SetStateAction<number>>;
};

export const FrameCount = createContext<FrameCountValues>({
	frameCount: 0,
	setFrameCount: () => {},
});

const FrameCountProvider = ({ children }: { children: JSX.Element }) => {
	const [frameCount, setFrameCount] = useState(0);
	const value = { frameCount, setFrameCount };

	useEffect(() => {
		if (frameCount > 1) {
			throw new Error('Max limit of one frame component per module.');
		}
	}, [frameCount]);

	return <FrameCount.Provider value={value}>{children}</FrameCount.Provider>;
};

export default FrameCountProvider;
