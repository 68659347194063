import gql from 'graphql-tag';

export const MentionsUsersQuery = gql`
	query usersV2Bulk($ids: [ID!]!) {
		usersV2(ids: $ids) {
			id
			name
		}
	}
`;
