import { LoadableAfterPaint, LoadablePaint } from '@confluence/loadable';
// eslint-disable-next-line no-restricted-imports
import type { LoadableType } from '@confluence/loadable/src/types';

import type { CreateFromTemplateInterface } from './CreateFromTemplate';

// NOTE: this loader should have an AFTER_PAINT priority, however it had to be bumped to PAINT
// to avoid creating conditions for the bug https://product-fabric.atlassian.net/browse/CCP-1371

export const CreateFromTemplateLoader: LoadableType<CreateFromTemplateInterface> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CreateFromTemplate" */ './CreateFromTemplate'))
			.CreateFromTemplate,
});

export const LazyCreateFromTemplateLoader: LoadableType<CreateFromTemplateInterface> =
	LoadableAfterPaint({
		loader: async () =>
			(await import(/* webpackChunkName: "loadable-CreateFromTemplate" */ './CreateFromTemplate'))
				.CreateFromTemplate,
	});
