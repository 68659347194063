import { getLogger } from '@confluence/logger';

import { requireSuperBatch } from './requireSuperBatch';
import { waitForWRM } from './waitForWRM';

export type RequireType = (wrm: string[], callback: Function) => Promise<unknown>;

export type WRMType = {
	require?: RequireType;
	realForceRequire?: RequireType;
};

declare global {
	interface Window {
		WRM?: WRMType;
	}
}

type Callback = {
	(modules: any): void;
};

type UnavailableCallback = {
	(): void;
};

const logger = getLogger('wrm');

let wrmEnabled = true;
/**
 * Set whether WRM is available
 * @param enabled Disable all the WRM loading if set to true
 */
export function setLegacyWRMEnabled(enabled: boolean): void {
	wrmEnabled = enabled;
}

/**
 * Require a legacy WRM resource
 *
 * @param wrm
 * Array of WRM resource keys.
 * For example: ["wrc!confluence.web.resources:navigator-context"]
 *
 * @param callback
 * A callback function will be call when WRM is loaded.
 *
 * @param unavailableCallback
 * A callback function to handle the case gracefully when WRM is completely unavailable.
 * For example, if the button triggers a WRM function.
 * You should hide the button initially and also hide if unavailableCallback is called.
 */
export function requireLegacyWRM(
	wrm: string | string[],
	callback: Callback,
	unavailableCallback: UnavailableCallback,
): void {
	if (!callback || !unavailableCallback || !wrmEnabled) {
		if (wrmEnabled && process.env.NODE_ENV !== 'production') {
			logger.error`Missing argument`;
		}
		// Timeout to avoid trigger infinite render loop in React Hooks
		if (unavailableCallback) {
			setTimeout(unavailableCallback);
		}
		return;
	}

	void Promise.all([requireSuperBatch(), waitForWRM()]).then(([_superBatch, wrmFns]) =>
		wrmFns?.require?.(Array.isArray(wrm) ? wrm : [wrm], callback),
	);
}

export function realForceRequireLegacyWRM(
	wrm: string | string[],
	callback: Callback,
	unavailableCallback: UnavailableCallback,
): void {
	if (!callback || !unavailableCallback || !wrmEnabled) {
		if (wrmEnabled && process.env.NODE_ENV !== 'production') {
			logger.error`Missing argument`;
		}
		// Timeout to avoid trigger infinite render loop in React Hooks
		if (unavailableCallback) {
			setTimeout(unavailableCallback);
		}
		return;
	}

	void Promise.all([requireSuperBatch(), waitForWRM()]).then(([_superBatch, wrmFns]) =>
		wrmFns?.realForceRequire?.(Array.isArray(wrm) ? wrm : [wrm], callback),
	);
}
