import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { B50, N20A, N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Select, { components } from '@atlaskit/select';

export type YAxisValue = 'number' | 'percentage';
const Y_AXIS_CHART_OPTIONS = ['number', 'percentage'] as const;

const SELECT_STYLE_PROPS = {
	isSearchable: false,
	spacing: 'compact' as const,
	styles: {
		control: (base, state) => ({
			...base,
			backgroundColor: token('color.background.neutral', N20A),
			borderColor: state.isFocused ? token('color.border.selected', B50) : 'transparent',
			':hover': {
				backgroundColor: state.isFocused
					? token('color.background.neutral', N20A)
					: token('color.background.neutral.hovered', N30A),
				borderColor: state.isFocused ? token('color.border.selected', B50) : 'transparent',
			},
		}),
		menu: (styles) => ({
			...styles,
			width: '125px',
			position: 'absolute',
			right: 0,
		}),
		option: (styles) => ({
			...styles,
			paddingLeft: token('space.200', '16px'),
		}),
	},
};

const i18n = defineMessages({
	yAxisDropdownNumberValue: {
		id: 'admin-center.y-axis-chart.value.number',
		defaultMessage: '# Number',
		description: 'Value to be selected for the y-axis chart to display numbers on y-axis',
	},
	yAxisDropdownNumberAbbrevation: {
		id: 'admin-center.y-axis-chart.value.number.abbrevation',
		defaultMessage: '# Num.',
		description:
			'Abbreviation for the value to be selected for the y-axis chart to display numbers on y-axis. Shows up in select input field',
	},
	yAxisDropdownPercentValue: {
		id: 'admin-center.y-axis-chart.value.percent',
		defaultMessage: '% Percentage',
		description: 'Value to be selected for the y-axis chart to display percentages on y-axis',
	},
	yAxisDropdownPercentAbbrevation: {
		id: 'admin-center.y-axis-chart.value.percent.abbreviation',
		defaultMessage: '% Pct.',
		description:
			'Abbreviation for the value to be selected for the y-axis chart to display percentages on y-axis.  Shows up in select input field',
	},
	ariaLabel: {
		id: 'admin-center.y-axis-chart.value.aria-label',
		defaultMessage: 'Select y-axis value for chart',
		description:
			"Aria label for the select element that allows user to select the chart's y-axis values for a given chart",
	},
});

const createYAxisChartOptionsObject = (yAxisChartType: YAxisValue) => {
	if (yAxisChartType === 'percentage') {
		return {
			labelMessage: i18n.yAxisDropdownPercentValue,
			inputValueMessage: i18n.yAxisDropdownPercentAbbrevation,
		};
	} else {
		// In this case, yAxisChartType should be "number".
		// Although it should not happen, if yAxisChartType is an unexpected value, then we default to number.
		return {
			labelMessage: i18n.yAxisDropdownNumberValue,
			inputValueMessage: i18n.yAxisDropdownNumberAbbrevation,
		};
	}
};

export type YAxisSelectProps = {
	onYAxisValueChange: (yAxisSwitcherValue: YAxisValue) => void;
	yAxisValue: YAxisValue;
	yAxisActionSubjectPrefix: string;
	analyticsSource: string;
};

export const YAxisSelect = ({
	onYAxisValueChange,
	yAxisValue,
	yAxisActionSubjectPrefix,
	analyticsSource,
}: YAxisSelectProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const yAxisOptions = useMemo(
		() =>
			Y_AXIS_CHART_OPTIONS.map((value) => ({
				label: formatMessage(createYAxisChartOptionsObject(value)?.labelMessage),
				value,
				inputValueMessage: formatMessage(createYAxisChartOptionsObject(value)?.inputValueMessage),
			})),
		[formatMessage],
	);

	const fireDropdownAnalyticsEvent = useCallback(
		({ actionSubjectId, value }: { actionSubjectId: string; value?: YAxisValue }) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'dropdown',
					actionSubjectId,
					source: analyticsSource,
					...(value && {
						attributes: {
							value,
						},
					}),
				},
			}).fire();
		},
		[analyticsSource, createAnalyticsEvent],
	);

	return (
		<Select
			{...SELECT_STYLE_PROPS}
			aria-label={formatMessage(i18n.ariaLabel)}
			options={yAxisOptions}
			components={{ SingleValue }}
			value={yAxisOptions.find((option) => option.value === yAxisValue)}
			onMenuOpen={() => {
				fireDropdownAnalyticsEvent({
					actionSubjectId: `${yAxisActionSubjectPrefix}YAxisMenu`,
				});
			}}
			onChange={(option) => {
				if (option) {
					const newYAxisValue = option.value;
					fireDropdownAnalyticsEvent({
						actionSubjectId: `${yAxisActionSubjectPrefix}YAxisMenuItem`,
						value: newYAxisValue,
					});
					onYAxisValueChange(newYAxisValue);
				}
			}}
		/>
	);
};

const SingleValue = (props) => {
	return <components.SingleValue {...props}>{props.data.inputValueMessage}</components.SingleValue>;
};
