import { LoadableLazy } from '@confluence/loadable';

export const UnpublishHubDialogLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-UnpublishHubDialogLoadable" */ './UnpublishHubDialog'
			)
		).UnpublishHubDialog,
});
