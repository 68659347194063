import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	openLink: {
		id: 'fabric-extension-handlers.inline-image.openLink',
		defaultMessage: 'Open link in a new tab',
		description:
			'Tooltip message when user hover over inline image to alert them that they can open the link in a new tab',
	},
	imageFailedToLoad: {
		id: 'fabric-extension-handlers.inline-image.imageFailed',
		defaultMessage: 'Image has failed to load',
		description:
			'Tooltip message when user hover over inline image icon of image that failed to load',
	},
});
