/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import React, { lazy, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { FieldContainer } from '../../UIKit-legacy/utils/FieldContainer';
import { type FieldProps, type TextAreaProps } from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';

const AkFormField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const AKTextArea = React.lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.textarea' */
			'@atlaskit/textarea'
		),
);

const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

const validate = (value: any) => (!value ? 'EMPTY' : undefined);

const TextArea = (props: TextAreaProps) => (
	<FieldContainer>
		<AkFormField
			name={props.name}
			label={props.label}
			defaultValue={props.defaultValue}
			isRequired={props.isRequired}
			validate={props.isRequired ? validate : undefined}
		>
			{({ fieldProps, error }: { fieldProps: FieldProps; error?: string }) => {
				const {
					id,
					isRequired,
					isDisabled,
					isInvalid,
					onChange,
					onBlur,
					onFocus,
					value,
					'aria-labelledby': ariaLabelledBy,
				} = fieldProps;
				return (
					<Fragment>
						<FormStateChangeNotifier name={props.name} value={fieldProps.value} />
						<AKTextArea
							id={id}
							isRequired={isRequired}
							isDisabled={isDisabled}
							isInvalid={isInvalid}
							onChange={onChange}
							onBlur={onBlur}
							onFocus={onFocus}
							value={value}
							aria-labelledby={ariaLabelledBy}
							appearance="standard"
							resize="smart"
							minimumRows={2}
							isMonospaced={props.isMonospaced}
							placeholder={props.placeholder}
							spellCheck={props.spellCheck}
						/>
						{error === 'EMPTY' && (
							<AKErrorMessage>
								<FormattedMessage {...messages.requiredFieldMessage} />
							</AKErrorMessage>
						)}
						{props.description && <AKHelperMessage>{props.description}</AKHelperMessage>}
					</Fragment>
				);
			}}
		</AkFormField>
	</FieldContainer>
);

export default TextArea;

export const TextAreaFn = ({ props }: Props) => {
	const {
		name,
		label,
		defaultValue,
		isMonospaced,
		isRequired,
		description,
		placeholder,
		spellCheck,
	} = props as TextAreaProps;
	return (
		<TextArea
			name={name}
			label={label}
			defaultValue={defaultValue}
			isMonospaced={isMonospaced}
			isRequired={isRequired}
			placeholder={placeholder}
			description={description}
			spellCheck={spellCheck}
		/>
	);
};

const messages = defineMessages({
	requiredFieldMessage: {
		id: 'confluence.text.area.required.field.message',
		defaultMessage: 'This field is required.',
		description: 'This message alerts the user that a field is required.',
	},
});
