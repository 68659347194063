import { createStore, createHook } from 'react-sweet-state';

export enum Metric {
	VIEWS = 'views',
	UNIQUE_VISITORS = 'uniqueVisitors',
	CLICKS = 'clicks',
	CTR = 'ctr',
}

type State = {
	selectedMetric: Metric;
};

const initialState: State = {
	selectedMetric: Metric.VIEWS,
};

const actions = {
	setSelectedMetric:
		(metric: Metric) =>
		({ setState }: any) => {
			setState({ selectedMetric: metric });
		},
};

const Store = createStore({
	initialState,
	actions,
});

export const useSelectedMetric = createHook(Store);
