interface keyableTimestamp {
	[key: string]: number;
}
/**
 * A type of object that will act as a helper for keeping track of
 */
type BrowserMetricsState = {
	transitionId: number;
	pageLoadStartTimestamp: number;
	routeName: string | null;
	previousRoute: string | null;
	startTimes: keyableTimestamp;
	previousContentId: string | null;
	contentId: string | null;
};

export const browserMetricsState: BrowserMetricsState = {
	transitionId: 0,
	pageLoadStartTimestamp: 0,
	routeName: null,
	previousRoute: null,
	startTimes: {},
	previousContentId: null,
	contentId: null,
};

export const isInitial = () => browserMetricsState.transitionId === 0;
