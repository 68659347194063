import React, { Fragment } from 'react';

import type { ExtensionHandler } from '@atlaskit/editor-common/extensions';

import { createMacroExtensionHandler } from '@confluence/fabric-extension-lib/entry-points/extensions-common';
import { EXTENSION_KEY } from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import {
	EDITOR,
	getExperienceName,
	getMacroAttributesFromADFNode,
	MacroExperienceStart,
} from '@confluence/macro-tracker';
import { ExperienceTimeout } from '@confluence/experience-tracker';

import { InvalidExtensionLoader as InvalidExtension } from '../InvalidExtension/InvalidExtensionLoader';
import { InlineExternalImage } from '../InlineExternalImage';
import { InlineImage } from '../InlineImage';
import type {
	InlineExternalImageParams,
	InlineImageParams,
	ExtensionParamsType,
} from '../extensionHandlerTypes';

export const getMigrationExtHandlerForEditor = ({
	contentId,
	contextProviders,
}): ExtensionHandler<ExtensionParamsType> =>
	createMacroExtensionHandler((extension) => {
		if (!extension) {
			return null;
		}

		const { extensionKey, parameters } = extension;
		const { ClassicEditorContextProvider = ({ children }) => children } = contextProviders;
		const attributes = getMacroAttributesFromADFNode(extension);
		let implementation;

		const defaultProps = {
			contentId,
			mode: EDITOR,
			extensionKey,
			node: extension,
		};

		switch (extensionKey) {
			case EXTENSION_KEY.INVALID:
				// extension is rendered outside of React DOM tree and require access to the ClassicEditorContext
				implementation = (
					<ClassicEditorContextProvider>
						<InvalidExtension parameters={parameters} {...defaultProps} />
					</ClassicEditorContextProvider>
				);
				break;

			case EXTENSION_KEY.INLINE_EXTERNAL_IMAGE:
				const inlineExtImageProps = parameters as InlineExternalImageParams;
				implementation = <InlineExternalImage {...inlineExtImageProps} {...defaultProps} />;
				break;

			case EXTENSION_KEY.INLINE_MEDIA_IMAGE:
				const inlineMediaImageProps = parameters as InlineImageParams;
				const { link } = parameters;
				implementation = <InlineImage {...inlineMediaImageProps} {...defaultProps} link={link} />;
				break;
		}

		return (
			<Fragment>
				<MacroExperienceStart
					name={getExperienceName(EDITOR, extension)}
					contentId={contentId}
					mode={EDITOR}
					extensionKey={extensionKey}
					attributes={attributes}
					timeout={ExperienceTimeout.MACRO_LOAD}
				/>
				{implementation}
			</Fragment>
		);
	});
