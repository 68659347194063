import type { Option } from '@atlaskit/editor-common/extensions';

import { cfetch } from '@confluence/network';

import type { LegacyMacroManifest, ConfluencePageContext } from '../extensionTypes';

interface AttachmentSearchOptions {
	pageId: string;
	title?: string;
	fileTypes?: string[];
}

const search = async (options: AttachmentSearchOptions) => {
	const params = Object.entries(options)
		.map(([key, value]) =>
			Array.isArray(value)
				? // fileTypes=pdf&fileTypes=xls
					value.map((item) => `${key}=${item}`)
				: // pageId=910426922
					`${key}=${value}`,
		)
		.flat()
		.join('&');

	// /json/attachmentsearch.action?pageId=910426922&fileTypes=pdf&fileTypes=xls
	const res = await cfetch(`/wiki/json/attachmentsearch.action?${params}`);

	const { attachments } = await res.json();
	if (attachments) {
		return attachments.map(({ title }) => ({
			label: title,
			value: title,
		}));
	}

	return [];
};

const getAttachmentResolver =
	(options: AttachmentSearchOptions) =>
	async (searchTerm?: string, selectedValue?: string | string[]) => {
		const searchers: Array<Promise<Option[]>> = [];

		searchers.push(search({ ...options, title: searchTerm }));

		if (selectedValue) {
			searchers.push(
				Promise.resolve(
					(Array.isArray(selectedValue) ? selectedValue : [selectedValue]).map((value) => ({
						label: value,
						value,
					})),
				),
			);
		}

		return (await Promise.all(searchers)).flat();
	};

export const getAttachmentResolverFor = (
	macro: LegacyMacroManifest,
	pageContext: ConfluencePageContext,
) => {
	const pageId = pageContext.contentId;

	// fileTypes were taken from
	// https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence/browse/confluence-plugins/confluence-bundled-plugins/officeconnector/Zen/src/main/resources/javascript/office-connector-macro-fields.js#125-128
	switch (macro.macroName) {
		case 'viewpdf':
			return getAttachmentResolver({ pageId, fileTypes: ['pdf'] });
		case 'viewdoc':
			return getAttachmentResolver({ pageId, fileTypes: ['doc', 'docx'] });
		case 'viewxls':
			return getAttachmentResolver({ pageId, fileTypes: ['xls', 'xlsx'] });
		case 'viewppt':
			return getAttachmentResolver({ pageId, fileTypes: ['ppt', 'pptx'] });
		default:
			return getAttachmentResolver({ pageId });
	}
};
