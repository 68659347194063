import gql from 'graphql-tag';

export const EditorContentStateQuery = gql`
	query EditorContentStateQuery($contentId: ID!, $isDraft: Boolean!) {
		singleContent(id: $contentId) {
			id
			contentState(isDraft: $isDraft) {
				id
				name
				color
				restrictionLevel
				isCallerPermitted
			}
		}
		availableContentStates(contentId: $contentId) {
			customContentStates {
				name
				color
				id
			}
			spaceContentStates {
				name
				color
				id
				restrictionLevel
			}
		}
	}
`;
