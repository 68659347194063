import { onError } from 'apollo-link-error';

import { setGraphqlErrorAttributes } from '@confluence/monitoring';
import { getTraceIdFromResponse } from '@confluence/network';
import { processError } from '@confluence/graphql-error-processor';

export const createOnErrorLink = () =>
	onError(({ operation, graphQLErrors, networkError }) => {
		let response: Response | undefined = operation.getContext()?.response;
		if (!response) {
			// Experimental queries don't have 'response' populated in context, so try
			// to get the Response object from graphQLErrors:
			response = graphQLErrors
				?.map((e) => getResponseFromError(e.originalError))
				.find((e) => e != null);
		}

		const traceId = getTraceIdFromResponse(response);

		if (graphQLErrors?.length) {
			graphQLErrors.forEach((e) => {
				setGraphqlErrorAttributes(e, {
					graphqlPath: e.path?.join('.'),
					operationName: operation.operationName,
					statusCode: response?.status,
					traceId,
				});
				processError(e);
			});
		}

		if (networkError) {
			setGraphqlErrorAttributes(networkError, {
				operationName: operation.operationName,
				statusCode: getResponseFromError(networkError)?.status,
				traceId,
			});
			processError(networkError);
		}
	});

function getResponseFromError(error: Error | null | undefined) {
	if (typeof error === 'object' && error && 'response' in error) {
		const { response } = error;
		if (typeof response === 'object' && response && 'status' in response) {
			return response as Response;
		}
	}
	return undefined;
}
