import memoize from 'memoize-one';

import ProfileClient from '@atlaskit/profilecard/client';

import type { FeatureFlagsType } from '@confluence/session-data';

import { UserProfileClientForConfluence } from './UserProfileClientForConfluence';
import {
	DIRECTORY_GRAPHQL_PATH,
	PROFILECARD_WITH_ATLAS_FEATURES_ENABLED_FF,
	TEAMCENTRAL_GRAPHQL_PATH,
} from './constants';

const USER_PROFILE_CONFIG = {
	url: DIRECTORY_GRAPHQL_PATH,
	cacheSize: 100,
	cacheMaxAge: 5 * 60 * 1000, // - 5 minutes
};

export const getProfileClient = memoize(
	(cloudId: string, featureFlags: FeatureFlagsType): ProfileClient => {
		const profileClientConfig =
			featureFlags[PROFILECARD_WITH_ATLAS_FEATURES_ENABLED_FF] === true
				? {
						...USER_PROFILE_CONFIG,
						teamCentralUrl: TEAMCENTRAL_GRAPHQL_PATH,
						teamCentralBaseUrl:
							process.env.CLOUD_ENV === 'staging' ||
							process.env.CLOUD_ENV === 'branch' ||
							process.env.CLOUD_ENV === 'dev'
								? 'https://team.stg.atlassian.com'
								: 'https://team.atlassian.com',
						productIdentifier: 'confluence',
						cloudId,
					}
				: USER_PROFILE_CONFIG;

		return new ProfileClient(profileClientConfig, {
			userClient: new UserProfileClientForConfluence(profileClientConfig),
		});
	},
);
