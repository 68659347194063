import { LoadableBackground } from '@confluence/loadable';

export const Dialogs = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Dialogs" */ './Dialogs')).Dialogs,
});

export { DialogsStateContainer } from './DialogsStateContainer';
export type {
	DialogsContainerProps,
	ModalDialogOnCloseCompleteArgs,
} from './DialogsStateContainer';
export { isDialogShown } from './helpers/helpers';

export type { WithDialogsProps } from './withDialogs';
export { withDialogs } from './withDialogs';
