import { query, writeQuery } from '@confluence/query-preloader-tools';

import { EditButtonQuery } from './EditButtonQuery.graphql';
import { EditButtonWithSpaceKeyQuery } from './EditButtonWithSpaceKeyQuery.graphql';
import type {
	EditButtonWithSpaceKeyQuery as EditButtonWithSpaceKeyQueryType,
	EditButtonWithSpaceKeyQueryVariables,
} from './__types__/EditButtonWithSpaceKeyQuery';
import type {
	EditButtonQuery as EditButtonQueryType,
	EditButtonQueryVariables,
} from './__types__/EditButtonQuery';

export type preloadEditButtonProps = {
	spaceKey: string;
	contentId?: string;
};

export function preloadEditButton({ spaceKey, contentId = '' }: preloadEditButtonProps) {
	// spaceKey will always be passed for both space overviews and view pages, but contentId is
	// distinct for the latter
	if (spaceKey && !contentId) {
		return query<EditButtonWithSpaceKeyQueryType, EditButtonWithSpaceKeyQueryVariables>({
			query: EditButtonWithSpaceKeyQuery,
			variables: {
				spaceKey,
			},
		}).then(({ data }) => {
			if (data?.spaceHomepage?.id) {
				writeQuery<EditButtonQueryType, EditButtonQueryVariables>({
					query: EditButtonQuery,
					variables: {
						contentId: data?.spaceHomepage?.id,
					},
					data: {
						content: data?.spaceHomepage
							? {
									nodes: [
										{
											...data.spaceHomepage,
											space: data?.space || null,
										},
									],
									// @ts-ignore
									__typename: 'PaginatedContentListWithChild',
								}
							: null,
					},
				});
			}
		});
	}
	return query({
		query: EditButtonQuery,
		variables: {
			contentId,
		},
	});
}
