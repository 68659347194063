import React from 'react';
import { type NativeCheckboxProps, type RenderFn } from '@atlassian/forge-ui-types';

const AKCheckbox = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.checkbox' */
		'@atlaskit/checkbox'
	).then((module) => ({ default: module.Checkbox })),
);

export const Checkbox = (props: Parameters<RenderFn>[0]) => {
	const checkboxProps = props.forgeDoc.props as NativeCheckboxProps;
	const { onChange, value, key, label, defaultChecked, isRequired } = checkboxProps;
	return (
		<AKCheckbox
			key={key}
			label={label}
			defaultChecked={defaultChecked}
			isRequired={isRequired}
			value={value}
			onChange={(e) => onChange?.({ value, isChecked: e.target.checked })}
		/>
	);
};
