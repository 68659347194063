import { createSelector } from 'reselect';
import { List } from 'immutable';

import createWeakMapSelector from './WeakMapMemoizer';

const PREDEFINED_BLUEPRINT_TYPES = {
	'meeting-notes': true,
	decisions: true,
	'kb-how-to-article': true,
	'file-list': true,
	retrospective: true,
	requirements: true,
	'shared-links': true,
	'kb-troubleshooting-article': true,
	'task-report': true,
	jirareport: true,
};

function isEmpty(data) {
	if (!data) {
		return true;
	}
	return !size(data);
}

function size(data) {
	if (!data) {
		return 0;
	}
	return data.hasOwnProperty('size') ? data.size : data.length;
}

export const buildContent = createWeakMapSelector(
	(item) => item,
	(item, users) => users,
	// 'containers' may be a space or a content, right now it's only ever content on file records
	(item, users, containers) => containers,
	(item, users, containers, versions) => versions,
	(item, users, containers, versions) => {
		let version = versions.get(`${item.version}`);

		if (version) {
			version = version.set('by', users.get(version.by));
		}

		const container = item.container ? containers.get(`${item.container}`) : null;
		let result = item.set('createdBy', users.get(item.createdBy)).set('version', version);
		if (item.contributors) {
			result = result.set(
				'contributors',
				item.contributors.map((accountId) => users.get(accountId)),
			);
		}
		if (item.viewers) {
			result = result.set(
				'viewers',
				item.viewers.map((accountId) => users.get(accountId)),
			);
		}
		if (container) {
			result = result.set('container', container);
			const containerVersion = container.get('version');
			if (containerVersion) {
				result = result.setIn(['container', 'version'], versions.get(`${containerVersion}`));
			}
		}
		return result;
	},
);

export const buildContentItem = (contentId, state) => {
	const entities = state.entities;
	const content = entities.get('contents').get(`${contentId}`);

	if (content && (content.createdBy || content.version)) {
		return buildContent(
			content,
			entities.get('users'),
			entities.get('contents'),
			entities.get('versions'),
		);
	}

	return content;
};

export const checkContentPermission = (state, contentId, permissionType) => {
	const content = state.entities.getIn(['contents', contentId]);
	return content.currentUserPermissions.contains(permissionType);
};

export const canEditContent = (state, contentId) =>
	checkContentPermission(state, contentId, 'update');

const buildContentCreatedBy = createWeakMapSelector(
	(item) => item,
	(item, users) => users,
	(item, users) => item.set('createdBy', users.get(item.createdBy)),
);

/**
 * Function that returns selector for `contents`
 * combined with proper version and user
 * from `versions` and `users` entities in the state
 *
 * Selector usage:
 * const selector = createContentsOnlyFromIdsSelector();
 * const selectedData = selector(ids, state);
 *
 * Where:
 * `ids` {Array}- an array of ids of contents to select
 * `state` - current state, that should have `state.entities.contents` inside
 *
 * Returned value:
 * Array of contents items [contentItem, ...]
 */
export const createContentsFromIdsSelector = () =>
	createSelector(
		(ids) => ids,
		(ids, state, contents) => contents || state.entities.get('contents'),
		(ids, state) => state.entities.get('contents'),
		(ids, state) => state.entities.get('versions'),
		(ids, state) => state.entities.get('users'),
		// 'containers' may be a space or a content, right now it's only ever content on file records
		(ids, contents, containers, versions, users) => {
			if (!ids) {
				return null;
			}

			if (typeof ids === 'number' || typeof ids === 'string') {
				ids = List([`${ids}`]);
			}

			if (ids.length && !List.isList(ids)) {
				ids = List(ids);
			}

			if (!ids.size) {
				return List([]);
			}

			ids = ids.filter((id) => !!contents.get(`${id}`));

			return ids.map((id) => {
				const content = contents.get(`${id}`);
				if (content.createdBy || content.version) {
					return buildContent(content, users, containers, versions);
				}

				return content;
			});
		},
	);

/**
 * Function that returns selector for contents only
 * ( NOT combined with user and version information )
 *
 * Selector usage:
 * const selector = createContentsOnlyFromIdsSelector();
 * const selectedData = selector(ids, state);
 *
 * Where:
 * `ids` {Array}- an array of ids of contents to select
 * `state` - current state, that should have `state.entities.contents` inside
 *
 * Returned value:
 * Array of contents items [contentItem, ...]
 */
export const createContentsOnlyFromIdsSelector = () =>
	createSelector(
		(ids) => ids,
		(ids, state) => state.entities.get('contents'),
		(ids, contents) => {
			if (isEmpty(ids)) {
				return List([]);
			}

			return ids.map((id) => contents.get(`${id}`));
		},
	);

/**
 * Function that returns selector for contents expanded by user information as `createdBy` property
 * ( combined with user information )
 *
 * Selector usage:
 * const selector = createContentsWithUserFromIdsSelector();
 * const selectedData = selector(ids, state);
 *
 * Where:
 * `ids` {Array}- an array of ids of contents to select
 * `state` - current state, that should have `state.entities.contents` and `state.entities.users` inside
 *
 * Returned value:
 * Array of contents items [contentItem, ...]
 */
export const createContentsWithUserFromIdsSelector = () =>
	createSelector(
		(ids) => ids,
		(ids, state) => state.entities.get('contents'),
		(ids, state) => state.entities.get('users'),
		(ids, contents, users) => {
			if (isEmpty(ids)) {
				return List([]);
			}

			return ids.map((id) => {
				const content = contents.get(`${id}`);
				if (content && content.createdBy) {
					return buildContentCreatedBy(content, users);
				}

				return content;
			});
		},
	);

const getContent = (contentId, state) => state.entities.getIn(['contents', contentId]);

const getLabels = (contentId, state) => state.entities.get('labels');

export const blueprintTypeSelector = createSelector(getContent, getLabels, (content, labels) => {
	if (!content || !labels) {
		return '';
	}

	const contentLabels = content.get('labels');
	if (!contentLabels || contentLabels.size === 0) {
		return '';
	}

	const contentBlueprintId = contentLabels.find((labelId) => {
		const labelData = labels.get(labelId);
		return !labelData ? false : PREDEFINED_BLUEPRINT_TYPES[labelData.get('name')];
	});

	return contentBlueprintId ? labels.get(contentBlueprintId).get('name') : '';
});
