/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React from 'react';
import { token } from '@atlaskit/tokens';
import { type RenderFn } from '@atlassian/forge-ui-types';

export const COMPONENT_TOP_MARGIN = token('space.150', '12px');

export const Root = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { children } = forgeDoc;

	const isChildVisible = (type: string) => !['ModalDialog'].includes(type);
	const nonVisibleChildren = children.filter(({ type }) => !isChildVisible(type));
	const visibleChildren = children.filter(({ type }) => isChildVisible(type));
	const visibleReactChildren = visibleChildren.map(render);

	return (
		<div
			data-testid="ui-kit-2-root-element"
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				display: 'inherit',
				width: 'inherit',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'> *:not(:first-of-type)': {
					marginTop: COMPONENT_TOP_MARGIN,
					width: 'inherit',
				},
			})}
		>
			{React.Children.map(visibleReactChildren, (child) => (
				<div>{child}</div>
			))}
			{nonVisibleChildren.map(render)}
		</div>
	);
};
