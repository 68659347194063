import { query } from '@confluence/query-preloader-tools';

import { CustomHeaderAndFooterQuery } from './CustomHeaderAndFooterQuery.graphql';

export function preloadCustomHeaderAndFooter(spaceKey) {
	return query({
		query: CustomHeaderAndFooterQuery,
		variables: {
			spaceKey,
		},
	});
}
