import { RENDERER_ANNOTATION_PROVIDER_SSR_FF } from '@confluence/annotation-provider/entry-points/featureFlags';
import { preloadContentHeader } from '@confluence/content-header/entry-points/preloadContentHeader';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import { preloadPageTitleContentProperties } from '@confluence/custom-sites-extensions/entry-points/preloadPageTitleContentProperties';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { preloadFocusedInlineComment } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadInlineHighlights } from '@confluence/inline-highlights-query/entry-points/preloadInlineHighlights';
import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import {
	getPreloaderFnContext,
	prepareGuardExceptionTask,
} from '@confluence/query-preloader-tools';
import { preloadPageReactions } from '@confluence/reactions/entry-points/preloadPageReactions';
import type { RouteMatch } from '@confluence/route';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadPageStatusQuery } from '@confluence/view-page-common/entry-points/preloadPageStatusQuery';
import { preloadLegacyBridge } from '@confluence/view-page/entry-points/preloadLegacyBridge';
import { ATL_PAGE_CONTENT_FOOTER_ACTIONS } from '@confluence/web-item-location/entry-points/ATL_PAGE_CONTENT_FOOTER_ACTIONS';
import { preloadWebPanelLocation } from '@confluence/web-panel-location/entry-points/preloadWebPanelLocation';

import { getIsEmbeddedConfluence, getUsername, hasEmbeddedAllowedFeatures } from './matchHelpers';
import { matchBlogPage } from './matchRoutes';
/*
 * preloadLivePageRoute preload queries are currently arranged in order of latency, with slowest
 * queries pushed to the tasks array first.
 */
export const preloadLivePageRouteSSR = async (match: RouteMatch, url: string) => {
	const { featureFlags, isLicensed, userId, edition, cloudId } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;
	const username = getUsername(match);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const isBlog = Boolean(matchBlogPage(url));
	const shouldPreloadNavigation = !isEmbeddedConfluence;
	const isCustomSitesPageTitleFFOn = featureFlags[CUSTOM_SITES_PAGE_TITLE_FF];

	if (process.env.REACT_SSR) {
		tasks.push(
			prepareGuardExceptionTask('EditorData', () =>
				preloadEditorData({
					spaceKey,
					userId,
					contentId,
					preloadNativeCollabData: true,
					shouldPreloadMentionsProvider: true,
					shouldPreloadNativeEditorData: false,
					cloudId,
					isBlog,
					isTransition: false,
					isLivePage: true,
				}),
			),
		);
	}

	// 1. Content Header and possibly content prerequisites
	tasks.push(
		preloadContentHeader({
			spaceKey,
			contentId,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute: false,
			useNewContentTopper: Boolean(
				featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
			),
			isSpaceAliasFFEnabled: Boolean(featureFlags['confluence.frontend.space.alias']),
			isBlog: false,
			isLivePageRoute: true,
		}).then(({ results, hasErrors }) => {
			if (!hasErrors) {
				return results;
			}

			return preloadContentPrerequisites(
				contentId,
				spaceKey,
				Boolean(featureFlags['confluence.frontend.space.alias']),
			);
		}),
		preloadPageStatusQuery(contentId),
		preloadLegacyBridge({ contentId, isBlog }),
	);

	// 2. Conditional page title
	if (isCustomSitesPageTitleFFOn) {
		tasks.push(preloadPageTitleContentProperties({ contentId, spaceKey }));
	}

	// 3. Space details
	tasks.push(preloadSpaceDetail(spaceKey));

	// 4. Navigation and page tree
	if (shouldPreloadNavigation) {
		tasks.push(
			preloadSpaceGuestList(spaceKey),
			preloadShortcutsSmartLinks(spaceKey),
			preloadSideNavigation(spaceKey),
			preloadSideNavigationNeedsContentId(spaceKey, contentId),
			preloadSpaceViews(spaceKey, isLicensed),
			preloadPageTree({
				spaceKey,
				contentId,
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
			}),
			preloadBlogTree({ spaceKey }),
		);
	}

	// 5. Inline Comments
	if (match?.query.focusedCommentId) {
		if (!featureFlags[RENDERER_ANNOTATION_PROVIDER_SSR_FF]) {
			tasks.push(preloadFocusedInlineComment(match?.query.focusedCommentId as string, contentId));
		}
	}

	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('inline-comments', match)) {
		// when rendering an embedded confluence page, we do not want to preload inline comments if they are not requested via the allowlist features
		tasks.push(preloadInlineHighlights(contentId));
		if (!featureFlags[RENDERER_ANNOTATION_PROVIDER_SSR_FF]) {
			tasks.push(preloadInlineComments({ pageId: contentId }));
		}
	}

	// 6. Reactions
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('page-reactions', match)) {
		// Preload whether the server has enabled reactions at all to show placeholder
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_PAGE_CONTENT_FOOTER_ACTIONS,
			}),
		);
		tasks.push(preloadPageReactions(contentId));
	}

	// 10. Stuff only needed for initial load
	tasks.push(
		// We only refresh token when it is about to expire.
		preloadMediaToken(contentId),
		preloadCustomHeaderAndFooter(spaceKey),
	);

	return Promise.all(tasks);
};
