import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import { MessageAction, MessageDeliveryStatus, type ProductIds } from './constants';
import { DebugLogger } from './DebugLogger';
import { Logger } from './Logger';
import type {
	ChoreographerState,
	IChoreographer,
	IChoreographerMessages,
	IChoreographerPlugin,
	IMessage,
	OperationalEventPayload,
	StartMessageDeliveryStatuses,
	StopMessageDeliveryStatuses,
} from './types';
import { type IMessageFinalizers } from './types/choreographer';
import { isChoreographerSuppressed } from './utils';

// This timeout is used for any long-running messages that are either stuck for some reason, or that the user has yet to clear, to ensure that messaging doesn't stay blocked indefinitely
const CHOREOGRAPHER_MESSAGE_TTL = 300_000; // 5 minute TTL for messages

// Lazily instantiate this the first time it's requested
let singleton: Choreographer;

/**
 * Choreographer Core API. Used to ensure only a single message at a time can be started on screen for the current user experience.
 * Accepts any number of plugins that want to register for choreographer services, provided they adhere to the IChoreographerPlugin contract.
 */
export class Choreographer implements IChoreographer {
	private state: ChoreographerState;

	/**
	 * Static method to create and/or return a singleton of the choreographer API.
	 *
	 * @returns Shared instance of choreographer API.
	 */
	public static getInstance(): Choreographer {
		if (!singleton) {
			singleton = new this();
		}

		return singleton;
	}

	private constructor() {
		this.state = {
			currentMessage: null,
			isDisabled: false,
			messages: new Map(),
			messageQueue: [],
			messageTtl: null,
			plugins: new Map(),
			queueingBehindMessage: null,
			shouldQueueMessages: false,
		};
	}

	private stopCurrentMessage() {
		if (this.state.currentMessage) {
			void this.stopMessageInternal(
				this.state.currentMessage.productId,
				this.state.currentMessage.messageId,
				this.state.currentMessage.analyticsClient,
				this.state.currentMessage.metricsData,
				true,
			).then(() => {
				// Manually clear the message TTL and reenable plugins here just in case the unregistered plugin's message denied the
				// stop request. We may still get a collision on-screen, but we're considering the message forcibly stopped at this
				// point, so need to allow new requests.
				this.clearMessageTtl();
				this.enablePlugins();
			});
		}
	}

	/**
	 * Enables the message queue, and logs which message everybody else is queueing up behind.
	 *
	 * @param productId Unique identifier for the product making the current startMessage request blocking others.
	 * @param messageId Unique identifier for the messageId blocking others.
	 */
	private queueMessages(productId: ProductIds, messageId: string) {
		this.state.shouldQueueMessages = true;
		this.state.queueingBehindMessage = { productId, messageId, timestamp: Date.now() };
	}

	/**
	 * Pushes all data associated with a startMessage request onto an internal queue, to potentially be processed at a later time.
	 * If a pending message request opts out (or otherwise fails to start), this data will be used to reinitiate the startMessage
	 * request for this message request instance. Returns a Promise that the caller can await as normal, and which will eventually
	 * either be resolved as 'blocked' if any prior pending message is started, or will be resolved with its eventual outcome when
	 * its startMessage request is processed.
	 *
	 * @param productId Unique identifier for the product making the current startMessage request being queued.
	 * @param messageId Unique identifier for the messageId being queued.
	 * @param analyticsClient The analytics client to be used for logging/metrics.
	 * @param metricsData Additional data from plugin to be sent for metrics logging.
	 * @param [plugin] An optional plugin instance to prevent from being disabled when this request starts.
	 * @returns A Promise that resolves to a MessageDeliveryStatus value, to denote the ultimate status of the attempted message start operation.
	 */
	private queueMessage(
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData: OperationalEventPayload['attributes'],
		plugin?: IChoreographerPlugin,
	) {
		return new Promise<StartMessageDeliveryStatuses>((resolve) => {
			this.state.messageQueue.push({
				productId,
				messageId,
				analyticsClient,
				metricsData,
				plugin,
				resolve,
			});
		});
	}

	/**
	 * Responsible for pulling a previously queued message request that was received while a message request was already in flight.
	 * Shift the first message off the top of the queue, and use the stored resolve function to kickstart another pass through the
	 * startMessage function, ultimately resolving with the final disposition of the request. Set the internal state for shouldQueueMessages
	 * to false if we're restarting a startMessage request, so that this doesn't get immediately thrown back into the queue. If it
	 * makes it through to its start callback being fired, that code will turn the shouldQueueMessages state back on.
	 */
	private processQueuedMessage() {
		const queuedMessage = this.state.messageQueue.shift();

		// Disable the shouldQueueMessages state here. If we have a queued message, it will turn the shouldQueueMessages state back on when it
		// hits the call to its message's startCallback function. Otherwise, the queue is now empty, and we let things process normally.
		this.state.shouldQueueMessages = false;
		// Also null out the queueingBehindMessage value, which will be recreated if/when another message opens the queue below.
		this.state.queueingBehindMessage = null;

		if (queuedMessage) {
			queuedMessage.resolve(
				this.startMessage(
					queuedMessage.productId,
					queuedMessage.messageId,
					queuedMessage.analyticsClient,
					queuedMessage.metricsData,
					queuedMessage.plugin,
				),
			);
		}
	}

	/**
	 * Responsible for flushing out any queued messages and resolving their Promises as "blocked". We don't officially consider a
	 * message as "blocked" until one of the prior calls has signaled a valid "started" state. This way, any async callbacks that
	 * are in flight can allow additional messages to queue up, and if they eventually opt out, we can move on to the next message
	 * in the queue and see if it will signal success. Only when we get a successful start to we consider all queued up messages to
	 * be "blocked". Run a loop to shift each of them off of the array and resolve them as blocked, until the array is empty.
	 */
	private destroyMessageQueue() {
		this.state.shouldQueueMessages = false;
		this.state.queueingBehindMessage = null;

		while (this.state.messageQueue.length > 0) {
			const { analyticsClient, messageId, metricsData, productId, resolve } =
				this.state.messageQueue.shift()!;
			const attributes = { ...metricsData, productId, messageId };
			const actionSubject = MessageAction.START;
			const logger = new Logger(actionSubject, messageId, attributes, analyticsClient);
			const debugLogger = new DebugLogger(productId, messageId);

			logger.blocked({
				blockedByProductId: this.state.currentMessage?.productId,
				blockedByMessageId: this.state.currentMessage?.messageId,
			});

			debugLogger.log({
				verbose: {
					phase: actionSubject,
					disposition: MessageDeliveryStatus.BLOCKED,
					blockedByProductId: this.state.currentMessage?.productId,
					blockedByMessageId: this.state.currentMessage?.messageId,
				},
			});

			resolve(MessageDeliveryStatus.BLOCKED);
		}
	}

	/**
	 * Sets a timeout to auto-expire any messaging that stays open for too long, to prevent messaging from being blocked indefinitely.
	 *
	 * @param productId Unique identifier for the product making the current startMessage request that triggered this message timeout.
	 * @param messageId Unique identifier for the messageId that triggered this message timeout.
	 * @param AnalyticsClient The analytics client to be used for logging/metrics.
	 * @param metricsData Additional data from plugin to be sent for metrics logging.
	 */
	private setMessageTtl(
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData: OperationalEventPayload['attributes'],
	) {
		this.state.currentMessage = {
			productId,
			messageId,
			analyticsClient,
			metricsData,
			timestamp: Date.now(),
		};
		this.state.messageTtl = setTimeout(() => {
			if (this.isCurrentMessage(productId, messageId)) {
				void this.stopCurrentMessage();
			}
		}, CHOREOGRAPHER_MESSAGE_TTL);
	}

	/**
	 * Checks to see if the requesting productId/messageId pair matches the data for the currently-displayed message.
	 *
	 * @param productId Unique identifier for the product being checked for a current message match.
	 * @param messageId Unique identifier for the messageId being checked for a current message match.
	 */
	private isCurrentMessage(productId: ProductIds, messageId: string) {
		return Boolean(
			this.state.currentMessage &&
				this.state.currentMessage.productId === productId &&
				this.state.currentMessage.messageId === messageId,
		);
	}

	/**
	 * Returns the currentMessage object.
	 */
	public getCurrentMessage() {
		return this.state.currentMessage;
	}

	/**
	 * Returns the queueingBehindMessage object.
	 */
	public getCurrentPendingMessage() {
		return this.state.queueingBehindMessage;
	}

	/**
	 * Clears the timeout that was established for the currently-displayed message.
	 */
	private clearMessageTtl() {
		this.state.currentMessage = null;
		if (this.state.messageTtl) {
			clearTimeout(this.state.messageTtl);
		}
	}

	/**
	 * Disables all plugins that are currently registered with the choreographer, called when a startMessage call is being dispatched.
	 *
	 * @param [exceptFor] An optional plugin instance to exclude from the items being disabled, e.g. the plugin responsible for the current startMessage call.
	 */
	private disablePlugins(exceptFor?: IChoreographerPlugin) {
		this.state.isDisabled = true;
		[...this.state.plugins.values()].forEach((p) => {
			if (p !== exceptFor) {
				p.disable();
			}
		});
	}

	/**
	 * Enables all plugins that are currently registered with the choreographer, called when a matching stopMessage call is successful.
	 */
	private enablePlugins() {
		[...this.state.plugins.values()].forEach((p) => p.enable());
		this.state.isDisabled = false;
	}

	/**
	 * A callback used to remove a plugin from the list of subscribers for the choreographer service.
	 *
	 * @param productId A unique string representing the plugin to be unregistered from the choreographer API.
	 */
	private unregisterPlugin(productId: ProductIds) {
		// If we're unregistering this productId's plugin, and the currentMessage is from this productId, we need to stop it to clear the choreographer
		if (this.state.currentMessage?.productId === productId) {
			void this.stopCurrentMessage();
		}

		this.state.messages.delete(productId);
		this.state.plugins.delete(productId);
	}

	/**
	 * Determines whether any message is currently being displayed, by checking its own status and optionally, asking each registered plugin for its individual status.
	 *
	 * @returns A value reflecting whether a message is currently being displayed on screen.
	 */
	private isDisplayingMessage() {
		return Boolean(
			this.state.currentMessage ||
				[...this.state.plugins.values()].reduce((isDisplayingMessage, p) => {
					return isDisplayingMessage || p.isDisplayingMessage();
				}, false),
		);
	}

	/**
	 * This function is intended for test cleanup purposes only, *not* to be called by external callers.
	 *
	 * @ignore
	 * @param productId Unique identifier for the product whose messages are being requested
	 * @returns A Map of IMessage objects, keyed by unique messageId strings.
	 */
	public getMessages(productId: ProductIds): IChoreographerMessages {
		return this.state.messages.get(productId) ?? (new Map() as IChoreographerMessages);
	}

	/**
	 * Requests to start a message via the choreographer service, ensuring only a single message instance at a time on the screen.
	 * A successful request will block any other message attempts until such time as the matching stopMessage request is received,
	 * or a message TTL timeout has expired.
	 *
	 * @param productId Unique identifier for the product making the current startMessage request that triggered this message timeout.
	 * @param messageId Unique identifier for the messageId that triggered this message timeout.
	 * @param analyticsClient The analytics client to be used for logging/metrics.
	 * @param metricsData Additional data from plugin to be sent for metrics logging.
	 * @param [plugin] An optional plugin instance to prevent from being disabled when this request starts.
	 * @returns A Promise that resolves to a MessageDeliveryStatus value, to denote the ultimate status of the attempted message start operation.
	 */
	public async startMessage(
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData: OperationalEventPayload['attributes'] = {},
		plugin?: IChoreographerPlugin,
	): Promise<StartMessageDeliveryStatuses> {
		if (isChoreographerSuppressed()) {
			if (this.hasMessageRecord(productId, messageId)) {
				const message = this.ensureMessageRecord(productId, messageId);

				const didStart = (await message.start?.()) ?? true;

				if (didStart) {
					// Fire the onStart callback, if provided, to handle any post-start concerns for the consumer
					message.onStart?.();

					return MessageDeliveryStatus.STARTED;
				}

				return MessageDeliveryStatus.CANCELED;
			}

			return MessageDeliveryStatus.UNREGISTERED;
		}

		const debugLogger = new DebugLogger(productId, messageId);
		const actionSubject = MessageAction.START;
		const requestTimestamp = performance.now();

		debugLogger.log({
			info: { phase: actionSubject, timestamp: requestTimestamp },
			verbose: {
				phase: actionSubject,
				timestamp: requestTimestamp,
			},
		});

		const attributes = { ...metricsData, productId, messageId };
		const logger = new Logger(actionSubject, messageId, attributes, analyticsClient);

		try {
			if (!this.hasMessageRecord(productId, messageId)) {
				// Bail early here, before even checking if we're in a queueing situation, or blocked, or anything else
				logger.unregistered();

				debugLogger.log({
					verbose: {
						phase: actionSubject,
						disposition: MessageDeliveryStatus.UNREGISTERED,
					},
				});

				return MessageDeliveryStatus.UNREGISTERED;
			}

			// If we're currently queueing messages, push this one onto the queue and return the new Promise
			if (this.state.shouldQueueMessages) {
				debugLogger.log({
					verbose: {
						phase: actionSubject,
						disposition: `queued behind ${this.state.queueingBehindMessage?.productId} ${this.state.queueingBehindMessage?.messageId}`,
					},
				});

				return this.queueMessage(productId, messageId, analyticsClient, metricsData, plugin);
			}

			if (this.state.isDisabled || this.isDisplayingMessage()) {
				logger.blocked({
					blockedByProductId: this.state.currentMessage?.productId,
					blockedByMessageId: this.state.currentMessage?.messageId,
				});

				debugLogger.log({
					verbose: {
						phase: actionSubject,
						disposition: MessageDeliveryStatus.BLOCKED,
						blockedByProductId: this.state.currentMessage?.productId,
						blockedByMessageId: this.state.currentMessage?.messageId,
					},
				});

				return MessageDeliveryStatus.BLOCKED;
			}

			const message = this.ensureMessageRecord(productId, messageId);

			this.queueMessages(productId, messageId);

			const didStart = (await message.start?.()) ?? true;

			if (didStart) {
				// Fire the onStart callback, if provided, to handle any post-start concerns for the consumer
				message.onStart?.();
				logger.started();
				this.disablePlugins(plugin);
				this.setMessageTtl(productId, messageId, analyticsClient, metricsData);
				// Successfully started the message, flush the queue and mark any pending requests as "blocked"
				this.destroyMessageQueue();

				debugLogger.log({
					verbose: {
						phase: actionSubject,
						disposition: MessageDeliveryStatus.STARTED,
					},
				});

				return MessageDeliveryStatus.STARTED;
			}

			// Check to see if we have any pending requests in the message queue and attempt to start the next one in line
			this.processQueuedMessage();

			debugLogger.log({
				verbose: {
					phase: actionSubject,
					disposition: MessageDeliveryStatus.CANCELED,
				},
			});

			return MessageDeliveryStatus.CANCELED;
		} catch (error) {
			const e = error as Error;
			logger.error({
				reason: e.message,
			});

			debugLogger.log({
				verbose: {
					phase: actionSubject,
					disposition: MessageDeliveryStatus.ERROR,
				},
			});

			return MessageDeliveryStatus.ERROR;
		}
	}

	/**
	 * Requests to stop a message via the choreographer service. A successful request will allow any other plugins to begin issuing startMessage requests again.
	 * This version of the stopMessage function is private, and only intended to be called internally, so that it can make use of the forceStop parameter without exposing that to external callers.
	 *
	 * @param productId Unique identifier for the product making the current stopMessage request.
	 * @param messageId Unique identifier for the messageId to be stopped.
	 * @param analyticsClient The analytics client to be used for logging/metrics.
	 * @param metricsData Additional data from plugin to be sent for metrics logging.
	 * @param forceStop A boolean to denote whether to forcefully clear the Choreographer's blocked state (used for TTL expiration and unregistering a plugin).
	 * @returns A Promise that resolves to a MessageDeliveryStatus value, to denote the ultimate status of the attempted message stop operation.
	 */
	private async stopMessageInternal(
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData: OperationalEventPayload['attributes'] = {},
		forceStop = false,
	): Promise<StopMessageDeliveryStatuses> {
		if (isChoreographerSuppressed()) {
			if (this.hasMessageRecord(productId, messageId)) {
				const message = this.ensureMessageRecord(productId, messageId);

				const didStop = (await message.stop?.()) ?? true;

				if (didStop) {
					// Fire the onStop callback, if provided, to handle any post-stop concerns for the consumer
					message.onStop?.();

					return MessageDeliveryStatus.STOPPED;
				}

				return MessageDeliveryStatus.CANCELED;
			}

			return MessageDeliveryStatus.UNREGISTERED;
		}

		const debugLogger = new DebugLogger(productId, messageId);
		const actionSubject = MessageAction.STOP;
		const requestTimestamp = performance.now();

		debugLogger.log({
			info: { phase: actionSubject, timestamp: requestTimestamp },
			verbose: { phase: actionSubject, timestamp: requestTimestamp },
		});

		const attributes = { ...metricsData, productId, messageId };
		const logger = new Logger(actionSubject, messageId, attributes, analyticsClient);

		try {
			if (!this.hasMessageRecord(productId, messageId)) {
				logger.unregistered();

				debugLogger.log({
					verbose: {
						phase: actionSubject,
						disposition: MessageDeliveryStatus.UNREGISTERED,
					},
				});

				return MessageDeliveryStatus.UNREGISTERED;
			}

			const message = this.ensureMessageRecord(productId, messageId);

			// Enable the message queue *if* the current stopMessage request matches the currentMessage data.
			// Any incoming startMessage requests will be queued while this stopMessage request is in-flight,
			// so that they can be immediately honored if this request comes back successfully.
			if (this.isCurrentMessage(productId, messageId)) {
				this.queueMessages(productId, messageId);
			}

			const didStop = (await message.stop?.()) ?? true;

			// If we're hitting this from stopCurrentMessage, it means either a TTL has expired, or we're
			// unregistering a plugin. We forceStop the choreographer blocked state here, even if the stop
			// callback returned false.We don't want to block the UI indefinitely, we'll just have to risk
			// 1 additional message collision if the current message's stop callback refuses to clear.
			if (didStop || forceStop) {
				if (this.isCurrentMessage(productId, messageId)) {
					// Fire the onStop callback, if provided, to handle any post-stop concerns for the consumer
					message.onStop?.();
					// Only log this even if we're stopping the current message, so we have a 1:1 mapping of starts to stops.
					// If this is a forced stop, log that in the attributes, so we can track those events specifically.
					// Also log the value of didStop, so we know if this was a rejected stop attempt that *had* to be force stopped.
					logger.stopped(forceStop ? { forceStop, didStop } : undefined);
					// Only clear the message TTL and reenable plugins if we're stopping the *current* message
					this.clearMessageTtl();
					this.enablePlugins();
				}

				// Check to see if we have any pending requests in the message queue and attempt to start them
				this.processQueuedMessage();

				debugLogger.log({
					verbose: {
						phase: actionSubject,
						disposition: MessageDeliveryStatus.STOPPED,
					},
				});

				return MessageDeliveryStatus.STOPPED;
			}

			// Stopping the message failed, so the UI is still intentionally blocked, flush the queue and mark any pending requests as "blocked"
			this.destroyMessageQueue();

			debugLogger.log({
				verbose: {
					phase: actionSubject,
					disposition: MessageDeliveryStatus.CANCELED,
				},
			});

			return MessageDeliveryStatus.CANCELED;
		} catch (error) {
			const e = error as Error;
			logger.error({
				reason: e.message,
			});

			debugLogger.log({
				verbose: {
					phase: actionSubject,
					disposition: MessageDeliveryStatus.ERROR,
				},
			});

			return MessageDeliveryStatus.ERROR;
		}
	}

	/**
	 * Requests to stop a message via the choreographer service. A successful request will allow any other plugins to begin issuing startMessage requests again.
	 *
	 * @param productId Unique identifier for the product making the current stopMessage request.
	 * @param messageId Unique identifier for the messageId to be stopped.
	 * @param analyticsClient The analytics client to be used for logging/metrics.
	 * @param metricsData Additional data from plugin to be sent for metrics logging.
	 * @returns A Promise that resolves to a MessageDeliveryStatus value, to denote the ultimate status of the attempted message stop operation.
	 */
	public async stopMessage(
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData: OperationalEventPayload['attributes'] = {},
	): Promise<StopMessageDeliveryStatuses> {
		return this.stopMessageInternal(productId, messageId, analyticsClient, metricsData);
	}

	/**
	 * Registers a plugin instance with the choreographer API. Allows the choreographer to enable and disable it as needed to ensure individual message experiences on screen.
	 *
	 * @param productId Unique identifier to register as the product owner of this plugin.
	 * @param plugin A plugin instance to be registered with the choreographer instance.
	 * @returns A callback to be used to unregister this plugin with the choreographer instance, for cleanup purposes, if necessary.
	 */
	public registerPlugin(productId: ProductIds, plugin: IChoreographerPlugin): () => void {
		if (this.state.plugins.has(productId)) {
			throw new Error(`Choreographer plugin already registered for ${productId}!`);
		}

		this.state.plugins = this.state.plugins.set(productId, plugin);

		if (this.state.isDisabled) {
			plugin.disable();
		}

		return () => {
			this.unregisterPlugin(productId);
		};
	}

	/**
	 * Validates that a message record is present and ready to be retrieved and/or manipulated.
	 * Creates the message instance if it doesn't yet exist, initializing both start and stop callbacks to noop functions.
	 *
	 * @param productId Unique identifier to retrieve map of IMessage objects.
	 * @param messageId Unique identifier for the messageId to be retrieved.
	 * @returns IMessage object for the requested productId/messageId combination.
	 */
	private ensureMessageRecord(productId: ProductIds, messageId: string) {
		let productMessages = this.state.messages.get(productId);

		if (!productMessages) {
			productMessages = new Map();
			this.state.messages.set(productId, productMessages);
		}

		let message = productMessages.get(messageId);

		if (!message) {
			message = {};
			productMessages.set(messageId, message);
		}

		return message;
	}

	/**
	 * Checks for the existance of a message record for a given productId/messageId combination.
	 *
	 * @param productId Unique identifier to check for a map of IMessage objects.
	 * @param messageId Unique identifier to check for within the map of productId IMessage objects.
	 * @returns Boolean indicating whether or not an IMessage record exists for the requested productId/messageId combination.
	 */
	private hasMessageRecord(productId: ProductIds, messageId: string) {
		return Boolean(this.state.messages.get(productId)?.has?.(messageId));
	}

	/**
	 * Subscribes callbacks to be used for starting and stopping message requests through the choreographer, keyed by a combination of productId and messageId.
	 *
	 * @param productId Unique identifier to be used as a primary key for this message subscription.
	 * @param messageId Unique identifier within the context of all productId message entries, to be used as a secondary key for this message subscription.
	 * @param options An object containing optional start and stop callback functions, to be invoked upon successful requests to startMessage and stopMessage, respectively.
	 * @param finalizers An object containing optional onStart and onStop callback functions, to be invoked upon successful starts and stops of messages, respectively.
	 */
	public on(
		productId: ProductIds,
		messageId: string,
		options: Partial<IMessage> = {},
		finalizers: Partial<IMessageFinalizers> = {},
	): void {
		const message = {
			...this.ensureMessageRecord(productId, messageId),
			...options,
			...finalizers,
		};
		this.state.messages.get(productId)!.set(messageId, message);
	}

	/**
	 * Subscribes a callback to be used for starting message requests through the choreographer, keyed by a combination of productId and messageId.
	 *
	 * @param productId Unique identifier to be used as a primary key for this message subscription.
	 * @param messageId Unique identifier within the context of all productId message entries, to be used as a secondary key for this message subscription.
	 * @param startCallback A callback to be invoked upon successful requests to startMessage.
	 * @param finalizers An object containing optional onStart and onStop callback functions, to be invoked upon successful starts and stops of messages, respectively.
	 */
	public onStart(
		productId: ProductIds,
		messageId: string,
		startCallback: IMessage['start'],
		finalizers?: Partial<IMessageFinalizers>,
	): void {
		this.on(
			productId,
			messageId,
			{
				start: startCallback,
			},
			finalizers,
		);
	}

	/**
	 * Subscribes a callback to be used for stopping message requests through the choreographer, keyed by a combination of productId and messageId.
	 *
	 * @param productId Unique identifier to be used as a primary key for this message subscription.
	 * @param messageId Unique identifier within the context of all productId message entries, to be used as a secondary key for this message subscription.
	 * @param stopCallback A callback to be invoked upon successful requests to stopMessage.
	 * @param finalizers An object containing optional onStart and onStop callback functions, to be invoked upon successful starts and stops of messages, respectively.
	 */
	public onStop(
		productId: ProductIds,
		messageId: string,
		stopCallback: IMessage['stop'],
		finalizers: Partial<IMessageFinalizers> = {},
	): void {
		this.on(
			productId,
			messageId,
			{
				stop: stopCallback,
			},
			finalizers,
		);
	}

	/**
	 * Unsubscribes a message's start and stop message callbacks from the choreographer, keyed by a combination of productId and messageId.
	 *
	 * @param productId Unique identifier to be used as a primary key for unsubscribing this message.
	 * @param messageId Unique identifier within the context of all productId message entries, to be used as a secondary key for unsubscribing this message.
	 */
	public off(productId: ProductIds, messageId: string): void {
		this.state.messages.get(productId)?.delete?.(messageId);
	}
}
