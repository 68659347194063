import { useEffect } from 'react';

import { ExposureTriggerReason } from '@atlaskit/feature-flag-client';
import type { TrackFeatureFlagOptions } from '@atlaskit/feature-flag-client/types';

import { useSessionData } from './useSessionData';

export const useTrackFeatureFlag = (
	featureName: string,
	options: TrackFeatureFlagOptions = {
		triggerReason: ExposureTriggerReason.AutoExposure,
	},
) => {
	const sessionData = useSessionData();

	useEffect(() => {
		// Many tests do not define sessionData and/or featureFlagClient and/or trackFeatureFlag
		sessionData?.featureFlagClient?.trackFeatureFlag?.(featureName, options);
	}, [featureName, options, sessionData?.featureFlagClient]);
};
