import type { ForgeDoc, Render } from '@atlassian/forge-ui-types';
import type { ButtonGroupProps as PlatformButtonGroupProps } from '@atlaskit/button';
import type { ButtonProps } from '@atlaskit/button';

export type ButtonGroupProps = Pick<
	PlatformButtonGroupProps,
	'children' | 'testId' | 'label' | 'titleId'
> & { appearance?: ButtonProps['appearance'] };

/**
 * Applying the appearance prop directly to the children forgedocs.
 * It is because the logic for applying appearance to the children components
 * through React.cloneComponent can only be applied to the Forge component wrapper,
 * and not to the actual Button element.
 */
const applyAppearanceToChildren = (
	children: ForgeDoc[],
	render: Render,
	appearance?: ButtonProps['appearance'],
) => {
	if (!appearance) {
		return children.map(render);
	}
	return children.map((child) => {
		if (child.type !== 'String') {
			return render({
				...child,
				props: {
					appearance,
					...child.props,
				},
			});
		}
		return render(child);
	});
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::10523cdb8adecaa74cfa0b8cec0874b4>>
 * @codegenId #button-group
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen button-group
 * @codegenParams { "defineOwnType": true, "customProps": ["appearance"], "props": { "children": { "value": "applyAppearanceToChildren(props.forgeDoc.children, props.render, appearance)", "comment": "apply consistent appearance to children elements" }, "testId": true, "label": true, "titleId": true } }
 * @codegenDependency ../../../../../../../design-system/button/src/containers/button-group.tsx <<SignedSource::b6fdd663bc4c5d4ce25292a9be241bbf>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ButtonGroup as PlatformButtonGroup } from '@atlaskit/button';

// Define the type for ButtonGroup as ButtonGroupProps at the top of this file

export const ButtonGroup = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
    label,
    titleId,
    appearance,
  } = props.forgeDoc.props as ButtonGroupProps;
  return (
    <PlatformButtonGroup
      // apply consistent appearance to children elements
      children={applyAppearanceToChildren(props.forgeDoc.children, props.render, appearance)}
      testId={testId}
      label={label}
      titleId={titleId}
    />
  );
};
/**
 * @codegenEnd
 */
