import React from 'react';
import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import { LazyQuestions } from '../lazyComponents';

export function QuestionRoute({ params: { spaceKey, questionId } }: RouteMatch) {
	return <LazyQuestions spaceKey={spaceKey} questionId={questionId} />;
}

QuestionRoute.NAVIGATION_PARAMS = ({ params: { spaceKey, questionId } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'questionScreen',
			questionId,
		},
		pageState: { spaceKey },
	},

	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
