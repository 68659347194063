import gql from 'graphql-tag';

export const featureDiscovery = gql`
	query featureDiscovery {
		featureDiscovery {
			pluginKey
			featureKey
			userKey
			date
		}
	}
`;
