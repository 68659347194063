import gql from 'graphql-tag';

export const HomeRecentWorkQuery = gql`
	query HomeRecentWorkQuery(
		$cloudId: ID!
		$first: Int
		$after: String
		$objectTypes: [ActivitiesObjectType!]
		$includeAlias: Boolean = false
	) {
		activities {
			myActivities {
				all(
					filters: [
						{
							type: AND
							arguments: { products: [CONFLUENCE], objectTypes: $objectTypes, cloudIds: [$cloudId] }
						}
					]
					first: $first
					after: $after
				) {
					edges {
						cursor
						node {
							id
							timestamp
							object {
								id
								events {
									eventType
								}
								content {
									id
									title
									subType
									type
									links {
										webui
										editui
									}
									metadata {
										sourceTemplateEntityId
									}
									space {
										alias @include(if: $includeAlias)
										id
										key
										name
									}
									status
									blank
									emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
										nodes {
											id
											key
											value
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
