/* eslint-disable no-useless-constructor */
import type { ObjectKey, TaskState } from '@atlaskit/task-decision/types';

type OnFlushCallback = (batch: TaskAction[]) => void;

export type TaskAction = {
	contentId?: string; // Optional identifier for contentId
	objectKey: ObjectKey;
	state: TaskState;
};

/**
 * A mechanism for batching and de-duping task actions. This is based on the Batcher class used by CustomMonitoringClient.
 * A batch of task actions is created based on the interval time. When the interval begins, we compute task actions and then
 * call the necessary task actions.
 */
export class TaskBatcher {
	private flushIntervalId: number | undefined;
	private onFlushCallback: OnFlushCallback | undefined;
	private currentBatch: TaskAction[] = [];

	constructor(private flushIntervalMs: number) {}

	public add = (item: TaskAction): void => {
		this.scheduleFlush();

		// If there's a previous task action for the same task, remove it
		this.currentBatch = this.currentBatch.filter(
			(task) => task.objectKey.localId !== item.objectKey.localId,
		);

		this.currentBatch.push(item);
	};

	public onFlush(callback: OnFlushCallback) {
		if (!(callback instanceof Function)) {
			throw new Error(
				`"onFlush" callback is supposed to be a function; saw ${typeof callback} instead`,
			);
		}

		this.onFlushCallback = callback;
	}

	/**
	 * Forcefully flushes the batcher. Noop if current batch is empty.
	 */
	public forceFlush(): void {
		// Currently force-flush is synchronous. Make sure the call sites are updated if
		// this is changed to a promise-based approach
		this.flush();
	}

	private flush = (): void => {
		const batch = this.renewBatch();
		this.unscheduleFlush();

		if (batch.length === 0) {
			return;
		}

		try {
			if (this.onFlushCallback) {
				this.onFlushCallback(batch);
			}
		} catch (e) {
			// rethrow error asynchronously to allow other callbacks to execute
			setTimeout(() => {
				/* eslint-disable-next-line no-console */
				console.error(
					`"onFlush" callback threw the below error. Please make sure your callbacks have proper error handling`,
				);
				throw e;
			}, 0);
		}
	};

	private renewBatch(): TaskAction[] {
		const oldBatch = this.currentBatch;
		this.currentBatch = [];

		return oldBatch;
	}

	private scheduleFlush() {
		if (!this.flushIntervalId) {
			this.flushIntervalId = window.setInterval(this.flush, this.flushIntervalMs);
		}
	}

	private unscheduleFlush() {
		if (this.flushIntervalId) {
			window.clearInterval(this.flushIntervalId);
			this.flushIntervalId = undefined;
		}
	}
}
