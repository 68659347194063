import type { Operation } from 'apollo-link';
import type { DefinitionNode, OperationDefinitionNode } from 'graphql';

function isOperationDefinitionNode(node: DefinitionNode): node is OperationDefinitionNode {
	return 'operation' in node;
}

function hasDirective(name: string) {
	return (operation: Operation): boolean => {
		if (!operation.query.definitions) return false;
		for (const { operation: type, directives } of operation.query.definitions.filter(
			isOperationDefinitionNode,
		)) {
			if (type === 'query' || type === 'mutation') {
				return (directives ?? []).some((directive) => directive.name.value === name);
			}
		}
		return false;
	};
}

// keeping this for now since we don't actually check for directives
// on subscription types
function isWSOperation() {
	return (operation: Operation) => {
		if (!operation.query.definitions) return false;
		for (const { operation: type } of operation.query.definitions.filter(
			isOperationDefinitionNode,
		)) {
			if (type === 'subscription') {
				return true;
			}
		}
		return false;
	};
}

export const isExperimental = hasDirective('experimental');
export const isSLA = hasDirective('SLA');
export const isWS = isWSOperation();
