import React, { Component } from 'react';
import ErrorPanel from '../components/UIKit1/errorPanel/errorPanel';

export class StyleError extends Error {
	_issue: string;
	_property: string;

	get issue() {
		return this._issue;
	}

	get property() {
		return this._property;
	}

	set property(value: string) {
		this._property = value;
	}

	constructor({ issue, message }: { issue: string; message: string }) {
		super(message);
		this._issue = issue;
		this._property = '';
		// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, StyleError.prototype);
	}
}

interface State {
	error: StyleError | null;
}

class StyleErrorBoundary extends Component<React.PropsWithChildren<{}>, State> {
	public state: State = {
		error: null,
	};

	public componentDidCatch(error: Error) {
		if (!(error instanceof StyleError)) {
			throw error;
		}

		this.setState({
			error,
		});
	}

	public render() {
		const { error } = this.state;

		if (!error) {
			return this.props.children;
		}

		return (
			<ErrorPanel
				error={{
					name: 'style-error',
					message: `Error was thrown on style property: ${error.property}`,
					errorMessage: `${error.issue}\n${error.message}`,
				}}
				isReloadPage
			/>
		);
	}
}

export default StyleErrorBoundary;
