import React, { lazy, type ReactElement, useContext, useEffect, useState } from 'react';
import { type ForgeDoc, type FormData, type RenderFn } from '@atlassian/forge-ui-types';
import { AkButton, useNativeButton } from '../button';
import { ButtonSet } from '../../UIKit1/button/ButtonSet';
import { emotionInstance } from '../../../context/emotion';
import { token } from '@atlaskit/tokens';

export const AkForm = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_form" */
		'@atlaskit/form'
	).then((module) => ({ default: module.default })),
);
export const AkFormFooter = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_form" */
		'@atlaskit/form'
	).then((module) => ({ default: module.FormFooter })),
);

export const AkFormField = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_form" */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

export const isActionButton = (auxElement: ForgeDoc) => {
	return auxElement.key && auxElement.key.startsWith('actionButton');
};

type PropsOf<T extends (props: any) => React.ReactNode> = Parameters<T>[0];

export function useForm({ forgeDoc, dispatch, render }: Parameters<RenderFn>[0]): {
	actions: React.ReactNode;
	akFormProps: PropsOf<typeof AkForm> & { key: React.Key };
	akFormFooterProps: PropsOf<typeof AkFormFooter>;
	akSubmitButtonProps: PropsOf<typeof AkButton>;
	children: React.ReactNode;
	htmlFormProps: React.DetailedHTMLProps<
		React.FormHTMLAttributes<HTMLFormElement>,
		HTMLFormElement
	>;
} {
	const {
		props: {
			submitButtonText = 'Submit',
			onSubmit,
			submitButtonAppearance = 'default',
			alignFooterButtons = 'start',
		} = {},
	} = forgeDoc;
	const [loading, setLoading] = useState(false);

	/**
	 * Toggle the Form's React key to remount the component for clearing fields
	 * Using the formProps' reset() would trigger our custom form validation for isRequired on some fields
	 */
	const [formKey, setFormKey] = useState<number>(0);

	const onSubmitHandler = async (formData: FormData) => {
		setLoading(true);
		try {
			await onSubmit(formData);
			setFormKey(Number(!formKey));
		} finally {
			setLoading(false);
		}
	};

	const akSubmitButtonProps = {
		...useNativeButton({
			forgeDoc: {
				type: 'Button',
				props: { appearance: submitButtonAppearance },
				children: [
					{
						type: 'String',
						children: [],
						props: {
							text: submitButtonText,
						},
					},
				],
			},
			dispatch,
			render,
		}).akButtonProps,
		onClick: undefined,
		type: 'submit' as const,
		isLoading: loading,
	};

	const children = forgeDoc.children.filter((x) => !isActionButton(x));
	const actions =
		forgeDoc.props?.actionButtons?.map(
			// actionButton is of type ReactElement because the @forge/react custom reconciler
			// does not reconcile components in props into ForgeDoc
			(actionButton: ReactElement) => {
				const { children, ...restProps } = actionButton.props;

				return {
					...actionButton,
					props: {
						...restProps,
						appearance: 'subtle',
					},
					children,
				};
			},
		) || [];

	return {
		akFormProps: {
			key: formKey,
			onSubmit: onSubmitHandler,
			children: () => {
				throw Error('@atlassian/forge-ui: Invalid composition of UI Kit useForm and Atlaskit Form');
			},
		},
		akSubmitButtonProps,
		htmlFormProps: {
			// @ts-ignore
			'data-testid': 'form',
			className: emotionInstance.css`
        width: 100%;
        margin-top: 0;
        > *:not(footer) {
          margin-top: 0;
        }
        > *:not(:last-child) {
          margin-bottom: ${token('space.100', '8px')};
        }
        ${
					children.length === 0
						? `
            > footer {
              margin-top: 0;
            }
            `
						: ''
				}
      `,
		},
		akFormFooterProps: {
			align: alignFooterButtons,
		},
		children: children.map(render),
		actions: actions.length > 0 ? actions.map(render) : null,
	};
}

export const FormContext = React.createContext<{
	formValues: Record<string, any>;
	setFormValue: (name: string, value: any) => void;
} | null>(null);

type FormStateChangeNotifierProps = {
	name: string;
	value: any;
};

export const FormStateChangeNotifier: React.FunctionComponent<FormStateChangeNotifierProps> = ({
	name,
	value,
}) => {
	const formContext = useContext(FormContext);
	useEffect(() => {
		formContext?.setFormValue(name, value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, Array.isArray(value) ? value.join() : value]);
	return null;
};

export function Form(props: Parameters<RenderFn>[0]) {
	const { actions, akFormFooterProps, akFormProps, akSubmitButtonProps, children, htmlFormProps } =
		useForm(props);

	const {
		shouldFitContainer,
		iconBefore,
		iconAfter,
		isLoading: loading,
		isDisabled: disabled,
		appearance,
		onClick,
		type,
		children: buttonChildren,
	} = akSubmitButtonProps;

	const { align } = akFormFooterProps;
	const { key, onSubmit } = akFormProps;

	// @ts-ignore Ignoring the fact that "data-testid" is not part of the declared type
	const { className, 'data-testid': dataTestId } = htmlFormProps;

	const [formValues, setFormValues] = useState({});
	const setFormValue = (name: string, value: any) => {
		setFormValues((prevState) => ({ ...prevState, [name]: value }));
	};
	return (
		<AkForm key={key} onSubmit={onSubmit}>
			{({ formProps }) => {
				const { onSubmit, onKeyDown, ref } = formProps;
				return (
					<form
						onSubmit={onSubmit}
						onKeyDown={onKeyDown}
						ref={ref}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={className}
						data-testid={dataTestId}
					>
						<FormContext.Provider value={{ formValues, setFormValue }}>
							{children}
						</FormContext.Provider>
						<AkFormFooter align={align}>
							{align === 'start' ? (
								<ButtonSet>
									<AkButton
										shouldFitContainer={shouldFitContainer}
										iconBefore={iconBefore}
										iconAfter={iconAfter}
										isLoading={loading}
										isDisabled={disabled}
										appearance={appearance}
										onClick={onClick}
										type={type}
									>
										{buttonChildren}
									</AkButton>
									{actions}
								</ButtonSet>
							) : (
								<ButtonSet>
									{actions}
									<AkButton
										shouldFitContainer={shouldFitContainer}
										iconBefore={iconBefore}
										iconAfter={iconAfter}
										isLoading={loading}
										isDisabled={disabled}
										appearance="primary"
										onClick={onClick}
										type={type}
									>
										{buttonChildren}
									</AkButton>
								</ButtonSet>
							)}
						</AkFormFooter>
					</form>
				);
			}}
		</AkForm>
	);
}
