import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import window from 'window-or-global';

import type { ReferenceEntity } from '@atlaskit/editor-common/extensions';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isViewPage } from '@confluence/named-routes';

export type LegacyMacroReferenceListenerProps = {
	references?: ReferenceEntity[];
	macroContainer: Element | null;
};

const extractExtensionKey = (extensionId: string) => {
	const split = extensionId.split('__');
	if (split.length === 3) {
		return split[1];
	}

	return 'UNKOWN_EXTENSION_KEY';
};

const sendReferenceData = (
	references: ReferenceEntity[],
	extensionId: string,
	createAnalyticsEvent,
) => {
	const eventName = 'data_provider';
	const addonFilter = {
		id: extensionId,
	};
	const eventData = {
		eventData: references,
	};

	const analyticsEvent = createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			containerType: 'page',
			objectType: 'connectAddon',
			source: isViewPage() ? 'viewPageScreen' : 'editPageScreen',
			action: 'broadcasted',
			actionSubject: 'connectRefEventData',
			attributes: {
				extensionKey: extractExtensionKey(extensionId),
			},
		},
	});
	analyticsEvent.fire();
	// @ts-ignore
	window.connectHost.broadcastEvent(eventName, addonFilter, eventData);
};

export const LegacyMacroReferenceListener: FC<LegacyMacroReferenceListenerProps> = ({
	references,
	macroContainer,
}) => {
	const [extensionId, setExtensionId] = useState<string | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (extensionId && Array.isArray(references) && references.length > 0) {
			sendReferenceData(references, extensionId, createAnalyticsEvent);
		}
	}, [references, extensionId, createAnalyticsEvent]);

	const listeningForInitialization = useCallback(
		(event: MessageEvent) => {
			if (
				event.data?.type !== 'event_query' ||
				!event.data?.eid ||
				!event.data?.args?.includes?.('data_provider')
			) {
				return;
			}

			const iframeEl = document.getElementById(event.data.eid);
			if (iframeEl && macroContainer?.contains?.(iframeEl)) {
				setExtensionId(event.data.eid);
			}
		},
		[macroContainer, setExtensionId],
	);

	// Starts listening for macro initialization
	useEffect(() => {
		window.addEventListener('message', listeningForInitialization);

		return () => {
			window.removeEventListener('message', listeningForInitialization);
		};
	}, [listeningForInitialization]);

	return null;
};
