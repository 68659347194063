export const START = 'navigationSessionStart';
export const START_TOUCH = 'navigationSessionStartTouch';
export const TOUCH = 'navigationSessionTouch';
export const END = 'navigationSessionEnd';

export type NavdexPointType =
	| 'navigationSessionStart'
	| 'navigationSessionStartTouch'
	| 'navigationSessionTouch'
	| 'navigationSessionEnd';
