import type { MessageDescriptor } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { isUnauthorizedError } from '@confluence/unauthorized-error-boundary';

import { messages } from './messages';

type ExpectedError = {
	description: MessageDescriptor;
	title: MessageDescriptor;
} | null;

const isParentPageMovedError = (error: any) => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some((graphQLError: any) =>
			graphQLError.extensions.data.errors.some((nestedError: any) =>
				nestedError.message.key.includes('The parent space does not match the desired space'),
			),
		);
	}
	return false;
};

export const isInvalidParentError = (error: any) => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some((graphQLError: any) =>
			graphQLError.extensions.data.errors.some((nestedError: any) =>
				nestedError.message.key.includes('Invalid potential parent passed'),
			),
		);
	}
	return false;
};

export const getExpectedError = (error: ApolloError | Error | null | undefined): ExpectedError => {
	if (isParentPageMovedError(error)) {
		return {
			title: messages.whiteboardParentPageMovedErrorTitle,
			description: messages.whiteboardParentPageMovedErrorDescription,
		};
	} else if (isInvalidParentError(error)) {
		return {
			title: messages.whiteboardInvalidParentErrorTitle,
			description: messages.whiteboardInvalidParentErrorDescription,
		};
	} else if (isUnauthorizedError(error)) {
		return {
			title: messages.whiteboardPermissionRemovedErrorTitle,
			description: messages.whiteboardPermissionRemovedErrorDescription,
		};
	}
	return null;
};
