import type { FC } from 'react';
import React, { memo, useContext } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { FlagsStateContainer } from '@confluence/flags';
import {
	BannerContext,
	BannerStateContainer,
	FloatingBannerStateContainer,
} from '@confluence/banners';
import { useATLGeneralWebPanelListener } from '@confluence/web-panel-location';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';

import type { ADFRendererProps } from './ADFRendererComponent';
import { ADFRendererComponent } from './ADFRendererComponent';

type StickyHeaderProps = {
	offsetTop: number;
	defaultScrollRootId_DO_NOT_USE?: string;
	shouldAddDefaultScrollRootOffsetTop_DO_NOT_USE?: boolean;
};

export const ADFRenderer: FC<ADFRendererProps> = memo((props: ADFRendererProps) => {
	const isNav4Enabled = useIsNav4Enabled();

	const { includeTopNav } = useContext(BannerContext);
	const { atlGeneralWebPanelHeight } = useATLGeneralWebPanelListener();

	return (
		<Subscribe to={[FlagsStateContainer, BannerStateContainer, FloatingBannerStateContainer]}>
			{(
				flags: FlagsStateContainer,
				bannerState: BannerStateContainer,
				floatingBanners: FloatingBannerStateContainer,
			) => {
				const topNavAndBannerHeight = bannerState.getTotalHeight(includeTopNav);
				// if page header is fixed at the top, it adds extra 55px offset
				// However, when there is a theme applied to a space, page headers are not fixed at the top and will not require additional offset.
				const pageHeaderAndBannerHeight =
					floatingBanners.getFloatingHeight() +
					(props.isContentHeaderFixedAtTop ? 55 + atlGeneralWebPanelHeight : 0);

				const stickyTableHeaderOffsetTopForNav3 = topNavAndBannerHeight + pageHeaderAndBannerHeight;
				//topNavAndBannerHeight is outside the scroll root in Nav4
				const stickyTableHeaderOffsetTopForNav4 = pageHeaderAndBannerHeight;

				let stickyTableHeaderConfig: StickyHeaderProps = {
					offsetTop: stickyTableHeaderOffsetTopForNav3,
				};
				if (isNav4Enabled && fg('confluence_nav_4_table_sticky_headers')) {
					stickyTableHeaderConfig = {
						offsetTop: stickyTableHeaderOffsetTopForNav4,
						defaultScrollRootId_DO_NOT_USE: 'AkMainContent',
						shouldAddDefaultScrollRootOffsetTop_DO_NOT_USE: true,
					};
				}

				// WARNING: PERFORMANCE: <ADFRendererComponent> has been split out from <ADFRenderer>
				// because the child function of <Subscribe> component is called 4-5 times per every
				// transition or initial load. This caused AkRenderer component to be re-rendered the
				// same amount of times, which is quite bad for performance. Split allows to memoize
				// the <ADFRendererComponent> and render it only once.
				//
				// The fact that <ADFRenderer> is used for comments as well makes it even more
				// important to *not* re-render AkRenderer more than once for a page or comment.
				return <ADFRendererComponent flags={flags} {...stickyTableHeaderConfig} {...props} />;
			}}
		</Subscribe>
	);
});

ADFRenderer.displayName = 'ADFRenderer';
