/* eslint react/prop-types:0 */
import React from 'react';

import { useSessionData } from '@confluence/session-data';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { ADMIN_SPACE_PERMISSIONS } from '@confluence/named-routes';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

export const DefaultSpacePermissionsPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencesite-permissionsentry-pointsDefaultSpacePermissions" */ '@confluence/default-space-permissions/entry-points/DefaultSpacePermissionsPage'
			)
		).DefaultSpacePermissionsPage,
});

export const DefaultSpacePermissionsRoute = () => {
	const { edition } = useSessionData();
	const isFreeEdition = edition === ConfluenceEdition.FREE;

	if (isFreeEdition) {
		return <Redirection name={ADMIN_SPACE_PERMISSIONS.name} />;
	}

	return <DefaultSpacePermissionsPage activeTab={'defaults'} />;
};

DefaultSpacePermissionsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'defaultSpacePermissionsScreen' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
