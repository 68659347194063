import type { Mark, Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type {
	ExtensionParams,
	ReferenceEntity,
	Parameters,
} from '@atlaskit/editor-common/extensions';
import type { DataSourceProvider } from './data-source-provider';
import type { NodeMutationObserver } from './node-mutation-observer';
import type { FragmentAttributes } from '@atlaskit/adf-schema/schema';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { JSONNode } from '@atlaskit/editor-json-transformer';
import type { EditorAnalyticsAPI } from '@atlaskit/editor-common/analytics';
import type { EditorProps } from '@atlaskit/editor-core';

export type LocalId = FragmentAttributes['localId'];

export enum ADFReferenceTypes {
	TABLE = 'table-adf',
}

export enum MEASURE_NAME {
	INITIALISE_FRAGMENT_MARKS = 'initialiseFragmentMarks',
	CONNECT_NODES = 'connectNodes',
	UPDATE_SOURCE = 'updateSource',
	DISCONNECT_SOURCE = 'disconnectSource',
	UPDATE_TARGET = 'updateTarget',
	DISCONNECT_TARGET = 'disconnectTarget',
	UPDATE_FRAGMENT_MARK_NAME = 'updateFragmentMarkName',
	GET_CONNECTIONS = 'getConnections',
}

export interface ReferentialityPluginState {
	dataSourceProvider: DataSourceProvider;
	nodeMutationObserver: NodeMutationObserver;
	changedSources: Map<number, PMNode>;
	selectedNodeLocalId?: string;
}

export type ADFReference = {
	[prop in ADFReferenceTypes]: ADFEntity;
};

type ExternalReference = {
	[prop: string]: Object;
};

export type ExternalObserver = {
	init: (input: { emit: (localId: string, data: ExternalReference) => void }) => void;
	destroy: () => void;
};

export type ExtensionHandlerWithReferenceFn<T extends Parameters> = (
	extensionParams: ExtensionParams<T>,
	fn: (param: { references: ReferenceEntity[] }) => JSX.Element,
) => JSX.Element;

export type NodeNamingHandler = ({ node }: { node: JSONNode }) => string;
export type NodeFocusHandler = (linkNode?: LinkNode) => void;

export type NormalizedConnection = {
	node: PMNode;
	pos: number;
	parent: PMNode;
	normalizedId: LocalId;
	name: string;
	ids: Set<LocalId>;
	targets: Set<LocalId>;
	dataConsumer?: Mark;
	fragmentMark?: Mark;
	decodedName?: Nullable<{ defaultName: string; defaultNameNumber: number }>;
};

export type LinkNode = {
	readonly localId: LocalId;
	readonly name: string;
	readonly node: {
		readonly type: string;
		readonly attrs: { readonly [attr: string]: any };
	};
};

export type ConnectionLink = {
	readonly linkNode: LinkNode;
	readonly sources: LocalId[];
	readonly targets: LocalId[];
};

export type NewTableLinkElement = {
	type: 'table';
};

export type NewExtensionLinkElement = {
	type: 'inlineExtension' | 'extension' | 'bodiedExtension';
	attrs?: ADFEntity['attrs'];
};

// At the moment having tables be targets is not supported
export type NewLinkElement = NewTableLinkElement | NewExtensionLinkElement;

export interface ReferentialityAPI {
	/** Adds fragmentMark to all accessable nodes. To set custom names, call setNodeNameCallback API first. */
	initialiseFragmentMarks(): void;
	/** Collates a map of all dataConsumer linkages from within the current document. */
	getConnections(): Record<LocalId, ConnectionLink>;
	/** Selects a node with provided localId */
	select(localId: LocalId, scroll: boolean): void;
	/** Updates a nodes fragment mark to have a name */
	updateName(localId: LocalId, name: string): LinkNode;
	/** Connects a source to a target */
	connectSource(targetLocalId: LocalId, sourceLocalId: LocalId): LinkNode;
	/** Disconnects all sources from a target */
	disconnectSource(targetLocalId: LocalId): void;
	/** Resets the target sources to consume only the new source */
	updateSource(targetLocalId: LocalId, newSourceLocalId: LocalId): LinkNode;
	/** Inserts a new source and connects the target to it */
	insertAndConnectSource(targetLocalId: LocalId, source: NewLinkElement): LinkNode;
	/** Inserts a new source and replaces the target sources to use the new source */
	replaceAndUpdateSource(targetLocalId: LocalId, newSource: NewLinkElement): LinkNode;
	/** Connects a target to a source */
	connectTarget(sourceLocalId: LocalId, targetLocalId: LocalId): LinkNode;
	/** Disconnects a target from a source */
	disconnectTarget(sourceLocalId: LocalId, targetLocalId: LocalId): void;
	/** Disconnects an old target from a source and connects a new target */
	updateTarget(
		sourceLocalId: LocalId,
		oldTargetLocalId: LocalId,
		newTargetLocalId: LocalId,
	): LinkNode;
	/** Inserts a new element as a target and connects it to a source */
	insertAndConnectTarget(sourceLocalId: LocalId, target: NewLinkElement): LinkNode;
	/** Disconnects an old target and inserts and connects a new element to a source */
	replaceAndUpdateTarget(
		sourceLocalId: LocalId,
		oldTargetLocalId: LocalId,
		newTarget: NewLinkElement,
	): LinkNode;
	/** Gets an ADF representation of the node */
	getADFFromLocalId(localId: LocalId): JSONNode;
	/** Sets a callback to create custom names. It should be called prior to initialiseFragmentMarks. */
	setNodeNameCallback(callback: NodeNamingHandler | null): void;
	/** Sets a callback to move focus, used by setSelectedLocalId */
	setNodeFocusCallback(callback: NodeFocusHandler | null): void;
}

export interface AttachedEditorView {
	readonly isAttached: boolean;
	attach(editorView: EditorView): void;
	detach(): void;
	setSelectedLocalId(localId?: LocalId): void;
}

export type ReferentialityAPIOptions = {
	nodeNameCallback?: NodeNamingHandler;
	nodeFocusCallback?: NodeFocusHandler;
	editorAnalyticsAPI?: EditorAnalyticsAPI;
	editorView?: EditorView;
	featureFlags?: EditorProps['featureFlags'];
	__livePage?: boolean;
};

export type NormalizeLocalIdHandler = (
	attrsLocalId?: LocalId,
	fragmentLocalId?: LocalId,
) => LocalId;
