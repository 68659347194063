import { type envType } from '@atlassiansox/analytics-web-client';

import { type ProductIds } from '../constants';

import { ChoreographerPlugin } from './ChoreographerPlugin';

/**
 * Quick utility class to provide a ready-to-use solution for the most common use case within products.
 */
export class ProductChoreographerPlugin extends ChoreographerPlugin {
	constructor(product: ProductIds, env: envType, metaMetricsData = {}) {
		super(product, env, metaMetricsData);
	}
}
