import { useStableObject } from '@atlassian/post-office-context';
import { PlacementProvider } from '@post-office/placement-context';

import type { PlacementProviderProps } from './types';
import type { StableProvider } from '../../types';

export const StablePlacementProvider: StableProvider<PlacementProviderProps> = ({
	children,
	placement,
}) => {
	const stableValue = useStableObject(placement);

	return <PlacementProvider value={stableValue}>{children}</PlacementProvider>;
};
