/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { FC } from 'react';
import React, { useState, useContext, useEffect, useRef } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { DataSourceProvider as ReferentialityDataSourceProvider } from '@atlassian/editor-referentiality';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

import { requireLegacyWRM } from '@confluence/wrm';
import { evalLegacyConnectInlineScripts } from '@confluence/connect-utils';
import { ADFRenderer } from '@confluence/adf-renderer';
import {
	LegacyMacroStyledElement,
	FabricLegacyMacrosServerRenderer,
	useSSRedLegacyMacroElement,
} from '@confluence/content-renderer-legacy-macros';
import {
	scopeCSS,
	mainContentSelector,
} from '@confluence/content-renderer-legacy-macros/entry-points/scopeStyleMacro';
import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';
import { ExtensionLoadingComplete } from '@confluence/extensions-performance';
import { LegacyUserElementsHover } from '@confluence/profile';
import { getLogger } from '@confluence/logger';
import { EDITOR, RENDERER, PDF, MacroExperienceSuccess } from '@confluence/macro-tracker';
import {
	teamCalendarWRM,
	loadMacroWebResources,
} from '@confluence/fabric-extension-lib/entry-points/extensions-common';
import {
	INLINE_EXTENSION_TYPE,
	MULTI_BODIED_EXTENSION_TYPE,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import { withNavigationPolicy } from '@confluence/route-manager';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { ClassicEditorContext } from '@confluence/editor-features/entry-points/ClassicEditorContext';
import {
	checkIsDPModuleDefined,
	loadDataProviderModule,
} from '@confluence/app-referentiality/entry-points/DataProviderModule';
import {
	defineMacroModule,
	MultiBodiedExtensionHandler,
} from '@confluence/editor-multi-bodied-extension';

import type { ComponentProps } from './macroRendererTypes';
import { LegacyMacroReferenceListener } from './LegacyMacroReferenceListener';
import { LegacyMacroRendererError } from './LegacyMacroRendererError';
import { useShouldHideOverlay } from './useShouldHideOverlay';
import { useLinkIntercept } from './useLinkIntercept';

// The rendered HTML currently outputs a div that contains a macro id attribute
// `data-macro-id="XYZ"`. We can parse that and get the ID
const idFromStorageFormat = /data-macro-id="([0-9a-fA-F\-]+?)"/;
// this will be fallback pattern in case div does not contain the macro id attribute.
// Parse in from data variable in the script tag.Pattern looks like `\\\"macro.id\\\":\\\"XYZ\\\"`
const idFromADF = /macro\.id\\":\\"([0-9a-fA-F\-]+?)\\"/;
const logger = getLogger('LegacyMacroRenderer');

const Overlay = () => (
	<div
		data-testid="legacy-macro-overlay"
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			position: 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			top: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			bottom: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			left: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			right: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			cursor: 'default',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			zIndex: 2,
		}}
	/>
);

/**
 * CEMS-919 and HOT-84603
 * ---------
 * Current implementation of the Team Calendars Plugin does not account for
 * a SPA transition. It only loads the calendars once and forgets about
 * re-initializing them once the page is unmounted. For this, we need to
 * invoke the assets when necessary and initialize the calendars. Also reference:
 * confluence-frontend-server/src/components/ContentBody/ContentBodyDefaultComponent.js
 * @private
 */
const initializeCalendarIfNeeded = async (extensionKey: string) => {
	if (extensionKey === 'calendar') {
		requireLegacyWRM(
			teamCalendarWRM(),
			() => {
				try {
					window.require('tc/init-resources').requireResources();
				} catch (err) {
					logger.error(err);
				}
			},
			() => {
				logger.error`Error loading team calendar WRM!`;
			},
		);
	}
};

const getMacroId = (adf, macroRenderedOutput): string => {
	const idFromNode = adf?.attrs?.parameters?.macroMetadata?.macroId?.value;
	if (idFromNode) return idFromNode;

	let matches = idFromStorageFormat.exec(macroRenderedOutput || '');
	if (matches?.[1]) return matches[1];
	matches = idFromADF.exec(macroRenderedOutput || '');
	if (matches?.[1]) return matches[1];

	logger.error`Cannot find macro id from ${macroRenderedOutput}`;
	return '';
};

const MACROS_TO_URL_SHIM = [
	'include',
	'contentbylabel',
	'children',
	'content-report-table',
	'index',
	'pagetree',
	'tasks-report-macro',
	'recently-updated-dashboard',
	'recently-updated',
	'toc',
];

export const LegacyMacroRendererComponentClass: FC<ComponentProps> = withNavigationPolicy(
	({
		adf,
		macroRepresentation,
		macroRenderedOutput,
		mode,
		node,
		contentId,
		attributes,
		experienceName,
		featureFlags,
		webresource,
		body,
		parameters,
		navigationPolicy,
		references,
		multiBodiedExtensionActions,
		macroRenderedOutputFromSSR,
		macroOutput,
		context,
		spaceKey,
		providers,
		mediaToken,
		noOverlay,
		isWithinEditor,
		isPreviewMode,
	}) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const isInline = adf.type === INLINE_EXTENSION_TYPE;
		const isMultiBodied = adf.type === MULTI_BODIED_EXTENSION_TYPE;
		const extensionKey = adf?.attrs?.extensionKey || '';
		const scriptContainer = useRef<HTMLDivElement>(null);
		if (!macroRenderedOutput) macroRenderedOutput = '';

		if (extensionKey && macroRenderedOutput) {
			// If a Tiny page with Style Macro gets converted to Fabric, need to Scope the CSS
			// When in edit mode, the <style> tag don't have data-macro-name="style" attribute, so need to pass a different regex
			if (extensionKey === 'style') {
				macroRenderedOutput = scopeCSS(
					macroRenderedOutput,
					mainContentSelector,
					/(<style[^>]*>)((.|\n|\r)*?)(<\/style>)/gm,
				).contentWithScopedCSS as string;
			} else {
				// Other extensionKeys could have nested Style Macro
				macroRenderedOutput = scopeCSS(macroRenderedOutput, mainContentSelector)
					.contentWithScopedCSS as string;
			}
		}
		const shouldHideOverlay = useShouldHideOverlay(extensionKey);

		/**
		 * Callback to be triggered when web resources are resolved.
		 * This is to make sure certain macros e.g. Gadget Macro only to render output after web resources loaded.
		 * For more context of the issue: https://hello.atlassian.net/wiki/spaces/~462393321/pages/1007234825/Debugging+Legacy+Jira+gadgets+within+Connie
		 * https://jira.atlassian.com/browse/CONFCLOUD-70573
		 */
		const [delayedOutput, setDelayedOutput] = useState<string>(
			extensionKey === 'gadget' ? '' : macroRenderedOutput,
		);
		const [container, setContainer] = useState<Element | null>(null);
		const [isMacroResourcesLoaded, setIsMacroResourcesLoaded] = useState<boolean>(false);

		useEffect(() => {
			if (container) {
				const loadMacroResources = async () => {
					const isDPModuleDefined = checkIsDPModuleDefined();

					if (!isDPModuleDefined) {
						const isViewPage = mode === RENDERER;
						await loadDataProviderModule({
							logger,
							isViewPage,
							createAnalyticsEvent,
						});
					}

					if (isMultiBodied) {
						await defineMacroModule({
							logger,
						});
					}

					await loadMacroWebResources(
						webresource,
						macroRenderedOutputFromSSR,
						macroOutput,
						() => {
							if (delayedOutput !== macroRenderedOutput) {
								setDelayedOutput(macroRenderedOutput as string);
							}
						},
						scriptContainer?.current,
					);
					setIsMacroResourcesLoaded(true);
				};
				void loadMacroResources();
				void evalLegacyConnectInlineScripts(container);
				void initializeCalendarIfNeeded(extensionKey);
			}
		}, [
			webresource,
			extensionKey,
			macroRenderedOutput,
			delayedOutput,
			container,
			scriptContainer,
			macroRenderedOutputFromSSR,
			macroOutput,
			mode,
			createAnalyticsEvent,
			isMultiBodied,
		]);

		useLinkIntercept(extensionKey ?? '', container, mode === EDITOR || isWithinEditor);

		const macroId = getMacroId(adf, macroRenderedOutput);
		const { ssredElement } = useSSRedLegacyMacroElement(macroId);

		const nodeProps = {
			ref: setContainer,
		};
		const macroDataProps = {
			'data-fabric-macro': macroId,
			'data-macro-body': body || '',
			'data-macro-parameters': parameters || '',
			'data-node-type': extensionKey === 'include' ? 'include' : undefined,
		};

		// Use parent id if the macro source is different than the current page
		const getContentId = (adf, contentId) => {
			const parentId = adf?.attrs?.parameters?.macroParams?._parentId?.value;
			return parentId || contentId;
		};

		const { getExtensionHandlers } = providers;

		const extensionHandlers = getExtensionHandlers
			? getExtensionHandlers({
					adf:
						macroRepresentation == 'atlas_doc_format'
							? macroRenderedOutputFromSSR || macroOutput || macroRenderedOutput
							: adf,
					contentId: getContentId(adf, contentId),
					spaceKey,
					macroRenderedOutput: macroRenderedOutputFromSSR || macroOutput || macroRenderedOutput,
					shouldIncludeMacroWithReferencesHandlers: true,
					referentialityDataSourceProvider: new ReferentialityDataSourceProvider(),
					mediaToken,
				})
			: {};

		const elementProps = {
			adf,
			isInline,
			contentId: getContentId(adf, contentId),
			mode,
			extensionKey,
			nodeProps,
			macroRepresentation,
			macroDataProps,
			macroRenderedOutput: delayedOutput,
			extensionHandlers,
			mediaToken,
			ssredElement,
			delayIframe: false,
		};

		if (mode === RENDERER || mode === PDF) {
			try {
				const macroId = attributes?.macroId ? attributes.macroId : '';
				const type = attributes?.type ? attributes.type : 'none';
				// on view page it will send macro load event
				// it will help to alert in case of multiple rendering of macro
				// event name: macro renderer (client)
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'renderer',
						actionSubject: 'macro',
						source: 'legacyMacroRenderer',
						attributes: {
							mode,
							extensionKey,
							macroId,
							contentId,
							type,
						},
					},
				}).fire();
			} catch (err) {
				logger.error`An error occurred while firing the analytics event for legacyMacroRendererComponent: ${err}`;
			}

			/**
			 * https://product-fabric.atlassian.net/browse/CCP-1798
			 * If macroRenderedOutputFromSSR or macroOuput are passed as a prop, they will be used for SPA rehydration (rather
			 * than the MacroContentQuery). macroRenderedOutputFromSSR / macroOutput already have JS/CSS coming from ContentUnifiedQuery,
			 * so here we use the base components of LegacyMacroRendererComponent, but instead
			 * render FabricLegacyMacrosServerRenderer, which sets JS/CSS internally.
			 * https://product-fabric.atlassian.net/browse/DLRN-3182
			 * If macroOutput exists, it contains the full html (as a string) required to render the macro.
			 */
			if (macroRenderedOutputFromSSR || macroOutput) {
				const macroDefaultProps: ExtensionHandlerProps = {
					node,
					context,
					references,
					contentId,
					spaceKey: spaceKey || '',
					mode: RENDERER,
					extensionKey,
					attributes,
				};

				return (
					<UFOLoadHold
						name="LegacyMacroRendererComponentResourcesSSR"
						hold={!isMacroResourcesLoaded}
					>
						<LegacyMacroReferenceListener references={references} macroContainer={container} />
						<ExtensionLoadingComplete
							extensionKey={attributes?.extensionKey || extensionKey}
							contentId={contentId}
							node={node}
						/>
						<FabricLegacyMacrosServerRenderer
							macroRenderedOutput={macroRenderedOutputFromSSR}
							macroDefaultProps={macroDefaultProps}
							containerRef={setContainer}
							macroOutput={macroOutput}
						/>
						<LegacyUserElementsHover node={container} />
						{
							// WS-3295 - DO NOT REMOVE THIS LOADER
							// We need to load the legacy editor here to allow users to create/edit/view comments with the attachments macro
							extensionKey === 'attachments' && !isPreviewMode && (
								<RendererExtensionContext.Consumer>
									{({ EditorLoaderLoader }) => {
										// isWithinEditor will be true if the attachments macro is within a macro
										// that itself is being rendered in the editor, e.g., excerpt-include.
										return (
											!isWithinEditor &&
											!isPreviewMode &&
											EditorLoaderLoader && <EditorLoaderLoader />
										);
									}}
								</RendererExtensionContext.Consumer>
							)
						}
						<MacroExperienceSuccess
							name={experienceName}
							contentId={contentId}
							extensionKey={extensionKey}
							mode={mode}
							attributes={attributes}
						/>
					</UFOLoadHold>
				);
			}

			return (
				<UFOLoadHold
					name="LegacyMacroRendererComponentResourcesNonSSR"
					hold={!isMacroResourcesLoaded}
				>
					<LegacyMacroReferenceListener references={references} macroContainer={container} />
					{isMultiBodied && (
						<MultiBodiedExtensionHandler
							macroId={macroId}
							macroParams={parameters}
							editorActions={multiBodiedExtensionActions}
							readonly
						/>
					)}

					{/* we don't want to push elements down so display none*/}
					<div
						dangerouslySetInnerHTML={{
							__html: `${webresource?.tags?.css || ''}`,
						}}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ display: 'none' }}
					/>
					<ExtensionLoadingComplete
						extensionKey={attributes?.extensionKey || extensionKey}
						contentId={contentId}
						node={node}
					/>
					<LegacyMacroStyledElement
						{...elementProps}
						macroRenderedOutput={
							MACROS_TO_URL_SHIM.includes(extensionKey)
								? navigationPolicy.shimHtmlUrls(delayedOutput)
								: delayedOutput
						}
					/>
					<LegacyUserElementsHover node={container} />
					{
						// WS-3295 - DO NOT REMOVE THIS LOADER
						// We need to load the legacy editor here to allow users to create/edit/view comments with the attachments macro
						extensionKey === 'attachments' && !isPreviewMode && (
							<RendererExtensionContext.Consumer>
								{({ EditorLoaderLoader }) => {
									// isWithinEditor will be true if the attachments macro is within a macro
									// that itself is being rendered in the editor, e.g., excerpt-include.
									return !isWithinEditor && EditorLoaderLoader && <EditorLoaderLoader />;
								}}
							</RendererExtensionContext.Consumer>
						)
					}
					<div
						key="js-container"
						ref={scriptContainer}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ display: 'none' }}
					/>
					<MacroExperienceSuccess
						name={experienceName}
						contentId={contentId}
						extensionKey={extensionKey}
						mode={mode}
						attributes={attributes}
					/>
				</UFOLoadHold>
			);
		}

		let parsedDelayedOutput;
		let parseError;
		let component;

		try {
			if (macroRepresentation === 'atlas_doc_format') {
				parsedDelayedOutput = JSON.parse(delayedOutput);
				component = (
					<ADFRenderer
						document={parsedDelayedOutput}
						contentId={getContentId(adf, contentId)}
						featureFlags={featureFlags ?? {}}
						extensionHandlers={extensionHandlers}
						mediaToken={mediaToken}
						appearance="full-width"
					/>
				);
			}
		} catch (e) {
			parseError = e;
			logger.error`An error occurred while parsing adf output: ${delayedOutput}`;
			component = (
				<LegacyMacroRendererError
					error={parseError}
					node={node}
					mode={mode}
					extensionKey={extensionKey}
					contentId={contentId}
				/>
			);
		}

		return (
			<UFOLoadHold
				name="LegacyMacroRendererComponentResourcesFabric"
				hold={!isMacroResourcesLoaded}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: `${webresource?.tags?.css || ''}`,
					}}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={{ display: 'none' }}
				/>
				{isMultiBodied && (
					<MultiBodiedExtensionHandler
						macroId={macroId}
						macroParams={parameters}
						editorActions={multiBodiedExtensionActions}
					/>
				)}

				<LegacyMacroStyledElement {...elementProps}>
					{noOverlay || shouldHideOverlay || isMultiBodied ? null : <Overlay />}

					<LegacyMacroReferenceListener references={references} macroContainer={container} />

					{macroRepresentation === 'atlas_doc_format' ? (
						<div data-testid="macro-adf-renderer" ref={setContainer}>
							{component}
						</div>
					) : (
						<div ref={setContainer} dangerouslySetInnerHTML={{ __html: `${delayedOutput}` }} />
					)}

					<MacroExperienceSuccess
						name={experienceName}
						contentId={contentId}
						extensionKey={extensionKey}
						mode={mode}
						attributes={attributes}
					/>
				</LegacyMacroStyledElement>
				<div
					key="js-container"
					ref={scriptContainer}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={{ display: 'none' }}
				/>
			</UFOLoadHold>
		);
	},
);

export const LegacyMacroRendererComponent = (props) => {
	const rendererExtensionProviders = useContext(RendererExtensionContext);
	const classicEditorExtensionProviders = useContext(ClassicEditorContext);
	const isWithinEditor = classicEditorExtensionProviders?.isWithinEditor ?? false;

	const providers = {
		...classicEditorExtensionProviders,
		...rendererExtensionProviders,
	};
	const propsWithProviders = { ...props, providers, isWithinEditor };
	return <LegacyMacroRendererComponentClass {...propsWithProviders} />;
};
