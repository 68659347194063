import type { ArrowKeys } from './types';

export const arrowKeys: Record<string, ArrowKeys> = {
	ArrowDown: 'down',
	ArrowLeft: 'left',
	ArrowRight: 'right',
	ArrowUp: 'up',
};

export const daysPerWeek = 7;

export const monthsPerYear = 12;

export const blankStringArray: Array<string> = [];
