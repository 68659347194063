import type { PerformanceEntryBuffer } from './utils/buffer';

const MIN_LONG_TASK_DURATION = 50;

/**
 * Returns the time to app idle.
 * Looks for the end of first period in `buffer` which has a gap of at least `idleThreshold` after it.
 * Returns `null` if no such period is found.
 * The search is limited to the interval [`start`, `stop`].
 */
export const getTimeToAppIdle = (
	start: number,
	stop: number,
	idleThreshold: number,
	buffer: PerformanceEntryBuffer,
) => {
	const entires = buffer
		.getAll()
		.filter((entry) => entry.duration >= MIN_LONG_TASK_DURATION)
		.map((entry) => ({
			start: entry.startTime,
			stop: entry.startTime + entry.duration,
		}))
		.filter((entry) => entry.stop > start && entry.start < stop)
		.concat([
			// Add a fake entry to simplify the logic below
			{ start: stop, stop },
		])
		.sort((a, b) => a.start - b.start);

	let lastStop = start;
	for (const entry of entires) {
		if (lastStop + idleThreshold < entry.start) {
			return lastStop;
		}

		lastStop = entry.stop;
	}

	return null;
};
