import { fg } from '@confluence/feature-gating';
import { getLatestActiveSlaExperience } from '@confluence/experience-tracker';
import {
	X_ATL_EXPERIENCE,
	X_ATL_EXPERIENCE_ID,
	appendHeader,
	getHeaderValue,
	cfetch,
} from '@confluence/network';

export function createExperienceAwareFetch() {
	if (process.env.REACT_SSR || process.env.NODE_ENV === 'testing') {
		return cfetch;
	}

	return (resource: Parameters<typeof cfetch>[0], init: Parameters<typeof cfetch>[1] = {}) => {
		const activeSlaExperience = getLatestActiveSlaExperience();
		if (activeSlaExperience) {
			init.headers = init.headers || {};
			// Here we're very far from where the query was triggered and we haven't
			// been told explicitly that the query was triggered because of any of the
			// currently active experiences but we're presuming there's a correlation
			// because it's our best available guess. If the header was already added
			// (e.g. when preloading a route on transition) and because we can only
			// send one experience at this time, presume that whoever added the header
			// before us knew better:
			appendHeader(
				init.headers,
				X_ATL_EXPERIENCE,
				getHeaderValue(init.headers, X_ATL_EXPERIENCE) || activeSlaExperience.name,
			);
			appendHeader(
				init.headers,
				X_ATL_EXPERIENCE_ID,
				getHeaderValue(init.headers, X_ATL_EXPERIENCE_ID) ||
					((activeSlaExperience.attributes?.uniqueExperienceId as string) ??
						activeSlaExperience.id),
			);
		}

		// eslint-disable-next-line no-restricted-syntax
		return cfetch(resource, init);
	};
}

export const isExperienceAwareFetchAlwaysEnabled = () => {
	return !process.env.REACT_SSR && fg('confluence_frontend_experience_aware_fetch');
};
