export enum View {
	OVERVIEW = 'overview',
	RECENT = 'recent',
	STARRED = 'starred',
	WATCHING = 'watching',
	DRAFTS = 'drafts',
	TASKS = 'tasks',
}

export const SUPPORTED_ROUTES = [View.RECENT, View.STARRED, View.WATCHING, View.DRAFTS, View.TASKS];
