export const MessageEventType = {
	START: 'start',
	STOP: 'stop',
} as const;

export const MessageDeliveryStatus = {
	BLOCKED: 'blocked',
	CANCELED: 'canceled',
	ERROR: 'error',
	STARTED: 'started',
	STOPPED: 'stopped',
	UNREGISTERED: 'unregistered',
} as const;

export enum ProductIds {
	CONFLUENCE = 'confluence',
	ENGAGEKIT = 'Engagekit',
	INTERCOM = 'intercom',
	JIRA = 'jira',
	PENDO = 'pendo',
	CLOUDADMIN = 'cloudadmin',
	BITBUCKET = 'bitbucket',
	POST_OFFICE = 'post-office',
}

export const MessageTypesEnum = {
	ENGAGEMENT: 'engagement',
	MARKETING: 'marketing',
	TRANSACTIONAL: 'transactional',
} as const;

export const IntegratedChoreographerMessageTypesEnum = [
	MessageTypesEnum.ENGAGEMENT,
	MessageTypesEnum.MARKETING,
] as const;

export const MessageAction = {
	START: 'messageStart',
	STOP: 'messageStop',
} as const;

export const LoggingLevel = {
	INFO: 'info',
	VERBOSE: 'verbose',
} as const;
