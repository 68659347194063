import React, { createContext, useState, useMemo, useContext } from 'react';
import type { ReactNode } from 'react';

type CommentContentContextValue = {
	hasContentChanged: boolean;
	isCommentContentReady: boolean;
};

type CommentContentDispatchContextType = {
	onChange: (hasChanges: boolean) => void;
	resetContentChanged: () => void;
	onCommentContentReady: () => void;
};

export const CommentContentDispatchContext = createContext<CommentContentDispatchContextType>({
	onChange: () => {},
	resetContentChanged: () => {},
	onCommentContentReady: () => {},
});

CommentContentDispatchContext.displayName = 'CommentContentDispatchContext';

export const CommentContentContext = createContext<CommentContentContextValue>({
	hasContentChanged: false,
	isCommentContentReady: false,
});
CommentContentContext.displayName = 'CommentContentContext';

type CommentContentProviderProps = {
	children: ReactNode;
};

export const CommentContentProvider = ({ children }: CommentContentProviderProps) => {
	const [hasContentChanged, setHasContentChanged] = useState<boolean>(false);
	const [isCommentContentReady, setIsCommentContentReady] = useState<boolean>(false);

	const providerValue = useMemo(
		() => ({
			hasContentChanged,
			isCommentContentReady,
		}),
		[hasContentChanged, isCommentContentReady],
	);

	const dispatchProviderValue = useMemo(
		() => ({
			onChange: (hasChanges: boolean) => setHasContentChanged(hasChanges),
			resetContentChanged: () => setHasContentChanged(false),
			onCommentContentReady: () => setIsCommentContentReady(true),
		}),
		[setHasContentChanged, setIsCommentContentReady],
	);

	return (
		<CommentContentContext.Provider value={providerValue}>
			<CommentContentDispatchContext.Provider value={dispatchProviderValue}>
				{children}
			</CommentContentDispatchContext.Provider>
		</CommentContentContext.Provider>
	);
};

export const useCommentContentContext = () => {
	return useContext(CommentContentContext);
};

export const useCommentContentDispatchContext = () => {
	return useContext(CommentContentDispatchContext);
};
