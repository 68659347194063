import { memo } from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import * as Components from './index';
import { forgePropsAreEqual } from '../utils/forgePropsAreEqual';

export const importMap: Record<string, (props: Parameters<RenderFn>[0]) => JSX.Element> = {
	Badge: Components.Badge,
	Bleed: Components.Bleed,
	Box: Components.Box,
	Button: Components.Button,
	ButtonGroup: Components.ButtonGroup,
	Calendar: Components.Calendar,
	Checkbox: Components.Checkbox,
	CheckboxGroup: Components.CheckboxGroup,
	Code: Components.Code,
	CodeBlock: Components.CodeBlock,
	DatePicker: Components.DatePicker,
	DynamicTable: Components.DynamicTable,
	EmptyState: Components.EmptyState,
	ErrorMessage: Components.ErrorMessage,
	Flex: Components.Flex,
	Form: Components.Form,
	FormFooter: Components.FormFooter,
	FormHeader: Components.FormHeader,
	FormSection: Components.FormSection,
	Grid: Components.Grid,
	Heading: Components.Heading,
	HelperMessage: Components.HelperMessage,
	Icon: Components.Icon,
	Inline: Components.Inline,
	InlineEdit: Components.InlineEdit,
	Label: Components.Label,
	LinkButton: Components.LinkButton,
	List: Components.List,
	ListItem: Components.ListItem,
	LoadingButton: Components.LoadingButton,
	Lozenge: Components.Lozenge,
	Modal: Components.Modal,
	ModalBody: Components.ModalBody,
	ModalFooter: Components.ModalFooter,
	ModalHeader: Components.ModalHeader,
	ModalTitle: Components.ModalTitle,
	ModalTransition: Components.ModalTransition,
	Popup: Components.Popup,
	ProgressBar: Components.ProgressBar,
	ProgressTracker: Components.ProgressTracker,
	Radio: Components.Radio,
	RadioGroup: Components.RadioGroup,
	Range: Components.Range,
	RequiredAsterisk: Components.RequiredAsterisk,
	SectionMessage: Components.SectionMessage,
	SectionMessageAction: Components.SectionMessageAction,
	Select: Components.Select,
	Spinner: Components.Spinner,
	Stack: Components.Stack,
	Tab: Components.Tab,
	TabList: Components.TabList,
	TabPanel: Components.TabPanel,
	Tabs: Components.Tabs,
	Tag: Components.Tag,
	TagGroup: Components.TagGroup,
	TextArea: Components.TextArea,
	Textfield: Components.Textfield,
	TimePicker: Components.TimePicker,
	Toggle: Components.Toggle,
	Tooltip: Components.Tooltip,
	ValidMessage: Components.ValidMessage,
};

export const importMapMemo = Object.entries(importMap).reduce<typeof importMap>(
	(agg, [key, Component]) => {
		agg[key] = memo(Component, forgePropsAreEqual) as (typeof importMap)[keyof typeof importMap];
		return agg;
	},
	{},
);
