import { ProductEnvironment, ForgeCDNEnvironment } from '@atlassian/forge-ui-types';
import { isFedRamp } from '@atlassian/atl-context';

export const getCDNEnvironment = (productEnvironment: ProductEnvironment): ForgeCDNEnvironment => {
	if (isFedRamp()) {
		if (productEnvironment === ProductEnvironment.DEVELOPMENT) {
			return ForgeCDNEnvironment.FEDRAMP_SANDBOX;
		} else if (productEnvironment === ProductEnvironment.STAGING) {
			return ForgeCDNEnvironment.STAGING;
		} else {
			return ForgeCDNEnvironment.PRODUCTION;
		}
	}

	if (productEnvironment === ProductEnvironment.DEVELOPMENT) {
		return ForgeCDNEnvironment.DEVELOPMENT;
	} else if (productEnvironment === ProductEnvironment.STAGING) {
		return ForgeCDNEnvironment.STAGING;
	} else {
		return ForgeCDNEnvironment.PRODUCTION;
	}
};
