import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

export const GlobalAutomationRoute = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Automation" */ '@confluence/automation'))
			.Automation,
});

(GlobalAutomationRoute as any).NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'globalAutomationRoute',
		},
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
