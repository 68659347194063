import type { CustomPlugin, CustomValues } from '@atlassian/browser-metrics/types';

import { isInitial } from '../utils/browserMetricsState';

import { getCacheAttributes } from './getCacheAttributes';
import { getjQueryVersion } from './getjQueryVersion';

export const customEventPlugin: CustomPlugin = ({ stop }): CustomValues => {
	const customAttributes: CustomValues = {
		initial: isInitial(),
		criticalAssets: getCacheAttributes(stop),
		jqVer: getjQueryVersion(),
	};
	return customAttributes;
};
