import gql from 'graphql-tag';

export const UpdateLocalStorageMutation = gql`
	mutation UpdateLocalStorageMutation($storageinput: LocalStorageInput!, $stringKeys: [String]!) {
		updateLocalStorage(localStorage: $storageinput) {
			stringValues(keys: $stringKeys) {
				key
				value
			}
		}
	}
`;
