import { pageLoadState } from './utils/pageLoadState';

interface keyable {
	[key: string]: any;
}
interface userSessionInfo {
	userId: string | null;
	cloudId: string | null;
}

export const getUniquePageLoadId = () => {
	pageLoadState.currentSavedTimestamp = performance.now();
	return {
		uuid: pageLoadState.uuid,
		contentId: pageLoadState.pageId,
		isInitial: pageLoadState.transitionId === 0,
		isSSR: Boolean(window['__SSR_RENDERED__']),
		routingDuration: pageLoadState.currentSavedTimestamp - pageLoadState.routingTimestamp,
	};
};

export const getCurrentSavedTimeStamp = () => {
	return pageLoadState.currentSavedTimestamp;
};

export const setUniquePageLoadId = (
	routeName: string | null = '',
	match: keyable,
	transitionId: number,
	userInfo: userSessionInfo,
) => {
	const contentId = match?.params?.contentId;
	const useUserInfo = userInfo.userId;
	if (pageLoadState.pageId !== contentId || pageLoadState.routeName !== routeName) {
		let savedUniqueId;
		if (useUserInfo) {
			savedUniqueId = createPageLoadId(routeName, contentId, userInfo);
		} else {
			savedUniqueId = createUniqueId();
		}
		pageLoadState.uuid = savedUniqueId;
		pageLoadState.routeName = routeName;
		pageLoadState.pageId = contentId;
		pageLoadState.transitionId = transitionId;
		pageLoadState.routingTimestamp = performance.now();
	}
};

// refer to https://gist.github.com/jed/982883 for uuid generation
// For Anonymous users
const createUniqueId = () => {
	return `${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/1|0/g, function () {
		// This is a LEGACY disable statement after rule addition. Use `Math.floor()` next time
		// eslint-disable-next-line no-bitwise
		return (0 | (Math.random() * 16)).toString(16);
	});
};

// For Atlassian users
const createPageLoadId = (
	routeName: string | null,
	contentId: string | null,
	userInfo: userSessionInfo,
) => {
	const { userId, cloudId } = userInfo;
	routeName = routeName || 'route';
	contentId = contentId || 'page';
	// TO-DO ask Dennis if he wants to take any of these out
	return `${routeName}-${contentId}-${userId}-${cloudId}-${Date.now()}`;
};
