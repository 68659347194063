import { API_PATHNAME_STG_PROD } from '../constants';
import { defaultEnvironmentValues } from '../index';
import type { PostOfficeEnvironmentValues } from '../types';

type BuiltBasePath = {
	path: string;
	baseUrl?: string;
};

export const buildBasePath = (
	currentEnv: PostOfficeEnvironmentValues['currentEnv'],
	envConfigs: PostOfficeEnvironmentValues['envConfigs'] = defaultEnvironmentValues.envConfigs,
): BuiltBasePath => {
	const baseUrl = envConfigs[currentEnv].api.origin;

	// TypeScript & Safety reasons: Final default to staging-prod API path in case path got overriden somewhere to be undefined, but this should not be the case
	const path = envConfigs[currentEnv].api.pathname ?? API_PATHNAME_STG_PROD;

	return {
		baseUrl,
		path,
	};
};
