import type { FC, RefObject } from 'react';
import React, { memo, useMemo } from 'react';

import { withOutline } from '../tooling/outline';

import { getAndIncreaseServerMarkupPointer, getServerMarkup } from './placeholders';
import { ReactBasedPlaceholder } from './ReactBasedPlaceholder';

type LoadablePlaceholderProps = {
	debugName?: string;
	placeholderId?: string;
	children?: React.ReactNode;
	tag?: string;
	ssrPlaceholderId?: string;
	ssrPlaceholderIgnored?: boolean;
};

type LoadablePlaceholderClientProps = LoadablePlaceholderProps & {
	clientPlaceholder?: any;
	outlineJestEnforce?: boolean;
	elementRef?: RefObject<HTMLElement | null>;
};

export const LoadablePlaceholderServer: FC<LoadablePlaceholderProps> = ({
	debugName,
	placeholderId,
	children,
	tag = 'input',
	ssrPlaceholderId,
	ssrPlaceholderIgnored,
}) => {
	// We are kind of messed up by also executing supposedly server-side code on client-side.
	if (!process.env.REACT_SSR) {
		return <>{children}</>;
	}

	const debugProp: Record<string, any> =
		process.env.NODE_ENV !== 'production' ? { 'data-debug-name': debugName } : {};

	const ssrPlaceholderIdProp: Record<string, any> = ssrPlaceholderId
		? { 'data-ssr-placeholder-id': ssrPlaceholderId }
		: {};

	const hidden = tag === 'input' ? { type: 'hidden' } : {};
	const ssrPlaceholderIgnoredProp: Record<string, any> = ssrPlaceholderIgnored
		? { 'data-ssr-placeholder-ignored': true }
		: {};

	return (
		<>
			{React.createElement(tag, {
				...hidden,
				'data-loadable-begin': placeholderId,
				...ssrPlaceholderIdProp,
				...ssrPlaceholderIgnoredProp,
				...debugProp,
			})}
			{children}
			{React.createElement(tag, {
				...hidden,
				'data-loadable-end': placeholderId,
			})}
		</>
	);
};

export const LoadablePlaceholderClient: FC<LoadablePlaceholderClientProps> = memo(
	({
		placeholderId,
		clientPlaceholder,
		outlineJestEnforce, // used only for jest test as jest cannot set module level sessionStorage
		elementRef,
		...rest
	}) => {
		let shouldOutline = false;
		try {
			// handle The "operation is insecure" when cookie is disabled
			shouldOutline =
				typeof window !== 'undefined' &&
				Boolean(window?.sessionStorage?.getItem('confluence.ssr-outline-enabled'));
		} catch (e) {}

		const content = useMemo(() => {
			if (!placeholderId) return null;
			// One placeholderId could possibly map to multiple different contents.
			// For example, if one page has multiple Table of Content macros with different config, flat or bullet list.
			// The placeholderId represents TableOfContentsLoader, the contents are different for each ToC macro.
			const index = getAndIncreaseServerMarkupPointer(placeholderId);
			return index !== undefined ? getServerMarkup().get(placeholderId)?.[index] : null;
		}, [placeholderId]);
		const showDebugOutline = outlineJestEnforce || shouldOutline;

		if (!placeholderId || !content) {
			const clientPlaceHolderElement = React.createElement(clientPlaceholder, rest);

			// When outlining is enabled, outline client-based placeholders in red
			return showDebugOutline
				? withOutline('red', clientPlaceHolderElement)
				: clientPlaceHolderElement;
		}

		return (
			<ReactBasedPlaceholder
				content={content}
				showDebugOutline={showDebugOutline}
				elementRef={elementRef}
			/>
		);
	},
);
