/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { FC, ReactNode } from 'react';
import React, { useEffect, Fragment } from 'react';

import type { ADNode } from '@atlaskit/editor-common/validator';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ContentUnifiedQueryMacroRenderedOutputType } from '@confluence/content-unified-query';
import { ExtensionSSRMark, getRenderType } from '@confluence/extensions-performance';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { INLINE_EXTENSION_TYPE } from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import {
	extensionToADF,
	getParametersString,
} from '@confluence/fabric-extension-lib/entry-points/editor-extensions';
import { markMissingMacroData } from '@confluence/fabric-extension-lib/entry-points/ssr-macro-analytics-helpers';
import { deferScript, addSSRInlineStyleAttr } from '@confluence/dom-helpers';
import { useSessionData } from '@confluence/session-data';
import {
	ExtensionPlaceholder,
	editToSetupMessage,
} from '@confluence/fabric-extension-lib/entry-points/providers';

import { scopeCSS, mainContentSelector } from '../scopeStyleMacro';
import { LegacyMacroStyledElement } from '../LegacyMacroStyledElement';
import { useSSRedLegacyMacroElement } from '../SSRedLegacyMacro';

import { FabricLegacyMacrosServerLoader } from './FabricLegacyMacrosServerLoader';
import { useDataMacroBody } from './useDataMacroBody';
import { useMacroContentData } from './useMacroContentData';
import { getSsrExtensionHandlers } from './getSsrExtensionHandlers';

export type FabricLegacyMacroContentVariablesType = {
	macroRenderedOutput?: (ContentUnifiedQueryMacroRenderedOutputType | null)[] | null | false;
	macroDefaultProps: ExtensionHandlerProps;
	containerRef?: Function;
	/**
	 * Component to render after content is ready.
	 * Used for rendering performance metrics.
	 */
	renderWhenReady?: () => ReactNode;
	macroOutput?: string | null;
	reuseSSRedElement?: boolean;
};

export const FabricLegacyMacrosServerRenderer: FC<FabricLegacyMacroContentVariablesType> = ({
	macroRenderedOutput,
	macroDefaultProps,
	containerRef,
	macroOutput: rawMacroOutput,
	reuseSSRedElement,
}) => {
	// NOTE: `featureFlags` object here is prop-drilled down through layers
	// of legacy code so the usage of deprecated attribute is retained here.
	const { featureFlags, cloudId, userId } = useSessionData();

	const macroOutput = rawMacroOutput;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { node, contentId, mode, extensionKey } = macroDefaultProps;

	const keys = Object.keys(node);

	keys.forEach((key) => {
		if (node[key] === undefined || node[key] === null) {
			delete node[key];
		}
	});

	const macroId = node.parameters?.macroMetadata?.macroId?.value;

	const isMissingMacroParameters = node.parameters?.macroParams?.isMissingRequiredParameters?.value;

	useEffect(() => {
		if (macroOutput) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'macro.with.macroOutput',
					source: 'FabricLegacyMacrosServerRenderer',
					attributes: {
						extensionKey,
						contentId,
					},
				},
			}).fire();
		}
	}, [macroOutput, createAnalyticsEvent, extensionKey, contentId]);

	const macroData = useMacroContentData(contentId, node, featureFlags);
	const { ssredElement } = useSSRedLegacyMacroElement(macroId);

	const containsMacro = macroRenderedOutput
		? macroRenderedOutput.filter((macro) => macro?.key === macroId)
		: [];
	let macroHtml =
		macroData?.macroBodyRenderer?.value || containsMacro[0]?.value?.value || macroOutput;

	if (extensionKey && macroRenderedOutput) {
		// If a Tiny page with Style Macro gets converted to Fabric, need to Scope the CSS
		// Not limiting extensionKey to "style" only because the Style Macro can be nested in other macros
		macroHtml = scopeCSS(macroHtml, mainContentSelector).contentWithScopedCSS as string;
	}

	const dataMacroBody = useDataMacroBody(node, contentId);

	/**
	 * "tasks-report-macro" has no actually required macro parameters.
	 * Required parameter was introduced for UI only so the macro doesn't try to render all tasks in the instance when it's inserted in editor,
	 *
	 * Due to a bug https://product-fabric.atlassian.net/browse/COMMENTS-177
	 * it has polluted database with wrong isMissingMacroParameters and it prevents SSRing of this particular (and popular) macro.
	 * This check should be removed when we fix the property editor and clean up the DB.
	 */
	const isActuallyMissingMacroParameters =
		isMissingMacroParameters && extensionKey !== 'tasks-report-macro';

	//To match the placeholder from SPA
	if (
		isMissingMacroParameters &&
		extensionKey === 'tasks-report-macro' &&
		!node.parameters?.macroParams?.pages &&
		!node.parameters?.macroParams?.spaces
	) {
		const macro = {
			title: 'Task report',
			macroName: extensionKey,
			icon: {
				height: 80,
				location:
					'/download/resources/com.atlassian.confluence.plugins.confluence-inline-tasks/tasks-report-image-resources/task-report.png',
				relative: true,
				width: 80,
			},
		};

		return <ExtensionPlaceholder isEditor={false} macro={macro} description={editToSetupMessage} />;
	}

	/**
	 *
	 * skip check if macroOutput exists, as it contains all the html
	 * required to render the macro
	 */
	if (!macroOutput && isActuallyMissingMacroParameters) {
		return null;
	}

	const preloadingDataPresent =
		(macroData && !dataMacroBody.missing) || macroData?.macroBodyStorageFormat;
	// If there's no data and no macro data from preloading to render - resort to standard macro preloading
	if (!macroOutput && containsMacro.length === 0 && !preloadingDataPresent) {
		markMissingMacroData(macroId, extensionKey);

		// This logic is applicable to the cases when there's no server output for given macro
		// and therefore macro data loading is deferred till client code takes over. The idea is
		// when macro data is unavailable we attempt preloading it through the query preloader.
		//
		// NOTE: there's a lot of logic around what query to use (see LegacyMacroRendererQuery)
		// and the fact that we may want to THROTTLE macro loading to not overwhelm the backend.
		return <FabricLegacyMacrosServerLoader {...macroDefaultProps} featureFlags={featureFlags} />;
	}

	if (!macroHtml) {
		return null;
	}

	const css =
		macroData?.macroBodyRenderer?.webResourceDependencies?.tags?.css ||
		containsMacro[0]?.value?.webresource?.tags?.css;
	const js =
		macroData?.macroBodyRenderer?.webResourceDependencies?.tags?.js ||
		containsMacro[0]?.value?.webresource?.tags?.js;

	const adf: ADNode = extensionToADF(macroDefaultProps.node);
	const isInline = adf.type === INLINE_EXTENSION_TYPE;

	const macroDataProps = {
		'data-fabric-macro': macroId,
		'data-macro-body': macroData?.macroBodyStorageFormat?.value || dataMacroBody.value,
		'data-macro-parameters': getParametersString(node),
		'data-skate-created':
			(macroData || reuseSSRedElement) && process.env.REACT_SSR ? true : undefined,
	};

	const nodeProps = {
		// ref will be passed in only for SPA in order to load default WRM modules
		ref: containerRef || null,
	};

	const macroRepresentation =
		macroData?.macroBodyRenderer?.representation ||
		containsMacro[0]?.value?.representation ||
		'view';

	// In case of ADF format there might be nested macro(s)
	// so we have to pass extensionHandlers to dispatch them
	const extensionHandlers =
		macroRepresentation === 'atlas_doc_format'
			? (getSsrExtensionHandlers({
					adf: macroHtml,
					contentId,
					userId,
					cloudId,
				}) as any)
			: undefined;

	const mediaToken = macroData?.macroBodyRenderer?.mediaToken?.token;

	const elementProps = {
		adf,
		isInline,
		contentId,
		mode,
		extensionKey,
		macroRepresentation,
		macroDataProps,
		macroRenderedOutput: macroHtml,
		featureFlags,
		nodeProps,
		mediaToken,
		extensionHandlers,
		ssredElement,
		delayIframe: true,
	};

	return (
		<Fragment>
			{/* css tags should be placed inside content div so they can be cleaned up from page to page
      Macro’s CSS needs to be placed before HTML to avoid FOUC*/}
			{css && (
				<div
					key="css-container"
					data-testid="macro_css"
					dangerouslySetInnerHTML={{
						__html: addSSRInlineStyleAttr(css),
					}}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={{ display: 'none' }}
				/>
			)}
			<LegacyMacroStyledElement {...elementProps} />
			<ExtensionSSRMark macroId={macroId} renderType={getRenderType(macroOutput, macroData)} />
			{/* Macro’s JS needs to be executed after the macro’s DOM structure*/}
			{js && (
				<div
					data-testid="macro_js"
					dangerouslySetInnerHTML={{
						__html: `${deferScript(js)}`,
					}}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={{ display: 'none' }}
				/>
			)}
		</Fragment>
	);
};
