import React, { useContext, useMemo } from 'react';

import type { DocNode } from '@atlaskit/adf-schema';
import { filter } from '@atlaskit/adf-utils/traverse';
import type { ADDoc } from '@atlaskit/editor-common/validator';

import { supportedExtensions } from '@confluence/fabric-extension-lib/entry-points/extensionConstants';

interface ExtrensionIndexContextType {
	indexes: Map<string, number>; // [macroId -> index in adf]
}

declare global {
	interface Window {
		unsupportedExtension: { [key: string]: number };
	}
}

export const ExtrensionIndexContext = React.createContext<ExtrensionIndexContextType>({
	indexes: new Map(),
});

type ExtrensionIndexProviderProps = React.PropsWithChildren<{
	document: DocNode;
}>;

const extractExtensions = (adf: ADDoc) => filter(adf, (node) => supportedExtensions.has(node.type));

export const UNKNOWN_INDEX = 'UNKNOWN';

/**
 * The purpose of this provider is to track indexes of extensions withing ADF document being rendered.
 * The index can later be requested via `useExtensionIndex` hook in a nested component.
 * Note: there some legitimate cases where requesting index could return UNKNOWN. For instance, `include` extension
 * may bring with referenced document other extensions which are not present in original ADF,
 * for those the UNKNOWN will be returned.
 */
export const ExtrensionIndexProvider = ({ document, children }: ExtrensionIndexProviderProps) => {
	const indexes = useMemo(() => {
		const extensions = extractExtensions(document as any);

		return new Map(
			extensions.map((node, idx) => {
				window['unsupportedExtension'] = window['unsupportedExtension'] || {};

				if (
					node.attrs?.parameters?.nestedContent?.content &&
					node.attrs.extensionKey ===
						'__confluenceADFMigrationUnsupportedContentInternalExtension__'
				) {
					node.attrs.parameters.nestedContent.content.forEach((each: any) => {
						window['unsupportedExtension'][each.attrs?.parameters?.macroMetadata?.macroId?.value] =
							idx;
					});
				}

				return [node.attrs?.parameters?.macroMetadata?.macroId?.value, idx];
			}),
		);
	}, [document]);
	const value = useMemo(() => ({ indexes }), [indexes]);

	return (
		<ExtrensionIndexContext.Provider value={value}>{children}</ExtrensionIndexContext.Provider>
	);
};

export const useExtensionIndex = (
	macroId: string,
): {
	extensionIndex: number | typeof UNKNOWN_INDEX;
	isUnsupportedExtension: boolean;
} => {
	const { indexes } = useContext(ExtrensionIndexContext);
	let index = indexes.get(macroId);

	const unsupportedExtensions = window['unsupportedExtension'];
	let isUnsupportedExtension = false;

	if (unsupportedExtensions && unsupportedExtensions[macroId]) {
		index = unsupportedExtensions[macroId];
		isUnsupportedExtension = true;
	}

	return {
		extensionIndex: typeof index === 'number' ? index : UNKNOWN_INDEX,
		isUnsupportedExtension,
	};
};
