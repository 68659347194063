import {
	VIEW_PAGE,
	HOME,
	SPACE_OVERVIEW,
	EDIT_PAGE_EMBED,
	EDIT_PAGE_V2,
	EDIT_BLOG_V2,
	EDIT_PAGE,
	EDIT_BLOG,
} from './routes';

export const isViewPage = () => Boolean(VIEW_PAGE.match(window.location.pathname));

export const isHomePage = () => Boolean(HOME.match(window.location.pathname));

export const isSpaceOverview = () => Boolean(SPACE_OVERVIEW.match(window.location.pathname));

export const isEditPage = (routeName: string) => {
	if (!routeName) return false;

	const editRoutes = [
		EDIT_PAGE_EMBED.name,
		EDIT_PAGE_V2.name,
		EDIT_BLOG_V2.name,
		EDIT_PAGE.name,
		EDIT_BLOG.name,
	];

	return editRoutes.includes(routeName);
};
