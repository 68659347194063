import type { BrowserOptions, Event as SentryEvent, EventHint, Hub } from '@sentry/browser';
import memoize from 'memoize-one';

import { liftPromiseState } from '@confluence/lifted-promise';
import { getBuildInfo } from '@confluence/build-info';
import { ConfluenceRestApiError } from '@confluence/network';

import { getLogSafeErrorAttributes } from './error-attributes';

const cloudFrontRegex = /(https?):\/\/[a-z0-9]+\.cloudfront\.net\//g;
const cloudFrontS3Regex = /(https?):\/\/[a-z0-9]+\.cloudfront\.net\/download\//g;

// Used to match web resource URLs like this:
//   .../wiki/s/99914b932bd37a50b983c5e7c90ae93b-CDN/-1510873586/6452/236c4aa50522e3b557db968ff45d01d44604ffe1/1000.0.53/_/download/...
//   .../wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-783152381/h/2ddebfb80ae902fe7ab6e15441fe94e2/_/download/batch/...
//   .../wiki/s/d065fee603fdcf75115204ec65310e1c-CDN/-1510873586/6452/236c4aa50522e3b557db968ff45d01d44604ffe1/11d5083bb8bb9dac4c3a5987eeac4b5b/_/download/contextbatch/...
//   .../wiki/s/1283600969/6452/571d5459a4fbf60c255c41bb4e0d3ef3b8022627/_/download/resources/...
const wrmResourceRegex = /s\/[^_]+_\/download\//g;

/**
 * For values which represent a stringified JSON, tries to extract the `"message"`
 * property and returns it if found. Returned result defaults to input value.
 */
function tryExtractMessage(value: string) {
	try {
		const parsedValue = JSON.parse(value);

		return parsedValue.message || value;
	} catch (_) {
		return value;
	}
}

// Turns the culprit URL into .../wiki/download/... to remove the hash information.
function stripHashAndCDNHost(url: string) {
	return url.match(wrmResourceRegex)
		? url.replace(wrmResourceRegex, 'download/').replace(cloudFrontRegex, '$1://')
		: url;
}

function getErrorFingerprint(error: Error): string[] | undefined {
	// See docs at https://docs.sentry.io/platforms/javascript/data-management/event-grouping/sdk-fingerprinting/
	const DEFAULT_FINGERPRINT = '{{ default }}';

	if (
		error.message.startsWith('Quota exceeded on key') ||
		error.message.startsWith('QuotaExceededError')
	) {
		return ['storage-quota-error'];
	}

	if (
		error.message.startsWith('Maximum call stack size exceeded') ||
		error.message.startsWith('too much recursion')
	) {
		// Most (if not all) of these errors originate from editor/prosemirror, and they
		// are hard to troubleshoot. So, although they all might represent a
		// different error, we're bundling them together
		return ['maximum-call-stack'];
	}

	if (error.message.startsWith('Invalid position')) {
		// These errors originate from editor/prosemirror. The rest of the message
		// is the position number itself, and Sentry seldom groups those together.
		// By giving them all the same fingerprint, we're forcing Sentry to treat all
		// of those as the occurrence of one and the same error.
		return ['invalid-position'];
	}

	if (error.message.match(/Loading chunk .+? failed/)) {
		// These are webpack chunk load errors, which often have very different stack traces.
		// We're grouping them together.
		return ['chunk-load-error'];
	}

	if (error.message.startsWith('Error creating blueprint content:')) {
		// Grouping those by message should work better than looking at the stack trace
		return ['blueprint-creation-error', error.message.substr(0, 100)];
	}

	if (error.message.startsWith('Error from synchrony plugin')) {
		// These are hard to troubleshoot, so to reduce noise we group them together
		return ['error-from-synchrony-plugin'];
	}

	if (
		error.message.includes(
			'Error fetching data from identity - Error has been logged with execution ID:',
		)
	) {
		// The "execution ID" part in these errors is always different.
		return ['identity-fetching-error'];
	}

	if (error.message.includes('java.net.SocketTimeoutException')) {
		return ['socket-timeout'];
	}

	if (error instanceof URIError) {
		// This will group all URI errors together, as they often have different stack traces
		return ['malformed-uri-error'];
	}

	if (error instanceof ConfluenceRestApiError && error.route) {
		// These errors tend to produce unpredictable messages which are hard to group.
		// So, instead of grouping by message, we'll group them by `route` and status code (which should be more predictable)
		return [`rest-api-${error.route}-${error.statusCode}`];
	}

	const logSafeAttributes = getLogSafeErrorAttributes(error);

	if (logSafeAttributes.failedSlaExperience) {
		if (logSafeAttributes.operationName || logSafeAttributes.statusCode) {
			// This code branch is for GraphQL/REST failures which result in experience failures.
			// Since the same backend errors can be thrown for different operations/endpoints,
			// we're applying a per-message grouping.
			// Also, we're **not** including default fingerprint here.
			return [
				`experience-${logSafeAttributes.failedSlaExperience}`,
				tryExtractMessage(error.message).substr(0, 100),
			];
		} else {
			// this way SLA-affecting errors shouldn't be bundled with other types of errors
			return [DEFAULT_FINGERPRINT, `experience-${logSafeAttributes.failedSlaExperience}`];
		}
	}

	return undefined;
}

const dataTransformer = function (
	data: SentryEvent,
	hint: EventHint | undefined,
): PromiseLike<SentryEvent | null> | SentryEvent | null {
	let fromWRM = false;
	let fromS3 = false;

	if (data.exception?.values) {
		// Go through the stack trace and sanitize all the exception URLs.
		data.exception.values = data.exception.values.map((exception) => {
			if (exception.stacktrace && exception.stacktrace.frames) {
				exception.stacktrace.frames = exception.stacktrace.frames.map((frame) => {
					if (frame.filename) {
						fromWRM = Boolean(frame.filename.match(wrmResourceRegex));
						fromS3 = Boolean(frame.filename.match(cloudFrontS3Regex));
						frame.filename = stripHashAndCDNHost(frame.filename);
					}

					return frame;
				});
			}
			return exception;
		});
	}

	if (typeof hint?.originalException === 'object' && hint?.originalException !== null) {
		data.fingerprint = getErrorFingerprint(hint.originalException);
		const {
			componentStack: _1, // this field is very big
			failedSlaExperience: _2, // this one is included as tag
			...rest
		} = getLogSafeErrorAttributes(hint.originalException);
		data.extra = {
			...data.extra,
			...rest,
		};
	}

	if (fromWRM) {
		data.logger = 'wrm';
	} else if (fromS3) {
		data.logger = 'react';
	} else {
		data.logger = 'javascript';
	}

	// strip out url and breadcrumbs which include customer sensitive data
	if (data.request) {
		delete data.request.url;
	}
	delete data.breadcrumbs;
	data.transaction = ''; // transactions can have URLs in them, too

	return data;
};

// Structure: https://<PUBLIC_KEY>@o55978.ingest.sentry.io/<PROJECT_ID>
// See: https://hello.atlassian.net/wiki/spaces/OBSERVABILITY/pages/1436386358/Sentry+Cloud+-+Getting+Started#Setting-up-the-Sentry-SDK-for-your-project
function getSentryDsn() {
	// https://sentry.io/settings/atlassian-2y/projects/confluence/keys/
	const SENTRY_DSN_PROD =
		'https://1f56d81aaaae4b1ea5ca08bca6633223@o55978.ingest.sentry.io/5988811';

	// https://sentry.io/settings/atlassian-2y/projects/confluence-staging/keys/
	const SENTRY_DSN_STAGING =
		'https://ce2ea70bbd2a439793b772cc1d59e2fe@o55978.ingest.sentry.io/5988871';

	if (process.env.NODE_ENV === 'testing') {
		// this is a dummy dsn
		return 'https://abcdef@o55978.ingest.sentry.io/5988871';
	}

	if (process.env.CLOUD_ENV === 'staging') {
		return SENTRY_DSN_STAGING;
	}

	return SENTRY_DSN_PROD;
}

function getSentryEnvironment() {
	const SENTRY_ENV_PROD = 'CONFLUENCE';
	const SENTRY_ENV_STAGING = 'CONFLUENCE-STAGING';
	const SENTRY_ENV_HELLO = 'HELLO';

	if (process.env.NODE_ENV === 'testing') {
		return 'TESTING';
	}

	if (process.env.CLOUD_ENV === 'staging') {
		return SENTRY_ENV_STAGING;
	}

	return process.env.CLOUD_ENV === 'hello' ? SENTRY_ENV_HELLO : SENTRY_ENV_PROD;
}

function getSentryOptions(dsn: string): BrowserOptions {
	const additionalOptions =
		process.env.NODE_ENV === 'testing'
			? { transport: (window as any).__TEST_SENTRY_TRANSPORT }
			: {};

	return {
		release: getBuildInfo().FRONTEND_VERSION,
		dsn,
		environment: getSentryEnvironment(),
		beforeSend(
			event: SentryEvent,
			hint?: EventHint,
		): PromiseLike<SentryEvent | null> | SentryEvent | null {
			return dataTransformer(event, hint);
		},
		ignoreErrors: [
			'Transport destroyed', // Error being thrown by media that won't be resolved any time soon.
			'Worker was destroyed', // Error being thrown by media that won't be resolved any time soon.
			'Failed to connect on any of the ADC ports', // Error being thrown by media that won't be resolved any time soon.
			"Cannot read property 'postMessage' of undefined", // Error being thrown by Connect that won't be resolved any time soon.
			"Cannot read property 'postMessage' of null", // Error being thrown by Connect that won't be resolved any time soon.
			'd.hide is not a function', // From com.atlassian.auiplugin:aui-date-picker,
			/^network error/i, // We can't do anything about network issues
			/^network failure/i, // We can't do anything about network issues
			'TypeError: Failed to fetch', // We can't do anything about network issues
			'ResizeObserver loop limit exceeded', // A benign error, see https://stackoverflow.com/a/50387233/2645305
			/ResizeObserver loop completed with undelivered notifications/,
		],
		autoSessionTracking: false,
		...additionalOptions,
	};
}

export const getSentryHubForSLAErrors = memoize(async (): Promise<Hub | null> => {
	if (
		(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing') ||
		process.env.CLOUD_ENV === 'branch'
	) {
		return null;
	}

	// https://sentry.io/settings/atlassian-2y/projects/confluence-sla/keys/
	const SENTRY_DSN_SLA = 'https://2d329ea4a8a04cf0b19117b4675b336f@o55978.ingest.sentry.io/5988900';

	return liftPromiseState(
		import(/* webpackChunkName: "loadable-sentrybrowser" */ '@sentry/browser')
			.then(({ BrowserClient, Hub }) => {
				const client = new BrowserClient(getSentryOptions(SENTRY_DSN_SLA));
				return new Hub(client);
			})
			.catch(() => {
				// noop, there's nowhere to report this error
				return null;
			}),
	);
});

export function initializeSentry() {
	if (
		(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing') ||
		process.env.CLOUD_ENV === 'branch'
	) {
		return;
	}

	import(/* webpackChunkName: "loadable-sentrybrowser" */ '@sentry/browser')
		.then(({ init }) => {
			init(getSentryOptions(getSentryDsn()));
		})
		.catch(() => {
			// noop, there's nowhere to report this error
		});
}
