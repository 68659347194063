import { combineReducers } from 'redux';

import changeboarding from '../components/Changeboarding/reducers';

import { dashboard } from './views/dashboard';
import spaceHome from './views/spaceHome';
import viewPage from './views/viewPage';
import space from './views/space';

export default combineReducers({
	dashboard,
	spaceHome,
	viewPage,
	space,
	changeboarding,
});
