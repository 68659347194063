import React, { type FC, useMemo, useContext } from 'react';

import { useIsEmbeddedConfluence_DO_NOT_USE } from '@confluence/embedded-confluence-check';

import { BannerContext } from './BannerContext';

export const BannerContextProvider: FC<{ includeTopNav?: boolean }> = ({
	includeTopNav,
	children,
}) => {
	const { includeTopNav: inheritedOrDefaultValue } = useContext(BannerContext);
	const isEmbeddedConfluence = useIsEmbeddedConfluence_DO_NOT_USE();
	if (typeof includeTopNav !== 'boolean') {
		includeTopNav = isEmbeddedConfluence ? false : inheritedOrDefaultValue;
	}
	const bannerContextValue = useMemo(() => ({ includeTopNav }), [includeTopNav]);
	return <BannerContext.Provider value={bannerContextValue}>{children}</BannerContext.Provider>;
};
