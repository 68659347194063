import React, { type ComponentType, type PropsWithChildren } from 'react';
import { type JSONValue, type PluginCollection } from '@atlassiansox/cross-flow-base-types';
import { CrossFlowProvider } from './lib/CrossFlowProvider';
import { type AnalyticsWebClientInterface, type MaybePromise } from './lib/types';
export { useCrossFlow, withCrossFlow, Journeys, Targets, Reasons } from './index';
export type { WithCrossFlowProps, TargetType } from './index';

interface ConfluenceProps {
	cloudId: string;
	analyticsClient: MaybePromise<AnalyticsWebClientInterface>;
	locale: string;
	plugins?: PluginCollection;
	globalExperimentalOptions?: Record<string, JSONValue>;
}

const ConfluenceProvider: ComponentType<PropsWithChildren<ConfluenceProps>> = (props) => {
	return <CrossFlowProvider {...props} originProduct="confluence" />;
};

export default ConfluenceProvider;
