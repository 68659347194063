import { query } from '@confluence/query-preloader-tools';

import { ReactionsContentQuery } from './graphql/ReactionsContentQuery.graphql';

export function preloadPageReactions(pageId) {
	return query({
		query: ReactionsContentQuery,
		variables: {
			contentId: pageId,
		},
	});
}
