import type { PostOfficeContextValue } from '@atlassian/post-office-context';

// This is here to satisfy requirements of new context
// Next step should be to remove this context provider enitrely
export const DEFAULT_CONTEXT: PostOfficeContextValue = {
	isSiteAdmin: undefined,
	locale: undefined,
	product: undefined,
	subproduct: undefined,
	tenantId: undefined,
	workspaceAri: 'no-workspace-ari-available',
	workspaceId: undefined,
	orgId: undefined,
	productEdition: undefined,
};

export const ERROR_BOUNDARY_LOCATION = 'create-placement-component';
