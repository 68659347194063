import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../../common/utils';
import { isProcessingAllowedByPreferences } from '../../../../common/utils/item-processing';
import { Logger } from '../../../../common/utils/logger';
import { type AtlBrowserStorage, WebStorageType } from '../../../../services/web-storage-service';
import { CategoryStorageType } from '../../../../types';

// GET SET COOKIE ANALOGUE
export const setItemOverride =
	(
		bscStorage: AtlBrowserStorage,
		originalSetItem: (key: string, value: string) => void,
		storageType: WebStorageType,
	) =>
	(originalItemKey: string, originalItemValue: string): void => {
		const storageContext = bscStorage.storage;
		let itemSetByPackage = false;

		bscStorage.checkIfSetByPackage({
			itemKey: originalItemKey,
			isSetCallback: () => {
				itemSetByPackage = true;
			},
			isNotSetCallback: () => {
				// Note: This is async and could fail, but if the call errors out in IDB,
				// it will forcibly resolve to false here, so we're not catching the error again here ✅
				itemSetByPackage = false;
			},
		});

		if (itemSetByPackage) {
			try {
				// Controls have already been run, passthrough as usual
				return originalSetItem.apply(storageContext, [originalItemKey, originalItemValue]);
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'usedAtlBrowserStorageSetItemError',
					message: `${storageType} failed to setItem. ${e.message || ''}`,
					attributes: {
						outsidePackage: false,
						storageType,
					},
				});
			}
		}

		try {
			sendPackageOperationalEvent({
				action: 'usedAtlBrowserStorageSetItem',
				attributes: {
					itemKey: originalItemKey,
					outsidePackage: !itemSetByPackage,
					storageType,
				},
			});
			try {
				// Attempt to set a known item key that was set from outside the package
				isProcessingAllowedByPreferences({
					itemKey: originalItemKey,
					allowedCallback: () => {
						originalSetItem.apply(storageContext, [originalItemKey, originalItemValue]);
					},
					blockedCallback: ({ itemHasCategory }) => {
						if (fg('platform.moonjelly.block-unknown-cookies')) {
							return;
						} else {
							if (!itemHasCategory) {
								originalSetItem.apply(storageContext, [originalItemKey, originalItemValue]);
							}
						}
					},
					storageType:
						storageType === WebStorageType.LocalStorage
							? CategoryStorageType.LocalStorage
							: CategoryStorageType.SessionStorage,
				});
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'usedAtlBrowserStorageSetItemError',
					message: `Failed to set item. ${e.message || ''}`,
					attributes: {
						itemKey: originalItemKey,
						storageType,
					},
				});
			}
		} catch (e: any) {
			Logger.errorWithOperationalEvent({
				action: 'usedAtlBrowserStorageSetItemError',
				message: `Failed to send ${storageType} setter event. ${e.message || ''}`,
			});
		}
	};
