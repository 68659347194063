import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ContentHistoryLoader } from '@confluence/content-history';

const ContentHistoryRoute = ({ params: { spaceKey, contentId } }: RouteMatch) => {
	return <ContentHistoryLoader spaceKey={spaceKey} contentId={contentId} stack="classic" />;
};

ContentHistoryRoute.NAVIGATION_PARAMS = ({
	params: { spaceKey, contentId, contentSlug },
}: RouteMatch) => ({
	Screen: {
		screenEvent: { name: 'pageHistoryScreen', id: contentId },
		pageState: {
			spaceKey,
			contentId,
			contentSlug,
		},
	},
	MainLayout: {
		spaceKey,
		navView: CONTAINER_HOME_ID,
	},
});

export { ContentHistoryRoute };
