import gql from 'graphql-tag';

import { UserPrivilegeFragment } from '@confluence/current-user/entry-points/UserPrivilege.fragment';

export const UserPrivilegeQuery = gql`
	query UserPrivilegeQuery {
		...UserPrivilegeFragment
	}

	${UserPrivilegeFragment}
`;
