import { useQuery } from 'react-apollo';

import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { isUnauthorizedError, isNotFoundError, Attribution } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { useSessionData } from '@confluence/session-data';

import type { CompanyHubPremiumGateQuery as CompanyHubPremiumGateQueryData } from './__types__/CompanyHubPremiumGateQuery';
import { CompanyHubPremiumGateQuery } from './CompanyHubPremiumGateQuery.graphql';

export const useCompanyHubPremiumGate = ({ skip = false } = {}) => {
	const { edition } = useSessionData();

	const shouldSkip = skip || edition === ConfluenceEdition.PREMIUM;

	const { data, error } = useQuery<CompanyHubPremiumGateQueryData>(CompanyHubPremiumGateQuery, {
		skip: shouldSkip,
	});

	if (error) {
		if (!(isUnauthorizedError(error) || isNotFoundError(error))) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMPANY_HUB,
			});
		}
		markErrorAsHandled(error);
	}

	return !shouldSkip && !data?.entitlements.companyHub.isEntitled;
};
