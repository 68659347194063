import React, { useState, useCallback } from 'react';
import { safeAuth, isExternalAuth } from './auth';
import { type ThreeLOPromptProps } from './threeLOPrompt';
import { useIntl, type IntlShape, defineMessages } from 'react-intl-next';
import { Inline } from '@atlaskit/primitives';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import { isOfTypeAuthError } from '@atlaskit/outbound-auth-flow-client/types';

// TODO: We should make appName non-optional later: http://go/j/AUX-570
// appName was made optional because it isn't in already inserted apps prior to the change in http://go/j/AUX-411
const defaultPromptMessage = (intl: IntlShape, appName?: string) => {
	return intl.formatMessage(messages.defaultPromptMessage, {
		appString: appName ? appName : intl.formatMessage(messages.thisAppLower),
	});
};

const externalAuthPromptMessage = (intl: IntlShape, appName?: string) => {
	return intl.formatMessage(messages.externalAuthPromptMessage, {
		appString: appName ? appName : intl.formatMessage(messages.thisAppUpper),
	});
};

export const useAuth = ({
	message,
	promptText,
	appName,
	authUrl,
	onSuccess,
	metadata,
}: ThreeLOPromptProps) => {
	const [failedToAuthenticate, setFailedToAuthenticate] = useState(false);
	const [authFailureMessage, setAuthFailureMessage] = useState<boolean | string>(false);
	const [loading, setLoading] = useState(false);
	const promptIsForExternalAuth = isExternalAuth(authUrl);

	const intl = useIntl();

	const startAuth = useCallback(() => {
		setLoading(true);
		setAuthFailureMessage(false);
		safeAuth(authUrl).then(
			() => {
				onSuccess().then((callbackAfterConsentFlowHasFinished) => {
					setLoading(false);
					setFailedToAuthenticate(false);

					if (
						callbackAfterConsentFlowHasFinished &&
						typeof callbackAfterConsentFlowHasFinished === 'function'
					) {
						callbackAfterConsentFlowHasFinished();
					}
				});
			},
			(err) => {
				setLoading(false);
				setFailedToAuthenticate(true);
				if (isOfTypeAuthError(err.type)) {
					setAuthFailureMessage(err.message);
				}
			},
		);
	}, [authUrl, onSuccess, setLoading, setFailedToAuthenticate, setAuthFailureMessage]);

	const promptMessage = failedToAuthenticate
		? authFailureMessage || intl.formatMessage(messages.defaultFailedToAuthMessage)
		: message ||
			(promptIsForExternalAuth
				? externalAuthPromptMessage(intl, appName)
				: defaultPromptMessage(intl, appName));

	const content = (
		<Inline alignBlock="center">
			<WarningIcon
				label={intl.formatMessage(messages.externalAuthWarning)}
				color={token('color.icon.warning', '#FF8B00')}
				LEGACY_size="medium"
				spacing="spacious"
			/>
			{promptMessage}
		</Inline>
	);

	const externalAuthButtonText = intl.formatMessage(messages.configureAccess);
	const buttonText =
		promptText ||
		(promptIsForExternalAuth ? externalAuthButtonText : intl.formatMessage(messages.allowAccess));

	return { loading, startAuth, promptMessage: content, buttonText };
};

const messages = defineMessages({
	defaultPromptMessage: {
		id: 'confluence.threeLOPrompt.use-auth.default.prompt.message',
		defaultMessage:
			'For {appString} to display, you need to allow the app to access Atlassian products on your behalf.',
		description:
			'A text prompt that tells a user that an app needs to be given permission before the app can be used.',
	},
	externalAuthPromptMessage: {
		id: 'confluence.threeLOPrompt.use-auth.external.auth.prompt.message',
		defaultMessage: '{appString} requires additional access to your account.',
		description:
			'A text prompt indicating that an app requires additional permissions before the app can be used.',
	},
	thisAppUpper: {
		id: 'confluence.threeLOPrompt.use-auth.this.app.upper.message',
		defaultMessage: 'This app',
		description: 'a default message shown to the user when the name of an application is unknown.',
	},
	thisAppLower: {
		id: 'confluence.threeLOPrompt.use-auth.this.app.lower.message',
		defaultMessage: 'this app',
		description: 'a default message shown to the user when the name of an application is unknown.',
	},
	defaultFailedToAuthMessage: {
		id: 'confluence.threeLOPrompt.use-auth.default.failed.to.auth.message',
		defaultMessage: 'Failed to authenticate.',
		description: 'A default text prompt to indicate than an app was unable to authenticate a user.',
	},
	externalAuthWarning: {
		id: 'confluence.threeLOPrompt.use-auth.external.auth.warning.message',
		defaultMessage: 'External Auth Warning',
		description: 'A default text prompt to indicate than an app was unable to authenticate a user.',
	},
	configureAccess: {
		id: 'confluence.threeLOPrompt.use-auth.configure.access.button.text',
		defaultMessage: 'Configure access',
		description:
			"Label for a button that allows a user to configure the app's access to the user's account",
	},
	allowAccess: {
		id: 'confluence.threeLOPrompt.use-auth.allow.access.button.text',
		defaultMessage: 'Allow access',
		description:
			'Label for a button that would allow the user to grant access to some application.',
	},
});
