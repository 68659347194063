import React from "react";

import { LegacyMacroRenderer } from "@confluence/fabric-extension-legacy-macro-renderer";
import { extensionToADF } from "@confluence/fabric-extension-lib/entry-points/editor-extensions";
import { LiveSearchLoader, LazyLiveSearchLoader } from "@confluence/live-search";


export const LiveSearchHandler = ({hasADF, macroDefaultProps, shouldLoadAfterPaint }) => {
  // Fallback to LegacyMacroRenderer when adf string is not defined
  if (!hasADF) {
    return <LegacyMacroRenderer {...macroDefaultProps} />;
  }

  const { node } = macroDefaultProps;
  const macroNode = extensionToADF(node);
  return shouldLoadAfterPaint
  ? <LazyLiveSearchLoader macroNode={macroNode} />
  : <LiveSearchLoader macroNode={macroNode} />;
};
