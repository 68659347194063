import { LoadablePaint } from '@confluence/loadable';

import { ExcerptIncludeComponentShadow } from './ExcerptIncludeComponentShadow';
import type { ExcerptIncludeProps } from './types';

// <LiveSearchLoader />, the children of <ExcerptIncludeLoader/>, use LoadablePaint so this has to match
export const ExcerptIncludeLoader = LoadablePaint<ExcerptIncludeProps>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ExcerptInclude" */ './ExcerptInclude'))
			.ExcerptInclude,
	// Component shadow is a subset of ExcerptIncludeProps so this would generate a TS error here as Loadable
	// expects the prop types to match.
	// @ts-expect-error
	loading: ExcerptIncludeComponentShadow,
});
