import { query } from '@confluence/query-preloader-tools';
import { preloadFeatureDiscovery } from '@confluence/feature-discovery/entry-points/preloadFeatureDiscovery';
import { fg } from '@confluence/feature-gating';

import { FeedContentQuery } from './FeedContentQuery.graphql';
import { FeedTypeSelectorRefreshQuery } from './FeedTypeSelectorRefreshQuery.graphql';
import { FeedPopularFeedQuery } from './FeedPopularFeedQuery.graphql';
import { getInitialPageSize } from './initialPageSizeConstant';

export const preloadFeed = ({ isLoggedIn, isSpaceAliasFFEnabled = false }) => {
	// If the user is not logged in we show the following feed by default
	// so only preload that feed. If the user is logged in preload both
	// feeds and the feed preference
	const tasks: Array<Promise<any>> = [
		query({
			query: FeedContentQuery,
			variables: {
				first: getInitialPageSize(),
				includeAlias: isSpaceAliasFFEnabled,
			},
		}),
	];
	if (isLoggedIn) {
		tasks.push(
			query({
				query: FeedPopularFeedQuery,
				variables: {
					first: getInitialPageSize(),
					includeAlias: isSpaceAliasFFEnabled,
				},
			}),
			query({
				query: FeedTypeSelectorRefreshQuery,
			}),
		);

		if (!fg('confluence_user_settings_query_migration')) {
			tasks.push(preloadFeatureDiscovery());
		}
	}
	return Promise.all(tasks);
};
