import type { IEditorPreloader } from "@confluence/edit-button";

export const nativeEditorComponentsPreloader = async (): Promise<void> => {
  return (
    await import(
      /* webpackChunkName: "loadable-nativeEditorComponentsPreloader" */
      "./nativeEditorComponentsPreloader"
    )
  ).nativeEditorComponentsPreloader();
};

export const tinyEditorComponentsPreloader = async (): Promise<void> => {
  return (
    await import(
      /* webpackChunkName: "loadable-tinyEditorComponentsPreloader" */
      "./tinyEditorComponentsPreloader"
    )
  ).tinyEditorComponentsPreloader();
};

export const getEditorPreloader = async (): Promise<
  () => (config: IEditorPreloader) => JSX.Element
> => {
  return (
    await import(
      /* webpackChunkName: "loadable-getEditorPreloader" */
      "../editorPreloader"
    )
  ).getEditorPreloader;
};
