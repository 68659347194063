import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import {
	ContentUnifiedQuery,
	ContentBlogUnifiedQuery,
	type ContentUnifiedQueryType,
	type ContentBlogUnifiedQueryType,
	type ContentUnifiedQueryVariablesType,
	type ContentBlogUnifiedQueryVariablesType,
} from '@confluence/content-unified-query';
import { useSpaceKey } from '@confluence/space-utils';
import { getApolloClient } from '@confluence/graphql';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useContentType } from '@confluence/page-context';
import { useBooleanFeatureFlag } from '@confluence/session-data';

type ContentRefetchHandlerProps = {
	contentId?: string;
	shouldRefetch: boolean;
	refetchImmediately?: boolean;
	onRefetchComplete?: (versionNumber?: number | null) => void;
};

type ChooseContentUnifiedQueryType = ContentUnifiedQueryType | ContentBlogUnifiedQueryType;
type ChooseContentUnifiedQueryVariablesType =
	| ContentUnifiedQueryVariablesType
	| ContentBlogUnifiedQueryVariablesType;

export const ContentRefetchHandler: FC<ContentRefetchHandlerProps> = ({
	contentId,
	shouldRefetch,
	refetchImmediately,
	onRefetchComplete,
}) => {
	const { history } = useContext(RoutesContext);
	const [contentType] = useContentType();
	const spaceKey = useSpaceKey();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	useEffect(() => {
		let unlisten;
		if (shouldRefetch && contentId) {
			let chooseContentUnifiedQuery = ContentUnifiedQuery;

			let queryVariable: ChooseContentUnifiedQueryVariablesType = {
				contentId,
				spaceKey,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper: isNewContentTopperFFEnabled,
			};

			if (contentType === 'blogpost') {
				chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
			}

			const refetchFn = async () => {
				let versionNumber: number | null | undefined;
				try {
					let queryResult = await getApolloClient().query<
						ChooseContentUnifiedQueryType,
						ChooseContentUnifiedQueryVariablesType
					>({
						query: chooseContentUnifiedQuery,
						context: { single: true },
						fetchPolicy: 'network-only',
						variables: queryVariable,
					});

					// at this time, queryResult.data is always ContentUnifiedQueryType or ContentBlogUnifiedQueryType format
					// @ts-ignore
					versionNumber = queryResult.data.content?.nodes?.[0]?.version?.number;
				} catch (error) {
					// NOTE: We intentionally swallow any possible error here as this query is only aimed to
					// populate the cache with just published content. Should any error occur here after
					// successful publishing the page, the Apollo cache for the query will be invalidated
					// anyway and View Page will fetch the page and handle possible errors.
				}

				// If a callback is supplied, call it regardless
				onRefetchComplete && onRefetchComplete(versionNumber);
			};

			if (refetchImmediately) {
				void refetchFn();
			} else {
				unlisten = history?.listen(refetchFn);
			}
		}

		return () => {
			// Call the unlistener when this component unmounts
			unlisten && unlisten();
		};
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [shouldRefetch, isSpaceAliasFFEnabled]);

	return null;
};
