import React from 'react';

import type { DocNode } from '@atlaskit/adf-schema';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import type { Fragment, Schema } from '@atlaskit/editor-prosemirror/model';

import { PreviewRenderer } from './components/PreviewRenderer/PreviewRenderer';
import { MarkdownConversionError } from './MarkdownConversionError';

const jsonTransformer = new JSONTransformer();

/**
 * This takes a markdown string, and generates a react preview
 * of the content.
 *
 * When it is unable to create a preview, it triggers an error.
 */
export function convertPMFragmentToPreview({
	schema,
	pmFragment,
	showTelepointer,
	providerFactory,
}: {
	schema: Schema;
	pmFragment: Fragment;

	// This is used to tell the renderer wether or not to show the telepointer based on where
	// convertPMFragmentToPreview is called from.
	// If called from the loading screen we want to show the telepointer as it will be streaming
	// However if called from the preview screen the stream has finished and we are showing the final result
	showTelepointer: boolean;
	providerFactory: ProviderFactory;
}): React.ReactElement {
	let jsonDocument;
	const prosemirrorDocument = schema.nodes.doc.createAndFill({}, pmFragment);

	// If a failure has occured creating this, we don't know what's happened
	// and so should trigger the error boundary
	if (!prosemirrorDocument) {
		throw new MarkdownConversionError({
			message: 'Unable to transform markdown into a valid prosemirror document',
			type: 'noProseMirrorDoc',
		});
	}

	if (prosemirrorDocument.content.size === 0) {
		throw new MarkdownConversionError({
			message: 'Transforming markdown returns an empty prosemirror document',
			type: 'emptyProseMirrorDoc',
		});
	}

	try {
		// Warning -- this is asserted due to a mismatch in the editor-json-transformer
		// and renderer types.
		// jsonTransformer.encode returns a "JSONDocNode" which _should_ be runtime
		// compatible with DocNode -- but does not have compatible types.
		// See the following files for more details
		// - packages/editor/editor-json-transformer/src/types.ts
		// - packages/editor/adf-schema/src/schema/nodes/doc.ts
		jsonDocument = jsonTransformer.encode(prosemirrorDocument) as DocNode;
	} catch {
		throw new MarkdownConversionError({
			message: 'Unable to transform markdown into a valid prosemirror document',
			type: 'invalidProseMirrorDoc',
		});
	}

	return (
		<PreviewRenderer
			jsonDocument={jsonDocument}
			showTelepointer={showTelepointer}
			providerFactory={providerFactory}
		></PreviewRenderer>
	);
}
