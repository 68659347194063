import { type ComponentType } from 'react';

import AkInlineDialog from '@atlaskit/inline-dialog';
import { type InlineDialogProps as AkInlineDialogProps } from '@atlaskit/inline-dialog/types';

import { wrapToggleableComponentForChoreographer } from '../utils';

export const InlineDialog = wrapToggleableComponentForChoreographer<AkInlineDialogProps>(
	AkInlineDialog as ComponentType<AkInlineDialogProps>,
	'isOpen',
);
