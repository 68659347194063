import { getPreloadResult } from './PreloadResult';

export function restQuery(
	url: string,
	{
		headers,
		method,
		body,
	}: {
		headers?: HeadersInit;
		method: string;
		body?: { [key: string]: any };
	},
) {
	const variables = { method, ...body, ...headers };

	return getPreloadResult().load({ name: url, variables }, () =>
		// eslint-disable-next-line no-restricted-syntax, check-credentials-option/has-credentials-option
		fetch(url, {
			method,
			credentials: 'include',
			headers,
			body: JSON.stringify(body),
		}),
	);
}
