import type { ApolloCache } from 'apollo-cache';
import { ApolloError } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';

import { getLogger } from '@confluence/logger';
import {
	BadStatusError,
	isSessionExpiredError,
	NoNetworkError,
	SessionExpiredError,
	causedByAbortError,
} from '@confluence/network';

import type { NetworkStatusLinkQuery as NetworkStatusLinkQueryT } from './__types__/NetworkStatusLinkQuery';
import { ClientNetworkStatus as NetworkStatus } from './__types__/NetworkStatusLinkQuery';
import { NetworkStatusLinkQuery } from './NetworkStatusLinkQuery.graphql';

function readNetworkStatus(cache: ApolloCache<any>) {
	try {
		const data = cache.readQuery<NetworkStatusLinkQueryT>({
			query: NetworkStatusLinkQuery,
		});

		return data?.network?.status;
	} catch (e) {
		return undefined;
	}
}

export function writeNetworkStatus(cache: ApolloCache<any>, status: NetworkStatus) {
	const currentStatus = readNetworkStatus(cache);
	if (currentStatus === NetworkStatus.SESSION_EXPIRED) {
		// recovering from this error only via page reload
		return;
	}

	if (currentStatus !== status) {
		cache.writeQuery<NetworkStatusLinkQueryT>({
			query: NetworkStatusLinkQuery,
			data: {
				network: {
					// @ts-ignore
					__typename: 'ClientNetwork',
					status,
				},
			},
		});
	}
}

export { NetworkStatus };

export const networkStatusLink = () => {
	const logger = getLogger(networkStatusLink);

	return new ApolloLink((operation, forward) => {
		return new Observable((observer) => {
			forward(operation).subscribe({
				next(result) {
					const context = operation.getContext();

					if (result.errors?.some(isSessionExpiredError)) {
						writeNetworkStatus(context.cache, NetworkStatus.SESSION_EXPIRED);

						const sessionExpiredError = new SessionExpiredError(
							operation.operationName,
							context.response || {},
						);
						// Don't throw original errors when translating them:
						// 1. By Error's convention, the original cause of the Error is in
						//    `cause`.
						// 2. By our convention inspired by GraphQLError, the original cause
						//    of the Error is in `originalError`).
						(sessionExpiredError as any).originalError = sessionExpiredError.cause =
							new ApolloError({
								graphQLErrors: result.errors,
							});
						observer.error(sessionExpiredError);
					} else {
						writeNetworkStatus(context.cache, NetworkStatus.ONLINE);
						observer.next(result);
					}
				},

				error(error) {
					const context = operation.getContext();

					if (error instanceof BadStatusError) {
						switch (error.response.status) {
							case /* Forbidden */ 403:
								logger.log`IP blocked for ${operation}`;
								writeNetworkStatus(context.cache, NetworkStatus.IP_BLOCKED);
								return; // as it doesn't call observer next/error, the request will stay in unresolved state

							case /* Too Many Requests */ 429:
								logger.log`Rate limit was reached for ${operation}`;
								writeNetworkStatus(context.cache, NetworkStatus.RATE_LIMITED);
								observer.error(error);
								return;
						}
					} else if (
						error instanceof NoNetworkError &&
						!error.ignore &&
						!causedByAbortError(error)
					) {
						logger.log`Network is offline for ${operation}`;
						writeNetworkStatus(context.cache, NetworkStatus.OFFLINE);
						return; // as it doesn't call observer next/error, the request will stay in unresolved state
					}

					observer.error(error);
				},

				complete() {
					observer.complete();
				},
			});
		});
	});
};
