import gql from 'graphql-tag';

import type { Option } from '@atlaskit/editor-common/extensions';

import { getApolloClient } from '@confluence/graphql';

import { escapeText } from '../cql/transformers';

const userSearchQuery =
	// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming -- Read https://go/connie-relay-migration-fyi
	gql`
		query UserSearch($cql: String!) {
			searchUser(cql: $cql, first: 25, sitePermissionTypeFilter: "all") {
				edges {
					node {
						user {
							displayName
							accountId
							profilePicture {
								path
							}
						}
					}
				}
			}
		}
	`;

const getQueryTerm = (term: string) => {
	// query term looks like this: "type = user AND (user.fullname ~ \"bob*\" OR user = \"bob\")"
	return `type = user AND (user.fullname ~ "${term}*" OR user = "${term}")`;
};

const search = async (cql: string) => {
	const client = getApolloClient();

	const res = await client.query({
		query: userSearchQuery,
		variables: {
			cql,
		},
	});

	return res.data.searchUser.edges.map((edge) => ({
		label: edge.node.user.displayName,
		value: edge.node.user.accountId,
		icon: edge.node.user.profilePicture.path,
	}));
};

export const usernameResolver = async (searchTerm?: string, selectedValue?: string | string[]) => {
	const searchers: Array<Promise<Option[]>> = [];

	if (searchTerm) {
		searchers.push(search(getQueryTerm(searchTerm)));
	}

	if (selectedValue) {
		if (Array.isArray(selectedValue)) {
			// if values are in array, concat the items into one search phrase
			// e.g if array is ["sponge", "bob"], query term is: "user IN ( \"sponge\", \"bob\")"
			searchers.push(
				search(`user IN (${selectedValue.map((val) => `"${escapeText(val)}"`).join(', ')})`),
			);
		} else {
			// if not an array just pass in the term as selected val
			searchers.push(search(getQueryTerm(selectedValue)));
		}
	}

	return (await Promise.all(searchers)).flat();
};
