import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { type SerializableRecord } from '@post-office/serializable-record';
import { camelCase } from 'lodash';
import { useEffect, useState } from 'react';

import { type State } from './types';
import { assertResponseIsOk, toData, toError, toLoading } from './utils';

type Options = {
	analytics?: {
		name: string;
	} & Record<string, unknown>;
};

type DataFetcherOptions = { signal: AbortSignal };

export type DataFetcher = ({ signal }: DataFetcherOptions) => Promise<Response>;

export class AlreadyRunningError extends Error {
	constructor() {
		super('Request is already running.');
		this.name = 'AlreadyRunningError';
	}
}

export const useData = <Data extends SerializableRecord>(
	promiseToResolve: DataFetcher | undefined,
	options?: Options,
): State<Data> => {
	const [state, setState] = useState<State<Data>>(toLoading());

	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();
	const { name: analyticsSubjectId, ...analyticsAttributes } = options?.analytics ?? {
		name: 'unknown',
	};

	useEffect(() => {
		if (typeof promiseToResolve !== 'function') {
			return;
		}

		const abortController = new AbortController();
		const { signal } = abortController;

		promiseToResolve({ signal })
			.then(assertResponseIsOk)
			.then((res) => res.json())
			.then((data) => {
				setState(toData(data));

				fireAnalyticsEvent({
					action: 'resolved',
					actionSubject: 'dataPromise',
					actionSubjectId: camelCase(analyticsSubjectId) + 'DataPromise',
					eventType: 'operational',
					attributes: {
						...analyticsAttributes,
					},
				});
			})
			.catch((err) => {
				if (err instanceof AlreadyRunningError || signal.aborted) {
					return;
				}

				const errorState = toError(err);

				fireAnalyticsEvent({
					action: 'rejected',
					actionSubject: 'dataPromise',
					actionSubjectId: camelCase(analyticsSubjectId) + 'DataPromise',
					eventType: 'operational',
					attributes: {
						...analyticsAttributes,
						errorName: errorState.error.name ?? 'unknown',
						errorMessage: errorState.error.message ?? 'unknown',
					},
				});

				setState(errorState);
			});

		return () => {
			abortController.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [promiseToResolve]);

	return state;
};
