import { type ChoreographerFactory } from '@atlassian/ipm-choreographer';
import { usePostOfficeContext, usePostOfficeEnvironment } from '@atlassian/post-office-context';
import { createContext, useContext, useMemo, useState } from 'react';

import ChoreographerRegistry from './choreographerRegistry';
import { type StableProvider } from '../../types';

const DEFAULT_IS_CHOREOGRAPHER_ENABLED = true;

const defaultContext = {
	choreographerEnabled: DEFAULT_IS_CHOREOGRAPHER_ENABLED,
	setChoreographerEnabled: () => {},
	factory: undefined,
};

type ChoreographerContext = {
	choreographerEnabled: boolean;
	setChoreographerEnabled: (isEnabled: boolean) => void;
	factory: ChoreographerFactory | undefined;
};

const ChoreographerContext = createContext<ChoreographerContext>(defaultContext);

export const StableChoreographerProvider: StableProvider = ({ children }) => {
	const [choreographerEnabled, setChoreographerEnabled] = useState(
		defaultContext.choreographerEnabled,
	);
	const environmentContext = usePostOfficeEnvironment();
	const context = usePostOfficeContext();

	const choreographerFactory = new ChoreographerRegistry(
		context.product,
		environmentContext.currentEnv,
	).getPostOfficeChoreographerFactory();

	const stableChoreographerContext = useMemo(
		() => ({
			factory: choreographerFactory,
			choreographerEnabled,
			setChoreographerEnabled,
		}),
		[choreographerFactory, choreographerEnabled, setChoreographerEnabled],
	);

	return (
		<ChoreographerContext.Provider value={stableChoreographerContext}>
			{children}
		</ChoreographerContext.Provider>
	);
};

export const useChoreographerContext = () => useContext(ChoreographerContext);
