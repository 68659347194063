/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type NativeLinkProps, type RenderFn } from '@atlassian/forge-ui-types';
import { sanitizeUrl } from '../../UIKit1/markup';

function LinkInner(props: Parameters<RenderFn>[0]) {
	const { forgeDoc, render } = props;
	const { href, openNewTab } = forgeDoc.props as NativeLinkProps;

	return (
		<a
			target={openNewTab ? '_blank' : undefined}
			href={sanitizeUrl(href)}
			rel="noopener noreferrer"
		>
			{forgeDoc.children.map(render)}
		</a>
	);
}

export const Link = (props: Parameters<RenderFn>[0]) => {
	return <LinkInner {...props} />;
};
