import type { ComponentType } from 'react';
import React from 'react';

import { token } from '@atlaskit/tokens';
import type { GlyphProps } from '@atlaskit/icon/types';
import ErrorIcon from '@atlaskit/icon/glyph/editor/error';
import InfoIcon from '@atlaskit/icon/glyph/info';
import SuccessIcon from '@atlaskit/icon/glyph/check';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import SuccessCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { G300, R300, Y300, B300 } from '@atlaskit/theme/colors';

function colorizeIcon(
	Icon: ComponentType<GlyphProps>,
	color: string,
	label: string,
	isBold: boolean,
) {
	return isBold ? (
		<Icon label={label} secondaryColor={color} />
	) : (
		<Icon label={label} primaryColor={color} />
	);
}

export const FlagIcon = ({
	type,
	isBold,
	customIcon,
}: {
	type?: 'success-circle' | 'success' | 'error' | 'warning' | 'info' | 'custom';
	isBold: boolean;
	customIcon?: JSX.Element;
}) => {
	switch (type) {
		case 'success-circle':
			return colorizeIcon(SuccessCircleIcon, token('color.icon.success', G300), 'Success', isBold);
		case 'success':
			return colorizeIcon(SuccessIcon, token('color.icon.success', G300), 'Success', isBold);
		case 'error':
			return colorizeIcon(ErrorIcon, token('color.icon.danger', R300), 'Error', isBold);
		case 'warning':
			return colorizeIcon(WarningIcon, token('color.icon.warning', Y300), 'Warning', isBold);
		case 'custom':
			return (
				customIcon ?? colorizeIcon(InfoIcon, token('color.icon.information', B300), 'Info', isBold)
			);
		case 'info':
		default:
			return colorizeIcon(InfoIcon, token('color.icon.information', B300), 'Info', isBold);
	}
};
