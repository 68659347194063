export class SSRPrepareErrorsClient {
	private errors: Array<{
		error: { message: string; stack?: string };
		meta: any;
	}>;

	constructor() {
		this.errors = [];
	}

	public submitError(e: any, errorData: any = {}): void {
		let additional = {};

		// Not actually importing the error class to avoid circular dependency
		const response =
			// RateLimitedError or BadStatusError
			e?.response ||
			// Apollo Error
			e?.networkError?.response;

		if (response) {
			additional = {
				response: {
					url: response.url,
					status: response.status,
					statusText: response.statusText,
				},
			};
		}
		this.errors.push({
			error: {
				message: e.message,
				stack: e.stack,
			},
			meta: errorData,
			...additional,
		});
	}

	public getErrors() {
		return this.errors;
	}
}
