import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { EmbeddedEditPageLoader } from '@confluence/embedded-confluence';

import { ClassicEditorContextProviderLoader } from '../../ClassicEditorContextProvider';
import { navdexEnd } from '../naxdev';

export const EditPageEmbedRoute = ({
	params: { contentId, spaceKey },
	query: { draftShareId },
}: RouteMatch) => (
	<ClassicEditorContextProviderLoader>
		<EmbeddedEditPageLoader
			contentId={contentId}
			spaceKey={spaceKey}
			draftShareId={String(draftShareId)}
			contentType="page"
		/>
	</ClassicEditorContextProviderLoader>
);

EditPageEmbedRoute.NAVIGATION_PARAMS = ({ params: { contentId, spaceKey } }: RouteMatch) => ({
	Screen: {
		showPremiumOnboarding: false,
		screenEvent: {
			name: 'editPageEmbedScreen',
			id: contentId,
			...navdexEnd,
		},
		pageState: { contentId, spaceKey, contentType: 'page' },
	},
	MainLayout: false,
	BannerContext: { includeTopNav: false },
});
