import gql from 'graphql-tag';

export const SpacePropertiesQuery = gql`
	query SpacePropertiesQuery($spaceKey: String!, $propertyKeys: [String!]!) @experimental {
		experimentalSpaceProperties(spaceKey: $spaceKey, propertyKeys: $propertyKeys) {
			key
			value
		}
	}
`;
