import type { FC, ReactNode } from 'react';
import React, { createContext } from 'react';

import { useCommentCounts, type UseCommentCountsData } from '@confluence/comments-hooks';

export const CommentCountsContext = createContext<UseCommentCountsData>({
	footerCommentsCount: 0,
	unresolvedInlineCommentsCount: 0,
});

type CommentCountsProviderProps = {
	contentId: string;
	children: ReactNode;
};

export const CommentCountsProvider: FC<CommentCountsProviderProps> = ({ contentId, children }) => {
	const commentCounts = useCommentCounts(contentId);

	return (
		<CommentCountsContext.Provider value={commentCounts}>{children}</CommentCountsContext.Provider>
	);
};
