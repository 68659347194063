import React, { useContext } from 'react';
import { Grid } from '@visx/grid';
import { DataContext } from '@visx/xychart';

import { token } from '@atlaskit/tokens';

// We must construct a lower-level Grid from "@visx/grid" instead of using the higher-level grid from "@visx/xychart"
// because we need a greater level of control over rowTickValues and columnTickValues. The rest of the code is just
// managing the lower-level details that we then have to manage as a result.
export const CustomGrid = ({
	rowTickValues,
	columnTickValues,
}: {
	rowTickValues: number[];
	columnTickValues: Date[];
}) => {
	const { xScale, yScale, innerHeight, innerWidth, margin } = useContext(DataContext);
	// This check makes the compiler happy.
	if (!(xScale && yScale && innerHeight && innerWidth && margin)) {
		return <></>;
	}

	return (
		<Grid
			xScale={xScale}
			yScale={yScale}
			height={innerHeight}
			width={innerWidth}
			left={margin.left}
			top={margin.top}
			xOffset={-1 * margin.left}
			yOffset={-1 * margin.top}
			rowTickValues={rowTickValues}
			columnTickValues={columnTickValues}
			stroke={token('color.background.accent.gray.subtler', '#DCDFE4')}
		/>
	);
};
