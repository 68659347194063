// taken from https://hello.atlassian.net/wiki/spaces/PRODSEC/pages/158006558/URI+scheme+Allow+listing
const PROTOCOL_ALLOWLIST = [
	'http:',
	'https:',
	'ftp:',
	'ftps:',
	'mailto:',
	'skype:',
	'callto:',
	'facetime:',
	'git:',
	'irc:',
	'irc6:',
	'news:',
	'nntp:',
	'feed:',
	'cvs:',
	'svn:',
	'mvn:',
	'ssh:',
	'scp:',
	'sftp:',
	'itms:',
	'notes:',
	'hipchat:',
	'sourcetree:',
	'urn:',
	'tel:',
	'xmpp:',
	'telnet:',
	'vnc:',
	'rdp:',
	'whatsapp:',
	'slack:',
	'sip:',
	'sips:',
	'magnet:',
	'tds:',
];

export const sanitizeUrl = (urlString?: string) => {
	const url = new URL(urlString || '', window.location.href);
	if (PROTOCOL_ALLOWLIST.includes(url.protocol)) {
		return url.toString();
	} else {
		return 'about:blank';
	}
};
