import React from 'react';

import { LoadableLazy } from '@confluence/loadable';
import { CenteredSpinner } from '@confluence/confluence-shared-components';

export const TemplateVariableDialogLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TemplateVariableDialogComponent" */ './TemplateVariableDialogComponent'
			)
		).TemplateVariableDialogComponent,
	loading: () => <CenteredSpinner size="medium" />,
});
