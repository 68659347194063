import type { ThemeState } from '@atlaskit/tokens';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import {
	API_DEFAULT_THEME_STATE,
	API_ORIGINAL_THEME_STATE,
	getEffectiveThemeState,
	getEffectiveThemeStateSerialized,
} from '@confluence/theming-utils';

export class LocalThemeStorage {
	public static getThemeLocal(): Partial<ThemeState> {
		const persistedLocalTheme = localStorage.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_USER_THEME_PREFERENCE,
		);
		return getEffectiveThemeState(persistedLocalTheme || API_DEFAULT_THEME_STATE);
	}

	public static saveThemeLocal(theme: Partial<ThemeState>): void {
		localStorage.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_USER_THEME_PREFERENCE,
			getEffectiveThemeStateSerialized(theme),
		);
	}

	// TODO - FF cleanup - This is only used when the RemoveOriginal FF is NOT enabled.
	// When the FF is enabled, there are no states where there isn't a theme.
	public static removeThemeLocal(): void {
		// setItem is required for server-side local storage as removeItem does not remove server-side values
		localStorage.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_USER_THEME_PREFERENCE,
			API_ORIGINAL_THEME_STATE,
		);
	}
}
