import gql from 'graphql-tag';

export const ContentConverterQuery = gql`
	query ContentConverterQuery(
		$content: String!
		$contentId: ID!
		$from: String!
		$to: String!
		$spaceKeyContext: String = ""
	) {
		contentConverter(
			from: $from
			to: $to
			content: $content
			contentIdContext: $contentId
			spaceKeyContext: $spaceKeyContext
		) {
			value
		}
	}
`;
