import gql from 'graphql-tag';

export const SetBatchedTaskStatusMutation = gql`
	mutation SetBatchedTaskStatus(
		$contentId: ID!
		$tasks: [InlineTask]!
		$trigger: PageUpdateTrigger = VIEW_PAGE
	) {
		setBatchedTaskStatus(
			batchedInlineTasksInput: { contentId: $contentId, tasks: $tasks, trigger: $trigger }
		) {
			success
		}
	}
`;
