/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type ApolloClient from 'apollo-client';
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { defineMessages, useIntl } from 'react-intl-next';
import { type UserProps, type RenderFn } from '@atlassian/forge-ui-types';
import { useUser } from '../../../web-client/hooks/useUser';
import { type Props } from '../..';
import { useInlineContext } from '../../../context/inline';

const AKAvatar = React.lazy(() => import('@atlaskit/avatar'));
const AKBadge = React.lazy(() => import('@atlaskit/badge'));
const AKAvatarItem = React.lazy(() =>
	import('@atlaskit/avatar').then((module) => ({
		default: module.AvatarItem,
	})),
);

interface Dependencies {
	client: ApolloClient<any> | undefined;
}

function Avatar({ name, picture }: { name: string; picture: string }) {
	return (
		<div
			// Reduce Atlaskit padding to vertically-align with other components
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'> span': {
					paddingLeft: 0,
					backgroundColor: 'transparent',
					cursor: 'default',
				},
			})}
		>
			<AKAvatarItem avatar={<AKAvatar name={name} src={picture} />} primaryText={name} />
		</div>
	);
}

function UserInline({ name }: { name?: string }) {
	const intl = useIntl();
	return <AKBadge appearance="primary">{name || intl.formatMessage(messages.unknownUser)}</AKBadge>;
}

export default function User({ accountId, client }: UserProps & Dependencies) {
	const { picture, name } = useUser(accountId, client);
	const { inline } = useInlineContext();

	if (inline) {
		return <UserInline name={name} />;
	}
	return <Avatar name={name} picture={picture} />;
}

export function UserFn({ props }: Props) {
	const { accountId } = props as UserProps;
	return <User accountId={accountId} client={undefined} />;
}

// new approach requires ApolloClient as a prop
export function makeUser({ client }: Dependencies): RenderFn {
	return function UserFnNext({ forgeDoc: { props } }) {
		const { accountId } = props as UserProps;
		return <User accountId={accountId} client={client} />;
	};
}

const messages = defineMessages({
	unknownUser: {
		id: 'confluence.user.unknown.user.message',
		defaultMessage: 'Unknown User',
		description: 'This is a fallback message that displays when a username is not known.',
	},
});
