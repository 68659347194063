import { isApolloError } from 'apollo-client';
import type { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';
import {
	add as dateAdd,
	sub as dateSub,
	differenceInHours,
	differenceInCalendarDays,
	isSameYear,
	isAfter,
	differenceInDays,
	differenceInMonths,
	differenceInYears,
	differenceInMinutes,
} from 'date-fns';
import type { IntlShape } from 'react-intl-next';
import { format } from 'date-fns-tz';

import type { Datum } from '@atlassian/analytics-chart';

import type { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

import type { AnalyticsDialogGraphQuery_TimeseriesCount as GraphQueryResponse } from '../queries/__types__/AnalyticsDialogQuery';
import { AnalyticsTimeseriesGranularity as GraphGranularity } from '../queries/__types__/AnalyticsDialogQuery';
import type { ContentAnalyticsViewersUnifiedQuery as GraphqlQueryData } from '../queries/__types__/ContentAnalyticsViewersUnifiedQuery';
import { SitePermissionType } from '../queries/__types__/ContentAnalyticsViewersUnifiedQuery';

import { i18n } from './AnalyticsDialog/constants';

export const getViewersCount = (data: GraphqlQueryData | undefined): number =>
	data?.contentAnalyticsViewers?.count || 0;

export const isInternalUser = (data: GraphqlQueryData | undefined): boolean =>
	data?.user?.confluence?.permissionType === SitePermissionType.INTERNAL;

export const isSSRError = (data?: GraphqlQueryData, error?: ApolloError): boolean =>
	!error && data?.contentAnalyticsViewers === null;

const PERMISSION_ERROR_CODES = [400, 401, 403];
const CONTENT_ERROR_CODES = [404];
const capitalizeFirstLetter = (word: string): string =>
	`${word[0].toLocaleUpperCase()}${word.slice(1)}`;
const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";
const GRANULARITY_RANGE_LIMITS = {
	hours: { dayDiff: 2, hourDiff: 48 },
	days: { dayDiff: 28 },
	weeks: { dayDiff: 48 },
	months: { monthDiff: 12 },
};

const errorContainsCodes = (error: ApolloError | GraphQLError, expectedCodes: number[]) => {
	if (isApolloError(error)) {
		return error.graphQLErrors.some((e) => expectedCodes.includes(e?.extensions?.statusCode));
	} else {
		return expectedCodes.includes(error?.extensions?.statusCode);
	}
};

export const isExpectedError = (error: ApolloError | GraphQLError) => {
	return (
		errorContainsCodes(error, PERMISSION_ERROR_CODES) ||
		errorContainsCodes(error, CONTENT_ERROR_CODES)
	);
};

export const isPermissionError = (error: ApolloError | GraphQLError | undefined) =>
	error && errorContainsCodes(error, PERMISSION_ERROR_CODES);

export const isEditionAllowed = (edition: ConfluenceEdition | null) => edition !== 'FREE';

export const limitStartDate = (
	max: Duration,
	contentCreatedDate: Date,
	baseDate: Date = new Date(),
): Date => {
	const earliestPossibleStartDate = getEarliestPossibleStartDate(baseDate, max);

	if (isAfter(earliestPossibleStartDate, contentCreatedDate)) {
		return earliestPossibleStartDate;
	}
	return contentCreatedDate;
};

export const getEarliestPossibleStartDate = (today: Date, max: Duration) => {
	return dateAdd(dateSub(today, max), {
		days: 1,
	});
};

export const getGraphGranularity = (createdAtDate: Date): GraphGranularity => {
	const ageOfPageInDays = differenceInCalendarDays(new Date(), createdAtDate);
	if (ageOfPageInDays <= GRANULARITY_RANGE_LIMITS.hours.dayDiff) {
		return GraphGranularity.HOUR;
	} else if (ageOfPageInDays <= GRANULARITY_RANGE_LIMITS.days.dayDiff) {
		return GraphGranularity.DAY;
	} else if (ageOfPageInDays <= GRANULARITY_RANGE_LIMITS.weeks.dayDiff) {
		return GraphGranularity.WEEK;
	}
	return GraphGranularity.MONTH;
};

const formatRelativeDate = (date, now = new Date(), locale: string) => {
	const relativeDateFormatter = new Intl.RelativeTimeFormat(locale, {
		numeric: 'auto',
	});
	return relativeDateFormatter.format(-differenceInCalendarDays(now, date), 'days');
};

const formatIntlDate = (
	date,
	options: {
		showHours?: boolean;
		showMinutes?: boolean;
		showDays?: boolean;
		showMonths?: boolean;
		showYears?: boolean;
	},
) =>
	Intl.DateTimeFormat(undefined, {
		...(options.showMinutes ? { minute: 'numeric' } : {}),
		...(options.showHours ? { hour: 'numeric' } : {}),
		...(options.showDays ? { day: 'numeric' } : {}),
		...(options.showMonths ? { month: 'long' } : {}),
		...(options.showYears ? { year: 'numeric' } : {}),
		hourCycle: 'h12',
	}).format(date);

type FormatSubTextDateOptions = {
	date: Date;
	granularity: GraphGranularity;
	now: Date;
	intl: IntlShape;
};

const formatSubTextDate = ({
	date,
	granularity,
	now = new Date(),
	intl,
}: FormatSubTextDateOptions): string => {
	switch (granularity) {
		case GraphGranularity.HOUR:
			if (differenceInCalendarDays(now, date) < 2) {
				return `${capitalizeFirstLetter(
					formatRelativeDate(date, now, intl.locale),
				)}, ${formatIntlDate(date, {
					showHours: true,
				})}`;
			}
			return formatIntlDate(date, {
				showMonths: true,
				showHours: true,
				showDays: true,
			});
		case GraphGranularity.DAY:
			return formatIntlDate(date, {
				showMonths: true,
				showDays: true,
			});
		case GraphGranularity.WEEK:
			const formattedIntlDate = formatIntlDate(date, {
				showMonths: true,
				showDays: true,
			});
			return intl.formatMessage(i18n.graphGranularityWeek, {
				date: formattedIntlDate,
			});
		default:
			return formatIntlDate(date, {
				showYears: true,
				showMonths: true,
			});
	}
};

type FormatDateRangeOptions = {
	fromDate: Date;
	toDate: Date;
	now?: Date;
	intl: IntlShape;
};

export const formatDateRange = ({
	fromDate,
	toDate,
	now = new Date(),
	intl,
}: FormatDateRangeOptions): string => {
	const hoursFromNow = differenceInHours(now, fromDate);
	const formattedDate = (date: Date) => {
		return formatIntlDate(date, {
			showMonths: true,
			showDays: true,
			showYears: !isSameYear(fromDate, toDate),
		});
	};
	const formattedFromTime = formatIntlDate(fromDate, {
		showHours: true,
		showMinutes: true,
	});

	if (hoursFromNow <= 24) {
		const formattedFromDate = formatRelativeDate(fromDate, now, intl.locale);

		return intl.formatMessage(i18n.graphCreatedAtDateTimeLabel, {
			date: formattedFromDate,
			time: formattedFromTime,
		});
	} else if (hoursFromNow < 48) {
		const formattedFromDate = formattedDate(fromDate);

		return intl.formatMessage(i18n.graphCreatedAtDateTimeLabel, {
			date: formattedFromDate,
			time: formattedFromTime,
		});
	} else {
		const formattedFromDate = formattedDate(fromDate);
		const formattedToDate = formattedDate(toDate);
		const formattedToTime = formatRelativeDate(toDate, now, intl.locale);

		return intl.formatMessage(i18n.graphDateToDateTimeLabel, {
			fromDate: formattedFromDate,
			toDate: formattedToDate,
			toTime: formattedToTime,
		});
	}
};

export const formatInTimeZone = (timeZone: string, date: Date) =>
	format(date, DATE_FORMAT, { timeZone });

type FormatGraphDatesOptions = {
	graphData: GraphQueryResponse['nodes'];
	granularity: GraphGranularity;
	now?: Date;
	intl: IntlShape;
};

export const formatGraphDates = ({
	graphData,
	granularity,
	now = new Date(),
	intl,
}: FormatGraphDatesOptions): Datum[] => {
	return graphData.map((graphPoint) => ({
		date: formatSubTextDate({
			date: new Date(graphPoint.date),
			granularity,
			now,
			intl,
		}),
		value: graphPoint.count,
	}));
};

export const getLastViewedText = (
	lastViewedAt: Date,
	intl: IntlShape,
	now: Date = new Date(),
): string | null => {
	const minutesDiff = differenceInMinutes(now, lastViewedAt);
	const hoursDiff = differenceInHours(now, lastViewedAt);
	const daysDiff = differenceInDays(now, lastViewedAt);
	const monthsDiff = differenceInMonths(now, lastViewedAt);
	const yearsDiff = differenceInYears(now, lastViewedAt);

	// Less than a minute, return 'now'
	if (minutesDiff < 1) {
		return intl.formatMessage(i18n.lastViewedJustNow);
	}
	// Less than an hour, return minutes
	if (minutesDiff < 60) {
		return intl.formatMessage(i18n.lastViewedMinutesAgo, {
			minuteCount: minutesDiff,
		});
	}
	// Less than a day, return hours
	if (hoursDiff < 24) {
		return intl.formatMessage(i18n.lastViewedHoursAgo, {
			hourCount: hoursDiff,
		});
	}
	// Less than 31 days, return days
	if (daysDiff < 31) {
		return intl.formatMessage(i18n.lastViewedDaysAgo, {
			dayCount: daysDiff,
		});
	}
	// Less than a year, return months
	if (monthsDiff < 12) {
		return intl.formatMessage(i18n.lastViewedMonthsAgo, {
			monthCount: monthsDiff,
		});
	}
	// Anything greater than a year just return years
	return intl.formatMessage(i18n.lastViewedYearsAgo, { yearCount: yearsDiff });
};
