import { query } from '@confluence/query-preloader-tools';

import {
	ONBOARDING_INTENT_EXPERIENCES,
	ONBOARDING_EXPERIENCES,
} from '../../constants/onboarding-state/onboarding-constants';

import { getOnboardingStateGQL } from './OnboardingStatus.graphql';

export const preloadIntentBasedOnboardingState = () => {
	return query({
		query: getOnboardingStateGQL,
		variables: {
			key: Object.values({
				...ONBOARDING_INTENT_EXPERIENCES,
				...ONBOARDING_EXPERIENCES,
			}),
		},
	});
};
