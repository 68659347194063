import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const SitePermissions = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencesite-permissionsentry-pointsSitePermissions" */ '@confluence/site-permissions/entry-points/SitePermissions'
			)
		).SitePermissions,
});

export const GlobalPermissionRoute = () => <SitePermissions />;

GlobalPermissionRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'sitePermissionsScreen',
		},
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
