import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ADMIN_USER_ACCESS_ANALYTICS_SOURCE, LazyUserAccessPage } from '@confluence/site-settings';

export const UserAccessRoute = () => <LazyUserAccessPage />;

UserAccessRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_USER_ACCESS_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
