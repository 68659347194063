import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const PageHeaderContainerStyles = xcss({
	marginBottom: 'space.500',
});

const SectionHeaderContainerStyles = xcss({
	marginBottom: 'space.300',
});

export const PageHeaderContainer = ({ children }: React.PropsWithChildren<{}>) => {
	return <Box xcss={PageHeaderContainerStyles}>{children}</Box>;
};

export const SectionHeaderContainer = ({ children }: React.PropsWithChildren<{}>) => {
	return <Box xcss={SectionHeaderContainerStyles}>{children}</Box>;
};
