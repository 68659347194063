import { useEffect } from 'react';

import type { ThemeState } from '@atlaskit/tokens';

import { useThemingActions } from './useThemingState';

// This hook will not set any state upon init and should be used in places where re-render is not acceptable
//  i.e. async fire and forget
export const useInitializeThemeStateless = (initialTheme?: Partial<ThemeState>) => {
	const { initializeTheme } = useThemingActions();

	useEffect(() => {
		void initializeTheme(initialTheme);
		// Only execute upon initial render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
