import type { CategorizedStorageControlsCache } from '../../../types';

// The categories.json file is already configured to be cached by the browser, but for this sampling
// config, it's even less important to manage stale values. Most BSC API interaction is done on
// application startup.  So we may as well cache this in memory once per app load and not retrieve
// it from local disk for each event emitted.
export class CachedSamplingConfig {
	private cache: CategorizedStorageControlsCache['analyticsSamplingRatio'] | undefined;

	get cachedSamplingConfig() {
		return this.cache;
	}

	set cachedSamplingConfig(value: CategorizedStorageControlsCache['analyticsSamplingRatio']) {
		this.cache = value;
	}
}
