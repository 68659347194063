import { type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { type CompletionStatus } from '@atlassiansox/cross-flow-api-internals';
import { type AnalyticsWebClientInterface } from './analytics-helpers/types';
import { type JSONValue, type PluginCollection } from '@atlassiansox/cross-flow-base-types';
import { type PropsWithChildren } from 'react';

export type MaybePromise<T> = T | Promise<T>;

export type OriginType =
	| 'confluence'
	| 'bitbucket'
	| 'start'
	| 'jira'
	| 'trello'
	| 'trusted-admin'
	| 'atlas'
	| 'admin'
	| 'wac';

interface IframeEvent {
	context: UIAnalyticsEvent['context'];
	payload: UIAnalyticsEvent['payload'];
}

export type OnUIAnalyticsEvent = (event: IframeEvent) => void;

export type TenantDefinition = { cloudId?: string };

export type Environment = 'staging' | 'production';

export enum UtmCampaign {
	'atlassian-switcher' = 'atlassian_switcher',
}

export type UtmCampaignType = keyof typeof UtmCampaign;

export interface RedirectToWacDefinition {
	redirectToWac?: boolean;
	env?: Environment;
}

export type CrossFlowProviderProps = PropsWithChildren<
	RedirectToWacDefinition &
		TenantDefinition & {
			analyticsClient: MaybePromise<AnalyticsWebClientInterface>;
			locale: string;
			originProduct: OriginType;
			plugins?: PluginCollection;
			edgePrefix?: string;
			/**
			 * Global experimentalOptions that will be injected into "experimentalOptions" for every touchpoint that CrossFlow is invoked
			 * e.g. crossFlow.api.open({ ..., experimentalOptions: {...globalExperimentalOptions, isXyz:true}})
			 */
			globalExperimentalOptions?: Record<string, JSONValue>;
			onError?: (error: Error) => void;
		}
>;

export type ConsumerProps = {
	onComplete?: (status: CompletionStatus) => void;
	onError?: (error: any) => void;
};

export type { AnalyticsWebClientInterface };
