/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::8a1e9f050af22a87a29eb3d2a0f478c6>>
 * @codegenId #form-section
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen form-section
 * @codegenParams { "props": { "title": true, "children": true, "description": true } }
 * @codegenDependency ../../../../../../../design-system/form/src/form-section.tsx <<SignedSource::7a3dd290cbdad2d83982f363ec94dc86>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { FormSection as PlatformFormSection } from '@atlaskit/form';

type PlatformFormSectionProps = React.ComponentProps<typeof PlatformFormSection>;

export type FormSectionProps = Pick<
  PlatformFormSectionProps,
  'title' | 'children' | 'description'
>;

export const FormSection = (props: Parameters<RenderFn>[0]) => {
  const {
    title,
    description,
  } = props.forgeDoc.props as FormSectionProps;
  return (
    <PlatformFormSection
      title={title}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      description={description}
    />
  );
};
/**
 * @codegenEnd
 */