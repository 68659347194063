import { useEffect } from 'react';
import type { FC } from 'react';

import type { MultiBodiedExtensionActions } from '@atlaskit/editor-common/extensions';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { ConnectMacroModule } from './MacroModule';

export const MultiBodiedExtensionHandler: FC<{
	macroId: string;
	macroParams: string;
	editorActions?: MultiBodiedExtensionActions;
	readonly?: boolean;
}> = ({ macroId, macroParams, editorActions, readonly = false }) => {
	useEffect(() => {
		if (!editorActions) {
			getMonitoringClient().submitError(
				new Error(
					`No editor actions has been provided to MultiBodiedExtensionHandler for macro ${macroId}.`,
				),
				{
					attribution: Attribution.ECOSYSTEM,
				},
			);
			return;
		}

		ConnectMacroModule.register(macroId, macroParams, editorActions, readonly);

		return () => {
			ConnectMacroModule.deregister(macroId);
		};
	}, [macroId, macroParams, editorActions, readonly]);

	return null;
};
