import { useCallback } from 'react';

import { type UseComponentErrorHandling } from './types';

export const useComponentErrorHandling: UseComponentErrorHandling = ({
	onEvent,
	onError,
	fallbackRender,
	errorFallback,
}) => {
	const internalOnError = useCallback(
		(error: Error, info: React.ErrorInfo) => {
			onEvent?.({ type: 'component-error', error });
			onError?.(error, info);
		},
		[onEvent, onError],
	);

	// This is here to facade for a extra prop that overwrote the original error boundary api, consituting a breaking change.
	// This makes sure that the original api is still valid.
	const internalFallbackRender = fallbackRender ?? errorFallback;

	return {
		onError: internalOnError,
		fallbackRender: internalFallbackRender,
	};
};
