import Cookie from 'js-cookie';

import { AllAnalyticsCookies, type AWCCookiesKeys } from './AllAnalyticsCookies';
import LocalStorageAlt from './localStorageAlt';
import { type CookieLike } from './types';
import { canUseCookie, getTld } from './util';
export default class CookieWrapper implements CookieLike {
	private cookieLike: CookieLike;

	constructor(disableCookiePersistence?: boolean) {
		this.cookieLike = canUseCookie(disableCookiePersistence) ? Cookie : new LocalStorageAlt();
	}

	set(
		name: AWCCookiesKeys,
		value: string,
		options?: Cookie.CookieAttributes | undefined,
	): string | undefined {
		// no-op
		if (!AllAnalyticsCookies[name]) {
			// eslint-disable-next-line no-console
			console.warn(`Unknown cookie key ${name} provided, not set.`);
			return;
		}

		return this.cookieLike.set(AllAnalyticsCookies[name].getKey(), value, {
			...AllAnalyticsCookies[name].getAttributes(),
			// Function is memoized so we only calculate this once per page load
			domain: getTld(),
			...options,
		});
	}

	get(name: AWCCookiesKeys): string | undefined {
		if (!AllAnalyticsCookies[name]) {
			// eslint-disable-next-line no-console
			console.warn(`Unknown cookie key ${name} provided, not fetched.`);
			return;
		}
		return this.cookieLike.get(AllAnalyticsCookies[name].getKey());
	}

	remove(name: AWCCookiesKeys, options?: Cookie.CookieAttributes | undefined): void {
		if (!AllAnalyticsCookies[name]) {
			// eslint-disable-next-line no-console
			console.warn(`Unknown cookie key ${name} provided, not removed.`);
			return;
		}
		this.cookieLike.remove(AllAnalyticsCookies[name].getKey(), {
			...options,
		});
	}
}
