import { format, parse } from 'url';

import type { FC, PropsWithChildren } from 'react';
import React, { Component, Fragment, memo } from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import isEqual from 'lodash/isEqual';
import type { Location, Action } from 'history';

import { isHashedOnlyURL } from '@confluence/route-manager-utils';
import { Route } from '@confluence/route';
import type { setBrowserHistoryListenerType } from '@confluence/browser-history';

import { GlobalAnchorHandler } from './GlobalAnchorHandler';
import type { RoutesContextType } from './RoutesContext';
import { RoutesContext } from './RoutesContext';
import type { RoutePolicy } from './RoutePolicy';
import { RouteStateContainer } from './RouteState';

const NOOP = () => {};

export type Match = NonNullable<ReturnType<RoutesContextType['matchSupportedRoute']>>;

/**
 * @param {*} routes
 * @param {string} url
 * @returns {(Route & import(("./Route")).RouteMatch) | null}
 */
export function findAndMatchRoute(
	routes: RouteManagerProps['namedRoutes'] | Route[],
	url: string,
): Match | null {
	// routes can be module from "import" which is not iterable
	const iterableRoutes = Object.values(routes);
	for (const route of iterableRoutes) {
		if (!(route instanceof Route)) {
			// This is important because named-routes has CONTEXT_PATH
			// which is just a string
			continue;
		}
		const match = route.match(url);
		if (match) {
			// FIXME `route` is a `class` instance so it is not 100% safe to spread!
			// But we've been running with it for quite some time, we're not aware of
			// actual problems and I'm only discovering it now while translating to
			// TypeScript.
			//
			// @ts-ignore
			return {
				...route,
				...match,
			};
		}
	}

	return null;
}

/**
 * NOTE: Only exported for testing. DO NOT call this function outside of RouteManager.tsx.
 * @param queryParams
 * @returns Constructed query string
 */
export const _buildQueryString = (queryParams: { [key: string]: any }) => {
	const array = Object.entries(queryParams);

	return array.length
		? array.reduce(
				(accumulator, current, index) =>
					`${accumulator + current[0]}=${encodeURIComponent(current[1])}${
						index < array.length - 1 ? '&' : ''
					}`,
				'?',
			)
		: '';
};

export type RouteManagerProps = PropsWithChildren<{
	namedRoutes: { [name: string]: Route };
	routeComponents: { [name: string]: FC<Match> };
	notFound?: Parameters<typeof React.createElement>[0];

	onInitialize?(match: Match | null, transitionId: number): void;
	onPageReload?: RoutesContextType['onPageReload'];
	onTransition?(
		match: Match | null,
		transitionId: number,
		action: 'POP' | 'PUSH' | 'REPLACE',
	): void;
	onPreload?(match: Match, previousMatch: Match | null): void;
	routePolicy?: RoutePolicy;
	setBrowserHistoryListener?: setBrowserHistoryListenerType;
}>;

class RouteManagerInner extends Component<RouteManagerProps & RouteComponentProps> {
	static defaultProps = {
		routeComponents: {},
		namedRoutes: {},
		onPageReload: NOOP,
	};

	constructor(props: RouteManagerProps & RouteComponentProps) {
		super(props);

		const { location, onInitialize, routeComponents } = this.props;

		// Keep a component => memo(component) mapping so later in matchAndRenderComponent we can render the memoized one.
		// We are not re-creating the routeComponents object because several items in the object may share the same reference.
		// If that is the case then our memoized component should sharing the same reference as well.
		for (const key in routeComponents) {
			const component = routeComponents[key];
			if (!this.memoizedRouteComponents.has(component)) {
				this.memoizedRouteComponents.set(component, memo(component, isEqual));
			}
		}

		const match = this.matchSupportedRoute(
			`${location.pathname}${location.search}${location.hash}`,
		);
		if (onInitialize) onInitialize(match, this.transitionId);
	}

	componentDidMount() {
		const { location, history } = this.props;

		const initialMatch = this.matchSupportedRoute(
			`${location.pathname}${location.search}${location.hash}`,
		);

		let previousMatch: RoutesContextType['match'] = initialMatch;

		const routeChangeHandler = (location: Location, action: Action) => {
			// we de-structure `onTransition` prop here instead of in enclosing scope
			// because otherwise changes to `onTransition` props will be ignored (as this function
			// will have a "cached" version of it via closure).
			const { onTransition } = this.props;
			// New logic determine if it is a transition by calling isTransition on route.
			const match = this.matchSupportedRoute(
				`${location.pathname}${location.search}${location.hash}`,
			);
			if (onTransition && match && match.isTransition(previousMatch, match)) {
				if (action === 'PUSH' || action === 'POP') {
					this.transitionId++;
				}
				onTransition(match, this.transitionId, action);
			}
			previousMatch = match;
		};

		if (this.props.setBrowserHistoryListener) {
			// Ensure RouteManager's `listen` handler is called before the listen handler registered by ConnectedRouter and redux action
			// prettier-ignore
			this.unlistenToHistory = this.props.setBrowserHistoryListener(routeChangeHandler);
		} else {
			this.unlistenToHistory = history.block(routeChangeHandler);
		}
	}

	componentWillUnmount() {
		this.unlistenToHistory?.();
	}

	private memoizedRouteComponents = new WeakMap<FC<Match>, FC<Match>>();
	private unlistenToHistory?(): void;

	// transitionId is incremented on every push/pop to distinguish a deliberate
	// user navigation from an automatic redirect (replace). An id of 0 indicates that
	// we are currently on the initial load.
	//
	// Ideally this would be in component state, since it is passed down through context,
	// and should trigger a re-render when changed.
	// However, we don't want to re-render with the updated value until the location
	// changes, which unfortunately is controlled by <ConnectedRouter>.
	// At the same time, this state cannot be purely derived from location changes,
	// since we need to look at the action that caused the update.
	// When we remove react-router and can make both state changes atomically then
	// this can move into component state alongside the current location.
	transitionId = 0;
	pendingQueryParams: { [key: string]: string } = {};

	/**
	 * Mapping components to named routes
	 */
	routes = this.mapRoutes(this.props.routeComponents);

	mapRoutes(routeComponents: RouteManagerProps['routeComponents']) {
		const supportedRoutes = Object.keys(routeComponents);

		return supportedRoutes.map((name) => {
			const route = this.getRoute(name);
			const mappedRoute = new Route({
				name: route.name,
				ufoName: route.ufoName,
				pattern: route.pattern,
				condition: route.condition,
				isTransition: route.isTransition,
			});
			// @ts-ignore FIXME Who's using `component` on "mapped" `Route`?
			mappedRoute['component'] = routeComponents[name];
			return mappedRoute;
		});
	}

	getRoute(name: string) {
		const { namedRoutes } = this.props;
		const route = namedRoutes[name];
		if (!route) {
			throw new Error(`Unknown route name: ${name}`);
		}

		return route;
	}

	toUrl = (
		name: string | undefined,
		params: {
			query?: { [key: string]: any };
			hash?: string;
			[key: string]: any;
		} = {},
	): string => {
		const href = (params as any)['href'];
		if (href) {
			return href;
		}

		if (!name) {
			return '';
		}

		const query = params.query || {};
		const hash = params.hash || '';
		return this.getRoute(name).toUrl(params, { query, hash });
	};

	matchRoute: RoutesContextType['matchRoute'] = (url) => {
		const { namedRoutes } = this.props;
		return findAndMatchRoute(namedRoutes, url);
	};

	matchSupportedRoute: RoutesContextType['matchSupportedRoute'] = (url) => {
		const parsed = parse(url);
		// Note here we are using location from window not from withRouter
		// history's location doesn't have host field
		// See https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/history.md

		//What is this doing?
		if (parsed.host && parsed.host !== window.location.host) {
			return null;
		}
		return findAndMatchRoute(this.routes, url);
	};

	replace: RoutesContextType['replace'] = (url, forceReload = false) => {
		const { history, onPageReload = RouteManagerInner.defaultProps.onPageReload } = this.props;

		let match: ReturnType<RouteManagerInner['matchSupportedRoute']>;
		if (!forceReload && (match = this.matchSupportedRoute(url))) {
			// replace only supports pushing a path.
			// here the url might be a full URL that has same domain
			history.replace(
				format({
					pathname: match.pathname,
					search: match.search,
					hash: match.hash,
				}),
			);
		} else {
			onPageReload(url, true);
			// eslint-disable-next-line no-restricted-syntax
			window.location.replace(url);
		}
	};

	open: RoutesContextType['open'] = (url, target, features) => window.open(url, target, features);

	defaultPush: RoutesContextType['push'] = (url, forceReload = false) => {
		const {
			history,
			onPageReload = RouteManagerInner.defaultProps.onPageReload,
			location,
		} = this.props;

		let match: ReturnType<RouteManagerInner['matchSupportedRoute']>;

		if (
			!forceReload &&
			(match = this.matchSupportedRoute(
				isHashedOnlyURL(url)
					? format({
							pathname: location.pathname,
							search: location.search,
							hash: url,
						})
					: url,
			))
		) {
			// push only supports pushing a path.
			// here the url might be a full URL that has same domain
			history.push(
				format({
					pathname: match.pathname,
					search: match.search,
					hash: match.hash,
				}),
			);
		} else {
			onPageReload(url, false);
			// eslint-disable-next-line no-restricted-syntax
			window.location.assign(url);
		}
	};

	push: RoutesContextType['push'] = (url, forceReload = false) => {
		const { routePolicy } = this.props;

		if (routePolicy) {
			return routePolicy.push(
				{
					...this.getRoutesContextValue(),
					push: this.defaultPush,
				},
				url,
				forceReload,
			);
		}

		return this.defaultPush(url, forceReload);
	};

	getQueryParams: RoutesContextType['getQueryParams'] = () => {
		const { location } = this.props;
		return parse(location.search, true).query;
	};

	setQueryParams: RoutesContextType['setQueryParams'] = (query, replace = false) => {
		if (query && typeof query === 'object') {
			const { history } = this.props;
			const queryParams = {
				...this.getQueryParams(),
				...this.pendingQueryParams,
				...query,
			};

			Object.keys(queryParams).forEach((key) => {
				if (queryParams[key] === null || queryParams[key] === undefined) {
					delete queryParams[key];
				}
			});

			const search = this.buildQueryString(queryParams);

			(replace ? history.replace : history.push)({ search });

			Object.assign(this.pendingQueryParams, query);
			// https://github.com/ReactTraining/history/issues/767#issuecomment-573358882
			setTimeout(() => (this.pendingQueryParams = {}));
		}
	};

	buildQueryString = _buildQueryString;

	matchAndRenderComponent(match: Match | null) {
		const { notFound, routeComponents } = this.props;
		if (match === null) {
			return notFound ? React.createElement(notFound) : null;
		} else {
			window.performance.mark('CFP-63.route-component-render');
			const memoizedComponent = this.memoizedRouteComponents.get(routeComponents[match.name]);
			return memoizedComponent ? React.createElement(memoizedComponent, match) : null;
		}
	}

	getHash = () => {
		const { location } = this.props;
		return location.hash ? location.hash.substring(1) : '';
	};

	setHash: RoutesContextType['setHash'] = (hash) => {
		const { history, location } = this.props;

		history.replace({
			...location,
			hash,
		});
	};

	getHref() {
		return location.pathname + location.search;
	}

	preloadRoute: RoutesContextType['preloadRoute'] = (matchOrUrl) => {
		// URL match and string are among the most common representations in our
		// codebase. RouteManager owns converting between them so reduce the
		// strain on the caller and accept both:
		const match =
			typeof matchOrUrl === 'string' ? this.matchSupportedRoute(matchOrUrl) : matchOrUrl;
		if (match) {
			const previousMatch = this.matchSupportedRoute(this.getHref());
			const { onPreload } = this.props;
			if (onPreload) onPreload(match, previousMatch);
		}
	};

	getRoutesContextValue = (): RoutesContextType => {
		const { history, location, onPageReload } = this.props;

		return {
			getHref: this.getHref,
			history,
			location,
			match: this.matchSupportedRoute(`${location.pathname}${location.search}${location.hash}`),
			transitionId: this.transitionId,
			toUrl: this.toUrl,
			matchRoute: this.matchRoute,
			matchSupportedRoute: this.matchSupportedRoute,
			getQueryParams: this.getQueryParams,
			setQueryParams: this.setQueryParams,
			getHash: this.getHash,
			setHash: this.setHash,
			replace: this.replace,
			routes: this.routes,
			push: this.push,
			open: this.open,
			onPageReload: onPageReload || NOOP,
			preloadRoute: this.preloadRoute,
		};
	};

	render() {
		const { children } = this.props;
		const routesContextValue = this.getRoutesContextValue();
		return (
			<RouteStateContainer {...routesContextValue}>
				<RoutesContext.Provider value={routesContextValue}>
					{this.matchAndRenderComponent(routesContextValue.match)}
					<Fragment>{children}</Fragment>
					<GlobalAnchorHandler />
				</RoutesContext.Provider>
			</RouteStateContainer>
		);
	}
}

export const RouteManager = withRouter(RouteManagerInner);
