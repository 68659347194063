import gql from 'graphql-tag';

export const HubAnalyticsChartQuery = gql`
	query HubAnalyticsChartQuery(
		$spaceId: String!
		$startDate: String!
		$endDate: String!
		$timezone: String!
		$granularity: AnalyticsTimeseriesGranularity!
	) {
		hubViews: timeseriesPageBlogCount(
			spaceId: [$spaceId]
			contentType: page
			contentAction: viewed
			startTime: $startDate
			endTime: $endDate
			timezone: $timezone
			granularity: $granularity
		) {
			nodes {
				date
				count
			}
		}
	}
`;

export const HubAnalyticsClicksChartQuery = gql`
	query HubAnalyticsClicksChartQuery(
		$startDate: String!
		$timezone: String!
		$granularity: AnalyticsTimeseriesGranularity!
	) {
		hubClicks: timeseriesCount(
			startTime: $startDate
			timezone: $timezone
			granularity: $granularity
			eventName: [companyHubLink_clicked]
		) {
			nodes {
				date
				count
			}
		}
	}
`;

export const HubAnalyticsUniqueVisitorsChartQuery = gql`
	query HubAnalyticsUniqueVisitorsChartQuery(
		$spaceId: String!
		$granularity: AnalyticsTimeseriesGranularity!
		$startDate: String!
		$endDate: String!
		$timezone: String!
	) {
		hubUniqueVisitors: timeseriesUniqueUserCount(
			endTime: $endDate
			eventName: [companyHubLink_viewed]
			granularity: $granularity
			spaceId: [$spaceId]
			startTime: $startDate
			timezone: $timezone
		) {
			nodes {
				date
				count
			}
		}
	}
`;

export const HubAnalyticsCTRChartQuery = gql`
	query HubAnalyticsCTRChartQuery(
		$granularity: AnalyticsTimeseriesGranularity!
		$startDate: String!
		$endDate: String!
		$timezone: String!
	) {
		hubCTR: eventTimeseriesCTR(
			startTime: $startDate
			endTime: $endDate
			timezone: $timezone
			granularity: $granularity
			clickEventName: companyHubLink_clicked
			discoverEventName: companyHubLink_viewed
			sortOrder: "desc"
		) {
			nodes {
				ctr
				timestamp
			}
		}
	}
`;
