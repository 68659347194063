import { deferScript } from '@confluence/dom-helpers';

export class ResourceRecorder {
	private resource: string;

	constructor() {
		this.resource = '';
	}

	reset() {
		this.resource = '';
	}

	add(resource: string) {
		this.resource += resource;
		return this;
	}

	addDeferTags(tags: string) {
		this.resource += deferScript(tags);
		return this;
	}

	get() {
		return this.resource;
	}
}
