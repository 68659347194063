import { LoadableAfterPaint } from '@confluence/loadable';

export const BrowserNotSupportedLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcBrowserNotSupported" */ '../src/BrowserNotSupported'
			)
		).BrowserNotSupported,
});
