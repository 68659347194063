import idx from 'idx';
import { isApolloError } from 'apollo-client';

import { BadStatusError } from './BadStatusError';

const CAUGHT_SESSION_ERROR_TEXT = 'Session expired';

export class SessionExpiredError extends BadStatusError {
	constructor(message: string, response: Response) {
		super(`${message} ${CAUGHT_SESSION_ERROR_TEXT}`, response);
	}
}

// At the moment when we invoke the function it's still a generic Object
type GraphQLErrorLikeObject = {
	extensions: {
		statusCode: number;
	};
};

export function isSessionExpiredError(error: Error | GraphQLErrorLikeObject | null): boolean {
	if (!error) {
		return false;
	}
	if (error instanceof SessionExpiredError) {
		return true;
	}
	const message = idx(error as Error, (_) => _.message) || '';
	if (message.indexOf(CAUGHT_SESSION_ERROR_TEXT) != -1) {
		return true;
	}
	const statusCode = idx(error as GraphQLErrorLikeObject, (_) => _.extensions.statusCode);
	if (statusCode == 401) {
		return true;
	}
	return false;
}

export function containsSessionExpiredError(error: Error | null): boolean {
	if (!error) {
		return false;
	}

	if (isSessionExpiredError(error)) {
		return true;
	}

	if (isApolloError(error)) {
		const { graphQLErrors, networkError } = error;
		if (isSessionExpiredError(networkError)) {
			return true;
		}

		if ((graphQLErrors || []).some(isSessionExpiredError)) {
			return true;
		}
	}

	return false;
}
