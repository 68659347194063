import { ApolloLink } from 'apollo-link';

import { getSuperAdminClaim } from '@confluence/super-admin-claim';

const SUPER_ADMIN_DIRECTIVE = 'asSuperAdmin';

/**
 * This custom link adds the @asSuperAdmin directive to all outgoing
 * query and mutation based on the existence of the confluence.asSuperAdmin cookie.
 */
export const addSuperAdminDirectiveLink = new ApolloLink((operation, forward) => {
	const { asSuperAdmin = true } = operation.getContext();

	if (asSuperAdmin && getSuperAdminClaim()) {
		operation.query.definitions.forEach((definition) => {
			if (!('operation' in definition)) {
				return;
			}

			const { operation, directives } = definition;

			if (operation === 'query' || operation === 'mutation') {
				const superAdminDirectiveExists = directives?.some(
					(directive) => directive.name.value === SUPER_ADMIN_DIRECTIVE,
				);

				if (!superAdminDirectiveExists) {
					const superAdminDirective = {
						arguments: [],
						kind: 'Directive',
						name: {
							kind: 'Name',
							value: SUPER_ADMIN_DIRECTIVE,
						},
					};

					// @ts-ignore "directive" is marked as ReadonlyArray in types
					directives?.push(superAdminDirective);
				}
			}
		});
	}

	return forward(operation);
});
