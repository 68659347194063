import {
	CONTENT_RENDERER_SEGMENT_KEY,
	createPageSegmentLoadMetric,
} from '@confluence/browser-metrics';

export const CONTENT_RENDERER_METRIC = createPageSegmentLoadMetric({
	key: CONTENT_RENDERER_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
	timings: [
		{ key: 'tti', endMark: 'tti' },
		{ key: 'fy23-legacy-tti', endMark: 'fy23-legacy-tti' },
	],
});
