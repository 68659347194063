import { Component } from 'react';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';

export type ScreenEventProps = {
	name: string;
	id: string;
	attributes?: object;
};

export const ScreenEvent = withAnalyticsEvents()(
	class ScreenEvent extends Component<ScreenEventProps & WithAnalyticsEventsProps> {
		componentDidMount() {
			this.sendScreenEvent();
		}

		shouldComponentUpdate(nextProps: ScreenEventProps & WithAnalyticsEventsProps) {
			return nextProps.name !== this.props.name || nextProps.id !== this.props.id;
		}

		componentDidUpdate() {
			this.sendScreenEvent();
		}

		sendScreenEvent() {
			const { createAnalyticsEvent, name, attributes } = this.props;
			const analyticsEvent = createAnalyticsEvent?.({
				type: 'sendScreenEvent',
				data: {
					name,
					attributes,
				},
			});
			analyticsEvent?.fire();
		}

		render() {
			return null;
		}
	},
);
