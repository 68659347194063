import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';

import { NotFound } from '../NotFound';

export const ActionRedirectLegacyRoute = ({ pathname, search, query }: RouteMatch) => {
	if (query['unmatched-route']) {
		return <NotFound />;
	}
	search = search.startsWith('?') ? `${search}&unmatched-route=true` : `?unmatched-route=true`;
	return (
		//Redirect for .action pages that forces a full page reload
		<Redirection href={`${pathname}${search}`} forceReload />
	);
};

ActionRedirectLegacyRoute.NAVIGATION_PARAMS = () => false;
