/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::e596e1908215394c3df0bdd6555d60c9>>
 * @codegenId #toggle
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen toggle
 * @codegenParams { "hasFieldProps": { "isRequired": false, "isInvalid": false, "aria-invalid": false, "aria-labelledby": false }, "props": { "size": true, "testId": true, "defaultChecked": true, "isChecked": true, "label": true } }
 * @codegenDependency ../../../../../../../design-system/toggle/src/types.tsx <<SignedSource::e784570455b1275c74dc2ef4948cd81d>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::776e3f3df8ff5c06e378bdcf4d407167>>
 * @codegenDependency ../../../../../../../design-system/toggle/src/internal/styles.tsx <<SignedSource::e0ec35aeaf51ef8f100628651fdfa8a5>>
 * @codegenDependency ../../../../../../../design-system/toggle/src/toggle.tsx <<SignedSource::5cab3077df217e91bc11e9b25b8c3e37>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformToggle from '@atlaskit/toggle';
import { adaptEventHandler } from "../../../utils";
import type { EventHandlerProps } from "../../types";

type PlatformToggleProps = React.ComponentProps<typeof PlatformToggle>;

export type ToggleProps = Pick<
  PlatformToggleProps,
  'size' | 'testId' | 'defaultChecked' | 'isChecked' | 'label'
 | 'id' | 'isDisabled' | 'value' | 'name'
> & Pick<EventHandlerProps, 'onChange' | 'onBlur' | 'onFocus'>;

export const Toggle = (props: Parameters<RenderFn>[0]) => {
  const {
    size,
    testId,
    defaultChecked,
    isChecked,
    label,
    // Field props
    id,
    isDisabled,
    onChange,
    onBlur,
    onFocus,
    value,
    name,
  } = props.forgeDoc.props as ToggleProps;
  return (
    <PlatformToggle
      size={size}
      testId={testId}
      defaultChecked={defaultChecked}
      isChecked={isChecked}
      label={label}
      // Field props
      id={id}
      isDisabled={isDisabled}
      onChange={adaptEventHandler(onChange)}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      value={value}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
