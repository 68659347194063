import React, { Fragment, memo } from 'react';

import { isInitial, PageLoadMark } from '@confluence/browser-metrics';

// These metrics are tracked for historical purposes.
// Refer to the content-rendered page-segment-load for a better measurement of the main content timing.
export const LegacyPerformanceMetrics = memo(() => {
	const shouldMarkFmp = !(isInitial() && window?.__SSR_RENDERED__);

	// The legacy markers represent where the <PageLoadEnd> previously existed in financial year 2021.
	return (
		<Fragment>
			{shouldMarkFmp && <PageLoadMark name="fy21-legacy-fmp" />}
			<PageLoadMark name="fy21-legacy-tti" />
		</Fragment>
	);
});
