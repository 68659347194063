import { useMemo } from 'react';

import type { IframePassThroughProps } from '@atlassian/embedded-confluence/IframeElementType';
import { useReactProps_DO_NOT_USE } from '@atlassian/embedded-confluence/useReactProps';

import type { RoutePolicy } from '../RoutePolicy';

import { useRoutePolicy } from './useRoutePolicy';
import { useShimUrl } from './useShimUrl';

export type NavigationPolicyType = {
	shimUrl: (urlToShim: string) => string;
	shimAdfUrls: (adf: object) => object;
	shimHtmlUrls: (html: string | null) => string | null;
	routePolicy: RoutePolicy | undefined;
};

export function useNavigationPolicy(): NavigationPolicyType {
	const { navigationPolicy } = useReactProps_DO_NOT_USE<IframePassThroughProps>() || {};

	const routePolicy = useRoutePolicy(navigationPolicy);
	const shimUrl = useShimUrl(navigationPolicy);

	return useMemo(
		() => ({
			routePolicy,
			...shimUrl,
		}),
		[routePolicy, shimUrl],
	);
}
