import { parse } from 'url';

import { matchPath } from 'react-router';

export type MatchQuery = {
	[key: string]: string | string[] | undefined;
};

export type UrlMatch = {
	// Raw URL parts
	url: string;
	pathname: string;
	search: string;
	hash: string;

	// Pattern-matching or processing
	pattern: string;
	params: { [key: string]: string };
	query: MatchQuery;
};

export function matchUrl(pattern: string, url: string): UrlMatch | null {
	const parsed = parse(url, true);
	const pathname: string = parsed.pathname || '';
	let match = matchPath<{ [key: string]: string }>(pathname, {
		path: pattern,
		exact: true,
	});

	// some of our routes need query params to match
	if (!match) {
		match = matchPath(`${pathname}${parsed.search}`, {
			path: pattern,
			exact: true,
		});
	}

	if (!match) {
		return null;
	}

	// URI decode params
	const decodedParams: { [key: string]: string } = {};
	if (match.params) {
		for (const [key, value] of Object.entries(match.params)) {
			let decodedValue = value;
			if (typeof value !== 'undefined') {
				try {
					// decodeURIComponent("%") throws "URI malformed" error
					decodedValue = decodeURIComponent(value);
				} catch (e) {}
				decodedParams[key] = decodedValue;
			}
		}
	}

	return {
		url,
		pathname,
		search: parsed.search || '',
		hash: parsed.hash || '',
		pattern,
		params: decodedParams,
		query: parsed.query || '',
	};
}
