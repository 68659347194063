import { useContext } from 'react';
import { useEgressPermissionService } from '../../utils/useEgressPermissionService';
import { RendererContext } from '../../context';

const isLocalImage = (url: string, iframeDomainName: string | undefined): boolean => {
	if (!iframeDomainName) {
		return false;
	}

	let imageSrcUrl;
	try {
		imageSrcUrl = new URL(url);
		return iframeDomainName === imageSrcUrl.host;
	} catch (e) {
		return false;
	}
};

export const useIsValidImageUrl = (url: string): boolean => {
	const { egress, appDomainName } = useContext(RendererContext);

	const dataImageUrlRegex = /^data:image/;
	const egressService = useEgressPermissionService('IMAGES', egress);

	try {
		const isValidUrl =
			isLocalImage(url, appDomainName) ||
			dataImageUrlRegex.test(url) ||
			egressService.isValidUrl(url);

		return isValidUrl;
	} catch (e) {
		return false;
	}
};
