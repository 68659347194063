export { markErrorAsHandled, isErrorMarkedAsHandled } from '@confluence/graphql-error-processor';
export { isGraphQLError } from '@confluence/network'; // re-export because this package fits the naming better, but causes a circular dependency

export { apolloCache, createClient, resetApolloStats } from './createClient';
export { getAGGClient } from './getAGGClient';
export { QUERY_OVERRIDE_KEYS } from './links/QueryOverridesLink';

import { enableNetworkRetryLink } from './links/NetworkErrorRetryLink';
import { enableStatusCodeRetryLink } from './links/StatusCodeRetryLink';
import { apolloCache } from './createClient';
import { apolloAGGCache } from './getAGGClient';

// Export for mocking
export { hasWebSocketLink, getSubscriptionClientInstance } from './links/WebSocketLink';
export { NetworkStatus, networkStatusLink } from './links/NetworkStatusLink';
export { ClientSchema } from './types/ClientSchema.graphqls';
export { useQueryNoFurtherUpdate } from './useQueryNoFurtherUpdate';
export { getApolloClient } from './getApolloClient';

export function getApolloCache() {
	return apolloCache;
}

export function getApolloAGGCache() {
	return apolloAGGCache;
}

// On client side the uri is /cgraphql
// However when running on the server side we want to dynamic change the URI
// So we can route the request to the graphql deployment that closer to the data.
// This function should only be used in ssr-app.
export function updateGraphQLEndpointURI(uri: string) {
	window['GLOBAL_APOLLO_CLIENT_URI'] = uri;
}

// When firing preload functions we don't have experience tracker started thus we can only annotate the request through URL match
export function setFallbackAtlExperience(exp: string) {
	window['GLOBAL_FALLBACK_ATL_EXP'] = exp;
}

// Used for skipping all the graphql queries
// This function should only be used in ssr-app.
export function setStopGraphQLQueries(value: boolean) {
	window['GLOBAL_APOLLO_SKIP_QUERY'] = value;
}

export function enableGraphqlRetries() {
	enableNetworkRetryLink();
	enableStatusCodeRetryLink();
}

export { SSR_TEST_QUERY_FIRED } from './links/SSRTestLink';
