function isDefined(path) {
  const pathParts = path.split(".");
  let obj = window[pathParts.shift()];
  while (typeof obj !== "undefined" && pathParts.length) {
    obj = obj[pathParts.shift()];
  }
  return obj;
}

const lines = {};
function logOnce(message) {
  if (process.env.NODE_ENV !== "production") {
    if (!lines[message]) {
      console.info(message);
      lines[message] = true;
    }
  }
}

// Export for testing
export function clearCache() {
  for (const key in lines) {
    delete lines[key];
  }
}

export default function waitForGlobal(
  { listener, globalProperty, interval = 250 },
  callback
) {
  if (isDefined(globalProperty)) {
    logOnce(
      `window.${globalProperty} is already available for use by ${listener}.`
    );
    callback();
    return null;
  }
  const waitForGlobalInterval = setInterval(() => {
    logOnce(
      `${listener} is waiting for window.${globalProperty} to be available`
    );
    if (isDefined(globalProperty)) {
      logOnce(`window.${globalProperty} now available!`);
      clearInterval(waitForGlobalInterval);
      callback();
    }
  }, interval);
  return {
    cancel() {
      if (waitForGlobalInterval) {
        clearInterval(waitForGlobalInterval);
      }
    },
  };
}
