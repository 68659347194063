import React from 'react';

import { TableOfContentsLoader } from '@confluence/table-of-contents';

export const TableOfContentsHandler = ({ macroDefaultProps, adf, cloudId, userId }) => {
	return (
		<TableOfContentsLoader
			userId={userId}
			cloudId={cloudId}
			adf={adf}
			macroDefaultProps={macroDefaultProps}
		/>
	);
};
