import type { Option, Parameters } from '@atlaskit/editor-common/extensions';

import { cfetch } from '@confluence/network';

import { toArray } from './utils';

const search = async (cql: string) => {
	return cfetch(`/wiki/rest/api/search?limit=10&expand=content.container.icon&cql=${cql}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
		.then((result) => result.json())
		.then((response) =>
			response.results.map((result) => ({
				label: result.content.title,
				value: result.content.id,
				icon: `/wiki${result.content.container.icon.path}`,
				description: result.content.container.name,
			})),
		);
};

export const idInCqlClause = (contentIds: string[]) => `id IN (${contentIds.join(',')})`;

export const confluenceContentResolver = async (
	searchTerm?: string,
	selectedValue?: string | string[],
	options?: Parameters,
) => {
	const searchers: Array<Promise<Option[]>> = [];
	const type = (options && options.type) || 'page, blogpost';
	const selectedValues = toArray(selectedValue);
	const conditionalCql = selectedValues.length ? `OR ${idInCqlClause(selectedValues)}` : '';

	if (searchTerm) {
		searchers.push(search(`type IN (${type}) AND title ~ "${searchTerm}*" ${conditionalCql}`));
	} else {
		searchers.push(
			search(`type IN (${type}) AND id in recentlyViewedContent(5) ${conditionalCql}`),
		);
	}

	return (await Promise.all(searchers)).flat();
};
