import React from 'react';

import Icon from '@atlaskit/icon/base';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { N30, N60, B50, B300 } from '@atlaskit/theme/colors';

const ChildMacroIconGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid="child-macro-icon"
	>
		<rect
			x="6"
			y="7"
			width="23"
			height="18"
			rx="2"
			fill={token('color.background.accent.blue.subtlest.hovered', B50)}
		/>
		<rect x="9" y="10" width="2" height="2" rx="1" fill={token('color.link', B300)} />
		<rect x="13" y="10" width="13" height="2" rx="1" fill={token('color.link', B300)} />
		<rect x="9" y="15" width="2" height="2" rx="1" fill={token('color.link', B300)} />
		<rect x="13" y="15" width="13" height="2" rx="1" fill={token('color.link', B300)} />
		<rect x="9" y="20" width="2" height="2" rx="1" fill={token('color.link', B300)} />
		<rect x="13" y="20" width="13" height="2" rx="1" fill={token('color.link', B300)} />
		<rect
			x="6"
			y="2"
			width="23"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N60)}
		/>
		<rect
			x="6"
			y="28"
			width="23"
			height="2"
			rx="1"
			fill={token('color.background.accent.gray.subtlest.pressed', N60)}
		/>
		<path
			d="M3 3.5L2 2.5"
			stroke={token('color.background.accent.gray.subtlest.pressed', N60)}
			strokeLinecap="round"
		/>
		<path
			d="M3 3.5L4 2.5"
			stroke={token('color.background.accent.gray.subtlest.pressed', N60)}
			strokeLinecap="round"
		/>
		<path
			d="M3.5 29L2.5 30"
			stroke={token('color.background.accent.gray.subtlest.pressed', N60)}
			strokeLinecap="round"
		/>
		<path
			d="M3.5 29L2.5 28"
			stroke={token('color.background.accent.gray.subtlest.pressed', N60)}
			strokeLinecap="round"
		/>
	</svg>
);

export const ChildMacroIcon = (props: IconProps) => (
	<Icon
		glyph={ChildMacroIconGlyph}
		size="large"
		secondaryColor={token('color.background.accent.gray.subtler', N30)}
		{...props}
	/>
);
