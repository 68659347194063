import { createSelector } from 'reselect';

import { hasFeatureBeenDiscovered } from '@confluence/fabric-page-optin-migration/entry-points/hasFeatureBeenDiscovered';

export const getDiscoveredPluginFeatures = (state) => state.discoveredFeatures;
export const haveDiscoveredFeaturesBeenLoaded = createSelector(
	getDiscoveredPluginFeatures,
	(discoveredPluginFeatures) => discoveredPluginFeatures.get('hasLoaded'),
);
const getInputParameter = (state, param) => param;

export const hasFeatureBeenDiscoveredSelector = createSelector(
	getDiscoveredPluginFeatures,
	haveDiscoveredFeaturesBeenLoaded,
	getInputParameter,
	(discoveredPluginFeatures, discoveredFeaturesLoaded, { pluginKey = '', featureKey = '' } = {}) =>
		!discoveredFeaturesLoaded ||
		hasFeatureBeenDiscovered(discoveredPluginFeatures.get('list'), {
			pluginKey,
			featureKey,
		}),
);
