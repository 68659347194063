import type { IntlShape, MessageDescriptor } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';
import React from 'react';
import type { ApolloError } from 'apollo-client';
import ReactDOM from 'react-dom';

import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution, isUnauthorizedError } from '@confluence/error-boundary';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { DATABASE_VIEW } from '@confluence/named-routes';
import type { FlagsStateContainer } from '@confluence/flags';
import { getTraceIdFromApolloError } from '@confluence/template-utils';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { CREATE_DATABASE_EXPERIENCE, ExperienceTimeout } from '@confluence/experience-tracker';
import { getSessionData } from '@confluence/session-data';

import { CreateContentInlineMutation } from './CreateContentInline.graphql';
import { i18n } from './i18n';
import InlineCreateIcon from './icons/InlineCreateIcon.svg';
import InlineEmbedIcon from './icons/InlineEmbedIcon.svg';
import { DatabaseReferenceModal } from './components/DatabaseReferenceModal';
import { DatabaseValueContainer } from './components/DatabaseValueContainer';

type CreateDatabaseExtensionProps = {
	pageId: string;
	spaceKey: string;
	spaceId: string;
	intl: IntlShape;
	experienceTracker?: ExperienceTrackerAPI;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	flags?: FlagsStateContainer;
	contentType?: string;
	allowDatabaseQuickInsert?: boolean;
	isEdit?: boolean;
};

type ExpectedError = {
	description: MessageDescriptor;
	title: MessageDescriptor;
} | null;

const isParentPageMovedError = (error: any) => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some((graphQLError: any) =>
			graphQLError.extensions.data.errors.some((nestedError: any) =>
				nestedError.message.key.includes('The parent space does not match the desired space'),
			),
		);
	}
	return false;
};

const isInvalidParentError = (error: any) => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some((graphQLError: any) =>
			graphQLError.extensions.data.errors.some((nestedError: any) =>
				nestedError.message.key.includes('Invalid potential parent passed'),
			),
		);
	}
	return false;
};

export const getExpectedError = (error: ApolloError | Error | null | undefined): ExpectedError => {
	if (isParentPageMovedError(error)) {
		return {
			title: i18n.parentPageMovedErrorTitle,
			description: i18n.parentPageMovedErrorDescription,
		};
	} else if (isInvalidParentError(error)) {
		return {
			title: i18n.invalidParentErrorTitle,
			description: i18n.invalidParentErrorDescription,
		};
	} else if (isUnauthorizedError(error)) {
		return {
			title: i18n.permissionRemovedErrorTitle,
			description: i18n.permissionRemovedErrorDescription,
		};
	}
	return null;
};

export const createDatabaseQuickInsertModule = (
	pageId: string,
	spaceKey: string,
	spaceId: string,
	experienceTracker?: ExperienceTrackerAPI,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	flags?: FlagsStateContainer,
) => {
	return async () => {
		try {
			experienceTracker?.start({
				name: CREATE_DATABASE_EXPERIENCE,
				timeout: ExperienceTimeout.CREATE_CONTENT,
				attributes: {
					flow: 'inlinePageCreation',
				},
			});
			const mutationResult = await getApolloClient().mutate({
				mutation: CreateContentInlineMutation,
				variables: { createdInContentId: pageId, spaceKey },
			});
			const { id: contentId } = mutationResult.data.createContentInline;

			createAnalyticsEvent?.({
				type: 'sendTrackEvent',
				data: {
					actionSubject: 'databaseMacro',
					action: 'created',
					objectId: contentId,
					objectType: 'database',
					containerId: spaceId,
					containerType: 'space',
					source: 'editorComponent',
					actionSubjectId: contentId,
					attributes: {
						macroType: 'DATABASE',
						databaseContainer: 'PAGE',
						isNew: true,
					},
				},
			}).fire();

			const fullUrl = getFullDatabaseUrl(contentId, spaceKey);

			await flags?.showFlag({
				type: 'success-circle',
				title: <FormattedMessage {...i18n.successFlagTitle} />,
				actions: [
					{
						content: <FormattedMessage {...i18n.successFlagAction} />,
						href: fullUrl,
					},
				],
				description: <FormattedMessage {...i18n.successFlagDescription} />,
			});

			experienceTracker?.succeed({
				name: CREATE_DATABASE_EXPERIENCE,
			});

			return {
				type: 'embedCard',
				attrs: {
					url: fullUrl,
				},
			};
		} catch (error) {
			const cteTraceId = getTraceIdFromApolloError(error);
			const expectedErrorMessage = getExpectedError(error);
			if (expectedErrorMessage) {
				experienceTracker?.succeed({
					name: CREATE_DATABASE_EXPERIENCE,
					attributes: {
						abortReason: 'expectedErrorMessage',
					},
				});
				await flags?.showErrorFlag({
					title: <FormattedMessage {...expectedErrorMessage.title} />,
					description: <FormattedMessage {...expectedErrorMessage.description} />,
				});
			} else {
				getMonitoringClient().submitError(error, {
					attribution: Attribution.DATABASES,
				});
				experienceTracker?.stopOnError({
					name: CREATE_DATABASE_EXPERIENCE,
					error,
					attributes: {
						cteTraceId,
					},
				});
				await flags?.showErrorFlag({
					title: <FormattedMessage {...i18n.defaultErrorFlagTitle} />,
					description: <FormattedMessage {...i18n.defaultErrorFlagDescription} />,
				});
			}
			markErrorAsHandled(error);
		}
	};
};

export const getBaseUrl = (overrideLocalhost: boolean = true) => {
	if (typeof window.location !== 'undefined') {
		let baseUrl = window.location.origin;
		if (new URL(baseUrl).hostname === 'localhost' && overrideLocalhost) {
			baseUrl = window!.document
				.querySelector("meta[name='confluence-base-url']")
				?.getAttribute('content') as string;
			baseUrl = baseUrl.substring(0, baseUrl.length - 5);
		}
		return baseUrl;
	} else {
		return '';
	}
};

export const getFullDatabaseUrl = (contentId: string, spaceKey: string) => {
	const databaseUrl = DATABASE_VIEW.toUrl({
		spaceKey,
		contentId,
	});

	const fullUrl = getBaseUrl() + databaseUrl;

	return fullUrl;
};

export const getDatabaseReferenceUrl = (spaceKey: string, cloudId: string): string => {
	const baseUrl = getBaseUrl(false);

	const fullUrl = `${baseUrl}/databases${
		process.env.NODE_ENV === 'development' ? '-dev' : ''
	}/embed/?referencing=true&embedded=true&spaceKey=${spaceKey}&cloudId=${cloudId}`;

	return fullUrl;
};

export const referenceDatabaseQuickInsertModule = (spaceKey: string, intl: IntlShape) => {
	return async () => {
		const el = document.createElement('div');
		document.body.appendChild(el);

		const { cloudId } = await getSessionData();
		const iframeUrl = getDatabaseReferenceUrl(spaceKey, cloudId);

		let result;
		try {
			result = await new Promise((resolve) => {
				// eslint-disable-next-line react/no-deprecated
				ReactDOM.render(
					<DatabaseReferenceModal
						onClose={async (result: string | undefined) => {
							resolve(result);
						}}
						iframeUrl={iframeUrl}
						intl={intl}
					/>,
					el,
				);
			});
		} finally {
			// eslint-disable-next-line react/no-deprecated
			ReactDOM.unmountComponentAtNode(el);
			document.body.removeChild(el);
		}

		if (result) {
			return {
				type: 'embedCard',
				attrs: {
					url: result,
				},
			};
		} else {
			return;
		}
	};
};

export const createDatabaseExtension = ({
	pageId,
	spaceKey,
	spaceId,
	intl,
	flags,
	experienceTracker,
	createAnalyticsEvent,
	contentType,
	allowDatabaseQuickInsert,
	isEdit,
}: CreateDatabaseExtensionProps): ExtensionManifest => {
	const quickInsert = [];

	if (contentType === 'page') {
		quickInsert.push({
			key: 'create-database',
			title: intl.formatMessage(i18n.createDatabaseTitle),
			description: intl.formatMessage(i18n.createDatabaseDescription),
			icon: async () => () => (
				<img src={InlineCreateIcon} alt={intl.formatMessage(i18n.createDatabaseIconAltText)} />
			),
			action: createDatabaseQuickInsertModule(
				pageId,
				spaceKey,
				spaceId,
				experienceTracker,
				createAnalyticsEvent,
				flags,
			),
		});
	}

	quickInsert.push({
		key: 'reference-database',
		title: intl.formatMessage(i18n.referenceDatabaseTitle),
		description: intl.formatMessage(i18n.referenceDatabaseDescription),
		icon: async () => () => (
			<img src={InlineEmbedIcon} alt={intl.formatMessage(i18n.referenceDatabaseIconAltText)} />
		),
		action: referenceDatabaseQuickInsertModule(spaceKey, intl),
	});

	return {
		title: intl.formatMessage(i18n.manifestTitle),
		type: 'com.atlassian.confluence.macro.core',
		key: 'database-extension',
		icons: {
			'48': async () => () => (
				<img src={InlineCreateIcon} alt={intl.formatMessage(i18n.createDatabaseIconAltText)} />
			),
		},
		modules: {
			quickInsert: allowDatabaseQuickInsert ? quickInsert : [],
			nodes: {
				default: {
					type: 'inlineExtension',
					render: async () => {
						const { cloudId } = await getSessionData();

						return ({ node }) => {
							const contentId = node.parameters?.macroParams?.contentId.value;
							const entryId = node.parameters?.macroParams?.entryId.value;
							const fieldId = node.parameters?.macroParams?.fieldId.value;
							return (
								<DatabaseValueContainer
									contentId={contentId}
									fieldId={fieldId}
									entryId={entryId}
									spaceKey={spaceKey}
									cloudId={cloudId}
									isEdit={isEdit}
								/>
							);
						};
					},
				},
			},
		},
	};
};
