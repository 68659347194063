const { minify, syntax } = require('csso');

const styleMacroBoundryCSSSelectors = [
	{
		name: 'main-content',
		type: 'IdSelector',
	},
	{
		name: 'content',
		type: 'IdSelector',
	},
	{
		name: 'wiki-content',
		type: 'ClassSelector',
	},
];

export const mainContentSelector = '#main-content';

const styleMacroRegex = /(<style[^>]*data-macro-name="style"[^>]*>)((.|\n|\r)*?)(<\/style>)/gm;

function addScopeBoundry(css: string, scope: string) {
	const ast = syntax.parse(css);
	const combinatorObj = { type: 'Combinator', loc: null, name: ' ' };

	syntax.walk(ast, (node) => {
		// Remove CSS imported from URL as thet can't be scoped and styles can leak to entire application
		if (node.type === 'Atrule' && node.name === 'import') {
			node.prelude?.children.forEach((child) => {
				if (child.type === 'Url') {
					child.value = '';
				}
			});
		}
		if (node.type === 'Selector') {
			const isFirstChildPseudoClass = node.children?.first.type === 'PseudoClassSelector';
			const isFirstChildCombinator = node.children?.first.type === 'Combinator';
			const existingBoundry = syntax.find(node, (n) =>
				styleMacroBoundryCSSSelectors.find(
					(selector) => selector.name === n.name && selector.type === n.type,
				),
			);

			if (
				!Boolean(existingBoundry) &&
				!Boolean(isFirstChildPseudoClass) &&
				!Boolean(isFirstChildCombinator)
			) {
				const styleMacroBoundryAST = syntax.parse(scope, {
					context: 'selector',
				});
				const styleMacroBoundryNode = node.children.createItem(styleMacroBoundryAST.children.first);
				const combinatorNode = node.children.createItem(syntax.fromPlainObject(combinatorObj));
				node.children.prepend(combinatorNode);
				node.children.prepend(styleMacroBoundryNode);
			}
		}
	});

	const scopedCSS = syntax.generate(ast);
	let minified = '';
	try {
		minified = minify(scopedCSS).css;
	} finally {
		return minified || scopedCSS;
	}
}

export function extractCSS(html: string) {
	const matches = html.matchAll(styleMacroRegex);
	const matchedCSS: string[] = [];

	for (const match of matches) {
		if (match[2]) {
			matchedCSS.push(match[2]);
		}
	}

	return matchedCSS;
}

export function scopeCSS(
	html: string = '',
	scopeSelector: string = mainContentSelector,
	regex: RegExp = styleMacroRegex,
) {
	const matches = html.matchAll(regex);
	const CSSForAnalytics: { originalCSS: string; scopedCSS: string }[] = [];

	for (const match of matches) {
		if (match[2]) {
			const originalCSS = match[2];
			const scopedCSS = addScopeBoundry(originalCSS, scopeSelector);
			CSSForAnalytics.push({ originalCSS, scopedCSS });
			html = html.replace(match[2], scopedCSS);
		}
	}
	return {
		contentWithScopedCSS: html,
		CSSForAnalytics,
	};
}
