import type { ConfluencePageContext } from '../extensionTypes';

declare global {
	interface Window {
		__EXTENSION_MANIFEST__?: Promise<any>;
		__EXTENSION_MANIFEST_SPACEAWARE__?: Record<string, Promise<any>>;
	}
}

export async function getExtensionManifest(
	{ spaceKey }: Pick<ConfluencePageContext, 'spaceKey'> = {
		spaceKey: undefined,
	},
) {
	if (spaceKey) {
		const extensionManifestSpaceaware = (window.__EXTENSION_MANIFEST_SPACEAWARE__ ||= {});

		if (!extensionManifestSpaceaware[spaceKey]) {
			extensionManifestSpaceaware[spaceKey] = fetchExtensionManifests(spaceKey);
		}
		return extensionManifestSpaceaware[spaceKey];
	}

	if (!window.__EXTENSION_MANIFEST__) {
		window.__EXTENSION_MANIFEST__ = fetchExtensionManifests();
	}
	return window.__EXTENSION_MANIFEST__;
}

function fetchExtensionManifests(spaceKey?: string) {
	// eslint-disable-next-line check-credentials-option/has-credentials-option,no-restricted-syntax
	return fetch(
		`/wiki/plugins/macrobrowser/browse-macros.action?detailed=true&origin=extension-provider${
			spaceKey ? `&spaceKey=${spaceKey}` : ''
		}`,
		{
			method: 'GET',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	).then((response) => response.json());
}
