import { GuardPolicy, StoreType } from './constants';
import { GET_ITEM_COUNT, VISIBILITY_TIMEOUT } from './defaults';
import { CallbackProcessingError, InvalidPolicyError } from './errors';
import MemoryDbEventCountGuard from './MemoryDbEventCountGuard';
import {
	type AddItemResult,
	type AddOptions,
	type BulkAddItemResult,
	type BulkAddOption,
	type GetItemsResult,
	type ItemWrapperType,
	type ProcessFnType,
	type Resilience,
	type ResilienceOptions,
} from './types';
import { convertToItemWrapper, createOptionsWithDefaults } from './util';

export default class MemoryDb<T> implements Resilience<T> {
	private memoryStore: ItemWrapperType<T>[];
	private options: Required<ResilienceOptions>;
	private globalEventLimitGuard: MemoryDbEventCountGuard<T>;
	private namespace: string;

	constructor(namespace: string, options: ResilienceOptions = {}) {
		this.namespace = namespace;
		this.memoryStore = [];
		this.options = createOptionsWithDefaults(options);
		/**
		 * This class will enforce the number of analytics events we can store in our MemoryDb
		 */
		this.globalEventLimitGuard = new MemoryDbEventCountGuard(this.options.maxEventLimit, {
			addItems: this.addItems.bind(this),
			getItemCount: this.getItemCount.bind(this),
			evictEventsIfNeeded: this.evictEventsIfNeeded.bind(this),
		});
	}

	/**
	 * This method is used mainly to write new events to MemoryDB and uses the MEMORY_DB_GUARD_POLICY.EVICT_OLDEST_IF_LIMIT_EXECEEDED policy
	 * to removes oldest events when limit is reached.
	 */
	async addItem(
		item: T,
		options: AddOptions = {},
		policy: GuardPolicy = GuardPolicy.ABANDON,
	): Promise<AddItemResult<T>> {
		if (policy === GuardPolicy.IGNORE) {
			throw new InvalidPolicyError(policy, 'IndexedDbConnector#addItem');
		}
		const storedValue: ItemWrapperType<T> = convertToItemWrapper(item, this.namespace, options);
		// Delegated responsiblity to event limit guard to insert to memory stored
		const bulkAddItemsResult = await this.globalEventLimitGuard.insertItemsToMemoryStore(
			[storedValue],
			policy,
		);
		return Promise.resolve({
			item: bulkAddItemsResult.items[0],
			numberOfEvictedItems: bulkAddItemsResult.numberOfEvictedItems,
		});
	}

	bulkAddItem(
		itemOptions: BulkAddOption<T>[],
		policy: GuardPolicy = GuardPolicy.ABANDON,
	): Promise<BulkAddItemResult<T>> {
		const items: ItemWrapperType<T>[] = itemOptions.map(({ item, ...addOptions }) =>
			convertToItemWrapper(item, this.namespace, addOptions),
		);
		return this.bulkAddItemWrapperType(items, policy);
	}

	/**
	 * This method is used mainly to write events to MemoryDB when unknown errors are thrown from IndexedDB.
	 */
	bulkAddItemWrapperType(
		items: ItemWrapperType<T>[],
		policy: GuardPolicy = GuardPolicy.ABANDON,
	): Promise<BulkAddItemResult<T>> {
		return Promise.resolve(this.globalEventLimitGuard.insertItemsToMemoryStore(items, policy));
	}

	getItems(count: number = GET_ITEM_COUNT): Promise<GetItemsResult<T>> {
		return Promise.resolve(this.synchronousGetItems(count));
	}

	private synchronousGetItems(count: number = GET_ITEM_COUNT): GetItemsResult<T> {
		const fixedCount = count > 0 ? count : GET_ITEM_COUNT;
		const now = Date.now();
		const wrappedItems: ItemWrapperType<T>[] = [];
		const itemsToRemove: ItemWrapperType<T>[] = [];

		for (let wrappedItem of this.memoryStore) {
			if (wrappedItem.timeToBeProcessedAfter <= now) {
				wrappedItems.push({
					...wrappedItem,
				});
				// Mutates the item in the memoryStore array, but not whats inside of wrappedItems
				wrappedItem.timeToBeProcessedAfter += VISIBILITY_TIMEOUT;
				wrappedItem.retryAttempts += 1;

				if (wrappedItem.retryAttempts >= this.options.maxAttempts) {
					itemsToRemove.push(wrappedItem);
				}
			}

			if (wrappedItems.length >= fixedCount) {
				break;
			}
		}

		itemsToRemove.forEach((item) => {
			const index = this.memoryStore.indexOf(item);
			this.memoryStore.splice(index, 1);
		});

		return {
			items: wrappedItems,
			numberOfDeletedItems: itemsToRemove.length,
		};
	}

	deleteItems(itemIds: string[]): Promise<void> {
		this.memoryStore = this.memoryStore.filter((item) => !itemIds.includes(item.id));
		return Promise.resolve(void 0);
	}

	getItemCount(): Promise<number> {
		const now = Date.now();
		const count = this.memoryStore.filter((item) => item.timeToBeProcessedAfter <= now).length;
		return Promise.resolve(count);
	}

	async processItems<R>(processFn: ProcessFnType<T, R>, count?: number): Promise<R> {
		const { items, ...partialGetResult } = this.synchronousGetItems(count);
		const itemIds = items.map((i) => i.id);
		try {
			const result = await processFn(items, partialGetResult);
			await this.deleteItems(itemIds);
			return result;
		} catch (error) {
			throw new CallbackProcessingError(error);
		}
	}

	public storeType(): StoreType {
		return StoreType.MEMORY;
	}

	/**
	 * This function is adding items to the tail of the memoryStore and as it adds new items it keeps the
	 * memory store sorted by timeAdded property. This makes evictions easier and adding elements to
	 * memoryStore much faster.
	 *
	 * @param itemsToAdd
	 */
	private addItems(itemsToAdd: ItemWrapperType<T>[]) {
		this.memoryStore.push(...itemsToAdd);

		// Sorting everytime, intentionally.
		this.memoryStore.sort(function (a, b) {
			return a.timeAdded - b.timeAdded;
		});
	}

	/**
	 * This function checks the number of events currently in AWC MemoryDb and if necessary,
	 * will evict the oldest events in favour of the events we want to add.
	 *
	 * @param countOfEventsToAdd - The number of events we are proposing to add.
	 */
	private evictEventsIfNeeded(eventLimit: number): number {
		const numberOfEventsInDb = this.memoryStore.length;
		// The number of analytics events currently in MemoryDb and
		// the Z events we are proposing to add will exceed our event count limit.
		if (numberOfEventsInDb > eventLimit) {
			const m = numberOfEventsInDb - eventLimit;

			// Removing oldest M events
			this.memoryStore.splice(0, m);
			return m;
		}
		return 0;
	}
}
