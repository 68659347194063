/* eslint react/prop-types:0 */
import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { BetaFeaturesSiteSettingsPageLoader } from '@confluence/beta-features/entry-points/BetaFeaturesSiteSettingsPage';

export const BetaFeaturesRoute = () => <BetaFeaturesSiteSettingsPageLoader />;

BetaFeaturesRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'betaFeaturesScreen' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
