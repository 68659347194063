/**
 * Analytics Debugger
 *
 * A micro debugger for the load testing team designed to safely cache View Page's
 * page segment load metrics on the window.
 */

/**
 * Indicates whether debugging analytics-web-client is enabled
 */
export let debuggerEnabled = false;

/**
 * Enables the analytics debugger
 */
export const enableAnalyticsDebugger = () => {
	debuggerEnabled = true;
};

/**
 * Check the window for the test interface added by the load testing team
 */
const getTestInterface = () => {
	return !!(window as HashTable<any>).testAnalytics;
};

/**
 * Add View Page's expected page segments
 */
export const cacheExpectedViewPageSegments = (segments: any[]) => {
	if (getTestInterface()) {
		(window as HashTable<any>).testAnalytics.expectedPageSegments = segments;
	}
};

/**
 * Clear the cached analytics events between routes
 */
export const clearAnalyticsDebugger = () => {
	if (getTestInterface()) {
		(window as HashTable<any>).testAnalytics.clear();
	}
};

/**
 * Disable the AnalyticsDebugger
 */
export const disableAnalyticsDebugger = () => {
	debuggerEnabled = false;
};

export type HashTable<T> = {
	[key: string]: T;
};
