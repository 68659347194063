import { type CategorizedStorageControlsCache, CategoryStorageType } from '../../../types';
import { Logger } from '../logger';

import {
	COOKIE_CACHE_URL_PROD,
	LEGACY_COOKIE_CACHE_URL_PROD,
	LOCAL_STORAGE_CACHE_URL_PROD,
	SESSION_STORAGE_CACHE_URL_PROD,
} from './constants';

export class CacheLoader {
	private cacheUrl: string;

	/**
	 * Cache variable for storing categorized cookies cache
	 */
	private localResponseCache: CategorizedStorageControlsCache | undefined;

	/**
	 * Cache variable for storing a promise that's been fired by a different invocation of loadStorageControls
	 *
	 * Allows for many invocations to happen simultaneously, and for only one fetch to go out
	 */
	private localResponsePromiseCache: Promise<Response> | undefined;

	/**
	 * Cache variable for storing the promise returned from the response object's json method
	 *
	 * Ensures we don't invoke response.json multiple times
	 */
	private localResponseJsonPromiseCache:
		| Promise<CategorizedStorageControlsCache | undefined>
		| undefined;

	constructor(storageType: CategoryStorageType) {
		switch (storageType) {
			case CategoryStorageType.Cookies:
				this.cacheUrl = COOKIE_CACHE_URL_PROD;
				break;
			case CategoryStorageType.LocalStorage:
				this.cacheUrl = LOCAL_STORAGE_CACHE_URL_PROD;
				break;
			case CategoryStorageType.SessionStorage:
				this.cacheUrl = SESSION_STORAGE_CACHE_URL_PROD;
				break;
			default:
				this.cacheUrl = LEGACY_COOKIE_CACHE_URL_PROD;
		}
	}

	clearStorageControlsDataCache() {
		this.localResponseCache = undefined;
		this.localResponsePromiseCache = undefined;
		this.localResponseJsonPromiseCache = undefined;
	}

	/**
	 * Unpacks the response promise from the categories CDN
	 *
	 * errors if that request was unsuccessful, returns the categories data otherwise
	 *
	 */
	private async handleCategoriesResponse(responsePromise: Promise<Response> | undefined) {
		if (!responsePromise) {
			throw new Error(`Failed to fetch Cookies Cache! Cached promise was undefined`);
		}

		const response = await responsePromise;
		if (!response.ok) {
			throw new Error(`Failed to fetch Cookies Cache! status: ${response.status}`);
		}
		// Checks if we've already fired the response.json() promise
		this.localResponseJsonPromiseCache = this.localResponseJsonPromiseCache || response?.json();

		return this.localResponseJsonPromiseCache;
	}

	async loadStorageControlsData(): Promise<CategorizedStorageControlsCache | undefined> {
		if (this.localResponseCache) {
			return this.localResponseCache;
		} else {
			// Always use Prod CDN for prod/local/dev/staging environments, but stub the route for automation tests
			try {
				if (!this.localResponsePromiseCache) {
					// invoke CDN and cache the promise
					this.localResponsePromiseCache = fetch(this.cacheUrl);
				}

				// unpack response
				this.localResponseCache = await this.handleCategoriesResponse(
					this.localResponsePromiseCache,
				);

				return this.localResponseCache;
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'loadStorageControlsError',
					message: e.message || '',
				});
				this.clearStorageControlsDataCache();
			}
		}
	}
}

export {
	LEGACY_COOKIE_CACHE_URL_PROD,
	COOKIE_CACHE_URL_PROD,
	LOCAL_STORAGE_CACHE_URL_PROD,
	SESSION_STORAGE_CACHE_URL_PROD,
} from './constants';
