import type { ReactElement } from 'react';
import React from 'react';
import { ApolloProvider } from 'react-apollo';

import type { ExtensionParams, ExtensionHandler } from '@atlaskit/editor-common/extensions';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { SessionData, SessionDataContext } from '@confluence/session-data';
import { getApolloClient } from '@confluence/graphql';

// type ExtensionHandler<T> = (ext: ExtensionParams<T>, doc: Object)
export const createMacroExtensionHandler =
	(extensionHandler: ExtensionHandler<any>) =>
	(extension: ExtensionParams<any>, ...args): ReactElement => (
		<ErrorBoundary attribution={Attribution.PAGE_EXTENSIONS}>
			<ApolloProvider client={getApolloClient()}>
				<SessionData>
					{(sessionData) => (
						<SessionDataContext.Provider value={sessionData}>
							{extensionHandler(extension, args)}
						</SessionDataContext.Provider>
					)}
				</SessionData>
			</ApolloProvider>
		</ErrorBoundary>
	);
