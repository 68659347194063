import { type EditorView } from '@atlaskit/editor-prosemirror/view';

// Utils in here are shared with event-hub but also directly in config items, so to avoid
// circular dependency issues, we separate shared utils here.

/**
 * Retrieves the `editorId` from the given `EditorView` instance.
 *
 * @param editorView - The `EditorView` instance from which to extract the `editorId`.
 * @returns The `editorId` string if available; otherwise, `undefined`.
 *
 * @example
 * ```typescript
 * const editorId = findEditorIdFromView(editorViewInstance);
 * if (editorId) {
 *   console.log(`Editor ID is: ${editorId}`);
 * } else {
 *   console.log('Editor ID not found.');
 * }
 * ```
 */
export const findEditorIdFromView = (editorView: EditorView): string | undefined => {
	return editorView.dom?.dataset?.editorId;
};
