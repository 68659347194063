import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import type { MessageDeliveryStatus, MessageEventType, ProductIds } from '../constants';

export type MessageDeliveryStatusKeys = keyof typeof MessageDeliveryStatus;
export type MessageDeliveryStatuses = (typeof MessageDeliveryStatus)[MessageDeliveryStatusKeys];
export type StartMessageDeliveryStatuses = Exclude<MessageDeliveryStatuses, 'stopped'>;
export type StopMessageDeliveryStatuses = Exclude<MessageDeliveryStatuses, 'started' | 'blocked'>;
export type MessageEventTypeKeys = keyof typeof MessageEventType;
export type MessageEventTypes = (typeof MessageEventType)[MessageEventTypeKeys];

export interface IChoreographer {
	getCurrentMessage: () => ChoreographerState['currentMessage'];
	getCurrentPendingMessage: () => ChoreographerState['queueingBehindMessage'];
	startMessage: (
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData?: OperationalEventPayload['attributes'],
		plugin?: IChoreographerPlugin,
	) => Promise<StartMessageDeliveryStatuses>;
	stopMessage: (
		productId: ProductIds,
		messageId: string,
		analyticsClient: AnalyticsWebClient,
		metricsData?: OperationalEventPayload['attributes'],
	) => Promise<StopMessageDeliveryStatuses>;
	on: (
		productId: ProductIds,
		messageId: string,
		options: IMessage,
		finalizers?: Partial<IMessageFinalizers>,
	) => void;
	onStart: (
		productId: ProductIds,
		messageId: string,
		callback: IMessage['start'],
		finalizers?: Partial<IMessageFinalizers>,
	) => void;
	onStop: (
		productId: ProductIds,
		messageId: string,
		callback: IMessage['stop'],
		finalizers?: Partial<IMessageFinalizers>,
	) => void;
	off: (productId: ProductIds, messageId: string) => void;
	registerPlugin<T extends IChoreographerPlugin>(productId: ProductIds, plugin: T): () => void;
}

export interface IChoreographerPluginConfig {}

export interface IChoreographerPlugin {
	destroy: () => void;
	disable: () => void;
	enable: () => void;
	isDisplayingMessage: () => boolean;
	startMessage: (
		messageId: string,
		additionalData: OperationalEventPayload['attributes'],
	) => ReturnType<IChoreographer['startMessage']>;
	stopMessage: (
		messageId: string,
		additionalData: OperationalEventPayload['attributes'],
	) => ReturnType<IChoreographer['stopMessage']>;
	on: (messageId: string, options: IMessage) => ReturnType<IChoreographer['on']>;
	onStart: (
		messageId: string,
		callback: IMessage['start'],
	) => ReturnType<IChoreographer['onStart']>;
	onStop: (messageId: string, callback: IMessage['stop']) => ReturnType<IChoreographer['onStop']>;
	off: (messageId: string) => ReturnType<IChoreographer['off']>;
}

export type IMessage = {
	[key in MessageEventTypes]?: () => Promise<boolean | void> | boolean | void;
};

type IMessageFinalizerKeys = `on${Capitalize<keyof IMessage>}`;
export type IMessageFinalizers = {
	[key in IMessageFinalizerKeys]?: () => void;
};

export type QueuedMessage = {
	productId: ProductIds;
	messageId: string;
	analyticsClient: AnalyticsWebClient;
	metricsData: OperationalEventPayload['attributes'];
	plugin?: IChoreographerPlugin;
	resolve: (value: StartMessageDeliveryStatuses | Promise<StartMessageDeliveryStatuses>) => void;
};

/**
 * Map of IChoreographerPlugin instances, keyed by unique product identifier.
 */
export type IChoreographerPluginMap = Map<ProductIds, IChoreographerPlugin>;

/**
 * Map of IMessage objects, keyed by unique identifiers, such as message template ID or 1P/3P message ID, so long as it's unique.
 */
export type IChoreographerMessages = Map<string, IMessage & IMessageFinalizers>;

/**
 * Map of productId keys to IChoreographerMessages maps, segmented from each other to prevent collisions between products.
 */
export type IChoreographerProductMessageMap = Map<ProductIds, IChoreographerMessages>;

export enum envType {
	LOCAL = 'local',
	DEV = 'dev',
	STAGING = 'staging',
	PROD = 'prod',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Attributes = Record<string, any>;

export type EventOverrides = {
	anonymousId?: string;
};

export type OperationalEventPayload = EventOverrides & {
	action: string;
	actionSubject: string;
	actionSubjectId?: string;
	attributes?: Attributes;
	tags?: string[];
	source: string;
	objectType?: string;
	objectId?: string;
	containerType?: string;
	containerId?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	containers?: any;
};

export type ChoreographerState = {
	currentMessage: {
		productId: ProductIds;
		messageId: string;
		metricsData: OperationalEventPayload['attributes'];
		analyticsClient: AnalyticsWebClient;
		timestamp: number;
	} | null;
	isDisabled: boolean;
	messages: IChoreographerProductMessageMap;
	messageQueue: QueuedMessage[];
	messageTtl: ReturnType<typeof setTimeout> | null;
	plugins: IChoreographerPluginMap;
	queueingBehindMessage: { productId: ProductIds; messageId: string; timestamp: number } | null;
	shouldQueueMessages: boolean;
};
