export enum COLLAB_EDIT_PROVIDER_MEASURES {
	COLLAB_EDIT_PROVIDER_FETCH = 'collabEditProviderAnalyticsFetch',
	COLLAB_EDIT_PROVIDER_CREATED = 'collabEditProviderCreated',
	COLLAB_EDIT_PROVIDER_DISABLED = 'collabEditProviderDisabled',
	COLLAB_EDIT_PROVIDER_INIT = 'collabEditProviderInitialized',
	COLLAB_EDIT_PROVIDER_PRE_CONNECTED = 'collabEditProviderPreconnected',
	COLLAB_EDIT_PROVIDER_CONNECTED = 'collabEditProviderConnected',
	COLLAB_EDIT_PROVIDER_INIT_DOC = 'collabEditProviderInitDoc',
	COLLAB_EDIT_PROVIDER_FETCH_NCS_DRAFT = 'collabEditProviderFetchNCSDraft',
	COLLAB_EDIT_PROVIDER_PRELOAD_NCS_DRAFT = 'collabEditProviderPreloadNCSDraft',
	COLLAB_EDIT_PROVIDER_PRELOAD_NCS_DRAFT_TIMEOUT = 'collabEditProviderPreloadNCSDraftTimeout',
	COLLAB_PERMS_TOKEN_FETCH = 'collabEditProviderPermsTokenFetch',
}
