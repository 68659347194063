import { LoadableBackground } from '@confluence/loadable';

export const PageShownPublicLinksEventLoader = LoadableBackground({
	loader: async () => {
		const { PageShownPublicLinksEvent } = await import(
			/* webpackChunkName: "loadable-srcPageShownPublicLinksEvent" */ '../src/PageShownPublicLinksEvent'
		);
		return PageShownPublicLinksEvent;
	},
});
