import type { CacheResolverMap } from 'apollo-cache-inmemory';
export const cacheRedirects: CacheResolverMap = {
	Query: {
		singleContent(_, { id }, { getCacheKey }) {
			return getCacheKey({
				__typename: 'Content',
				id,
			});
		},
	},
};
