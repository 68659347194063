import { HomeDraftsQuery } from './HomeDraftsQuery.graphql';

const PAGE_SIZE = 24;

export const getDraftsQueryOptions = ({
	isSpaceAliasFFEnabled,
}: {
	isSpaceAliasFFEnabled: boolean;
}) => ({
	query: HomeDraftsQuery,
	variables: {
		cqlcontext: `{"contentStatuses":["draft"]}`,
		first: PAGE_SIZE,
		includeAlias: isSpaceAliasFFEnabled,
	},
});
