import type { MacroAttributes } from '@atlaskit/editor-common/provider-factory';

import type { MacroConfig, MacroMetadata } from '../extensions-common/types';
import { createMetadataLoader } from '../extensions-common/macro-loaders';

import { isAllowedWithFeatureFlags } from './blocklist';
import { autoConvert } from './converters/auto-convert';

const loadMacroMetadata = createMetadataLoader();

export function getAutoConverter(
	browserConfig: MacroConfig,
): Promise<(link: string) => MacroAttributes | null> {
	return autoConvert(getMacroMetaData, browserConfig);
}

export const getMacroMetaData = async (): Promise<MacroMetadata[]> => {
	const macros = await loadMacroMetadata();
	const isAllowed = isAllowedWithFeatureFlags();

	return macros.filter((macroMetadata) => {
		if (macroMetadata.hidden) {
			return false;
		}

		return isAllowed(macroMetadata);
	});
};
