import { useContext, useEffect } from 'react';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { globalRequire, requireLegacyWRM, waitForRequiredModule } from '@confluence/wrm';

let LOADED = false;

// This forcefully calls the `loadConfluencePreviews` function from the `confluence-previews`
// plugin to get the previewer to immediately load if the URL contains the `preview` query
// param. Although the plugin contains logic to automatically load the previewer based
// the query params, the code path to do so is dependent on the app _not_ being an SPA.
// Relevant ticket: https://product-fabric.atlassian.net/browse/CEMS-2289
export const useConfluencePreviewsForceLoad = (contentId: string | undefined) => {
	const { getQueryParams } = useContext(RoutesContext);

	useEffect(() => {
		if (!contentId) {
			return;
		}

		const params = getQueryParams();
		// Only run if the `preview` query param is present.
		const previewParams = params['preview'];
		if (previewParams === undefined) {
			return;
		}

		requireLegacyWRM(
			['wr!com.atlassian.confluence.plugins.confluence-previews:confluence-previews-jquery'],
			() => {
				if (LOADED) {
					// Refers to `CONFCLOUD/repos/confluence/browse/confluence-plugins/confluence-bundled-plugins/confluence-previews/confluence-previews/src/main/resources/js/confluence/file-preview-loader.js`
					const confluencePreviewsFilePreviewLoader = globalRequire(
						'cp/confluence/file-preview-loader',
					);
					confluencePreviewsFilePreviewLoader?.resetPreviewFileList();
					let viewMode = 'full';
					// Check if the URL appears to point to a comment specifically
					if ((previewParams as string)?.split('/')?.length === 6) {
						viewMode = 'comment';
					}
					confluencePreviewsFilePreviewLoader?.setupPreviewerForSPA(
						undefined,
						undefined,
						viewMode,
						viewMode === 'comment',
					);
				} else {
					// Refers to `CONFCLOUD/repos/confluence/browse/confluence-plugins/confluence-bundled-plugins/confluence-previews/confluence-previews/src/main/resources/js/confluence/preview.js`
					waitForRequiredModule(
						{
							listener: 'useConfluencePreviewsForceLoad',
							module: 'cp/confluence/preview',
						},
						() => {
							const confluencePreviewsModule = globalRequire('cp/confluence/preview');
							// NB: Second call is intentional.
							confluencePreviewsModule?.loadConfluencePreviews();
							confluencePreviewsModule?.loadConfluencePreviews();
							LOADED = true;
						},
					);
				}
			},
			() => {
				/* Unavailable callback */
			},
		);
	}, [getQueryParams, contentId]);

	return null;
};
