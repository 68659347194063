import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import {
	EDITOR_CREATE_PAGE,
	EDITOR_LOAD_DRAFT,
	EDITOR_LOAD_DRAFTMETADATA,
} from '../actions/legacy-editor-action-types';

const OK = 200;

// This is based on viewpage's defaultState
const defaultState = fromJS({
	draftId: null,
	isRequesting: false,
	hasFetched: false,
	result: null,
	errorData: null,
	statusCode: null,
	silent: null,
});

const copyFromOriginalResponse = (response, keys) => {
	if (!response || !response._originalResponse) {
		return {};
	}
	return Object.keys(response._originalResponse)
		.filter((key) => keys.indexOf(key) > -1)
		.reduce((filteredKeys, key) => {
			filteredKeys[key] = response._originalResponse[key];
			return filteredKeys;
		}, {});
};

export default function content(state = defaultState, action) {
	switch (action.type) {
		// Creating page.

		case `${EDITOR_CREATE_PAGE}_REQUEST`:
			return state.merge({
				draftId: null,
				isRequesting: true,
				hasFetched: false,
				result: null,
				errorData: null,
				statusCode: null,
				silent: !!action.silent,
			});

		case `${EDITOR_CREATE_PAGE}_SUCCESS`:
			return state.merge({
				draftId: action.response.id,
				isRequesting: false,
				hasFetched: true,
				result: {
					title: '', // Prevent undefined title in new page
					editorContent: '', // Not having this attribute causes failure in collaborative-helper.js on 'rte-loaded' event and collaborative editing is never initialised propertly.
					...action.response,
				},
				statusCode: OK,
				errorData: null,
			});

		case `${EDITOR_CREATE_PAGE}_FAILURE`:
			if (state.get('draftId') !== action.options.contentId) {
				return state;
			}

			return state.merge({
				isRequesting: false,
				hasFetched: true,
				statusCode: action.error && action.error.statusCode,
				errorData: action.message,
			});

		// Loading existing draft.

		case `${EDITOR_LOAD_DRAFT}_REQUEST`:
			return state.merge({
				draftId: action.contentId,
				isRequesting: true,
				hasFetched: false,
				result: null,
				errorData: null,
				statusCode: null,
				silent: !!action.silent,
			});

		case `${EDITOR_LOAD_DRAFT}_SUCCESS`:
			return state.mergeDeep({
				draftId: action.response.id.toString(),
				isRequesting: false,
				hasFetched: true,
				result: {
					// editorContent - Not having this attribute causes failure in collaborative-helper.js on 'rte-loaded' event and collaborative editing is never initialised propertly.
					// syncRev - needed by collab editing plugin when loading draft
					...copyFromOriginalResponse(action.response, [
						'ancestors',
						'confRev',
						'editorContent',
						'spaceKey',
						'syncRev',
						'syncRevSource',
						'type',
						'publishedId',
					]),
					...action.response,
					title: action.response && action.response.title ? action.response.title : '', // assure not null
				},
				statusCode: OK,
				errorData: null,
			});

		case `${EDITOR_LOAD_DRAFT}_FAILURE`:
			if (state.get('draftId') !== action.options.contentId) {
				return state;
			}

			return state.merge({
				isRequesting: false,
				hasFetched: true,
				statusCode: action.error && action.error.statusCode,
				errorData: action.message,
			});

		// Loading metadata for existing draft (ancestors, spaceKey etc).

		case `${EDITOR_LOAD_DRAFTMETADATA}_REQUEST`:
			return state.merge({});

		case `${EDITOR_LOAD_DRAFTMETADATA}_SUCCESS`:
			return state.mergeDeep({
				result: {
					...action.response,
				},
			});

		case `${EDITOR_LOAD_DRAFTMETADATA}_FAILURE`:
			if (state.get('draftId') !== action.options.contentId) {
				return state;
			}

			return state.merge({
				hasFetched: true,
				statusCode: action.error && action.error.statusCode,
				errorData: action.message,
			});

		default:
			return state;
	}
}

/*
 * Selectors
 */
const getEditorContentState = (state) => state.editor;

export const createEditorContentSelector = () =>
	createSelector(getEditorContentState, (contentState) => {
		const isRequesting = contentState.get('isRequesting', false);
		const hasFetched = contentState.get('hasFetched', false);
		const draftId = contentState.get('draftId');
		const silent = contentState.get('silent', false);
		const statusCode = contentState.get('statusCode');
		const errorData = contentState.get('errorData', null);
		const result = contentState.get('result', null);

		return {
			draftId,
			isRequesting,
			hasFetched,
			result: result && typeof result.toJS === 'function' ? result.toJS() : result,
			statusCode,
			errorData: errorData && typeof errorData.toJS === 'function' ? errorData.toJS() : errorData,
			// calculated data
			shouldNavigateToEditPage: !silent && hasFetched && statusCode === OK,
		};
	});
