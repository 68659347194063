import gql from 'graphql-tag';

export const DisplayConditionsContentPropertiesQuery = gql`
	query DisplayConditionsContentPropertiesQuery($contentId: ID!, $propertyKeys: [String]!) {
		content(id: $contentId) {
			nodes {
				id
				properties(keys: $propertyKeys) {
					nodes {
						id
						key
						value
					}
				}
			}
		}
	}
`;
