import {
	ADMIN_TEMPLATES_AND_BLUEPRINTS_PAGE_TEMPLATES_LOAD_EXPERIENCE,
	ADMIN_TEMPLATES_AND_BLUEPRINTS_PAGE_BLUEPRINTS_LOAD_EXPERIENCE,
	ADMIN_TEMPLATES_AND_BLUEPRINTS_SPACE_BLUEPRINTS_LOAD_EXPERIENCE,
	ADMIN_TEMPLATES_AND_BLUEPRINTS_SYSTEM_TEMPLATES_LOAD_EXPERIENCE,
} from '@confluence/experience-tracker';

export const ADMIN_MIGRATION_TEMPLATES_AND_BLUEPRINTS_ANALYTICS_SOURCE =
	'adminTemplatesAndBlueprints';
export const PATH_NAME = '/admin/templates-and-blueprints';

export const ADMIN_TEMPLATES_AND_BLUEPRINTS_SUB_EXPERIENCES = [
	ADMIN_TEMPLATES_AND_BLUEPRINTS_PAGE_TEMPLATES_LOAD_EXPERIENCE,
	ADMIN_TEMPLATES_AND_BLUEPRINTS_PAGE_BLUEPRINTS_LOAD_EXPERIENCE,
	ADMIN_TEMPLATES_AND_BLUEPRINTS_SPACE_BLUEPRINTS_LOAD_EXPERIENCE,
	ADMIN_TEMPLATES_AND_BLUEPRINTS_SYSTEM_TEMPLATES_LOAD_EXPERIENCE,
];
