import React from 'react';

import { ADMIN_THEMES_ANALYTICS_SOURCE, LazyThemesPage } from '@confluence/site-settings';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const AdminThemesRoute = () => <LazyThemesPage />;

AdminThemesRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_THEMES_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
