import React, { type FC } from 'react';

import { InlineCommentsProvider, CommentContentProvider } from '@confluence/comment-context';

export const CommentContextWrapper: FC<{}> = ({ children }) => {
	return (
		<InlineCommentsProvider>
			<CommentContentProvider>{children}</CommentContentProvider>
		</InlineCommentsProvider>
	);
};
