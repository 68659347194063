import { Decoration } from '@atlaskit/editor-prosemirror/view';

import { getSelectedDiffObject } from '../utils';

import {
	type DecorationTransformerParams,
	filterDecorationByKey,
	SpellingAndGrammarDecorations,
} from './index';

export const removeSelectedSpellingDecoration = ({
	decorationSet,
	pluginState,
}: DecorationTransformerParams) => {
	return decorationSet.remove(
		filterDecorationByKey(SpellingAndGrammarDecorations.SPELLING_ERROR_SELECTED, decorationSet),
	);
};

export const addSelectedSpellingDecoration = ({
	decorationSet,
	tr,
	pluginState,
}: DecorationTransformerParams) => {
	const { selection } = tr;

	if (selection.from === selection.to) {
		const selectedDiff = getSelectedDiffObject(pluginState, selection.to);

		if (selectedDiff) {
			const { from, to } = selectedDiff;

			return decorationSet.add(tr.doc, [
				Decoration.inline(
					from,
					to,
					{
						class: `ai-suggestions-selected`,
					},
					{
						key: SpellingAndGrammarDecorations.SPELLING_ERROR_SELECTED,
					},
				),
			]);
		}
	}

	return decorationSet;
};
