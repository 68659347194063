import gql from 'graphql-tag';

export const GetUserStateQuery = gql`
	query GetUserState {
		isSiteAdmin
		tenantContext {
			licensedProducts {
				licenseStatus
				productKey
			}
		}
	}
`;
