// codeBidi props are not allowed to prevent malicious developers from misusing them.

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::f983b3736b616a87bb54a8bd2192d46a>>
 * @codegenId #code-block
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen code-block
 * @codegenParams { "props": { "text": true, "testId": true, "showLineNumbers": true, "language": true, "highlight": true, "highlightedStartText": true, "highlightedEndText": true, "shouldWrapLongLines": true } }
 * @codegenDependency ../../../../../../../design-system/code/src/internal/types.tsx <<SignedSource::a59e8453c5363aabb2be3e4db41cfa32>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/hooks/use-highlight.tsx <<SignedSource::8543ff616b2aecb33de153abb2b7745a>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/theme/styles.tsx <<SignedSource::b10f8e616bfe48ca7b5b801b552ab1f0>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/utils/get-normalized-language.tsx <<SignedSource::5fb5e2d7dc2b3a6a5c788dadfa14a2a2>>
 * @codegenDependency ../../../../../../../design-system/code/src/syntax-highlighter/index.tsx <<SignedSource::51c8198de28f6833c3690cdb208ed852>>
 * @codegenDependency ../../../../../../../design-system/code/src/code-block.tsx <<SignedSource::197e6f3576dd29dd0db07e8c3aab1970>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { CodeBlock as PlatformCodeBlock } from '@atlaskit/code';

type PlatformCodeBlockProps = React.ComponentProps<typeof PlatformCodeBlock>;

export type CodeBlockProps = Pick<
  PlatformCodeBlockProps,
  'text' | 'testId' | 'showLineNumbers' | 'language' | 'highlight' | 'highlightedStartText' | 'highlightedEndText' | 'shouldWrapLongLines'
>;

export const CodeBlock = (props: Parameters<RenderFn>[0]) => {
  const {
    text,
    testId,
    showLineNumbers,
    language,
    highlight,
    highlightedStartText,
    highlightedEndText,
    shouldWrapLongLines,
  } = props.forgeDoc.props as CodeBlockProps;
  return (
    <PlatformCodeBlock
      text={text}
      testId={testId}
      showLineNumbers={showLineNumbers}
      language={language}
      highlight={highlight}
      highlightedStartText={highlightedStartText}
      highlightedEndText={highlightedEndText}
      shouldWrapLongLines={shouldWrapLongLines}
    />
  );
};
/**
 * @codegenEnd
 */
