/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::4dec8db7b3d92476b99f5c2d47052df8>>
 * @codegenId #modal-transition
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-transition
 * @codegenParams { "props": { "children": true } }
 * @codegenDependency ../../../../../../../design-system/motion/src/entering/exiting-persistence.tsx <<SignedSource::bf4b721c11945167238211e4957186c2>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-transition.tsx <<SignedSource::467ffb8cf5e27391fd505b84334778d0>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalTransition as PlatformModalTransition } from '@atlaskit/modal-dialog';

type PlatformModalTransitionProps = React.ComponentProps<typeof PlatformModalTransition>;

export type ModalTransitionProps = Pick<
  PlatformModalTransitionProps,
  'children'
>;

export const ModalTransition = (props: Parameters<RenderFn>[0]) => {
  const {
  } = props.forgeDoc.props as ModalTransitionProps;
  return (
    <PlatformModalTransition
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
    />
  );
};
/**
 * @codegenEnd
 */
