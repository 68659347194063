import gql from 'graphql-tag';

export const ExperimentalThemeSpaceHomeOverrideQuery = gql`
	query ExperimentalThemeSpaceHomeOverrideQuery(
		$themeAddonKey: String
		$themeModuleKey: String
		$spaceKey: String!
		$spaceHomePageId: String!
	) @experimental {
		experimentalThemeSpaceHomeOverride(
			themeAddonKey: $themeAddonKey
			themeModuleKey: $themeModuleKey
			spaceKey: $spaceKey
			spaceHomePageId: $spaceHomePageId
		) {
			hasSpaceHomeOverride
			spaceHomeOverrideDescriptor {
				addon_key
				apiMigrations {
					gdpr
				}
				contentClassifier
				contextJwt
				cp
				general
				h
				hostOrigin
				key
				moduleType
				origin
				productCtx
				sandbox
				structuredContext
				timeZone
				uniqueKey
				url
				w
			}
		}
	}
`;
