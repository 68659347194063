import { LoadablePaint } from '@confluence/loadable';

export const PagePropertiesReportRenderer = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PagePropertiesReportRenderer" */ './PagePropertiesReportRenderer'
			)
		).PagePropertiesReportRenderer,
});
