import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type {
	PlacementCallbackParams,
	PlacementMountCallback,
	PlacementUnmountCallback,
	PostOfficeScreenContextProps,
	PostOfficeScreenUpdateContextProps,
	ScreenPlacementContext,
} from './types';
import { uniqueArray } from './util/unique-array';

const initialPlacementState: ScreenPlacementContext = {
	currentlyMounted: [],
	hasMounted: [],
};

const PostOfficeScreenContext = createContext<PostOfficeScreenContextProps>({
	placements: initialPlacementState,
});

/**
 * This hook provides access to the PostOfficeScreen context, which provides information about
 * the state of the screen under the PostOfficeProvider. This can be used for coordination between placements.
 *
 * @returns information about the screen state
 * @property {object} placements - Information about placements on screen
 * @property {Array<string>} placements.currentlyMounted - An array of placementIds for placements that are currently mounted.
 * @property {Array<string>} placements.hasMounted - An array of placementIds for placements that have been mounted at least once.
 *
 **/
export const usePostOfficeScreen = () => useContext(PostOfficeScreenContext);

const PostOfficeScreenUpdateContext = createContext<PostOfficeScreenUpdateContextProps>({
	placementMount: () => {},
	placementUnmount: () => {},
});

export const usePostOfficeScreenUpdateContext = () => useContext(PostOfficeScreenUpdateContext);

export const PostOfficeScreenContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const [placements, setPlacements] = useState<ScreenPlacementContext>(initialPlacementState);

	const placementMount: PlacementMountCallback = useCallback(
		({ placementId }) =>
			setPlacements((state) =>
				state.currentlyMounted.includes(placementId)
					? state
					: {
							...state,
							currentlyMounted: uniqueArray([...state.currentlyMounted, placementId]),
							hasMounted: uniqueArray([...state.hasMounted, placementId]),
						},
			),
		[],
	);

	const placementUnmount: PlacementUnmountCallback = useCallback(
		({ placementId }) =>
			setPlacements((state) =>
				!state.currentlyMounted.includes(placementId)
					? state
					: {
							...state,
							currentlyMounted: state.currentlyMounted.filter((e) => e !== placementId),
						},
			),
		[],
	);

	const postOfficeScreenUpdateContext: PostOfficeScreenUpdateContextProps = useMemo(
		() => ({
			placementMount,
			placementUnmount,
		}),
		[placementMount, placementUnmount],
	);

	const postOfficeScreenContext: PostOfficeScreenContextProps = useMemo(
		() => ({ placements }),
		[placements],
	);

	return (
		<PostOfficeScreenUpdateContext.Provider value={postOfficeScreenUpdateContext}>
			<PostOfficeScreenContext.Provider value={postOfficeScreenContext}>
				{children}
			</PostOfficeScreenContext.Provider>
		</PostOfficeScreenUpdateContext.Provider>
	);
};

export const usePostOfficeScreenPlacement = ({ placementId }: Partial<PlacementCallbackParams>) => {
	const { placementMount, placementUnmount } = usePostOfficeScreenUpdateContext();

	useEffect(() => {
		if (!placementId) {
			return;
		}

		placementMount({ placementId });

		return () => {
			placementUnmount({ placementId });
		};
	}, [placementId, placementMount, placementUnmount]);
};
