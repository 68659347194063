import { query } from '@confluence/query-preloader-tools';

import { EDITOR_ONBOARDING_STATE_KEYS } from '../../constants/onboarding-state/onboarding-editor-constants';

import { getOnboardingStateGQL } from './OnboardingStatus.graphql';

export const preloadEditorOnboardingState = () => {
	return query({
		query: getOnboardingStateGQL,
		variables: {
			key: Object.values(EDITOR_ONBOARDING_STATE_KEYS),
		},
	});
};
