import type { RenderFn, ForgeDoc } from '@atlassian/forge-ui-types';

type ForgeComponentProps = Parameters<RenderFn>[0];

type ForgeDocPropValue =
	| NonNullable<ForgeDoc['props']>[keyof NonNullable<ForgeDoc['props']>]
	| undefined
	| null;

/**
 * this function assume the inputs are not same object, and hence
 * it doesn't check for reference equality.
 *
 * Additionally, it check keys of the object and then check the values
 * for better performance.
 */
const objectValuesAreEqual = <T = unknown>(
	prev: Record<string, T>,
	next: Record<string, T>,
	valuesAreEqual: (a: T, b: T) => boolean,
): boolean => {
	const keys = Object.keys(prev);
	const length = keys.length;
	if (length !== Object.keys(next).length) {
		return false;
	}
	// there are two small optimization techniques used in this file:
	// 1. using decrementing for loop instead of incrementing for loop,
	//    to reduce 1 instruction per iteration.
	// 2. for object equality check, we check the key match first and then
	//    the value match. This is to identify the inequality as soon as possible.
	for (let i = length; i-- !== 0; ) {
		if (!(keys[i] in next)) {
			return false;
		}
	}
	for (let i = length; i-- !== 0; ) {
		const key = keys[i];
		if (!valuesAreEqual(prev[key], next[key])) {
			return false;
		}
	}
	return true;
};

/**
 * Some of the ideas are based on https://github.com/FormidableLabs/react-fast-compare
 */
const forgeDocPropValuesAreEqual = (prev: ForgeDocPropValue, next: ForgeDocPropValue): boolean => {
	if (prev === next) {
		return true;
	} else if (!prev || !next) {
		return false;
	} else if (typeof prev === 'function' && typeof next === 'function') {
		// if the function is a crossDomainFunctionWrapper (i.e. post-bot),
		// then we can check if the function is the same by looking at the
		// __id__ property.
		return prev.__id__ === next.__id__;
	} else if (typeof prev === 'object' && typeof next === 'object') {
		if (prev.constructor !== next.constructor) {
			return false;
		} else if (Array.isArray(prev)) {
			const length = prev.length;
			if (length !== next.length) {
				return false;
			}
			for (let i = length; i-- !== 0; ) {
				if (!forgeDocPropValuesAreEqual(prev[i], next[i])) {
					return false;
				}
			}
			return true;
		} else if (
			prev.valueOf !== Object.prototype.valueOf &&
			typeof prev.valueOf === 'function' &&
			typeof next.valueOf === 'function'
		) {
			return prev.valueOf() === next.valueOf();
		} else if (
			prev.toString !== Object.prototype.toString &&
			typeof prev.toString === 'function' &&
			typeof next.toString === 'function'
		) {
			return prev.toString() === next.toString();
		}

		return objectValuesAreEqual(prev, next, forgeDocPropValuesAreEqual);
	}
	return false;
};

const forgeDocPropsAreEqual = (prev: ForgeDoc['props'], next: ForgeDoc['props']) => {
	if (prev === next) {
		return true;
	} else if (!prev || !next) {
		return false;
	}

	return objectValuesAreEqual(prev, next, forgeDocPropValuesAreEqual);
};

/**
 * the function is used to cache the hasChanged value in the ForgeDoc.
 * Please note, the cached result need to be stored in the next prop not the prev prop.
 * This is because react internally caches the props, and the cache become stale
 * at every render, if the calculation depends on the prev prop, the stale cache
 * will impact the accuracy of the result.
 */
const cacheForgeDocHasChanged = (nextDoc: ForgeDoc, hasChanged: boolean) => {
	nextDoc.hasChanged = hasChanged;
	return !hasChanged;
};

const forgeDocsAreEqual = (prev: ForgeDoc, next: ForgeDoc) => {
	if (next.hasChanged !== undefined) {
		return !next.hasChanged;
	} else if (prev === next) {
		return cacheForgeDocHasChanged(next, false);
	} else if (!prev || !next) {
		return next ? cacheForgeDocHasChanged(next, true) : false;
	}

	if (prev.type !== next.type || prev.key !== next.key) {
		return cacheForgeDocHasChanged(next, true);
	} else if (prev.children !== next.children) {
		if (prev.children?.length !== next.children?.length) {
			return cacheForgeDocHasChanged(next, true);
		}
		const length = prev.children?.length ?? 0;
		for (let i = length; i-- !== 0; ) {
			if (!forgeDocsAreEqual(prev.children[i], next.children[i])) {
				return cacheForgeDocHasChanged(next, true);
			}
		}
	}
	return cacheForgeDocHasChanged(next, !forgeDocPropsAreEqual(prev.props, next.props));
};

const forgePropsAreEqual = (prev: ForgeComponentProps, next: ForgeComponentProps) =>
	forgeDocsAreEqual(prev.forgeDoc, next.forgeDoc);

export { forgePropsAreEqual };
