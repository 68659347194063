import { useMemo } from 'react';

import { useSessionData } from '@confluence/session-data';

import type {
	SmartCardProviderFeatureFlags,
	SmartCardProviderLocation,
} from '../utils/getSmartCardProviderFeatureFlags';
import { getSmartCardProviderFeatureFlags } from '../utils/getSmartCardProviderFeatureFlags';

/**
 * React hook to obtain all SmartCardProvider feature flags.
 * @see getSmartCardProviderFeatureFlags
 */
export const useSmartCardProviderFeatureFlags = (
	location?: SmartCardProviderLocation,
): SmartCardProviderFeatureFlags => {
	const { featureFlagClient, featureFlags } = useSessionData();

	return useMemo(
		() => getSmartCardProviderFeatureFlags(featureFlagClient, featureFlags, location),
		[featureFlagClient, featureFlags, location],
	);
};
