import React from 'react';

import type { RouteMatch } from '@confluence/route';

import { navdexEnd } from '../naxdev';
import { LazyRouteToRightEditor } from '../lazyComponents';

export const EditPageRoute = ({ params: { contentId, spaceKey } }: RouteMatch) => (
	<LazyRouteToRightEditor contentType="pages" contentId={contentId} spaceKey={spaceKey} />
);

EditPageRoute.NAVIGATION_PARAMS = ({ params: { contentId, spaceKey } }: RouteMatch) => ({
	Screen: {
		showPremiumOnboarding: false,
		screenEvent: {
			name: 'editPageScreen',
			id: contentId,
			...navdexEnd,
		},
		pageState: { contentId, spaceKey },
	},
	MainLayout: false,
});
