import React from 'react';

import { LoadablePaint } from '@confluence/loadable';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { COMPANY_HUB_ANALYTICS } from '@confluence/named-routes';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import {
	CompanyHubSettingsPage,
	CompanyHubSettingsPageTabsType,
} from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { fg } from '@confluence/feature-gating';

const CompanyHubAnalytics = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHubAnalytics" */ '@confluence/company-hub/entry-points/CompanyHubAnalyticsPage'
			)
		).CompanyHubAnalyticsPage,
});

// TODO: COMPHUB-2196 This file needs an test suite
export const CompanyHubAnalyticsRoute = () => {
	const spaceKey = getCompanyHubSpaceKey() || '';
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate();

	if (shouldShowPremiumUpsell) {
		return <CompanyHomeBuilderUpsellPage featureName="companyHub" />;
	} else {
		const isHubSettingsEnabled = fg('company_hub_consolidated_settings');
		if (isHubSettingsEnabled) {
			return <CompanyHubSettingsPage tab={CompanyHubSettingsPageTabsType.ANALYTICS} />;
		}
		return <CompanyHubAnalytics spaceKey={spaceKey} />;
	}
};

CompanyHubAnalyticsRoute.NAVIGATION_PARAMS = () => {
	const spaceKey = getCompanyHubSpaceKey() || '';
	return {
		Screen: {
			screenEvent: {
				name: 'companyHubAnalytics',
			},
			pageState: {
				routeName: COMPANY_HUB_ANALYTICS.name,
				spaceKey,
			},
		},
		MainLayout: {
			navView: PRODUCT_HOME_ID,
		},
	};
};
