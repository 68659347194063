/**
 * Bucket Queue
 *
 * A priority queue supporting N number of buckets
 * that represent the order in which items will be
 * dequeued. The lower the bucket-index, the higher
 * priority its items
 */
export class BucketQueue<T> {
	private buckets: T[][];
	public size: number;
	constructor(max: number) {
		this.size = 0;
		this.buckets = Array(max + 1)
			.fill(null)
			.map(() => []);
	}

	/**
	 * Add
	 *
	 * Adds an item to the queue into the bucket specified. The
	 * lower the number, the sooner your item will be dequeued
	 */
	add(t: T, bucket: number) {
		if (bucket >= this.buckets.length || bucket < 0) {
			throw new Error('Range error: The bucket specified does not exist');
		}
		this.buckets[bucket].push(t);
		this.size++;
	}

	/**
	 * Peek
	 *
	 * Returns the highest priority item in the queue
	 */
	peek(): T | null {
		for (const bucket of this.buckets) {
			if (bucket.length) {
				return bucket[0];
			}
		}
		return null;
	}

	/**
	 * Poll
	 *
	 * Returns the highest priority item in the queue by
	 * dequeuing it
	 */
	poll(): T | null {
		for (const bucket of this.buckets) {
			if (bucket.length) {
				this.size--;
				return bucket.shift()!;
			}
		}
		return null;
	}
}
