import { LoadableBackground } from '@confluence/loadable';

export const PremiumOnboarding = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PremiumOnboarding" */ './PremiumOnboarding'))
			.PremiumOnboarding,
});

export const PremiumAdminOnboarding = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PremiumAdminOnboarding" */ './PremiumAdminOnboarding'
			)
		).PremiumAdminOnboarding,
});

export const PremiumTrialOnboardingModal = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PremiumOnboardingModal" */ './PremiumTrialOnboardingModal'
			)
		).PremiumTrialOnboardingModal,
});

export const PremiumTrialOnboardingSpotlight = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PremiumTrialOnboardingSpotlight" */ './PremiumTrialOnboardingSpotlight'
			)
		).PremiumTrialOnboardingSpotlight,
});
