import { parse } from 'url';

// eslint-disable-next-line check-cross-package-imports/dont-import-redux-in-next
import { combineReducers } from 'redux';
import { List, fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { stripContentTitlesFromURL } from '@confluence-classic/confluence-urls';

import errors from '../components/ReduxError/src/reducer';

const MAXIMUM_ITEM_SAVE_IN_HISTORY = 10;

const rootState = {
	session: {
		history: List([]),

		analytics: fromJS({
			currentSection: '',
			previousSection: '',
			searchSessionId: '',
		}),
	},
};

export function history(state = rootState.session.history, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return state.push(action.payload.location.pathname).takeLast(MAXIMUM_ITEM_SAVE_IN_HISTORY);
		default:
			return state;
	}
}

export function analytics(state = rootState.session.analytics, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			const previousSection = state.get('currentSection');
			const currentSection = stripContentTitlesFromURL(action.payload.location.pathname);

			const searchSessionId =
				parse(action.payload.location.search, true).query.search_id || state.get('searchSessionId');

			return state.merge({
				currentSection,
				previousSection,
				searchSessionId,
			});

		default:
			return state;
	}
}

export default combineReducers({
	errors,
	history,
	analytics,
});
