import { LoadablePaint } from '@confluence/loadable';

export const ViewPagePdfExportLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-view-page-pdf-export" */ './ViewPagePdfExportPage'
			)
		).ViewPagePdfExportPage,
});
