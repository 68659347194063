/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */

import { xcss as platformXCSS } from '@atlaskit/primitives';
import { makeXCSSValidator } from '../../../utils';

type PlatformBoxProps = React.ComponentProps<typeof PlatformBox>;

const xcssValidator = makeXCSSValidator({
  // color related props
  color: true,
  boxShadow: true,
  opacity: true,
  backgroundColor: true,
  borderColor: true,
  borderBlockColor: true,
  borderBlockEndColor: true,
  borderBlockStartColor: true,
  borderBottomColor: true,
  borderInlineColor: true,
  borderInlineEndColor: true,
  borderInlineStartColor: true,
  borderLeftColor: true,
  borderRightColor: true,
  borderTopColor: true,

  // layout and space related props
  width: {
    allowCSS: true,
  },
  height: {
    allowCSS: true,
  },
  minWidth: {
    allowCSS: true,
  },
  maxWidth: {
    allowCSS: true,
  },
  minHeight: {
    allowCSS: true,
  },
  maxHeight: {
    allowCSS: true,
  },
  margin: true,
  marginBlock: true,
  marginBlockEnd: true,
  marginBlockStart: true,
  marginBottom: true,
  marginInline: true,
  marginInlineEnd: true,
  marginInlineStart: true,
  marginLeft: true,
  marginRight: true,
  marginTop: true,
  padding: true,
  paddingBlock: true,
  paddingBlockEnd: true,
  paddingBlockStart: true,
  paddingBottom: true,
  paddingInline: true,
  paddingInlineEnd: true,
  paddingInlineStart: true,
  paddingLeft: true,
  paddingRight: true,
  paddingTop: true,

  // other box related props
  borderRadius: { supportedValues: ['border.radius'] },
  borderBottomLeftRadius: { supportedValues: ['border.radius'] },
  borderBottomRightRadius: { supportedValues: ['border.radius'] },
  borderTopLeftRadius: { supportedValues: ['border.radius'] },
  borderTopRightRadius: { supportedValues: ['border.radius'] },
  borderEndEndRadius: { supportedValues: ['border.radius'] },
  borderEndStartRadius: { supportedValues: ['border.radius'] },
  borderStartEndRadius: { supportedValues: ['border.radius'] },
  borderStartStartRadius: { supportedValues: ['border.radius'] },
  borderWidth: { supportedValues: ['border.width'] },
  borderBlockWidth: { supportedValues: ['border.width'] },
  borderBlockEndWidth: { supportedValues: ['border.width'] },
  borderBlockStartWidth: { supportedValues: ['border.width'] },
  borderBottomWidth: { supportedValues: ['border.width'] },
  borderInlineWidth: { supportedValues: ['border.width'] },
  borderInlineEndWidth: { supportedValues: ['border.width'] },
  borderInlineStartWidth: { supportedValues: ['border.width'] },
  borderLeftWidth: { supportedValues: ['border.width'] },
  borderRightWidth: { supportedValues: ['border.width'] },
  borderTopWidth: { supportedValues: ['border.width'] },

  // other props not in tokens based props
  borderTopStyle: {
    supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
  },
  borderBottomStyle: {
    supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
  },
  borderRightStyle: {
    supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
  },
  borderLeftStyle: {
    supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
  },
  borderStyle: {
    supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
  },
  position: {
    supportedValues: ['relative', 'static'],
  },
});

type XCSSProp = ReturnType<typeof xcssValidator>;

export type BoxProps = Pick<
  PlatformBoxProps,
  | 'padding'
  | 'paddingBlock'
  | 'paddingBlockStart'
  | 'paddingBlockEnd'
  | 'paddingInline'
  | 'paddingInlineStart'
  | 'paddingInlineEnd'
  | 'backgroundColor'
  | 'children'
  | 'ref'
  | 'testId'
  | 'role'
> & {
  xcss?: XCSSProp;
};

type PlatformXCSSParam = Parameters<typeof platformXCSS>[0];

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b93075289709f8ddfd914233bc4cd2df>>
 * @codegenId #box
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen box
 * @codegenParams { "defineOwnType": true, "props": { "as": false, "padding": true, "paddingBlock": true, "paddingBlockStart": true, "paddingBlockEnd": true, "paddingInline": true, "paddingInlineStart": true, "paddingInlineEnd": true, "backgroundColor": true, "children": true, "ref": true, "testId": true, "data-testid": false, "xcss": { "value": "xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)" }, "role": true, "style": false } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::2f4848af544e0f64968f7c28012cf0f0>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/box.tsx <<SignedSource::3f46b211de65978994fb1cf89b9daeca>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::00aab072ec1c97fd176380fcd46ccf7e>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::a91bf7842593d9052c045e4a40d7ab3b>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/internal/surface-provider.tsx <<SignedSource::3358069cee38bc7ad0dc32d45be08599>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/internal/types.tsx <<SignedSource::98a727fd83a4a2992ebaf492654478ba>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Box as PlatformBox } from '@atlaskit/primitives';

// Define the type for Box as BoxProps at the top of this file

export const Box = (props: Parameters<RenderFn>[0]) => {
  const {
    padding,
    paddingBlock,
    paddingBlockStart,
    paddingBlockEnd,
    paddingInline,
    paddingInlineStart,
    paddingInlineEnd,
    backgroundColor,
    ref,
    testId,
    xcss,
    role,
  } = props.forgeDoc.props as BoxProps;
  return (
    <PlatformBox
      padding={padding}
      paddingBlock={paddingBlock}
      paddingBlockStart={paddingBlockStart}
      paddingBlockEnd={paddingBlockEnd}
      paddingInline={paddingInline}
      paddingInlineStart={paddingInlineStart}
      paddingInlineEnd={paddingInlineEnd}
      backgroundColor={backgroundColor}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      ref={ref}
      testId={testId}
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
      xcss={xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
