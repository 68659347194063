import {
	pageLoadEventPlugin,
	customEventPlugin,
	ssrRegionPlugin,
} from '@confluence/browser-metrics';
import { getBuildInfo } from '@confluence/build-info';

export const additionalPayloadData = (
	interaction: {
		end: number;
		start: number;
		ufoName: string;
	},
	otherMetadataFromSessionData: {
		cloudId: string;
		isShardPreProd: boolean;
	},
) => {
	const buildInfoData = getBuildInfo();
	const buildInfo: any = {};

	if (buildInfoData) {
		Object.keys(buildInfoData).forEach((item) => {
			const newKey = `buildInfo:${item}`;
			buildInfo[newKey] = (buildInfoData as any)[item];
		});
	}

	const data = {
		...pageLoadEventPlugin({
			stop: interaction.end,
			start: interaction.start,
			key: interaction.ufoName,
		}),
		...customEventPlugin({
			stop: interaction.end,
			start: interaction.start,
			key: interaction.ufoName,
		}),
		...ssrRegionPlugin(),
		...buildInfo,
		...otherMetadataFromSessionData,
	};

	return {
		confluence: data,
	};
};
