import { requireLegacyWRM } from '@confluence/wrm';

export function evalLegacyConnectInlineScripts(container) {
	// Connect macros have an inline script that doesn't work automatically when
	// rendered in react using dangerouslySetInnerHTML. This script evaluates any
	// connect macro.
	if (!container) {
		return Promise.reject(new Error('evalLegacyConnectInlineScripts missing container argument'));
	}

	return new Promise((resolve) => {
		const connectScripts = Array.from(
			container.querySelectorAll('script.ap-iframe-body-script'),
		) as HTMLElement[];
		const macroScripts = Array.from(
			container.querySelectorAll('script.cc-macro-body-script'),
		) as HTMLElement[];

		const allScripts = connectScripts.concat(macroScripts);

		if (allScripts.length > 0) {
			// Download the connect web resources required to render an add-on before evaluating the inline scripts.
			requireLegacyWRM(
				[
					// ensure navigator-context misc dep is loaded before navigator-context
					'wr!confluence.web.resources:querystring',
					// resource to provide the context value of AP.navigator.getLocation
					'wr!confluence.web.resources:navigator-context',
					'wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-resources-v5',
				],
				() => {
					allScripts.forEach((elementScript) => {
						eval(elementScript.innerHTML);
					});
					resolve(null);
				},
				() => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line no-console
						console.error('Connect add-on cannot be initialized when WRM is disabled.');
					}
				},
			);
		} else {
			resolve(null);
		}
	});
}
