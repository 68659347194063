/* GeneratedContentHash: 428c94c3c4b598a230a515abdce25b63ed99224149a7858d4eba5874bc69e431 */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
#
# Generate source: local/cli/src/commands/generate-all/i18n-extract.ts
*/

import defaultMessages from './generated/en.json';
import mapLocaleToPrsLocale from '@atlaskit/prs-locale-mapper';

// Helper to automatically handle ESM and non-ESM *.json imports (either is possible depending on the service/product's build configuration)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const importHelper = (mod: any) => (mod && mod.__esModule ? mod.default : mod);

export const messagesLoader = async (locale: string) => {
	const normalizedLocale = mapLocaleToPrsLocale(locale);
	switch (normalizedLocale) {
		case 'cs':
			return importHelper(await import('./generated/cs.json'));
		case 'da':
			return importHelper(await import('./generated/da.json'));
		case 'de':
			return importHelper(await import('./generated/de.json'));
		case 'en-GB':
			return importHelper(await import('./generated/en-GB.json'));
		case 'en-US':
			return importHelper(await import('./generated/en-US.json'));
		case 'en-XA':
			return importHelper(await import('./generated/en-XA.json'));
		case 'en-XB':
			return importHelper(await import('./generated/en-XB.json'));
		case 'en-ZZ':
			return importHelper(await import('./generated/en-ZZ.json'));
		case 'en':
			return importHelper(await import('./generated/en.json'));
		case 'es':
			return importHelper(await import('./generated/es.json'));
		case 'et':
			return importHelper(await import('./generated/et.json'));
		case 'fi':
			return importHelper(await import('./generated/fi.json'));
		case 'fr':
			return importHelper(await import('./generated/fr.json'));
		case 'hu':
			return importHelper(await import('./generated/hu.json'));
		case 'it':
			return importHelper(await import('./generated/it.json'));
		case 'ja':
			return importHelper(await import('./generated/ja.json'));
		case 'ko':
			return importHelper(await import('./generated/ko.json'));
		case 'nb':
			return importHelper(await import('./generated/nb.json'));
		case 'nl':
			return importHelper(await import('./generated/nl.json'));
		case 'pl':
			return importHelper(await import('./generated/pl.json'));
		case 'pt-BR':
			return importHelper(await import('./generated/pt-BR.json'));
		case 'ro':
			return importHelper(await import('./generated/ro.json'));
		case 'ru':
			return importHelper(await import('./generated/ru.json'));
		case 'sv':
			return importHelper(await import('./generated/sv.json'));
		case 'th':
			return importHelper(await import('./generated/th.json'));
		case 'tr':
			return importHelper(await import('./generated/tr.json'));
		case 'uk':
			return importHelper(await import('./generated/uk.json'));
		case 'vi':
			return importHelper(await import('./generated/vi.json'));
		case 'zh-CN':
			return importHelper(await import('./generated/zh-CN.json'));
		case 'zh-TW':
			return importHelper(await import('./generated/zh-TW.json'));
		default:
			return defaultMessages;
	}
};
