export enum XIDItemType {
	XC = 'xc',
	UID = 'uid',
}

export enum XIDState {
	NEW = 'NEW',
	CHANGED = 'CHANGED',
	EXISTING = 'EXISTING',
	TIMEOUT = 'TIMEOUT',
	ERROR = 'ERROR',
	UNAVAILABLE = 'UNAVAILABLE',
	MALFORMED = 'MALFORMED',
	UNKNOWN = 'UNKNOWN',
}
