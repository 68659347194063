import { type Config } from '../../common/types/config';
import { type EventMapping, type MessageMapping } from '../../common/types/message';

export const SET_MESSAGE_MAPPING: 'SET_MESSAGE_MAPPING' = 'SET_MESSAGE_MAPPING';
export const SET_INITIALIZING: 'SET_INITIALIZING' = 'SET_INITIALIZING';
export const RESET_STORE: 'RESET_STORE' = 'RESET_STORE';
export const CLEAR_CURRENT: 'CLEAR_CURRENT' = 'CLEAR_CURRENT';
export const SET_CURRENT: 'SET_CURRENT' = 'SET_CURRENT';

export type RESET_STORE_ACTION = {
	type: typeof RESET_STORE;
};

export const resetStore = (): RESET_STORE_ACTION => ({
	type: RESET_STORE,
});

export type INITIALIZE_ACTION = {
	type: typeof SET_INITIALIZING;
	config: Config;
};

export const initialize = (config: Config): INITIALIZE_ACTION => ({
	type: SET_INITIALIZING,
	config,
});

export type SET_MESSAGE_MAPPING_ACTION = {
	type: typeof SET_MESSAGE_MAPPING;
	messageMapping: MessageMapping;
};

export const setMessageMapping = (messageMapping: MessageMapping): SET_MESSAGE_MAPPING_ACTION => ({
	type: SET_MESSAGE_MAPPING,
	messageMapping,
});

export type SET_CURRENT_ACTION = {
	type: typeof SET_CURRENT;
	eventMapping: EventMapping | Record<string, any>;
};

export type CLEAR_CURRENT_ACTION = {
	type: typeof CLEAR_CURRENT;
};

export function setCurrent(eventMapping: EventMapping | Record<string, any>): SET_CURRENT_ACTION {
	return {
		type: SET_CURRENT,
		eventMapping,
	};
}

export function clearCurrent(): CLEAR_CURRENT_ACTION {
	return {
		type: CLEAR_CURRENT,
	};
}
