import { useContext } from 'react';

import { Context } from './ChoreographerAssetsContext';

export const useChoreographerAssets = () => {
	const ctx = useContext(Context);

	if (!ctx) {
		throw new Error(
			'ChoreographerAssetsContext not found, did you include the correct Context provider?',
		);
	}

	return ctx;
};
