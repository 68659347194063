import { useCallback } from 'react';

import {
	buildBasePath,
	usePostOfficeContext,
	usePostOfficeEnvironment,
	type PostOfficeEnvironmentValues,
	type PostOfficeContextValue,
} from '@atlassian/post-office-context';
import { requestInitialData } from '@atlassian/post-office-placement-shared/request-initial-data';

import {
	type PreloadFnContext,
	restQuery,
	preloadedRestLink,
} from '@confluence/query-preloader-tools';

import {
	type ConfluenceSessionDataFields,
	sessionToPostOfficeContext,
	transformConfluenceEnvToPostOfficeEnvironment,
} from './session-to-post-office-context';

type OptionalEnvConfigsForPostOfficeEnvironment = Omit<PostOfficeEnvironmentValues, 'envConfigs'> &
	Partial<Pick<PostOfficeEnvironmentValues, 'envConfigs'>>;

/**
 * This is a cached fetcher that is used by the Post Office requests.
 *
 * See also `post-office/packages/post-office-clients/post-office-placement-shared/src/Placement.tsx` for the initial data fetching.
 *
 * @param url
 * @returns
 */
const confluenceFetcher = (url: string) => {
	return preloadedRestLink(url, { method: 'GET' }, () => restQuery(url, { method: 'GET' }));
};

/**
 * Common method for setting up the Post Office placement request URL with the environment and product context
 *
 * @param placementId
 * @param contextOptions
 * @returns
 */
const preloadPostOfficePlacement = (
	placementId: string,
	contextOptions: {
		postOfficeContext: PostOfficeContextValue;
		environment: OptionalEnvConfigsForPostOfficeEnvironment;
	},
) => {
	const { postOfficeContext, environment } = contextOptions;

	return requestInitialData(
		placementId,
		postOfficeContext,
		confluenceFetcher,
		buildBasePath(environment.currentEnv, environment.envConfigs),
	);
};

/**
 * Returns a fetcher for Post Office placement API that is shared with the SSR preload
 *
 * @param placementId
 * @returns
 */
export const usePlacementSSRFetch = (placementId: string) => {
	const postOfficeContext = usePostOfficeContext();
	const environment = usePostOfficeEnvironment();

	return useCallback(
		() => preloadPostOfficePlacement(placementId, { postOfficeContext, environment }),
		[placementId, postOfficeContext, environment],
	);
};

/**
 * Preload a Post Office placement API via SSR
 *
 * @param placementId
 * @param preloadedSessionContext
 * @returns
 */
export const preloadPostOfficePlacementWithSessionContext = (
	placementId: string,
	preloadedSessionContext: Pick<PreloadFnContext, ConfluenceSessionDataFields | 'environment'>,
) => {
	return preloadPostOfficePlacement(placementId, {
		postOfficeContext: sessionToPostOfficeContext(preloadedSessionContext),
		environment: {
			currentEnv: transformConfluenceEnvToPostOfficeEnvironment(
				preloadedSessionContext.environment,
			),
		},
	});
};
