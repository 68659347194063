import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import { Legend } from '@visx/legend';
import { DataContext } from '@visx/xychart';

import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

const LEGEND_STYLE = {
	display: 'flex',
	color: token('color.chart.gray.bolder.hovered', '#626F86'),
	font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
	height: token('space.200', '16px'),
};
const LEGEND_SHAPE_HEIGHT = token('space.100', '8px');
const LEGEND_SHAPE_WIDTH = token('space.100', '8px');
const LEGEND_ITEM_MARGIN = `${token('space.0', '0px')} ${token(
	'space.200',
	'16px',
)} ${token('space.0', '0px')} ${token('space.0', '0px')}`;

type CustomLegendProps = {
	shouldRightAlign: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegendContainer = styled.div<CustomLegendProps>({
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: ({ shouldRightAlign }) => (shouldRightAlign ? 'end' : undefined),
});

// This component creates a customized Legend component because no higher-level component exists from "@visx/xychart".
export const CustomLegend = ({ shouldRightAlign }: CustomLegendProps) => {
	const { colorScale, margin } = useContext(DataContext);
	// This check makes the compiler happy.
	if (!(colorScale && margin)) {
		return <></>;
	}

	return (
		<LegendContainer shouldRightAlign={shouldRightAlign}>
			<Legend
				// The direction is "row-reverse" here so that the legend items are ordered correctly
				// even though we render the lines in reverse order.
				direction="row-reverse"
				scale={colorScale}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={LEGEND_STYLE}
				shapeHeight={LEGEND_SHAPE_HEIGHT}
				shapeWidth={LEGEND_SHAPE_WIDTH}
				itemMargin={LEGEND_ITEM_MARGIN}
				data-testid="CustomLegendItem"
			/>
		</LegendContainer>
	);
};
