/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
export const CALL_API = Symbol('Call API');

/**
 * Creates a thunk action that calls an API method from the Confluence javascript rest API. When the API method is first
 * called, the {type}_REQUEST action is triggered. If the API request succeeds, the {type}_SUCCESS action is triggered
 * with the response from the API request. If the API request fails, the {type}_FAILURE action is triggered with the
 * error.
 *
 * @param type {String} The name of the action. e.g. "FAVOURITE_SPACE".
 * @param apiMethod {Function} The Confluence javascript rest API method to call.
 * @param [actionOptions] {Object} actionOptions.schema - The normalizr schema to apply to the API response
 *                               actionOptions.errorHandler - Response transformer applied if an error occurs during the request
 * @returns {Function}
 */
export function restActionCreator(type, apiMethod, actionOptions) {
	return (options) => ({
		[CALL_API]: {
			types: [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`],
			apiMethod,
			options,
			actionOptions,
		},
	});
}

export function commonActionCreator(type) {
	return (options) => ({
		type,
		...options,
	});
}

export function deprecatedRestActionCreator(type, apiMethod, actionOptions) {
	if (process.env.NODE_ENV === 'development') {
		const COLOR_RED = '\x1b[31m';
		console.error(`${COLOR_RED}ERROR: ${type} action will soon be deprecated.`);
	}

	return restActionCreator(type, apiMethod, actionOptions);
}

export function deprecatedCommonActionCreator(type) {
	if (process.env.NODE_ENV === 'development') {
		const COLOR_RED = '\x1b[31m';
		console.error(`${COLOR_RED}ERROR: ${type} action will soon be deprecated.`);
	}

	return commonActionCreator(type);
}
