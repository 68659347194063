import type { CustomField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export const include: FieldTransformer<any> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name !== 'defaultParameterValue') {
				return field;
			}

			return {
				...field,
				type: 'custom',
				// description talks about entering page using SPACEKEY:Page_title syntax
				// which no longer applies as users are now just picking from a dropdown
				description: undefined,
				options: {
					resolver: {
						type: 'spaceKeyAndPageTitleContent',
					},
				},
			} as CustomField;
		});
	},
};
