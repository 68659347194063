import { parse, format } from 'url';

import { compile } from 'path-to-regexp';

export const toUrl = (
	pattern: string,
	params: { [key: string]: any } = {},
	options: { query?: { [key: string]: any }; hash?: string } = {},
): string => {
	const toPath = compile(pattern, { encode: decodeURIComponent });
	const path = toPath(params);
	const parsed = parse(path, true);
	return format({
		pathname: parsed.pathname,
		query: {
			...parsed.query,
			...(options.query || {}),
		},
		hash: options.hash,
	});
};
