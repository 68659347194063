import { getLogger } from '@confluence/logger';

import type { FutureTask, Task } from './types';

const logger = getLogger('FutureTask');

/**
 * Define a task to be run in the future.
 *
 * Allows you to get a Promise of the task's return value even before the task is run.
 */
export const createFutureTask = <T>(id: string, task: Task<T>, timeout?: number): FutureTask<T> => {
	let resolve: (value: T | PromiseLike<T>) => void;
	let reject: (reason?: any) => void;
	let started = false;
	let timer: ReturnType<typeof setTimeout>;

	const run = () => {
		if (started) return;
		if (timer && typeof clearTimeout !== 'undefined') clearTimeout(timer);
		started = true;

		logger.debug`${id} start`;
		try {
			const result = task();
			if (result instanceof Promise) {
				result.then(resolve, reject);
			} else {
				resolve(result);
			}
		} catch (e) {
			// result can be a Promise or a value
			// The try catch cannot catch async errors
			// It is used to catch sync throws
			reject(e);
		}
	};

	if (timeout && typeof setTimeout !== 'undefined') {
		timer = setTimeout(run, timeout);
	}

	return {
		get started() {
			return started;
		},

		result: new Promise<T>((_resolve, _reject) => {
			resolve = _resolve;
			reject = _reject;
		}),

		run,
	};
};
