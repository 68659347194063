import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

export const ContactAdministratorRoute = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContactAdministratorPageComponent" */ '@confluence/contact-administrator/entry-points/ContactAdministratorPage'
			)
		).ContactAdministratorPage,
});

(ContactAdministratorRoute as any).NAVIGATION_PARAMS = () => {
	return {
		Screen: {
			screenEvent: { name: 'contactAdministratorScreen' },
		},
		MainLayout: {
			navView: PRODUCT_HOME_ID,
		},
	};
};
