import gql from 'graphql-tag';

export const SpaceOverviewQuery = gql`
	query SpaceOverviewQuery($spaceKey: String!, $excludeOperations: Boolean!) {
		space(key: $spaceKey) {
			id
			currentUser {
				isAdmin
			}
			name
			theme {
				themeKey
			}
			icon {
				path
			}
			homepage {
				id
				operations @skip(if: $excludeOperations) {
					operation
				}
				properties(keys: ["emoji-title-published"]) {
					nodes {
						id
						key
						value
					}
				}
			}
		}
	}
`;
