import React from 'react';
import type { ReactElement } from 'react';
import { styled } from '@compiled/react';

import { xcss, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { CTAProps } from '../../common/useCTA';

import { MainStat } from './sub-components/MainStat';
import type { MainStatType } from './sub-components/MainStat';
import { SubStat } from './sub-components/SubStat';
import type { SubStatType } from './sub-components/SubStat';
import { CTAButton } from './sub-components/CTAButton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryBaseWrapper = styled.div({
	display: 'grid',
	gridTemplateRows: 'repeat(auto-fill, auto)',
	gridGap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	'@media (min-width: 480px)': {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
});

const MainStatExtraInfoComponentContainerStyles = xcss({
	height: '100%',
	marginLeft: 'space.100',
});

const SubStatDotContainerStyles = xcss({
	color: 'color.text.subtlest',
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

export type SummaryBaseProps = {
	mainStat?: MainStatType;
	mainStatExtraInfoComponent?: ReactElement;
	subStat?: SubStatType;
	subStatExtraInfoComponent?: ReactElement;
	cta?: CTAProps;
	hideSubStat?: boolean;
	showSkeletons?: boolean;
};

const SummaryBaseMainStat = ({
	mainStat,
	mainStatExtraInfoComponent,
	showSkeletons,
}: Pick<SummaryBaseProps, 'mainStat' | 'mainStatExtraInfoComponent' | 'showSkeletons'>) => (
	<Flex direction="row" alignItems="center">
		<MainStat showSkeleton={showSkeletons} data={mainStat} />
		{mainStatExtraInfoComponent && (
			<Flex direction="row" xcss={MainStatExtraInfoComponentContainerStyles}>
				{mainStatExtraInfoComponent}
			</Flex>
		)}
	</Flex>
);

const SummaryBaseSubStat = ({
	subStat,
	subStatExtraInfoComponent,
	showSkeletons,
}: Pick<SummaryBaseProps, 'subStat' | 'subStatExtraInfoComponent' | 'showSkeletons'>) => (
	<Flex direction="row" alignItems="center">
		{subStatExtraInfoComponent}
		{subStatExtraInfoComponent && subStat && <Flex xcss={SubStatDotContainerStyles}>·</Flex>}
		<SubStat showSkeleton={showSkeletons} data={subStat} />
	</Flex>
);

export const SummaryBase = ({
	mainStat,
	mainStatExtraInfoComponent,
	subStat,
	subStatExtraInfoComponent,
	cta,
	hideSubStat,
	showSkeletons,
}: SummaryBaseProps) => (
	<SummaryBaseWrapper>
		<SummaryBaseMainStat
			mainStat={mainStat}
			mainStatExtraInfoComponent={mainStatExtraInfoComponent}
			showSkeletons={showSkeletons}
		/>
		<Footer data-testid="footer-container">
			{!hideSubStat && (
				<SummaryBaseSubStat
					subStat={subStat}
					subStatExtraInfoComponent={subStatExtraInfoComponent}
					showSkeletons={showSkeletons}
				/>
			)}
			{cta && <CTAButton showWithDot={!hideSubStat} data={cta} />}
		</Footer>
	</SummaryBaseWrapper>
);
