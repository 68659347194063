import React, { useMemo } from 'react';

import {
	PostOfficeProvider as PostOfficeBaseProvider,
	usePostOfficeRouteUpdate,
} from '@atlassian/post-office-context';

import { useSessionData } from '@confluence/session-data';
import { useRouteDataRef } from '@confluence/route-manager';
import { fg } from '@confluence/feature-gating';

import {
	sessionToPostOfficeContext,
	transformConfluenceEnvToPostOfficeEnvironment,
} from './session-to-post-office-context';

const ConfluencePostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	const { accessStatus, activationId, cloudId, environment, locale, orgId, edition } =
		useSessionData();

	const context = useMemo(
		() => ({
			current: sessionToPostOfficeContext({
				accessStatus,
				activationId,
				cloudId,
				locale,
				orgId,
				edition,
			}),
		}),
		[activationId, cloudId, accessStatus, locale, orgId, edition],
	);

	const env = useMemo(
		() => ({ env: transformConfluenceEnvToPostOfficeEnvironment(environment) }),
		[environment],
	);

	return (
		<PostOfficeBaseProvider context={context} environment={env}>
			{children}
		</PostOfficeBaseProvider>
	);
};

export const PostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	return fg('post-office-screen-space-overlay-confluence') ||
		fg('post_office_app_switcher_discovery_confluence') ? (
		<ConfluencePostOfficeProvider>{children}</ConfluencePostOfficeProvider>
	) : (
		<>{children}</>
	);
};

const ReactResourceRouterListener = ({ children }: { children: React.ReactNode }) => {
	const routeDataRef = useRouteDataRef();

	usePostOfficeRouteUpdate([
		routeDataRef?.current?.href ?? '',
		routeDataRef.current?.routeName ?? '',
	]);

	return <>{children}</>;
};

export const PostOfficeRouterListener = ({ children }: { children: React.ReactNode }) => {
	return fg('post-office-screen-space-overlay-confluence') ? (
		<ReactResourceRouterListener>{children}</ReactResourceRouterListener>
	) : (
		<>{children}</>
	);
};
