import { type PostOfficeEnvironmentValues } from '../../../post-office-environment/types';
import type { PostOfficeEnvironmentConfig } from '../../types';

export const generateEnvironmentConfigWithOverrides = (
	defaultEnvironmentValues: PostOfficeEnvironmentValues,
	environmentWithOverrides: PostOfficeEnvironmentConfig,
): PostOfficeEnvironmentValues => {
	const { env, overrides } = environmentWithOverrides;
	return {
		currentEnv: env,
		envConfigs: {
			...defaultEnvironmentValues.envConfigs,
			[env]: {
				remoteModule: {
					origin:
						overrides?.remoteModule?.origin ??
						defaultEnvironmentValues.envConfigs[env].remoteModule.origin,
					pathname:
						overrides?.remoteModule?.pathname ??
						defaultEnvironmentValues.envConfigs[env].remoteModule.pathname,
					searchParams:
						overrides?.remoteModule?.searchParams ??
						defaultEnvironmentValues.envConfigs[env].remoteModule.searchParams,
				},
				api: {
					origin: overrides?.api?.origin ?? defaultEnvironmentValues.envConfigs[env].api.origin,
					pathname:
						overrides?.api?.pathname ?? defaultEnvironmentValues.envConfigs[env].api.pathname,
					searchParams:
						overrides?.api?.searchParams ??
						defaultEnvironmentValues.envConfigs[env].api.searchParams,
				},
			},
		},
	};
};
