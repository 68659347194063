export const blocklistedErrorMessagePatterns = [
	// Error in user code
	/^There was an error invoking the function/,

	// User code returned non-JSON value, so XIS threw an InvalidInvocationPayloadError.
	/^Invalid response body, data must be JSON or undefined$/,

	// Networking errors
	/^Failed to fetch$/,
	/^Network error: Failed to fetch$/,
	/^Network error: Network failure: TypeError: Failed to fetch$/,
	/^NetworkError when attempting to fetch resource.$/,
	/^Network error: Received status 504$/, // time out
	/^GraphQL error: The underlying service call timed out$/,

	// Unauthenticated requests made from public confluence content
	/^GraphQL error: Auth category: UNAUTHENTICATED is not allowed in service xen_invocation_service$/,
];
