import React, { useEffect, useState } from 'react';
import Portal from '@atlaskit/portal';
import { layers } from '@atlaskit/theme/constants';

const { Provider, Consumer } = React.createContext<HTMLDivElement | null | undefined>(undefined);

export const PortalProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const [portal, setPortal] = useState<HTMLDivElement | null | undefined>();
	const [isPortalReady, setIsPortalReady] = useState(false);

	useEffect(() => {
		if (portal !== null) {
			setIsPortalReady(true);
		}
	}, [portal]);

	return (
		<>
			{isPortalReady && <Provider value={portal}>{children}</Provider>}
			<Portal zIndex={layers.modal() + 1}>
				<div data-testid="forge-ui-portal" ref={setPortal} />
			</Portal>
		</>
	);
};

export { Consumer as PortalConsumer };
