import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { CSSProperties } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useLazyQuery, useQuery } from 'react-apollo';

import DynamicTable from '@atlaskit/dynamic-table';
import { Box, Text, xcss, Stack } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';

import { MetricSettingsContext } from '@confluence/admin-center/entry-points/MetricSettingsContext';
import {
	COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE,
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import { HighlightContainer } from './HighlightContainer';
import { Metric, useSelectedMetric } from './SelectedMetricContext';
import {
	HubAnalyticsTableContentQuery,
	HubAnalyticsTableMetricsQuery,
} from './HubAnalyticsTableQuery.graphql';
import type {
	HubAnalyticsTableMetricsQueryVariables,
	HubAnalyticsTableMetricsQuery as HubAnalyticsTableMetricsQueryType,
} from './__types__/HubAnalyticsTableMetricsQuery';
import type {
	HubAnalyticsTableContentQuery as HubAnalyticsTableContentQueryType,
	HubAnalyticsTableContentQueryVariables,
} from './__types__/HubAnalyticsTableContentQuery';
import { SearchFilter } from './SearchFilter';

type tableDataEntryType = {
	title: string;
	contentId: string;
	publishDate: string;
	views: number | string;
	uniqueVisitors: number | string;
	clicks: number | string;
	clickThroughRate: string;
};

type tableHeadingEntryType = {
	content: string | JSX.Element;
	inlineStyles?: CSSProperties;
	isRightAligned: boolean;
	isHighlighted?: boolean;
	isSortable: boolean;
	key: keyof tableDataEntryType;
};

const i18n = defineMessages({
	tableCaption: {
		id: 'company-hub.analytics.hub-analytics-table.caption',
		defaultMessage: 'Top hub impressions',
		description: 'The caption that appears above the links clicked table',
	},
	titleColumnHeading: {
		id: 'company-hub.analytics.hub-analytics-table.title-column-heading',
		defaultMessage: 'Title',
		description: 'the column heading for the "title" column in the links clicked table',
	},
	publishDateColumnHeading: {
		id: 'company-hub.analytics.hub-analytics-table.publish-date-column-heading',
		defaultMessage: 'Publish Date',
		description: 'the column heading for the "publish date" column in the links clicked table',
	},
	seenByColumnHeading: {
		id: 'company-hub.analytics.hub-analytics-table.seen-by-column-heading',
		defaultMessage: 'Seen by',
		description: 'the column heading for the "Seen by" column in the links clicked table',
	},
	uniqueVisitorsColumnHeading: {
		id: 'company-hub.analytics.hub-analytics-table.unique-visitors-column-heading',
		defaultMessage: 'Unique Visitors',
		description: 'the column heading for the "unique visitors" column in the links clicked table',
	},
	clicksColumnHeading: {
		id: 'company-hub.analytics.hub-analytics-table.clicks-column-heading',
		defaultMessage: 'Clicks',
		description: 'the column heading for the "clicks" column in the links clicked table',
	},
	clickThroughRateColumnHeading: {
		id: 'company-hub.analytics.hub-analytics-table.ctr-column-heading',
		defaultMessage: 'CTR',
		description:
			'the column heading for the "click through rate" column in the links clicked table',
	},
	clickThroughRateTooltipMessage: {
		id: 'company-hub.analytics.hub-analytics-table.ctr-tooltip-message',
		defaultMessage: 'Click through rate of a link',
		description:
			'the tool tip message for when the user hovers over the "CTR" heading to explain "CTR"',
	},
	tableErrorMessage: {
		id: 'company-hub.analytics.hub-analytics-table.error-message',
		defaultMessage: 'An error occurred while fetching table data.',
		description: 'Error message displayed when data fetching fails.',
	},
	tableSearchEmptyResults: {
		id: 'company-hub.analytics.hub-analytics-table.search-empty',
		defaultMessage: 'No results match your criteria',
		description:
			'Empty search results message displayed when the search term matches none of the table data',
	},
});

const rowContainerTextStyles = xcss({
	paddingBlock: 'space.075',
	paddingInline: 'space.150',
});

const captionContainerStyles = xcss({
	font: token('font.heading.xsmall'),
});

const rightAlignedTextStyles = xcss({
	textAlign: 'right',
});

const centerAlignedTextStyles = xcss({
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TableContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	table: {
		border: `1px solid ${token('color.border')}`,
		borderRadius: token('border.radius.200'),
		tableLayout: 'fixed',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'table > thead > tr > th': {
		borderBlockEnd: `1px solid ${token('color.border')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'table > tbody > tr > td:first-child': {
		borderRight: `1px solid ${token('color.border')}`,
	},
});

const headingInlineStyles: CSSProperties = {
	paddingBlock: '10px',
	paddingInline: token('space.150'),
};

const rightAlignedHeadingStyles: CSSProperties = {
	...headingInlineStyles,
	textAlign: 'right',
};

const highlightedRightAlignedHeadingStyles = (isTableEmpty: boolean): CSSProperties => ({
	...rightAlignedHeadingStyles,
	paddingBlock: token('space.100'),
	paddingInline: '10px',
	borderTop: `2px solid ${token('color.border.selected')}`,
	borderLeft: `2px solid ${token('color.border.selected')}`,
	borderRight: `2px solid ${token('color.border.selected')}`,
	borderBottom: isTableEmpty ? `2px solid ${token('color.border.selected')}` : undefined,
});

const getHeadingConfig = (metricState: Metric, isTableEmpty: boolean): tableHeadingEntryType[] => [
	{
		content: (
			<Box>
				<FormattedMessage {...i18n.titleColumnHeading} />
			</Box>
		),
		key: 'title',
		inlineStyles: {
			...headingInlineStyles,
			width: '60%',
		},
		isRightAligned: false,
		isSortable: true,
	},
	{
		content: (
			<Box>
				<FormattedMessage {...i18n.seenByColumnHeading} />
			</Box>
		),
		key: 'views',
		// Need these inline styles since a button wraps the content when isSortable is true
		// Applying the style to the table head cell directly ensures we have the correct alignment on all children of the cell
		// This style prop is consumed at confluence/platform/packages/design-system/dynamic-table/src/components/table-head-cell.tsx
		inlineStyles:
			metricState === Metric.VIEWS
				? highlightedRightAlignedHeadingStyles(isTableEmpty)
				: rightAlignedHeadingStyles,
		isRightAligned: true,
		isHighlighted: metricState === Metric.VIEWS,
		isSortable: true,
	},
	{
		content: (
			<Box>
				<FormattedMessage {...i18n.uniqueVisitorsColumnHeading} />
			</Box>
		),
		key: 'uniqueVisitors',
		inlineStyles:
			metricState === Metric.UNIQUE_VISITORS
				? highlightedRightAlignedHeadingStyles(isTableEmpty)
				: rightAlignedHeadingStyles,
		isRightAligned: true,
		isHighlighted: metricState === Metric.UNIQUE_VISITORS,
		isSortable: true,
	},
	{
		content: (
			<Box>
				<FormattedMessage {...i18n.clicksColumnHeading} />
			</Box>
		),
		key: 'clicks',
		inlineStyles:
			metricState === Metric.CLICKS
				? highlightedRightAlignedHeadingStyles(isTableEmpty)
				: rightAlignedHeadingStyles,
		isRightAligned: true,
		isHighlighted: metricState === Metric.CLICKS,
		isSortable: true,
	},
	{
		content: (
			<Tooltip
				content={<FormattedMessage {...i18n.clickThroughRateTooltipMessage} />}
				testId="ctr-tooltip"
			>
				{(toolTipProps) => (
					<Box {...toolTipProps} testId="ctr-heading">
						<FormattedMessage {...i18n.clickThroughRateColumnHeading} />
					</Box>
				)}
			</Tooltip>
		),
		key: 'clickThroughRate',
		inlineStyles:
			metricState === Metric.CTR
				? highlightedRightAlignedHeadingStyles(isTableEmpty)
				: rightAlignedHeadingStyles,
		isRightAligned: true,
		isHighlighted: metricState === Metric.CTR,
		isSortable: true,
	},
];

const getTableRows = (data: tableDataEntryType[], headingConfig: tableHeadingEntryType[]) => {
	return data.map((row, rowIndex) => ({
		key: row.contentId,
		cells: headingConfig.map(({ isHighlighted, isRightAligned, isSortable, key }) => ({
			key: isSortable ? `${row[key]}-${key}` : `row-${rowIndex}-${key}`,
			content: (
				<Box xcss={[rowContainerTextStyles, isRightAligned ? rightAlignedTextStyles : undefined]}>
					<Text
						size="medium"
						testId="links-clicked-table-cell"
						weight={isHighlighted ? 'bold' : 'regular'}
					>
						{row[key]}
					</Text>
				</Box>
			),
		})),
	}));
};

type HubAnalyticsTableProps = {
	isSpaceCreatedDateOver1DayAgo: boolean | undefined;
};

export const HubAnalyticsTable = ({ isSpaceCreatedDateOver1DayAgo }: HubAnalyticsTableProps) => {
	const [metricState] = useSelectedMetric();
	const metricSettingsValues = useContext(MetricSettingsContext);
	const { startDate, endDate } = metricSettingsValues;

	const [tableDataMap, setTableDataMap] = useState<Map<string, tableDataEntryType>>(new Map());

	const [searchText, setSearchText] = useState<string>('');
	const doesIncludeSearchText = useCallback(
		(input: string) => input.toLowerCase().includes(searchText.toLowerCase()),
		[searchText],
	);

	// Helper function to update an entry in the map
	const updateMapEntry = (key: string, updates) => {
		setTableDataMap((prevMap) => {
			// Create a shallow copy of the previous map
			const newMap = new Map(prevMap);
			// Get the current object for the given key, or initialize it if it doesn't exist
			const currentEntry = newMap.get(key) || {
				title: 'N/A',
				contentId: key,
				publishDate: 'N/A',
				views: 'N/A',
				uniqueVisitors: 'N/A',
				clicks: 'N/A',
				clickThroughRate: 'N/A',
			};
			// Update CTR once we have both views and clicks
			if (currentEntry.views !== 'N/A' && currentEntry.clicks !== 'N/A') {
				const ctrCalc = (Number(currentEntry.clicks) / Number(currentEntry.views)) * 100;
				currentEntry.clickThroughRate =
					ctrCalc < 1 && ctrCalc !== 0 ? `${ctrCalc.toFixed(2)}%` : `${ctrCalc.toFixed(0)}%`;
			}
			// Create an updated object with the new values
			const updatedEntry = { ...currentEntry, ...updates };
			// Set the updated object back in the map
			newMap.set(key, updatedEntry);
			return newMap;
		});
	};

	const [fetchTableContent, { loading: contentLoading, error: contentError }] = useLazyQuery<
		HubAnalyticsTableContentQueryType,
		HubAnalyticsTableContentQueryVariables
	>(HubAnalyticsTableContentQuery, {
		onCompleted: (data) => {
			if (data?.content?.nodes) {
				data.content.nodes.forEach((node) => {
					if (node?.id && node?.title) {
						updateMapEntry(node.id, {
							title: node.title,
							contentId: node.id,
						});
					}
				});
			}
		},
	});

	const { loading: metricsLoading, error: metricsError } = useQuery<
		HubAnalyticsTableMetricsQueryType,
		HubAnalyticsTableMetricsQueryVariables
	>(HubAnalyticsTableMetricsQuery, {
		skip: !isSpaceCreatedDateOver1DayAgo,
		variables: {
			startTime: startDate.toISOString(),
			endTime: endDate.toISOString(),
		},
		context: {
			single: true,
		},
		onCompleted: (data) => {
			// populate views, clicks, and unique visitors
			data.views.nodes.forEach((currentNode) => {
				const clicksNode = data.clicks.nodes.find((node) => node.page === currentNode.page);
				const uniqueVisitorsNode = data.uniqueVisitors.nodes.find(
					(node) => node.page === currentNode.page,
				);
				updateMapEntry(currentNode.page, {
					contentId: currentNode.page,
					views: currentNode.count,
					clicks: clicksNode ? clicksNode.count : 0,
					uniqueVisitors: uniqueVisitorsNode ? uniqueVisitorsNode.user : 0,
				});
			});
			// populate titles
			const ids = data.views.nodes.map((node) => node.page);
			// If we fetchTableContent with no IDs it returns all content
			if (ids.length > 0 && isSpaceCreatedDateOver1DayAgo) {
				fetchTableContent({
					variables: {
						ids,
					},
				});
			}
		},
	});

	const loading = contentLoading || metricsLoading;
	const error = contentError || metricsError;

	const tableDataArray = useMemo(
		() =>
			Array.from(tableDataMap)
				.map(([_key, value]) => value)
				.filter((value) => doesIncludeSearchText(value.title)),
		[doesIncludeSearchText, tableDataMap],
	);

	const isTableEmpty = tableDataArray.length === 0;
	const isEmptySearchResult = isTableEmpty && searchText !== '';

	const headingConfig = getHeadingConfig(metricState.selectedMetric, isTableEmpty);

	const head = {
		cells: headingConfig,
	};

	const rows = useMemo(
		() => getTableRows(tableDataArray, headingConfig),
		[tableDataArray, headingConfig],
	);

	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		experienceTracker.start({ name: COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE });
	}, [experienceTracker]);

	return (
		<>
			{error ? (
				<ExperienceFailure name={COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE} error={error} />
			) : (
				!loading && <ExperienceSuccess name={COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE} />
			)}
			<Stack space="space.200">
				<Box xcss={captionContainerStyles}>
					<FormattedMessage {...i18n.tableCaption} />
				</Box>
				<Box>
					<SearchFilter onChange={setSearchText} searchText={searchText} />
				</Box>
				<TableContainer>
					<HighlightContainer metric={isTableEmpty ? null : metricState.selectedMetric}>
						<DynamicTable
							head={head}
							rows={rows}
							defaultSortOrder="ASC"
							isLoading={loading}
							rowsPerPage={18}
						/>
						{error && (
							<Box xcss={centerAlignedTextStyles} paddingBlockEnd="space.1000">
								<FormattedMessage {...i18n.tableErrorMessage} />
							</Box>
						)}
						{isEmptySearchResult && (
							<Box xcss={centerAlignedTextStyles} paddingBlockEnd="space.1000">
								<FormattedMessage {...i18n.tableSearchEmptyResults} />
							</Box>
						)}
					</HighlightContainer>
				</TableContainer>
			</Stack>
		</>
	);
};
