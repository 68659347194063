import gql from 'graphql-tag';
import type { ApolloClient } from '@apollo/client';
import type { TypedDocumentNode } from '@apollo/client/core';

const ExtractContentAutoAttributesSubTypeQuery =
	// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming -- Read https://go/connie-relay-migration-fyi
	gql`
		query ExtractContentAutoAttributesQuery($contentId: ID!) {
			singleContent(id: $contentId) {
				subType
			}
		}
	`;

const convertToAnalyticsAttribute = (value: string | undefined | null): boolean | undefined => {
	if (value === null) {
		// A subType of `null` is used for non-live pages
		return false;
	} else if (value === 'live') {
		return true;
	} else {
		// Otherwise, subType was not found or the page had a subType other than 'live'.
		return undefined;
	}
};
const gracefullyReadFromCache = (
	client: ApolloClient<any>,
	contentId: string,
	query: TypedDocumentNode<any, { contentId: string }>,
) => {
	// Apollo's `readQuery` method will throw an error if ANY of the requested fields are missing.
	// In order to gracefully read each field from the cache, we query for them one at a time in a try/catch block.
	// We don't need to do any error handling/reporting because we just want to return undefined if an error occurs.
	let data;
	try {
		data = client.readQuery({
			query,
			variables: { contentId },
		});
	} catch {}
	return data;
};

export const extractContentAttributes = (client: ApolloClient<any>, contentId: string) => {
	const subTypeData = gracefullyReadFromCache(
		client,
		contentId,
		ExtractContentAutoAttributesSubTypeQuery,
	);

	return {
		isLivePage: convertToAnalyticsAttribute(subTypeData?.singleContent?.subType),
	};
};
