import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import type { ADNode } from '@atlaskit/editor-common/validator';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

import { LEARN_MORE_URL } from '@confluence/fabric-page-optin-migration';
import type { FlagsStateContainer } from '@confluence/flags';

import type { ExtensionParamsType } from '../extensionHandlerTypes';

const i18n = defineMessages({
	unmodifiableMacroDialogTitle: {
		id: 'fabric-extension-handlers.unmodifiable.macro.dialog.title',
		defaultMessage: 'This macro can’t be edited',
		description:
			'The title of the error flag that appears when a user clicks to edit the unmodifiable macro in the editor',
	},
	unmodifiableMacroDialogBody: {
		id: 'fabric-extension-handlers.unmodifiable.macro.dialog.body',
		defaultMessage:
			'This macro was added to a page that was converted from the legacy editor. It can’t be edited because it is now incompatible with the <alink>current editor</alink>.',
		description:
			'The body of the error flag that appears when a user clicks to edit the unmodifiable macro in the editor',
	},
});

export const showUnmodifiableMacroFlag = (
	selectedNode: ADNode,
	flags?: FlagsStateContainer,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) =>
	new Promise<ExtensionParamsType | void>((resolve, reject) => {
		if (!selectedNode || !selectedNode.attrs || !flags) {
			reject(new Error('Cannot show unmodifiable macro error flag'));
			return;
		}

		const fireLinkClickAnalytics = () => {
			if (createAnalyticsEvent) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'link',
						actionSubjectId: 'unmodifiableMacroDialogComponentLearnMoreLink',
						source: 'showUnmodifiableMacroFlag',
					},
				}).fire();
			}
		};

		void flags.showFlag({
			type: 'error',
			title: <FormattedMessage {...i18n.unmodifiableMacroDialogTitle} />,
			description: (
				<FormattedMessage
					{...i18n.unmodifiableMacroDialogBody}
					values={{
						alink: (...chunks) => (
							<a href={LEARN_MORE_URL} target="_blank" onClick={fireLinkClickAnalytics}>
								{chunks}
							</a>
						),
					}}
				/>
			),
			close: 'auto',
		});

		resolve();
	});
