import { LoadableAfterPaint } from '@confluence/loadable';

export const TemplateMigrationPreviewLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TemplateMigrationPreview" */ './TemplateMigrationPreview'
			)
		).TemplateMigrationPreview,
});
