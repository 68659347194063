import { fromJS, List } from 'immutable';

import { hasFeatureBeenDiscovered } from '@confluence/fabric-page-optin-migration/entry-points/hasFeatureBeenDiscovered';

// TODO: temporary to support the transition from the 'confluence-dashboard' plugin name
const updateFeature = (feature) => {
	if (feature.pluginKey.indexOf('confluence-dashboard') !== -1) {
		feature.pluginKey = feature.pluginKey.replace(
			'confluence-dashboard',
			'confluence-frontend-support',
		);
	}
	return feature;
};

export const defaultState = fromJS({
	hasLoaded: false,
	list: List([]),
});

export default function discoveredFeatures(state = defaultState, action) {
	switch (action.type) {
		case 'DISCOVERED_FEATURES_SUCCESS':
			const newList = List(action.response && action.response.map(updateFeature));

			return state.withMutations((state) => {
				state.set('hasLoaded', true);

				// merge 2 list
				const currentList = state.get('list');
				const updatedList = currentList.concat(
					newList.filter((item) => !hasFeatureBeenDiscovered(currentList, item)),
				);

				state.set('list', updatedList);
				return state;
			});

		case 'FEATURE_DISCOVERED_MARK_REQUEST':
		case 'FEATURE_DISCOVERED_MARK_SUCCESS':
			// there is no success action dispatch for mark feature discover
			// because the POST request does not return any response
			// this change is necessary to update the state after the action happens
			// and mark that the feature has been discovered
			const alreadyDiscovered = hasFeatureBeenDiscovered(state.get('list'), action);

			if (alreadyDiscovered) {
				return state;
			} else {
				const newEntry = {
					pluginKey: action.pluginKey,
					featureKey: action.featureKey,
				};
				return state.set('list', state.get('list').push(newEntry));
			}

		default:
			return state;
	}
}
