import React from 'react';
import { type NativeStackProps, type RenderFn } from '@atlassian/forge-ui-types';
import { spaceMap } from '../utils/spaceMap';

const flexGrowMap = {
	hug: { flexGrow: 0 },
	fill: {
		width: '100%',
		flexGrow: 1,
	},
};

export const Stack = ({ forgeDoc, render }: Parameters<RenderFn>[0]) => {
	const { alignBlock, alignInline, space, spread, grow } = forgeDoc.props as NativeStackProps;

	const justifyContent = spread || alignBlock;
	const growProps = grow ? flexGrowMap[grow] : undefined;
	const gap = space && spaceMap[space];

	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'flex',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				flexDirection: 'column',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				gap,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				justifyContent: justifyContent,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				alignItems: alignInline,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...growProps,
			}}
		>
			{forgeDoc.children.map(render)}
		</div>
	);
};
