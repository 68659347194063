import type { DefinitionNode, DocumentNode, OperationDefinitionNode } from 'graphql';

const QUERY_NAME_MATCHER = /query\s+(\w+).*?\{/;

export const UNEXPECTED_RESPONSE = { data: null };

function isOperationDefinition(definition: DefinitionNode): definition is OperationDefinitionNode {
	return definition.kind === 'OperationDefinition';
}

export function getOperationName(query: string | DocumentNode): string | null | undefined {
	if (typeof query === 'string') {
		const match = query.match(QUERY_NAME_MATCHER);
		if (match) return match[1];
	} else {
		// graphql-tag tagged AST:
		return (query.definitions || [])
			.filter(isOperationDefinition)
			.map((definition) => definition.name)
			.filter((name) => name != null)[0]?.value;
	}

	return undefined;
}
