// Please only keep this import as a *type import* to avoid bringing in the entire lib
import type { ApolloError } from 'apollo-client';

import {
	ConfluenceRestApiError,
	BadStatusError,
	getTraceIdFromResponse,
} from '@confluence/network';

function isApolloError(error: unknown): error is ApolloError {
	// theoretically this could be an `instanceof ApolloError` check, but bringing another
	// dependency to this package for the sake of this single check seems like an overkill
	return (
		typeof error === 'object' &&
		error !== null &&
		('graphQLErrors' in error || 'networkError' in error)
	);
}

export type ReactErrorAttributes = {
	componentStack: string;
	errorHashCode: string;
};

const REACT_ERROR_CONTEXT_SYMBOL = Symbol('React error context');

/**
 * Sets the error context for exceptions caught by `ErrorBoundary`-like components.
 * This context will later be used if the error is logged through `MonitoringClient`.
 *
 * @param {Error} error - the error that was caught by the boundary.
 * @param {ReactErrorAttributes} context - the context of the error.
 */
export function setReactErrorAttributes(error: Error | null, context: ReactErrorAttributes): void {
	if (typeof error === 'object' && error !== null) {
		error[REACT_ERROR_CONTEXT_SYMBOL] = context;
	}
}

export function getReactErrorAttributes(error: Error | null): ReactErrorAttributes | undefined {
	if (typeof error === 'object' && error !== null) {
		return error[REACT_ERROR_CONTEXT_SYMBOL];
	}
	return undefined;
}

export type GraphqlErrorAttributes = {
	operationName: string;
	traceId?: string | null;
	statusCode?: number;
	graphqlPath?: string;
};

const GRAPHQL_ERROR_CONTEXT_SYMBOL = Symbol('Graphql error context');

/**
 * Sets the error context for Graphql exceptions. This method will most likely be used
 * by an `ApolloLink` that first sees the error.
 * This context will later be used if the error is logged through `MonitoringClient`.
 *
 * @param {Error} error - the error that was received from Graphql layer.
 * @param {GraphqlErrorAttributes} context - the context of the error.
 */
export function setGraphqlErrorAttributes(
	error: Error | null,
	context: GraphqlErrorAttributes,
): void {
	if (typeof error === 'object' && error !== null) {
		error[GRAPHQL_ERROR_CONTEXT_SYMBOL] = context;
	}
}

export function getGraphqlErrorAttributes(error: Error | null): GraphqlErrorAttributes | undefined {
	if (typeof error !== 'object' || error === null) {
		return undefined;
	}

	if (error[GRAPHQL_ERROR_CONTEXT_SYMBOL]) {
		return error[GRAPHQL_ERROR_CONTEXT_SYMBOL];
	}

	// Currently the graphql error context is set on the first entry of the `graphqlErrors`
	// property of `ApolloError`. That way, `ApolloError` itself does not have the context
	// set properly. This method is a measure to overcome this and make sure that
	// we are able to retrieve the context correctly.
	return getGraphqlErrorAttributesFromApolloError(error);
}

function getGraphqlErrorAttributesFromApolloError(
	error: Error,
): GraphqlErrorAttributes | undefined {
	if (!isApolloError(error)) {
		return undefined;
	}

	const graphqlErrors = error.graphQLErrors;

	// the context is populated by next/packages/graphql/src/links/OnErrorLink.ts
	if (graphqlErrors && graphqlErrors.length > 0 && graphqlErrors[0][GRAPHQL_ERROR_CONTEXT_SYMBOL]) {
		return graphqlErrors[0][GRAPHQL_ERROR_CONTEXT_SYMBOL];
	}

	if (error.networkError) {
		return error.networkError[GRAPHQL_ERROR_CONTEXT_SYMBOL];
	}

	return undefined;
}

export type SlaErrorAttributes = {
	failedSlaExperience: string;
	experienceId: string;
};

const SLA_ERROR_CONTEXT_SYMBOL = Symbol('SLA error context');

export function getSlaErrorAttributes(error: Error | null): SlaErrorAttributes | undefined {
	if (typeof error === 'object' && error !== null) {
		return error[SLA_ERROR_CONTEXT_SYMBOL];
	}
	return undefined;
}

/**
 * Sets the error context for errors that result in "taskFail" of SLA experiences.\
 * This context will later be used if the error is logged through `MonitoringClient`.
 *
 * @param error - the error that experience was failed with
 * @param context - the context of the error
 *
 * @see ExperienceTracker.stopOnError
 */
export function setSlaErrorAttributes(error: Error | null, context: SlaErrorAttributes) {
	if (typeof error === 'object' && error !== null) {
		error[SLA_ERROR_CONTEXT_SYMBOL] = context;
	}
}

export type BadStatusErrorAttributes = {
	statusCode: number;
	traceId?: string | null;
};

function getBadStatusErrorAttributes(error: unknown): BadStatusErrorAttributes | undefined {
	if (!(error instanceof BadStatusError)) {
		return undefined;
	}

	return {
		statusCode: error.response.status,
		traceId: getTraceIdFromResponse(error.response),
	};
}

export type ConfluenceRestApiErrorAttributes = {
	statusCode: number;
	restMethod: string;
	restRoute?: string;
	traceId?: string | null;
};

function getConfluenceRestApiErrorAttributes(
	error: unknown,
): ConfluenceRestApiErrorAttributes | undefined {
	if (!(error instanceof ConfluenceRestApiError)) {
		return undefined;
	}

	return {
		statusCode: error.statusCode,
		restMethod: error.method,
		restRoute: error.route,
		traceId: error.traceId,
	};
}

export type ContentErrorAttributes = {
	objectId?: string | null;
	containerId?: string | null;
};

const CONTENT_ERROR_CONTEXT_SYMBOL = Symbol('Content error context');

export function setContentErrorAttributes(
	error: Error | null,
	context: ContentErrorAttributes,
): void {
	if (typeof error === 'object' && error !== null) {
		error[CONTENT_ERROR_CONTEXT_SYMBOL] = context;
	}
}

export function getContentErrorAttributes(error: Error | null): ContentErrorAttributes | undefined {
	if (typeof error === 'object' && error !== null) {
		return error[CONTENT_ERROR_CONTEXT_SYMBOL];
	}
	return undefined;
}

export type LoadableErrorAttributes = {
	loadableIds?: string[] | null;
};

const LOADABLE_ERROR_CONTEXT_SYMBOL = Symbol('Loadable error context');

export function setLoadableErrorAttributes(
	error: Error | null,
	context: LoadableErrorAttributes,
): void {
	if (typeof error === 'object' && error !== null) {
		error[LOADABLE_ERROR_CONTEXT_SYMBOL] = context;
	}
}

export function getLoadableErrorAttributes(
	error: Error | null,
): LoadableErrorAttributes | undefined {
	if (typeof error === 'object' && error !== null) {
		return error[LOADABLE_ERROR_CONTEXT_SYMBOL];
	}
	return undefined;
}

export type LogSafeErrorAttributes = Partial<
	ReactErrorAttributes &
		GraphqlErrorAttributes &
		SlaErrorAttributes &
		ConfluenceRestApiErrorAttributes &
		BadStatusErrorAttributes &
		ContentErrorAttributes
>;

export function getTraceIdFromApolloError(error: ApolloError): string | null {
	const traceId =
		getGraphqlErrorAttributes(error)?.traceId ||
		getGraphqlErrorAttributes(error?.graphQLErrors?.[0])?.traceId;
	if (traceId) {
		return traceId;
	}

	// handle experimental query error
	if (error.graphQLErrors?.[0].originalError instanceof BadStatusError) {
		return error.graphQLErrors[0].originalError?.traceId;
	}

	return null;
}

export function getLogSafeErrorAttributes(error: unknown): LogSafeErrorAttributes {
	if (!error || typeof error !== 'object') {
		return {};
	}

	return {
		...getReactErrorAttributes(error as Error),
		...getGraphqlErrorAttributes(error as Error),
		...getSlaErrorAttributes(error as Error),
		...getContentErrorAttributes(error as Error),
		...getLoadableErrorAttributes(error as Error),
		...getConfluenceRestApiErrorAttributes(error),
		...getBadStatusErrorAttributes(error),
	};
}

declare global {
	interface Error {
		[GRAPHQL_ERROR_CONTEXT_SYMBOL]?: GraphqlErrorAttributes;
		[REACT_ERROR_CONTEXT_SYMBOL]?: ReactErrorAttributes;
		[SLA_ERROR_CONTEXT_SYMBOL]?: SlaErrorAttributes;
		[CONTENT_ERROR_CONTEXT_SYMBOL]?: ContentErrorAttributes;
		[LOADABLE_ERROR_CONTEXT_SYMBOL]?: LoadableErrorAttributes;
	}
}
