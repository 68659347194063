/* eslint-disable check-file/filename-blocklist */
export enum LoadingPriority {
	PAINT = 0,
	// setting hover priority higher than after paint because there could be after paint components inside hover
	// and that can mess up the hierarchy order of components
	HYDRATE_ON_HOVER = 1,
	AFTER_PAINT = 2,
	LAZY = 3,
	BACKGROUND = 4,
	MAX_PRIORITY = BACKGROUND,
}

export const NOOP = () => null;
