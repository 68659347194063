import { Map } from 'immutable';

export function dashboard(state = Map({}), action) {
	switch (action.type) {
		case 'GET_EXPERIMENT_FEATURES_SUCCESS':
		case 'DASHBOARD_VIEW_MODEL_SUCCESS':
			return state.merge({
				...action.response,
			});
		default:
			return state;
	}
}
