import gql from 'graphql-tag';

export const HomeWidgetsQuery = gql`
	query HomeWidgetsQuery {
		userPreferences {
			homeWidgets {
				id
				state
				__typename
			}
			__typename
		}
	}
`;
