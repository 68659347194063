/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useEffect, useCallback } from 'react';
import type ApolloClient from 'apollo-client';
import {
	type Extension,
	RendererNext,
	makeAvatar,
	makeAvatarStack,
	makeUserPicker,
	useWebRuntime,
	makeThreeLOPrompt,
	AkButton,
	useButton,
	type RenderFn,
} from '@atlassian/forge-ui/ui';

import { type DispatchEffect, type CoreData, type ExtensionData } from '@atlassian/forge-ui-types';
import { token } from '@atlaskit/tokens';
import { isPdfExportEntrypoint } from '../../utils/pdfEntrypointUtils';

interface UIKitOneRendererProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	extension: Extension;
	extensionData: ExtensionData;
	extensionId: string;
	extensionTitle?: string;
}

export function ButtonWithBackground(props: Parameters<RenderFn>[0]) {
	const { akButtonProps } = useButton(props);
	return (
		<div
			// The button background is needed to prevent any coloured
			// background behind an app from showing through the button.
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				backgroundColor: token('color.background.neutral', 'white'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				width: akButtonProps.shouldFitContainer ? '100%' : 'max-content',
				borderRadius: '3px',
			})}
		>
			<AkButton {...akButtonProps} />
		</div>
	);
}

export const UIKitOneRenderer = ({
	accountId,
	apolloClient,
	contextIds,
	coreData,
	extension,
	extensionData,
	extensionId,
	extensionTitle,
}: UIKitOneRendererProps) => {
	const entryPoint = isPdfExportEntrypoint(extension) ? 'export' : '';

	const { cloudId } = coreData;
	const { config } = extensionData;

	const [dispatch, { forgeDoc, error, loading }] = useWebRuntime({
		apolloClient,
		contextIds,
		extensionId,
		coreData,
		entryPoint,
	});

	const curriedDispatch = useCallback(
		(effect: DispatchEffect) => {
			return dispatch({
				...effect,
				extensionData: {
					...effect.extensionData,
					...extensionData,
					isConfig: false,
				},
				extensionPayload: {
					...effect.extensionPayload,
					config,
				},
			});
		},
		[dispatch, config, extensionData],
	);

	useEffect(() => {
		curriedDispatch({
			type: 'render',
			extensionData: {},
		});
	}, [curriedDispatch]);

	return (
		<RendererNext
			forgeDoc={forgeDoc}
			loading={loading}
			error={error}
			dispatch={curriedDispatch}
			components={(defaultComponents) => ({
				...defaultComponents,
				Button: (args) => <ButtonWithBackground {...args} />,
				Avatar: makeAvatar({ client: apolloClient }),
				AvatarStack: makeAvatarStack({ client: apolloClient }),
				ThreeLOPrompt: makeThreeLOPrompt({ appName: extensionTitle }),
				UserPicker: makeUserPicker({
					client: apolloClient,
					accountId,
					cloudId,
					productKey: 'confluence',
				}),
			})}
			extension={extension ?? {}}
		/>
	);
};
