import { mapEventType } from '../../helper/map-event-type';
import type {
	BaseMetricDataWithStartAndStop,
	BasePageLoadMetricDataWithStartAndStop,
	PerformanceEventConfig,
	ShareableGlobalConfig,
} from '../../types';

export const eventKey = (
	config: PerformanceEventConfig,
	_data: BaseMetricDataWithStartAndStop | BasePageLoadMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
) => {
	return {
		'event:key': `${globalConfig.product}.fe.${mapEventType(config.type)}.${config.key}`,
	};
};
