import { useEffect, useState, useRef } from 'react';
import { useCallbackOne as useCallback, useMemoOne as useMemo } from 'use-memo-one';
import type ApolloClient from 'apollo-client';
import deepEqual from 'fast-deep-equal';
import {
	type CoreData,
	type ForgeDoc,
	type DispatchEffect,
	type Dispatch,
	type ClientEffect,
	type LegacyClientEffect,
	type ResultEffect,
	type LegacyRenderEffect,
	type CoreDataInner,
	type ForgeContextToken,
} from '@atlassian/forge-ui-types';
import { OPERATIONAL_EVENT_TYPE, UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { FORGE_UI_ANALYTICS_CHANNEL } from '../analytics';
import { invokeAuxEffectsMutation } from '../web-client';
import { type GQLInvokeAuxEffectsResponse, type GQLEffect } from '../web-client/graphql/types';
import { parseExtensionId } from '../utils';

interface MutationData {
	invokeAuxEffects: GQLInvokeAuxEffectsResponse;
}

interface WebRuntimeProps {
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	extensionId: string;
	coreData: CoreData;
	entryPoint?: string;
	/**
	 * This is required if the product is not relying on the platform to generate a Forge Context Token.
	 * This function should check the expiry date on any existing FCT, and if it is expired,
	 * then generate a new FCT, or if it is still valid, should return it.
	 */
	getContextToken?: () => Promise<string>;
	resetStateOnExtensionDataChange?: boolean;
}

function isRenderEffect(effect: GQLEffect): effect is LegacyRenderEffect {
	return effect.type === 'render' && !!effect.aux && !!effect.state;
}

function isResultEffect(effect: GQLEffect): effect is ResultEffect {
	return effect.type === 'result' && !!effect.forgeDoc && !!effect.state;
}

export function isClientEffect(effect: GQLEffect): effect is LegacyClientEffect | ClientEffect {
	return isRenderEffect(effect) || isResultEffect(effect);
}

export function isArrayOfClientEffects(
	effects: GQLEffect[],
): effects is (LegacyClientEffect | ClientEffect)[] {
	return effects.every(isClientEffect);
}
interface GQLInvokeAuxEffectsResponseSucceeded extends GQLInvokeAuxEffectsResponse {
	result: NonNullable<GQLInvokeAuxEffectsResponse['result']>;
	success: true;
}

function isGQLInvokeAuxEffectsNetworkFailure(response: GQLInvokeAuxEffectsResponse): boolean {
	return typeof response.success === 'undefined';
}

function isGQLInvokeAuxEffectsResponseSucceeded(
	response: GQLInvokeAuxEffectsResponse,
): response is GQLInvokeAuxEffectsResponseSucceeded {
	return response.success;
}

const downgradeEffect = (effect: DispatchEffect) => {
	if (effect.type === 'render') {
		return { type: 'initialize' };
	} else if (effect.type === 'event') {
		return {
			type: 'event',
			handler: effect.handler,
			args: effect.args,
		};
	}
};

const getRuntimeStateError = (response: GQLInvokeAuxEffectsResponse) => {
	const { result, errors } = response;

	if (isGQLInvokeAuxEffectsNetworkFailure(response)) {
		return 'Unexpected error occurred. Try refreshing your browser.';
	} else if (!isGQLInvokeAuxEffectsResponseSucceeded(response)) {
		const error = errors?.[0];
		const statusCode = error?.extensions.statusCode;
		const message = error?.message as string;

		if (statusCode === 400 || statusCode === 500) {
			if (!result?.effects) {
				return message;
			} else if (!isArrayOfClientEffects(result.effects)) {
				return 'received a non-client effect';
			}
			return undefined;
		}
		return message;
	} else if (!!result && !isArrayOfClientEffects(result.effects)) {
		return 'received a non-client effect';
	}
	return undefined;
};

function useIsUnmountedRef() {
	const isUnmountedRef = useRef(false);
	useEffect(() => {
		return () => {
			isUnmountedRef.current = true;
		};
	}, []);
	return isUnmountedRef;
}

interface RuntimeState {
	forgeDoc: ForgeDoc | undefined;
	appTime: number;
	loading: boolean;
	error: string | undefined;
}

export const useWebRuntime = ({
	apolloClient,
	contextIds,
	extensionId,
	coreData,
	entryPoint,
	getContextToken,
	resetStateOnExtensionDataChange,
}: WebRuntimeProps) => {
	const [{ forgeDoc, appTime, error, loading }, setRuntimeState] = useState<RuntimeState>({
		forgeDoc: undefined,
		appTime: 0,
		error: undefined,
		loading: false,
	});

	const previousProps = useRef({
		extensionId,
		entryPoint,
	});

	const extensionData = useRef({});
	const appState = useRef({});

	if (!deepEqual(previousProps.current, { extensionId, entryPoint })) {
		appState.current = {};
		previousProps.current = { extensionId, entryPoint };
	}

	const contextToken = useRef<ForgeContextToken | undefined>(undefined);
	const isUnmountedRef = useIsUnmountedRef();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// TODO: remove this event once we have switched over to the new event schema
	// https://ecosystem-platform.atlassian.net/browse/EXT-2196
	useEffect(() => {
		createAnalyticsEvent({
			eventType: UI_EVENT_TYPE,
			data: {
				action: 'viewed',
				actionSubject: 'forgeUIExtension',
				actionSubjectId: 'editorMacro',
			},
		}).fire(FORGE_UI_ANALYTICS_CHANNEL);
	}, [createAnalyticsEvent]);

	/* eslint-disable */
	/* prettier-ignore */
	// Disabled to prevent eslint auto-fixing these hooks.
	// The autofix breaks usages like: useWebRuntime({ coreData: { localId, cloudId } })
	const memoedContextIds = useMemo(() => contextIds, [
    contextIds.sort().join(),
  ]);

	const { moduleKey } = parseExtensionId(extensionId);
	const siteUrl = window.location.origin;

	const memoedCoreData: CoreDataInner = useMemo(
		() => ({ ...coreData, moduleKey, siteUrl }),
		[coreData.localId, coreData.cloudId, moduleKey, siteUrl],
	);

	/* eslint-enable */
	const dispatch = useCallback(
		async (effect: DispatchEffect): Promise<void> => {
			performance.mark(`forge-ui.fe.render-start-${memoedCoreData.localId}`);
			switch (effect.type) {
				case 'event': /* eslint-disable-line no-fallthrough */
				case 'render': {
					if (resetStateOnExtensionDataChange) {
						if (!deepEqual(effect.extensionData, extensionData.current)) {
							appState.current = {};
						}
					}
					extensionData.current = effect.extensionData;

					setRuntimeState(({ forgeDoc, appTime, error }) => ({
						forgeDoc,
						appTime,
						error,
						loading: true,
					}));

					try {
						const mutationResult = await apolloClient.mutate<MutationData>({
							mutation: invokeAuxEffectsMutation,
							variables: {
								input: {
									contextIds: memoedContextIds,
									extensionId,
									entryPoint,
									payload: {
										state: appState.current,
										context: {
											...memoedCoreData,
											extensionData: effect.extensionData,
										},
										extensionPayload: effect.extensionPayload,
										contextToken: getContextToken
											? await getContextToken()
											: contextToken.current?.jwt,
										effects: [downgradeEffect(effect)],
										config: effect.extensionData?.config,
									},
								},
							},
						});

						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						const response = mutationResult.data!.invokeAuxEffects || {};
						const { result } = response;

						contextToken.current = result?.contextToken;

						setRuntimeState(({ forgeDoc }) => ({
							forgeDoc: (() => {
								if (!result || !result.effects[0]) {
									return forgeDoc;
								}
								const resultEffect = result.effects[0];
								appState.current = resultEffect.state;
								return resultEffect.aux;
							})(),
							appTime: result?.metrics?.appTimeMs || 0,
							error: getRuntimeStateError(response),
							loading: false,
						}));

						if (!isUnmountedRef.current) {
							requestAnimationFrame(() => {
								setTimeout(() => {
									performance.mark(`forge-ui.fe.render-end-${memoedCoreData.localId}`);
									performance.measure(
										'forge-ui.fe.render',
										`forge-ui.fe.render-start-${memoedCoreData.localId}`,
										`forge-ui.fe.render-end-${memoedCoreData.localId}`,
									);
								});
							});

							// this tracks every time an app is rendered
							createAnalyticsEvent({
								eventType: OPERATIONAL_EVENT_TYPE,
								data: {
									action: 'rendered',
									actionSubject: 'forge.ui.renderer',
									attributes: {
										target: 'useWebRuntime',
									},
									tags: ['forge'],
								},
							}).fire(FORGE_UI_ANALYTICS_CHANNEL);
						}
					} catch (error) {
						setRuntimeState(({ forgeDoc, appTime }) => ({
							forgeDoc,
							appTime,
							error: error && (error as any).message,
							loading: false,
						}));
					}
					return;
				}
				default: {
					setRuntimeState(({ forgeDoc, appTime, loading }) => ({
						forgeDoc,
						appTime,
						error: `Internal Error: Don't know how to handle effect ${effect}.`,
						loading,
					}));
				}
			}
		},
		[
			apolloClient,
			memoedContextIds,
			extensionId,
			appState,
			memoedCoreData,
			isUnmountedRef,
			entryPoint,
			getContextToken,
			resetStateOnExtensionDataChange,
			createAnalyticsEvent,
		],
	);

	Object.freeze(forgeDoc);

	return [dispatch, { forgeDoc, appTime, loading, error }] as [
		Dispatch,
		{
			forgeDoc: ForgeDoc | undefined;
			appTime: number;
			loading: boolean;
			error: string | undefined;
		},
	];
};
