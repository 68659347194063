export type Subscription = {
	unsubscribe(): void;
};

// This prefix is also used in ConfigPanelFieldsLoader.tsx in the
// @atlaskit/editor-plugin-extension package. The Editor package is public, so it
// cannot import this constant from @atlassian/forge-ui. Do not update this prefix
// without also doing so in the Editor package.
const EVENT_PREFIX = 'forge.bridge.';

export type ExtensionDetails = {
	id: string;
	installationId?: string;
};

export function on(
	event: string,
	callback: (payload?: any, extension?: ExtensionDetails) => Promise<any>,
	extension?: ExtensionDetails,
): Subscription {
	const wrappedCallback = (e: any) => {
		if (
			!extension ||
			!e.detail?.extension ||
			(extension.id === e.detail?.extension?.id &&
				extension.installationId === e.detail?.extension?.installationId)
		) {
			callback(e.detail?.payload, e.detail?.extension);
		}
	};
	window.document.addEventListener(`${EVENT_PREFIX}${event}`, wrappedCallback);

	return {
		unsubscribe: () =>
			window.document.removeEventListener(`${EVENT_PREFIX}${event}`, wrappedCallback),
	};
}

export function emit(event: string, payload?: any, extension?: ExtensionDetails): void {
	const newEvent = new CustomEvent(`${EVENT_PREFIX}${event}`, {
		detail: {
			extension,
			payload,
		},
	});

	window.document.dispatchEvent(newEvent);
}
