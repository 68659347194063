import {
	GQLExtensionContextsFilterType,
	type UseExtensionListOptions,
} from '@atlassian/forge-ui/provider';

import { getAGGClient } from '@confluence/graphql';

import { getWorkspaceARI } from './useWorkspaceARI';

export const getUseExtensionListOptions = ({
	cloudId,
	activationId,
	locale,
	dataClassificationTags,
	hasAccessNarrowingSupport,
	expandAppOwner,
	moduleType,
	queryOptions,
	skip,
	skipFilter,
}) => {
	const workspaceARI = getWorkspaceARI({ cloudId, activationId });

	const options: UseExtensionListOptions = {
		client: getAGGClient(),
		contextIds: [workspaceARI],
		type: moduleType,
		queryOptions: {
			...queryOptions,
			skip,
		},
		expandAppOwner,
		locale,
	};

	// Passing the `filter` key will use the newer extension() resolver over the extensionByType() resolver
	// Going forward we to use the newer resolver
	// IMPORTANT NOTE: For some reason the addition of using filter (uses newer Forge resolver) is causing SSR to timeout when calling
	// preloadUseExtensionList with the FORGE_MODULE_BYLINE extension point. Creating this workaround by skipping the filter - be careful to test before removing this flag.
	// TODO: replace with new resolver  https://product-fabric.atlassian.net/browse/CCECO-3476
	if (hasAccessNarrowingSupport && !skipFilter) {
		options.filter = [{ type: GQLExtensionContextsFilterType.ExtensionType, value: [moduleType] }];
		options.dataClassificationTagIds = dataClassificationTags || [];
	}

	return options;
};
