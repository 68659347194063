// Note that the prop `templateAreas` is removed as it requires child components to use `style`

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::2fce58c49cdbe929afb174115d9a6133>>
 * @codegenId #grid
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen grid
 * @codegenParams { "props": { "children": true, "as": false, "justifyContent": true, "justifyItems": true, "alignItems": true, "alignContent": true, "columnGap": true, "gap": true, "rowGap": true, "autoFlow": true, "templateRows": true, "templateColumns": true, "templateAreas": false, "id": true, "testId": true, "data-testid": false, "role": true, "xcss": false } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/grid.tsx <<SignedSource::b44e39814ae7b233b7d04abd627094ee>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::2f4848af544e0f64968f7c28012cf0f0>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::00aab072ec1c97fd176380fcd46ccf7e>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::a91bf7842593d9052c045e4a40d7ab3b>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Grid as PlatformGrid } from '@atlaskit/primitives';

type PlatformGridProps = React.ComponentProps<typeof PlatformGrid>;

export type GridProps = Pick<
  PlatformGridProps,
  'children' | 'justifyContent' | 'justifyItems' | 'alignItems' | 'alignContent' | 'columnGap' | 'gap' | 'rowGap' | 'autoFlow' | 'templateRows' | 'templateColumns' | 'id' | 'testId' | 'role'
>;

export const Grid = (props: Parameters<RenderFn>[0]) => {
  const {
    justifyContent,
    justifyItems,
    alignItems,
    alignContent,
    columnGap,
    gap,
    rowGap,
    autoFlow,
    templateRows,
    templateColumns,
    id,
    testId,
    role,
  } = props.forgeDoc.props as GridProps;
  return (
    <PlatformGrid
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignItems={alignItems}
      alignContent={alignContent}
      columnGap={columnGap}
      gap={gap}
      rowGap={rowGap}
      autoFlow={autoFlow}
      templateRows={templateRows}
      templateColumns={templateColumns}
      id={id}
      testId={testId}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
