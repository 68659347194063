import { PostOfficeRouteContext, useStableObject } from '@atlassian/post-office-context';

import type { RouteProviderProps } from './types';
import { useLocationHref } from '../../hooks/useLocation';
import type { StableProvider } from '../../types';

export const StableRouteProvider: StableProvider<RouteProviderProps> = ({ route, children }) => {
	const href = useLocationHref();

	const stableRoute = useStableObject(
		route ?? {
			previous: href,
			current: href,
		},
	);

	return (
		<PostOfficeRouteContext.Provider value={stableRoute}>
			{children}
		</PostOfficeRouteContext.Provider>
	);
};
