import type { ADFEntity } from '@atlaskit/adf-utils/types';

import type { ContentConverterQueryVariables } from './ContentConverter/__types__/ContentConverterQuery';
import { serializeExtensionADF } from './serializeExtensionADF';

type ContentConverterParams = {
	content: ADFEntity['content'] | string | object;
	contentId: string;
};

export const getContentConverterVariables = ({
	content,
	contentId,
}: ContentConverterParams): ContentConverterQueryVariables => {
	const contentValue = content || [];
	const contentDocument = {
		type: 'doc',
		content: Array.isArray(contentValue) ? contentValue : [contentValue],
		version: 1,
	};
	const contentDocumentAdf = serializeExtensionADF(contentDocument);
	return {
		content: contentDocumentAdf,
		from: 'atlas_doc_format',
		to: 'storage',
		contentId,
	};
};
