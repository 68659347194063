import { SSRMeasures } from '@confluence/action-measures';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { preloadEditButton } from '@confluence/edit-button/entry-points/preloadEditButton';
import { preloadWatchDialog } from '@confluence/watch-dialog/entry-points/preloadWatchDialog';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { preloadSpaceStarTextButton } from './preloadSpaceStarTextButton';

export type preloadGroupedButtonsProps = {
	contentId: string;
	spaceKey: string;
	userId: string | null;
	isLicensed: boolean;
	shouldPreloadUsingSpaceKey?: boolean;
};

export function preloadGroupedButtons({
	contentId,
	spaceKey,
	userId,
	isLicensed = false,
}: preloadGroupedButtonsProps) {
	/**
	 * Watch dialog button:
	 * next/packages/ssr-app/src/module-replacements/@confluence/watch-dialog.js
	 *
	 * "..." button:
	 * See next/packages/ssr-app/src/module-replacements/@confluence/content-tools.js
	 *
	 * Share button:
	 * See next/packages/ssr-app/src/module-replacements/@confluence/share.js
	 */
	return SSRMeasures.run('ssr-app/prepare/preloadQuery/fetch:pageButtons', async () => {
		const tasks: Promise<any>[] = [preloadEditButton({ spaceKey, contentId })];
		const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
		if (!isCompanyHub) {
			// Determine whether to show restriction and share button
			tasks.push(
				preloadWebItemLocation({
					location: 'system.content.button',
					// Special case when we know the content id the code in SpaceOverviewGroupedButtons only cares about content id
					...(contentId ? { contentId } : { spaceKey }),
				}),
				preloadWatchDialog({
					spaceKey,
					isLicensed,
					userId,
					contentId,
				}),
				preloadSpaceStarTextButton(spaceKey),
			);
		}

		return Promise.all(tasks);
	});
}
