import isEqual from 'lodash/isEqual';
import { createSelectorCreator, createSelector, defaultMemoize } from 'reselect';
import { Set } from 'immutable';

export const createSpacesOnlyFromIdsSelector = () => {
	const selectorCreator = createSelectorCreator(defaultMemoize, isEqual);

	return selectorCreator(
		(ids) => ids,
		(ids, state) => state.entities.get('spaces'),
		(ids, spaces) => {
			if (!ids || !ids.size) {
				return Set([]);
			}

			return ids.map((id) => (id ? spaces.get(`${id}`) : null));
		},
	);
};

export const getSpaceHomePageId = createSelector(
	(spaceKey) => spaceKey,
	(spaceKey, state) => state.entities.get('spaces'),
	(spaceKey, spaces) => spaces.getIn([spaceKey, 'homepageId'], null),
);

export const getSpaceInfoSelector = createSelector(
	[(state) => state.entities.get('spaces'), (state, spaceKey) => spaceKey],
	(spaces, spaceKey) => spaces.get(spaceKey, null),
);
