import React, { memo } from 'react';
import { styled } from '@compiled/react';

import { AVATAR_SIZES } from '@atlaskit/avatar';

import type { IconSize } from './types';
import { iconStyles, loadingBorderRadius } from './TemplateIconConstants';

type TemplateLegacyIconTemplate = {
	styleClass: string | null;
	itemModuleCompleteKey: string | null;
	blueprintModuleCompleteKey?: string | null;
	contentBlueprintId?: string | null;
};

type TemplateLegacyIconProps = {
	template: TemplateLegacyIconTemplate;
	size?: IconSize;
};

type StyleClassIconProps = {
	size?: IconSize;
};

// We need to preserve the HTML structure from the Create Dialog for 3rd party blueprints that use a styleclass
// for their icon, as the corresponding CSS selectors rely on this structure
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const StyleClassIconWrapper = styled.li(iconStyles, {
	listStyleType: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const StyleClassIcon = styled.span<StyleClassIconProps>(iconStyles, {
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: `${(props) => AVATAR_SIZES[props.size || 'medium']}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: `${(props) => AVATAR_SIZES[props.size || 'medium']}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: loadingBorderRadius,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundSize: `${(props) => AVATAR_SIZES[props.size || 'medium']}px ${(
		props,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	) => AVATAR_SIZES[props.size || 'medium']}px !important`,
});

// The avatar-item component, which actually renders TemplateLegacyIcon, passes a backgroundColor
// prop to the TemplateLegacyIcon. The TemplateLegacyIcon disregards this backgroundColor
// prop. Therefore, we can ignore the backgroundColor prop for memoization.
const areEqual = (prevProps, nextProps) => {
	return prevProps.template === nextProps.template;
};

export const TemplateLegacyIcon = memo(({ template, size }: TemplateLegacyIconProps) => {
	const styleClass = template.styleClass || '';
	const itemModuleCompleteKey = template.itemModuleCompleteKey || '';
	const blueprintModuleCompleteKey = template.blueprintModuleCompleteKey || '';
	const contentBlueprintId = template.contentBlueprintId || '';

	return (
		<StyleClassIconWrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="template"
			data-item-module-complete-key={itemModuleCompleteKey}
			data-blueprint-module-complete-key={blueprintModuleCompleteKey}
			data-content-blueprint-id={contentBlueprintId}
			data-testid="template-icon-legacy"
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<StyleClassIcon size={size} className={styleClass} />
		</StyleClassIconWrapper>
	);
}, areEqual);
