import React, { type ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import type {
	ActiveTriggerState,
	SetTriggerStateParams,
	TriggerContextDispatchProps,
	TriggerContextProps,
	UseFirePostOfficeTrigger,
	UseTrigger,
} from './types';

const initialActiveTriggerState: TriggerContextProps = {
	activeTriggerState: {
		activeTriggerId: undefined,
	},
};

const initialTriggerDispatchState: TriggerContextDispatchProps = {
	setTriggerState: () => {
		// eslint-disable-next-line no-console
		console.error(
			'No Post Office Trigger Context found. Did you remember to add a Post Office Provider?',
		);
	},
};

const TriggerContext = React.createContext<TriggerContextProps>(initialActiveTriggerState);

const TriggerDispatchContext = React.createContext<TriggerContextDispatchProps>(
	initialTriggerDispatchState,
);

export const TriggerContextProvider = ({ children }: { children: ReactNode }) => {
	const [activeTriggerState, setActiveTriggerState] = useState<ActiveTriggerState>(
		initialActiveTriggerState.activeTriggerState,
	);

	const setTriggerState = useCallback<(params: SetTriggerStateParams) => void>(
		({ isActive, triggerId }) => {
			setActiveTriggerState((current) => {
				const hasStateChanged =
					current.activeTriggerId !== triggerId ||
					(current.activeTriggerId === triggerId && isActive === false);
				if (!hasStateChanged) {
					return current;
				}

				if (isActive) {
					return { activeTriggerId: triggerId };
				}

				return { activeTriggerId: undefined };
			});
		},
		[],
	);

	const triggerContextValue = useMemo(() => ({ activeTriggerState }), [activeTriggerState]);

	const triggerContexDispatchValue = useMemo(() => ({ setTriggerState }), [setTriggerState]);

	return (
		<TriggerContext.Provider value={triggerContextValue}>
			<TriggerDispatchContext.Provider value={triggerContexDispatchValue}>
				{children}
			</TriggerDispatchContext.Provider>
		</TriggerContext.Provider>
	);
};

// Public Hooks

// For use in dev tools
export const useTriggerContext = () => useContext(TriggerContext);
export const useTriggerDispatchContext = () => useContext(TriggerDispatchContext);

// For use in messages
export const useTrigger: UseTrigger = (triggerId) => {
	const { activeTriggerState } = useTriggerContext();

	return triggerId
		? { isActive: activeTriggerState.activeTriggerId === triggerId }
		: { isActive: undefined };
};

// For use externally
export const useFirePostOfficeTrigger: UseFirePostOfficeTrigger = ({ triggerId }) => {
	const { setTriggerState: setContextTriggerState } = useTriggerDispatchContext();

	const fireTrigger = useCallback(
		({ isActive }: { isActive: boolean }) => {
			return setContextTriggerState({ triggerId, isActive });
		},
		[setContextTriggerState, triggerId],
	);

	return {
		fireTrigger,
	};
};
