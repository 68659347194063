import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { RedactedPlaceholderComponentShadow } from './RedactedPlaceholderComponentShadow';

export const RedactedPlaceholderExtensionHandler = LoadableAfterPaint({
	loader: async () => {
		const { RedactedPlaceholder } = await import(
			/* webpackChunkName: "loadable-RedactedPlaceholder" */ './RedactedPlaceholder'
		);
		return RedactedPlaceholder;
	},
	loading: () => <RedactedPlaceholderComponentShadow />,
});
