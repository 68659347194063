import { Logger } from '../../common/utils';
import type { ConsentPreference } from '../../types';

import type {
	ConsentDisplayedTextMap,
	ConsentHubRequestBody,
	ConsentHubResponse,
	ConsentPreferenceData,
} from './types';
import {
	deserializeConsents,
	getConsentTimestamp,
	serializeConsents,
	StatusCodeError,
} from './utils';

const BASE_CONSENTS_URL = '/gateway/api/consenthub/session';
const POST_CONSENTS_URL = `${BASE_CONSENTS_URL}/user`;
const GET_CONSENTS_URL = `${POST_CONSENTS_URL}/consents/cookies`;

export const buildConsentPreferences = (consentData?: ConsentHubResponse) => {
	if (consentData) {
		const serializedConsents = serializeConsents(consentData);
		const consentTimestamp = getConsentTimestamp(consentData);

		return {
			consentToken: serializedConsents,
			consentTimestamp,
		};
	}

	// Without consent data (due to server failure or unauthenticated user), set defaults illustrating that absence
	return {
		consentToken: serializeConsents({}),
		consentHubInaccessible: true,
	};
};

const fetchConsentPreferences = async (): Promise<ConsentPreferenceData> => {
	const { origin } = window.location;

	const getFromConsentHubURL = `${origin}${GET_CONSENTS_URL}`;

	try {
		const response = await fetch(getFromConsentHubURL, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(
				`Failed to fetch preferences from ConsentHub with status: ${response.status}`,
			);
		}
		const consentData: ConsentHubResponse = await response.json();
		return buildConsentPreferences(consentData);
	} catch (err: any) {
		Logger.errorWithOperationalEvent({
			action: 'fetchConsentPreferencesError',
			message: err.message || '',
		});
		return buildConsentPreferences();
	}
};

const buildConsentRequestBody = (
	unpackedUserPreferences: ConsentPreference,
	displayedTextMap: ConsentDisplayedTextMap,
) => {
	const { origin, pathname } = window.location;
	const deserializedConsents = deserializeConsents(unpackedUserPreferences, displayedTextMap);

	// Type guard here so TS knows we're working with ConsentPreferenceV003
	const action =
		'CONSENT_DATA_UNAVAILABLE' in unpackedUserPreferences &&
		unpackedUserPreferences.CONSENT_DATA_UNAVAILABLE
			? 'FORM_FILL'
			: 'UPDATE_PREFERENCES';

	const consentRequestBody: ConsentHubRequestBody = {
		action, // If there are no consents, it's a first time fill, otherwise it's an update action
		consents: deserializedConsents,
		formUrl: `${origin}${pathname}`,
		locale: 'UNKNOWN',
		site: 'atlassian',
		source: 'COOKIE_PREFERENCES',
	};
	return JSON.stringify(consentRequestBody);
};

const saveConsentPreferences = async (
	unpackedUserPreferences: ConsentPreference,
	displayedTextMap: ConsentDisplayedTextMap,
) => {
	const saveToConsentHubURL = `${window.location.origin}${POST_CONSENTS_URL}`;

	try {
		const response = await fetch(saveToConsentHubURL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: buildConsentRequestBody(unpackedUserPreferences, displayedTextMap),
		});

		// Saving is an async API (we don't know if the save succeeds) so returns 202 Accepted.
		// 401 = User is unauthenticated
		// 5xx = ConsentHub is unavailable
		if (!response.ok || (response.status !== 202 && response.status !== 200)) {
			const errMsg = `Failed to save preferences to ConsentHub with status: ${response.status}`;
			const errWithStatusCode = new StatusCodeError(errMsg, response.status);
			throw errWithStatusCode;
		}
	} catch (err: any) {
		Logger.errorWithOperationalEvent({
			action: 'saveConsentPreferencesError',
			message: err.message || '',
		});
		// Re-throw to any encapsulating logic, a la `setConsentToken()`
		throw err;
	}
};

export { fetchConsentPreferences, saveConsentPreferences };
