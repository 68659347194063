import { preloadTemplatesCollectionOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadTemplatesCollectionOnboardingState';
import { preloadProductsTeamAndSpaceQuery } from '@confluence/onboarding-helpers/entry-points/preloadProductsTeamAndSpaceQuery';

export const CREATE_FROM_TEMPLATE_QUERY = 'CreateFromTemplateQuery';

export const preloadCreateFromTemplate = () => {
	return Promise.all([
		preloadTemplatesCollectionOnboardingState(),
		preloadProductsTeamAndSpaceQuery(),
	]);
};
