import { useCallback, useMemo } from 'react';

import { useSessionData } from './useSessionData';

export const useMultivariantFeatureFlag = (
	featureName: string,
	variants: string[],
	defaultValue: string,
	shouldFireExposure = false,
) => {
	const { featureFlagClient } = useSessionData();

	const getCohort = useCallback(
		(shouldTrackExposureEvent: boolean) =>
			featureFlagClient.getVariantValue(featureName, {
				default: defaultValue,
				oneOf: variants,
				shouldTrackExposureEvent,
			}),
		[featureName, variants, defaultValue, featureFlagClient],
	);

	return useMemo(
		() => ({
			cohort: getCohort(shouldFireExposure),
			// NOTE: return a closure to fire an exposure event
			fireExposureEvent: () => getCohort(true),
		}),
		[getCohort, shouldFireExposure],
	);
};
