import { type RecommendationContext } from '@post-office/recommendation-context';

import { type MessageEventClient } from './message-event-client';

export enum InAppMessageEventType {
	Viewed = 'viewed',
	Acknowledged = 'acknowledged',
	Clicked = 'clicked',
	ClickedSecondary = 'clickedSecondary',
	Dismissed = 'dismissed',
	Snoozed = 'snoozed',
	Read = 'read',
	Unread = 'unread',
}

export type PostOfficeContext = {
	orgId?: string;
	subproduct?: string;
	tenantId?: string;
	isSiteAdmin?: boolean;
	workspaceId?: string;
	locale: string | undefined;
	product: string | undefined;
};

export interface MessageEventPostPayload {
	messageTemplateId: string;
	messageInstanceId: string;
	transactionAccountId?: string;
	placement: string;
	type: InAppMessageEventType;
	context: {
		recommendation: RecommendationContext;
		browser: PostOfficeContext;
	};
	analyticsDetails?: Record<string, string | number>;
	createdAt: number;
	statusExpiryTime?: number;
}

declare global {
	interface Window {
		postOfficeMessageEventClient?: MessageEventClient;
	}
}
