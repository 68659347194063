import gql from 'graphql-tag';

export const BlogTree = gql`
	query BlogTree($spaceKey: String!, $first: Int, $endCursor: String, $statuses: [String]) {
		content(
			spaceKey: $spaceKey
			type: "blogpost"
			first: $first
			orderby: "history.createdDate desc"
			after: $endCursor
			status: $statuses
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			nodes {
				id
				title
				status
				blank
				properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
					nodes {
						id
						key
						value
					}
				}
				history {
					createdDate
				}
				links {
					webui
				}
				metadata {
					lastModifiedDate
				}
			}
		}
	}
`;
