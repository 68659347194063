import React, { useEffect, useState, type ChangeEvent, useCallback } from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Box } from '@atlaskit/primitives';
import PlatformCheckbox from '@atlaskit/checkbox';

export interface CheckboxGroupProps {
	name: string;
	options: { label: string; value: string; isDisabled?: boolean }[];
	value?: string[];
	onChange?: (values: string[]) => void;
	defaultValue?: string[];
	isDisabled?: boolean;
}

export const CheckboxGroup = (props: Parameters<RenderFn>[0]) => {
	const {
		name,
		options,
		value,
		onChange,
		defaultValue = [],
		isDisabled = false,
	} = props.forgeDoc.props as CheckboxGroupProps;
	const [selectedValues, setSelectedValues] = useState<string[]>(value ?? defaultValue);

	useEffect(() => {
		if (value) {
			setSelectedValues(value);
		}
	}, [value]);
	const notStaticCheckbox = !value || onChange;
	const onCheckboxChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (notStaticCheckbox) {
				const newSelectedValues = selectedValues.includes(event.target.value)
					? selectedValues.filter((item) => item !== event.target.value)
					: [...selectedValues, event.target.value];

				setSelectedValues(newSelectedValues);
				if (onChange) {
					onChange(newSelectedValues);
				}
			}
		},
		[notStaticCheckbox, onChange, selectedValues, setSelectedValues],
	);

	return (
		<Box role="group">
			{options.map((item, index) => {
				return (
					<PlatformCheckbox
						key={index}
						name={name}
						value={item.value}
						label={item.label}
						isChecked={selectedValues.includes(item.value)}
						isDisabled={item.isDisabled ?? isDisabled}
						onChange={onCheckboxChange}
					/>
				);
			})}
		</Box>
	);
};
