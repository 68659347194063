import { query } from '@confluence/query-preloader-tools';

import { PageStatusQuery } from '../graphql/PageStatusQuery.graphql';

export function preloadPageStatusQuery(contentId: string) {
	return query({
		query: PageStatusQuery,
		variables: {
			contentId,
		},
	});
}
