import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

import { POST_OFFICE_PLATFORM_NAME, UFOExperienceNames } from '../shared/constants';

export class MessagePerformanceTracker {
	private readonly aggregatedExperience: UFOExperience;
	private readonly messageSpecificExperience: UFOExperience;
	private readonly experiences: UFOExperience[];
	private readonly metadata: Record<string, string> = {};

	constructor(messageTemplateId: string) {
		this.aggregatedExperience = new UFOExperience(UFOExperienceNames.MESSAGE, {
			performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
			type: ExperienceTypes.Load,
			platform: { component: POST_OFFICE_PLATFORM_NAME },
		});
		this.messageSpecificExperience = new UFOExperience(
			`${UFOExperienceNames.MESSAGE}.${messageTemplateId}`,
			{
				performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
				type: ExperienceTypes.Load,
				platform: { component: POST_OFFICE_PLATFORM_NAME },
			},
		);
		this.experiences = [this.aggregatedExperience, this.messageSpecificExperience];
		this.experiences.forEach((experience) => experience.start());
		this.metadata = { messageTemplateId };
	}

	public markSuccess = () => {
		for (const experience of this.experiences) {
			void experience.success({
				metadata: this.metadata,
			});
		}
	};

	public markFailure = (error: Error) => {
		for (const experience of this.experiences) {
			void experience.failure({
				metadata: { ...this.metadata, error: JSON.stringify(error) },
			});
		}
	};

	public abort = () => {
		for (const experience of this.experiences) {
			void experience.abort({
				metadata: this.metadata,
			});
		}
	};
}
