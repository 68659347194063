import gql from 'graphql-tag';

export const ReactionsContentQuery = gql`
	query ReactionsContentQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				contentReactionsSummary {
					reactionsCount
					ari
					containerAri
					reactionsSummaryForEmoji {
						emojiId
						count
						reacted
						id
					}
				}
			}
		}
	}
`;
