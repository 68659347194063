export const editorFeatureFlags = (featureFlags: Record<string, boolean | string>) => {
	const editorFlags: Record<string, boolean | string> = {};
	for (const key in featureFlags) {
		if (key.startsWith('confluence.frontend.fabric.editor.')) {
			editorFlags[key.replace('confluence.frontend.fabric.editor.', '')] = featureFlags[key];
		}
	}

	return editorFlags;
};
