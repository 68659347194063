import { combineReducers } from 'redux';
import { Map, OrderedSet, List } from 'immutable';
import { SpaceLinkRecord } from '@confluence-classic/confluence-legacy-redux';

import {
	SPACE_TAB_PAGES_KEY,
	SPACE_SETTINGS_KEY,
} from '@confluence/web-item/entry-points/webItemKeys';

const ALWAYS_VISIBLE_WEBITEMS = [SPACE_TAB_PAGES_KEY, SPACE_SETTINGS_KEY];

// items that shouldn't appear in ADG3 sidebar
const blacklist = ['spacebar-profile'];

export function links(state = Map({}), action) {
	switch (action.type) {
		case 'GET_SPACE_LINKS_SUCCESS':
			const spaceKey = action.spaceKey;

			Object.keys(action.response).forEach((group) => {
				const allowedItems = action.response[group].filter((link) => {
					return !(link.webItemKey && blacklist.indexOf(link.webItemKey) !== -1);
				});

				const spaceLinks = allowedItems.map((link) => {
					const item = { ...link };

					if (group === 'quick') {
						item.canHide = true;
					}

					// force "pages" and "space settings" always visible
					if (ALWAYS_VISIBLE_WEBITEMS.indexOf(item.webItemCompleteKey) !== -1) {
						item.hidden = false;
						item.canHide = false;
					}

					return SpaceLinkRecord(item);
				});
				state = state.setIn([spaceKey, group], List(spaceLinks));
			});

			return state;

		case 'MOVE_SPACE_LINK_REQUEST':
			const { group } = action;

			return state.updateIn([action.spaceKey, group], (items) => {
				const indexSource = items.findIndex((item) => item.id === action.id);
				if (indexSource !== -1) {
					const item = items.get(indexSource);
					const updatedItems = items.delete(indexSource);

					let indexDestination = -1;
					if (action.position && action.position === 'First') {
						// Move to the first
						indexDestination = 0;
					} else if (action.after) {
						// Move the item to a item with certain id
						indexDestination = updatedItems.findIndex((item) => item.id === action.after) + 1;
					}

					if (indexDestination !== -1) {
						return updatedItems.insert(indexDestination, item);
					}
				}

				return items;
			});

		case 'TOGGLE_SPACE_LINK_REQUEST':
			const path = [action.spaceKey, action.group];
			const index = state.getIn(path).findIndex((item) => item.id === action.id);
			return state.updateIn(path.concat(index), (item) => item.set('hidden', !item.hidden));

		case 'REMOVE_SPACE_SHORTCUT_LINK_REQUEST':
			const { linkId } = action;
			return state.updateIn([action.spaceKey, 'quick'], (items) => {
				const indexSource = items.findIndex((item) => item.id === linkId);
				return items.delete(indexSource);
			});

		default:
			return state;
	}
}

export function allSpaces(state = OrderedSet(), action) {
	switch (action.type) {
		case 'GET_SPACES_SUCCESS':
			if (action.response.result.allSpaces && action.response.result.allSpaces.results.length) {
				return OrderedSet(action.response.result.allSpaces.results);
			}

			return state;
	}

	return state;
}

export default combineReducers({
	links,
	allSpaces,
});
