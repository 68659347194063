import gql from 'graphql-tag';

export const HomeViewsUserPreferencesQuery = gql`
	query HomeViewsUserPreferencesQuery {
		userPreferences {
			homePagesDisplayView
		}
	}
`;

export const HomeViewsUserPreferencesMutation = gql`
	mutation HomeViewsUserPreferencesMutation($appearance: PagesDisplayPersistenceOption!) {
		updateUserPreferences(userPreferences: { homePagesDisplayView: $appearance }) {
			homePagesDisplayView
		}
	}
`;
