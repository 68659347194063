import gql from 'graphql-tag';

// TODO CCT: when we clean up the contentId based urls FF, move this to the whiteboardUnifiedQuery
export const SpaceQuery = gql`
	query SpaceQueryWhiteboards($spaceKey: String) {
		space(key: $spaceKey) {
			id
			name
			icon {
				path
			}
			homepage {
				id
			}
		}
	}
`;
