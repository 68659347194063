import { getTaskManager } from '../TaskManager';
import { LoadingPriority } from '../constants';

// Fix case for <p><span><marker start><span></p><marker end>
// Increase the depth needed if found other cases
const MAX_MOVE_UP_LEVEL_FOR_BEGIN_MARKER = 2;
const serverMarkup = new Map<string, Element[][]>();
const serverMarkupIndexMap = new Map<string, number>();
const refToOriginalElement = new WeakMap<Element, Element>();
export const DELAY_CLEAN_UP_SERVER_MARKUP_TIME = 10000; // 10s
const dataLoadableBeginRegex = /data-loadable-begin="([^"]*)"/;
let totalElementsToHydrate = 0;

/**
 * NOTE:
 *
 * Node: Includes Element, Text, Comment, ProcessingInstruction, CDATASection, DocumentType
 * Element: Includes HTMLElement, SVGElement, MathMLElement
 * HTMLElement: Includes all common HTML element like span and div
 *
 * Because placeholders contain SVG so most of the time we are dealing with Element.
 * Unless checking comment which is a Node.
 */

export function shouldUseOriginalElement(el: Element): boolean {
	const tagName = el.tagName.toUpperCase();

	return tagName === 'VIDEO' || tagName === 'AUDIO' || tagName === 'IMG';
}

function saveRefToOriginalElement(el: Node, clone: Node) {
	if (!(el instanceof Element) || !(clone instanceof Element)) return;

	// For certain node like img. Inserting a cloned node or re-creating triggers the load again.
	// This caused flickering in emoji and media image. HOT-105601
	// Here we are walking the DOM tree and keeping a map of cloned node to original node.
	// Later when creating the placeholder from SSR result we can use the original node directly.
	if (shouldUseOriginalElement(el)) {
		refToOriginalElement.set(clone, el);
	}
	el.childNodes.forEach((child, i) => {
		saveRefToOriginalElement(child, clone.childNodes[i]);
	});
}

export function lookupOriginalElement(el: Element) {
	return refToOriginalElement.get(el);
}

// Export for testing
export function cloneElements(fromEl: Element, loadableId: string | undefined) {
	let el: Element | null = fromEl;
	const nodes: Element[] = [];
	while ((el = el.nextSibling as Element)) {
		if (el instanceof HTMLElement) {
			if (el.dataset?.loadableEnd === loadableId) break;
			if (el.dataset?.loadableBegin || el.dataset?.loadableEnd) continue;
		}
		const clone = el.cloneNode(true) as Element;
		saveRefToOriginalElement(el, clone);
		nodes.push(filterMarkers(clone));
	}

	return nodes;
}

export function cloneElementsWithCommentMarker(
	fromEl: Node,
	loadableId: string | undefined,
): { cloned: Element[]; foundCloseMarker: boolean } {
	let el: Node | null = fromEl;
	let foundCloseMarker = false;
	const nodes: Element[] = [];
	while ((el = el.nextSibling)) {
		const dataLoadableEnd = /data-loadable-end="([^"]*)"/;
		const match = el?.nodeValue?.match(dataLoadableEnd);
		if (match && match[1] === loadableId) {
			foundCloseMarker = true;
			break;
		}
		if (el instanceof Element) {
			const clone = el.cloneNode(true) as Element;
			saveRefToOriginalElement(el, clone);
			nodes.push(clone);
		}
	}

	return { cloned: nodes, foundCloseMarker };
}

function filterMarkers(el: Element): Element {
	// Deleted node doesn't have childNodes so wait until the end
	const pendingRemove: Element[] = [];
	el.childNodes.forEach((child) => {
		if (
			child instanceof HTMLElement &&
			(child.dataset?.loadableBegin || child.dataset?.loadableEnd)
		) {
			pendingRemove.push(child);
		}
		filterMarkers(child as Element);
	});
	pendingRemove.forEach((node) => node.remove());
	return el;
}

function findAndCollectCommentMarkers(root: Node) {
	const nodes = root.childNodes;
	for (let i = 0; i < nodes.length; i++) {
		const node = nodes[i];
		const nodeType = node.nodeType;
		if (nodeType === Node.COMMENT_NODE && node?.nodeValue?.includes('data-loadable-begin')) {
			const match = node.nodeValue?.match(dataLoadableBeginRegex);
			if (!match) {
				continue;
			}
			const loadableId = match[1];

			/**
			 * This is a bit messed up.
			 * In renderer there are case like <span><!-- begin -->..<div>...<!-- end --></span>
			 * This is an invalid HTML browser will correct it by closing the span tag.
			 * It ends up like <span><!-- begin --></span>..<div>...<!-- end --></div>
			 * In this case the ancestor of the begin marker should be treated as the begin marker.
			 */
			let clonedElements: Element[] = [];
			let beginNode: Node | null = node;
			let moveUpLevelCount = 0;
			while (beginNode && moveUpLevelCount <= MAX_MOVE_UP_LEVEL_FOR_BEGIN_MARKER) {
				const { cloned, foundCloseMarker } = cloneElementsWithCommentMarker(beginNode, loadableId);
				if (foundCloseMarker) {
					clonedElements = cloned;
					break;
				} else {
					// If we didn't find the close marker, we need to go up the tree and find the next begin marker.
					beginNode = beginNode.parentNode;
					moveUpLevelCount++;
				}
			}
			if (!clonedElements.length) {
				continue;
			}

			totalElementsToHydrate += 1;

			if (serverMarkup.has(loadableId)) {
				serverMarkup.get(loadableId)!.push(clonedElements);
			} else {
				serverMarkup.set(loadableId, [clonedElements]);
				serverMarkupIndexMap.set(loadableId, 0);
			}
		} else if (nodeType === Node.ELEMENT_NODE) {
			findAndCollectCommentMarkers(node);
		}
	}
}

function collect() {
	findAndCollectCommentMarkers(document.body);
}

export function getServerMarkup() {
	return serverMarkup;
}

//exported for testing purposes only
export function getServerMarkupIndexMap() {
	return serverMarkupIndexMap;
}

export function getTotalHydratedElements() {
	return totalElementsToHydrate;
}

export function initLoadablePlaceholders() {
	collect();
}

export function cleanUpLoadablePlaceholders() {
	// Delay cleaning up server markups.
	// On a slower device or poor network connection, the client side rendering might not be completed,
	// server markups are still in use. Therefore, wait for ${DELAY_CLEAN_UP_SERVER_MARKUP_TIME}(10s) before clean it up.
	void getTaskManager().push({
		id: 'clean-up-server-markup',
		task: () => {
			setTimeout(() => {
				serverMarkup.clear();
				serverMarkupIndexMap.clear();
				totalElementsToHydrate = 0;
			}, DELAY_CLEAN_UP_SERVER_MARKUP_TIME);
		},
		priority: LoadingPriority.BACKGROUND,
	});
}

export function getAndIncreaseServerMarkupPointer(loadableId?: string) {
	if (!loadableId || !serverMarkupIndexMap.has(loadableId)) return;
	const index = serverMarkupIndexMap.get(loadableId);
	serverMarkupIndexMap.set(loadableId, index! + 1);
	return index;
}
