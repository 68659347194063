import type { ADNode } from '@atlaskit/editor-common/validator';

import { extensionToADF } from '@confluence/fabric-extension-lib/entry-points/editor-extensions';

import { serializeExtensionADF } from './serializeExtensionADF';

export const FF_EDITOR_MACRO_ADF_RENDERING =
	'confluence.frontend.fabric.editor.macro.adf.frontend.render';

const getContentId = (adf: ADNode, contentId: string) => {
	const parentId = adf?.attrs?.parameters?.macroParams?._parentId?.value;
	return parentId || contentId;
};

type MacroQueryParams = {
	adf?: ADNode;
	node?: any;
	mode?: string;
	contentId: string;
	featureFlags: { [key: string]: string | number | boolean };
};

export const getMacroQueryVariables = ({
	adf,
	node,
	mode = 'RENDERER',
	contentId,
	featureFlags,
}: MacroQueryParams) => {
	const extensionADF = adf || extensionToADF(node);
	const isMacroContentAndBody = Boolean(featureFlags[FF_EDITOR_MACRO_ADF_RENDERING]);

	return {
		adf: serializeExtensionADF(extensionADF),
		mode: mode.toUpperCase(),
		contentId: isMacroContentAndBody ? getContentId(extensionADF, contentId) : contentId,
		isMacroContentAndBody,
	};
};
