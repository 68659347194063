import {
	contentsWithBodyResponseTransformer,
	spaceResponseTransformer,
	editorContentsWithBodyResponseTransformerV2,
} from '@confluence-classic/confluence-rest-api';
// eslint-disable-next-line check-cross-package-imports/dont-import-redux-in-next
import { content, spaces } from '@confluence-classic/confluence-legacy-redux';

import {
	featureDiscovery as featureDiscoveryQuery,
	editorContent as contentForEditPageQuery,
	spaceForBaseSpaceQuery,
} from '@confluence/load-edit-page/entry-points/LoadEditPageQueries';

import { ViewPageQuery as contentForViewPageQuery } from '../queries/contentForViewPage.graphql.js';
// edit page
import { templateEditorContent } from '../queries/contentForTemplateEditPage.graphql.js';

export const graphqlActionCreator = ({
	query,
	operationName,
	variables,
	actionName,
	transformer,
	normalizrSchema,
	errorsFilter,
	batch = true,
	fetchPolicy,
}) => {
	const actionObject = {
		type: 'APOLLO_QUERY',
		query,
		operationName,
		variables,
		actionName,
		transformer,
		schema: normalizrSchema,
		errorsFilter,
		batch,
	};
	if (fetchPolicy) {
		actionObject.fetchPolicy = fetchPolicy;
	}
	return actionObject;
};

/**
 * Ignore errors for the 'likes' field, to avoid problems with likes on page view
 */
function ignoreGraphQLLikeErrors(errors) {
	// error.message.indexOf is temporary until atlassian-graphql-java passes the correct path
	return errors.every(
		(error) =>
			(error.path && error.path.indexOf('likes') !== -1) ||
			(error.message && error.message.indexOf('likes') !== -1),
	)
		? null
		: errors;
}

export const getContent = (variables) =>
	graphqlActionCreator({
		query: contentForViewPageQuery,
		variables,
		operationName: 'content',
		actionName: ['CONTENT'],
		transformer: (data) => contentsWithBodyResponseTransformer(data.content),
		normalizrSchema: content,
		batch: false,
		errorsFilter: ignoreGraphQLLikeErrors,
	});

export const getSpaceByKey = (variables) =>
	graphqlActionCreator({
		query: spaceForBaseSpaceQuery,
		variables,
		operationName: 'spaceForBaseSpaceQuery',
		actionName: 'SPACE_INFO',
		transformer: (data) => spaceResponseTransformer(data.spaces),
		normalizrSchema: spaces,
	});

export const getEditorContent = (variables) =>
	graphqlActionCreator({
		query: contentForEditPageQuery,
		variables,
		operationName: 'editorContent',
		actionName: 'EDITOR_CONTENT_V2',
		transformer: (data) => editorContentsWithBodyResponseTransformerV2(data.content),
		normalizrSchema: content,
		batch: false,
		fetchPolicy: 'no-cache',
	});

export const getTemplateEditorContent = ({ contentId }) => {
	return graphqlActionCreator({
		query: templateEditorContent,
		variables: { contentId },
		operationName: 'templateEditorContent',
		actionName: 'GET_TEMPLATE_EDITOR_CONTENT',
		transformer: (data) => data.template,
		batch: false,
		fetchPolicy: 'network-only',
	});
};

export const getDiscoveredFeatures = () =>
	graphqlActionCreator({
		query: featureDiscoveryQuery,
		operationName: 'featureDiscovery',
		actionName: 'DISCOVERED_FEATURES',
		batch: true,
		transformer: (data) => data.featureDiscovery,
	});
