import type { ReactNode, ComponentType } from 'react';
import { type FlagProps, AutoDismissFlag } from '@atlaskit/flag';

import type { FlagOptions } from '@atlassian/forge-ui/iframe';

type FlagAction = NonNullable<FlagProps['actions']>[0];

type FlagType = 'success-circle' | 'success' | 'info' | 'error' | 'warning' | 'custom';

export interface FlagDescriptor {
	title?: string | ReactNode;
	description?: string | ReactNode;
	actions?: FlagAction[];
	onClose?: () => void;
	id?: number | string;
	type?: FlagType;
	customIcon?: JSX.Element;
	appearance?: FlagProps['appearance'];
	close?: string;
	customComponent?: ComponentType<FlagProps>;
}

export interface FlagFunctions {
	showFlag: (flag: FlagDescriptor) => Promise<FlagDescriptor>;
	hideFlag: (id: number | string | undefined) => Promise<void>;
}

export type FlagProviderHOC = ({
	children,
}: {
	children: (flags: FlagFunctions) => JSX.Element;
}) => JSX.Element;

export const getFlagProvider = ({ showFlag, hideFlag }: FlagFunctions) => {
	if (!showFlag || !hideFlag) {
		return {};
	}

	const createFlag = (flagOptions: FlagOptions) => {
		const { isAutoDismiss, ...restOptions } = flagOptions;
		const { type, appearance, actions } = flagOptions;
		const shouldAutoDismiss = isAutoDismiss ?? (appearance === 'info' || appearance === 'success');

		void showFlag({
			...restOptions,
			type: appearance ?? type,
			actions: actions?.map(({ text: content, onClick }) => ({
				onClick: () => onClick(),
				content,
			})),
			customComponent: shouldAutoDismiss ? AutoDismissFlag : undefined,
		});
		return true;
	};

	const closeFlag = ({ id }: Pick<FlagOptions, 'id'>) => {
		void hideFlag(id);
		return true;
	};

	return { showFlag: createFlag, closeFlag };
};
