import React, { createContext, type PropsWithChildren } from 'react';

import type {
	ChoreographerAssetsContext,
	ChoreographerAssetsContextProviderProps,
} from './__types__/ChoreographerAssetsContext';
import { useChoreographerServices } from './useChoreographerServices';

const Context = createContext<ChoreographerAssetsContext | undefined>(undefined);
const { Consumer, Provider } = Context;

export const ChoreographerAssetsContextProvider = ({
	children,
}: PropsWithChildren<ChoreographerAssetsContextProviderProps>) => {
	const choreographerFactoryAssets = useChoreographerServices();

	return <Provider value={choreographerFactoryAssets}>{children}</Provider>;
};

export { Consumer, Context, ChoreographerAssetsContextProvider as Provider };
