import { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';
import type { ClientOptions } from '@atlaskit/feature-gate-js-client';

import type { SessionDataType } from '@confluence/session-data';

/**
 * Transforms an environment string into a more strongly typed Statsig FeatureGateEnvironment
 * @param rawEnvironment an environment from SessionDataType or SSR (cc-frontend-ssr/src/common/constants.ts:MicrosEnvironmentType)
 */
export const getStatsigEnvironment = (
	rawEnvironment?: SessionDataType['environment'] | string,
): ClientOptions['environment'] => {
	let environment;
	switch (rawEnvironment) {
		case 'PRODUCTION':
		case 'prod':
			environment = FeatureGateEnvironment.Production;
			break;
		case 'STAGING':
		case 'staging':
			environment = FeatureGateEnvironment.Staging;
			break;
		default:
			environment = FeatureGateEnvironment.Development;
	}
	return environment;
};
