import { fetchDraft } from './fetchDraft';

declare global {
	interface Window {
		__PRELOAD_DRAFT__?: any;
	}
}

export async function preloadDraft(contentId: string) {
	window.__PRELOAD_DRAFT__ = {
		contentId,
		draftFetchPromise: fetchDraft(contentId),
		// eslint-disable-next-line no-restricted-properties
		startTime: window?.performance?.now?.(),
	};
}
