import type { IntlShape } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';
import React from 'react';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import { CREATE_WHITEBOARD_EXPERIENCE, ExperienceTimeout } from '@confluence/experience-tracker';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import type { FlagsStateContainer } from '@confluence/flags';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getTraceIdFromApolloError } from '@confluence/template-utils';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { CreateWhiteboardMutation } from './CreateWhiteboard.graphql';
import { messages } from './messages';
import { getExpectedError } from './error-handling';
import { getFullWhiteboardUrl } from './urls';

type CreateWhiteboardExtensionProps = {
	pageId: string;
	spaceKey: string;
	intl: IntlShape;
	experienceTracker?: ExperienceTrackerAPI;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	flags?: FlagsStateContainer;
	contentType?: string;
	allowWhiteboardQuickInsert?: boolean;
};

export const createWhiteboardQuickInsertModule = (
	pageId: string,
	spaceKey: string,
	experienceTracker?: ExperienceTrackerAPI,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	flags?: FlagsStateContainer,
) => {
	return async () => {
		try {
			experienceTracker?.start({
				name: CREATE_WHITEBOARD_EXPERIENCE,
				timeout: ExperienceTimeout.CREATE_CONTENT,
				attributes: { flow: 'inlinePageCreation' },
			});
			const mutationResult = await getApolloClient().mutate({
				mutation: CreateWhiteboardMutation,
				variables: { createdInContentId: pageId, spaceKey },
			});
			const { id: contentId } = mutationResult.data.createContentInline;

			createAnalyticsEvent?.({
				type: 'sendTrackEvent',
				data: {
					actionSubject: 'whiteboardMacro',
					action: 'created',
					objectId: contentId,
					source: 'editorComponent',
					actionSubjectId: contentId,
					attributes: {
						macroType: 'WHITEBOARD',
						whiteboardContainer: 'PAGE',
						isNew: true,
					},
				},
			}).fire();

			const fullUrl = getFullWhiteboardUrl(contentId, spaceKey);

			await flags?.showFlag({
				type: 'success-circle',
				title: <FormattedMessage {...messages.successFlagTitle} />,
				actions: [
					{
						content: <FormattedMessage {...messages.successFlagAction} />,
						href: fullUrl,
					},
				],
				description: <FormattedMessage {...messages.successFlagDescription} />,
			});

			experienceTracker?.succeed({
				name: CREATE_WHITEBOARD_EXPERIENCE,
			});

			return {
				type: 'embedCard',
				attrs: {
					url: fullUrl,
				},
			};
		} catch (error) {
			const expectedErrorMessage = getExpectedError(error);
			if (expectedErrorMessage) {
				experienceTracker?.succeed({
					name: CREATE_WHITEBOARD_EXPERIENCE,
					attributes: { abortReason: 'expectedErrorMessage' },
				});
				await flags?.showErrorFlag({
					title: <FormattedMessage {...expectedErrorMessage.title} />,
					description: <FormattedMessage {...expectedErrorMessage.description} />,
				});
			} else {
				getMonitoringClient().submitError(error, {
					attribution: Attribution.WHITEBOARDS,
				});
				experienceTracker?.stopOnError({
					name: CREATE_WHITEBOARD_EXPERIENCE,
					error,
					attributes: { cteTraceId: getTraceIdFromApolloError(error) },
				});
				await flags?.showErrorFlag({
					title: <FormattedMessage {...messages.whiteboardDefaultErrorFlagTitle} />,
					description: <FormattedMessage {...messages.whiteboardDefaultErrorFlagDescription} />,
				});
			}
			markErrorAsHandled(error);
		}
	};
};

export const createWhiteboardExtension = ({
	pageId,
	spaceKey,
	intl,
	flags,
	experienceTracker,
	createAnalyticsEvent,
	allowWhiteboardQuickInsert,
	contentType,
}: CreateWhiteboardExtensionProps): ExtensionManifest => {
	const quickInsert = [];

	if (contentType === 'page') {
		quickInsert.push({
			key: 'create-whiteboard',
			title: intl.formatMessage(messages.createWhiteboardTitle),
			description: intl.formatMessage(messages.createWhiteboardDescription),
			// eslint-disable-next-line import/dynamic-import-chunkname
			icon: () => import('@atlaskit/icon/glyph/editor/addon'),
			action: createWhiteboardQuickInsertModule(
				pageId,
				spaceKey,
				experienceTracker,
				createAnalyticsEvent,
				flags,
			),
		});
	}

	return {
		title: intl.formatMessage(messages.whiteboardManifestTitle),
		description: intl.formatMessage(messages.whiteboardManifestDescription),
		type: 'com.atlassian.confluence.macro.core',
		key: 'whiteboard-extension',
		icons: {
			// eslint-disable-next-line import/dynamic-import-chunkname
			'48': () => import('@atlaskit/icon/glyph/editor/addon'),
		},
		modules: {
			quickInsert: allowWhiteboardQuickInsert ? quickInsert : [],
		},
	};
};
