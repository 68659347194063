import type { ConnectDataTransferResponse } from './types';
import { ConnectDataTransferStatus } from './types';
import { getConnectMacroKeyRefOutputTypeMap } from './macro-schema-map';
import { getValidationFailedEvent } from './analytics';

let connectDataListener;

class ConnectDataListener {
	private emit;

	public init({ emit }) {
		this.emit = emit;
	}

	private static async validate(extensionKey: string, data) {
		const { validateSchema, DataTransferValidationError } = await import(
			/* webpackChunkName: "loadable-confluencejson-schema-validation" */ '@confluence/json-schema-validation'
		);
		if (!data.eventData) {
			throw new DataTransferValidationError({
				message: "Passed data is missing 'eventData'.",
			});
		}

		const keys = Object.keys(data.eventData);
		if (keys.length !== 1) {
			throw new DataTransferValidationError({
				message:
					"'eventData' should only include a single key. The key should be same with 'refDataSchema.outputType'.",
			});
		}

		const connectMacroKeyRefOutputTypeMap = await getConnectMacroKeyRefOutputTypeMap();
		if (!connectMacroKeyRefOutputTypeMap.has(extensionKey)) {
			// This is a case that shouldn't really happen. Throwing a regular error instead of DataTransferValidationError
			// because this error is not related to what the vendor emitted data.
			throw new Error('extensionKey is not found in the macro metadata.');
		}

		if (connectMacroKeyRefOutputTypeMap.get(extensionKey) !== keys[0]) {
			throw new DataTransferValidationError({
				message: `'eventData' should contain 'refDataSchema.outputType' defined in the app manifest (${connectMacroKeyRefOutputTypeMap.get(
					extensionKey,
				)}).`,
			});
		}

		validateSchema(keys[0], data.eventData[keys[0]]);
	}

	public async transfer(
		extensionKey,
		localId,
		data,
		createAnalyticsEvent,
		isViewPage,
	): Promise<ConnectDataTransferResponse> {
		if (this.emit && localId && data) {
			const { DataTransferValidationError } = await import(
				/* webpackChunkName: "loadable-confluencejson-schema-validation" */ '@confluence/json-schema-validation'
			);
			try {
				await ConnectDataListener.validate(extensionKey, data);
			} catch (e) {
				const event = getValidationFailedEvent({
					isViewPage,
					extensionKey,
					error: e,
				});
				createAnalyticsEvent(event).fire();

				if (e instanceof DataTransferValidationError) {
					return {
						status: ConnectDataTransferStatus.Error,
						message: e.message,
						module: 'data_provider',
						errors: e.errors,
					};
				}

				throw e;
			}
			this.emit(localId, data.eventData);

			return {
				status: ConnectDataTransferStatus.Success,
				message: 'Data accepted and emitted',
				module: 'data_provider',
			};
		}

		return {
			status: ConnectDataTransferStatus.Error,
			message: 'There was an issue accepting the data',
			module: 'data_provider',
		};
	}
}

export const getConnectDataListener = () => {
	if (!connectDataListener) {
		connectDataListener = new ConnectDataListener();
	}
	return connectDataListener;
};
