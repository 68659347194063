import React, { createContext, useContext } from 'react';
import { useMemoOne as useMemo } from 'use-memo-one';
import { type ProductEnvironment } from '@atlassian/forge-ui-types';
import { EnvironmentContext } from '../environment';

type MetricsContextValue = {
	environment?: ProductEnvironment;
	product: string;
	page: string;
};

type MetricsContextProviderProps = {
	value: MetricsContextValue;
	children: React.ReactNode;
};

export const MetricsContext = createContext<MetricsContextValue>({
	environment: undefined,
	product: '',
	page: '',
});

export const MetricsContextProvider = ({ value, children }: MetricsContextProviderProps) => {
	const envFromContext = useContext(EnvironmentContext);
	const { product, page, environment } = value;

	const memoedValue = useMemo(() => {
		return {
			environment: environment ?? envFromContext,
			product,
			page,
		};
	}, [page, environment, envFromContext, product]);

	return <MetricsContext.Provider value={memoedValue}>{children}</MetricsContext.Provider>;
};

export const useMetricsContext = () => {
	return useContext(MetricsContext);
};
