import React, { useContext } from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import type { ChartColorTokens } from '../../utils/colorTokens/chartColorTokens';
import { getChartColorTokenValues } from '../../utils/getColorTokenValue';
import { RendererContext } from '../../../context';

const PlatformStackBarChart = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_stackbarchart' */
		'@atlassian/visualization/charts'
	).then((module) => ({ default: module.StackBarChart })),
);

const Title = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_stackbarchart' */
		'@atlassian/visualization/charts'
	).then((module) => ({ default: module.StackBarChart.elements.Title })),
);

const SubTitle = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_stackbarchart' */
		'@atlassian/visualization/charts'
	).then((module) => ({ default: module.StackBarChart.elements.SubTitle })),
);

type PlatformStackBarChartProps = React.ComponentProps<typeof PlatformStackBarChart>;
/**
 * Excluded props:
 * - grid - does not work across all components
 * - showTableOption - does not work across all components and will be moved into a primitive
 * - showToolTip - does not work across all components
 * - children - we will not be supporting primitives/elements to compose the chart
 * - patterns
 * - isPatterned
 * - sort
 *
 * Limited props:
 * - xAccessor - only allow non function types
 * - yAccessor - only allow non function types
 * - colorAccessor - only allow non function types
 * - colors - only allow chart color tokens (color.chart.*)
 */
export type StackBarChartProps = Pick<
	PlatformStackBarChartProps,
	'width' | 'height' | 'data' | 'showBorder'
> & {
	xAccessor: number | string;
	yAccessor: number | string;
	colorAccessor: number | string;
	title?: string;
	subtitle?: string;
	colors?: ChartColorTokens[];
};
export const StackBarChart = (props: Parameters<RenderFn>[0]) => {
	const {
		width,
		height,
		data,
		showBorder,
		xAccessor,
		yAccessor,
		colorAccessor,
		colors,
		title,
		subtitle,
	} = props.forgeDoc.props as StackBarChartProps;

	const colorValues = getChartColorTokenValues(colors);

	// Stop viz components from being rendered in Prod Forge environment
	const { forgeEnvironment } = useContext(RendererContext);
	if (forgeEnvironment === 'PRODUCTION') {
		throw new Error(
			'EAP features are only accessible to users with EAP signup and not supported or recommended for use in production.',
		);
	}

	return (
		<PlatformStackBarChart
			width={width}
			height={height ? height : 400}
			data={data}
			showBorder={showBorder}
			xAccessor={xAccessor}
			yAccessor={yAccessor}
			colorAccessor={colorAccessor}
			colors={colorValues}
		>
			{title && <Title>{title}</Title>}
			{subtitle && <SubTitle>{subtitle}</SubTitle>}
		</PlatformStackBarChart>
	);
};
