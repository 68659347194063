import gql from 'graphql-tag';

export const TemplateMediaSessionDownloadQuery = gql`
	query TemplateMediaSessionDownloadQuery(
		$spaceKey: String
		$templateIds: [String]!
		$collectionId: String
	) {
		templateMediaSession(
			spaceKey: $spaceKey
			templateIds: $templateIds
			collectionId: $collectionId
		) {
			collections {
				key
				value
			}
			configuration {
				fileStoreUrl
				clientId
			}
			downloadToken {
				value
				duration
			}
		}
	}
`;
