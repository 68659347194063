import { format } from 'date-fns';
import { defineMessages } from 'react-intl-next';

const i18n = defineMessages({
	aMomentAgo: {
		id: 'save-indicator.tooltip.time.aMomentAgo',
		description:
			'Time string in save indicator tooltip that indicates last change was a moment ago',
		defaultMessage: 'just a moment ago',
	},
	lessThanAMinuteAgo: {
		id: 'save-indicator.tooltip.time.lessThanAMinuteAgo',
		description:
			'Time string in save indicator tooltip that indicates last change was less than a minute ago',
		defaultMessage: 'less than a minute ago',
	},
	oneMinuteAgo: {
		id: 'save-indicator.tooltip.time.oneMinuteAgo',
		description:
			'Time string in save indicator tooltip that indicates last change was one minute ago',
		defaultMessage: 'a minute ago',
	},
	xMinutesAgo: {
		id: 'save-indicator.tooltip.time.xMinutesAgo',
		description:
			'Time string in save indicator tooltip that indicates last change was several minutes ago',
		defaultMessage: '{time} minutes ago',
	},
	oneHourAgo: {
		id: 'save-indicator.tooltip.time.oneHourAgo',
		description:
			'Time string in save indicator tooltip that indicates last change was about one hour ago',
		defaultMessage: 'about an hour ago',
	},
	xHoursAgo: {
		id: 'save-indicator.tooltip.time.xHoursAgo',
		description:
			'Time string in save indicator tooltip that indicates last change was about several hour ago',
		defaultMessage: 'about {time} hours ago',
	},
	yesterday: {
		id: 'save-indicator.tooltip.time.yesterday',
		description:
			'Time string in save indicator tooltip that indicates last change was the day before',
		defaultMessage: 'yesterday at {time}',
	},
});

const MILLISECONDS_TO_SECONDS = 1000;
const MILLISECONDS_TO_MINUTES = 60 * 1000;
const MILLISECONDS_TO_HOUR = 60 * 60 * 1000;
const MILLISECONDS_TO_DAY = 24 * 60 * 60 * 1000;

// timeFormatter formats time to a friendly time string the same way as
// backend formats by-line time string
export const timeFormatter = (savedTime: Date, formatMessage: any) => {
	let formattedTime;
	const timeNow = new Date();
	const timeDifference = timeNow.getTime() - savedTime.getTime();
	if (timeDifference / MILLISECONDS_TO_SECONDS < 4) {
		formattedTime = formatMessage(i18n.aMomentAgo);
	} else if (timeDifference / MILLISECONDS_TO_MINUTES < 1) {
		formattedTime = formatMessage(i18n.lessThanAMinuteAgo);
	} else if (timeDifference / MILLISECONDS_TO_MINUTES < 2) {
		formattedTime = formatMessage(i18n.oneMinuteAgo);
	} else if (timeDifference / MILLISECONDS_TO_MINUTES < 50) {
		const roundedMinutes = Math.round(timeDifference / MILLISECONDS_TO_MINUTES);
		formattedTime = formatMessage(i18n.xMinutesAgo, { time: roundedMinutes });
	} else if (timeDifference / MILLISECONDS_TO_MINUTES < 90) {
		formattedTime = formatMessage(i18n.oneHourAgo);
	} else {
		const yesterday = timeNow;
		yesterday.setDate(timeNow.getDate() - 1);
		const savedTimeWasYesterday = yesterday.toLocaleDateString() === savedTime.toLocaleDateString();
		if (savedTimeWasYesterday && timeDifference / MILLISECONDS_TO_HOUR > 5) {
			// yesterday and more than 5 hours ago
			formattedTime = formatMessage(i18n.yesterday, {
				time: format(savedTime, 'p'),
			});
		} else if (timeDifference / MILLISECONDS_TO_DAY < 1) {
			const roundedHours = Math.round(timeDifference / MILLISECONDS_TO_HOUR);
			formattedTime = formatMessage(i18n.xHoursAgo, { time: roundedHours });
		} else {
			formattedTime = format(savedTime, 'PP');
		}
	}
	return formattedTime;
};
