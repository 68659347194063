import React, { useState, useMemo, useCallback, useContext, useRef } from 'react';

import { startPageLoadForEditor } from './startPageLoadForEditor';

export type EditPageLoadingContextProviderProps = {
	nativeEditorComponentsPreloader: () => Promise<void>;
	children?: React.ReactNode;
};

export type LoadEditorArgs = Partial<EditLoadingContextType>;
export type EditLoadingContextType = {
	contentId: string;
	spaceKey: string | undefined;
	redirectUrl: string | null;
	contentType: string | undefined | null;

	isFabricSupported?: boolean;
	isEmbeddedContent?: boolean;
};

export type EditPageLoadingActionsContextType = {
	loadEditor: (args: LoadEditorArgs) => void;
	nativeEditorComponentsPreloader: () => Promise<void>;
};

export const defaultEditLoadingValues = {
	contentId: '',
	spaceKey: undefined,
	redirectUrl: null,
	contentType: null,

	isFabricSupported: true,
	isEmbeddedContent: false,
};

export const EditPageLoadingContext = React.createContext<EditLoadingContextType>({
	...defaultEditLoadingValues,
});

export const EditPageLoadingActionsContext = React.createContext<EditPageLoadingActionsContextType>(
	{
		loadEditor: () => {},
		nativeEditorComponentsPreloader: () => Promise.resolve(),
	},
);

export function EditPageLoadingContextProvider({
	nativeEditorComponentsPreloader,
	children,
}: EditPageLoadingContextProviderProps) {
	const [editLoadingValues, setEditLoadingValues] =
		useState<EditLoadingContextType>(defaultEditLoadingValues);

	const ref = useRef(() => Promise.resolve());
	ref.current = nativeEditorComponentsPreloader;
	const stableNativeEditorComponentsPreloader = useCallback(() => ref.current?.(), []);

	const actionsContextValue = useMemo(
		() => ({
			loadEditor: (newValues: LoadEditorArgs) => {
				if (newValues.contentId) {
					// There's a special case for loadEditor call with default values and empty contentId.
					// It cleans up loading state before navigating to editor, and IS NOT indicating the
					// start of editor loading. So we should not start editor loading measurement in this
					// case, as it is already started.
					startPageLoadForEditor(
						{
							isBlogpost: newValues.contentType === 'blogpost',
							isFabricSupported: Boolean(newValues.isFabricSupported),
							isEmbeddedContent: Boolean(newValues.isEmbeddedContent),
						},
						// NOTE: passing transitionId for editor loading measurement doesn't warrant
						// the complexity of managing it in this context. It's barely used for performance
						// measurements but, being tied to Route context may force a lot of unnecessary
						// re-renders. If this is needed in future, we can revisit this decision.
						null,
						newValues.contentId,
					);
				}

				return setEditLoadingValues((oldState) => ({ ...oldState, ...newValues }));
			},
			nativeEditorComponentsPreloader: stableNativeEditorComponentsPreloader,
		}),
		[setEditLoadingValues, stableNativeEditorComponentsPreloader],
	);

	const contextValue = useMemo(
		() => ({
			...editLoadingValues,
		}),
		[editLoadingValues],
	);

	return (
		<EditPageLoadingContext.Provider value={contextValue}>
			<EditPageLoadingActionsContext.Provider value={actionsContextValue}>
				{children}
			</EditPageLoadingActionsContext.Provider>
		</EditPageLoadingContext.Provider>
	);
}

export const useEditPageLoadingValues = (): EditLoadingContextType => {
	return useContext(EditPageLoadingContext);
};
export const useEditPageLoadingActions = (): EditPageLoadingActionsContextType => {
	return useContext(EditPageLoadingActionsContext);
};
