import React from 'react';
import { type InputEvent } from '../UIKit/types';

// The below is a fix for https://ecosystem-platform.atlassian.net/browse/EXT-2328
// The cursor always jumps to the end of the input field when the value changes due
// the runtime passing the forgedoc back to the product asynchronously.
// The workaround here is to store the last cursor position in state
// Check if this is still an issue when all products have moved to react-18
export const useCursorState = (value?: any, onChange?: (e: InputEvent) => void) => {
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [cursor, setCursor] = React.useState<number | null>(null);

	React.useEffect(() => {
		const input = inputRef.current;
		if (input && cursor !== null && input.selectionStart !== cursor) {
			input.setSelectionRange(cursor, cursor);
		}
	}, [inputRef, cursor, value]);

	const onChangeWithCursorState = React.useCallback(
		(e: InputEvent) => {
			onChange?.(e);
			if (e.target.selectionStart) {
				setCursor(e.target?.selectionStart);
			}
		},
		[onChange],
	);

	if (!onChange) {
		return { onChangeWithCursorState: undefined, inputRef };
	}

	return { onChangeWithCursorState, inputRef };
};
