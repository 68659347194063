import { createTimeoutXid, createUnknownXid, XID_TIMEOUT } from './constants';
import type { DeferredXIDPromise, XIDCallback } from './types';

export const XIDPromise = (
	xidConsent?: boolean,
	xidPromiseFn?: DeferredXIDPromise,
): Promise<XIDCallback> | undefined => {
	if (xidConsent && xidPromiseFn && typeof xidPromiseFn === 'function') {
		const timeoutPromise: DeferredXIDPromise = () => {
			return new Promise((resolve) => {
				setTimeout(() => resolve(() => createTimeoutXid()), XID_TIMEOUT);
			});
		};

		const xidPromise: DeferredXIDPromise = () => {
			return xidPromiseFn().catch(() => () => createUnknownXid());
		};

		return Promise.race([xidPromise(), timeoutPromise()]);
	}

	return undefined;
};
