import React from 'react';
import type { FC, ReactNode } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { h300Compiled } from '@confluence/typography-placeholder';

import { useIsDisabledContext } from './IsDisabledContext';

type ConfigPanelSubheadingProps = {
	children: ReactNode;
};

const configPanelSubheadingStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
	...h300Compiled,
	color: 'color.text.subtlest',
	fontSize: 'space.150',
	marginTop: 'space.0',
	textTransform: 'none',
});

const configPanelSubheadingDisabledStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
	...h300Compiled,
	color: 'color.text.disabled',
	fontSize: 'space.150',
	marginTop: 'space.0',
	textTransform: 'none',
	':hover': {
		cursor: 'not-allowed',
	},
});

export const ConfigPanelSubheading: FC<ConfigPanelSubheadingProps> = ({ children }) => {
	const isDisabled = useIsDisabledContext();

	return (
		<Box xcss={isDisabled ? configPanelSubheadingDisabledStyles : configPanelSubheadingStyles}>
			{children}
		</Box>
	);
};
