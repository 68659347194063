import { isPageLoadMetricData, isPageSegmentLoadMetricData } from '../../metric/types';
import type {
	BaseMetricDataWithStartAndStop,
	BasePageLoadMetricDataWithStartAndStop,
	PageSegmentLoadMetricDataWithStartAndStop,
	PerformanceEventConfig,
} from '../../types';

export const eventInitial = (
	_config: PerformanceEventConfig,
	data:
		| BaseMetricDataWithStartAndStop
		| PageSegmentLoadMetricDataWithStartAndStop
		| BasePageLoadMetricDataWithStartAndStop,
) => {
	if (!isPageLoadMetricData(data) && !isPageSegmentLoadMetricData(data)) {
		return null;
	}
	return {
		'event:initial': data.isInitial,
	};
};
