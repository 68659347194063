import traceUFOPageload from '@atlaskit/react-ufo/trace-pageload';
import traceUFOTransition from '@atlaskit/react-ufo/trace-transition';

import { browserMetrics } from '@atlassian/browser-metrics';

import { getMark } from '@confluence/performance';
import { pauseLowPriorityEvents } from '@confluence/analytics-event-delay';
import { WATERFALL_INITIAL_MEASURES, WaterfallMeasures } from '@confluence/action-measures';

import { browserMetricsState } from './utils';
import { getPerformanceEntries } from './collectFromPerfMarkers';
import { addAllBM3TimingsToUFO } from './addAllBM3TimingsToUFO';

/**
 * Function that begins a browser-metrics page loading timer for the current navigation.
 * Intended to be plugged into the RouteManager onRoute() callback.
 * If a page loading timer has already been started but is unfinished, this function cancels and replaces it.
 *
 * @param routeName The matched route name
 * @param transitionId The current transition ID (0 means initial)
 */
export const startBrowserMetricsPageLoad = (
	routeName = '',
	ufoName = routeName,
	transitionId: number,
	contentId = '',
): void => {
	// Begin the loading timer _immediately_ using BM libraries generic meta startPageLoad metric.
	// Routes should later call stopPageLoad on the actual PageLoadMetric that is specifically defined for
	// the route in order to claim this page load.
	browserMetrics.startPageLoad({ isInitial: transitionId === 0 });

	if (transitionId === 0) {
		traceUFOPageload(ufoName.toLocaleLowerCase());
	} else {
		traceUFOTransition(ufoName.toLocaleLowerCase());
	}

	performance.mark('browser-metric.start');

	const pageLoadMetric = browserMetrics.getPageLoadMetric();

	browserMetricsState.transitionId = transitionId;
	browserMetricsState.pageLoadStartTimestamp = pageLoadMetric.getData().start ?? performance.now();

	browserMetricsState.previousRoute = browserMetricsState.routeName;
	browserMetricsState.routeName = routeName;
	browserMetrics.setRoute(routeName);

	browserMetricsState.previousContentId = browserMetricsState.contentId;
	browserMetricsState.contentId = contentId;

	// will pause low priority events to avoid impacting page load performance
	// once the pageLoadEnd is triggered the resumeLowPriorityEvents will be called
	void pauseLowPriorityEvents(routeName);

	if (pageLoadMetric.isInitialLoad() && window?.__SSR_RENDERED__) {
		// Mark the legacy FMP point if server-side rendered
		pageLoadMetric.mark('fy21-legacy-fmp', getMark('CFP-63.ssr-ttr'));
	}
	pageLoadMetric.onStop(
		() => {
			if (pageLoadMetric.isInitialLoad()) {
				const t = window.performance?.getEntriesByType?.('navigation')?.[0] as
					| PerformanceNavigationTiming
					| undefined;
				if (t) {
					// Redirection time
					if (t.redirectStart >= 0 && t.redirectEnd >= t.redirectStart) {
						WaterfallMeasures.markMeasureStart(
							WATERFALL_INITIAL_MEASURES.REDIRECTION,
							t.redirectStart,
						);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.REDIRECTION, t.redirectEnd);
					}

					// DNS resolution
					if (t.domainLookupStart >= 0 && t.domainLookupEnd >= t.domainLookupStart) {
						WaterfallMeasures.markMeasureStart(WATERFALL_INITIAL_MEASURES.DNS, t.domainLookupStart);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.DNS, t.domainLookupEnd);
					}

					// TCP connection
					if (t.connectStart >= 0 && t.connectEnd >= t.connectStart) {
						WaterfallMeasures.markMeasureStart(WATERFALL_INITIAL_MEASURES.TCP, t.connectStart);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.TCP, t.connectEnd);
					}

					// TLS handshake
					if (t.secureConnectionStart >= 0 && t.connectEnd >= 0) {
						WaterfallMeasures.markMeasureStart(
							WATERFALL_INITIAL_MEASURES.TLS,
							t.secureConnectionStart,
						);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.TLS, t.connectEnd);
					}

					// Response
					if (t.requestStart >= 0 && t.responseStart >= t.requestStart) {
						WaterfallMeasures.markMeasureStart(WATERFALL_INITIAL_MEASURES.RESPONSE, t.requestStart);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.RESPONSE, t.responseStart);
					}

					// TTFB
					if (t.startTime >= 0 && t.responseStart >= 0) {
						WaterfallMeasures.markMeasureStart(WATERFALL_INITIAL_MEASURES.TTFB, t.startTime);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.TTFB, t.responseStart);
					}

					// SSR complete
					if (t.requestStart >= 0 && t.responseEnd >= t.requestStart) {
						WaterfallMeasures.markMeasureStart(
							WATERFALL_INITIAL_MEASURES.FULL_HTML,
							t.requestStart,
						);
						WaterfallMeasures.markMeasureEnd(WATERFALL_INITIAL_MEASURES.FULL_HTML, t.responseEnd);
					}
				}
			}

			// Copy all /wf markers to the page load metric
			// - window.performance.mark('wf/something.start/.end')
			// - WaterfallMetrics.run()
			// - WaterfallMetrics.markMeasureStart/markMeasureEnd()
			const currentMetric = browserMetrics.getPageLoadMetric();
			getPerformanceEntries('wf/').forEach(({ name, startTime }) => {
				currentMetric.mark(name, startTime);
			});

			// NOTE: currentMetric is stopping _too early_ and some segment-end marks are added _after_ the metric is already stopped
			// To see this, console.log the following and compare the outputs:
			// console.log({ ...currentMetric.getData().marks }); // the marks available at the time of the object spread
			// console.log(currentMetric.getData().marks); // the marks available when browser can log to the console
			// what is logged at the bottom console.log is the contents of the memory reference of (currentMetric.getData().marks, which seems to be getting modified at a later point in time.
			addAllBM3TimingsToUFO(currentMetric.getData().marks);
		},
		() => {},
	);
};
