import {
	matchHome,
	matchFabricEditor,
	matchSpaceOverview,
	matchViewPage,
	matchCreateContent,
	matchWhiteboardPage,
} from '@confluence/query-preloaders/src/matchRoutes';

/**
 * Preload components/chunks that are required for the critical path of the current route,
 * when they're not preloaded as part of SSR page delivery. This may happen when:
 *  - page is not SSR'ed due to some failure, or
 *  - no SSR enabled for the page
 */
export const preloadComponents = async (url, isTransition) => {
	const shouldPreload = isTransition || !window.__SSR_RENDERED__;

	const match = matchHome(url);
	if (match) {
		(
			await import(/* webpackChunkName: "loadable-preloadershome" */ './preloaders/home')
		).preloadHome(match);
	} else if (matchSpaceOverview(url) && shouldPreload) {
		(
			await import(
				/* webpackChunkName: "loadable-preloadersspace-overview" */ './preloaders/space-overview'
			)
		).preloadSpaceOverview();
	} else if (matchViewPage(url) && shouldPreload) {
		(
			await import(/* webpackChunkName: "loadable-preloadersview-page" */ './preloaders/view-page')
		).preloadViewPage();
	} else if (matchFabricEditor(url)) {
		(
			await import(
				/* webpackChunkName: "loadable-nativeEditorComponentsPreloader" */ '@confluence-classic/confluence-fabric-editor/src/lib'
			)
		).nativeEditorComponentsPreloader();
	} else if (matchCreateContent(url)) {
		(
			await import(
				/* webpackChunkName: "loadable-preloaderscreate-content" */ './preloaders/create-content'
			)
		).preloadCreateContentRoute();
	} else if (matchWhiteboardPage(url)) {
		(
			await import(
				/* webpackChunkName: "loadable-preloaderswhiteboard-page" */ './preloaders/whiteboard-page'
			)
		).preloadWhiteboardPage();
	}
};
