export let userAgent: string | undefined;

export const setUserAgent = (agent?: string) => {
	if (agent) {
		userAgent = agent;
	} else if (typeof window !== 'undefined' && window.navigator) {
		userAgent = window.navigator.userAgent;
	}
};

export const reset = () => {
	userAgent = undefined;
};

export const isFirefox = () => {
	return userAgent?.indexOf('Firefox/') !== -1;
};

export const notFirefox = () => {
	return !isFirefox();
};

export const isMSEdge = () => {
	return userAgent?.indexOf('Edge/') !== -1;
};

export const notMSEdge = () => {
	return !isMSEdge();
};

export const isIE = () => {
	return userAgent?.indexOf('MSIE') !== -1 || userAgent?.indexOf('Trident/') !== -1 || isMSEdge();
};

export const notIE = () => {
	return !isIE();
};

export const isChrome = () => {
	return userAgent?.indexOf('Chrome/') !== -1;
};

export const notChrome = () => {
	return !isChrome();
};

export const isSafari = () => {
	return userAgent?.indexOf('Safari/') !== -1 && userAgent?.indexOf('Chrome/') === -1;
};

export const notSafari = () => {
	return !isSafari();
};

export const isPhantom = () => {
	return userAgent?.indexOf('PhantomJS') !== -1;
};

export const notPhantom = () => {
	return !isPhantom();
};

export const isNativeMobile = () => {
	return (
		!!userAgent &&
		(userAgent?.indexOf('Confluence iOS/') !== -1 ||
			userAgent?.indexOf('Confluence Android/') !== -1)
	);
};

export const version = () => {
	let versionMatch: RegExpMatchArray | null | undefined;

	if (isIE()) {
		versionMatch =
			userAgent?.match(/MSIE\s([\d.]+)/) ||
			userAgent?.match(/rv:([\d.]+)/) ||
			userAgent?.match(/Edge\/([\d.]+)/);
	} else if (isChrome()) {
		versionMatch = userAgent?.match(/Chrome\/([\d.]+)/);
	} else if (isSafari()) {
		versionMatch = userAgent?.match(/Version\/([\d.]+)/);
	} else if (isFirefox()) {
		versionMatch = userAgent?.match(/Firefox\/([\d.]+)/);
	}

	return versionMatch && parseInt(versionMatch[1]);
};

export const friendlyName = () => {
	if (isMSEdge()) {
		return 'MSEdge';
	}
	if (isIE()) {
		return 'IE';
	}
	if (isChrome()) {
		return 'Chrome';
	}
	if (isSafari()) {
		return 'Safari';
	}
	if (isFirefox()) {
		return 'Firefox';
	}
};
