import { useQuery } from '@apollo/react-hooks';
import type { FC } from 'react';
import React, { useContext } from 'react';

import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import { LoadableLazy } from '@confluence/loadable';

import type { NetworkStatusListenerQuery as TData } from './__types__/NetworkStatusListenerQuery';
import { ClientNetworkStatus } from './__types__/NetworkStatusListenerQuery';
import { NetworkStatusListenerQuery } from './NetworkStatusListenerQuery.graphql';

const NetworkStatusFlagLoader = LoadableLazy({
	loader: () => import(/* webpackChunkName: "loadable-NetworkStatusFlag" */ './NetworkStatusFlag'),
});

export const NetworkStatusListener: FC = () => {
	const { data } = useQuery<TData>(NetworkStatusListenerQuery, {
		fetchPolicy: 'cache-only',
	});
	const status = data?.network?.status;

	// 1. experience-tracker
	const experienceTracker = useContext(ExperienceTrackerContext);
	let reason: string | undefined;

	switch (status) {
		case ClientNetworkStatus.IP_BLOCKED:
			reason = 'IP blocked';
			break;
		case ClientNetworkStatus.OFFLINE:
			reason = 'Network unavailable';
			break;
	}
	if (reason) {
		experienceTracker.abort({
			reason,
			checkForTimeout: false,
		});
	}

	// 2. flags
	return <NetworkStatusFlagLoader status={status} />;
};
