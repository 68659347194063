import gql from 'graphql-tag';

const viewPageContentDetailsFragment = gql`
	fragment viewPageContentDetailsFragment on Content {
		id
		type
		status
		title
	}
`;

export const ViewPageQuery = gql`
	query ViewPageQuery($contentId: ID, $versionOverride: Int, $embeddedContentRender: String) {
		content(
			id: $contentId
			version: $versionOverride
			embeddedContentRender: $embeddedContentRender
		) {
			nodes {
				id
				type
				status
				title
				properties(key: "editor") {
					nodes {
						id
						key
						value
					}
				}
				operations {
					operation
					targetType
				}
				space {
					id
					key
					name
				}
				history {
					createdDate
				}
				ancestors {
					...viewPageContentDetailsFragment
				}
			}
		}
	}

	${viewPageContentDetailsFragment}
`;
