import React from 'react';
import ExternalShareContentRenderer from '@confluence-classic/confluence-fabric-editor/src/components/ExternalShareContentRenderer';

import type { RouteMatch } from '@confluence/route';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { LoadablePaint } from '@confluence/loadable';

const ExternalSharePageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExternalShareRouteComponent" */ '@confluence/external-share/entry-points/ExternalSharePage'
			)
		).ExternalSharePage,
});

export const ExternalShareRoute = ({ params: { contentId, externalToken }, hash }: RouteMatch) => (
	<ExternalSharePageLoader
		siteUrl={window.location.hostname}
		contentId={contentId === 'undefined' ? undefined : contentId}
		shareToken={externalToken}
		queryHash={hash}
		ContentRenderer={ExternalShareContentRenderer}
	/>
);

ExternalShareRoute.NAVIGATION_PARAMS = ({ name, params: { contentId } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'viewPageScreen',
			id: contentId,
			attributes: {
				isPublicView: true,
				pageLoadInfo: getUniquePageLoadId(),
			},
		},
		pageState: {
			contentId,
			routeName: name,
		},
	},
	MainLayout: false,
	BannerContext: { includeTopNav: false },
});
