import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import { preloadEditorOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadEditorOnboardingState';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { preloadDraft } from '@confluence/fabric-providers/entry-points/preloadDraft';
import { preloadBreadcrumbs } from '@confluence/breadcrumbs/entry-points/preloadBreadcrumbs';
import {
	preloadContentDataClassificationQuery,
	preloadDataClassificationEnabledQuery,
	preloadDefaultAndSpaceClassificationLevelsQuery,
} from '@confluence/data-classification/entry-points/queries';
import { ContentStatus } from '@confluence/data-classification/entry-points/contentStatus';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { fg } from '@confluence/feature-gating';

import { matchEditBlog } from './matchRoutes';

export const preloadEditPageRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { featureFlags, userId, isLicensed, cloudId } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { contentId, spaceKey } = match.params;

	const isSpaceAliasFFEnabled = Boolean(featureFlags['confluence.frontend.space.alias']);
	const isBlog = Boolean(matchEditBlog(_url));

	const shouldPreloadMentionsProvider = Boolean(process.env.REACT_SSR);

	tasks.push(
		preloadBreadcrumbs(spaceKey, contentId, false, isSpaceAliasFFEnabled),
		preloadEditorData({
			spaceKey,
			userId,
			contentId,
			cloudId,
			preloadNativeCollabData: true,
			shouldPreloadMentionsProvider,
			shouldPreloadNativeEditorData: false,
			isBlog,
			isTransition,
		}),
	);

	if (isLicensed) {
		tasks.push(preloadEditorOnboardingState());
	}

	if (isTransition) {
		return Promise.all(tasks);
	}

	if (process.env.REACT_SSR) {
		tasks.push(
			preloadSideNavigation(spaceKey),
			preloadSideNavigationNeedsContentId(spaceKey, contentId),
			preloadSpaceGuestList(spaceKey),
			preloadShortcutsSmartLinks(spaceKey),
			preloadPageTree({
				spaceKey,
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
				contentId,
			}),
		);

		tasks.push(preloadMediaToken(contentId));

		if (!fg('confluence_user_settings_query_migration')) {
			tasks.push(preloadDataClassificationEnabledQuery());
		}

		if (fg('dlp_cc-ellipsis-classify-content')) {
			tasks.push(preloadContentDataClassificationQuery(contentId, ContentStatus.DRAFT));
			tasks.push(preloadDefaultAndSpaceClassificationLevelsQuery(spaceKey));
		}
	} else {
		tasks.push(preloadDraft(contentId));
	}

	tasks.push(preloadSpaceDetail(spaceKey));

	return Promise.all(tasks);
};
