export function appendHeader(headers: HeadersInit, headerName: string, headerValue: string): void {
	if (Array.isArray(headers)) {
		headers.push([headerName, headerValue]);
		return;
	}

	if (headers instanceof Headers) {
		headers.append(headerName, headerValue);
		return;
	}

	headers[headerName] = headerValue;
}
