import gql from 'graphql-tag';

/*
A problem exists with the Babel compiler where updating fragments does not refresh the cache.
When altering this fragment, make sure to include a comment to clear the cache for the primary queries that rely on this fragment.
Add the comment "*comment to invalidate cache*" to achieve this.

Refer to this example: https://sourcegraph-frontend.internal.shared-prod.us-west-2.kitt-inf.net/stash.atlassian.com/ATLASSIAN/atlassian-frontend-monorepo/-/blob/confluence/next/packages/whiteboard-common/src/graphql/WhiteboardUnifiedQuery.graphql.ts?L5
*/
export const WhiteboardTokenFragment = gql`
	fragment WhiteboardTokenFragment on Query {
		canvasToken(contentId: $contentId) {
			token
		}
		contentMediaSession(contentId: $contentId) {
			accessTokens {
				readOnlyToken {
					value
				}
				readWriteToken {
					value
				}
			}
			configuration {
				fileStoreUrl
			}
		}
		entitlements {
			whiteboardFeatures {
				smartConnectors {
					isEntitled
				}
				smartSections {
					isEntitled
				}
			}
		}
	}
`;
