import { version as reactVersion } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';
import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getBuildInfo } from '@confluence/build-info';
import { getMark } from '@confluence/performance';
import { getMetalClient } from '@confluence/metal-client';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
import type { SessionDataType } from '@confluence/session-data';
import { getLogger } from '@confluence/logger';

import { bundleEvalTimings } from './plugins/bundleEvalTimings';
import { getCustomEventConfigs } from './utils';
import { startLSObserver, startLTObserver } from './plugins/lighthouse-metrics';

let isBrowserMetricsInitialized = false;

const logger = getLogger('initializeBrowserMetrics');
const criticalAssetsList = [
	'app',
	'atl',
	'runtime',
	'vendors',
	'ssr-scripts',
	'stable_vendors',
	'query-preloader',
	'browser-not-supported',
];

function mapResources(url: string) {
	return criticalAssetsList.find((asset) => url.includes(`/${asset}.`)) || 'ignored';
}

/**
 * Initializes the Browser Metrics library with Confluence specific configurations.
 *
 * Should be called before any metrics are fired.
 */
export const initializeBrowserMetrics = (sessionData: SessionDataType): void => {
	if (!isBrowserMetricsInitialized) {
		const { featureFlags, shard } = sessionData;
		const getAnalyticsWebClientPromise = getAnalyticsWebClient();
		const product = isEmbeddedConfluence_DO_NOT_USE() ? 'embeddedConfluence' : 'confluence';

		if (featureFlags['confluence.frontend.enable-ufo']) {
			import(/* webpackChunkName: "loadable-initializeUFOPublisher" */ './initializeUFOPublisher')
				.then((ufo) => ufo.setup(sessionData, getAnalyticsWebClientPromise))
				.catch((error) => {
					logger.error`Fail to import UFO client: ${error}`;
				});
		}

		startLSObserver();
		startLTObserver();

		browserMetrics.init({
			info: {
				app: {
					framework: {
						name: 'react',
						version: reactVersion,
					},
					version: {
						web: getBuildInfo().FRONTEND_VERSION,
						ssr: window.__SSR_BUILD__,
					},
				},
				hostname: window.location.hostname,
				product,
				region: shard,
			},
			plugins: {
				featureFlags: {
					client: Promise.resolve({
						getValue: (featureFlagKey: string) =>
							featureFlags[featureFlagKey] ?? getSSRFeatureFlag(featureFlagKey),
					}),
				},
				resourceTimings: {
					mapResources,
					// Not interested in fetch requests as we have already marked wf/
					sanitiseEndpoints: () => 'ignored',
				},
				bundleEvalTimings,
			},
			endpoints: {
				eventPipelineClient: getAnalyticsWebClientPromise,
				metalClient: getMetalClient(),
			},
			events: getCustomEventConfigs(sessionData),
			ssr: {
				getDoneMark: () => getMark('CFP-63.ssr-ttr') ?? null,
				getTimings: () => (window as any).__SSR_MEASURES__ || {},
			},
			enableReactUFOTracker: true,
		});

		isBrowserMetricsInitialized = true;
	}
};
