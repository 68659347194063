import gql from 'graphql-tag';

export const NativeCollabProviderQuery = gql`
	query NativeCollabProviderQuery($accountId: ID) {
		user(id: $accountId) {
			id
			displayName
			photos {
				value
				isPrimary
			}
		}
	}
`;
