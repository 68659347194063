import gql from 'graphql-tag';

export const GlobalOperationsQuery = gql`
	query GlobalOperationsQuery {
		globalOperations {
			operation
			targetType
		}
	}
`;
