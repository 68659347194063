import React, { useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import InformationIcon from '@atlaskit/icon/utility/information';
import { Box, Stack, Text, Flex, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { LinkButton } from '@atlaskit/button/new';
import ArrowUpRightIcon from '@atlaskit/icon/core/arrow-up-right';
import Avatar from '@atlaskit/avatar';

import {
	Attribution,
	isUnauthorizedError,
	withErrorBoundary,
	ErrorDisplay,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { COMPANY_HUB_EDIT } from '@confluence/named-routes';
import { timeFormatter } from '@confluence/save-indicator';
import { SPAViewContext } from '@confluence/spa-view-context';
import { FrontCoverStateEnum } from '@confluence/route-manager/entry-points/companyHubUtils';

import type {
	HubInfoQueryVariables,
	HubInfoQuery as HubInfoQueryType,
} from './__types__/HubInfoQuery';
import { HubInfoQuery } from './HubInfoQuery.graphql';
import { HubActionsDropdown } from './HubActionsDropdown';

export const i18n = defineMessages({
	publishedPill: {
		id: 'company-hub.hub-settings.general.dashboard.pill.published',
		defaultMessage: 'Published',
		description: 'Pill that informs the user if their hub is in a published state',
	},
	publishedTooltip: {
		id: 'company-hub.hub-settings.general.dashboard.tooltip.published',
		defaultMessage: 'Your hub is visible to everyone in the company',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	notPublishedPill: {
		id: 'company-hub.hub-settings.general.dashboard.pill.not-published',
		defaultMessage: 'Not published',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	notPublishedTooltip: {
		id: 'company-hub.hub-settings.general.dashboard.tooltip.not-published',
		defaultMessage:
			'Your hub is only visible to you and other admin and editors. The rest of the company will not see your hub until you publish.',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	versionLabel: {
		id: 'company-hub.hub-settings.general.dashboard.version-label',
		defaultMessage: 'Version {versionNumber}',
		description: 'The version number of the Company hub page content',
	},
	lastUpdatedText: {
		id: 'company-hub.hub-settings.general.dashboard.edited-time',
		defaultMessage: 'Edited {formattedTime}',
		description:
			'Text that displays to the user the last time the Company hub page content was updated',
	},
	editHubButtonLabel: {
		id: 'company-hub.hub-settings.general.dashboard.edit-hub-button-label',
		defaultMessage: 'Edit hub',
		description: 'Label of button that takes the user to the Company hub editor',
	},
});

const loadingStyles = xcss({
	width: '100%',
	minWidth: '250px',
	borderRadius: '6px',
	border: `1px solid ${token('color.border')}`,
	background: token('elevation.surface.overlay'),
	height: '174px',
});

const containerStyles = xcss({
	width: '100%',
	minWidth: '250px',
	borderRadius: '6px',
	border: `1px solid ${token('color.border')}`,
	background: token('elevation.surface.overlay'),
	padding: 'space.200',
	display: 'flex',
	gap: 'space.200',
	alignSelf: 'stretch',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const customTooltipContent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	background: token('elevation.surface.overlay'),
	borderRadius: token('border.radius', '4px'),
	boxShadow: token('elevation.shadow.overlay'),
	boxSizing: 'content-box',
	color: token('color.text'),
	maxHeight: '300px',
	maxWidth: '300px',
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
});

const HubStatus = ({ isPublished }: { isPublished: boolean }) => {
	const { formatMessage } = useIntl();
	return (
		<Flex gap="space.050" alignItems="center">
			<Lozenge appearance={isPublished ? 'success' : undefined}>
				<FormattedMessage {...(isPublished ? i18n.publishedPill : i18n.notPublishedPill)} />
			</Lozenge>
			<Tooltip
				component={customTooltipContent}
				position="bottom-start"
				content={formatMessage(isPublished ? i18n.publishedTooltip : i18n.notPublishedTooltip)}
			>
				<InformationIcon label="" color={token('color.icon')} />
			</Tooltip>
		</Flex>
	);
};

const UserTooltip = ({
	displayName,
	profilePicturePath,
}: {
	displayName?: string;
	profilePicturePath?: string;
}) => (
	<Tooltip content={displayName}>
		<Avatar src={profilePicturePath} name={displayName} size="small" />
	</Tooltip>
);

const EditHubButton = () => (
	<LinkButton iconBefore={ArrowUpRightIcon} href={COMPANY_HUB_EDIT.toUrl()}>
		<FormattedMessage {...i18n.editHubButtonLabel} />
	</LinkButton>
);

type HubDashboardProps = {
	spaceKey: string;
};

const HubDashboardComponent = ({ spaceKey }: HubDashboardProps) => {
	const { frontCoverState, companyHubName } = useContext(SPAViewContext);
	const { formatMessage } = useIntl();
	const isHubPublished = frontCoverState === FrontCoverStateEnum.SHOWN;

	const { data, loading, error } = useQuery<HubInfoQueryType, HubInfoQueryVariables>(HubInfoQuery, {
		variables: { key: spaceKey },
	});

	if (loading) {
		return <Box xcss={loadingStyles} testId="loading-state" />;
	}

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
			return <Box testId="unauthorized-error-state" />;
		} else {
			return (
				<ErrorDisplay error={error}>
					<Box testId="generic-error-state" />
				</ErrorDisplay>
			);
		}
	}

	const versionData = data?.space?.homepageV2?.version;
	const displayName = versionData?.by?.displayName ?? '';
	const profilePicturePath = versionData?.by?.profilePicture?.path;
	const lastUpdated = versionData?.when ? new Date(versionData.when) : null;
	const formattedTime =
		lastUpdated && !isNaN(lastUpdated.getTime()) ? timeFormatter(lastUpdated, formatMessage) : null;
	const lastUpdatedInfo = (
		<Flex gap="space.050" alignItems="center">
			<UserTooltip displayName={displayName} profilePicturePath={profilePicturePath} />
			<Text color="color.text.subtle">
				<FormattedMessage {...i18n.lastUpdatedText} values={{ formattedTime }} />
			</Text>
		</Flex>
	);

	const versionNumber = versionData?.number;
	return (
		<Box xcss={containerStyles}>
			<Stack space="space.250">
				<HubStatus isPublished={isHubPublished} />
				<Stack space="space.050">
					<Text size="large" weight="semibold">
						{companyHubName}
					</Text>
					{versionNumber ? (
						<Text color="color.text.subtle">
							<FormattedMessage {...i18n.versionLabel} values={{ versionNumber }} />
						</Text>
					) : null}
				</Stack>
				{lastUpdated ? lastUpdatedInfo : null}
			</Stack>
			<Flex gap="space.050" alignItems="start">
				<EditHubButton />
				<HubActionsDropdown spaceKey={spaceKey} isHubPublished={isHubPublished} />
			</Flex>
		</Box>
	);
};

export const HubDashboard = withErrorBoundary({
	attribution: Attribution.COMPANY_HUB,
})(HubDashboardComponent);
