import type { CustomField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';

export interface AttachmentMacroParams {
	labels?: string | string[];
	spaces?: string | string[];
}

const FIELDS_TO_FIX = ['labels', 'spaces'];

export const blogPosts: FieldTransformer<AttachmentMacroParams> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (!FIELDS_TO_FIX.includes(field.name)) {
				return field;
			}

			let options;
			switch (field.name) {
				// Converts the "labels" field to be a multi-select
				// which is not supported by the old macro browser
				case 'labels':
					options = {
						resolver: {
							type: 'label',
						},
					};
					break;
				case 'spaces':
					// restrict to particular spaces
					// supports special values like @self
					options = {
						resolver: {
							type: 'blogRecentlyUpdatedSpace',
						},
					};
					break;
				default:
					options = {};
					break;
			}

			return {
				...field,
				options,
				// description talks about entering comma separated syntax
				// which no longer applies as users are now just picking from a dropdown
				description: undefined,
				type: 'custom',
				isMultiple: true,
			} as CustomField;
		});
	},
	// Custom field receives an array
	transformBefore(params) {
		FIELDS_TO_FIX.forEach((field) => {
			if (params[field] && typeof params[field] === 'string') {
				params[field] = params[field].split(',');
			}
		});
		return params;
	},
	transformAfter(params) {
		FIELDS_TO_FIX.forEach((field) => {
			if (Array.isArray(params[field])) {
				params[field] = params[field].join(',');
			}
		});
		return params;
	},
};
