import gql from 'graphql-tag';

export const ContentSmartLinksQuery = gql`
	query ContentSmartLinksQuery($contentId: ID!) {
		contentSmartLinks(id: $contentId) {
			nodes {
				__typename
				url
			}
		}
	}
`;
