import memoize from 'memoize-one';

import { fg } from '@confluence/feature-gating';

import {
	EDIT_PAGE_EXPERIENCE,
	LIVE_PAGE_EDIT_EXPERIENCE,
	LIVE_PAGE_VIEW_EXPERIENCE,
	VIEW_PAGE_EXPERIENCE,
} from './ExperienceName';

export const getLivePageViewExperienceName: () => string = memoize(() => {
	return fg('live_page_exp_to_core_exp_migration')
		? VIEW_PAGE_EXPERIENCE
		: LIVE_PAGE_VIEW_EXPERIENCE;
});

export const getLivePageEditExperienceName: () => string = memoize(() => {
	return fg('live_page_exp_to_core_exp_migration')
		? EDIT_PAGE_EXPERIENCE
		: LIVE_PAGE_EDIT_EXPERIENCE;
});
