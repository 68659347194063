import { LoadableBackground } from '@confluence/loadable';

export const LazyCreateDialogBlueprintWizardRenderer = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PseudoWizardCreateDialog" */ './PseudoWizardCreateDialog'
			)
		).CreateDialogBlueprintWizardRenderer,
});
