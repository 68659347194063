import { elementToForgeDoc } from '../../../../utils/elementToForgeDoc';
import { sanitizeUrl } from '../../../../utils';

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::610241e938abb9d122d1be3b3b80264f>>
 * @codegenId #tag
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tag
 * @codegenParams {"props": {"text": true, "appearance": true, "color": true, "elemBefore": {"value": "elemBefore ? props.render(elementToForgeDoc(elemBefore as React.ReactElement)): null", "comment": "forge/react reconciler does not convert components in props into ForgeDoc. We need to do it manually here"}, "href": { "value": "href ? sanitizeUrl(href) : undefined" }, "linkComponent": false, "testId": true}}
 * @codegenDependency ../../../../../../../design-system/tag/src/tag/internal/shared/types.tsx <<SignedSource::ddee00cd6bf2d109ae72bb6d864fdf41>>
 * @codegenDependency ../../../../../../../design-system/tag/src/index.tsx <<SignedSource::731b748ac22451769ad35af62f36c14d>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { SimpleTag as PlatformSimpleTag } from '@atlaskit/tag';

type PlatformSimpleTagProps = React.ComponentProps<typeof PlatformSimpleTag>;

export type SimpleTagProps = Pick<
  PlatformSimpleTagProps,
  'text' | 'appearance' | 'color' | 'elemBefore' | 'href' | 'testId'
>;

export const SimpleTag = (props: Parameters<RenderFn>[0]) => {
  const {
    text,
    appearance,
    color,
    elemBefore,
    href,
    testId,
  } = props.forgeDoc.props as SimpleTagProps;
  return (
    <PlatformSimpleTag
      text={text}
      appearance={appearance}
      color={color}
      // forge/react reconciler does not convert components in props into ForgeDoc. We need to do it manually here
      elemBefore={elemBefore ? props.render(elementToForgeDoc(elemBefore as React.ReactElement)): null}
      href={href ? sanitizeUrl(href) : undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
