import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { SpaceIdentifiersQuery } from './SpaceIdentifiersQuery.graphql';
import type {
	SpaceIdentifiersQuery as SpaceIdentifiersQueryType,
	SpaceIdentifiersQueryVariables,
} from './__types__/SpaceIdentifiersQuery';

type SpaceIdentifiersType = {
	spaceId: string | undefined;
	spaceKey: string | undefined;
};

/**
 * This hook should only be used in rare cases where the system space key is needed.
 * Please consider using useSpaceKey or useSpaceId instead.
 * @param spaceKey The space key to get the identifiers for. If not provided, the current space key will be used.
 * @returns The space id and system space key for the given space key.
 */
export const useSpaceIdentifiers = (spaceKey?: string): SpaceIdentifiersType => {
	const [pageContextSpaceKey] = usePageSpaceKey();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	// The given space key may be an alias, not the actual key.
	spaceKey = spaceKey ?? pageContextSpaceKey;
	const { data } = useQuery<SpaceIdentifiersQueryType, SpaceIdentifiersQueryVariables>(
		SpaceIdentifiersQuery,
		{
			variables: { spaceKey, includeKey: isSpaceAliasFFEnabled },
			skip: !spaceKey,
		},
	);
	return {
		spaceId: data?.space?.id || undefined,
		// The returned space key is the actual key, not an alias.
		spaceKey: data?.space?.key || undefined,
	};
};
