import React from 'react';
import type { ReactNode } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';

import Link from '@atlaskit/link';
import PageHeader from '@atlaskit/page-header';
import Heading from '@atlaskit/heading';
import { Text, Box, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, useTabPanel } from '@atlaskit/tabs';

import { SpaceSettingsBreadcrumbs } from '@confluence/breadcrumbs/entry-points/SpaceSettingsBreadcrumbs';
import { DocumentContentTitle } from '@confluence/document-title';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import {
	ADMIN_COMPANY_HUB_PERMISSIONS,
	COMPANY_HUB_ANALYTICS,
	COMPANY_HUB_SETTINGS,
} from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import {
	PageContainer,
	PageHeaderContainer,
} from '@confluence/site-settings/entry-points/Components';

import { CompanyHubAnalyticsPage } from '../analytics/CompanyHubAnalyticsPage';

import { GeneralPageContent } from './GeneralPageContent';
import { PermissionsPageContent } from './PermissionsPageContent';

export const ATLASSIAN_COMPANY_HUB_DOCS_URL =
	'https://support.atlassian.com/confluence-cloud/docs/create-and-customize-a-company-hub';

export const i18n = defineMessages({
	pageTitle: {
		id: 'company-hub.hub-settings.header-title',
		defaultMessage: 'Company Hub settings',
		description:
			'Title of the Company hub settings page where users can edit all of their hub preferences',
	},
	headerDescription: {
		id: 'company-hub.hub-settings.header-description',
		defaultMessage:
			'Company Hub is your dynamic and customized info central, where employees can go to get the verified, up-to-date news, announcements, and resources they need most. <link>Learn more</link>',
		description:
			'Description area below title where users can learn about the hub preferences and also access the Company hub documentation',
	},
	generalTabTitle: {
		id: 'company-hub.hub-settings.navigation.general-title',
		defaultMessage: 'General',
		description: 'Navigation title of the hub general settings page',
	},
	analyticsTabTitle: {
		id: 'company-hub.hub-settings.navigation.analytics-title',
		defaultMessage: 'Analytics',
		description: 'Navigation title of the hub analytics page',
	},
	usersTabTitle: {
		id: 'company-hub.hub-settings.navigation.users-title',
		defaultMessage: 'Users',
		description: 'Navigation title of the users hub permissions page',
	},
});

export enum CompanyHubSettingsPageTabsType {
	GENERAL = 0,
	ANALYTICS = 1,
	USERS = 2,
}

const HUB_SETTINGS_NAVIGATION_TABS: Array<{ i18nLabel: MessageDescriptor; url: string }> = [];
HUB_SETTINGS_NAVIGATION_TABS[CompanyHubSettingsPageTabsType.GENERAL] = {
	i18nLabel: i18n.generalTabTitle,
	url: COMPANY_HUB_SETTINGS.toUrl({ tab: 'general' }),
};
HUB_SETTINGS_NAVIGATION_TABS[CompanyHubSettingsPageTabsType.ANALYTICS] = {
	i18nLabel: i18n.analyticsTabTitle,
	url: COMPANY_HUB_ANALYTICS.toUrl(),
};
HUB_SETTINGS_NAVIGATION_TABS[CompanyHubSettingsPageTabsType.USERS] = {
	i18nLabel: i18n.usersTabTitle,
	url: ADMIN_COMPANY_HUB_PERMISSIONS.toUrl(),
};

const customPanelStyles = xcss({
	marginTop: 'space.400',
});

const CustomTabPanel = ({ children }: { children: ReactNode }) => {
	const tabPanelAttributes = useTabPanel();
	return (
		<Box xcss={customPanelStyles} {...tabPanelAttributes}>
			{children}
		</Box>
	);
};

type CompanyHubSettingsPageProps = {
	tab?: CompanyHubSettingsPageTabsType;
};

export const CompanyHubSettingsPage = ({
	tab = CompanyHubSettingsPageTabsType.GENERAL,
}: CompanyHubSettingsPageProps) => {
	const { push } = useRouteActions();
	const { formatMessage } = useIntl();

	const spaceKey = getCompanyHubSpaceKey();
	const pageTitle = formatMessage(i18n.pageTitle);

	const handleTabChange = (index: number) => {
		push(HUB_SETTINGS_NAVIGATION_TABS[index].url);
	};

	return (
		<PageContainer>
			<PageHeaderContainer>
				{!fg('confluence_nav_4') && (
					<TransparentErrorBoundary attribution={Attribution.COMPANY_HUB}>
						<SpaceSettingsBreadcrumbs
							spaceKey={spaceKey}
							source="companyHubSettings"
							currentSection="companyHubSettingsBackButton"
						/>
					</TransparentErrorBoundary>
				)}
				<PageHeader>
					<Heading size="xlarge">{pageTitle}</Heading>
				</PageHeader>
				<Text color="color.text.subtle">
					<FormattedMessage
						{...i18n.headerDescription}
						values={{
							link: (chunks: React.ReactNode[]) => (
								<Link appearance="subtle" target="_blank" href={ATLASSIAN_COMPANY_HUB_DOCS_URL}>
									{chunks}
								</Link>
							),
						}}
					/>
				</Text>
			</PageHeaderContainer>
			<Tabs id="hub-settings-tabs" onChange={handleTabChange} defaultSelected={tab}>
				<TabList>
					{HUB_SETTINGS_NAVIGATION_TABS.map(({ i18nLabel }) => (
						<Tab key={i18nLabel.id}>
							<FormattedMessage {...i18nLabel} />
						</Tab>
					))}
				</TabList>
				<CustomTabPanel>
					<DocumentContentTitle
						titlePrefix={formatMessage(i18n.generalTabTitle)}
						contentTitle={pageTitle}
					/>
					<GeneralPageContent />
				</CustomTabPanel>
				<CustomTabPanel>
					<DocumentContentTitle
						titlePrefix={formatMessage(i18n.analyticsTabTitle)}
						contentTitle={pageTitle}
					/>
					<CompanyHubAnalyticsPage spaceKey={spaceKey} />
				</CustomTabPanel>
				<CustomTabPanel>
					<DocumentContentTitle
						titlePrefix={formatMessage(i18n.usersTabTitle)}
						contentTitle={pageTitle}
					/>
					<PermissionsPageContent spaceKey={spaceKey} />
				</CustomTabPanel>
			</Tabs>
		</PageContainer>
	);
};
