/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

const boxStyles = xcss({
	maxWidth: '100%',
	width: '320px',
});

type FieldContainerProps = PropsWithChildren<{}>;

/**
 * @private
 * @deprecated This component has been created to standardise the constrained width of UI Kit 2 fields. Once
 * we have added layout components to UI Kit 2 though this will be unnecessary and this component and all uses
 * of it can be removed from consuming components.
 */
export const FieldContainer = (props: FieldContainerProps) => {
	return <Box xcss={boxStyles}>{props.children}</Box>;
};
