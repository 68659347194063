import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import AkSpinner from '@atlaskit/spinner/spinner';

export const SIZES = {
	small: 12,
	medium: 26,
	large: 36,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-array-arguments, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const Wrapper = styled.div([], (props) => ({
	width: '100%',
	height: '100%',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...props.style,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const Spinner = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: ${(props) => props.marginLeft}px;
	margin-top: ${(props) => props.marginTop}px;
	z-index: 100;
`;

class CenteredSpinner extends PureComponent {
	static displayName = 'CenteredSpinner';
	static propTypes = {
		/**
		 * custom spinner style
		 */
		style: PropTypes.object,

		/**
		 * Size of spinner
		 */
		size: PropTypes.oneOf(['small', 'medium', 'large']),
	};

	static defaultProps = {
		size: 'small',
	};

	render() {
		const { style, size } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<Wrapper style={style} data-test-id="wrapper-spinner">
				<Spinner data-test-id="spinner" marginLeft={-SIZES[size] / 2} marginTop={-SIZES[size] / 2}>
					<AkSpinner size={SIZES[size]} />
				</Spinner>
			</Wrapper>
		);
	}
}

export { CenteredSpinner };
