import { query } from '@confluence/query-preloader-tools';

import { FocusedInlineCommentQuery } from './FocusedInlineCommentQuery.graphql';

export function preloadFocusedInlineComment(commentId: string, contentId: string) {
	return query({
		query: FocusedInlineCommentQuery,
		variables: {
			commentId,
			contentId,
		},
	});
}
