import { fg } from '@atlaskit/platform-feature-flags';

import { CategoryStorageType } from '../../../../types';
import { type OperationalEventAction } from '../../analytics-client';
import { getBscGlobalState } from '../../bsc-global-state';
import { cache } from '../../cached-sampling-config';
import { isSSR } from '../../is-ssr';
import { loadStorageControlsData } from '../../load-storage-controls-data-legacy';

export type AnalyticsSamplingKey = OperationalEventAction;

// In case we are unable to load the config file, don't provide a specific key, or make a mistake and
// remove a sampling key, we'd like to set some reasonable defaults to avoid: A) not emitting events
// at all, or B) sending ALL events without any sampling.
export const samplingDefaults: Record<AnalyticsSamplingKey, number> = {
	usedGetCookie: 100000,
	usedGetCookieError: 1,
	usedSetCookie: 1000,
	usedSetCookieError: 1,
	usedSetStrictlyNecessaryCookie: 10000,
	usedSetStrictlyNecessaryCookieError: 1,
	usedDeleteCookie: 10,
	usedDeleteCookieError: 1,
	usedCheckThirdParty: 100000,
	checkThirdPartyError: 1,
	usedDocumentCookie: 100,
	usedDocumentCookieError: 1,
	initializeControlsError: 1,
	loadStorageControlsError: 1,
	updatePreferencesError: 1,
	fetchConsentPreferencesError: 1,
	saveConsentPreferencesError: 1,
	usedAtlBrowserStorageGetItem: 100,
	usedAtlBrowserStorageGetItemError: 1,
	usedAtlBrowserStorageSetItem: 100,
	usedAtlBrowserStorageSetItemError: 1,
	usedAtlBrowserStorageSetStrictlyNecessaryItem: 100,
	usedAtlBrowserStorageSetStrictlyNecessaryItemError: 1,
	usedCheckIfSetByPackageError: 1,
	renderConsentBannerError: 1,
	bannerAppearanceAfterDismissal: 1,
};

// This short-circuits finding a sampling ratio from the remote config. Useful for things like
// `loadStorageControlsError` which itself tries to log an event on error, causing an infinite
// recursion as each lookup fails, logging another event, etc.
const localOnlySamplingKeys = ['loadStorageControlsError'];

async function loadSamplingConfig() {
	if (cache.cachedSamplingConfig) {
		return cache.cachedSamplingConfig;
	}
	if (isSSR()) {
		return undefined;
	}

	if (fg('platform_moonjelly_bsc_new_caches')) {
		const { loadCache } = getBscGlobalState();

		const controlsData = await loadCache(CategoryStorageType.Cookies);

		const analyticsSamplingRatioConfigs = controlsData?.analyticsSamplingRatio;

		cache.cachedSamplingConfig = analyticsSamplingRatioConfigs;
		return analyticsSamplingRatioConfigs;
	} else {
		const controlsData = await loadStorageControlsData();

		const analyticsSamplingRatioConfigs = controlsData?.analyticsSamplingRatio;

		cache.cachedSamplingConfig = analyticsSamplingRatioConfigs;
		return analyticsSamplingRatioConfigs;
	}
}

export async function getAnalyticsSamplingRatio(
	analyticsEventKey: AnalyticsSamplingKey,
): Promise<number> {
	if (localOnlySamplingKeys.includes(analyticsEventKey)) {
		return samplingDefaults[analyticsEventKey];
	}

	const remoteSamplingConfig = await loadSamplingConfig();
	const samplingConfig = remoteSamplingConfig ?? samplingDefaults;

	const samplingRatio =
		samplingConfig?.[analyticsEventKey] !== undefined
			? samplingConfig[analyticsEventKey]
			: samplingDefaults[analyticsEventKey];

	return samplingRatio;
}

export function withSampling(samplingRatio: number, cb: () => any) {
	/* Only run if rolling the samplingRatio-sized die lands on a 0
       ________
      /\       \
     /()\   ()  \
    /    \_______\
    \    /()     /
     \()/   ()  /
      \/_____()/
  */
	const shouldRun = !Math.floor(Math.random() * samplingRatio);

	shouldRun && cb();
}

export async function withAnalyticsEventSampling(
	analyticsEventKey: AnalyticsSamplingKey,
	cb: () => any,
) {
	const ratio = await getAnalyticsSamplingRatio(analyticsEventKey);
	withSampling(ratio, cb);
}
