import type { CustomValues } from '@atlassian/browser-metrics/types';

import { JSCacheRate } from './JSCacheRate';

export const getCacheAttributes = (stop: number): CustomValues => {
	const {
		criticalPath: { scripts, totalSize, cachedCount, cachedSize, nonCachedSize },
	} = JSCacheRate.computeCacheRate(stop);
	return {
		cachedRate: (cachedCount / scripts.length) * 100,
		count: scripts.length,
		size: totalSize,
		cachedSize,
		nonCachedSize,
		sizeBasedCachedRate: cachedSize / (cachedSize + nonCachedSize),
	};
};
