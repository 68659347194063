import React from 'react';

import {
	ADMIN_CONFIGURATION_ANALYTICS_SOURCE,
	LazyConfigurationPage,
} from '@confluence/site-settings';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const AdminConfigurationRoute = () => <LazyConfigurationPage />;

AdminConfigurationRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_CONFIGURATION_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
