import type { ContainerList, ContainerData } from '@atlassiansox/cross-flow-plugins';
import { createGetContainersPlugin, ContainerTypes } from '@atlassiansox/cross-flow-plugins';

import { preloadSPAViewContext } from '@confluence/spa-view-context';

interface SiteConfiguration {
	siteConfiguration: {
		tenantId: string;
		siteTitle: string;
	};
}

function hasSiteConfiguration(data: unknown): data is SiteConfiguration {
	return data && typeof data === 'object' && data['siteConfiguration'];
}

const getCloudId = async (): Promise<ContainerList> => {
	const { data } = await preloadSPAViewContext();
	const containers: ContainerData[] = [];

	if (hasSiteConfiguration(data)) {
		const { tenantId, siteTitle } = data.siteConfiguration;

		containers.push({
			id: tenantId,
			displayName: siteTitle,
			type: ContainerTypes.ATLASSIAN_CLOUD_ID,
		});
	}

	return {
		type: ContainerTypes.ATLASSIAN_CLOUD_ID,
		containers,
	};
};

export const getContainersPlugin = createGetContainersPlugin(getCloudId);
