import type { FetchPlacementApi, Fetcher, SerializableRecord } from './types';

export const REQUEST_URL = `/gateway/api/post-office/api/v1/placements/`;

const isNotEmpty = (input: unknown) => !(typeof input === 'undefined' || input === null);

const stringifyObjectValues = <T extends Record<string, unknown>>(input: T) => {
	const entries = Object.entries(input);
	const stringifiedEntries = entries
		.filter(([, value]) => isNotEmpty(value))
		.map(([key, value]) => [key, String(value)]);
	return Object.fromEntries(stringifiedEntries) as Record<string, string>;
};

export const fetchPlacementData: FetchPlacementApi = <C extends SerializableRecord>(
	placementId: string,
	path: string,
	context: C,
	fetcher: Fetcher,
	requestOptions?: RequestInit,
) => {
	const defaultHeaders = {
		credentials: 'include',
		mode: 'cors',
		'Content-Type': 'application/json',
	};

	const normalizedPath = `/${path.charAt(0) === '/' ? path.substring(1) : path}`;

	const contextAsSearchParams = new URLSearchParams(stringifyObjectValues(context)).toString();

	const formattedSearchParams = contextAsSearchParams
		? `?${contextAsSearchParams}`
		: contextAsSearchParams;

	const url = encodeURI(`${REQUEST_URL}${placementId}${normalizedPath}${formattedSearchParams}`);

	return fetcher.fetch(url, {
		...requestOptions,
		headers: {
			...defaultHeaders,
			...requestOptions?.headers,
		},
	});
};
