import React, { useContext, useEffect, useMemo, useState } from 'react';

import { getApolloClient } from '@confluence/graphql';
import { useNativeCollabState } from '@confluence/native-collab';

import { fetchContentAppearanceProperty } from './contentAppearance';
import type { ContentAppearance } from './types';
import { ContentAppearancePropertyKey, ContentAppearanceType } from './types';

type ContentAppearanceContextType = {
	contentAppearance: ContentAppearance;
	setContentAppearance(contentAppearance: ContentAppearance): void;
	contentAppearanceLoading: boolean;
};
const ContentAppearanceContext = React.createContext<ContentAppearanceContextType>({
	contentAppearance: {
		appearance: ContentAppearanceType.DEFAULT,
		version: 0,
	},
	setContentAppearance: () => {},
	contentAppearanceLoading: false,
});

type ContentAppearanceContextProviderProps = {
	contentId: string;
	children: React.ReactNode;
};
export const ContentAppearanceContextProvider = ({
	contentId,
	children,
}: ContentAppearanceContextProviderProps) => {
	const [contentAppearanceLoading, setContentAppearanceLoading] = useState(false);
	const [contentAppearance, setContentAppearance] = useState<ContentAppearance>({
		appearance: ContentAppearanceType.DEFAULT,
		version: 0,
	});
	const [{ collabEditProvider }] = useNativeCollabState();
	// Subscribe to metadata:changed events to update the content appearance for collaborators
	useEffect(() => {
		if (!collabEditProvider) {
			return;
		}

		const handleMetadataChanged = (metadata: any) => {
			const widthValue = metadata?.editorWidth?.value;
			const version = metadata?.editorWidth?.version;

			if (widthValue) {
				setContentAppearance((currentAppearance) => {
					if (version > currentAppearance.version) {
						return {
							appearance: widthValue,
							version,
						};
					} else {
						return currentAppearance;
					}
				});
			}
		};

		collabEditProvider.on('metadata:changed', handleMetadataChanged);
		return () => {
			collabEditProvider.off('metadata:changed', handleMetadataChanged);
		};
	}, [collabEditProvider]);

	// Get the initial content appearance for this draft
	useEffect(() => {
		const getDraftAppearance = async () => {
			setContentAppearanceLoading(true);
			try {
				const client = getApolloClient();
				const draftAppearance = await fetchContentAppearanceProperty(
					contentId,
					ContentAppearancePropertyKey.DRAFT,
					client,
				);
				setContentAppearance(draftAppearance);
			} finally {
				setContentAppearanceLoading(false);
			}
		};

		void getDraftAppearance();
	}, [contentId]);

	const context = useMemo(
		() => ({
			contentAppearance,
			setContentAppearance,
			contentAppearanceLoading,
		}),
		[contentAppearance, setContentAppearance, contentAppearanceLoading],
	);

	return (
		<ContentAppearanceContext.Provider value={context}>
			{children}
		</ContentAppearanceContext.Provider>
	);
};

type ContentAppearanceContextConsumerProps = {
	children: (props: ContentAppearanceContextType) => JSX.Element;
};
export const ContentAppearanceContextConsumer = ({
	children,
}: ContentAppearanceContextConsumerProps) => {
	const context = useContentAppearance();
	return children(context);
};

export const useContentAppearance = () => {
	return useContext(ContentAppearanceContext);
};
