// Note: codeBidi props are not exposed to prevent misuse by malicious developers

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::54319e8b31e96717d48edc542050eb87>>
 * @codegenId #code
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen code
 * @codegenParams { "props": { "children": { "value": "props.forgeDoc.children[0]?.props?.text", "comment": "Only render string children" }, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/code/src/types.tsx <<SignedSource::925d3b4913f207152c0fb6b771f5a600>>
 * @codegenDependency ../../../../../../../design-system/code/src/bidi-warning/index.tsx <<SignedSource::eed1a1140ce6d1464984243dc5b41ba4>>
 * @codegenDependency ../../../../../../../design-system/code/src/bidi-warning/bidi-warning-decorator.tsx <<SignedSource::9812fcc07c0b396dcc82bb867cd0082b>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/theme/styles.tsx <<SignedSource::b10f8e616bfe48ca7b5b801b552ab1f0>>
 * @codegenDependency ../../../../../../../design-system/code/src/code.tsx <<SignedSource::7b6eaa1398a5fde7b6ece1b2bc27bb8e>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Code as PlatformCode } from '@atlaskit/code';

type PlatformCodeProps = React.ComponentProps<typeof PlatformCode>;

export type CodeProps = Pick<
  PlatformCodeProps,
  'children' | 'testId'
>;

export const Code = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as CodeProps;
  return (
    <PlatformCode
      // Only render string children
      children={props.forgeDoc.children[0]?.props?.text}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
