import { type EditorView } from '@atlaskit/editor-prosemirror/view';

import { updateChunksWithDiffObjects } from './commands';
import { getPluginState } from './plugin-factory';
import { type ProactiveAIBlock, type ProactiveAISentence } from './states';
import { getBlocksSentences } from './utils';

export type ProactiveAIDocumentSGCheckerConfig = {
	enabled: boolean;
	blocksPerRequest: number;
	sentencesPerRequest: number;
	timings: {
		delayBetweenChecks: number;
		numberOfRequestsForIncrease: number;
		delayIncrease: number;
		maxDelay: number;
	};
};

export const NUMBER_OF_BLOCKS_PER_REQUEST = 3;
export const NUMBER_OF_SENTENCES_PER_REQUEST = 7;
/**
 * Delay between requests and how do we increase this delay
 * 	is temporary till we figure something solid based on document size.
 * Till then we will go ahead with;
 * - start 1st request immediately
 * - send 2nd request after 5 seconds
 * - send 3rd request after 7 seconds, 4th one at 7 seconds
 * - for 5th one increase by another 2 seconds and send request after 9 seconds
 * - so on ..., till max delay is 15 seconds
 */
export const DELAY_BETWEEN_TWO_CHECKS = 4 * 1000;
export const NUMBER_OF_REQUESTS_FOR_INCREASE = 2;
export const DELAY_INCREASE = 2 * 1000;
export const MAX_DELAY = 14000;
export const defaultConfig = {
	enabled: false,
	blocksPerRequest: NUMBER_OF_BLOCKS_PER_REQUEST,
	sentencesPerRequest: NUMBER_OF_SENTENCES_PER_REQUEST,
	timings: {
		delayBetweenChecks: DELAY_BETWEEN_TWO_CHECKS,
		numberOfRequestsForIncrease: NUMBER_OF_REQUESTS_FOR_INCREASE,
		delayIncrease: DELAY_INCREASE,
		maxDelay: MAX_DELAY,
	},
};

export class DocumentSGChecker {
	private config: ProactiveAIDocumentSGCheckerConfig = defaultConfig;
	private view?: EditorView;
	private locale?: string;
	private timerId?: ReturnType<typeof setTimeout>;

	private chunksPerRequest: number = NUMBER_OF_BLOCKS_PER_REQUEST;
	private currentDelay: number = DELAY_BETWEEN_TWO_CHECKS;
	private requestsSentSinceLastIncrease: number = 0;

	private getSGPluginState() {
		if (this.view) {
			return getPluginState(this.view.state);
		}
	}

	/**
	 * We need to get chunks before sending request each time because;
	 * - now we have replacing blocks and sentences in pluginState;
	 */
	private getChunks() {
		const pluginState = this.getSGPluginState();
		if (pluginState) {
			const { splitParagraphIntoSentences, proactiveAIBlocks } = pluginState;
			const blocks = proactiveAIBlocks || [];
			return splitParagraphIntoSentences ? getBlocksSentences(blocks) : blocks;
		}
		return [];
	}

	private filterChunksNeedingSGCheck(chunks: Array<ProactiveAIBlock | ProactiveAISentence>) {
		return chunks.filter((chunk) => !!chunk.needSGCheckForDocChecker);
	}

	private getNextChunks() {
		let chunks: Array<ProactiveAIBlock | ProactiveAISentence> = this.getChunks();
		chunks = this.filterChunksNeedingSGCheck(chunks);

		return chunks.slice(0, this.chunksPerRequest);
	}

	private async checkNextBlocks() {
		if (!this.view || !this.locale) {
			return;
		}

		const chunks = this.getNextChunks();
		if (chunks.length) {
			await updateChunksWithDiffObjects(this.view, chunks, this.locale);
			this.setTimer();
		} else {
			this.stop();
		}
	}

	private setTimer() {
		if (this.currentDelay !== this.config.timings.maxDelay) {
			this.requestsSentSinceLastIncrease = this.requestsSentSinceLastIncrease + 1;
			if (this.requestsSentSinceLastIncrease < this.config.timings.numberOfRequestsForIncrease) {
				this.currentDelay = Math.min(
					this.config.timings.maxDelay,
					this.currentDelay + this.config.timings.delayIncrease,
				);
				this.requestsSentSinceLastIncrease = 0;
			}
		}

		this.timerId = setTimeout(this.checkNextBlocks.bind(this), this.currentDelay);
	}

	setEditorViewAndLocale(
		config: ProactiveAIDocumentSGCheckerConfig,
		view: EditorView,
		locale: string,
	) {
		this.config = config;
		this.currentDelay = config.timings.delayBetweenChecks;
		this.chunksPerRequest = config.blocksPerRequest;
		this.view = view;
		this.locale = locale;
	}

	/**
	 * When we start S+G check across whole document.
	 *	- we will get chunks and filter them based on needSGCheckForDocChecker before each request.
	 *	- when chunk is checked for S+G, needSGCheckForDocChecker is set to false for that chunk.
	 *	- between this, if any block is updated checked for S+G, in that needSGCheckForDocChecker will
	 *    	also be set to false.
	 * 	- perform S+G check for chunksPerRequest every currentDelay (set to DELAY_BETWEEN_TWO_CHECKS)
	 *    - increases delay every 2 requests by 2 seconds.
	 */
	start() {
		this.stop();

		const pluginState = this.getSGPluginState();
		const splitParagraphIntoSentences = pluginState?.splitParagraphIntoSentences;
		this.chunksPerRequest = splitParagraphIntoSentences
			? this.config.sentencesPerRequest
			: this.config.blocksPerRequest;
		this.checkNextBlocks();
	}

	stop() {
		clearTimeout(this.timerId);
		this.timerId = undefined;
		this.currentDelay = this.config.timings.delayBetweenChecks;
		this.requestsSentSinceLastIncrease = 0;
	}

	reset() {
		this.stop();
		this.view = undefined;
		this.locale = undefined;
	}
}
