import React, { type ComponentProps, type ComponentType, type ReactElement } from 'react';

import { type Store } from 'redux';

import { withAnalyticsEvents } from '@atlaskit/analytics-next';

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { type AnalyticsWebClientType } from '../../common/types/analytics';
import { type Config } from '../../common/types/config';
import preventNestedInstances from '../../common/utils/prevent-nested-instances';
import { type State as EngagementStoreState } from '../../controllers/engagement-store';
import { type CoordinationOptions } from '../../services/engage-targeting';
import initializeEngagementStore from '../engagement-store';

import { EngagementProvider } from './main';

// This extra bit of typing is required because typing on analytics-next
// doesn't seem to be working properly and we haven't been able to
// come up with a solution for it yet. Also analytics-next is in
// maintenance mode so will need to be replaced anyway.
type PropsWithEvents = Omit<
	ComponentProps<typeof EngagementProvider>,
	'createAnalyticsEvent' | 'eventTarget'
>;
const EngagementProviderWithEvents: ComponentType<PropsWithEvents> =
	withAnalyticsEvents()(EngagementProvider);

export interface EngagementProviderProps {
	children: ReactElement;
	store?: Store<EngagementStoreState>;
	config: Config;
	analyticsClientInstance: AnalyticsWebClientType | Promise<AnalyticsWebClientType>;
	coordinationOptions?: CoordinationOptions;
}

const EngagementProviderWithDefaultStore = (props: EngagementProviderProps) => {
	return (
		<EngagementProviderWithEvents {...props} store={initializeEngagementStore(props.config)} />
	);
};

export default preventNestedInstances(EngagementProviderWithDefaultStore);
