import type { Schema } from '@atlaskit/editor-prosemirror/model';
/**
 * BE supported markdowns are defined in https://bitbucket.org/atlassian/cc-smarts/src/master/src/main/java/io/atlassian/micros/smarts/model/Markdown.java
 * BE support is based on https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3632267938/Mapping+node+and+mark+names+to+markdown+features
 */
const backEndSupportedMarkdown: Record<string, string> = {
	blockquote: 'BLOCKQUOTES',
	paragraph: 'PARAGRAPH',
	rule: 'HORIZONTAL_RULE',
	heading: 'HEADING',
	hardbreak: 'HARD_LINE_BREAK',
	codeBlock: 'CODEBLOCKS',
	bulletList: 'BULLET_LISTS',
	orderedList: 'NUMBERED_LISTS',
	media: 'IMAGES',
	table: 'TABLE',
	taskList: 'TASK_LIST',
	code: 'CODE_SPAN',
	em: 'ITALICS',
	strong: 'BOLD',
	link: 'LINK',
	strike: 'STRIKETHROUGH',
};

export const getSupportedMarkdowns = (schema?: Schema) => {
	if (!schema?.nodes && !schema?.marks) {
		return [];
	}
	const schemaNodesAndMarks = [...Object.keys(schema.nodes), ...Object.keys(schema.marks)];
	return schemaNodesAndMarks
		.map((nodeOrMark) => backEndSupportedMarkdown[nodeOrMark])
		.filter(Boolean);
};
