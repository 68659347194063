import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import SpaceBase from '../../SpaceBase';

import { LazyJiraProjectBoard } from '../lazyComponents';

const SpaceJiraProjectBoardRoute = ({ params: { spaceKey } }: RouteMatch) => {
	return (
		<SpaceBase>
			<LazyJiraProjectBoard spaceKey={spaceKey} />
		</SpaceBase>
	);
};

SpaceJiraProjectBoardRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'jiraProjectBoardScreen',
		},
		pageState: {
			spaceKey,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});

export { SpaceJiraProjectBoardRoute };
