/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { h500 } from '@atlaskit/theme/typography';
import { type ForgeUIExtensionType, type ProductEnvironment } from '@atlassian/forge-ui-types';
import { Component, type ReactElement, type ErrorInfo, useContext } from 'react';
import { captureAndReportError } from '../error-reporting';
import { EnvironmentContext, useMetricsContext } from '../context';
import { APIError } from '../web-client/hooks/useInvokeAuxEffects';
import { type CreateUIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../analytics';
import SectionMessage from '@atlaskit/section-message';

interface Props {
	children: ReactElement;
	extension?: ForgeUIExtensionType;
	environment: ProductEnvironment;
	page: string;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}

interface State {
	thrownError?: {
		error: Error;
		errorInfo: ErrorInfo;
	};
}
class ForgeErrorBoundary extends Component<Props, State> {
	public state: State = {
		thrownError: undefined,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({
			thrownError: {
				error,
				errorInfo,
			},
		});
		const { page } = this.props;
		const environment = this.props.environment;

		captureAndReportError({
			error,
			errorExtensionDetails: this.props.extension
				? {
						type: this.props.extension.type,
						environmentType: this.props.extension.environmentType,
						appOwnerAccountId: this.props.extension.appOwner?.accountId,
						properties: this.props.extension.properties,
					}
				: undefined,
			errorInfo,
			page,
			environment,
		});

		if (!(error instanceof APIError)) {
			this.props
				.createAnalyticsEvent({
					eventType: OPERATIONAL_EVENT_TYPE,
					data: {
						action: 'failed',
						actionSubject: 'forge.ui.renderer',
						source: page,
						tags: ['forge'],
						attributes: {
							target: 'errorBoundary',
							errorName: error.name,
						},
					},
				})
				.fire(FORGE_UI_ANALYTICS_CHANNEL);
		}
	}

	public render() {
		const { thrownError } = this.state;

		if (!thrownError) {
			return this.props.children;
		}
		return (
			<div
				data-testid="ForgeErrorBoundary"
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
					'&&': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
						h1: h500(),
					},
				})}
			>
				<SectionMessage appearance="error" title="Error rendering app" children={null} />
			</div>
		);
	}
}

const withAnalyticsContext =
	(Component: typeof ForgeErrorBoundary) =>
	({
		extension,
		children,
	}: Omit<Props, 'page' | 'environment' | 'createAnalyticsEvent'> & {
		children?: React.ReactNode;
	}) => {
		const { page, environment: envFromContext } = useMetricsContext();
		const environment = useContext(EnvironmentContext);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		return (
			<Component
				environment={envFromContext ?? environment}
				extension={extension}
				page={page}
				createAnalyticsEvent={createAnalyticsEvent}
			>
				{children}
			</Component>
		);
	};

export default withAnalyticsContext(ForgeErrorBoundary);
