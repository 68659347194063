import type React from 'react';
import { Component } from 'react';

class IntegrationErrorHandlerBoundary extends Component<
	React.PropsWithChildren<{
		onError?: (error: Error) => void;
	}>
> {
	public componentDidCatch(error: Error) {
		if (this.props.onError) {
			this.props.onError(error);
		}
		throw error;
	}

	public render(): React.ReactNode {
		return this.props.children;
	}
}

export default IntegrationErrorHandlerBoundary;
