import React from "react";

import type { ExtensionHandler } from "@atlaskit/editor-common/extensions";

import { EXTENSION_KEY } from "@confluence/fabric-extension-lib/entry-points/extensionConstants";
import { TemplateVariableComponent, TemplateVariableInput } from "@confluence/template-variable";

export const getTemplateExtensionHandler: ExtensionHandler<any> = extension => {
  if (!extension) {
    return null;
  }

  const { extensionKey, parameters } = extension;
  if (extensionKey === EXTENSION_KEY.TEMPLATE) {
    return <TemplateVariableComponent parameters={parameters} />;
  }

  return null;
};

export const getTemplateVariableInputExtensionHandler: ExtensionHandler<
  any
> = extension => {
  if (!extension) {
    return null;
  }
  const { extensionKey, parameters } = extension;

  if (extensionKey === EXTENSION_KEY.TEMPLATE) {
    return <TemplateVariableInput parameters={parameters} />;
  }

  return null;
};
