import React from 'react';

import { extensionToADF } from '@confluence/fabric-extension-lib/entry-points/editor-extensions';
import { LiveSearchLoader } from '@confluence/live-search';

export const LiveSearchHandler = ({ macroDefaultProps }) => {
	const { node } = macroDefaultProps;
	const macroNode = extensionToADF(node);

	return <LiveSearchLoader macroNode={macroNode} />;
};
