const browserMatchers = [
	{
		name: 'IE',
		pattern: /Trident\/.*; rv:([^;)]+)/,
	},
	{
		name: 'Chrome',
		pattern: /Chrome\/([^ )]+)/,
	},
	{
		name: 'Firefox',
		pattern: /Firefox\/([^ )]+)/,
	},
];

export type BrowserInfo = {
	name: string;
	version: string;
};

export const unknownBrowser: BrowserInfo = {
	name: 'unknown',
	version: 'unknown',
};

export function getBrowserInfo(userAgent?: string): BrowserInfo {
	if (!window.navigator) {
		return unknownBrowser;
	}

	if (!userAgent) {
		userAgent = window.navigator.userAgent;
	}

	for (const matcher of browserMatchers) {
		const versionMatch = userAgent.match(matcher.pattern);
		if (versionMatch) {
			return {
				name: matcher.name,
				version: versionMatch[1],
			};
		}
	}

	return unknownBrowser;
}
