import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { type PropsWithChildren } from 'react';
import {
	IntlProvider as IntlProviderNext,
	type MessageFormatElement,
	useIntl,
} from 'react-intl-next';

import { type MessageLoader, useIntlMessages } from './useIntlMessages';

export type IntlProviderProps<T> = {
	messagesLoader: MessageLoader<T>;
};

function useFetchLocale() {
	let locale = 'en';
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();

	try {
		const { locale: intlLocale } = useIntl();
		locale = intlLocale;
	} catch (err) {
		fireAnalyticsEvent({
			action: 'errored',
			actionSubject: 'intlProvider',
			eventType: 'operational',
			attributes: {
				errorMessage: err,
			},
		});
	}

	return { locale };
}

export default function IntlProvider<
	T extends Record<string, string> | Record<string, MessageFormatElement[]>,
>(props: PropsWithChildren<IntlProviderProps<T>>) {
	const { locale } = useFetchLocale();
	const messages = useIntlMessages(locale, props.messagesLoader);

	return (
		<IntlProviderNext locale={locale} messages={messages}>
			{props.children}
		</IntlProviderNext>
	);
}
