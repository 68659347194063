// Grow is being excluded at the moment as it requires a Box component to have `flexGrow: 1` as a property

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::8f75b19de46962cdb4dba3608b8d2dc5>>
 * @codegenId #inline
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen inline
 * @codegenParams { "props": { "children": true, "alignBlock": true, "alignInline": true, "spread": true, "grow": false, "space": true, "shouldWrap": true, "separator": true, "rowSpace": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/inline.tsx <<SignedSource::ac1fd698906b3e51f282996ecec80b5b>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::2f4848af544e0f64968f7c28012cf0f0>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::00aab072ec1c97fd176380fcd46ccf7e>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::a91bf7842593d9052c045e4a40d7ab3b>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/flex.tsx <<SignedSource::7148f07a56c498fe6d7f2eee9837f198>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Inline as PlatformInline } from '@atlaskit/primitives';

type PlatformInlineProps = React.ComponentProps<typeof PlatformInline>;

export type InlineProps = Pick<
  PlatformInlineProps,
  'children' | 'alignBlock' | 'alignInline' | 'spread' | 'space' | 'shouldWrap' | 'separator' | 'rowSpace' | 'testId'
>;

export const Inline = (props: Parameters<RenderFn>[0]) => {
  const {
    alignBlock,
    alignInline,
    spread,
    space,
    shouldWrap,
    separator,
    rowSpace,
    testId,
  } = props.forgeDoc.props as InlineProps;
  return (
    <PlatformInline
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      alignBlock={alignBlock}
      alignInline={alignInline}
      spread={spread}
      space={space}
      shouldWrap={shouldWrap}
      separator={separator}
      rowSpace={rowSpace}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
