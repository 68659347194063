import React from 'react';

import { LoadablePaint } from '@confluence/loadable';

const MobileAppTakeoverPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-MobileAppTakeoverPage" */ '@confluence/mobile-web/entry-points/MobileAppTakeoverPage'
			)
		).MobileAppTakeoverPage,
});

export const MobileTakeoverPageRoute = () => <MobileAppTakeoverPageLoader />;

MobileTakeoverPageRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'mobileTakeoverPage',
		},
	},
	MainLayout: false,
});
