import gql from 'graphql-tag';

export const templateEditorContent = gql`
	query templateEditorContent($contentId: String!) {
		template(contentTemplateId: $contentId) {
			templateId
			name
			description
			labels {
				id
				name
				prefix
			}
			space {
				id
				key
				name
			}
			originalTemplate {
				pluginKey
				moduleKey
			}
			body {
				atlas_doc_format {
					value
					representation
				}
			}
			templatePropertySet {
				contentAppearance
			}
		}
	}
`;
