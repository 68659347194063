import { token } from '@atlaskit/tokens';

export const chartColorPalette = [
	token('color.chart.categorical.1', '#1D9AAA'),
	token('color.chart.categorical.2', '#8270DB'),
	token('color.chart.categorical.3', '#D97008'),
	token('color.chart.categorical.4', '#943D73'),
	token('color.chart.categorical.5', '#09326C'),
	token('color.chart.categorical.6', '#8F7EE7'),
	token('color.chart.categorical.7', '#50253F'),
	token('color.chart.categorical.8', '#974F0C'),
];
