import { useSessionData } from '@confluence/session-data';

export const getWorkspaceARI = ({
	cloudId,
	activationId,
}: {
	cloudId: string;
	activationId: string;
}) => {
	return `ari:cloud:confluence:${cloudId}:workspace/${activationId}`;
};

export const useWorkspaceARI = () => {
	const { cloudId, activationId } = useSessionData();
	return getWorkspaceARI({ cloudId, activationId });
};
