import memoize from 'memoize-one';

import { type SegmentBatchDef } from './types';

export type Options = {
	timeout?: number;
	abortController?: AbortController;
	url: string;
	batch: SegmentBatchDef;
};

export const sendEvents = async (options: Options): Promise<Response> => {
	const signal = createOrGetAbortController(options)?.signal;

	const corsOptions = getCorsForUrl(options.url);

	const response = await fetch(options.url, {
		method: 'POST',
		// Content-type has to be text/plain in order to prevent unnecessary `OPTIONS` requests.
		headers: { 'Content-Type': 'text/plain' },
		body: JSON.stringify(options.batch),
		signal,
		...corsOptions,
	});

	if (response.status === 429 || (response.status >= 500 && response.status < 600)) {
		throw new Error(`HTTP Error ${response.status} (${response.statusText})`);
	}

	return response;
};

const createOrGetAbortController = (options: Options): AbortController | undefined => {
	if (options.abortController) {
		return options.abortController;
	} else if (options.timeout) {
		const abortController = new AbortController();

		// After a fetch has completed, calling abort is ignored.
		setTimeout(() => {
			abortController.abort();
		}, options.timeout);

		return abortController;
	}
};

// To ensure we always have cookies, we should check if the origins match.
// If they dont, we need to set mode to `cors` and credentials to `include`.
const getCorsForUrl = memoize((url: string): RequestInit => {
	const origin = new URL(url).origin;
	if (origin === location.origin) {
		return {};
	} else {
		return {
			mode: 'cors',
			credentials: 'include',
		};
	}
});
