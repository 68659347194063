import React from 'react';
import type { ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import Tooltip from '@atlaskit/tooltip';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ClockIcon from '@atlaskit/icon/core/clock';
import EyeOpenStrikethroughIcon from '@atlaskit/icon/core/eye-open-strikethrough';
import FieldTextIcon from '@atlaskit/icon-lab/core/field-text';
import { Box } from '@atlaskit/primitives';

import { usePageContentId } from '@confluence/page-context';
import {
	Attribution,
	ErrorDisplay,
	isUnauthorizedError,
	withErrorBoundary,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { CONTENT_HISTORY } from '@confluence/named-routes';
import { useIsProductAdmin } from '@confluence/current-user';

import { useChangeHubNameDialog } from '../change-hub-name-dialog/useChangeHubNameDialog';
import { useDeactivateHubDialog } from '../unpublish-hub-dialog/useDeactivateHubDialog';

import type {
	HubActionsDropdownQuery as HubActionsDropdownQueryType,
	HubActionsDropdownQueryVariables,
} from './__types__/HubActionsDropdownQuery';
import { HubActionsDropdownQuery } from './HubActionsDropdownQuery.graphql';

export const i18n = defineMessages({
	ellipsisMenuLabel: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.label',
		defaultMessage: 'More actions',
		description: 'Label of ellipsis menu to adjust Company hub settings',
	},
	ellipsisMenuItemHistory: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.history',
		defaultMessage: 'History',
		description:
			'Ellipsis menu item in hub dashboard that allows the user to go to the Company hub history page',
	},
	ellipsisMenuItemRename: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.rename',
		defaultMessage: 'Rename',
		description:
			'Ellipsis menu item in hub dashboard that allows the user to go rename their Company hub',
	},
	ellipsisMenuItemDeactivate: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.deactivate',
		defaultMessage: 'Deactivate',
		description:
			'Ellipsis menu item in hub dashboard that allows the user to go to deactivate their Company hub',
	},
});

type MenuItem = {
	i18nMessage: MessageDescriptor;
	href?: string;
	action?: () => void;
	isRestricted?: boolean;
	iconElement: ReactNode;
};

type HubActionsDropdownProps = {
	spaceKey: string;
	isHubPublished?: boolean;
};

const HubActionsDropdownComponent = ({ spaceKey, isHubPublished }: HubActionsDropdownProps) => {
	const dropdownItems: MenuItem[] = [];

	const { data, error } = useQuery<HubActionsDropdownQueryType, HubActionsDropdownQueryVariables>(
		HubActionsDropdownQuery,
		{
			variables: {
				spaceKey,
			},
		},
	);

	const [contentId = ''] = usePageContentId();
	if (contentId && spaceKey) {
		dropdownItems.push({
			i18nMessage: i18n.ellipsisMenuItemHistory,
			href: CONTENT_HISTORY.toUrl({ contentId, spaceKey }),
			iconElement: <ClockIcon label="" />,
		});
	}

	const isSpaceAdmin = data?.space?.currentUser?.isAdmin;
	const openChangeHubNameDialog = useChangeHubNameDialog({ isFirstTimeDialog: false });
	dropdownItems.push({
		i18nMessage: i18n.ellipsisMenuItemRename,
		action: openChangeHubNameDialog,
		isRestricted: !isSpaceAdmin,
		iconElement: <FieldTextIcon label="" />,
	});

	const { isProductAdmin } = useIsProductAdmin();
	const openDeactivateHubDialog = useDeactivateHubDialog({ contentId });
	if (isHubPublished) {
		dropdownItems.push({
			i18nMessage: i18n.ellipsisMenuItemDeactivate,
			action: openDeactivateHubDialog,
			isRestricted: !isProductAdmin,
			iconElement: <EyeOpenStrikethroughIcon label="" />,
		});
	}

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
			return <Box testId="unauthorized-error-state" />;
		} else {
			return (
				<ErrorDisplay error={error}>
					<Box testId="generic-error-state" />
				</ErrorDisplay>
			);
		}
	}

	return (
		<DropdownMenu
			placement="bottom-end"
			shouldRenderToParent
			trigger={({ triggerRef, ...props }) => (
				<Tooltip hideTooltipOnClick content={<FormattedMessage {...i18n.ellipsisMenuLabel} />}>
					<IconButton
						{...props}
						label={<FormattedMessage {...i18n.ellipsisMenuLabel} />}
						ref={triggerRef}
						icon={ShowMoreHorizontalIcon}
					/>
				</Tooltip>
			)}
		>
			<DropdownItemGroup>
				{dropdownItems.map(({ i18nMessage, href, action, isRestricted, iconElement }) => (
					<DropdownItem
						key={i18nMessage.id}
						elemBefore={iconElement}
						onClick={action}
						href={href}
						isDisabled={isRestricted}
					>
						<FormattedMessage {...i18nMessage} />
					</DropdownItem>
				))}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export const HubActionsDropdown = withErrorBoundary({
	attribution: Attribution.COMPANY_HUB,
})(HubActionsDropdownComponent);
