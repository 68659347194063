import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { COMPANY_HUB_SETTINGS } from '@confluence/named-routes';
import { fg } from '@confluence/feature-gating';
import { NotFound } from '@confluence/not-found';
import { CompanyHubSettingsPage } from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';

export const CompanyHubSettingsRoute = () => {
	const isRouteEnabled = fg('company_hub_consolidated_settings');

	if (!isRouteEnabled) {
		return <NotFound />;
	}

	return <CompanyHubSettingsPage />;
};

CompanyHubSettingsRoute.NAVIGATION_PARAMS = () => {
	return {
		Screen: {
			screenEvent: {
				name: 'companyHubSettingsScreen',
			},
			pageState: {
				routeName: COMPANY_HUB_SETTINGS.name,
				spaceKey: getCompanyHubSpaceKey() || '',
			},
		},
		MainLayout: {
			navView: PRODUCT_HOME_ID,
		},
	};
};
