import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { CONTEXT_PATH } from '@confluence/named-routes';

import { ProfileCardWrapper } from './ProfileCardWrapper';
import { replaceUserElementsWithPlaceholders, getAtlassianPeopleProfileUrl } from './helpers';

/**
 * This component processes the DOM Node for ContentBody, Likes, & CommentBody to find all User Mentions
 * and User Links and replaces them with a React Element for @atlaskit/profilecard
 */
const LEGACY_USER_ELEMENT_SELECTORS = [
	'a.user-mention',
	'a.confluence-userlink',
	'a.userLogoLink',
	'span.user-hover-trigger',
	'img.confluence-userlink',
];

export class LegacyUserElementsHover extends Component<any> {
	shouldComponentUpdate(nextProps: any) {
		return nextProps.node !== this.props.node;
	}

	getLegacyUserElements(): Element[] {
		const { node } = this.props;
		if (!node) {
			return [];
		}

		return Array.from(node.querySelectorAll(LEGACY_USER_ELEMENT_SELECTORS.join(',')));
	}

	renderPortal = (item: any) => {
		const { position } = this.props;
		const userId = item.props['data-account-id'];

		item.props.href = getAtlassianPeopleProfileUrl(
			item.props['data-account-id'],
			null,
			CONTEXT_PATH,
		);

		return ReactDOM.createPortal(
			<ProfileCardWrapper isAnonymous={!userId} userId={userId} position={position}>
				<a {...item.props}>{item.props.children}</a>
			</ProfileCardWrapper>,
			item.placeholder,
		);
	};

	render() {
		return replaceUserElementsWithPlaceholders(this.getLegacyUserElements()).map((item: any) =>
			this.renderPortal(item),
		);
	}
}
