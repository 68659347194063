import React from 'react';
import memoizeOne from 'memoize-one';
import gql from 'graphql-tag';

import { getApolloClient } from '@confluence/graphql';
import { getTemplateId, requireStyleClassResourcesForTemplates } from '@confluence/template-utils';
import { TemplateIcon } from '@confluence/template-card';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import type { ConfluencePageContext, Option } from '../extensionTypes';

enum SortOrder {
	RELEVANCY_SORT = 'web.item.sorting.scheme.relevancy',
}

const AllTemplatesQuery =
	// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming -- Read https://go/connie-relay-migration-fyi
	gql`
		query AllTemplatesQuery($spaceKey: String!, $sortingScheme: String!) {
			allTemplates(spaceKey: $spaceKey, sortingScheme: $sortingScheme) {
				nodes {
					name
					description
					templateId
					contentBlueprintId
					iconURL
					darkModeIconURL
				}
			}
		}
	`;

const loadLegacyTemplateStyles = memoizeOne(requireStyleClassResourcesForTemplates);

const getAllTemplates = async (spaceKey: string): Promise<any> => {
	const allTemplatesData = await getApolloClient().query({
		query: AllTemplatesQuery,
		variables: { spaceKey, sortingScheme: SortOrder.RELEVANCY_SORT },
	});

	return allTemplatesData?.data.allTemplates?.nodes;
};

const search = async (spaceKey: string): Promise<Option[]> => {
	try {
		const templates = await getAllTemplates(spaceKey);

		return templates.map((template) => ({
			label: template.name,
			description: template.description,
			icon: <TemplateIcon size="small" template={template} />,
			value: getTemplateId(template),
		}));
	} catch (error) {
		getMonitoringClient().submitError(
			new Error(
				`Failed to fetch templates from template resolver in macro config panel with error: ${error}`,
			),
			{
				attribution: Attribution.TAILORED_EXPERIENCES,
			},
		);

		return [];
	}
};

export const templateResolverFor = (spaceKey?: string) => async (_searchTerm, _selectedValue) => {
	return spaceKey ? search(spaceKey) : [];
};

export const getTemplateResolver = ({ spaceKey }: ConfluencePageContext) => {
	loadLegacyTemplateStyles();
	return templateResolverFor(spaceKey);
};
