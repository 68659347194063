export enum ContentAppearancePropertyKey {
	PUBLISHED = 'content-appearance-published',
	DRAFT = 'content-appearance-draft',
}

type ContentAppearanceContentProperty = {
	key: ContentAppearancePropertyKey | string | null;
	value: string | null;
	version?: {
		number: number | null;
	} | null;
};

export type GraphqlAppearanceContentProperty = {
	nodes: (ContentAppearanceContentProperty | null)[] | null;
};

export type GraphqlQueryContentWithDraftAppearance = {
	appearanceDraft: GraphqlAppearanceContentProperty | null;
};

export type GraphqlQueryContentWithPublishedAppearance = {
	appearancePublished: GraphqlAppearanceContentProperty | null;
};

export type ContentAppearance = {
	appearance: ContentAppearanceType;
	version: number;
};

export enum ContentAppearanceType {
	DEFAULT = 'default',
	FULL_WIDTH = 'full-width',
}

export type FullPageAppearances = 'full-page' | 'full-width';

export type ContentAppearanceToAtlaskitMap = {
	[key in ContentAppearanceType]: FullPageAppearances;
};
