import { useEffect, useState } from 'react';

import { useLocation, useHistory } from '@confluence/route-manager';

import type { GetFeatureCodesResponse } from '.';
import { FEATURE_CODES_QUERY_PARAM, getFeatureCodes } from '.';

import { hideFeatureCodesQueryParam } from './featureCodes';

/**
 * Hook to extract feature codes from the URL and hide them from the URL
 *
 * @param queryParams - The query parameters from the URL
 * @returns The feature codes extracted from the URL
 */
export const useFeatureCodesInURL = (): GetFeatureCodesResponse => {
	const history = useHistory();
	const location = useLocation();

	const queryParams = location?.search;

	const [featureCodes, setFeatureCodes] = useState<GetFeatureCodesResponse>({
		from: undefined,
	});

	useEffect(() => {
		if (queryParams && queryParams.includes(FEATURE_CODES_QUERY_PARAM)) {
			setFeatureCodes(getFeatureCodes(new URLSearchParams(queryParams)));
			hideFeatureCodesQueryParam(history, location);
		}
	}, [history, location, queryParams]);

	return featureCodes;
};
