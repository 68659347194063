import type { Resolver, Option } from '../extensionTypes';

export const unsupportedValueResolver =
	(resolver: Resolver) => async (searchTerm?: string, selectedValue?: string | string[]) => {
		const options = await resolver(searchTerm, selectedValue);
		if (selectedValue) {
			const selectedValues = ([] as string[]).concat(selectedValue);
			return selectedValues
				.filter((v) => !options.find(({ value }) => value === v))
				.map<Option>((v) => ({
					label: v,
					value: v,
				}))
				.concat(options);
		}
		return options;
	};
