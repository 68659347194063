import { confluenceLocalStorageInstance as localStorage } from '@confluence/storage-manager';

export type CriticalChunks = {
	[chunkPrefix: string]: {
		cached: boolean;
		duration: number;
	};
};

type Assets = {
	[chunkPrefix: string]: string;
};

const isAssets = (x: unknown): x is Assets => {
	if (typeof x !== 'object' || !x) {
		return false;
	}

	for (const value of Object.values(x)) {
		if (typeof value !== 'string') {
			return false;
		}
	}

	return true;
};

const scriptInitiator = {
	script: true,
	other: true,
	link: true,
};

const CACHED_ASSETS_KEY = 'confluence.cachedAssets';
const criticalChunkPrefixes = {
	'query-preloader': true,
	'translations.en-US': true,
	runtime: true,
	atlaskit: true,
	afp: true,
	static: true,
	vendors: true,
	app: true,
};

const getCachedAssets = (): Assets => {
	try {
		const assets = JSON.parse(localStorage.getItem(CACHED_ASSETS_KEY)) || {};

		if (!isAssets(assets)) {
			return {};
		}

		return assets;
	} catch (ex) {
		return {};
	}
};

const updateCachedAssets = (assets: Assets): void => {
	try {
		localStorage.setItem(CACHED_ASSETS_KEY, JSON.stringify(assets));
	} catch (ex) {
		// NOOP
	}
};

export type AssetCacheStatus = {
	criticalChunks: CriticalChunks;
	totalChunkCount: number;
};

export const getAssetCacheStatus = (endTime: number): AssetCacheStatus => {
	const cachedChunks = getCachedAssets();
	const loadedChunks: Assets = {};
	const criticalChunks: CriticalChunks = {};

	let totalChunkCount = 0;

	performance.getEntriesByType('resource').forEach((entry) => {
		const { initiatorType, responseEnd, duration, name } = entry as PerformanceResourceTiming;

		// Discard non-scripts and scripts that were loaded after the end time
		if (!scriptInitiator[initiatorType as keyof typeof scriptInitiator] || responseEnd > endTime) {
			return;
		}

		const chunkName = name.split('/').pop();

		if (!chunkName) {
			return;
		}

		const chunkPrefix = chunkName.split('~').shift();

		if (!chunkPrefix) {
			return;
		}

		// Match loaded chunk name with cached chunk name for all critical chunks
		if (criticalChunkPrefixes[chunkPrefix as keyof typeof criticalChunkPrefixes]) {
			loadedChunks[chunkPrefix] = chunkName;
			criticalChunks[chunkPrefix] = {
				cached: cachedChunks[chunkPrefix] === chunkName,
				duration,
			};
		}

		totalChunkCount++;
	});

	updateCachedAssets(loadedChunks);

	return {
		criticalChunks,
		totalChunkCount,
	};
};
