import type { ReactElement } from 'react';
import idx from 'idx';

import type { ExperienceAttributes, ExperienceEvent } from '@confluence/experience-tracker';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { useDeepCompareEffect } from '../useDeepCompareEffect';
import { getMacroExperienceTracker, MACRO_TRACKER_FF } from '../MacroExperienceTracker';

export type MacroExperienceStartProps = {
	extensionKey?: string;
	name: string;
	mode: string;
	timeout: number;
	attributes?: ExperienceAttributes;
	collect?(events: ExperienceEvent[], experience): void;
	onSuccess?(): void;
	children?: ReactElement | null;
	contentId: string;
};

export function MacroExperienceStart({
	attributes,
	children = null,
	contentId,
	extensionKey,
	mode,
	name,
	timeout,
}: MacroExperienceStartProps) {
	const isMacroTrackerEnabled = useBooleanFeatureFlag(MACRO_TRACKER_FF);

	useDeepCompareEffect(() => {
		if (isMacroTrackerEnabled) {
			getMacroExperienceTracker(mode, contentId).start({
				attributes,
				name,
				timeout,
				id: (idx(attributes, (_) => _.macroId) as string) || contentId,
			});
		}
	}, [attributes, contentId, extensionKey, isMacroTrackerEnabled, mode, name, timeout]);

	// https://stackoverflow.com/questions/54905376
	return children as ReactElement<any>;
}
