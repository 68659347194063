/**
 * Takes an object and returns a new object with the specified keys removed.
 * @param {Object} obj - The object that will have keys removed.
 * @param {Set} keys - The keys to remove from the target object.
 * @returns {Object}
 */
export function exclude(obj, keys) {
  return Object.keys(obj).reduce((newObj, key) => {
    if (!keys.has(key)) {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
}

/**
 * Applies a function to each element in an object (similar to Array.prototype.map).
 * @param {Object} obj - The object that will be iterated.
 * @param {Function} fn - The function to apply to each item of the target object.
 * @returns {Object}
 */
export function map(obj, fn) {
  const newObj = {};
  Object.keys(obj || {}).forEach(key => {
    newObj[key] = fn(obj[key], key, obj);
  });
  return newObj;
}
