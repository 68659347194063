import { query } from '@confluence/query-preloader-tools';

import type { AdminAnnouncementBannerQuery as AdminAnnouncementBannerQueryType } from './queries/__types__/AdminAnnouncementBannerQuery';
import { AdminAnnouncementBannerQuery } from './queries/AdminAnnouncementBannerQuery.graphql';

export const ADMIN_ANNOUNCEMENT_BANNER_QUERY = 'AdminAnnouncementBannerQuery';

export const preloadAdminAnnouncementBanner = () =>
	query<AdminAnnouncementBannerQueryType, {}>({
		query: AdminAnnouncementBannerQuery,
	});
