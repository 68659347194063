import React, { Suspense } from 'react';
import { type Props } from '../..';
import { IconPaths } from './imports';
import { type Icon as Glyph } from '@atlassian/forge-ui-types';
import { useIntl, defineMessages } from 'react-intl-next';

interface IconProps {
	size?: 'small' | 'medium' | 'large';
	glyph: Glyph;
}

const Icon = ({ size = 'medium', glyph }: IconProps) => {
	const Component = IconPaths[glyph] as React.LazyExoticComponent<any>;
	const intl = useIntl();
	if (!Component) {
		return null;
	} // do nothing

	return (
		<Suspense fallback={null}>
			{
				// @ts-ignore Jira only - TS2322: Type '{ label: string; size: "small" | "medium" | "large"; }' is not assignable to type 'IntrinsicAttributes'.
				<Component label={intl.formatMessage(messages.icon)} size={size} />
			}
		</Suspense>
	);
};

export default Icon;

export function IconFn({ props }: Props) {
	const { glyph, size } = props as IconProps;
	return <Icon glyph={glyph} size={size} />;
}

const messages = defineMessages({
	icon: {
		id: 'confluence.icon.label',
		defaultMessage: 'Icon',
		description: 'A label identifying this component as an icon.',
	},
});
