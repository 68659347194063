import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';

import { createSimplePubSub } from '../../util/simple-pub-sub';
import { PostOfficeRouteProvider } from '../store';
import type { RouteChangeParams } from '../store/types';

import type { RouteSubscriber, RouteUpdater, UseRouteListener } from './types';

const noop = () => {};

const RouteListenerContext = createContext<RouteUpdater>({
	update: noop,
});

export const createRouteSubscriber = (): RouteSubscriber => createSimplePubSub<RouteChangeParams>();

export const useRouteListenerContext = (): RouteUpdater => useContext(RouteListenerContext);

export const useRouteListener: UseRouteListener = () => {
	const ref = useRef<RouteSubscriber>();

	if (!ref.current) {
		ref.current = createRouteSubscriber();
	}

	const [routeSubscriber, updateRoute] = ref.current;

	const routeConfig = useMemo(
		() => ({
			initialUrl: new URL(window.location.href),
			routeSubscriber,
		}),
		[routeSubscriber],
	);

	const routeUpdater = useMemo(() => ({ update: updateRoute }), [updateRoute]);

	return [routeConfig, routeUpdater];
};

/**
 * Custom hook to integrate post-office with your router. This allows placement to subscriber to route updates
 * This hook is specifically designed to work in a browser environment and will not execute in server-side or non-browser environments.
 *
 * Note: This requires PostOfficeProvider to be in the tree above the use of this hook.
 *
 * @param {string[]} deps - An array of dependencies that, when changed, will trigger the update of the route context.
 * This array should include any variables or state that, when updated, should cause the route context to be updated with the current URL.
 * In practise this is likely to be location information from your router.
 *
 * @example
 * // Example usage with React Resource Router
 * const ReactResourceRouterListener = ({ children }: { children: React.ReactNode }) => {
 *	const [route] = useRouter();
 *
 *	usePostOfficeRouteUpdate([route.basePath, route.location.pathname]);
 *
 *	return <>{children}</>;
 *};
 */
export const usePostOfficeRouteUpdate = (deps: string[]) => {
	const { update } = useRouteListenerContext();

	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}

		update({ next: new URL(window.location.href) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};

export const RouteListenerProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const [route, routeUpdater] = useRouteListener();

	return (
		<RouteListenerContext.Provider value={routeUpdater}>
			<PostOfficeRouteProvider {...route}>{children}</PostOfficeRouteProvider>
		</RouteListenerContext.Provider>
	);
};
