export enum ProductEnvironment {
	DEVELOPMENT = 'DEVELOPMENT',
	STAGING = 'STAGING',
	PRODUCTION = 'PRODUCTION',
}

export enum ForgeCDNEnvironment {
	DEVELOPMENT = 'dev',
	STAGING = 'stg',
	PRODUCTION = 'prod',
	FEDRAMP_SANDBOX = 'fex', // fex = fedex = fedramp sandbox = fedramp experiment environment
}
