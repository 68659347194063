// @ts-ignore
import traverseISODate from '@segment/isodate-traverse';

import { type Context } from '../types';

import { type SegmentContextDef } from './types';

export const buildContext = (context: Context): SegmentContextDef => {
	return {
		...context.context,
		userAgent: navigator.userAgent,
	};
};

export const prepareEventProperties = <T extends object>(properties: T): T => {
	const propertiesWithoutUndefined = nestedRemoveUndefinedValues(properties);
	return traverseISODate(propertiesWithoutUndefined);
};

// This function will essentially deepClone as well
export const nestedRemoveUndefinedValues = <T extends object>(obj: T): T => {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	const clone: any = {};

	Object.keys(obj).forEach((key) => {
		const value = getValueOfObject(obj, key);
		if (value !== undefined) {
			if (Array.isArray(value)) {
				clone[key] = value.map((i) => nestedRemoveUndefinedValues(i));
			} else if (typeof value === 'object' && value !== null) {
				clone[key] = nestedRemoveUndefinedValues(value);
			} else {
				clone[key] = value;
			}
		}
	});
	return clone;
};

// Using ts-ignore everywhere urked me
const getValueOfObject = (obj: object, key: string): unknown => {
	// @ts-ignore This is an object
	return obj[key];
};
