const loaded = new Set<string>();

export function hasBeenLoaded(id: string | undefined) {
	return id ? loaded.has(id) : false;
}

export function saveHasBeenLoaded(id: string | undefined) {
	if (id) {
		loaded.add(id);
	}
}

export function cleanStateForTesting() {
	loaded.clear();
}
