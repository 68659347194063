import type { NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { NodeSelection } from '@atlaskit/editor-prosemirror/state';
import { uuid } from '@atlaskit/adf-schema';
import type { JSONNode } from '@atlaskit/editor-json-transformer';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import type { EditorAnalyticsAPI } from '@atlaskit/editor-common/analytics';
import { startMeasure, stopMeasure } from '@atlaskit/editor-common/performance-measures';
import type {
	AttachedEditorView,
	ConnectionLink,
	LinkNode,
	LocalId,
	NewLinkElement,
	NodeNamingHandler,
	ReferentialityAPI,
	NodeFocusHandler,
	ReferentialityAPIOptions,
	NormalizeLocalIdHandler,
} from './types';
import { MEASURE_NAME } from './types';
import { ReferentialityContext } from './referentiality-context';
import {
	CompatibilityError,
	ConfigurationError,
	NodeNotFoundError,
	UnsupportedTypeError,
	TargetReferenceError,
} from './errors';
import {
	connectNodes,
	createConnectionLink,
	createLinkElementNode,
	disconnectNodes,
	findNodeByLocalId,
	generateMarkNameWithNumber,
	getNodesSupportingFragmentMark,
	insertNodeAfter,
	insertTarget,
	normalizeIds,
	updateSources,
	createLinkNode,
	resetNodes,
	updateNodeSources,
	updateFragmentName,
} from './utils';
import {
	captureConnectNodesEvent,
	captureDisconnectSourceEvent,
	captureDisconnectTargetEvent,
	captureGetConnectionsEvent,
	captureInitialiseFragmentMarksEvent,
	captureUpdateFragmentMarkNameEvent,
	captureUpdateSourceEvent,
	captureUpdateTargetEvent,
} from './analytics';

export class ReferentialityAPIImpl implements AttachedEditorView, ReferentialityAPI {
	/**
	 * @constructor
	 */
	constructor({
		nodeNameCallback, // you can also set this callback using setNodeNameCallback method
		nodeFocusCallback, // you can also set this callback using setNodeFocusCallback method
		editorAnalyticsAPI,
		editorView,
		featureFlags,
	}: ReferentialityAPIOptions = {}) {
		this.nodeNameCallback = nodeNameCallback;
		this.nodeFocusCallback = nodeFocusCallback;
		this.editorAnalyticsAPI = editorAnalyticsAPI;
		this.editorView = editorView;
		this.normalizeLocalId = (attrsLocalId, fragmentLocalId) => fragmentLocalId ?? attrsLocalId!;
	}

	private nodeNameCallback?: NodeNamingHandler | null;
	private nodeFocusCallback?: NodeFocusHandler | null;
	private normalizeLocalId: NormalizeLocalIdHandler;
	private editorAnalyticsAPI?: EditorAnalyticsAPI;
	private editorView?: EditorView;
	private selectedLocalId?: string;

	get isAttached(): boolean {
		return !!this.editorView;
	}

	attach(editorView: EditorView): void {
		const {
			state: { schema },
		} = editorView;

		if (!schema.marks.fragment) {
			throw new CompatibilityError('fragmentMark not supported');
		}

		this.editorView = editorView;
	}

	detach(): void {
		this.editorView = undefined;
	}

	setNodeFocusCallback(callback?: NodeFocusHandler | null): void {
		this.nodeFocusCallback = callback;
	}

	setNodeNameCallback(callback: NodeNamingHandler | null): void {
		this.nodeNameCallback = callback;
	}

	setSelectedLocalId(localId?: LocalId): void {
		if (this.selectedLocalId === localId) {
			return;
		}

		this.selectedLocalId = localId;

		if (!!this.nodeFocusCallback) {
			if (!localId) {
				this.nodeFocusCallback(undefined);
				return;
			}

			if (this.editorView) {
				const { state } = this.editorView;
				const foundNode = findNodeByLocalId(localId, state);
				if (foundNode) {
					this.nodeFocusCallback(
						createLinkNode(
							this.normalizeLocalId(localId, foundNode.fragmentMark?.attrs.localId),
							foundNode.fragmentMark?.attrs.name || '',
							foundNode.node,
						),
					);
				}
			}
		}
	}

	initialiseFragmentMarks() {
		if (!this.editorView) {
			throw new ConfigurationError('initialiseFragmentMarks');
		}

		startMeasure(MEASURE_NAME.INITIALISE_FRAGMENT_MARKS);

		const { state, dispatch } = this.editorView;
		let { tr } = state;
		const docSize = state.doc.nodeSize;
		const context = new ReferentialityContext(this.editorView, this.normalizeLocalId, false);
		let count = 0;

		const maxNumberUsedInFragmentMarkNames = context.getMaxNumberUsedInFragmentMarkNames();

		for (const target of context.namelessOnlyConnections()) {
			const { markNameWithNumber, markName, markNameNumber } = generateMarkNameWithNumber(
				target.node,
				state.schema,
				maxNumberUsedInFragmentMarkNames,
				this.nodeNameCallback,
			);

			maxNumberUsedInFragmentMarkNames[markName] = markNameNumber;

			tr = updateFragmentName(state.schema, target, markNameWithNumber)(tr);
			count++;
		}

		tr.setMeta('addToHistory', false);

		stopMeasure(
			MEASURE_NAME.INITIALISE_FRAGMENT_MARKS,
			captureInitialiseFragmentMarksEvent({
				docSize,
				count,
				tr,
				editorAnalyticsAPI: this.editorAnalyticsAPI,
			}),
		);

		dispatch(tr);
	}

	getConnections() {
		startMeasure(MEASURE_NAME.GET_CONNECTIONS);
		const result: Record<LocalId, ConnectionLink> = {};

		if (!this.editorView) {
			stopMeasure(MEASURE_NAME.GET_CONNECTIONS);
			return result;
		}

		const {
			state: {
				tr,
				doc: { nodeSize },
			},
		} = this.editorView;

		const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);
		// First initialize all possible connection linkages
		for (const { node, normalizedId, name } of context.uniqueConnections()) {
			result[normalizedId] = createConnectionLink(node, normalizedId, name);
		}

		// This 2nd-pass only looks at the consumer sources and updates all connections with the correct id refs.
		for (const { normalizedId, dataConsumer } of context.consumerOnlyConnections()) {
			// This is a ref to the node (connection link) which contains the consumer.
			const currentNodeLink = result[normalizedId];

			dataConsumer!.attrs.sources.forEach((src: string) => {
				const normalizedSrcId = context.getById(src)?.normalizedId ?? src;
				const srcLink = result[normalizedSrcId];

				if (srcLink && normalizedSrcId !== normalizedId) {
					srcLink.targets.push(normalizedId);
					currentNodeLink?.sources.push(normalizedSrcId);
				}
			});
		}

		stopMeasure(
			MEASURE_NAME.GET_CONNECTIONS,
			captureGetConnectionsEvent({
				tr,
				editorAnalyticsAPI: this.editorAnalyticsAPI,
				docSize: nodeSize,
				count: Object.keys(result).length,
			}),
		);

		return result;
	}

	select(localId: LocalId, scroll: boolean = false): void {
		if (!this.editorView || !localId) {
			throw new ConfigurationError('select');
		}

		const { state, dispatch } = this.editorView;
		const foundNode = findNodeByLocalId(localId, state);

		if (foundNode) {
			const { tr } = state;
			tr.setSelection(NodeSelection.create(state.doc, foundNode.pos));
			if (scroll) {
				tr.scrollIntoView();
			}
			tr.setMeta('addToHistory', false);
			dispatch(tr);
		}
	}

	updateName(localId: LocalId, name: string) {
		try {
			startMeasure(MEASURE_NAME.UPDATE_FRAGMENT_MARK_NAME);

			if (!this.isAttached || !this.editorView) {
				throw new ConfigurationError('updateName');
			}

			const {
				state,
				state: { schema },
				dispatch,
			} = this.editorView;

			const foundNode = findNodeByLocalId(localId, state);
			if (!foundNode) {
				throw new NodeNotFoundError(localId);
			}

			const { fragment } = schema.marks;
			const { fragmentMark, node, pos } = foundNode;

			const fragLocalId = fragmentMark?.attrs.localId ?? uuid.generate();

			const nextFragmentMark = fragment.create({
				localId: fragLocalId,
				name,
			});

			const nextMarks = fragmentMark
				? node.marks.filter((mark) => mark.type !== nextFragmentMark.type)
				: node.marks.concat();
			nextMarks.push(nextFragmentMark);

			// ensure the fragment mark on the node has the correct id & name.
			let tr = state.tr.setNodeMarkup(pos, undefined, node.attrs, nextMarks);

			dispatch(tr);

			const linkNode = createLinkNode(
				this.normalizeLocalId(node.attrs.localId, fragLocalId),
				name,
				node,
			);

			stopMeasure(
				MEASURE_NAME.UPDATE_FRAGMENT_MARK_NAME,
				captureUpdateFragmentMarkNameEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					nodeType: node.type.name,
				}),
			);

			return linkNode;
		} catch (e) {
			stopMeasure(MEASURE_NAME.UPDATE_FRAGMENT_MARK_NAME);
			throw e;
		}
	}

	getADFFromLocalId(localId: LocalId): JSONNode {
		if (!this.isAttached || !this.editorView) {
			throw new ConfigurationError('getADFFromLocalId');
		}

		const { state } = this.editorView;

		const foundNode = findNodeByLocalId(localId, state);
		if (!foundNode) {
			throw new NodeNotFoundError(localId);
		}

		return new JSONTransformer().encodeNode(foundNode.node) as JSONNode;
	}

	connectSource(targetLocalId: LocalId, sourceLocalId: LocalId): LinkNode {
		try {
			startMeasure(MEASURE_NAME.CONNECT_NODES);

			if (!this.editorView) {
				throw new ConfigurationError('connectSource');
			}

			const { dispatch, state } = this.editorView;
			const docSize = state.doc.nodeSize;

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const { source, target, tr } = connectNodes(context, targetLocalId, sourceLocalId, state.tr);

			dispatch(tr);

			stopMeasure(
				MEASURE_NAME.CONNECT_NODES,
				captureConnectNodesEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					actionType: 'connectSource',
					type: 'connect',
					sourceNodeType: source.node.type,
					targetNodeType: target.node.type,
				}),
			);

			return source;
		} catch (e) {
			stopMeasure(MEASURE_NAME.CONNECT_NODES);
			throw e;
		}
	}

	connectTarget(sourceLocalId: LocalId, targetLocalId: LocalId): LinkNode {
		try {
			startMeasure(MEASURE_NAME.CONNECT_NODES);

			if (!this.editorView) {
				throw new ConfigurationError('connectTarget');
			}

			const { dispatch, state } = this.editorView;
			const docSize = state.doc.nodeSize;

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const { source, target, tr } = connectNodes(context, targetLocalId, sourceLocalId, state.tr);

			dispatch(tr);

			stopMeasure(
				MEASURE_NAME.CONNECT_NODES,
				captureConnectNodesEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					actionType: 'connectTarget',
					type: 'connect',
					sourceNodeType: source.node.type,
					targetNodeType: target.node.type,
				}),
			);

			return target;
		} catch (e) {
			stopMeasure(MEASURE_NAME.CONNECT_NODES);
			throw e;
		}
	}

	updateTarget(
		sourceLocalId: LocalId,
		oldTargetLocalId: LocalId,
		newTargetLocalId: LocalId,
	): LinkNode {
		try {
			startMeasure(MEASURE_NAME.UPDATE_TARGET);

			if (!this.editorView) {
				throw new ConfigurationError('updateTarget');
			}

			const { dispatch, state } = this.editorView;
			const docSize = state.doc.nodeSize;

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const { target, source, tr } = connectNodes(
				context,
				newTargetLocalId,
				sourceLocalId,
				disconnectNodes(context, oldTargetLocalId, sourceLocalId, state.tr),
			);

			dispatch(tr);

			stopMeasure(
				MEASURE_NAME.UPDATE_TARGET,
				captureUpdateTargetEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					newTargetNodeType: target.node.type,
					oldTargetNodeType: context.getById(oldTargetLocalId)?.node.type.name || '',
					sourceNodeType: source.node.type,
					type: 'update',
				}),
			);

			return target;
		} catch (e) {
			stopMeasure(MEASURE_NAME.UPDATE_TARGET);
			throw e;
		}
	}

	updateSource(targetLocalId: LocalId, newSourceLocalId: LocalId): LinkNode {
		try {
			startMeasure(MEASURE_NAME.UPDATE_SOURCE);

			if (!this.editorView) {
				throw new ConfigurationError('updateSource');
			}

			const { dispatch, state } = this.editorView;
			const docSize = state.doc.nodeSize;

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const { target, source, tr } = resetNodes(context, targetLocalId, newSourceLocalId, state.tr);

			dispatch(tr);

			stopMeasure(
				MEASURE_NAME.UPDATE_SOURCE,
				captureUpdateSourceEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					oldSourceNodeType: target.node.type,
					newSourceNodeType: source.node.type,
					type: 'update',
				}),
			);

			return source;
		} catch (e) {
			stopMeasure(MEASURE_NAME.UPDATE_SOURCE);
			throw e;
		}
	}

	disconnectTarget(sourceLocalId: LocalId, targetLocalId: LocalId): void {
		try {
			startMeasure(MEASURE_NAME.DISCONNECT_TARGET);

			if (!this.editorView) {
				throw new ConfigurationError('disconnectTarget');
			}

			const { dispatch, state } = this.editorView;
			const docSize = state.doc.nodeSize;

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const tr = disconnectNodes(context, targetLocalId, sourceLocalId, state.tr);

			dispatch(tr);

			stopMeasure(
				MEASURE_NAME.DISCONNECT_TARGET,
				captureDisconnectTargetEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					sourceNodeType: context.getById(sourceLocalId)?.node.type.name || '',
					targetNodeType: context.getById(targetLocalId)?.node.type.name || '',
				}),
			);
		} catch (e) {
			stopMeasure(MEASURE_NAME.DISCONNECT_TARGET);
			throw e;
		}
	}

	disconnectSource(targetLocalId: LocalId): void {
		try {
			startMeasure(MEASURE_NAME.DISCONNECT_SOURCE);

			if (!this.editorView) {
				throw new ConfigurationError('disconnectSource');
			}

			const { dispatch, state } = this.editorView;
			const docSize = state.doc.nodeSize;

			const target = findNodeByLocalId(targetLocalId, state);
			if (!target) {
				throw new TargetReferenceError(targetLocalId);
			}

			const tr = updateNodeSources(state.schema, target.node, target.pos)(state.tr);

			dispatch(tr);

			stopMeasure(
				MEASURE_NAME.DISCONNECT_SOURCE,
				captureDisconnectSourceEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					targetNodeType: target.node.type.name,
				}),
			);
		} catch (e) {
			stopMeasure(MEASURE_NAME.DISCONNECT_SOURCE);
			throw e;
		}
	}

	insertAndConnectTarget(sourceLocalId: LocalId, target: NewLinkElement): LinkNode {
		try {
			startMeasure(MEASURE_NAME.CONNECT_NODES);

			if (!this.editorView) {
				throw new ConfigurationError('insertAndConnectTarget');
			}

			const { dispatch, state } = this.editorView;

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const { linkNode, tr } = insertTarget(
				context,
				sourceLocalId,
				target,
				state.tr,
				this.nodeNameCallback,
			);

			dispatch(tr);

			const docSize = tr.doc.nodeSize;

			stopMeasure(
				MEASURE_NAME.CONNECT_NODES,
				captureConnectNodesEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					actionType: 'connectTarget',
					type: 'insertAndConnect',
					sourceNodeType: context.getById(sourceLocalId)?.node.type.name || '',
					targetNodeType: target.type,
				}),
			);

			return linkNode;
		} catch (e) {
			stopMeasure(MEASURE_NAME.CONNECT_NODES);
			throw e;
		}
	}

	insertAndConnectSource(targetLocalId: LocalId, source: NewLinkElement): LinkNode {
		try {
			stopMeasure(MEASURE_NAME.CONNECT_NODES);

			if (!this.editorView) {
				throw new ConfigurationError('insertAndConnectSource');
			}

			const {
				state,
				state: { schema },
				dispatch,
			} = this.editorView;
			const nodeType: NodeType = schema.nodes[source.type];

			if (!getNodesSupportingFragmentMark(schema).has(nodeType)) {
				throw new UnsupportedTypeError(source.type);
			}

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);
			const target = context.getById(targetLocalId);

			if (!target) {
				throw new TargetReferenceError(targetLocalId);
			}

			const { linkNode, node } = createLinkElementNode(context, source, this.nodeNameCallback);

			let tr = state.tr;

			const targetSources = normalizeIds(context, target.dataConsumer?.attrs.sources).add(
				linkNode.localId,
			);

			tr = updateSources(schema, target, targetSources)(tr);
			tr = insertNodeAfter(targetLocalId, node, state, tr);
			tr = tr.setMeta('referentialityTableInserted', node.type.name === schema.nodes.table.name);

			dispatch(tr);

			const docSize = tr.doc.nodeSize;

			stopMeasure(
				MEASURE_NAME.CONNECT_NODES,
				captureConnectNodesEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					actionType: 'connectSource',
					type: 'insertAndConnect',
					sourceNodeType: node.type.name,
					targetNodeType: context.getById(targetLocalId)?.node.type.name || '',
				}),
			);

			return linkNode;
		} catch (e) {
			stopMeasure(MEASURE_NAME.CONNECT_NODES);
			throw e;
		}
	}

	replaceAndUpdateTarget(
		sourceLocalId: LocalId,
		oldTargetLocalId: LocalId,
		newTarget: NewLinkElement,
	): LinkNode {
		try {
			stopMeasure(MEASURE_NAME.UPDATE_TARGET);
			if (!this.editorView) {
				throw new ConfigurationError('replaceAndUpdateTarget');
			}

			const { dispatch, state } = this.editorView;
			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);

			const { linkNode, tr } = insertTarget(
				context,
				sourceLocalId,
				newTarget,
				state.tr,
				this.nodeNameCallback,
				oldTargetLocalId,
			);

			dispatch(tr);

			const docSize = tr.doc.nodeSize;

			stopMeasure(
				MEASURE_NAME.UPDATE_TARGET,
				captureUpdateTargetEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					type: 'replaceAndUpdate',
					newTargetNodeType: newTarget.type,
					oldTargetNodeType: context.getById(oldTargetLocalId)?.node.type.name || '',
					sourceNodeType: context.getById(sourceLocalId)?.node.type.name || '',
				}),
			);

			return linkNode;
		} catch (e) {
			stopMeasure(MEASURE_NAME.UPDATE_TARGET);
			throw e;
		}
	}

	replaceAndUpdateSource(targetLocalId: LocalId, newSource: NewLinkElement): LinkNode {
		try {
			startMeasure(MEASURE_NAME.UPDATE_SOURCE);

			if (!this.editorView) {
				throw new ConfigurationError('replaceAndUpdateSource');
			}

			const {
				state: { schema },
				state,
				dispatch,
			} = this.editorView;
			const nodeType: NodeType = schema.nodes[newSource.type];

			if (!getNodesSupportingFragmentMark(schema).has(nodeType)) {
				throw new UnsupportedTypeError(newSource.type);
			}

			const context = new ReferentialityContext(this.editorView, this.normalizeLocalId);
			const target = context.getById(targetLocalId);

			if (!target) {
				throw new TargetReferenceError(targetLocalId);
			}

			const { linkNode, node } = createLinkElementNode(context, newSource, this.nodeNameCallback);

			let tr = updateSources(context.schema, target, new Set([linkNode.localId]))(state.tr);

			tr = insertNodeAfter(targetLocalId, node, state, tr);
			tr = tr.setMeta('referentialityTableInserted', node.type.name === schema.nodes.table.name);

			dispatch(tr);

			const docSize = tr.doc.nodeSize;

			stopMeasure(
				MEASURE_NAME.UPDATE_SOURCE,
				captureUpdateSourceEvent({
					tr,
					editorAnalyticsAPI: this.editorAnalyticsAPI,
					docSize,
					oldSourceNodeType: target.node.type.name,
					newSourceNodeType: newSource.type,
					type: 'replaceAndUpdate',
				}),
			);

			return linkNode;
		} catch (e) {
			stopMeasure(MEASURE_NAME.UPDATE_SOURCE);
			throw e;
		}
	}
}
