import { useCallback } from 'react';
import type { ApolloError } from 'apollo-client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import type { GraphQLError } from 'graphql';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';

import { FeedFollowQuery } from './FeedFollowQuery.graphql';
import { FeedFollowMutation } from './FeedFollowMutation.graphql';
import type { FeedFollowQuery as FeedFollowQueryType } from './__types__/FeedFollowQuery';
import type {
	FeedFollowMutation as FeedFollowMutationType,
	FeedFollowMutationVariables,
} from './__types__/FeedFollowMutation';

const isUserHasNoAccessError = (error: ApolloError): boolean =>
	error?.graphQLErrors?.some(
		(gqlError: GraphQLError) =>
			gqlError?.path?.includes('getFeedUserConfig') &&
			gqlError?.message?.includes('User not permitted to access Confluence'),
	);

type FeedFollowData = {
	renderFeedFollow: boolean;
	getToggleFollowUser(
		userId: string | null | undefined,
		followed: boolean,
		source: string,
	): () => void;
	followedUsers: (string | null)[] | undefined;
	feedConfigError?: ApolloError;
	mutationError?: ApolloError;
	loading?: boolean;
};

export let skipQuery = false;

export function useFeedFollow(): FeedFollowData {
	const { isLicensed } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		data,
		loading: queryLoading,
		error: feedConfigError,
	} = useQuery<FeedFollowQueryType>(FeedFollowQuery, {
		skip: !isLicensed || skipQuery,
		onError: (error: ApolloError) => (skipQuery = isUserHasNoAccessError(error)),
	});
	const [mutateFeedFollow, { error: mutationError }] = useMutation<
		FeedFollowMutationType,
		FeedFollowMutationVariables
	>(FeedFollowMutation, {
		refetchQueries: ['FeedFollowQuery'],
	});

	const followedUsers = data?.getFeedUserConfig.accountIds;

	const getToggleFollowUser = useCallback(
		(accountId: string | null | undefined, following: boolean, source: string) => async () => {
			if (!accountId) {
				return;
			}

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'feedFollow',
					source,
					attributes: {
						id: accountId,
						settingType: 'user',
						actionType: following ? 'unfollow' : 'follow',
					},
				},
			}).fire();

			try {
				if (following) {
					await mutateFeedFollow({ variables: { unfollow: [accountId] } });
				} else {
					await mutateFeedFollow({ variables: { follow: [accountId] } });
				}
			} catch (_) {
				// noop since the hook consumer logs the error through <ErrorDisplay>
				// mutateFeedFollow() has the potential to throw an error;
				// since we do not provide an onError callback to the useMutation hook
				// we need to catch the error here as a safety net.
			}
		},
		[mutateFeedFollow, createAnalyticsEvent],
	);

	return {
		renderFeedFollow: !(queryLoading || feedConfigError || skipQuery) && isLicensed,
		loading: queryLoading,
		getToggleFollowUser,
		feedConfigError,
		mutationError,
		followedUsers,
	};
}
