import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import window from 'window-or-global';

import waitForGlobal from '../../lib/wait-for-global-PLEASE-AVOID-USING';

/**
 * LegacyBridge aims to solve in a generic way the dependencies we
 * have on legacy metatags, params and etc
 *
 * Important info:
 * - This component should be rendered with its container at the highest
 * point possible in the tree, only once;
 * - Currently in MainStage.js, it will never be unmounted. MainStage is
 * the first component returned by the router so it is the perfect place;
 * - Properties not available in the state will be set undefined.
 * - It shouldn't handle api requests, only translate info from the state
 * to metatags, params, etc. Call actions for performing api requests
 * inside your component as you would do if this component was not available.
 */

/**
 * DO NOT ADD ANYTHING NEW TO THIS FILE.
 * ADD TO next/packages/view-page/src/LegacyBridgeNext.js
 */
export default class LegacyBridge extends Component {
	static propTypes = {
		contentId: PropTypes.string,
		contentType: PropTypes.string,

		spaceKey: PropTypes.string,
		spaceName: PropTypes.string,
		spaceType: PropTypes.string,

		pageTitle: PropTypes.string,
		pageVersion: PropTypes.number,
		parentPageId: PropTypes.string,
		parentPageTitle: PropTypes.string,
		canRemovePage: PropTypes.bool,

		experimentFeatures: PropTypes.string,
	};

	componentDidMount() {
		this._setLegacyProps(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this._setLegacyProps(nextProps);
	}

	shouldComponentUpdate(nextProps) {
		return !isEqual(this.props, nextProps);
	}

	componentWillUnmount() {
		this._setAJSValues({});
		this._cancelWaitForGlobalInProgress();
	}

	_waitForGlobalInProgress = null;

	_setLegacyProps = (props) => {
		this._cancelWaitForGlobalInProgress();
		this._waitForGlobalInProgress = waitForGlobal(
			{ listener: 'LegacyBridge.js', globalProperty: 'AJS.params' },
			() => this._setAJSValues(props),
		);
	};

	_cancelWaitForGlobalInProgress = () => {
		if (this._waitForGlobalInProgress) {
			// This prevents waitForGlobal to updates this component in random order in case of multiple calls.
			this._waitForGlobalInProgress.cancel();
		}
	};

	_setAJSValues = (props) => {
		if (!window.AJS || !window.AJS.params) {
			return;
		}

		const {
			spaceKey,
			spaceName,
			spaceType,
			contentId,
			contentType,
			pageTitle,
			pageVersion,
			parentPageId,
			parentPageTitle,
			canRemovePage,
			experimentFeatures,
		} = props;

		const createPage = pageVersion === 0;
		const pageId = createPage ? '0' : contentId;

		// page/content info
		window.AJS.params.pageId = pageId;
		window.AJS.params.spaceKey = spaceKey;

		const metaParams = {
			// page/content info
			'content-id': contentId,
			'content-type': contentType,
			'page-id': pageId,
			'page-title': pageTitle,
			'page-version': pageVersion,
			'parent-page-id': parentPageId,
			'parent-page-title': parentPageTitle,
			'latest-page-id': contentId,
			'can-remove-page': canRemovePage,
			'new-page': createPage,

			// space info
			'space-key': spaceKey,
			'space-name': spaceName,
			'space-type': spaceType,

			// experiments
			'experiment-features': experimentFeatures,
		};

		// Do not set if legacy bridge in next has already handled
		// See: next/packages/view-page/src/LegacyBridgeNext.js
		if (window.AJS.Meta && !window.AJS.Meta.get('has-next-legacy-bridge')) {
			Object.keys(metaParams).forEach((key) => {
				// standalone mode doesn't have AJS
				window.AJS.Meta.set(key, metaParams[key]);
			});
		}
	};

	render() {
		return null;
	}
}
