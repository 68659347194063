import type { ApolloClient } from 'apollo-client';

import { getActiveTraceHttpRequestHeaders } from '@atlaskit/react-ufo/experience-trace-id-context';

import { fg } from '@confluence/feature-gating';
import { appendHeader, cfetch } from '@confluence/network';
import { SSRMeasures, WaterfallMeasures } from '@confluence/action-measures';
import { markErrorAsHandled } from '@confluence/graphql-error-processor';

import { InlineCommentsQueryAgg } from './queries/InlineCommentsQuery.agggraphql';
import type {
	InlineCommentsQueryAgg as InlineCommentsQueryAggType,
	InlineCommentsQueryVariables,
} from './queries/__types__/InlineCommentsQuery';
import {
	createExperienceAwareFetch,
	isExperienceAwareFetchAlwaysEnabled,
} from './createExperienceAwareFetch';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';

const experienceAwareFetch = createExperienceAwareFetch();

// appends the operation names as query params, and
// includes them as headers as well
export const createWrappedFetch = (isCcGraphQL: Boolean) => {
	return (uri: string, options: RequestInit) => {
		if (isCcGraphQL) {
			// On client side the uri is /cgraphql
			// However when running on the server side we want to dynamic change the URI
			// So we can route the request to the graphql deployment that closer to the data.
			// AGG host is redirected by Tesseract so we don't need to change the URI
			uri = window.GLOBAL_APOLLO_CLIENT_URI || uri;
		}

		const payload: Array<{
			operationName: string;
			query: string;
			variables: { [key: string]: any };
		}> = [].concat(typeof options.body === 'string' ? JSON.parse(options.body) : []); // concat normalizes into an array

		const operationNames = payload.map(({ operationName, variables }) => {
			// There are various webitem location we want to distinguish them
			if (operationName === 'WebItemLocationQuery' || operationName === 'WebPanelLocationQuery') {
				return `${operationName}:${
					variables?.location || (variables?.locations || []).join(',') || ''
				}`;
			}

			if (
				operationName === 'InlineCommentsQuery' &&
				isCcGraphQL &&
				fg('confluence_inline_comments_query_shadow_test')
			) {
				if ((window as any).__APOLLO_AGG_CLIENT__) {
					// DO NOT USE: Shadow query for AGG performance test
					((window as any).__APOLLO_AGG_CLIENT__ as ApolloClient<object>)
						.query<InlineCommentsQueryAggType, InlineCommentsQueryVariables>({
							query: InlineCommentsQueryAgg,
							variables: {
								pageId: variables?.pageId,
							},
							fetchPolicy: 'network-only',
							context: { single: true },
						})
						.catch((e) => {
							markErrorAsHandled(e); // marking all errors as handled since data from this shadow query is never consumed
						});
				}
			}
			return operationName;
		});

		// pass as headers for rate-limiting compatibility
		const operationNamesList = operationNames.join(',');
		if (!options.headers) {
			options.headers = {};
		}

		appendHeader(options.headers, 'X-APOLLO-OPERATION-NAME', operationNamesList);

		// Headers are already getting forwarded explicitly to cc-graphql via SSR
		// so we only need to append them here for AGG requests
		if (!isCcGraphQL) {
			const { aggHeaders } = getSSRRenderInputs();
			if (aggHeaders) {
				Object.entries(aggHeaders).forEach(([header, val]) => {
					if (val) {
						appendHeader(options.headers!, header, val);
					}
				});
			}
		}

		options.referrerPolicy = options.referrerPolicy || 'same-origin';

		// Tesseract instruments the outgoing http requests automatically, no need to pass tracing headers when running in SSR.
		if (!process.env.REACT_SSR) {
			// add experience tracing headers

			if (fg('confluence_fe_enable_trace_propagation')) {
				const tracingHeaders = getActiveTraceHttpRequestHeaders('' /* url, not used */);
				options.headers = Object.assign(options.headers, tracingHeaders);
			}
		}

		// At this point the query has lost the structure and formatted back to string
		// However it is different in experimental queries. The AST is always accessible
		// Refer to next/packages/graphql/src/links/ExperimentalLink/schemaLink.ts
		const executeFetch = () => {
			return (
				isExperienceAwareFetchAlwaysEnabled() || payload.some((item) => item.query.includes('@SLA'))
					? experienceAwareFetch
					: cfetch
			)(`${uri}?q=${operationNamesList}`, options);
		};

		const markingPrefix = process.env.REACT_SSR
			? `ssr-app/prepare/preloadQuery/fetch:`
			: 'wf/client-graphql/';
		const markingNames = operationNames.map((name) => {
			name = isCcGraphQL ? name : `agg_${name}`;
			return `${markingPrefix}${name?.replace(/[^\-\.\w]/g, '_')}`;
		});
		if (process.env.REACT_SSR) {
			return SSRMeasures.run(markingNames, executeFetch);
		}
		// Ignore batch queries because they are not deterministic. Don't make sense to show on performance waterfall.
		return operationNames.length > 1
			? executeFetch()
			: WaterfallMeasures.run(markingNames, executeFetch);
	};
};
