import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import window from 'window-or-global';
import { DISPLAY_ROOT, SPACE_ROOT } from '@confluence-classic/confluence-urls';

import { ScreenEvent } from '@confluence/analytics';
import { RestrictedSpace } from '@confluence/no-permission';
import { SpaceRestrictionCheck } from '@confluence/restrictions/src/Query/SpaceRestrictionCheck';
import { Space404 } from '@confluence/space-404';
// Decorators
import { withDocumentTitle } from '@confluence/document-title';
import { SpaceBaseThemedComponent } from '@confluence/space-base';

// eslint-disable-next-line no-restricted-syntax, @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import './SpaceBaseComponent.module.css';

const getUrlRoot = (pathname) => {
	if (pathname.includes(`/${SPACE_ROOT}/`)) {
		return SPACE_ROOT;
	} else if (pathname.includes(`/${DISPLAY_ROOT}/`)) {
		return DISPLAY_ROOT;
	} else {
		return undefined;
	}
};

export default withDocumentTitle((props) => props.space && props.space.name)(
	class SpaceBaseComponent extends Component {
		static propTypes = {
			/**
			 * Dashboard page children
			 */
			children: PropTypes.node,
			/**
			 * Space Key
			 */
			spaceKey: PropTypes.string,
			/**
			 * Get space action dispatcher
			 */
			getSpaceByKey: PropTypes.func,
			/**
			 * Current space object
			 */
			space: PropTypes.object,
			/**
			 * Indicates loading space information state.
			 */
			isRequesting: PropTypes.bool,
			/**
			 * Indicates if the space information is fetched or not.
			 */
			hasFetched: PropTypes.bool,
			/**
			 * Tab key from route path
			 */
			tabKey: PropTypes.string,
			/**
			 * A dispatcher to go to previous page.
			 */
			goBackPreviousPage: PropTypes.func,
			/**
			 * Indicate the current route is first route or not.
			 */
			isFirstRoute: PropTypes.bool,
			/**
			 * Current path name - will be passed down to SpaceHeader on changes so it can recalculate active items
			 */
			pathname: PropTypes.string,
			/**
			 * Action to favourite the current space.
			 */
			addSpaceToFavourites: PropTypes.func,
			/**
			 * Action to unfavourite the current space.
			 */
			removeSpaceFromFavourites: PropTypes.func,
			/**
			 * Indicate the current route is view page or not
			 */
			isViewPage: PropTypes.bool,
			/**
			 * Indicates if the user is an anonymous user
			 */
			isAnonymous: PropTypes.bool,
			/**
			 * Content/Page Id
			 */
			contentId: PropTypes.string,
			/**
			 * Content/Page Slug
			 */
			contentSlug: PropTypes.string,
			/**
			 * Did we find the space?
			 */
			isSpaceFound: PropTypes.bool,
			/**
			 * Indicates if the space alias feature flag is enabled.
			 */
			isSpaceAliasFFEnabled: PropTypes.bool,
			/**
			 * Create analytics event.
			 */
			createAnalyticsEvent: PropTypes.func,
			/**
			 * Match route based on given path.
			 */
			matchRoute: PropTypes.func,
			/**
			 * A function to redirect with. Redirections occur when a space key includes a space character (which can only
			 * happen for personal spaces) or if the user navigates to a space with the wrong case for the key.
			 *
			 * The previous route will be replaced by the new one when redirecting.
			 */
			replace: PropTypes.func.isRequired,
			location: PropTypes.object,
			routeParams: PropTypes.object,
		};

		componentDidMount() {
			const { spaceKey, location, routeParams } = this.props;

			this._fixSpaceEncodingInSpaceKey(location, routeParams);
			this._removeEmailQueryParameters(location);
			this._fetchSpaceData(spaceKey);
		}

		UNSAFE_componentWillReceiveProps(nextProps) {
			const { replace } = this.props;
			const {
				spaceKey,
				isRequesting,
				space,
				isSpaceAliasFFEnabled,
				createAnalyticsEvent,
				matchRoute,
			} = nextProps;

			if (isSpaceAliasFFEnabled && space && space.alias && space.alias !== spaceKey) {
				// Redirect to correct space path when key in the URL is not matching the current space alias.
				const pathnameRoot = getUrlRoot(window.location.pathname);
				if (pathnameRoot) {
					const route = matchRoute(window.location.href);
					const routeName = route?.name || null;

					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'redirect',
							actionSubject: 'route',
							source: 'SpaceBase',
							attributes: {
								routeName,

								// Space key related attributes.
								areSpaceKeysEqualLowercase: space?.alias?.toLowerCase() === spaceKey?.toLowerCase(),
							},
						},
					}).fire();

					replace({
						...window.location,
						pathname: window.location.pathname.replace(
							`/${pathnameRoot}/${spaceKey}`,
							`/${pathnameRoot}/${space.alias}`,
						),
					});
				}
				return;
			}

			if (
				!isSpaceAliasFFEnabled &&
				space &&
				space.key &&
				space.key !== spaceKey &&
				space?.key?.toLowerCase() === spaceKey?.toLowerCase()
			) {
				// Redirect to correct space path when letter case in URL is not matching the real space key
				replace({
					...window.location,
					pathname: window.location.pathname.replace(
						`/${SPACE_ROOT}/${spaceKey}`,
						`/${SPACE_ROOT}/${space.key}`,
					),
				});
				return;
			}

			const shouldFetchSpaceInfo =
				(!space || !space.spaceKey || !space.currentUserPermissions) &&
				this.props.spaceKey !== spaceKey &&
				!isRequesting;
			if (shouldFetchSpaceInfo) {
				this._fetchSpaceData(spaceKey);
			}
		}

		componentDidUpdate(prevProps) {
			const { routeParams } = this.props;
			if (prevProps.spaceKey !== this.props.spaceKey) {
				this._fixSpaceEncodingInSpaceKey(window.location, routeParams);
				this._removeEmailQueryParameters(location);
			}
		}

		/**
		 * Confluence back-end encodes space in spaceKey with "+".
		 * This isn't the standard URL encoding so react router can't decode it back to space.
		 * This callback replace it back to normal space so rest of the code can match the space key with key returned in REST API.
		 *
		 * This only happens in personal space where usernames are from outside providers.
		 * User normally can't create a space with a special character in the key.
		 *
		 * @param location
		 * @param routeParams
		 * @private
		 */
		_fixSpaceEncodingInSpaceKey(location, { spaceKey = '' }) {
			const pathname = location.pathname || '';
			const decodedPathname = decodeURIComponent(pathname);

			if (
				// excludes the cases where there are no + symbols to replace at all
				// eg: /spaces/perfectly-fine-space-key
				pathname.indexOf('+') !== -1 &&
				// excludes the cases where there is a + that follows the space key portion of the path
				// eg: /spaces/DS/other+stuff+that+contains+plus
				spaceKey.indexOf('+') !== -1 &&
				// excludes the cases where the decoded path name still contains a + (from %2B)
				// , but the replacement to strip the + from the space key has already happened in previous SPA transition
				// eg: pathname = '/spaces/space%2Bkey', decodedPathname = '/spaces/space+key', spaceKey = 'space+key'
				decodedPathname.indexOf(`spaces/${spaceKey}`) !== -1
			) {
				this.props.replace({
					...location,
					// Ensure we are replacing the "+" in the space key not other funny stuff
					pathname: decodedPathname.replace(
						`spaces/${spaceKey}`,
						`spaces/${spaceKey}`.replace(/\+/g, ' '),
					),
				});
			}
		}

		_removeEmailQueryParameters(location) {
			if (!location.search) return;

			let search = location.search.substring(1);
			const params = search
				.split('&')
				.filter((param) => !param.includes('src=mail') && !param.startsWith('src.mail.'));
			search = `?${params.join('&')}`;

			this.props.replace({
				...location,
				search,
			});
		}

		_fetchSpaceData(spaceKey) {
			// TODO: this could probably be a thunk, making the component fetching logic much simpler.
			const { getSpaceByKey, isSpaceAliasFFEnabled } = this.props;

			getSpaceByKey({
				spaceKey,
				includeAlias: isSpaceAliasFFEnabled,
			});
		}

		render() {
			const { spaceKey, isViewPage, isAnonymous, goBackPreviousPage, isFirstRoute, contentId } =
				this.props;

			return (
				<SpaceRestrictionCheck spaceKey={spaceKey}>
					{({ isSpaceRestricted, isSpaceNotFound }) => {
						if (isSpaceRestricted) {
							return (
								<RestrictedSpace
									goToPreviousPage={goBackPreviousPage}
									isFirstRoute={isFirstRoute}
									contentId={contentId}
								/>
							);
						}

						if (isSpaceNotFound && !isAnonymous && !contentId) {
							return (
								<Fragment>
									<Space404 goBackPreviousPage={goBackPreviousPage} isFirstRoute={isFirstRoute} />
									<ScreenEvent screenEvent={{ name: 'spaceNotFoundScreen' }} id={spaceKey} />
								</Fragment>
							);
						}

						return (
							<div
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={classnames({
									'wrapper-space-view-page': isViewPage,
									'wrapper-space-home-page': !isViewPage,
								})}
							>
								<SpaceBaseThemedComponent>{this.props.children}</SpaceBaseThemedComponent>
							</div>
						);
					}}
				</SpaceRestrictionCheck>
			);
		}
	},
);
