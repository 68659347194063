import gql from 'graphql-tag';

export const SendTrackViewPageEventQuery = gql`
	query SendTrackViewPageEventQuery($contentId: ID) {
		content(id: $contentId) {
			nodes {
				id
				history {
					createdDate
					lastUpdated {
						when
					}
				}
				metadata {
					frontend {
						fabricEditorSupported
						fabricEditorEligibility
					}
				}
				space {
					id
				}
				status
			}
		}
	}
`;
