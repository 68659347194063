import { CLOSE_PREVIEWER, OPEN_PREVIEWER } from '../../actions/media';

const previewerInitialState = {
	currentMediaItem: null,
	isOpen: false,
};

export const mediaPreviewer = (state = previewerInitialState, action) => {
	switch (action.type) {
		case OPEN_PREVIEWER:
			return Object.assign({}, state, {
				currentMediaItem: action.item.id,
				isOpen: true,
			});
		case CLOSE_PREVIEWER:
			return previewerInitialState;
	}

	return state;
};
