import gql from 'graphql-tag';

export const ContentBodyConvertQuery = gql`
	query ContentBodyConvertQuery(
		$from: String!
		$to: String!
		$body: String!
		$contentIdContext: String
	) @experimental {
		experimentalContentBodyConvert(
			body: $body
			from: $from
			to: $to
			contentIdContext: $contentIdContext
		) {
			value
		}
	}
`;
