import type { ForgeDoc } from '@atlassian/forge-ui-types';

export const getChildrenText = (children: ForgeDoc[] | string) => {
	if (Array.isArray(children) && children.length && children[0].type === 'String') {
		return children[0].props?.text;
	} else if (typeof children === 'string') {
		return children;
	}
	return '';
};
