import { LoadablePaint, MustPreloadLoadableNames } from '@confluence/loadable';

export const EditPageLoaderServer = LoadablePaint({
	name: 'EditPageLoaderServer',
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditPageLoaderServer" */ '../src/EditPageLoaderServer'
			)
		).EditPageLoaderServer,
	_mustPreloadWhenRenderingSPA: [
		MustPreloadLoadableNames.ClassicEditorContextProviderLoader,
		MustPreloadLoadableNames.NativeCollabLoader,
		MustPreloadLoadableNames.FullPageEditorLoader,
	],
});
