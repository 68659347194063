import { type ADFReference } from './types';
import { type DataSourceProvider } from './data-source-provider';

export class NodeMutationObserver {
	private dataSourceProvider: DataSourceProvider;

	constructor(dataSourceProvider: DataSourceProvider) {
		this.dataSourceProvider = dataSourceProvider;
	}

	emit(id: string, adfReference: ADFReference) {
		this.dataSourceProvider.createOrUpdate(id, adfReference);
	}
}
