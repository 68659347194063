import type { EmojiId } from '@atlaskit/emoji';

import type { ConfigOptions, EmojiSSRConfig } from '@confluence/fabric-providers';

export type CreateEmojiProviderParametersType = {
	cloudId: string;
	userId?: string;
	disableUpload?: boolean;
	emojiSSRConfig?: EmojiSSRConfig;
};

type ConfigOptionsWithGuest = ConfigOptions & {
	isLicensed?: boolean;
	isLoggedIn?: boolean;
};

export const BASE_URL = '/gateway/api/emoji';

const isServerEnvironment = () => !!process.env.REACT_SSR; // "1" true || "" false || undefined false
const isClientEnvironment = () => !!window.__SSR_RENDERED__;

type SSRConditionalParams = {
	isSSR?: boolean;
	hasCloudId?: boolean;
	isGuestUser?: boolean;
};

export const getOptimisticImageURL = (cloudId: string, emojiId: string) =>
	`${BASE_URL}/${cloudId}/${emojiId}/path`;

export const getEmojiSSRSupport = (params: SSRConditionalParams) => {
	const { isSSR, hasCloudId, isGuestUser } = params;

	const ssrEnabled = !!(isSSR && hasCloudId);

	// SSR Not Supported
	if (!ssrEnabled || isGuestUser) {
		return false;
	}

	// SSR For Logged in Users
	return true;
};

export const generateEmojiIdentifierFromEmojiId = (emojiId: EmojiId) => {
	const { id, shortName } = emojiId;
	const regexp = new RegExp(/:([^:]+):/g); // tests and takes the value between two colons

	if (id) return id;

	if (regexp.test(shortName)) {
		return shortName.replaceAll(':', '');
	}

	return shortName;
};

export const createEmojiProviderParameters = (
	params: ConfigOptionsWithGuest,
): CreateEmojiProviderParametersType => {
	// Feature enabled flags
	const isSSR = isServerEnvironment() || isClientEnvironment();
	const isGuestUser = !params.isLicensed || !params.isLoggedIn;

	// Transform parameters
	const cloudId = params?.cloudId ?? '';
	const userId = params?.userId ?? undefined;
	const disableUpload = params?.disableUpload;
	const emojiSSRConfig = params?.emojiSSRConfig ?? {};

	// Enable emoji ssr
	const useEmojiSSR = getEmojiSSRSupport({
		isSSR,
		hasCloudId: !!cloudId,
		isGuestUser,
	});

	// Default emoji SSR interface
	const emojiSSRConfigOverride: EmojiSSRConfig | undefined = useEmojiSSR
		? {
				optimisticImageApi: {
					getUrl: (emojiId) =>
						getOptimisticImageURL(cloudId, generateEmojiIdentifierFromEmojiId(emojiId)),
				},
				...emojiSSRConfig,
			}
		: undefined;

	return {
		cloudId,
		userId,
		disableUpload,
		...(useEmojiSSR && { emojiSSRConfig: emojiSSRConfigOverride }),
	};
};
