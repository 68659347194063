import gql from 'graphql-tag';

/**
 * Client schema is used for client-side fields and is edited manually.
 */

export const ClientSchema = gql`
	type FabricEditor {
		editorMode: String
	}

	directive @client on FIELD

	directive @connection(key: String!, filter: [String]) on FIELD

	extend type Query {
		network: ClientNetwork
		fabricEditor: FabricEditor
		localActivity: [LocalActivityEvent!]!
	}

	type ClientNetwork {
		status: ClientNetworkStatus
	}

	enum ClientNetworkStatus {
		IP_BLOCKED
		OFFLINE
		ONLINE
		RATE_LIMITED
		SESSION_EXPIRED
	}

	type LocalActivityEvent {
		id: ID!
		action: LocalActivityAction!
		timestamp: String!
		content: Content!
	}

	enum LocalActivityAction {
		viewed
		deleted
	}
`;
