import gql from 'graphql-tag';

import type { ADNode } from '@atlaskit/editor-common/validator';
import type { MacroAttributes } from '@atlaskit/editor-common/provider-factory';

import { getApolloClient } from '@confluence/graphql';

import {
	EMPTY_ADF_CONTENT,
	BODIED_EXTENSION_TYPE,
	MULTI_BODIED_EXTENSION_TYPE,
	MULTI_BODIED_INITIAL_CONTENT,
} from '../extensionConstants';
import { type ExtensionNodeType, isBodiedExtension } from '../extensions-common';

import type { LegacyPlaceholderMacro } from './types';
import { formatAsMacroAttributes } from './transformers';

const isBlueprint = (contentId) => !/^[0-9]+$/.test(contentId);
/**
 * Options for fetching a specific macro attributes object
 */
export type FetchMacroAttributesOptions = {
	/**
	 * The macro definition of the macro that we want to fetch attributes from.
	 */
	macroDefinition: LegacyPlaceholderMacro;
	/**
	 * The content ID of the content that contains the macro that we want to fetch attributes from.
	 */
	contentId?: string;
	/**
	 * A macro to copy content from into the returned macro attributes object.
	 */
	selectedMacro?: ADNode | null;
};

export const getMacroPlaceholderADF =
	// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming -- Read https://go/connie-relay-migration-fyi
	gql`
		query MacroPlaceholderADFQuery($contentId: ID!, $macroDefinition: String!) @experimental {
			experimentalMacroPlaceholderADF(contentId: $contentId, macroDefinition: $macroDefinition) {
				json
			}
		}
	`;

/**
 * Fetches the attributes of a given macro via the content API.
 *
 * If a selected macro is provided, the contents of that macro will be copied
 * into the macro attributes.
 *
 * @param  options Options for fetching a specific macro attributes object.
 * @return A promise that resolves with the macro's attributes
 */
export const fetchMacroAttributes = async (
	options: FetchMacroAttributesOptions,
): Promise<MacroAttributes> => {
	const { macroDefinition, contentId, selectedMacro } = options;

	const results = await getApolloClient().query({
		query: getMacroPlaceholderADF,
		variables: {
			contentId: isBlueprint(contentId) ? '0' : contentId,
			macroDefinition: JSON.stringify(macroDefinition),
		},
	});
	let placeholderAdf;
	try {
		placeholderAdf = JSON.parse(results?.data?.experimentalMacroPlaceholderADF?.json);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Error getting placeholder ADF');
	}

	const adf: ADNode = { ...placeholderAdf };

	if (isBodiedExtension(adf.type as ExtensionNodeType)) {
		if (selectedMacro) {
			// Copy the macro contents into the adf node
			adf.content = selectedMacro.content;
		} else if (!adf.content || adf.content.length === 0) {
			if (adf.type === BODIED_EXTENSION_TYPE) {
				adf.content = EMPTY_ADF_CONTENT;
			}

			if (adf.type === MULTI_BODIED_EXTENSION_TYPE) {
				adf.content = MULTI_BODIED_INITIAL_CONTENT;
			}
		}
	}

	return formatAsMacroAttributes(adf);
};
