import type { Provider as SynchronyProviderType } from '@atlassian/prosemirror-synchrony-plugin/build/collab-provider';

export enum ActionTypes {
	HANDLE_PUBLISH_EVENT = 'HANDLE_PUBLISH_EVENT',
	HANDLE_VERSION_UPDATE = 'HANDLE_VERSION_UPDATE',
	SYNCHRONY_UPDATE_STATUS = 'SYNCHRONY_UPDATE_STATUS',
	CONNECTION_OFFLINE = 'CONNECTION_OFFLINE',
	CONNECTION_ONLINE = 'CONNECTION_ONLINE',
	INIT_COLLAB_PROVIDER_FAILURE = 'INIT_COLLAB_PROVIDER_FAILURE',
	INIT_COLLAB_PROVIDER_SUCCESS = 'INIT_COLLAB_PROVIDER_SUCCESS',
	INIT_COLLAB_PROVIDER_REQUEST = 'INIT_COLLAB_PROVIDER_REQUEST',
	RESET_COLLAB_PROVIDER = 'RESET_COLLAB_PROVIDER',
}

export type SynchronyBehindStateType = 'product' | 'synchrony' | null;

export type SynchronyConnectionStatusType =
	| 'connected'
	| 'disconnected'
	| 'collab-limit'
	| 'permission-changed'
	| 'error';

export type SynchronyUpdateStatusType = {
	type: ActionTypes.SYNCHRONY_UPDATE_STATUS;
	status: SynchronyConnectionStatusType;
};

export type InitCollabProviderRequestType = {
	type: ActionTypes.INIT_COLLAB_PROVIDER_REQUEST;
	contentId: string;
};

export type InitCollabProviderFailureType = {
	type: ActionTypes.INIT_COLLAB_PROVIDER_FAILURE;
	contentId: string;
};

export type InitCollabProviderSuccessType = {
	type: ActionTypes.INIT_COLLAB_PROVIDER_SUCCESS;
	contentId: string;
	provider: SynchronyProviderType;
	preInitSucceeded: boolean;
};

export type ResetCollabProviderType = {
	type: ActionTypes.RESET_COLLAB_PROVIDER;
	contentId: string;
};

export type NoActionBodyActionTypes = {
	type: ActionTypes.CONNECTION_OFFLINE | ActionTypes.CONNECTION_ONLINE;
};

export type AllActionTypes =
	| NoActionBodyActionTypes
	| SynchronyUpdateStatusType
	| InitCollabProviderRequestType
	| InitCollabProviderSuccessType
	| InitCollabProviderFailureType
	| ResetCollabProviderType;
