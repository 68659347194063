import React from 'react';
import { connect } from 'react-redux';

import { closeActiveModal } from '../../actions/modal';

import SingleModal from './SingleModalComponent';

const mapStateToProps = ({ modal: { modalComponent, modalProps } }) => ({
	modalComponent,
	modalProps,
});

export default connect(mapStateToProps, {
	closeActiveModal,
})(SingleModal);
