import {
	CREATE_AND_EDIT_PAGE_EXPERIENCE,
	EDIT_PAGE_CLOSE_EXPERIENCE,
	EDIT_PAGE_EXPERIENCE,
	EDIT_PAGE_LOAD_EXPERIENCE,
	EDIT_PAGE_PUBLISH_EXPERIENCE,
} from './ExperienceName';
import type { Experience } from './Experience';
import type { ExperienceEvent } from './ExperienceEvent';
import { hasName } from './ExperienceEvent';
import { getExperienceTracker } from './getExperienceTracker';

const EDIT_PAGE_SUBEXPERIENCES = [
	EDIT_PAGE_LOAD_EXPERIENCE,
	EDIT_PAGE_CLOSE_EXPERIENCE,
	EDIT_PAGE_PUBLISH_EXPERIENCE,
];

export const startCreateAndEditPage = () => {
	getExperienceTracker().start({
		name: CREATE_AND_EDIT_PAGE_EXPERIENCE,
		collect,
	});
};

export const collect = (events: ExperienceEvent[], experience: Experience) => {
	experience.stopOn(getEditPageEvent(events, 'taskSuccess'));
	experience.stopOn(getEditPageEvent(events, 'taskFail'));
};

export const getEditPageEvent = (events: ExperienceEvent[], action: string) => {
	return events.find(
		(event: ExperienceEvent) => event.action === action && hasName(event, EDIT_PAGE_EXPERIENCE),
	);
};

// Returns true is edit-page experience is running and all relevant edit-page subexperiences are either not started or have already succeeded
export const hasHealthyRunningEditPageExperience = () => {
	const tracker = getExperienceTracker();
	const editPageExperienceState = tracker.getExperienceState(EDIT_PAGE_EXPERIENCE);
	const isEditPageRunning = editPageExperienceState && !editPageExperienceState.hasStopped;

	if (!isEditPageRunning) return false;

	// Validate all subexperiences are healthy. If any experience is unhealthy, `every` will immediately return false, otherwise true
	return EDIT_PAGE_SUBEXPERIENCES.every((subexperience) => {
		const subexperienceState = tracker.getExperienceState(subexperience);

		// Subexperience has not run, so edit-page is still healthy
		if (!subexperienceState) return true;

		// Subexperience has not run for current edit-page's contentId, so edit-page is still healthy
		if (subexperienceState.attributes?.contentId !== editPageExperienceState.attributes?.contentId)
			return true;

		// Subexperience has run and has finished successfully, so edit-page is still healthy
		if (subexperienceState.stopState === 'taskSuccess') return true;

		// Subexperience has run and has not finished successfully, so edit-page is unhealthy
		return false;
	});
};
