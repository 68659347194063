import React from 'react';

import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import { ExtensionKeys } from '../../../../actions/types';
import Icon from '../../../../prebuilt/config-items-empty/generate-content/Icon';
import { AIPanelRenderer } from '../../ai-panel-renderer/AIPanelRenderer';
import { AISummaryExtension } from '../../ai-summary-panel/AISummaryExtension';
import type { AIManifest, AIPanelParameters } from '../../types';
import {
	getFeedbackToolbarItem,
	getQualityToolbarItem,
	getRegenerateToolbarItem,
} from '../../utils';

import { messages } from './messages';

const buildSummarisePagePanelManifest = ({
	api,
	intl,
	providerFactory,
}: AIManifest): ExtensionManifest<AIPanelParameters> => {
	return {
		title: intl.formatMessage(messages.summarizePageTitle),
		// TODO: rename to 'com.atlassian.ai-panels'
		// Requires a change in the confluence backend
		type: 'com.atlassian.ai-blocks',
		key: 'ai-summary-block',
		description: intl.formatMessage(messages.summarisePageDescription),
		icons: {
			'48': async () => () => <Icon alt={intl.formatMessage(messages.summarizePageTitle)} />,
		},
		modules: {
			quickInsert: [
				{
					key: 'quick-insert',
					action: {
						type: 'node',
						key: 'aiSummaryExtension',
						parameters: {
							content: '',
							macroMetadata: {
								title: intl.formatMessage(messages.summarizePageTitle),
							},
						},
					},
				},
			],
			nodes: {
				aiSummaryBodiedExtension: {
					type: 'bodiedExtension',
					render:
						async () =>
						({ node }) => {
							return <AIPanelRenderer node={node} providerFactory={providerFactory} />;
						},
				},
				aiSummaryExtension: {
					type: 'extension',
					render:
						async () =>
						({ node }) => {
							return <AISummaryExtension node={node} api={api} intl={intl} />;
						},
				},
			},
			contextualToolbars: [
				{
					context: {
						type: 'extension',
						nodeType: 'extension',
						extensionKey: 'ai-summary-block:aiSummaryExtension',
						// TODO: rename to 'com.atlassian.ai-panels'
						// Requires a change in the confluence backend
						extensionType: 'com.atlassian.ai-blocks',
					},
					toolbarItems: [
						getQualityToolbarItem(intl),
						getRegenerateToolbarItem(intl, api, ExtensionKeys.AISummaryExtension, messages),
						getFeedbackToolbarItem(intl, api, messages),
					],
				},
				{
					context: {
						type: 'extension',
						nodeType: 'bodiedExtension',
						extensionKey: 'ai-summary-block:aiSummaryBodiedExtension',
						// TODO: rename to 'com.atlassian.ai-panels'
						// Requires a change in the confluence backend
						extensionType: 'com.atlassian.ai-blocks',
					},
					toolbarItems: [
						getQualityToolbarItem(intl),
						getRegenerateToolbarItem(intl, api, ExtensionKeys.AISummaryExtension, messages),
						getFeedbackToolbarItem(intl, api, messages),
					],
				},
			],
		},
	};
};

export default buildSummarisePagePanelManifest;
