import gql from 'graphql-tag';

export const HubAnalyticsTableMetricsQuery = gql`
	query HubAnalyticsTableMetricsQuery($startTime: String!, $endTime: String) {
		clicks: countGroupByPage(
			startTime: $startTime
			endTime: $endTime
			eventName: [companyHubLink_clicked]
			limit: 250
		) {
			nodes {
				page
				count
			}
			pageInfo {
				next
			}
		}
		views: countGroupByPage(
			startTime: $startTime
			endTime: $endTime
			eventName: [companyHubLink_viewed]
			limit: 250
		) {
			nodes {
				page
				count
			}
			pageInfo {
				next
			}
		}
		uniqueVisitors: countUsersGroupByPage(
			startTime: $startTime
			endTime: $endTime
			eventName: [companyHubLink_clicked]
			limit: 250
		) {
			nodes {
				page
				user
			}
			pageInfo {
				next
			}
		}
	}
`;

export const HubAnalyticsTableContentQuery = gql`
	query HubAnalyticsTableContentQuery($ids: [ID!]!) {
		content(ids: $ids) {
			nodes {
				id
				title
			}
		}
	}
`;
