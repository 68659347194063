import { BaseMeasures } from '@confluence/action-measures';

export const getPerformanceEntries = (prefix: string) => {
	const filteredMarkers = (window.performance?.getEntriesByType?.('mark') || []).filter((marker) =>
		marker.name.startsWith(prefix),
	);
	filteredMarkers.sort((a, b) => a.startTime - b.startTime);
	return filteredMarkers;
};

/**
 * Utility to collect duration between markers
 *
 * CFP-63.xxx.start
 * CFP-63.xxx.end
 */
export const collectDurationFromPerfMarkers = (prefix: string) => {
	const measures = new BaseMeasures();
	for (const { name, startTime } of getPerformanceEntries(prefix)) {
		const fieldName = name.replace(/\.start|\.end$/, '').replace(prefix, '');
		// Handle multiple pairs of markers due to re-renders
		if (name.endsWith('.start')) {
			measures.markMeasureStart(fieldName, Math.floor(startTime));
		} else {
			measures.markMeasureEnd(fieldName, Math.floor(startTime), true);
		}
	}
	const durations: Record<string, number> = {};
	for (const [name, { duration }] of Object.entries(measures.getMeasures())) {
		durations[name] = duration;
	}
	return durations;
};
