import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import ListChecklistIcon from '@atlaskit/icon/core/list-checklist';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		{...props}
	>
		<path d="M18 16H11C10.4477 16 10 16.4477 10 17C10 17.5523 10.4477 18 11 18H18C18.5523 18 19 17.5523 19 17C19 16.4477 18.5523 16 18 16Z" />
		<path d="M18 11H11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11Z" />
		<path d="M18 6H11C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8H18C18.5523 8 19 7.55228 19 7C19 6.44772 18.5523 6 18 6Z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.207 10.293C9.39448 10.4805 9.49979 10.7348 9.49979 11C9.49979 11.2652 9.39448 11.5195 9.207 11.707L6.707 14.207C6.51948 14.3945 6.26517 14.4998 6 14.4998C5.73484 14.4998 5.48053 14.3945 5.29301 14.207L4.29301 13.207C4.19749 13.1148 4.12131 13.0044 4.0689 12.8824C4.01649 12.7604 3.98891 12.6292 3.98775 12.4964C3.9866 12.3636 4.0119 12.2319 4.06218 12.109C4.11246 11.9861 4.18672 11.8745 4.28061 11.7806C4.3745 11.6867 4.48615 11.6125 4.60905 11.5622C4.73195 11.5119 4.86363 11.4866 4.99641 11.4877C5.12919 11.4889 5.26041 11.5165 5.38241 11.5689C5.50441 11.6213 5.61476 11.6975 5.707 11.793L6 12.086L7.793 10.293C7.98053 10.1055 8.23484 10.0002 8.5 10.0002C8.76517 10.0002 9.01948 10.1055 9.207 10.293ZM9.207 15.293C9.39448 15.4805 9.49979 15.7348 9.49979 16C9.49979 16.2652 9.39448 16.5195 9.207 16.707L6.707 19.207C6.51948 19.3945 6.26517 19.4998 6 19.4998C5.73484 19.4998 5.48053 19.3945 5.29301 19.207L4.29301 18.207C4.19749 18.1148 4.12131 18.0044 4.0689 17.8824C4.01649 17.7604 3.98891 17.6292 3.98775 17.4964C3.9866 17.3636 4.0119 17.2319 4.06218 17.109C4.11246 16.9862 4.18672 16.8745 4.28061 16.7806C4.3745 16.6867 4.48615 16.6125 4.60905 16.5622C4.73195 16.5119 4.86363 16.4866 4.99641 16.4877C5.12919 16.4889 5.26041 16.5165 5.38241 16.5689C5.50441 16.6213 5.61476 16.6975 5.707 16.793L6 17.086L7.793 15.293C7.98053 15.1055 8.23484 15.0002 8.5 15.0002C8.76517 15.0002 9.01948 15.1055 9.207 15.293ZM9.207 5.293C9.39448 5.48053 9.49979 5.73484 9.49979 6C9.49979 6.26516 9.39448 6.51947 9.207 6.707L6.707 9.207C6.51948 9.39447 6.26517 9.49979 6 9.49979C5.73484 9.49979 5.48053 9.39447 5.29301 9.207L4.29301 8.207C4.11085 8.0184 4.01005 7.7658 4.01233 7.5036C4.01461 7.2414 4.11978 6.99059 4.30519 6.80518C4.49059 6.61977 4.74141 6.5146 5.0036 6.51233C5.2658 6.51005 5.5184 6.61084 5.707 6.793L6 7.086L7.793 5.293C7.98053 5.10553 8.23484 5.00021 8.5 5.00021C8.76517 5.00021 9.01948 5.10553 9.207 5.293Z"
		/>
	</svg>
);

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<ListChecklistIcon
				label=""
				color="currentColor"
				spacing="spacious"
				LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
				LEGACY_secondaryColor={token('color.icon')}
			/>
		</RainbowBorder>
	);
};
