import type { Action } from 'react-sweet-state';
import { createStore, createHook, createActionsHook } from 'react-sweet-state';

type Mode = 'view' | 'edit';

type State = {
	isLive: boolean;
	mode: Mode | undefined;
};
type ModeSelectorReturnType = {
	mode: Mode | undefined;
	isEditMode: boolean;
	isViewMode: boolean;
};

const initialState: State = {
	isLive: false,
	mode: undefined,
};

// Action creators
/**
 * Switches the live status of the page to control whether the page is currently live or not.
 * @param isLive - A boolean indicating the desired live status of the page. Accepts:
 *                 - `true`: The page is live.
 *                 - `false`: The page is not live.
 */
const setIsLive =
	(isLive: boolean): Action<State> =>
	({ setState }) => {
		setState({ isLive });
	};

/**
 * Switches the page mode to control how users see a live page: 'view' mode for a read only view,
 * and 'edit' mode to allow a user to make changes to the page.
 *
 * @param mode - The desired mode for the page. Accepts:
 *               - 'view': Users can only view content.
 *               - 'edit': Users can modify the content.
 */
const setMode =
	(mode: Mode | undefined): Action<State> =>
	({ setState }) => {
		setState({ mode });
	};

const actions = {
	setIsLive,
	setMode,
};

// Store creation
const LivePagesStore = createStore({
	initialState,
	actions,
	name: 'live-pages-store',
});

// Selectors
const selectIsLive = (state: State) => state.isLive;
const selectMode = (state: State): ModeSelectorReturnType => ({
	mode: state.mode,
	isEditMode: state.mode === 'edit',
	isViewMode: state.mode === 'view',
});

// Hooks for accessing specific parts of the state
/**
 * Determines if the current page is a live page.
 * Note: For most situations, use `useIsLivePage` instead as it also checks if the live page feature is enabled.
 * @returns `true` if the page is live, otherwise `false`.
 *  */
export const useIsLivePageRaw = createHook(LivePagesStore, {
	selector: selectIsLive,
});

export const useLivePageStoreActions = createActionsHook(LivePagesStore);

/**
 * Hook to get the current mode of the live page and booleans indicating if it's in view or edit mode.
 * @returns An object containing:
 * - `value`: 'view' if in read-only mode, 'edit' if in edit mode, or `undefined` if not set.
 * - `isEditMode`: `true` if the current mode is 'edit', otherwise `false`.
 * - `isViewMode`: `true` if the current mode is 'view', otherwise `false`.
 */
export const useLivePageMode = createHook(LivePagesStore, {
	selector: selectMode,
});

// Hook for accessing the entire store
export const useLivePagesStore = createHook(LivePagesStore);
