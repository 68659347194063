import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import {
	LazySpaceReportsSiteSettings,
	ADMIN_SPACE_REPORT_SCREEN,
} from '@confluence/site-settings/entry-points/spaceReports';

export const AdminSpaceReportsRoute = () => <LazySpaceReportsSiteSettings />;

AdminSpaceReportsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_SPACE_REPORT_SCREEN },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
