import React from 'react';
import { type ForgeDoc } from '@atlassian/forge-ui-types';
import { Iframe } from '../iframe';

interface WebRuntimeProps {
	setForgeDoc: (forgeDoc: ForgeDoc) => void;
	setError?: (error: Error) => void;
}

type IframeProps = Parameters<typeof Iframe>[0];

export const WebRuntime = ({
	setForgeDoc,
	setError,
	accountId,
	apolloClient,
	contextIds,
	extension,
	components,
	coreData,
	extensionData,
	extensionPayload,
	extensionViewData,
	getContextToken,
	timezone,
	locale,
	bridge,
	...restProps
}: WebRuntimeProps & IframeProps) => {
	const combinedBridge = {
		onError: ({ error }: { error: Error }) => {
			if (setError) {
				setError(error);
			}
		},
		...bridge,
		reconcile: ({ forgeDoc }: { forgeDoc: ForgeDoc }) => {
			Object.freeze(forgeDoc);
			setForgeDoc(forgeDoc);
		},
	};

	return (
		<Iframe
			accountId={accountId}
			apolloClient={apolloClient}
			contextIds={contextIds}
			extension={extension}
			coreData={coreData}
			components={components}
			extensionData={extensionData}
			extensionPayload={extensionPayload}
			extensionViewData={extensionViewData}
			getContextToken={getContextToken}
			timezone={timezone}
			locale={locale}
			bridge={combinedBridge}
			isHidden={true}
			{...restProps}
		/>
	);
};
