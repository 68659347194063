import type { PreloadFailureResult } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import {
	getMultiMacroQueryBlocklistFF,
	getMultiMacrosQueryFF,
} from '@confluence/fabric-extension-queries';

import { preloadMacrosSSRIndividual } from './preloadMacrosSSRIndividual';
import { preloadMacrosSSRMultiple } from './preloadMacrosSSRMultiple';
import { DEFAULT_PRELOAD_COUNT } from './preloader-constants';

export const preloadMacrosSSR = async ({
	contentId,
	featureFlags,
	useMultipleMacrosQuery,
	contentNodes,
	numberOfExtensionsToSSR = DEFAULT_PRELOAD_COUNT,
}: {
	contentId: string;
	featureFlags: { [key: string]: string | number | boolean };
	useMultipleMacrosQuery?: boolean;
	contentNodes?: any;
	numberOfExtensionsToSSR?: number;
}): Promise<PreloadFailureResult | undefined> => {
	if (getMultiMacrosQueryFF(featureFlags)) {
		if (useMultipleMacrosQuery) {
			return preloadMacrosSSRMultiple(contentId, featureFlags);
		} else {
			// if we're using MacrosQuery but this is the call for the old query, we need to
			// check if there are any blocklisted (for the Macros query) macros on the page
			// and attempt to use the old queries to render them instead
			const blocklistedExtensions = getMultiMacroQueryBlocklistFF(featureFlags);

			return preloadMacrosSSRIndividual(
				contentId,
				contentNodes,
				featureFlags,
				numberOfExtensionsToSSR,
				blocklistedExtensions,
			);
		}
	}

	return preloadMacrosSSRIndividual(contentId, contentNodes, featureFlags, numberOfExtensionsToSSR);
};
