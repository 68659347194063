/**
 * The error returned if an error occurs while calling a REST API.
 */
export class ConfluenceRestApiError extends Error {
	/**
	 * The URL of the failed request.
	 */
	public url: string;
	/**
	 * The attempted http request method.
	 */
	public method: string;
	/**
	 * The status code returned in the response
	 */
	public statusCode: number;
	/**
	 * The name of the requested route (without UGC/PII)
	 */
	public route?: string;
	/**
	 * The traceId of the request
	 */
	public traceId?: string | null;

	constructor(
		{
			message,
			method,
			url,
			'status-code': statusCode,
		}: {
			message: string;
			method: string;
			url: string;
			'status-code': number;
		},
		{ route, traceId }: { route?: string; traceId?: string | null } = {},
	) {
		const formattedMessage = message.length ? message : `Failed to ${method} ${route || url}`;
		super(formattedMessage);

		this.url = url;
		this.route = route;
		this.method = method;
		this.statusCode = statusCode;
		this.traceId = traceId;
	}
}
