import type { TrackFeatureFlagOptions } from '@atlaskit/feature-flag-client/types';

import { useSessionData } from './useSessionData';
import { useTrackFeatureFlag } from './useTrackFeatureFlag';

export const useStringFeatureFlag = (
	flagName: string,
	options?: TrackFeatureFlagOptions,
): string | undefined => {
	const sessionData = useSessionData();
	const flagValue = sessionData?.featureFlags[flagName];

	useTrackFeatureFlag(flagName, options);
	// Null, undefined, and emptystring values will return undefined.
	return flagValue ? String(flagValue) : undefined;
};
