import { type Extension } from '@atlassian/forge-ui/ui';

const PDF_URL = '/wiki/pdf/';

const isCurrentLocationPdf = (): boolean => {
	return window?.location.href.includes(PDF_URL);
};

export const isPdfExportEntrypoint = (extension: Extension): boolean => {
	return isCurrentLocationPdf() && extension?.properties?.export;
};

export const isAdfExportEntrypoint = (extension: Extension): boolean => {
	return isCurrentLocationPdf() && extension?.properties?.adfExport;
};
