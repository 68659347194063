import { fromJS, Iterable, Record, List } from 'immutable';

import OperationRecord from './operation-record';
import LookAndFeelRecord from './look-and-feel-record';
import ThemeRecord from './theme-record';

const Settings = Record({
	routeOverrideEnabled: null,
});

export default class Space extends Record({
	id: null,
	key: null,
	name: null,
	alias: null,
	type: null,
	description: null,
	iconPath: null,
	isFavourite: null,
	isWatched: null,
	labels: [],
	homepageId: null,
	currentUserPermissions: null,
	operations: List([]),
	shortcutLinks: null,
	settings: new Settings({}),
	lookAndFeel: new LookAndFeelRecord({}),
	theme: new ThemeRecord({}),
}) {
	constructor(recordData) {
		super(recordData);
		return this.withMutations((spaceData) => {
			if (recordData.currentUserPermissions) {
				spaceData.set('currentUserPermissions', List(recordData.currentUserPermissions));
			}

			if (recordData.settings) {
				spaceData.set('settings', new Settings(recordData.settings));
			}

			if (recordData.operations) {
				spaceData.set(
					'operations',
					fromJS(recordData.operations, (key, val) => {
						const isIndexed = Iterable.isIndexed(val);
						return isIndexed ? val.toList() : new OperationRecord(val);
					}),
				);
			}

			if (recordData.lookAndFeel) {
				spaceData.set('lookAndFeel', new LookAndFeelRecord(recordData.lookAndFeel));
			}

			if (recordData.theme) {
				spaceData.set('theme', new ThemeRecord(recordData.theme));
			}

			return spaceData;
		});
	}
}
