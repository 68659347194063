import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import type { envType, OperationalEventPayload } from '@atlassiansox/analytics-web-client';

import type { PopupProps } from '@atlaskit/popup';

import { MessageDeliveryStatus, MessageEventType, type ProductIds } from '../constants';
import { ProductChoreographerPlugin } from '../plugins';
import type {
	ChoreographedComponentProps,
	ChoreographedMessage,
	ChoreographerCallback,
	ChoreographerFactory,
	ChoreographerOptions,
	ChoreographerUtilities,
	MaybeChoreographedComponentProps,
	ShouldBeChoreographedFunction,
	ToggleableChoreographerUtilities,
	UseChoreographerFunction,
	WithChoreographedRenderFunction,
	WithChoreographedToggleFunction,
} from '../types';
import {
	AutoDismissFlag as MaybeChoreographedAutoDismissFlag,
	Banner as MaybeChoreographedBanner,
	Flag as MaybeChoreographedFlag,
	InlineDialog as MaybeChoreographedInlineDialog,
	Modal as MaybeChoreographedModal,
	Popper as MaybeChoreographedPopper,
	Popup as MaybeChoreographedPopup,
	Spotlight as MaybeChoreographedSpotlight,
	shouldBeChoreographed as shouldBeChoreographedUiFn,
} from '../ui';

/**
 * Factory function for convenience to obtain React bindings for integrating with the choreographer API. Returns
 * a set of hooks that can be re-exported by the caller, as well as a React context Consumer component, and a
 * context Provider wrapper, which exposes some thin function wrappers around a ProductChoreographerPlugin instance,
 * for the purposes of starting and stopping messages through the choreographer API.
 *
 * @param productId The name of the product for which this plugin will be managing communications with the choreographer API.
 * @param env The environment type for the product, to be used for analytics events.
 * @param metaMetricsData An object containing additional metadata to be sent along with every message request.
 * @param choreographerOptions An object containing additional configurations for the choreographer.
 * @returns A new plugin instance and helper functions to use for integrating a 1P product with the choreographer API.
 *
 */
export function choreographerFactory(
	productId: ProductIds,
	env: envType,
	metaMetricsData: OperationalEventPayload['attributes'] = {},
	choreographerOptions: ChoreographerOptions = { enableChoreographer: true },
): ChoreographerFactory {
	// Instantiate our plugin instance, passing the productId through for segmenting any messageId's from any other plugins
	const plugin = new ProductChoreographerPlugin(productId, env, metaMetricsData);

	/**
	 * React hook that provides startMessage and stopMessage functions that call through the plugin API with the provided messageId, for easy assignment to React event handlers.
	 *
	 * @param messageId The messageId to be provided to the choreographer API whenever the startMessage and stopMessage callbacks are invoked.
	 * @param startCallback A callback to be used to set local state that determines whether a message should be shown, e.g. a callback to a useState setter, or that dispatches to a reducer function.
	 * @param stopCallback A callback to be used to set local state that determines whether a message should be hidden, e.g. a callback to a useState setter, or that dispatches to a reducer function.
	 * @returns An object with startMessage and stopMessage callbacks, which contain references to the messageId to be passed to the choreographer API, so that the consumer no longer needs to specify that at call time.
	 */
	const useChoreographer: UseChoreographerFunction = <
		StartCallback extends ChoreographerCallback,
		StopCallback extends ChoreographerCallback,
	>(
		messageId: string,
		{
			[MessageEventType.START]: startCallback,
			[MessageEventType.STOP]: stopCallback,
		}: {
			[MessageEventType.START]?: StartCallback;
			[MessageEventType.STOP]?: StopCallback;
		} = {},
	) => {
		const isMessageStarted = useRef(false);
		const startRef = useRef(startCallback);
		const stopRef = useRef(stopCallback);

		// Callback that uses a ref to track any changes in the received startCallback parameter, so the caller doesn't have to worry about memoization
		const startMessageCallback = useCallback(
			async (...args: Parameters<StartCallback>) => {
				const didMessageStart = (await startRef.current?.(...args)) ?? true;

				isMessageStarted.current = didMessageStart;

				return didMessageStart;
			},
			[isMessageStarted],
		);

		// Stable function reference that invokes the plugin's startMessage function, for use in components.
		const startMessage = useCallback(
			(...args: unknown[]) => {
				plugin.onStart(messageId, () =>
					startMessageCallback(...(args as Parameters<StartCallback>)),
				);
				return plugin.startMessage(messageId);
			},
			[messageId, startMessageCallback],
		);

		// Callback that uses a ref to track any changes in the received stopCallback parameter, so the caller doesn't have to worry about memoization
		const stopMessageCallback = useCallback(
			async (...args: Parameters<StopCallback>) => {
				const didMessageStop = (await stopRef.current?.(...args)) ?? true;

				isMessageStarted.current = !didMessageStop;

				return didMessageStop;
			},
			[isMessageStarted],
		);

		// Stable function reference that invokes the plugin's stopMessage function, for use in components.
		const stopMessage = useCallback(
			(...args: unknown[]) => {
				if (isMessageStarted.current) {
					plugin.onStop(messageId, () =>
						stopMessageCallback(...(args as Parameters<StopCallback>)),
					);
					return plugin.stopMessage(messageId);
				}

				return Promise.resolve(MessageDeliveryStatus.STOPPED);
			},
			[isMessageStarted, messageId, stopMessageCallback],
		);

		// Stable function reference that invokes whichever start or stop handler is appropriate for the message's current display state.
		// Tracking this state with a ref allows us to know the current display state in real-time, so that potentially subsequent calls
		// can be made to toggle its state, without requiring tracking a useState value and requiring rerenders to update the callback
		// with the latest display state, and without generating false blocked messages from cached/outdated toggleMessage references.
		const toggleMessage = useCallback(() => {
			return isMessageStarted.current ? stopMessage() : startMessage();
		}, [isMessageStarted, startMessage, stopMessage]);

		// Syncs any incoming startCallback and stopCallback functions as the latest version to be invoked when a startMessage or stopMessage call is issued through the choreographer API
		useLayoutEffect(() => {
			startRef.current = startCallback;
			stopRef.current = stopCallback;
		}, [startCallback, stopCallback]);

		/**
		 * At component mount, we want to default a start and stop function, which invokes these functions with no additional parameters,
		 * just to supply some sane defaults. If startMessage or stopMessage are called explicitly, they can optionally supply some extra
		 * parameters, at which point, a just-in-time registration of a new callback version will be supplied to the choreographer, which
		 * will proxy those parameter through to the target state-setter functions. But if they are not called explicitly, such as in the
		 * case where a message is started and never stopped, and is timed out from the Choreographer, we want to have a default version
		 * available to execute against.
		 *
		 * At component unmount, stop the current message, if it's displayed, and unsubscribe the stop callback for this messageId within
		 * the plugin.
		 */
		useLayoutEffect(() => {
			plugin.on(messageId, {
				start: async () => {
					return (await startMessage()) === MessageDeliveryStatus.STARTED;
				},
				stop: async () => {
					return (await stopMessage()) === MessageDeliveryStatus.STOPPED;
				},
			});

			return () => {
				// If unsubscribing, fire the stopMessageCallback one last time to ensure the component can clear the local state responsible for displaying its message
				void stopMessage(messageId);
				plugin.off(messageId);
			};
		}, [messageId, startMessage, stopMessage]);

		// Return the wrapped startMessage and stopMessage functions to the consumer, which will route their requests through the choreographer API with the messageId proxied straight through on behalf of the caller
		return useMemo(
			() => ({
				startMessage,
				stopMessage,
				toggleMessage,
			}),
			[startMessage, stopMessage, toggleMessage],
		);
	};

	const shouldBeChoreographed: ShouldBeChoreographedFunction = (
		props?: MaybeChoreographedComponentProps,
	): props is ChoreographedMessage => {
		return shouldBeChoreographedUiFn(props, choreographerOptions);
	};

	const useChoreographedRender = ({
		messageId,
		onMessageDisposition,
	}: ChoreographedComponentProps) => {
		const [shouldRender, setShouldRender] = useState(false);
		const { startMessage, stopMessage } = useChoreographer(messageId, {
			start: () => setShouldRender(true),
			stop: () => setShouldRender(false),
		});

		useLayoutEffect(() => {
			const startMessageAsync = async () => {
				const disposition = await startMessage();
				onMessageDisposition?.(disposition);
			};

			void startMessageAsync();

			return () => void stopMessage();
		}, [messageId, onMessageDisposition, startMessage, stopMessage]);

		return shouldRender;
	};

	const withChoreographedRender: WithChoreographedRenderFunction = <
		T extends { [K in keyof T]: T[K] },
	>(
		Component: React.ComponentType<T>,
	) => {
		function ChoreographedComponent({
			messageId,
			onMessageDisposition,
			...props
		}: ChoreographedComponentProps & T) {
			const shouldRender = useChoreographedRender({ messageId, onMessageDisposition });

			if (shouldRender) {
				// Need to cast "props" as unknown to avoid TS error because it can't infer that
				// Omit<ChoreographedComponentProps & T, "messageId" | "onMessageDisposition"> is the same as T
				return <Component {...(props as unknown as T)} />;
			}

			return null;
		}

		ChoreographedComponent.displayName = `Choreographed${Component.displayName ?? 'Component'}`;

		return ChoreographedComponent;
	};

	const withChoreographedToggle: WithChoreographedToggleFunction = <
		T extends { [K in keyof T]: T[K] },
	>(
		Component: React.ComponentType<T>,
		toggleableProp: keyof T,
	) => {
		function ChoreographedComponent({
			messageId,
			onMessageDisposition,
			...rest
		}: ChoreographedComponentProps & T) {
			// Need to cast "rest" as unknown to avoid TS error because it can't infer that
			// Omit<ChoreographedComponentProps & T, "messageId" | "onMessageDisposition"> is the same as T
			const props: T = rest as unknown as T;

			const toggleablePropValue = props[toggleableProp];
			const [shouldRender, setShouldRender] = useState<boolean>(false);
			const { startMessage, stopMessage } = useChoreographer(messageId, {
				start: () => setShouldRender(true),
				stop: () => setShouldRender(false),
			});

			useLayoutEffect(() => {
				if (toggleablePropValue && !shouldRender) {
					const startMessageAsync = async () => {
						const disposition = await startMessage();
						onMessageDisposition?.(disposition);
					};

					void startMessageAsync();
				} else if (!toggleablePropValue && shouldRender) {
					void stopMessage();
				}

				return () => {
					if (!toggleablePropValue && shouldRender) {
						void stopMessage();
					}
				};
			}, [
				messageId,
				onMessageDisposition,
				shouldRender,
				startMessage,
				stopMessage,
				toggleablePropValue,
			]);

			return <Component {...props} {...{ [toggleableProp]: shouldRender }} />;
		}

		return ChoreographedComponent;
	};

	function wrapWithFactoryFunctions<T>(
		MaybeChoreographedComponent: React.ComponentType<T & ChoreographerUtilities>,
	) {
		function Component(props: T) {
			return (
				<MaybeChoreographedComponent
					{...props}
					shouldBeChoreographed={shouldBeChoreographed}
					withChoreographedRender={withChoreographedRender}
				/>
			);
		}

		Component.displayName =
			MaybeChoreographedComponent.displayName = `ChoreographerFactory(${MaybeChoreographedComponent.displayName})`;

		return Component;
	}

	function wrapWithToggleFactoryFunctions<T>(
		MaybeChoreographedComponent: React.ComponentType<T & ToggleableChoreographerUtilities>,
	) {
		function Component(props: T) {
			return (
				<MaybeChoreographedComponent
					{...props}
					shouldBeChoreographed={shouldBeChoreographed}
					withChoreographedToggle={withChoreographedToggle}
				/>
			);
		}

		Component.displayName =
			MaybeChoreographedComponent.displayName = `ChoreographerFactory(${MaybeChoreographedComponent.displayName})`;

		return Component;
	}

	const AutoDismissFlag = wrapWithFactoryFunctions(MaybeChoreographedAutoDismissFlag);
	const Banner = wrapWithFactoryFunctions(MaybeChoreographedBanner);
	const Flag = wrapWithFactoryFunctions(MaybeChoreographedFlag);
	const Modal = wrapWithFactoryFunctions(MaybeChoreographedModal);
	const Popper = wrapWithFactoryFunctions(MaybeChoreographedPopper);
	const Spotlight = wrapWithFactoryFunctions(MaybeChoreographedSpotlight);

	const InlineDialog = wrapWithToggleFactoryFunctions(MaybeChoreographedInlineDialog);

	// Would ideally like to use wrapWithToggleFactoryFunctions here, but the Popup component's props is a union
	// type, and that for some reason causes ToggleableChoreographerUtilities to be passed through to the
	// consumer-exposed Popup component's prop type, which is not what we want, so we'll just wrap it manually here
	const Popup = (function () {
		function WrappedPopup(props: PopupProps & MaybeChoreographedComponentProps) {
			return (
				<MaybeChoreographedPopup
					{...props}
					shouldBeChoreographed={shouldBeChoreographed}
					withChoreographedToggle={withChoreographedToggle}
				/>
			);
		}
		WrappedPopup.displayName = `ChoreographerFactory(${MaybeChoreographedPopup.displayName})`;
		return WrappedPopup;
	})();

	// Return these items from the factory function for use within React products
	return {
		plugin,
		useChoreographer,
		shouldBeChoreographed,
		useChoreographedRender,
		withChoreographedRender,
		withChoreographedToggle,
		AutoDismissFlag,
		Banner,
		Flag,
		InlineDialog,
		Modal,
		Popper,
		Popup,
		Spotlight,
	};
}
