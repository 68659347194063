import {
	type REGULAR_EXTENSION_TYPE,
	type INLINE_EXTENSION_TYPE,
	BODIED_EXTENSION_TYPE,
	MULTI_BODIED_EXTENSION_TYPE,
} from '../extensionConstants';

export type ExtensionNodeType =
	| typeof REGULAR_EXTENSION_TYPE
	| typeof INLINE_EXTENSION_TYPE
	| typeof BODIED_EXTENSION_TYPE
	| typeof MULTI_BODIED_EXTENSION_TYPE;

export const isBodiedExtension = (extensionType: ExtensionNodeType): boolean => {
	return [BODIED_EXTENSION_TYPE, MULTI_BODIED_EXTENSION_TYPE].indexOf(extensionType) >= 0;
};
