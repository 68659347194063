import type { VFC } from 'react';

// This breakdown by extension (macro) rendering type comes useful for analyzing performance implications
// of using different types of macro rendering:
//  - "ssr-legacy" - when macro uses `macroRenderedOutput`
//  - "ssr-new" - when macro uses `macroOutput` attribute of macro itself
//  - "ssr-exp" - with experiment, when macros loaded separately but during SSR prepare phase
export type ExtensionSsrRenderType = 'ssr-legacy' | 'ssr-new' | 'ssr-exp';

type Props = {
	macroId: string;
	renderType: ExtensionSsrRenderType;
};

export const __LEGACY_MACRO_SSRED__ = '__LEGACY_MACRO_SSRED__';

export const getRenderType = (
	macroOutput: string | null | undefined,
	macroData: string | null | undefined,
): ExtensionSsrRenderType => {
	if (macroOutput) {
		return 'ssr-new';
	} else if (macroData) {
		return 'ssr-exp';
	}

	return 'ssr-legacy';
};

export const getExtensionSSRMark = (macroId: string): string | undefined =>
	window[__LEGACY_MACRO_SSRED__]?.[macroId];

/**
 * Should be placed in a component tree to mark an extension (macro) as SSRed
 * for monitoring purpose.
 * Has no effect when used outside of SSR context.
 */
export const ExtensionSSRMark: VFC<Props> = ({ macroId, renderType }) => {
	if (process.env.REACT_SSR && macroId) {
		window[__LEGACY_MACRO_SSRED__] = window[__LEGACY_MACRO_SSRED__] || {};
		window[__LEGACY_MACRO_SSRED__][macroId] = renderType;
	}
	return null;
};
