import React from 'react';

import { ADMIN_MACRO_USAGE_ANALYTICS_SOURCE, LazyMacroUsagePage } from '@confluence/site-settings';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const AdminMacroUsageRoute = () => <LazyMacroUsagePage />;

AdminMacroUsageRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_MACRO_USAGE_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
