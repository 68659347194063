import React from 'react';

import type { RouteMatch } from '@confluence/route';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { ManagePagesLoader } from '@confluence/manage-pages/entry-points/ManagePagesLoader';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { DocumentContentTitle } from '@confluence/document-title/entry-points/DocumentContentTitle';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';
import { NotFound } from '@confluence/not-found';

import SpaceBase from '../../SpaceBase';

export const SpaceBulkToolsRoute = ({ params: { spaceKey } }: RouteMatch) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	if (isCompanyHubSpaceKey(spaceKey)) {
		return (
			<>
				<DocumentContentTitle notFound />
				<NotFound />
			</>
		);
	}

	return isSpaceAliasFFEnabled ? (
		<SpaceBase>
			<ManagePagesLoader spaceKey={spaceKey} />
		</SpaceBase>
	) : (
		<ManagePagesLoader spaceKey={spaceKey} />
	);
};

SpaceBulkToolsRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: { name: 'spaceBulkTools', id: spaceKey },
		pageState: { routeName: name, spaceKey },
	},
	MainLayout: {
		navView: getIsNav4Enabled() ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
	},
});
