import gql from 'graphql-tag';

export const WebPanelLocationQuery = gql`
	query WebPanelLocationQuery($location: String, $contentId: ID, $spaceKey: String) {
		webPanels(location: $location, contentId: $contentId, key: $spaceKey) {
			moduleKey
			completeKey
			html
			location
			label
			weight
			name
		}
	}
`;
