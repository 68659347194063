import { LoadableBackground } from '@confluence/loadable';

export const LazyCreateDialogQueryRenderer = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CreateDialogQueryRenderer" */ './CreateDialogQueryRenderer'
			)
		).CreateDialogQueryRenderer,
});
