// @ts-nocheck
export type CreateCustomEvent = <Detail>({
	eventName: string,
	canBubble: boolean,
	cancellable: boolean,
	detail: Detail,
}) => CustomEvent;

const createCustomEvent: CreateCustomEvent = ({ eventName, canBubble, cancellable, detail }) => {
	const customEvent = document.createEvent('CustomEvent');
	customEvent.initCustomEvent(eventName, canBubble, cancellable, detail);

	return customEvent;
};

export default createCustomEvent;
