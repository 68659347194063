import { userTransformer } from './transformers/users';

const _toStringPreserveNull = (value) => {
	if (typeof value === 'undefined' || value === null) {
		return value;
	}
	return String(value);
};

const _contentPropertiesResponseTransformer = (properties) => {
	const contentProperties = {};

	if (!properties) {
		return contentProperties;
	}

	if (properties instanceof Array) {
		// GraphQL
		for (const property of properties) {
			contentProperties[property.key] = {
				key: property.key,
				value: JSON.parse(property.value),
			};
		}
	} else {
		// REST
		Object.keys(properties)
			.filter((key) => !key.startsWith('_'))
			.forEach((key) => {
				const value = properties[key].value;
				contentProperties[key] = { key, value };
			});
	}
	return contentProperties;
};

/**
 * @typedef {Object} NormalizedAllUpdatesResponse
 * @property {Object} response - The original REST API response. This will be removed as development progresses.
 * @property {Array<Object>} changeSets - The normalized content entities from the response object.
 */

/**
 * @typedef {Object} SpaceEntity
 * @property {string} key - The unique key for a space.
 * @property {string} title - The display title of a space.
 */

/**
 * Transforms common properties from a REST Space object response.
 * @param spaceResult The REST space object
 * @returns {{id: Number, key: String, name: String, type: String, description: null, iconPath: String, homepageId: String}}
 * @private
 */
const _transformSpaceEntity = (spaceResult) => {
	const homepageId =
		spaceResult._expandable && spaceResult._expandable.homepage
			? spaceResult._expandable.homepage.substring('/rest/api/content/'.length)
			: spaceResult.homepage && spaceResult.homepage.id;

	const space = {
		id: spaceResult.id ? `${spaceResult.id}` : spaceResult.id,
		key: spaceResult.key,
		alias: spaceResult.alias,
		name: spaceResult.name,
		type: spaceResult.type,
	};
	if (homepageId) {
		space.homepageId = `${homepageId}`;
	}
	if (spaceResult.icon && spaceResult.icon.path) {
		space.iconPath = spaceResult.icon.path;
	}
	if (spaceResult.isFavourite) {
		space.isFavourite = spaceResult.isFavourite;
	}
	if (spaceResult.isWatched) {
		space.isWatched = spaceResult.isWatched;
	}
	if (spaceResult.operations) {
		space.operations = spaceResult.operations;
		space.currentUserPermissions = spaceResult.operations
			.filter((op) => op.targetType === 'space')
			.map((op) => op.operation);
	}
	if (spaceResult.lookAndFeel) {
		space.lookAndFeel = spaceResult.lookAndFeel;
	}
	if (spaceResult.settings) {
		space.settings = spaceResult.settings;
	}
	if (spaceResult.theme) {
		space.theme = spaceThemeResponseTransformer(spaceResult.theme);
	}
	return space;
};

export const spaceResponseTransformer = (response) => {
	const results = [];
	(response.results || response.nodes).map((space) => {
		const labels =
			space.metadata &&
			space.metadata.labels &&
			(space.metadata.labels.results || space.metadata.labels.nodes);
		results.push(
			_transformSpaceEntity({
				...space,
				isFavourite:
					labels &&
					labels.some(
						(label) =>
							label.prefix === 'my' && (label.name === 'favourite' || label.name === 'favorite'),
					),
			}),
		);
	});

	return {
		results,
	};
};

const spaceThemeKeyModuleKeyRegex = /^ac:(.*)__(.*)-remote-theme$/;
export const spaceThemeResponseTransformer = (response) => {
	const regexResult = spaceThemeKeyModuleKeyRegex.exec(response.themeKey);

	return regexResult
		? {
				addonKey: regexResult[1],
				moduleKey: regexResult[2],
			}
		: {
				addonKey: null,
				moduleKey: null,
			};
};

/**
 * Return 'null' in place of 'undefined'
 */
const orNull = (value) => (typeof value === 'undefined' ? null : value);

/**
 * Standardize the response for getting the space home content.
 * @param response - The REST response
 * @returns {*} a normalized content response for the space home.
 */
export const contentMetadataResponseTransformer = (response) => {
	if (!response) {
		return {};
	}

	const { metadata = {} } = response;
	const id = response ? _toStringPreserveNull(response.id) : null;
	const properties = (response.properties && response.properties.nodes) || metadata.properties;

	const data = {
		id,
		properties: _contentPropertiesResponseTransformer(properties),
		title: response.title,
		type: response.type,
		operations: response.operations,
		currentUserPermissions: response.operations
			? response.operations.map((op) => op.operation)
			: null,
	};

	if (response.version) {
		data.version = contentVersionResponseTransformer(response).version;
	}

	return data;
};

/**
 * Standardize the response for getting the space home content.
 * @param response - The REST response
 * @returns {*} a normalized content response for the space home.
 */
const contentBodyResponseTransformer = (response) => {
	if (!response) {
		return {};
	}

	const { body } = response;
	const id = response ? _toStringPreserveNull(response.id) : null;

	return {
		body: {
			id,
			html: body && body.view && body.view.value,
			cssTags: body && body.view && body.view.webresource.tags.css,
			jsUris: body && body.view && body.view.webresource.uris.js,
		},
	};
};

/**
 * Standardize the response for getting versions.
 * @param response - The REST response
 * @param id - The content id
 * @returns {*} a normalized content response for the version
 */
const contentVersionResponseTransformer = (response, id) => {
	if (!response) {
		return {};
	}

	id = _toStringPreserveNull(id || response.id);

	return {
		version: {
			id: `${id}`,
			by: response.version && response.version.by && userTransformer(response.version.by),
			when: response.version && response.version.when,
			friendlyWhen: response.version && response.version.friendlyWhen,
			number: response.version && response.version.number,
			syncRev: response.version && response.version.syncRev,
			confRev: response.version && response.version.confRev,
		},
	};
};

/**
 * Standardize the response for getting createdBy field.
 * @param response - The REST response
 * @param id - The content id
 * @returns {*} a normalized content response for the createdBy field
 */
const contentCreatedResponseTransformer = (content) => {
	const created = {
		createdDate: undefined,
		createdBy: undefined,
	};

	if (!content || !content.history) {
		return created;
	}

	const { createdDate, createdBy } = content.history;

	if (createdDate) {
		created.createdDate = createdDate;
	}

	if (createdBy) {
		const user = userTransformer(createdBy);
		if (typeof user.isAnonymous !== 'undefined') {
			created.createdBy = user;
		}
	}

	return created;
};
/**
 * Standardize the response for getting the space home content.
 * @param response - The REST response
 * @returns {*} a normalized content response for the space home.
 */
export const contentWithBodyResponseTransformer = (response) => {
	if (!response) {
		return {};
	}

	// when using cql search through graphql, there is one extra level of nesting
	const content = response.content || response;

	return {
		...contentMetadataResponseTransformer(content),
		...contentBodyResponseTransformer(content),
		ancestors: content.ancestors,
		...contentCreatedResponseTransformer(content),
		...contentVersionResponseTransformer(content),
		space: content.space,
	};
};

export const contentsWithBodyResponseTransformer = (response) => {
	const results = response && (response.results || response.nodes);
	if (!results || !results.length) {
		return null;
	}
	return contentWithBodyResponseTransformer(results[0]);
};

export const editorContentWithBodyResponseTransformer = (response, url) => {
	if (!response) {
		return {};
	}

	// Do not use the id from the response - there could be a rounding error if the id is too large.
	const id = _toStringPreserveNull(url.substring(url.lastIndexOf('/') + 1).split('.')[0]);

	return {
		_originalResponse: response,
		id,
		title: response.title,
		version: {
			id: `${id}`,
			number: parseInt(response.pageVersion, 10),
		},
		spaceKey: response.spaceKey,
	};
};

export const publishContentTransformer = (response, url) => {
	if (!response) {
		return {};
	}

	return {
		id: response.id,
		createdDate: response.history && response.history.createdDate,
		createdBy:
			response.history && response.history.createdBy && userTransformer(response.history.createdBy),
		...contentVersionResponseTransformer(response),
		type: response.type,
		title: response.title,
	};
};

// Schedule publish would not update version
export const schedulePublishContentTransformer = (response, url) => {
	if (!response) {
		return {};
	}

	return {
		id: response.id,
		createdDate: response.history && response.history.createdDate,
		createdBy:
			response.history && response.history.createdBy && userTransformer(response.history.createdBy),
		type: response.type,
		title: response.title,
	};
};

export const editorContentsWithBodyResponseTransformerV2 = (response) => {
	const results = response && (response.results || response.nodes);
	if (!results || !results.length) {
		return null;
	}
	return editorContentWithBodyResponseTransformerV2(results[0]);
};

const EMPTY_ADF_DOCUMENT = {
	value: {
		content: [
			{
				type: 'paragraph',
				content: [],
			},
		],
		type: 'doc',
		version: 1,
	},
};

function _parseAtlasDocFormat(atlas_doc_format) {
	//TODO: CFE-610 Remove this empty ADF document
	if (!atlas_doc_format.value || atlas_doc_format.value.length === 0) {
		return EMPTY_ADF_DOCUMENT;
	}

	try {
		return {
			value: JSON.parse(atlas_doc_format.value),
		};
	} catch (e) {
		return {
			// use the value to show in ADF Parsing Error dialog
			value: atlas_doc_format.value,
			isADFContentInvalid: true,
		};
	}
}

export const editorContentWithBodyResponseTransformerV2 = (response, url) => {
	if (!response) {
		return {};
	}

	let id;
	if (response.id) {
		id = response.id;
	} else if (url) {
		id = _toStringPreserveNull(url.substring(url.lastIndexOf('/') + 1).split('.')[0]);
	}

	const parsedEditorContent = {};

	parsedEditorContent.atlas_doc_format = _parseAtlasDocFormat(response.body.atlas_doc_format);

	const data = {
		id,
		title: response.title,
		editorContent: { ...response.body, ...parsedEditorContent },
		type: response.type,
		isUnpublishedDraft: !response.history.createdBy,
		ancestors: response.ancestors,
		space: response.space,
		links: response.links,
		currentUserPermissions: response.operations && response.operations.map((op) => op.operation),
		...contentCreatedResponseTransformer(response),
		...contentVersionResponseTransformer(response),
		appearancePublished: response.appearancePublished.nodes,
		appearanceDraft: response.appearanceDraft.nodes,
		labels: response.metadata.labels,
		schedulePublishDate: response.schedulePublishDate,
	};

	return data;
};

export const renderContentResponseTransformer = (response, url) => {
	if (!response) {
		return {};
	}

	const id = _toStringPreserveNull(url.substring(url.lastIndexOf('/') + 1).split('.')[0]);

	return { id, renderedPreviewContent: response };
};

/**
 * Standardize the response for favoriting an item.
 *
 * @param {?Object} response - The REST response object.
 *
 * @return {Object} response - The normalised response ready for consumption by Redux.
 * @return {Object.<string, Object>} response.entities.spaces - A object containing a space with the space key as the object key and 'favourite' property has 'true' value.
 *
 */
export const favouriteResponseTransformer = (response, url) => {
	if (url) {
		const spaceKey = url.substr(url.lastIndexOf('/') + 1);
		return {
			entities: {
				spaces: {
					[spaceKey]: {
						isFavourite: true,
					},
				},
			},
		};
	} else {
		return {
			entities: {
				spaces: {},
			},
		};
	}
};

/**
 * Standardize the response for unfavoriting an item.
 *
 * @param {?Object} response - The REST response object.
 *
 * @return {Object} response - The normalised response ready for consumption by Redux.
 * @return {Object.<string, Object>} response.entities.spaces - A object containing a space with the space key as the object key and 'favourite' property has 'false' value.
 *
 */
export const unFavouriteResponseTransformer = (response, url) => {
	if (url) {
		const spaceKey = url.substr(url.lastIndexOf('/') + 1);
		return {
			entities: {
				spaces: {
					[spaceKey]: {
						isFavourite: false,
					},
				},
			},
		};
	} else {
		return {
			entities: {
				spaces: {},
			},
		};
	}
};

export const relationSourcesTransformer = (response) => {
	return response.results.map(
		({
			source: { displayName, accountId, profilePicture: { path: avatarUrl } = {} },
			relationData: { createdDate, friendlyCreatedDate },
		}) => {
			return {
				avatarUrl,
				displayName,
				accountId,
				createdDate,
				friendlyCreatedDate,
			};
		},
	);
};
