const { createLoadable } = require('@confluence/loadable/entry-points/createLoadable');
const { LoadingPriority } = require('@confluence/loadable/entry-points/priorities');

function WrappedReactLoadable(opts) {
	const { overrideReactLoadablePriority } = opts;
	const ReactLoadable = createLoadable(
		overrideReactLoadablePriority !== undefined
			? overrideReactLoadablePriority
			: LoadingPriority.AFTER_PAINT,
	);

	return ReactLoadable({ ...opts, isReactLoadable: true });
}

module.exports = WrappedReactLoadable;
