import { XIDItemType, type XIDState } from './enums';
import type { XIDItem } from './types';

export const XID_TIMEOUT: number = 5000;

export const createTimeoutXid: () => XIDItem[] = () => [
	{ type: XIDItemType.XC, state: 'TIMEOUT' as XIDState.TIMEOUT },
	{ type: XIDItemType.UID, state: 'TIMEOUT' as XIDState.TIMEOUT },
];

export const createUnknownXid: () => XIDItem[] = () => [
	{ type: XIDItemType.XC, state: 'UNKNOWN' as XIDState.UNKNOWN },
	{ type: XIDItemType.UID, state: 'UNKNOWN' as XIDState.UNKNOWN },
];
