import React from 'react';

import type { UIAnalyticsEventHandler } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';

import { START_TOUCH } from './NavdexPointType';

const atlaskitRegistry = {
	[START_TOUCH]: [
		{
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: 'productLogo',
		},
		{
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: 'recentIcon',
		},
		{
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: 'quickSearch',
		},
		{
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: 'notifications',
		},
		{
			action: 'clicked',
			actionSubject: 'smartLink',
		},
		{
			action: 'dismissed',
			actionSubject: 'drawer',
		},
		{
			action: 'selected',
			actionSubject: 'navigationItem',
			actionSubjectId: 'searchResult',
		},
		{
			action: 'selected',
			actionSubject: 'navigationItem',
			actionSubjectId: 'advanced_search_confluence',
		},
	] as Record<string, string>[],
};

type AtlaskitShimProps = {
	children: React.ReactNode;
};

export const AtlaskitShim = ({ children }: AtlaskitShimProps) => {
	const onEvent: UIAnalyticsEventHandler = (event) => {
		for (const type in atlaskitRegistry) {
			if (
				atlaskitRegistry[type as keyof typeof atlaskitRegistry].some((entry) =>
					Object.keys(entry).every((key) => entry[key] === event.payload[key]),
				)
			) {
				event.update((payload) => ({
					...payload,
					attributes: {
						...payload.attributes,
						navdexPointType: type,
					},
				}));
			}
		}
	};

	return (
		<AnalyticsListener onEvent={onEvent} channel="*">
			{children}
		</AnalyticsListener>
	);
};
