import { query } from '@confluence/query-preloader-tools';

import type {
	ContentPrerequisitesQuery as QP,
	ContentPrerequisitesQueryVariables as VP,
} from './__types__/ContentPrerequisitesQuery';
import { ContentPrerequisitesQuery } from './ContentPrerequisitesQuery.graphql';
import type {
	ContentRedirectQuery as QR,
	ContentRedirectQueryVariables as VR,
} from './__types__/ContentRedirectQuery';
import { ContentRedirectQuery } from './ContentRedirectQuery.graphql';

const preloadContentPrerequisitesQuery = (
	contentId: string,
	spaceKey: string | null | undefined,
	includeAlias: boolean,
) =>
	query<QP, VP>({
		query: ContentPrerequisitesQuery,
		errorPolicy: 'all',
		variables: {
			contentId,
			missingSpaceKey: !spaceKey,
			spaceKey,
			includeAlias,
		},
	});

const preloadContentRedirectQuery = (contentId: string, includeAlias: boolean) =>
	query<QR, VR>({
		query: ContentRedirectQuery,
		errorPolicy: 'all',
		variables: {
			contentId,
			includeAlias,
		},
	});

export const preloadContentPrerequisites = (
	contentId: string,
	spaceKey: string | null | undefined,
	includeAlias: boolean,
) =>
	Promise.all([
		preloadContentPrerequisitesQuery(contentId, spaceKey, includeAlias),
		preloadContentRedirectQuery(contentId, includeAlias),
	]);
