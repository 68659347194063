import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LazySpaceDirectory } from '@confluence/space-directory';

export const SpaceDirectoryRoute = () => <LazySpaceDirectory />;

SpaceDirectoryRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'spaceDirectoryScreen' },
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
		disableMinWidth: true,
	},
});
