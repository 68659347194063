export interface FlagsType {
	[key: string]: string | boolean;
}

const getQueryParam = (url?: string): any => {
	// eslint-disable-next-line no-restricted-syntax
	if (!url && !window.location.href) {
		return {};
	}
	// eslint-disable-next-line no-restricted-syntax
	const generatedUrl = new URL(url || window.location.href);
	return new URLSearchParams(generatedUrl.search);
};

function parseParamFlag(flag: string): [string, string | boolean] {
	const [rawFlagName, rawFlagValue] = flag.split(':');

	// Flag names can be specified as either confluence.frontend.foo or confluence.ssr.foo.
	// If there's no prefix, assume it's confluence.frontend
	const flagName = !rawFlagName.startsWith('confluence.')
		? `confluence.frontend.${rawFlagName}`
		: rawFlagName;

	// If flag value is not specified, assume it's true.
	let flagValue: string | boolean = rawFlagValue ?? 'true';

	// For boolean flags, convert them to real boolean values.
	if (flagValue.toLowerCase() === 'true') {
		flagValue = true;
	} else if (flagValue.toLowerCase() === 'false') {
		flagValue = false;
	}

	return [flagName, flagValue];
}

/*
 * Initialize overriding of feature flags based on query parameters from the URL
 * Multiple FF can be specified with ?ff=<name>:<value>,<other name>:<other value>, e.g.
 * ?ff=confluence.frontend.enable-feature:true,confluence.frontend.enable-other-feature:some-string
 * For convenience, prefix and value may be omitted:
 * ?ff=enable-feature is equivalent to ?ff=confluence.frontend.enable-feature:true
 * Note: We only return the overrides here (rather than all of the feature flags)
 * so we can later persist those overrides to the server.
 */
export const processQueryParamFeatureFlags = (featureFlags: FlagsType, url?: string): FlagsType => {
	const queryParams = getQueryParam(url);

	if (!queryParams?.get || !queryParams.get('ff')) {
		return {};
	}

	const paramFlags = queryParams.get('ff').split(',');

	return paramFlags.reduce((acc: FlagsType, flag: string) => {
		const [flagName, flagValue] = parseParamFlag(flag);

		if (featureFlags[flagName] === undefined) {
			// We don't set any flags that don't already exist in featureFlags.
			return acc;
		}

		acc[flagName] = flagValue;
		return acc;
	}, {});
};
