/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConfluenceContentSearchScope {
  ATTACHMENT = "ATTACHMENT",
  BLOGPOST = "BLOGPOST",
  DATABASE = "DATABASE",
  EMBED = "EMBED",
  FOLDER = "FOLDER",
  PAGE = "PAGE",
  SPACE = "SPACE",
  WHITEBOARD = "WHITEBOARD",
}

export enum ConfluenceContentSortingParameter {
  LAST_MODIFIED_DATE = "LAST_MODIFIED_DATE",
  TITLE = "TITLE",
}

export enum ConfluenceSearchContentStatus {
  ARCHIVED = "ARCHIVED",
  CURRENT = "CURRENT",
  DRAFT = "DRAFT",
}

export enum LabelNamespaceEnum {
  GLOBAL = "GLOBAL",
  MY = "MY",
  TEAM = "TEAM",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ConfluenceContentSortField {
  field: ConfluenceContentSortingParameter;
  key?: string | null;
  order: SortOrder;
}

export interface ConfluenceSearchContributorFilter {
  accountIds: string[];
}

export interface ConfluenceSearchFilters {
  spaces?: ConfluenceSearchSpaceFilter | null;
  contributors?: ConfluenceSearchContributorFilter | null;
  labels?: ConfluenceSearchLabelFilter[] | null;
  lastModified?: ConfluenceSearchLastModifiedFilter | null;
  statuses?: ConfluenceSearchStatusesFilter | null;
  titleMatchOnly?: ConfluenceSearchTitleMatchOnlyFilter | null;
}

export interface ConfluenceSearchLabelFilter {
  name: string;
  namespace?: LabelNamespaceEnum | null;
}

export interface ConfluenceSearchLastModifiedFilter {
  from: string;
  to: string;
}

export interface ConfluenceSearchSpaceFilter {
  spaceKeys: string[];
}

export interface ConfluenceSearchStatusesFilter {
  statuses: ConfluenceSearchContentStatus[];
}

export interface ConfluenceSearchTitleMatchOnlyFilter {
  titleMatchOnly?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpacePagesQuery
// ====================================================

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceSpaceSearchResult {}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent_space_currentUser {
  isWatched: boolean;
  isWatchingBlogs: boolean;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent_space_operations {
  operation: string | null;
  targetType: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent_space {
  id: string | null;
  key: string | null;
  currentUser: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent_space_currentUser;
  operations: (SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent_space_operations | null)[] | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent {
  id: string | null;
  currentUserIsWatching: boolean;
  type: string | null;
  space: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent_space | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_lastModified {
  value: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_links {
  editui: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_emoji_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_emoji {
  nodes: (SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_emoji_nodes | null)[] | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_Anonymous_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_Anonymous {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_Anonymous_profilePicture | null;
  displayName: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_KnownUser_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_KnownUser {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_KnownUser_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_User_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_User {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_User_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export type SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy = SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_Anonymous | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_KnownUser | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy_User;

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_Anonymous_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_Anonymous {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_Anonymous_profilePicture | null;
  displayName: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_KnownUser_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_KnownUser {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_KnownUser_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_User_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_User {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_User_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export type SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy = SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_Anonymous | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_KnownUser | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy_User;

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history {
  createdBy: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_createdBy | null;
  ownedBy: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history_ownedBy | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata_currentuser_favourited {
  isFavourite: boolean | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata_currentuser {
  favourited: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata_currentuser_favourited | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata_comments {
  commentsCount: number | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata {
  currentuser: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata_currentuser | null;
  comments: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata_comments | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_likes {
  count: number | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_Anonymous_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_Anonymous {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_Anonymous_profilePicture | null;
  displayName: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_KnownUser_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_KnownUser {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_KnownUser_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_User_profilePicture {
  path: string;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_User {
  profilePicture: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_User_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export type SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by = SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_Anonymous | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_KnownUser | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by_User;

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version {
  by: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version_by | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content {
  id: string | null;
  type: string | null;
  subType: string | null;
  status: string | null;
  blank: boolean;
  links: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_links | null;
  emoji: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_emoji | null;
  history: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_history | null;
  metadata: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_metadata;
  likes: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_likes | null;
  version: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content_version | null;
}

export interface SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult {
  watchDialogContent: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_watchDialogContent | null;
  id: string;
  title: string;
  url: string;
  excerpt: string;
  lastModified: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_lastModified;
  content: SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult_content | null;
}

export type SpacePagesQuery_confluenceContentSearch_nodes = SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceSpaceSearchResult | SpacePagesQuery_confluenceContentSearch_nodes_ConfluenceContentSearchResult;

export interface SpacePagesQuery_confluenceContentSearch_pageInfo {
  hasNextPage: boolean;
  nextPageToken: string | null;
}

export interface SpacePagesQuery_confluenceContentSearch {
  nodes: SpacePagesQuery_confluenceContentSearch_nodes[];
  totalCount: number;
  pageInfo: SpacePagesQuery_confluenceContentSearch_pageInfo;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_links {
  webui: string | null;
  editui: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_emoji_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_emoji {
  nodes: (SpacePagesQuery_objectRecommendations_nodes_content_emoji_nodes | null)[] | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_Anonymous_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_Anonymous {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_Anonymous_profilePicture | null;
  displayName: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_KnownUser_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_KnownUser {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_KnownUser_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_User_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_User {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_User_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export type SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy = SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_Anonymous | SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_KnownUser | SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy_User;

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_Anonymous_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_Anonymous {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_Anonymous_profilePicture | null;
  displayName: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_KnownUser_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_KnownUser {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_KnownUser_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_User_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_User {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_User_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export type SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy = SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_Anonymous | SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_KnownUser | SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy_User;

export interface SpacePagesQuery_objectRecommendations_nodes_content_history {
  createdBy: SpacePagesQuery_objectRecommendations_nodes_content_history_createdBy | null;
  ownedBy: SpacePagesQuery_objectRecommendations_nodes_content_history_ownedBy | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_metadata_currentuser_favourited {
  isFavourite: boolean | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_metadata_currentuser {
  favourited: SpacePagesQuery_objectRecommendations_nodes_content_metadata_currentuser_favourited | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_metadata_comments {
  commentsCount: number | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_metadata {
  lastModifiedDate: string | null;
  currentuser: SpacePagesQuery_objectRecommendations_nodes_content_metadata_currentuser | null;
  comments: SpacePagesQuery_objectRecommendations_nodes_content_metadata_comments | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_likes {
  count: number | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_version_by_Anonymous_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_version_by_Anonymous {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_version_by_Anonymous_profilePicture | null;
  displayName: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_version_by_KnownUser_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_version_by_KnownUser {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_version_by_KnownUser_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_version_by_User_profilePicture {
  path: string;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content_version_by_User {
  profilePicture: SpacePagesQuery_objectRecommendations_nodes_content_version_by_User_profilePicture | null;
  displayName: string | null;
  accountId: string | null;
}

export type SpacePagesQuery_objectRecommendations_nodes_content_version_by = SpacePagesQuery_objectRecommendations_nodes_content_version_by_Anonymous | SpacePagesQuery_objectRecommendations_nodes_content_version_by_KnownUser | SpacePagesQuery_objectRecommendations_nodes_content_version_by_User;

export interface SpacePagesQuery_objectRecommendations_nodes_content_version {
  by: SpacePagesQuery_objectRecommendations_nodes_content_version_by | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes_content {
  id: string | null;
  title: string | null;
  type: string | null;
  subType: string | null;
  status: string | null;
  blank: boolean;
  excerpt: string;
  links: SpacePagesQuery_objectRecommendations_nodes_content_links | null;
  emoji: SpacePagesQuery_objectRecommendations_nodes_content_emoji | null;
  history: SpacePagesQuery_objectRecommendations_nodes_content_history | null;
  metadata: SpacePagesQuery_objectRecommendations_nodes_content_metadata;
  likes: SpacePagesQuery_objectRecommendations_nodes_content_likes | null;
  version: SpacePagesQuery_objectRecommendations_nodes_content_version | null;
}

export interface SpacePagesQuery_objectRecommendations_nodes {
  id: string;
  content: SpacePagesQuery_objectRecommendations_nodes_content | null;
}

export interface SpacePagesQuery_objectRecommendations {
  nodes: SpacePagesQuery_objectRecommendations_nodes[];
}

export interface SpacePagesQuery {
  confluenceContentSearch: SpacePagesQuery_confluenceContentSearch;
  objectRecommendations: SpacePagesQuery_objectRecommendations;
}

export interface SpacePagesQueryVariables {
  searchText: string;
  filters: ConfluenceSearchFilters;
  first: number;
  maxNumberOfResults: number;
  token?: string | null;
  sortBy?: ConfluenceContentSortField[] | null;
  isLicensed: boolean;
  includeRecommended?: boolean | null;
  spaceId?: string | null;
  scopes: ConfluenceContentSearchScope[];
}
