// used for page-load and page-segment-load events
export const FF_ALLOWLIST_FOR_PERF_METRICS = [
	'confluence.frontend.delay.page.comments.render',
	'confluence.ssr.page-statuses',
	'confluence.frontend.fabric.editor.macro.adf.frontend.render',
	'confluence.frontend.fabric.editor.preload.timeout',
	'confluence.frontend.custom-sites.page-header-and-title',
	'confluence.frontend.custom-sites.cards-extension',
	'confluence.ssr.preload.query.timeout',
	'confluence.ssr.render.above.threshold',
	'confluence.ssr.delay.hydration.on.hover',
	'confluence.ssr.use-react18',
	'platform.analytics-next-use-modern-context_fqgbx',
	'confluence_ssr_enable_tesseract_stream_endpoint',
	'confluence_frontend_livepages_fastloading',
	'confluence.frontend.use-multiple-macros-query',
	'confluence_operation_query_migration',
	'confluence_ssr_remove-smartlink-regex-filtering',
	'confluence_ssr_batch_ors_smartlinks',
	'confluence_user_settings_query_migration',
	'confluence_frontend_templates_query_migration',
];

// In order to reduce BM event payload size, include a FF in the BM payload only if it's in the allow list.
// The following FFs will be included in all types of BM event.
export const ALLOWLIST_FFS_CUSTOM_EVENTS = [
	'confluence.frontend.pollinator.synthetic.check.tenants',
	'confluence.ssr.delay.hydration.on.hover',
];
