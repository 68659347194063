import { useMemo, useContext } from 'react';
import { useIntl } from 'react-intl-next';
import type { IntlShape } from 'react-intl-next';

import {
	getSubStatProps,
	getSubStatPropsFromPercent,
} from '../../base-components/SummaryBase/SubStat-utils';
import { MetricSettingsContext } from '../MetricSettingsContext';
import type { MetricSettingsContextValueType } from '../MetricSettingsContext';
import { getLineDataForChartEventMetric } from '../../../admin-center-utils';

import { sumTimeseriesDataPointsByDate } from './useMetricData-utils';
import type { TimeseriesData, UseMetricDataReturnType } from './useMetricData-utils';

export type EventMetricLineDataToProcess = {
	// Description to add to processed LineDefinition
	description: string;
	// Query data for event metric timeseries
	timeseriesData: TimeseriesData;
};

type GetEventMetricStatsArgs = {
	lineDataToProcess: EventMetricLineDataToProcess[];
	metricSettingsValues: MetricSettingsContextValueType;
	formatMessage: IntlShape['formatMessage'];
	isPercentage: boolean;
};

const getEventMetricStats = ({
	lineDataToProcess,
	metricSettingsValues: {
		firstFullGranularityStartDate,
		lastFullGranularityStartDate,
		granularity,
		lastXGranularities,
		isActivationDateOver1DayAgo,
	},
	formatMessage,
	isPercentage,
}: GetEventMetricStatsArgs): Omit<UseMetricDataReturnType, 'lines'> => {
	const unprocessedTimeseriesData = lineDataToProcess.map(({ timeseriesData }) => timeseriesData);
	// Get combined data point for the first full granularity (i.e. first chart data point)
	const firstFullGranularityStartDateValue = sumTimeseriesDataPointsByDate(
		unprocessedTimeseriesData,
		firstFullGranularityStartDate,
	);
	// Get combined data point for the last full granularity chart data point.
	// Note: Main and sub stats are relative to last full granularity, not the potential in-progress data point.
	const currentValue = sumTimeseriesDataPointsByDate(
		unprocessedTimeseriesData,
		lastFullGranularityStartDate,
	);
	// If isPercentage is true, format the subStat as a percentage; otherwise, treat the above values as decimal counts.
	const subStat = isPercentage
		? getSubStatPropsFromPercent({
				granularity,
				lastXGranularities,
				historicalPercent: firstFullGranularityStartDateValue,
				currentPercent: currentValue,
				isActivationDateOver1DayAgo,
				formatMessage,
			})
		: getSubStatProps({
				granularity,
				lastXGranularities,
				historicalCount: firstFullGranularityStartDateValue,
				currentCount: currentValue,
				isActivationDateOver1DayAgo,
				formatMessage,
			});

	return {
		mainStatCount: currentValue,
		subStat,
	};
};

// Custom hook that takes query data for an event metric, and returns processed lines, main stat, and sub stat to plug into
// a base component (ChartSummaryBaseCard/ChartBaseCard/SummaryBaseCard).
export const useEventMetricData = (
	lineDataToProcess: EventMetricLineDataToProcess[],
	isPercentage: boolean = false,
): UseMetricDataReturnType => {
	const { formatMessage, formatNumber } = useIntl();
	const metricSettingsValues = useContext(MetricSettingsContext);

	const lines = useMemo(() => {
		return lineDataToProcess.map(({ description, timeseriesData }) => {
			const lineData = getLineDataForChartEventMetric({
				data: timeseriesData,
				metricSettingsValues,
				formatNumber,
				formatNumberStyle: isPercentage ? 'percent' : 'decimal',
				formatMessage,
			});
			return {
				description,
				data: lineData,
			};
		});
	}, [lineDataToProcess, isPercentage, metricSettingsValues, formatNumber, formatMessage]);

	const { mainStatCount, subStat } = useMemo(
		() =>
			getEventMetricStats({
				lineDataToProcess,
				metricSettingsValues,
				formatMessage,
				isPercentage,
			}),
		[lineDataToProcess, metricSettingsValues, formatMessage, isPercentage],
	);

	return {
		lines,
		mainStatCount,
		subStat,
	};
};
