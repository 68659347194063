import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.empty.findActionItems.title',
		defaultMessage: 'Find action items',
		description:
			'Title for "Find Action Items" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.empty.findActionItems.description',
		defaultMessage: 'Finds action items in the content you provide',
		description:
			'Describes the "Find Action Items" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
});
