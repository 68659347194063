import { useEffect, useContext } from 'react';

import { ExperienceTrackerContext } from './ExperienceTrackerContext';

export type ExperienceAbortProps = {
	name: string;
	reason: string;
};

export function ExperienceAbort({ name, reason }: ExperienceAbortProps) {
	const experienceTracker = useContext(ExperienceTrackerContext);
	useEffect(() => experienceTracker.abort({ name, reason }), [name, reason, experienceTracker]);

	return null;
}
