import { query } from '@confluence/query-preloader-tools';

import { CoverPictureQuery } from '../CoverPictureQuery.graphql';
import type {
	CoverPictureQuery as CoverPictureQueryType,
	CoverPictureQueryVariables as CoverPictureQueryVariablesType,
} from '../__types__/CoverPictureQuery';

export function preloadEditorPageCoverPicture(
	contentId: CoverPictureQueryVariablesType['contentId'],
	draftShareId: CoverPictureQueryVariablesType['draftShareId'],
	useCache: boolean,
) {
	return query<CoverPictureQueryType, CoverPictureQueryVariablesType>({
		query: CoverPictureQuery,
		variables: {
			contentId,
			status: ['current', 'draft'],
			draftShareId,
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	});
}
