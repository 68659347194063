import type { GQLExtension } from '../web-client/graphql/types';
import { isCustomUI } from './isCustomUI';
import { fg } from '@atlaskit/platform-feature-flags';

export const getExtensionType = (extension: Pick<GQLExtension, 'properties'>) => {
	const { properties } = extension;
	const enableInlineMacroForCustomUI = fg('platform-enable-inline-macro-for-custom-ui');

	if (properties?.bodyType === 'rich-text') {
		return 'bodiedExtension';
	} else if (
		properties?.layout === 'inline' &&
		(!isCustomUI(extension) || enableInlineMacroForCustomUI)
	) {
		return 'inlineExtension';
	}
	return 'extension';
};
