import type { SyntheticEvent } from 'react';
import { useContext, useEffect, useMemo } from 'react';

import {
	COPY_PAGE_LEGACY,
	CREATE_PAGE_ACTION_LEGACY,
	EXTERNAL_SHARE,
} from '@confluence/named-routes';

import type { RoutesContextType } from './RoutesContext';
import { RoutesContext } from './RoutesContext';
declare global {
	interface WindowEventMap {
		preloadOnHover: CustomEvent;
	}
}
export function GlobalAnchorHandler() {
	useAnchorEventListener('click', spaTransitionOnAnchorClick, false);

	useAnchorEventListener('mousedown', preloadRouteOnMouseDown, true);

	useAnchorEventListener('preloadOnHover', preloadRouteOnMouseDown, true);

	return null;
}

function createAnchorEventListener(
	eventListener: (
		e: MouseEvent | CustomEvent,
		routesContext: RoutesContextType,
		href: string,
	) => void,
	routesContext: RoutesContextType,
) {
	return (e: MouseEvent | CustomEvent) => {
		// Some code already handled this
		if (e.defaultPrevented) return;

		const href = getSpaTransitionHref(e, routesContext);
		if (href) {
			eventListener(e, routesContext, href);
		}
	};
}

function isContentEditable(element: Element | null): boolean {
	return element instanceof HTMLElement && element.isContentEditable;
}

export function getSpaTransitionHref(
	e: MouseEvent | SyntheticEvent<HTMLAnchorElement> | CustomEvent,
	routesContext: Pick<RoutesContextType, 'matchRoute'>,
	skipContentEditableCheck?: boolean,
): string | undefined {
	if (e.type === 'preloadOnHover') {
		if ((e as CustomEvent).detail?.preloadUrl) {
			return (e as CustomEvent).detail.preloadUrl;
		}

		return;
	}
	if (!('button' in e) || e['button'] !== 0 /* main button */) return;
	// Don't handle if modifier keys used
	if (
		(e as MouseEvent)['ctrlKey'] ||
		(e as MouseEvent)['shiftKey'] ||
		(e as MouseEvent)['altKey'] ||
		(e as MouseEvent)['metaKey']
	)
		return;

	const { target } = e;
	if (!target || !(target instanceof Element)) return;

	// This might be the child of an anchor (such as the span inside of a smart card),
	// so check for a parent anchor.
	const anchor = target.closest('a');
	if (!anchor) return;

	//ignore hash links, they're handled by scroll handlers that also listen to document click
	if (!(anchor instanceof HTMLAnchorElement) || ('hash' in anchor && anchor['hash'])) {
		return;
	}

	// Links in contenteditable elements are not clickable
	// https://product-fabric.atlassian.net/browse/CEMS-951
	// Checking closest 'contentEditable' for anchor,
	// In case there are "contenteditable=false" in between target and anchor.
	// Also check the property, since contenteditable="false" is possible
	const closestContentEditableFromAnchor = anchor.closest('[contenteditable]');
	const closestContentEditableFromTarget = target.closest('[contenteditable]');
	if (
		!skipContentEditableCheck &&
		(isContentEditable(closestContentEditableFromAnchor) ||
			isContentEditable(closestContentEditableFromTarget))
	) {
		return;
	}

	// Only handle anchors with relevant targets
	if (anchor.target && anchor.target !== '_self') return;

	const { href } = anchor;
	if (!href) return;

	// CONFCLOUD-69367
	// skip copy page legacy to allow user to edit page title
	// CEMS-1140
	// skip create page legacy to allow user to create a page using newer working fabric creation
	if (!routesContext || typeof routesContext.matchRoute !== 'function') return;
	const match = routesContext.matchRoute(href);
	if (
		match &&
		(match.name === COPY_PAGE_LEGACY.name || match.name === CREATE_PAGE_ACTION_LEGACY.name)
	) {
		return;
	}

	return href;
}

function preloadRouteOnMouseDown(_: Event, { preloadRoute }: RoutesContextType, href: string) {
	preloadRoute(href);
}

function spaTransitionOnAnchorClick(
	e: Event | CustomEvent,
	{ push }: RoutesContextType,
	href: string,
) {
	e.preventDefault();
	push(href, Boolean(EXTERNAL_SHARE.match(window.location.pathname)));
}

function useAnchorEventListener(
	type: 'click' | 'mousedown' | 'preloadOnHover',
	anchorEventListener: Parameters<typeof createAnchorEventListener>[0],
	useCapture: boolean,
) {
	const routesContext = useContext(RoutesContext);
	const eventListener = useMemo(
		() => createAnchorEventListener(anchorEventListener, routesContext),
		[anchorEventListener, routesContext],
	);

	useEffect(() => {
		if (eventListener) {
			window.addEventListener(type, eventListener, useCapture);
		}

		return () => {
			if (eventListener) {
				window.removeEventListener(type, eventListener, useCapture);
			}
		};
	}, [eventListener, type, useCapture]);
}
