import { Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';

type Attributes = Record<string, string | number | boolean | object>;

/**
 * Sends a track event to Pendo, if it exists and is ready. Silently fails
 * otherwise (since Pendo FF may be off)
 */
export function sendPendoTrackEvent(event: string, properties?: Attributes) {
	try {
		if (isPendoReady()) {
			window.pendo?.track(event, properties);
		}
	} catch (error) {
		getMonitoringClient().submitError(error, {
			attribution: Attribution.PERMISSIONS_EXPERIENCE,
		});
	}
}

const isPendoReady = (): boolean =>
	(window?.pendo?.isReady ?? false) &&
	typeof window.pendo?.isReady === 'function' &&
	window.pendo.isReady();
