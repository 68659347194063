import type { FieldProps } from '@atlassian/forge-ui-types';
import type { DateTimePickerSelectProps } from '@atlaskit/datetime-picker';

/**
 * Excluded props:
 * - innerProps - not supporting HTML attributes at this time
 * - parseInputValue - not supporting as it does not work, function can't be passed outside of runtime
 * - formatDisplayLabel - not supporting as it does not work, function can't be passed outside of runtime
 */

// Type copied from packages/design-system/datetime-picker/src/components/time-picker.tsx
type Appearance = 'default' | 'subtle' | 'none';
type Spacing = 'compact' | 'default';

export type TimePickerProps = {
	appearance?: Appearance;
	autoFocus?: boolean;
	defaultIsOpen?: boolean;
	defaultValue?: string;
	isOpen?: boolean;
	label?: string;
	onChange?: (value: string) => void;
	spacing?: Spacing;
	times?: string[];
	timeIsEditable?: boolean;
	value?: string;
	isInvalid?: boolean;
	hideIcon?: boolean;
	timeFormat?: string;
	placeholder?: string;
	locale?: string;
	testId?: string;
  selectProps?: DateTimePickerSelectProps;
} & Partial<Omit<FieldProps, 'onChange' | 'isRequired'>>;

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::62491940f8e54a7380d7190185c202f2>>
 * @codegenId #time-picker
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen time-picker
 * @codegenParams {  "defineOwnType": true, "hasFieldProps": { "value": false, "isRequired": false, "onChange": false}, "props": { "appearance": true, "autoFocus": true, "defaultIsOpen": true, "defaultValue": true, "formatDisplayLabel": false, "innerProps": false, "isOpen": true, "label": true, "parseInputValue": false, "spacing": true, "times": true, "timeIsEditable": true, "hideIcon": true, "timeFormat": true, "placeholder": true, "locale": true, "testId": true, "selectProps": true, "onChange": true, "value": {"replaceLine": true, "value": "{...(value !== undefined ? { value }: undefined) }", "comment": "Explicitly setting value to undefined is causing issues"} } }
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/components/time-picker.tsx <<SignedSource::9aed4f8cfe5282299130fc3339889374>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/types.tsx <<SignedSource::42eaf7f0e8c276db6ef0ce7554a9b74f>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsContext.tsx <<SignedSource::fe8cfa3b5df7c0625832b02a14245144>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/index.tsx <<SignedSource::78a77bf39be7819249c18801c3d7f4b9>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/fixed-layer-menu.tsx <<SignedSource::e0ccda9cb318afb5e9fcd5a508d1ed1b>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/parse-time.tsx <<SignedSource::5481d2b70e747b61840c1a02387bb635>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/parse-tokens.tsx <<SignedSource::ddc88693298751deb4801a641ea3b0a3>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/single-value.tsx <<SignedSource::8cff23b1988189a6156ccd812d6b60ad>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { TimePicker as PlatformTimePicker } from '@atlaskit/datetime-picker';
import { adaptEventHandler } from "../../../utils";

// Define the type for TimePicker as TimePickerProps at the top of this file

export const TimePicker = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    autoFocus,
    defaultIsOpen,
    defaultValue,
    isOpen,
    label,
    spacing,
    times,
    timeIsEditable,
    hideIcon,
    timeFormat,
    placeholder,
    locale,
    testId,
    selectProps,
    onChange,
    value,
    // Field props
    id,
    isDisabled,
    isInvalid,
    onBlur,
    onFocus,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as TimePickerProps;
  // warning: defaultIsOpen is deprecated;
  return (
    <PlatformTimePicker
      appearance={appearance}
      autoFocus={autoFocus}
      defaultIsOpen={defaultIsOpen}
      defaultValue={defaultValue}
      isOpen={isOpen}
      label={label}
      spacing={spacing}
      times={times}
      timeIsEditable={timeIsEditable}
      hideIcon={hideIcon}
      timeFormat={timeFormat}
      placeholder={placeholder}
      locale={locale}
      testId={testId}
      selectProps={selectProps}
      onChange={onChange}
      // Explicitly setting value to undefined is causing issues
      {...(value !== undefined ? { value }: undefined) }
      // Field props
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
