import gql from 'graphql-tag';

export const RendererContentStateQuery = gql`
	query RendererContentState($contentId: ID!, $isDraft: Boolean!, $format: GraphQLDateFormat) {
		singleContent(id: $contentId) {
			id
			contentState(isDraft: $isDraft) {
				id
				name
				color
				restrictionLevel
			}
			contentStateLastUpdated(format: $format) {
				value
			}
		}
	}
`;
