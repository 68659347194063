import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

export const ErrorSubjects = {
	themeAPI: 'themeAPI',
	remoteThemeStorage: 'remoteThemeStorage',
	useThemingState: 'useThemingState',
};

export const ErrorSubjectIds = {
	getSavedTheme: 'getSavedTheme',
	apolloMutationErrors: 'apolloMutationErrors',
	saveThemeRemote: 'saveThemeRemote',
	setColorMode: 'setColorMode',
};

export const reportThemingError = async (subject, subjectId, attributes) => {
	const client = await getAnalyticsWebClient();
	client.sendUIEvent({
		source: 'theming',
		action: 'error',
		actionSubject: subject,
		actionSubjectId: subjectId,
		attributes,
	});
};
