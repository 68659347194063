import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import { EXTENSION_TYPE, EXTENSION_KEY } from '../../extensionConstants';
import type { RendererExtensionHandlers } from '../../providers/macros';

export function getRedactedMacroPlaceholderExtensionManifest(
	extensionHandlers: RendererExtensionHandlers,
): ExtensionManifest {
	return {
		title: 'Redacted Macro Placeholder',
		key: EXTENSION_KEY.REDACTED_MACRO_PLACEHOLDER,
		type: EXTENSION_TYPE.MACRO_CORE,
		icons: {
			'48': async () =>
				import(
					/* webpackChunkName: "loadable-atlaskiticonglyphlock" */ '@atlaskit/icon/glyph/lock'
				),
		},
		modules: {
			nodes: {
				default: {
					type: 'extension',
					render:
						async () =>
						({ node }) => {
							// We are already loading `RedactedPlaceholderExtensionHandler` for `extensionHandlers`
							// and it also uses `@confluence/loadable` so loading it here asynchronously isn't useful
							const handler = extensionHandlers[EXTENSION_TYPE.MACRO_CORE];
							if (typeof handler === 'function') {
								return handler(node, null as any);
							}
							return null;
						},
				},
			},
		},
	};
}
