import window from 'window-or-global';
import { CONTEXT_PATH } from '@confluence-classic/confluence-urls';

export default function configuration() {
	const API_HOST = window.location ? `${window.location.protocol}//${window.location.host}` : ``;

	return {
		endpoint: API_HOST,
		contextPath: CONTEXT_PATH,
		extraHeaders: {
			// required for auth/referrer in rest calls
			'X-Requested-With': 'XMLHttpRequest',
		},
	};
}
