import memoizeOne from 'memoize-one';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { COLLAB_EDIT_PROVIDER_MEASURES } from '@confluence/fabric-providers';
import { CollabEditProviderMeasures } from '@confluence/action-measures';

import type {
	getCollabToken,
	getCollabTokenVariables,
} from './__types__/GetCollabPermissionTokenQuery';
import { GetCollabPermissionTokenQuery } from './CollabPermissionTokenQuery.graphql';
import { nativeCollabPermissionsTokenState } from './CollabPermissionsTokenState';
const isContentNotFoundError = (error): boolean => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some((graphQLError) =>
			graphQLError.message.includes('Invalid content id'),
		);
	}
	return false;
};

export const fetchCollabPermissionToken = memoizeOne(
	(contentId: string, draftShareId: string | undefined) => async (): Promise<string | null> => {
		const permissionsTokenState =
			nativeCollabPermissionsTokenState.getPermissionsTokenState(contentId);
		if (permissionsTokenState) {
			const { getToken, isUsedForDraftFetch, isUsedForWSInitialization } = permissionsTokenState;
			// fetchCollabPermissionToken should only return the token if it is valid and has successfully been passed to the getDraft call
			if (isUsedForDraftFetch && !isUsedForWSInitialization) {
				const token = await getToken;
				if (token) {
					nativeCollabPermissionsTokenState.setIsUsedForWSInitialization(contentId, true);
					return token;
				}
			}
		}
		const apolloClient = getApolloClient();
		try {
			CollabEditProviderMeasures.markMeasureStart(
				COLLAB_EDIT_PROVIDER_MEASURES.COLLAB_PERMS_TOKEN_FETCH,
			);
			const result = await apolloClient.query<getCollabToken, getCollabTokenVariables>({
				query: GetCollabPermissionTokenQuery,
				variables: {
					contentId,
					...(draftShareId && { draftShareId }),
				},
				fetchPolicy: 'network-only',
			});
			CollabEditProviderMeasures.markMeasureEnd(
				COLLAB_EDIT_PROVIDER_MEASURES.COLLAB_PERMS_TOKEN_FETCH,
			);
			return result.data?.collabToken?.token ?? null; // if token is null, collab service will fall back to permissions service check
		} catch (e) {
			markErrorAsHandled(e);
			if (isContentNotFoundError(e)) {
				// nasty fragile hack, mimic 404 exception which goes through collab-provider, then gets wrapped and emitted and handled in `useError`.
				// collab provider simply emits errors thrown in permissionTokenRefresh directly on its internal channel, so we have to conform to their error type
				// prettier-ignore
				throw { message: "Content not found", data: { status: 404, code: "INSUFFICIENT_EDITING_PERMISSION", meta: { description: "RESOURCE_DELETED", reason: "RESOURCE_DELETED" }}};
			} else if (e?.networkError?.response?.status === 503) {
				// prettier-ignore
				throw { message: "Service unavailable", data: { meta: { reason: "RESOURCE_DELETED" }}}; // temporary hack to ensure draft cannot be edited during tenant migration, but we should throw TENANT_INSTANCE_MAINTENANCE once supported by collab-provider
				// throw { message: "Service unavailable", data: { status: 423, code: "TENANT_INSTANCE_MAINTENANCE", meta: { description: "Service unavailable" }}};
			}
			return null;
		}
	},
);
