// Maximum number of characters in a single part
export const PART_MAX_SIZE = 5000;
// Maximum number of characters in all parts in a queue
export const TOTAL_PARTS_MAX_SIZE = 10000;
// Maximum number of parts in a queue
export const MAX_PARTS = 50;
// Maximium number of additional queues to request
export const QUEUE_MAX_ADDITIONAL_ATTEMPTS = 2;
// Limit until we stop tracking count of doc changes
export const DOC_CHANGE_COUNT_LIMIT = 8;
// Minimum size of document's text content to be eligible for proactive AI suggestions
export const DOC_MINIMUM_SIZE_THRESHOLD = 8;

export const START_PUNC_CHARS = [
	...Array.from(
		String.fromCharCode(
			39, // single quote
			145, // left single quote
			147, // left double quote
		),
	),
	'(',
	'[',
];

export const END_PUNC_CHARS = [
	Array.from(
		String.fromCharCode(
			34, // double quotes
			39, // single quote
			146, // right single quote
			148, // right double quote
		),
	),
	'.',
	',',
	'!',
	'?',
	')',
	']',
];

export const MIDDLE_PUNC_CHARS = Array.from(
	String.fromCharCode(
		// HYPHENS
		45, // hyphen-minus
		95, // underscore
		150, // en dash
		151, // em dash
		// SINGLE QUOTES
		39, // single quote
		146, // right single quote (apostrophe)
	),
);
