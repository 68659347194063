import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadContentHistoryRoute = async (match: RouteMatch) => {
	const tasks: Promise<any>[] = [];

	const { spaceKey, contentId } = match.params;

	// Since this query is not called in ContentPrerequisites when contentId is
	// missing, we should follow the same reasoning and skip preload when content is
	// missing.
	if (contentId) {
		const { featureFlags } = await getPreloaderFnContext();
		tasks.push(
			preloadContentPrerequisites(
				contentId,
				spaceKey,
				Boolean(featureFlags['confluence.frontend.space.alias']),
			),
		);
	}

	return Promise.all(tasks);
};
