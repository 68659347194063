import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { WIKI } from '@confluence/named-routes';

export const ResumeDraftLegacyRoute = ({ pathname, search, query }: RouteMatch) => {
	const { draftId } = query;
	if (typeof draftId !== 'string' || !/^[0-9]+$/.test(draftId)) {
		return <Redirection name={WIKI.name} />;
	} else {
		return <Redirection href={`${pathname}${search}`} forceReload />;
	}
};

ResumeDraftLegacyRoute.NAVIGATION_PARAMS = () => false;
