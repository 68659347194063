import { LoadablePaint } from '@confluence/loadable';

export const LiveEditSiteSettingsPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LiveEditSiteSettingsPage" */ '../src/site-settings/LiveEditSiteSettingsPage'
			)
		).LiveEditSiteSettingsPage,
});
