import React, { type FunctionComponent } from 'react';
import { token } from '@atlaskit/tokens';
import { R400 } from '@atlaskit/theme/colors';

const AKLabel = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.Label,
	})),
);

interface Props {
	isRequired?: boolean;
	label: string;
	name: string;
}

export const Label: FunctionComponent<Props> = ({ isRequired, label, name }) => {
	return (
		<AKLabel htmlFor={name} id={`${name}-label`}>
			{label}
			{isRequired && (
				<span
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						paddingLeft: token('space.025', '2px'),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						color: token('color.text.danger', R400),
					}}
					aria-hidden="true"
				>
					*
				</span>
			)}
		</AKLabel>
	);
};
