import type { Parameters } from '@atlaskit/editor-common/extensions';

import { parse } from '@confluence/search-utils';
import type { Expression } from '@confluence/search-utils';

import { cqlFieldMap } from './fields';

export const escapeText = (text) => text.replace(/\\/g, '\\\\').replace(/"/g, '\\"');

export const quoteString = (value) =>
	value === 'currentSpace()' ? value : `"${escapeText(value.toString())}"`;

// receives a kv of all fields in the CQL frame, even if not added yet
// those fields will be undefined or empty
export const cqlSerializer = (content: Parameters) => {
	const exprs: string[] = [];
	// content is an object with key/value pairs
	// we first convert it array and filter out undefined/null
	// if content is array, we convert each object in array to tuple array of [key, value]
	const arr = Object.entries(content).filter((entry) => entry[1]);

	exprs.push(
		...arr.map((entry) => {
			// eslint-disable-next-line prefer-const
			let [key, value] = entry;

			const definition = cqlFieldMap[key];

			const cqlKey = definition.key || key;
			let cqlValue;
			let cqlOp = '=';

			// use a different operator for this field?
			// e.g. a text field might want to use ~
			if (definition && definition.op) {
				cqlOp = definition.op;
			}

			// flatten singular arrays to scalar
			if (Array.isArray(value) && value.length === 1) {
				value = value[0];
			}

			// construct the value expression
			if (Array.isArray(value)) {
				cqlValue = `(${value.map(quoteString).join(', ')})`;
				cqlOp = 'IN';
			} else {
				if (typeof value === 'number') {
					// we don't have any number cql fields as of yet, but just in case
					cqlValue = value.toString();
				} else {
					cqlValue = quoteString(value.toString());
				}
			}

			return `${cqlKey} ${cqlOp} ${cqlValue}`;
		}),
	);

	return exprs.join(' AND ');
};

// walks the parse tree and finds expressions
// it also ensures we only have AND queries
function* collectExpr(node): Generator<Expression> {
	if (node.clause) {
		yield* collectExpr(node.clause);
	}

	if (node.clauses) {
		if (node.type.toUpperCase() === 'OR' && node.clauses.length !== 1) {
			throw new SyntaxError('cannot handle multiple OR clauses');
		}

		for (const subclause of node.clauses) {
			yield* collectExpr(subclause);
		}
	}

	if (node.nodeType === 'expr') {
		yield node;
	}
}

/*
  BEFORE ED-12586: cqlDeserializer return key, value object
  {
    ...
    'label': 'a',
    ...
  }
  AFTER ED-12586: cqlDeserializer return an array of key, value object because
  there can be more than 1 object with same field (label)
  [{
    ...
    'label': 'a',
    ...
  },
  {
    ...
    'label': 'b',
    ...
  }]
  ]
 */

export const cqlDeserializer = (value?: string): Parameters => {
	if (value === null || typeof value === 'undefined') {
		return {};
	}

	// try to parse CQL and pull only expressions out of the AST
	const parsed = parse(value);
	const exprs = Array.from(collectExpr(parsed));

	// create an object from the set of expressions
	const deserializedCQL: Parameters = {};
	exprs.forEach((expr) => {
		deserializedCQL[expr.field] = expr.value;
	});

	return deserializedCQL;
};
