import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getApolloClient } from '@confluence/graphql';
import type { TaskAction } from '@confluence/fabric-media-support';
import { SetBatchedTaskStatusMutation } from '@confluence/fabric-media-support';

export async function batchTaskUpdate(contentId: string, batch: TaskAction[]) {
	const updateBatch = batch.map((update) => {
		const { objectKey, state } = update;
		const status = state === 'DONE' ? 'CHECKED' : 'UNCHECKED';

		return { taskId: objectKey.localId, status };
	});

	const taskBatch = {
		contentId,
		tasks: updateBatch,
		trigger: 'VIEW_PAGE',
	};

	try {
		return await getApolloClient().mutate({
			mutation: SetBatchedTaskStatusMutation,
			variables: taskBatch,
		});
	} catch (error) {
		const client = await getAnalyticsWebClient();
		client.sendOperationalEvent({
			source: 'page',
			action: 'batchTaskUpdate',
			actionSubject: 'checkbox',
			attributes: {
				error: error.message || String(error),
				stack: error.stack,
			},
		});
	}
}
