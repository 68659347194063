import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import {
	SPACE_MANAGER_ANALYTICS_SOURCE,
	SpaceManagerLoader,
} from '@confluence/space-manager/entry-points/SpaceManagerLoader';

// Note that "Space Manager" is a global settings page that shows all the spaces in the instance.
// Thus we don't need to pass in a spaceKey or render the <SpaceBase> component.

export const SpaceManagerRoute = () => <SpaceManagerLoader />;

SpaceManagerRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: SPACE_MANAGER_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
