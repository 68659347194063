import { Logger } from '../../../../common/utils/logger';
import { getSetCookieOverride } from '../set-cookie-override';

// Hijack the document.cookie setter logic so we can track cookie and usage info if we've opted into cookies management
// This will generate a passthrough that will call the original document.cookie setter logic after we've logged analytics
export const createDocumentCookiePassthrough = async () => {
	const documentCookieProperty = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');
	const originalSetter = documentCookieProperty?.set;
	const originalGetter = documentCookieProperty?.get;

	if (!originalSetter || !originalGetter) {
		Logger.warn('No document.cookie capabilities found');
		return;
	}

	// Override document.cookie setter
	Object.defineProperty(document, 'cookie', {
		get: originalGetter,
		set: getSetCookieOverride(originalSetter),
		configurable: true,
	});
};
