export const SHOW_EDITOR = 'SHOW_EDITOR';
export const HIDE_EDITOR = 'HIDE_EDITOR';
export const CHANGE_EDITOR_TITLE = 'CHANGE_EDITOR_TITLE';
export const CLOSE_EDITOR_ERROR = 'CLOSE_EDITOR_ERROR';
export const SHOW_EDITOR_ERROR = 'SHOW_EDITOR_ERROR';
export const EDITOR_LOAD_COMPLETE = 'EDITOR_LOAD_COMPLETE';
export const EDITOR_PRELOAD_START = 'EDITOR_PRELOAD_START';
export const EDITOR_PRELOAD_FINISH = 'EDITOR_PRELOAD_FINISH';
export const RESET_EDITOR_STATE = 'RESET_EDITOR_STATE';
export const EDITOR_CONTENT = 'EDITOR_CONTENT';
export const EDITOR_CONTENT_V2 = 'EDITOR_CONTENT_V2';
export const EDITOR_CONTENT_PUBLISH = 'EDITOR_CONTENT_PUBLISH';
export const EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH = 'EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH';

export const CHANGE_EDITOR_MODE = 'CHANGE_EDITOR_MODE';
export const EDITOR_CONTENT_EDITABLE = 'EDITOR_CONTENT_EDITABLE';

// Drafts actions
export const EDITOR_DRAFT_SAVE = 'EDITOR_DRAFT_SAVE';
export const SET_SAVE_DRAFT = 'SET_SAVE_DRAFT';

export const EDITOR_CREATE_PAGE = 'EDITOR_CREATE_PAGE';
export const EDITOR_LOAD_DRAFT = 'EDITOR_LOAD_DRAFT';
export const EDITOR_LOAD_DRAFTMETADATA = 'EDITOR_LOAD_DRAFTMETADATA';
export const SET_IS_PUBLISHING_CONTENT = 'SET_IS_PUBLISHING_CONTENT';
export const EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY =
	'EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY';
export const UPDATE_CONTENT_SPACE_KEY = 'UPDATE_CONTENT_SPACE_KEY';
export const EDITOR_SHOW_SCHEDULED_PUBLISH_DIALOG = 'EDITOR_SHOW_SCHEDULED_PUBLISH_DIALOG';
export const EDITOR_HIDE_SCHEDULED_PUBLISH_COMPONENT = 'EDITOR_HIDE_SCHEDULED_PUBLISH_COMPONENT';
export const EDITOR_CURRENTLY_SCHEDULED_PUBLISH_DATE = 'EDITOR_CURRENTLY_SCHEDULED_PUBLISH_DATE';
export const EDITOR_CANCEL_SCHEDULED_PUBLISH = 'EDITOR_CANCEL_SCHEDULED_PUBLISH';
export const SET_IS_SCHEDULING_PUBLISH = 'SET_IS_SCHEDULING_PUBLISH';
