import { schema } from 'normalizr';
const ANONYMOUS = 'anonymous';

/**
 * Users schema.Entity
 */
export const user = new schema.Entity(
	'users',
	{},
	{
		idAttribute: (item) => {
			if (item.isAnonymous) {
				return ANONYMOUS;
			}
			if (item.accountId) {
				return item.accountId;
			}

			if (item.unknownUser) {
				return item.displayName;
			}

			// Changesets for allUpdates are structured such that we receive:
			// [{
			//   modifier: { name: "dankMemer", ... },
			//   recentUpdates: [ { ... }, { ... } ], ...
			// }]
			// For some reason, this means that we create a user for the modifier, and then try to create more users
			// for each object in the recentUpdates field, but there's no info about users in the recentUpdates objects
			// so we end up getting garbage here. Instead of overwriting the data for anonymous with this junk
			// let's put it all in a safe place. Ideally, we would fix this process, but it's not worth doing when we
			// are migrating away from Redux and into GraphQL.
			return 'FIX-ME:not-a-real-user';
		},
	},
);

/**
 * Versions schema.Entity
 */
const version = new schema.Entity('versions');
version.define({
	by: user,
});

/**
 * Spaces schema.Entity
 */
const space = new schema.Entity(
	'spaces',
	{},
	{
		idAttribute: (item) => item.spaceKey || item.key,
	},
);
export const spaces = {
	results: new schema.Array(space),
	nodes: new schema.Array(space),
};

/**
 * Labels schema.Entity
 */
const label = new schema.Entity('labels');

/**
 * Content schema.Entity
 */
export const content = new schema.Entity('contents');
content.define({
	space,
	version,
	createdBy: user,
	contributors: new schema.Array(user),
	childNodes: new schema.Array(content),
	ancestors: new schema.Array(content),
	labels: new schema.Array(label),
	viewers: new schema.Array(user),
});
