/**
 * TODO AIFOLLOWUP ED-18808, EDITOR-56:
 * This should no longer be needed once token case been finalised + added.
 * Once AI tokens are available, replace usages of `aiThemeColor` with
 * the correct tokens.
 */

import { useThemeObserver } from '@atlaskit/tokens';

export type AIThemeColor = (key: AIThemeKey) => string;
export type UseAIThemeColor = () => AIThemeColor;

export const useAIThemeColor: UseAIThemeColor = () => {
	const { colorMode } = useThemeObserver();
	return (key: AIThemeKey) => aiTheme[key][colorMode || 'light'];
};

type AITheme = typeof aiTheme;
export type AIThemeKey = keyof AITheme;

const aiTheme = {
	// Prism border gradient steps
	['prism.border.step.1']: {
		light: '#0065FF', // Blue
		dark: '#0065FF', // Blue
	},
	['prism.border.step.2']: {
		light: '#0469FF', // Blue
		dark: '#0469FF', // Blue
	},
	['prism.border.step.3']: {
		light: '#BF63F3', // Purple
		dark: '#BF63F3', // Purple
	},
	['prism.border.step.4']: {
		light: '#FFA900', // Yellow
		dark: '#FFA900', // Yellow
	},
	// AI Logo Color
	['icon.brand']: {
		light: '#44546F',
		dark: '#9FADBC',
	},
	// Telepointer background gradient steps
	['telepointer.background.step.1']: {
		light: '#F8E6A0',
		dark: '#F5CD47',
	},
	['telepointer.background.step.2']: {
		light: '#8BDBE5',
		dark: '#60C6D2',
	},
	['telepointer.background.step.3']: {
		light: '#0C66E4', // Blue700
		dark: '#388BFF', // Blue500
	},
	// Loading dots colours
	['loading.step.1']: {
		light: '#0C66E4', // Blue700
		dark: '#0C66E4', // Blue700
	},
	['loading.step.2']: {
		light: '#1D7AFC', // Blue600
		dark: '#1D7AFC', // Blue600
	},
	['loading.step.3']: {
		light: '#388BFF', // Blue500
		dark: '#388BFF', // Blue500
	},
};
