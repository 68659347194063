import { useContext } from 'react';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';

// Use with caution. A temporary solution to determine whether Confluence is embedded in Product X using an iframe
export function useIsEmbeddedConfluence_DO_NOT_USE(): boolean {
	const { location } = useContext(RoutesContext);
	return isEmbeddedConfluence_DO_NOT_USE(location || window.location);
}
