import React from 'react';

import { Onboarding } from '@confluence/onboarding';

export const OnboardingRoute = () => <Onboarding />;

OnboardingRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'onboardingContainerScreen' },
	},
	MainLayout: false,
});
