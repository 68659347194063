import type { CustomField, DateField } from '@atlaskit/editor-common/extensions';

import { isPageId } from '../utils';

import type { FieldTransformer } from './types';
import { customToIsoDate, isoToCustomDate } from './utils';

// Dates must be entered as dd-mm-yyyy
// https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
const CREATED_DATE_FORMAT = 'dd-MM-yyyy';

export interface TasksReportMacroParams {
	spaceAndPage?: string | string[];
	spaces?: string;
	pages?: string;
	createddateFrom?: string;
}

export const tasksReport: FieldTransformer<TasksReportMacroParams> = {
	// Converts the "spaceAndPage" field to be a custom multi-select
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name === 'spaceAndPage') {
				return {
					...field,
					isMultiple: true,
					type: 'custom',
					options: {
						resolver: {
							type: 'spaceAndPage',
						},
					},
				} as CustomField;
			} else if (field.name === 'createddateFrom') {
				// Remove the description since we are using a date picker now
				return {
					...field,
					description: undefined,
				} as DateField;
			}

			return field;
		});
	},
	transformBefore(params) {
		// Handle Space and Pages
		// We store { pages: '123', spaces: '~456' } in macroParams
		// but new config field definition has one `spaceAndPage` field
		// containing both `pages` and `spaces` field values joined together
		let spaceAndPage = '';
		if (params.spaces) {
			spaceAndPage += params.spaces;
		}
		if (params.pages) {
			if (spaceAndPage) {
				spaceAndPage += ',';
			}
			spaceAndPage += params.pages;
		}
		if (spaceAndPage) {
			params.spaceAndPage = spaceAndPage;
		}
		delete params.spaces;
		delete params.pages;

		// Handle Date
		if (params.createddateFrom?.length) {
			params.createddateFrom = customToIsoDate(params.createddateFrom, CREATED_DATE_FORMAT);
		}

		return params;
	},
	transformAfter(params) {
		// Before passing it back to macro splitting `spaceAndPage` into `spaces` & `pages`
		if (Array.isArray(params.spaceAndPage)) {
			const spaces = params.spaceAndPage.filter((value) => !isPageId(value));
			if (spaces.length > 0) {
				params.spaces = spaces.join(',');
			}
			const pages = params.spaceAndPage.filter(isPageId);
			if (pages.length > 0) {
				params.pages = pages.join(',');
			}
			delete params.spaceAndPage;
		}

		// Handle Date
		if (params.createddateFrom?.length) {
			params.createddateFrom = isoToCustomDate(params.createddateFrom, CREATED_DATE_FORMAT);
		}

		return params;
	},
};
