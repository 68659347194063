import type { BaseMetricDataWithStartAndStop, PerformanceEventConfig } from '../../types';

export const eventRoute = (
	_config: PerformanceEventConfig,
	data: BaseMetricDataWithStartAndStop,
) => {
	if (!data.route) {
		return null;
	}
	return {
		'event:route': data.route,
	};
};
