import { LoadableBackground } from '@confluence/loadable';

export const CreateContentLegacyQueryParamRedirect = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CreateContentLegacyQueryParamRedirect" */ '../src/CreateContentLegacyQueryParamRedirect'
			)
		).CreateContentLegacyQueryParamRedirect,
});
