// Usage of 'lodash/get' is justified here because:
// 1. Object from which the property is read is an arbitrary JSON object thus it has 'any' type.
// 2. The path that is used to read a property from an object is represented by a string and that is coming from a
//    Forge app config, thus 'idx' doesn't quite work here.
// eslint-disable-next-line no-restricted-imports
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isString from 'lodash/isString';

import { EntityType } from '@atlassian/forge-conditions';
import type { PropertyValueRequest, PropertyValuesResponse } from '@atlassian/forge-conditions';

import { getApolloClient } from '@confluence/graphql';

import { DisplayConditionsContentPropertiesQuery as ContentPropertiesQuery } from './DisplayConditionsContentPropertiesQuery.graphql';
import { SpacePropertiesQuery } from './SpacePropertiesQuery.experimentalgraphql';

export type FetchContext = {
	contentId?: string;
	spaceKey?: string;
};

type PropertyKeysById = [string, string][];
type PropertyValuesById = {
	[id: string]: any;
};

interface PropertyFetcher {
	(propKeysById: PropertyKeysById, fetchContext: FetchContext): Promise<PropertyValuesById>;
}

const fetchProperties = (
	propKeysById: PropertyKeysById,
	query,
	fetchContext: FetchContext,
	extractPropsFromResponse: (response) => any,
): Promise<PropertyValuesById> => {
	const propKeysToFetch = [...new Set(propKeysById.map(([_, key]) => key))];
	return getApolloClient()
		.query({
			query,
			fetchPolicy: 'cache-first',
			variables: {
				...fetchContext,
				propertyKeys: propKeysToFetch,
			},
		})
		.then((response) => {
			const properties = extractPropsFromResponse(response);
			const propValuesByKeys = new Map(
				properties.map((prop) => [prop.key, JSON.parse(prop.value)] as [string, any]),
			);
			return Object.fromEntries(
				propKeysById
					.filter(([_, key]) => propValuesByKeys.has(key))
					.map(([id, key]) => [id, propValuesByKeys.get(key)]),
			);
		});
};

const fetchContentProperties: PropertyFetcher = (
	propKeysById: PropertyKeysById,
	fetchContext: FetchContext,
): Promise<PropertyValuesById> => {
	return fetchProperties(
		propKeysById,
		ContentPropertiesQuery,
		fetchContext,
		(res) => res.data.content.nodes[0].properties.nodes,
	);
};

const fetchSpaceProperties: PropertyFetcher = (
	propKeysById: PropertyKeysById,
	fetchContext: FetchContext,
): Promise<PropertyValuesById> => {
	return fetchProperties(
		propKeysById,
		SpacePropertiesQuery,
		fetchContext,
		(res) => res.data.experimentalSpaceProperties,
	);
};

const propertyFetchers = new Map<EntityType, PropertyFetcher>([
	[EntityType.Content, fetchContentProperties],
	[EntityType.Space, fetchSpaceProperties],
]);

const createIdToPropValuePair = (
	propRequest: PropertyValueRequest,
	propValuesById: PropertyValuesById,
): [string, string | string[] | undefined] => {
	const propValue = propValuesById[propRequest.id];
	if (!propRequest.objectName) {
		return [propRequest.id, stringify(propValue)] as [string, string | string[] | undefined];
	}

	const nestedValue = get(propValue, propRequest.objectName);
	return [propRequest.id, stringify(nestedValue)] as [string, string | string[] | undefined];
};

const stringify = (value: any, stringifyArray = false): string | string[] | undefined => {
	if (typeof value === 'undefined' || value === null) {
		return undefined;
	}

	if (isString(value)) {
		return value;
	}

	if (!stringifyArray && Array.isArray(value)) {
		return value.map((item) => stringify(item, true) as string);
	}

	return JSON.stringify(value);
};

export const fetchPropertyData = (
	propertyRequestsInput: PropertyValueRequest[],
	fetchContext: FetchContext,
): Promise<PropertyValuesResponse> => {
	const propertyRequests = propertyRequestsInput || [];
	if (!propertyRequests.length) {
		return Promise.resolve({});
	}

	const requestsByEntityType = groupBy(propertyRequests, (request) => request.entity);
	const propValuePromises: Promise<PropertyValuesById>[] = Object.entries(requestsByEntityType).map(
		([entityType, propRequests]) => {
			const propFetcher = propertyFetchers.get(entityType as EntityType);
			if (!propFetcher) {
				throw new Error(`Can't find entity property fetcher. Unknown entity type: ${entityType}`);
			}

			return propFetcher(
				propRequests.map((p) => [p.id, p.propertyKey]),
				fetchContext,
			);
		},
	);

	return Promise.all(propValuePromises).then((propValues: PropertyValuesById[]) => {
		if (!propValues.length || propValues.every((values) => !Object.keys(values).length)) {
			return {};
		}
		const allValuesById: PropertyValuesById = propValues.reduce((previous, current) => ({
			...previous,
			...current,
		}));

		const propValuesResponse: PropertyValuesResponse = {};
		propertyRequests
			.map((propRequest) => createIdToPropValuePair(propRequest, allValuesById))
			.filter(([_, value]) => typeof value !== 'undefined') // Filter out those with undefined value.
			.forEach(([id, value]) => (propValuesResponse[id] = value as string | string[]));

		return propValuesResponse;
	});
};
