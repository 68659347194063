import type { CustomField, DateField } from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from './types';
import { customToIsoDate, isoToCustomDate } from './utils';

// NOTE: Contributors uses ISO date format so this is only useful when
// the calendar picker will use a different locale.
// Dates must be entered as YYYY/MM/DD
// https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
const PUBLISH_DATE_FORMAT = 'yyyy-MM-dd';

export interface ContributorsMacroParams {
	publishDate?: string;
	spaces?: string | string[];
}

export const contributors: FieldTransformer<ContributorsMacroParams> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			switch (field.name) {
				case 'publishDate':
					return {
						...field,
						description: undefined,
					} as DateField;
				case 'spaces':
					return {
						...field,
						options: {
							resolver: {
								type: 'contributorsSpaceParamResolver',
							},
						},
					} as CustomField;
				default:
					return field;
			}
		});
	},
	transformBefore(params) {
		if (params.publishDate?.length) {
			params.publishDate = customToIsoDate(params.publishDate, PUBLISH_DATE_FORMAT);
		}
		return params;
	},
	transformAfter(params) {
		if (params.publishDate?.length) {
			params.publishDate = isoToCustomDate(params.publishDate, PUBLISH_DATE_FORMAT);
		}

		return params;
	},
};
