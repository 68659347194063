import React, { Component } from 'react';
import memoize from 'memoize-one';

import { getLegacyMessageQueue } from '@confluence/legacy-message-queue';

import { CreateDialogContext } from './CreateDialogContext';
import { getDefaultChangeableValues } from './defaults';
import type {
	OpenBlueprintWizardParams,
	OpenTemplateVariablesParams,
	ChangeableValues,
	CreateDialogContextType,
} from './create-dialog-context-types';

export class CreateDialogContextProvider extends Component<
	React.PropsWithChildren<{}>,
	ChangeableValues
> {
	constructor(props) {
		super(props);

		this.state = getDefaultChangeableValues;
	}

	componentDidMount() {
		void getLegacyMessageQueue().then((legacyMessageQueue) => {
			legacyMessageQueue.push({
				type: 'ATLASSIAN_CONFLUENCE_VARIABLEINPUT_REGISTER_CALLBACK',
				payload: this.openTemplateVariables,
			});
		});
	}

	openTemplateVariables = ({
		templateId,
		parentPageId,
		spaceKey,
		title,
		contentId,
		onContentUpdated,
		onError,
		beforeUpdateRequest,
		forceTemplatePanelOpen,
		openContentInNewTab,
	}: OpenTemplateVariablesParams) => {
		this.setState({
			templateId,
			parentPageId,
			spaceKey,
			title,
			contentId,
			onContentUpdated,
			onError,
			showTemplateVariableInputDrawer: true,
			beforeUpdateRequest,
			forceTemplatePanelOpen,
			openContentInNewTab,
		});
	};

	openBlueprintWizard = ({
		blueprint,
		parentPageId,
		spaceKey,
		templateOriginSpaceKey,
		title,
		contentId,
		onContentUpdated,
		onError,
		beforeUpdateRequest,
	}: OpenBlueprintWizardParams) => {
		this.setState({
			contentId,
			blueprint,
			parentPageId,
			spaceKey,
			templateOriginSpaceKey,
			onContentUpdated,
			onError,
			showBlueprintVariableWizard: true,
			title: title || '',
			beforeUpdateRequest,
		});
	};

	onClose = () => {
		this.setState(getDefaultChangeableValues);
	};

	getProviderValue = memoize(
		(state: ChangeableValues): CreateDialogContextType => ({
			...state,
			openTemplateVariables: this.openTemplateVariables,
			openBlueprintWizard: this.openBlueprintWizard,
			onClose: this.onClose,
		}),
	);

	render() {
		return (
			<CreateDialogContext.Provider value={this.getProviderValue(this.state)}>
				{this.props.children}
			</CreateDialogContext.Provider>
		);
	}
}
