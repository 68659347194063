import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

const contentFragment = gql`
	fragment contentFragment on Content {
		id
		type
		space {
			id
		}
		operations {
			operation
			targetType
		}
	}
`;

export const InlineCommentsQuery = gql`
	query InlineCommentsQuery($pageId: ID!) {
		content(id: $pageId) {
			nodes {
				...contentFragment
			}
		}
		comments(pageId: $pageId, type: UNRESOLVED, depth: ROOT, first: 1000) {
			nodes {
				...inlineCommentFragment
				replies {
					...inlineCommentFragment
				}
			}
		}
	}
	${contentFragment}
	${inlineCommentFragment}
`;

export const InlineCommentsWithSpaceKeyQuery = gql`
	query InlineCommentsWithSpaceKeyQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			homepageComments(type: UNRESOLVED, depth: ROOT) {
				nodes {
					...inlineCommentFragment
					replies {
						...inlineCommentFragment
					}
				}
			}
		}
		spaceHomepage(spaceKey: $spaceKey) {
			...contentFragment
		}
	}
	${contentFragment}
	${inlineCommentFragment}
`;
