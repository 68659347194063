import { RetryLink } from 'apollo-link-retry';

import { BadStatusError } from '@confluence/network';

// In some circumstances, unmounting a <Query> won't cancel the underlying query,
// causing a retry-loop to continue long after the corresponding UI has
// disappeared (link-to-this-page-dialog was doing this for me -mdean2).
// Seems to need fetchPolicy="cache-first" (the default) and a not-single query
// to show the effect. While that's still an issue, be conservative with the
// max retries.
const BAD_STATUS_CODE_MAX_RETRIES = 2;
const BAD_STATUS_CODE_INITIAL_RETRY_DELAY_MS = 500;
const BAD_STATUS_CODE_MAX_RETRY_DELAY_MS = 5000;

// Query retries are disabled by default: reconsider after fetching feature flags
let retryEnabled = false;

// @ts-ignore
// eslint-disable-next-line no-unused-vars
const sendTrackEvent = (error, operation) => {
	void import(
		/* webpackChunkName: "loadable-confluenceanalytics-web-client" */ '@confluence/analytics-web-client'
	).then(({ getAnalyticsWebClient }) => {
		void getAnalyticsWebClient().then((analyticsWebClient) => {
			analyticsWebClient.sendOperationalEvent({
				source: 'ui',
				actionSubject: 'graphql',
				action: 'errored',
				attributes: {
					eventName: 'status.code.retry',
					queryName: operation.operationName,
					priorRetries: operation.getContext().statusRetries || 0,
					status: error.response.status,
					statusText: error.response.statusText,
				},
			});
		});
	});
};

export const statusCodeRetryLink = new RetryLink({
	delay: {
		initial: BAD_STATUS_CODE_INITIAL_RETRY_DELAY_MS,
		max: BAD_STATUS_CODE_MAX_RETRY_DELAY_MS,
		jitter: true,
	},
	attempts: {
		max: BAD_STATUS_CODE_MAX_RETRIES,
		retryIf: (error, operation) => {
			// Never retry if feature-flag not enabled
			if (!retryEnabled) return false;

			// If we didn't receive a 2xx status code, then...
			if (error instanceof BadStatusError) {
				// Don't retry mutations, regardless of all other concerns
				if (
					operation.query.definitions.some(
						(definition) => 'operation' in definition && definition.operation === 'mutation',
					)
				)
					return false;

				// When we've decided policy on which 5xx errors merit a retry, implement here.
				//
				// If you wanted, for instance, to retry based on your machine's WiFi being
				// down (where the dev proxy will be responding with 504 codes), uncomment this:
				/*
        if (error.response.status === 504) {
          console.log("Do not commit");
          sendTrackEvent(error, operation);
          operation.setContext(context => ({
            statusRetries: (context.statusRetries || 0) + 1
          }));
          return true;
        }
        */
			}

			// All other errors, leave it to a higher link in the chain to retry if it wants to
			return false;
		},
	},
});

export function enableStatusCodeRetryLink() {
	retryEnabled = true;
}
