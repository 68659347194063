import React, {
	type ComponentType,
	type FunctionComponent,
	Suspense,
	useCallback,
	useMemo,
	useState,
	useRef,
} from 'react';
import {
	BaseCrossFlowApiProvider,
	type OnOpen,
	type CompletionStatus,
	type Options,
	Journeys,
} from '@atlassiansox/cross-flow-api-internals';
import { getGlobalTheme } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { analyticsWrapper } from './analytics-helpers/analyticsHelpers';
import { transformEvent } from './analytics-helpers/analyticsTransformers';
import { setProductSignUpLocation } from './redirects-helpers/redirectsHelpers';
import { ErrorBoundary } from './errorBoundary';
import { type CrossFlowProviderProps } from './types';
import { type IntegrationViewProps } from './view/types';
import { type OnUIAnalyticsEvent } from './types';
import { enrichWithPackageDetails } from './enrichWithPackageDetails';
import { enrichWithRequestOptions } from './enrichWithRequestOptions';
import {
	OPERATIONAL_EVENT_TYPE,
	source,
	uiInitialized,
	crossFlowActionSubject,
	openInvoked,
} from './constants';

const IntegrationView = React.lazy(
	() =>
		import(
			/* webpackChunkName: "atlassiansox-cross-flow-support-deferred" */
			/* webpackPrefetch: true */
			'./view'
		),
);

type OnCloseResolver = (completionStatus: CompletionStatus) => void;

/**
 * Generic Cross Flow API provider.
 *
 * Initializes Cross Flow support in product.
 * Renders Cross Flow overlay when requested via context API.
 */

export const createCrossFlowProvider =
	(
		IntegrationView: ComponentType<IntegrationViewProps>,
	): FunctionComponent<CrossFlowProviderProps> =>
	(props) => {
		const {
			children,
			analyticsClient,
			originProduct,
			redirectToWac,
			env,
			plugins = [],
			globalExperimentalOptions,
			onError,
			...baseProviderProps
		} = props;

		// TBLZ-852: Product Store - Personal Use Case
		// go/trailblazerexperiments
		const tblz852ExperimentCohort: string = FeatureGates.getExperimentValue(
			'product_store_personal_use_case',
			'cohort',
			'not-enrolled',
			{
				fireExperimentExposure: false,
			},
		);
		const isInTblz852ExperimentCohort = tblz852ExperimentCohort === 'variation';
		// TBLZ-852

		const onCloseResolver = useRef<OnCloseResolver>();

		const [firstHandshakeReceived, setFirstHandshakeReceived] = useState(false);

		const fireEvent = useMemo(() => analyticsWrapper(analyticsClient), [analyticsClient]);

		const [requestOptions, setRequestOptions] = useState<Options>();

		const onAnalyticsEvent: OnUIAnalyticsEvent = useCallback(
			(payload) => {
				const gasV3Event = transformEvent(payload);
				if (gasV3Event) {
					const enrichedEvent = enrichWithRequestOptions(
						enrichWithPackageDetails(gasV3Event),
						requestOptions,
					);
					fireEvent(enrichedEvent);
				}
			},
			[fireEvent, requestOptions],
		);

		const onHandShake = useCallback(
			(appName: string) => {
				if (!firstHandshakeReceived) {
					onAnalyticsEvent({
						payload: {
							eventType: OPERATIONAL_EVENT_TYPE,
							action: uiInitialized,
							actionSubject: crossFlowActionSubject,
							attributes: { appName },
						},
						context: [{ source }],
					});
					setFirstHandshakeReceived(true);
				}
			},
			[firstHandshakeReceived, onAnalyticsEvent],
		);

		const onOpen: OnOpen = useCallback(
			(options) => {
				/**
				 * Reset first handshake state to ensure 1:1 value of open invoked and handshake received
				 * Caveat that if open api is invoked while cross flow support UI is already mounted there will be mismatch of openInvoked events to uiInitialized events
				 */
				setFirstHandshakeReceived(false);

				const analyticsEvent = {
					// setRequestOptions does not update the state right away (updates on the next render) so for the openInvoked event we need to manually append the sourceComponent and sourceContext
					payload: enrichWithRequestOptions(
						{
							eventType: OPERATIONAL_EVENT_TYPE,
							action: openInvoked,
							actionSubject: crossFlowActionSubject,
						},
						options,
					),
					context: [{ source }],
				};
				onAnalyticsEvent(analyticsEvent);

				// Short circuit requestOptions for WAC expansions
				if (options.journey === Journeys.GET_STARTED && redirectToWac) {
					setProductSignUpLocation(
						options.targetProduct,
						env,
						options.sourceComponent,
						options.sourceContext,
					);
					const completionStatus: CompletionStatus = {};
					return Promise.resolve(completionStatus);
				}

				const updatedExperimentalOptions = {
					...(globalExperimentalOptions || {}), // "globalExperimentalOptions" will be injected to EVERY crossFlow invoke
					...(options.experimentalOptions || {}), // "options.experimentalOptions" takes care of passing experimentalOptions to ONE specific crossFlow invoke
				};

				options.experimentalOptions = updatedExperimentalOptions;

				setRequestOptions(options);
				return new Promise((resolve: OnCloseResolver) => {
					onCloseResolver.current = resolve;
				});
			},
			[onAnalyticsEvent, env, redirectToWac, globalExperimentalOptions],
		);

		const onClose = useCallback((completionStatus: CompletionStatus) => {
			setFirstHandshakeReceived(false);
			setRequestOptions(undefined);
			onCloseResolver.current && onCloseResolver.current(completionStatus);
		}, []);

		const { colorMode } = getGlobalTheme();

		return (
			<BaseCrossFlowApiProvider onOpen={onOpen}>
				{children}

				{requestOptions && (
					<ErrorBoundary onAnalyticsEvent={onAnalyticsEvent} onError={onError}>
						<Suspense fallback={null}>
							<IntegrationView
								{...baseProviderProps}
								{...requestOptions}
								onAnalyticsEvent={onAnalyticsEvent}
								originProduct={originProduct}
								theme={colorMode}
								onClose={onClose}
								onHandShake={onHandShake}
								plugins={plugins}
								env={env}
								redirectToWac={redirectToWac}
								isInTblz852ExperimentCohort={isInTblz852ExperimentCohort}
								zIndex={requestOptions.zIndex ?? 1000}
							/>
						</Suspense>
					</ErrorBoundary>
				)}
			</BaseCrossFlowApiProvider>
		);
	};

export const CrossFlowProvider = createCrossFlowProvider(IntegrationView);
