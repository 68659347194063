export const ONBOARDING_TRACKER_STATE_KEYS = {
	EXPERIMENT_ELIGIBLE: 'onboarding-tracker:isTaggedForOnboardingTrackerExperiment',
	EXPERIMENT_DISMISSED: 'onboarding-tracker:isTrackerDismissed',
	HAS_USER_ADDED_CONTENT: 'onboarding-tracker:hasUserAddedContent',
	HAS_USER_INVITED: 'onboarding-tracker:hasUserInvited',
	HAS_EXPLORED_SPACE: 'onboarding-tracker:hasExploredSpace',
};

export type OnboardingTrackerState = {
	isTaggedForOnboardingTrackerExperiment?: boolean;
	isTrackerDismissed?: boolean;
	hasUserAddedContent?: boolean;
	hasUserInvited?: boolean;
	hasExploredSpace?: boolean;
};
