import type {
	BaseMetricDataWithStartAndStop,
	PerformanceEventConfig,
	ShareableGlobalConfig,
} from '../../types';

export const eventRegion = (
	_config: PerformanceEventConfig,
	_data: BaseMetricDataWithStartAndStop,
	globalConfig: ShareableGlobalConfig,
) => {
	return {
		'event:region': `${globalConfig.region}`,
	};
};
