import type { SessionDataType, FeatureFlagsType } from '@confluence/session-data';
import { getSessionData } from '@confluence/session-data';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getMonitoringClient } from '@confluence/monitoring';

export const clearMarks = (names: string[]) => {
	if (window.performance?.clearMarks) {
		names.forEach((name) => window.performance.clearMarks(name));
	}
};

export const getEntryByType = (name: string) => {
	if (window.performance?.getEntriesByType) {
		const entries = window.performance.getEntriesByType(name);
		return entries && entries[0];
	}
};

export const getEntryByName = (name: string) => {
	if (window.performance?.getEntriesByName) {
		const entries = window.performance.getEntriesByName(name);
		return entries && entries[0];
	}
};

export const measureMarks = (a: string, b: string) => {
	const entryA = getEntryByName(a);
	const entryB = getEntryByName(b);
	if (entryA && entryB) {
		return Math.abs(entryA.startTime - entryB.startTime);
	}
	return undefined;
};

export const getMark = (name: string) => {
	const mark = getEntryByName(name);
	if (mark) {
		return mark.startTime;
	}
	return undefined;
};

// perfMarkStart works together with perfMarkEnd function.
export const perfMarkStart = ({
	subject,
	subjectId = '',
}: {
	subject: string;
	subjectId?: string;
}) => {
	const startMark = `${subject}.${subjectId}.start`;
	const endMark = `${subject}.${subjectId}.end`;

	clearMarks([startMark, endMark]);
	window.performance?.mark?.(startMark);
};

// Filter out feature flags with falsy values to save user bandwidth
const filterFeatureFlags = (flags: FeatureFlagsType = {}) =>
	Object.keys(flags)
		.filter((key) => flags[key])
		.reduce<FeatureFlagsType>((acc, key) => {
			acc[key] = flags[key];
			return acc;
		}, {});

const performanceEnd = async ({
	details,
	subject,
	subjectId,
	includeFeatureFlags,
}: {
	subject: string;
	subjectId?: string;
	details?: object;
	includeFeatureFlags?: boolean;
}) => {
	const endMark = `${subject}.${subjectId}.end`;
	window.performance?.mark?.(endMark);

	const duration = measureMarks(`${subject}.${subjectId}.start`, endMark);

	if (duration === undefined) {
		return;
	}

	try {
		const analyticsClientPromise = getAnalyticsWebClient();
		const sessionDataPromise = includeFeatureFlags
			? getSessionData()
			: Promise.resolve<Pick<SessionDataType, 'featureFlags'>>({
					featureFlags: {},
				});

		const [client, { featureFlags }] = await Promise.all([
			analyticsClientPromise,
			sessionDataPromise,
		]);

		const filteredFeatureFlags = filterFeatureFlags(featureFlags);
		client.sendOperationalEvent({
			tags: ['performance'],
			source: 'confluence-frontend',
			action: 'done',
			actionSubject: subject,
			actionSubjectId: subjectId,
			attributes: {
				featureFlags: filteredFeatureFlags,
				duration,
				...details,
			},
		});
	} catch (ex) {
		// This is a catch-all no-op, to prevent analytics component from screwing
		// up the user experience in case of any unexpected errors.
		getMonitoringClient().submitError(ex, { attribution: 'unknown' });
	}
};

export const perfMarkEnd = ({
	subject,
	subjectId = '',
	details = {},
	includeFeatureFlags = false,
}: {
	subject: string;
	subjectId?: string;
	details?: object;
	includeFeatureFlags?: boolean;
}) => {
	const endMarkExists = getEntryByName(`${subject}.${subjectId}.end`);
	if (!endMarkExists) {
		void performanceEnd({ details, includeFeatureFlags, subject, subjectId });
	}
};
