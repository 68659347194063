/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * lazy imports for all icons in @atlaskit/icon/glyph that are used in UI Kit 2 components
 *
 * @codegen <<SignedSource::ffa1f844261bab1815e1e39f5604aba5>>
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen icon
 * @codegenDependency ../../../../../../../design-system/icon/src/metadata.tsx <<SignedSource::44570dd38135563e5295802787995570>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import { lazy } from 'react';
import type { ComponentType, LazyExoticComponent } from 'react';
import type { Icon } from '@atlassian/forge-ui-types';

type LazyIcon = LazyExoticComponent<ComponentType<any>>;
export const IconGlyphs: Record<Icon, LazyIcon> = {
  'activity': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--activity" */ '@atlaskit/icon/glyph/activity')),
  'add': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--add" */ '@atlaskit/icon/glyph/add')),
  'add-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--add-circle" */ '@atlaskit/icon/glyph/add-circle')),
  'add-item': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--add-item" */ '@atlaskit/icon/glyph/add-item')),
  'addon': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--addon" */ '@atlaskit/icon/glyph/addon')),
  'app-access': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--app-access" */ '@atlaskit/icon/glyph/app-access')),
  'app-switcher': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--app-switcher" */ '@atlaskit/icon/glyph/app-switcher')),
  'archive': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--archive" */ '@atlaskit/icon/glyph/archive')),
  'arrow-down': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-down" */ '@atlaskit/icon/glyph/arrow-down')),
  'arrow-down-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-down-circle" */ '@atlaskit/icon/glyph/arrow-down-circle')),
  'arrow-left': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-left" */ '@atlaskit/icon/glyph/arrow-left')),
  'arrow-left-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-left-circle" */ '@atlaskit/icon/glyph/arrow-left-circle')),
  'arrow-right': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-right" */ '@atlaskit/icon/glyph/arrow-right')),
  'arrow-right-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-right-circle" */ '@atlaskit/icon/glyph/arrow-right-circle')),
  'arrow-up': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-up" */ '@atlaskit/icon/glyph/arrow-up')),
  'arrow-up-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--arrow-up-circle" */ '@atlaskit/icon/glyph/arrow-up-circle')),
  'attachment': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--attachment" */ '@atlaskit/icon/glyph/attachment')),
  'audio': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--audio" */ '@atlaskit/icon/glyph/audio')),
  'audio-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--audio-circle" */ '@atlaskit/icon/glyph/audio-circle')),
  'backlog': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--backlog" */ '@atlaskit/icon/glyph/backlog')),
  'billing': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--billing" */ '@atlaskit/icon/glyph/billing')),
  'billing-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--billing-filled" */ '@atlaskit/icon/glyph/billing-filled')),
  'bitbucket-branches': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-branches" */ '@atlaskit/icon/glyph/bitbucket/branches')),
  'bitbucket-builds': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-builds" */ '@atlaskit/icon/glyph/bitbucket/builds')),
  'bitbucket-clone': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-clone" */ '@atlaskit/icon/glyph/bitbucket/clone')),
  'bitbucket-commits': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-commits" */ '@atlaskit/icon/glyph/bitbucket/commits')),
  'bitbucket-compare': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-compare" */ '@atlaskit/icon/glyph/bitbucket/compare')),
  'bitbucket-forks': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-forks" */ '@atlaskit/icon/glyph/bitbucket/forks')),
  'bitbucket-output': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-output" */ '@atlaskit/icon/glyph/bitbucket/output')),
  'bitbucket-pipelines': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-pipelines" */ '@atlaskit/icon/glyph/bitbucket/pipelines')),
  'bitbucket-pullrequests': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-pullrequests" */ '@atlaskit/icon/glyph/bitbucket/pullrequests')),
  'bitbucket-repos': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-repos" */ '@atlaskit/icon/glyph/bitbucket/repos')),
  'bitbucket-snippets': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-snippets" */ '@atlaskit/icon/glyph/bitbucket/snippets')),
  'bitbucket-source': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bitbucket-source" */ '@atlaskit/icon/glyph/bitbucket/source')),
  'board': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--board" */ '@atlaskit/icon/glyph/board')),
  'book': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--book" */ '@atlaskit/icon/glyph/book')),
  'bullet-list': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--bullet-list" */ '@atlaskit/icon/glyph/bullet-list')),
  'calendar': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--calendar" */ '@atlaskit/icon/glyph/calendar')),
  'calendar-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--calendar-filled" */ '@atlaskit/icon/glyph/calendar-filled')),
  'camera': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--camera" */ '@atlaskit/icon/glyph/camera')),
  'camera-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--camera-filled" */ '@atlaskit/icon/glyph/camera-filled')),
  'camera-rotate': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--camera-rotate" */ '@atlaskit/icon/glyph/camera-rotate')),
  'camera-take-picture': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--camera-take-picture" */ '@atlaskit/icon/glyph/camera-take-picture')),
  'canvas': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--canvas" */ '@atlaskit/icon/glyph/canvas')),
  'check': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--check" */ '@atlaskit/icon/glyph/check')),
  'check-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--check-circle" */ '@atlaskit/icon/glyph/check-circle')),
  'check-circle-outline': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--check-circle-outline" */ '@atlaskit/icon/glyph/check-circle-outline')),
  'checkbox': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--checkbox" */ '@atlaskit/icon/glyph/checkbox')),
  'checkbox-indeterminate': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--checkbox-indeterminate" */ '@atlaskit/icon/glyph/checkbox-indeterminate')),
  'chevron-down': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-down" */ '@atlaskit/icon/glyph/chevron-down')),
  'chevron-down-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-down-circle" */ '@atlaskit/icon/glyph/chevron-down-circle')),
  'chevron-left': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-left" */ '@atlaskit/icon/glyph/chevron-left')),
  'chevron-left-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-left-circle" */ '@atlaskit/icon/glyph/chevron-left-circle')),
  'chevron-left-large': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-left-large" */ '@atlaskit/icon/glyph/chevron-left-large')),
  'chevron-right': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-right" */ '@atlaskit/icon/glyph/chevron-right')),
  'chevron-right-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-right-circle" */ '@atlaskit/icon/glyph/chevron-right-circle')),
  'chevron-right-large': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-right-large" */ '@atlaskit/icon/glyph/chevron-right-large')),
  'chevron-up': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-up" */ '@atlaskit/icon/glyph/chevron-up')),
  'chevron-up-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--chevron-up-circle" */ '@atlaskit/icon/glyph/chevron-up-circle')),
  'child-issues': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--child-issues" */ '@atlaskit/icon/glyph/child-issues')),
  'code': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--code" */ '@atlaskit/icon/glyph/code')),
  'comment': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--comment" */ '@atlaskit/icon/glyph/comment')),
  'component': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--component" */ '@atlaskit/icon/glyph/component')),
  'copy': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--copy" */ '@atlaskit/icon/glyph/copy')),
  'creditcard': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--creditcard" */ '@atlaskit/icon/glyph/creditcard')),
  'creditcard-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--creditcard-filled" */ '@atlaskit/icon/glyph/creditcard-filled')),
  'cross': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--cross" */ '@atlaskit/icon/glyph/cross')),
  'cross-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--cross-circle" */ '@atlaskit/icon/glyph/cross-circle')),
  'dashboard': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--dashboard" */ '@atlaskit/icon/glyph/dashboard')),
  'decision': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--decision" */ '@atlaskit/icon/glyph/decision')),
  'department': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--department" */ '@atlaskit/icon/glyph/department')),
  'detail-view': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--detail-view" */ '@atlaskit/icon/glyph/detail-view')),
  'discover': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--discover" */ '@atlaskit/icon/glyph/discover')),
  'discover-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--discover-filled" */ '@atlaskit/icon/glyph/discover-filled')),
  'document': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--document" */ '@atlaskit/icon/glyph/document')),
  'document-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--document-filled" */ '@atlaskit/icon/glyph/document-filled')),
  'documents': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--documents" */ '@atlaskit/icon/glyph/documents')),
  'download': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--download" */ '@atlaskit/icon/glyph/download')),
  'drag-handler': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--drag-handler" */ '@atlaskit/icon/glyph/drag-handler')),
  'dropbox': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--dropbox" */ '@atlaskit/icon/glyph/dropbox')),
  'edit': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--edit" */ '@atlaskit/icon/glyph/edit')),
  'edit-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--edit-filled" */ '@atlaskit/icon/glyph/edit-filled')),
  'editor-add': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-add" */ '@atlaskit/icon/glyph/editor/add')),
  'editor-addon': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-addon" */ '@atlaskit/icon/glyph/editor/addon')),
  'editor-advanced': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-advanced" */ '@atlaskit/icon/glyph/editor/advanced')),
  'editor-align-center': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-align-center" */ '@atlaskit/icon/glyph/editor/align-center')),
  'editor-align-image-center': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-align-image-center" */ '@atlaskit/icon/glyph/editor/align-image-center')),
  'editor-align-image-left': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-align-image-left" */ '@atlaskit/icon/glyph/editor/align-image-left')),
  'editor-align-image-right': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-align-image-right" */ '@atlaskit/icon/glyph/editor/align-image-right')),
  'editor-align-left': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-align-left" */ '@atlaskit/icon/glyph/editor/align-left')),
  'editor-align-right': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-align-right" */ '@atlaskit/icon/glyph/editor/align-right')),
  'editor-attachment': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-attachment" */ '@atlaskit/icon/glyph/editor/attachment')),
  'editor-background-color': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-background-color" */ '@atlaskit/icon/glyph/editor/background-color')),
  'editor-bold': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-bold" */ '@atlaskit/icon/glyph/editor/bold')),
  'editor-bullet-list': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-bullet-list" */ '@atlaskit/icon/glyph/editor/bullet-list')),
  'editor-close': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-close" */ '@atlaskit/icon/glyph/editor/close')),
  'editor-code': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-code" */ '@atlaskit/icon/glyph/editor/code')),
  'editor-collapse': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-collapse" */ '@atlaskit/icon/glyph/editor/collapse')),
  'editor-date': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-date" */ '@atlaskit/icon/glyph/editor/date')),
  'editor-decision': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-decision" */ '@atlaskit/icon/glyph/editor/decision')),
  'editor-divider': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-divider" */ '@atlaskit/icon/glyph/editor/divider')),
  'editor-done': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-done" */ '@atlaskit/icon/glyph/editor/done')),
  'editor-edit': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-edit" */ '@atlaskit/icon/glyph/editor/edit')),
  'editor-emoji': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-emoji" */ '@atlaskit/icon/glyph/editor/emoji')),
  'editor-error': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-error" */ '@atlaskit/icon/glyph/editor/error')),
  'editor-expand': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-expand" */ '@atlaskit/icon/glyph/editor/expand')),
  'editor-feedback': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-feedback" */ '@atlaskit/icon/glyph/editor/feedback')),
  'editor-file': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-file" */ '@atlaskit/icon/glyph/editor/file')),
  'editor-file-preview': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-file-preview" */ '@atlaskit/icon/glyph/editor/file-preview')),
  'editor-help': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-help" */ '@atlaskit/icon/glyph/editor/help')),
  'editor-hint': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-hint" */ '@atlaskit/icon/glyph/editor/hint')),
  'editor-horizontal-rule': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-horizontal-rule" */ '@atlaskit/icon/glyph/editor/horizontal-rule')),
  'editor-image': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-image" */ '@atlaskit/icon/glyph/editor/image')),
  'editor-image-border': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-image-border" */ '@atlaskit/icon/glyph/editor/image-border')),
  'editor-image-resize': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-image-resize" */ '@atlaskit/icon/glyph/editor/image-resize')),
  'editor-indent': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-indent" */ '@atlaskit/icon/glyph/editor/indent')),
  'editor-info': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-info" */ '@atlaskit/icon/glyph/editor/info')),
  'editor-italic': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-italic" */ '@atlaskit/icon/glyph/editor/italic')),
  'editor-layout-single': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-layout-single" */ '@atlaskit/icon/glyph/editor/layout-single')),
  'editor-layout-three-equal': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-layout-three-equal" */ '@atlaskit/icon/glyph/editor/layout-three-equal')),
  'editor-layout-three-with-sidebars': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-layout-three-with-sidebars" */ '@atlaskit/icon/glyph/editor/layout-three-with-sidebars')),
  'editor-layout-two-equal': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-layout-two-equal" */ '@atlaskit/icon/glyph/editor/layout-two-equal')),
  'editor-layout-two-left-sidebar': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-layout-two-left-sidebar" */ '@atlaskit/icon/glyph/editor/layout-two-left-sidebar')),
  'editor-layout-two-right-sidebar': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-layout-two-right-sidebar" */ '@atlaskit/icon/glyph/editor/layout-two-right-sidebar')),
  'editor-link': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-link" */ '@atlaskit/icon/glyph/editor/link')),
  'editor-media-center': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-media-center" */ '@atlaskit/icon/glyph/editor/media-center')),
  'editor-media-full-width': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-media-full-width" */ '@atlaskit/icon/glyph/editor/media-full-width')),
  'editor-media-wide': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-media-wide" */ '@atlaskit/icon/glyph/editor/media-wide')),
  'editor-media-wrap-left': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-media-wrap-left" */ '@atlaskit/icon/glyph/editor/media-wrap-left')),
  'editor-media-wrap-right': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-media-wrap-right" */ '@atlaskit/icon/glyph/editor/media-wrap-right')),
  'editor-mention': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-mention" */ '@atlaskit/icon/glyph/editor/mention')),
  'editor-more': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-more" */ '@atlaskit/icon/glyph/editor/more')),
  'editor-note': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-note" */ '@atlaskit/icon/glyph/editor/note')),
  'editor-number-list': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-number-list" */ '@atlaskit/icon/glyph/editor/number-list')),
  'editor-open': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-open" */ '@atlaskit/icon/glyph/editor/open')),
  'editor-outdent': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-outdent" */ '@atlaskit/icon/glyph/editor/outdent')),
  'editor-panel': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-panel" */ '@atlaskit/icon/glyph/editor/panel')),
  'editor-photo': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-photo" */ '@atlaskit/icon/glyph/editor/photo')),
  'editor-quote': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-quote" */ '@atlaskit/icon/glyph/editor/quote')),
  'editor-recent': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-recent" */ '@atlaskit/icon/glyph/editor/recent')),
  'editor-redo': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-redo" */ '@atlaskit/icon/glyph/editor/redo')),
  'editor-remove': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-remove" */ '@atlaskit/icon/glyph/editor/remove')),
  'editor-remove-emoji': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-remove-emoji" */ '@atlaskit/icon/glyph/editor/remove-emoji')),
  'editor-search': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-search" */ '@atlaskit/icon/glyph/editor/search')),
  'editor-settings': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-settings" */ '@atlaskit/icon/glyph/editor/settings')),
  'editor-strikethrough': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-strikethrough" */ '@atlaskit/icon/glyph/editor/strikethrough')),
  'editor-success': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-success" */ '@atlaskit/icon/glyph/editor/success')),
  'editor-table': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-table" */ '@atlaskit/icon/glyph/editor/table')),
  'editor-table-display-options': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-table-display-options" */ '@atlaskit/icon/glyph/editor/table-display-options')),
  'editor-task': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-task" */ '@atlaskit/icon/glyph/editor/task')),
  'editor-text-color': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-text-color" */ '@atlaskit/icon/glyph/editor/text-color')),
  'editor-text-style': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-text-style" */ '@atlaskit/icon/glyph/editor/text-style')),
  'editor-underline': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-underline" */ '@atlaskit/icon/glyph/editor/underline')),
  'editor-undo': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-undo" */ '@atlaskit/icon/glyph/editor/undo')),
  'editor-unlink': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-unlink" */ '@atlaskit/icon/glyph/editor/unlink')),
  'editor-warning': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--editor-warning" */ '@atlaskit/icon/glyph/editor/warning')),
  'email': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--email" */ '@atlaskit/icon/glyph/email')),
  'emoji': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji" */ '@atlaskit/icon/glyph/emoji')),
  'emoji-add': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-add" */ '@atlaskit/icon/glyph/emoji-add')),
  'emoji-activity': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-activity" */ '@atlaskit/icon/glyph/emoji/activity')),
  'emoji-atlassian': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-atlassian" */ '@atlaskit/icon/glyph/emoji/atlassian')),
  'emoji-custom': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-custom" */ '@atlaskit/icon/glyph/emoji/custom')),
  'emoji-emoji': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-emoji" */ '@atlaskit/icon/glyph/emoji/emoji')),
  'emoji-flags': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-flags" */ '@atlaskit/icon/glyph/emoji/flags')),
  'emoji-food': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-food" */ '@atlaskit/icon/glyph/emoji/food')),
  'emoji-frequent': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-frequent" */ '@atlaskit/icon/glyph/emoji/frequent')),
  'emoji-keyboard': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-keyboard" */ '@atlaskit/icon/glyph/emoji/keyboard')),
  'emoji-nature': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-nature" */ '@atlaskit/icon/glyph/emoji/nature')),
  'emoji-objects': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-objects" */ '@atlaskit/icon/glyph/emoji/objects')),
  'emoji-people': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-people" */ '@atlaskit/icon/glyph/emoji/people')),
  'emoji-productivity': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-productivity" */ '@atlaskit/icon/glyph/emoji/productivity')),
  'emoji-symbols': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-symbols" */ '@atlaskit/icon/glyph/emoji/symbols')),
  'emoji-travel': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--emoji-travel" */ '@atlaskit/icon/glyph/emoji/travel')),
  'error': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--error" */ '@atlaskit/icon/glyph/error')),
  'export': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--export" */ '@atlaskit/icon/glyph/export')),
  'feedback': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--feedback" */ '@atlaskit/icon/glyph/feedback')),
  'file': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--file" */ '@atlaskit/icon/glyph/file')),
  'filter': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--filter" */ '@atlaskit/icon/glyph/filter')),
  'flag-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--flag-filled" */ '@atlaskit/icon/glyph/flag-filled')),
  'folder': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--folder" */ '@atlaskit/icon/glyph/folder')),
  'folder-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--folder-filled" */ '@atlaskit/icon/glyph/folder-filled')),
  'followers': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--followers" */ '@atlaskit/icon/glyph/followers')),
  'following': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--following" */ '@atlaskit/icon/glyph/following')),
  'googledrive': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--googledrive" */ '@atlaskit/icon/glyph/googledrive')),
  'graph-bar': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--graph-bar" */ '@atlaskit/icon/glyph/graph-bar')),
  'graph-line': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--graph-line" */ '@atlaskit/icon/glyph/graph-line')),
  'gsuite': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--gsuite" */ '@atlaskit/icon/glyph/gsuite')),
  'highlights': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--highlights" */ '@atlaskit/icon/glyph/highlights')),
  'hipchat-audio-only': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-audio-only" */ '@atlaskit/icon/glyph/hipchat/audio-only')),
  'hipchat-chevron-double-down': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-chevron-double-down" */ '@atlaskit/icon/glyph/hipchat/chevron-double-down')),
  'hipchat-chevron-double-up': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-chevron-double-up" */ '@atlaskit/icon/glyph/hipchat/chevron-double-up')),
  'hipchat-chevron-down': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-chevron-down" */ '@atlaskit/icon/glyph/hipchat/chevron-down')),
  'hipchat-chevron-up': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-chevron-up" */ '@atlaskit/icon/glyph/hipchat/chevron-up')),
  'hipchat-dial-out': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-dial-out" */ '@atlaskit/icon/glyph/hipchat/dial-out')),
  'hipchat-lobby': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-lobby" */ '@atlaskit/icon/glyph/hipchat/lobby')),
  'hipchat-media-attachment-count': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-media-attachment-count" */ '@atlaskit/icon/glyph/hipchat/media-attachment-count')),
  'hipchat-outgoing-sound': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-outgoing-sound" */ '@atlaskit/icon/glyph/hipchat/outgoing-sound')),
  'hipchat-sd-video': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--hipchat-sd-video" */ '@atlaskit/icon/glyph/hipchat/sd-video')),
  'home': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--home" */ '@atlaskit/icon/glyph/home')),
  'home-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--home-circle" */ '@atlaskit/icon/glyph/home-circle')),
  'image': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--image" */ '@atlaskit/icon/glyph/image')),
  'image-border': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--image-border" */ '@atlaskit/icon/glyph/image-border')),
  'image-resize': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--image-resize" */ '@atlaskit/icon/glyph/image-resize')),
  'info': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--info" */ '@atlaskit/icon/glyph/info')),
  'invite-team': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--invite-team" */ '@atlaskit/icon/glyph/invite-team')),
  'issue': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--issue" */ '@atlaskit/icon/glyph/issue')),
  'issue-raise': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--issue-raise" */ '@atlaskit/icon/glyph/issue-raise')),
  'issues': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--issues" */ '@atlaskit/icon/glyph/issues')),
  'jira-capture': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--jira-capture" */ '@atlaskit/icon/glyph/jira/capture')),
  'jira-failed-build-status': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--jira-failed-build-status" */ '@atlaskit/icon/glyph/jira/failed-build-status')),
  'jira-labs': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--jira-labs" */ '@atlaskit/icon/glyph/jira/labs')),
  'jira-test-session': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--jira-test-session" */ '@atlaskit/icon/glyph/jira/test-session')),
  'label': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--label" */ '@atlaskit/icon/glyph/label')),
  'lightbulb': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--lightbulb" */ '@atlaskit/icon/glyph/lightbulb')),
  'lightbulb-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--lightbulb-filled" */ '@atlaskit/icon/glyph/lightbulb-filled')),
  'like': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--like" */ '@atlaskit/icon/glyph/like')),
  'link': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--link" */ '@atlaskit/icon/glyph/link')),
  'link-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--link-filled" */ '@atlaskit/icon/glyph/link-filled')),
  'list': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--list" */ '@atlaskit/icon/glyph/list')),
  'location': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--location" */ '@atlaskit/icon/glyph/location')),
  'lock': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--lock" */ '@atlaskit/icon/glyph/lock')),
  'lock-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--lock-circle" */ '@atlaskit/icon/glyph/lock-circle')),
  'lock-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--lock-filled" */ '@atlaskit/icon/glyph/lock-filled')),
  'marketplace': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--marketplace" */ '@atlaskit/icon/glyph/marketplace')),
  'media-services-actual-size': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-actual-size" */ '@atlaskit/icon/glyph/media-services/actual-size')),
  'media-services-add-comment': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-add-comment" */ '@atlaskit/icon/glyph/media-services/add-comment')),
  'media-services-annotate': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-annotate" */ '@atlaskit/icon/glyph/media-services/annotate')),
  'media-services-arrow': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-arrow" */ '@atlaskit/icon/glyph/media-services/arrow')),
  'media-services-audio': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-audio" */ '@atlaskit/icon/glyph/media-services/audio')),
  'media-services-blur': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-blur" */ '@atlaskit/icon/glyph/media-services/blur')),
  'media-services-brush': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-brush" */ '@atlaskit/icon/glyph/media-services/brush')),
  'media-services-button-option': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-button-option" */ '@atlaskit/icon/glyph/media-services/button-option')),
  'media-services-code': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-code" */ '@atlaskit/icon/glyph/media-services/code')),
  'media-services-document': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-document" */ '@atlaskit/icon/glyph/media-services/document')),
  'media-services-filter': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-filter" */ '@atlaskit/icon/glyph/media-services/filter')),
  'media-services-fit-to-page': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-fit-to-page" */ '@atlaskit/icon/glyph/media-services/fit-to-page')),
  'media-services-full-screen': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-full-screen" */ '@atlaskit/icon/glyph/media-services/full-screen')),
  'media-services-grid': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-grid" */ '@atlaskit/icon/glyph/media-services/grid')),
  'media-services-image': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-image" */ '@atlaskit/icon/glyph/media-services/image')),
  'media-services-line': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-line" */ '@atlaskit/icon/glyph/media-services/line')),
  'media-services-line-thickness': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-line-thickness" */ '@atlaskit/icon/glyph/media-services/line-thickness')),
  'media-services-no-image': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-no-image" */ '@atlaskit/icon/glyph/media-services/no-image')),
  'media-services-open-mediaviewer': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-open-mediaviewer" */ '@atlaskit/icon/glyph/media-services/open-mediaviewer')),
  'media-services-oval': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-oval" */ '@atlaskit/icon/glyph/media-services/oval')),
  'media-services-pdf': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-pdf" */ '@atlaskit/icon/glyph/media-services/pdf')),
  'media-services-preselected': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-preselected" */ '@atlaskit/icon/glyph/media-services/preselected')),
  'media-services-presentation': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-presentation" */ '@atlaskit/icon/glyph/media-services/presentation')),
  'media-services-rectangle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-rectangle" */ '@atlaskit/icon/glyph/media-services/rectangle')),
  'media-services-scale-large': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-scale-large" */ '@atlaskit/icon/glyph/media-services/scale-large')),
  'media-services-scale-small': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-scale-small" */ '@atlaskit/icon/glyph/media-services/scale-small')),
  'media-services-spreadsheet': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-spreadsheet" */ '@atlaskit/icon/glyph/media-services/spreadsheet')),
  'media-services-text': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-text" */ '@atlaskit/icon/glyph/media-services/text')),
  'media-services-unknown': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-unknown" */ '@atlaskit/icon/glyph/media-services/unknown')),
  'media-services-video': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-video" */ '@atlaskit/icon/glyph/media-services/video')),
  'media-services-zip': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-zip" */ '@atlaskit/icon/glyph/media-services/zip')),
  'media-services-zoom-in': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-zoom-in" */ '@atlaskit/icon/glyph/media-services/zoom-in')),
  'media-services-zoom-out': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--media-services-zoom-out" */ '@atlaskit/icon/glyph/media-services/zoom-out')),
  'mention': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--mention" */ '@atlaskit/icon/glyph/mention')),
  'menu': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--menu" */ '@atlaskit/icon/glyph/menu')),
  'menu-expand': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--menu-expand" */ '@atlaskit/icon/glyph/menu-expand')),
  'mobile': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--mobile" */ '@atlaskit/icon/glyph/mobile')),
  'more': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--more" */ '@atlaskit/icon/glyph/more')),
  'more-vertical': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--more-vertical" */ '@atlaskit/icon/glyph/more-vertical')),
  'notification': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--notification" */ '@atlaskit/icon/glyph/notification')),
  'notification-all': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--notification-all" */ '@atlaskit/icon/glyph/notification-all')),
  'notification-direct': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--notification-direct" */ '@atlaskit/icon/glyph/notification-direct')),
  'office-building': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--office-building" */ '@atlaskit/icon/glyph/office-building')),
  'office-building-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--office-building-filled" */ '@atlaskit/icon/glyph/office-building-filled')),
  'open': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--open" */ '@atlaskit/icon/glyph/open')),
  'overview': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--overview" */ '@atlaskit/icon/glyph/overview')),
  'page': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--page" */ '@atlaskit/icon/glyph/page')),
  'page-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--page-filled" */ '@atlaskit/icon/glyph/page-filled')),
  'pdf': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--pdf" */ '@atlaskit/icon/glyph/pdf')),
  'people': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--people" */ '@atlaskit/icon/glyph/people')),
  'people-group': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--people-group" */ '@atlaskit/icon/glyph/people-group')),
  'person': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--person" */ '@atlaskit/icon/glyph/person')),
  'person-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--person-circle" */ '@atlaskit/icon/glyph/person-circle')),
  'person-with-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--person-with-circle" */ '@atlaskit/icon/glyph/person-with-circle')),
  'person-with-cross': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--person-with-cross" */ '@atlaskit/icon/glyph/person-with-cross')),
  'person-with-tick': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--person-with-tick" */ '@atlaskit/icon/glyph/person-with-tick')),
  'portfolio': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--portfolio" */ '@atlaskit/icon/glyph/portfolio')),
  'preferences': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--preferences" */ '@atlaskit/icon/glyph/preferences')),
  'premium': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--premium" */ '@atlaskit/icon/glyph/premium')),
  'presence-active': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--presence-active" */ '@atlaskit/icon/glyph/presence-active')),
  'presence-busy': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--presence-busy" */ '@atlaskit/icon/glyph/presence-busy')),
  'presence-unavailable': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--presence-unavailable" */ '@atlaskit/icon/glyph/presence-unavailable')),
  'question': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--question" */ '@atlaskit/icon/glyph/question')),
  'question-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--question-circle" */ '@atlaskit/icon/glyph/question-circle')),
  'questions': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--questions" */ '@atlaskit/icon/glyph/questions')),
  'queues': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--queues" */ '@atlaskit/icon/glyph/queues')),
  'quote': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--quote" */ '@atlaskit/icon/glyph/quote')),
  'radio': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--radio" */ '@atlaskit/icon/glyph/radio')),
  'recent': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--recent" */ '@atlaskit/icon/glyph/recent')),
  'redo': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--redo" */ '@atlaskit/icon/glyph/redo')),
  'refresh': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--refresh" */ '@atlaskit/icon/glyph/refresh')),
  'retry': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--retry" */ '@atlaskit/icon/glyph/retry')),
  'roadmap': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--roadmap" */ '@atlaskit/icon/glyph/roadmap')),
  'room-menu': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--room-menu" */ '@atlaskit/icon/glyph/room-menu')),
  'schedule': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--schedule" */ '@atlaskit/icon/glyph/schedule')),
  'schedule-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--schedule-filled" */ '@atlaskit/icon/glyph/schedule-filled')),
  'screen': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--screen" */ '@atlaskit/icon/glyph/screen')),
  'search': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--search" */ '@atlaskit/icon/glyph/search')),
  'select-clear': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--select-clear" */ '@atlaskit/icon/glyph/select-clear')),
  'send': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--send" */ '@atlaskit/icon/glyph/send')),
  'settings': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--settings" */ '@atlaskit/icon/glyph/settings')),
  'share': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--share" */ '@atlaskit/icon/glyph/share')),
  'ship': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--ship" */ '@atlaskit/icon/glyph/ship')),
  'shortcut': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--shortcut" */ '@atlaskit/icon/glyph/shortcut')),
  'sign-in': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--sign-in" */ '@atlaskit/icon/glyph/sign-in')),
  'sign-out': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--sign-out" */ '@atlaskit/icon/glyph/sign-out')),
  'sprint': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--sprint" */ '@atlaskit/icon/glyph/sprint')),
  'star': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--star" */ '@atlaskit/icon/glyph/star')),
  'star-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--star-filled" */ '@atlaskit/icon/glyph/star-filled')),
  'star-large': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--star-large" */ '@atlaskit/icon/glyph/star-large')),
  'status': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--status" */ '@atlaskit/icon/glyph/status')),
  'stopwatch': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--stopwatch" */ '@atlaskit/icon/glyph/stopwatch')),
  'subtask': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--subtask" */ '@atlaskit/icon/glyph/subtask')),
  'suitcase': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--suitcase" */ '@atlaskit/icon/glyph/suitcase')),
  'switcher': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--switcher" */ '@atlaskit/icon/glyph/switcher')),
  'table': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--table" */ '@atlaskit/icon/glyph/table')),
  'task': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--task" */ '@atlaskit/icon/glyph/task')),
  'teams': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--teams" */ '@atlaskit/icon/glyph/teams')),
  'trash': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--trash" */ '@atlaskit/icon/glyph/trash')),
  'tray': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--tray" */ '@atlaskit/icon/glyph/tray')),
  'undo': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--undo" */ '@atlaskit/icon/glyph/undo')),
  'unlink': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--unlink" */ '@atlaskit/icon/glyph/unlink')),
  'unlock': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--unlock" */ '@atlaskit/icon/glyph/unlock')),
  'unlock-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--unlock-circle" */ '@atlaskit/icon/glyph/unlock-circle')),
  'unlock-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--unlock-filled" */ '@atlaskit/icon/glyph/unlock-filled')),
  'upload': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--upload" */ '@atlaskit/icon/glyph/upload')),
  'user-avatar-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--user-avatar-circle" */ '@atlaskit/icon/glyph/user-avatar-circle')),
  'vid-audio-muted': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-audio-muted" */ '@atlaskit/icon/glyph/vid-audio-muted')),
  'vid-audio-on': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-audio-on" */ '@atlaskit/icon/glyph/vid-audio-on')),
  'vid-backward': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-backward" */ '@atlaskit/icon/glyph/vid-backward')),
  'vid-camera-off': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-camera-off" */ '@atlaskit/icon/glyph/vid-camera-off')),
  'vid-camera-on': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-camera-on" */ '@atlaskit/icon/glyph/vid-camera-on')),
  'vid-connection-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-connection-circle" */ '@atlaskit/icon/glyph/vid-connection-circle')),
  'vid-forward': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-forward" */ '@atlaskit/icon/glyph/vid-forward')),
  'vid-full-screen-off': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-full-screen-off" */ '@atlaskit/icon/glyph/vid-full-screen-off')),
  'vid-full-screen-on': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-full-screen-on" */ '@atlaskit/icon/glyph/vid-full-screen-on')),
  'vid-hang-up': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-hang-up" */ '@atlaskit/icon/glyph/vid-hang-up')),
  'vid-hd-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-hd-circle" */ '@atlaskit/icon/glyph/vid-hd-circle')),
  'vid-pause': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-pause" */ '@atlaskit/icon/glyph/vid-pause')),
  'vid-play': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-play" */ '@atlaskit/icon/glyph/vid-play')),
  'vid-raised-hand': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-raised-hand" */ '@atlaskit/icon/glyph/vid-raised-hand')),
  'vid-share-screen': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-share-screen" */ '@atlaskit/icon/glyph/vid-share-screen')),
  'vid-speaking-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-speaking-circle" */ '@atlaskit/icon/glyph/vid-speaking-circle')),
  'vid-volume-full': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-volume-full" */ '@atlaskit/icon/glyph/vid-volume-full')),
  'vid-volume-half': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-volume-half" */ '@atlaskit/icon/glyph/vid-volume-half')),
  'vid-volume-muted': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--vid-volume-muted" */ '@atlaskit/icon/glyph/vid-volume-muted')),
  'video-circle': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--video-circle" */ '@atlaskit/icon/glyph/video-circle')),
  'video-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--video-filled" */ '@atlaskit/icon/glyph/video-filled')),
  'warning': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--warning" */ '@atlaskit/icon/glyph/warning')),
  'watch': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--watch" */ '@atlaskit/icon/glyph/watch')),
  'watch-filled': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--watch-filled" */ '@atlaskit/icon/glyph/watch-filled')),
  'world': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--world" */ '@atlaskit/icon/glyph/world')),
  'world-small': lazy(() => import(/* webpackChunkName: "@atlaskit-internal_forge-icons--world-small" */ '@atlaskit/icon/glyph/world-small')),
};
