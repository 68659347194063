import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';

interface UseViewUserProfileData {
	canViewUserProfile: boolean;
}

export const useViewUserProfile = (): UseViewUserProfileData => {
	const { isExternalCollaborator } = useIsExternalCollaborator();
	return { canViewUserProfile: !isExternalCollaborator };
};
