import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

const PublicLinksSettingsPageRouteComponent = LoadableAfterPaint({
	loader: async () =>
		import(
			/* webpackChunkName: "loadable-PublicLinksSettingsPageRouteComponent" */ './PublicLinksSettingsPageRouteComponent'
		),
});

export const PublicLinksSettingsPageRoute = () => <PublicLinksSettingsPageRouteComponent />;

PublicLinksSettingsPageRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'publicLinksSettingsScreen',
		},
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
