import { LoadableBackground } from '@confluence/loadable';

export const CreateContentDataPreloader = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CreateContentDataPreloader" */ '../src/CreateContentDataPreloader'
			)
		).CreateContentDataPreloader,
});
