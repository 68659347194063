/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { type PropsWithChildren } from 'react';
import { token } from '@atlaskit/tokens';
import { type Props } from '../..';

const styles = css({
	width: 'inherit',
});
const COMPONENT_TOP_MARGIN = token('space.150', '12px');

function applyTopMargin(element: React.ReactNode) {
	return (
		<div
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				marginTop: COMPONENT_TOP_MARGIN,
				width: 'inherit',
			})}
		>
			{element}
		</div>
	);
}

const View = ({
	children,
	nonVisibleChildren,
}: PropsWithChildren<{ nonVisibleChildren: React.ReactNode }>) => {
	return (
		<div
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				display: 'flex',
				background: 'transparent',
				flexDirection: 'column',
				padding: 0,
				position: 'relative',
				overflow: 'hidden',
				alignItems: 'flex-start',
				width: '100%',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'> *': {
					marginBottom: 0,
				},
			})}
		>
			{React.Children.map(children, (child, index) =>
				index ? applyTopMargin(child) : <div css={styles}>{child}</div>,
			)}
			{nonVisibleChildren}
		</div>
	);
};

export default View;

export const ViewFn: React.FunctionComponent<Props> = ({
	children,
	dispatch,
	Components,
	render,
	renderChildren,
}) => {
	const isChildVisible = (type: string) => !['ModalDialog'].includes(type);
	const nonVisibleChildren = children.filter(({ type }) => !isChildVisible(type));
	const visibleChildren = children.filter(({ type }) => isChildVisible(type));
	return (
		<View
			nonVisibleChildren={renderChildren({
				children: nonVisibleChildren,
				dispatch,
				Components,
				render,
			})}
		>
			{renderChildren({
				children: visibleChildren,
				dispatch,
				Components,
				render,
			})}
		</View>
	);
};
