import React from 'react';

import { CALENDAR_CONTEXT } from '@confluence/team-calendar/entry-points/TeamCalendars';

import { LazyTeamCalendars } from '../lazyComponents';

export function MyCalendarRoute() {
	return <LazyTeamCalendars calendarContext={CALENDAR_CONTEXT.MY_CALENDARS} />;
}

MyCalendarRoute.NAVIGATION_PARAMS = ({ spaceKey }) => ({
	Screen: {
		screenEvent: {
			name: 'myCalendarsScreen',
			id: spaceKey,
		},
		pageState: { spaceKey },
	},

	MainLayout: {
		spaceKey,
	},
});
