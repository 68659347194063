import React, { type FunctionComponent, useEffect } from 'react';

type OnUnmount = () => void;
type OnMount = () => void | OnUnmount;

type TrackMountProps = {
	onMount: OnMount;
};

/**
 * Utility component to track when a component is mounted and unmounted.
 */
export const TrackMount: FunctionComponent<React.PropsWithChildren<TrackMountProps>> = ({
	onMount,
	children,
}) => {
	useEffect(() => {
		const onUnmount = onMount();
		return onUnmount;
	}, []);

	return <>{children}</>;
};
