import { LoadableLazy } from '@confluence/loadable';

export const ClassicEditorContextProviderLazyLoader = LoadableLazy({
	name: 'ClassicEditorContextProviderLazyLoader',

	loader: () =>
		import(
			/* webpackChunkName: "loadable-ClassicEditorContextProvider" */ './ClassicEditorContextProvider'
		),
});
