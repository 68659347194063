import memoize from 'memoize-one';

import type { PreloadFailureResult } from './fabric-extension-lib-types';

type MacroData = { extensionKey?: string; reason: string };

export const macrosWithMissingData = new Map<string, string>();
export let preloadFailedMacros: {
	value: Record<string, MacroData> | undefined;
} | null = null;

const getListOfPreloadedMacrosFromApolloState = memoize(() => {
	const rootQueryList = window?.__APOLLO_STATE__?.ROOT_QUERY || {};
	return Object.keys(rootQueryList).filter((query) => query.startsWith('macroBodyRenderer'));
});

function isMacroPreloaded(macroId: string) {
	const preloadedMacros = getListOfPreloadedMacrosFromApolloState();
	return preloadedMacros.some((preloaded) => preloaded.includes(macroId));
}

export const macrosPreloadPerformed = () =>
	window.__SSR_RENDERED__ && !!window?.__SSR_MACRO_PRELOAD_TOPX_FAILS__;

function getPreloadFailedMacrosImpl() {
	const cacheMismatches = getPreloadCacheMismatches();
	const reportedFails = (window?.__SSR_MACRO_PRELOAD_TOPX_FAILS__ || {}) as PreloadFailureResult;
	if (
		cacheMismatches.length == 0 &&
		(!reportedFails || typeof reportedFails !== 'object' || Object.keys(reportedFails).length === 0)
	) {
		return;
	}

	const failedMacros: Record<string, MacroData> = {};

	function addFailed(reason: string, macroId: string, extensionKey: string) {
		failedMacros[macroId] = { reason, extensionKey };
	}

	Object.entries(reportedFails).forEach(([keyAndId, reason]) => {
		if (keyAndId === 'general') {
			return;
		}
		const [extensionKey, macroId] = keyAndId.split(':');
		if (reason === 'timeout') {
			if (isMacroPreloaded(macroId)) {
				addFailed('falseTimeouts', macroId, extensionKey);
			} else {
				addFailed('trueTimeouts', macroId, extensionKey);
			}
		} else {
			addFailed(reason, macroId, extensionKey);
		}
	});

	cacheMismatches.forEach(([macroId, extensionKey]) =>
		addFailed('cacheMismatch', macroId, extensionKey),
	);

	return failedMacros;
}

export function getPreloadFailedMacros() {
	if (!preloadFailedMacros) {
		preloadFailedMacros = { value: getPreloadFailedMacrosImpl() };
	}
	return preloadFailedMacros.value;
}

export function resetPreloadFailedMacros() {
	preloadFailedMacros = null;
}

export function getPreloadFailures(macroId: string) {
	const failedMacros = getPreloadFailedMacros();
	return failedMacros?.[macroId]?.reason;
}

export function getGeneralFailures() {
	const reportedFails = (window?.__SSR_MACRO_PRELOAD_TOPX_FAILS__ || {}) as PreloadFailureResult;
	if (
		!reportedFails ||
		typeof reportedFails !== 'object' ||
		Object.keys(reportedFails).length === 0
	) {
		return;
	}
	return reportedFails?.['general'];
}

export function markMissingMacroData(macroId: string, extensionKey?: string) {
	preloadFailedMacros = null;
	macrosWithMissingData.set(macroId, extensionKey || '');
}

function getPreloadCacheMismatches() {
	return [...macrosWithMissingData].filter(([macroId]) => isMacroPreloaded(macroId));
}
