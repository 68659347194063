import { ACTIONS } from './states';
import type { AISpellingGrammarPluginState, SpellingGrammarAction } from './states';

export const reducer = (
	pluginState: AISpellingGrammarPluginState,
	action: SpellingGrammarAction,
): AISpellingGrammarPluginState => {
	switch (action.type) {
		case ACTIONS.UPDATE_PLUGIN_STATE:
			return {
				...pluginState,
				...action.data,
			};
		case ACTIONS.TOGGLE_SPELLING_AND_GRAMMAR_ENABLED:
			return {
				...pluginState,
				toggleCount: action.data.toggleCount,
				isSpellingAndGrammarEnabled: !pluginState.isSpellingAndGrammarEnabled,
				...(pluginState.isSpellingAndGrammarEnabled && {
					docChangeCountUntilLimit: 0,
				}),
			};
		case ACTIONS.DISABLE_NEED_SPELLING_AND_GRAMMAR:
			return {
				...pluginState,
				proactiveAIBlocks: pluginState.proactiveAIBlocks?.map((block) => {
					if (pluginState.splitParagraphIntoSentences) {
						return {
							...block,
							sentences: block.sentences?.map((sentence) => {
								if (action.data.skippedChunkIds.includes(sentence.id)) {
									return {
										...sentence,
										needSpellingAndGrammarCheck: false,
										needSGCheckForDocChecker: false,
									};
								}
								return sentence;
							}),
						};
					}

					if (action.data.skippedChunkIds.includes(block.id)) {
						return {
							...block,
							needSpellingAndGrammarCheck: false,
							needSGCheckForDocChecker: false,
						};
					}
					return block;
				}),
			};
		case ACTIONS.UPDATE_CHUNK_METADATA:
			return {
				...pluginState,
				proactiveAIBlocks: pluginState.proactiveAIBlocks?.map((block) => {
					if (pluginState.splitParagraphIntoSentences) {
						return {
							...block,
							sentences: block.sentences?.map((sentence) => {
								if (action.data.chunkId === sentence.id) {
									return {
										...sentence,
										metadata: action.data.metadata,
									};
								}
								return sentence;
							}),
						};
					}

					if (action.data.chunkId === block.id) {
						return {
							...block,
							metadata: action.data.metadata,
						};
					}
					return block;
				}),
			};
		case ACTIONS.START_SPELLING_AND_GRAMMAR:
			return {
				...pluginState,
				isSpellingAndGrammarEnabled: true,
				toggleCount: pluginState.isSpellingAndGrammarEnabled
					? pluginState.toggleCount
					: pluginState.toggleCount + 1,
			};
		default:
			return pluginState;
	}
};
