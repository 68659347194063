/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { lazy } from 'react';
import { type CodeProps } from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { useInlineContext } from '../../../context/inline';

const styles = css({
	width: "'100%'",
});
const AKCodeBlock = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.code' */
		'@atlaskit/code'
	)
		.then((module) => ({
			AkCode: module.Code,
			AkCodeBlock: module.CodeBlock,
		}))
		.then((module) => ({
			default: module.AkCodeBlock,
		})),
);

const AKCodeInline = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.code' */
		'@atlaskit/code'
	)
		.then((module) => ({
			AkCode: module.Code,
			AkCodeBlock: module.CodeBlock,
		}))
		.then((module) => ({
			default: module.AkCode,
		})),
);

const Code: React.FunctionComponent<CodeProps> = ({
	text,
	language = 'text',
	showLineNumbers = true,
}) => {
	const { inline } = useInlineContext();
	return inline ? (
		<AKCodeInline>{text}</AKCodeInline>
	) : (
		<div css={styles}>
			<AKCodeBlock text={text} language={language} showLineNumbers={showLineNumbers} />
		</div>
	);
};

export default Code;

export const CodeFn: React.FunctionComponent<Props> = ({ props }) => {
	const { text, language, showLineNumbers } = props as CodeProps;

	return <Code text={text} language={language} showLineNumbers={showLineNumbers} />;
};
