import { waitForGlobal } from './waitForGlobal';

export function isRequiredModuleLoaded(module: string) {
	try {
		window.require(module);
		return true;
	} catch (err) {
		return false;
	}
}

export function waitForRequiredModule(
	{ listener, module, interval = 250 }: { listener: string; module: string; interval?: number },
	callback: () => void,
) {
	waitForGlobal(
		{
			listener: `wait-for-required-module ${module}`,
			globalProperty: 'require',
		},
		() => {
			if (isRequiredModuleLoaded(module)) {
				callback();
				return;
			}

			const waitForRequiredModuleInterval = setInterval(() => {
				if (process.env.NODE_ENV !== 'production') {
					// eslint-disable-next-line no-console
					console.info(`${listener} is waiting for global module ${module} to be available`);
				}

				if (isRequiredModuleLoaded(module)) {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line no-console
						console.info(`${module} now available!`);
					}

					clearInterval(waitForRequiredModuleInterval);
					callback();
				}
			}, interval);
		},
	);
}
