import { getMonitoringClient } from '@confluence/monitoring';

import type { FeatureFlagsType } from './SessionDataTypes';

export interface RolloutType {
	[key: string]: {
		rolloutFF: string;
	};
}

let rolloutPlanOverride: RolloutType = {
	// userIds ending with certain characters will have the resulting ff enabled
};

export const _testOnlySetFFOverrides = (rolloutPlans: RolloutType) => {
	rolloutPlanOverride = rolloutPlans;
};

/*
 ** For the purpose of rolling out to users with ids ending in certain values
 *  and for specific users
 */
export const processRolloutFeatureFlags = (
	featureFlags: FeatureFlagsType,
	userId?: string | null,
) => {
	for (const ff in rolloutPlanOverride) {
		const optionsString = featureFlags[rolloutPlanOverride[ff].rolloutFF];

		if (typeof optionsString === 'string') {
			try {
				const options = JSON.parse(optionsString);

				if (userId) {
					featureFlags[ff] =
						featureFlags[ff] &&
						(options.enabledLastDigits.includes(userId.charAt(userId.length - 1)) ||
							options.enabledUserIds?.includes(userId));
				} else {
					featureFlags[ff] = featureFlags[ff] && options.includeAnonUsers;
				}
			} catch (err) {
				if (
					process.env.CLOUD_ENV === 'staging' ||
					process.env.CLOUD_ENV === 'hello' ||
					process.env.CLOUD_ENV === 'branch'
				) {
					// eslint-disable-next-line no-console
					console.error(`Failed to parse options from rollout featureflag`, err);
				}
				getMonitoringClient().submitError(err, { attribution: 'backbone' });
			}
		}
	}
};
