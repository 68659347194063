import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { FlagsStateContainer } from './FlagsStateContainer';
import type { FlagsComponentProps } from './FlagsComponent';
import { FlagsComponent } from './FlagsComponent';

export type FlagsProps = Omit<
	FlagsComponentProps,
	'flags' | 'showFlag' | 'hideFlag' | 'areFlagsVisible'
>;

export const Flags = (props: FlagsProps) => (
	<Subscribe to={[FlagsStateContainer]}>
		{(flags: FlagsStateContainer) => (
			<FlagsComponent
				flags={flags.state.flags}
				areFlagsVisible={flags.state.areFlagsVisible}
				showFlag={flags.showFlag}
				hideFlag={flags.hideFlag}
				{...props}
			/>
		)}
	</Subscribe>
);
