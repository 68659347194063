export const ADMIN_CONFIGURATION_ANALYTICS_SOURCE = 'adminConfiguration';

export const WIKI_LEARN_MORE_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/insert-confluence-wiki-markup/';

export const CONVERT_PAGES_LEARN_MORE_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/convert-pages-to-the-new-editor/';

export const DATE_TIME_FORMAT_LEARN_MORE_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/configure-time-and-date-formats/';

export const LANGUAGE_SUPPORT_DOC = 'https://confluence.atlassian.com/x/A41jL';
