import { LoadablePaint, LoadableAfterPaint } from '@confluence/loadable';
// eslint-disable-next-line no-restricted-imports
import type { LoadableType } from '@confluence/loadable/src/types';

import type { LiveSearchInterface } from './LiveSearch';

// NOTE: this loader should have an AFTER_PAINT priority, however it had to be bumped to PAINT
// to avoid creating conditions for the bug https://product-fabric.atlassian.net/browse/CCP-1371

export const LiveSearchLoader: LoadableType<LiveSearchInterface> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LiveSearch" */ './LiveSearch')).LiveSearch,
});

export const LazyLiveSearchLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LiveSearch" */ './LiveSearch')).LiveSearch,
});
