import { adaptEventHandler } from '../../../utils';
import type { OnCloseHandler } from '@atlaskit/modal-dialog';

/**
 * To simplify API surface and maintain consistent behaviour, the following props are excluded:
 * - onStackChange
 * - stackIndex
 * - isBlanketHidden
 * - shouldCloseOnOverlayClick
 * - shouldCloseOnEscapePress
 * - shouldReturnFocus
 * - onCloseComplete - behaviour can be replicated using `useEffect`
 * - onOpenComplete - behaviour can be replicated using `useEffect`
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::0a2ed5d4cfb4aa57032be0f7ede2001b>>
 * @codegenId #modal
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal
 * @codegenParams { "props": { "autoFocus": true, "children": true, "height": true, "width": true, "onClose": {"value": "adaptEventHandler(onClose) as OnCloseHandler"}, "onCloseComplete": false, "onOpenComplete": false, "onStackChange": false, "shouldScrollInViewport": true, "shouldCloseOnOverlayClick": false, "shouldCloseOnEscapePress": false, "shouldReturnFocus": false, "isBlanketHidden":false, "stackIndex": false, "label": true, "testId":true } }
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/types.tsx <<SignedSource::69859d598b31939a4be80d243c9fef7e>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/components/modal-dialog.tsx <<SignedSource::ed8af851658eb1cc59f2ca8a62084d8a>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-modal-stack.tsx <<SignedSource::05c4d793d0fb10d792e61cb51c9606e3>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-prevent-programmatic-scroll.tsx <<SignedSource::a2862cc0dc40c0cf4e015bf386675b17>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-wrapper.tsx <<SignedSource::276a5bdb79bc088c8321cebe9da0acd7>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformModalDialog from '@atlaskit/modal-dialog';

type PlatformModalDialogProps = React.ComponentProps<typeof PlatformModalDialog>;

export type ModalProps = Pick<
  PlatformModalDialogProps,
  'autoFocus' | 'children' | 'height' | 'width' | 'onClose' | 'shouldScrollInViewport' | 'label' | 'testId'
>;

export const Modal = (props: Parameters<RenderFn>[0]) => {
  const {
    autoFocus,
    height,
    width,
    onClose,
    shouldScrollInViewport,
    label,
    testId,
  } = props.forgeDoc.props as ModalProps;
  return (
    <PlatformModalDialog
      autoFocus={autoFocus}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      height={height}
      width={width}
      onClose={adaptEventHandler(onClose) as OnCloseHandler}
      shouldScrollInViewport={shouldScrollInViewport}
      label={label}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
