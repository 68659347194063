import type { TrackFeatureFlagOptions } from '@atlaskit/feature-flag-client/types';

import { useSessionData } from './useSessionData';
import { useTrackFeatureFlag } from './useTrackFeatureFlag';

export const useBooleanFeatureFlag = (
	flagName: string,
	options?: TrackFeatureFlagOptions,
): boolean => {
	const sessionData = useSessionData();

	useTrackFeatureFlag(flagName, options);
	return Boolean(sessionData?.featureFlags?.[flagName]);
};
