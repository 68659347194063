import gql from 'graphql-tag';
import {
	ONBOARDING_EXPERIENCES,
	TEMPLATES_COLLECTION,
} from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

const queryString = `
		query TemplatesCollectionEligibleQuery {
		onboardingState(
			key: ${JSON.stringify(
				Object.values({
					...TEMPLATES_COLLECTION,
					...ONBOARDING_EXPERIENCES,
				}),
			)}
		) {
			key
			value
		}
		tenantContext {
			licensedProducts {
				licenseStatus
				productKey
			}
		}
		user {
			id
			team
			confluence {
				hasPersonalSpace
				space {
					id
					key
				}
			}
		}
	}
`;

export const TemplatesCollectionEligibleQuery = gql(queryString);
