import { getPreloadResult } from './PreloadResult';

export const preloadedRestLink = async (
	url: string,
	{
		headers,
		method,
	}: {
		headers?: HeadersInit;
		method: string;
	},
	passThrough: () => Promise<any>,
) => {
	if (!method || method.toUpperCase() !== 'GET') {
		return passThrough();
	}

	return getPreloadResult()
		.claim({ name: url, variables: { method, ...headers } }, (result) => result)
		.then((data) => {
			if (!data) {
				return passThrough();
			}
			return data;
		});
};
