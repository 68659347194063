export enum ActionTypes {
	EDITOR_DRAFT_SAVE_REQUEST = 'EDITOR_DRAFT_SAVE_REQUEST',
	EDITOR_DRAFT_SAVE_SUCCESS = 'EDITOR_DRAFT_SAVE_SUCCESS',
	EDITOR_DRAFT_SAVE_FAILURE = 'EDITOR_DRAFT_SAVE_FAILURE',
	SET_SAVE_DRAFT = 'SET_SAVE_DRAFT',
}

export type DraftActionTypes = {
	type:
		| ActionTypes.EDITOR_DRAFT_SAVE_REQUEST
		| ActionTypes.EDITOR_DRAFT_SAVE_SUCCESS
		| ActionTypes.EDITOR_DRAFT_SAVE_FAILURE
		| ActionTypes.SET_SAVE_DRAFT;
	isSaving?: boolean;
	isError?: boolean;
	message?: {
		statusCode?: number | null;
	};
};
