import gql from 'graphql-tag';

// space pages and space blogs will share the same page card appearance user preference under "globalPageCardAppearancePreference"

export const SpacePagesAppearanceMutation = gql`
	mutation SpacePagesAppearanceMutation($persistenceOption: PagesDisplayPersistenceOption!) {
		updateUserPreferences(
			userPreferences: { globalPageCardAppearancePreference: $persistenceOption }
		) {
			globalPageCardAppearancePreference
		}
	}
`;

export const SpacePagesSortMutation = gql`
	mutation SpacePagesSortMutation(
		$spaceKey: String!
		$field: PagesSortField!
		$order: PagesSortOrder!
	) {
		updateUserPreferences(
			userPreferences: {
				spacePagesSortView: {
					spaceKey: $spaceKey
					spacePagesSortPersistenceOption: { field: $field, order: $order }
				}
			}
		) {
			spacePagesSortView(spaceKey: $spaceKey) {
				field
				order
			}
		}
	}
`;

export const SpacePagesPersistenceQuery = gql`
	query SpacePagesPersistenceQuery($spaceKey: String!) {
		userPreferences {
			globalPageCardAppearancePreference
			spacePagesSortView(spaceKey: $spaceKey) {
				field
				order
			}
		}
	}
`;
