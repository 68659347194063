import AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import { fg } from '@atlaskit/platform-feature-flags';

import { Logger } from '../logger';

export const isSSR: () => boolean = () => {
	const isMissingNecessaryBrowserUtils =
		typeof document === 'undefined' ||
		typeof window === 'undefined' ||
		!Document ||
		!Document.prototype ||
		!Object ||
		!Object.defineProperty;

	const isMissingBooleanFF = !fg;
	const isMissingAnalyticsWebClient = !Boolean(AnalyticsWebClient);

	// When rendering from the server document/window may not be available
	if (isMissingNecessaryBrowserUtils) {
		Logger.warn('Browser context is not available.');
	}
	if (isMissingBooleanFF) {
		Logger.warn('Platform Feature Flag library is not available');
	}

	if (!AnalyticsWebClient) {
		Logger.warn('analytics-web-client is not available');
	}

	if (isMissingNecessaryBrowserUtils || isMissingBooleanFF || isMissingAnalyticsWebClient) {
		return true;
	} else {
		return false;
	}
};
