import uuid from 'uuid/v4';

import {
	aliasType,
	envType,
	eventType,
	groupType,
	isType,
	objectValues,
	originType,
	perimeterType,
	platformType,
	tenantType,
	type userType,
} from './analyticsWebTypes';
import ApdexEvent from './apdexEvent';
import createGetter from './createGetter';
import { buildActionEvent, buildActionName, buildContext, buildScreenEvent } from './eventBuilder';
import EventDelayQueue, { type StopLowPriorityEventDelayReason } from './eventDelay';
import {
	validateContainers,
	validateIdentifyEvent,
	validateOperationalEvent,
	validatePlatform,
	validateScreenEvent,
	validateTrackEvent,
	validateUIEvent,
} from './eventValidation';
import EventProcessor from './integration';
import OriginTracing from './originTracing';
import PageVisibility from './pageVisibility';
import { type Logger } from './resiliencedb';
import { type RetryQueueOptions } from './resilienceQueue';
import { selectHost } from './selectHost';
import SessionTracking from './sessionTracking';
import SafeSessionStorage from './storage/SafeSessionStorage';
import TabTracking from './tabTracking';
import TaskSessionStore from './taskSessionStore';
import TestingCache from './testingCache';
import {
	type Context,
	type InternalProductInfoType,
	type OperationalEventPayload,
	type ProductInfoType,
	type SendScreenEventInput,
	type SettingsType,
	type TrackEventPayload,
	type UIEventPayload,
	type UserInfo,
} from './types';
import UIViewedEvent from './uiViewedEvent';
import { defaultHistoryReplaceFn } from './urlUtils';
import wrapCallback from './wrapCallback';
import { XIDPromise } from './xid';

export const STARGATE_PROXY_PATH = '/gateway/api/gasv3/api/v1';
const LAST_SCREEN_EVENT_STORAGE_KEY = 'last.screen.event';

export default class AnalyticsWebClient {
	_apdexEvent: ApdexEvent;

	_context: Context;

	private eventDelayQueue: EventDelayQueue;

	_historyReplaceFn: any;

	_orgInfo: any;

	_pageVisibility: any;

	_productInfo: InternalProductInfoType;

	_safeSessionStorage: SafeSessionStorage;

	_sessionTracking: SessionTracking;

	_tabTracking: TabTracking;

	_tenantInfo: any;

	_testingCache: TestingCache;

	_uiViewedAttributes: any;

	_uiViewedEvent?: UIViewedEvent | null;

	_userInfo: UserInfo;

	originTracing: OriginTracing;

	task: TaskSessionStore;

	_pageLoadId: string;

	_workspaceInfo: any;

	_aliases: any;

	_groups: any;

	private eventProcessor: EventProcessor;

	private logger: Logger;

	constructor(productInfo: ProductInfoType, settings: SettingsType = {}) {
		if (!productInfo) {
			throw new Error('Missing productInfo');
		}

		if (!productInfo.env) {
			throw new Error('Missing productInfo.env');
		}

		if (!productInfo.product) {
			throw new Error('Missing productInfo.product');
		}

		if (!isType(envType, productInfo.env)) {
			throw new Error(
				`Invalid productInfo.env '${productInfo.env}', ` +
					`must be an envType: [${objectValues(envType)}]`,
			);
		}

		if (productInfo.perimeter && !isType(perimeterType, productInfo.perimeter)) {
			throw new Error(
				`Invalid productInfo.perimeter '${productInfo.perimeter}', ` +
					`must be an perimeterType: [${objectValues(perimeterType)}]`,
			);
		}

		if (!productInfo.origin) {
			productInfo.origin = originType.WEB;
		} else if (!isType(originType, productInfo.origin)) {
			throw new Error(
				`Invalid productInfo.origin '${productInfo.origin}', ` +
					`must be an originType: [${objectValues(originType)}]`,
			);
		}

		if (!productInfo.platform) {
			productInfo.platform =
				productInfo.origin === originType.WEB ? platformType.WEB : platformType.DESKTOP;
		} else {
			validatePlatform(productInfo);
		}

		this.logger = settings.logger || console;

		this._productInfo = {
			...productInfo,
			subproduct: this._createSubproductGetter(productInfo.subproduct),
			embeddedProduct: this._createEmbeddedProductGetter(productInfo.embeddedProduct),
		};
		this._tenantInfo = {};
		this._orgInfo = {};
		this._uiViewedAttributes = {};
		this._context = buildContext(this._productInfo);
		this._safeSessionStorage = new SafeSessionStorage();

		const useStargate = this._useStargate(settings.useStargate);
		const apiHost =
			settings.apiHost ||
			selectHost({
				useStargate,
				env: productInfo.env,
				useLegacyUrl: settings.useLegacyUrl,
				perimeter: productInfo.perimeter,
				envOverride: productInfo.envOverride,
			});
		const apiHostProtocol = settings.apiHostProtocol || 'https';

		const minRetryDelay = settings.minRetryDelay || 1000;

		const retryQueueOptions: RetryQueueOptions = {
			maxRetryDelay: 60000,
			minRetryDelay,
			backoffFactor: 2,
			flushWaitMs: settings.flushWaitInterval,
			flushBeforeUnload: settings.flushBeforeUnload,
		};

		const retryQueuePrefix = `awc-${productInfo.env}`;

		const xidPromiseGetter = () => XIDPromise(settings.xidConsent, settings.xidPromiseFn);

		const disableCookiePersistence = settings.disableCookiePersistence || false;

		this.eventProcessor = new EventProcessor({
			apiHost,
			apiHostProtocol,
			product: productInfo.product,
			retryQueuePrefix,
			retryQueueOptions,
			xidPromiseGetter,
			logger: this.logger,
			disableCookiePersistence,
		});

		this._userInfo = {
			anonymousId: this.eventProcessor
				.getUser()
				.getAnonymousId(settings?.customAnonymousIdGenerator),
		};

		this._pageVisibility = new PageVisibility();
		this._tabTracking = new TabTracking();
		this._sessionTracking = new SessionTracking({
			sessionExpiryTime: settings.sessionExpiryTime,
			onNewSessionStarted: settings.onNewSessionStarted,
		});

		this.task = new TaskSessionStore();
		this.originTracing = new OriginTracing();

		// Init Apdex
		this._apdexEvent = new ApdexEvent(this.sendOperationalEvent, this._pageVisibility);

		this._historyReplaceFn =
			typeof settings.historyReplaceFn === 'function'
				? settings.historyReplaceFn
				: defaultHistoryReplaceFn;

		this.eventDelayQueue = new EventDelayQueue(
			this._fireDelayedEvent,
			settings.delayQueueCompressors || [],
		);
		this._testingCache = new TestingCache();

		this._pageLoadId = uuid();

		this._workspaceInfo = {};
		this._aliases = {};
		this._groups = {};
	}

	_useStargate = (useStargate?: boolean): boolean => {
		if (useStargate == null) {
			return true;
		}
		return useStargate;
	};

	_endsWith = (str: string, suffix: string) =>
		str.indexOf(suffix, str.length - suffix.length) !== -1;

	_changeInternalUserId = (userId: string | undefined, anonymousId?: string) => {
		this.eventProcessor.getUser().setUserId(userId);

		if (anonymousId && anonymousId !== this.eventProcessor.getUser().getAnonymousId()) {
			// Setting anonymous id can take a long time. Reading is a lot faster.
			// Only update if it has changed.
			this.eventProcessor.getUser().setAnonymousId(anonymousId);
		}
	};

	_createSubproductGetter = (subproduct: any) =>
		createGetter(subproduct, 'Cannot get subproduct from the callback. Proceeding without it.');

	_createEmbeddedProductGetter = (embeddedProduct: any) =>
		createGetter(
			embeddedProduct,
			'Cannot get embeddedProduct from the callback. Proceeding without it.',
		);

	_getLastScreenEvent = () => {
		try {
			return JSON.parse(this._safeSessionStorage.getItem(LAST_SCREEN_EVENT_STORAGE_KEY) || '');
		} catch (err) {
			this._safeSessionStorage.removeItem(LAST_SCREEN_EVENT_STORAGE_KEY);
			return null;
		}
	};

	_setLastScreenEvent = (event: any) => {
		this._safeSessionStorage.setItem(
			LAST_SCREEN_EVENT_STORAGE_KEY,
			JSON.stringify({
				name: event.name,
				attributes: event.attributes,
			}),
		);
	};

	_shouldEventBeDelayed = (event: any) => {
		// TODO: this is a temporary restriction for the purposes of the Track All Changes project
		// The delay mechanism has a chance of event loss, which we can only accept for our own data at this point.
		// Once the delay queue implementation has been improved and measured to confirm that it is reliable enough,
		// then we will be able to open it up for other products to use by removing this check.
		if (!event.tags || event.tags.indexOf('measurement') === -1) {
			return false;
		}

		const isEventHighPriority = event.highPriority !== false; // defaults to true if excluded
		return this.eventDelayQueue.isDelayingLowPriorityEvents() && !isEventHighPriority;
	};

	_fireEvent = (
		identifier: string,
		builtEvent: any,
		context: Context,
		callback: any,
	): Promise<void> => {
		switch (builtEvent.eventType) {
			case eventType.UI:
			case eventType.OPERATIONAL:
			case eventType.TRACK:
				return this.eventProcessor.track(identifier, builtEvent, context, callback);
			case eventType.SCREEN:
				return this.eventProcessor.page(identifier, builtEvent, context, callback);
			case eventType.IDENTIFY:
				return this.eventProcessor.identify(identifier, builtEvent, context, callback);
			default:
				throw new Error(`No handler has been defined for events of type ${builtEvent.eventType}`);
		}
	};

	_fireDelayedEvent = (identifier: any, builtEvent: any, context: Context, userInfo: UserInfo) => {
		try {
			// User information can change while the delay period is active, so we need to restore the values that
			// were active when the event was originally fired.
			this._changeInternalUserId(userInfo.userId, userInfo.anonymousId);
			builtEvent.tags = [...(builtEvent.tags || []), 'sentWithDelay'];

			// The callbacks for delayed events are fired immediately, so there is nothing to pass through for this argument.
			this._fireEvent(identifier, builtEvent, context, undefined);
		} finally {
			this._changeInternalUserId(this._userInfo.userId, this._userInfo.anonymousId);
		}
	};

	_delayEvent = (
		identifier: string,
		builtEvent: any,
		context: Context,
		userInfo: UserInfo,
		callback: any,
	) => {
		this.eventDelayQueue.push(identifier, builtEvent, context, userInfo);
		// Fire the callback immediately, as we can consider the event successfully processed at this point
		if (callback) {
			callback();
		}
	};

	_processEvent = (
		identifier: string,
		builtEvent: any,
		context: Context,
		callback: any,
	): Promise<void> => {
		this._testingCache.saveEvent(builtEvent);
		if (this._shouldEventBeDelayed(builtEvent)) {
			this._delayEvent(identifier, builtEvent, context, this._userInfo, callback);
			return Promise.resolve();
		} else {
			return this._fireEvent(identifier, builtEvent, context, callback);
		}
	};

	setEmbeddedProduct = (embeddedProduct: any) => {
		this._productInfo.embeddedProduct = this._createEmbeddedProductGetter(embeddedProduct);
		this.resetUIViewedTimers();
	};

	clearEmbeddedProduct = () => {
		this._productInfo.embeddedProduct = this._createEmbeddedProductGetter(null);
	};

	setSubproduct = (subproduct: any) => {
		this._productInfo.subproduct = this._createSubproductGetter(subproduct);
		this.resetUIViewedTimers();
	};

	/**
   * Calling this function in the intialisation of the client in product
   * captures specified 'origin tracing' URL params and fires a single origin landed event
   * <p>
   * This function expects a mapping between the keys for any URL parameters
   *  that should be captured and removed for origin tracing
   * Multiple parameters may be captured simultaneously if multiple key: handler function pairs are provided
   * Each handler function should return an object with two items
   * a) 'originTracingAttributes' - an object that will be added to the 'origin landed' event's attributes under 'originTracing
   * b) 'taskSessionId' (optional) - an Id string that will be added to the tasksessions for any event that fires from the tab, with the key
   *    matching the URL parameter, for the purpose of attributing subsequent analytics event to the origin land.
   * </p>
   * The general use case for this feature is for allowing attributation of user behaviour to a out of product or cross product link,
   * e.g. from a share or email
   *
   * An example calling this function using an external decoding library, with taskSessionId specified to persist
   * analyticsWebClient.setOriginTracingHandlers({
        atlOrigin: encodedOrigin => {
            const { id, product } = OriginTracing.fromEncoded(encodedOrigin);
            return { originTracingAttributes: {'id': id, 'product': product}, taskSessionId: id };
        },
    });
   *
   * @param  {Object} originParamHandlerMapping a dictionary of mappings between origin url param keys and handler functions
   * @this {AnalyticsWebClient}
   */
	setOriginTracingHandlers = (originParamHandlerMapping: any): Promise<void> => {
		const capturedOriginTraces = this.originTracing.handleOriginParameters(
			originParamHandlerMapping,
			this._historyReplaceFn,
		);
		Object.keys(capturedOriginTraces).forEach((x) => {
			if (typeof capturedOriginTraces[x].taskSessionId !== 'undefined') {
				this.task.createTaskSessionWithProvidedId(x, capturedOriginTraces[x].taskSessionId);
			}
		});
		const originAttributes: any = {};
		Object.keys(capturedOriginTraces).forEach((x) => {
			if (capturedOriginTraces[x].originTracingAttributes) {
				originAttributes[x] = capturedOriginTraces[x].originTracingAttributes;
			} else {
				// eslint-disable-next-line no-console
				console.warn(`Handling method for origin parameter ${x} has not returned any attributes`);
			}
		});
		if (Object.keys(capturedOriginTraces).length > 0) {
			return this.sendOperationalEvent(
				{
					action: 'landed',
					actionSubject: 'origin',
					source: 'webClient',
					attributes: { originTracesLanded: originAttributes },
				},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				() => {},
			);
		}
		return Promise.resolve();
	};

	setTenantInfo = (tenantIdType: tenantType, tenantId?: string) => {
		if (!tenantIdType) {
			throw new Error('Missing tenantIdType');
		}

		if (tenantIdType !== tenantType.NONE && !tenantId) {
			throw new Error('Missing tenantId');
		}

		if (!isType(tenantType, tenantIdType)) {
			throw new Error(
				`Invalid tenantIdType '${tenantIdType}', ` +
					`must be an tenantType: [${objectValues(tenantType)}]`,
			);
		}

		this._tenantInfo = {
			tenantIdType,
			tenantId,
		};
	};

	clearTenantInfo = () => {
		this._tenantInfo = {};
	};

	setOrgInfo = (orgId: any) => {
		if (!orgId) {
			throw new Error('Missing orgId');
		}
		this._orgInfo = {
			orgId,
		};
	};

	clearOrgInfo = () => {
		this._orgInfo = {};
	};

	setWorkspaceInfo = (workspaceId: any) => {
		if (!workspaceId) {
			throw new Error('Missing workspaceId');
		}
		this._workspaceInfo = {
			workspaceId,
		};
	};

	clearWorkspaceInfo = () => {
		this._workspaceInfo = {};
	};

	setUserInfo = (userIdType: string, userId: string) => {
		validateIdentifyEvent(userIdType, userId);
		this._changeInternalUserId(userId);
		this._userInfo = {
			userIdType: userIdType as userType,
			userId,
			anonymousId: this.eventProcessor.getUser().getAnonymousId(),
		};
	};

	clearUserInfo = () => {
		this._changeInternalUserId(undefined);
		this._userInfo = {
			anonymousId: this.eventProcessor.getUser().getAnonymousId(),
		};
	};

	setAlias = (aliasKeyType: aliasType, alias: string) => {
		if (!aliasKeyType) {
			throw new Error('Missing aliasType');
		}
		if (!isType(aliasType, aliasKeyType)) {
			throw new Error(
				`Invalid aliasType '${aliasKeyType}', ` +
					`must be an aliasType: [${objectValues(aliasType)}]`,
			);
		}
		this._aliases[aliasKeyType] = alias;
	};

	clearAlias = () => {
		this._aliases = {};
	};

	setGroup = (groupsKeyType: groupType, group: string) => {
		if (!groupsKeyType) {
			throw new Error('Missing groupType');
		}
		if (!isType(groupType, groupsKeyType)) {
			throw new Error(
				`Invalid groupType '${groupsKeyType}', ` +
					`must be an groupType: [${objectValues(groupType)}]`,
			);
		}
		this._groups[groupsKeyType] = group;
	};

	clearGroup = () => {
		this._groups = {};
	};

	getAnonymousId = () => this._userInfo.anonymousId;

	setUIViewedAttributes = (uiViewedAttributes: any) => {
		if (!uiViewedAttributes) {
			throw new Error('Missing uiViewedAttributes');
		}
		if (typeof uiViewedAttributes !== 'object' || Array.isArray(uiViewedAttributes)) {
			throw new Error('Invalid uiViewedAttributes type, should be a non array object');
		}
		this._uiViewedAttributes = { ...uiViewedAttributes };
	};

	getUIViewedAttributes = () => {
		return this._uiViewedAttributes;
	};

	clearUIViewedAttributes = () => {
		this._uiViewedAttributes = {};
	};

	sendIdentifyEvent = (userIdType: string, userId: string, callback?: any): Promise<void> => {
		this.setUserInfo(userIdType, userId);
		const builtEvent = {
			userIdType,
			eventType: eventType.IDENTIFY,
		};

		return this._processEvent(userId, builtEvent, this._context, callback);
	};

	/**
	 * @deprecated
	 * please use {@link sendScreenEvent instead)
	 */
	sendPageEvent = (name: string, callback: any): Promise<void> => {
		return this.sendScreenEvent(name, callback);
	};

	/**
	 * send screen event
	 * @param event The event / For retrocompatibility event name is still supported here.
	 * @param callback
	 * @param attributes. Deprecated, will get ignored if using an event object as first param.
	 */
	sendScreenEvent = (
		event: SendScreenEventInput,
		callback?: any,
		attributes?: any,
	): Promise<void> => {
		let screenName;
		let screenAttributes;
		let screenContainers;
		let screenTags;
		if (typeof event === 'object') {
			/* This is for retrocompatibility */
			screenName = event.name;
			screenAttributes = event.attributes;
			screenContainers = event.containers;
			screenTags = event.tags;
		} else {
			screenName = event;
			screenAttributes = attributes;
		}

		validateScreenEvent(screenName);
		validateContainers(screenContainers);
		const builtEvent = buildScreenEvent(
			this._productInfo,
			this._tenantInfo,
			this._userInfo,
			screenAttributes,
			// TODO: Remove the as any and move into a place where we know event is an object
			(event as any).nonPrivacySafeAttributes,
			screenTags,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this.task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			event,
			screenContainers,
			this._aliases,
			this._groups,
		);

		const builtEventWithName = {
			name: screenName,
			...builtEvent,
		};

		this._setLastScreenEvent(builtEventWithName);

		return this._processEvent(
			screenName,
			builtEventWithName,
			this._context,
			wrapCallback(callback, builtEventWithName),
		);
	};

	sendTrackEvent = (event: TrackEventPayload, callback?: any): Promise<void> => {
		validateTrackEvent(event);
		const builtEvent = buildActionEvent(
			this._productInfo,
			this._tenantInfo,
			this._userInfo,
			event,
			eventType.TRACK,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this.task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			this._aliases,
			this._groups,
		);

		return this._processEvent(
			buildActionName(event),
			builtEvent,
			this._context,
			wrapCallback(callback, builtEvent),
		);
	};

	sendUIEvent = (event: UIEventPayload, callback?: any): Promise<void> => {
		validateUIEvent(event);
		const builtEvent = buildActionEvent(
			this._productInfo,
			this._tenantInfo,
			this._userInfo,
			event,
			eventType.UI,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this.task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			this._aliases,
			this._groups,
		);

		return this._processEvent(
			buildActionName(event),
			builtEvent,
			this._context,
			wrapCallback(callback, builtEvent),
		);
	};

	sendOperationalEvent = (event: OperationalEventPayload, callback?: any): Promise<void> => {
		validateOperationalEvent(event);
		const builtEvent = buildActionEvent(
			this._productInfo,
			this._tenantInfo,
			this._userInfo,
			event,
			eventType.OPERATIONAL,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this.task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			this._aliases,
			this._groups,
		);

		return this._processEvent(
			buildActionName(event),
			builtEvent,
			this._context,
			wrapCallback(callback, builtEvent),
		);
	};

	startUIViewedEvent = (callback?: any) => {
		this.stopUIViewedEvent();

		this._uiViewedEvent = new UIViewedEvent(
			this._productInfo,
			() => ({
				embeddedProduct: this._productInfo.embeddedProduct(),
				subproduct: this._productInfo.subproduct(),
				tenantIdType: this._tenantInfo.tenantIdType,
				tenantId: this._tenantInfo.tenantId,
				userId: this._userInfo.userId,
				lastScreenEvent: this._getLastScreenEvent(),
				attributes: this._uiViewedAttributes,
			}),
			(event: any) => this.sendUIEvent(event, callback),
		);
		this._uiViewedEvent.start();
	};

	stopUIViewedEvent = () => {
		if (this._uiViewedEvent) {
			this._uiViewedEvent.stop();
			this._uiViewedEvent = null;
		}
	};

	resetUIViewedTimers = () => {
		if (this._uiViewedEvent) {
			this._uiViewedEvent.resetTimers();
		}
	};

	startApdexEvent = (apdexEvent: any) => {
		this._apdexEvent.start(apdexEvent);
	};

	getApdexStart = (apdexEvent: any) => this._apdexEvent.getStart(apdexEvent);

	stopApdexEvent = (apdexEvent: any, callback?: any) => {
		this._apdexEvent.stop(apdexEvent, callback);
	};

	// TODO If we ever make another breaking change, merge these two optional args into an `options` object arg.
	startLowPriorityEventDelay = (
		timeout?: number,
		callback?: (reason: StopLowPriorityEventDelayReason) => void,
	) => {
		this.eventDelayQueue.startLowPriorityEventDelay(timeout, callback);
	};

	stopLowPriorityEventDelay = () => {
		this.eventDelayQueue.stopLowPriorityEventDelay();
	};

	onEvent = (_analyticsId: any, analyticsData: any): Promise<void> => {
		if (!analyticsData) {
			throw new Error('Missing analyticsData');
		}

		if (!analyticsData.eventType) {
			throw new Error('Missing analyticsData.eventType');
		}

		if (analyticsData.eventType === eventType.TRACK) {
			return this.sendTrackEvent(analyticsData);
		} else if (analyticsData.eventType === eventType.UI) {
			return this.sendUIEvent(analyticsData);
		} else if (analyticsData.eventType === eventType.OPERATIONAL) {
			return this.sendOperationalEvent(analyticsData);
		} else if (analyticsData.eventType === eventType.SCREEN) {
			return this.sendScreenEvent(analyticsData.name, null, analyticsData.attributes);
		} else if (analyticsData.eventType === eventType.IDENTIFY) {
			return this.sendIdentifyEvent(analyticsData.userIdType, analyticsData.userId);
		}
		throw new Error(
			`Invalid analyticsData.eventType '${analyticsData.eventType}', ` +
				`must be an eventType: [${objectValues(eventType)}]`,
		);
	};
}
