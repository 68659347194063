import { useEffect, useLayoutEffect } from 'react';

const iframeSrcRegex = /(<iframe.*)\ssrc=((?:".*?")|(?:'.*?'))(.*>)/gim;
const iframeTagRegex = /<iframe/gi;

/**
 * Delays iframe loading by moving `src` attribute into `data-delayed-src`
 * NOTE: this is hack, or at least it smells like a hack. Proper solution would be to
 * comb through macros implementation in monolith/plugins and allow iframe-based macros
 * to optionally return content with already delayed src.
 *
 * @param html extension HTML
 * @returns extension HTML with replaced `src` attribute
 */
export const delayIframeLoading = (extensionHTML: string | null) => {
	if (!extensionHTML) return extensionHTML;

	// Account for multiple iframes in the input, to make non-advised Regex-based HTML
	// handling more robust in this particular case.
	return extensionHTML
		.split(iframeTagRegex)
		.map((fragment: string, index: number) => {
			return index === 0
				? fragment
				: `<iframe${fragment}`.replace(iframeSrcRegex, `$1 data-delayed-src=$2$3`);
		})
		.join('');
};

/**
 * Implements logic allowing to delay loading of the iframe source.
 * This logic is useful when extension is rendered on a server and is anticipated to be replaced
 * and therefore remounted by SPA-based extensions logic
 *
 * @param delayIframe specifices, whether the iframe-based extension should delay loading
 * @param extensionElement extension HTML element
 */
export const useDelayedIframe = (delayIframe: boolean, extensionElement: Element | undefined) => {
	useLayoutEffect(() => {
		if (extensionElement) {
			if (delayIframe) {
				extensionElement.setAttribute('data-delay-iframe', 'true');
			} else {
				extensionElement.removeAttribute('data-delay-iframe');
			}
		}
	}, [extensionElement, delayIframe]);

	useEffect(() => {
		const srcAttr = 'src';
		const delayedSrcAttr = 'data-delayed-src';
		if (extensionElement) {
			const iframe = extensionElement.querySelector('iframe');
			if (iframe && !delayIframe) {
				const delayedSrc = iframe.getAttribute(delayedSrcAttr);
				if (delayedSrc) {
					iframe.setAttribute(srcAttr, delayedSrc);
					iframe.removeAttribute(delayedSrcAttr);
				}
			}
		}
	}, [extensionElement, delayIframe]);
};
