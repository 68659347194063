import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { Icon } from '../../assets/icons/summarize';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const summarizePageConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	title: messages.title,
	description: messages.description,
	selectionType: 'empty',
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	getInitialContext: ({ editorView, intl, updateIdMap, mentionMap }) => {
		const { markdown, contentStatistics } = sliceOrNodeToMarkdown({
			node: editorView.state.doc,
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});
		return {
			document: markdown,
			userLocale: intl.locale,
			intentSchemaId: 'summarize_intent_schema.json',
			contentStatistics,
		};
	},
	triggerPromptRequest({ initialContext, editorSchema, streamingListener, analyticsContext }) {
		if (fg('platform_editor_ai_assistance_service')) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'summarize_intent_schema.json',
				editorSchema,
				fullDocument: initialContext.document,
			});
		}

		return streamXPGenAI({
			userLocale: initialContext.userLocale,
			intentSchemaId: 'summarize_intent_schema.json',
			editorSchema,
			contextList: [
				{
					type: 'ADF_MARKDOWN_V1',
					entity: initialContext.document,
					relationship: 'FULL_CONTENT',
				},
			],
			streamingListener,
		});
	},
	isVisible: ({ editorView }) => isMinLength({ editorView, minLength: 1 }),
	actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
