import gql from 'graphql-tag';

export const SpaceDetailQuery = gql`
	query SpaceDetailQuery($spaceKey: String, $includeKey: Boolean = false) {
		space(key: $spaceKey) {
			id
			key @include(if: $includeKey)
			type
			dataClassificationTags
		}
	}
`;
