import React from 'react';

import { BadgeFn } from '../components/UIKit1/badge';
import { Button } from '../components/UIKit1/button';
import { ButtonSetFn } from '../components/UIKit1/button/ButtonSet';
import { CheckboxGroupFn } from '../components/UIKit1/checkbox';
import { CodeFn } from '../components/UIKit1/code';
import { DateLozengeFn } from '../components/UIKit1/dateLozenge';
import { DatePickerFn } from '../components/UIKit1/datepicker';
import { ErrorPanelRenderFn } from '../components/UIKit1/errorPanel/errorPanel';
import { Form } from '../components/UIKit1/form';
import { FormConditionFn } from '../components/UIKit1/formCondition';
import { ImageFn } from '../components/UIKit1/image';
import { InlineDialog } from '../components/UIKit1/inlineDialog';
import { ModalDialog } from '../components/UIKit1/modalDialog';
import { RadioGroupFn } from '../components/UIKit1/radio';
import { RangeFn } from '../components/UIKit1/range';
import { SectionMessageFn } from '../components/UIKit1/sectionMessage';
import { SelectFn } from '../components/UIKit1/select';
import { StatusLozengeFn } from '../components/UIKit1/statusLozenge';
import { TabsFn } from '../components/UIKit1/tabs';
import { TableFn } from '../components/UIKit1/table';
import { TagFn, TagGroupFn } from '../components/UIKit1/tag';
import { TextFn } from '../components/UIKit1/text';
import { TextAreaFn } from '../components/UIKit1/textarea';
import { TextFieldFn } from '../components/UIKit1/textfield';
import { ToggleFn } from '../components/UIKit1/toggle';
import { TooltipFn } from '../components/UIKit1/tooltip';
import { ViewFn } from '../components/UIKit1/view';
import { HeadingFn } from '../components/UIKit1/heading';

import { EmFn, LinkFn, StrikeFn, StringFn, StrongFn } from '../components/UIKit1/markup';
import { upgradeComponent, type ComponentMap } from './util';
import * as UIKit2 from '../components/UIKit-legacy';

export const uiKit1Components: ComponentMap = {
	Badge: upgradeComponent(BadgeFn),
	Button: (args) => <Button {...args} key={args.forgeDoc.key} />,
	ButtonSet: upgradeComponent(ButtonSetFn),
	CheckboxGroup: upgradeComponent(CheckboxGroupFn),
	Code: upgradeComponent(CodeFn),
	DateLozenge: upgradeComponent(DateLozengeFn),
	DatePicker: upgradeComponent(DatePickerFn),
	Em: upgradeComponent(EmFn),
	ErrorPanel: ErrorPanelRenderFn,
	Form: (args) => <Form {...args} key={args.forgeDoc.key} />,
	FormCondition: upgradeComponent(FormConditionFn),
	Heading: upgradeComponent(HeadingFn),
	Image: upgradeComponent(ImageFn),
	InlineDialog: (args) => <InlineDialog {...args} key={args.forgeDoc.key} />,
	Link: upgradeComponent(LinkFn),
	ModalDialog: (args) => <ModalDialog {...args} key={args.forgeDoc.key} />,
	RadioGroup: upgradeComponent(RadioGroupFn),
	Range: upgradeComponent(RangeFn),
	SectionMessage: upgradeComponent(SectionMessageFn),
	Select: upgradeComponent(SelectFn),
	StatusLozenge: upgradeComponent(StatusLozengeFn),
	Strike: upgradeComponent(StrikeFn),
	String: upgradeComponent(StringFn),
	Strong: upgradeComponent(StrongFn),
	Table: upgradeComponent(TableFn),
	Tabs: upgradeComponent(TabsFn),
	Tag: upgradeComponent(TagFn),
	TagGroup: upgradeComponent(TagGroupFn),
	Text: upgradeComponent(TextFn),
	TextArea: upgradeComponent(TextAreaFn),
	TextField: upgradeComponent(TextFieldFn),
	Toggle: upgradeComponent(ToggleFn),
	Tooltip: upgradeComponent(TooltipFn),
	View: upgradeComponent(ViewFn),
};

export const uiKit2Components: ComponentMap = {
	Root: (args) => <UIKit2.Root {...args} key={args.forgeDoc.key} />,
	Badge: (arg) => <UIKit2.Badge {...arg} key={arg.forgeDoc.key} />,
	Image: (args) => <UIKit2.Image {...args} key={args.forgeDoc.key} />,
	Link: (args) => <UIKit2.Link {...args} key={args.forgeDoc.key} />,
	Text: (args) => <UIKit2.Text {...args} key={args.forgeDoc.key} />,
	Select: (args) => <UIKit2.Select {...args} key={args.forgeDoc.key} />,
	Spinner: (args) => <UIKit2.Spinner {...args} key={args.forgeDoc.key} />,
	ModalDialog: (args) => <UIKit2.ModalDialog {...args} key={args.forgeDoc.key} />,
	Form: (args) => <UIKit2.Form {...args} key={args.forgeDoc.key} />,
	Button: (args) => <UIKit2.Button {...args} key={args.forgeDoc.key} />,
	ButtonSet: (args) => <UIKit2.ButtonSet {...args} key={args.forgeDoc.key} />,
	Toggle: (args) => <UIKit2.Toggle {...args} key={args.forgeDoc.key} />,
	CheckboxGroup: (args) => <UIKit2.CheckboxGroup {...args} key={args.forgeDoc.key} />,
	RadioGroup: (args) => <UIKit2.RadioGroup {...args} key={args.forgeDoc.key} />,
	TextField: (args) => <UIKit2.TextField {...args} key={args.forgeDoc.key} />,
	TextArea: (args) => <UIKit2.TextArea {...args} key={args.forgeDoc.key} />,
	Range: (args) => <UIKit2.Range {...args} key={args.forgeDoc.key} />,
	DatePicker: (args) => <UIKit2.DatePicker {...args} key={args.forgeDoc.key} />,
	Checkbox: (args) => <UIKit2.Checkbox {...args} key={args.forgeDoc.key} />,
	Code: (args) => <UIKit2.Code {...args} key={args.forgeDoc.key} />,
	StatusLozenge: (args) => <UIKit2.StatusLozenge {...args} key={args.forgeDoc.key} />,
	Tag: (args) => <UIKit2.Tag {...args} key={args.forgeDoc.key} />,
	Heading: (args) => <UIKit2.Heading {...args} key={args.forgeDoc.key} />,
	TableRoot: (args) => <UIKit2.TableRoot {...args} key={args.forgeDoc.key} />,
	TableCaption: (args) => <UIKit2.TableCaption {...args} key={args.forgeDoc.key} />,
	TableHead: (args) => <UIKit2.TableHead {...args} key={args.forgeDoc.key} />,
	TableBody: (args) => <UIKit2.TableBody {...args} key={args.forgeDoc.key} />,
	TableFoot: (args) => <UIKit2.TableFoot {...args} key={args.forgeDoc.key} />,
	TableRow: (args) => <UIKit2.TableRow {...args} key={args.forgeDoc.key} />,
	TableHeadCell: (args) => <UIKit2.TableHeadCell {...args} key={args.forgeDoc.key} />,
	TableCell: (args) => <UIKit2.TableCell {...args} key={args.forgeDoc.key} />,
	Inline: (args) => <UIKit2.Inline {...args} key={args.forgeDoc.key} />,
	Stack: (args) => <UIKit2.Stack {...args} key={args.forgeDoc.key} />,
};
