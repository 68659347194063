import { query } from '@confluence/query-preloader-tools';

import { ScreenQuery } from './ScreenQuery.graphql.js';

export function preloadScreen(spaceKey) {
	return query({
		query: ScreenQuery,
		variables: {
			spaceKey,
		},
	});
}
