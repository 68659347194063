import type { TrackFeatureFlagOptions } from '@atlaskit/feature-flag-client/types';

import { useSessionData } from './useSessionData';
import { useTrackFeatureFlag } from './useTrackFeatureFlag';

export const useIntegerFeatureFlag = (
	flagName: string,
	defaultValue: number,
	options?: TrackFeatureFlagOptions,
): number => {
	const sessionData = useSessionData();
	const flagValue = parseInt(String(sessionData?.featureFlags[flagName]), 10);

	useTrackFeatureFlag(flagName, options);
	return isNaN(flagValue) ? defaultValue : flagValue;
};
