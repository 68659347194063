export function getMultiMacroQueryBlocklistFF(featureFlags: Record<string, any> | undefined) {
	return String(
		featureFlags?.['confluence.frontend.extensions.macros-query-server-render-top-x.blocklist'],
	)
		.split(',')
		.map((extensionKey) => extensionKey.trim())
		.filter(Boolean);
}

export function getMultiMacrosQueryFF(featureFlags: Record<string, any> | undefined) {
	return Boolean(featureFlags?.['confluence.frontend.use-multiple-macros-query']);
}

export function canUseMultiMacroQuery(
	extensionKey: string | undefined,
	featureFlags: Record<string, any>,
) {
	const isBlocklisted = getMultiMacroQueryBlocklistFF(featureFlags).includes(extensionKey || '');
	return getMultiMacrosQueryFF(featureFlags) && !isBlocklisted;
}
