import { LoadablePaint } from '@confluence/loadable';

export const TopicHomepage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TopicHomepageComponent" */ './TopicHomepageComponent'
			)
		).TopicHomepageComponent,
});
