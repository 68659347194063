export class ObjectRecorder<T> {
	private object: T | null = null;

	set(object: T) {
		this.object = object;
	}

	get(): T | null {
		return this.object;
	}
}
