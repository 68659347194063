import gql from 'graphql-tag';

export const WatchDialogWithSpaceKeyQuery = gql`
	query WatchDialogWithSpaceKeyQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			key
			currentUser {
				isWatched
				isWatchingBlogs
			}
			operations {
				operation
				targetType
			}
		}
		spaceHomepage(spaceKey: $spaceKey) {
			id
			type
			currentUserIsWatching
		}
	}
`;
