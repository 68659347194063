import type { FC } from 'react';
import React from 'react';

import type { MacroExperienceBase } from '@confluence/macro-tracker';
import {
	getExperienceName,
	getMacroAttributesFromADFNode,
	MacroExperienceStop,
} from '@confluence/macro-tracker';

import type { InlineExternalImageProps } from './types';

export const InlineExternalImage: FC<InlineExternalImageProps & MacroExperienceBase> = ({
	width = 'auto',
	height = 'auto',
	src,
	mode,
	node,
	contentId,
}: InlineExternalImageProps & MacroExperienceBase) => {
	const stopMacroExperience = (error) => {
		const attributes = getMacroAttributesFromADFNode(node);

		return (
			<MacroExperienceStop
				name={getExperienceName(mode, node)}
				contentId={contentId}
				mode={mode}
				error={error}
				attributes={attributes}
				source="InlineExternalImage"
			/>
		);
	};

	return (
		// eslint-disable-next-line jsx-a11y/alt-text
		<img
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				cursor: 'move',
			}}
			data-testid="external-image"
			width={width}
			height={height}
			src={src}
			onError={stopMacroExperience}
			onLoad={stopMacroExperience}
		/>
	);
};
