import { fetchConsentPreferences } from '../../../services/consent-hub-service';
import { checkTokenVersionValidity } from '../transformer';

import { type ValidConsentData } from './types';

export const getValidConsentData = async (
	fallbackConsentToken?: string,
): Promise<ValidConsentData | undefined> => {
	// Fetches preferences from ConsentHub and returns serialized token data if possible
	const consentData = await fetchConsentPreferences();
	let { consentToken, consentTimestamp, consentHubInaccessible } = consentData;

	let { isValid } = checkTokenVersionValidity(consentToken);

	if (isValid && consentHubInaccessible) {
		if (fallbackConsentToken) {
			// `consentHubInaccessible`: ConsentHub request failed (e.g. service is down/bad request/etc). Attempt to use
			// any previously set consents in the interim if we have them.
			consentToken = fallbackConsentToken;
		} else {
			// Temporary: Sets the default token to have true consentedUnderXXXX bits
			// when ConsentHub is inaccessible and there is not fallback token. This
			// will ensure the banner isn't shown when ConsentHub is down.
			consentToken = `${consentToken.slice(0, 8)}1111`;
		}
	}

	return isValid ? { consentToken, consentTimestamp } : undefined;
};
