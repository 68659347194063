import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

export const LegacyCreateDialogCssOverridesLoader = LoadableAfterPaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-LegacyCreateDialogCssOverrides" */ './LegacyCreateDialogCssOverrides'
		),
});

const LegacyCssOverridesLoader = LoadableAfterPaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-LegacyCssOverrides" */ './LegacyCssOverrides'),
});

export default LegacyCssOverridesLoader;
