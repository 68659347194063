import type { ComponentProps, FC } from 'react';
import React, { useContext } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import AkButton from '@atlaskit/button/standard-button';
import ChevronLeftLargeIcon from '@atlaskit/icon/utility/migration/chevron-left--chevron-left-large';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import {
	COMPANY_HUB,
	COMPANY_HUB_EDIT,
	EDIT_PAGE_V2,
	SPACE_SETTINGS_LANDING_PAGE,
} from '@confluence/named-routes';
import { usePageContentId } from '@confluence/page-context';
import { useHistory, useRouteDataRef } from '@confluence/route-manager';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { SPAViewContext } from '@confluence/spa-view-context';
import { fg } from '@confluence/feature-gating';

import { getCompanyHubSideNav } from './company-hub/getCompanyHubSideNav';

const i18n = defineMessages({
	spaceSettingsBreadcrumb: {
		id: 'breadcrumbs.navigation.space-settings-breadcrumb-link.landing-page',
		defaultMessage: 'Space settings',
		description: 'Text for a breadcrumb link back to Space Settings overview page',
	},
	hubNameBreadcrumb: {
		id: 'breadcrumbs.navigation.space-settings-breadcrumb-link.hub-name',
		defaultMessage: '{hubName, select, other {{hubName}} undefined {Company Hub}}',
		description: 'Text for a breadcrumb link back to Company Hub Landing Page editor',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbLinkStyledText = styled.span({
	fontWeight: 'normal',
	color: token('color.text.subtle', N500),
	paddingTop: token('space.025', '2px'),
	display: 'inline-block',
	textDecoration: 'none',
	'&:hover': {
		textDecoration: 'none',
	},
});

type BreadcrumbsProps = {
	spaceKey: string;
	spaceAlias?: string | null;
	source: string;
	currentSection: string | null;
};

export const SpaceSettingsBreadcrumbs: FC<BreadcrumbsProps> = ({
	spaceKey,
	spaceAlias,
	source,
	currentSection,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { current: route } = useRouteDataRef();
	const history = useHistory();
	const [contentId] = usePageContentId();
	const { companyHubName } = useContext(SPAViewContext);

	let href: NonNullable<ComponentProps<typeof AkButton>['href']>;
	let text: MessageDescriptor;

	if (isCompanyHubSpaceKey(spaceKey)) {
		// The breadcrumb in the hub is only necessary when there's no side nav.
		if (getCompanyHubSideNav({ name: route?.routeName, params: route?.routeParams })) {
			return null;
		}

		if (fg('company_hub_consolidated_settings')) {
			href =
				history?.location?.state?.['from'] === COMPANY_HUB_EDIT.name
					? COMPANY_HUB_EDIT.toUrl()
					: COMPANY_HUB.toUrl();
		} else {
			href = fg('confluence_frontend_company_hub_simplified_routes')
				? COMPANY_HUB_EDIT.toUrl()
				: EDIT_PAGE_V2.toUrl({
						contentType: 'pages',
						spaceKey: spaceAlias || spaceKey,
						contentId,
					});
		}

		text = i18n.hubNameBreadcrumb;
	} else {
		// Regular space, not Company hub
		href = SPACE_SETTINGS_LANDING_PAGE.toUrl({ spaceKey: spaceAlias || spaceKey });
		text = i18n.spaceSettingsBreadcrumb;
	}

	const onClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'spaceSettingsBreadcrumbLink',
				source,
				attributes: {
					currentSection,
				},
			},
		}).fire();
	};

	return (
		<nav>
			<AkButton
				href={href}
				onClick={onClick}
				iconBefore={<ChevronLeftLargeIcon label="" color={token('color.icon', N500)} />}
				appearance="subtle-link"
				spacing="none"
			>
				<FormattedMessage
					{...text}
					tagName={BreadcrumbLinkStyledText}
					values={{
						hubName: companyHubName || undefined,
					}}
				/>
			</AkButton>
		</nav>
	);
};
