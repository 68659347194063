import type { FieldTransformer } from '../types';

// This transformer runs for all macros
export const stripDefaultValues: FieldTransformer<{ [key: string]: any }> = {
	transformAfter(params, metadata) {
		Object.entries(metadata).forEach(([key, field]) => {
			let remove = false;
			if (
				field &&
				// Ideally required field shouldn't have defaultValue but it's "macro" ¯\_(ツ)_/¯
				!field.required
			) {
				// Default behavior
				if (field.defaultValue === params[key]) {
					remove = true;
				}
				/**
				 * Special cases
				 */
				// Because we do the opposite transformation inside field-helper
				// NOTE: Temporary fix. Should be removed once ED-12388 lands
				else if (
					field.type.name === 'boolean' &&
					((field.defaultValue === 'false' && params[key] === false) ||
						(field.defaultValue === 'true' && params[key] === true))
				) {
					remove = true;
				}
				// Handle null string
				else if (
					field.type.name === 'string' &&
					field.defaultValue === null &&
					params[key] === ''
				) {
					remove = true;
				}
			}

			if (remove) {
				delete params[key];
			}
		});
		return params;
	},
};
