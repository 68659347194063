import { useContext } from 'react';

import { usePageContentId } from '@confluence/page-context';
import { RoutesContext, getSingleParam } from '@confluence/route-manager';

export function useContentId<T>(contentId?: string | T): string | T | undefined {
	// As we have a bunch of places where IsUpsellQueryComponent is used, we need
	// to cover all possible cases how we're getting contentId:

	const [pageStateContentId] = usePageContentId();
	const { getQueryParams } = useContext(RoutesContext);

	// 1) Legacy create page link - *directly passed as a prop*
	//    /wiki/spaces/~533571401/pages/create?fromPageId=55410689
	if (contentId) return contentId;

	// 2) New editor - *get contentID from RoutesContext*
	//    /wiki/spaces/{SPACE}/pages/{CONTENTID}
	//    /wiki/spaces/~351876773/pages/edit-v2/{CONTENTID}?draftShareId=7539fbb2-5f68-4982-8571-36bb8ef89c06
	if (pageStateContentId) return pageStateContentId;

	// 3) Old Editor creating new page - *get contentId as draftId from query*
	//    /wiki/spaces/~351876773/pages/create?draftId={DRAFTID}&draftShareId=ab6a566d-2ecd-4c32-a5ff-f8c13a36f713&

	return getSingleParam(getQueryParams(), 'draftId');
}
