import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import {
	ANALYTICS_SOURCE,
	BulkSpacePermissionsPage,
} from '@confluence/bulk-permissions/entry-points/BulkPermissions';

export const BulkSpacePermissionsRoute = () => <BulkSpacePermissionsPage />;

BulkSpacePermissionsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
