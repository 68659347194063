import type { VFC } from 'react';
import { memo, useCallback } from 'react';

import type { PageSegmentLoadMetric } from '@atlassian/browser-metrics/types';

import type { ActionTiming } from './usePerformanceAction';
import { usePerformanceAction } from './usePerformanceAction';

export type Props = {
	/**
	 * The name of the mark to create.
	 */
	markName: string;
	/**
	 * The metric to stop.
	 */
	metric: PageSegmentLoadMetric;
	/**
	 * The timing to use when creating a mark.
	 */
	timing?: ActionTiming;
	/**
	 * A callback that is fired right after the metric is started.
	 */
	onComplete?: () => void;
	/**
	 * A override to use for the mark timestamp.
	 */
	timestamp?: number;
};

const noop = () => {};

/**
 * Component for creating marks during a custom event's measurement.
 *
 * The timing of the mark (when the mark should be created) can be customized,
 * depending on needs.
 */
export const PageSegmentLoadMark: VFC<Props> = memo(
	({ markName, timing, metric, onComplete = noop, timestamp }) => {
		const handlePageSegmentLoadMetricMark = useCallback(() => {
			if (metric) {
				metric.mark(markName, timestamp);
				onComplete();
			}
			// Not adding exhaustive dependencies because we want this to fire only once,
			// despite any changes to `timestamp`
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [metric, onComplete]);

		usePerformanceAction(handlePageSegmentLoadMetricMark, timing);
		return null;
	},
);
