import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';
// This extension implements no functionality but is used as a placeholder for the nested tables in tables
// experience in Confluence and to reserve this key to prevent it from being used elsewhere.
// The actual functionality is implemented in Editor transforms to handle conversion of the extension content
// in the ADF to/from nested ProseMirror nodes.
export const nestedTableExtension: ExtensionManifest = {
	type: 'com.atlassian.nesting',
	key: 'nested-table',
	title: 'Nested tables',
	description: 'Allows you to nest tables within tables',
	icons: {
		'48': () =>
			import(
				/* webpackChunkName: "loadable-atlaskiticonglypheditortable" */ '@atlaskit/icon/glyph/editor/table'
			),
	},
	modules: {},
};
