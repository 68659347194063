import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { default as PlatformInlineEdit } from '@atlaskit/inline-edit';

type EditViewFieldProps<V> = {
	onChange: (event: any) => void;
	value: V;
	errorMessage?: string;
	isInvalid: boolean;
	'aria-invalid': 'true' | 'false';
	isRequired: boolean;
};

// Public API - aligns with ADS version
export type InlineEditProps<V = string> = Omit<
	React.ComponentProps<typeof PlatformInlineEdit<V>>,
	'analyticsContext' | 'onConfirm' | 'editView'
> & {
	// ADS has an additional analyticsEvent arg that is not optional. We don't use this in UI Kit and it can't be removed due to breaking changes in products
	onConfirm: (value: V) => void;
	editView: (fieldProps: EditViewFieldProps<V>) => React.ReactNode;
};

// Internal API that aligns with the UI Kit wrapper in `@forge/react`
// This is required since we can't pass functions outside of the runtime
// The workaround is to invoke within the runtime and pass out the result back to the product
export type InternalInlineEditProps = Omit<
	InlineEditProps,
	'readView' | 'editView' | 'validate'
> & {
	children: React.ReactNode;
};

const noop = () => {};

export const InlineEdit = (props: Parameters<RenderFn>[0]) => {
	const {
		label,
		startWithEditViewOpen = false,
		keepEditViewOpenOnBlur = false,
		hideActionButtons = false,
		isRequired = false,
		readViewFitContainerWidth = false,
		editButtonLabel = 'Edit',
		confirmButtonLabel = 'Confirm',
		cancelButtonLabel = 'Cancel',
		defaultValue,
		isEditing,
		onConfirm: providedOnConfirm = noop,
		onCancel: providedOnCancel = noop,
		onEdit: providedOnEdit = noop,
	} = props.forgeDoc.props as InternalInlineEditProps;

	const readViewForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'readView') {
			return child;
		}
	});
	const editViewForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'editView') {
			return child;
		}
	});

	return (
		<PlatformInlineEdit
			label={label}
			startWithEditViewOpen={startWithEditViewOpen}
			keepEditViewOpenOnBlur={keepEditViewOpenOnBlur}
			hideActionButtons={hideActionButtons}
			isRequired={isRequired}
			readViewFitContainerWidth={readViewFitContainerWidth}
			editButtonLabel={editButtonLabel}
			confirmButtonLabel={confirmButtonLabel}
			cancelButtonLabel={cancelButtonLabel}
			defaultValue={defaultValue}
			isEditing={isEditing}
			readView={() => readViewForgeDoc?.children.map(props.render)}
			editView={() => editViewForgeDoc?.children.map(props.render)}
			onConfirm={providedOnConfirm}
			onCancel={providedOnCancel}
			onEdit={providedOnEdit}
		/>
	);
};
