import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { MentionsProviderQuery } from './MentionsProviderQuery.graphql';
import type { MentionsProviderQuery as MentionsProviderQueryType } from './__types__/MentionsProviderQuery';

export const fetchMentionsProviderData = async () => {
	return getApolloClient()
		.query<MentionsProviderQueryType>({
			query: MentionsProviderQuery,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((error) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.ADMIN_EXPERIENCE,
			});
			markErrorAsHandled(error);
			return undefined;
		});
};
