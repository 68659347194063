/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type RenderFn } from '@atlassian/forge-ui-types';

export interface ListItemProps {
	children: React.ReactNode;
}

export const ListItem = (props: Parameters<RenderFn>[0]) => {
	const { forgeDoc, render } = props;
	return <li key={forgeDoc.key} children={forgeDoc.children.map(render)} />;
};
