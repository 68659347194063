import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';

import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { GeneralPageShareFeedbackButton } from './GeneralPageShareFeedbackButton';
import { HubDashboard } from './HubDashboard';

export const i18n = defineMessages({
	headerTitle: {
		id: 'company-hub.hub-settings.general.header-title',
		defaultMessage: 'Your hub',
		description: 'Header title for the general tab in company hub settings page',
	},
});

export const GeneralPageContent = () => {
	const spaceKey = getCompanyHubSpaceKey();

	return (
		<Stack grow="fill" space="space.400">
			<Stack grow="fill" space="space.200">
				<Heading size="medium">
					<FormattedMessage {...i18n.headerTitle} />
				</Heading>
				<HubDashboard spaceKey={spaceKey} />
			</Stack>
			<GeneralPageShareFeedbackButton />
		</Stack>
	);
};
