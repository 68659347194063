import type { FieldTransformer } from '../types';

const FIELD_TYPES_TO_FIX = ['username', 'spacekey', 'enum', 'label'];

export interface MultiValuedField {
	[key: string]: undefined | string | string[];
}

// Currently none of the pickers work with `multiple: true` by default
// We need to transform params (array <-> comma separated list)
export const transformMultiValuedFields: FieldTransformer<MultiValuedField> = {
	transformBefore(params, metadata) {
		Object.entries(metadata).forEach(([key, field]) => {
			const value = params[key];
			if (
				field?.multiple &&
				value !== undefined &&
				typeof value === 'string' &&
				FIELD_TYPES_TO_FIX.includes(field.type.name)
			) {
				params[key] = value.split(',');
			}
		});
		return params;
	},
	transformAfter(params, metadata) {
		Object.entries(metadata).forEach(([key, field]) => {
			const value = params[key];
			if (field?.multiple && Array.isArray(value) && FIELD_TYPES_TO_FIX.includes(field.type.name)) {
				params[key] = value.join(',');
			}
		});
		return params;
	},
};
