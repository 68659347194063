/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConfluenceEdition {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: waitForEditionChangeQuery
// ====================================================

export interface waitForEditionChangeQuery_tenant_editions {
  edition: ConfluenceEdition;
}

export interface waitForEditionChangeQuery_tenant {
  editions: waitForEditionChangeQuery_tenant_editions | null;
}

export interface waitForEditionChangeQuery {
  tenant: waitForEditionChangeQuery_tenant;
}
