import gql from 'graphql-tag';

export const FileUploadMutation = gql`
	mutation FileUploadMutation(
		$size: Int!
		$contentType: String!
		$mimeType: String!
		$fileName: String!
		$pageId: String!
		$fileStoreId: String!
		$minorEdit: Boolean
		$renderEditorHTML: Boolean
		$collectionName: String!
		$uploadSource: String
	) @experimental {
		experimentalFileUpload(
			size: $size
			contentType: $contentType
			mimeType: $mimeType
			fileName: $fileName
			pageId: $pageId
			fileStoreId: $fileStoreId
			minorEdit: $minorEdit
			renderEditorHTML: $renderEditorHTML
			collectionName: $collectionName
			uploadSource: $uploadSource
		)
	}
`;
