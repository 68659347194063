import PropTypes from 'prop-types';
import { createStore, createHook, createSubscriber, createContainer } from 'react-sweet-state';

export type PageStateType = {
	/**
	 * Unique ID of content currently being viewed
	 */
	contentId?: string;
	/**
	 * Type of the content
	 */
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	/**
	 * Determines whether contentId is loading
	 */
	contentIdLoading?: boolean;
	/**
	 * Page title without special characters, and with spaces replaced by "+", it's optional parameter
	 */
	contentSlug?: string;
	/**
	 * Control which version should be rendered for embedded content (attachments, "page-include" macro, etc.)
	 */
	embeddedContentRender?: string;
	/**
	 * Hash in URL, prefixed with "#"
	 * @deprecated Please use RoutesContext instead
	 */
	queryHash?: string;
	/**
	 * One of names of routes listed in `@confluence/named-routes` package
	 * @deprecated Please use RoutesContext instead
	 */
	routeName?: string;
	/**
	 * Unique key for the current space
	 */
	spaceKey?: string;
	/**
	 * Override of version to render
	 */
	versionOverride?: number;
	/**
	 * Determines whether current page is rendered in lite mode
	 */
	isLite?: boolean;
};

/**
 * Only used in Screen.js
 * @deprecated Please use typescript instead
 */
export const pageStateShape = PropTypes.shape({
	contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol]),
	contentIdLoading: PropTypes.bool,
	contentSlug: PropTypes.string,
	embeddedContentRender: PropTypes.string,
	queryHash: PropTypes.string,
	routeName: PropTypes.string,
	spaceKey: PropTypes.string,
	versionOverride: PropTypes.number,
	searchSessionId: PropTypes.string,
	isLite: PropTypes.bool,
	contentType: PropTypes.oneOf(['page', 'blogpost', 'whiteboard', 'database', 'embed']),
});

type PageActions = {
	setPageStore: (pageState: PageStateType) => ({ setState }: { setState: any }) => void;
};

const actions: PageActions = {
	setPageStore:
		(pageState) =>
		({ setState }) =>
			setState(pageState),
};

export const initialPageState = {
	contentId: undefined,
	contentType: undefined,
	contentIdLoading: false,
	contentSlug: undefined,
	embeddedContentRender: undefined,
	queryHash: undefined,
	routeName: undefined,
	spaceKey: undefined,
	versionOverride: undefined,
	isLite: false,
};

const PageStore = createStore<PageStateType, PageActions>({
	name: 'PageStore',
	initialState: initialPageState,
	actions,
});

// TODO: Update this to use latest container once react-sweet-state is updated to v2.7.0 or above
export const PageStateContainer = createContainer(PageStore, {
	onInit:
		() =>
		({ setState }, pageState: PageStateType) => {
			setState(pageState);
		},
	onUpdate:
		() =>
		({ setState }, pageState: PageStateType) => {
			setState(pageState);
		},
});
export const PageSubscriber = createSubscriber(PageStore);

export const usePageState = createHook(PageStore);

export const usePageContentId = createHook(PageStore, {
	selector: (state) => state.contentId,
});

export const usePageSpaceKey = createHook(PageStore, {
	selector: (state) => state.spaceKey,
});

export const useContentType = createHook(PageStore, {
	selector: (state) => state.contentType,
});
