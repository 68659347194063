import { LoadablePaint } from '@confluence/loadable';

export const BetaFeaturesSiteSettingsPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BetaFeaturesSiteSettingsPage" */ '../src/BetaFeaturesSiteSettingsPage'
			)
		).BetaFeaturesSiteSettingsPage,
});
