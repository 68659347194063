import isEqual from 'lodash/isEqual';
import { createStore, type Store } from 'redux';

import { type Config } from '../../common/types/config';
import { type CurrentMessage, type MessageMapping } from '../../common/types/message';
import { createCancellationToken } from '../../utils/createCancellationToken';
import { isMessagingEnabled } from '../../utils/messaging';
import {
	CLEAR_CURRENT,
	type CLEAR_CURRENT_ACTION,
	initialize,
	type INITIALIZE_ACTION,
	RESET_STORE,
	type RESET_STORE_ACTION,
	resetStore,
	SET_CURRENT,
	type SET_CURRENT_ACTION,
	SET_INITIALIZING,
	SET_MESSAGE_MAPPING,
	type SET_MESSAGE_MAPPING_ACTION,
} from '../engagement-actions';
import { processMessages } from '../engagement-api';

export const INITIAL_STATE: State = {
	messageMapping: new Map(),
	current: null,
	initialized: false,
	initializing: false,
	config: {
		cloudId: '',
		aaid: '',
		locale: '',
		product: '',
		stargateUrl: '',
		orgId: '',
		workspaceId: '',
	},
};

let processMessagesCancellationToken = createCancellationToken();

export type Action =
	| INITIALIZE_ACTION
	| RESET_STORE_ACTION
	| SET_MESSAGE_MAPPING_ACTION
	| SET_CURRENT_ACTION
	| CLEAR_CURRENT_ACTION;

/**
 * Remove all keys whose value matches the component from the mapping
 */
export function removeComponentFromMapping(
	messageMapping: MessageMapping,
	component: Record<string, any>,
): MessageMapping {
	const newMapping = new Map();

	messageMapping.forEach((value, key) => {
		if (!isEqual(value.component, component)) {
			newMapping.set(key, value);
		}
	});

	return newMapping;
}

export type State = {
	messageMapping: MessageMapping;
	current: CurrentMessage | null;
	initialized: boolean;
	initializing: boolean;
	config: Config;
};

export const store: Store<State> = createStore(
	(state: State = INITIAL_STATE, action: Action): State => {
		switch (action.type) {
			case SET_MESSAGE_MAPPING: {
				return {
					...state,
					initialized: true,
					initializing: false,
					messageMapping: action.messageMapping,
				};
			}

			case SET_INITIALIZING: {
				return {
					...state,
					config: action.config,
					initializing: true,
				};
			}

			case SET_CURRENT: {
				const { eventMapping } = action;
				const { message, component } = eventMapping;
				const messageMapping = removeComponentFromMapping(state.messageMapping, component);

				return {
					...state,
					current: {
						messageId: message.messageId,
						variationId: message.variationId,
						...component,
					},
					messageMapping,
				};
			}

			case CLEAR_CURRENT: {
				return {
					...state,
					current: null,
				};
			}

			case RESET_STORE: {
				return INITIAL_STATE;
			}

			default: {
				return state;
			}
		}
	},
);

export default (config: Config): Store<State> => {
	const state: State = store.getState();
	if (state.initializing || state.initialized) {
		if (state.config.cloudId === config.cloudId) {
			return store;
		}

		// CloudId has changed, cancel any in process processMessages request
		processMessagesCancellationToken.isCancelled = true;
		processMessagesCancellationToken = createCancellationToken();
		store.dispatch(resetStore());
	}

	const newConfig = { ...config };
	newConfig.locale = newConfig.locale.replace('_', '-');
	store.dispatch(initialize(newConfig));

	if (isMessagingEnabled()) {
		processMessages(store, newConfig, processMessagesCancellationToken);
	}

	return store;
};
