const parseNumber = (numStr: string): number | null => {
	numStr = numStr.replace(/[,$%]/g, '');
	const num = Number(numStr);

	if (!Number.isNaN(num)) {
		return num;
	}

	return null;
};

export { parseNumber };
