import type { AnalyticsEventPayload } from '@atlaskit/editor-common/analytics';
import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState, ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { ErrorUtils } from '../../utils/errors';

import { createInlineDecoration, createWidgetDecoration } from './actions';
import { aiExperienceDecorationPluginKey } from './decoration-plugin-key';
import type { AIDecorationExperiencePluginState } from './reducer';
import { reducer } from './reducer';
import { hasGeneratedContentDecorations } from './utils/hasGeneratedContentDecorations';
import {
	getGeneratedDecorationFromTransaction,
	getGeneratedNodeDecorationsFromTransaction,
} from './utils/step-utils';

/**
 * This creates a function which is called inside the prosemirror plugin state apply method via the
 * pluginFactory.
 *
 * The docChanged is called for every transaction where the docChanged property of the transaction is true,
 * and the returned value is set to the PluginState value.
 *
 * It takes a dispatchAnalyticsEvent in order to track whether the mapping we use to build a new PluginState
 * is throwing any errors.  This is a temporary measure to track whether we are hitting any edge cases
 * where the mapping is throwing errors. If we find that this is happening, we will revisit the approach
 * to building a new PluginState.
 *
 * The state being built here is used to  from the document used to;
 * - both apply and remove decorations to display the AI modal,
 * - apply decorations following the insertion of AI generated content, to highlight the inserted content (these are removed in the selectionChanged below).
 *
 * In future the intention is to seperate these concerns into seperate plugins.
 */
function onDocChanged(
	tr: ReadonlyTransaction,
	pluginState: AIDecorationExperiencePluginState,
): AIDecorationExperiencePluginState {
	const { dispatchAnalyticsEvent } = pluginState;
	// In some collab edge cases mapping decorations could throw an error
	// The ProseMirror .mapping approach has a “dangerous” axiom:
	// Besides the initial state, a document will always “change” through a Transaction
	// However, in some conditions we may reconfigure the EditorState, and a new document
	// will be created without a transaction.
	// This try/catch intends to track cases of this happening and see if it impacts users
	try {
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		if (editorExperiment('platform_editor_ai_ai_button_block_elements', 'test')) {
			// Check if transaction is from inserting AI generated content for blocks
			const isAiContentTransformationForBlocks = tr.getMeta('isAiContentTransformationForBlocks');
			// Return with generated content decoration for blocks
			if (isAiContentTransformationForBlocks) {
				const newDecorations = getGeneratedNodeDecorationsFromTransaction(tr);

				if (newDecorations.length) {
					return {
						...pluginState,
						modalDecorationSet: DecorationSet.create(tr.doc, newDecorations),
					};
				}
			}
		}

		// Check if transaction is from inserting AI generated content
		const generatedContentMeta = tr.getMeta('isAiContentTransformation');

		// Return with generated content decoration
		if (generatedContentMeta) {
			const newDecoration = getGeneratedDecorationFromTransaction(tr);

			if (!newDecoration) {
				throw new Error(
					'Did not expect not to find new content in a `isAiContentTransformation` transaction?',
				);
			}
			return {
				...pluginState,
				modalDecorationSet: DecorationSet.create(tr.doc, newDecoration),
			};
		}

		// If there is no existing modal decoration -- exit early
		if (pluginState.modalDecorationSet === DecorationSet.empty) {
			return pluginState;
		}

		// Check if generated AI decorations already exist
		const hasGeneratedContentDecoration = hasGeneratedContentDecorations(pluginState);

		const isRemote = tr.getMeta('isRemote') ?? false;

		/**
		 * Make sure it isn't a selection change from an appendTransaction
		 */
		const selectionWasSet = tr.selectionSet && tr.getMeta('appendedTransaction') === undefined;

		/**
		 * Remove decorations if
		 * - current user interacts with doc
		 *   - and it is not an appendedTransaction
		 */
		if (hasGeneratedContentDecoration && !isRemote && selectionWasSet) {
			return { ...pluginState, modalDecorationSet: DecorationSet.empty };
		}

		// Map the modal decorationSet against the transaction
		const mappedModalDecorationSet = pluginState.modalDecorationSet.map(tr.mapping, tr.doc);

		// return mapped decoration set for case where other users in collab are making changes to doc
		if (hasGeneratedContentDecoration) {
			return { ...pluginState, modalDecorationSet: mappedModalDecorationSet };
		}

		// Find the start/inline/end decoration
		const decorations = mappedModalDecorationSet.find();
		let inlineDecoration = decorations.find((d) => d.spec.key === 'inlineDecoration');
		let startDecoration = decorations.find((d) => d.spec.key === 'startWidgetDecoration');
		let endDecoration = decorations.find((d) => d.spec.key === 'endWidgetDecoration');
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		const nodeDecoration = decorations.find((d) => d.spec.key === 'ai-nodeDecoration');

		// AI experience was triggered by quick insert, no start widget or inline decoration
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		if (nodeDecoration || (endDecoration && !startDecoration && !inlineDecoration)) {
			return {
				...pluginState,
				modalDecorationSet: mappedModalDecorationSet,
			};
		}

		// Check if inlineDecoration has any content by looping through the textBetween
		const inlineDecorationHasContent =
			!!inlineDecoration && tr.doc.textBetween(inlineDecoration.from, inlineDecoration.to) !== '';

		// Return with no decorations if inline decoration does not exist
		// Handles edge case where the inline decoration can't be deleted at the start
		// of a doc even when it has no content
		// OR if it has no content (eg. selection is just empty paragraphs)
		if (!inlineDecoration || !inlineDecorationHasContent) {
			return {
				...pluginState,
				modalDecorationSet: DecorationSet.empty,
			};
		}

		// Handle the case where doc changes move inlineDecoration to position 0
		// We cannot add text before inline decoration if from position is 0
		// Remove and recreate inline decoration starting at position 1
		if (inlineDecoration.from === 0) {
			inlineDecoration = createInlineDecoration(
				inlineDecoration.from || 1, // widget cannot be placed at pos 0,
				inlineDecoration.to || 1, // widget cannot be placed at pos 0
			);
		}

		// Create and add start widget into DecorationSet if it's been deleted
		if (!startDecoration) {
			startDecoration = createWidgetDecoration(inlineDecoration.from, 'start');
		}
		// Create and add end widget into DecorationSet if it doesn't exist
		if (!endDecoration) {
			endDecoration = createWidgetDecoration(inlineDecoration.to, 'end');
		}

		return {
			...pluginState,
			modalDecorationSet: DecorationSet.create(tr.doc, [
				startDecoration,
				inlineDecoration,
				endDecoration,
			]),
		};
	} catch (error) {
		const analyticsPayload = {
			action: 'unhandledErrorCaught',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'decorationPluginFactory',
			attributes: {
				errorType: 'handleDocChangedError',
				errorMessage: ErrorUtils.extractErrorMessage(error),
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		} as any as AnalyticsEventPayload;

		dispatchAnalyticsEvent(analyticsPayload);

		return {
			...pluginState,
			modalDecorationSet: DecorationSet.empty,
		};
	}
}

/**
 * This function is called inside the prosemirror plugin state apply method via the pluginFactory.
 *
 * The docChanged is called for every transaction where the selectionSet property of the transaction is true,
 * and the returned value is set to the PluginState value.
 *
 * The state transformation here is being used to remove the decorations that are added when the AI experience has inserted content.
 *
 * These are added inside docChanged above.
 *
 * In future the intention is to seperate this concerns into seperate plugin (currently the majority of this file is dealing
 * with decorations related to the presentation of a modal for the AI experience).
 */
function onSelectionChanged(
	_tr: ReadonlyTransaction,
	pluginState: AIDecorationExperiencePluginState,
): AIDecorationExperiencePluginState {
	if (pluginState.modalDecorationSet === DecorationSet.empty || pluginState.configItem) {
		return pluginState;
	}

	if (hasGeneratedContentDecorations(pluginState)) {
		return {
			...pluginState,
			modalDecorationSet: DecorationSet.empty,
		};
	}
	return pluginState;
}

export const createInitialState =
	(dispatchAnalyticsEvent: PMPluginFactoryParams['dispatchAnalyticsEvent']) =>
	(state: EditorState) => {
		return {
			/**
			 * Longer term it would be good to expand the decorationset to include
			 * the entire selection, as well as the 'valid inline position' to place
			 * an inline widget visualising start+end of valid selections
			 */
			modalDecorationSet: DecorationSet.empty,

			/**
			 * This takes a dispatchAnalyticsEvent in order to support firing analytics events in case
			 * mapping logic used in the onDocChanged passed to the pluginFactory throws an error.
			 */
			dispatchAnalyticsEvent,
		};
	};

export const { createPluginState, createCommand, getPluginState } = pluginFactory(
	aiExperienceDecorationPluginKey,
	reducer,
	{
		onDocChanged,
		onSelectionChanged,
	},
);
