import { createContext } from 'react';

type BannerContextValue = {
	includeTopNav: boolean;
};

export const BannerContext = createContext<BannerContextValue>({
	includeTopNav: true,
});

BannerContext.displayName = 'BannerContext';
