import { useMemo } from 'react';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';

import { getPMFragmentWithFallback } from '../../../experience-application/get-pm-fragment-with-fallback';
import { convertPMFragmentToPreview } from '../../../ui/convert-markdown-to-preview';

type Props = {
	content: string;
	schema: EditorState['schema'];
	providerFactory: ProviderFactory;
	status: string;
	idMap?: IDMap;
};

export const StreamedContentPreview = ({
	content,
	schema,
	idMap,
	status,
	providerFactory,
}: Props) => {
	const preview = useMemo(() => {
		if (!content) {
			return null;
		}

		const { pmFragment } = getPMFragmentWithFallback({
			markdown: content,
			schema,
			source: 'ai-blocks',
			fireAIAnalyticsEvent: () => {},
			idMap: idMap || {},
		});

		return convertPMFragmentToPreview({
			schema,
			pmFragment,
			showTelepointer: status === 'loading',
			providerFactory: providerFactory,
		});
	}, [content, schema, status, providerFactory, idMap]);

	return preview;
};
