import { createSelectorCreator } from 'reselect';

/**
 * WeakMapMemoizer allows memoisation of lists
 */
class WeakMapMemoizer {
	constructor(func) {
		this.map = new WeakMap();
		this.func = func;
	}

	memoize(...weakArgs) {
		return this._memoizeImpl(this.map, weakArgs, 0);
	}

	_memoizeImpl(map, args, i) {
		let value = map.get(args[i]);
		if (i === args.length - 1) {
			if (!value) {
				value = this.func(...args);
				map.set(args[i], value);
			}
			return value;
		}
		if (!value) {
			value = new WeakMap();
			map.set(args[i], value);
		}
		return this._memoizeImpl(value, args, i + 1);
	}
}

/**
 * createSelectorCreator - drop in replacement for reselect.createSelector
 * which offers better performance when dealing with lists that should
 * be recreated very often
 */
export default createSelectorCreator((func) => {
	const memoizer = new WeakMapMemoizer(func);
	return memoizer.memoize.bind(memoizer);
});
