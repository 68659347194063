import gql from 'graphql-tag';

const contentAppearanceInnerFragment = gql`
	fragment contentAppearanceInnerFragment on PaginatedJsonContentPropertyList {
		nodes {
			id
			key
			value
			version {
				number
			}
		}
	}
`;

export const contentAppearancePublishedFragment = gql`
	fragment contentAppearancePublishedFragment on Content {
		appearancePublished: properties(key: "content-appearance-published") {
			...contentAppearanceInnerFragment
		}
	}
	${contentAppearanceInnerFragment}
`;

export const contentAppearanceDraftFragment = gql`
	fragment contentAppearanceDraftFragment on Content {
		appearanceDraft: properties(key: "content-appearance-draft") {
			...contentAppearanceInnerFragment
		}
	}
	${contentAppearanceInnerFragment}
`;
