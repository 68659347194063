import type { ComponentProps } from 'react';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';

import { FeedbackCollectorLoader } from './FeedbackCollectorLoader';

type FeedbackCollectorLoaderProps = ComponentProps<typeof FeedbackCollectorLoader>;

export function useFeedbackCollector(
	props: Omit<FeedbackCollectorLoaderProps, keyof DialogsContainerProps>,
) {
	const { showModal } = useDialogs();

	return () => showModal(FeedbackCollectorLoader, props);
}
