import { useCallback, useEffect, useState } from 'react';

import { type OnPerformanceTrackingEvent } from './types';

export const usePerformanceTracking = ({
	onEvent,
	initialData,
}: {
	onEvent?: OnPerformanceTrackingEvent;
	initialData: Promise<unknown> | undefined;
}) => {
	const [isLoading, setIsLoading] = useState(true);

	const onMount = useCallback(() => {
		return () => onEvent?.({ type: 'unmount' });
	}, [onEvent]);

	useEffect(() => {
		if (initialData) {
			void initialData
				.then(() => {
					onEvent?.({ type: 'initial-data-complete' });
				})
				.catch((error) => {
					onEvent?.({ type: 'initial-data-error', error });
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(false);
		}
	}, [initialData, onEvent]);

	const onComponentError = useCallback(
		(error: Error) => {
			onEvent?.({ type: 'component-error', error });
		},
		[onEvent],
	);

	useEffect(() => {
		if (isLoading === false) {
			onEvent?.({ type: 'component-ready' });
		}
	}, [isLoading]);

	return {
		onMount,
		isLoading,
		onComponentError,
	};
};
