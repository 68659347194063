/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::12c9b283fa3937a70d5b658b27d70718>>
 * @codegenId #tab-list
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tab-list
 * @codegenParams { "props": { "children": { "value": "props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))", "comment": "Filter out line spacing strings as TabList should only have Tab children" } } }
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::d14a7503f424a1271c00e3d090b07d73>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/hooks.tsx <<SignedSource::85aae58dfbe7e51fa5c11faea1be0adf>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/context.tsx <<SignedSource::70ea6ece9135134cc7ab63208ad212f0>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/styles.tsx <<SignedSource::8945f585ee9624a8c31c58ee72ac05b6>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/utils.tsx <<SignedSource::9a6e8596f4f58e54324d784797ffbf6a>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tab-list.tsx <<SignedSource::5e4c5b49f55949476ae771d7e86d74c9>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { TabList as PlatformTabList } from '@atlaskit/tabs';

type PlatformTabListProps = React.ComponentProps<typeof PlatformTabList>;

export type TabListProps = Pick<
  PlatformTabListProps,
  'children'
>;

export const TabList = (props: Parameters<RenderFn>[0]) => {
  const {
  } = props.forgeDoc.props as TabListProps;
  return (
    <PlatformTabList
      // Filter out line spacing strings as TabList should only have Tab children
      children={props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))}
    />
  );
};
/**
 * @codegenEnd
 */