/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { lazy, useState } from 'react';
import type { CheckboxFieldProps } from '@atlaskit/form';
import {
	type CheckboxOption,
	type NativeCheckboxGroupProps,
	type NativeCheckboxProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { FormStateChangeNotifier } from '../form';
import { useIsInForm } from '../utils/useIsInForm';

const styles2 = css({
	lineHeight: '20px',
});
const styles = css({
	lineHeight: '20px',
});
const AKCheckbox = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.checkbox' */
		'@atlaskit/checkbox'
	).then((module) => ({ default: module.Checkbox })),
);

const AKCheckboxField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.CheckboxField,
	})),
);
const Fieldset = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Fieldset })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

interface AKCheckboxGroupProps {
	options: CheckboxOption[];
	onChange: (value: string, checked: boolean) => void;
	label: string;
	name: string;
	description?: string;
	checkboxFieldValues: Array<string>;
}

const FormCheckboxGroup: React.FunctionComponent<AKCheckboxGroupProps> = ({
	options,
	label,
	name,
	description,
	onChange: checkboxGroupOnChange,
	checkboxFieldValues,
}) => {
	return (
		<Fieldset legend={label}>
			{description && <AKHelperMessage>{description}</AKHelperMessage>}
			{options.map((option: NativeCheckboxProps) => {
				const {
					key,
					label,
					value,
					defaultChecked,
					isRequired,
					onChange: checkboxOptionOnChange,
				} = option;
				return (
					<AKCheckboxField key={key} value={value} defaultIsChecked={defaultChecked} name={name}>
						{({ fieldProps }: { fieldProps: CheckboxFieldProps }) => {
							const {
								id,
								isDisabled,
								isInvalid,
								onChange,
								onBlur,
								onFocus,
								name,
								'aria-invalid': ariaInvalid,
								'aria-labelledby': ariaLabelledBy,
								isChecked,
							} = fieldProps;

							return (
								<div css={styles}>
									<AKCheckbox
										id={id}
										isChecked={isChecked}
										isDisabled={isDisabled}
										isInvalid={isInvalid}
										isRequired={isRequired}
										onBlur={onBlur}
										onFocus={onFocus}
										value={fieldProps.value}
										name={name}
										label={label}
										onChange={(e) => {
											checkboxGroupOnChange(fieldProps.value || '', e.target.checked);
											checkboxOptionOnChange?.({
												value,
												isChecked: e.target.checked,
											});
											return onChange(e);
										}}
										aria-invalid={ariaInvalid}
										aria-labelledby={ariaLabelledBy}
									/>
								</div>
							);
						}}
					</AKCheckboxField>
				);
			})}
			<FormStateChangeNotifier name={name} value={checkboxFieldValues} />
		</Fieldset>
	);
};

const CheckboxGroupInner: React.FunctionComponent<
	Omit<AKCheckboxGroupProps, 'checkboxFieldValues'>
> = ({ options, label, onChange: checkboxGroupOnChange, description }) => {
	return (
		<Fieldset legend={label}>
			{description && <AKHelperMessage>{description}</AKHelperMessage>}
			{options.map((option: NativeCheckboxProps) => {
				const {
					key,
					label,
					value,
					defaultChecked,
					isRequired,
					onChange: checkboxOptionOnChange,
				} = option;
				return (
					<div key={key} css={styles2}>
						<AKCheckbox
							label={label}
							isRequired={isRequired}
							value={value}
							defaultChecked={defaultChecked}
							onChange={(e) => {
								checkboxGroupOnChange(value, e.target.checked);
								checkboxOptionOnChange?.({
									value,
									isChecked: e.target.checked,
								});
							}}
						/>
					</div>
				);
			})}
		</Fieldset>
	);
};

export const CheckboxGroup = (props: Parameters<RenderFn>[0]) => {
	const nativeCheckboxGroupProps = props.forgeDoc.props as NativeCheckboxGroupProps;

	const { name, description, onChange } = nativeCheckboxGroupProps;
	const label = nativeCheckboxGroupProps.label;
	const children = props.forgeDoc.children;

	const options = children
		.filter(({ type }) => type === 'Checkbox')
		.map((child) => {
			const { key, props } = child;
			const {
				label,
				value,
				defaultChecked,
				isRequired,
				onChange: checkboxOnChange,
			} = props as NativeCheckboxProps;

			return {
				key,
				label,
				value,
				defaultChecked,
				isRequired,
				onChange: checkboxOnChange,
			} as NativeCheckboxProps;
		});

	const isInForm = useIsInForm();
	const defaultValues: string[] = [];
	options.forEach((option: NativeCheckboxProps) => {
		if (option.defaultChecked) {
			defaultValues.push(option.value);
		}
	});
	const [checkboxFieldValues, setCheckboxFieldValues] = useState<Array<string>>(defaultValues);

	const handleOnChange = (value: string, checked: boolean) => {
		setCheckboxFieldValues((prevState) => {
			let newVal: Array<string> = [];
			if (checked) {
				newVal = [...prevState, value];
			} else {
				newVal = prevState.filter((e) => e !== value);
			}
			onChange?.(newVal);
			return newVal;
		});
	};

	if (!isInForm) {
		return (
			<CheckboxGroupInner
				label={label}
				name={name}
				options={options}
				description={description}
				onChange={handleOnChange}
			/>
		);
	}

	return (
		<FormCheckboxGroup
			label={label}
			name={name}
			options={options}
			description={description}
			onChange={handleOnChange}
			checkboxFieldValues={checkboxFieldValues}
		/>
	);
};
