/* eslint-disable @atlassian/tangerine/import/no-restricted-paths */
import ALLOW_CORS_WHITELIST from '../allow-cors-whitelist';

export const MAX_RETRIES = 3;
export const JITTER_FACTOR = 0.1;
export const MULTIPLIER = 2;
export const WAIT_TIME_MS = 1000;

export async function errorIfNotOk(response: Response): Promise<Response> {
	if (!response.ok) {
		// We use the response body for two reasons:
		// 1. To ensure that we consume the response body, so that it gets reported on in Chrome DevTools
		// 2. It could have some useful information relevant to the error
		throw Error(`${response.status}: ${await response.text()}`);
	}

	return response;
}

export async function pullResponseTextIfNotOk(response: Response): Promise<Response> {
	if (!response.ok) {
		await response.text();
	}

	return response;
}

export function stargateFetch(input: RequestInfo, reqOptions?: RequestInit): Promise<Response> {
	return fetchWithRetry({
		input,
		reqOptions,
		numRetries: 0,
		maxRetries: MAX_RETRIES,
		waitTimeMs: WAIT_TIME_MS,
		backoffFn: exponentialRandomizedBackoff,
	});
}

interface FetchWithRetryParams {
	input: RequestInfo;
	reqOptions?: RequestInit;
	numRetries?: number;
	maxRetries?: number;
	waitTimeMs?: number;
	backoffFn?: (waitTimeMs: number) => number;
}

function fetchWithRetry({
	input,
	reqOptions,
	numRetries = 0,
	maxRetries = 0,
	waitTimeMs = 0,
	backoffFn = exponentialRandomizedBackoff,
}: FetchWithRetryParams): Promise<Response> {
	if (numRetries > maxRetries) {
		throw new Error(`Failed to fetch ${input}: Max retries exceeded`);
	}

	const options: RequestInit = {
		...reqOptions,
		credentials: ALLOW_CORS_WHITELIST.includes(location.host) ? 'include' : 'same-origin',
	};

	const retryAttempt = () =>
		new Promise<Response>((resolve) => {
			setTimeout(() => {
				resolve(
					fetchWithRetry({
						input,
						reqOptions,
						numRetries: numRetries + 1,
						maxRetries,
						waitTimeMs: backoffFn(waitTimeMs),
						backoffFn,
					}),
				);
			}, waitTimeMs);
		});

	return fetch(input, options)
		.then((response) => {
			if (response.status >= 500 && response.status <= 599) {
				return retryAttempt();
			}

			return response;
		})
		.catch(() => {
			return retryAttempt();
		});
}

export function exponentialRandomizedBackoff(waitTimeMs: number): number {
	const nextWaitTime = waitTimeMs * MULTIPLIER;
	const jitter = nextWaitTime * JITTER_FACTOR * Math.random();

	return nextWaitTime + jitter;
}
