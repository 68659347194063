import { query } from '@confluence/query-preloader-tools';

import { SpacePagesQuery } from './SpacePagesQuery.graphql';
import { PagesSpaceIdQuery } from './hooks/PagesSpaceIdQuery.graphql';
import { SpacePagesPersistenceQuery } from './SpacePagesPersistenceQueries.graphql';
import {
	ConfluenceSearchContentStatus,
	ConfluenceContentSearchScope,
	ConfluenceContentSortingParameter as PagesSortField,
	SortOrder as PagesSortOrder,
} from './__types__/SpacePagesQuery';
import type { ParsedUrlQuery } from './types';

const getSingleParam: (
	queryParams: ParsedUrlQuery | undefined,
	paramName: string,
) => string | undefined = (queryParams, paramName) => {
	if (!queryParams) {
		return undefined;
	}

	const value = queryParams[paramName];

	if (Array.isArray(value)) {
		return value[0];
	}

	return value;
};

export const preloadSpacePages = ({
	spaceKey,
	queryParams,
	userId,
	isLicensed,
	isLoggedIn,
	spaceId = '',
	isWhiteboardEnabled = false,
	isDatabaseEnabled = false,
	isFolderEnabled = false,
}: {
	spaceKey: string;
	queryParams?: ParsedUrlQuery;
	userId?: string | null;
	isLicensed?: boolean;
	isLoggedIn?: boolean;
	spaceId?: string;
	isWhiteboardEnabled?: boolean;
	isDatabaseEnabled?: boolean;
	isFolderEnabled?: boolean;
}) => {
	const pagesFilter = getSingleParam(queryParams, 'pagesFilter');
	const searchText = getSingleParam(queryParams, 'text') || '';
	const variables = {
		first: 24,
		maxNumberOfResults: 12,
		searchText,
		filters: {
			spaces: { spaceKeys: [spaceKey] },
			titleMatchOnly: { titleMatchOnly: true },
			...(pagesFilter === 'worked-on' &&
				userId &&
				isLicensed && {
					contributors: { accountIds: [userId] },
				}),
			...(pagesFilter === 'starred' &&
				isLicensed && {
					labels: [{ namespace: 'MY', name: 'favourite' }],
				}),
			statuses: {
				statuses: [ConfluenceSearchContentStatus.CURRENT, ConfluenceSearchContentStatus.DRAFT],
			},
		},
		sortBy: [
			{
				field: PagesSortField.LAST_MODIFIED_DATE,
				order: PagesSortOrder.DESC,
			},
		],
		isLicensed: Boolean(isLicensed),
		includeRecommended: false,
		spaceId,
		scopes: [
			ConfluenceContentSearchScope.PAGE,
			...(isWhiteboardEnabled ? [ConfluenceContentSearchScope.WHITEBOARD] : []),
			...(isDatabaseEnabled ? [ConfluenceContentSearchScope.DATABASE] : []),
			ConfluenceContentSearchScope.EMBED,
			...(isFolderEnabled ? [ConfluenceContentSearchScope.FOLDER] : []),
		],
	};

	return Promise.all([
		query({
			query: SpacePagesQuery,
			variables,
		}),
		...(!spaceId
			? [
					query({
						query: PagesSpaceIdQuery,
						variables: { spaceKey },
					}),
				]
			: []),
		...(isLoggedIn
			? [
					query({
						query: SpacePagesPersistenceQuery,
						variables: { spaceKey },
					}),
				]
			: []),
	]);
};
