import { type FieldDefinition, type Option } from '@atlaskit/editor-common/extensions';
import { type ForgeDoc, type ForgeProps } from '@atlassian/forge-ui-types';

type FieldConstructor = (props: ForgeProps, children: ForgeDoc[]) => FieldDefinition;

type FieldMap = {
	[key: string]: FieldConstructor;
};

export const CONFIG_USER_PICKER_PROVIDER = 'configUserPicker';

export class ValidationError extends Error {
	constructor(message: string) {
		super(message);
		Object.setPrototypeOf(this, ValidationError.prototype);
	}
}

export const threeLOPromptErrorMessage = `For this app to display, you need to press the Allow Access button in the
macro to allow the app to access Atlassian products on your behalf.`;

function findDefaultValue(options: ForgeDoc[], isMulti: true): string[];
function findDefaultValue(options: ForgeDoc[], isMulti: false): string | undefined;
function findDefaultValue(options: ForgeDoc[], isMulti: boolean): string | string[] | undefined {
	const defaultValues = options.reduce((acc, { props }) => {
		if (props) {
			for (const prop of ['defaultChecked', 'defaultSelected']) {
				if (prop in props) {
					return [...acc, props.value];
				}
			}
		}
		return acc;
	}, [] as string[]);

	return isMulti ? defaultValues : defaultValues[0];
}

const findItems = (options: ForgeDoc[]): Option[] =>
	options.map(({ props, type }) => {
		if (!props) {
			throw new Error(`Props are undefined on ${type}`);
		}
		return {
			label: props.label,
			value: props.value,
		};
	});
const fieldMap: FieldMap = {
	TextField: (props) => ({
		type: 'string',
		name: props.name,
		label: props.label,
		description: props.description,
		defaultValue: props.defaultValue,
		isRequired: props.isRequired,
		isHidden: props.isHidden,
		placeholder: props.placeholder,
	}),
	DatePicker: (props) => ({
		type: 'date',
		name: props.name,
		label: props.label,
		description: props.description,
		defaultValue: props.defaultValue,
		isRequired: props.isRequired,
		placeholder: props.placeholder,
	}),
	Select: (props, children) => ({
		type: 'enum',
		name: props.name,
		label: props.label,
		description: props.description,
		isRequired: props.isRequired,
		style: 'select',
		isMultiple: props.isMulti,
		placeholder: props.placeholder,
		items: findItems(children),
		defaultValue: findDefaultValue(children, props.isMulti),
	}),
	CheckboxGroup: (props, children) => ({
		type: 'enum',
		name: props.name,
		label: props.label,
		description: props.description,
		isRequired: props.isRequired,
		style: 'checkbox',
		isMultiple: true,
		items: findItems(children),
		defaultValue: findDefaultValue(children, true),
	}),
	RadioGroup: (props, children) => ({
		type: 'enum',
		name: props.name,
		label: props.label,
		description: props.description,
		isRequired: props.isRequired,
		style: 'radio',
		isMultiple: false,
		items: findItems(children),
		defaultValue: findDefaultValue(children, false),
	}),
	UserPicker: (props) => ({
		type: 'user',
		name: props.name,
		label: props.label,
		isMultiple: props.isMulti,
		isRequired: props.isRequired,
		options: {
			provider: { type: CONFIG_USER_PICKER_PROVIDER },
		},
		placeholder: props.placeholder,
		description: props.description,
		defaultValue: props.defaultValue,
	}),
	TextArea: (props) => ({
		type: 'string',
		name: props.name,
		style: 'multiline',
		label: props.label,
		placeholder: props.placeholder,
		description: props.description,
		isRequired: props.isRequired,
		defaultValue: props.defaultValue,
	}),
};

const codegenComponentFieldMap: FieldMap = {
	Textfield: (props) => ({
		type: 'string',
		name: props.name,
		label: props.label,
		description: props.description,
		defaultValue: props.defaultValue,
		isRequired: props.isRequired,
		isHidden: props.isHidden,
		placeholder: props.placeholder,
	}),
	DatePicker: (props) => ({
		type: 'date',
		name: props.name,
		label: props.label,
		defaultValue: props.defaultValue,
		isRequired: props.isRequired,
		placeholder: props.placeholder,
	}),
	Select: (props) => ({
		type: 'enum',
		name: props.name,
		label: props.label,
		description: props.description,
		isRequired: props.isRequired,
		style: 'select',
		isMultiple: props.isMulti,
		placeholder: props.placeholder,
		items: props.options,
		defaultValue: props.defaultValue,
	}),
	CheckboxGroup: (props) => ({
		type: 'enum',
		name: props.name,
		label: props.label,
		isRequired: props.isRequired,
		isMultiple: true,
		style: 'checkbox',
		items: props.options,
		defaultValue: props.defaultValue,
	}),
	RadioGroup: (props) => ({
		type: 'enum',
		name: props.name,
		label: props.label,
		isRequired: props.isRequired,
		style: 'radio',
		isMultiple: false,
		items: props.options,
		defaultValue: props.defaultValue,
	}),
	UserPicker: (props) => ({
		type: 'user',
		name: props.name,
		label: props.label,
		isMultiple: props.isMulti,
		isRequired: props.isRequired,
		options: {
			provider: { type: CONFIG_USER_PICKER_PROVIDER },
		},
		placeholder: props.placeholder,
		description: props.description,
		defaultValue: props.defaultValue,
	}),
	TextArea: (props) => ({
		type: 'string',
		name: props.name,
		style: 'multiline',
		label: props.label,
		placeholder: props.placeholder,
		isRequired: props.isRequired,
		defaultValue: props.defaultValue,
	}),
};

/** The shape of the XIS error is "Expected direct child of MacroConfig to be one of [...]. Received <component> component."
 * for each component, which is not ideal if the app contains multiple invalid components as the first part repeats.
 */
const formatErrorDetailsFromXIS = (errorDetails: string): string => {
	let invalidComponentArr = [];
	const regex = /Received (.*?) component/g;
	let match;
	while ((match = regex.exec(errorDetails)) !== null) {
		const [_, component] = match;
		invalidComponentArr.push(component);
	}

	return createValidationError(invalidComponentArr);
};

const createValidationError = (invalidComponentTypes: string[]): string => {
	// Filter out duplicate types. e.g. if there were multiple Images we only need to mention it once.
	// Error shouldn't look like: "Image and Image components are not supported in macro config"
	const types = [...new Set(invalidComponentTypes)];
	return `${
		types.length === 1 ? '' : `${types.slice(0, -1).join(', ')} and`
	} ${types.slice(-1)} components are not supported in macro config`;
};

const toLabelInferredForgeDoc = (configForgeDoc: ForgeDoc[]): ForgeDoc[] => {
	const newConfigForgeDoc: ForgeDoc[] = [];

	let previousNodeLabel: string | null = null;

	configForgeDoc.forEach(({ children, props, type, ...rest }) => {
		const newProps: ForgeProps = { ...props };
		if (previousNodeLabel && type !== 'Label') {
			newProps.label = previousNodeLabel;
			previousNodeLabel = null;
		} else if (type === 'Label') {
			previousNodeLabel = children[0]?.props?.text ?? null;
		} else if (props && !props.label) {
			newProps.label = '';
		}
		newConfigForgeDoc.push({
			...rest,
			children,
			type,
			props: newProps,
		});
	});

	return newConfigForgeDoc;
};

export const createSchemaFromConfig = (
	configChildren: ForgeDoc[],
	forgeReactMajorVersion?: number,
): FieldDefinition[] => {
	/* For codegen components, the label prop does not exist on the component, so we are allowing users to add <Label/> components to format their config the same way.
    This requires the following mutation where the label text gets applied to the next component. Editor also requires a label component in their mapping
    (see https://product-fabric.atlassian.net/wiki/spaces/E/pages/1245610891/Extension+configuration) so we must mutate the props like so.
  */
	const configChildrenClone = toLabelInferredForgeDoc(configChildren);

	const schema =
		forgeReactMajorVersion && forgeReactMajorVersion >= 10
			? configChildrenClone
					.filter((forgeDoc) => forgeDoc.type !== 'Label')
					.map(({ children, props, type }) => {
						if (!codegenComponentFieldMap[type]) {
							return type;
						}
						return codegenComponentFieldMap[type](props || {}, children);
					})
			: configChildren.map(({ children, props, type }) => {
					if (!fieldMap[type]) {
						return type;
					}

					return fieldMap[type](props || {}, children);
				});

	// Check if any validation errors occured
	const invalidComponentTypes = schema.filter((field) => typeof field === 'string') as string[];

	if (invalidComponentTypes.length > 0) {
		throw new ValidationError(createValidationError(invalidComponentTypes));
	}

	return schema as FieldDefinition[];
};

export function createSchemaFromAux(aux: ForgeDoc) {
	const forgeReactMajorVersion = aux.children[0]?.forgeReactMajorVersion;
	for (const { type, props, children } of aux.children) {
		if (type === 'ErrorPanel' && props) {
			const {
				error: { errorMessage, errorDetails },
			} = props;
			if (
				errorMessage.includes('Validation error') &&
				errorDetails.includes('Expected direct child of MacroConfig to be one of')
			) {
				throw new ValidationError(formatErrorDetailsFromXIS(errorDetails));
			}
			throw new Error(`${errorDetails || errorMessage || ''}`);
		}
		if (type === 'ThreeLOPrompt') {
			throw new Error(threeLOPromptErrorMessage);
		}
		if (type === 'MacroConfig') {
			return createSchemaFromConfig(children, forgeReactMajorVersion);
		}
	}
	throw new Error('<MacroConfig> must be used at the root');
}
