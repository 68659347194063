import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../../common/utils';
import { isProcessingAllowedByPreferences } from '../../../../common/utils/item-processing';
import { Logger } from '../../../../common/utils/logger';
import { CategoryStorageType } from '../../../../types';

export const getSetCookieOverride =
	(originalSetter: (v: any) => any) => (originalCookieValue: string) => {
		const cookieSetByPackage = originalCookieValue.includes('atl-set-cookie');
		if (cookieSetByPackage) {
			try {
				// Controls have already been run, passthrough as usual
				return originalSetter.apply(document, [originalCookieValue]);
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'usedDocumentCookieError',
					message: `document.cookie setter failed to set cookie set by package. ${e.message || ''}`,
					attributes: {
						outsidePackage: false,
					},
				});
			}
		}

		try {
			// Raw cookies and query params can contain sensitive PII/UGC we can't store, so we want to
			// strip that out before we log it out to our analytics client.
			const sanitizedCookieKey =
				originalCookieValue.slice(0, originalCookieValue.indexOf('=')) || '';

			sendPackageOperationalEvent({
				action: 'usedDocumentCookie',
				attributes: {
					cookieKey: sanitizedCookieKey,
					outsidePackage: !cookieSetByPackage,
				},
			});
			try {
				// Attempt to set a known cookie that was set from outside the package
				isProcessingAllowedByPreferences({
					itemKey: sanitizedCookieKey,
					allowedCallback: () => {
						return originalSetter.apply(document, [originalCookieValue]);
					},
					blockedCallback: ({ itemHasCategory }) => {
						if (fg('platform.moonjelly.block-unknown-cookies')) {
							return;
						} else {
							if (!itemHasCategory) {
								return originalSetter.apply(document, [originalCookieValue]);
							} else {
								return;
							}
						}
					},
					storageType: CategoryStorageType.Cookies,
				});
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'usedDocumentCookieError',
					message: `Failed to set cookie. ${e.message || ''}`,
					attributes: {
						cookieKey: sanitizedCookieKey,
					},
				});
			}
		} catch (e: any) {
			Logger.errorWithOperationalEvent({
				action: 'usedDocumentCookieError',
				message: `Failed to send document.cookie setter event. ${e.message || ''}`,
			});
		}
	};
