import { connect } from 'react-redux';

import ChangeboardingManagerComponent from './ChangeboardingManagerComponent';
import { getCurrentSpotlightId } from './selectors';

const mapStateToProps = (state) => ({
	spotlightId: getCurrentSpotlightId(state.views.changeboarding),
});

const ChangeboardingManager = connect(mapStateToProps)(ChangeboardingManagerComponent);

export default ChangeboardingManager;
