import gql from 'graphql-tag';

export const SetTaskStatusMutation = gql`
	mutation SetTaskStatusMutation($contentId: ID!, $taskId: ID!, $status: TaskStatus!) {
		setTaskStatus(
			inlineTasksInput: { cid: $contentId, taskId: $taskId, status: $status, trigger: VIEW_PAGE }
		) {
			success
		}
	}
`;
