import type { Node as PMNode, Slice } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { ContextSelectionScope } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';

/**
 * Returns whether selection is full document, partial selection or none.
 */
export const getContextSelectionScope = ({
	editorView,
	slice,
	node,
}: {
	editorView: EditorView;
	slice?: Slice;
	node?: PMNode;
}): ContextSelectionScope => {
	const hasContent = node ? (node.content?.size || 0) > 2 : slice ? slice.size >= 1 : false;
	if (hasContent) {
		if (slice?.content?.eq?.(editorView.state.doc.content) || node?.eq?.(editorView.state.doc)) {
			return 'fullDocument';
		} else {
			return 'partialSelection';
		}
	}
	return 'none';
};
