/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleIndices = { aqlStatement: 0 },
      peg$startRuleIndex   = 0,

      peg$consts = [
        function(clause, orderby) { return { clause, orderby } },
        function(orderby) { return orderby },
        "order by",
        peg$literalExpectation("ORDER BY", true),
        function(primary, secondary) { return [primary, ...secondary] },
        ",",
        peg$literalExpectation(",", false),
        function(clause) { return clause },
        function(field, direction) { return { field, direction } },
        "asc",
        peg$literalExpectation("ASC", true),
        "desc",
        peg$literalExpectation("DESC", true),
        function(clause, joined) { return { nodeType: 'clause', clauses: [clause, ...joined], type: 'or' } },
        "or",
        peg$literalExpectation("OR", true),
        function(clause, joined) { return { nodeType: 'clause', clauses: [clause, ...joined], type: 'and' } },
        "and",
        peg$literalExpectation("AND", true),
        function(haveNot, clause) { return { nodeType: 'clause', clause, invert: !!haveNot } },
        function(expr) { return { nodeType: 'expr', ...expr } },
        "not ",
        peg$literalExpectation("NOT ", true),
        "!",
        peg$literalExpectation("!", false),
        function(field, op, value) { return { field, op, value } },
        function(field, op, value) { return  { field, op, value } },
        "<",
        peg$literalExpectation("<", false),
        ">",
        peg$literalExpectation(">", false),
        "~",
        peg$literalExpectation("~", false),
        "!~",
        peg$literalExpectation("!~", false),
        "in",
        peg$literalExpectation("IN", true),
        function(haveNot) { return haveNot ? 'NOT IN' : 'IN' },
        "=",
        peg$literalExpectation("=", false),
        "!=",
        peg$literalExpectation("!=", false),
        function(v1, vremain) { return [v1, ...vremain] },
        function(val) { return val },
        ">=",
        peg$literalExpectation(">=", false),
        "<=",
        peg$literalExpectation("<=", false),
        function(n) { return Number.parseFloat(n) },
        "(",
        peg$literalExpectation("(", false),
        ")",
        peg$literalExpectation(")", false),
        function(funcName, params) { return `${funcName}(${(params || []).join(',')})` },
        function(value, remaining) { return [value, ...remaining] },
        function(value) { return value },
        "'",
        peg$literalExpectation("'", false),
        "\\",
        peg$literalExpectation("\\", false),
        "\"",
        peg$literalExpectation("\"", false),
        ".",
        peg$literalExpectation(".", false),
        /^[^']/,
        peg$classExpectation(["'"], true, false),
        function(val) { return val.join('') },
        /^[^"]/,
        peg$classExpectation(["\""], true, false),
        function(val) { return val.join('.') },
        /^[0-9]/,
        peg$classExpectation([["0", "9"]], false, false),
        /^[\\\-]/,
        peg$classExpectation(["\\", "-"], false, false),
        function(rootIdentifier, indexedIdentifier) { return [rootIdentifier, ...indexedIdentifier] },
        function(item) {return item},
        /^[a-zA-Z_]/,
        peg$classExpectation([["a", "z"], ["A", "Z"], "_"], false, false),
        /^[a-zA-Z_0-9]/,
        peg$classExpectation([["a", "z"], ["A", "Z"], "_", ["0", "9"]], false, false),
        function(firstLetter, remaining) { return firstLetter + remaining },
        /^[eE]/,
        peg$classExpectation(["e", "E"], false, false),
        /^[\-+]/,
        peg$classExpectation(["-", "+"], false, false),
        /^[\t ]/,
        peg$classExpectation(["\t", " "], false, false)
      ],

      peg$bytecode = [
        peg$decode("%;&/7#;!.\" &\"/)$8\": \"\"! )(\"'#&'#"),
        peg$decode("%;V/1#;\"/($8\":!\"! )(\"'#&'#"),
        peg$decode("%3\"\"\"5(7#/K#;V/B$;$/9$$;#0#*;#&/)$8$:$$\"! )($'#(#'#(\"'#&'#"),
        peg$decode("%2%\"\"6%7&/:#;W/1$;$/($8#:'#! )(#'#(\"'#&'#"),
        peg$decode("%;M/;#;V/2$;%/)$8#:(#\"\" )(#'#(\"'#&'#"),
        peg$decode("3)\"\"5#7*.) &3+\"\"5$7,"),
        peg$decode("%;(/9#$;'0#*;'&/)$8\":-\"\"! )(\"'#&'#"),
        peg$decode("%;V/I#3.\"\"5\"7//:$;V/1$;(/($8$:'$! )($'#(#'#(\"'#&'#"),
        peg$decode("%;+/9#$;)0#*;)&/)$8\":0\"\"! )(\"'#&'#"),
        peg$decode("%;V/I#31\"\"5#72/:$;V/1$;*/($8$:'$! )($'#(#'#(\"'#&'#"),
        peg$decode("%;,.\" &\"/2#;+/)$8\":3\"\"! )(\"'#&'#"),
        peg$decode("%;J/L#;W/C$;&/:$;W/1$;K/($8%:'%!\")(%'#($'#(#'#(\"'#&'#./ &%;-/' 8!:4!! )"),
        peg$decode("35\"\"5$76.) &27\"\"6778"),
        peg$decode(";..5 &;/./ &;1.) &;0.# &;2"),
        peg$decode("%;M/N#;W/E$;6/<$;W/3$;5/*$8%:9%#$\" )(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;M/N#;W/E$;7/<$;W/3$;9/*$8%:9%#$\" )(%'#($'#(#'#(\"'#&'#.X &%;M/N#;W/E$;8/<$;W/3$;=/*$8%:9%#$\" )(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;M/N#;W/E$;>/<$;W/3$;3/*$8%::%#$\" )(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;M/N#;W/E$;>/<$;W/3$;4/*$8%::%#$\" )(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;M/\\#2;\"\"6;7</M$;M/D$2=\"\"6=7>/5$;A/,$;@/#$+&)(&'#(%'#($'#(#'#(\"'#&'#"),
        peg$decode(";?.* &%;C/\"!&,)"),
        peg$decode("%;C/\"!&,).# &;B"),
        peg$decode(";M.# &;C"),
        peg$decode("2?\"\"6?7@.) &2A\"\"6A7B"),
        peg$decode("%;,.\" &\"/7#3C\"\"5\"7D/($8\":E\"!!)(\"'#&'#"),
        peg$decode("2F\"\"6F7G.) &2H\"\"6H7I"),
        peg$decode(";C.# &;:"),
        peg$decode("%;J/f#;W/]$;</T$;W/K$$;;0#*;;&/;$;W/2$;K/)$8':J'\"$\")(''#(&'#(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%2%\"\"6%7&/J#$;V0#*;V&/:$;</1$;W/($8$:K$!!)($'#(#'#(\"'#&'#"),
        peg$decode(";C.# &;="),
        peg$decode(";C./ &;M.) &;P.# &;?"),
        peg$decode("2L\"\"6L7M.Y &2N\"\"6N7O.M &2=\"\"6=7>.A &2;\"\"6;7<.5 &2F\"\"6F7G.) &2H\"\"6H7I"),
        peg$decode("%;U/' 8!:P!! )"),
        peg$decode("%;7/:#;V.\" &\"/,$;9/#$+#)(#'#(\"'#&'#.\xB9 &%;8/:#;V.\" &\"/,$;=/#$+#)(#'#(\"'#&'#.\x92 &%;6/:#;V.\" &\"/,$;5/#$+#)(#'#(\"'#&'#.k &%;>/:#;V.\" &\"/,$;4/#$+#)(#'#(\"'#&'#.D &%;>/:#;V.\" &\"/,$;3/#$+#)(#'#(\"'#&'#"),
        peg$decode("%;I/,#;M/#$+\")(\"'#&'#"),
        peg$decode(";P.# &;M"),
        peg$decode("%;M/p#;W/g$2Q\"\"6Q7R/X$;W/O$;D.\" &\"/A$;W/8$2S\"\"6S7T/)$8':U'\"&\")(''#(&'#(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;=/9#$;E0#*;E&/)$8\":V\"\"! )(\"'#&'#"),
        peg$decode("%2%\"\"6%7&/:#;W/1$;=/($8#:W#! )(#'#(\"'#&'#"),
        peg$decode("2X\"\"6X7Y"),
        peg$decode("2Z\"\"6Z7["),
        peg$decode("2\\\"\"6\\7]"),
        peg$decode("2^\"\"6^7_"),
        peg$decode("2Q\"\"6Q7R"),
        peg$decode("2S\"\"6S7T"),
        peg$decode("%;G/,#;F/#$+\")(\"'#&'#.O &%;G/,#;G/#$+\")(\"'#&'#.6 &%;G/,#;H/#$+\")(\"'#&'#"),
        peg$decode("%;F/g#$%;L/\"!&,).) &4`\"\"5!7a06*%;L/\"!&,).) &4`\"\"5!7a&/1$;F/($8#:b#!!)(#'#(\"'#&'#.\x83 &%;H/g#$%;L/\"!&,).) &4c\"\"5!7d06*%;L/\"!&,).) &4c\"\"5!7d&/1$;H/($8#:b#!!)(#'#(\"'#&'#./ &%;Q/' 8!:e!! )"),
        peg$decode("4f\"\"5!7g"),
        peg$decode("%4h\"\"5!7i/\"!&,)"),
        peg$decode("%%;N/t#;N/k$;N/b$;N/Y$;O/P$;N/G$;N/>$;O/5$;N/,$;N/#$+*)(*'#()'#(('#(''#(&'#(%'#($'#(#'#(\"'#&'#/\"!&,)"),
        peg$decode("%;S/9#$;R0#*;R&/)$8\":j\"\"! )(\"'#&'#"),
        peg$decode("%;I/1#;S/($8\":k\"! )(\"'#&'#"),
        peg$decode("%4l\"\"5!7m/L#%$4n\"\"5!7o0)*4n\"\"5!7o&/\"!&,)/)$8\":p\"\"! )(\"'#&'#"),
        peg$decode("$4f\"\"5!7g/,#0)*4f\"\"5!7g&&&#"),
        peg$decode("%;T/\x98#%2^\"\"6^7_/?#$4f\"\"5!7g0)*4f\"\"5!7g&/#$+\")(\"'#&'#.\" &\"/^$%4q\"\"5!7r/@#4s\"\"5!7t.\" &\"/,$;T/#$+#)(#'#(\"'#&'#.\" &\"/#$+#)(#'#(\"'#&'#.} &%2^\"\"6^7_/g#;T/^$%4q\"\"5!7r/@#4s\"\"5!7t.\" &\"/,$;T/#$+#)(#'#(\"'#&'#.\" &\"/#$+#)(#'#(\"'#&'#.# &;T"),
        peg$decode("$4u\"\"5!7v/,#0)*4u\"\"5!7v&&&#"),
        peg$decode("$4u\"\"5!7v0)*4u\"\"5!7v&")
      ],

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleIndices)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleIndex = peg$startRuleIndices[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$decode(s) {
    var bc = new Array(s.length), i;

    for (i = 0; i < s.length; i++) {
      bc[i] = s.charCodeAt(i) - 32;
    }

    return bc;
  }

  function peg$parseRule(index) {
    var bc    = peg$bytecode[index],
        ip    = 0,
        ips   = [],
        end   = bc.length,
        ends  = [],
        stack = [],
        params, i;

    while (true) {
      while (ip < end) {
        switch (bc[ip]) {
          case 0:
            stack.push(peg$consts[bc[ip + 1]]);
            ip += 2;
            break;

          case 1:
            stack.push(void 0);
            ip++;
            break;

          case 2:
            stack.push(null);
            ip++;
            break;

          case 3:
            stack.push(peg$FAILED);
            ip++;
            break;

          case 4:
            stack.push([]);
            ip++;
            break;

          case 5:
            stack.push(peg$currPos);
            ip++;
            break;

          case 6:
            stack.pop();
            ip++;
            break;

          case 7:
            peg$currPos = stack.pop();
            ip++;
            break;

          case 8:
            stack.length -= bc[ip + 1];
            ip += 2;
            break;

          case 9:
            stack.splice(-2, 1);
            ip++;
            break;

          case 10:
            stack[stack.length - 2].push(stack.pop());
            ip++;
            break;

          case 11:
            stack.push(stack.splice(stack.length - bc[ip + 1], bc[ip + 1]));
            ip += 2;
            break;

          case 12:
            stack.push(input.substring(stack.pop(), peg$currPos));
            ip++;
            break;

          case 13:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (stack[stack.length - 1]) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 14:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (stack[stack.length - 1] === peg$FAILED) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 15:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (stack[stack.length - 1] !== peg$FAILED) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 16:
            if (stack[stack.length - 1] !== peg$FAILED) {
              ends.push(end);
              ips.push(ip);

              end = ip + 2 + bc[ip + 1];
              ip += 2;
            } else {
              ip += 2 + bc[ip + 1];
            }

            break;

          case 17:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (input.length > peg$currPos) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 18:
            ends.push(end);
            ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

            if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length) === peg$consts[bc[ip + 1]]) {
              end = ip + 4 + bc[ip + 2];
              ip += 4;
            } else {
              end = ip + 4 + bc[ip + 2] + bc[ip + 3];
              ip += 4 + bc[ip + 2];
            }

            break;

          case 19:
            ends.push(end);
            ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

            if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length).toLowerCase() === peg$consts[bc[ip + 1]]) {
              end = ip + 4 + bc[ip + 2];
              ip += 4;
            } else {
              end = ip + 4 + bc[ip + 2] + bc[ip + 3];
              ip += 4 + bc[ip + 2];
            }

            break;

          case 20:
            ends.push(end);
            ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

            if (peg$consts[bc[ip + 1]].test(input.charAt(peg$currPos))) {
              end = ip + 4 + bc[ip + 2];
              ip += 4;
            } else {
              end = ip + 4 + bc[ip + 2] + bc[ip + 3];
              ip += 4 + bc[ip + 2];
            }

            break;

          case 21:
            stack.push(input.substr(peg$currPos, bc[ip + 1]));
            peg$currPos += bc[ip + 1];
            ip += 2;
            break;

          case 22:
            stack.push(peg$consts[bc[ip + 1]]);
            peg$currPos += peg$consts[bc[ip + 1]].length;
            ip += 2;
            break;

          case 23:
            stack.push(peg$FAILED);
            if (peg$silentFails === 0) {
              peg$fail(peg$consts[bc[ip + 1]]);
            }
            ip += 2;
            break;

          case 24:
            peg$savedPos = stack[stack.length - 1 - bc[ip + 1]];
            ip += 2;
            break;

          case 25:
            peg$savedPos = peg$currPos;
            ip++;
            break;

          case 26:
            params = bc.slice(ip + 4, ip + 4 + bc[ip + 3]);
            for (i = 0; i < bc[ip + 3]; i++) {
              params[i] = stack[stack.length - 1 - params[i]];
            }

            stack.splice(
              stack.length - bc[ip + 2],
              bc[ip + 2],
              peg$consts[bc[ip + 1]].apply(null, params)
            );

            ip += 4 + bc[ip + 3];
            break;

          case 27:
            stack.push(peg$parseRule(bc[ip + 1]));
            ip += 2;
            break;

          case 28:
            peg$silentFails++;
            ip++;
            break;

          case 29:
            peg$silentFails--;
            ip++;
            break;

          default:
            throw new Error("Invalid opcode: " + bc[ip] + ".");
        }
      }

      if (ends.length > 0) {
        end = ends.pop();
        ip = ips.pop();
      } else {
        break;
      }
    }

    return stack[0];
  }

  peg$result = peg$parseRule(peg$startRuleIndex);

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
