import type { ComponentType, ReactElement, ReactNode } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { FlagProps } from '@atlaskit/flag';

import type { MaybeChoreographedComponentProps } from '@confluence/choreographer-services';

export type FlagAction = NonNullable<FlagProps['actions']>[0];

export type FlagType = 'success-circle' | 'success' | 'info' | 'error' | 'warning' | 'custom';

export type FlagDescriptor = {
	title?: ReactNode;
	description?: ReactNode;
	actions?: FlagAction[];
	onClose?: () => void;
	id: number | string;
	/**
	 * `type` is used to decide the icon of Flag
	 */
	type?: FlagType;
	customIcon?: JSX.Element;
	/**
	 * When `appearance` is given, the Flag is bold. If not, it's a normal Flag.
	 */
	appearance?: FlagProps['appearance'];
	/**
	 * If not specified, the flag will stay until the user dismisses/closes it or
	 * `hideAllFlags()` is called. This is the most common use case in this
	 * codebase.
	 *
	 * If `auto` is specified and `customComponent` is falsy, the flag will
	 * automatically dismiss/close after a built-in timeout. This is the second
	 * most common use case in this codebase. If `customComponent` is truthy,
	 * `auto` has no effect.
	 *
	 * ---
	 *
	 * WARNING: Other values are uncommon in this codebase and are to only be used
	 * in special cases after careful consideration of alternatives!
	 *
	 * If `never` is specified, the flag will stay until the user dismisses/closes
	 * it, calling `hideAllFlags()` will not hide it. The value is to be used only
	 * after undesireable interaction with existing `hideAllFlags()` calls was
	 * observed.
	 */
	close?: 'auto' | 'never';
	customComponent?: ComponentType<FlagProps>;
} & MaybeChoreographedComponentProps;

export type ShowFlagArgs = Pick<
	FlagDescriptorWithOptionalId,
	'id' | 'title' | 'description' | 'actions' | 'onClose' | 'appearance'
> & {
	isAutoDismiss?: boolean;
	customIcon?: ReactElement;
};

type FlagDescriptorWithoutId = Omit<FlagDescriptor, 'id'>;

type FlagDescriptorWithOptionalId = Partial<Pick<FlagDescriptor, 'id'>> & FlagDescriptorWithoutId;

type FlagsStateContainerState = {
	nextFlagId: number;
	flags: FlagDescriptor[];
	areFlagsVisible: boolean;
};

export class FlagsStateContainer extends Container<FlagsStateContainerState> {
	private previousShowFlag: Promise<void> | null;

	constructor(flags?: FlagDescriptor[]) {
		super();
		this.previousShowFlag = null;
		this.state = {
			flags: flags || [],
			nextFlagId: flags ? flags.length : 0,
			areFlagsVisible: true,
		};
	}

	showMultipleFlags = (flags: FlagDescriptorWithoutId[]) =>
		this.setState(({ nextFlagId }) => {
			const newFlags = flags.map((flag) => ({
				...flag,
				id: nextFlagId++,
			}));

			return { flags: newFlags, nextFlagId };
		});

	showFlag = (flag: FlagDescriptorWithOptionalId): Promise<FlagDescriptor> => {
		const updateFlagState = () => {
			let newFlag: FlagDescriptor;
			return this.setState(({ flags, nextFlagId }) => {
				const equalsFlag = (testFlag: FlagDescriptor) => testFlag.id === flag.id;
				if (flags.some(equalsFlag)) {
					const newFlags = flags.map((testFlag) =>
						equalsFlag(testFlag) ? (flag as FlagDescriptor) : testFlag,
					);
					return {
						flags: newFlags,
						nextFlagId,
					};
				}

				newFlag = {
					...flag,
					id: flag.id || nextFlagId,
				};

				return {
					flags: [newFlag, ...flags],
					nextFlagId: nextFlagId + 1,
				};
			}).then(() => newFlag);
		};

		const delay = () =>
			new Promise<void>((resolve) => {
				// Timeout protects against visual bug caused by
				// race condition from concurrent executions of this function.
				setTimeout(resolve, 500);
			});

		if (this.previousShowFlag) {
			const result = this.previousShowFlag.then(updateFlagState);
			this.previousShowFlag = delay();
			return result;
		} else {
			this.previousShowFlag = delay();
			return updateFlagState();
		}
	};

	updateFlag = (id: number | string | undefined, patch: ShowFlagArgs) =>
		this.setState((state) => {
			// Ensure we create new instance of both updated flag and of an entire flags array,
			// and retain the order of the flags in the array.
			// Avoid direct mutation of flag and array as it may cause bugs when rendering flags.
			const flags = [...state.flags];
			for (let index = 0; index < flags.length; index++) {
				const flag = flags[index];
				if (flag.id === id) {
					flags[index] = {
						...flag,
						...patch,
					};
				}
			}

			return { flags };
		});

	hideFlag = (id: number | string | undefined) =>
		this.setState((state) => ({
			flags: state.flags.filter((flag) => flag.id !== id),
		}));

	/**
	 * Hides all active flags but the ones which have explicitly been marked with
	 * `close: "never"` - these have been marked explicitly, because an
	 * undesireable interaction with existing `hideAllFlags()` calls was observed.
	 */
	hideAllFlags = () =>
		this.setState((state) => ({
			flags: state.flags.filter((flag) => flag.close === 'never'),
		}));

	// Allows for setting whether the flags should be visually hidden or shown in the UI
	setFlagsVisibility = (areFlagsVisible: boolean) => this.setState({ areFlagsVisible });

	private showFlagOfType =
		(type: FlagDescriptor['type']) =>
		({
			id,
			title,
			description,
			actions,
			onClose,
			isAutoDismiss,
			customIcon,
			appearance,
		}: ShowFlagArgs) =>
			this.showFlag({
				id,
				type,
				title,
				description,
				actions,
				onClose,
				close: isAutoDismiss ? 'auto' : undefined,
				customIcon,
				appearance,
			});

	showSuccessFlag = this.showFlagOfType('success');

	showInfoFlag = this.showFlagOfType('info');

	showCustomFlag = this.showFlagOfType('custom');

	showWarningFlag = this.showFlagOfType('warning');

	showErrorFlag = this.showFlagOfType('error');
}
