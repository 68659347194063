import { getSessionData } from '@confluence/session-data';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

const STOP_DELAY_TIMEOUT = 11_000;
let lastDelayActionPromise = getAnalyticsWebClient();
let isAutomaticExposuresEnabled = false;

export const pauseLowPriorityEvents = async (routeName: string) => {
	if (routeName !== 'VIEW_PAGE') {
		return lastDelayActionPromise;
	}

	const { featureFlagClient } = await getSessionData();

	// By using chained promises we can ensure that the order of pause and resume calls are kept
	lastDelayActionPromise = lastDelayActionPromise.then((analyticsClient) => {
		// If pauseLowPriorityEvents is called when it's already active, then we are just extending
		// the delay period, so we don't want to reset any metadata we have captured about the original start.
		if (!isAutomaticExposuresEnabled) {
			featureFlagClient.setIsAutomaticExposuresEnabled(true);
			isAutomaticExposuresEnabled = true;
		}

		analyticsClient.startLowPriorityEventDelay(
			STOP_DELAY_TIMEOUT,
			stopLowPriorityEventDelayCallback,
		);

		return analyticsClient;
	});

	// return only for test usage
	return lastDelayActionPromise;
};

export const resumeLowPriorityEvents = () => {
	lastDelayActionPromise = lastDelayActionPromise.then((analyticsClient) => {
		analyticsClient.stopLowPriorityEventDelay();
		return analyticsClient;
	});

	// return only for test usage
	return lastDelayActionPromise;
};

// this callback will always be called from the analytics web client
const stopLowPriorityEventDelayCallback = async (): Promise<void> => {
	const { featureFlagClient } = await getSessionData();
	featureFlagClient.setIsAutomaticExposuresEnabled(false);
	isAutomaticExposuresEnabled = true;
};
