/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::c2b92c49438f15ec6e4bf637fc2cd519>>
 * @codegenId #progress-bar
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen progress-bar
 * @codegenParams { "props": { "appearance":true, "ariaLabel":true, "isIndeterminate":true, "testId":true, "value":true } }
 * @codegenDependency ../../../../../../../design-system/progress-bar/src/types.tsx <<SignedSource::e0b1648d9e607ad4ab27530e09396ef7>>
 * @codegenDependency ../../../../../../../design-system/progress-bar/src/components/progress-bar.tsx <<SignedSource::2c68c582bf2447e8391c7012c8fbdcd5>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformProgressBar from '@atlaskit/progress-bar';

type PlatformProgressBarProps = React.ComponentProps<typeof PlatformProgressBar>;

export type ProgressBarProps = Pick<
  PlatformProgressBarProps,
  'appearance' | 'ariaLabel' | 'isIndeterminate' | 'testId' | 'value'
>;

export const ProgressBar = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    ariaLabel,
    isIndeterminate,
    testId,
    value,
  } = props.forgeDoc.props as ProgressBarProps;
  return (
    <PlatformProgressBar
      appearance={appearance}
      ariaLabel={ariaLabel}
      isIndeterminate={isIndeterminate}
      testId={testId}
      value={value}
    />
  );
};
/**
 * @codegenEnd
 */
