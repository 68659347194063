import gql from 'graphql-tag';

export const PersonalSpaceDetailQuery = gql`
	query PersonalSpaceDetailQuery {
		user(current: true) {
			id
			confluence {
				space {
					id
					key
					dataClassificationTags
				}
			}
		}
	}
`;
