import { copyHTMLToClipboard } from '@atlaskit/editor-common/clipboard';
import type { Fragment, Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import { DOMSerializer } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

/**
 * Copied from packages/editor/editor-core/src/plugins/copy-button/utils.ts
 * and slightly amended to accept a PM Fragment instead of a node
 */
const toDOM = (fragment: Fragment, schema: Schema): Node => {
	return DOMSerializer.fromSchema(schema).serializeFragment(fragment);
};

/**
 * Copied from packages/editor/editor-core/src/plugins/paste/pm-plugins/clipboard-text-serializer.ts
 * and slightly amended to accept a PM Fragment instead of a slice
 */
function clipboardTextSerializer(fragment: Fragment) {
	let text = '';
	const blockSeparater = '\n\n';
	fragment.nodesBetween(0, fragment.size, (node: PMNode) => {
		if (node.type.isBlock) {
			text += blockSeparater;
		}
		if (node.type.name === 'paragraph') {
			return true;
		} else if (node.type.name === 'hardBreak') {
			text += '\n';
		} else if (node.type.name === 'text') {
			text += node.text;
		} else if (node.type.name === 'inlineCard') {
			text += node.attrs.url;
		} else if (node.type.name === 'blockCard') {
			text += node.attrs.url;
		} else {
			text += node.textBetween(0, node.content.size, '\n\n');
		}
		return false;
	});
	return text.trim();
}

export function copyResponseToClipboard({
	editorView,
	pmFragment,
}: {
	editorView: EditorView;
	pmFragment: Fragment;
}) {
	const transaction = editorView.state.tr;
	const domNode = toDOM(pmFragment, editorView.state.schema);
	if (domNode) {
		const div = document.createElement('div');
		div.appendChild(domNode);

		// The "0 0" refers to the start and end depth of the slice
		// since we're copying the block node only, it will always be 0 0
		// https://github.com/ProseMirror/prosemirror-view/blob/master/src/clipboard.ts#L32
		(div.firstChild as HTMLElement).setAttribute('data-pm-slice', '0 0 []');

		/**
		 * ED-18062: override the plain text in
		 * copyHTMLToClipboard using clipboardTextSerializer(pmFragment)
		 * because HTML to plain text is formatted without new lines
		 * in certain nodes such as lists
		 */
		copyHTMLToClipboard(div, clipboardTextSerializer(pmFragment));
	}

	transaction.setMeta('scrollIntoView', false);
	editorView.dispatch(transaction);
}

/*
 * This function checks if the current selection is a partial copy of the target element.
 */
export function isPartialCopy(targetElement: HTMLElement) {
	const selection = window.getSelection();
	const selectionText = selection?.toString();

	// If there is no current selection, we don't want to send the event.
	if (!selectionText) {
		return false;
	}

	// Remove new lines and trailing spaces from the text to compare as innerText may have unecessary newlines.
	const strippedPreviewResponseText = targetElement.innerText.replace(/\n/g, '').trim();
	const strippedSelectionText = selectionText.replace(/\n/g, '').trim();

	let isSelectionOutsideTargetElement = false;
	let isSelectionWholeAndMore = false;
	if (selection) {
		const { anchorNode, focusNode } = selection;
		// Cases where the selection may be outside the node but are considered a whole copy:
		// Starts and ends outside the node.
		// Note: The anchor and focus nodes might be text nodes, not necessarily elements themselves,
		// but the `contains` method works for any node type.
		const selectionStartsOutside = !targetElement.contains(anchorNode);
		const selectionEndsOutside = !targetElement.contains(focusNode);
		isSelectionOutsideTargetElement = selectionStartsOutside && selectionEndsOutside;
		// Starts outside, ends at the end of the node.
		// OR Ends outside, starts at the start of the node.

		// Verify start and end locations.
		const selectionStartsAtStart = selection.anchorOffset === 0;
		const selectionEndsAtEnd = selection.focusOffset === focusNode?.textContent?.length;
		// Verify if the selection is a whole copy and more.
		const startsOutsideAndEndsAtEnd = selectionStartsOutside && selectionEndsAtEnd;
		const startsAtStartAndEndsOutside = selectionEndsOutside && selectionStartsAtStart;

		if (startsOutsideAndEndsAtEnd || startsAtStartAndEndsOutside) {
			isSelectionWholeAndMore = true;
		}
	}

	if (isSelectionOutsideTargetElement || isSelectionWholeAndMore) {
		return false;
	}

	return strippedPreviewResponseText !== strippedSelectionText;
}

/**
 * Checks if the current selection is within the target element.
 */
export function isSelectionWithinElement(element: HTMLElement): boolean {
	const selection = window.getSelection();

	if (!selection?.rangeCount || !selection.toString()) {
		return false;
	}

	const { anchorNode, focusNode } = selection;
	if (!anchorNode || !focusNode) {
		return false;
	}

	// if start or end of the selection is within element no need in further checks
	if ([anchorNode, focusNode].some((node) => element.contains(node))) {
		return true;
	}

	// detect start and end node based on the direction of the selection
	const isLTRSelection =
		anchorNode.compareDocumentPosition(focusNode) & Node.DOCUMENT_POSITION_FOLLOWING;
	const startNode = isLTRSelection ? selection.anchorNode : selection.focusNode;
	const endNode = isLTRSelection ? selection.focusNode : selection.anchorNode;

	if (!startNode || !endNode) {
		return false;
	}

	// Check if the selection starts before and ends after the element
	if (
		startNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_FOLLOWING &&
		endNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_PRECEDING
	) {
		return true;
	}

	// Check if the selection starts after and ends before the element
	if (
		startNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_PRECEDING &&
		endNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_FOLLOWING
	) {
		return true;
	}

	return false;
}
