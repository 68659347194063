import { processFeatureFlags } from './processFeatureFlags';
import type { SessionDataQuery } from './__types__/SessionDataQuery';
import type { FeatureFlagsQuery } from './__types__/FeatureFlagsQuery';
import type { SessionDataType } from './SessionDataTypes';

function nonNull<T>(value: T | null): value is T {
	return value !== null;
}

export const getEmptySessionData = () => processSessionData(null);

export function processSessionData(
	data: (SessionDataQuery & FeatureFlagsQuery) | null,
): SessionDataType {
	const { originalFeatureFlags, featureFlagsWithOverrides, featureFlagClient } =
		processFeatureFlags(
			data?.featureFlags?.nodes?.filter(nonNull) || [],
			data?.platformFeatureFlags?.nodes?.filter(nonNull) || [],
			data?.user?.id,
		);

	// nullability of `tenant` is probably a schema issue
	// `userForAnalytics.id` would probably never be null, follow up with Xiao
	const confluenceLocale = data?.user?.confluence?.locale?.replace('_', '-');
	return {
		shard: data?.tenant?.shard! || '', // `tenant` nullability is a problem in cc-graphql schema
		cloudId: data?.tenant?.cloudId! || '', // `tenant` nullability is a problem in cc-graphql schema
		activationId: data?.tenant?.activationId || '',
		orgId: data?.organization?.orgId || undefined,
		environment: data?.tenant?.environment! || 'PRODUCTION', // `tenant` nullability is a problem in cc-graphql schema
		edition: data?.tenant?.editions?.edition || null,
		userId: data?.user?.id || null,
		displayName: data?.user?.displayName || null,
		isLoggedIn: Boolean(data?.user?.id),
		// CCP-472: This is for JSD users who are unlicensed for
		// Confluence usage. In that case, we need to skip
		// `WatchDialogQuery` as they are causing NullPointerExceptions
		// in the BE.
		isLicensed: Boolean(data?.user?.confluence?.accountId && data?.user?.confluence?.userKey),
		accessStatus: data?.user?.confluence?.accessStatus || null,
		userIdForAnalytics: data?.userForAnalytics?.id || null,
		featureFlags: featureFlagsWithOverrides,
		featureFlagClient,
		_originalFeatureFlags: originalFeatureFlags,
		locale: confluenceLocale || 'en-US',
		timeZone: data?.user?.timeZone || null,
		isAdminHubAIEnabled: Boolean(data?.getAIConfig?.isEnabled),
		isRovoEnabled: Boolean(data?.getAIConfig?.isRovoEnabled),
	};
}
