import type { VFC } from 'react';
import React, { useContext, useMemo } from 'react';

import type { EditorActions } from '@atlaskit/editor-core';

import type { FeatureFlagsType } from '@confluence/session-data';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import {
	ContentAppearanceContextProvider,
	useContentAppearance,
} from '@confluence/content-appearance';
import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';
import { ClassicEditorContext } from '@confluence/editor-features/entry-points/ClassicEditorContext';
import { ExcerptIncludeLoader } from '@confluence/excerpt-include/entry-points/loader';
import type {
	ExcerptIncludeProps,
	RendererWrapperProps,
} from '@confluence/excerpt-include/entry-points/types';

type ExcerptIncludeExtensionHandlerProps = {
	Renderer: React.FC<any>;
	editorActions: EditorActions;
	featureFlags?: FeatureFlagsType;
	isLivePage?: boolean;
};

/**
 * Extension handler for "excerpt-include" macro.
 *
 * This macro, when given a target page, will attempt to fetch and display
 * the first excerpt found on the target page.
 */
const ExcerptIncludeExtensionHandlerImpl: VFC<
	ExtensionHandlerProps & ExcerptIncludeExtensionHandlerProps
> = (props) => {
	const { contentId, spaceKey, extensionKey, node, mode, Renderer, featureFlags, isLivePage } =
		props;

	const {
		contentAppearance: { appearance },
	} = useContentAppearance();

	const RendererWrapper = useMemo(
		() =>
			({ targetContentId, adf, mediaToken, isInsideOfInlineExtension }: RendererWrapperProps) => {
				const rendererProps = {
					contentId: targetContentId,
					spaceKey,
					// Transform the adf data into something PageContentRenderer understands (matching with content API response)
					data: {
						content: {
							nodes: [
								{
									body: {
										dynamic: {
											representation: 'atlas_doc_format',
											value: JSON.stringify({ content: adf, type: 'doc' }), // Have to transform this back to a string :(
										},
									},
								},
							],
						},
					},
					appearance,
					hasInlineComments: false,
					mediaToken,
					annotationProvider: undefined,
					allowAnnotations: false,
					isInsideOfInlineExtension,
				};
				return <Renderer {...rendererProps} />;
			},
		[spaceKey, appearance, Renderer],
	);

	const componentProps: ExcerptIncludeProps = {
		mode,
		contentId,
		spaceKey,
		extensionKey,
		macroNode: node,
		RendererWrapper,
		isLegacyRenderer: true,
		featureFlags,
		isLivePage,
	};

	return <ExcerptIncludeLoader {...componentProps} />;
};

export const ExcerptIncludeExtensionHandler = (props) => {
	const { getExtensionHandlers, populateExtensionProviders } = useContext(ClassicEditorContext);

	const rendererExtensionOptions = useMemo(
		() => ({
			getExtensionHandlers,
			populateExtensionProviders,
		}),
		[getExtensionHandlers, populateExtensionProviders],
	);

	// ExcerptInclude use PageContentRenderer which depends on RendererExtensionContext
	return (
		<RendererExtensionContext.Provider value={rendererExtensionOptions}>
			<ContentAppearanceContextProvider contentId={props.contentId}>
				<ExcerptIncludeExtensionHandlerImpl {...props} />
			</ContentAppearanceContextProvider>
		</RendererExtensionContext.Provider>
	);
};
