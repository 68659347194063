import uuid from 'uuid/v4';

import { FabricChannel } from '@atlaskit/analytics-listeners/types';
import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { CONFIG_ITEM_KEYS } from '../../config-items/config-item-keys';
import { type EditorPluginAIConfigItemMarkdown } from '../../config-items/config-items';
import type { UserFlowAnalyticsErrors } from '../types';
import {
	createUnifiedAnalyticPayload,
	type FireAIAnalyticsEventCallback,
	isErrorStepAttributes,
} from '../utils';

import type {
	CommonStepAttributes,
	CompleteStep,
	FailedStep,
	PreviewStep,
	ServerErrorStep,
	StepName,
	UserFlowAEP,
	UserFlowStepAttributes,
} from './analyticsFlowTypes';

type FireStepArgs = {
	stepName: StepName;
	attributes?: Partial<UserFlowStepAttributes>;
};

export type AnalyticsFlow = {
	/** The unique identifier for the current user journey */
	aiSessionId: string;
	enqueueStep: (stepArgs: {
		stepName: StepName;
		attributes?: Partial<UserFlowStepAttributes>;
	}) => void;
	fireQueuedStep: (target?: StepName) => void;
	addAttributes: (attributes: Partial<UserFlowStepAttributes>) => void;
	updateCommonAttributes: (attributes: Partial<CommonStepAttributes>) => void;
	getLastAiInteractionId: () => string | undefined;
	getUserFlowCommonAttributes: (
		configItem: EditorPluginAIConfigItemMarkdown,
	) => Partial<CommonStepAttributes>;
};

export function convertExperienceName(experienceName: string) {
	if (experienceName === CONFIG_ITEM_KEYS.EXPERIMENTAL_PROMPT_PLACEHOLDER) {
		/**
		 * We only check for experiment value here as the config item would not exist
		 * if the experiment is not enabled / is misconfigured.
		 */
		const getExperimentValue = FeatureGates.getExperimentValue;
		const analyticsExperienceName = getExperimentValue(
			'platform_editor_ai-prompts-placeholder',
			'analytics_experience_name',
			'',
		);
		return analyticsExperienceName || experienceName;
	}
	return experienceName;
}

/*
 * make sure to create this flow once for each user journey
 */
export function createAnalyticsFlow({
	fireAIAnalyticsEvent,
	invokeAttributes,
}: {
	fireAIAnalyticsEvent: FireAIAnalyticsEventCallback;
	invokeAttributes: Partial<UserFlowStepAttributes>;
}): AnalyticsFlow {
	const startTime = performance.now();
	const stepTracker: { [stepName: string]: number } = {};
	const aiSessionId = uuid();

	let prevStepEnd = performance.now();
	let prevStepName: StepName;
	let experienceName: string | undefined;
	let adhocAttributes: Partial<UserFlowStepAttributes> = {};
	let commonAttributes: Partial<CommonStepAttributes> = {};
	let finished = false;
	let contentCopiedSometime = false;
	let sawAupViolation = false;

	let interactionsCounter = 0;
	let lastAiInteractionID: string | undefined;

	let queuedStepArgs: FireStepArgs | undefined = {
		stepName: 'invoke',
		attributes: invokeAttributes,
	};

	const analyticsFlow = {
		aiSessionId,
		/**
		 * Add a step to the queue to be fired on next screen.
		 * @example
		 * ```tsx
		 * const analyticsFlow = useAnalyticsFlow();
		 * analyticsFlow.enqueueStep({ stepName, attributes });
		 * ```
		 */
		enqueueStep: (stepArgs: {
			stepName: StepName;
			attributes?: Partial<UserFlowStepAttributes>;
		}) => {
			// If the user has already finished the experience, we fire an operational error and return
			if (finished) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'userFlowAlreadyFinished',
						errorProperties: {
							stepArgs,
							queuedStepArgs,
						},
					}),
				);
				return;
			}

			if (stepArgs.stepName === 'loading') {
				lastAiInteractionID = `${aiSessionId}-${++interactionsCounter}`;
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticPayload(
						'initiated',
						commonAttributes.channelId ?? lastAiInteractionID,
						convertExperienceName(experienceName ?? ''),
					),
				});
			}

			if (stepArgs.stepName === 'complete') {
				const attributes = stepArgs?.attributes ?? {};
				const actionTaken = 'actionTaken' in attributes ? attributes.actionTaken : undefined;
				const promptType = 'promptType' in attributes ? attributes.promptType : undefined;
				const refinementCount =
					'refinementCount' in attributes ? attributes.refinementCount : undefined;

				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticPayload(
						'actioned',
						commonAttributes.channelId ?? lastAiInteractionID,
						convertExperienceName(experienceName ?? ''),
						false,
						{
							promptType,
							refinementCount,
							aiResultAction: actionTaken?.toLowerCase(),
						},
					),
				});
			}

			// If there is already a queued step, we fire an operational error and return
			if (queuedStepArgs !== undefined) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'cannotEnqueueMoreThanOneEvent',
						errorProperties: { stepArgs, queuedStepArgs },
					}),
				);
				return;
			}
			queuedStepArgs = stepArgs;
		},
		fireQueuedStep: (target?: StepName) => {
			// If the user has already finished the experience, we fire an operational error and return
			if (finished) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'userFlowAlreadyFinished',
						errorProperties: {
							target,
							queuedStepArgs,
						},
					}),
				);
				return;
			}

			// If there is no queued step, we fire an operational error and return
			if (!queuedStepArgs) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'noEventExistsToBeFired',
						errorProperties: { target },
					}),
				);
				return;
			}

			queuedStepArgs.attributes = {
				...queuedStepArgs.attributes,
				...adhocAttributes,
				...commonAttributes,
				invokedFrom: invokeAttributes.invokedFrom,
				//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
				...(editorExperiment('platform_editor_ai_ai_button_block_elements', 'test')
					? { invokedFor: invokeAttributes.invokedFor }
					: {}),
				invokedFor: invokeAttributes.invokedFor,
				triggerMethod: invokeAttributes.triggerMethod,
				target,
			};

			if (target === 'failed' || target === 'apiError') {
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticPayload(
						'error',
						commonAttributes.channelId ?? lastAiInteractionID,
						convertExperienceName(experienceName ?? ''),
						false,
						{
							aiErrorMessage: isErrorStepAttributes(queuedStepArgs?.attributes)
								? queuedStepArgs?.attributes?.errorKey ?? queuedStepArgs?.attributes?.errorType
								: 'Unknown error',
							aiErrorCode: isErrorStepAttributes(queuedStepArgs.attributes)
								? queuedStepArgs.attributes?.statusCode ?? 500
								: 500,
						},
					),
				});
			}

			if (target === 'preview') {
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticPayload(
						'viewed',
						commonAttributes.channelId ?? lastAiInteractionID,
						convertExperienceName(experienceName ?? ''),
					),
				});
			}

			// Add target to attributes and fire the event
			fireUserFlowStep(queuedStepArgs);

			// Remove the queued event
			queuedStepArgs = undefined;
		},
		/**
		 * This is useful when you want to add attributes to the current step
		 * but you don't know them at the time of enqueuing the step.
		 *
		 * It's safe to fire this on dismount -- because the step is enqueued in the dismount
		 * of a parent component (where AnalyticsFlowContextProvider is setup).
		 *
		 * @example
		 * ```tsx
		 * const analyticsFlow = useAnalyticsFlow();
		 * React.useEffect(() => {
		 *  return () => {
		 *    // add attribute on dismount
		 *    analyticsFlow.addAttributes({ loadingTime })
		 *  }
		 * })
		 * ```
		 */
		addAttributes: (attributes: Partial<UserFlowStepAttributes>) => {
			// The user copying the content is being treated as a potential success indicator
			// This is a weak signal as we don't know if the user actually used the copied content
			// and might expect the user to copy the content in cases where the generation failed.
			if ('contentCopied' in attributes && attributes.contentCopied) {
				contentCopiedSometime = true;
			}
			adhocAttributes = {
				...adhocAttributes,
				...attributes,
			};
		},
		/**
		 * Common attributes will be retained for the rest of the analytics flow
		 * until it is updated again
		 *
		 * Example uses: keeping track of which backendModel has been selected
		 */
		updateCommonAttributes: (attributes: Partial<CommonStepAttributes>) => {
			commonAttributes = {
				...commonAttributes,
				...attributes,
			};
		},

		getLastAiInteractionId: () => lastAiInteractionID,

		getUserFlowCommonAttributes: (configItem: EditorPluginAIConfigItemMarkdown) => {
			return {
				agentId: configItem.agent?.id,
				agentName: configItem.agent?.creatorType === 'SYSTEM' ? configItem.agent?.name : undefined,
			};
		},
	};

	return analyticsFlow;

	function fireUserFlowStep({ stepName, attributes = {} }: FireStepArgs) {
		if (!stepTracker[stepName]) {
			stepTracker[stepName] = 1;
		} else {
			stepTracker[stepName] = stepTracker[stepName] + 1;
		}

		if (attributes.experienceName) {
			experienceName = convertExperienceName(attributes.experienceName);
		}
		let stepAttributes: UserFlowStepAttributes = {
			...commonAttributes,
			// the duration is currently being overriden in the complete step
			duration: Math.round(performance.now() - prevStepEnd),
			...attributes,
			aiSessionId,
			experienceName,
			source: prevStepName,
			invokedFrom: attributes.invokedFrom,
			triggerMethod: attributes.triggerMethod,
			stepOccurrence: String(stepTracker[stepName]),
			sawAupViolation,
		};

		// add totalDuration only to the final step
		// and set the experience as finished
		if (!attributes.target) {
			stepAttributes.totalDuration = Math.round(performance.now() - startTime);
			stepAttributes.contentCopiedSometime = contentCopiedSometime;
			finished = true;
		}

		// adding interaction ID to the events coming after the loading step, + final complete step
		if (stepAttributes.source === 'loading' || stepName === 'complete') {
			(
				stepAttributes as
					| PreviewStep['attributes']
					| ServerErrorStep['attributes']
					| FailedStep['attributes']
					| CompleteStep['attributes']
			).aiInteractionID = lastAiInteractionID;
		}

		let payload: UserFlowAEP = {
			action: stepName,
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'userFlow',
			attributes: stepAttributes,
			eventType: EVENT_TYPE.UI,
		};

		//each step is a starting point for measuring duration for next step
		prevStepEnd = performance.now();
		//reset previous step name and custom attributes
		prevStepName = stepName;
		adhocAttributes = {};
		fireAIAnalyticsEvent({
			payload,
		});

		if (finished) {
			fireAIAnalyticsEvent({
				payload: {
					...payload,
					action: 'finished',
					attributes: {
						...payload.attributes,
						// override source to be the previous last step in the flow
						source: stepName,
					},
				},
			});
		}
	}
}

/**
 * Creates an operational error payload
 */
function createErrorPayload<
	ErrorSubtype extends UserFlowAnalyticsErrors['attributes']['errorSubType'],
>({
	errorSubType,
	errorProperties,
}: {
	/**
	 * The userflow operational error subtype
	 */
	errorSubType: ErrorSubtype;

	errorProperties: Omit<
		Extract<UserFlowAnalyticsErrors, { attributes: { errorSubType: ErrorSubtype } }>['attributes'],
		'errorSubType' | 'errorType'
	>;

	aiSessionId: string;
}): {
	payload: UserFlowAnalyticsErrors;
	channel: FabricChannel.editor;
} {
	return {
		payload: {
			action: 'unhandledErrorCaught',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'experienceApplication',
			attributes: {
				errorType: 'userFlowAnalyticsError',
				errorSubType,
				...errorProperties,
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		} as UserFlowAnalyticsErrors,
		channel: FabricChannel.editor,
	};
}
