import { query } from '@confluence/query-preloader-tools';

import { FavouriteButtonQuery } from './FavouriteButtonQuery.graphql';

export function preloadFavouriteButton(contentId, userId) {
	// skip preloading on SSR if user is not logged in
	if (!Boolean(userId)) {
		return Promise.resolve({});
	}

	return query({
		query: FavouriteButtonQuery,
		variables: {
			contentId,
		},
	});
}
