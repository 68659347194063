import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { TemplateGalleryLoader } from '@confluence/template-gallery';

export const TemplateGalleryRoute = () => <TemplateGalleryLoader />;

TemplateGalleryRoute.NAVIGATION_PARAMS = ({ name }: RouteMatch) => ({
	Screen: {
		screenEvent: { name: 'templateGalleryScreen' },
		pageState: { routeName: name },
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
		disableMinWidth: true,
	},
});
