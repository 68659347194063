/* Statsig feature gate names to enable/disable the Choreographer API in
 * end products.
 *
 * For completion, Jira's gate name is included but is not actually used
 * within the Jira codebase because Jira does not allow the use of
 * constants or variables within their Statsig wrapper functions (e.g. ff
 * or fg from @atlassian/jira-feature-gating).
 */
export const ENABLE_CHOREOGRAPHER_GATE = {
	bitbucket: 'post-office_enable_bitbucket_choreographer',
	confluence: 'confluence.frontend.post-office_enable_confluence_choreographer',
	cloud_admin: 'post-office_enable_cloud_admin_choreographer',
	jira: 'post-office_enable_jira_choreographer',
};
