import React from 'react';

import { CreateFromTemplateLoader } from '@confluence/create-from-template';

export const CreateFromTemplateHandler = ({
	macroDefaultProps,
	cloudId,
	userId,
	ssrButtonLabel,
}) => (
	<CreateFromTemplateLoader
		userId={userId}
		cloudId={cloudId}
		macroDefaultProps={macroDefaultProps}
		ssrButtonLabel={ssrButtonLabel}
	/>
);
