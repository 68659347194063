import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import { Skeleton } from './Skeleton';

const i18n = defineMessages({
	mainStatNotAvailableText: {
		id: 'admin-center.summary-card.main-stat-not-available-text',
		defaultMessage: 'N/A',
		description: 'Text to display for the main stat when it is not available',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainStatContainer = styled.span({
	color: token('color.text', N800),
	fontSize: '16px',
	lineHeight: '20px',
	fontWeight: 600,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainStatValueWrapper = styled.span({
	color: token('color.text', N800),
	fontSize: '24px',
	lineHeight: '28px',
	fontWeight: 510,
});

export type MainStatType = {
	value: string | undefined;
	description?: MessageDescriptor;
	descriptionValues?: { [key in string]: JSX.Element };
};

type MainStatProps = {
	showSkeleton?: boolean;
	data: MainStatType | undefined;
};

export const MainStat = ({ showSkeleton = false, data }: MainStatProps) => {
	if (showSkeleton) {
		return <Skeleton width="15%" height="20px" />;
	} else if (!data || (!data.value && !data.description)) {
		return (
			<MainStatValueWrapper>
				<FormattedMessage {...i18n.mainStatNotAvailableText} />
			</MainStatValueWrapper>
		);
	} else if (!data.description) {
		return <MainStatValueWrapper>{data.value}</MainStatValueWrapper>;
	} else {
		const { value, description, descriptionValues } = data;
		return (
			<MainStatContainer data-testid="main-stat">
				<FormattedMessage
					{...description}
					values={{
						value: <MainStatValueWrapper>{value}</MainStatValueWrapper>,
						count: value,
						...descriptionValues,
					}}
				/>
			</MainStatContainer>
		);
	}
};
