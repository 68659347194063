import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { SPACE_OVERVIEW } from '@confluence/screen';
import { SpaceOverviewLoader } from '@confluence/space-overview';

import { ContentExtensionsProvider } from '../../ContentExtensionsProvider';
import SpaceBase from '../../SpaceBase';

export const PdfExportSpaceRoute = ({ params: { spaceKey } }: RouteMatch) => (
	<SpaceBase>
		<ContentExtensionsProvider>
			<SpaceOverviewLoader spaceKey={String(spaceKey)} />
		</ContentExtensionsProvider>
	</SpaceBase>
);

PdfExportSpaceRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		pageState: {
			routeName: name,
			contentId: SPACE_OVERVIEW,
			spaceKey,
		},
		screenEvent: { name: 'pdfExportScreen' },
		spaceKey,
	},
	MainLayout: {
		enableNavigation: false,
	},
});
